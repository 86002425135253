import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRange, ArrowDropDown } from '@material-ui/icons';
import Clear from '@material-ui/icons/Clear';
import { Button, Divider, Grid, IconButton, List, ListItem, Popover, Typography } from '@material-ui/core';
import {
    getStartAndEndDateFromOrderTypeDateFilter,
    isObjectEmptyOrValuesEmpty,
    isValidTextAndNotEmpty,
} from '../../../constants/CommonUtil';
import DateInput from './DateInput';
import { diffInDateInDays, formatDateForDisplay, isValidDate } from '../../../constants/DateUtil';
import ActionButton from '../../ActionButton/ActionButton';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';

class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            startDate: '',
            endDate: '',
            period: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextPeopsFromDateRange', { nextProps });
        const { selectedValues, defaultOption } = nextProps;

        if (isObjectEmptyOrValuesEmpty(selectedValues)) {
            this.setState({
                ...selectedValues,
            });
        } else if (isObjectEmptyOrValuesEmpty(defaultOption)) {
            this.getDefaultValueFromDefaultOption(defaultOption);
        }
    }


    onBlur = () => {}

    onPeriodClick = (e, value) => {
        e.stopPropagation();
        const { onRangeSelect } = this.props;
        console.log('selectedAPredefined', value);
        const {
            startDate,
            endDate,
        } = getStartAndEndDateFromOrderTypeDateFilter(value);
        console.log('selectedAPredefined', {
            startDate,
            endDate,
        });
        this.setState({
            startDate,
            endDate,
            period: value,
        });
        onRangeSelect({
            period: value,
            startDate,
            endDate,
        });
        this.closePopOver();
    }

    getDefaultValueFromDefaultOption = (defaultOption) => {
        console.log('getDefaultValueFromDefaultOption', defaultOption);
        const {
            startDate,
            endDate,
        } = getStartAndEndDateFromOrderTypeDateFilter(defaultOption.value);
        this.setState({
            startDate,
            endDate,
            period: defaultOption.value,
        });
    };


    getLabel = (startDate, endDate, period) => {
        if (isValidTextAndNotEmpty(period)) {
            return period;
        }
        let dateString = 'Date';
        console.log('checkDateValidity', {
            startDate, endDate, period, startDateValid: isValidDate(startDate), endDateValid: isValidDate(endDate),
        });
        if (isValidDate(startDate)) {
            dateString = formatDateForDisplay(startDate);
        } else if (isValidTextAndNotEmpty(startDate)) {
            dateString = formatDateForDisplay(new Date(startDate));
        }

        if (isValidDate(endDate)) {
            dateString = `${dateString}${dateString ? ' - ' : ''}${formatDateForDisplay(endDate)}`;
        } else if (isValidTextAndNotEmpty(startDate)) {
            dateString = `${dateString}${dateString ? ' - ' : ''}${formatDateForDisplay(new Date(endDate))}`;
        }
        return (
            <React.Fragment>
                <DateRange />
                <Typography align="center" style={{ marginTop: '0.3em' }}>
                    { dateString }
                </Typography>
            </React.Fragment>
        );
    }

    handleClick =(event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }
    closePopOver = () => {
        this.setState({
            anchorEl: null,
        });
    };
    handleDateSelect = name => (value) => {
        console.log('dateChanged', { value });
        this.setState({ [name]: value, period: '' });
    };

    handleApplyClick = () => {
        const { onRangeSelect, dispatch, disableDateRangeLimit } = this.props;
        const {
            period,
            endDate,
            startDate,
        } = this.state;
        const diff = diffInDateInDays(new Date(endDate), new Date(startDate));
        console.log('dateDiffCheck', { diff, disableDateRangeLimit });
        if (diff > 180 && !disableDateRangeLimit) {
            dispatch(errorMessage('Maximum custom date range limit is 180 days..! Please adjust the dates'));
            return;
        }
        onRangeSelect({
            period,
            startDate,
            endDate,
        });
        this.closePopOver();
    }


    render() {
        const {
            anchorEl,
            startDate,
            endDate,
            period,
        } = this.state;
        const {
            options, isClearable, size, label,
        } = this.props;
        console.log('dataperiod', {
            period, startDate, endDate, size,
        });
        const hasValue = period || startDate || endDate;
        const showLabel = isValidTextAndNotEmpty(label);
        const childNode = (
            <React.Fragment>
                <Button
                    fullWidth
                    primary={false}
                    variant=""
                    onClick={this.handleClick}
                    size={size}
                    color="primary"
                    style={showLabel ? {
                        marginTop: '-10px',
                    } : {}}
                >
                    {this.getLabel(startDate, endDate, period)}
                    {
                        isClearable && hasValue &&
                        <IconButton
                            onClick={(e) => {
                                this.onPeriodClick(e, null);
                            }}
                            style={{ padding: 0 }}
                        >
                            <Clear
                                fontSize="small"
                            />
                        </IconButton>
                    }
                    <ArrowDropDown />
                </Button>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.closePopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <List>

                        {
                            options.map(aPeriod => (
                                <ListItem
                                    key={aPeriod.key}
                                    button
                                    selected={aPeriod.value === period}
                                    onClick={e => this.onPeriodClick(e, aPeriod.value)}
                                >
                                    {aPeriod.value}
                                </ListItem>
                            ))
                        }
                    </List>
                    <Divider />
                    <Grid
                        container
                        spacing={8}
                        style={{
                            width: '30vw',
                            padding: '16px',
                        }}
                    >
                        <Grid item sm={12}>
                            <Typography
                                color="GrayText"
                            >
                                Custom Range
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <DateInput
                                onChange={this.handleDateSelect('startDate')}
                                label="From"
                                value={startDate}
                                maxDate={endDate}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <DateInput
                                onChange={this.handleDateSelect('endDate')}
                                label="To"
                                value={endDate}
                                minDate={startDate}
                            />
                        </Grid>
                        <Grid item sm={12} container>
                            <ActionButton
                                onClick={this.handleApplyClick}
                            >
                                Apply
                            </ActionButton>
                        </Grid>
                    </Grid>
                </Popover>
            </React.Fragment>
        );
        return (
            <fieldset
                style={{
                    padding: '0px',
                    borderRadius: '4px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                }}
            >
                {
                    showLabel &&
                    <legend
                        style={{
                            marginLeft: '12px',
                        }}
                    >
                        {label}
                    </legend>
                }
                {childNode}
            </fieldset>
        );
    }
}
DateRangePicker.propTypes = {
    onRangeSelect: PropTypes.object.isRequired,
    selectedValues: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    defaultOption: PropTypes.object,
    isClearable: PropTypes.bool,
    size: PropTypes.string,
    label: PropTypes.string,
    disableDateRangeLimit: PropTypes.bool,
    dispatch: PropTypes.func,
};
DateRangePicker.defaultProps = {
    defaultOption: {},
    isClearable: false,
    size: 'small',
    label: '',
    disableDateRangeLimit: false,
    dispatch: () => {},
};

const mapStateToProp = state => ({
    disableDateRangeLimit: getStringFromObject('appConfiguration.disable_date_range_limit', state) || false,

});
export default connect(mapStateToProp)(DateRangePicker);
