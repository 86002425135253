import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import MESSAGES from '../../constants/messages';
import {
    ORDER_OPERATION_SUCCESS, ORDER_SEND_TO_BILLING_SUCCESS,
    SAVE_ORDER_DETAILS, SAVE_MRS_ORDER_DETAILS,
} from '../../redux/modules/order/order-actions';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { insuranceRequestStates } from '../../containers/ApprovalQueueListingContainer/ApprovalQueueListingContainerUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* getOrderDetails(action) {
    const {
        uuid,
        requestType,
        status,
        orderTypes,
        onlyClinical,
    } = action;
    const api =
        `${action.api}?uuid=${uuid}&type=${requestType}&status=${status}&orderType=${orderTypes}&onlyClinical=${onlyClinical || false}`;
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().get,
            api,
        );
        yield put({ type: SAVE_ORDER_DETAILS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
    }
}
export function* getMrsOrderDetails(action) {
    const {
        fetchAdditionalPatDetails,
    } = action;
    const api =
        `${action.api}?orderUuid=${action.uuid}&fetchAdditionalPatDetails=${fetchAdditionalPatDetails || false}`;
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().get,
            api,
        );
        yield put({ type: SAVE_MRS_ORDER_DETAILS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
    }
}

export function* operateOrders(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().put,
            action.api,
            action.orders,
        );
        yield put({ type: ORDER_OPERATION_SUCCESS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: SUCCESS_MESSAGE, message: MESSAGES.ORDER.OPERATION_SUCCESS });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* sendToBilling(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().put,
            action.api,
            action.approvalDto,
        );
        yield put({ type: ORDER_SEND_TO_BILLING_SUCCESS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });

        let successMessage = `${MESSAGES.ORDER.SEND_TO_BILLING_SUCCESS}`;

        const saleOrderLineDetails =
            getStringFromObject('saleOrderLineDetails', action.approvalDto);
        if (isArrayValidAndNotEmpty(saleOrderLineDetails)) {
            const states = [...new Set(saleOrderLineDetails.map(aLine => aLine.insuranceRequestLineState))];
            console.log('asdahd-a0sda-s0diad', states);
            if (
                isArrayValidAndNotEmpty(states) &&
                states.length === 1 &&
                states.includes(insuranceRequestStates.DRAFT)
            ) {
                successMessage = `${MESSAGES.ORDER.OPERATION_SUCCESS}`;
            }
        }

        yield put({ type: SUCCESS_MESSAGE, message: successMessage });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
    }
}
