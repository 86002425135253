import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Paper from '@material-ui/core/Paper/Paper';
import ReduxFormText from '../../FormFieldComponents/ReduxFormText/ReduxFormText';
import { required } from '../../../constants/FormValidations';

const styles = () => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        margin: '0 auto',
    },
    table: {
        width: '100%',
    },
    tableCell: {
        color: '#FFFFFF',
    },
    headerRow: {
        background: 'linear-gradient(to right, rgb(58, 26, 228), rgb(47, 189, 204))',
    },
});


class CodeTable extends React.PureComponent {
    render() {
        const { fields, classes, isEditable } = this.props;
        console.log('asdfsdfsdf', fields);
        if (fields.length > 0) {
            return (
                <React.Fragment>
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.headerRow}>
                                    <TableCell className={classes.tableCell}>Rule Input Code</TableCell>
                                    <TableCell className={classes.tableCell}>Description</TableCell>
                                    <TableCell className={classes.tableCell}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    fields.map(member => (
                                        <TableRow key={member}>
                                            <TableCell>
                                                <Field
                                                    component={ReduxFormText}
                                                    name={`${member}.code`}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    component={ReduxFormText}
                                                    name={`${member}.description`}
                                                    validate={[required]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    component={isEditable ? TextField : ReduxFormText}
                                                    name={`${member}.value`}
                                                    validate={[required]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </React.Fragment>
            );
        }
        return <div />;
    }
}

CodeTable.propTypes = {
    fields: PropTypes.object.isRequired,
    isEditable: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CodeTable);
