import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ConsultationStatusDisplayComponent.css';
import { GLOBAL_PROPERTIES } from '../../constants/constants';
import ConsultationStatusDisplayComponent from './ConsultationStatusDisplayComponent';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const ConsultationStatusDisplayContainer = (props) => {
    const {
        encounterStatusBitHelper,
        row,
    } = props;
    console.log('asda09udas-0di-as0diasd', encounterStatusBitHelper);
    if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
        return Object.keys(encounterStatusBitHelper).map(anOrderType => (
            <ConsultationStatusDisplayComponent
                status={row[anOrderType]}
                label={anOrderType}
                key={anOrderType}
            />
        ));
    }
    return null;
};

ConsultationStatusDisplayContainer.propTypes = {
    row: PropTypes.object,
    encounterStatusBitHelper: PropTypes.object,
};

ConsultationStatusDisplayContainer.defaultProps = {
    row: null,
    encounterStatusBitHelper: null,
};
const mapStateToProps = state => ({
    encounterStatusBitHelper:
        getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP}`, state),
});

export default connect(mapStateToProps)(ConsultationStatusDisplayContainer);

