import initialStates from '../../initialStates';
import {
    ASSET_ADD_SUCCESS,
    ASSET_ADD_FAILURE,
    ASSET_LIST_FETCH_SUCCESS,
    ASSET_LIST_FETCH_FAILURE,
    ASSET_ADD_REQUEST,
    ASSET_VIEW_SUCCESS,
    ASSET_DELETE_REQUEST, ASSET_DELETE_SUCCESS, CLEAN_ASSET_INFO,
} from './asset-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

const assetReducer = (state = initialStates.asset, action) => {
    let newState = {};

    switch (action.type) {
    case CLEAN_ASSET_INFO:
        newState = Object.assign({}, state, { assetDetails: {} });
        break;
    case ASSET_ADD_REQUEST:
        newState = Object.assign({}, state, { isAdded: false });
        break;
    case ASSET_ADD_SUCCESS:
        newState = Object.assign({}, state, { isLoading: false, assetDetails: action.data, isAdded: true });
        break;
    case ASSET_ADD_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
        });
        break;
    case ASSET_LIST_FETCH_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.page.content', action, []),
            page: getStringFromObject('data.page.number', action, 0),
            isLast: getStringFromObject('data.isLast', action, false),
            totalElements: getStringFromObject('data.page.totalElements', action, 0),
            filters: getStringFromObject('filters', action, {}),
        });
        break;
    case ASSET_LIST_FETCH_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    case ASSET_VIEW_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            assetDetails: action.data,
        });
        break;
    case ASSET_DELETE_REQUEST:
        newState = Object.assign({}, state, { isDeleted: false });
        break;
    case ASSET_DELETE_SUCCESS:
        newState = Object.assign({}, state, { isDeleted: true });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default assetReducer;
