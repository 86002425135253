import { takeLatest } from 'redux-saga/effects';
import { commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';
import {
    CREATE_ADVANCE_PAYMENT_REQUEST,
    FETCH_ADVANCE_PAYMENT_REQUEST,
} from '../../redux/modules/advancePayment/advancePayment-actions';

export function* createAdvancePaymentWatcher() {
    yield takeLatest(CREATE_ADVANCE_PAYMENT_REQUEST, commonPostWorkerSaga);
}

export function* fetchAdvancePaymentWatcher() {
    yield takeLatest(FETCH_ADVANCE_PAYMENT_REQUEST, commonGetWorkerSaga);
}
