import { DATE_FILTERS } from '../../../components/Common/ReportDateFilterUtil';
import api from '../../../constants/api';

export const PURCHASE_ORDER_STATES = [
    { key: 'draft', value: 'Draft' },
    { key: 'confirmed', value: 'Confirmed' },
    { key: 'done', value: 'Completed' },
];

export const purchaseOrderFilters = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'purchase_order.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: false,
            formatDate: true,
            maxDateField: 'purchase_order.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'purchase_order.dateOrder',
                filterOperation: '>=',
            },
        },
        {
            name: 'purchase_order.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            formatDate: true,
            additionalFieldToClear: ['purchase_order.startDate'],
            minDateField: 'purchase_order.startDate',
            defaultValueField: 'purchase_order.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'purchase_order.dateOrder',
                filterOperation: '<=',
            },
        },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'purchase_order.state',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: false,
            multiple: true,
            options: PURCHASE_ORDER_STATES,
            searchConstructFields: {
                name: 'purchase_order.state',
                filterOperation: '=',
            },
        },
        {
            name: 'res_partner.id',
            type: 'autocomplete',
            label: 'Supplier',
            valueField: 'id',
            disableClear: false,
            dataSourceConfig: {
                text: 'name',
                value: 'id',
            },
            dataSourceApi: api.SEARCH.SUPPLIER,
            searchConstructFields: {
                name: 'res_partner.id',
                filterOperation: '=',
            },
        },
        {
            name: 'stock_location.id',
            type: 'autocomplete',
            label: 'Shipped To',
            valueField: 'id',
            disableClear: false,
            dataSourceConfig: {
                text: 'name',
                value: 'id',
            },
            dataSourceApi: api.SEARCH.STOCK_LOCATION_WITH_REPLENISHMENT('PURCHASE_ORDER'),
            searchConstructFields: {
                name: 'stock_location.id',
                filterOperation: '=',
            },
        },
    ]);
};

export const stockRequisitionStrategies = {
    MIN_MAX: 'MIN MAX',
    FORECASTING: 'FORECASTING',
};

export const productTypes = {
    consumable: 'Non Drugs',
    product: 'Drugs',
};

export const jobTypes = {
    PURCHASE_REQUISITION_JOB: 'PURCHASE_REQUISITION_JOB',
    INTERNAL_MOVE_JOB: 'INTERNAL_MOVE_JOB',
};
