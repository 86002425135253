import initialStates from '../../initialStates';
import { CLEAR_SELECTED_CONSOLIDATED_CASH_CLOSE, CREATE_CONSOLIDATED_CASH_CLOSE_SUCCESS, FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS } from './consolidatedCashClose-actions';

const consolidatedCashCloseReducer = (state = initialStates.consolidatedCashClose, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS:
    case CREATE_CONSOLIDATED_CASH_CLOSE_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_SELECTED_CONSOLIDATED_CASH_CLOSE:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default consolidatedCashCloseReducer;
