import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_ATTENDANCE_LIST_ACTION_FAILURE,
    FETCH_ATTENDANCE_LIST_ACTION_SUCCESS,
    FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE,
    FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS,
    FETCH_PERIODS_FAILURE,
    FETCH_PERIODS_SUCCESS,
    SAVE_DUTY_ACTION_FAILURE,
    SAVE_DUTY_ACTION_SUCCESS,
    UPDATE_APPROVED_ATTENDANCE_FAILURE,
    UPDATE_APPROVED_ATTENDANCE_SUCCESS,
    REVERT_APPROVED_ATTENDANCE_FAILURE,
    REVERT_APPROVED_ATTENDANCE_SUCCESS,
    UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS,
    UPDATE_EMPLOYEE_ATTENDANCE_FAILURE,
} from '../../redux/modules/attendance/attendance-actions';
import { UPDATE_DUTY_TIME } from '../../redux/modules/profile/profile-actions';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import generateAttendanceConstruct from './attendanceConstructUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { filterNullPunches } from '../../components/Attendance/AttendanceEmployeeListV2/AttendanceListUtilsV2';

export function* saveDutyWorkerSaga(action) {
    try {
        const apiWithQueryParam = `${action.api}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({
            type: SAVE_DUTY_ACTION_SUCCESS,
            data: response.data,
        });
        yield put({
            type: UPDATE_DUTY_TIME,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: SUCCESS_MESSAGE, message: action.message });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: SAVE_DUTY_ACTION_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* updateAttendanceWorkerSaga(action) {
    const status = getStringFromObject('payload.status', action);
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: UPDATE_APPROVED_ATTENDANCE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        let message = messages.ATTENDANCE.SAVE.SUCCESS;
        if (status === 'APPROVED') {
            message = messages.ATTENDANCE.APPROVE.SUCCESS;
        }
        yield put({ type: SUCCESS_MESSAGE, message });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        let message = messages.ATTENDANCE.SAVE.FAILED;
        if (status === 'APPROVED') {
            message = messages.ATTENDANCE.APPROVE.FAILED;
        }
        const error = getErrorMessage(e);
        yield put({ type: ERROR_MESSAGE, message: `${message} : ${error}` });
        yield put({ type: UPDATE_APPROVED_ATTENDANCE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* revertApprovedAttendanceWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithParams = `${action.api}?uuid=${action.uuid}`;
        const response = yield call(retriable().post, apiWithParams);
        yield put({
            type: REVERT_APPROVED_ATTENDANCE_SUCCESS,
            data: response.data,
        });
        yield put({ type: SUCCESS_MESSAGE, message: messages.ATTENDANCE.REVERT_APPROVED.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        const error = getErrorMessage(e);
        yield put({ type: ERROR_MESSAGE, message: `${messages.ATTENDANCE.REVERT_APPROVED.FAILED} : ${error}` });
        yield put({ type: REVERT_APPROVED_ATTENDANCE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchAttendanceListWorkerSaga(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const payload = generateAttendanceConstruct(action.filters);
        const response = yield call(retriable().post, action.api, payload, config);
        yield put({
            type: FETCH_ATTENDANCE_LIST_ACTION_SUCCESS,
            data: getStringFromObject('data.page', response, {}),
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: FETCH_ATTENDANCE_LIST_ACTION_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* updateEmployeeAttendanceWorkerSaga(action) {
    const status = getStringFromObject('payload.status', action);
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const payload = filterNullPunches(action.payload);
        const response = yield call(retriable().post, action.api, payload);
        yield put({
            type: UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        let message = messages.ATTENDANCE.SAVE.SUCCESS;
        if (status === 'APPROVED') {
            message = messages.ATTENDANCE.APPROVE.SUCCESS;
        }
        yield put({ type: SUCCESS_MESSAGE, message });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        let message = messages.ATTENDANCE.SAVE.FAILED;
        if (status === 'APPROVED') {
            message = messages.ATTENDANCE.APPROVE.FAILED;
        }
        const error = getErrorMessage(e);
        yield put({ type: ERROR_MESSAGE, message: `${message} : ${error}` });
        yield put({ type: UPDATE_EMPLOYEE_ATTENDANCE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchByEmployeeAndPeriodWorkerSaga(action) {
    try {
        const {
            employeeUuid,
            periodUuid,
            api,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithParams = `${api}?employeeUuid=${employeeUuid}&periodUuid=${periodUuid}`;
        const response = yield call(retriable().get, apiWithParams);
        yield put({
            type: FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS,
            data: { ...(response.data || {}) },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        const msg = 'Approved attendance fetch failed';
        yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        yield put({ type: FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPeriodsWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_PERIODS_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        const error = getErrorMessage(e);
        yield put({ type: ERROR_MESSAGE, message: `${messages.ATTENDANCE.PERIOD_FETCH_FAILED} : ${error}` });
        yield put({ type: FETCH_PERIODS_FAILURE, data: [] });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
