/**
 * Created by sachinprakash on 19/8/19.
 */
import API from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';
import { schemeListSearchConstruct } from '../../../components/PayerComponents/PayerList/PayerListUtil';

export const FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST = '@@payors/FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST';
export const FETCH_INSURANCE_SCHEME_VIEW_LIST_FAILURE = '@@payors/FETCH_INSURANCE_SCHEME_VIEW_LIST_FAILURE';
export const FETCH_INSURANCE_SCHEME_VIEW_LIST_SUCCESS = '@@payors/FETCH_INSURANCE_SCHEME_VIEW_LIST_SUCCESS';

export const fetchPagesOfInsuranceSchemeViews = (page, size, sortCol, sortOrder, filters = {}) => ({
    type: FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST,
    api: getUrlWithApiParams(API.PAYORS.FETCH_INSURANCE_SCHEME_VIEW, {
        page,
        size,
        sortCol,
        sortOrder,
    }),
    payload: schemeListSearchConstruct(filters),
    filters,
    page,
    size,
    sortCol,
    sortOrder,
    successAction: FETCH_INSURANCE_SCHEME_VIEW_LIST_SUCCESS,
    failureMessage: 'Failed to fetch insurance schemes',
    failureAction: FETCH_INSURANCE_SCHEME_VIEW_LIST_FAILURE,
});
