import cloneDeep from 'clone-deep';
import axios from 'axios';
import {
    apiCatchBlockFunction,
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    isValidObject,

} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const EMPTY_ROW = {
    product: '',
    uom: '',
    quantityOnHand: '',
    quantity: '',
    changeInQuantity: '',
    description: '',
    cost: '',
    salePrice: '',
    mrp: '',
};

const getChangeInQuantity = (rowValue) => {
    const quantityInHand = NumberOf(rowValue.quantityOnHand);
    const quantity = NumberOf(rowValue.quantity);
    const diff = quantity - quantityInHand;
    if (diff > 0) {
        return `+${diff}`;
    }
    return diff;
};

export const updateRowValsWIthProductInfo = (rowValue, actionParams) => {
    const newRowValue = cloneDeep(rowValue);
    const product = getStringFromObject('product', newRowValue, null);
    const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
    const salePrice = newRowValue.rate == null ? NumberOf(product.price) : NumberOf(newRowValue.salePrice);
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, false);
    newRowValue.uom = uom;
    newRowValue.paramMap = { productUomCategroyId: getStringFromObject('erpUomDto.category.key', product) };
    newRowValue.salePrice = salePrice;
    newRowValue.cost = salePrice;
    newRowValue.mrp = salePrice;
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, null);
    // newRowValue.amount = amount.toFixed(2);
    const locationUuid = getStringFromObject('sourceLoc.uuid', actionParams, null);
    const productUuid = getStringFromObject('product.uuid', newRowValue, null);
    return {
        ...newRowValue,
        locationUuid,
        productUuid,
    };
};

export const setBatchDetails = (response, newRowValue, rowValue) => {
    const newRowValueCopy = cloneDeep(newRowValue);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    newRowValueCopy.isInternalLocation = getStringFromObject('data.locationType', response, '') === 'internal';
    newRowValueCopy.batchWithQty = [];
    newRowValueCopy.allBatches = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        batchDetails.map((batch) => {
            if (!getStringFromObject('batch.key', batch, false)) {
                newRowValueCopy.quantityOnHand = getStringFromObject('quantity', batch, 0);
            } else {
                newRowValueCopy.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                            (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
                newRowValueCopy.allBatches.push({ ...batch });
            }
            return null;
        });
    }
    newRowValueCopy.changeInQuantity = getChangeInQuantity(rowValue);
    return newRowValueCopy;
};

const setLine = (rowValue, index, form, actionParams) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.products.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        setFieldValue(`products.${valuesLength}`, { ...EMPTY_ROW });
    }
    const dispatch = getStringFromObject('dispatch', form, () => {});
    console.log('asdfsdfsdfdsfdsf', rowValue);
    if (isObjectValidAndNotEmpty(rowValue)) {
        let newRowValue = updateRowValsWIthProductInfo(rowValue, actionParams);
        const {
            locationUuid,
            productUuid,
        } = newRowValue;
        const allowProductsWithoutBatch = true;
        if (locationUuid) {
            axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                params: {
                    productUuid,
                    locationUuid,
                    allowProductsWithoutBatch,
                },
            }).then((response) => {
                newRowValue = setBatchDetails(response, newRowValue, rowValue);
                setFieldValue(`products[${index}]`, newRowValue);
            }).catch(error => apiCatchBlockFunction(error, dispatch));
        }
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, fieldPath, form, actionParams) => {
    if (isObjectValidAndNotEmpty(product) && fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.taxes = product.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.description = null;
            rowValue.quantityOnHand = NumberOf(rowValue.quantityOnHand);
            rowValue.quantity = Math.max(NumberOf(rowValue.quantity, 1));
            rowValue.isInternalLocation = false;
            setLine(rowValue, index, form, actionParams);
        }
    }
};

const quantityChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isValidObject(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (!isValidFunction(setFieldValue)) {
            return;
        }
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = value;
                rowValue.changeInQuantity = getChangeInQuantity(rowValue);
                setFieldValue(`products[${index}]`, rowValue);
            }
        }
    }
};

const rowChangeHandler = (product, fieldPath, form) => {
    if (fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            const setFieldValue = getStringFromObject('setFieldValue', form);
            if (isValidFunction(setFieldValue)) {
                setFieldValue(`products[${index}].operation`, operation);
            }
        }
    }
};

const deleteRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.products', form, []));
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(setFieldValue) &&
        isArrayValidAndNotEmpty(values)) {
        selectedRows.forEach((idx) => {
            values.splice(idx, 1);
            if (values.length === 0) {
                values.push(EMPTY_ROW);
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
        setFieldValue('products', values);
    }
};

export const setValuesOnBatchSelect = (oldRowValue, value) => {
    const rowValue = cloneDeep(oldRowValue);
    rowValue.quantityOnHand = getStringFromObject('quantity', value, 0);
    rowValue.changeInQuantity = getChangeInQuantity(rowValue);
    rowValue.description = value;
    const expiryDateTimeInMillis = getStringFromObject('expiryDate', value);
    if (expiryDateTimeInMillis) {
        rowValue.expiryDate = getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis));
    }
    return rowValue;
};

const handleBatchSelect = (value, name, form) => {
    console.log('Params', value, form);
    if (name && isValidObject(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (!isValidFunction(setFieldValue)) {
            return;
        }
        const index = getIndexFromFieldName(name);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                setFieldValue(
                    `products.${index}`,
                    setValuesOnBatchSelect(rowValue, value),
                );
            }
        }
    }
};

export const ACTION_HANDLERS = {
    handleProductSelect,
    quantityChangeHandler,
    rowChangeHandler,
    deleteRowHandler,
    handleBatchSelect,
};

export default ACTION_HANDLERS;
