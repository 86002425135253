import initialStates from '../../initialStates';
import {
    FETCH_CALENDAR_WORK_HOURS_SUCCESS,
    FETCH_CURRENT_USER_PROFILE_FAILURE,
    FETCH_CURRENT_USER_PROFILE_REQUEST,
    FETCH_CURRENT_USER_PROFILE_SUCCESS, FETCH_OTHER_USER_DATA_SUCCESS, UPDATE_DUTY_TIME, UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPLOAD_PROFILE_PHOTO_SUCCESS,
} from './profile-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

const profileReducer = (state = initialStates.profile, action) => {
    let newState = {};

    switch (action.type) {
    case FETCH_CURRENT_USER_PROFILE_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_CURRENT_USER_PROFILE_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            currentUserData: action.data,
        });
        break;
    case FETCH_CURRENT_USER_PROFILE_FAILURE:
        newState = Object.assign({}, state, { isLoading: false, data: action.data });
        break;
    case UPDATE_PROFILE_REQUEST:
        newState = Object.assign({}, state, { isLoading: true, isUpdated: false });
        break;
    case UPDATE_PROFILE_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            isUpdated: true,
        });
        if (action.isCurrentEmployee) {
            newState.currentUserData = getStringFromObject('data.hrEmployeeDto', action, {});
        } else {
            newState.otherUserData = getStringFromObject('data.hrEmployeeDto', action, {});
        }
        break;
    case UPDATE_DUTY_TIME:
        newState = Object.assign({}, state, {
            currentUserData: {
                ...state.currentUserData,
                dutyAction: action.data.action,
                lastDutyTime: action.data.name,
            },
        });
        break;
    case UPDATE_PROFILE_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false, currentUserData: action.data, isUpdated: false,
        });
        break;
    case FETCH_OTHER_USER_DATA_SUCCESS:
        newState = Object.assign({}, state, {
            otherUserData: action.data,
        });
        break;
    case UPLOAD_PROFILE_PHOTO_SUCCESS:
        newState = Object.assign({}, state, { profilePictureUploaded: true });
        break;
    case FETCH_CALENDAR_WORK_HOURS_SUCCESS:
        newState = Object.assign({}, state, { calendarDetails: action.data });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default profileReducer;
