import { takeLatest } from 'redux-saga/effects';
import {
    BUNDLE_PAYER_LIST_REQUEST,
    DOWNLOAD_BUNDLE_REQUEST,
    DOWNLOAD_CLAIM_REQUEST,
    DOWNLOAD_TASK_STATUS_REQUEST,
    FETCH_CLAIMS_OR_BUNDLES_REQUEST,
    SAVE_CLAIMS_AS_BUNDLE_REQUEST,
} from '../../redux/modules/claimsAndBundles/claimsAndBundles-actions';
import { createClaimBundles, fetchClaimListWorker } from './claimsWorkerSaga';
import { commonGetWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* fetchClaimsListWatcher() {
    yield takeLatest(FETCH_CLAIMS_OR_BUNDLES_REQUEST, fetchClaimListWorker);
}

export function* saveClaimsBundleWatcher() {
    yield takeLatest(SAVE_CLAIMS_AS_BUNDLE_REQUEST, createClaimBundles);
}

export function* downloadClaimsBundleWatcher() {
    yield takeLatest(DOWNLOAD_BUNDLE_REQUEST, commonGetWorkerSaga);
}

export function* downloadClaimsWatcher() {
    yield takeLatest(DOWNLOAD_CLAIM_REQUEST, commonGetWorkerSaga);
}

export function* fetchBundlePayersWatcher() {
    yield takeLatest(BUNDLE_PAYER_LIST_REQUEST, commonGetWorkerSaga);
}

export function* getDownloadTaskStatusRequest() {
    yield takeLatest(DOWNLOAD_TASK_STATUS_REQUEST, commonGetWorkerSaga);
}
