/**
 * Created by sachinprakash on 8/7/19.
 */
import React from 'react';
import { Card, CardContent, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionButton from '../../ActionButton/ActionButton';
import {
    isValidFunction,
} from '../../../constants/CommonUtil';
// import FormikTextField from './FormikTextField';
import FormikReactSelectMaterial from './FormikReactSelectMaterial';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    disableText: {
        color: '#000000',
    },
});


class FormikMappingComponent extends React.Component {
    handleChange = (event) => {
        const {
            form,
        } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        alert(event.name.value);
        if (isValidFunction(setFieldValue)) {
            setFieldValue(`referenceTermMap.referenceTerms.${0}referenceTerm`, event.target.value);
        }
    };

    render() {
        const {
            form: {
                values: {
                    referenceTermMap,
                },
            },
            classes,
            push,
            remove,
        } = this.props;
        console.log('rupam test', referenceTermMap, this.props);
        return (
            <Card style={{ display: 'block', overflow: 'visible' }}>
                <CardContent>
                    <Grid container spacing={16} justify="flex-start">
                        <Grid item md={4} sm={4} lg={4} className="mt-1">
                        Mappings
                        </Grid>
                        <Grid />
                        <Grid container spacing={16} justify="flex-start">
                            <Grid item sm={12} md={12} lg={12}>
                                {
                                    isObjectValidAndNotEmpty(referenceTermMap) && isObjectValidAndNotEmpty(referenceTermMap.referenceTerms) && referenceTermMap.referenceTerms.map((a, index) => {
                                        console.log('kasjdljkasl', a);
                                        return (
                                            <Grid container key={a} justify="flex-start" className="mt-1">
                                                <Grid item sm={3} md={3} lg={3} style={{ marginRight: '1px' }}>
                                                    <Field
                                                        className={classes.textField}
                                                        component={FormikReactSelectMaterial}
                                                        fieldType="selectField"
                                                        dataSourceApi="/nuacare-core/rest/nuacare/v1/concept/searchConceptReferenceMapTypes?searchString="
                                                        disableUnderline
                                                        dataSourceConfig={{
                                                            text: 'value',
                                                            value: 'value',
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                        name={`referenceTermMap.referenceTerms.${index}.mapType`}
                                                        label="Map Type"
                                                    />
                                                </Grid>
                                                <Grid item sm={3} md={3} lg={3} style={{ marginRight: '1px' }}>
                                                    <Field
                                                        className={classes.textField}
                                                        component={FormikReactSelectMaterial}
                                                        disableUnderline
                                                        variant="outlined"
                                                        fieldType="selectField"
                                                        dataSourceApi="/nuacare-core/rest/nuacare/v1/concept/allConceptSources"
                                                        fullWidth
                                                        dataSourceConfig={{
                                                            text: 'value',
                                                            value: 'value',
                                                        }}
                                                        // component={OutlinedTextField}
                                                        name={`referenceTermMap.referenceTerms.${index}.referenceSource`}
                                                        // value={value}
                                                        label="Source"
                                                    />
                                                </Grid>
                                                <Grid item sm={2} md={2} lg={2} style={{ marginRight: '1px' }}>
                                                    <Field
                                                        className={classes.textField}
                                                        component={FormikReactSelectMaterial}
                                                        disableUnderline
                                                        variant="outlined"
                                                        fieldType="selectField"
                                                        dataSourceApi="/nuacare-core/rest/nuacare/v1/concept/searchReferenceTermsByCode?source=&searchString="
                                                        fullWidth
                                                        dataSourceConfig={{
                                                            text: 'displayString',
                                                            value: 'key',
                                                        }}
                                                        name={`referenceTermMap.referenceTerms.${index}.referenceTermCode`}
                                                        // value={value}
                                                        label="Code"
                                                    />
                                                </Grid>

                                                <Grid item sm={3} md={3} lg={3}>
                                                    <Field
                                                        className={classes.textField}
                                                        component={FormikReactSelectMaterial}
                                                        disableUnderline
                                                        variant="outlined"
                                                        fullWidth
                                                        dataSourceConfig={{
                                                            text: 'displayString',
                                                            value: 'key',
                                                        }}
                                                        // component={OutlinedTextField}
                                                        name={`referenceTermMap.referenceTerms.${index}.referenceTermCode`}
                                                        label="Name"
                                                    />
                                                </Grid>

                                                <Grid item lg={0.5} sm={0.5} md={0.5} style={{ marginRight: '0px' }}>
                                                    <Close onClick={() => remove(index)} />
                                                </Grid>
                                            </Grid>

                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" >
                        <Grid item sm={4} md={4} lg={4} />
                        <Grid item sm={2} md={2} lg={2}>
                            <ActionButton className="mt-1" onClick={() => push({})}>
                            Add Reference Term
                            </ActionButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

FormikMappingComponent.propTypes = {
    classes: PropTypes.object,
    // name: PropTypes.string.isRequired,
    /* formik props */
    push: PropTypes.func.isRequired,
    // setFieldValue: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    // field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

FormikMappingComponent.defaultProps = {
    classes: {},
};

export default connect()(withStyles(styles)(FormikMappingComponent));
