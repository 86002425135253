import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OrganizationListView from '../../components/OrganisationComponent/OrganisationListview';
import { profile } from '../../constants/constants';
import { fetchOrganizationList } from '../../redux/modules/organization/organization-actions';
import { getDateInDDMMMFormat } from '../../constants/DateUtil';
import { getNameOfEmployee, getProfileImageUrl } from '../../constants/CommonUtil';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { organisationAdmin } from '../../constants/privilegeConstants';
import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

class OrganizationListContainer extends React.Component {
    componentDidMount() {
        const { dispatch, organization } = this.props;
        dispatch(fetchOrganizationList({
            searchConstruct: null,
            searchText: organization.searchText,
            departmentSelectText: organization.departmentSelectText,
            positionSelectText: organization.positionSelectText,
            empType: organization.empType,
            showDeactivated: organization.showDeactivated,
        }, organization.page, organization.size));
    }

    handleProfileClick = (uuid) => {
        const { history } = this.props;
        if (checkIfPrivilegeExistsForUser(organisationAdmin)) {
            history.push(`${profile}/${uuid}`);
        }
    };

    refactorDataToShow = data => (
        data.map((t) => {
            let profileImage = getProfileImageUrl({
                birthDate: getStringFromObject('birthday', t, new Date().getTime()),
                gender: getStringFromObject('gender', t, ''),
            });
            if (t.imageName) {
                profileImage = `${API.FILE.DOWNLOAD}${t.imageName}`;
            }
            return {
                name: getNameOfEmployee(t),
                designation: t.position ? t.position.value : '-',
                reportingManager: t.reportingTo ? t.reportingTo.value : '-',
                department: t.department ? t.department.value : '-',
                userName: t.userName,
                phoneNo: t.landlineNumber,
                email: t.officialEmail,
                birthday: getDateInDDMMMFormat(new Date(t.birthday)),
                empId: t.employeeId,
                uuid: t.uuid,
                extension: t.extension,
                retired: t.retired,
                profileImage,
            };
        }));

    render() {
        const { organization } = this.props;
        const employeeList = this.refactorDataToShow(organization.list);
        return (
            <div>
                <OrganizationListView
                    handleProfileClick={this.handleProfileClick}
                    employeeList={employeeList}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    organization: state.organization,
});

OrganizationListContainer.propTypes = {
    history: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(OrganizationListContainer);
