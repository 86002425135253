import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import { leaveBasePage, leaves, leavesBalances } from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { leavePrivilege, organisationAdmin } from '../../constants/privilegeConstants';
import { getStringFromObject } from '../../constants/lodashUtils';

const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

class LeavesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.changeTabForRoute(props),
        };
    }

    changeRouteForSelectedTab = (selectedTab = this.state.selectedTab) => {
        this.props.history.push(selectedTab);
    };

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        if (route === leaveBasePage) {
            return leaves;
        }
        return route;
    };

    handleChange = (event, selectedTab) => {
        event.preventDefault();
        this.setState({
            selectedTab,
        }, this.changeRouteForSelectedTab);
    };
    render() {
        const { classes, children } = this.props;
        const {
            selectedTab,
        } = this.state;
        console.log('dasdfafdasfdaf', this.props);
        return (
            <React.Fragment>
                <div style={{ background: '#fff', padding: '2rem' }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item lg={8} md={8} sm={8}>
                            <AppBar position="static" className={classes.appBar}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    fullWidth
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    {
                                        checkIfPrivilegeExistsForUser(leavePrivilege) &&
                                        <Tab
                                            value={leaves}
                                            label="Leaves"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(organisationAdmin) &&
                                        <Tab
                                            value={leavesBalances}
                                            label="Leave Balances"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

LeavesContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node,
};

LeavesContainer.defaultProps = {
    children: null,
};

export default withStyles(style)(LeavesContainer);
