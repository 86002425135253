import { takeLatest } from 'redux-saga/effects';
import {
    DELETE_PROVIDER_FROM_SERVICE_REQUEST,
    DEPARTMENT_CREATE_REQUEST,
    DEPARTMENT_LIST_FETCH_REQUEST,
    DEPARTMENT_UPDATE_REQUEST,
    SERVICE_CREATE_REQUEST,
    SERVICE_LIST_FETCH_REQUEST,
    SERVICE_UPDATE_REQUEST,
} from '../../redux/modules/departmentService/department-service-actions';
import {
    createOrUpdateServiceWorker,
    fetchDepartmentsWorker,
    fetchServicesWorker,
    updateDepartmentWorker,
} from './departmentWorkerSaga';
import { commonGetWorkerSaga } from '../commonSaga/commonWorkerSaga';


export function* fetchDepartmentsWatcher() {
    yield takeLatest(DEPARTMENT_LIST_FETCH_REQUEST, fetchDepartmentsWorker);
}

export function* updateDepartmentWatcher() {
    yield takeLatest(DEPARTMENT_UPDATE_REQUEST, updateDepartmentWorker);
}

export function* createDepartmentWatcher() {
    yield takeLatest(DEPARTMENT_CREATE_REQUEST, updateDepartmentWorker);
}

export function* fetchServicesWatcher() {
    yield takeLatest(SERVICE_LIST_FETCH_REQUEST, fetchServicesWorker);
}

export function* createServicesWatcher() {
    yield takeLatest(SERVICE_CREATE_REQUEST, createOrUpdateServiceWorker);
}

export function* updateServicesWatcher() {
    yield takeLatest(SERVICE_UPDATE_REQUEST, createOrUpdateServiceWorker);
}

export function* deleteProviderFromServiceWatcher() {
    yield takeLatest(DELETE_PROVIDER_FROM_SERVICE_REQUEST, commonGetWorkerSaga);
}
