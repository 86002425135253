import API from '../../../constants/api';

export const FETCH_PRODUCT_DISPATCH = '@@productDispatch/FETCH_PRODUCT_DISPATCH';
export const FETCH_PRODUCT_DISPATCH_SUCCESS = '@@productDispatch/FETCH_PRODUCT_DISPATCH_SUCCESS';
export const FETCH_PRODUCT_DISPATCH_FAILURE = '@@productDispatch/FETCH_PRODUCT_DISPATCH_FAILURE';

export const fetchProductDispatch = (page, size, searchConstruct) => ({
    type: FETCH_PRODUCT_DISPATCH,
    api: API.PRODUCT_DISPATCH.LIST,
    page,
    size,
    searchConstruct,
});
