import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const COMBINEMODE = {
    AND: 'AND',
    OR: 'OR',
};


export const generateObjectWithCombineMode = (left, mode, right) => ({
    type: 'combineMode',
    left,
    right,
    mode,
});

export const getCombined = (left, mode, right) => {
    if (!isObjectValidAndNotEmpty(left)) {
        return right;
    }
    if (!isObjectValidAndNotEmpty(right)) {
        return left;
    }
    if (!isObjectValidAndNotEmpty(left) && !isObjectValidAndNotEmpty(right)) {
        return null;
    }
    return generateObjectWithCombineMode(left, mode, right);
};

export const generateObjectWithCriteriaMode = (key, value, operation) => ({
    type: 'criteria',
    key,
    value,
    operation,
});

const firstNameAttributeName = 'PersonName.givenName';
const middleNameAttributeName = 'PersonName.middleName';
const lastNameAttributeName = 'PersonName.familyName';

export const firstNameAttributeNameForPatientSpec = 'personNames.givenName';
export const middleNameAttributeNameForPatientSpec = 'personNames.middleName';
export const lastNameAttributeNameForPatientSpec = 'personNames.familyName';

export const getPosition = (string, subString, index) => {
    if (string) {
        return string.split(subString, index).join(subString).length;
    }
    return '';
};

const createConstructsForAllNames = (namesToSearch, searchText, searchConstruct) => {
    let searchConstructCopy = { ...searchConstruct };
    namesToSearch.map((aNameSearchAttribute) => {
        const searchCriteria =
            generateObjectWithCriteriaMode(aNameSearchAttribute, searchText, ':');
        searchConstructCopy = getCombined(searchConstructCopy, 'OR', searchCriteria);
        return null;
    });
    return searchConstructCopy;
};

const createSearchConstructForTwoNameAttributes = (
    namesToSearch,
    restOfTheWords,
    firstWord,
    searchConstruct,
    firstNameAttr = firstNameAttributeName,
    lastNameAttr = lastNameAttributeName,
    middleNameAttr = middleNameAttributeName,
) => {
    let searchConstructCopy = { ...searchConstruct };
    if (
        namesToSearch.includes(firstNameAttr) &&
        namesToSearch.includes(middleNameAttr)
    ) {
        let searchConstructForFirstNameAndMiddleName = {};
        const firstNameSearchCriteria =
            generateObjectWithCriteriaMode(firstNameAttr, firstWord, ':');
        searchConstructForFirstNameAndMiddleName =
            getCombined(searchConstructForFirstNameAndMiddleName, 'AND', firstNameSearchCriteria);
        const middleNameSearchCriteria =
            generateObjectWithCriteriaMode(middleNameAttr, restOfTheWords, ':');
        searchConstructForFirstNameAndMiddleName =
            getCombined(searchConstructForFirstNameAndMiddleName, 'AND', middleNameSearchCriteria);

        searchConstructCopy = getCombined(searchConstructCopy, 'OR', searchConstructForFirstNameAndMiddleName);
    }
    if (namesToSearch.includes(firstNameAttr) && namesToSearch.includes(lastNameAttr)) {
        let searchConstructForFirstNameAndLastName = {};
        const firstNameSearchCriteria =
            generateObjectWithCriteriaMode(firstNameAttr, firstWord, ':');
        searchConstructForFirstNameAndLastName =
            getCombined(searchConstructForFirstNameAndLastName, 'AND', firstNameSearchCriteria);
        const middleNameSearchCriteria =
            generateObjectWithCriteriaMode(lastNameAttr, restOfTheWords, ':');
        searchConstructForFirstNameAndLastName =
            getCombined(searchConstructForFirstNameAndLastName, 'AND', middleNameSearchCriteria);

        searchConstructCopy = getCombined(searchConstructCopy, 'OR', searchConstructForFirstNameAndLastName);
    }
    return searchConstructCopy;
};

const createSearchConstructForAllThreeNameAttributesCombined = (
    namesToSearch,
    firstWord,
    searchConstruct,
    secondWord,
    restOfTheWords,
    firstNameAttr = firstNameAttributeName,
    lastNameAttr = lastNameAttributeName,
    middleNameAttr = middleNameAttributeName,
) => {
    let searchConstructCopy = { ...searchConstruct };
    if (namesToSearch.includes(firstNameAttr)) {
        const searchCriteria =
            generateObjectWithCriteriaMode(firstNameAttr, firstWord, ':');
        searchConstructCopy = getCombined(searchConstructCopy, 'AND', searchCriteria);
    }
    if (namesToSearch.includes(middleNameAttr)) {
        const searchCriteria =
            generateObjectWithCriteriaMode(middleNameAttr, secondWord, ':');
        searchConstructCopy = getCombined(searchConstructCopy, 'AND', searchCriteria);
    }
    if (namesToSearch.includes(lastNameAttr)) {
        const searchCriteria =
            generateObjectWithCriteriaMode(lastNameAttr, restOfTheWords, ':');
        searchConstructCopy = getCombined(searchConstructCopy, 'AND', searchCriteria);
    }
    return searchConstructCopy;
};

export const generateNameSearchConstruct = (namesToSearch, searchText, patientAttr = {
    firstName: firstNameAttributeName,
    lastName: lastNameAttributeName,
    middleName: middleNameAttributeName,
}) => {
    let searchConstruct = {};
    const wordsInSearchText = searchText.split(' ');
    console.log('98aonjkdasd', namesToSearch, searchText);
    console.log('98aonjkdasd123213213213', wordsInSearchText, searchConstruct);
    console.log('234887878gfgg3213', getPosition(searchText, ' ', 1));
    console.log('234887878gfgg3213', getPosition(searchText, ' ', 2));

    // only first name to be searched
    // then search the entire string for that particular attribute
    if (namesToSearch.length === 1) {
        searchConstruct = createConstructsForAllNames(namesToSearch, searchText, searchConstruct);
    } else if (namesToSearch.length === 2) {
        const indexOfFirstSpace = getPosition(searchText, ' ', 1);
        const firstWord = (searchText.substring(0, indexOfFirstSpace) || '').trim();
        const restOfTheWords = (searchText.substring(indexOfFirstSpace + 1, searchText.length) || '').trim();

        // i.e only one word
        if (!restOfTheWords) {
            console.log('12309jpmioasdl', restOfTheWords, namesToSearch);
            searchConstruct = createConstructsForAllNames(namesToSearch, firstWord, searchConstruct);
        } else {
            searchConstruct =
                createSearchConstructForTwoNameAttributes(namesToSearch, restOfTheWords, firstWord, searchConstruct,
                    patientAttr.firstName, patientAttr.lastName, patientAttr.middleName);
        }
    } else {
        const indexOfFirstSpace = getPosition(searchText, ' ', 1);
        const indexOfSecondSpace = getPosition(searchText, ' ', 2);
        const firstWord = (searchText.substring(0, indexOfFirstSpace) || '').trim();
        const secondWord = (searchText.substring(indexOfFirstSpace + 1, indexOfSecondSpace) || '').trim();
        const restOfTheWords = (searchText.substring(indexOfSecondSpace + 1, searchText.length) || '').trim();

        // this means only one word is there, hence we need to follow 1st if condition statement
        if (!secondWord) {
            searchConstruct = createConstructsForAllNames(namesToSearch, searchText, searchConstruct);
        } else if (!restOfTheWords) { // there are two words, hence 2nd block of code must be executed
            searchConstruct =
                createSearchConstructForTwoNameAttributes(namesToSearch, secondWord, firstWord, searchConstruct,
                    patientAttr.firstName, patientAttr.lastName, patientAttr.middleName);
        } else {
            searchConstruct =
                createSearchConstructForAllThreeNameAttributesCombined(
                    namesToSearch,
                    firstWord,
                    searchConstruct,
                    secondWord,
                    restOfTheWords,
                    patientAttr.firstName,
                    patientAttr.lastName,
                    patientAttr.middleName,
                );
        }
    }
    console.log('9opjkasmdjp9odaksdas', searchConstruct);
    return searchConstruct;
};

export const createSearchConstructForSearchAttributes = (
    searchAttributes,
    searchString,
    patientAttr = {
        firstName: firstNameAttributeName,
        lastName: lastNameAttributeName,
        middleName: middleNameAttributeName,
    },
) => {
    console.log('123sh0ionfklmasd', searchAttributes);
    let searchConstruct = {};
    const copyOfSearchAttributes = searchAttributes.concat();
    // const lastCriteriaToSearch = copyOfSearchAttributes.splice(copyOfSearchAttributes.length - 1, 1)[0];
    // const right = generateObjectWithCriteriaMode(lastCriteriaToSearch, searchString, ':');
    // searchConstruct = getCombined(searchConstruct, 'OR', right);
    let trimmedSearchString = searchString ? searchString.trim() : '';
    // if search string ends with @ search with exact match
    let matchCriteria = ':';
    if (trimmedSearchString.endsWith('@')) {
        trimmedSearchString = trimmedSearchString.slice(0, trimmedSearchString.length - 1);
        matchCriteria = '=';
    }
    let namesToSearch = [];
    copyOfSearchAttributes.map((aSearchAttribute) => {
        console.log('asdopl,2q131', aSearchAttribute);
        /*
            if  only one search string is sent,
            then we OR it for first name. middle name and last name

            if two words are searched,
            then we AND first name and last name OR first name and Middle name

            if 3 words
            then AND all the names
         */
        if (
            aSearchAttribute === patientAttr.firstName ||
            aSearchAttribute === patientAttr.middleName ||
            aSearchAttribute === patientAttr.lastName
        ) {
            // eslint-disable-next-line prefer-destructuring
            namesToSearch = namesToSearch.concat(aSearchAttribute);
            return null;
        }

        if (trimmedSearchString) {
            const searchCriteria =
                generateObjectWithCriteriaMode(aSearchAttribute, trimmedSearchString, matchCriteria);
            searchConstruct = getCombined(searchConstruct, 'OR', searchCriteria);
        }
        return null;
    });
    const searchNameConstruct = generateNameSearchConstruct(namesToSearch, trimmedSearchString, patientAttr);
    console.log('2143sadasdsada14354656', searchNameConstruct);
    searchConstruct = getCombined(searchConstruct, 'OR', searchNameConstruct);
    return searchConstruct;
};

