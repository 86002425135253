import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popper from '../../components/Popper';

import { getStringFromObject } from '../../constants/lodashUtils';


const style = () => ({
    popper: {
        zIndex: 1,
        width: '30em',
    },
});

class LeavePlanDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
        };
    }

    onClick = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
        });
    };

    render() {
        const {
            open,
            anchorEl,
        } = this.state;
        const {
            data,
            classes,
        } = this.props;
        console.log(data, open);
        console.log('asdajud-asd-s0d-d', data);
        const leavePlanValues = getStringFromObject('leavePlan.leavePlanValues', data) || [];
        return (
            <React.Fragment>
                <div
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    role="presentation"
                    onKeyPress={this.onClick}
                    onClick={this.onClick}
                    style={{
                        textDecoration: 'underline',
                        color: 'rgb(26, 160, 228)',
                        cursor: 'pointer',
                    }}
                >
                    {
                        getStringFromObject('leavePlan.leavePlanName', data)
                    }
                </div>
                <Popper
                    id="material-ui-popper"
                    handleClose={this.handleClose}
                    open={open}
                    anchorEl={anchorEl}
                    transition
                    classes={{
                        paper: classes.popper,
                    }}
                    placement="bottom"
                >
                    <Paper>
                        <div style={{ paddingBottom: '0.2rem', padding: '1rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    height: '2rem',
                                    alignItems: 'center',
                                    color: '#1aa0e4',
                                    fontSize: '13px',
                                    borderBottom: '1px solid #d7d7d7',
                                }}
                            >
                                <div style={{ width: '33.33%' }}>Leave Type</div>
                                <div style={{ width: '33.33%' }}>Accrual Type</div>
                                <div style={{ width: '33.33%' }}>Number</div>
                            </div>
                            {
                                leavePlanValues.map((d, index) => (
                                    <div
                                        key={d.year}
                                        style={{
                                            display: 'flex',
                                            height: '2rem',
                                            fontSize: '13px',
                                            alignItems: 'center',
                                            borderBottom: index !== data.length - 1 && '1px solid #d7d7d7',
                                        }}
                                    >
                                        <div style={{ width: '33.33%' }}>
                                            {getStringFromObject('leaveType.value', d)}
                                        </div>
                                        <div style={{ width: '33.33%' }}>
                                            {getStringFromObject('accrualType', d)}
                                        </div>
                                        <div style={{ width: '33.33%' }}>
                                            {d.numberOfValues}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Paper>
                </Popper>
            </React.Fragment>
        );
    }
}

LeavePlanDisplay.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
};

LeavePlanDisplay.defaultProps = {
    data: {},
    classes: {},
};

export default withStyles(style)(LeavePlanDisplay);

