import { FETCH_EFORM_CONFIG_SUCCESS } from './eformConfig-actions';

const eformConfigReducer = (state = {}, action) => {
    let newState;
    switch (action.type) {
    case FETCH_EFORM_CONFIG_SUCCESS:
        newState = action.appConfiguration;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default eformConfigReducer;
