/* eslint-disable jsx-a11y/click-events-have-key-events,no-useless-constructor */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import StartSelected from '@material-ui/icons/Star';
import StartNonSelected from '@material-ui/icons/StarBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import QuestionOutlined from '@material-ui/icons/ContactSupportOutlined';
import Clear from '@material-ui/icons/Clear';
import withStyles from '@material-ui/core/styles/withStyles';
import { isValidFunction } from '../../constants/CommonUtil';
import FavouriteReportTitlePopOver from '../../containers/ReportsContainer/FavouriteReportTitlePopOver';

const style = () => ({
    titleDiv: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '15px',
        '&:hover': {
            color: 'blue',
        },
    },
    reportChild: {
        margin: '0 1em',
        padding: '1.2rem 0.5rem',
        borderBottom: '2px solid #9e9e9e61',
    },
    questionIcon: {
        fontSize: '20px',
        color: '#2196f3c2',
        margin: '0 0 0 8px',
        cursor: 'pointer',
    },
    reportDesc: {
        margin: '0.5rem 0',
    },
});

class ReportChild extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showPopper: null,
        };
    }

    handleTitleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleFavouriteClick = (e) => {
        const {
            report,
            favourite,
            handleFavouriteClick,
        } = this.props;
        if (!favourite) {
            this.setState({ showPopper: e.target });
        } else if (isValidFunction(handleFavouriteClick)) {
            handleFavouriteClick(!favourite, report);
        }
    };

    handleEditClick = (e) => {
        this.setState({ showPopper: e.target });
    };

    addAsFavourite = ({ title, desc }) => {
        const {
            report,
            handleFavouriteClick,
        } = this.props;
        if (isValidFunction(handleFavouriteClick)) {
            handleFavouriteClick(true, {
                ...report,
                title,
                desc,
            });
            this.handleClosePopper();
        }
    };

    handleClosePopper = () => {
        this.setState({ showPopper: null });
    };

    handleDeleteClick = () => {
        const {
            report,
            handleDeleteClick,
        } = this.props;
        if (isValidFunction(handleDeleteClick)) {
            handleDeleteClick(report);
        }
    };

    render() {
        const {
            report,
            classes,
            handleReportClick,
            handleDelete,
            showDeleteButton,
            showFavourite,
            favourite,
            showDelete,
        } = this.props;
        const { open, showPopper } = this.state;
        return (
            <div
                className={classes.reportChild}
                style={{ background: open ? '#F1F1F1' : '#FFFFFF' }}
            >
                <div className="flexChildren align-center">
                    <div
                        className={classes.titleDiv}
                        onClick={() => handleReportClick(report)}
                    >
                        {report.title}
                    </div>
                    {
                        showDelete &&
                            <IconButton
                                test-id="edit-icon"
                                onClick={this.handleEditClick}
                            >
                                <EditIcon />
                            </IconButton>
                    }
                    {
                        showDelete &&
                            <IconButton
                                test-id="delete-icon"
                                onClick={this.handleDeleteClick}
                            >
                                <DeleteIcon />
                            </IconButton>
                    }
                    {
                        showFavourite &&
                        <React.Fragment>
                            <IconButton
                                test-id="icon-button"
                                onClick={this.handleFavouriteClick}
                            >
                                {
                                    favourite ?
                                        <StartSelected style={{ color: '#FF8C00' }} />
                                        :
                                        <StartNonSelected style={{ color: '#FF8C00' }} />
                                }
                            </IconButton>
                        </React.Fragment>
                    }
                    <QuestionOutlined
                        test-id="question-mark"
                        className={classes.questionIcon}
                        onClick={this.handleTitleClick}
                    />
                    {
                        showDeleteButton &&
                        isValidFunction(handleDelete) &&
                        <Clear className="ml-1 cursor-pointer" test-id="clear-document" onClick={() => handleDelete(report)} />
                    }
                </div>
                <Collapse in={open} >
                    <div className={classes.reportDesc}>
                        {report.desc}
                    </div>
                </Collapse>
                {
                    Boolean(showPopper) &&
                    <FavouriteReportTitlePopOver
                        onClickSubmit={this.addAsFavourite}
                        handleClose={this.handleClosePopper}
                        anchorEl={showPopper}
                        report={report}
                    />
                }
            </div>
        );
    }
}

ReportChild.propTypes = {
    report: PropTypes.object,
    classes: PropTypes.object.isRequired,
    handleReportClick: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    showDeleteButton: PropTypes.bool,
    favourite: PropTypes.bool,
    showFavourite: PropTypes.bool,
    showDelete: PropTypes.bool,
    handleFavouriteClick: PropTypes.func,
    handleDeleteClick: PropTypes.func,
};

ReportChild.defaultProps = {
    report: {
        title: '',
        desc: '',
    },
    handleDelete: null,
    showDeleteButton: false,
    showFavourite: true,
    showDelete: false,
    favourite: false,
    handleFavouriteClick: () => {},
    handleDeleteClick: () => {},
};

export default withStyles(style)(ReportChild);
