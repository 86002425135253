import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    FETCH_ENCOUNTER_ORDERS_FAILURE,
    FETCH_ENCOUNTER_ORDERS_SUCCESS,
} from '../../redux/modules/encounterOrders/encounterOrders-actions';

export default function* encounterOrdersWorkerSaga(action) {
    try {
        const {
            api,
            page,
            size,
            encounterUuid,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, `${api}?encounterUuid=${encounterUuid}&page=${page}&size=${size}`);
        yield put({
            type: FETCH_ENCOUNTER_ORDERS_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Encounter order fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_ENCOUNTER_ORDERS_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
