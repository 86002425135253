import axios from 'axios';
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty,
    sortArrayOfObjectsByFieldValue,
} from '../constants/CommonUtil';
import { formatDateTimeForDisplay, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import API from '../constants/api';
import {
    IntentFields,
    operationTypes,
} from '../components/PurchaseOrderComponents/IntentDialog/IntentUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
// import { formatDate } from '../constants/DateUtil';

export const STOCK_INTENT_OPERATION = {
    SAVE: 'DRAFT',
    CONFIRM: 'CONFIRM',
};
const EMPTY_ROW = {
    product: '',
    description: '',
    uom: '',
    quantityRequired: '',
    cost: '',
};

export const UI_OBJECT = ({
    createDate: '',
    uuid: '',
    id: '',
    transactionType: '',
    [IntentFields.DEST_LOC]: '',
    [IntentFields.REQUEST_LOC]: '',
    state: '',
    [IntentFields.NOTES]: '',
    [IntentFields.PRODUCTS]: [cloneDeep(EMPTY_ROW)],
});

const mapStockIntentLinesFromUiObject = (lines) => {
    const stockIntentLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            if (line.product && line.product.uuid) {
                const stockIntentLine = {
                    uuid: getStringFromObject('uuid', line) || null,
                    product: {
                        key: getStringFromObject('product.uuid', line, null),
                        value: getStringFromObject('product.productName', line, ''),
                    },
                    quantityRequired: getStringFromObject('quantity', line, 0),
                    quantityReceived: getStringFromObject('quantityReceived', line, 0),

                    productUom: {
                        key: getStringFromObject('uom.uuid', line, null),
                        value: getStringFromObject('uom.name', line, ''),
                    },
                    // To start sequence from 1
                    sequence: index + 1,
                };
                stockIntentLines.push(stockIntentLine);
            }
        });
    }
    return stockIntentLines;
};

export const mapStockIntentFromUiObject = (uiObject, action) => {
    const payload = {};
    if (isObjectValidAndNotEmpty(uiObject)) {
        console.log('mapStockIntentFromUiObject', uiObject);
        console.log('mapStockIntentFromUiObject', !!uiObject.uuid, uiObject.uuid !== '');
        if (!!uiObject.uuid && uiObject.uuid !== '') {
            payload.uuid = uiObject.uuid;
        }
        if (!!uiObject.id && uiObject.id !== '') {
            payload.id = uiObject.id;
        }
        payload.memo = getStringFromObject(IntentFields.NOTES, uiObject);
        payload.state = action;
        // payload.origin = uiObject.reference;
        payload.type = getStringFromObject(`${IntentFields.OPERATION_TYPE}.key`, uiObject);
        payload.location = {
            key: getStringFromObject('requestLoc.uuid', uiObject, null),
            value: getStringFromObject('requestLoc.name', uiObject, ''),
        };
        payload.intentLineDtoList = mapStockIntentLinesFromUiObject(uiObject.products,
            {
                key: getStringFromObject('requestLoc.uuid', uiObject, null),
                value: getStringFromObject('requestLoc.name', uiObject, ''),
            });
    }
    console.log('mapStockIntentFromUiObject payload', payload);
    return payload;
};

export const getBatches = async (productUuid, locationUuid) => {
    const response = await axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
        params: {
            productUuid,
            locationUuid,
        },
    });
    return response;
};

const mapStockPickingLineUiObject = (lines, locationUuid, isConfirmed) => lines.map(async (stock, index) => {
    const productUuid = getStringFromObject('product.key', stock, null);
    let response = {};
    if (!isConfirmed) {
        response = await getBatches(productUuid, locationUuid);
    }
    console.log('asdfsdfdsfsdfdsf', response);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    const obj = {
        uuid: getStringFromObject('uuid', stock),
        product: {
            productId: getStringFromObject('productId', stock, ''),
            productName: getStringFromObject('product.value', stock, ''),
            uuid: getStringFromObject('product.key', stock, null),
        },
        uom: {
            name: getStringFromObject('productUom.value', stock, ''),
            uuid: getStringFromObject('productUom.key', stock, null),
        },
        currentStockQuantity: getStringFromObject('currentStockQuantity', stock, 0),
        cost: getStringFromObject('cost', stock, 0),
        salePrice: getStringFromObject('salePrice', stock, 0),
        mrp: getStringFromObject('mrp', stock, 0),
        quantity: getStringFromObject('quantityRequired', stock, 0),
        quantityReceived: getStringFromObject('quantityReceived', stock, 0),
        batchId: getStringFromObject('batchId', stock),
        description: {
            text: getStringFromObject('stockProdLot.value', stock, ''),
            value: getStringFromObject('stockProdLot.value', stock, ''),
        },
        expiryDate: stock.expiryDate ? getDateInYYYYMMDDFormat(new Date(stock.expiryDate)) : '',
        trackByBatch: getStringFromObject('productTrackByBatch', stock, false),
        sequence: stock.sequence || index,
    };
    obj.batchWithQty = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        const selectedBatch = getStringFromObject('stockProdLot.value', stock, '');
        batchDetails.map((batch) => {
            if (selectedBatch && selectedBatch === getStringFromObject('batch.value', batch, '')) {
                obj.availableQuantity = NumberOf(getStringFromObject('quantity', batch));
            }
            if (!getStringFromObject('batch.key', batch, false)) {
                // todo commenting this line as its overriding quantity set in above code
                // obj.quantity = getStringFromObject('quantity', batch, 0);
            } else {
                obj.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                    (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
            }
            return obj;
        });
    }
    console.log('asdfsdfdsfsdfdsf1', obj, response);
    return obj;
});

export const mapStockIntentDtoToUiObject = async (stock) => {
    console.log('mapStockIntentDtoToUiObject', stock);
    let uiObject = cloneDeep(UI_OBJECT);
    if (isObjectValidAndNotEmpty(stock)) {
        const isConfirmed = ['done', 'confirmed'].indexOf(getStringFromObject('state', stock)) !== -1 && stock.uuid;
        const createDateTime = getStringFromObject('createDate', stock);
        uiObject = {
            ...uiObject,
            createDate: createDateTime ?
                formatDateTimeForDisplay(new Date(createDateTime)) :
                getStringFromObject('createDate', stock),
            uuid: getStringFromObject('uuid', stock, ''),
            id: getStringFromObject('id', stock, ''),
            notes: getStringFromObject('memo', stock, ''),
            destinationLoc: {
                name: getStringFromObject('destLoc.value', stock, ''),
                uuid: getStringFromObject('destLoc.key', stock, null),
            },
            requestLoc: {
                name: getStringFromObject('location.value', stock, ''),
                uuid: getStringFromObject('location.key', stock, null),
            },
            status: getStringFromObject('state', stock, ''),
            createdBy: getStringFromObject('createdBy', stock, ''),
            name: getStringFromObject('name', stock, ''),
            transactionType: getStringFromObject('transactionType', stock, ''),
            refNo: getStringFromObject('intentNumber', stock, ''),

        };
        const type = getStringFromObject('type', stock);
        if (type) {
            const theOpType = Object.values(operationTypes).filter(anOpType => anOpType.key === type);
            if (isArrayValidAndNotEmpty(theOpType)) {
                // eslint-disable-next-line
                uiObject[IntentFields.OPERATION_TYPE] = theOpType[0];
            }
        }

        const intentLineDtoList = getStringFromObject('intentLineDtoList', stock, []);
        let products = [];
        if (isArrayValidAndNotEmpty(intentLineDtoList) && uiObject.requestLoc.uuid) {
            products =
                await Promise.all(mapStockPickingLineUiObject(intentLineDtoList, uiObject.requestLoc.uuid, isConfirmed));
            products = sortArrayOfObjectsByFieldValue(products, 'sequence');
        } else {
            products = [cloneDeep(EMPTY_ROW)];
        }
        uiObject.products = products;
    }
    return uiObject;
};
