/* eslint-disable max-len */
import Big from 'big.js';
import { getBillRoundingPlaces, getRoundingPlaces } from './state';

import { NumberOf } from './numberUtils';

const operations = {
    ADDITION: 'ADDITION',
    SUBTRACTION: 'SUBTRACTION',
    MULTIPLICATION: 'MULTIPLICATION',
    DIVISION: 'DIVISION',
};

export const getScaledAndRoundedBigDecimal = (number, digits = getRoundingPlaces()) => (
    new Big(NumberOf(number)).round(digits, Big.roundHalfUp)
);

const calculate = (big1, operation, big2) => {
    switch (operation) {
    case operations.ADDITION:
        return getScaledAndRoundedBigDecimal(big1.add(big2));
    case operations.SUBTRACTION:
        return getScaledAndRoundedBigDecimal(big1.sub(big2));
    case operations.MULTIPLICATION:
        return getScaledAndRoundedBigDecimal(big1.mul(big2));
    case operations.DIVISION:
        return getScaledAndRoundedBigDecimal(big1.div(big2));
    default:
        return getScaledAndRoundedBigDecimal(0);
    }
};

export const round = (number, digits = getRoundingPlaces()) => (
    getScaledAndRoundedBigDecimal(number, digits).toNumber()
);
export const roundBigToNumber = (big) => {
    const digits = getRoundingPlaces();
    return big ? big.round(digits, Big.roundHalfUp).toNumber() : 0;
};

export const add = (num1, num2) => (
    calculate(getScaledAndRoundedBigDecimal(num1), operations.ADDITION, getScaledAndRoundedBigDecimal(num2)).toNumber()
);

export const subtract = (num1, num2) => (
    calculate(getScaledAndRoundedBigDecimal(num1), operations.SUBTRACTION, getScaledAndRoundedBigDecimal(num2)).toNumber()
);

export const multiply = (num1, num2) => (
    calculate(getScaledAndRoundedBigDecimal(num1), operations.MULTIPLICATION, getScaledAndRoundedBigDecimal(num2)).toNumber()
);

export const divide = (num1, num2) => {
    if (NumberOf(num2)) {
        return calculate(getScaledAndRoundedBigDecimal(num1), operations.DIVISION, getScaledAndRoundedBigDecimal(num2)).toNumber();
    }
    return 0;
};

export const truncateNumber = (num, digits) => {
    const toFixedString = NumberOf(num).toFixed(digits + 1);
    return NumberOf(toFixedString.slice(0, toFixedString.indexOf('.') + digits + 1));
};

export const getRoundOffValue = (value) => {
    const roundingValue = getBillRoundingPlaces();
    console.log('as0d9uad0s', roundingValue);
    if (roundingValue > 0) {
        const halfRoundOfBy = roundingValue / 2.0;
        const remainder = round(value % roundingValue);
        return NumberOf(
            round(remainder < halfRoundOfBy ? -remainder : roundingValue - remainder),
        );
    }
    return 0;
};

/*
 * Return 1 if the value of big1 is greater than the value of big2,
 *       -1 if the value of big1 is less than the value of big2, or
 *        0 if they have the same value.
 */
export const compare = (big1, big2) => (
    big1.cmp(big2)
);

export const isGreaterThan = (big1, big2) => (
    compare(big1, big2) === 1
);
