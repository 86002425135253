/**
 * Created by sachinprakash on 12/8/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';


const styles = () => ({
    card: {
        width: '100%',
        borderRadius: '8px',
        minHeight: '100%',
        marginBottom: '1rem',
    },
});


const GenericSettingContainer = ({ children, classes }) => (
    <React.Fragment>
        <div style={{ padding: '2rem', height: '100%', width: '95%' }}>
            <Card className={classes.card}>
                <div style={{ background: '#fff', padding: '1rem' }}>
                    { children }
                </div>
            </Card>
        </div>
    </React.Fragment>
);

GenericSettingContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenericSettingContainer);
