import axios from 'axios';
import { getStringFromObject } from './constants/lodashUtils';

// eslint-disable-next-line import/prefer-default-export
export const retriable = () => {
    const ret = axios.create();
    ret.interceptors.response.use((response) => {
        let errorCode = 0;
        if (getStringFromObject('data.error', response)) {
            errorCode = getStringFromObject('data.error.code', response);
        }
        console.log('Errorcode ', errorCode);
        /*
        if (errorCode === 300) {
            persistState(initialAuthState);
            window.location('/');
            persistLocationId(null);
            persistShopId(null);
            return Promise.reject(response);
        }
        */
        return Promise.resolve(response);
    }, undefined);
    return ret;
};
