import React from 'react';
import deepEquals from 'react-fast-compare';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import sumBy from 'lodash.sumby';
import { change, Field, FieldArray, initialize } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import { required } from '../../constants/FormValidations';
import API from '../../constants/api';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import OutlinedTextField from '../OutlinedTextField';
import { APPLICATION_CONFIG_URL } from '../../constants/constants';
import {
    apiCatchBlockFunction, getJsonPath,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty, isValidFunction,
    roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import { CONSOLIDATED_CASH_CLOSE_FORM, getInitialValues, getPrintData, mapPayloadToUuObject } from './ConsolidatedCashCloseDialogUtil';
import ConsolidatedCashCloseCashCollection from './ConsolidatedCashCloseCashCollection';
import OtherCollection from './OtherCollection';
import { formatDate } from '../../constants/DateUtil';
import {
    clearSelectCashState,
    dispatchGetConsolidatedCashCloseWithIdRequest,
    dispatchGetConsolidatedCashCloseWithUuidRequest,
    dispatchUpdateConsolidatedCashClosePrintFlagRequest,
    dispatchGetConsolidatedCashCloseForType,
} from '../../redux/modules/consolidatedCashClose/consolidatedCashClose-actions';
import ActionButton from '../ActionButton/ActionButton';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { cashCloseEditPrivileges } from '../../constants/privilegeConstants';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class ConsolidatedCashCloseDialogForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denominations: {},
            bankTransactions: {},
            print: false,
            edit: true,
        };
    }

    componentDidMount() {
        this.updateDialogActions(this.props);
        fetch(getJsonPath('/BookManagement/cashCloseDenomination.json', APPLICATION_CONFIG_URL))
            .then(response => response.json())
            .then((denominations) => {
                console.log('kljdfajksdfalsdffj', denominations);
                this.setState({
                    denominations: getStringFromObject('denominations', denominations, {}),
                    defaultCashCloseType: getStringFromObject('defaultCashCloseType', denominations, {}),
                    bankTransactions: getStringFromObject('bankTransactions', denominations, {}),
                }, this.initializeFormFromState);
            })
            .catch((error) => {
                console.log('Error While Fetching Json Schema', error);
            });
        const { consolidatedCashCloseId, dispatch } = this.props;
        if (consolidatedCashCloseId) {
            dispatch(dispatchGetConsolidatedCashCloseWithIdRequest(consolidatedCashCloseId));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEquals(nextProps.selected, this.props.selected)) {
            if (nextProps.selected.uuid !== null) {
                this.setState({ edit: false }, () => {
                    this.initializeFormFromProps(nextProps);
                    this.updateDialogActions(nextProps);
                });
            } else {
                this.setState({ edit: true }, () => {
                    this.initializeFormFromProps(nextProps);
                    this.updateDialogActions(nextProps);
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectCashState());
    }

    getTotalCollectionFromServer = (date, user, cashCloseType) => {
        const employee = getStringFromObject('key', user);
        const dateString = formatDate(date, 'yyyy-MM-dd');
        if (employee && dateString && isObjectValidAndNotEmpty(cashCloseType)) {
            const { dispatch, formValues } = this.props;
            axios.get(
                getUrlWithApiParams(API.CONSOLIDATED_CASH_CLOSE.GET_COLLECTIONS, { date: dateString, employee, closeType: getStringFromObject('key', cashCloseType) }))
                .then((response) => {
                    const byPaymentMode = response.data || {};
                    const totalCollected = sumBy(Object.values(byPaymentMode), p => NumberOf(p));
                    dispatch(change(CONSOLIDATED_CASH_CLOSE_FORM, 'collectionInSystem', totalCollected));
                    let totalCollections = getStringFromObject('totalCollections', formValues, []);
                    if (isArrayValidAndNotEmpty(totalCollections)) {
                        totalCollections = totalCollections.map(collection => ({
                            ...collection,
                            amount: NumberOf(byPaymentMode[collection.paymentMethod]),
                        }));
                        dispatch(change(CONSOLIDATED_CASH_CLOSE_FORM, 'totalCollections', totalCollections));
                    }
                    let otherCollections = getStringFromObject('otherCollections', formValues, []);
                    if (isArrayValidAndNotEmpty(otherCollections)) {
                        otherCollections = otherCollections.map(collection => ({
                            ...collection,
                            amount: NumberOf(byPaymentMode[collection.paymentMethod]),
                        }));
                        dispatch(change(CONSOLIDATED_CASH_CLOSE_FORM, 'otherCollections', otherCollections));
                    }
                })
                .catch(error => apiCatchBlockFunction(error, dispatch));
        }
    };

    updateDialogActions = (props) => {
        const { edit } = this.state;
        const {
            updateDialogActions,
            handleClose,
            submitForm,
            selected,
        } = props;
        if (isValidFunction(updateDialogActions)) {
            updateDialogActions(
                <React.Fragment>
                    <ActionButton onClick={handleClose} test-id="consolidated-cash-close-cancel">
                        Cancel
                    </ActionButton>
                    {
                        edit &&
                        <ActionButton
                            testId="consolidated-cash-close-confirm"
                            className="ml-1"
                            onClick={submitForm}
                            disabled={selected != null ? !isArrayValidAndNotEmpty(selected.calculatedCollections) : true}
                        >
                            Confirm
                        </ActionButton>
                    }
                    {
                        !edit &&
                        <ActionButton onClick={this.handlePrint} className="ml-1" test-id="consolidated-cash-close-print">
                            Print
                        </ActionButton>
                    }
                </React.Fragment>,
            );
        }
    };

    initializeFormFromState = () => {
        const { dispatch } = this.props;
        const { denominations, bankTransactions, defaultCashCloseType } = this.state;
        dispatch(initialize(CONSOLIDATED_CASH_CLOSE_FORM, getInitialValues(denominations, bankTransactions, defaultCashCloseType)));
    };

    initializeFormFromProps = (props = this.props) => {
        const { dispatch, selected } = props;
        const { denominations, bankTransactions } = this.state;
        dispatch(initialize(CONSOLIDATED_CASH_CLOSE_FORM, mapPayloadToUuObject(selected, denominations, bankTransactions)));
    };

    handleUpdatePrintFlag = () => {
        const { selected, dispatch } = this.props;
        const uuid = getStringFromObject('uuid', selected);
        dispatch(dispatchUpdateConsolidatedCashClosePrintFlagRequest(uuid, () => {
            dispatch(dispatchGetConsolidatedCashCloseWithUuidRequest(uuid));
        }));
    };

    handleSelectDate = (date) => {
        const { formValues, dispatch } = this.props;
        if (this.areValuesEntered()) {
            this.showWarningToClearValues();
        } else {
            const dateString = formatDate(date, 'yyyy-MM-dd');
            dispatch(dispatchGetConsolidatedCashCloseForType(getStringFromObject('key', getStringFromObject('cashCloseType', formValues)), dateString));
        }
    };

    handleCashCloseType = (closeType) => {
        const { formValues, dispatch } = this.props;
        if (this.areValuesEntered()) {
            this.showWarningToClearValues();
        } else {
            const dateString = formatDate(getStringFromObject('date', formValues), 'yyyy-MM-dd');
            dispatch(dispatchGetConsolidatedCashCloseForType(getStringFromObject('key', closeType), dateString));
        }
    };

    handlePrint = () => {
        this.setState(prevState => ({ print: !prevState.print }), this.handleUpdatePrintFlag);
    };

    postEditStateChangeCallback = () => {
        const { edit } = this.state;
        this.updateDialogActions(this.props);
        if (!edit) {
            this.initializeFormFromProps(this.props);
        }
    };

    handleEdit = () => {
        this.setState(prevState => ({ edit: !prevState.edit }), this.postEditStateChangeCallback);
    };

    showWarningToClearValues = () => {
        const { dispatch } = this.props;
        const { denominations, bankTransactions, defaultCashCloseType } = this.state;
        if (this.areValuesEntered()) {
            dispatch(displayWarning(
                'Do you want to clear the entered values?',
                () => {
                    this.props.initialize(getInitialValues(denominations, bankTransactions, defaultCashCloseType));
                },
            ));
        }
    };

    areValuesEntered = () => {
        const {
            formValues,
        } = this.props;
        const userEnteredConsolidatedCashCollections = getStringFromObject('userEnteredConsolidatedCashCollections', formValues);
        const userEnteredConsolidatedOtherCollections = getStringFromObject('userEnteredConsolidatedOtherCollections', formValues);
        const remarks = getStringFromObject('remarks', formValues);
        if (isArrayValidAndNotEmpty(userEnteredConsolidatedCashCollections)) {
            if (isObjectValidAndNotEmpty(userEnteredConsolidatedCashCollections.find(aCashCol => !!aCashCol.amount))) {
                return true;
            }
        }
        if (isArrayValidAndNotEmpty(userEnteredConsolidatedOtherCollections)) {
            if (isObjectValidAndNotEmpty(userEnteredConsolidatedOtherCollections.find(aCashCol => !!aCashCol.amount))) {
                return true;
            }
        }
        return !!remarks;
    };

    render() {
        const {
            formValues,
            dispatch,
            selected,
        } = this.props;
        const {
            print, bankTransactions, edit,
        } = this.state;
        const amountInSystem = NumberOf(getStringFromObject('amountInSystem', formValues));
        const denAmt = sumBy(getStringFromObject('cashCollections', formValues, []), l => NumberOf(l.amount));
        const denUserConsolidatedAmt = sumBy(getStringFromObject('userEnteredConsolidatedCashCollections', formValues, []), l => NumberOf(l.amount));
        const bankAmt = sumBy(getStringFromObject('otherCollections', formValues, []), l => NumberOf(l.amount));
        const bankUserConsolidatedAmt = sumBy(getStringFromObject('userEnteredConsolidatedOtherCollections', formValues, []), l => NumberOf(l.amount));
        const systemCollected =
            sumBy(getStringFromObject('totalCollections', formValues, []), l => NumberOf(l.amount));
        const totalCollected = denAmt + bankAmt;
        const totalUserCollected = denUserConsolidatedAmt + bankUserConsolidatedAmt;
        const diff = totalUserCollected - totalCollected;
        let short = '';
        let excess = '';
        if (diff > 0) {
            excess = diff;
        } else if (diff < 0) {
            short = Math.abs(diff);
        }
        const uuid = getStringFromObject('uuid', formValues);
        const editable = !!edit;
        const cashCloseTypeSelected = isObjectValidAndNotEmpty(formValues.cashCloseType);
        return (
            <React.Fragment>
                <form>
                    <Grid container justify="space-between" spacing="16" className="mt-1">
                        <Grid lg={3} sm={4} md={4}>
                            <Field
                                name="cashCloseType"
                                component={ReduxFormReactSelectMaterial}
                                label="Type"
                                dataSourceConfig={{
                                    text: 'value',
                                    value: 'key',
                                }}
                                validate={[required]}
                                required
                                isDisabled={uuid}
                                onSelectHandlers={['handleCashCloseType']}
                                actionHandlers={{
                                    handleCashCloseType: this.handleCashCloseType,
                                }}
                                dataSourceApi={API.CASH_CLOSE_TYPES.SEARCH}
                            />
                        </Grid>
                        {
                            cashCloseTypeSelected &&
                            <React.Fragment>
                                <Grid lg={3} sm={4} md={4}>
                                    <Field
                                        testId="date"
                                        name="date"
                                        component={DateInput}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        label="Date"
                                        required
                                        validate={[required]}
                                        disabled={uuid}
                                        onChangeHandlers={['handleSelectDate']}
                                        actionHandlers={{
                                            handleSelectDate: this.handleSelectDate,
                                        }}
                                    />
                                </Grid>
                                <Grid lg={2} sm={2} md={2}>
                                    {
                                        !!uuid && checkIfPrivilegeExistsForUser(cashCloseEditPrivileges) &&
                                        <div style={{ float: 'right' }}>
                                            <ActionButton
                                                primary={!edit}
                                                onClick={this.handleEdit}
                                            >
                                                {edit ? 'Cancel' : 'Edit'}
                                            </ActionButton>
                                        </div>
                                    }
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    {
                        cashCloseTypeSelected &&
                        <React.Fragment>
                            <Grid container justify="space-around" spacing={2} className="mt-1">
                                <Grid item lg={4} md={4} sm={4}>
                                    <Grid container className="mt-1">
                                        <Grid container justify="center">Consolidated Total</Grid>
                                    </Grid>
                                    <FieldArray
                                        name="totalCollections"
                                        component={OtherCollection}
                                        disabled
                                    />
                                    <Grid container className="mt-1" justify="center">
                                        Total:&nbsp;&nbsp;{roundedValueFixedToTwoDigits(systemCollected)}
                                    </Grid>
                                    {
                                        !!uuid &&
                                        <Grid container className="mt-1" justify="center">
                                            System Total during last edit/save:&nbsp;&nbsp;{roundedValueFixedToTwoDigits(amountInSystem)}
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Grid container className="mt-1">
                                        <Grid container justify="center">Consolidated Cash Collection</Grid>
                                        <FieldArray
                                            name="cashCollections"
                                            formName={CONSOLIDATED_CASH_CLOSE_FORM}
                                            dispatch={dispatch}
                                            component={ConsolidatedCashCloseCashCollection}
                                            disabled
                                        />
                                        <Grid container className="mt-1" justify="center">
                                            Total Consolidated Cash:&nbsp;&nbsp;{roundedValueFixedToTwoDigits(denAmt)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Grid container className="mt-1">
                                        <Grid container justify="center">Enter Cash Collection</Grid>
                                        <FieldArray
                                            name="userEnteredConsolidatedCashCollections"
                                            formName={CONSOLIDATED_CASH_CLOSE_FORM}
                                            dispatch={dispatch}
                                            component={ConsolidatedCashCloseCashCollection}
                                            disabled={!editable}
                                        />
                                        <Grid container className="mt-1" justify="center">
                                            Total Cash:&nbsp;&nbsp;{roundedValueFixedToTwoDigits(denUserConsolidatedAmt)}
                                        </Grid>
                                    </Grid>
                                    <Grid container className="mt-1">
                                        <Grid container justify="center">Enter Other Collections</Grid>
                                        <FieldArray
                                            name="userEnteredConsolidatedOtherCollections"
                                            component={OtherCollection}
                                            disabled={!editable}
                                        />
                                        <Grid container className="mt-1" justify="center">
                                            Others Total:&nbsp;&nbsp;{roundedValueFixedToTwoDigits(bankUserConsolidatedAmt)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justify="space-around" className={classNames('mt-1', 'font-size-1-3')}>
                                <Grid item lg={4} md={5} sm={6}>
                                    Total Consolidated : {roundedValueFixedToTwoDigits(totalCollected)}
                                </Grid>
                                <Grid item lg={4} md={5} sm={6}>
                                    Total Entered (Cash + Card + Others): {roundedValueFixedToTwoDigits(totalUserCollected)}
                                </Grid>
                                <Grid item lg={2} md={2} sm={4}>Short: {roundedValueFixedToTwoDigits(short)}</Grid>
                                <Grid item lg={2} md={2} sm={4}>Excess: {roundedValueFixedToTwoDigits(excess)}</Grid>
                            </Grid>
                            <Grid container className="mt-1">
                                <Field
                                    component={OutlinedTextField}
                                    multiline
                                    fullWidth={false}
                                    name="remarks"
                                    label="Remarks"
                                    disabled={!editable}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </form>
                <Print
                    data={{
                        ...getPrintData(selected, bankTransactions),
                        bankAmt: roundedValueFixedToTwoDigits(bankAmt),
                        shortage: roundedValueFixedToTwoDigits(NumberOf(getStringFromObject('shortage', selected))),
                        excess: roundedValueFixedToTwoDigits(NumberOf(getStringFromObject('excess', selected))),
                        cash: roundedValueFixedToTwoDigits(denAmt),
                        userCash: roundedValueFixedToTwoDigits(denUserConsolidatedAmt),
                        totalCollected: roundedValueFixedToTwoDigits(totalUserCollected),
                        systemTotal: roundedValueFixedToTwoDigits(systemCollected),
                        now: new Date(),
                    }}
                    subCompany={getStringFromObject('cashCloseType.value', formValues)}
                    showHeader={false}
                    print={print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/ConsolidatedCashCloseReciept.html`}
                />
            </React.Fragment>
        );
    }
}

ConsolidatedCashCloseDialogForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    updateDialogActions: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    selected: PropTypes.object,
    consolidatedCashCloseId: PropTypes.number,
};

ConsolidatedCashCloseDialogForm.defaultProps = {
    formValues: {},
    selected: null,
    consolidatedCashCloseId: null,
};

export default ConsolidatedCashCloseDialogForm;
