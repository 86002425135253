import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';
import { patientActive, patientAll, patientSearch } from '../constants/constants';
import PatientSearch from '../containers/PatientSearchContainer/PatientSearch';
import { isArrayValidAndNotEmpty } from '../constants/CommonUtil';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { searchPatientTabs } from '../containers/PatientSearchContainer/PatientSearchUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
import PageLoader from '../components/PageLoader/PageLoader';

const ActivePatientPage = React.lazy(() => import('../pages/ActivePatientPage/ActivePatientPage'));
const AllPatientPage = React.lazy(() => import('../pages/AllPatientPage/AllPatientPage'));

const getDefaultRoute = (props) => {
    const route = getStringFromObject('history.location.pathname', props);
    if (route === patientSearch) {
        const visibleTabs = searchPatientTabs.filter(aTab => checkIfPrivilegeExistsForUser(aTab.privilege));
        if (isArrayValidAndNotEmpty(visibleTabs)) {
            const firstTab = visibleTabs[0];
            if (isObjectValidAndNotEmpty(firstTab)) {
                if (firstTab.value === searchPatientTabs[1].value) {
                    return (
                        <Route path={patientSearch} exact component={AllPatientPage} />
                    );
                }
                return (
                    <Route path={patientSearch} exact component={ActivePatientPage} />
                );
            }
        }
    }
    return '';
};

const PatientSearchRoutes = props => (
    <PatientSearch history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            {getDefaultRoute(props)}
            <Route path={patientActive} exact component={ActivePatientPage} />
            <Route path={patientAll} exact component={AllPatientPage} />
        </Suspense>
    </PatientSearch>
);

PatientSearchRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

PatientSearchRoutes.defaultProps = {
    history: {},
};

export default PatientSearchRoutes;
