
import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    FETCH_CASH_PAYMENT_VOUCHER_FAILURE,
    FETCH_CASH_PAYMENT_VOUCHER_SUCCESS,
    FETCH_DEBIT_AND_CREDIT_LINES_FAILURE,
    FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS,
} from '../../redux/modules/cashPaymentVoucher/cashPaymentVoucher-actions';

export function* fetchDebitAndCreditLinesWorker(action) {
    try {
        const {
            api,
            accountId,
        } = action;
        const apiWithParams = `${api}?accountId=${accountId}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, apiWithParams);
        yield put({
            type: FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Debit and credit lines fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_DEBIT_AND_CREDIT_LINES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createOrUpdateCashPaymentVoucherWorker(action) {
    try {
        const {
            api,
            payload,
            successMessage,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, api, payload);
        yield put({
            type: FETCH_CASH_PAYMENT_VOUCHER_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: SUCCESS_MESSAGE, message: successMessage });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Create cash payment voucher failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchCashPaymentVoucherWorker(action) {
    try {
        const {
            api,
            voucherUuid,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, `${api}?voucherUuid=${voucherUuid}`);
        yield put({
            type: FETCH_CASH_PAYMENT_VOUCHER_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Cash Payment Voucher fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_CASH_PAYMENT_VOUCHER_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
