import API from '../../../constants/api';

export const ADD_SALARY_ADVANCE = '@@salaryAdvance/ADD_SALARY_ADVANCE';
export const ADD_SALARY_FAILURE = '@@salaryAdvance/ADD_SALARY_FAILURE';
export const ADD_SALARY_SUCCESS = '@@salaryAdvance/ADD_SALARY_SUCCESS';

export const FETCH_ADVANCE_SALARY = '@@salaryAdvance/FETCH_ADVANCE_SALARY';
export const FETCH_ADVANCE_SALARY_SUCCESS = '@@salaryAdvance/FETCH_ADVANCE_SALARY_SUCCESS';
export const FETCH_ADVANCE_SALARY_FAILURE = '@@salaryAdvance/FETCH_ADVANCE_SALARY_FAILURE';

export const CLEAR_SELECTED_STATE = '@@salaryAdvance/CLEAR_SELECTED_STATE';

export const createAdvancedSalary = (payload, successCallBack, failureCallBack) => ({
    type: ADD_SALARY_ADVANCE,
    payload,
    api: API.SALARYADVANCE.ADD_ADVANCED_SALARY,
    successCallBack,
    failureCallBack,
});

export const fetchAdvanceSalaryIssued = id => ({
    type: FETCH_ADVANCE_SALARY,
    api: `${API.SALARYADVANCE.GET_ALL}&id=${id}`,
});

export const clearAdvanceSelectedState = () => ({
    type: CLEAR_SELECTED_STATE,
});
