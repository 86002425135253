import API from '../../../constants/api';

export const CREATE_ACCOUNT_TRANSFER_REQUEST = '@@accountTransfer/CREATE_ACCOUNT_TRANSFER_REQUEST';
export const CREATE_ACCOUNT_TRANSFER_FAILURE = '@@accountTransfer/CREATE_ACCOUNT_TRANSFER_FAILURE';
export const CREATE_ACCOUNT_TRANSFER_SUCCESS = '@@accountTransfer/CREATE_ACCOUNT_TRANSFER_SUCCESS';

export const FETCH_ACCOUNT_TRANSFER_REQUEST = '@@accountTransfer/FETCH_ACCOUNT_TRANSFER_REQUEST';
export const FETCH_ACCOUNT_TRANSFER_FAILURE = '@@accountTransfer/FETCH_ACCOUNT_TRANSFER_FAILURE';
export const FETCH_ACCOUNT_TRANSFER_SUCCESS = '@@accountTransfer/FETCH_ACCOUNT_TRANSFER_SUCCESS';

export const CLEAR_ACCOUNT_TRANSFER = '@@accountTransfer/CLEAR_ACCOUNT_TRANSFER';

export const createAccountTransferRequest = (payload, successCallBack, failureCallBack) => ({
    type: CREATE_ACCOUNT_TRANSFER_REQUEST,
    payload,
    api: API.ACCOUNT_TRANSFER.CREATE,
    successCallBack,
    failureCallBack,
});

export const clearAccountTransfer = () => ({
    type: CLEAR_ACCOUNT_TRANSFER,
});

export const fetchAccountTransfer = id => ({
    type: FETCH_ACCOUNT_TRANSFER_REQUEST,
    api: `${API.ACCOUNT_TRANSFER.FETCH_BY_ID}/${id}`,
});
