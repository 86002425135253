import React from 'react';
import PropTypes from 'prop-types';
import {
    findValueByChoices,
    isArrayValidAndNotEmpty,

} from '../../../constants/CommonUtil';
import {
    formatDateForDisplay,
    formatDateTimeForDisplay,
    getDateInDDMMMYYYYFormat,
    isDateString,
} from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


const ReduxFormText = (props) => {
    const {
        input,
        label,
        style,
        isComponent,
        ...otherProps
    } = props;
    const { InputProps, multiple, dataSourceConfig } = otherProps;
    let { value } = input;
    console.log('asdfsdfsdfsdfsfsdf', otherProps, input.value);
    if (otherProps.choices) {
        const { choices } = otherProps;
        value = findValueByChoices(choices, dataSourceConfig, input.value);
    }
    if (multiple && isArrayValidAndNotEmpty(value)) {
        let valueAsString = '';
        value.forEach((v, idx) => {
            if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
                valueAsString = valueAsString.concat(v[dataSourceConfig.text]);
            } else {
                valueAsString = valueAsString.concat(v);
            }
            if (idx !== value.length - 1) {
                valueAsString = valueAsString.concat(', ');
            }
        });
        value = valueAsString;
    }
    if ((typeof value === 'object')) {
        if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
            value = getStringFromObject(dataSourceConfig.text, value, '-');
        } else if (!isComponent) {
            value = JSON.stringify(value);
        }
    }
    /* check if contains date format like 2018-02-11 the convert into standard format */
    if (isDateString(value) || otherProps.isDate) {
        value = getDateInDDMMMYYYYFormat(new Date(value));
    }
    if (otherProps.isDateTime) {
        value = formatDateTimeForDisplay(new Date(value));
    }
    if (otherProps.type === 'date' && otherProps.dateFormatter) {
        value = formatDateForDisplay(value);
    }
    if (typeof value === 'boolean') {
        value = value.toString();
    }
    return (
        <div>
            {
                (InputProps) && (!InputProps.noLabel) &&
                <div>{label}</div>
            }
            <div style={{ fontSize: '1rem', ...style }}>
                {value}
            </div>
        </div>
    );
};

ReduxFormText.propTypes = {
    input: PropTypes.object.isRequired,
    style: PropTypes.object,
    label: PropTypes.string,
    isComponent: PropTypes.bool,
};

ReduxFormText.defaultProps = {
    style: { fontSize: '14px' },
    label: '',
    isComponent: false,
};

export default ReduxFormText;
