/**
 * Created by sachinprakash on 12/8/19.
 */
import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    costAccountSettings,
    costAccountSettingsAnalyticsDefault,
    costAccountSettingsDistributionPlan,
} from '../constants/constants';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const CostAccountList = React.lazy(() => import('../components/CostAccountSettingComponents/CostAccountList'));

const CostAccountSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={costAccountSettings} exact component={CostAccountList} />
            <Route path={costAccountSettingsDistributionPlan} exact component={CostAccountList} />
            <Route path={costAccountSettingsAnalyticsDefault} exact component={CostAccountList} />
        </Suspense>
    </GenericSettingContainer>
);

export default CostAccountSettingRoutes;
