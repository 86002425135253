import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { addDiagnosisSettings, mrsSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const addDiagnosisSettingsPage = React.lazy(() => import('../pages/MRSPages/DiagnosisPage'));

const MRSRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route exact path={mrsSettings} render={() => (<Redirect to={addDiagnosisSettings} />)} />
            <Route path={addDiagnosisSettings} exact component={addDiagnosisSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default MRSRoutes;
