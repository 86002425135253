import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { FETCH_VISITS_LIST_SUCCESS, FETCH_VISITS_LIST_FAILURE } from
    '../../redux/modules/registrationList/registrationList-actions';

export function* fetchRegistrationListWorker(action) {
    try {
        const {
            api,
            page,
            size,
            sortDir,
            sortCol,
            searchConstruct,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParams =
        // eslint-disable-next-line max-len
            `${api}?page=${page}&size=${size}&sortDir=${sortDir}&sortCol=${sortCol}`;
        const response = yield call(
            retriable().post,
            apiWithQueryParams,
            searchConstruct || null,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put({
            type: FETCH_VISITS_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_VISITS_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchRegistrationListWorker;
