import { SELECT_LANGUAGE } from './language-actions';

const languageReducer = (state = 'en', action) => {
    let newState = state;
    switch (action.type) {
    case SELECT_LANGUAGE:
        newState = action.language;
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only log if state has changed
        console.log(
            'new_state:',
            action,
            'state:',
            state,
            'newState:',
            newState,
        );
    }

    return newState;
};

export default languageReducer;
