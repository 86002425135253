import React from 'react';
import Spinner from '../Spinner/Spinner';

const PageLoader = () => (
    <div>
        <Spinner
            canShow
        />
    </div>
);

export default PageLoader;

