import API from '../../../constants/api';

export const FETCH_CALENDAR_DETAILS_REQUEST = '@@dashboard/FETCH_CALENDAR_DETAILS_REQUEST';
export const FETCH_CALENDAR_DETAILS_SUCCESS = '@@dashboard/FETCH_CALENDAR_DETAILS_SUCCESS';
export const FETCH_CALENDAR_DETAILS_FAILURE = '@@dashboard/FETCH_CALENDAR_DETAILS_FAILURE';

export const fetchCalendarDetail = (startDate, endDate) => ({
    type: FETCH_CALENDAR_DETAILS_REQUEST,
    api: API.CALENDAR.DETAILS,
    startDate,
    endDate,
});
