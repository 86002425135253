import initialStates from '../../initialStates';
import { ADD_ORDERS_REQUEST, ADD_ORDERS_SUCCESS, FETCH_ORDERS_QUEUE_LIST_SUCCESS } from './ordersQueue-actions';

const ordersQueue = (state = initialStates.visitQueue, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ORDERS_QUEUE_LIST_SUCCESS:
        newState = action.data;
        break;
    case ADD_ORDERS_REQUEST:
        newState = { ...state, success: false };
        break;
    case ADD_ORDERS_SUCCESS:
        newState = { ...state, success: true };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default ordersQueue;
