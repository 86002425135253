import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Clear from '@material-ui/icons/Clear';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs/Tabs';

import { completeFavoriteObjTypes, favoriteTypes, getPrescriptions } from './ClinicalFavoritesUtil';
import ReactSelectMaterial from '../../../components/ReactSelectMaterial/ReactSelectMaterial';
import {
    apiCatchBlockFunction, findDefinedArrayElements,
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,
    isValidFunction, isValidObject,
} from '../../../constants/CommonUtil';
import api from '../../../constants/api';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import OutlinedTextField from '../../../components/OutlinedTextField';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { clearFavoritesOfType } from '../../../constants/favoritesStorage';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { GLOBAL_PROPERTIES } from '../../../constants/constants';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const style = () => ({
    containerHeight: {
        height: '12rem',
        maxHeight: '12rem',
        overflow: 'auto',
        border: '1px solid #afafaf',
        boxShadow: '2px 2px #efefef',
    },
});

class ClinicalFavorites extends React.Component {
    constructor(props) {
        super(props);
        const appConfiguration = getStringFromObject('appConfiguration', props);
        this.state = {
            orderTypeSelector: {},
            favorites: {},
            selectedTab: props.isTemplate ?
                getStringFromObject('GENERIC_PRESCRIPTIONS_TEMPLATE.value', favoriteTypes(appConfiguration),
                    getStringFromObject('PRESCRIPTIONS_TEMPLATE.value', favoriteTypes(appConfiguration))) :
                favoriteTypes(appConfiguration).DIAGNOSES.value,
            loadedFavorites: {},
            searchValues: {},
        };
    }

    componentDidMount() {
        if (isValidFunction(this.props.updateDialogActions)) {
            this.props.updateDialogActions(
                <React.Fragment>
                    <ActionButton onClick={this.props.handleClose} primary={false} className="mr-1" test-id="clinical-cancel">
                        Cancel
                    </ActionButton>
                    <ActionButton onClick={this.onSaveFavorites} test-id="clinical-save">
                        Save
                    </ActionButton>
                </React.Fragment>,
            );
        }
        this.findAllFavorites();
        this.props.updateTabChangePreCheck(this.isFormDirty);
    }

    onTabChange = (event, value) => {
        const { dispatch } = this.props;
        const changeTabAndFetchFavorites = () => this.setState({ selectedTab: value }, this.findAllFavorites);
        if (this.isFormDirty()) {
            dispatch(displayWarning(
                'Any unsaved data will be lost. Do you wish to proceed?',
                changeTabAndFetchFavorites,
            ));
        } else {
            changeTabAndFetchFavorites();
        }
    };

    onSaveFavorites = () => {
        const {
            favorites,
            selectedTab,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        console.log('asd-0ai-a', favorites);
        const PRESCRIPTIONS_VALUE = getPrescriptions(selectedTab);
        const drugPropertyName = PRESCRIPTIONS_VALUE === 'PRESCRIPTIONS' ? 'drug' : 'genericDrug';
        const favs = cloneDeep(favorites);
        const prescripts = getStringFromObject(`${PRESCRIPTIONS_VALUE}.concepts`, favs);
        if (prescripts) {
            const prescriptionFavs =
                cloneDeep(prescripts);
            setStringPropertyToObject(
                `${PRESCRIPTIONS_VALUE}.${drugPropertyName}`,
                favs,
                prescriptionFavs,
            );
            setStringPropertyToObject(
                `${PRESCRIPTIONS_VALUE}.concepts`,
                favs,
                [],
            );
            console.log('asd-0ai-d0asi-[d0paksd', prescriptionFavs, favs);
        }
        let completeFavTypes = [];
        this.getCompleteFavoriteObjTypes().forEach((aCompleteFavType) => {
            completeFavTypes = completeFavTypes.concat(cloneDeep(favorites[aCompleteFavType]));
            delete favs[aCompleteFavType];
        });
        let favsDto = Object.values(favs);
        if (isArrayValidAndNotEmpty(completeFavTypes)) {
            favsDto = favsDto.concat(completeFavTypes);
        }
        dispatch(showSpinner());
        axios.post(api.CLINICAL_FAVORITES.SAVE, findDefinedArrayElements(favsDto))
            .then(() => {
                this.findAllFavorites();
                dispatch(successMessage('Favorites Saved Successfully'));
                clearFavoritesOfType(selectedTab);
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
            })
            .finally(() => {
                dispatch(hideSpinner());
            });
    };

    onFavoriteSelect = favoriteType => (favoriteObject) => {
        const { appConfiguration } = this.props;
        const maxFavoritesFromGP = getStringFromObject(GLOBAL_PROPERTIES.NUACARE_MAX_PROVIDER_FAVORITES, appConfiguration) || 50;
        if (isObjectValidAndNotEmpty(favoriteObject)) {
            this.setState((prevState) => {
                const newFavs = cloneDeep(prevState.favorites);
                let favorite = favoriteObject;
                if (favoriteType.valueField) {
                    favorite = getStringFromObject(favoriteType.valueField, favoriteObject);
                    if (favoriteType.value === favoriteTypes(appConfiguration).DIAGNOSES.value) {
                        setStringPropertyToObject(
                            'code',
                            favorite,
                            getStringFromObject('code', favoriteObject),
                        );
                    }
                }
                const favType = favoriteType.value;
                const { dispatch } = this.props;
                if (!isObjectValidAndNotEmpty(newFavs[favType])) {
                    newFavs[favType] = {
                        name: favType,
                        typeOfFavorite: favType,
                    };
                    if (!isArrayValidAndNotEmpty(newFavs[favType].concepts)) {
                        newFavs[favType].concepts = [];
                    }
                }
                const favDataSourceConfig = this.getFavDataSourceConfig(favoriteType);
                console.log('asd-0aisd-a0ida-0dia-s0i-0dias', favDataSourceConfig, newFavs[favType].concepts, favorite);
                if (
                    newFavs[favType].concepts.map((
                        aFav => getStringFromObject(favDataSourceConfig.key, aFav)),
                    ).includes(getStringFromObject(favDataSourceConfig.key, favorite))
                ) {
                    dispatch(errorMessage('Favorite Already Exists In List'));
                    return null;
                }
                const numberOfAddedFavorites = newFavs[favType].concepts.length || 0;
                if (numberOfAddedFavorites >= maxFavoritesFromGP) {
                    dispatch(errorMessage(`Cannot have more than ${maxFavoritesFromGP} ${favoriteType.label} favorites`));
                    return null;
                }

                newFavs[favType].concepts = newFavs[favType].concepts.concat(favorite);
                console.log('as-duas-dadsa', newFavs[favType]);
                return {
                    favorites: newFavs,
                };
            });
        }
    };

    onRemoveFavorite = (favType, index) => {
        this.setState((prevState) => {
            const newFavs = cloneDeep(prevState.favorites);
            console.log('asd0as0d09asd0adas', JSON.stringify(newFavs[favType]));
            if (
                isObjectValidAndNotEmpty(newFavs[favType]) &&
                isArrayValidAndNotEmpty(newFavs[favType].concepts)
            ) {
                newFavs[favType].concepts.splice(index, 1);
                return {
                    favorites: newFavs,
                };
            }
            return {};
        });
    };

    onRemoveConsultationFavorite = (favType, index) => {
        this.setState((prevState) => {
            const newFavs = cloneDeep(prevState.favorites);
            setStringPropertyToObject(`${favType}[${index}].toBeDeleted`, newFavs, true);
            return {
                favorites: newFavs,
            };
        });
    };

    onOrderTypeSelect = (suggestion) => {
        this.setState({
            orderTypeSelector: suggestion,
        });
    };

    onSearchFavorites = favoriteType => (e) => {
        const searchText = (getTextFieldChangedValue(e) || '').toLowerCase();
        this.setState(prevState => ({
            searchValues: {
                ...prevState.searchValues,
                [favoriteType]: searchText,
            },
        }));
        console.log('asd09ausd0aud0asdasd', searchText, getTextFieldChangedValue(e), e);
        const {
            favorites,
        } = this.state;
        if (this.getCompleteFavoriteObjTypes().includes(favoriteType)) {
            const consultationFavorites =
                getStringFromObject(favoriteType, favorites, []) || [];
            if (isArrayValidAndNotEmpty(consultationFavorites)) {
                const newFavConceptsList = consultationFavorites.map((aFavorite) => {
                    let isHidden = false;
                    if (!aFavorite.toBeDeleted) {
                        if (!(aFavorite.name.toLowerCase().includes(searchText))) {
                            isHidden = true;
                        }
                    }
                    return {
                        ...aFavorite,
                        isHidden,
                    };
                });
                const newFavs = cloneDeep(favorites);
                setStringPropertyToObject(favoriteType, newFavs, newFavConceptsList);
                this.setState(prevState => ({
                    favorites: newFavs,
                    searchValues: {
                        ...prevState.searchValues,
                        [favoriteType]: searchText,
                    },
                }));
            }
        } else {
            const favoritesOfType = getStringFromObject(`${favoriteType}.concepts`, favorites, []) || [];
            if (isArrayValidAndNotEmpty(favoritesOfType)) {
                const newFavConceptsList = favoritesOfType.map((aFavorite) => {
                    let isHidden = false;
                    if (!(aFavorite.value.toLowerCase().includes(searchText))) {
                        isHidden = true;
                    }
                    return {
                        ...aFavorite,
                        isHidden,
                    };
                });
                const newFavs = cloneDeep(favorites);
                setStringPropertyToObject(`${favoriteType}.concepts`, newFavs, newFavConceptsList);
                this.setState(prevState => ({
                    favorites: newFavs,
                    searchValues: {
                        ...prevState.searchValues,
                        [favoriteType]: searchText,
                    },
                }));
            }
        }
    };

    getSearchContainerForFavType = (favoriteType) => {
        const { appConfiguration } = this.props;
        if (favoriteType.value === favoriteTypes(appConfiguration).ORDERS.value) {
            let orderSearchApi = favoriteType.dataSourceApi;
            const {
                orderTypeSelector,
            } = this.state;
            if (isObjectValidAndNotEmpty(orderTypeSelector)) {
                orderSearchApi = `${orderSearchApi}?orderTypeUuid=${orderTypeSelector.key}&searchString=`;
            } else {
                orderSearchApi = `${orderSearchApi}?searchString=`;
            }
            return (
                <Grid container spacing={16}>
                    <Grid item sm={6} md={6} lg={6}>
                        <ReactSelectMaterial
                            testId="ordertype"
                            dataSourceApi={api.GET_ORDER_TYPES}
                            dataSourceConfig={{
                                text: 'value',
                                value: 'key',
                            }}
                            label="Order Type"
                            value={orderTypeSelector}
                            onChange={this.onOrderTypeSelect}
                        />
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                        <ReactSelectMaterial
                            testId="order-name"
                            key={isValidObject(orderTypeSelector) ? orderTypeSelector.value : 'null'}
                            dataSourceApi={orderSearchApi}
                            autocomplete
                            defaultOptions
                            label="Order Name"
                            dataSourceConfig={{
                                text: 'concept.value',
                                value: 'concept',
                            }}
                            onChange={this.onFavoriteSelect(favoriteType)}
                        />
                    </Grid>
                </Grid>
            );
        } else if (this.getCompleteFavoriteObjTypes().includes(favoriteType.value)) {
            return null;
        } else if (favoriteType.value === favoriteTypes(appConfiguration).DIAGNOSES.value) {
            return (
                <ReactSelectMaterial
                    testId="diagnosis"
                    dataSourceApi={favoriteType.dataSourceApi}
                    autocomplete
                    dataSourceConfig={favoriteType.dataSourceConfig || {
                        text: 'value',
                        value: 'key',
                    }}
                    getOptionValue={
                        option => getStringFromObject('codedDiagnosis.key', option)
                    }
                    getOptionLabel={
                        option => (
                            `${getStringFromObject('codedDiagnosis.value', option)}
                            (${getStringFromObject('code', option)})`
                        )
                    }
                    onChange={this.onFavoriteSelect(favoriteType)}
                />
            );
        }
        return (
            <ReactSelectMaterial
                testId="prescription"
                dataSourceApi={favoriteType.dataSourceApi}
                autocomplete
                dataSourceConfig={favoriteType.dataSourceConfig || {
                    text: 'value',
                    value: 'key',
                }}
                onChange={this.onFavoriteSelect(favoriteType)}
            />
        );
    };

    getFavDataSourceConfig = (favoriteType) => {
        const { appConfiguration } = this.props;
        const commonDataSourceConfig = { text: 'value', key: 'key' };
        let favTypeDataSourceConfig =
            getStringFromObject(
                'dataSourceConfig',
                favoriteType,
            ) || commonDataSourceConfig;
        if (favoriteType.value === favoriteTypes(appConfiguration).DIAGNOSES.value) {
            favTypeDataSourceConfig = { ...commonDataSourceConfig };
        }
        return favTypeDataSourceConfig;
    };

    getCompleteFavoriteObjTypes = () => {
        const {
            appConfiguration,
        } = this.props;
        return completeFavoriteObjTypes(appConfiguration);
    };

    isFormDirty = () => {
        const favorites = cloneDeep(this.state.favorites);
        if (isObjectValidAndNotEmpty(favorites)) {
            Object.values(favorites).forEach((aFav) => {
                if (isArrayValidAndNotEmpty(aFav.concepts)) {
                    setStringPropertyToObject(
                        'concepts',
                        aFav,
                        aFav.concepts.map((aConcept) => {
                            const concept = { ...aConcept };
                            delete concept.isHidden;
                            return concept;
                        }),
                    );
                }
            });
        }
        console.log('asd-a0da-0sida-', this.state.loadedFavorites);
        console.log('asd-a0da-0sida-', favorites);
        return !deepEqual(this.state.loadedFavorites, favorites);
    };

    findAllFavorites = () => {
        const {
            dispatch,
        } = this.props;
        const {
            selectedTab,
        } = this.state;
        const queryParams = `?types=${selectedTab}`;
        const PRESCRIPTIONS_VALUE = getPrescriptions(selectedTab);
        const drugPropertyName = PRESCRIPTIONS_VALUE === 'PRESCRIPTIONS' ? 'drug' : 'genericDrug';
        dispatch(showSpinner());
        axios.get(`${api.CLINICAL_FAVORITES.FIND_BY_TYPE}${queryParams}`)
            .then((response) => {
                const favorites = response.data;
                const favFormData = {};
                if (isArrayValidAndNotEmpty(favorites)) {
                    favorites.forEach((aFavorite) => {
                        if (this.getCompleteFavoriteObjTypes().includes(aFavorite.typeOfFavorite)) {
                            const favFormDatum = favFormData[aFavorite.typeOfFavorite];
                            favFormData[aFavorite.typeOfFavorite] =
                                favFormDatum ? favFormDatum.concat(aFavorite) : [aFavorite];
                        } else if (aFavorite.typeOfFavorite === PRESCRIPTIONS_VALUE) {
                            favFormData[aFavorite.typeOfFavorite] = {
                                ...aFavorite,
                                concepts: getStringFromObject(drugPropertyName, aFavorite, []),
                            };
                        } else {
                            favFormData[aFavorite.typeOfFavorite] = aFavorite;
                        }
                    });
                }
                this.setState({
                    favorites: favFormData,
                    loadedFavorites: favFormData,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
            })
            .finally(() => {
                dispatch(hideSpinner());
            });
    };

    renderList = (favoriteType) => {
        const {
            favorites,
        } = this.state;
        const {
            classes,
            appConfiguration,
        } = this.props;
        const favoritesOfType = getStringFromObject(`${favoriteType.value}.concepts`, favorites, []) || [];

        if (this.getCompleteFavoriteObjTypes().includes(favoriteType.value)) {
            const consultationFavorites = getStringFromObject(favoriteType.value, favorites, []) || [];
            console.log('asd-0ai-0i-[k2;1easd', consultationFavorites);
            return (
                <MenuList
                    className={classes.containerHeight}
                    PaperProps={{
                        elevation: 10,
                    }}
                >
                    {
                        consultationFavorites.map((aFav, index) => (
                            (aFav.isHidden || aFav.toBeDeleted) ?
                                null :
                                <MenuItem key={aFav.key}>
                                    <ListItemText
                                        test-id={aFav.name}
                                        classes={{ primary: classes.primary }}
                                        inset
                                        primary={aFav.name}
                                    />
                                    <ListItemIcon
                                        className={classes.icon}
                                        onClick={() => {
                                            this.onRemoveConsultationFavorite(favoriteType.value, index);
                                        }}
                                    >
                                        <Clear test-id={`${aFav.name}-close-diagnosis`} />
                                    </ListItemIcon>
                                </MenuItem>
                        ))
                    }
                </MenuList>
            );
        }


        if (isArrayValidAndNotEmpty(favoritesOfType)) {
            const favTypeDataSourceConfig = this.getFavDataSourceConfig(favoriteType);
            console.log('asda-0dias-0diasd', favTypeDataSourceConfig, favoriteType);
            return (
                <MenuList
                    className={classes.containerHeight}
                    PaperProps={{
                        elevation: 10,
                    }}
                >
                    {
                        favoritesOfType.map((aFav, index) => {
                            if (aFav.isHidden) {
                                return null;
                            }
                            let displayString = getStringFromObject(favTypeDataSourceConfig.text, aFav);
                            if (favoriteTypes(appConfiguration).DIAGNOSES.value === favoriteType.value) {
                                displayString = `${getStringFromObject('value', aFav)}
                                (${getStringFromObject('code', aFav)})`;
                            }
                            return (
                                <MenuItem key={getStringFromObject(favTypeDataSourceConfig.key, aFav)}>
                                    <ListItemText
                                        test-id={getStringFromObject(favTypeDataSourceConfig.text, aFav)}
                                        classes={{ primary: classes.primary }}
                                        inset
                                        primary={displayString}
                                    />
                                    <ListItemIcon
                                        className={classes.icon}
                                        onClick={() => {
                                            this.onRemoveFavorite(
                                                favoriteType.value,
                                                index,
                                            );
                                        }}
                                    >
                                        <Clear test-id={`${getStringFromObject(favTypeDataSourceConfig.text, aFav)}-clear-things`} />
                                    </ListItemIcon>
                                </MenuItem>
                            );
                        })
                    }
                </MenuList>
            );
        }
        return (
            <h4>No Favorites Added</h4>
        );
    };

    render() {
        const {
            favorites,
            searchValues,
            selectedTab,
        } = this.state;
        const {
            classes,
            appConfiguration,
            isTemplate,
        } = this.props;
        console.log('d123131312uas0ds', classes);
        const favTypes = favoriteTypes(appConfiguration);
        console.log('saoasdas9duas0ds', favorites, favTypes);
        return (
            <React.Fragment>
                <Tabs value={selectedTab} onChange={this.onTabChange} color="primary">
                    {
                        Object.keys(favTypes).map((aFavoriteType) => {
                            const isTemplateSection =
                                [
                                    getStringFromObject('GENERIC_PRESCRIPTIONS_TEMPLATE.value', favTypes),
                                    getStringFromObject('PRESCRIPTIONS_TEMPLATE.value', favTypes),
                                    getStringFromObject('CONSULTATION.value', favTypes),
                                ].includes(aFavoriteType);

                            if (
                                isTemplateSection &&
                                !isTemplate
                            ) {
                                return null;
                            }
                            if (!isTemplateSection && isTemplate) {
                                return null;
                            }
                            if (!checkIfPrivilegeExistsForUser(favTypes[aFavoriteType].privileges)) {
                                return null;
                            }
                            return (
                                <Tab
                                    key={aFavoriteType}
                                    test-id={aFavoriteType}
                                    value={favTypes[aFavoriteType].value}
                                    label={favTypes[aFavoriteType].label}
                                />
                            );
                        })
                    }
                </Tabs>
                {
                    selectedTab &&
                    [1].map(() => {
                        const aFavoriteType = selectedTab;
                        console.log('asd-asja');
                        let favoriteData = getStringFromObject(
                            `${favTypes[aFavoriteType].value}.concepts`,
                            favorites,
                            [],
                        ) || [];
                        if (this.getCompleteFavoriteObjTypes().includes(favTypes[aFavoriteType].value)) {
                            favoriteData = getStringFromObject(
                                favTypes[aFavoriteType].value,
                                favorites,
                                [],
                            ) || [];
                        }

                        const isTemplateSection =
                            [
                                getStringFromObject('GENERIC_PRESCRIPTIONS_TEMPLATE.value', favTypes),
                                getStringFromObject('PRESCRIPTIONS_TEMPLATE.value', favTypes),
                                getStringFromObject('CONSULTATION.value', favTypes),
                            ].includes(aFavoriteType);

                        if (
                            isTemplateSection &&
                            !isTemplate
                        ) {
                            return null;
                        }
                        if (!isTemplateSection && isTemplate) {
                            return null;
                        }
                        return (
                            checkIfPrivilegeExistsForUser(favTypes[aFavoriteType].privileges) &&
                            <Grid
                                key={aFavoriteType}
                                container
                            >
                                <Grid item sm={8} md={8} lg={8} />
                                <Grid item sm={4} md={4} lg={4} className="mt-1 pr-1">
                                    {
                                        isArrayValidAndNotEmpty(favoriteData) &&
                                        <OutlinedTextField
                                            testId="search-header"
                                            label="Search"
                                            value={searchValues[favTypes[aFavoriteType].value]}
                                            onChange={this.onSearchFavorites(favTypes[aFavoriteType].value)}
                                        />
                                    }
                                </Grid>
                                <Grid item sm={4} md={4} lg={4} className="p1">
                                    {
                                        this.getSearchContainerForFavType(favTypes[aFavoriteType])
                                    }
                                </Grid>
                                <Grid item sm={8} md={8} lg={8} className="p1">
                                    {
                                        this.renderList(favTypes[aFavoriteType])
                                    }
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </React.Fragment>
        );
    }
}

ClinicalFavorites.propTypes = {
    classes: PropTypes.object,
    appConfiguration: PropTypes.object,
    isTemplate: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    updateDialogActions: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    updateTabChangePreCheck: PropTypes.func,
};

ClinicalFavorites.defaultProps = {
    classes: {},
    appConfiguration: {},
    handleClose: () => {},
    isTemplate: false,
    updateTabChangePreCheck: () => {},
};

const mapStateToProps = state => ({
    appConfiguration: getStringFromObject('appConfiguration', state),
});

export default withStyles(style)(connect(mapStateToProps)(ClinicalFavorites));

