import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Chip, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import ActionButton from '../ActionButton/ActionButton';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isObjectEmptyOrValuesEmpty,
    isStringNullOrUndefined,
} from '../../constants/CommonUtil';
import api from '../../constants/api';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { errorMessage, successMessage } from '../../redux/modules/message/message-actions';
import OutlinedTextField from '../OutlinedTextField';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '920px',
        marginTop: '1rem',
        overflow: 'visible',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
});

class GtinMapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.gtin,
            allProductGtins: [],
        };
    }
    componentDidMount() {
        this.fetchGtinValues();
    }

    fetchGtinValues = () => {
        const { product } = this.props;
        axios.get(`${api.SEARCH.SEARCH_GTINS_BY_PRODUCT_UUID}?productUuid=${product.uuid}`)
            .then((response) => {
                this.setState({ allProductGtins: response.data });
            })
            .catch((error) => {
                apiCatchBlockFunction(error);
            });
    }

    handleChange = (newValue) => {
        this.setState({
            value: newValue,
        });
    }

    handleSave = async () => {
        const {
            dispatch, product, postGtinSave, handleClose,
        } = this.props;
        const { allProductGtins, value } = this.state;
        allProductGtins.push({ gtin: value });
        try {
            dispatch(showSpinner());
            await axios.post(`${api.PRODUCT.SAVE_PRODUCT_GTIN}${product.uuid}`,
                allProductGtins,
            )
                .then((resposne) => {
                    const addedGtin = resposne.data.find(ele => ele.gtin === value);
                    handleClose(addedGtin);
                    postGtinSave(addedGtin);
                    dispatch(successMessage('Added Gtin Successfully'));
                })
                .catch((err) => {
                    apiCatchBlockFunction(err, dispatch);
                });
            dispatch(hideSpinner());
            // handleClose(false);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
        this.setState({
            value: '',
        });
    }

    handleDelete = index => async () => {
        console.log('dloading', index);
        const { dispatch } = this.props;
        const { allProductGtins } = this.state;
        const productGtinMap = allProductGtins[index];
        let canbeDeleted = true;
        if (isObjectEmptyOrValuesEmpty(productGtinMap) && !isStringNullOrUndefined(productGtinMap.uuid)) {
            try {
                dispatch(showSpinner());
                const isProductGtinMapPresentInStockProdLot =
                    await axios.get(`${api.PRODUCT.IS_PRODUCT_GTIN_MAP_PRESENT_IN_STOCK_PRODUCTION_LOT
                    }?productGtinMapUuid=${productGtinMap.uuid}`);
                canbeDeleted = !isProductGtinMapPresentInStockProdLot.data;
                dispatch(hideSpinner());
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
        const updatedAllProductGtins = [...allProductGtins];
        if (canbeDeleted) {
            updatedAllProductGtins.splice(index, 1);
            this.setState({
                allProductGtins: updatedAllProductGtins,
            });
        } else {
            dispatch(errorMessage('Unable to remove: Gtin has batch associated with it.'));
        }
    }

    render() {
        const {
            open,
            header,
            classes,
            handleClose,
        } = this.props;
        const { value, allProductGtins } = this.state;
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    maxWidth="sm"
                    scroll="body"
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ position: 'relative' }}className={classes.title}>
                        <Grid container justify="space-between">
                            <div>{header}</div>
                            <Close style={{ position: 'absolute', right: '0' }} className="cursor-pointer" onClick={() => handleClose('')} test-id="close-mapper" />
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            <Typography variant="h6">
                                    Product GTIN
                            </Typography>
                        </Grid>
                        <br />
                        <Grid lg={7} md={7} sm={7} >
                            <OutlinedTextField
                                onChange={this.handleChange}
                                value={value}
                                label="Value"
                                testId="text-field-array-component"
                            />
                        </Grid>
                        <br />
                        <Grid item lg={7} md={7} sm={7} justify="space-between">
                            {
                                (isArrayValidAndNotEmpty(allProductGtins)) &&
                                allProductGtins.map((aField, index) => (
                                    <Chip
                                        key={aField.id}
                                        className="m0-5"
                                        label={aField.gtin}
                                        onDelete={this.handleDelete(index, allProductGtins)}
                                    />
                                ))
                            }
                        </Grid>
                        <br />
                        <Grid container sm={7} md={7} lg={7} justify="flex-end">
                            <Grid item>
                                <ActionButton
                                    variant="contained"
                                    disableRipple
                                    onClick={this.handleSave}
                                >
                                    Save
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

GtinMapper.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    postGtinSave: PropTypes.func,
    header: PropTypes.string,
    gtin: PropTypes.string,
};

GtinMapper.defaultProps = {
    header: 'Product Gtin Map',
    gtin: '',
    postGtinSave: () => {},
};

export default withStyles(style)(GtinMapper);
