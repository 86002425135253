import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import memoizeOne from 'memoize-one';
import {
    formatDateTimeForDisplay,
    formatDateForDisplay,
    formatTimeForDisplay,
    minutesToHoursDisplayStringNoZero,
    formatIntervalInHoursAndMinutes,
} from '../../constants/DateUtil';
import {
    commaSeparatedAndRoundedToTwoDigits,
    currencyFormatter,
    isArrayValidAndNotEmpty,
    isValidFunction,
    reprocessJson,
    roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import { getLoggedInProviderUuid } from '../../constants/state';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';

export const getQuotedCsv = (value) => {
    const keyArr = value.map(f => `'${f.key}'`);
    return keyArr.join(',');
};

export const getCsv = (value) => {
    const keyArr = value.map(f => `${f.key}`);
    return keyArr.join(',');
};

export const numberEqualsFilterGenerator = (field, value) => `${field} = ${value}`;
export const numberLessThanOrEqualsFilterGenerator = (field, value) => `${field} <= ${value}`;
export const numberGreaterThanOrEqualsFilterGenerator = (field, value) => `${field} >= ${value}`;
export const numberGreaterThanFilterGenerator = (field, value) => `${field} > ${value}`;
export const numberLessThanFilterGenerator = (field, value) => `${field} < ${value}`;
export const numberBetweenFilterGenerator = (field, value1, value2) => `${field} BETWEEN ${value1} AND ${value2}`;

export const stringEqualsFilterGenerator = (field, value) => `lower(${field}) = '${value.toLowerCase()}'`;
export const stringContainingFilterGenerator = (field, value) => `lower(${field}) like '%${value.toLowerCase()}%'`;
export const stringStartsWithFilterGenerator = (field, value) => `lower(${field}) like '${value.toLowerCase()}%'`;
export const stringEndsWithFilterGenerator = (field, value) => `lower(${field}) like '%${value.toLowerCase()}'`;

export const stringInFilterGeneratorForKVObj = (field, valueArray) => (
    `${field} in (${getQuotedCsv(valueArray)})`
);
export const numberInFilterGeneratorForKVObj = (field, valueArray) => `${field} in (${getCsv(valueArray)})`;
export const stringEqualsFilterGeneratorForKVObj = (field, value, valueKey) => `${field} = '${value[valueKey]}'`;
export const numberEqualsGeneratorForKVObj = (field, value, valueKey) => `${field} = ${value[valueKey]}`;
export const booleanIsFilterGeneratorForKVObj = (field, value, valueKey) => `${field} is ${value[valueKey]}`;

export const SEARCH_ARG_TYPE = {
    string: (value, onChange, idx, field) => (
        <TextField
            value={value}
            onChange={e => onChange(idx, e, field)}
        />
    ),
    date: (value, onChange, idx, field) => (
        <TextField
            type="date"
            value={value}
            onChange={e => onChange(idx, e, field)}
        />
    ),
    numeric: (value, onChange, idx, field) => (
        <TextField
            type="number"
            value={value}
            onChange={e => onChange(idx, e, field)}
        />
    ),
};

export const SEARCH_OPERATORS = {
    string: {
        EQUALS: { label: 'equals', extractor: stringEqualsFilterGenerator, arg: [SEARCH_ARG_TYPE.string] },
        CONTAINS: { label: 'Containing', extractor: stringContainingFilterGenerator, arg: [SEARCH_ARG_TYPE.string] },
        STARTS_WITH: {
            label: 'Starts With',
            extractor: stringStartsWithFilterGenerator,
            arg: [SEARCH_ARG_TYPE.string],
        },
        ENDS_WITH: { label: 'Ends With', extractor: stringEndsWithFilterGenerator, arg: [SEARCH_ARG_TYPE.string] },
    },
    number: {
        EQUALS: { label: '=', extractor: numberEqualsFilterGenerator, arg: [SEARCH_ARG_TYPE.numeric] },
        LESS_THAN: { label: '<', extractor: numberLessThanFilterGenerator, arg: [SEARCH_ARG_TYPE.numeric] },
        GREATER_THAN: { label: '>', extractor: numberGreaterThanFilterGenerator, arg: [SEARCH_ARG_TYPE.numeric] },
        LESS_THAN_OR_EQUALS: {
            label: '<=',
            extractor: numberLessThanOrEqualsFilterGenerator,
            arg: [SEARCH_ARG_TYPE.numeric],
        },
        GREATER_THAN_OR_EQUALS: {
            label: '>=',
            extractor: numberGreaterThanOrEqualsFilterGenerator,
            arg: [SEARCH_ARG_TYPE.numeric],
        },
        BETWEEN: {
            label: 'between',
            extractor: numberBetweenFilterGenerator,
            arg: [SEARCH_ARG_TYPE.numeric, SEARCH_ARG_TYPE.numeric],
        },
    },
};

const dataFormatters = {
    dateFormatter: dateInMs => formatDateForDisplay(new Date(dateInMs)),
    timeFormatter: dateInMs => formatTimeForDisplay(new Date(dateInMs)),
    dateTimeFormatter: dateInMs => formatDateTimeForDisplay(new Date(dateInMs)),
    amountFormatter: amount => commaSeparatedAndRoundedToTwoDigits(amount),
    minutesToHoursFormatter: minutes => minutesToHoursDisplayStringNoZero(minutes),
    intervalFormatter: interval => formatIntervalInHoursAndMinutes(interval),
};

const renderColumnData = columnSchema => (rowData) => {
    console.log('aed0asyud0asujdsa', rowData, columnSchema);
    const rowDataToDisplay = getStringFromObject(columnSchema.field, rowData);
    if (
        columnSchema.formatter &&
        isValidFunction(dataFormatters[columnSchema.formatter])
    ) {
        const dataFormatter = dataFormatters[columnSchema.formatter];
        return dataFormatter(rowDataToDisplay);
    }
    return rowDataToDisplay;
};

export const renderColumnDataMemoized = memoizeOne(renderColumnData);

const getConsolidatedFieldValues = (rows, fieldName) => {
    console.log('asdjas-da', rows, fieldName);
    if (isArrayValidAndNotEmpty(rows)) {
        let amount = 0;
        for (let i = 0; i < rows.length; i += 1) {
            amount += NumberOf(getStringFromObject(`[${i}].${fieldName}`, rows));
        }
        const rounded = roundedValueFixedToTwoDigits(amount);
        return currencyFormatter(rounded);
    }
    return null;
};

export const getConsolidatedFieldValuesMemoized = memoizeOne(getConsolidatedFieldValues);

const getAverageFieldValues = (rows, fieldName) => {
    console.log('avgworks', rows, fieldName);
    if (isArrayValidAndNotEmpty(rows)) {
        let s1 = 0;
        let s2 = 0;
        // eslint-disable-next-line no-useless-escape
        s1 = getConsolidatedFieldValues(rows, fieldName[0]).replace(/\,/g, '');
        // eslint-disable-next-line no-useless-escape
        s2 = getConsolidatedFieldValues(rows, fieldName[1]).replace(/\,/g, '');
        if (NumberOf(s2) === 0) {
            return currencyFormatter(0);
        }
        const rounded = roundedValueFixedToTwoDigits(s1 / s2);
        return currencyFormatter(rounded);
    }
    return null;
};

export const getAverageFieldValuesMemoized = memoizeOne(getAverageFieldValues);

export const getDefaultWhereClauses = (defaultWhereClauses) => {
    const providerUuid = getLoggedInProviderUuid();
    const dataForProcessing = { providerUuid };
    const processedClauses = reprocessJson(dataForProcessing, defaultWhereClauses);
    return processedClauses;
};
