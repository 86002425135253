import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { COMBINEMODE, generateObjectWithCriteriaMode, getCombined } from '../../sagas/util';
import { formatDateForDisplay, getDateInDDMMYYYYFormatWithTime, isValidDate } from '../../constants/DateUtil';
import { DATE, DATE_RANGE, FIELD_WITH_OPERATION } from '../ReportsComponents/ReportsGenericFilterComponent/ReportsGenericFilterUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const getFilterColumnValue = (filterColumnValues, filterColumn) => {
    let columnVal = getStringFromObject(filterColumn.name, filterColumnValues);

    // if col value doesn't exist, then see if defaultValueField's value can be set
    if (!columnVal &&
        (filterColumn.defaultValueField && filterColumnValues[filterColumn.defaultValueField])
    ) {
        columnVal = filterColumnValues[filterColumn.defaultValueField];
    }


    if (filterColumn.startOfDay) {
        const filterDate = new Date(columnVal);
        filterDate.setHours(0);
        filterDate.setMinutes(0);
        filterDate.setSeconds(0);
        columnVal = filterDate.getTime();
    }

    if (filterColumn.endOfDay) {
        const filterDate = new Date(columnVal);
        filterDate.setHours(23);
        filterDate.setMinutes(59);
        filterDate.setSeconds(59);
        columnVal = filterDate.getTime();
    }

    if (!filterColumn.multiple && filterColumn.valueField) {
        columnVal = columnVal[filterColumn.valueField];
    }

    // if (filterColumn.convertToGMT) {
    //     const timezoneOffset = new Date().getTimezoneOffset();
    //     columnVal += (timezoneOffset * 60 * 1000);
    // }
    return columnVal;
};


export const generateConstructFromFilterColumns = (filterColumns, filterColumnValues) => {
    let searchConstruct = null;
    if (isArrayValidAndNotEmpty(filterColumns) && isObjectValidAndNotEmpty(filterColumnValues)) {
        filterColumns.map((filterColumn) => {
            // either the filter column value is set or see if the defaultFilterColumnField
            // is set, take and value in
            if (
                (filterColumnValues[filterColumn.name] != null && filterColumnValues[filterColumn.name] !== undefined)
                || (filterColumn.defaultValueField && filterColumnValues[filterColumn.defaultValueField])
            ) {
                const {
                    name,
                    filterOperation,
                } = filterColumn.searchConstructFields;

                let columnVal = getFilterColumnValue(filterColumnValues, filterColumn);
                let afilterOperation = filterOperation;
                // handling filters with operation and amount/value
                if (filterColumn.type === 'fieldWithOperation') {
                    const operation = getStringFromObject('operation', columnVal);
                    if (isObjectValidAndNotEmpty(filterColumn.dataSourceConfig)) {
                        afilterOperation = getStringFromObject(
                            filterColumn.dataSourceConfig.value, operation, operation);
                    } else {
                        afilterOperation = operation;
                    }
                    columnVal = getStringFromObject('value', columnVal);
                } else if (filterColumn.formatDate) {
                    columnVal = getDateInDDMMYYYYFormatWithTime(new Date(columnVal));
                }
                // OR ALL THE VALUES
                let criteria = {};
                console.log('asda-u9u-9-9u09uasd-u-', columnVal, filterColumn);
                if (filterColumn.multiple) {
                    if (isArrayValidAndNotEmpty(columnVal)) {
                        columnVal.map((aColumnValue) => {
                            const columnCritera = generateObjectWithCriteriaMode(
                                name,
                                getStringFromObject(filterColumn.valueField, aColumnValue),
                                afilterOperation,
                            );
                            criteria =
                                getCombined(criteria, COMBINEMODE.OR, columnCritera);
                            return null;
                        });
                    }
                } else if (columnVal != null && columnVal !== undefined) {
                    criteria = generateObjectWithCriteriaMode(
                        name,
                        columnVal,
                        afilterOperation,
                    );
                }

                searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, criteria);
            }
            return searchConstruct;
        });
    }

    return isObjectValidAndNotEmpty(searchConstruct) ? searchConstruct : null;
};

export const getStartEndDate = () => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    const stDate = d.getTime();
    return {
        stDate,
    };
};

export const generateSearchFilterConstruct = (searchValue, searchFieldFilters) => {
    let searchConstruct = null;
    let searchString = (searchValue || '').trim();
    let exactMatch = false;
    if (searchString.endsWith('@')) {
        exactMatch = true;
        searchString = searchString.substr(0, searchString.length - 1);
    }
    if (searchString) {
        searchFieldFilters.map((aSearchField) => {
            // special handling for person attribute
            if (aSearchField.name) {
                let criteria = null;
                if (aSearchField.name.toLowerCase().includes('personattribute')) {
                    const split = aSearchField.name.split('.');
                    const personAttributeCriteria = generateObjectWithCriteriaMode(
                        `${split[0]}.value`,
                        searchString,
                        exactMatch ? '=' : aSearchField.filterOperation,
                    );
                    const personAttributeTypeCriteria = generateObjectWithCriteriaMode(
                        'PersonAttributeType.name',
                        split[1],
                        '=',
                    );
                    criteria = getCombined(personAttributeCriteria, COMBINEMODE.AND, personAttributeTypeCriteria);
                } else {
                    criteria = generateObjectWithCriteriaMode(
                        aSearchField.name,
                        searchString,
                        exactMatch ? '=' : aSearchField.filterOperation,
                    );
                }
                searchConstruct = getCombined(searchConstruct, COMBINEMODE.OR, criteria);
            }
            return null;
        });
    }
    return searchConstruct;
};

export const initializeFilterColumnValues = (filterColumns, currentFilterValues = {}) => {
    const filterColumnValues = {};
    if (isArrayValidAndNotEmpty(filterColumns)) {
        filterColumns.forEach((aColumn) => {
            console.log('asd-9ajd[paosdl', aColumn);
            if (!aColumn.disabled) {
                switch (aColumn.type) {
                case 'autocomplete':
                case 'select':
                    if (aColumn.defaultValue) {
                        filterColumnValues[aColumn.name] = aColumn.defaultValue;
                    } else {
                        filterColumnValues[aColumn.name] = aColumn.multiple ? [] : null;
                    }
                    break;
                case FIELD_WITH_OPERATION:
                    if (aColumn.defaultOperation) {
                        filterColumnValues[aColumn.name] = { operation: aColumn.defaultOperation };
                    }
                    break;
                default:
                    filterColumnValues[aColumn.name] = aColumn.defaultValue || null;
                }
            } else {
                filterColumnValues[aColumn.name] = currentFilterValues[aColumn.name];
            }
        });
    }
    return filterColumnValues;
};

export const getFilterNameAndValueMap = (filters, filterSchema, getDisplayLabel) => {
    const filterAndValue = {};
    if (isObjectValidAndNotEmpty(filters) && isArrayValidAndNotEmpty(filterSchema)) {
        filterSchema.forEach((aFilter) => {
            if (filters[aFilter.name]) {
                let valueToGet = aFilter.valueField;
                let filterValue = filters[aFilter.name];
                const keyProp = 'name';
                switch (aFilter.type) {
                case 'select':
                case 'autocomplete':
                    if (getDisplayLabel) {
                        valueToGet = 'value';
                    }
                    if (isObjectValidAndNotEmpty(aFilter.dataSourceConfig)) {
                        valueToGet = aFilter.dataSourceConfig.text;
                    }
                    console.log('asd9ua0sdsd', aFilter.name, filters);
                    if (isArrayValidAndNotEmpty(filterValue)) {
                        filterValue = filterValue.map(aFilterVal => (
                            getStringFromObject(
                                valueToGet,
                                aFilterVal,
                            )
                        )).join(', ');
                    } else {
                        filterValue =
                            getStringFromObject(
                                valueToGet,
                                filterValue,
                            );
                    }
                    break;
                case 'date':
                    filterValue =
                        formatDateForDisplay(typeof filterValue === 'object' ? filterValue : new Date(filterValue));
                    break;
                case 'fieldWithOperation':
                    console.log('asdjasd-0id-a0d', filterValue);
                    filterValue = `${filterValue.operation} ${filterValue.value}`;
                    break;
                case 'checkbox':
                    filterValue = filterValue ? 'YES' : 'NO';
                    break;
                default:
                    console.log('not def');
                }
                filterAndValue[aFilter[keyProp]] = {
                    value: filterValue,
                    label: aFilter.label,
                };
            }
        });
        console.log('asd9sa0d9a', filterAndValue);
    }
    return filterAndValue;
};

const isValidDateRangeObject =
dateRangeValue => isValidDate(dateRangeValue.startDate) || isValidDate(dateRangeValue.endDate);

export const filterHasValueChecker = (filterType, filterValue) => {
    let valueFlag = false;
    switch (filterType) {
    case DATE_RANGE:
        if (filterValue) {
            valueFlag = isValidDateRangeObject(filterValue);
        }
        break;
    case DATE:
        valueFlag = isValidDate(filterValue);
        break;
    default:
        valueFlag = isArrayValidAndNotEmpty(filterValue) || isObjectValidAndNotEmpty(filterValue);
    }
    return valueFlag;
};
