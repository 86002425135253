/**
 * Created by sachinprakash on 1/7/19.
 */
import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    ADD_OR_UPDATE_PACKAGE_FAILURE,
    ADD_OR_UPDATE_PACKAGE_SUCCESS,
    FETCH_PACKAGE_LIST_FAILURE,
    FETCH_PACKAGE_LIST_SUCCESS,
} from '../../redux/modules/servicePackage/servicePackage-actions';
import messages from '../../constants/messages';

export const test = 10;

export function* packageListFetchWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        console.log('lkjasdhdlfkjahsdlkjfhaskdff', action);
        yield put({
            type: SHOW_SPINNER,
            state: { canShow: true },
        });
        const response = yield call(retriable().get, action.api, config);
        console.log('sdaghksdfgkasdhfgajsjkdfhlaksjfhlasj', response);
        yield put({
            type: FETCH_PACKAGE_LIST_SUCCESS,
            data: response.data,
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({
                type: SESSION_TIMEOUT,
                actionToDispatchAfterLogin: action,
            });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: e.response && e.response.data && e.response.data.exception,
        });
        yield put({
            type: FETCH_PACKAGE_LIST_FAILURE,
            data: {},
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    }
}


export function* addOrUpdatePackageWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        console.log('lkjasdhdlfkjahsdlkjfhaskdff', action, config);
        yield put({
            type: SHOW_SPINNER,
            state: { canShow: true },
        });
        const response = yield call(retriable().post, action.api, action.payload);
        console.log('sdaghksdfgkasdhfgajsjkdfhlaksjfhlasj', response);
        yield put({
            type: ADD_OR_UPDATE_PACKAGE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.PACKAGE.ADD_SUCCESS });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({
                type: SESSION_TIMEOUT,
                actionToDispatchAfterLogin: action,
            });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: e.response && e.response.data && e.response.data.exception,
        });
        yield put({
            type: ADD_OR_UPDATE_PACKAGE_FAILURE,
            data: {},
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    }
}
