/**
 * Created by vinay on 16/1/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import API from '../../../constants/api';
import { isValidFunction } from '../../../constants/CommonUtil';
import { required } from '../../../constants/FormValidations';
import ActionButton from '../../ActionButton/ActionButton';
import { PAYMENT_METHODS, PAYMENT_METHODS_MAP } from '../../../constants/constants';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import { commonPostApiRequest } from '../../../redux/modules/common/common-actions';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import { getPayload, getRefundUiObject } from './PaidOnAccountUtil';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { REFUND_PAID_ON_ACCOUNT_SUCCESS } from '../../../redux/modules/paidOnAccount/paidOnAccount-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';

const formName = 'PaidOnAccountRefundForm';

const style = theme => ({
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    paper: {
        background: '#fafafa',
    },
    dialogContent: {
        minWidth: '768px',
        overflow: 'visible',
    },
});

/**
 @deprecated as paid on account entity is deprecated
 */
class PaidOnAccountRefundDialog extends React.PureComponent {
    handleCancel = () => {
        const { dispatch, dirty, values } = this.props;
        const readOnly = getStringFromObject('readOnly', values);
        if (!readOnly && dirty) {
            dispatch(displayWarning(
                'You have unsaved changes, select action to confirm !!',
                this.handleSaveConfirm,
                undefined,
                this.props.handleClose,
                undefined,
                'Save Changes',
                'Cancel & Exit',
                'Cancel',
            ));
        } else {
            this.props.handleClose();
        }
    };

    handleReset = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'Any unsaved changes will be lost, are you sure want to continue ?',
            this.props.handleReset,
        ));
    };

    validateAmount = (amount) => {
        const amountResidue = NumberOf(getStringFromObject('amountResidue', this.props.values));
        if (required(amount)) {
            return required(amount);
        } else if (NumberOf(amount) > amountResidue) {
            return `Refund amount should not be more than ${amountResidue}`;
        } else if (NumberOf(amount) < 1) {
            return 'Refund amount must be more than 0';
        }
        return undefined;
    };

    render() {
        const {
            classes,
            values,
            dirty,
            isValid,
            submitForm,
        } = this.props;
        const paymentMethod = getStringFromObject('paymentMode.value', values);
        console.log('agfjahfjka', this.props);
        const isCheque = paymentMethod === PAYMENT_METHODS_MAP.CHEQUE.value;
        const isBank = paymentMethod === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open
                        maxWidth={false}
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    Account Payment Refund
                                </div>
                                <Close className="cursor-pointer" onClick={this.handleCancel} test-id="expense-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent
                            className={classes.dialogContent}
                        >
                            <div>
                                <Grid container className="mt-2" justify="flex-start" spacing={40}>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <Field
                                            testId="paymentDate"
                                            name="date"
                                            component={DateInput}
                                            label="Payment Date"
                                            required
                                            placeholder="Payment Date"
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={3}>
                                        <Field
                                            testId="paymentmethod"
                                            name="paymentMode"
                                            component={FormikReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'label',
                                                value: 'value',
                                            }}
                                            label="Payment Method"
                                            placeholder="Payment Method"
                                            required
                                            validate={required}
                                            options={PAYMENT_METHODS}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    (isCheque || isBank) &&
                                    <Grid container className="mt-2" justify="flex-start" spacing={24}>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                testId="paymentDetails.refNUmber"
                                                name="paymentDetails.refNumber"
                                                component={FormikTextField}
                                                label={isCheque ? 'Cheque Number' : 'Reference Number'}
                                                required
                                                type="number"
                                                placeholder={isCheque ? 'Cheque Number' : 'Reference Number'}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                testId="paymentDetails.bankName"
                                                name="paymentDetails.bankName"
                                                component={FormikTextField}
                                                label="Bank Name"
                                                required
                                                type="text"
                                                placeholder="Bank Name"
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                testId="paymentDetails.date"
                                                name="paymentDetails.date"
                                                component={DateInput}
                                                label={isCheque ? 'Cheque Date' : 'Transfer Date'}
                                                required
                                                placeholder={isCheque ? 'Cheque Date' : 'Transfer Date'}
                                                validate={required}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container className="mt-2" justify="flex-start" spacing={40}>
                                    <Grid item lg={4} md={5} sm={5}>
                                        <Field
                                            testId="amountResidue"
                                            name="amountResidue"
                                            component={FormikTextField}
                                            label="Refundable Amount"
                                            type="number"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={5}>
                                        <Field
                                            testId="amount"
                                            name="amountAdvance"
                                            component={FormikTextField}
                                            label="Amount To Refund"
                                            type="number"
                                            fullWidth
                                            min={1}
                                            required
                                            validate={this.validateAmount}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className="mt-2" justify="center">
                                    <Grid item lg={4} md={5} sm={6}>
                                        <Field
                                            testId="memo"
                                            name="notes"
                                            component={FormikTextField}
                                            label="Notes"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <React.Fragment>
                                <ActionButton
                                    test-id="cancel-expense"
                                    disableRipple
                                    primary={false}
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </ActionButton>
                                <React.Fragment>
                                    <ActionButton
                                        test-id="reset-expense"
                                        disableRipple
                                        disabled={!dirty}
                                        onClick={this.handleReset}
                                        className="ml0-5"
                                    >
                                        Reset
                                    </ActionButton>
                                    <ActionButton
                                        test-id="expense-confirm"
                                        disableRipple
                                        onClick={submitForm}
                                        disabled={!dirty && !isValid}
                                        className="ml0-5"
                                    >
                                        Refund
                                    </ActionButton>
                                </React.Fragment>
                            </React.Fragment>
                        </DialogActions>
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

PaidOnAccountRefundDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object,
};

PaidOnAccountRefundDialog.defaultProps = {
    values: {},
};

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('ahfajflafa submit', values);
    formikBag.setSubmitting(true);
    const callBackFunction = getStringFromObject('callBackFunction', values);
    const payload = getPayload(values);
    console.log('ahfajflafa payload', payload);
    props.dispatch(commonPostApiRequest(API.PAID_ON_ACCOUNT.REFUND, {
        payload,
        successCallback: (r) => {
            if (isValidFunction(callBackFunction)) {
                callBackFunction(r);
            }
            props.handleClose();
            formikBag.setSubmitting(false);
        },
        successAction: REFUND_PAID_ON_ACCOUNT_SUCCESS,
        successMessage: 'Payment on account successfully refunded',
        failureMessage: 'Failed to refund payment on account',
        failureCallBack: () => formikBag.setSubmitting(false),
    }));
};

export default connect()(withFormik({
    mapPropsToValues: props => getRefundUiObject(props.selectedPayment),
    displayName: formName,
    handleSubmit: handleSubmitForm,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
})(withStyles(style)(PaidOnAccountRefundDialog)));
