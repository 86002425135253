import React from 'react';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import Clear from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip/Chip';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { connect } from 'react-redux';

import UploadFile from '../UploadFiles/UploadFile';
import { isValidFunction } from '../../../constants/CommonUtil';
import ImageGallery from '../../GalleryPopup/ImageGallery';
import API from '../../../constants/api';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

class ReduxFormChipFileUpload extends React.PureComponent {
    constructor(props) {
        super(props);
        this.uploaderRef = null;
        this.state = {
            uploading: false,
            openGallery: false,
        };
    }

    onUploadClick = () => {
        if (this.uploaderRef && this.uploaderRef.clickUpload && (!this.props.disabled)) {
            this.uploaderRef.clickUpload();
        }
    };
    onStartUpload = () => {
        this.setState({
            uploading: true,
        });
    };

    onUploadSuccess = () => {
        this.setState({
            uploading: false,
        });
    };

    onDeleteFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {
            input,
            dispatch,
        } = this.props;
        if (isValidFunction(input.onChange)) {
            dispatch(displayWarning(
                'Do you really want to delete this file?',
                () => {
                    input.onChange(null);
                },
            ));
        }
    };

    getUploaderRef = (uploaderRef) => {
        this.uploaderRef = uploaderRef;
    };

    handleChipClick = (token) => {
        if (token === 'Add File') {
            this.onUploadClick();
        } else if (token) {
            this.toggleGallery();
            // const { downloadApi } = this.props;
            // window.open(`${downloadApi}${token}`, '_blank');
        }
    };

    removeUploadedFile = () => {
        if (!(this.props.disabled)) {
            if (this.state.uploading &&
                this.uploaderRef &&
                this.uploaderRef.cancelUpload &&
                typeof this.uploaderRef.cancelUpload === 'function'
            ) {
                this.uploaderRef.cancelUpload();
            }
            // this.props.dispatch(change(this.props.formName, this.state.clickedTab, null));
        }
    };

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.openGallery,
        }));
    };

    render() {
        console.log('sdfsadfsasd12444df', this.props);
        const {
            uploadApi,
            input,
            meta: { touched, error },
            disabled,
            labelLength,
            supportedTypes,
            downloadApi,
            customFileLabel,
            className,
        } = this.props;
        const { openGallery } = this.state;
        const { value } = input;
        const fieldName = input.name;
        let fileName = '';
        let fileToken = '';
        console.log('sadfsdfsdfsdfasdfsdf', this.props, supportedTypes, downloadApi);
        if (isObjectValidAndNotEmpty(value) && (Boolean(value.key) || Boolean(value.value))) {
            fileName = value ? (value.value) : '';
            fileToken = value ? (value.key) : '';
        } else if (value && (typeof value === 'string')) {
            fileName = value;
            fileToken = value;
        } else if (!disabled) {
            fileName = getStringFromObject('value', value) || 'Add File';
            fileToken = getStringFromObject('key', value) || 'Add File';
        }
        let label = (fileName && fileName.length) > labelLength ? fileName.substr(0, labelLength) : fileName;
        if (customFileLabel && disabled) {
            label = fileToken ? customFileLabel : '';
        }
        console.log('adfdaflafdaf', input.value);
        return (
            <div className={className}>
                {
                    (disabled) &&
                    <FormControl>
                        <Chip
                            label={label || 'No File Selected'}
                            style={{
                                maxWidth: '100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.handleChipClick(fileToken)}
                        />
                        {(touched && error) && <FormHelperText htmlFor={fieldName} error>{error}</FormHelperText>}
                    </FormControl>
                }
                {
                    (!disabled) &&
                        <FormControl>
                            <Chip
                                label={label}
                                style={{
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}
                                onClick={() => this.handleChipClick(fileToken)}
                                onDelete={this.onUploadClick}
                                deleteIcon={
                                    !disabled ?
                                        <React.Fragment>
                                            <AttachFile test-id="attach-file" />
                                            {
                                                value &&
                                                <Clear
                                                    onClick={this.onDeleteFile}
                                                />
                                            }
                                        </React.Fragment> :
                                        null
                                }
                            />
                            {(touched && error) && <FormHelperText htmlFor={fieldName} error>{error}</FormHelperText>}
                        </FormControl>
                }
                <div style={{ display: 'none' }}>
                    <UploadFile
                        test-id="upload-file"
                        disabled
                        hideUploadedFileList
                        supportedTypes={supportedTypes}
                        uploadApi={uploadApi}
                        onUploadSuccess={this.onUploadSuccess}
                        onStartUpload={this.onStartUpload}
                        input={input}
                        ref={this.getUploaderRef}
                        dispatch={this.props.dispatch}
                    />
                </div>
                {
                    openGallery &&
                    <ImageGallery
                        images={[
                            {
                                key: getStringFromObject('value.key', input) || getStringFromObject('value', input),
                            },
                        ]}
                        onClose={this.toggleGallery}
                        downloadPDFAPI={API.FILE.DOWNLOAD}
                        downloadAPI={API.FILE.DOWNLOAD}
                        clickedDocument={0}
                    />
                }
            </div>
        );
    }
}

ReduxFormChipFileUpload.propTypes = {
    disabled: PropTypes.bool,
    meta: PropTypes.object,
    uploadApi: PropTypes.string.isRequired,
    customFileLabel: PropTypes.string,
    className: PropTypes.string,
    downloadApi: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    labelLength: PropTypes.number,
    dispatch: PropTypes.func,
    supportedTypes: PropTypes.array,
};

ReduxFormChipFileUpload.defaultProps = {
    disabled: true,
    customFileLabel: '',
    className: '',
    meta: {},
    supportedTypes: [],
    dispatch: () => {},
    labelLength: 30,
};

export default connect()(ReduxFormChipFileUpload);
