/**
 * Created by sachinprakash on 4/6/19.
 */
import API from '../../../constants/api';

export const ASSET_MANUFACTURERS_LIST_FETCH_REQUEST = '@@assetMANUFACTURERS/ASSET_MANUFACTURERS_LIST_FETCH_REQUEST';
export const ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS = '@@assetMANUFACTURERS/ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS';
export const ASSET_MANUFACTURERS_LIST_FETCH_FAILURE = '@@assetMANUFACTURERS/ASSET_MANUFACTURERS_LIST_FETCH_FAILIRE';

export const ASSET_MANUFACTURER_ADD_REQUEST = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_ADD_REQUEST';
export const ASSET_MANUFACTURER_ADD_FAILURE = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_ADD_FAILURE';
export const ASSET_MANUFACTURER_ADD_SUCCESS = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_ADD_SUCCESS';

export const ASSET_MANUFACTURER_UPDATE_REQUEST = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_UPDATE_REQUEST';
export const ASSET_MANUFACTURER_UPDATE_FAILURE = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_UPDATE_FAILURE';
export const ASSET_MANUFACTURER_UPDATE_SUCCESS = '@@assetMANUFACTURERS/ASSET_MANUFACTURER_UPDATE_SUCCESS';

export const fetchManufacturersList = (page, size, filter = {}) => ({
    type: ASSET_MANUFACTURERS_LIST_FETCH_REQUEST,
    api: API.ASSETS.GET_MANUFACTURERS_LIST,
    page,
    size,
    filter,
});

export const addManufacturer = (payload, successCallback) => ({
    type: ASSET_MANUFACTURER_ADD_REQUEST,
    api: API.ASSETS.CREATE_MANUFACTURER,
    payload,
    successCallback,
});

export const updateManufacturer = (payload, successCallback) => ({
    type: ASSET_MANUFACTURER_UPDATE_REQUEST,
    api: API.ASSETS.CREATE_MANUFACTURER,
    payload,
    successCallback,
});
