import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_PAYOR_CATEGORY_REQUEST,
    CREATE_PAYOR_REQUEST, FETCH_PAYOR_DETAILS_REQUEST,
    FETCH_PAYORS_LIST_REQUEST,
    FETCH_PAYORS_RES_PARTNER_LIST_REQUEST,
} from '../../redux/modules/payors/payers/payors-actions';
import {
    createPayorCategoryWorker,
    createPayorWorker, fetchCategoryListWorker,
    fetchPagesOfPayorsWorker,
    fetchPagesOfResPartnerPayorsWorker,
    fetchPayorDetailsWorker, fetchPolicyListWorker,
} from './payorsWorkerSaga';
import { FETCH_SUB_INSURERS_LIST_REQUEST } from '../../redux/modules/payors/subInsurers/subInsurer-actions';
import { FETCH_POLICY_LIST_REQUEST } from '../../redux/modules/payors/policy/policy-actions';
import { FETCH_CATEGORY_LIST_REQUEST } from '../../redux/modules/payors/scheme/scheme-actions';

export function* fetchPagesOfResPartnerPayorsWatcher() {
    yield takeLatest(FETCH_PAYORS_RES_PARTNER_LIST_REQUEST, fetchPagesOfResPartnerPayorsWorker);
}

export function* fetchPagesOfPayorsWatcher() {
    yield takeLatest(FETCH_PAYORS_LIST_REQUEST, fetchPagesOfPayorsWorker);
    yield takeLatest(FETCH_SUB_INSURERS_LIST_REQUEST, fetchPagesOfPayorsWorker);
}

export function* fetchPayorDetails() {
    yield takeLatest(FETCH_PAYOR_DETAILS_REQUEST, fetchPayorDetailsWorker);
}

export function* createPayorWatcher() {
    yield takeLatest(CREATE_PAYOR_REQUEST, createPayorWorker);
}

export function* createPayorCategoryWatcher() {
    yield takeLatest(CREATE_PAYOR_CATEGORY_REQUEST, createPayorCategoryWorker);
}

export function* fetchCategoryListRequest() {
    yield takeLatest(FETCH_CATEGORY_LIST_REQUEST, fetchCategoryListWorker);
}

export function* fetchPolicyListRequest() {
    yield takeLatest(FETCH_POLICY_LIST_REQUEST, fetchPolicyListWorker);
}
