import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    FETCH_HOLIDAY_LIST_FAILURE,
    FETCH_HOLIDAY_LIST_SUCCESS,
    SAVE_HOLIDAY_SUCCESS,
    SAVE_HOLIDAY_FAILURE,
    FETCH_HOLIDAY_FOR_YEAR_SUCCESS,
    FETCH_HOLIDAY_FOR_YEAR_FAILURE,
    FETCH_UPCOMING_HOLIDAY_SUCCESS, FETCH_UPCOMING_HOLIDAY_FAILURE,
} from '../../redux/modules/holidays/holiday-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* holidayFetchRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}?page=${action.page}&size=${action.size}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_HOLIDAY_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_HOLIDAY_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* holidaySaveRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({
            type: SAVE_HOLIDAY_SUCCESS,
            data: response.data,
        });
        yield put({ type: SUCCESS_MESSAGE, message: 'Successfully Saved' });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: SAVE_HOLIDAY_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
export function* fetchHolidayForYearRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}?uuid=${action.uuid}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_HOLIDAY_FOR_YEAR_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_HOLIDAY_FOR_YEAR_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchUpcomingHolidaysRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_UPCOMING_HOLIDAY_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_UPCOMING_HOLIDAY_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
