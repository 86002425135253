import initialStates from '../../../initialStates';
import { CLEAR_SCHEME_STATE, FETCH_CATEGORY_LIST_REQUEST, FETCH_CATEGORY_LIST_SUCCESS } from './scheme-actions';


const schemeReducer = (state = initialStates.subinsurers, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_CATEGORY_LIST_SUCCESS:
        newState = {
            ...state,
            categoryList: action.data.content,
            totalElements: action.data.totalElements,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        };
        break;
    case FETCH_CATEGORY_LIST_REQUEST:
        newState = {
            ...state,
            page: action.page,
            size: action.size,
            filters: action.filters,
        };
        break;
    case CLEAR_SCHEME_STATE:
        newState = initialStates.subinsurers;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default schemeReducer;
