import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import api from '../../../constants/api';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const DRUG = 'DRUG';
const NON_DRUG = 'NON_DRUG';
const GENERIC_DRUG = 'GENERIC_DRUG';

const nonDrugPrescriptionTabs = {
    PRESCRIPTIONS: {
        value: 'PRESCRIPTIONS',
        label: 'PRESCRIPTIONS (NON DRUGS)',
        dataSourceApi: `${api.SEARCH.NON_DRUG_SEARCH}`,
        privileges: ['emr.prescription.edit'],
    },
    PRESCRIPTIONS_TEMPLATE: {
        value: 'PRESCRIPTIONS_TEMPLATE',
        label: 'PRESCRIPTIONS TEMPLATE (NON DRUGS)',
        privileges: ['emr.prescription.edit'],
    },

};

const genericPrescriptionTabs = {
    GENERIC_PRESCRIPTIONS: {
        value: 'GENERIC_PRESCRIPTIONS',
        label: 'PRESCRIPTIONS (GENERIC DRUGS)',
        dataSourceApi: `${api.SEARCH.GENERIC_DRUG}`,
        privileges: ['emr.prescription.edit'],
    },
    GENERIC_PRESCRIPTIONS_TEMPLATE: {
        value: 'GENERIC_PRESCRIPTIONS_TEMPLATE',
        label: 'GENERIC PRESCRIPTIONS TEMPLATE',
        privileges: ['emr.prescription.edit'],
    },
};
const drugPrescriptionTabs = {
    PRESCRIPTIONS: {
        value: 'PRESCRIPTIONS',
        label: 'PRESCRIPTIONS (DRUGS)',
        dataSourceApi: `${api.SEARCH.DRUG_SEARCH}`,
        privileges: ['emr.prescription.edit'],
    },
    PRESCRIPTIONS_TEMPLATE: {
        value: 'PRESCRIPTIONS_TEMPLATE',
        label: 'PRESCRIPTIONS TEMPLATE',
        privileges: ['emr.prescription.edit'],
    },
};

const getPrescriptionFavoriteTypes = (appConfiguration) => {
    let prescriptionFavoriteTypes = {};
    const prescriptionTypesForPayerTypes =
        getStringFromObject('prescription_types_for_payer_types', appConfiguration);
    const enableGenericDrugPrescription = getStringFromObject('enable_generic_drug_prescription', appConfiguration);
    let allPrescriptionTypes = [];
    if (isArrayValidAndNotEmpty(prescriptionTypesForPayerTypes)) {
        prescriptionTypesForPayerTypes.forEach((aPrescriptionTypeForPayerTypes) => {
            if (isArrayValidAndNotEmpty(aPrescriptionTypeForPayerTypes.prescriptionTypes)) {
                allPrescriptionTypes = allPrescriptionTypes.concat(aPrescriptionTypeForPayerTypes.prescriptionTypes
                    .map(aPrescriptionTypes => aPrescriptionTypes.type || ''));
            }
        });
        const isGenericDrugTypePresent = allPrescriptionTypes.includes(GENERIC_DRUG);
        const isDrugTypePresent = allPrescriptionTypes.includes(DRUG);
        const isNonDrugTypePresent = allPrescriptionTypes.includes(NON_DRUG);
        if (isGenericDrugTypePresent && enableGenericDrugPrescription) {
            prescriptionFavoriteTypes = {
                ...prescriptionFavoriteTypes,
                ...genericPrescriptionTabs,
            };
        }
        if (isNonDrugTypePresent && !isDrugTypePresent) {
            prescriptionFavoriteTypes = {
                ...prescriptionFavoriteTypes,
                ...nonDrugPrescriptionTabs,
            };
        }
        if (isDrugTypePresent) {
            prescriptionFavoriteTypes = {
                ...prescriptionFavoriteTypes,
                ...drugPrescriptionTabs,
            };
        }
    } else if (enableGenericDrugPrescription) {
        prescriptionFavoriteTypes = {
            ...prescriptionFavoriteTypes,
            ...genericPrescriptionTabs,
            ...nonDrugPrescriptionTabs,
        };
    } else {
        prescriptionFavoriteTypes = {
            ...prescriptionFavoriteTypes,
            ...drugPrescriptionTabs,
        };
    }
    return prescriptionFavoriteTypes;
};

export const favoriteTypes = (appConfiguration) => {
    const dentalConditionsSetUuid = getStringFromObject('dentalConditionsSetUuid', appConfiguration);
    const dentalTreatmentsSetUuid = getStringFromObject('dentalTreatmentsSetUuid', appConfiguration);
    const enableSaveAsTemplate = getStringFromObject('enableSaveAsTemplate', appConfiguration);
    const conditionSearchApi =
        `${api.CONCEPTS_UNDER_CONCEPT_SET}/${dentalConditionsSetUuid}?size=20&searchString=`;
    const treatmentSearchApi =
        `${api.CONCEPTS_UNDER_CONCEPT_SET}/${dentalTreatmentsSetUuid}?size=20&searchString=`;
    let favTypes = {
        DENTAL_CONDITIONS: {
            value: 'DENTAL_CONDITIONS',
            label: 'DENTAL CONDITIONS',
            dataSourceApi: conditionSearchApi,
            privileges: ['emr.dental.edit'],
        },
        DENTAL_TREATMENTS: {
            value: 'DENTAL_TREATMENTS',
            label: 'DENTAL TREATMENTS',
            dataSourceApi: treatmentSearchApi,
            privileges: ['emr.dental.edit'],
        },
        DIAGNOSES: {
            value: 'DIAGNOSES',
            label: 'DIAGNOSES',
            dataSourceApi: `${api.SEARCH.DIAGNOSIS}`,
            dataSourceConfig: {
                text: 'codedDiagnosis.value',
                key: 'codedDiagnosis.key',
            },
            valueField: 'codedDiagnosis',
            privileges: ['emr.consultation'],
        },
        ORDERS: {
            value: 'ORDERS',
            label: 'ORDERS',
            dataSourceApi: `${api.SEARCH.ORDERABLE_ITEMS}`,
            valueField: 'concept',
            privileges: ['emr.orders.edit'],
        },
        ...getPrescriptionFavoriteTypes(appConfiguration),
    };
    if (enableSaveAsTemplate) {
        favTypes = {
            ...favTypes,
            CONSULTATION: {
                value: 'CONSULTATION',
                label: 'CONSULTATION',
                privileges: ['emr.consultation'],
            },
        };
    }
    return favTypes;
};

export const completeFavoriteObjTypes = (appConfiguration) => {
    let completeFavObjTypes = [];
    if (isObjectValidAndNotEmpty(favoriteTypes(appConfiguration).GENERIC_PRESCRIPTIONS_TEMPLATE)) {
        completeFavObjTypes = [
            ...completeFavObjTypes,
            favoriteTypes(appConfiguration).GENERIC_PRESCRIPTIONS_TEMPLATE.value,
        ];
    }
    if (isObjectValidAndNotEmpty(favoriteTypes(appConfiguration).PRESCRIPTIONS_TEMPLATE)) {
        completeFavObjTypes = [
            ...completeFavObjTypes,
            favoriteTypes(appConfiguration).PRESCRIPTIONS_TEMPLATE.value,
        ];
    }
    if (isObjectValidAndNotEmpty(favoriteTypes(appConfiguration).CONSULTATION)) {
        completeFavObjTypes = [
            ...completeFavObjTypes,
            favoriteTypes(appConfiguration).CONSULTATION.value,
        ];
    }
    return completeFavObjTypes;
};

export const getPrescriptions = (selectedTab) => {
    if (selectedTab === 'PRESCRIPTIONS' || selectedTab === 'GENERIC_PRESCRIPTIONS') {
        return selectedTab;
    }
    return '';
};
