import initialStates from '../../../initialStates';
import {
    CLEAR_PAYOR_STATE,
    CLEAR_SELECTED_PAYER_STATE,
    FETCH_PAYOR_DETAILS_SUCCESS,
    FETCH_PAYORS_LIST_FAILURE,
    FETCH_PAYORS_LIST_REQUEST,
    FETCH_PAYORS_LIST_SUCCESS,
    FETCH_PAYORS_RES_PARTNER_LIST_FAILURE,
    FETCH_PAYORS_RES_PARTNER_LIST_REQUEST,
    FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS,
    SAVE_PARENT_PAYOR,
} from './payors-actions';


const payorReducer = (state = initialStates.payors, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_PAYORS_RES_PARTNER_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_PAYORS_RES_PARTNER_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_PAYORS_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_PAYORS_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_PAYORS_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_PAYOR_DETAILS_SUCCESS:
        newState = Object.assign({}, state, { selectedPayor: action.data });
        break;
    case CLEAR_PAYOR_STATE:
        newState = initialStates.payors;
        break;
    case CLEAR_SELECTED_PAYER_STATE:
        newState = { ...state, selectedPayor: {} };
        break;
    case SAVE_PARENT_PAYOR:
        newState = Object.assign({}, state, { parentPayor: action.parentPayor });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default payorReducer;
