import React from 'react';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel';
import PropTypes from 'prop-types';
import { headerColStyle, headerRowStyle } from '../../../constants/constants';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';

const styles = theme => ({
    checkBox: {
        color: `${theme.palette.common.white}`,
        fontSize: '1rem',
        fontWeight: '500',
        '&$checked': {
            color: `${theme.palette.common.white}`,
        },
    },
    checked: {},
    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    miniCell: {
        minWidth: '75px',
    },
    narrowCell: {
        maxWidth: '150px',
    },
});

class FormikTableHead extends React.Component {
    createSortHandler = property => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            order,
            orderBy,
            tableCells,
            classes,
            hideCheckBox,
            numSelected,
            rowCount,
            onSelectAllClick,
            isExpandable,
            isEditable,
            showRowNumber,
            saveIcon,
            deleteIcon,
            infoIcon,
            additionalButtons,
        } = this.props;


        return (
            <TableHead>
                <TableRow style={headerRowStyle}>
                    {
                        !(hideCheckBox) && isEditable &&
                        <TableCell style={{ ...headerColStyle }}>
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                classes={{
                                    root: classes.checkBox,
                                    checked: classes.checked,
                                }}
                                style={headerColStyle}
                            />
                        </TableCell>
                    }
                    {
                        isExpandable &&
                        <TableCell padding="checkbox" style={{ width: '1em' }} />
                    }
                    {
                        showRowNumber &&
                        <TableCell
                            className={classes.tableCell}
                        >
                            <span style={{ color: 'white', ...headerColStyle, fontSize: '1rem' }}>#</span>
                        </TableCell>
                    }
                    {tableCells.map(row => (
                        <TableCell
                            className={classes.tableCell}
                            key={row.id}
                            test-id={row.id}
                            component={row.component}
                            numeric={row.numeric}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.sortCol ? order : false}
                            style={{ ...row.headerStyle }}
                        >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row.sortCol}
                                    direction={order}
                                    onClick={row.sortCol ? this.createSortHandler(row.sortCol) : () => {}}
                                    style={{
                                        color: 'white',
                                        ...headerColStyle,
                                        ...row.headerStyle,
                                        cursor: row.sortCol ? 'pointer' : 'inherit',
                                    }}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ), this)}
                    {
                        isEditable && (deleteIcon || saveIcon) &&
                        <TableCell style={{ width: '2%' }} />
                    }
                    {
                        infoIcon &&
                            <TableCell style={{ width: '2%' }} />
                    }

                    {
                        isArrayValidAndNotEmpty(additionalButtons) &&
                        additionalButtons.map(e => (
                            <TableCell key={e.name} style={{ width: '2%' }} />
                        ))

                    }
                </TableRow>
            </TableHead>
        );
    }
}

FormikTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    hideCheckBox: PropTypes.bool,
    isEditable: PropTypes.bool.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    tableCells: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    saveIcon: PropTypes.bool,
    infoIcon: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    additionalButtons: PropTypes.array,
};

FormikTableHead.defaultProps = {
    hideCheckBox: false,
    isExpandable: false,
    showRowNumber: false,
    saveIcon: false,
    deleteIcon: false,
    infoIcon: false,
    additionalButtons: [],
};

export default withStyles(styles)(FormikTableHead);
