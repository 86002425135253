import { call, put } from 'redux-saga/effects';

import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_PAYOR_CATEGORY_FAILURE,
    CREATE_PAYOR_CATEGORY_SUCCESS,
    CREATE_PAYOR_FAILURE,
    CREATE_PAYOR_SUCCESS,
    FETCH_PAYOR_DETAILS_FAILURE,
    FETCH_PAYOR_DETAILS_SUCCESS,
    FETCH_PAYORS_LIST_FAILURE,
    FETCH_PAYORS_LIST_SUCCESS,
    FETCH_PAYORS_RES_PARTNER_LIST_FAILURE,
    FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS,
} from '../../redux/modules/payors/payers/payors-actions';
import { generatePayorsSearchConstruct } from './payorSearchConstructUtil';
import { commonHeaders } from '../../constants/constants';
import {
    FETCH_SUB_INSURERS_LIST_REQUEST,
    FETCH_SUB_INSURERS_LIST_SUCCESS,
} from '../../redux/modules/payors/subInsurers/subInsurer-actions';
import { FETCH_POLICY_LIST_FAILURE, FETCH_POLICY_LIST_SUCCESS } from '../../redux/modules/payors/policy/policy-actions';
import {
    FETCH_CATEGORY_LIST_FAILURE,
    FETCH_CATEGORY_LIST_SUCCESS,
} from '../../redux/modules/payors/scheme/scheme-actions';


export function* fetchPagesOfResPartnerPayorsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PAYORS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_PAYORS_RES_PARTNER_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPagesOfPayorsWorker(action) {
    try {
        let searchConstruct = null;
        if (action.filters) {
            searchConstruct = generatePayorsSearchConstruct(action.filters);
        }
        const response = yield call(retriable().post, action.api, {
            searchConstruct,
        });
        let actionToDispatch = FETCH_PAYORS_LIST_SUCCESS;
        if (action.type === FETCH_SUB_INSURERS_LIST_REQUEST) {
            actionToDispatch = FETCH_SUB_INSURERS_LIST_SUCCESS;
        }
        yield put({
            type: actionToDispatch,
            data: response.data,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            filters: action.filters,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PAYORS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_PAYORS_LIST_FAILURE });
    }
}

export function* fetchPayorDetailsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_PAYOR_DETAILS_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PAYORS.DETAILS.FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_PAYOR_DETAILS_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchCategoryListWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload, commonHeaders);
        yield put({
            type: FETCH_CATEGORY_LIST_SUCCESS,
            data: response.data,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PAYOR_CATEGORY.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_CATEGORY_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPolicyListWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload, commonHeaders);
        yield put({
            type: FETCH_POLICY_LIST_SUCCESS,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PAYOR_POLICY.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_POLICY_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createPayorWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_PAYOR_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.PAYORS.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.PAYORS.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_PAYOR_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createPayorCategoryWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_PAYOR_CATEGORY_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.PAYOR_CATEGORY.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.PAYOR_CATEGORY.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_PAYOR_CATEGORY_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
