import React from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import ReactResumableJs from '../ResumableUploader/ResumableUploader';
import ImageGallery from '../../GalleryPopup/ImageGallery';
import API from '../../../constants/api';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { getUniqueFileTypes } from '../../../constants/CommonUtil';

let defaultMessage = '';

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        console.log('Supported Types', props.defaultMessage);
        defaultMessage = `${props.defaultMessage ||
            'Drag And Drop Files or Click To Select'}.
        Supported File Types: ${getUniqueFileTypes(props.supportedTypes)}`;
        this.state = {
            msg:
                props.value && props.value.value
                    ? props.value.value
                    : defaultMessage,
            disableInput: false,
            disableDragAndDrop: false,
            fileToView: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                msg: nextProps.value.value || defaultMessage,
            });
        }
    }

    onSuccessUpload = (file, message) => {
        console.log('Uploaded successfully', file.file.name, message);
        const fileName = file.file.name;
        this.setState({
            msg: fileName,
            disableInput: false,
            disableDragAndDrop: false,
        });
        const keyValueOfFile = {
            key: message,
            value: fileName,
        };
        this.props.input.onChange(keyValueOfFile);
    };

    onCancelUpload = () => {
        this.setState({
            msg: defaultMessage,
            disableInput: false,
            disableDragAndDrop: false,
        });
    };

    onFileAdded = (file, resumable) => {
        console.log('File Added Upload', file.file.name); // eslint-disable-line
        if (this.props.fileAdded !== undefined) {
            this.props.fileAdded();
        }
        this.setState({
            msg: `${this.props.msg}`,
            disableInput: true,
            disableDragAndDrop: true,
        });
        resumable.upload();
    };

    onFileAddError = (file, errorCount) => {
        console.log('FILE ERRORR', file, errorCount);
        // const errorMsgId = generateCustomErrorMessage(`${file.name} is not a supported file type`);
        // this.props.dispatch(errorMessage(errorMsgId));
    };

    onFileClick = () => {
        const uploadedFile = getStringFromObject('value.value', this.props);
        if (uploadedFile) {
            this.setState({
                fileToView: uploadedFile,
            });
        }
    };

    onCloseGallery = () => {
        this.setState({
            fileToView: null,
        });
    };

    onMaxFileSizeErrorCallback = (file) => {
        console.log(file);
        /* const errorMsgId = generateCustomErrorMessage(
      `${file.name} is of Size ${Math.round(file.size / 1048576)}MB Which is More Than The Upload Limit Of 10GB`
    );
    this.props.dispatch(errorMessage(errorMsgId));
    */
    };

    getFileUploaderButton = () => {
        if (!this.props.disabled) {
            return (
                <div style={{ float: 'left' }}>
                    <Button
                        test-id="upload-file-button"
                        onClick={this.clickUpload}
                        variant="contained"
                        color="primary"
                        style={{
                            minHeight: '1rem',
                            height: '2rem',
                            width: '1rem',
                            minWidth: '1rem',
                        }}
                    >
                        <UploadIcon test-id="upload-icon" style={{ fontSize: '1rem' }} />
                    </Button>
                </div>
            );
        }
        return null;
    };

    removeFileFromFileList = (fileToRemove) => {
        console.log('the file uploader remove list', this.resumableJs);
        this.resumableJs.removeImage(fileToRemove);
    };

    clickUpload = () => {
        console.log('the uploader', this.resumableJs);
        if (this.resumableJs.uploader) {
            this.resumableJs.uploader.click();
        }
    };

    maxFilesErrorCallback = () => {
        /*
            const errorMsgId =
            generateCustomErrorMessage(`You Can't Upload More Than ${this.props.maxFiles} File At a Time`);
            this.props.dispatch(errorMessage(errorMsgId));
        */
    };

    render() {
        console.log('Upload file disable', this.props);
        const {
            fileToView,
        } = this.state;
        return (
            <React.Fragment>
                <ReactResumableJs
                    ref={input => (this.resumableJs = input)} // eslint-disable-line
                    uploaderID={`${Math.random()}`}
                    style={{ float: 'left' }}
                    maxFileSize={10240000000}
                    dropTargetID="myDropTarget"
                    filetypes={this.props.supportedTypes}
                    fileAccept="*/*"
                    disableDragAndDrop={this.state.disableDragAndDrop}
                    hideInputTag
                    browseFileButton={this.getFileUploaderButton()}
                    disableInput={this.state.disableInput}
                    fileAddedMessage="Started!"
                    completedMessage="Uploaded"
                    previousText={this.state.msg}
                    service={this.props.uploadApi}
                    showFileList={false}
                    cancelButton="Cancel"
                    onFileSuccess={this.onSuccessUpload}
                    onFileClick={this.onFileClick}
                    onFileAdded={this.onFileAdded}
                    onFileAddedError={this.onFileAddError}
                    onFileRemoved={() => this.forceUpdate()}
                    onMaxFileSizeErrorCallback={this.onMaxFileSizeErrorCallback}
                    maxFilesErrorCallback={this.maxFilesErrorCallback}
                    maxFiles={this.props.maxFiles}
                    onCancelUpload={this.onCancelUpload}
                    dispatch={this.props.dispatch}
                />
                {
                    fileToView &&
                    <ImageGallery
                        images={[
                            {
                                key: fileToView,
                            },
                        ]}
                        onClose={this.onCloseGallery}
                        downloadPDFAPI={API.FILE.DOWNLOAD}
                        downloadAPI={API.FILE.DOWNLOAD}
                        clickedDocument={0}
                    />
                }
            </React.Fragment>
        );
    }
}

UploadFile.propTypes = {
    supportedTypes: PropTypes.array,
    msg: PropTypes.string,
    defaultMessage: PropTypes.string,
    fileAdded: PropTypes.func,
    dispatch: PropTypes.func,
    maxFiles: PropTypes.number,
    disabled: PropTypes.bool,
    input: PropTypes.object,
    value: PropTypes.object,
    uploadApi: PropTypes.string.isRequired,
};

UploadFile.defaultProps = {
    supportedTypes: [],
    msg: '',
    defaultMessage: '',
    fileAdded: () => {},
    dispatch: () => {},
    maxFiles: 1,
    disabled: false,
    input: {},
    value: {},
};

export default UploadFile;
