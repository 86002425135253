import API from '../../../constants/api';

export const FETCH_INSURANCE_REQUEST_LINES_REQUEST = '@@IRL/FETCH_INSURANCE_REQUEST_LINES_REQUEST';
export const FETCH_INSURANCE_REQUEST_LINES_SUCCESS = '@@IRL/FETCH_INSURANCE_REQUEST_LINES_SUCCESS';
export const FETCH_INSURANCE_REQUEST_LINES_FAIL = '@@IRL/FETCH_INSURANCE_REQUEST_LINES_FAIL';

export const FETCH_INSURANCE_REQUESTS_REQUEST = '@@IRL/FETCH_INSURANCE_REQUESTS_REQUEST';
export const FETCH_INSURANCE_REQUESTS_SUCCESS = '@@IRL/FETCH_INSURANCE_REQUESTS_SUCCESS';
export const FETCH_INSURANCE_REQUESTS_FAIL = '@@IRL/FETCH_INSURANCE_REQUESTS_FAIL';


export const fetchInsuranceRequestLines = (
    page,
    size,
    filters,
    searchFilter,
) => ({
    type: FETCH_INSURANCE_REQUEST_LINES_REQUEST,
    payload: {
        api: API.INSURANCE_REQUESTS.LIST_LINES,
        page,
        size,
        filters,
        searchFilter,
    },
});

export const fetchInsuranceRequests = (
    page,
    size,
    filters,
    searchFilter,
) => ({
    type: FETCH_INSURANCE_REQUESTS_REQUEST,
    payload: {
        api: API.INSURANCE_REQUESTS.REQUEST_LIST,
        page,
        size,
        filters,
        searchFilter,
    },
});
