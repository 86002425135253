import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    discountSettings,
    globalPropertySettings,
    hrPayrollPeriodSettings,
    mrsLocationSettings,
    privCardCompanySettings,
    ruleInputCodeSettings,
    salaryRuleSettings,
    salaryStructureSettings,
    saleShopSettings,
    stockLocationSettings,
} from '../constants/constants';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const MRSLocationSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/MRSLocationSettingsPage'));
const StockLocationSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/StockLocationSettingsPage'));
const HrPayrollPeriodSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/HrPayrollPeriodSettingsPage'));
const privCardCompanyPage = React.lazy(() => import('../pages/MiscSettingsPages/PrivCardCompanyPage'));
const SaleShopSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/SaleShopSettingsPage'));
const SalaryStructureSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/SalaryStructureSettingsPage'));
const SalaryRuleSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/SalaryRuleSettingsPage'));
const RuleInputCodeSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/RuleInputCodeSettingsPage'));
const GlobalPropertySettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/GlobalPropertySettingsPage'));
const DiscountSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/DiscountSettingsPage'));

const MiscSettingsRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={mrsLocationSettings} exact component={MRSLocationSettingsPage} />
            <Route path={stockLocationSettings} exact component={StockLocationSettingsPage} />
            <Route path={hrPayrollPeriodSettings} exact component={HrPayrollPeriodSettingsPage} />
            <Route path={saleShopSettings} exact component={SaleShopSettingsPage} />
            <Route path={salaryStructureSettings} exact component={SalaryStructureSettingsPage} />
            <Route path={salaryRuleSettings} exact component={SalaryRuleSettingsPage} />
            <Route path={ruleInputCodeSettings} exact component={RuleInputCodeSettingsPage} />
            <Route path={globalPropertySettings} exact component={GlobalPropertySettingsPage} />
            <Route path={discountSettings} exact component={DiscountSettingsPage} />
            <Route path={privCardCompanySettings} exact component={privCardCompanyPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default MiscSettingsRoutes;
