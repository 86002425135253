import { CHANGE_ROUTE_REQUEST } from './navigator-actions';

const navigatorReducer = (state = '', action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case CHANGE_ROUTE_REQUEST:
        newState = action.route;
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default navigatorReducer;
