/**
 * Created by vinay on 20/5/19.
 */
import API from '../../../constants/api';

export const CREATE_ADVANCE_PAYMENT_REQUEST = '@@advancePayment/CREATE_ADVANCE_PAYMENT_REQUEST';
export const CREATE_ADVANCE_PAYMENT_FAILURE = '@@advancePayment/CREATE_ADVANCE_PAYMENT_FAILURE';
export const CREATE_ADVANCE_PAYMENT_SUCCESS = '@@advancePayment/CREATE_ADVANCE_PAYMENT_SUCCESS';

export const FETCH_ADVANCE_PAYMENT_REQUEST = '@@advancePayment/FETCH_ADVANCE_PAYMENT_REQUEST';
export const FETCH_ADVANCE_PAYMENT_FAILURE = '@@advancePayment/FETCH_ADVANCE_PAYMENT_FAILURE';
export const FETCH_ADVANCE_PAYMENT_SUCCESS = '@@advancePayment/FETCH_ADVANCE_PAYMENT_SUCCESS';

export const CLEAR_ADVANCE_PAYMENT_SELECTED = '@@advancePayment/CLEAR_ADVANCE_PAYMENT_SELECTED';

export const createAdvancePayment = (payload, successCallBack, failureCallBack) => ({
    type: CREATE_ADVANCE_PAYMENT_REQUEST,
    api: API.ADVANCE_PAYMENT.CREATE,
    payload,
    successAction: CREATE_ADVANCE_PAYMENT_SUCCESS,
    successMessage: '',
    successCallBack,
    failureAction: CREATE_ADVANCE_PAYMENT_FAILURE,
    failureMessage: '',
    failureCallBack,
});

export const findAdvancePaymentWithUuid = (payload, successCallBack, failureCallBack) => ({
    type: CREATE_ADVANCE_PAYMENT_REQUEST,
    api: API.ACCOUNT_VOUCHER.ADVANCE_PAYMENT,
    payload,
    successAction: CREATE_ADVANCE_PAYMENT_SUCCESS,
    successMessage: '',
    successCallBack,
    failureAction: CREATE_ADVANCE_PAYMENT_FAILURE,
    failureMessage: '',
    failureCallBack,
});
