import { call, put } from 'redux-saga/effects';
import {
    DEPARTMENT_CREATE_FAILURE,
    DEPARTMENT_CREATE_REQUEST,
    DEPARTMENT_CREATE_SUCCESS,
    DEPARTMENT_LIST_FETCH_FAILURE,
    DEPARTMENT_LIST_FETCH_SUCCESS,
    DEPARTMENT_UPDATE_FAILURE,
    DEPARTMENT_UPDATE_SUCCESS,
    SERVICE_CREATE_FAILURE,
    SERVICE_CREATE_REQUEST,
    SERVICE_CREATE_SUCCESS,
    SERVICE_LIST_FETCH_FAILURE,
    SERVICE_LIST_FETCH_SUCCESS,
    SERVICE_UPDATE_FAILURE,
    SERVICE_UPDATE_SUCCESS,
} from '../../redux/modules/departmentService/department-service-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { retriable } from '../retriable';
import message from '../../constants/messages';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';

export function* fetchDepartmentsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: DEPARTMENT_LIST_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        console.error('error in api call', message);
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: `${message.DEPARTMENT.LIST_FETCH_FAILED} : ${getErrorMessage(e)}`,
        });
        yield put({ type: DEPARTMENT_LIST_FETCH_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* updateDepartmentWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        let response = null;
        let sucessMessage = '';
        if (action.type === DEPARTMENT_CREATE_REQUEST) {
            response = yield call(retriable().post, action.api, action.department);
            yield put({ type: DEPARTMENT_CREATE_SUCCESS, data: response.data });
            sucessMessage = message.DEPARTMENT.CREATE.SUCCESS;
        } else {
            response = yield call(retriable().put, action.api, action.department);
            yield put({ type: DEPARTMENT_UPDATE_SUCCESS, data: response.data });
            sucessMessage = message.DEPARTMENT.UPDATE.SUCCESS;
        }
        yield put({
            type: SUCCESS_MESSAGE,
            data: response.data,
            message: sucessMessage,
        });
        if (isValidFunction(action.successCallback)) {
            const department = response.data.department || {};
            action.successCallback({ uuid: department.key, name: department.value });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let error = '';
            if (action.type === DEPARTMENT_CREATE_REQUEST) {
                yield put({ type: DEPARTMENT_CREATE_FAILURE });
                error = message.DEPARTMENT.CREATE.FAILED;
            } else {
                yield put({ type: DEPARTMENT_UPDATE_FAILURE });
                error = message.DEPARTMENT.UPDATE.FAILED;
            }
            yield put({
                type: ERROR_MESSAGE,
                message: `${error} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createOrUpdateServiceWorker(action) {
    try {
        console.log('Sande', action);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().put, action.api, action.department);
        let sucessMessage = '';
        if (action.type === SERVICE_CREATE_REQUEST) {
            yield put({ type: SERVICE_CREATE_SUCCESS, data: response.data });
            sucessMessage = message.SERVICE.CREATE.SUCCESS;
        } else {
            yield put({ type: SERVICE_UPDATE_SUCCESS, data: response.data });
            sucessMessage = message.SERVICE.UPDATE.SUCCESS;
        }
        yield put({
            type: SUCCESS_MESSAGE,
            data: response.data,
            message: sucessMessage,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let error = '';
            if (action.type === SERVICE_CREATE_REQUEST) {
                yield put({ type: SERVICE_CREATE_FAILURE });
                error = message.SERVICE.CREATE.FAILED;
            } else {
                yield put({ type: SERVICE_UPDATE_FAILURE });
                error = message.SERVICE.UPDATE.FAILED;
            }
            yield put({
                type: ERROR_MESSAGE,
                message: `${error} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchServicesWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: SERVICE_LIST_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        console.error('error in api call', message);
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: `${message.SERVICE.LIST_FETCH_FAILED} : ${getErrorMessage(e)}`,
        });
        yield put({ type: SERVICE_LIST_FETCH_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
