import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    FETCH_PAYMENT_ADVICE_FAILURE,
    FETCH_PAYMENT_ADVICE_LIST_FAILURE,
    FETCH_PAYMENT_ADVICE_LIST_SUCCESS,
    FETCH_PAYMENT_ADVICE_SUCCESS,
    FETCH_PAYROLL_PAYSLIP_FAILURE,
    FETCH_PAYROLL_PAYSLIP_SUCCESS,
    FETCH_PAYROLL_REPORT_FAILURE,
    FETCH_PAYROLL_REPORT_SUCCESS,
    FETCH_PAYSLIP_BATCHES_FAILURE,
    FETCH_PAYSLIP_BATCHES_SUCCESS,
    SAVE_EMP_PAYSLIP_INPUT_FAILURE,
    SAVE_EMP_PAYSLIP_INPUT_SUCCESS,
    SAVE_PAYMENT_ADVICE_FAILURE,
    SAVE_PAYMENT_ADVICE_SUCCESS,
    SEARCH_EMP_BY_DEPT_FAILURE,
    SEARCH_EMP_BY_DEPT_SUCCESS,
    VERIFY_PAYMENT_BATCH_FAILURE,
    VERIFY_PAYMENT_BATCH_SUCCESS,
} from '../../redux/modules/payroll/payroll-actions';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    getUrlWithApiParams,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    generatePaymentAdviceConstruct,
    generatePayrollBatchesConstruct,
    generatePayslipsConstruct,
} from './payrollSearchConstructUtil';

const config = {
    headers: { 'Content-Type': 'application/json' },
};

export function* searchEmpByDeptRequest(action) {
    const { deptId, name } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}?departmentId=${deptId}&name=${name}&page=0&size=15`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: SEARCH_EMP_BY_DEPT_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let message = 'Network Error !!!';
            if (e.response && e.response.data && e.response.data.exception) {
                message = e.response.data.exception;
            }
            yield put({ type: ERROR_MESSAGE, message });
        }
        yield put({ type: SEARCH_EMP_BY_DEPT_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPayslipBatchesWorker(action) {
    const {
        page, size, sortOrder, sortCol, api, filters,
    } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const payload = generatePayrollBatchesConstruct(filters);
        const apiWithQueryParam = getUrlWithApiParams(api, {
            page, size, sortOrder, sortCol,
        });
        const response = yield call(retriable().post, apiWithQueryParam, payload, config);
        yield put({
            type: FETCH_PAYSLIP_BATCHES_SUCCESS,
            data: response.data,
            sortOrder,
            sortCol,
            filters,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_PAYSLIP_BATCHES_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* savePayslipInputWorker(action) {
    const { payload, api } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const response = yield call(retriable().post, api, payload);
        yield put({
            type: SAVE_EMP_PAYSLIP_INPUT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: SUCCESS_MESSAGE, message: 'Successfully Saved' });
    } catch (e) {
        // do error action
        console.error('errorrrrr', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        yield put({ type: SAVE_EMP_PAYSLIP_INPUT_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* verifyPaymentBatchRequest(action) {
    const { payload, api } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const response = yield call(retriable().post, api, payload);
        yield put({
            type: VERIFY_PAYMENT_BATCH_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: VERIFY_PAYMENT_BATCH_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPaymentAdviceListRequest(action) {
    const {
        page, size, api, filters,
    } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithParams = `${api}?page=${page}&size=${size}`;
        const payload = generatePaymentAdviceConstruct(filters);
        const response = yield call(retriable().post, apiWithParams, payload, config);
        yield put({
            type: FETCH_PAYMENT_ADVICE_LIST_SUCCESS,
            data: response.data,
            filters,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_PAYMENT_ADVICE_LIST_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPaymentAdviceWorker(action) {
    const { uuid, api } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithParams = `${api}?uuid=${uuid}`;
        const response = yield call(retriable().get, apiWithParams);
        yield put({
            type: FETCH_PAYMENT_ADVICE_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_PAYMENT_ADVICE_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* savePaymentAdviceWorker(action) {
    const { payload, api } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const response = yield call(retriable().post, api, payload);
        yield put({
            type: SAVE_PAYMENT_ADVICE_SUCCESS,
            data: response.data,
        });
        yield put({ type: SUCCESS_MESSAGE, message: 'Successfully Saved' });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: SAVE_PAYMENT_ADVICE_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPayrollPayslipRequest(action) {
    const {
        page, size, api, filters,
    } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const payload = generatePayslipsConstruct(filters);
        const apiWithParams = `${api}?page=${page}&size=${size}&name=`;
        const response = yield call(retriable().post, apiWithParams, payload, config);
        yield put({
            type: FETCH_PAYROLL_PAYSLIP_SUCCESS,
            data: response.data,
            filters,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_PAYROLL_PAYSLIP_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPayrollReportRequest(action) {
    const { startDate, api } = action;
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithParams = `${api}?startDate=${startDate}`;
        const response = yield call(retriable().get, apiWithParams);
        yield put({
            type: FETCH_PAYROLL_REPORT_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_PAYROLL_REPORT_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
