import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    ADD_PATIENT_FAILURE,
    ADD_PATIENT_SUCCESS,
    FETCH_PATIENT_SUCCESS,
    UPDATE_PATIENT_FAILURE,
    UPDATE_PATIENT_SUCCESS,
} from '../../redux/modules/patientDetails/patientDetails-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { SET_PATIENT_UUID } from '../../redux/modules/appointmentDetails/appointmentDetails-actions';
import { SET_VISIT_RELATED_INFO } from '../../redux/modules/visitDetails/visitDetails-actions';
import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* addPatientWorkerSaga(action) {
    try {
        console.log('0999iaisjkdlassww', action);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.patient);
        console.log('searching', response.data);
        yield put({
            type: ADD_PATIENT_SUCCESS,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: { id: 'patientAddForm.add.success', defaultMessage: 'Patient Registered Successfully' },
        });
        yield put({
            type: SET_PATIENT_UUID,
            patientUuid: response.data,
        });
        yield call(action.successCallback, response.data);

        // if the newly registered patient is a person
        // who had an appointment, then department, service, appointmentType and doctor,
        // must be sent to in the URL so that is can be prefilled in visit screen
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.error('there has been api call error', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: getErrorMessage(e),
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: ADD_PATIENT_FAILURE });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* addRegistrationWorkerSaga(action) {
    try {
        const {
            saveOnlyPatientDetails,
        } = action;
        console.log('0999iaisjkdlassww', action);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.patient);
        console.log('searching', response.data);
        yield put({
            type: ADD_PATIENT_SUCCESS,
        });
        if (saveOnlyPatientDetails) {
            yield put({
                type: SUCCESS_MESSAGE,
                message: { id: 'patientAddForm.add.success', defaultMessage: 'Patient Registered Successfully' },
            });
        } else {
            yield put({
                type: SUCCESS_MESSAGE,
                message: { id: 'patientAddForm.episode.success', defaultMessage: 'Operation Successful' },
            });
        }
        if (isValidFunction(action.successCallback)) {
            yield call(action.successCallback, response.data, action.formValues);
        }

        const visitUuid = getStringFromObject('visitUuid', response.data);
        const visitType = getStringFromObject('visitType', response.data);
        if (visitUuid) {
            const response2 =
                yield call(retriable().get, `${API.PATIENT.LAST_VISIT_EPISODE_ATTRIBUTES}${visitUuid}`);
            yield put({
                type: SET_VISIT_RELATED_INFO,
                payload: {
                    visitType,
                    visitUuid,
                    previousVisitEpisodeAttributes: response2.data,
                },
            });
            yield put({
                type: SET_VISIT_RELATED_INFO,
                payload: {
                    visitType,
                    visitUuid,
                    previousVisitEpisodeAttributes: response2.data,
                },
            });
        }
        // if the newly registered patient is a person
        // who had an appointment, then department, service, appointmentType and doctor,
        // must be sent to in the URL so that is can be prefilled in visit screen
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.error('there has been api call error', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: getErrorMessage(e),
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: ADD_PATIENT_FAILURE });
        if (isValidFunction(action.failCallback)) {
            yield call(action.failCallback);
        }
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* updatePatientWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, `${action.api}${action.patientUuid}`, action.patient);
        console.log('searching', response.data);
        console.log('23sadsadsad92131', action.patient);
        yield put({
            type: UPDATE_PATIENT_SUCCESS,
            editable: false,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: { id: 'patientAddForm.update.success', defaultMessage: 'Patient Updated Successfully' },
        });
        // do this in the success callback if necessary when migrating to old reg
        /* yield put({
            type: FETCH_PATIENT_REQUEST,
            api: API.PATIENT.FETCH,
            patientUuid: action.patientUuid,
        }); */
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'patientAddForm.update.fail', defaultMessage: 'Failed To Update Patient' },
        });
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'a.b', defaultMessage: getErrorMessage(e) },
        });
        yield put({ type: UPDATE_PATIENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* updateRegistrationWorkerSaga(action) {
    try {
        const {
            saveOnlyPatientDetails,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        console.log('asd0s9aud0asu9dasda', action.patient);
        const response = yield call(retriable().post, `${action.api}${action.patientUuid}`, action.patient);
        console.log('searching', response.data);
        console.log('23sadsadsad92131', action.patient);
        yield put({
            type: UPDATE_PATIENT_SUCCESS,
            editable: false,
        });
        yield put({
            type: UPDATE_PATIENT_SUCCESS,
            editable: false,
        });
        // do this in the success callback if necessary when migrating to old reg
        /* yield put({
            type: FETCH_PATIENT_REQUEST,
            api: API.PATIENT.FETCH,
            patientUuid: action.patientUuid,
        }); */
        if (saveOnlyPatientDetails) {
            yield put({
                type: SUCCESS_MESSAGE,
                message: { id: 'patientAddForm.update.success', defaultMessage: 'Patient Updated Successfully' },
            });
        } else {
            yield put({
                type: SUCCESS_MESSAGE,
                message: { id: 'patientAddForm.episode.success', defaultMessage: 'Operation Successful' },
            });
        }
        const visitUuid = getStringFromObject('visitUuid', response.data);
        const visitType = getStringFromObject('visitType', response.data);
        console.log('asdoapjsmdsakldadad', response.data);
        if (visitUuid) {
            console.log('asdoapjsmdsakldadad', visitUuid);
            const response2 =
                yield call(retriable().get, `${API.PATIENT.LAST_VISIT_EPISODE_ATTRIBUTES}${visitUuid}`);
            yield put({
                type: SET_VISIT_RELATED_INFO,
                payload: {
                    visitType,
                    visitUuid,
                },
            });
            yield put({
                type: SET_VISIT_RELATED_INFO,
                payload: {
                    visitType,
                    visitUuid,
                    previousVisitEpisodeAttributes: response2.data,
                },
            });
        }
        if (isValidFunction(action.successCallback)) {
            yield call(action.successCallback, response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'patientAddForm.update.fail', defaultMessage: 'Failed To Update Patient' },
        });
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'a.b', defaultMessage: getErrorMessage(e) },
        });
        if (isValidFunction(action.failCallback)) {
            yield call(action.failCallback);
        }
        yield put({ type: UPDATE_PATIENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* fetchPatientWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, `${action.api}${action.patientUuid}`);
        yield put({
            type: FETCH_PATIENT_SUCCESS,
            patientDetails: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.log('searching', response.data);
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'patientAddForm.fetch.fail', defaultMessage: 'Failed To Fetch Patient Details' },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.error('error in api call', e);
        // yield error({ type: SEARCH_FAILURE });
    }
}
export default addPatientWorkerSaga;
