/**
 * Created by sachinprakash on 21/6/19.
 */
import { takeLatest } from 'redux-saga/effects';

import { addOrderableServiceWorker, addProductServiceWorker, productsListFetch } from './productsWorkerSaga';
import {
    ADD_ORDERABLE_SERVICES_REQUEST,
    ADD_PRODUCT_SERVICES_REQUEST,
    FETCH_PRODUCTS_REQUEST,
} from '../../redux/modules/servicesPage/products-actions';

export function* productFetchWatcher() {
    yield takeLatest(FETCH_PRODUCTS_REQUEST, productsListFetch);
}

export function* addProductServiceWatcher() {
    yield takeLatest(ADD_PRODUCT_SERVICES_REQUEST, addProductServiceWorker);
}

export function* addOrderableServiceWatcher() {
    yield takeLatest(ADD_ORDERABLE_SERVICES_REQUEST, addOrderableServiceWorker);
}
