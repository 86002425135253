import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Clear';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import dialogComponentStyles from './DialogComponentStyles';

class DialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogActions: props.dialogActions,
            dialogRef: null,
        };
    }

    updateDialogActions = (dialogActions) => {
        this.setState({
            dialogActions,
        });
    };

    dialogRef = (dialogRef) => {
        if (!this.state.dialogRef) {
            this.setState({
                dialogRef,
            });
        }
    };

    render() {
        const {
            classes,
            open,
            header,
            handleClose,
            children,
            fullScreen,
            handleClick,
            maxWidth,
            disableEnforceFocus,
            disableEscapeKeyDown,
            disableBackdropClick,
        } = this.props;
        const {
            dialogActions,
            dialogRef,
        } = this.state;
        const childrenWithProps = React.Children.map(children, child =>
            child && React.cloneElement(child, { updateDialogActions: this.updateDialogActions, dialogRef }),
        );
        let propsToSpread = {};
        if (!this.props.fullScreen) {
            propsToSpread = { ...this.props };
        }
        return (
            <Dialog
                open={open}
                maxWidth={maxWidth}
                scroll="body"
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
                ref={this.dialogRef}
                onClick={handleClick}
                onClose={handleClose}
                disableEnforceFocus={disableEnforceFocus}
                disableEscapeKeyDown={disableEscapeKeyDown}
                disableBackdropClick={disableBackdropClick}
                {...propsToSpread}
            >
                <DialogTitle id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>{header}</div>
                        <Close
                            test-id={`${header}-close`}
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={handleClose}
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent
                    className={classes.dialogContent}
                    style={{ overflowY: this.props.overflowY }}
                >
                    {childrenWithProps}
                </DialogContent>
                {
                    dialogActions &&
                    <DialogActions style={{ padding: '12px', marginTop: '0px' }}>
                        {dialogActions}
                    </DialogActions>
                }
            </Dialog>
        );
    }
}

DialogComponent.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.string,
    overflowY: PropTypes.string,
    open: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    disableEnforceFocus: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    dialogActions: PropTypes.node,
    children: PropTypes.node,
    handleClose: PropTypes.func,
    handleClick: PropTypes.func,
    maxWidth: PropTypes.oneOf(['lg', 'md']),
};

DialogComponent.defaultProps = {
    classes: {},
    header: '',
    open: false,
    fullScreen: false,
    disableEnforceFocus: false,
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    fullWidth: true,
    dialogActions: null,
    children: null,
    maxWidth: 'md',
    handleClose: () => {},
    handleClick: () => {},
    overflowY: '',
};

export default withStyles(dialogComponentStyles)(withMobileDialog()(DialogComponent));

