import axios from 'axios';
import {
    apiCatchBlockFunction,
    isStringNullOrUndefined,
} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
// import { extractInfoFromBarcode } from '../../../constants/BarcodeParser';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { extractInfoFromBarcode, extractInfoFromBarcodeXML } from '../../../constants/BarcodeParser';
import { BarcodeParsers } from '../../../constants/constants';
import { addOrSubtractDays } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const internalMoveFields = {
    SOURCE_LOC: 'sourceLoc',
    DEST_LOC: 'destinationLoc',
    OPERATION_TYPE: 'operationType',
    PRODUCTS: 'products',
    NOTES: 'notes',
};
export const expiryLegend = {
    currentDatePlus30Days: 'Expires in 30',
    currentDatePlus90Days: 'Expires in 90',
    currentDatePlus180Days: 'Expires in 180',
};
export const expiryDateColors = {
    currentDatePlus30Days: 'rgb(239,154,154)',
    currentDatePlus90Days: 'rgb(255,179,0)',
    currentDatePlus180Days: 'rgb(165,214,167)',
};
export const getColorByExpiryDate = (expiry) => {
    const expiryDate = new Date(expiry);
    const currentDatePlus30Days = addOrSubtractDays(new Date(), 30);
    const currentDatePlus90Days = addOrSubtractDays(new Date(), 90);
    const currentDatePlus180Days = addOrSubtractDays(new Date(), 180);
    let rowBackgroundColor;
    if (currentDatePlus180Days.getTime() > expiryDate.getTime()) {
        // color green
        rowBackgroundColor = expiryDateColors.currentDatePlus180Days;
    }
    if (currentDatePlus90Days.getTime() > expiryDate.getTime()) {
        // med yellow
        rowBackgroundColor = expiryDateColors.currentDatePlus90Days;
    }
    if (currentDatePlus30Days.getTime() > expiryDate.getTime()) {
        // red
        rowBackgroundColor = expiryDateColors.currentDatePlus30Days;
    }
    return rowBackgroundColor;
};

export const otherOperationTypes = {
    LOAN_RECEIVE: {
        key: 'loanReceive',
        value: 'Loan Receive',
    },
    GIVE_LOAN: {
        key: 'giveLoan',
        value: 'Give Loan',
    },
    EXTRA_BONUS: {
        key: 'extraBonus',
        value: 'Extra Bonus',
    },
    REPLACEMENT_FROM_DOCTOR: {
        key: 'replacementFromDoctor',
        value: 'Replacement From Doctor',
    },
    RETURN_EXPIRED_PRODS_TO_REP: {
        key: 'returnExpiredProds',
        value: 'Return Expired Products To Rep',
    },
    EXPIRED_PRODS_REPLACEMENT: {
        key: 'expiredProdsReplacement',
        value: 'Replacement for Expired Products',
    },
};

export const otherOperationsWhichRequireSourceInput = [
    otherOperationTypes.GIVE_LOAN.key,
    otherOperationTypes.RETURN_EXPIRED_PRODS_TO_REP.key,
];

export const otherOperationsWhichRequireDestInput = [
    otherOperationTypes.LOAN_RECEIVE.key,
    otherOperationTypes.EXTRA_BONUS.key,
    otherOperationTypes.REPLACEMENT_FROM_DOCTOR.key,
    otherOperationTypes.EXPIRED_PRODS_REPLACEMENT.key,
];

export const getIndexToAdd = (values, fieldName) => {
    const lastIndex = getStringFromObject(`${fieldName}.length`, values) - 1;
    const productAtLengthIndex = getStringFromObject(`${fieldName}[${lastIndex}]`, values);
    let indexToAddTo = -1;
    if (!isObjectValidAndNotEmpty(productAtLengthIndex)) {
        indexToAddTo = lastIndex;
    } else if (isObjectValidAndNotEmpty(productAtLengthIndex.product)) {
        indexToAddTo = lastIndex + 1;
    } else {
        indexToAddTo = lastIndex;
    }
    return indexToAddTo;
};

export const PRODUCT_CODE_FIELD = {
    DEFAULT_CODE: 'defaultCode',
    EAN_13: 'ean13',
};

export const getProductDetailsFromScannedBarcode = async (scannedBarcode, dispatcher, onlyBatch, barcodeParser) => {
    console.log('barcodeParser:2312', barcodeParser);
    let prodInfo = null;
    if (scannedBarcode) {
        try {
            const barcodeType = scannedBarcode[0] || '';
            const batchOrProductId = scannedBarcode.substr(1, scannedBarcode.length - 1);
            const isProduct = barcodeType.toLowerCase() === 'p';
            if (
                isProduct ||
                barcodeType.toLowerCase() === 'b'
            ) {
                if (onlyBatch && isProduct) {
                    dispatcher(errorMessage('Only Batch barcodes supported'));
                    return null;
                }
                console.log('asdpod-0asid-0sd', barcodeType, batchOrProductId);
                const response =
                    await axios.get(
                        `${API.PRODUCT.GET_PRODUCT_OR_BATCH_FROM_BARCODE}${batchOrProductId}&isProduct=${isProduct}`,
                    );
                prodInfo = response.data;
                prodInfo.codeField = PRODUCT_CODE_FIELD.DEFAULT_CODE;
            } else if (scannedBarcode.length < 15) { // Just ean number, without batch
                prodInfo = {
                    produceCode: scannedBarcode,
                };
                prodInfo.codeField = PRODUCT_CODE_FIELD.EAN_13;
            } else {
                let extractInfoFromBarcodeFunction = extractInfoFromBarcodeXML;
                let scannedBarCodeFinal = scannedBarcode.replaceAll(/DFI=(@GS1@)/gi, 'dfi="GS1"');
                if (!isStringNullOrUndefined(barcodeParser)) {
                    switch (barcodeParser) {
                    case BarcodeParsers.XML:
                        extractInfoFromBarcodeFunction = extractInfoFromBarcodeXML;
                        break;
                    case BarcodeParsers.GS1:
                        extractInfoFromBarcodeFunction = extractInfoFromBarcode;
                        scannedBarCodeFinal = scannedBarcode;
                        break;
                    default:
                        extractInfoFromBarcodeFunction = extractInfoFromBarcodeXML;
                        break;
                    }
                }
                prodInfo = extractInfoFromBarcodeFunction(scannedBarCodeFinal, dispatcher);
                prodInfo.codeField = PRODUCT_CODE_FIELD.EAN_13;
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatcher);
        }
    }
    if (!isObjectValidAndNotEmpty(prodInfo)) {
        dispatcher(errorMessage('Unable to parse barcode'));
        return null;
    }
    console.log('returning', prodInfo);
    return prodInfo;
};

export const intentSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'intent',
    showInNonEditableMode: true,
    showInReadOnlyMode: true,
    hideCheckBoxIcon: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#FFFFFF',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'product',
            name: 'product',
            fieldType: 'text',
            find: 'product.value',
            label: 'Product',
            fullWidth: true,
        },
        {
            id: 'required',
            name: 'required',
            fieldType: 'text',
            find: 'quantityRequired',
            label: 'Required',
            fullWidth: true,
        },
        {
            id: 'uom',
            name: 'uom',
            fieldType: 'text',
            find: 'productUom.value',
            label: 'UOM',
            fullWidth: true,
        },
        {
            id: 'checked',
            name: 'recieved',
            fieldType: 'toggleCheck',
            checkField: ['quantityReceived', 'quantityRequired'],
            label: 'Checked',
            fullWidth: true,
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};
