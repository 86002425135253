import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import ReportChild from '../PurchaseOrderComponents/ReportChild';
import { getStringFromObject } from '../../constants/lodashUtils';

class ClaimDocumentsListViewer extends React.Component {
    getRenderChilds = (reports, start, end) => {
        const children = [];
        let i = 0;
        const {
            handleReportClick,
            titleDescConfig,
            handleDocumentDelete,
            showDeleteButton,
        } = this.props;
        console.log('Second', start, end, reports.length);
        for (i = start; i < end; i += 1) {
            const title = getStringFromObject(`[${i}].${titleDescConfig.title}`, reports);
            const description = getStringFromObject(`[${i}].${titleDescConfig.description}`, reports);
            const key = getStringFromObject(`[${i}].key`, reports);
            children.push(
                <ReportChild
                    key={`report-${i}`}
                    report={{
                        key,
                        title,
                        description,
                    }}
                    showFavourite={false}
                    handleDelete={handleDocumentDelete}
                    handleReportClick={handleReportClick}
                    showDeleteButton={showDeleteButton(getStringFromObject(`[${i}]`, reports))}
                />,
            );
        }
        return children;
    };


    render() {
        const {
            claimDocuments,
        } = this.props;
        if (isArrayValidAndNotEmpty(claimDocuments)) {
            return (
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} >
                        {
                            this.getRenderChilds(claimDocuments, 0, claimDocuments.length / 2)
                        }
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} >
                        {
                            this.getRenderChilds(
                                claimDocuments,
                                Math.ceil(claimDocuments.length / 2),
                                claimDocuments.length,
                            )
                        }
                    </Grid>
                </Grid>
            );
        }
        return null;
    }
}

ClaimDocumentsListViewer.propTypes = {
    claimDocuments: PropTypes.array,
    handleReportClick: PropTypes.func,
    titleDescConfig: PropTypes.object.isRequired,
    handleDocumentDelete: PropTypes.func,
    showDeleteButton: PropTypes.func,
};

ClaimDocumentsListViewer.defaultProps = {
    claimDocuments: [],
    handleReportClick: () => {},
    handleDocumentDelete: null,
    showDeleteButton: () => false,
};

export default ClaimDocumentsListViewer;

