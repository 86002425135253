import API from '../../../constants/api';

export const FETCH_CURRENT_USER_PROFILE_REQUEST = '@@profile/FETCH_CURRENT_USER_PROFILE_REQUEST';
export const FETCH_CURRENT_USER_PROFILE_FAILURE = '@@profile/FETCH_CURRENT_USER_PROFILE_FAILURE';
export const FETCH_CURRENT_USER_PROFILE_SUCCESS = '@@profile/FETCH_CURRENT_USER_PROFILE_SUCCESS';

export const UPDATE_PROFILE_REQUEST = '@@profile/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_FAILURE = '@@profile/UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_SUCCESS = '@@profile/UPDATE_PROFILE_SUCCESS';

export const FETCH_OTHER_USER_DATA_REQUEST = '@@profile/FETCH_OTHER_USER_DATA_REQUEST';
export const FETCH_OTHER_USER_DATA_FAILURE = '@@profile/FETCH_OTHER_USER_DATA_FAILURE';
export const FETCH_OTHER_USER_DATA_SUCCESS = '@@profile/FETCH_OTHER_USER_DATA_SUCCESS';

export const UPLOAD_PROFILE_PHOTO_REQUEST = '@@profile/UPLOAD_PROFILE_PHOTO_REQUEST';
export const UPLOAD_PROFILE_PHOTO_SUCCESS = '@@profile/UPLOAD_PROFILE_PHOTO_SUCCESS';
export const UPLOAD_PROFILE_PHOTO_FAILURE = '@@profile/UPLOAD_PROFILE_PHOTO_FAILURE';

export const FETCH_CALENDAR_WORK_HOURS_REQUEST = '@@profile/FETCH_CALENDAR_WORK_HOURS_REQUEST';
export const FETCH_CALENDAR_WORK_HOURS_SUCCESS = '@@profile/FETCH_CALENDAR_WORK_HOURS_SUCCESS';
export const FETCH_CALENDAR_WORK_HOURS_FAILURE = '@@profile/FETCH_CALENDAR_WORK_HOURS_FAILURE';

export const UPDATE_DUTY_TIME = '@@attendance/UPDATE_DUTY_TIME';

export const fetchCurrentProfile = () => ({
    type: FETCH_CURRENT_USER_PROFILE_REQUEST,
    api: API.PROFILE.FETCH_CURRENT_PROFILE,
});

export const updateProfile = (payload, isCurrentEmployee, empUuid, successCallback) => ({
    type: UPDATE_PROFILE_REQUEST,
    api: API.PROFILE.UPDATE_CURRENT_PROFILE,
    payload,
    empUuid,
    isCurrentEmployee,
    successCallback,
});

export const fetchOtherUser = uuid => ({
    type: FETCH_OTHER_USER_DATA_REQUEST,
    api: API.PROFILE.FETCH_EMPLOYEE_PROFILE,
    uuid,
});

export const uploadProfilePhoto = base64Image => ({
    type: UPLOAD_PROFILE_PHOTO_REQUEST,
    api: API.FILE.BASE_64,
    base64Image,
});

export const fetchCalendarWorkHourRequest = (startDate, endDate, uuid, isCurrentUser = false) => ({
    type: FETCH_CALENDAR_WORK_HOURS_REQUEST,
    api: API.CALENDAR.WORK_HOURS,
    startDate,
    endDate,
    isCurrentUser,
    uuid,
});
