import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const style = () => ({
    input: {
        paddingTop: '14px',
        paddingBottom: '14px',
    },
});

class OutlinedSelectField extends React.Component {
    onChange = (event) => {
        let { onChange } = this.props;
        if (isObjectValidAndNotEmpty(this.props.input)) {
            // eslint-disable-next-line prefer-destructuring
            onChange = this.props.input.onChange;
        }
        onChange(event.target.value);
    };

    render() {
        const {
            label,
            placeholder,
            classes,
            type,
            InputLabelProps,
            InputProps,
            input,
            options,
            testId,
        } = this.props;
        console.log('Options', options);
        const touched = getStringFromObject('meta.touched', this.props);
        const error = getStringFromObject('meta.error', this.props);
        const labelProps = { shrink: true, ...InputLabelProps };
        let otherProps = {};
        if (testId) {
            otherProps = {
                inputProps: {
                    'test-id': testId,
                },
            };
        }
        return (
            <TextField
                testId={testId}
                type={type}
                label={label}
                placeholder={placeholder}
                fullWidth
                select
                onChange={this.onChange}
                error={touched && error}
                helperText={touched && error}
                variant="outlined"
                {...this.props}
                {...input}
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    ...InputProps,
                }}
                {...otherProps}
                InputLabelProps={labelProps}
            >
                {options}
            </TextField>
        );
    }
}

OutlinedSelectField.propTypes = {
    input: PropTypes.object,
    classes: PropTypes.object.isRequired,
    testId: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
};

OutlinedSelectField.defaultProps = {
    input: {},
    label: '',
    testId: '',
    placeholder: '',
    type: 'input',
    InputLabelProps: {},
    InputProps: {},
    onChange: () => {},
};

export default withStyles(style)(OutlinedSelectField);
