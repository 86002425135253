/**
 * Created by sachinprakash on 15/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    ADD_OR_UPDATE_DICTIONARY_REQUEST,
    FETCH_DICTIONARY_REQUEST,
} from '../../redux/modules/LabSettings/labTest-actions';
import { addOrUpdateDictionaryWorker, fetchPagesOfDictionaryWorker } from './labDictionaryWorkerSaga';

export function* fetchPagesOfDictionaryWatcher() {
    yield takeLatest(FETCH_DICTIONARY_REQUEST, fetchPagesOfDictionaryWorker);
}

export function* addOrUpdateDictionaryWatcher() {
    yield takeLatest(ADD_OR_UPDATE_DICTIONARY_REQUEST, addOrUpdateDictionaryWorker);
}
