import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';

import { connect } from 'react-redux';
import { Field, initialize, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

import Close from '@material-ui/icons/Close';
import classNames from 'classnames';
import ActionButton from '../ActionButton/ActionButton';
import {
    getAllowedDefaultAccountTypes,
    isValidFunction,

} from '../../constants/CommonUtil';
import dialogComponentStyles from '../DialogComponent/DialogComponentStyles';
import {
    defaultAllowedPartnerAccountTypes,
    getVoucherTypeByAccountType,
    initialValues,
    PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS,
    voucherTypesRadioChoices,
} from './PayOrReceiveOnAccountUtil';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import { required } from '../../constants/FormValidations';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import { PAYMENT_METHODS, PAYMENT_METHODS_MAP } from '../../constants/constants';
import API from '../../constants/api';
import OutlinedTextField from '../OutlinedTextField';
import ReduxFormRadio from '../FormFieldComponents/ReduxFormRadio/ReduxFormRadio';
import {
    CASH_PAYMENT_VOUCHER_FORM_FIELDS,
    getFormValuesFrom,
    getNewPartnerIfValuesAreMissing,
    getPayloadFrom,
    getSubCompanyPartnerAccountAndPayable,
    voucherStates,
    voucherTypes,
} from '../CashPaymentVoucher/CashPaymentVoucherUtil';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { getPartnerApi } from '../AccountVoucherTable/AccountVoucherTableUtil';
import {
    clearCashPaymentVoucher,
    payOrReceiveOnAccount,
} from '../../redux/modules/cashPaymentVoucher/cashPaymentVoucher-actions';
import NumberTextField from '../NumberTextField';
import { fetchAccountFavorites } from '../../redux/modules/favoriteAccounts/favoritesForDoctor-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const PAY_OR_RECEIVE_ON_ACCOUNT_FORM = 'payOrReceiveOnAccountForm';

class PayOrReceiveOnAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subCompany: null,
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchAccountFavorites());
        if (isObjectValidAndNotEmpty(this.props.partner) && this.props.partner.uuid) {
            this.initializeFormWithPartner(this.props.partner);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (isObjectValidAndNotEmpty(nextProps.partner)) {
            const nextPartnerUuid = getStringFromObject('partner.uuid', nextProps);
            const partnerUuid = getStringFromObject('partner.uuid', this.props);
            if (
                nextPartnerUuid &&
                nextPartnerUuid !== partnerUuid
            ) {
                this.initializeFormWithPartner({
                    name: getStringFromObject('partner.name', nextProps),
                    uuid: nextPartnerUuid,
                });
            }
        }
        if (isObjectValidAndNotEmpty(nextProps.cashPaymentVoucher)) {
            const nextPartnerAccountUuid = getStringFromObject('cashPaymentVoucher.partnerAccount.key', nextProps);
            const partnerAccountUuid = getStringFromObject('cashPaymentVoucher.partnerAccount.key', this.props);
            const nextVoucherState = getStringFromObject('cashPaymentVoucher.state', nextProps);
            const voucherState = getStringFromObject('cashPaymentVoucher.state', this.props);
            if (nextPartnerAccountUuid) {
                if (
                    nextPartnerAccountUuid !== partnerAccountUuid ||
                    nextVoucherState !== voucherState
                ) {
                    const formValues = getFormValuesFrom(nextProps.cashPaymentVoucher);
                    this.initializeForm(formValues);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { formValues } = this.props;
        const previousPartnerUuid = getStringFromObject(
            `formValues.${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER}.uuid`,
            prevProps);
        const partnerUuid = getStringFromObject(
            `formValues.${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER}.uuid`,
            this.props,
        );
        if (
            partnerUuid &&
            previousPartnerUuid !== partnerUuid
        ) {
            this.updatePartnerAndAccount(formValues.partner, formValues.partnerAccount);
        }
    }

    onCloseClick = () => {
        const { handleClose } = this.props;
        if (isValidFunction(handleClose)) {
            handleClose();
        }
    }

    updatePartnerAccount = (partnerAccount) => {
        const { change } = this.props;
        change(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER_ACCOUNT, partnerAccount);
    }

    updateVoucherType = (voucherType) => {
        const { change } = this.props;
        change(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.VOUCHER_TYPE, voucherType);
    }

    updatePartnerAndAccount = async (partner) => {
        const { dispatch } = this.props;
        const newPartner = await getNewPartnerIfValuesAreMissing(partner, dispatch);
        if (isObjectValidAndNotEmpty(newPartner)) {
            const { subCompany, partnerAccount, isPayable } = getSubCompanyPartnerAccountAndPayable(newPartner);
            this.setState({
                subCompany,
            }, () => {
                this.updatePartnerAccount({ ...partnerAccount });
                this.updateVoucherType(isPayable ? voucherTypes.PAYMENT : voucherTypes.RECEIPT);
            });
        }
    }

    handlePartnerAccountSelect = (newAccount) => {
        const { change, allowedPartnerAccountTypes } = this.props;
        if (isObjectValidAndNotEmpty(newAccount)) {
            this.setState({
                subCompany: newAccount.subCompany,
            }, () => {
                change(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER, null);
                this.updateVoucherType(getVoucherTypeByAccountType(newAccount.type, allowedPartnerAccountTypes));
            });
        }
    }

    initializeFormWithPartner = (partner) => {
        this.initializeForm({
            ...initialValues,
            [PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER]: partner,
        });
    }

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        if (isObjectValidAndNotEmpty(formValues)) {
            dispatch(initialize(PAY_OR_RECEIVE_ON_ACCOUNT_FORM, formValues));
        }
    }

    payOrReceiveOnAccount = (formValues) => {
        const { dispatch } = this.props;
        const payload = getPayloadFrom(formValues, true);
        if (isObjectValidAndNotEmpty(payload)) {
            if (NumberOf(payload.totalAmountToPay)) {
                const message = 'Payment is Successfully';
                dispatch(payOrReceiveOnAccount(payload, message));
            } else {
                dispatch(errorMessage('Enter a valid Amount'));
            }
        } else {
            // code should never reach here
            dispatch(errorMessage('Something went wrong while submitting'));
        }
    }

    displayWarningAndPerformAction = (formValues, message) => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            message,
            () => {
                this.payOrReceiveOnAccount(formValues);
            },
        ));
    }

    confirmAndPay = (formValues) => {
        const { partner, partnerAccount, voucherType } = formValues;
        if (isObjectValidAndNotEmpty(partnerAccount)) {
            if (
                (partnerAccount.type === 'payable' || (isObjectValidAndNotEmpty(partner) && partner.supplier)) &&
                voucherType === voucherTypes.RECEIPT
            ) {
                this.displayWarningAndPerformAction(
                    formValues,
                    'Do you really want to receive money from a payable account?\n' +
                    'If it is a debit memo please use the Debit memo screen.',
                );
            } else if (
                (partnerAccount.type === 'receivable' || (isObjectValidAndNotEmpty(partner) && !partner.supplier)) &&
                voucherType === voucherTypes.PAYMENT
            ) {
                this.displayWarningAndPerformAction(
                    formValues,
                    'Do you really want to pay money to a receivable account?\n' +
                    'If it is a credit memo please use the Credit memo screen.',
                );
            } else {
                this.payOrReceiveOnAccount(formValues);
            }
        }
    }

    handleCreateNew = () => {
        const { dispatch } = this.props;
        dispatch(clearCashPaymentVoucher());
        this.initializeForm({ ...initialValues });
    }

    render() {
        const {
            classes,
            handleSubmit,
            onlyPayers,
            onlySuppliers,
            partner,
            formValues,
            allowedPartnerAccountTypes,
            favorites,
        } = this.props;
        const { subCompany } = this.state;
        console.log('pay-or-receive-on-account-form-values', formValues);

        const paymentMethod = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_METHOD, formValues);
        const selectedPartner = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER, formValues);
        const partnerAccount = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER_ACCOUNT, formValues);
        const creditFrom = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.CREDIT_FORM, formValues);
        const voucherType = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.VOUCHER_TYPE, formValues);
        const voucherState = getStringFromObject(PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.STATE, formValues);

        const isCheque = paymentMethod && paymentMethod.value === PAYMENT_METHODS_MAP.CHEQUE.value;
        const isBank = paymentMethod && paymentMethod.value === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
        const isPayable = voucherType === voucherTypes.PAYMENT;
        const isDraft = voucherState === voucherStates.DRAFT;
        let creditingAccountName = '';
        let debitingAccountName = '';
        if (isObjectValidAndNotEmpty(creditFrom) && isObjectValidAndNotEmpty(partnerAccount)) {
            creditingAccountName = isPayable ? creditFrom.value : partnerAccount.value;
            debitingAccountName = isPayable ? partnerAccount.value : creditFrom.value;
        }
        const allowedPartnerAccounts = isObjectValidAndNotEmpty(allowedPartnerAccountTypes) ?
            Object.keys(allowedPartnerAccountTypes).join(',') :
            Object.keys(defaultAllowedPartnerAccountTypes).join(',');
        return (
            <Dialog
                open
                maxWidth="md"
                scroll="body"
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>
                            Pay Or Receive On Account
                        </div>
                        <Close
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={this.onCloseClick}
                            test-id="pay-or-receive-on-account"
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'unset' }}>
                    <Grid
                        container
                        spacing={24}
                        className="mt-1"
                    >
                        <Grid container item spacing={24}>
                            <Grid item lg={6} md={6} sm={6}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER}
                                    component={ReduxFormReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label="Partner"
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    autocomplete
                                    defaultOptions
                                    dataSourceApi={getPartnerApi(onlySuppliers, onlyPayers)}
                                    validate={isObjectValidAndNotEmpty(partnerAccount) ? [] : [required]}
                                    required={!isObjectValidAndNotEmpty(partnerAccount)}
                                    onSelectHandlers={['handPartnerSelect']}
                                    actionHandlers={{
                                        handPartnerSelect: this.handPartnerSelect,
                                    }}
                                    isDisabled={!isDraft || isObjectValidAndNotEmpty(partner)}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_DATE}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_DATE}
                                    component={DateInput}
                                    label="Payment Date"
                                    placeholder="Payment Date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    validate={required}
                                    disabled={!isDraft}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24}>
                            <Grid item lg={6} md={6} sm={6}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER_ACCOUNT}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PARTNER_ACCOUNT}
                                    component={ReduxFormReactSelectMaterial}
                                    label="Account"
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    autocomplete
                                    defaultOptions
                                    dataSourceApi={`${API.SEARCH.ACCOUNTS
                                    }?type=${allowedPartnerAccounts}&subCompany=${subCompany || ''}&searchString=`}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'id',
                                    }}
                                    onSelectHandlers={['handlePartnerAccountSelect']}
                                    actionHandlers={{
                                        handlePartnerAccountSelect: this.handlePartnerAccountSelect,
                                    }}
                                    validate={[required]}
                                    required
                                    isDisabled={!isDraft || isObjectValidAndNotEmpty(partner)}
                                    fixedOptionSet={favorites}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.CREDIT_FORM}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.CREDIT_FORM}
                                    component={ReduxFormReactSelectMaterial}
                                    label={isPayable ? 'Credit From' : 'Debit To'}
                                    validate={[required]}
                                    required
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    dataSourceApi={`${API.SEARCH.ACCOUNTS
                                    }?type=${getAllowedDefaultAccountTypes('payOrReceiveCreditAccountTypes', this.props.allowedAccountTypes)}&subCompany=${subCompany || ''}&searchString=`}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    autocomplete
                                    isDisabled={
                                        !isDraft ||
                                        (!isObjectValidAndNotEmpty(partnerAccount) &&
                                            !isObjectValidAndNotEmpty(selectedPartner))
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24}>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_METHOD}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_METHOD}
                                    component={ReduxFormReactSelectMaterial}
                                    label="Payment Method"
                                    validate={[required]}
                                    placeholder="Select"
                                    required
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    options={PAYMENT_METHODS}
                                    dataSourceConfig={{
                                        text: 'label',
                                        value: 'value',
                                    }}
                                    isDisabled={!isDraft}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY}-test-id`}
                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY}
                                    label="Amount"
                                    type="number"
                                    required
                                    validate={required}
                                    component={NumberTextField}
                                    disabled={!isDraft}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                                {
                                    isDraft && (
                                        <Field
                                            testId={
                                                `${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.VOUCHER_TYPE}-test-id`
                                            }
                                            name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.VOUCHER_TYPE}
                                            label=""
                                            labelPlacement="start"
                                            fullWidth
                                            choices={voucherTypesRadioChoices}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            component={ReduxFormRadio}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                        {
                            isObjectValidAndNotEmpty(paymentMethod) && (
                                (isCheque || isBank) && (
                                    <React.Fragment>
                                        <Grid container item spacing={24}>
                                            <Grid item lg={6} md={6} sm={6}>
                                                <Field
                                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.BANK_NAME}-test-id`}
                                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.BANK_NAME}
                                                    label="Bank Name"
                                                    placeholder="Enter Bank Name"
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={5}>
                                                <Field
                                                    testId={
                                                        `${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.REFERENCE_NUMBER}-test-id`
                                                    }
                                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.REFERENCE_NUMBER}
                                                    label={isCheque ? 'Cheque Number' : 'Reference Number'}
                                                    placeholder={isCheque ? 'Enter Cheque No' : 'Enter Reference No'}
                                                    required
                                                    validate={[required]}
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={24}>
                                            <Grid item lg={4} md={4} sm={4}>
                                                <Field
                                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.DATE}-test-id`}
                                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.DATE}
                                                    component={DateInput}
                                                    label={isCheque ? 'Cheque Date' : 'Date'}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required
                                                    validate={required}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6}>
                                                <Field
                                                    testId={`${PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.REMARKS}-test-id`}
                                                    name={PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.REMARKS}
                                                    label="Remarks"
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )
                            )
                        }
                        <Grid container item spacing={24}>
                            <Grid item lg={6} md={6} sm={6}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.NARRATION}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.NARRATION}
                                    component={OutlinedTextField}
                                    label="Memo"
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    fullWidth
                                    required
                                    disabled={!isDraft}
                                    validate={[required]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions style={{ padding: '12px' }}>
                        <div style={{ width: '100%' }}>
                            {
                                (creditingAccountName && debitingAccountName) && (
                                    <div>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    Credit from <strong>{creditingAccountName}</strong> and Debit to <strong>{debitingAccountName}</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            }
                            <div>
                                <Grid container justify="flex-end" className="mt-1">
                                    <ActionButton
                                        test-id="pay-or-receive-on-account-cancel-payment"
                                        primary={false}
                                        disableRipple
                                        onClick={this.onCloseClick}
                                    >
                                        Cancel
                                    </ActionButton>
                                    {
                                        isDraft ? (
                                            <ActionButton
                                                testId="pay-or-receive-on-account-confirm"
                                                className="ml-1"
                                                disableRipple
                                                onClick={handleSubmit(this.confirmAndPay)}
                                            >
                                                {`Confirm & ${isPayable ? 'Pay' : 'Receive'}`}
                                            </ActionButton>
                                        ) : (
                                            <React.Fragment>
                                                {
                                                    !isObjectValidAndNotEmpty(partner) && (
                                                        <ActionButton
                                                            test-id="cash-purchase-voucher-new"
                                                            className="ml-1"
                                                            disableRipple
                                                            onClick={this.handleCreateNew}
                                                        >
                                                            Create New
                                                        </ActionButton>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </Grid>
                            </div>
                        </div>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

PayOrReceiveOnAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    dispatch: PropTypes.func,
    change: PropTypes.func,
    cashPaymentVoucher: PropTypes.object,
    // if both payer and supplier are false then both payers and suppliers are fetched
    // if any one is true only that partner is fetched
    onlyPayers: PropTypes.bool,
    onlySuppliers: PropTypes.bool,
    partner: PropTypes.object,
    allowedPartnerAccountTypes: PropTypes.object,
    favorites: PropTypes.array,
    allowedAccountTypes: PropTypes.object,
};

PayOrReceiveOnAccount.defaultProps = {
    formValues: {},
    onlyPayers: false,
    onlySuppliers: false,
    partner: {},
    dispatch: () => {},
    change: () => {},
    cashPaymentVoucher: {},
    allowedPartnerAccountTypes: { ...defaultAllowedPartnerAccountTypes },
    allowedAccountTypes: {},
    favorites: [],
};

const mapStateToProps = state => ({
    formValues: state.form[PAY_OR_RECEIVE_ON_ACCOUNT_FORM] ? state.form[PAY_OR_RECEIVE_ON_ACCOUNT_FORM].values : {},
    cashPaymentVoucher: state.cashPaymentVoucher,
    allowedPartnerAccountTypes: state.appConfiguration && state.appConfiguration.allowedPartnerAccountTypes ?
        state.appConfiguration.allowedPartnerAccountTypes : [],
    allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
    favorites: getStringFromObject('favoriteForDoctor', state),
});

export default connect(mapStateToProps)(reduxForm({
    form: PAY_OR_RECEIVE_ON_ACCOUNT_FORM,
    initialValues: { ...initialValues },
})(withStyles(dialogComponentStyles)(PayOrReceiveOnAccount)));
