/* eslint-disable indent */
import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_MATERIAL_RECEIVE,
    CREATE_MATERIAL_RECEIVE_SUCCESS,
    FETCH_MATERIAL_RECEIVE_SUCCESS,
} from './materialReceive-actions';

const materialReceiveReducer = (state = initialStates.materialReceive, action) => {
    let newState = {};
    switch (action.type) {
        case CREATE_MATERIAL_RECEIVE_SUCCESS:
            newState = Object.assign({}, state, { materialReceive: action.data });
            break;
        case CLEAR_SELECTED_MATERIAL_RECEIVE:
            newState = Object.assign({}, state, { materialReceive: {} });
            break;
        case FETCH_MATERIAL_RECEIVE_SUCCESS:
            newState = Object.assign({}, state, { materialReceive: action.data });
            break;
        default:
            newState = state;
    }
    return newState;
};

export default materialReceiveReducer;
