import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

import { getRequiredFieldLabel } from '../../../constants/CommonUtil';

const FormikKeyboardDatePicker = (props) => {
    const {
        field: {
            name, value, onBlur,
        },
        form: {
            touched,
            errors,
            setFieldValue,
            setFieldError,
        },
        views,
        openTo,
        label,
        autoOk,
        required,
    } = props;
    let labelComponent = label;
    if (required) {
        labelComponent = getRequiredFieldLabel(label);
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                label={labelComponent}
                name={name}
                value={value ? new Date(value) : null}
                onChange={v => setFieldValue(name, v, true)}
                onBlur={onBlur}
                error={touched[name] && errors[name]}
                helperText={errors[name]}
                views={views}
                openTo={openTo}
                autoOk={autoOk}
                onError={(_, error) => setFieldError(name, error)}
                InputLabelProps={{ shrink: true }}
                format="dd/MM/yyyy"
                keyboard
            />
        </MuiPickersUtilsProvider>
    );
};

FormikKeyboardDatePicker.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.func.isRequired,
    views: PropTypes.array,
    openTo: PropTypes.string,
    label: PropTypes.string,
    autoOk: PropTypes.bool,
    required: PropTypes.bool,
};

FormikKeyboardDatePicker.defaultProps = {
    views: ['year', 'month', 'date'],
    openTo: 'day',
    label: '',
    autoOk: true,
    required: false,
};

export default FormikKeyboardDatePicker;
