import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    FETCH_TRANSACTIONS_LIST_FAILURE,
    FETCH_TRANSACTIONS_LIST_SUCCESS,
} from '../../redux/modules/transaction/transaction-actions';
import { PAGE, SIZE, SORT_COL, SORT_ORDER_DESC } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';

export default function* fetchListOfTransactionWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const params = {
            page: getStringFromObject('page', action, PAGE),
            size: getStringFromObject('size', action, SIZE),
            sortCol: getStringFromObject('sortCol', action, SORT_COL),
            sortOrder: getStringFromObject('sortOrder', action, SORT_ORDER_DESC),
            name: getStringFromObject('filters.name', action, ''),
            searchString: getStringFromObject('filters.searchString', action, ''),
            referenceNumber: getStringFromObject('filters.referenceNumber', action, ''),
        };
        const typesIgnoreList = getStringFromObject('filters.typeIgnoreList', action, []);
        if (isArrayValidAndNotEmpty(typesIgnoreList)) {
            params.typeIgnoreList = typesIgnoreList.map(e => e).join(',');
        }
        const types = getStringFromObject('filters.types', action, []);
        if (isArrayValidAndNotEmpty(types)) {
            params.types = types.map(e => e).join(',');
        }
        const response = yield call(retriable().get, action.api, {
            params,
        });
        yield put({
            type: FETCH_TRANSACTIONS_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.ERP_TRANSACTION.FETCH_FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_TRANSACTIONS_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
