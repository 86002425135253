import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import {
    CREATE_PURCHASE_ORDER_FAILURE,
    CREATE_PURCHASE_ORDER_SUCCESS,
    FETCH_PURCHASE_ORDER_FAILURE,
    FETCH_PURCHASE_ORDER_SUCCESS,
} from '../../redux/modules/purchaseOrder/purchaseOrder-actions';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import { PURCHASE_ORDER_OPERATION } from '../../mapper/PurchaseOrderMapper';

export function* createPurchaseOrderWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.purchaseOrder);
        yield put({
            type: CREATE_PURCHASE_ORDER_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        if (action.purchaseOrder.operation === PURCHASE_ORDER_OPERATION.SAVE) {
            const msg = action.purchaseOrder.uuid ? messages.PURCHASE_ORDER.UPDATE.SUCCESS :
                messages.PURCHASE_ORDER.CREATE.SUCCESS;
            yield put({ type: SUCCESS_MESSAGE, message: msg });
        } else {
            yield put({ type: SUCCESS_MESSAGE, message: messages.PURCHASE_ORDER.CONFIRM.SUCCESS });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = '';
            if (action.purchaseOrder.operation === PURCHASE_ORDER_OPERATION.SAVE) {
                msg = action.purchaseOrder.uuid ? messages.PURCHASE_ORDER.UPDATE.FAILED :
                    messages.PURCHASE_ORDER.CREATE.FAILED;
            } else {
                msg = messages.PURCHASE_ORDER.CONFIRM.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_PURCHASE_ORDER_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPurchaseOrderWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_PURCHASE_ORDER_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PURCHASE_ORDER.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_PURCHASE_ORDER_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
