import React from 'react';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel';
import PropTypes from 'prop-types';

const styles = () => ({
    checkBox: {
        // color: `${theme.palette.common.white}`,
        color: '#1E88E5',
        fontSize: '1rem',
        fontWeight: '500',
        '&$checked': {
            color: '#2196F3',
            // color: `${theme.palette.common.white}`,
        },
    },
    checked: {},
});

class EnhancedTableHead extends React.Component {
    createSortHandler = property => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            order,
            orderBy,
            tableCells,
            classes,
            hideCheckBox,
            numSelected,
            rowCount,
            onSelectAllClick,
            isExpandable,
            isEditable,
            saveIcon,
            deleteIcon,
            headerRowStyle,
            headerColStyle,
            showRowNumber,
            forceEnableCheckBox,
        } = this.props;
        console.log('klajdfalkjsdfhalksdjfh', this.props);
        return (
            <TableHead>
                <TableRow style={{ ...headerRowStyle }}>
                    {
                        !(hideCheckBox) && (isEditable || forceEnableCheckBox) &&
                        <TableCell style={{ width: '5em', ...headerColStyle }} >
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                classes={{
                                    root: classes.checkBox,
                                    checked: classes.checked,
                                }}
                            />
                        </TableCell>
                    }
                    {
                        isExpandable &&
                        <TableCell />
                    }
                    {
                        showRowNumber &&
                        <TableCell
                            className={classes.tableCell}
                        >
                            #
                        </TableCell>
                    }
                    {tableCells.map(row => (
                        <TableCell
                            className={classes.tableCell}
                            key={row.id}
                            test-id={row.id}
                            tabIndex={-1}
                            component={row.component}
                            numeric={row.numeric}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.sortCol ? order : false}
                            style={{ ...row.headerStyle }}
                        >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    test-id={row.label}
                                    tabIndex={-1}
                                    active={orderBy === row.sortCol}
                                    direction={order}
                                    onClick={row.sortCol ? this.createSortHandler(row.sortCol) : () => {}}
                                    style={{
                                        ...headerColStyle,
                                        cursor: row.sortCol ? 'pointer' : 'inherit',
                                    }}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ), this)}
                    {
                        saveIcon &&
                            <TableCell style={{ width: '1em', ...headerColStyle }} />
                    }
                    {
                        deleteIcon &&
                            <TableCell style={{ width: '1em', ...headerColStyle }} />
                    }
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    hideCheckBox: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    saveIcon: PropTypes.bool,
    isEditable: PropTypes.bool.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    tableCells: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    headerRowStyle: PropTypes.object,
    headerColStyle: PropTypes.object,
    forceEnableCheckBox: PropTypes.bool.isRequired,
};

EnhancedTableHead.defaultProps = {
    hideCheckBox: false,
    isExpandable: false,
    saveIcon: false,
    deleteIcon: false,
    showRowNumber: false,
    headerRowStyle: {},
    headerColStyle: {},
};

export default withStyles(styles)(EnhancedTableHead);
