import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Clear';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import IframeDialogComponentStyles from './IframeDialogComponentStyles';

class IframeDialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogActions: props.dialogActions,
            dialogRef: null,
            iframeLoading: true,
        };
    }
    componentDidMount() {
        window.addEventListener('message', this.handleIframeMessage);
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeMessage);
    }
    handleIframeLoad = () => {
        this.setState({ iframeLoading: false }); // Set loading state to false when iframe has finished loading
    }
    updateDialogActions = (dialogActions) => {
        this.setState({
            dialogActions,
        });
    };
    dialogRef = (dialogRef) => {
        if (!this.state.dialogRef) {
            this.setState({
                dialogRef,
            });
        }
    };
    handleIframeMessage = (event) => {
        if (event.data === 'closeIframe') {
            this.props.handleClose();
        }
    };
    render() {
        const {
            classes,
            open,
            header,
            handleClose,
            fullScreen,
            handleClick,
            maxWidth,
            disableEnforceFocus,
            disableEscapeKeyDown,
            disableBackdropClick,
            iframeUrl,
        } = this.props;
        const {
            dialogActions,
            dialogRef,
            iframeLoading,
        } = this.state;
        console.log(dialogRef);
        let propsToSpread = {};
        if (!this.props.fullScreen) {
            propsToSpread = { ...this.props };
        }
        return (
            <Dialog
                open={open}
                maxWidth={maxWidth}
                scroll="body"
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
                ref={this.dialogRef}
                onClick={handleClick}
                onClose={handleClose}
                disableEnforceFocus={disableEnforceFocus}
                disableEscapeKeyDown={disableEscapeKeyDown}
                disableBackdropClick={disableBackdropClick}
                {...propsToSpread}
            >
                <DialogTitle id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>{header}</div>
                        <Close
                            test-id={`${header}-close`}
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={handleClose}
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent
                    className={classes.dialogContent}
                    style={{ overflowY: this.props.overflowY }}
                >
                    <Grid container style={{ height: '100%' }}>
                        {iframeLoading ? 'Loading...' : null
                        }
                        <iframe
                            onLoad={this.handleIframeLoad}
                            title="test"
                            src={iframeUrl}
                            width="100%"
                            height="100%"
                            style={{ display: iframeLoading ? 'none' : 'block' }}
                            frameBorder="0"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '12px', marginTop: '0px' }}>
                    {dialogActions}
                </DialogActions>
            </Dialog>
        );
    }
}

IframeDialogComponent.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.string,
    overflowY: PropTypes.string,
    open: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    disableEnforceFocus: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    dialogActions: PropTypes.node,
    children: PropTypes.node,
    handleClose: PropTypes.func,
    handleClick: PropTypes.func,
    maxWidth: PropTypes.oneOf(['lg', 'md']),
    iframeUrl: PropTypes.string.isRequired,
};

IframeDialogComponent.defaultProps = {
    classes: {},
    header: '',
    open: false,
    fullScreen: false,
    disableEnforceFocus: false,
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    fullWidth: true,
    dialogActions: null,
    children: null,
    maxWidth: 'md',
    handleClose: () => {},
    handleClick: () => {},
    overflowY: '',
};

export default withStyles(IframeDialogComponentStyles)(withMobileDialog()(IframeDialogComponent));

