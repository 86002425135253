import { takeLatest } from 'redux-saga/effects';
import {
    CLEAR_LOGIN_STATE,
    LOGIN_REQUEST,
    LOGIN_SILENT_REQUEST,
    VALIDATE_SESSION_REQUEST,
    CLEAR_CACHE_LOGIN_STATE,
} from '../../redux/modules/login/login-actions';
import {
    login, loginSilently, logout, validateSessionWorker,
    clearCacheAndLogout,
} from './loginWorkerSaga';


export function* loginSaga() {
    yield takeLatest(LOGIN_REQUEST, login);
}

export function* logoutSaga() {
    yield takeLatest(CLEAR_LOGIN_STATE, logout);
}

export function* validateSessionSaga() {
    yield takeLatest(VALIDATE_SESSION_REQUEST, validateSessionWorker);
}

export function* loginSilentSaga() {
    yield takeLatest(LOGIN_SILENT_REQUEST, loginSilently);
}

export function* clearCacheAndLogoutSaga() {
    yield takeLatest(CLEAR_CACHE_LOGIN_STATE, clearCacheAndLogout);
}
