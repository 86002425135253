import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { addOrSubtractDays, computeFiscalyearDates, getCurrentQuarter } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

export const DATE_FILTERS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This Week',
    THIS_WEEK_TO_DATE: 'This Week To Date',
    THIS_MONTH: 'This Month',
    THIS_MONTH_TO_DATE: 'This Month To Date',
    THIS_FINANCIAL_QUARTER: 'This Financial Quarter',
    THIS_FINANCIAL_QUARTER_TO_DATE: 'This Financial Quarter To Date',
    THIS_FINANCIAL_YEAR: 'This Financial Year',
    THIS_FINANCIAL_YEAR_TO_DATE: 'This Financial Year To Date',
    LAST_WEEK: 'Last Week',
    LAST_MONTH: 'Last Month',
    LAST_QUARTER: 'Last Quarter',
    LAST_FINANCIAL_YEAR: 'Last Financial Year',
    CUSTOM: 'Custom',
};

export const NON_FINANCIAL_DATE_FILTERS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    LAST_WEEK: 'Last Week',
    LAST_MONTH: 'Last Month',
    CUSTOM: 'Custom',
};

export const getStartAndEndDateFromFilter = (filter, appConfiguration) => {
    const now = new Date();
    let startDate = new Date(now.getTime());
    let endDate = new Date(now.getTime());
    const financialMonth = getStringFromObject('financialYear.start', appConfiguration, 1);
    const financialLastMonth = getStringFromObject('financialYear.lastMonth', appConfiguration, 12);
    const financialLastDay = getStringFromObject('financialYear.lastDay', appConfiguration, 31);
    const startOfFiscalYear1 = computeFiscalyearDates(new Date(), financialLastMonth - 1, financialLastDay);
    console.log('sdfdsfdsfdsfdsf', financialMonth);
    switch (filter) {
    case DATE_FILTERS.CUSTOM:
        startDate = now;
        endDate = now;
        break;
    case DATE_FILTERS.YESTERDAY:
        startDate = startOfDay(addOrSubtractDays(now, -1));
        endDate = endOfDay(startDate);
        break;
    case DATE_FILTERS.THIS_WEEK:
        startDate = startOfWeek(new Date());
        endDate = endOfWeek(new Date());
        break;
    case DATE_FILTERS.THIS_WEEK_TO_DATE:
        startDate = startOfWeek(new Date());
        break;
    case DATE_FILTERS.LAST_WEEK:
        startDate = startOfWeek(subWeeks(new Date(), 1));
        endDate = endOfWeek(subWeeks(new Date(), 1));
        break;
    case DATE_FILTERS.THIS_MONTH:
        startDate = startOfMonth(now);
        endDate = endOfMonth(now);
        break;
    case DATE_FILTERS.THIS_MONTH_TO_DATE:
        startDate = startOfMonth(now);
        break;
    case DATE_FILTERS.LAST_MONTH:
        startDate = startOfMonth(subMonths(now, 1));
        endDate = endOfMonth(subMonths(now, 1));
        break;
    case DATE_FILTERS.THIS_FINANCIAL_QUARTER:
        startDate = getStringFromObject('start', getCurrentQuarter(financialMonth), null);
        endDate = getStringFromObject('end', getCurrentQuarter(financialMonth), null);
        break;
    case DATE_FILTERS.THIS_FINANCIAL_QUARTER_TO_DATE:
        startDate = getStringFromObject('start', getCurrentQuarter(financialMonth), null);
        break;
    case DATE_FILTERS.LAST_QUARTER:
        startDate = getStringFromObject('start', getCurrentQuarter(financialMonth, 1, '-'), null);
        endDate = getStringFromObject('end', getCurrentQuarter(financialMonth, 1, '-'), null);
        break;
    case DATE_FILTERS.THIS_FINANCIAL_YEAR:
        startDate = startOfFiscalYear1.dateFrom;
        endDate = startOfFiscalYear1.dateTo;
        break;
    case DATE_FILTERS.THIS_FINANCIAL_YEAR_TO_DATE:
        startDate = startOfFiscalYear1.dateFrom;
        break;
    case DATE_FILTERS.LAST_FINANCIAL_YEAR:
        startDate = subYears(startOfFiscalYear1.dateFrom, 1);
        endDate = subYears(startOfFiscalYear1.dateTo, 1);
        break;
    default:
    }
    return {
        startDate,
        endDate,
    };
};
