import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    FETCH_AGING_REPORT_FAILURE,
    FETCH_AGING_REPORT_SUCCESS,
    FETCH_PAYOR_INVOICES_FAILURE,
    FETCH_PAYOR_INVOICES_SUCCESS,
} from '../../redux/modules/agingReport/agingReport-actions';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch, getUrlWithApiParams,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { getUrlParamsFromFilters } from './AgingSearchConstruct';
import { getStringFromObject } from '../../constants/lodashUtils';


export function* fetchAgingReportWorkerSaga(action) {
    try {
        const {
            page,
            size,
            api,
            filters,
            agingInvoicePayerType,
            subCompany,
        } = action;
        const params = {
            page,
            size,
            getCompany: true,
            ...getUrlParamsFromFilters(filters, agingInvoicePayerType),
            subCompany: getStringFromObject('key', subCompany) || '',
        };
        const paramApi = getUrlWithApiParams(api, params);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, paramApi);
        yield put({
            type: FETCH_AGING_REPORT_SUCCESS,
            data: response.data,
            filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        console.log('Error', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: FETCH_AGING_REPORT_FAILURE });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchInvoicesForPayorWorker(action) {
    try {
        const {
            payorId, api,
        } = action;
        const paramApi = `${api}?payerId=${payorId}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, paramApi);
        yield put({
            type: FETCH_PAYOR_INVOICES_SUCCESS,
            data: response.data,
            payorId,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: FETCH_PAYOR_INVOICES_FAILURE });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchAgingReportWorkerSaga;
