import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import { risUrls } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const RISOpdQueuePage = React.lazy(() => import('../pages/RISPages/RISOpdQueuePage/RISOpdQueuePage'));
const RisMainContainer = React.lazy(() => import('../containers/RisMainContainer/RisMainContainer'));
const OpdAppointmentsPage = React.lazy(() => import('../pages/OpdPage/OpdAppointmentsPage'));
const RISOrdersListPage = React.lazy(() => import('../pages/RISPages/RISOrdersListPage/RISOrdersListPage'));
const RISOldOrdersListPage = React.lazy(() => import('../pages/RISPages/RISOldOrdersListPage/RISOldOrdersListPage'));
const RISOrdersDetailsPage = React.lazy(() => import('../pages/RISPages/RISOrdersDetailsPage/RISOrdersDetailsPage'));
const RISReportsPage = React.lazy(() => import('../pages/RISPages/RISReportsPage/RISReportsPage'));
const MissedOPDQueue = React.lazy(() => import('../pages/RISPages/RISMissedOPDQueue/RISMissedOPDQueue'));

const RisRoutes = props => (
    <RisMainContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={risUrls.home} exact component={RISOpdQueuePage} />
            <Route path={risUrls.reportTemplates} exact component={OpdAppointmentsPage} />
            <Route path={risUrls.reports} exact component={RISReportsPage} />
            <Route path={risUrls.orders} exact component={RISOrdersListPage} />
            <Route path={risUrls.oldOrders} exact component={RISOldOrdersListPage} />
            <Route path={risUrls.missedList} exact component={MissedOPDQueue} />
            <Route path={`${risUrls.orderDetail}/:uuid`} exact component={RISOrdersDetailsPage} />
        </Suspense>
    </RisMainContainer>
);

RisRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

RisRoutes.defaultProps = {
    history: {},
};

export default RisRoutes;
