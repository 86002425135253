import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ArrowLeft, ArrowRight, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
    Button,
    Grid,
    IconButton,
    Menu, MenuItem, Paper,
} from '@material-ui/core';
import DialogComponent from '../DialogComponent/DialogComponent';
import PrintPDF from '../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import { apiCatchBlockFunction,
    getUrlWithApiParams, isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { errorMessage } from '../../redux/modules/message/message-actions';
import API from '../../constants/api';
import OutlinedTextField from '../OutlinedTextField';
import ActionButton from '../ActionButton/ActionButton';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const JobStatus = {
    READY: 'READY',
    GENERATING: 'GENERATING',
    PRINTING: 'PRINTING',
    DONE: 'DONE',
};

class BarcodePrintDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            printBarcode: false,
            itemsToPrint: [],
            jobQueue: [],
            currentIndex: 0,
            page: 0,
            size: 5,
            sizeMenuAnchor: null,
            STATE: 'READY',
            // onPrintComplete: () => {},
        };
    }

    componentDidMount() {
        this.initializeItems();
    }

    onPrintComplete = () => {
        const { currentIndex } = this.state;
        this.printNext(currentIndex);
        if (currentIndex > 0) {
            this.updateStatus(currentIndex - 1, JobStatus.DONE);
        }
    }

    getVisibleItems = () => {
        const { jobQueue, page, size } = this.state;
        const start = (page) * size;
        let end = (page + 1) * size;
        end = end < jobQueue.length ? end : jobQueue.length;
        return jobQueue.slice(start, end);
    }
    initializeItems = () => {
        const { items } = this.props;
        const initialJobs = items.map(anItem => ({ ...anItem, status: JobStatus.READY }));
        this.setState({ jobQueue: initialJobs });
    }

    printNext = (index) => {
        const { jobQueue } = this.state;
        console.log('printExtIndex', { index, jobs: jobQueue.length });
        if (index > 0) {
            this.updateStatus(index - 1, JobStatus.DONE);
        }
        if (index < jobQueue.length) {
            this.setState({
                currentIndex: index + 1,
            });
            this.printBarcode(index);
        } else {
            this.setState({ page: 0, STATE: 'DONE' });
        }
    }

    startPrint = () => {
        this.printNext(0);
        this.setState({ page: 0, STATE: 'PRINTING' });
    }

    updateQuantity = index => (value) => {
        console.log(index, value);
        const { jobQueue } = this.state;
        console.log('beforeUpdatedJobQueue', { jobQueue, value });

        const itemToUpdate = jobQueue[index];
        jobQueue[index] = { ...itemToUpdate, quantity: value };
        console.log('updatedJobQueue', { jobQueue });
        this.setState({
            jobQueue,
        });
    }

    updateStatus = (index, status) => {
        const { jobQueue } = this.state;
        const currentJob = jobQueue[index];
        jobQueue[index] = { ...currentJob, status };
        this.setState({ jobQueue });
    }


    printBarcode =async (index) => {
        const {
            jobQueue,
            page,
            size,
        } = this.state;
        const {
            dispatch,
            type,
        } = this.props;
        const print = [];
        const currentJob = jobQueue[index];
        console.log('ToBePrinted', index,
            currentJob,
        );
        let end = (page + 1) * size;
        end = end < jobQueue.length ? end : jobQueue.length;
        if (index >= end) {
            this.setState({ page: page + 1 });
        }
        try {
            this.updateStatus(index, JobStatus.GENERATING);
            const response = await axios.get(getUrlWithApiParams(API.BATCHES.PRINT, { itemUuid: currentJob.uuid, quantity: 1, type }));
            if (isObjectValidAndNotEmpty(response.data)) {
                const failureReason = getStringFromObject('failureReason', response.data);
                if (failureReason) {
                    dispatch(errorMessage(failureReason));
                } else {
                    const token = getStringFromObject('downloadToken', response.data);
                    print.push({
                        url: `${API.FILE.DOWNLOAD_PDF_WITH_TOKEN}${token}`,
                        name: 'inventoryProductPrint',
                        copies: currentJob.quantity,
                    });
                    this.setState(
                        {
                            itemsToPrint: print,
                            printBarcode: !this.state.printBarcode,
                        },
                    );
                    this.updateStatus(index, JobStatus.PRINTING);
                }
            }
            // dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    }
    nextPage = () => {
        const { page, jobQueue, size } = this.state;
        if (((page + 1) * size) < jobQueue.length) {
            this.setState({ page: page + 1 });
        }
    }
    prevPage = () => {
        const { page } = this.state;
        if (page > 0) {
            this.setState({ page: page - 1 });
        }
    }

    handleSizeChange = size => () => {
        this.setState({ size, page: 0 });
        this.handleSizeMenuClose();
    }

    handleSizeMenuClick = (event) => {
        this.setState({
            sizeMenuAnchor: event.target,
        });
    };

    handleSizeMenuClose = () => {
        this.setState({
            sizeMenuAnchor: null,
        });
    };

    deleteItem = index => () => {
        const { jobQueue } = this.state;
        jobQueue.splice(index, 1);
        this.setState({ jobQueue });
    };

    render() {
        const {
            printBarcode,
            itemsToPrint,
            action,
            page,
            size,
            jobQueue,
            sizeMenuAnchor,
            STATE,
            // onPrintComplete,
        } = this.state;
        const {
            items,
            onClose,
        } = this.props;
        console.log('BarcodePrintDialog Items', items, action);
        const visibleItems = this.getVisibleItems();
        let end = (page + 1) * size;
        end = end < jobQueue.length ? end : jobQueue.length;
        return (
            <React.Fragment>
                <DialogComponent
                    open
                    handleClose={onClose}
                    fullWidth
                    disableEscapeKeyDown
                    disableBackdropClick
                    header="Barcode Printer"
                >
                    <Grid
                        container
                        spacing={16}
                        justify="flex-end"
                    >
                        <Grid item>
                            <Paper style={{ padding: '4px' }}>
                                Showing
                                <Button
                                    onClick={this.handleSizeMenuClick}
                                    disabled={!isArrayValidAndNotEmpty(items)}
                                    style={{
                                        background: 'white',
                                    }}
                                >
                                    {size}
                                </Button>
                                <Menu
                                    id="batch-action-menu"
                                    anchorEl={sizeMenuAnchor}
                                    open={Boolean(sizeMenuAnchor)}
                                    onClose={this.handlePrintMenuClose}
                                >
                                    {
                                        [5, 10, 20].map(aSize => (
                                            <MenuItem
                                                test-id={aSize}
                                                key={aSize}
                                                onClick={this.handleSizeChange(aSize)}
                                                value={aSize}
                                            >
                                                {aSize}
                                            </MenuItem>
                                        ))
                                    }
                                </Menu>
                                items
                                <IconButton
                                    onClick={this.prevPage}
                                    disabled={page < 1}
                                >
                                    <ArrowLeft />
                                </IconButton>
                                {(page * size) + 1}
                                &nbsp;
                                -
                                &nbsp;
                                {end}
                                <IconButton
                                    onClick={this.nextPage}
                                    disabled={end >= jobQueue.length}
                                >
                                    <ArrowRight />
                                </IconButton>
                            </Paper>
                        </Grid>
                        {
                            isArrayValidAndNotEmpty(visibleItems) &&
                            visibleItems.map((aJob, index) =>
                                (
                                    <Grid
                                        item
                                        sm={12}
                                        key={aJob.uuid}
                                        container
                                        spacing={16}
                                        justify="center"
                                    >
                                        <Grid item sm={3}>
                                            {aJob.name}
                                        </Grid>
                                        <Grid item sm={3}>
                                            {aJob.batch}
                                        </Grid>

                                        <Grid item sm={2}>
                                            <OutlinedTextField
                                                label="quantity"
                                                value={aJob.quantity}
                                                onChange={this.updateQuantity(index)}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            {aJob.status}
                                        </Grid>
                                        <Grid item sm={1}>
                                            <IconButton
                                                onClick={this.deleteItem(index)}
                                                disabled={STATE === 'PRINTING'}
                                                color="error"
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ),
                            )
                        }

                        <Grid item sm={12}>
                            <ActionButton
                                className="flo-right"
                                onClick={this.startPrint}
                                disabled={STATE === 'PRINTING'}
                            >
                                Print
                            </ActionButton>
                        </Grid>
                    </Grid>
                </DialogComponent>
                <PrintPDF
                    print={printBarcode}
                    itemsToPrint={itemsToPrint}
                    onPrintComplete={this.onPrintComplete}
                />
            </React.Fragment>
        );
    }
}

BarcodePrintDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

BarcodePrintDialog.defaultProps = {

};

export default connect(null)(BarcodePrintDialog);
