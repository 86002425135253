/**
 * Created by sachinprakash on 4/6/19.
 */
import initialStates from '../../initialStates';
import {
    ASSET_MANUFACTURER_ADD_FAILURE,
    ASSET_MANUFACTURER_ADD_SUCCESS, ASSET_MANUFACTURER_UPDATE_FAILURE, ASSET_MANUFACTURER_UPDATE_SUCCESS,
    ASSET_MANUFACTURERS_LIST_FETCH_FAILURE,
    ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS,
} from './assetManufacturer-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

export const assetManufacturerList = (state = initialStates.assetManufacturer, action) => {
    let newState = {};
    switch (action.type) {
    case ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS:
        console.log('sndkfjahgfakjdfa', action.data);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.Last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            filters: getStringFromObject('filters', action, {}),
        });
        break;
    case ASSET_MANUFACTURERS_LIST_FETCH_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const assetManufacturerAdd = (state = initialStates.addManufacturer, action) => {
    let newState = {};
    switch (action.type) {
    case ASSET_MANUFACTURER_ADD_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            name: getStringFromObject('data.name', action, ''),
            id: getStringFromObject('data.id', action, 0),
            active: !getStringFromObject('data.active', action, false),
            retired: !getStringFromObject('data.retired', action, false),
        });
        break;
    case ASSET_MANUFACTURER_ADD_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const assetManufacturerUpdate = (state = initialStates.addManufacturer, action) => {
    let newState = {};
    switch (action.type) {
    case ASSET_MANUFACTURER_UPDATE_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            name: getStringFromObject('data.name', action, ''),
            id: getStringFromObject('data.id', action, 0),
            active: !getStringFromObject('data.active', action, false),
            retired: !getStringFromObject('data.retired', action, false),
        });
        break;
    case ASSET_MANUFACTURER_UPDATE_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

