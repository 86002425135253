/**
 * Created by sachinprakash on 3/6/19.
 */
import API from '../../../constants/api';

export const ASSET_CATEGORY_LIST_FETCH_REQUEST = '@@assetCategory/ASSET_CATEGORY_LIST_FETCH_REQUEST';
export const ASSET_CATEGORY_LIST_FETCH_SUCCESS = '@@assetCategory/ASSET_CATEGORY_LIST_FETCH_SUCCESS';
export const ASSET_CATEGORY_LIST_FETCH_FAILURE = '@@assetCategory/ASSET_CATEGORY_LIST_FETCH_FAILIRE';

export const ASSET_CATEGORY_LIST_CLEAR = '@@assetCategory/ASSET_CATEGORY_LIST_CLEAR';


export const ASSET_CATEGORY_ADD_REQUEST = '@@assetCategory/ASSET_CATEGORY_ADD_REQUEST';
export const ASSET_CATEGORY_ADD_SUCCESS = '@@assetCategory/ASSET_CATEGORY_ADD_SUCCESS';
export const ASSET_CATEGORY_ADD_FAILURE = '@@assetCategory/ASSET_CATEGORY_ADD_FAILURE';


export const fetchAssetCategoryList = (page, size, filters) => ({
    type: ASSET_CATEGORY_LIST_FETCH_REQUEST,
    api: API.ASSETS.GET_CATEGORIES_LIST,
    page,
    size,
    filters,
});


export const assetCategoryAddOrUpdate = (payload, successCallback) => ({
    type: ASSET_CATEGORY_ADD_REQUEST,
    api: API.ASSETS.GET_CATEGORIES_LIST,
    payload,
    successCallback,
});
export const clearAssetCategoryList = () => ({
    type: ASSET_CATEGORY_LIST_CLEAR,
});
