import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class RoundedCornerButton extends React.Component {
    onClick = (e) => {
        // eslint-disable-next-line react/prop-types
        this.props.onClick(e);
    };

    render() {
        const {
            id,
            testId,
        } = this.props;
        let extraProps = {};
        if (id) {
            extraProps = {
                ...extraProps,
                id,
            };
        }
        if (testId) {
            extraProps = {
                ...extraProps,
                'test-id': testId,
            };
        }
        const buttonStyle = {
            border: this.props.border,
            color: this.props.color,
            background: this.props.backgroundColor,
            borderRadius: this.props.borderRadius,
            boxShadow: 'none',
            height: this.props.height,
            minHeight: this.props.minHeight,
            fontSize: this.props.fontSize,
            width: this.props.width,
            padding: this.props.padding,
            zIndex: this.props.zIndex,
            marginLeft: this.props.marginLeft,
            textTransform: this.props.textTransform,
            ...this.props.style,
        };
        return (
            <Button
                {...extraProps}
                style={buttonStyle}
                variant="contained"
                onClick={this.onClick}
                testId={this.props.testId}
                name={this.props.name}
                type={this.props.type}
                disabled={this.props.disabled}
                fullWidth={this.props.fullWidth}
                disableRipple
            >
                {this.props.label}
            </Button>
        );
    }
}

RoundedCornerButton.propTypes = {
    onClick: PropTypes.func,
    border: PropTypes.string,
    testId: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string,
    fontSize: PropTypes.string,
    borderRadius: PropTypes.string,
    padding: PropTypes.string,
    zIndex: PropTypes.string,
    width: PropTypes.string,
    name: PropTypes.string,
    marginLeft: PropTypes.string,
    textTransform: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

RoundedCornerButton.defaultProps = {
    onClick: () => {},
    border: '',
    color: 'white',
    backgroundColor: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    borderRadius: '15px',
    label: '',
    name: '',
    height: '1.8rem',
    style: {},
    minHeight: '1rem',
    fontSize: '0.8rem',
    padding: '0',
    width: '',
    zIndex: '',
    type: '',
    id: '',
    testId: '',
    marginLeft: '',
    disabled: false,
    textTransform: 'initial',
    fullWidth: false,
};

export default RoundedCornerButton;
