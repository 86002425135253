/**
 * Created by vinay on 20/12/18.
 */
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { formatDate, setTimeForDate } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const generateBatchesSearchConstruct = (filters) => {
    let construct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        if (filters.fromDate && filters.toDate) {
            construct = getCombined(
                construct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode(
                        'stock_production_lot.lifeDate',
                        formatDate(setTimeForDate(filters.fromDate), 'dd/MM/yyyy HH:mm'),
                        '>=',
                    ),
                    'AND',
                    generateObjectWithCriteriaMode(
                        'stock_production_lot.lifeDate',
                        formatDate(setTimeForDate(filters.toDate, 23, 59), 'dd/MM/yyyy HH:mm'),
                        '<=',
                    ),
                ),
            );
        } else if (!filters.fromDate && filters.toDate) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_production_lot.lifeDate',
                    formatDate(setTimeForDate(filters.toDate, 23, 59), 'dd/MM/yyyy HH:mm'),
                    '<=',
                ),
            );
        } else if (filters.fromDate && !filters.toDate) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_production_lot.lifeDate',
                    formatDate(setTimeForDate(filters.fromDate), 'dd/MM/yyyy HH:mm'),
                    '>=',
                ),
            );
        }
        const supplier = getStringFromObject('supplier.id', filters);
        if (supplier) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'res_partner.id',
                    filters.supplier.id,
                    '=',
                ),
            );
        }
        const category = getStringFromObject('category.uuid', filters);
        if (category) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'product_category.uuid',
                    category,
                    '=',
                ),
            );
        }
        const qunatOp = getStringFromObject('quantityOperation', filters);
        const quantity = getStringFromObject('quantity', filters, null);
        if (qunatOp && quantity != null) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_quant.quantity',
                    quantity,
                    qunatOp,
                ),
            );
        }
        if (filters.hideZeroBatches) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_quant.quantity',
                    0,
                    '>',
                ),
            );
        }
        if (isObjectValidAndNotEmpty(filters.location)) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'stock_location.id',
                    filters.location.id,
                    '=',
                ),
            );
        } else {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('stock_location.usage', 'internal', '='),
            );
        }
        let nameConstruct = null;
        if (filters.searchString) {
            // add product name search criteria
            nameConstruct = getCombined(
                generateObjectWithCriteriaMode(
                    'stock_production_lot.name',
                    filters.searchString,
                    ':',
                ),
                'OR',
                generateObjectWithCriteriaMode(
                    'product.nameTemplate',
                    filters.searchString,
                    ':',
                ),
            );
            // batchNumber
        }
        if (filters.productName) {
            const productCriteria = generateObjectWithCriteriaMode(
                'product.id',
                filters.productName.productId,
                ':',
            );
            nameConstruct = getCombined(
                productCriteria,
                'OR',
                nameConstruct,
            );
        }
        construct = getCombined(
            construct,
            'AND',
            nameConstruct,
        );
        if (filters.defaultCode) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'product.defaultCode',
                    filters.defaultCode,
                    '=',
                ),
            );
        }
        if (filters.ean13) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode(
                    'product_gtin_map.gtin',
                    filters.ean13,
                    '=',
                ),
            );
        }
    } else {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('stock_location.usage', 'internal', '='),
        );
    }
    return construct;
};

export default generateBatchesSearchConstruct;
