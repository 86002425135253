import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import { Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
// import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
// import AssetsContainer from '../../containers/AssetsContainer/AssetContainer';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { assetCategory, assetManufacturer } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
// import { assets } from '../../constants/constants';


export const assetsTabs = [
    {
        label: 'Assets',
        route: '/assets',
        value: 'assets',
    },
    {
        label: 'Asset Category',
        route: '/assets/asset-category',
        value: 'assetCategory',
    },
    {
        label: 'Asset Manufacturer',
        route: '/assets/asset-manufacturer',
        value: 'assetManufacturer',
    },
];


const style = theme => ({
    appBar: {
        backgroundColor: 'white',
        minHeight: '4rem',
        width: '50%',
        margin: '1em',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        // width: '40%',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

class AssetsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.changeTabForRoute(props),
        };
    }

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        switch (route) {
        case assetCategory:
            return 'assetCategory';
        case assetManufacturer:
            return 'assetManufacturer';
        default: return 'assets';
        }
    };

    tabChange = (event, value) => {
        const currentTab = assetsTabs.find(t => t.value === value);
        if (isObjectValidAndNotEmpty(currentTab)) {
            this.props.history.push(currentTab.route);
        }
        this.setState({
            activeTab: value,
        });
    };

    checkForAssetEdit = (path) => {
        let result = false;
        const editPattern = /edit/g;
        const viewPattern = /view/g;
        const addPattern = /add/g;
        if (editPattern.test(path) || viewPattern.test(path) || addPattern.test(path)) {
            result = true;
        }
        return result;
    };

    render() {
        const { activeTab } = this.state;
        const { classes, children, history } = this.props;
        const path = getStringFromObject('location.pathname', history);
        console.log('sdhfgakjsdfgkajsgdf', this.checkForAssetEdit(path));
        return (
            <React.Fragment>
                <div style={{ background: '#fff', padding: '2rem', minHeight: '100%' }}>
                    {
                        isArrayValidAndNotEmpty(assetsTabs) && !this.checkForAssetEdit(path) &&
                        <AppBar position="static" className={classes.appBar}>
                            <Tabs
                                value={activeTab}
                                onChange={this.tabChange}
                                fullWidth
                                scrollButtons="auto"
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                            >
                                {
                                    isArrayValidAndNotEmpty(assetsTabs) &&
                                    assetsTabs.map(tab => (
                                        <Tab
                                            test-id={tab.label}
                                            value={tab.value}
                                            key={tab.value}
                                            label={tab.label}
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                        />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    }
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

AssetsPage.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    children: PropTypes.node,
};
AssetsPage.defaultProps = {
    classes: {},
    history: {},
    children: null,
};

export default withStyles(style)(AssetsPage);
