/**
 * Created by sachinprakash on 4/6/19.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    ASSET_MANUFACTURER_ADD_FAILURE,
    ASSET_MANUFACTURER_ADD_SUCCESS,
    ASSET_MANUFACTURER_UPDATE_FAILURE,
    ASSET_MANUFACTURER_UPDATE_SUCCESS,
    ASSET_MANUFACTURERS_LIST_FETCH_FAILURE,
    ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS,
} from '../../redux/modules/assetManufacturer/assetManufacturer-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* assetManufacturerListFetch(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const {
            page,
            size,
            filter,
        } = action;
        const filterToApply = encodeURIComponent(JSON.stringify(filter));
        const apiWithQueryParam =
            `${action.api}?page=${page}&size=${size}&sortCol=name&sortOrder=ASC&filter=${filterToApply}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam, config);
        yield put({
            type: ASSET_MANUFACTURERS_LIST_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_MANUFACTURERS_LIST_FETCH_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetManufacturerAddWorkerRequest(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({ type: ASSET_MANUFACTURER_ADD_SUCCESS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: 'Added Manufacturer Successfully' });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_MANUFACTURER_ADD_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetManufacturerUpdateWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        console.log('dhasfhlksfdh', action, retriable);
        const { id } = action.payload;
        const apiWithId = `${action.api}/${id}`;
        let response = null;
        if (getStringFromObject('delete', action.payload)) {
            response = yield call(retriable().delete, apiWithId, config);
            console.log('jhflajsfhdaklhfalks', apiWithId, response);
        } else response = yield call(retriable().put, apiWithId, action.payload, config);
        yield put({ type: ASSET_MANUFACTURER_UPDATE_SUCCESS, data: response.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: SUCCESS_MESSAGE, message: 'Updated Manufacturer Successfully' });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        console.log('jhflajsfhdaklhfalks', e);
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_MANUFACTURER_UPDATE_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
