import API from '../../../constants/api';

export const FETCH_APPOINTMENT_DETAILS_REQUEST = '@@Visit/FETCH_APPOINTMENT_DETAILS_REQUEST';
export const FETCH_APPOINTMENT_DETAILS_SUCCESS = '@@Visit/FETCH_APPOINTMENT_DETAILS_SUCCESS';
export const FETCH_APPOINTMENT_DETAILS_FAILURE = '@@Visit/FETCH_APPOINTMENT_DETAILS_FAILURE';

export const SELECT_APPOINTMENT_DETAILS_SUCCESS = '@@Visit/SELECT_APPOINTMENT_DETAILS_SUCCESS';

export const SET_PATIENT_UUID = '@@Visit/SET_PATIENT_UUID';

export const CLEAR_APPOINTMENT_DETAILS = '@@Visit/CLEAR_APPOINTMENT_DETAILS';
export const CLEAR_SELECTED_APPOINTMENT_DETAILS = '@@Visit/CLEAR_SELECTED_APPOINTMENT_DETAILS';

export const clearAppointmentDetails = () => ({
    type: CLEAR_APPOINTMENT_DETAILS,
});

export const clearSelectedAppointmentDetails = () => ({
    type: CLEAR_SELECTED_APPOINTMENT_DETAILS,
});

export const setAppointmentDetails = appointmentDetails => ({
    type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
    appointmentDetails,
});

export const fetchAppointmentDetails = uuid => ({
    type: FETCH_APPOINTMENT_DETAILS_REQUEST,
    api: `${API.APPOINTMENT.APPOINTMENT_BASE}/${uuid}`,
});

export const selectAppointmentDetails = appointmentDetails => ({
    type: SELECT_APPOINTMENT_DETAILS_SUCCESS,
    appointmentDetails,
});
