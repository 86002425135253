/**
 * Created by sachinprakash on 15/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    ADD_OR_UPDATE_DEPARTMENT_REQUEST,
    FETCH_DEPARTMENT_REQUEST,
} from '../../redux/modules/LabSettings/labTest-actions';
import { addOrUpdateDepartmentWorker, fetchPagesOfDepartmentWorker } from './labDepartmentWorkerSaga';

export function* fetchPagesOfDepartmentWatcher() {
    yield takeLatest(FETCH_DEPARTMENT_REQUEST, fetchPagesOfDepartmentWorker);
}
export function* addOrUpdateDepartmentWatcher() {
    yield takeLatest(ADD_OR_UPDATE_DEPARTMENT_REQUEST, addOrUpdateDepartmentWorker);
}

export const test = 'test';
