/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events, no-unused-vars */
import React from 'react';
import deepEqual from 'react-fast-compare';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ArrowRight from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';
import { isArrayValidAndNotEmpty, isValidFunction } from '../../../constants/CommonUtil';
import generateFormikFields from '../generateFormikFields';
import shouldHide, { shouldShow } from '../../MaterialTableV2/TabelRowUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const ICON_ACTIONS = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
};

const styles = {
    root: {
        color: blue[600],
        '&$checked': {
            color: blue[500],
        },
    },
    checked: {},
    narrow: {
        minWidth: '26em',
    },
    disabledText: {
        color: 'black',
    },
    error: {
        color: '',
    },
    warning: {
        color: '',
    },
};

const checkIfDisable = (cell, rowValue) => {
    if (cell.disableIf) {
        const { disableIf } = cell;
        const checkValue = getStringFromObject(disableIf.name, rowValue, null);
        if (checkValue === disableIf.value || checkValue === undefined) {
            return true;
        }
    }

    return false;
};


const getChoicesFromRow = (cell, rowValue) => {
    if (cell.takeChoicesFromRow) {
        const { takeChoicesFromRow } = cell;
        const choices = getStringFromObject(takeChoicesFromRow, rowValue, []);
        if (isArrayValidAndNotEmpty(choices)) {
            return choices;
        }
    }
    return [];
};

class FormikTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.isRowExpanded(props, false),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.controlledExpand && !deepEqual(nextProps.expanded, this.props.expanded)) {
            this.setState({ expanded: this.isRowExpanded(nextProps, this.state.expanded) });
        }
    }

    onRowExpand = () => {
        const {
            isExpandable,
            index,
            onRowClick,
        } = this.props;
        if (isExpandable) {
            this.setState(prev => ({ expanded: !prev.expanded }), this.handleControlledExpand);
        }
        onRowClick(index);
    };

    handleControlledExpand = () => {
        const {
            index,
            expanded,
            controlledExpand,
            rowExpandHandler,
        } = this.props;
        if (controlledExpand && isValidFunction(rowExpandHandler)) {
            const copy = [...expanded];
            const findIndex = expanded.indexOf(index);
            if (findIndex !== -1) {
                copy.splice(findIndex, 1);
            } else {
                copy.push(index);
            }
            rowExpandHandler(copy, index);
        }
    };

    isRowExpanded = (props, defaultVal) => {
        const { controlledExpand, expanded, index } = props;
        if (controlledExpand) {
            return isArrayValidAndNotEmpty(expanded) && (expanded.indexOf(index) !== -1);
        }
        return defaultVal;
    };

    handleSelect = (value) => {
        const { handleSelectAction, index } = this.props;
        handleSelectAction(value, index);
    };

    handleDeleteRowClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { handleDeleteRowClick, index } = this.props;
        handleDeleteRowClick(e, index);
    };

    handleSaveIconClick = (e) => {
        e.preventDefault();
        e.stopPropagation(); const { handleSaveIconClick, index } = this.props;
        handleSaveIconClick(e, index);
    };

    handleInfoIconClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { handleInfoIconClick, index } = this.props;
        handleInfoIconClick(e, index);
    };

    handleAdditionalButtonClick = (e, additionalButtonIndex) => {
        const { additionalButtons, index } = this.props;
        e.preventDefault();
        e.stopPropagation();
        const buttonClick = getStringFromObject(`[${additionalButtonIndex}].onClick`, additionalButtons);
        if (isValidFunction(buttonClick)) {
            buttonClick(e, index);
        }
    };

    handleAddRef = (ref, colIndex) => {
        const { addRef, index } = this.props;
        addRef(ref, index, colIndex);
    };

    render() {
        const {
            isSelected,
            tableCells,
            isEditable,
            index,
            handleClick,
            classes,
            rowStyle,
            hideCheckBox,
            member,
            actionHandlers,
            isExpandable,
            handleRowExpand,
            getExpandableComponent,
            paramMap,
            showRowNumber,
            actionParams,
            rowValue,
            // setFieldValue,
            // form,
            saveIcon,
            deleteIcon,
            infoIcon,
            errorIcon,
            warningIcon,
            customValidationFunctions,
            handleIconAction,
            handleButtonAction,
            rowStyleSchema,
            formValues,
            additionalButtons,
        } = this.props;

        const { expanded } = this.state;
        if (rowValue.productName === 'REMOVAL OF FOREIGN BOADY(CORNEA,CONJUCTIVA)') {
            console.log('sandeddedd12312321', rowValue, index, member);
        }
        const tableRowStyle = { ...rowStyle };

        if (rowStyleSchema === 'attendance') {
            if (rowValue.markedForApproval) {
                tableRowStyle.backgroundColor = '#DCEDC8';
            }
        }
        const isRowCheckboxDisabled = getStringFromObject('rowCheckboxDisabled', rowValue, false);
        if (getStringFromObject('rowError', rowValue, false)) {
            tableRowStyle.backgroundColor = '#FECACA80';
        }
        return (
            <React.Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    selected={isSelected}
                    style={tableRowStyle}
                    onClick={this.onRowExpand}
                >
                    {
                        isEditable && (!hideCheckBox) &&
                        <TableCell>
                            <Checkbox
                                checked={isSelected}
                                classes={{
                                    checked: classes.checked,
                                }}
                                onClick={event => handleClick(event, index)}
                                data-test-id={`${member}-cb`}
                                disabled={isRowCheckboxDisabled}
                            />
                        </TableCell>
                    }
                    {
                        isExpandable &&
                        <TableCell>
                            <ArrowRight
                                style={{
                                    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'all 0.5s',
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                }}
                            />
                        </TableCell>
                    }
                    {
                        showRowNumber &&
                        <TableCell style={{ fontSize: '1rem' }}>
                            {index + 1}
                        </TableCell>
                    }
                    {
                        tableCells.map((cell, colIndex) => {
                            console.log('afhkkahdfjkaa', member, rowValue);
                            let className = cell.error && rowValue.error ? classes.error : '';
                            className = cell.warning && rowValue.warning ? classes.warning : '';
                            return (
                                <TableCell
                                    scope="row"
                                    className={classNames(cell.narrow ? classes.narrow : '')}
                                    key={cell.id}
                                    style={cell.cellStyle}
                                    data-test-id={`${cell.id}[${index}]`}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {
                                            cell.error && rowValue.error &&
                                            <div onClick={e => handleIconAction(e, ICON_ACTIONS.ERROR, index)}>
                                                {errorIcon}
                                            </div>
                                        }
                                        {
                                            cell.warning && rowValue.warning &&
                                            <div onClick={e => handleIconAction(e, ICON_ACTIONS.WARNING, index)}>
                                                {warningIcon}
                                            </div>
                                        }
                                        <div className={className} style={{ width: className ? '95%' : '100%' }}>
                                            {
                                                shouldShow(cell, rowValue) && !shouldHide(cell, rowValue) &&
                                                generateFormikFields({
                                                    formValues,
                                                    fieldData: cell,
                                                    member,
                                                    editable: cell.fieldType === 'menuActionButton' ? (!checkIfDisable(cell, rowValue)) : isEditable && (!checkIfDisable(cell, rowValue)),
                                                    handleSelectAction: this.handleSelect,
                                                    handleButtonAction,
                                                    actionHandlers,
                                                    fieldChoices: getChoicesFromRow(cell, rowValue),
                                                    paramMap, //  urlParameters map for autocompletes
                                                    actionParams,
                                                    addRef: ref => this.handleAddRef(ref, colIndex),
                                                    customValidationFunctions,
                                                })
                                            }
                                        </div>
                                    </div>
                                </TableCell>
                            );
                        })
                    }
                    {
                        isEditable && deleteIcon && !getStringFromObject('hideDeleteButton', rowValue, false) &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleDeleteRowClick} data-test-id={`${member}-delete`}>
                                <DeleteIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                    {
                        saveIcon && isEditable &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleSaveIconClick}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                    {
                        infoIcon &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleInfoIconClick} data-test-id={`${member}-info`}>
                                <InfoIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                    {
                        isArrayValidAndNotEmpty(additionalButtons) &&
                        additionalButtons.map((e, additionalButonIndex) => (
                            <TableCell key={`${member}${e.name}`} style={{ width: '2%' }}>
                                <Tooltip title={e.label || ''}>
                                    <IconButton onClick={(event) => { this.handleAdditionalButtonClick(event, additionalButonIndex); }} data-test-id={`${member}-info`}>
                                        {
                                            e.element
                                        }
                                    </IconButton>
                                </Tooltip>
                            </TableCell>))
                    }
                </TableRow>
                {
                    expanded &&
                    (
                        getExpandableComponent(
                            member,
                            index,
                            handleRowExpand,
                        )
                    )
                }
            </React.Fragment>
        );
    }
}

FormikTableRow.propTypes = {
    isSelected: PropTypes.bool,
    tableCells: PropTypes.array.isRequired,
    paramMap: PropTypes.object,
    isEditable: PropTypes.bool,
    hideCheckBox: PropTypes.bool,
    handleClick: PropTypes.func,
    handleSelectAction: PropTypes.func,
    index: PropTypes.number.isRequired,
    member: PropTypes.string.isRequired,
    rowStyle: PropTypes.object,
    classes: PropTypes.object.isRequired,
    actionHandlers: PropTypes.object,
    actionParams: PropTypes.object,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    handleRowExpand: PropTypes.func, // TODO remove, what is this being used for ?
    getExpandableComponent: PropTypes.func,
    onRowClick: PropTypes.func,
    rowValue: PropTypes.object,
    customValidationFunctions: PropTypes.object,
    handleDeleteRowClick: PropTypes.func,
    addRef: PropTypes.func,
    handleSaveIconClick: PropTypes.func,
    handleInfoIconClick: PropTypes.func,
    saveIcon: PropTypes.bool,
    infoIcon: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    errorIcon: PropTypes.element,
    warningIcon: PropTypes.element,
    handleIconAction: PropTypes.func,
    handleButtonAction: PropTypes.func,
    controlledExpand: PropTypes.bool,
    expanded: PropTypes.array,
    rowStyleSchema: PropTypes.string,
    rowExpandHandler: PropTypes.func,
    formValues: PropTypes.object,
    // handles controlled row expand
    // setFieldValue: PropTypes.func.isRequired,
    // form: PropTypes.object.isRequired,
    additionalButtons: PropTypes.array,
};

FormikTableRow.defaultProps = {
    isSelected: false,
    controlledExpand: false,
    expanded: [],
    rowExpandHandler: () => {},
    handleClick: () => {},
    handleSelectAction: () => {},
    isEditable: false,
    hideCheckBox: false,
    rowStyle: {},
    paramMap: {},
    actionParams: {},
    actionHandlers: {},
    isExpandable: false,
    showRowNumber: false,
    handleRowExpand: () => {},
    getExpandableComponent: () => {},
    addRef: () => {},
    handleDeleteRowClick: () => {},
    handleSaveIconClick: () => {},
    handleInfoIconClick: () => {},
    onRowClick: () => {},
    rowValue: {},
    customValidationFunctions: {},
    saveIcon: false,
    infoIcon: false,
    deleteIcon: false,
    errorIcon: <React.Fragment />,
    warningIcon: <React.Fragment />,
    handleIconAction: () => {},
    handleButtonAction: () => {},
    rowStyleSchema: '',
    formValues: {},
    additionalButtons: [],
};

export default withStyles(styles)(FormikTableRow);
