import initialStates from '../../initialStates';
import {
    CLEAR_CONSUMABLE_STATE,
    FETCH_CONSUMABLE_LIST_FAILURE,
    FETCH_CONSUMABLE_LIST_REQUEST,
    FETCH_CONSUMABLE_LIST_SUCCESS,
} from './consumable-actions';

const consumablesReducer = (state = initialStates.consumables, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_CONSUMABLE_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_CONSUMABLE_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_CONSUMABLE_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case CLEAR_CONSUMABLE_STATE:
        newState = initialStates.consumables;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default consumablesReducer;
