import { searchActivePatientPrivilege, searchAllPatientPrivilege } from '../../constants/privilegeConstants';

export const searchPatientTabs = [
    {
        value: 'active',
        label: 'Active',
        key: 'patient-search-active',
        privilege: searchActivePatientPrivilege,
    },
    {
        value: 'all',
        label: 'All',
        key: 'patient-search-all',
        privilege: searchAllPatientPrivilege,
    },
];

export const a = 'b';
