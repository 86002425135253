import API from '../../../../constants/api';

export const FETCH_BED_TYPE_LIST_REQUEST = '@@bedType/FETCH_BED_TYPE_LIST_REQUEST';
export const FETCH_BED_TYPE_LIST_FAILURE = '@@bedType/FETCH_BED_TYPE_LIST_FAILURE';
export const FETCH_BED_TYPE_LIST_SUCCESS = '@@bedType/FETCH_BED_TYPE_LIST_SUCCESS';

export const CREATE_BED_TYPE_REQUEST = '@@bedType/CREATE_BED_TYPE_REQUEST';
export const CREATE_BED_TYPE_FAILURE = '@@bedType/CREATE_BED_TYPE_FAILURE';
export const CREATE_BED_TYPE_SUCCESS = '@@bedType/CREATE_BED_TYPE_SUCCESS';

export const CLEAR_BED_TYPE_STATE = '@@bedType/CLEAR_BED_TYPE_STATE';

export const fetchPagesOfBedType = (api = API.BED_TYPE.PAGES, page, size, sortCol, sortOrder, filters) => {
    console.log('jakdhflksjdfhakjlsdfh', api);
    return ({
        type: FETCH_BED_TYPE_LIST_REQUEST,
        api:
        // eslint-disable-next-line
            `${api}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&name=${filters.name}`,
        page,
        size,
        sortCol,
        sortOrder,
        filters,
    });
};

export const createBedTypeRequest = (bedType, isUpdate, successCallback, failureCallBack) => ({
    type: CREATE_BED_TYPE_REQUEST,
    api: API.BED_TYPE.CREATE,
    bedType,
    isUpdate,
    successCallback,
    failureCallBack,
});

export const clearBedTypeState = () => ({
    type: CLEAR_BED_TYPE_STATE,
});

