import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { FETCH_ACCOUNT_INVOICE_FAILURE } from '../../redux/modules/accountInvoice/accountInvoice-actions';
import { FETCH_APPOINTMENTS_SUCCESS } from '../../redux/modules/appointmentsList/appointmentsList-actions';
import generateAppointmentListConstruct from './appointmentListSearchConstructUtil';

export function* fetchAppointmentsListWorker(action) {
    try {
        const {
            page,
            api,
            size,
            sortCol,
            sortDir,
            filters,
        } = action;
        const apiWithQueryParams =
            // eslint-disable-next-line max-len
            `${api}?page=${page}&size=${size}&sortDir=${sortDir}&sortCol=${sortCol}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const searchConstruct = generateAppointmentListConstruct(filters);
        const response = yield call(
            retriable().post,
            apiWithQueryParams,
            searchConstruct,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put({
            type: FETCH_APPOINTMENTS_SUCCESS,
            data: response.data,
            filters: action.filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: getErrorMessage(e),
            });
        }
        yield put({ type: FETCH_ACCOUNT_INVOICE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchAppointmentsListWorker;
