import {
    voucherStates,
    voucherTypes,
} from '../CashPaymentVoucher/CashPaymentVoucherUtil';

import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS = {
    PARTNER: 'partner',
    PARTNER_ACCOUNT: 'partnerAccount',
    PAYMENT_DATE: 'paymentDate',
    PAYMENT_METHOD: 'paymentMethod',
    CREDIT_FORM: 'creditFrom',
    BANK_NAME: 'bankName',
    REFERENCE_NUMBER: 'referenceNumber',
    DATE: 'date',
    REMARKS: 'remarks',
    TOTAL_AMOUNT_TO_PAY: 'totalAmountToPay',
    VOUCHER_TYPE: 'voucherType',
    STATE: 'state',
};

export const voucherTypesRadioChoices = [
    { key: voucherTypes.PAYMENT, value: 'Payment' },
    { key: voucherTypes.RECEIPT, value: 'Receive' },
];

export const initialValues = {
    [PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.STATE]: voucherStates.DRAFT,
    [PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.PAYMENT_DATE]: new Date(),
    [PAY_OR_RECEIVE_ON_ACCOUNT_FORM_FIELDS.VOUCHER_TYPE]: voucherTypes.PAYMENT,
};

export const defaultAllowedPartnerAccountTypes = {
    payable: 'payment',
    receivable: 'receipt',
};

export const defaultAllowedCreditAccountTypes = ['liquidity'];

export const getVoucherTypeByAccountType = (accountType, allowedPartnerAccountTypes) => {
    const accountTypes = isObjectValidAndNotEmpty(allowedPartnerAccountTypes) ?
        { ...allowedPartnerAccountTypes } :
        { ...defaultAllowedPartnerAccountTypes };
    if (accountTypes[accountType]) {
        return accountTypes[accountType];
    }
    return '';
};
