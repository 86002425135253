/**
 * Created by vinay on 16/1/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    arrayInsert,
    arrayRemove,
    autofill,
    change,
    Field,
    formValueSelector,
    initialize,
    reduxForm,
} from 'redux-form';
import { withStyles, Card, CardContent } from '@material-ui/core';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Dialog from '@material-ui/core/Dialog/Dialog';
import cloneDeep from 'clone-deep';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';

import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import {
    getAllowedDefaultAccountTypes,
    apiCatchBlockFunction,
    getIndexFromFieldName,
    isValidFunction,
    roundedValueFixedToTwoDigits,
    valueToFixedTwoDigits, getJsonPath,
} from '../../constants/CommonUtil';
// import UploadFile from '../FormFieldComponents/UploadFiles/UploadFile';
import ReduxFormMaterialTable from '../FormFieldComponents/ReduxFormMaterialTable/ReduxFormMaterialTable';
import ReduxFormCheckbox from '../FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import {
    calculateTaxAndTotal,
    getSubTotal,
    mapExpenseToUiObject,
    mapUiObjectToExpense,
} from '../../mapper/ExpenseMapper';
import {
    clearSelectedExpenseInvoice,
    createAccountExpenseRequest,
    fetchAccountExpenseRequest,
    fetchAccountExpenseRequestById,
} from '../../redux/modules/accountExpense/accountExpense-actions';
import { isEmpty, minValue, required } from '../../constants/FormValidations';
import ActionButton from '../ActionButton/ActionButton';
import { APPLICATION_CONFIG_URL, PAYMENT_METHODS } from '../../constants/constants';
import CreateNewSupplierDialog from '../../components/PurchaseOrderComponents/CreateNewSupplierDialog';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import OutlinedTextField from '../OutlinedTextField';
// import DateInput from '../FormFieldComponents/DateInput/DateInput';
import ReduxFormSelectField
    from '../../containers/RegistrationAppComponents/FormComponents/ReduxFormSelectField/ReduxFormSelectField';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';
import { getSupplierSubCompany } from '../PurchaseOrderComponents/MakeBillDialog/MakeBillDialogUtil';
import { commonGetApiRequest } from '../../redux/modules/common/common-actions';
import ReduxFormTextField from '../FormFieldComponents/ReduxFormTextField/ReduxFormTextField';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { expenseEditPrivilege } from '../../constants/privilegeConstants';
import { formatUrl } from '../../constants/UrlUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'expenseForm';

const style = theme => ({
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    paper: {
        background: '#fafafa',
    },
    root: {
        overflow: 'visible',
    },
});

class ExpenseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schemaJson: null,
            schema: null,
            readOnly: false,
            tempName: '',
            subCompany: '',
            createNewSupplier: false,
            print: false,
            journalEntryPrintData: null,
            printJournal: false,
        };
    }

    componentWillMount() {
        this.initializeForm({
            expenseLines: [{}],
            paymentDate: getDateInYYYYMMDDFormat(new Date()),
        });
        const { dispatch, selectedExpenseUuid, selectedExpenseId } = this.props;
        if (selectedExpenseId) {
            dispatch(fetchAccountExpenseRequestById(selectedExpenseId));
        } else if (selectedExpenseUuid) {
            dispatch(fetchAccountExpenseRequest(selectedExpenseUuid));
        }
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/Expense.json'))
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schemaJson: json,
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        this.fetchEmployeeShareStatus();
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.selectedExpense, this.props.selectedExpense)) {
            this.setState({ readOnly: true });
            console.log('jklhasdfaskdfhalksj', nextProps);
            this.initializeForm(mapExpenseToUiObject(nextProps.selectedExpense));
            this.fetchEmployeeShareStatus(nextProps);
        }
    }

    componentDidUpdate(prevProps) {
        const oldSupplier = getStringFromObject('payee', prevProps);
        const newSupplier = getStringFromObject('payee', this.props);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
            if (!this.state.readOnly) {
                this.props.change('assetAccount', null);
                const {
                    expenseLines,
                } = this.props;
                const newExpenseLines = expenseLines.map(anExpenseLine => ({ ...anExpenseLine, category: null }));
                this.props.change('expenseLines', newExpenseLines);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedExpenseInvoice());
    }

    onAccountSelect = (account, fn, field, fields) => {
        if (isObjectValidAndNotEmpty(account) && fn && field && isObjectValidAndNotEmpty(fields)) {
            const valuesLength = fields.length;
            console.log('asdfsdfsdfdsfdsf', valuesLength);
            // const index = getIndexFromFieldName(field);
            // if (index === (valuesLength - 1).toString()) {
            //     // Add row automatically..
            //     fields.push({});
            // }
        }
    };

    getSubCompanyOfSupplier = async (supplier) => {
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        console.log('asd09audas09dsa', subCompany);
        this.setState({
            subCompany,
        }, () => {
            const {
                schemaJson,
            } = this.state;
            const newSchema = cloneDeep(schemaJson);
            if (isObjectValidAndNotEmpty(schemaJson)) {
                newSchema.tableCells = newSchema.tableCells.map((aCell) => {
                    const newCell = { ...aCell };
                    if (newCell.name === 'category' || newCell.name === 'costCenter') {
                        newCell.dataSourceApi = formatUrl(newCell.dataSourceApi, { subCompany: subCompany || '' });
                    }
                    return newCell;
                });
                this.setState({
                    schema: newSchema,
                });
            }
        });
    };

    amountChangeHandler = (value, fn, field, fields, dispatch) => {
        console.log('a,fhajdfhajkhdj', value, fn, field, fields, dispatch);
        if (fn && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
            const index = getIndexFromFieldName(field);
            if (index != null) {
                const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
                if (isObjectValidAndNotEmpty(rowValue)) {
                    const newRowValue = cloneDeep(rowValue);
                    newRowValue.amount = Math.max(NumberOf(value), 0);
                    dispatch(arrayRemove('expenseForm', 'expenseLines', index));
                    dispatch(arrayInsert('expenseForm', 'expenseLines', index, calculateTaxAndTotal(newRowValue)));
                }
            }
        }
    };

    handleTaxSelect = (value, fn, field, fields, dispatch) => {
        if (fn && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
        ) {
            const index = getIndexFromFieldName(field);
            if (index != null) {
                let rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
                if (isObjectValidAndNotEmpty(rowValue)) {
                    rowValue = { ...rowValue, taxes: value };
                    dispatch(arrayRemove('expenseForm', 'expenseLines', index));
                    dispatch(arrayInsert('expenseForm', 'expenseLines', index, calculateTaxAndTotal(rowValue)));
                }
            }
        }
    };

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        dispatch(initialize(formName, {
            ...formValues,
        }));
    };

    fetchJournalEntriesAndPrint = () => {
        const { selectedExpense, dispatch } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', selectedExpense);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    handleSave = (values) => {
        this.props.dispatch(createAccountExpenseRequest(mapUiObjectToExpense(values)));
    };

    handleAddNewLine = (selected, fields) => {
        if (isObjectValidAndNotEmpty(fields)) {
            fields.push({
                sNo: fields.length + 1,
            });
        }
    };

    handleCloseCreateNewSupplier = (createdPartner) => {
        this.setState({
            createNewSupplier: false,
        }, () => {
            const { dispatch } = this.props;
            dispatch(autofill(formName, 'payee', createdPartner));
            this.handleOnPayeeSelect(createdPartner, formName, null, null, dispatch);
        });
    };

    fetchEmployeeShareStatus = async (nextProps) => {
        const {
            dispatch,
        } = this.props;
        try {
            const response = await axios.get(`${API.ACCOUNT_EXPENSE.GET_EMPLOYEE_SHARE_STATUS}?expenseUuid=${getStringFromObject('selectedExpense.expenseUuid', nextProps)}`);
            const employeeShareExists = getStringFromObject('status', response.data) || false;
            this.props.change(
                'haveEmployeeShare', employeeShareExists,
            );
        } catch (e) {
            apiCatchBlockFunction(e, dispatch, () => { this.fetchEmployeeShareStatus(); });
        }
    };

    handleOnPayeeSelect = (supplier, formNameParam, field, fields, dispatch) => {
        // get accounts for selected payee
        if (isObjectValidAndNotEmpty(supplier) && formNameParam && isValidFunction(dispatch)) {
            if (supplier.uuid && (typeof supplier.uuid === 'string') && supplier.uuid.includes('NCD-')) {
                const tempName = supplier.uuid.substr(supplier.uuid.search('-') + 1);
                this.setState({
                    createNewSupplier: true,
                    tempName,
                }, () => dispatch(autofill(formNameParam, 'payee', null)));
            }
        }
    };

    printHandler = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    handleChangeDiscountPerc = () => {
        const { dispatch } = this.props;
        dispatch(change(formName, 'discountAmount', 0));
    };

    handleChangeDiscountAmount = () => {
        const { dispatch } = this.props;
        dispatch(change(formName, 'discountPer', 0));
    };

    handleEdit = () => {
        this.setState({ readOnly: false }, () => {
            const newSupplier = getStringFromObject('payee', this.props);
            this.getSubCompanyOfSupplier(newSupplier);
        });
    };

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            // pristine,
            currency,
            // reset,
            paymentMethod,
            formValues,
        } = this.props;
        const {
            schema,
            readOnly,
            createNewSupplier,
            tempName,
            subCompany,
            journalEntryPrintData,
            printJournal,
        } = this.state;
        const { subTotal, discountAmount, total } = getSubTotal(formValues);
        const printData = {
            ...formValues,
            company: this.props.company,
            currency: this.props.currency,
            subTotal,
            discountAmount,
            total,
        };
        const uuid = getStringFromObject('expenseUuid', formValues);
        const isPeriodClosed = getStringFromObject('isPeriodClosed', formValues);
        console.log('agfjahfjka', this.props, isPeriodClosed);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>Expense {readOnly
                                    ?
                                    '('.concat(getStringFromObject('name', formValues)).concat(')')
                                    :
                                    ''
                                }
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="expense-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <div className="mt-2" style={{ paddingLeft: '2rem' }}>
                                <Grid container className="mt-2" justify="space-between">
                                    <Grid item lg={10} md={10} sm={10}>
                                        <Grid container className="mt-2" justify="space-between">
                                            <Grid item lg={5} md={5} sm={6} alignItems="flex-end">
                                                <Grid container direction="column" spacing={8} className="mt-0-5">
                                                    <Grid item>
                                                        <Grid container spacing={10}>
                                                            <Grid item sm={12} lg={12} md={12}>
                                                                <Field
                                                                    testId="payeename"
                                                                    name="payee"
                                                                    component={ReduxFormReactSelectMaterial}
                                                                    dataSourceConfig={{
                                                                        text: 'name',
                                                                        value: 'uuid',
                                                                    }}
                                                                    label="Payee"
                                                                    required
                                                                    placeholder="Choose a payee"
                                                                    isDisabled={readOnly}
                                                                    autocomplete
                                                                    onSelectHandlers={['onSelectPayee']}
                                                                    actionHandlers={{
                                                                        onSelectPayee: this.handleOnPayeeSelect,
                                                                    }}
                                                                    validate={[required]}
                                                                    dataSourceApi={
                                                                        `${API.SEARCH.RES_PARTNER_BASE
                                                                        }&isSupplier=true&isEmployee=true&searchString=`
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item />
                                                    <Grid item>
                                                        <Grid container spacing={10}>
                                                            <Grid item sm={12} lg={12} md={12}>
                                                                <Field
                                                                    testId="assetAccount"
                                                                    name="assetAccount"
                                                                    component={ReduxFormReactSelectMaterial}
                                                                    dataSourceConfig={{
                                                                        text: 'value',
                                                                        value: 'key',
                                                                    }}
                                                                    label="Bank/Credit Account"
                                                                    placeholder="Choose account"
                                                                    required
                                                                    validate={[required]}
                                                                    isDisabled={readOnly}
                                                                    key={subCompany}
                                                                    dataSourceApi={
                                                                        // eslint-disable-next-line max-len
                                                                        `${API.SEARCH.ACCOUNTS}?type=${getAllowedDefaultAccountTypes('expenseCreditAccountTypes', this.props.allowedAccountTypes)}&typeIgnoreList=consolidation,view&accountType=&subCompany=${subCompany || ''}&searchString=`
                                                                    }
                                                                    autocomplete
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item />
                                                    <Grid item>
                                                        <Grid container spacing={8}>
                                                            <Grid item sm={6} lg={6} md={6}>
                                                                <Field
                                                                    testId="paymentDate"
                                                                    name="paymentDate"
                                                                    component={OutlinedTextField}
                                                                    label="Payment Date"
                                                                    required
                                                                    type="date"
                                                                    placeholder="Payment Date"
                                                                    disabled={readOnly}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    validate={[required]}
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} lg={6} md={6}>
                                                                <Field
                                                                    testId="paymentmethod"
                                                                    name="paymentMethod"
                                                                    component={ReduxFormSelectField}
                                                                    label="Payment Method"
                                                                    validate={[required]}
                                                                    placeholder="Payment Method"
                                                                    required
                                                                    disabled={readOnly}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    menuItems={PAYMENT_METHODS.map(method => (
                                                                        <MenuItem key={method.value} value={method.value} test-id={method.label} >
                                                                            {method.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item />
                                                    {
                                                        (paymentMethod === 'CHEQUE') &&
                                                        <Grid item>
                                                            <Grid container spacing={8}>
                                                                <Grid item sm={6} lg={6} md={6}>
                                                                    <Field
                                                                        testId="paymentDetails.refNUmber"
                                                                        name="paymentDetails.refNumber"
                                                                        component={OutlinedTextField}
                                                                        label="Cheque Number"
                                                                        required
                                                                        type="number"
                                                                        placeholder="Cheque Number"
                                                                        disabled={readOnly}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        validate={[required, minValue(1)]}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={6} lg={6} md={6}>
                                                                    <Field
                                                                        testId="paymentDetails.date"
                                                                        name="paymentDetails.date"
                                                                        component={OutlinedTextField}
                                                                        label="Cheque Date"
                                                                        required
                                                                        type="date"
                                                                        placeholder="Cheque Date"
                                                                        disabled={readOnly}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        validate={[required]}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        <Grid item />
                                                    }
                                                    {
                                                        (paymentMethod === 'CHEQUE') &&
                                                        <Grid item>
                                                            <Grid container spacing={10}>
                                                                <Grid item sm={12} lg={12} md={12}>
                                                                    <Field
                                                                        testId="paymentDetails.bankName"
                                                                        name="paymentDetails.bankName"
                                                                        component={OutlinedTextField}
                                                                        label="Bank Name"
                                                                        required
                                                                        type="text"
                                                                        placeholder="Bank Name"
                                                                        disabled={readOnly}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        validate={[required]}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        (paymentMethod === 'CHEQUE') &&
                                                        <Grid item />
                                                    }
                                                    {
                                                        <Grid item>
                                                            <Grid container spacing={10}>
                                                                <Grid item sm={12} lg={12} md={12}>
                                                                    <Field
                                                                        testId="referenceNumber"
                                                                        name="referenceNumber"
                                                                        component={OutlinedTextField}
                                                                        label="Reference No"
                                                                        placeholder="Reference No"
                                                                        disabled={readOnly}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={5}>
                                                <Grid container alignContent="flex-end" direction="column">
                                                    <Card className={classes.root}>
                                                        <CardContent>
                                                            <Grid item />
                                                            <Grid container className="mt-1" justify="flex-start" spacing={10}>
                                                                <Grid item sm={12} lg={12} md={12}>
                                                                    <Field
                                                                        testId="haveEmployeeShare"
                                                                        name="haveEmployeeShare"
                                                                        component={ReduxFormCheckbox}
                                                                        disabled={readOnly}
                                                                        label="Does this expense have employee share (deducted from salary)?"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                getStringFromObject('haveEmployeeShare', formValues) &&
                                                                <Grid container className="mt-1" justify="flex-start" spacing="2">
                                                                    <Grid item lg={12} md={12} sm={12}>
                                                                        <Field
                                                                            testId="advanceEmployeeName"
                                                                            name="advanceEmployeeName"
                                                                            component={ReduxFormReactSelectMaterial}
                                                                            dataSourceConfig={{
                                                                                text: 'value',
                                                                                value: 'key',
                                                                            }}
                                                                            label="Employee Name"
                                                                            placeholder="Choose an employee"
                                                                            isDisabled={readOnly}
                                                                            autocomplete
                                                                            validate={required}
                                                                            required
                                                                            dataSourceApi={`${API.SALARYADVANCE.GET_EMPLOYEE}?size=20&name=`}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            <Grid container className="mt-1" justify="flex-start" spacing={10}>
                                                                {
                                                                    getStringFromObject('haveEmployeeShare', formValues) &&
                                                                    <Grid item>
                                                                        <Grid container spacing={8}>
                                                                            <Grid item sm={6} lg={6} md={6}>
                                                                                <Field
                                                                                    testId="advanceEmployeeShare"
                                                                                    name="advanceEmployeeShare"
                                                                                    component={OutlinedTextField}
                                                                                    label="Employee Share"
                                                                                    placeholder=""
                                                                                    disabled={readOnly}
                                                                                    validate={required}
                                                                                    required
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item sm={6} lg={6} md={6}>
                                                                                <Field
                                                                                    testId="advanceEmployeeRepayInstallment"
                                                                                    name="advanceEmployeeRepayInstallment"
                                                                                    component={OutlinedTextField}
                                                                                    label="No of Repayment Installments"
                                                                                    placeholder=""
                                                                                    validate={required}
                                                                                    required
                                                                                    disabled={readOnly}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                            <Grid container className="mt-1" justify="flex-end" spacing={10}>
                                                                <Grid item>
                                                                    <div style={{ float: 'right' }}>
                                                                        Amount
                                                                    </div>
                                                                    <div style={{ fontSize: '2em' }}>
                                                                        {currency}&nbsp;{roundedValueFixedToTwoDigits(total)}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            {
                                                uuid && !isPeriodClosed && readOnly
                                                && checkIfPrivilegeExistsForUser(expenseEditPrivilege) &&
                                                <Grid item lg={2} md={2} sm={5}>
                                                    <Grid container justify="flex-end">
                                                        <Grid item>
                                                            <div style={{ float: 'right' }}>
                                                                <ActionButton
                                                                    test-id="expense-confirm"
                                                                    disableRipple
                                                                    onClick={this.handleEdit}
                                                                    disabled={isPeriodClosed}
                                                                >
                                                                    Edit
                                                                </ActionButton>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                <Grid container style={{ fontSize: '1.2rem' }}>Category Details</Grid>
                                <Grid container className="mt-1">
                                    {
                                        isObjectValidAndNotEmpty(schema) &&
                                        <ReduxFormMaterialTable
                                            enablePagination={false}
                                            rowsPerPage={100}
                                            isEditable={!readOnly}
                                            showRowNumber
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            {...schema}
                                            fieldName="expenseLines"
                                            key={subCompany}
                                            actionHandlers={{
                                                addNewLine: this.handleAddNewLine,
                                                onAccountSelect: this.onAccountSelect,
                                                amountChangeHandler: this.amountChangeHandler,
                                                handleTaxSelect: this.handleTaxSelect,
                                            }}
                                            paramMap={{ subCompany }}
                                        />
                                    }
                                </Grid>
                                <Grid container className="mt-2" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            testId="memo"
                                            name="memo"
                                            component={OutlinedTextField}
                                            label="memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={8}>
                                        <Grid container justify="flex-end">
                                            <Grid item lg={3} md={3} sm={4} alignItems="flex-end">
                                                <Grid container direction="column" spacing={8} className="mt-0-5">
                                                    <Grid item>
                                                        <Grid container spacing={8}>
                                                            <Grid item sm={6} lg={6} md={6}>
                                                                <Field
                                                                    test-id="expense-discount-perc"
                                                                    label="Discount (%)"
                                                                    name="discountPer"
                                                                    component={ReduxFormTextField}
                                                                    type="number"
                                                                    fullWidth
                                                                    min={0}
                                                                    max={100}
                                                                    validate={[this.maxDiscount(0, 100)]}
                                                                    disabled={readOnly}
                                                                    onChangeHandlers={['handleChangeDiscountPerc']}
                                                                    actionHandlers={{
                                                                        handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} lg={6} md={6}>
                                                                <Field
                                                                    test-id="expense-discount-amount"
                                                                    label="Discount Amount"
                                                                    name="discountAmount"
                                                                    component={ReduxFormTextField}
                                                                    validate={[this.maxDiscount(0, subTotal)]}
                                                                    min={0}
                                                                    max={subTotal}
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled={readOnly}
                                                                    onChangeHandlers={['handleChangeDiscountAmount']}
                                                                    actionHandlers={{
                                                                        handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={2} md={3} sm={4}>
                                                <Grid container alignContent="flex-end" direction="column">
                                                    <div className={classes.totalField}>
                                                        Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(subTotal)}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(discountAmount)}
                                                    </div>
                                                    <hr />
                                                    <div className={classNames('mt-1', classes.totalField)}>
                                                        Net Amount:&nbsp;&nbsp; {valueToFixedTwoDigits(total)}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid container className="mt-2">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <div style={{ color: '#0000008a' }}>Attachments</div>
                                        <div className={classes.fileUpload}>
                                            <Field
                                                testId="attachment"
                                                name="attachments"
                                                disabled={readOnly}
                                                component={UploadFile}
                                            />
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="cancel-expense"
                                disableRipple
                                primary={false}
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            {/* {
                                !readOnly &&
                                <ActionButton
                                    test-id="reset-expense"
                                    disableRipple
                                    onClick={reset}
                                    disabled={pristine}
                                >
                                    Reset
                                </ActionButton>
                            } */}
                            {
                                !readOnly &&
                                <ActionButton
                                    testId="expense-confirm"
                                    disableRipple
                                    onClick={handleSubmit(this.handleSave)}
                                    // disabled={readOnly || pristine}
                                >
                                    Confirm
                                </ActionButton>
                            }
                            {
                                readOnly &&
                                <ActionButton
                                    test-id="expense-print"
                                    primary
                                    onClick={this.printHandler}
                                    disableRipple
                                >
                                    Print
                                </ActionButton>
                            }
                            {
                                readOnly &&
                                <ActionButton
                                    test-id="expense-journal-print"
                                    primary
                                    onClick={this.fetchJournalEntriesAndPrint}
                                    disableRipple
                                >
                                    Print Journal Entries
                                </ActionButton>
                            }
                        </DialogActions>
                    </Dialog>
                    <Print
                        data={printData}
                        print={this.state.print}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/supplierExpense.html`}
                        subCompany={subCompany}
                    />
                    <Print
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                        data={journalEntryPrintData}
                        print={printJournal}
                        subCompany={subCompany}
                    />
                </form>
                {
                    createNewSupplier &&
                    <CreateNewSupplierDialog
                        handleClose={this.handleCloseCreateNewSupplier}
                        open={createNewSupplier}
                        tempName={tempName}
                    />
                }
            </React.Fragment>
        );
    }
}

ExpenseDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    // pristine: PropTypes.bool.isRequired,
    isSearch: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    expenseLines: PropTypes.array,
    currency: PropTypes.string,
    company: PropTypes.string,
    selectedExpense: PropTypes.object,
    selectedExpenseUuid: PropTypes.string,
    selectedExpenseId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    type: PropTypes.string,
    payee: PropTypes.object,
    change: PropTypes.func,
    paymentMethod: PropTypes.string,
    formValues: PropTypes.object,
    allowedAccountTypes: PropTypes.object,
};

ExpenseDialog.defaultProps = {
    expenseLines: [],
    isSearch: false,
    selectedExpense: {},
    selectedExpenseUuid: '',
    selectedExpenseId: '',
    currency: '',
    company: '',
    payee: {},
    change: () => {},
    type: '',
    paymentMethod: '',
    formValues: {},
    allowedAccountTypes: {},
};

const mapStateToProps = (state) => {
    console.log('jksadfherawfdfs', state);
    return {
        selectedExpense: getStringFromObject('accountExpense.selectedExpense', state, {}),
        expenseLines: formValueSelector(formName)(state, 'expenseLines'),
        paymentMethod: formValueSelector(formName)(state, 'paymentMethod'),
        payee: formValueSelector(formName)(state, 'payee'),
        currency: getStringFromObject('appConfiguration.currency', state, ''),
        company: getStringFromObject('appConfiguration.companyName', state, ''),
        formValues: getStringFromObject(`${formName}.values`, state.form, {}),
        allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: formName,
})(withStyles(style)(ExpenseDialog)));
