import axios from 'axios';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isValidFunction,
    isValidTextAndNotEmpty,

} from '../../../constants/CommonUtil';
import { saveParentPayor } from '../../../redux/modules/payors/payers/payors-actions';
import { IMPLEMENTATION_LOCATIONS, payorSubInsurance } from '../../../constants/constants';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import api from '../../../constants/api';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import { savePriceList } from '../../../redux/modules/priceList/priceList-actions';
import { generateObjectWithCriteriaMode, getCombined } from '../../../sagas/util';
import { insurancePayerTypes } from '../AddNewPayer/AddNewPayerUtil';
import {
    payerTypes,
} from '../../../containers/RegistrationAppComponents/QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';
import { saveFormularyList } from '../../../redux/modules/formularyList/formularyList-actions';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';

export const mapToSampleCSV = (csvData, payerType) => {
    let mappedCsvData = [];
    // mappedCsvData =
    //     mappedCsvData.concat(['name', 'covered', 'active', 'discount', 'price', 'scode', 'pcode', 'approval']);
    if (isArrayValidAndNotEmpty(csvData)) {
        mappedCsvData = mappedCsvData.concat(csvData.map((aRow) => {
            let rowData = [
                aRow.insuranceProductCategory,
                aRow.productCategoryName,
                aRow.productName,
                'TRUE',
                'TRUE',
                '0',
                aRow.stdAmount,
                aRow.pCode,
            ];
            const isInsurance = [
                insurancePayerTypes.INSURANCE.value,
                insurancePayerTypes.SUBINSURANCE.value,
                insurancePayerTypes.POLICY.value,
            ].includes(payerType);
            if (isInsurance) {
                rowData = rowData.concat([
                    '',
                    'FALSE',
                    '',
                ]);
            }
            if (payerType === payerTypes.CORPORATE) {
                rowData = rowData.concat([
                    '',
                    '',
                ]);
            }
            return rowData;
        }));
    }
    console.log('asd-a0sdkpada', mappedCsvData);
    return mappedCsvData;
};

export const mapToSampleFormularyCSV = (csvData, payerType) => {
    let mappedCsvData = [];
    // mappedCsvData =
    //     mappedCsvData.concat(['name', 'covered', 'active', 'discount', 'price', 'scode', 'pcode', 'approval']);
    console.log(payerType);
    if (isArrayValidAndNotEmpty(csvData)) {
        mappedCsvData = mappedCsvData.concat(csvData.map((aRow) => {
            const rowData = [
                aRow.pCode,
                aRow.name,
                'TRUE',
                '0',
                aRow.rate,
            ];
            return rowData;
        }));
    }
    console.log('asd-a0sdkpada', mappedCsvData);
    return mappedCsvData;
};

export const deactivatePayer = (payerUuid, fetchCallBack, dispatch) => {
    dispatch(showSpinner());
    axios.get(`${api.PAYORS.ACTIVATATE_DEACTIVATE_PAYER}?payerUuid=${payerUuid}`)
        .then(() => {
            dispatch(successMessage('Status changed successfully'));
            if (isValidFunction(fetchCallBack)) {
                fetchCallBack();
            }
        })
        .catch((error) => {
            apiCatchBlockFunction(error, dispatch);
        })
        .finally(() => {
            dispatch(hideSpinner());
        });
};

export const downloadFile = async (apiWithParam, dispatch) => {
    try {
        dispatch(showSpinner());
        const response =
            await axios.get(apiWithParam);
        dispatch(hideSpinner());
        const failureReason = getStringFromObject('failureReason', response.data);
        if (failureReason) {
            dispatch(errorMessage(failureReason));
        } else {
            const token = getStringFromObject('downloadToken', response.data);
            window.open(`${api.FILE.DOWNLOAD_WITH_TOKEN}${token}`, '_blank');
        }
    } catch (e) {
        apiCatchBlockFunction(e, dispatch);
    }
};

export const downloadPriceList = (payerUuid, dispatch, payerType) => {
    console.log('asdasd2321asda', payerUuid, dispatch, payerType);
    const apiWithParam = `${api.PAYORS.DOWNLOAD_PRICELIST_FOR_PAYER}${payerUuid}&payerType=${payerType}`;
    downloadFile(apiWithParam, dispatch);
};

export const downloadFormularyList = (payerUuid, dispatch) => {
    const apiWithParam = `${api.PAYORS.DOWNLOAD_FORMULARY_LIST_FOR_PAYER}${payerUuid}`;
    downloadFile(apiWithParam, dispatch);
};

export const handlePayorAction = (
    action,
    index,
    props,
    fetchPayers,
    _this,
    noHandleEdit,
    listFieldName = 'list',
    rowValue,
    payerState, // the redux store value for the payer
) => {
    const list = getStringFromObject(listFieldName, payerState, []);
    console.log('asd-a0jdopanfkla d', rowValue, list, index);
    const { history, dispatch } = props;
    if (action === 'edit' && !noHandleEdit) {
        _this.setState({
            openPayor: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            selectedRow: rowValue,
        });
    } else if (action === 'network') {
        dispatch(saveParentPayor(rowValue));
        history.push(`${payorSubInsurance}/${rowValue.payerUuid}`);
    } else if (action === 'viewPriceList') {
        _this.setState({
            openPriceList: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
            priceListState: 'publish',
        });
    } else if (action === 'viewAllPriceLists') {
        _this.setState({
            openViewAllPriceLists: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
            priceListState: 'publish',
        });
    } else if (action === 'supplementProducts') {
        _this.setState({
            openSupplementalList: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            selectedRow: rowValue,
            index,
        });
    } else if (action === 'viewDraftPriceList') {
        _this.setState({
            openPriceList: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
            priceListState: 'draft',
        });
    } else if (action === 'uploadPriceList') {
        _this.setState({
            openCSV: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            selectedRow: rowValue,
            index,
        });
    } else if (action === 'retire') {
        deactivatePayer(rowValue.payerUuid, fetchPayers, dispatch);
    } else if (action === 'downloadPriceList') {
        downloadPriceList(rowValue.payerUuid, dispatch, rowValue.type);
    } else if (action === 'downloadFormularyList') {
        downloadFormularyList(rowValue.payerUuid, dispatch);
    } else if (action === 'uploadFormularyList') {
        _this.setState({
            openFormularyCSV: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            selectedRow: rowValue,
            index,
        });
    } else if (action === 'viewDraftFormularyList') {
        _this.setState({
            openFormularyList: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
            formularyListState: 'DRAFT',
        });
    } else if (action === 'viewFormularyList') {
        _this.setState({
            openFormularyList: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
            formularyListState: 'ACTIVE',
        });
    } else if (action === 'viewAllFormularyLists') {
        _this.setState({
            viewAllFormularyLists: true,
            payorUuid: rowValue.payerUuid,
            payerName: rowValue.name,
            index,
            selectedRow: rowValue,
        });
    }
};

export const savePriceListCallBack = (values, action, applyFrom, dispatch, payorUuid, callback) => {
    const payload = values.map((val) => {
        const nphiesCode = getStringFromObject('nphiesCode.key', val) || getStringFromObject('nphiesCode', val);
        return {
            ...val,
            nphiesCode: isValidTextAndNotEmpty(nphiesCode) ? nphiesCode : null,
        };
    });
    console.log('asda09id0a9d0a9sda', payload);
    dispatch(savePriceList(payload, payorUuid, action, applyFrom, callback));
};
export const saveFormularyListCallBack = (values, action, applyFrom, dispatch, payorUuid, callback) => {
    console.log('asd909u09aspd;kosad', values);
    console.log('asd909u09aspd;kosac', action);
    dispatch(saveFormularyList(values, payorUuid, action, applyFrom, callback));
};
export const PAYER_TYPES = [
    { key: 'COMPANY', value: 'Company' },
    { key: 'INSURANCE', value: 'Insurance' },
    { key: 'ASSOCIATION', value: 'Association' },
    { key: 'CORPORATE', value: 'Corporate' },
];

export const filterSchema = [
    {
        name: 'type',
        label: 'Type',
        type: 'select',
        defaultValue: '',
        options: PAYER_TYPES,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const POLICY_FILTER_SCHEMA = parentUuid => [
    {
        name: 'subInsurer',
        label: 'Sub Insurer',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi:
            `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?parentUuid=${parentUuid}&payerType=SUBINSURANCE&searchString=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const policyListSearchConstruct = (filters) => {
    let construct = null;
    const parentPayerId = getStringFromObject('parentPayerId', filters);
    const tpa = getStringFromObject('tpa.key', filters);
    if (parentPayerId) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Policy.tpaId', parentPayerId, '='),
        );
    } else if (tpa) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Policy.tpaUuid', tpa, '='),
        );
    }
    const subInsurerUuid = getStringFromObject('subInsurer.key', filters);
    if (subInsurerUuid) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Policy.subInsurerUuid', subInsurerUuid, '='),
        );
    }
    let text = getStringFromObject('searchText', filters);
    if (text) {
        text = text.trim();
        let operation = ':';
        if (text.endsWith('@')) {
            text = text.substr(0, text.length - 1);
            operation = '=';
        }
        construct = getCombined(
            construct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode('Policy.name', text, operation),
                'OR',
                generateObjectWithCriteriaMode('Policy.code', text, operation),
            ),
        );
    }
    return construct;
};

export const SCHEME_FILTER_SCHEMA = tpaUuid => [
    {
        name: 'subInsurer',
        label: 'Sub Insurer',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi:
            `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?parentUuid=${tpaUuid}&payerType=SUBINSURANCE&searchString=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'policy',
        label: 'Policy',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi:
        // eslint-disable-next-line
            `${api.SEARCH.SEARCH_POLICIES_UNDER_TPA_AND_SUB}?tpaUuid=${tpaUuid}&subInsurerUuid=<<subInsurer.key>>&searchString=`,
        formatUrl: true,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const schemeListSearchConstruct = (filters) => {
    let construct = null;
    const parentPayerId = getStringFromObject('parentPayerId', filters);
    const tpaUuid = getStringFromObject('tpa.key', filters);
    if (parentPayerId) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Scheme.tpaId', parentPayerId, '='),
        );
    } else if (tpaUuid) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Scheme.tpaUuid', tpaUuid, '='),
        );
    }
    const policyUuid = getStringFromObject('policy.key', filters);
    if (policyUuid) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Scheme.policyUuid', policyUuid, '='),
        );
    }
    const subInsurerUuid = getStringFromObject('subInsurer.key', filters);
    if (subInsurerUuid) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('Scheme.subInsurerUuid', subInsurerUuid, '='),
        );
    }
    let text = getStringFromObject('searchText', filters);
    if (text) {
        text = text.trim();
        let operation = ':';
        if (text.endsWith('@')) {
            text = text.substr(0, text.length - 1);
            operation = '=';
        }
        construct = getCombined(
            construct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode('Scheme.name', text, operation),
                'OR',
                generateObjectWithCriteriaMode('Scheme.number', text, operation),
            ),
        );
    }
    return construct;
};

export const csvHeaders = (payerType) => {
    let headers = [
        'Payer Product Category',
        'Product Category',
        'Name',
        'Covered',
        'Active',
        'Discount %',
        'Price',
        'Product Code',
    ];
    const isInsurance = [
        insurancePayerTypes.INSURANCE.value,
        insurancePayerTypes.SUBINSURANCE.value,
        insurancePayerTypes.POLICY.value,
    ].includes(payerType);
    console.log('asd0auda0s9dadas', payerType);
    if (isInsurance) {
        headers = headers.concat([
            'Payer Product Code',
            'Approval',
            'Description',
        ]);
    }
    if (payerType === payerTypes.CORPORATE) {
        headers = headers.concat([
            'CoPay %',
            'Max Usages',
        ]);
    }
    return headers;
};

export const formularyCsvHeaders = (payerType = '') => {
    const headers = [
        'Product Code',
        'Name',
        'Active',
        'Discount Percentage',
        'Rate',
        'SFDA Code',
    ];
    console.log('formularyCsvHeaders', payerType);
    return headers;
};

export const getModifiedSchemaAfterRemovingActions = (schema, conditions, sliceLength = 9) => {
    const newSchema = { ...schema };
    const implementationLocation = getStringFromObject('implementationLocation', conditions);
    if (implementationLocation === IMPLEMENTATION_LOCATIONS.BAHRAIN) {
        const tableCells = getStringFromObject('tableCells', schema) || [];
        let currentActionsChoices = getStringFromObject(`tableCells[${tableCells.length - 1}].choices`, schema);
        currentActionsChoices = currentActionsChoices.slice(0, sliceLength);
        setStringPropertyToObject(`tableCells[${tableCells.length - 1}].choices`, newSchema, currentActionsChoices);
        return newSchema;
    }
    return newSchema;
};

export const payerListSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'payorListTable',
    showInNonEditableMode: true,
    hideCheckBoxIcon: true,
    showInReadOnlyMode: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#000000',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'emptyDiv',
            name: 'emptyDiv',
            find: 'emptyDiv',
            label: '',
            fieldType: 'text',
        },
        {
            id: 'name',
            name: 'name',
            find: 'name',
            label: 'Name',
            fieldType: 'text',
            sortCol: 'name',
            displaySortIcon: true,
        },
        {
            id: 'code',
            name: 'code',
            find: 'code',
            label: 'Code',
            fieldType: 'text',
            sortCol: 'code',
            displaySortIcon: true,
        },
        {
            id: 'type',
            name: 'type',
            find: 'type',
            label: 'Type',
            fieldType: 'text',
            sortCol: 'type',
            displaySortIcon: true,
        },
        {
            id: 'action',
            name: 'rowAction',
            label: 'Action',
            fieldType: 'menuActionButton',
            choices: [
                {
                    key: 'network',
                    value: 'Network',
                    hideIf: [
                        {
                            key: 'type',
                            value: 'ASSOCIATION',
                        },
                        {
                            key: 'type',
                            value: 'COMPANY',
                        },
                        {
                            key: 'type',
                            value: 'CORPORATE',
                        },
                    ],
                },
                {
                    key: 'edit',
                    value: 'View/Edit',
                },
                {
                    key: 'uploadPriceList',
                    value: 'Upload Price List',
                },
                {
                    key: 'viewDraftPriceList',
                    value: 'View Draft Price List',
                },
                {
                    key: 'viewPriceList',
                    value: 'View Current Price List',
                },
                {
                    key: 'viewAllPriceLists',
                    value: 'View All Price Lists',
                },
                {
                    key: 'supplementProducts',
                    value: 'Supplemental Products',
                    hideIf: [
                        {
                            key: 'type',
                            value: 'ASSOCIATION',
                        },
                        {
                            key: 'type',
                            value: 'COMPANY',
                        },
                        {
                            key: 'type',
                            value: 'CORPORATE',
                        },
                    ],
                },
                {
                    key: 'downloadPriceList',
                    value: 'Download Price List',
                    hideIf: [
                        {
                            key: 'type',
                            value: 'ASSOCIATION',
                        },
                        {
                            key: 'type',
                            value: 'COMPANY',
                        },
                    ],
                },
                {
                    key: 'retire',
                    value: 'Make Inactive/Active',
                },
                {
                    key: 'uploadFormularyList',
                    value: 'Upload Formulary List',
                },
                {
                    key: 'viewDraftFormularyList',
                    value: 'View Draft Formulary List',
                },
                {
                    key: 'viewFormularyList',
                    value: 'View Current Formulary List',
                },
                {
                    key: 'downloadFormularyList',
                    value: 'Download Formulary List',
                },
                {
                    key: 'viewAllFormularyLists',
                    value: 'View All Formulary Lists',
                },
            ],
            dataSourceConfig: {
                text: 'value',
                value: 'key',
            },
            headerStyle: {
                fontSize: '14px',
                width: '10%',
            },
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};

