import { takeLatest } from 'redux-saga/effects';
import { FETCH_ROOM_DEPARTMENT_MAPPING_REQUEST, SAVE_ROOM_DEPARTMENT_MAPPING_REQUEST } from '../../redux/modules/roomDepartmentMapping/roomDepartmentMapping-actions';
import { saveRoomDepartmentMappingWorker, fetchRoomDepartmentMappingWorker } from './roomDepartmentMappingWorkerSaga';

export function* saveRoomDepartmentMappingWatcher() {
    yield takeLatest(SAVE_ROOM_DEPARTMENT_MAPPING_REQUEST, saveRoomDepartmentMappingWorker);
}

export function* fetchRoomDepartmentMappingWatcher() {
    yield takeLatest(FETCH_ROOM_DEPARTMENT_MAPPING_REQUEST, fetchRoomDepartmentMappingWorker);
}
