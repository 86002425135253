import axios from 'axios';
import { apiCatchBlockFunction } from '../../../constants/CommonUtil';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import API from '../../../constants/api';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

// eslint-disable-next-line import/prefer-default-export
export const fetchHrEmployeeDetails = async (employee, dispatcher) => {
    if (isObjectValidAndNotEmpty(employee) && employee.key) {
        try {
            dispatcher(showSpinner());
            const response = await axios.get(`${API.EMPLOYEE.GET_DETAILS}?employeeUuid=${employee.key}`);
            dispatcher(hideSpinner());
            return response.data;
        } catch (e) {
            apiCatchBlockFunction(e, dispatcher);
            return null;
        }
    }
    return null;
};
