import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pdf2base64 from 'pdf-to-base64';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';

class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            base64Pdf: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.loadPdfData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.src !== this.props.src) {
            this.loadPdfData(nextProps);
        }
    }

    loadPdfData = async (props = this.props) => {
        const {
            dispatch,
            src,
        } = props;
        try {
            this.setState({
                loading: true,
            });
            const response = await pdf2base64(src);
            console.log('as0dua09dasdsad', response);
            this.setState({
                base64Pdf: response,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        } finally {
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const {
            base64Pdf,
            loading,
        } = this.state;
        if (loading) {
            return (
                <CircularProgress />
            );
        }
        if (base64Pdf && !loading) {
            return (
                <object data={`data:application/pdf;base64,${base64Pdf}`} type="application/pdf" width="100%" height="100%">
                    AD
                </object>
            );
        }
        return null;
    }
}

PDFViewer.propTypes = {
    dispatch: PropTypes.func,
    src: PropTypes.string.isRequired,
};

PDFViewer.defaultProps = {
    dispatch: () => {},
};

export default connect()(PDFViewer);
