/**
 * Created by sachinprakash on 8/7/19.
 */
import React from 'react';
import { Card, CardContent, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionButton from '../../ActionButton/ActionButton';
import { required } from '../../../constants/FormValidations';
import {
    isValidFunction,
} from '../../../constants/CommonUtil';
import FormikTextField from './FormikTextField';
import FormikReactSelectMaterial from './FormikReactSelectMaterial';
import FormikCheckbox from './FormikCheckbox';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
// import API from "../../../constants/api";

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'visible',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    disableText: {
        color: '#000000',
    },
});

const accrualType = [
    {
        key: 'MONTHLY',
        value: 'Monthly',
    },
    {
        key: 'QUARTERLY',
        value: 'Quarterly',
    },
    {
        key: 'YEARLY',
        value: 'Yearly',
    },
    {
        key: 'BIANNUAL',
        value: 'BI-Annual',
    },

];

class FormikLeavePlanComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChange = (event) => {
        const {
            form,
            field: { name },
        } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (isValidFunction(setFieldValue)) {
            setFieldValue(name, event.target.value);
        }
    };

    render() {
        const {
            classes,
            form: {
                values: {
                    leavePlanValues = [{}],
                },
            },
            push,
            name,
            remove,
        } = this.props;
        console.log('rupam mapping prop', this.props);
        return (
            <Card style={{ display: 'block', overflow: 'visible' }}>
                <CardContent>
                    <Grid container spacing={16} justify="flex-start">
                        <Grid item sm={12} md={12} lg={12}>
                            {
                                isObjectValidAndNotEmpty(leavePlanValues) && leavePlanValues.map((a, index) => {
                                    console.log('kasjdljkasl', a);
                                    return (
                                        <Grid container key={a} justify="flex-start" className="mt-1">
                                            <Grid item sm={2} md={2} lg={2} style={{ marginRight: '20px' }}>
                                                <Field
                                                    className={classes.textField}
                                                    component={FormikReactSelectMaterial}
                                                    disableUnderline
                                                    variant="outlined"
                                                    fieldType="selectField"
                                                    dataSourceApi="/nuacare-core/rest/nuacare/v1/master/search/HrLeaveType?searchString="
                                                    fullWidth
                                                    required
                                                    validate={required}
                                                    name={`${name}.${index}.leaveType`}
                                                    label="Leave Type"
                                                    dataSourceConfig={{
                                                        text: 'value',
                                                        value: 'uuid',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sm={2} md={2} lg={2} style={{ marginRight: '20px' }}>
                                                <Field
                                                    className={classes.textField}
                                                    component={FormikReactSelectMaterial}
                                                    disableUnderline
                                                    options={accrualType}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    validate={required}
                                                    name={`${name}.${index}.accrualType`}
                                                    label="Accrual Type"
                                                    dataSourceConfig={{
                                                        text: 'value',
                                                        value: 'key',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sm={2} md={2} lg={2} style={{ marginRight: '20px' }}>
                                                <Field
                                                    className={classes.textField}
                                                    component={FormikTextField}
                                                    disableUnderline
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    validate={required}
                                                    name={`${name}.${index}.numberOfLeaves`}
                                                    label="Number Of Leaves"
                                                />
                                            </Grid>
                                            <Grid item sm={2} md={2} lg={2} style={{ marginRight: '20px' }}>
                                                <Field
                                                    className={classes.textField}
                                                    component={FormikTextField}
                                                    disableUnderline
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`${name}.${index}.numberOfLeavesCarryForwardable`}
                                                    label="Number Of Leaves Carry Forwardable"
                                                />
                                            </Grid>
                                            <Grid item sm={2} md={2} lg={2} style={{ marginRight: '20px' }}>
                                                <Field
                                                    className={classes.textField}
                                                    component={FormikCheckbox}
                                                    disableUnderline
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`${name}.${index}.canBeCarryForwarded`}
                                                    label="Can Be Carry Forwarded"
                                                />
                                            </Grid>

                                            <Grid item lg={0.5} sm={0.5} md={0.5} style={{ marginRight: '0px' }}>
                                                <Close onClick={() => remove(index)} />
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" >
                        <Grid item sm={4} md={4} lg={4} />
                        <Grid item sm={2} md={2} lg={2}>
                            <ActionButton className="mt-1" onClick={() => push({})} >
                                Add
                            </ActionButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

FormikLeavePlanComponent.propTypes = {
    classes: PropTypes.object,
    /* formik props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
};

FormikLeavePlanComponent.defaultProps = {
    classes: {},
};

export default connect()(withStyles(styles)(FormikLeavePlanComponent));
