import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    AVAILED_LEAVES_FETCH_FAILED,
    AVAILED_LEAVES_FETCH_SUCCESS, LEAVE_BALANCE_FETCH_FAILED,
    LEAVE_BALANCE_FETCH_SUCCESS, LEAVE_PLANS_FOR_EMPLOYEE_FETCH_FAILED, LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS,
} from '../../redux/modules/leaves/leave-actions';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* availedLeavesWorkerSaga(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const { api, year, uuid } = action;
        const apiWithQueryParam = `${api}?year=${year}&uuid=${uuid}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: AVAILED_LEAVES_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: AVAILED_LEAVES_FETCH_FAILED, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* leaveBalanceWorkerSaga(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const { api, uuid } = action;
        const apiWithQueryParam = `${api}?uuid=${uuid || ''}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: LEAVE_BALANCE_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: LEAVE_BALANCE_FETCH_FAILED, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchLeavePlansForEmployeeWorkerSaga(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const { api, uuid } = action;
        const apiWithQueryParam = `${api}?uuid=${uuid}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: LEAVE_PLANS_FOR_EMPLOYEE_FETCH_FAILED, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
