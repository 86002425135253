import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import {
    productDispatch,
    purchaseSuppliers,
    supplierInvoices,
    purchasePayments,
    purchaseOrders,
    STOCK_PURCHASE_PAYMENT_INVOICES_PRIVILEGE,
    STOCK_PURCHASE_SUPPLIERS_PRIVILEGE,
    STOCK_PURCHASE_PAYMENTS_PRIVILEGE,
    STOCK_PURCHASE_ORDERS_PRIVILEGE,
    STOCK_PRODUCT_DISPATCH_PRIVILEGE,
    GLOBAL_PROPERTIES,
} from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { filterTabsBasedOnConditions } from './PurchaseRoutesUtils';
import { getStringFromObject } from '../../constants/lodashUtils';

const style = (theme) => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

export const payerMangementTabs = {
    SUPPLIERS: {
        label: 'Suppliers',
        value: purchaseSuppliers,
        privilege: [STOCK_PURCHASE_SUPPLIERS_PRIVILEGE],
    },
    SUPPLIER_INVOICE: {
        value: 'supplierInvoice',
        label: 'Supplier Invoices',
        privilege: [STOCK_PURCHASE_PAYMENT_INVOICES_PRIVILEGE],
    },
    PURCHASE_ORDERS: {
        value: 'purchaseOrders',
        label: 'Purchase Orders',
        privilege: [STOCK_PURCHASE_ORDERS_PRIVILEGE],
    },
    SUPPLIER_PAYMENTS: {
        value: 'supplierPayments',
        label: 'Supplier Payments',
        privilege: [STOCK_PURCHASE_PAYMENTS_PRIVILEGE],
    },
    PRODUCT_DISPATCH: {
        value: 'productDispatch',
        label: 'Accepted Packages',
        privilege: [STOCK_PRODUCT_DISPATCH_PRIVILEGE],
    },
};

class PurchaseRoutesContainer extends React.Component {
    constructor(props) {
        super(props);
        const selectedTab = this.changeTabForRoute(props);
        this.state = {
            selectedTab,
        };
    }

    componentDidMount() {
        this.changeRouteForSelectedTab(this.state.selectedTab);
    }

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        switch (route) {
        case supplierInvoices: return payerMangementTabs.SUPPLIER_INVOICE.value;
        case purchaseSuppliers: return payerMangementTabs.SUPPLIERS.value;
        case purchasePayments: return payerMangementTabs.SUPPLIER_PAYMENTS.value;
        case purchaseOrders: return payerMangementTabs.PURCHASE_ORDERS.value;
        case productDispatch: return payerMangementTabs.PRODUCT_DISPATCH.value;
        default: return payerMangementTabs.SUPPLIERS.value;
        }
    };

    changeRouteForSelectedTab = (selected) => {
        let route = '';
        switch (selected) {
        case payerMangementTabs.SUPPLIER_INVOICE.value:
            route = supplierInvoices;
            break;
        case payerMangementTabs.SUPPLIERS.value:
            route = purchaseSuppliers;
            break;
        case payerMangementTabs.PURCHASE_ORDERS.value:
            route = purchaseOrders;
            break;
        case payerMangementTabs.SUPPLIER_PAYMENTS.value:
            route = purchasePayments;
            break;
        case payerMangementTabs.PRODUCT_DISPATCH.value:
            route = productDispatch;
            break;
        default:
        }
        if (route) {
            this.props.history.push(route);
        }
    };

    handleChange = (event, selectedTab) => {
        this.setState({
            selectedTab,
        }, () => this.changeRouteForSelectedTab(selectedTab));
    };

    render() {
        const { selectedTab } = this.state;
        const { classes, children, implementationLocation } = this.props;
        console.log('TestIsHere');
        const filteredTabs = filterTabsBasedOnConditions(Object.values(payerMangementTabs), { implementationLocation });
        return (
            <div style={{ background: '#fff', padding: '2rem' }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item lg={8} md={8} sm={8}>
                        <AppBar position="static" className={classes.appBar}>
                            <Tabs
                                value={selectedTab}
                                onChange={this.handleChange}
                                fullWidth
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                            >
                                {
                                    filteredTabs.map((aPayerManagementTab) => (
                                        checkIfPrivilegeExistsForUser(aPayerManagementTab.privilege)
                                            && (
                                                <Tab
                                                    key={aPayerManagementTab.value}
                                                    value={aPayerManagementTab.value}
                                                    label={aPayerManagementTab.label}
                                                    data-test-id={aPayerManagementTab.label}
                                                    classes={{
                                                        root: classes.tabRoot,
                                                        selected: classes.tabSelected,
                                                        label: classes.label,
                                                    }}
                                                    disableRipple
                                                />
                                            )
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
                {children}
            </div>
        );
    }
}

PurchaseRoutesContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    implementationLocation: PropTypes.string.isRequired,
};

PurchaseRoutesContainer.defaultProps = {};
const mapStateToProps = (state) => ({
    implementationLocation: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.IMPLEMENTATION_LOCATION.replace(/\./g, '_')}`, state),
});
export default connect(mapStateToProps)(withStyles(style)(PurchaseRoutesContainer));
