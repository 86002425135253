import { CLEAR_PATIENT, SELECT_PATIENT } from './selectedPatient-actions';

const selectedPatientReducer = (state = null, action) => {
    let newState = state;
    switch (action.type) {
    case SELECT_PATIENT:
        newState = action.patient;
        break;
    case CLEAR_PATIENT:
        newState = null;
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default selectedPatientReducer;
