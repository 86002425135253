import { checkIfPrivilegeExistsForUser } from '../../../../constants/privilegeChecker';
import consultationStatuses from '../../../QueueNodeDisplayContainer/QueueUtils';
import { secondaryIdentifierLabelReplaceString } from '../../../../constants/constants';

const doctorQueueSchema = (frontDeskSupPriv) => {
    const schema = {
        fieldType: 'materialTable',
        id: 'table',
        name: 'billableProductList',
        showInNonEditableMode: true,
        showInReadOnlyMode: true,
        hideCheckBoxIcon: true,
        processJson: true,
        rowstyle: {
            height: '56px',
        },
        headerRowStyle: {
            background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
            color: 'white',
        },
        headerColStyle: {
            color: '#FFFFFF',
            fontSize: '14px',
        },
        tableCells: [
            {
                id: 'tokenNumber',
                name: 'tokenNumber',
                find: 'tokenNumber',
                label: 'Token',
                fieldType: 'appointmentSlot',
            },
            {
                id: 'patient',
                name: 'patient',
                find: 'patient.value',
                label: 'Patient',
                fieldType: 'text',
            },
            {
                id: 'Identifier',
                name: 'Identifier',
                find: 'patientIdentifier',
                label: 'Identifier',
                fieldType: 'text',
            },
            {
                id: 'secondaryIdentifier',
                name: 'secondaryIdentifier',
                find: 'patientSecondaryIdentifier',
                label: secondaryIdentifierLabelReplaceString,
                fieldType: 'text',
            },
            {
                id: 'consultationStatus',
                name: 'consultationStatus',
                find: 'consultationStatus',
                label: 'Status',
                fieldType: 'text',
            },
            {
                id: 'type',
                name: 'episodeProviderType',
                find: 'episodeProviderType',
                label: 'Type',
                fieldType: 'text',
            },
        ],
        grid: {
            sm: 12,
            md: 12,
            lg: 12,
        },
    };
    if (checkIfPrivilegeExistsForUser([frontDeskSupPriv])) {
        schema.tableCells = schema.tableCells.concat({
            id: 'cancelToken',
            name: 'cancelToken',
            find: 'cancelToken',
            label: 'Cancel Token',
            testId: 'cancel-token',
            fieldType: 'actionButton',
            hideIf: [{
                fieldName: 'consultationStatus',
                value: [consultationStatuses.DONE, 'UNUSED', 'NOT_ALLOCATED'],
            }],
        });
    }
    return schema;
};

export default doctorQueueSchema;
