import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import {
    getTextFieldChangedValue,
    isValidFunction,
    roundedValueFixedToTwoDigits,
} from '../constants/CommonUtil';
import OutlinedTextField from './OutlinedTextField';
import { NumberOf } from '../constants/numberUtils';

class NumberTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
        };
    }
    onFocus = (e) => {
        this.setState({
            focus: true,
        });
        this.props.input.onFocus(e);
    };
    onBlur = (event) => {
        this.setState({
            focus: false,
        });
        console.log('sadfsdfs', this.props.input);
        let changedValue = this.props.input.value.toString();
        console.log('changed valueee max comparison', changedValue);
        if (event.target.type === 'number' && changedValue.replace) {
            changedValue = changedValue.replace(/^0+/, '');
        }
        // if changed value max, reset to max
        const { max, min } = this.props;
        if (max) {
            changedValue = Math.min(NumberOf(changedValue), max);
        }
        console.log('asdfkjskdjf', min, (min !== undefined && (Number(changedValue) < min)));
        if (changedValue !== '') {
            changedValue = Math.max(NumberOf(changedValue), min);
        }
        console.log('changed valueee', changedValue);
        this.props.input.onChange(Number(changedValue));
        const newEvent = cloneDeep(event);
        newEvent.target.value = changedValue;
        if (isValidFunction(this.props.input.onBlur)) {
            this.props.input.onBlur(newEvent);
        }
    };

    onChange = (value) => {
        console.log('asd-s0adi-asdi-a0as', value);
        this.props.input.onChange(getTextFieldChangedValue(value));
    };

    handleKeyDown = (event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
        }
    };

    render() {
        const {
            meta: {
                touched,
                error,
            } = {},
            roundValue,
            roundWhenBlurred,
        } = this.props;
        if (this.props.input.name === 'invoiceLines[0].discount') {
            console.log('aspfdjasas0-da-idas0', this.props.meta, this.state.focus);
        }
        const newInput = cloneDeep(this.props.input);
        const extraProps = {};
        if (roundWhenBlurred) {
            extraProps.onFocus = this.onFocus;
            if (!this.state.focus) {
                newInput.value = roundedValueFixedToTwoDigits(newInput.value);
            }
        }
        return (
            <OutlinedTextField
                type="number"
                value={roundValue ? roundedValueFixedToTwoDigits(this.props.input.value) : this.props.input.value}
                {...this.props}
                input={newInput}
                onChange={this.onChange}
                {...extraProps}
                onKeyDown={this.handleKeyDown}
                onBlur={this.onBlur}
                errorText={touched && error ? error : ''}
            />
        );
    }
}

NumberTextField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    roundValue: PropTypes.bool,
    roundWhenBlurred: PropTypes.bool,
    max: PropTypes.any,
    min: PropTypes.any,
};

NumberTextField.defaultProps = {
    input: {},
    roundValue: false,
    roundWhenBlurred: false,
    meta: {
        touched: false,
        error: '',
    },
    max: 999999999999,
    min: 0,
};

export default NumberTextField;
