/**
 * Created by sachinprakash on 8/7/19.
 */
import React from 'react';
import { withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionButton from '../../ActionButton/ActionButton';
import { required } from '../../../constants/FormValidations';
import {
    isValidFunction,
} from '../../../constants/CommonUtil';
import FormikTextField from './FormikTextField';
import FormikRadioButton from './FormikRadioButton';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    disableText: {
        color: '#000000',
    },
});


class FormikTextFieldWithRadioButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // checked: '',
        };
    }

    makeAllFalse = () => {
        const {
            form: {
                values,
            },
            name,
        } = this.props;
        const locale = name.slice(12, 14);
        if (isObjectValidAndNotEmpty(values.nameDetails) && isObjectValidAndNotEmpty(values.nameDetails[locale]) && isObjectValidAndNotEmpty(values.nameDetails[locale].synonyms)) {
            values.nameDetails[locale].fullySpecifiedName.preferred = false;
            // eslint-disable-next-line no-param-reassign,no-return-assign
            values.nameDetails[locale].synonyms.forEach(a => a.preferred = false);
        }
    };

    handleChange = (event) => {
        const {
            form,
            field: { name },
        } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (isValidFunction(setFieldValue)) {
            setFieldValue(name, event.target.value);
        }
    };
    handleOnBlur = () => {
        const {
            form,
            name,
        } = this.props;
        const locale = name.slice(12, 14);
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (isValidFunction(setFieldValue) && getStringFromObject(`values.nameDetails.${locale}.fullySpecifiedName.preferred`, form) !== false) {
            setFieldValue(`nameDetails.${locale}.fullySpecifiedName.preferred`, true);
        }
    };


    render() {
        const {
            classes,
            form: {
                values,
            },
            name,
            push,
            remove,
        } = this.props;
        // const { checked } = this.state;
        console.log('sdfdfsdaqweqw', this.props);
        const locale = name.slice(12, 14);
        return (
            <React.Fragment>
                <Grid container spacing={16} justify="flex-start">
                    <Grid item md={3} sm={3} lg={3} className="mt-1">
                        Fully Specified Name :
                    </Grid>
                    <Grid item sm={4} md={4} lg={4}>
                        <Field
                            className={classes.textField}
                            component={FormikTextField}
                            disableUnderline
                            variant="outlined"
                            fullWidth
                            required
                            validate={locale === 'en' && required}
                            name={`nameDetails.${locale}.fullySpecifiedName.name`}
                            handleOnBlur={this.handleOnBlur}
                        />
                    </Grid>
                    <Grid item lg={1} sm={1} md={1} justify="center" className="ml-2">
                        <Field
                            component={FormikRadioButton}
                            name={`nameDetails.${locale}.fullySpecifiedName.preferred`}
                            color="primary"
                            makeAllFalse={this.makeAllFalse}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={16} justify="flex-start">
                    <Grid item md={2} sm={2} lg={2} className="mt-1">
                        Synonym :
                    </Grid>
                    <Grid item sm={8} md={8} lg={8}>
                        {
                            isObjectValidAndNotEmpty(values.nameDetails) && isObjectValidAndNotEmpty(values.nameDetails[locale]) && isObjectValidAndNotEmpty(values.nameDetails[locale].synonyms) && values.nameDetails[locale].synonyms.map((a, index) => {
                                console.log('kasjdljkasl', a);
                                return (
                                    <Grid container key={a} justify="flex-start" className="mt-1">
                                        <Grid item sm={5.5} md={5.5} lg={5.5} style={{ marginRight: '20px', marginLeft: '60px' }}>
                                            <Field
                                                className={classes.textField}
                                                component={FormikTextField}
                                                disableUnderline
                                                variant="outlined"
                                                fullWidth
                                                name={`${name}.${index}.name`}
                                                label={`Synonym ${index + 1}`}
                                            />
                                        </Grid>
                                        <Grid item lg={1} sm={1} md={1} className="ml-2" style={{ marginRight: '10px' }}>
                                            <Field
                                                component={FormikRadioButton}
                                                name={`${name}.${index}.preferred`}
                                                color="primary"
                                                makeAllFalse={this.makeAllFalse}
                                            />
                                        </Grid>
                                        <Grid item lg={1} sm={1} md={1} style={{ marginTop: '10px' }}>
                                            <Close onClick={() => remove(index)} />
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" >
                    <Grid item sm={4} md={4} lg={4} />
                    <Grid item sm={2} md={2} lg={2}>
                        <ActionButton className="mt-1" onClick={() => push({ preferred: false })}>
                            Add Synonym
                        </ActionButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

FormikTextFieldWithRadioButton.propTypes = {
    classes: PropTypes.object,
    /* formik props */
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    name: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

FormikTextFieldWithRadioButton.defaultProps = {
    classes: {},
};

export default connect()(withStyles(styles)(FormikTextFieldWithRadioButton));
