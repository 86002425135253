import React from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GenericFilterComponent from './GenericFilterComponent';

const styles = () => ({
    paper: {
        padding: '2rem',
        width: '50rem',
        overflow: 'visible',
    },
});


class PopoverFilter extends React.PureComponent {
    render() {
        const {
            anchorEl,
            filterSchema,
            saveFilter,
            handleClose,
            filterValues,
        } = this.props;
        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ paper: this.props.classes.paper }}
            >
                <GenericFilterComponent
                    filterColumns={filterSchema}
                    applyFilter={saveFilter}
                    handleClose={handleClose}
                    filterValues={filterValues}
                />
            </Popover>
        );
    }
}

PopoverFilter.propTypes = {
    anchorEl: PropTypes.object,
    classes: PropTypes.object,
    filterValues: PropTypes.object,
    filterSchema: PropTypes.array,
    handleClose: PropTypes.func,
    saveFilter: PropTypes.func,
};

PopoverFilter.defaultProps = {
    anchorEl: null,
    handleClose: () => {},
    saveFilter: () => {},
    classes: {},
    filterValues: {},
    filterSchema: [],
};

export default withStyles(styles)(PopoverFilter);
