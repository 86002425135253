import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import { TextField } from 'redux-form-material-ui';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
    multiInput: {
        padding: '6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '1024px',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
});

class SendEmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            body: '',
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            emailId,
        } = this.props;
        const { subject, body } = this.state;
        console.log('2222222222ddddddddddd', subject, body);
        return (
            <Dialog
                open={open}
                scroll="body"
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div>Send Email</div>
                        <Close className="cursor-pointer" onClick={handleClose} />
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={16}>
                        <Grid item lg={6} md={6} sm={6}>
                            <Grid container className="mt-1">
                                <TextField
                                    label="Email"
                                    fullWidth
                                    value={emailId}
                                    disabled
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container className="mt-1">
                                <TextField
                                    label="Subject"
                                    placeholder="Subject"
                                    name="subject"
                                    fullWidth
                                    value={subject}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container className="mt-1">
                                <TextField
                                    name="body"
                                    label="Body"
                                    placeholder="Body"
                                    value={body}
                                    fullWidth
                                    multiline
                                    rows={6}
                                    rowsMax={6}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.multiInput),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                            Preview
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '12px' }}>
                    <Button
                        className={classNames(classes.buttonStyle, classes.cancelButton)}
                        disableRipple
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classNames(classes.buttonStyle, classes.cancelButton)}
                        disableRipple
                    >
                        Print
                    </Button>
                    <Button
                        className={classNames('gradient-background-right', classes.buttonStyle)}
                        disableRipple
                        onClick={handleClose}
                    >
                        Send and Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SendEmailDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    emailId: PropTypes.string,
};

SendEmailDialog.defaultProps = {
    emailId: 'supplier@gmail.com',
};

export default withStyles(style)(SendEmailDialog);
