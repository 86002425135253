import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_CALENDAR_DETAILS_FAILURE,
    FETCH_CALENDAR_DETAILS_SUCCESS,
} from '../../redux/modules/dashboard/dashboard-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export default function* fetchCalendarWorkerSaga(action) {
    try {
        const { api, startDate, endDate } = action;
        const apiWithQueryParam = `${api}?startDate=${startDate}&endDate=${endDate}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_CALENDAR_DETAILS_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_CALENDAR_DETAILS_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
