import API from '../../../constants/api';

export const CREATE_ACCOUNT_EXPENSE_REQUEST = '@@employeeExpense/CREATE_ACCOUNT_EXPENSE_REQUEST';
export const CREATE_ACCOUNT_EXPENSE_FAILURE = '@@employeeExpense/CREATE_ACCOUNT_EXPENSE_FAILURE';
export const CREATE_ACCOUNT_EXPENSE_SUCCESS = '@@employeeExpense/CREATE_ACCOUNT_EXPENSE_SUCCESS';

export const FETCH_ACCOUNT_EXPENSE_REQUEST = '@@employeeExpense/FETCH_ACCOUNT_EXPENSE_REQUEST';
export const FETCH_ACCOUNT_EXPENSE_FAILURE = '@@employeeExpense/FETCH_ACCOUNT_EXPENSE_FAILURE';
export const FETCH_ACCOUNT_EXPENSE_SUCCESS = '@@employeeExpense/FETCH_ACCOUNT_EXPENSE_SUCCESS';

export const CLEAR_SELECTED_ACCOUNT_EXPENSE = '@@employeeExpense/CLEAR_SELECTED_ACCOUNT_EXPENSE';


export const createAccountExpenseRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_ACCOUNT_EXPENSE_REQUEST,
    api: API.PETTYCASH.RECOUP,
    payload,
    successCallback,
    failureCallBack,
});

export const fetchAccountExpenseRequest = uuid => ({
    type: FETCH_ACCOUNT_EXPENSE_REQUEST,
    api: `${API.PETTYCASH.RECOUP}/${uuid}`,
});

export const fetchAccountExpenseRequestById = id => ({
    type: FETCH_ACCOUNT_EXPENSE_REQUEST,
    api: `${API.ACCOUNT_EXPENSE.FETCH}/byId/${id}`,
});


export const clearSelectedExpenseInvoice = () => ({
    type: CLEAR_SELECTED_ACCOUNT_EXPENSE,
});
