/**
 * Created by sachinprakash on 8/7/19.
 */
import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';

import { Field, FieldArray } from 'formik';

import PropTypes from 'prop-types';
import FormikTextFieldWithRadioButton from './FormikTextFieldWithRadioButton';
import FormikTextField from './FormikTextField';


class FormikCardsForTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            hidden,
            locale,

        } = this.props;
        console.log('khasdf;kjsdf', this.props);
        return (
            <Card style={{ display: hidden ? 'none' : 'block' }}>
                <CardContent>
                    <Grid container justify="flex-start" className="mt-1">
                        <Grid item sm={2} md={2} lg={2} style={{ paddingLeft: '33em' }}>
                            Preferred
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" className="mt-1">
                        <Grid item sm={10} md={10} lg={10}>
                            <FieldArray
                                component={FormikTextFieldWithRadioButton}
                                name={`${locale}.synonyms`}
                                inputProps={locale}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" className="mt-1" spacing={8}>
                        <Grid item lg={4} sm={4} md={4}>
                            <Field
                                // component={OutlinedTextField}
                                component={FormikTextField}
                                disableUnderline
                                variant="outlined"
                                fullWidth
                                name={`${locale}.shortName.name`}
                                label="ShortName"
                            />
                        </Grid>
                        <Grid item lg={4} sm={4} md={4}>
                            <Field
                                // component={OutlinedTextField}
                                component={FormikTextField}
                                disableUnderline
                                variant="outlined"
                                fullWidth
                                name={`${locale}.description`}
                                multiline
                                rows={2}
                                rowsMax={3}
                                label="Description"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

FormikCardsForTextField.propTypes = {
    locale: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
};

FormikCardsForTextField.defaultProps = {
};

export default FormikCardsForTextField;
