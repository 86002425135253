/**
 * Created by sachinprakash on 9/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { addOrUpdateWorker, fetchPagesOfProductsWorker } from './labTestsWorkerSaga';
import { ADD_OR_UPDATE_TESTS_REQUEST, FETCH_TESTS_REQUEST } from '../../redux/modules/LabSettings/labTest-actions';

export function* fetchPagesOfTestsWatcher() {
    yield takeLatest(FETCH_TESTS_REQUEST, fetchPagesOfProductsWorker);
}

export function* addOrUpdateTestWatcher() {
    yield takeLatest(ADD_OR_UPDATE_TESTS_REQUEST, addOrUpdateWorker);
}
