import axios from 'axios';
import cloneDeep from 'clone-deep';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import {
    apiCatchBlockFunction, getJsonPath,
    isArrayValidAndNotEmpty,


} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const insuranceSpecialCaseTypes = {
    DENTAL_DEPARTMENT_COPAY: 'DENTAL_DEPARTMENT_COPAY',
    Maternity: 'Maternity',
};

export const deductibleTypes = {
    MATERNITY: 'MATERNITY',
    DENTAL: 'DENTAL',
    NORMAL: 'NORMAL',
};

export const mapToDeductibleCateg = d => ({
    name: getStringFromObject('name', d, ''),
    categoryUuid: getStringFromObject('uuid', d, ''),
    absolute: false,
    deductibleType: {
        key: deductibleTypes.NORMAL,
        value: deductibleTypes.NORMAL,
    },
});

export const insuranceSchemeFieldNames = {
    NAME: 'name',
    NUMBER: 'number',
    DESCRIPTION: 'description',
    ACTIVE: 'active',

    MATERNITY_CASE_COVERED: 'maternityCaseCovered',
    MATERNITY_CASE_APPROVAL_REQUIRED: 'maternityCaseApprovalReq',
    MATERNITY_CASE_SPECIAL_COPAY: 'maternityCaseSpecialCoPay',
    COPAY_EDITABLE: 'coPayEditable',
    DENTAL_COVERED: 'dentalCovered',

    MAX_COPAY: 'maxCoPay',
    DAILY_MAX_COPAY: 'dailyMaxCoPay',
    MAX_EPISODES_IN_YEAR: 'maxEpisodesInAYear',

    INSURANCE_POLICY: 'policy',

    WARNING_MESSAGE: 'warningMessage',
    WARNING_MESSAGE_FOR_DOC: 'warningMessageForDoc',
    WARNING_MESSAGE_FOR_PHARMACIST: 'warningMessageForPharmacist',

    CATEGORY_COPAY: 'categoryCoPay',
    DEDUCTIBLE_COPAY: 'deductibleCoPay',
    NETWORK: 'network',

    DUMMY_SCHEME: 'dummyScheme',

    COPAY_PERCENTAGE: 'copayPercent',
    DEDUCTIBLE_AMOUNT: 'deductibleAmount',
    CLASS_NAME: 'className',
};

export const insuranceSchemeBooleanFields = {
    [insuranceSchemeFieldNames.MATERNITY_CASE_COVERED]: 'Maternity Case Covered',
    [insuranceSchemeFieldNames.MATERNITY_CASE_APPROVAL_REQUIRED]: 'Maternity Case Approval Required',
    [insuranceSchemeFieldNames.MATERNITY_CASE_SPECIAL_COPAY]: 'Maternity Case SpecialCoPay Applicable',
    [insuranceSchemeFieldNames.COPAY_EDITABLE]: 'Copay Editable',
    [insuranceSchemeFieldNames.DENTAL_COVERED]: 'Dental Covered',
    [insuranceSchemeFieldNames.DUMMY_SCHEME]: 'Dummy Scheme',
    [insuranceSchemeFieldNames.ACTIVE]: 'Active',
};

export const getMaxCopay = (scheme, enableDailyCopayLimit = false) => {
    if (enableDailyCopayLimit) {
        const maxCopayValue = getStringFromObject('dailyMaxCoPay', scheme) || getStringFromObject('maxCopay', scheme, null);
        return { key: insuranceSchemeFieldNames.DAILY_MAX_COPAY, value: maxCopayValue };
    }
    const maxCopayValue = getStringFromObject('maxCopay', scheme, null);
    return { key: insuranceSchemeFieldNames.MAX_COPAY, value: maxCopayValue };
};

export const getInsuranceSchemeFormDataFrom = (insuranceScheme) => {
    let formData = {};
    if (isObjectValidAndNotEmpty(insuranceScheme)) {
        const payerUuid = getStringFromObject('payer.key', insuranceScheme, null);
        formData = {
            ...insuranceScheme,
            [insuranceSchemeFieldNames.CODE]: getStringFromObject('name', insuranceScheme, null),
            uuid: getStringFromObject('uuid', insuranceScheme, null),
            [insuranceSchemeFieldNames.ACTIVE]: getStringFromObject('active', insuranceScheme, true),
            [insuranceSchemeFieldNames.DAILY_MAX_COPAY]: getStringFromObject('dailyMaxCoPay', insuranceScheme, null),
            [insuranceSchemeFieldNames.MAX_COPAY]: getStringFromObject('maxCoPay', insuranceScheme, null),
            [insuranceSchemeFieldNames.INSURANCE_POLICY]: payerUuid ? {
                payerUuid,
                name: getStringFromObject('payer.value', insuranceScheme, null),
            } : null,
        };
    } else {
        const booleanFieldsDefaultValue = {};
        Object.keys(insuranceSchemeBooleanFields).map((aField) => {
            booleanFieldsDefaultValue[aField] = false;
            return null;
        });
        formData = {
            [insuranceSchemeFieldNames.CODE]: null,
            [insuranceSchemeFieldNames.DESCRIPTION]: null,
            [insuranceSchemeFieldNames.MAX_COPAY]: null,
            [insuranceSchemeFieldNames.FOLLOWUP_DURATION_DENTAL]: null,
            [insuranceSchemeFieldNames.INSURANCE_POLICY]: null,
            [insuranceSchemeFieldNames.MATERNITY_CASE_COVERED]: false,
            [insuranceSchemeFieldNames.MATERNITY_CASE_APPROVAL_REQUIRED]: false,
            [insuranceSchemeFieldNames.DEDUCT_FULL_DEDUCTIBLE]: false,
            [insuranceSchemeFieldNames.MATERNITY_CASE_SPECIAL_COPAY]: false,
            [insuranceSchemeFieldNames.DIRECT_SPECIALIST_CONSULTATION_ALLOWED]: false,
            [insuranceSchemeFieldNames.DIRECT_CONSULTANT_CONSULTATION_ALLOWED]: false,
            [insuranceSchemeFieldNames.WARNING_MESSAGE]: null,
            [insuranceSchemeFieldNames.WARNING_MESSAGE_FOR_PHARMACIST]: null,
            [insuranceSchemeFieldNames.WARNING_MESSAGE_FOR_DOC]: null,
            [insuranceSchemeFieldNames.CATEGORY_COPAY]: [],
            [insuranceSchemeFieldNames.DEDUCTIBLE_COPAY]: [],
            [insuranceSchemeFieldNames.ACTIVE]: true,
            ...booleanFieldsDefaultValue,
        };
    }
    const coPayList = getStringFromObject('productCategoryCoPayDtoList', insuranceScheme, []);
    if (isArrayValidAndNotEmpty(coPayList)) {
        const category = [];
        const deductible = [];
        coPayList.sort((a, b) => a.id - b.id).map((c) => {
            if (c.deductible) {
                const deductibleAmount = getStringFromObject('deductibleAmount', c);
                if (NumberOf(deductibleAmount) > 0) {
                    formData[insuranceSchemeFieldNames.DEDUCTIBLE_AMOUNT] = deductibleAmount;
                }
                deductible.push({
                    name: getStringFromObject('name', c, ''),
                    deductibleValue: getStringFromObject('deductibleAmount', c, ''),
                    deductiblePercent: getStringFromObject('coPay', c, ''),
                    categoryUuid: getStringFromObject('insuranceCategory.key', c),
                    minValue: getStringFromObject('minCopay', c, ''),
                    id: getStringFromObject('id', c, ''),
                    absolute: !!(NumberOf(deductibleAmount) > 0),
                    maxValue: getStringFromObject('maxCopay', c, '') || '',
                    deductibleType: {
                        key: getStringFromObject('deductibleType', c),
                        value: getStringFromObject('deductibleType', c),
                    },
                });
            } else {
                const insuranceSpecialCases = getStringFromObject('insuranceSpecialCasesDtos', c, []);
                let copayPercent = getStringFromObject('coPay', c);
                console.log('asd9aud-asid0', copayPercent);
                if (!(copayPercent || copayPercent === 0)) {
                    copayPercent = '';
                }
                if (copayPercent || copayPercent === 0) {
                    formData[insuranceSchemeFieldNames.COPAY_PERCENTAGE] = copayPercent;
                }
                const dto = {
                    name: getStringFromObject('name', c, ''),
                    coPay: getStringFromObject('coPay', c, '') || 0,
                    id: getStringFromObject('id', c, ''),

                    // todo change this
                    insuranceSpecialCasesDtos: insuranceSpecialCases,

                    categoryUuid: getStringFromObject('insuranceCategory.key', c),
                };
                Object.values(insuranceSpecialCaseTypes).forEach((aSpecialCase) => {
                    dto[aSpecialCase] = '0';
                });
                if (isArrayValidAndNotEmpty(insuranceSpecialCases)) {
                    insuranceSpecialCases.forEach((aSpecialCase) => {
                        dto[aSpecialCase.name] = aSpecialCase.coPay || '0';
                    });
                }
                category.push(dto);
            }
            return null;
        });
        formData[insuranceSchemeFieldNames.CATEGORY_COPAY] = [...category];
        formData[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY] = [...deductible];
    }
    return formData;
};

export const getInsuranceSchemeSubmissionData = (values) => {
    const payload = {
        ...values,
        uuid: getStringFromObject('uuid', values, null),
        number: getStringFromObject('number', values, null) || '',
        operation: values.uuid ? 2 : 1,
        payerUuid:
            getStringFromObject(`${insuranceSchemeFieldNames.INSURANCE_POLICY}.payerUuid`, values, null),
        parentPayer:
            getStringFromObject(`${insuranceSchemeFieldNames.INSURANCE_POLICY}.payer`, values, null),
    };
    let coPayList = [];
    if (isArrayValidAndNotEmpty(values.categoryCoPay)) {
        coPayList = coPayList.concat(values.categoryCoPay.map((category) => {
            const dtoObject = {
                ...category,
                name: getStringFromObject('name', category, ''),
                coPay: getStringFromObject('coPay', category, 0),
                id: getStringFromObject('id', category, 0),
                insuranceCategory: {
                    key: getStringFromObject('categoryUuid', category, ''),
                    name: getStringFromObject('name', category, ''),
                },
                insuranceSpecialCasesDtos: [],
            };
            Object.keys(insuranceSpecialCaseTypes).forEach((anInsuranceSpecialCaseType) => {
                const coPayForType = getStringFromObject(anInsuranceSpecialCaseType, category);
                if (coPayForType) {
                    dtoObject.insuranceSpecialCasesDtos = dtoObject.insuranceSpecialCasesDtos.concat({
                        name: anInsuranceSpecialCaseType,
                        coPay: coPayForType,
                    });
                }
            });
            return dtoObject;
        }));
    }
    if (isArrayValidAndNotEmpty(values.deductibleCoPay)) {
        coPayList = coPayList.concat(values.deductibleCoPay.map(category => ({
            name: getStringFromObject('name', category, ''),
            deductibleAmount: category.absolute ? getStringFromObject('deductibleValue', category, 0) : null,
            deductible: true,
            insuranceCategory: {
                key: getStringFromObject('categoryUuid', category, ''),
                name: getStringFromObject('name', category, ''),
            },
            deductibleType: getStringFromObject('deductibleType.key', category),
            coPay: !category.absolute ? getStringFromObject('deductiblePercent', category, 0) : null,
            minCopay: !category.absolute ? getStringFromObject('minValue', category, 0) : null,
            maxCopay: !category.absolute ? getStringFromObject('maxValue', category, 0) : null,
            id: getStringFromObject('id', category, 0),
        })));
    }
    payload.productCategoryCoPayDtoList = coPayList;
    return payload;
};

export const validateInsuranceSchemeFormValues = (values, dispatcher) => {
    console.log('sad0su9adjpsaodjsadsapilkds.andasldjsa', dispatcher);
    const categoryCopays = getStringFromObject(insuranceSchemeFieldNames.CATEGORY_COPAY, values);
    const deductibleCopays = getStringFromObject(insuranceSchemeFieldNames.DEDUCTIBLE_COPAY, values);
    if (isArrayValidAndNotEmpty(categoryCopays)) {
        for (let i = 0; i < categoryCopays.length; i += 1) {
            const categoryCopay = categoryCopays[i];
            console.log('asd0asud9pod category', categoryCopay);
            if (!(categoryCopay.coPay || categoryCopay.coPay === '0' || categoryCopay.coPay === 0)) {
                dispatcher(errorMessage(`Category Copay Not Specified For ${categoryCopay.name}`));
                return false;
            }
            // if (!(categoryCopay.coPay || categoryCopay === '0' || categoryCopay === 0)) {
            // }
        }
    }

    if (isArrayValidAndNotEmpty(deductibleCopays)) {
        let processedDeductibleCopay = [];
        for (let j = 0; j < deductibleCopays.length; j += 1) {
            const deductibleCopay = deductibleCopays[j];
            const dedCategNameAndType =
                `${deductibleCopay.name}-${getStringFromObject('deductibleType.value', deductibleCopay)}`;
            if (processedDeductibleCopay.includes(dedCategNameAndType)) {
                dispatcher(errorMessage(`Duplicate deductible category and type found. ${deductibleCopay.name}`));
                return false;
            }
            processedDeductibleCopay = processedDeductibleCopay.concat(dedCategNameAndType);
            const absolute = getStringFromObject('absolute', deductibleCopay);
            const deductibleValue = getStringFromObject('deductibleValue', deductibleCopay);
            const deductiblePercent = getStringFromObject('deductiblePercent', deductibleCopay);
            console.log('asd-0as-d0ia-das', deductiblePercent, typeof deductiblePercent, absolute);
            if (absolute) {
                if (!(deductibleValue || deductibleValue === '0' || deductibleValue === 0)) {
                    dispatcher(errorMessage(`Deductible Value Not Given For ${deductibleCopay.name}`));
                    return false;
                }
            } else if (
                !(deductiblePercent || deductiblePercent === '0' || deductiblePercent === 0)
            ) {
                dispatcher(errorMessage(`Copay Not Given For ${deductibleCopay.name}`));
                return false;
            }
            console.log('asd0asud9pod deductible', deductibleCopay);
        }
    }
    return true;
};

export const validateSchemeAsyncFields = (values, dispatch, props) => {
    console.log('afa', props, dispatch);
    return axios.post(API.PAYORS.VALIDATE_SCHEME, getInsuranceSchemeSubmissionData(values))
        .then(
            (response) => {
                if (isObjectValidAndNotEmpty(response.data)) {
                    throw response.data;
                }
            },
            error => apiCatchBlockFunction(error, dispatch),
        );
};

export const setDeductibleValuesAndCopays = (coPayPercent, deductibleAmount, formValues) => {
    const newFormValues = cloneDeep(formValues);
    const categoryCopay = newFormValues[insuranceSchemeFieldNames.CATEGORY_COPAY];
    if (isArrayValidAndNotEmpty(categoryCopay)) {
        newFormValues[insuranceSchemeFieldNames.CATEGORY_COPAY] = categoryCopay.map(aRow => ({
            ...aRow,
            coPay: coPayPercent,
            [insuranceSpecialCaseTypes.Maternity]: coPayPercent,
            [insuranceSpecialCaseTypes.DENTAL_DEPARTMENT_COPAY]: coPayPercent,
        }));
    }
    const deductibleCopay = newFormValues[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY];
    if (isArrayValidAndNotEmpty(deductibleCopay)) {
        newFormValues[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY] = deductibleCopay.map(aRow => ({
            ...aRow,
            absolute: (!!deductibleAmount),
            deductibleValue: (deductibleAmount) || null,
            deductiblePercent: (deductibleAmount) ? null : (coPayPercent),
        }));
    }
    return newFormValues;
};

export const fetchSchemeInitialValues = async (dispatcher) => {
    try {
        const booleanFieldsDefaultValue = {};
        Object.keys(insuranceSchemeBooleanFields).map((aField) => {
            booleanFieldsDefaultValue[aField] = false;
            return null;
        });
        console.log('asdoasdajd-0as', insuranceSchemeBooleanFields);
        dispatcher(showSpinner());
        const response =
            await axios.get(getJsonPath('/Payor/SchemeDefaultValues.json', APPLICATION_CONFIG_URL));
        dispatcher(hideSpinner());
        return ({
            ...booleanFieldsDefaultValue,
            ...(response.data || {}),
        });
    } catch (e) {
        apiCatchBlockFunction(e, dispatcher);
    }
    return {};
};

export const fetchInsuranceCategoriesForInitialization = async (type, dispatcher, coPayPercent) => {
    try {
        dispatcher(showSpinner());
        const category = await axios.get(API.INSURANCE_PRODUCT_CATEGORIES.GET_ALL, {
            params: {
                deductible: false,
                payerType: type,
            },
        });
        const deductible = await axios.get(API.INSURANCE_PRODUCT_CATEGORIES.GET_ALL, {
            params: {
                deductible: true,
                payerType: type,
            },
        });
        dispatcher(hideSpinner());
        const insuranceSchemeInitialValues = await fetchSchemeInitialValues(dispatcher);
        const formInitialValues = getInsuranceSchemeFormDataFrom(insuranceSchemeInitialValues);
        formInitialValues[insuranceSchemeFieldNames.CATEGORY_COPAY] = category.data.map((c) => {
            const dto = {
                name: getStringFromObject('name', c, ''),
                categoryUuid: getStringFromObject('uuid', c, ''),
                coPay: 0,
            };
            Object.values(insuranceSpecialCaseTypes).forEach((aSpecialCase) => {
                dto[aSpecialCase] = 0;
            });
            return dto;
        });
        formInitialValues[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY] =
            deductible.data.map(d => mapToDeductibleCateg(d));

        if (coPayPercent !== null) {
            const deductibleValuesAndCopays =
                setDeductibleValuesAndCopays(coPayPercent, null, formInitialValues);
            formInitialValues[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY] =
                deductibleValuesAndCopays[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY];
            formInitialValues[insuranceSchemeFieldNames.CATEGORY_COPAY] =
                deductibleValuesAndCopays[insuranceSchemeFieldNames.CATEGORY_COPAY];
            console.log('asd9aud-asid0---22', coPayPercent);
            formInitialValues[insuranceSchemeFieldNames.COPAY_PERCENTAGE] = coPayPercent;
        }

        return {
            formInitialValues,
            deductibleData: deductible.data,
        };
    } catch (e) {
        apiCatchBlockFunction(e, dispatcher);
    }
    return {};
};
