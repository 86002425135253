import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    productDispatch,
    pathPrivileges,
    purchaseOrders,
    purchasePage,
    purchasePayments,
    purchaseSuppliers,
    supplierInvoices,
} from '../constants/constants';
import PurchaseRoutesContainer from '../containers/PurchaseRoutesContainer/PurchaseRoutesContainer';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { getStringFromObject } from '../constants/lodashUtils';
import PageLoader from '../components/PageLoader/PageLoader';

const PurchasePaymentsPageV2 = React.lazy(() => import('../pages/PurchaseRoutes/PurchasePaymentsPageV2'));
const PurchaseOrdersPage = React.lazy(() => import('../pages/PurchaseRoutes/PurchaseOrdersPage'));
const PurchaseInvoicesPage = React.lazy(() => import('../pages/PurchaseRoutes/PurchaseInvoicesPage'));
const StockManagementSupplierPage = React.lazy(() => import('../pages/PurchaseRoutes/PurchaseSuppliersPage'));
const ProductDispatchPage = React.lazy(() => import('../pages/PurchaseRoutes/ProductDispatchPage'));

const getDefaultRoute = (props) => {
    const route = getStringFromObject('history.location.pathname', props);
    if (route === purchasePage) {
        if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${purchaseSuppliers}.privileges`, pathPrivileges),
        )) {
            console.log('asdasdasd');
            return <Route path={purchasePage} exact component={StockManagementSupplierPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${supplierInvoices}.privileges`, pathPrivileges),
        )) {
            return <Route path={purchasePage} exact component={PurchaseInvoicesPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${purchasePayments}.privileges`, pathPrivileges),
        )) {
            return <Route path={purchasePage} exact component={PurchasePaymentsPageV2} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${purchaseOrders}.privileges`, pathPrivileges),
        )) {
            return <Route path={purchasePage} exact component={PurchaseOrdersPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${productDispatch}.privileges`, pathPrivileges),
        )) {
            return <Route path={purchasePage} exact component={ProductDispatchPage} />;
        }
    }
    return '';
};

const PurchaseManagementRoutes = props => (
    <PurchaseRoutesContainer {...props} >
        <Suspense fallback={<PageLoader />}>
            {getDefaultRoute(props)}
            <Route path={supplierInvoices} exact component={PurchaseInvoicesPage} />
            <Route path={purchaseSuppliers} exact component={StockManagementSupplierPage} />
            <Route path={purchasePayments} exact component={PurchasePaymentsPageV2} />
            <Route path={purchaseOrders} exact component={PurchaseOrdersPage} />
            <Route path={productDispatch} exact component={ProductDispatchPage} />
        </Suspense>
    </PurchaseRoutesContainer>
);

export default PurchaseManagementRoutes;
