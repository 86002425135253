import { getNurseShop, getRegistrationShop, persistNurseShop, persistRegistrationShop } from '../../../constants/state';
import {
    SET_NURSE_SHOP,
    SET_REGISTRATION_SHOP,
    TOGGLE_REGISTRATION_SHOP_SELECT,
} from './registrationShop-actions';

const registrationShopReducer = (
    state = {
        shop: getRegistrationShop(),
        nurseShop: getNurseShop(),
        openSelect: false,
    },
    action,
) => {
    let newState;
    console.log('new action', action);
    if (action.type === SET_REGISTRATION_SHOP) {
        newState = {
            shop: action.shop,
            openSelect: !action.closeDialog,
        };
        persistRegistrationShop(action.shop);
    } else if (action.type === TOGGLE_REGISTRATION_SHOP_SELECT) {
        newState = {
            ...state,
            openSelect: !state.openSelect,
        };
    } else if (action.type === SET_NURSE_SHOP) {
        newState = {
            ...state,
            nurseShop: action.shop,
            openSelect: !state.openSelect,
        };
        persistNurseShop(action.shop);
    } else {
        newState = state;
    }
    return newState;
};

export default registrationShopReducer;
