import API from '../../../constants/api';

export const FETCH_ORGANIZATION_LIST_REQUEST = '@@organization/FETCH_ORGANIZATION_LIST_REQUEST';
export const FETCH_ORGANIZATION_LIST_SUCCESS = '@@organization/FETCH_ORGANIZATION_LIST_SUCCESS';
export const FETCH_ORGANIZATION_LIST_FAILURE = '@@organization/FETCH_ORGANIZATION_LIST_FAILURE';

export const fetchOrganizationList = (payload, page, size) => ({
    type: FETCH_ORGANIZATION_LIST_REQUEST,
    api: API.EMPLOYEE.GET_ALL,
    page,
    size,
    payload,
});
