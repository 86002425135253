import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { formatDate, setTimeForDate } from '../../constants/DateUtil';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';

const generateAppointmentListConstruct = (filters) => {
    const now = new Date();
    const fromDate = filters.fromDate ? filters.fromDate : formatDate(now, 'yyyy-MM-dd');
    const toDate = filters.toDate ? filters.toDate : formatDate(now, 'yyyy-MM-dd');
    let construct = getCombined(
        generateObjectWithCriteriaMode(
            'appointment.appointmentDate',
            setTimeForDate(new Date(fromDate)).getTime(),
            '>=',
        ),
        'AND',
        generateObjectWithCriteriaMode(
            'appointment.appointmentDate',
            setTimeForDate(new Date(toDate), 23, 59).getTime(),
            '<=',
        ),
    );
    if (filters.status) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode('appointment.status', filters.status, '='),
        );
    }
    if (isArrayValidAndNotEmpty(filters.doctor)) {
        let docCriteria = {};
        filters.doctor.forEach((doc) => {
            docCriteria = getCombined(
                docCriteria,
                'OR',
                generateObjectWithCriteriaMode('provider.uuid', doc.key, '='),
            );
        });
        construct = getCombined(docCriteria, 'AND', construct);
    }
    if (filters.searchString && (typeof filters.searchString !== 'object')) {
        // patient mobile no
        let searchString = filters.searchString.trim();
        let operation = ':';
        if (searchString.endsWith('@')) {
            operation = '=';
            searchString = searchString.substring(0, searchString.length - 1);
        }
        // secondary identifier
        const nationalId = generateObjectWithCriteriaMode('person.secondaryIdentifier', searchString, operation);
        if (searchString.match('^[0-9]*[@]*$')) {
            const mobilePhoneCriteria = generateObjectWithCriteriaMode('appointment.patientPhoneNumber',
                searchString, operation);
            // const attributeConstruct = getAttributeSearchConstruct(payload.attributesToSearch, mobilePhoneCriteria,
            //     searchString);
            construct = getCombined(construct, 'AND', getCombined(mobilePhoneCriteria, 'OR', nationalId));
        } else {
            // patient name registered patient
            let nameConstruct = null;
            // remove if @ is added in the end for name search
            const searchStringWithoutAt = searchString.endsWith('@') ? searchString.slice(0, -1) : searchString;
            const names = searchStringWithoutAt.split(/ (.*)/);
            if (names[1]) {
                const lastNames = names[1].split(/ (.*)/);
                if (lastNames[1]) {
                    nameConstruct = getCombined(
                        generateObjectWithCriteriaMode('personNames.givenName', names[0], operation),
                        'AND',
                        generateObjectWithCriteriaMode('personNames.middleName', lastNames[0], operation));
                    nameConstruct = getCombined(
                        nameConstruct,
                        'AND',
                        generateObjectWithCriteriaMode('personNames.familyName', lastNames[1], operation));
                } else {
                    nameConstruct = getCombined(
                        generateObjectWithCriteriaMode('personNames.familyName', names[0], operation),
                        'OR',
                        generateObjectWithCriteriaMode('personNames.middleName', names[0], operation));
                    nameConstruct = getCombined(
                        nameConstruct,
                        'AND',
                        generateObjectWithCriteriaMode('personNames.givenName', names[0], operation));
                }
            } else {
                nameConstruct = getCombined(
                    generateObjectWithCriteriaMode('personNames.givenName', names[0], operation),
                    'OR',
                    generateObjectWithCriteriaMode('personNames.middleName', names[0], operation));
                nameConstruct = getCombined(
                    nameConstruct,
                    'OR',
                    generateObjectWithCriteriaMode('personNames.familyName', names[0], operation));
            }
            // non registered patient name search
            nameConstruct = getCombined(
                nameConstruct,
                'OR',
                generateObjectWithCriteriaMode('appointment.patientName', searchString, operation),
            );
            // nameConstruct = getAttributeSearchConstruct(payload.attributesToSearch, nameConstruct, searchString);
            construct = getCombined(getCombined(nameConstruct, 'OR', nationalId), 'AND', construct);
        }
    }
    return construct;
};

export default generateAppointmentListConstruct;
