import initialStates from '../../initialStates';
import { FETCH_CALENDAR_DETAILS_SUCCESS } from './dashboard-actions';

const dashboardReducer = (state = initialStates.dashboard, action) => {
    let newState = {};

    switch (action.type) {
    case FETCH_CALENDAR_DETAILS_SUCCESS:
        newState = {
            ...state,
            calendar: action.data,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default dashboardReducer;
