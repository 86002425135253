import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_STOCK_INTENT_REQUEST,
    FETCH_STOCK_INTENT_REQUEST,
    MARK_STOCK_INTENT_AS_COMPLETE,
} from '../../redux/modules/stockIntent/stockIntent-actions';
import {
    createStockIntentWorker,
    fetchStockIntent,
    markIntent,
} from './stockIntentWorkerSaga';

export function* createStockIntentWatcher() {
    yield takeLatest(CREATE_STOCK_INTENT_REQUEST, createStockIntentWorker);
}

export function* fetchStockIntentRequestWatcher() {
    yield takeLatest(FETCH_STOCK_INTENT_REQUEST, fetchStockIntent);
}

export function* markIntentAsComplete() {
    yield takeLatest(MARK_STOCK_INTENT_AS_COMPLETE, markIntent);
}
