/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_ADVANCE_PAYMENT_SELECTED,
    CREATE_ADVANCE_PAYMENT_SUCCESS,
    FETCH_ADVANCE_PAYMENT_SUCCESS,
} from './advancePayment-actions';

const advancePaymentReducer = (state = initialStates.advancePayment, action) => {
    let newState = {};
    switch (action.type) {
    case CREATE_ADVANCE_PAYMENT_SUCCESS:
    case FETCH_ADVANCE_PAYMENT_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_ADVANCE_PAYMENT_SELECTED:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default advancePaymentReducer;
