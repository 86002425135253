import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Field, withFormik } from 'formik';
import cloneDeep from 'clone-deep';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

import { required } from '../../constants/FormValidations';
import FormikTextField from '../Formik/FieldComponents/FormikTextField';
import {
    getJsonPath,
    isArrayValidAndNotEmpty,
    roundedValue,
    roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import FormikTable from '../Formik/FormikTable/FormikTable';
import ActionButton from '../ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import FormikReactSelectMaterial from '../Formik/FieldComponents/FormikReactSelectMaterial';
import API from '../../constants/api';
import {
    ACCOUNT_MOVE_STATE,
    ACCOUNT_MOVE_UI_OBJECT,
    getAccountMoveFromUiObject,
    getUiObjectFromAccountMove,
} from '../../mapper/JournalEntryMapper';
import { createJournalEntryRequest } from '../../redux/modules/journalEntries/journalEntries-actions';
import { ACTION_HANDLERS } from './JournalEntriesActionHandlers';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
// import { subCompanies } from '../../constants/ERPConstants';

export const formName = 'journalEntries';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
        color: 'white',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
    multiline: {
        padding: '2px 0',
    },
    multiInput: {
        padding: '6px',
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '1rem',
        backgroundColor: '#469DC7',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    paper: {
        background: '#fafafa',
    },
    closeIcon: {
        color: 'white',
    },
});

class JournalEntriesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
        };
    }

    componentWillMount() {
        const { setValues, selectedJournal } = this.props;
        console.log('ashldjfhasjkldfh', selectedJournal, getUiObjectFromAccountMove(ACCOUNT_MOVE_UI_OBJECT));
        if (isObjectValidAndNotEmpty(selectedJournal)) {
            setValues(getUiObjectFromAccountMove(selectedJournal));
        } else setValues(getUiObjectFromAccountMove(ACCOUNT_MOVE_UI_OBJECT));
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/JournalEntries.json'))
            .then(resp => resp.json())
            .then((json) => {
                console.log('dkjfkaf', json);
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.selectedJournal, this.props.selectedJournal)
            && isObjectValidAndNotEmpty(nextProps.selectedJournal)
        ) {
            console.log('JournalEntriesDialog, initailizing form', nextProps.selectedJournal);
            nextProps.setValues(getUiObjectFromAccountMove(nextProps.selectedJournal));
        }
    }

    getExtraRows = () => {
        const { values, readOnly } = this.props;
        const isPosted = getStringFromObject('state', values) === ACCOUNT_MOVE_STATE.POSTED;
        const isPeriodClosed = getStringFromObject('periodStatus', values) === 'done';
        let editable = !(isPosted && isPeriodClosed);
        if (readOnly) {
            editable = false;
        }
        const journals = getStringFromObject('moveLineDtos', values, []);
        const { credit, debit } = this.calculateCreditAndDebit(journals);
        return (
            <TableRow>
                <TableCell colSpan={editable ? 2 : 1} />
                <TableCell className="bigLabel" test-id="total">
                    Total
                </TableCell>
                <TableCell colSpan={1} />
                <TableCell className="bigLabel" test-id="debit">
                    {roundedValueFixedToTwoDigits(debit)}
                </TableCell>
                <TableCell className="bigLabel" test-id="credit">
                    {roundedValueFixedToTwoDigits(credit)}
                </TableCell>
            </TableRow>
        );
    };

    calculateCreditAndDebit = (journals) => {
        let debit = 0;
        let credit = 0;
        if (isArrayValidAndNotEmpty(journals)) {
            journals.map((j) => {
                debit = roundedValue(debit + (Number(j.debit) || 0));
                credit = roundedValue(credit + (Number(j.credit) || 0));
                return null;
            });
        }
        return { debit, credit };
    };

    handleSave = () => {
        const {
            values, dispatch, submitForm,
        } = this.props;
        const journals = getStringFromObject('moveLineDtos', values, []);
        const { credit, debit } = this.calculateCreditAndDebit(journals);
        if (credit !== debit) {
            dispatch(errorMessage('Credit and Debit must be equal'));
        } else {
            submitForm();
        }
    };

    render() {
        const {
            classes,
            open,
            handleClose,
            values,
            readOnly,
        } = this.props;
        const { schema } = this.state;
        console.log('JournalEntriesDialog, value', this.props);
        const isPosted = getStringFromObject('state', values) === ACCOUNT_MOVE_STATE.POSTED;
        const isPeriodClosed = getStringFromObject('periodStatus', values) === 'done';
        let editable = !(isPosted && isPeriodClosed);
        if (readOnly) {
            editable = false;
        }
        const name = getStringFromObject('name', values);
        const moveLines =
            getStringFromObject('moveLineDtos', values);
        let hasAssociatedBankStatements = false;
        if (isArrayValidAndNotEmpty(moveLines)) {
            hasAssociatedBankStatements =
                isObjectValidAndNotEmpty(moveLines.find(aLine => aLine.bankStatementLineUuid));
        }
        console.log('dsajkdfasldfjh', name);
        return (
            <Dialog
                open={open}
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>Journal Entries {isPosted && name ? `(${name})` : ''}</div>
                        <Close className={classNames(classes.closeIcon, 'cursor-pointer')} onClick={handleClose} test-id="journalentries-close" />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container className="mt-1" spacing={32}>
                        <Grid item lg={2} md={3} sm={6} style={{ marginTop: '4px' }}>
                            <Field
                                testId="journal"
                                name="journal"
                                component={FormikReactSelectMaterial}
                                dataSourceConfig={{
                                    text: 'name',
                                    value: 'id',
                                }}
                                style={{ padding: '6px' }}
                                autocomplete
                                label="Select Journal"
                                placeholder="Select Journal"
                                selectProps={{
                                    textFieldProps: {
                                        InputProps: {
                                            classes: {
                                                multiline: classes.multiline,
                                            },
                                            disableUnderline: true,
                                        },
                                    },
                                }}
                                isDisabled={!editable}
                                validate={required}
                                dataSourceApi={API.SEARCH.ACCOUNT_JOURNAL}
                            />
                        </Grid>
                        <Grid item lg={2} md={3} sm={6}>
                            <Field
                                testId="date"
                                name="date"
                                variant="outlined"
                                component={FormikTextField}
                                label="Date"
                                type="date"
                                fullWidht
                                disabled={!editable}
                                validate={required}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {
                            !getStringFromObject('uuid', values) &&
                            <Grid item sm={3}>
                                <Field
                                    name="subCompany"
                                    label="Sub Company"
                                    component={FormikReactSelectMaterial}
                                    isDisabled={readOnly}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    fullWidth
                                    dataSourceApi={API.EMPLOYEE.GET_EMPLOYEE_SUBCOMPANY}
                                    validate={required}
                                    required
                                />
                            </Grid>
                        }
                        <Grid item sm={3}>
                            <Field
                                name="partner"
                                label="Select Partner"
                                component={FormikReactSelectMaterial}
                                dataSourceConfig={{
                                    text: 'name',
                                    value: 'uuid',
                                }}
                                style={{ padding: '6px' }}
                                autocomplete
                                selectProps={{
                                    textFieldProps: {
                                        InputProps: {
                                            classes: {
                                                multiline: classes.multiline,
                                            },
                                            disableUnderline: true,
                                        },
                                    },
                                }}
                                isDisabled={readOnly}
                                dataSourceApi={API.SEARCH.RES_PARTNER}
                            />
                        </Grid>
                    </Grid>
                    {
                        hasAssociatedBankStatements &&
                        <Grid container className="mt-2">
                            <h2 className="m-0" style={{ color: '#C62828' }}>
                                This journal entry has an associated bank reconciliation.
                                Please edit the bank reconciliation after any edits.
                            </h2>
                        </Grid>
                    }
                    <Grid container className="mt-2">
                        {
                            isObjectValidAndNotEmpty(schema) &&
                            <FormikTable
                                fieldName="moveLineDtos"
                                enablePagination={false}
                                showRowNumber
                                tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                deleteIcon={false}
                                actionHandlers={ACTION_HANDLERS}
                                getExtraRows={this.getExtraRows}
                                {...schema}
                                isEditable={editable}
                            />
                        }
                    </Grid>
                    <Grid container className="mt-2">
                        <Grid item lg={3} sm={4} md={4}>
                            <Field
                                test-id="narration"
                                name="narration"
                                variant="outlined"
                                component={FormikTextField}
                                label="Memo"
                                multiline
                                fullWidth
                                disabled={!editable}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {
                            !getStringFromObject('uuid', values) && (
                                <Grid item lg={12} md={12} sm={12} className="mt-1">
                                    <Typography variant="body1" gutterBottom>
                                        Note: Sub Company is only used for sequencing.
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        test-id="cancel"
                        onClick={handleClose}
                        primary={false}
                    >
                        Cancel
                    </ActionButton>
                    {
                        editable &&
                        <ActionButton
                            testId="save"
                            onClick={this.handleSave}
                        >
                            Save
                        </ActionButton>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

JournalEntriesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    selectedJournal: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    /* formik props */
    submitForm: PropTypes.func.isRequired,
    // resetForm: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

JournalEntriesDialog.defaultProps = {
    selectedJournal: {},
    readOnly: false,
};

const handleSubmitForm = (values, { props }) => {
    const payload = getAccountMoveFromUiObject(values);
    console.log('JournalEntriesDialog payload', payload);
    props.dispatch(createJournalEntryRequest(payload, () => {
        props.handleClose(null, true);
    }));
};

export default connect()((withFormik({
    mapPropsToValues: () => cloneDeep(ACCOUNT_MOVE_UI_OBJECT),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(JournalEntriesDialog))));
