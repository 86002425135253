import API from '../../../constants/api';


export const zatcaInvoiceType = {
    // Simplified Tax Invoice (B2C)
    CASH_PURCHASE_CREDIT_MEMO: 'CASH_PURCHASE_CREDIT_MEMO',
    /* Issued to adjust or correct a previously recorded purchase invoice, this memo
    serves to receive adjustments to the CASH_PURCHASE_INVOICE. */

    CASH_PURCHASE_DEBIT_MEMO: 'CASH_PURCHASE_DEBIT_MEMO',
    /* When refunding goods or services collected from a previously recorded purchase invoice,
    this memo is used. It facilitates the refund of goods or services from the CASH_PURCHASE_INVOICE. */

    CASH_SALE_CREDIT_MEMO: 'CASH_SALE_CREDIT_MEMO',
    /* This memo is issued as a refund for sales recorded under CASH_SALE_INVOICE.
    The refund process is managed through the refund screen in the sales app. */

    CASH_SALE_DEBIT_MEMO: 'CASH_SALE_DEBIT_MEMO',
    /* Used to make adjustment entries for previously recorded sales invoices,
    this memo facilitates the necessary adjustments for transactions recorded under CASH_SALE_INVOICE. */

    CREDIT_PURCHASE_CREDIT_MEMO: 'CREDIT_PURCHASE_CREDIT_MEMO',
    /* Issued to adjust or correct a previously recorded purchase invoice,
    this memo is used to receive adjustments to the CREDIT_PURCHASE_INVOICE. */

    CREDIT_PURCHASE_DEBIT_MEMO: 'CREDIT_PURCHASE_DEBIT_MEMO',
    /* When refunding goods or services collected from a previously recorded purchase invoice,
    this memo is used. It allows for the refund of goods or services from the CREDIT_PURCHASE_INVOICE. */

    CREDIT_SALE_CREDIT_MEMO: 'CREDIT_SALE_CREDIT_MEMO',
    /* Issued when a customer refunds items bought in previous sales due to claim
    rejections or returns of medications by corporate customers,
    this memo is used to process refunds for CREDIT_SALE_INVOICE transactions. */

    CREDIT_SALE_DEBIT_MEMO: 'CREDIT_SALE_DEBIT_MEMO',
    /* Used to make adjustment entries for previously recorded sales invoices,
    this memo facilitates the necessary adjustments for transactions recorded under CREDIT_SALE_INVOICE. */
};

export const memoTypes = {
    ISSUE_CREDIT_MEMO: 'ISSUE_CREDIT_MEMO',
    ISSUE_DEBIT_MEMO: 'ISSUE_DEBIT_MEMO',
    RECEIVE_CREDIT_MEMO: 'RECEIVE_CREDIT_MEMO',
    RECEIVE_DEBIT_MEMO: 'RECEIVE_DEBIT_MEMO',
};

export const getIsCreditMemo = memoType => (
    [memoTypes.ISSUE_CREDIT_MEMO, memoTypes.RECEIVE_CREDIT_MEMO].includes(memoType)
);

export const getIsIssue = memoType => (
    [memoTypes.ISSUE_CREDIT_MEMO, memoTypes.ISSUE_DEBIT_MEMO].includes(memoType)
);

export const getPartnerApi = (memoType) => {
    const basePartnerApi = API.SEARCH.RES_PARTNER_BASE;
    switch (memoType) {
    case memoTypes.ISSUE_CREDIT_MEMO:
    case memoTypes.ISSUE_DEBIT_MEMO:
        return `${basePartnerApi}&isPayor=true&isCustomer=true&searchString=`;
    case memoTypes.RECEIVE_CREDIT_MEMO:
    case memoTypes.RECEIVE_DEBIT_MEMO:
        return `${basePartnerApi}&isSupplier=true&searchString=`;
    default:
        return '';
    }
};

export const getInvoiceTypeByMemoType = (memoType) => {
    switch (memoType) {
    case memoTypes.ISSUE_CREDIT_MEMO: return 'out_refund';
    case memoTypes.ISSUE_DEBIT_MEMO: return 'out_invoice';
    case memoTypes.RECEIVE_CREDIT_MEMO: return 'in_invoice';
    case memoTypes.RECEIVE_DEBIT_MEMO: return 'in_refund';
    default:
        return '';
    }
};

export const getMemoTitle = (memoType) => {
    switch (memoType) {
    case memoTypes.ISSUE_CREDIT_MEMO: return 'Issue Credit Memo';
    case memoTypes.ISSUE_DEBIT_MEMO: return 'Issue Debit Memo';
    case memoTypes.RECEIVE_CREDIT_MEMO: return 'Receive Credit Memo';
    case memoTypes.RECEIVE_DEBIT_MEMO: return 'Receive Debit Memo';
    default:
        return '';
    }
};

export const getMemoDescription = (memoType) => {
    switch (memoType) {
    case memoTypes.ISSUE_CREDIT_MEMO: return 'Issue Credit To Customer';
    case memoTypes.ISSUE_DEBIT_MEMO: return 'Issue Debit To Customer';
    case memoTypes.RECEIVE_CREDIT_MEMO: return 'Receive Debit From Supplier';
    case memoTypes.RECEIVE_DEBIT_MEMO: return 'Receive Credit From Supplier';
    default:
        return 'Memo';
    }
};

export const getAccountDescription = (memoType) => {
    switch (memoType) {
    case memoTypes.ISSUE_CREDIT_MEMO: return 'The amount is credited to accounts payable associated with the payer, select a account for debit';
    case memoTypes.ISSUE_DEBIT_MEMO: return 'The amount is debited from accounts payable associated with the payer, select a account for credit';
    case memoTypes.RECEIVE_CREDIT_MEMO: return 'The amount is credited to accounts payable associated with the supplier, select a account for debit';
    case memoTypes.RECEIVE_DEBIT_MEMO: return 'The amount is debited from accounts payable associated with the supplier, select a account for credit';
    default:
        return 'Memo';
    }
};

export const memoFieldNames = {
    IS_CASH_PURCHASE_OR_SALE: 'isCashPurchaseOrSale',
    CASH_PARTNER_NAME: 'cashPartnerName',
    CASH_PARTNER_ID_NUMBER: 'cashPartnerIdNumber',
    PAYMENT_MEANS: 'paymentMeans',
    CASH_PARTNER_SUB_COMPANY: 'cashPartnerSubCompany',
};

export const paymentMeans = {
    IN_CASH: 'IN_CASH',
    BANK_CARD: 'BANK_CARD',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    STANDING_AGREEMENT: 'STANDING_AGREEMENT',
    CLEARING_BETWEEN_PARTNERS: 'CLEARING_BETWEEN_PARTNERS',
};
