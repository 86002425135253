import { FETCH_WORKFLOW_CONFIG_SUCCESS } from './workflowConfig-actions';

const workflowConfigReducer = (state = {}, action) => {
    let newState;
    switch (action.type) {
    case FETCH_WORKFLOW_CONFIG_SUCCESS:
        newState = action.appConfiguration;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default workflowConfigReducer;
