import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormikCardsForTextField from './FormikCardsForTextField';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
});

class FormikLanguageTab extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            default: 'en',
        };
    }

    handleChange = (value) => {
        this.setState({
            default: value,
        });
    };

    render() {
        const {
            classes,
            containerStyle,
            languageForTabs,
        } = this.props;
        return (
            <div style={containerStyle}>
                <div className={classes.fieldDiv}>
                    <Tabs
                        disableRipple
                        onChange={(e, v) => {
                            this.handleChange(v);
                        }}
                        value={this.state.default}
                        classes={{
                            flexContainer: classes.flexTabs,
                            indicator: classes.tabIndicator,
                        }}
                    >
                        {
                            languageForTabs.map(e => (
                                <FormControlLabel
                                    label=""
                                    control={<Tab value={e.value} label={e.label} />}
                                    key={e.label}
                                    value={e.value}
                                />
                            ))
                        }
                    </Tabs>
                </div>

                <Grid className="mt-1" container justify="space-around" spacing={4}>
                    <Grid item sm={12} md={12} lg={12}>
                        {
                            isObjectValidAndNotEmpty(languageForTabs) &&
                            languageForTabs.map((locale) => {
                                console.log('jhdsflajsdfhlkjasdfh', locale);
                                return (
                                    <FormikCardsForTextField
                                        setPreferred={this.setPreferred}
                                        name={`nameDetails.${locale}`}
                                        key={`${locale.value}`}
                                        hidden={locale.value !== this.state.default}
                                        locale={`nameDetails.${locale.value}`}
                                    />
                                );
                            })
                        }
                    </Grid>
                </Grid>

            </div>
        );
    }
}

FormikLanguageTab.propTypes = {
    classes: PropTypes.object.isRequired,
    containerStyle: PropTypes.object,
    languageForTabs: PropTypes.object.isRequired,
    /* formik props */
    // field: PropTypes.object.isRequired,
    // form: PropTypes.object.isRequired,
};

FormikLanguageTab.defaultProps = {
    containerStyle: {},
};

export default withStyles(styles)(FormikLanguageTab);
