import API from '../../../constants/api';
import { simplifyFilters } from '../../../components/PurchaseOrderComponents/Utils/ConsumablesTabUtil';
import { simplifyOpticalPageFilters } from '../../../components/OpticalProductsTab/OpticalProductsTabUtils';

export const FETCH_CONSUMABLE_LIST_REQUEST = '@@consumables/FETCH_CONSUMABLE_LIST_REQUEST';
export const FETCH_CONSUMABLE_LIST_FAILURE = '@@consumables/FETCH_CONSUMABLE_LIST_FAILURE';
export const FETCH_CONSUMABLE_LIST_SUCCESS = '@@consumables/FETCH_CONSUMABLE_LIST_SUCCESS';

export const CREATE_CONSUMABLE_REQUEST = '@@consumable/CREATE_CONSUMABLE_REQUEST';
export const CREATE_CONSUMABLE_FAILURE = '@@consumable/CREATE_CONSUMABLE_FAILURE';
export const CREATE_CONSUMABLE_SUCCESS = '@@consumable/CREATE_CONSUMABLE_SUCCESS';

export const CLEAR_CONSUMABLE_STATE = '@@product/CLEAR_CONSUMABLE_STATE';

export const fetchPagesOfConsumables = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_CONSUMABLE_LIST_REQUEST,
    // eslint-disable-next-line max-len
    api: `${API.CONSUMABLE.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
        encodeURIComponent(JSON.stringify(simplifyFilters(filters)))}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const fetchPagesOfOpticalProducts = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_CONSUMABLE_LIST_REQUEST,
    // eslint-disable-next-line max-len
    api: `${API.CONSUMABLE.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
        encodeURIComponent(JSON.stringify(simplifyOpticalPageFilters(filters)))}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const createConsumableRequest = (product, successCallback, failureCallBack) => ({
    type: CREATE_CONSUMABLE_REQUEST,
    api: API.CONSUMABLE.CREATE,
    product,
    successCallback,
    failureCallBack,
});

export const clearConsumableState = () => ({
    type: CLEAR_CONSUMABLE_STATE,
});
