import { takeLatest } from 'redux-saga/effects';
import {
    assetAddRequest,
    assetDeleteRequest,
    assetListFetchRequest,
    assetRetireRequest,
    assetViewRequest,
    computeDepreciationBoardRequest, forceDepreciationRequest,
} from './assetWorkerSaga';
import {
    ASSET_ADD_REQUEST,
    ASSET_DELETE_REQUEST,
    ASSET_LIST_FETCH_REQUEST,
    ASSET_RETIRE_REQUEST, ASSET_UNRETIRE_REQUEST, ASSET_UPDATE_LOCATION_REQUEST, ASSET_UPDATE_STATE_REQUEST,
    ASSET_VIEW_REQUEST,
    COMPUTE_DEPRECIATION_BOARD_REQUEST, FORCE_DEPRECIATION_REQUEST,
} from '../../redux/modules/assets/asset-actions';
import { commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';


export function* assetFetchSaga() {
    yield takeLatest(ASSET_LIST_FETCH_REQUEST, assetListFetchRequest);
}

export function* assetAddSaga() {
    yield takeLatest(ASSET_ADD_REQUEST, assetAddRequest);
}

export function* assetViewSaga() {
    yield takeLatest(ASSET_VIEW_REQUEST, assetViewRequest);
}

export function* computeDepreciationBoardSaga() {
    yield takeLatest(COMPUTE_DEPRECIATION_BOARD_REQUEST, computeDepreciationBoardRequest);
}

export function* forceDepreciationSaga() {
    yield takeLatest(FORCE_DEPRECIATION_REQUEST, forceDepreciationRequest);
}

export function* assetDeleteSaga() {
    yield takeLatest(ASSET_DELETE_REQUEST, assetDeleteRequest);
}

export function* assetRetireRequestSaga() {
    yield takeLatest(ASSET_RETIRE_REQUEST, assetRetireRequest);
}

export function* assetUnRetireRequestSaga() {
    yield takeLatest(ASSET_UNRETIRE_REQUEST, commonPostWorkerSaga);
}

export function* assetUpdateStateSaga() {
    yield takeLatest(ASSET_UPDATE_STATE_REQUEST, commonPostWorkerSaga);
}
export function* assetUpdateLocationSaga() {
    yield takeLatest(ASSET_UPDATE_LOCATION_REQUEST, commonPostWorkerSaga);
}
