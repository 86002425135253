import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Close from '@material-ui/icons/Close';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';

import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import { getAccountDtoFromUiObject, getUiObjectFromAccountDto } from '../../mapper/AccountHeadMapper';
import ReduxFormCheckbox from '../FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { createAccountRequest } from '../../redux/modules/accounts/accounts-actions';
import ActionButton from '../ActionButton/ActionButton';
import {
    apiCatchBlockFunction,
    capitalizeFirstLetter,

} from '../../constants/CommonUtil';
import { required } from '../../constants/FormValidations';
import OutlinedTextField from '../OutlinedTextField';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
// import { subCompanies } from '../../constants/ERPConstants';

const drugAddForm = 'accountAddForm';


const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    disabledText: {
        color: '#000000 !important',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '920px',
        marginTop: '1rem',
        overflow: 'visible',
    },
});

class AddNewAccountHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
        };
    }
    componentDidMount() {
        const {
            isUpdate, account, dispatch, isView, selectedAccount,
        } = this.props;
        if (isUpdate || isView) {
            dispatch(initialize(drugAddForm, getUiObjectFromAccountDto(account)));
        } else if (selectedAccount) {
            dispatch(initialize(drugAddForm, getUiObjectFromAccountDto(selectedAccount)));
        } else {
            dispatch(initialize(drugAddForm, { active: true }));
        }
        axios.get(API.SEARCH.ACCOUNT_TYPES)
            .then((response) => {
                this.setState({ types: response.data });
            })
            .catch(error => apiCatchBlockFunction(error, dispatch));
    }

    handleAddAccount = (formValues) => {
        const { dispatch, handleClose } = this.props;
        const accountDto = getAccountDtoFromUiObject(formValues);
        dispatch(createAccountRequest(accountDto, handleClose));
    };

    handleSubCompanySelect = () => {
        const { change } = this.props;
        change('parent', null);
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            header,
            isView,
            subCompany,
        } = this.props;
        const subCompanyName = getStringFromObject('key', subCompany) || '';
        const { types } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit(this.handleAddAccount)}>
                    <Dialog
                        open={open}
                        maxWidth="md"
                        scroll="body"
                        aria-labelledby="form-dialog-title"
                        className={classes.dialog}
                        classes={{
                            paper: classes.paper,
                        }}
                    >
                        <DialogTitle id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>{header}</div>
                                <Close className="cursor-pointer" onClick={() => handleClose(false)} />
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container spacing={16}>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="account-name"
                                        name="name"
                                        component={OutlinedTextField}
                                        label="Account Name"
                                        disabled={isView}
                                        fullWidth
                                        required
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="account-code"
                                        name="code"
                                        component={OutlinedTextField}
                                        disabled={isView}
                                        label="Account Code"
                                        fullWidth
                                        required
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="sub-company"
                                        name="subCompany"
                                        component={ReduxFormReactSelectMaterial}
                                        label="Sub Company"
                                        fullWidth
                                        required
                                        validate={[required]}
                                        isDisabled={isView}
                                        dataSourceApi={API.EMPLOYEE.GET_EMPLOYEE_SUBCOMPANY}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        onSelectHandlers={['handleSubCompanySelect']}
                                        actionHandlers={{
                                            handleSubCompanySelect: this.handleSubCompanySelect,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="parent"
                                        name="parent"
                                        isDisabled={isView || !isObjectValidAndNotEmpty(subCompany)}
                                        component={ReduxFormReactSelectMaterial}
                                        required
                                        validate={[required]}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Parent"
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.VIEW_ACCOUNTS
                                        }?size=40&subCompany=${subCompanyName}&searchString=`}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        test-id="type"
                                        name="type"
                                        component={OutlinedTextField}
                                        select
                                        disabled={isView}
                                        label="Type"
                                        fullWidth
                                        required
                                        validate={[required]}
                                    >
                                        {
                                            types.map(type => (
                                                <MenuItem key={type} value={type}>
                                                    {capitalizeFirstLetter(type)}
                                                </MenuItem>
                                            ))
                                        }
                                    </Field>
                                </Grid>
                                {/* These fields will be same for all the accounts hence removing them */}
                                {/* <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="currency-mode"
                                        name="currencyMode"
                                        disabled={isView}
                                        component={OutlinedTextField}
                                        label="Currency Mode"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="user-type"
                                        name="userType"
                                        isDisabled={isView}
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="User Type"
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.ACCOUNTS_USER_TYPE}`}
                                        required
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        testId="company"
                                        name="company"
                                        isDisabled={isView}
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Company"
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        required
                                        validate={[required]}
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.COMPANY}`}
                                    />
                                </Grid> */}
                                <Grid item lg={6} md={6} sm={6}>
                                    <Field
                                        name="active"
                                        disabled={isView}
                                        component={ReduxFormCheckbox}
                                        label="Active"
                                    />
                                </Grid>
                                {isView && (
                                    <React.Fragment>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Field
                                                name="credit"
                                                component={OutlinedTextField}
                                                disabled
                                                label="Credit"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Field
                                                name="debit"
                                                component={OutlinedTextField}
                                                disabled
                                                label="Debit"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Field
                                                name="balance"
                                                component={OutlinedTextField}
                                                disabled
                                                label="Balance"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </DialogContent>
                        {
                            (!isView) &&
                            <DialogActions style={{ padding: '12px' }}>
                                <ActionButton
                                    primary={false}
                                    disableRipple
                                    onClick={handleClose}
                                >
                                    Cancel
                                </ActionButton>
                                <ActionButton
                                    testId="save"
                                    type="Submit"
                                    disableRipple
                                    onClick={handleSubmit(this.handleAddAccount)}
                                >
                                    Save
                                </ActionButton>
                            </DialogActions>
                        }
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

AddNewAccountHead.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    header: PropTypes.string,
    dispatch: PropTypes.func,
    isUpdate: PropTypes.bool,
    isView: PropTypes.bool,
    account: PropTypes.object,
    subCompany: PropTypes.object,
    change: PropTypes.func,
    selectedAccount: PropTypes.object,
};

AddNewAccountHead.defaultProps = {
    header: 'Add New Account Head',
    dispatch: () => {},
    isUpdate: false,
    isView: false,
    account: {},
    subCompany: {},
    change: () => {},
    selectedAccount: {},
};

const mapStateToProps = (state) => {
    const selector = formValueSelector(drugAddForm);
    return {
        subCompany: selector(state, 'subCompany'),
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: drugAddForm,
})(withStyles(style)(AddNewAccountHead)));
