import React from 'react';
import PropTypes from 'prop-types';
import ReactLinkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
);

const Linkify = props => (
    <ReactLinkify componentDecorator={componentDecorator}>
        {props.children}
    </ReactLinkify>
);

Linkify.propTypes = {
    children: PropTypes.any,
};

Linkify.defaultProps = {
    children: null,
};

export default Linkify;

