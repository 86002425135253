import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const createSearchCriteria = (filters) => {
    let searchConstruct = null;
    if (filters.search) {
        const payerNameCrit = generateObjectWithCriteriaMode('res_partner.name', filters.search, ':');
        const invoiceCriteria = generateObjectWithCriteriaMode('account_invoice.number',
            filters.search, ':');
        searchConstruct = getCombined(payerNameCrit, 'OR', invoiceCriteria);
    }
    return { searchConstruct };
};

export default createSearchCriteria;

export const getUrlParamsFromFilters = (filters, agingInvoicePayerType) => {
    const urlParams = {};
    const searchText = getStringFromObject('searchText', filters);
    if (searchText) {
        urlParams.searchString = searchText;
    }
    const amount = getStringFromObject('amount', filters);
    const operation = getStringFromObject('operation.key', filters);
    if (amount && operation) {
        urlParams.amountValue = amount;
        urlParams.amountOperator = operation;
    }
    if (isObjectValidAndNotEmpty(agingInvoicePayerType)) {
        urlParams.payerTypes = agingInvoicePayerType.key;
    } else {
        urlParams.payerTypes = '';
    }
    return urlParams;
};
