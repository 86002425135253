import API from '../../../constants/api';

export const CREATE_PURCHASE_ORDER_REQUEST = '@@purchaseOrder/CREATE_PURCHASE_ORDER_REQUEST';
export const CREATE_PURCHASE_ORDER_FAILURE = '@@purchaseOrder/CREATE_PURCHASE_ORDER_FAILURE';
export const CREATE_PURCHASE_ORDER_SUCCESS = '@@purchaseOrder/CREATE_PURCHASE_ORDER_SUCCESS';

export const FETCH_PURCHASE_ORDER_REQUEST = '@@purchaseOrder/FETCH_PURCHASE_ORDER_REQUEST';
export const FETCH_PURCHASE_ORDER_FAILURE = '@@purchaseOrder/FETCH_PURCHASE_ORDER_FAILURE';
export const FETCH_PURCHASE_ORDER_SUCCESS = '@@purchaseOrder/FETCH_PURCHASE_ORDER_SUCCESS';

export const CLEAR_SELECTED_PURCHASE_ORDER = '@@purchaseOrder/CLEAR_SELECTED_PURCHASE_ORDER';

export const createPurchaseOrderRequest = (purchaseOrder, successCallback, failureCallBack) => ({
    type: CREATE_PURCHASE_ORDER_REQUEST,
    api: API.PURCHASE_ORDER.CREATE,
    purchaseOrder,
    successCallback,
    failureCallBack,
});

export const fetchPurchaseOrderRequest = (id, successCallback, failureCallBack) => ({
    type: FETCH_PURCHASE_ORDER_REQUEST,
    api: `${API.PURCHASE_ORDER.FETCH_ONE}?id=${id}`,
    successCallback,
    failureCallBack,
});

export const fetchPurchaseOrderByUuidRequest = (uuid, successCallback, failureCallBack) => ({
    type: FETCH_PURCHASE_ORDER_REQUEST,
    api: `${API.PURCHASE_ORDER.FETCH_ONE_BY_UUID}${uuid}`,
    successCallback,
    failureCallBack,
});

export const clearSelectedPurchaseOrder = () => ({
    type: CLEAR_SELECTED_PURCHASE_ORDER,
});
