import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import api from '../../constants/api';
import {
    FETCH_FAVORITES_FOR_DOCTOR_SUCCESS,
    FETCH_FAVORITES_FOR_DOCTOR_FAILURE,
} from '../../redux/modules/favoriteAccounts/favoritesForDoctor-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { getErrorMessage } from '../../constants/CommonUtil';
import { getAccountFavorites, setAccountFavorites } from '../../constants/favoritesStorage';
import { getStringFromObject } from '../../constants/lodashUtils';

export const isFavoritesCachingDisabled = state => (
    !!getStringFromObject('appConfiguration.disableFavoritesCaching', state)
);

const isFavoriteNotPresentInCache = (favoriteDataInCache) => {
    if (favoriteDataInCache === null) return true;
    if (favoriteDataInCache === '') return true;
    if (favoriteDataInCache === undefined) return true;
    return false;
};

export function* favoritesForDoctorWorkerSaga() {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const favoritesCachingDisabled = yield select(isFavoritesCachingDisabled);
        let accountFavorites = getAccountFavorites();
        if (isFavoriteNotPresentInCache(accountFavorites) || favoritesCachingDisabled) {
            const response = yield call(axios.get, `${api.CLINICAL_FAVORITES.FIND_FAV_CONCEPTS_BY_NAME}ACCOUNTS`);
            accountFavorites = response.data;
            setAccountFavorites(accountFavorites);
        }
        yield put({
            type: FETCH_FAVORITES_FOR_DOCTOR_SUCCESS,
            favorites: accountFavorites,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        console.error('error in api call', e);
        yield put({ type: FETCH_FAVORITES_FOR_DOCTOR_FAILURE });
        yield put({
            type: ERROR_MESSAGE,
            message: `Failed to load favorites: ${getErrorMessage(e)}`,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default favoritesForDoctorWorkerSaga;
