export const TOGGLE_QUEUE_SELECT = '@@appConfig/TOGGLE_QUEUE_SELECT';
export const SET_QUEUE = '@@appConfig/SET_QUEUE';
export const SET_REGISTRATION_SERVICE_LOCATION = '@@appConfig/SET_SERVICE_LOCATION';

export const setQueue = (queue, closeDialog) => ({
    type: SET_QUEUE,
    queue,
    closeDialog,
});

export const toggleQueueSelect = closeDialog => ({
    type: TOGGLE_QUEUE_SELECT,
    closeDialog,
});

export const setServiceLocation = (serviceLocation, closeDialog) => ({
    type: SET_REGISTRATION_SERVICE_LOCATION,
    serviceLocation,
    closeDialog,
});
