import API from '../../../constants/api';

export const CREATE_INTERNAL_MOVE_REQUEST = '@@addStock/CREATE_INTERNAL_MOVE_REQUEST';
export const CREATE_INTERNAL_MOVE_FAILURE = '@@addStock/CREATE_INTERNAL_MOVE_FAILURE';
export const CREATE_INTERNAL_MOVE_SUCCESS = '@@addStock/CREATE_INTERNAL_MOVE_SUCCESS';

export const CLEAR_SELECTED_INTERNAL_MOVE_ORDER = '@@addStock/CLEAR_SELECTED_INTERNAL_MOVE_ORDER';

export const FETCH_INTENT_REQUEST = '@@addStock/FETCH_INTENT_REQUEST';
export const FETCH_INTENT_SUCCESS = '@@addStock/FETCH_INTENT_SUCCESS';
export const FETCH_INTENT_FAILURE = '@@addStock/FETCH_INTENT_FAILURE';

export const REVERSE_TRANSFER_REQUEST = '@@addStock/REVERSE_TRANSFER_REQUEST';
export const REVERSE_TRANSFER_SUCCESS = '@@addStock/REVERSE_TRANSFER_SUCCESS';
export const REVERSE_TRANSFER_FAILURE = '@@addStock/REVERSE_TRANSFER_FAILURE';

export const createInternalMoveRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_INTERNAL_MOVE_REQUEST,
    payload,
    api: API.INTERNAL_MOVE.CREATE,
    successCallback,
    failureCallBack,
});

export const fetchIntentData = (uuid, successCallback) => ({
    type: FETCH_INTENT_REQUEST,
    api: `${API.STOCK.GET_INTENT}${uuid}`,
    successCallback,
});

export const reverseTransferRequest = (payload, successCallback, failureCallBack) => ({
    type: REVERSE_TRANSFER_REQUEST,
    payload,
    api: API.INTERNAL_MOVE.REVERSE,
    successCallback,
    failureCallBack,
});
