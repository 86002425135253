import { takeLatest } from 'redux-saga/effects';

import {
    CREATE_PAYMENT_ADVICE_ALL_REQUEST,
    CREATE_PAYMENT_ADVICE_REQUEST,
    DELETE_CONTRACT_REQUEST,
    FETCH_EMP_BY_DEPT_REQUEST,
    FETCH_EMP_PAYSLIP_INPUT_REQUEST,
    FETCH_PAYMENT_ADVICE_LIST_REQUEST,
    FETCH_PAYMENT_ADVICE_REQUEST,
    FETCH_PAYROLL_PAYSLIP_REQUEST,
    FETCH_PAYROLL_PERIODS_REQUEST,
    FETCH_PAYROLL_REPORT_REQUEST,
    FETCH_PAYSLIP_BATCH_DETAIL_REQUEST,
    FETCH_PAYSLIP_BATCHES_REQUEST,
    FETCH_PAYSLIP_REQUEST,
    FETCH_SALARY_COMPUTATION_REQUEST,
    GENERATE_PAYMENT_ADVICE_REQUEST,
    REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_REQUEST,
    SAVE_EMP_PAYSLIP_INPUT_REQUEST,
    SAVE_PAYMENT_ADVICE_REQUEST,
    SAVE_PAYSLIP_BATCH_REQUEST,
    SEARCH_EMP_BY_DEPT_REQUEST,
    VERIFY_PAYMENT_BATCH_REQUEST,
} from '../../redux/modules/payroll/payroll-actions';
import {
    fetchPaymentAdviceListRequest,
    fetchPaymentAdviceWorker,
    fetchPayrollPayslipRequest,
    fetchPayrollReportRequest,
    fetchPayslipBatchesWorker,
    savePaymentAdviceWorker,
    savePayslipInputWorker,
    searchEmpByDeptRequest,
} from './payrollWorkerSaga';
import { commonDeleteWorkerSaga, commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* fetchEmpByDeptSaga() {
    yield takeLatest(FETCH_EMP_BY_DEPT_REQUEST, commonGetWorkerSaga);
}

export function* fetchOpenPayrollPeriodsSaga() {
    yield takeLatest(FETCH_PAYROLL_PERIODS_REQUEST, commonGetWorkerSaga);
}

export function* createPaymentAdviceSaga() {
    yield takeLatest(CREATE_PAYMENT_ADVICE_REQUEST, commonGetWorkerSaga);
}

export function* deleteContractSaga() {
    yield takeLatest(DELETE_CONTRACT_REQUEST, commonDeleteWorkerSaga);
}

export function* createPaymentAdviceAllSaga() {
    yield takeLatest(CREATE_PAYMENT_ADVICE_ALL_REQUEST, commonPostWorkerSaga);
}

export function* fetchPayslipSaga() {
    yield takeLatest(FETCH_PAYSLIP_REQUEST, commonGetWorkerSaga);
}

export function* fetchSalaryComputationSaga() {
    yield takeLatest(FETCH_SALARY_COMPUTATION_REQUEST, commonPostWorkerSaga);
}

export function* searchEmpByDeptSaga() {
    yield takeLatest(SEARCH_EMP_BY_DEPT_REQUEST, searchEmpByDeptRequest);
}

export function* fetchEmpPayslipInputSaga() {
    yield takeLatest(FETCH_EMP_PAYSLIP_INPUT_REQUEST, commonGetWorkerSaga);
}

export function* savePayslipBatchSaga() {
    yield takeLatest(SAVE_PAYSLIP_BATCH_REQUEST, commonPostWorkerSaga);
}

export function* fetchPayslipBatchesSaga() {
    yield takeLatest(FETCH_PAYSLIP_BATCHES_REQUEST, fetchPayslipBatchesWorker);
}

export function* fetchPayslipBatchDetailSaga() {
    yield takeLatest(FETCH_PAYSLIP_BATCH_DETAIL_REQUEST, commonGetWorkerSaga);
}

export function* savePayslipInputSaga() {
    yield takeLatest(SAVE_EMP_PAYSLIP_INPUT_REQUEST, savePayslipInputWorker);
}

export function* generatePaymentAdviceSaga() {
    yield takeLatest(GENERATE_PAYMENT_ADVICE_REQUEST, commonPostWorkerSaga);
}

export function* verifyPaymentBatchSaga() {
    yield takeLatest(VERIFY_PAYMENT_BATCH_REQUEST, commonPostWorkerSaga);
}

export function* fetchPaymentAdviceListSaga() {
    yield takeLatest(FETCH_PAYMENT_ADVICE_LIST_REQUEST, fetchPaymentAdviceListRequest);
}

export function* fetchPaymentAdviceSaga() {
    yield takeLatest(FETCH_PAYMENT_ADVICE_REQUEST, fetchPaymentAdviceWorker);
}
export function* removePayslipsFromAdviceSaga() {
    yield takeLatest(REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_REQUEST, commonGetWorkerSaga);
}

export function* savePaymentAdviceSaga() {
    yield takeLatest(SAVE_PAYMENT_ADVICE_REQUEST, savePaymentAdviceWorker);
}

export function* fetchPaymentPayslipSaga() {
    yield takeLatest(FETCH_PAYROLL_PAYSLIP_REQUEST, fetchPayrollPayslipRequest);
}

export function* fetchPayrollReportSaga() {
    yield takeLatest(FETCH_PAYROLL_REPORT_REQUEST, fetchPayrollReportRequest);
}
