export const INBOX_FETCH_REQUEST = '@@inbox/INBOX_FETCH_REQUEST';
export const INBOX_FETCH_SUCCESS = '@@inbox/INBOX_FETCH_SUCCESS';
export const INBOX_FETCH_FAILURE = '@@inbox/INBOX_FETCH_FAILURE';

export const getInboxMessages = (api, inboxType, page, size) => ({
    type: INBOX_FETCH_REQUEST,
    api,
    inboxType,
    page,
    size,
});
