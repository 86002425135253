/**
 * Created by vinay on 19/12/18.
 */
import cloneDeep from 'clone-deep';
import initialStates from '../../initialStates';
import {
    FETCH_SLOT_TEMPLATE_LIST_SUCCESS,
    FETCH_SLOT_TEMPLATE_SUCCESS,
    RESET_SELECTED_TEMPLATE,
    UPDATE_TEMPLATE_LIST_ROW,
    UPDATE_TEMPLATE_SUCCESS,
} from './appointmentSlotTemplate-actions';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const templateReducer = (state = initialStates.slotTemplate, action) => {
    let newState = {};
    let list = [];
    switch (action.type) {
    case FETCH_SLOT_TEMPLATE_LIST_SUCCESS:
        newState = Object.assign({}, state, { list: action.data });
        break;
    case FETCH_SLOT_TEMPLATE_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case UPDATE_TEMPLATE_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case RESET_SELECTED_TEMPLATE:
        newState = Object.assign({}, state, { selected: {} });
        break;
    case UPDATE_TEMPLATE_LIST_ROW:
        list = cloneDeep(getStringFromObject('list', state, []));
        if (isArrayValidAndNotEmpty(list)) {
            list.splice(action.index, 1, action.payload);
        }
        list.splice(action.index, 1, action.payload);
        newState = Object.assign({}, state, {
            list,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default templateReducer;
