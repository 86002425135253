import { ADD_PETTY_CASH_SUCCESS, CLEAR_SELECTED_PETTY_CASH, FETCH_PETTY_CASH_SUCCESS } from './pettyCash-actions';
import initialStates from '../../initialStates';

const createPettyCashReducer = (state = initialStates.pettyCash, action) => {
    let newState = {};
    switch (action.type) {
    case ADD_PETTY_CASH_SUCCESS:
    case FETCH_PETTY_CASH_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_SELECTED_PETTY_CASH:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default createPettyCashReducer;
