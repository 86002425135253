import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import Grid from '@material-ui/core/Grid/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DialogComponent from '../../components/DialogComponent/DialogComponent';
import ActionButton from '../../components/ActionButton/ActionButton';
import { required } from '../../constants/FormValidations';
import { addOrSubtractMonth } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const DateInput = ({
    field: {   // eslint-disable-line
        name, value, onBlur,
    },
    form: { touched, errors, setFieldValue },  // eslint-disable-line
    views,  // eslint-disable-line
}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            name={name}
            value={new Date(value)}
            onChange={v => setFieldValue(name, v, true)}
            onBlur={onBlur}
            error={touched[name] && errors[name]}
            helperText={errors[name]}
            views={views}
            openTo="year"
            maxDate={addOrSubtractMonth(new Date(), 1, '-')}
        />
    </MuiPickersUtilsProvider>
);

const AttendanceUploadDialog = (props) => {
    const {
        handleClose,
        submitForm,
    } = props;
    console.log('andjasfgahgdsj', props);
    return (
        <DialogComponent
            open
            header="Select Month & Year to upload Attendance"
            handleClose={handleClose}
            dialogActions={
                <React.Fragment>
                    <ActionButton
                        onClick={submitForm}
                        testId="import"
                    >
                        Import CSV
                    </ActionButton>
                </React.Fragment>
            }
        >
            <form>
                <Grid container>
                    <Grid item>
                        <Field
                            name="month"
                            component={DateInput}
                            views={['year', 'month']}
                            validate={required}
                        />
                    </Grid>
                </Grid>
            </form>
        </DialogComponent>
    );
};

const handleSubmitForm = (values, { props }) => {
    props.handleSelectMonth(getStringFromObject('month', values, null));
};

AttendanceUploadDialog.propTypes = {
    submitForm: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    // handleSelectMonth: PropTypes.func.isRequired,
};

AttendanceUploadDialog.defaultProps = {};

export default withFormik({
    mapPropsToValues: () => ({ month: addOrSubtractMonth(new Date(), 1, '-') }),
    validateOnBlur: true,
    validateOnChange: false,
    handleSubmit: handleSubmitForm,
    displayName: 'attendanceUploadMonthly',
})(AttendanceUploadDialog);
