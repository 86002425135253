import initialStates from '../../initialStates';
import {
    CLEAR_CASH_PAYMENT_VOUCHER,
    CLEAR_DEBIT_AND_CREDIT_LINES,
    FETCH_CASH_PAYMENT_VOUCHER_FAILURE,
    FETCH_CASH_PAYMENT_VOUCHER_SUCCESS,
    FETCH_DEBIT_AND_CREDIT_LINES_FAILURE,
    FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS,
} from './cashPaymentVoucher-actions';

export const debitAndCreditLinesReducer = (state = initialStates.debitAndCreditLines, action) => {
    let newState;
    switch (action.type) {
    case FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS:
        newState = {
            ...(action.data || {}),
        };
        break;
    case FETCH_DEBIT_AND_CREDIT_LINES_FAILURE:
    case CLEAR_DEBIT_AND_CREDIT_LINES:
        newState = { ...initialStates.debitAndCreditLines };
        break;
    default:
        newState = state;
    }
    return newState;
};

export const cashPaymentVoucherReducer = (state = initialStates.cashPaymentVoucher, action) => {
    let newState;
    switch (action.type) {
    case FETCH_CASH_PAYMENT_VOUCHER_SUCCESS:
        newState = {
            ...(action.data || {}),
        };
        break;
    case CLEAR_CASH_PAYMENT_VOUCHER:
    case FETCH_CASH_PAYMENT_VOUCHER_FAILURE:
        newState = { ...initialStates.cashPaymentVoucher };
        break;
    default:
        newState = state;
    }
    return newState;
};
