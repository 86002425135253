/**
 * Created by vinay on 1/4/19.
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { fetchAttendanceRequest } from '../../redux/modules/attendance/attendance-actions';
import {
    isArrayValidAndNotEmpty,


} from '../../constants/CommonUtil';
import API from '../../constants/api';
import { formatDateTimeForDisplay, formatTimeForDisplay, getDateObjectFromTimeString } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const ATTENDANCE_DEFAULT_SORT_COL = 'approvedAttendance.id';

export const fetchAttendanceListFromServer = (dispatch, page, size, sortCol, sortOrder, filters, successCallback) => {
    dispatch(
        fetchAttendanceRequest(page, size, sortCol, sortOrder, filters, successCallback));
};

export const getStatus = (status) => {
    switch (status) {
    case 'P': return 'Present';
    case 'A': return 'Absent';
    case 'OT': return 'Overtime';
    case 'L': return 'Leave';
    case 'H': return 'Holiday';
    case 'SH': return 'Shortage';
    case 'U': return 'Unparsable';
    default: return status;
    }
};

export const convertIntoTimeFormat = (minutes) => {
    const min = NumberOf(minutes);
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    return `${hours.toString().padStart(2, '0')}:${minFraction.toString().padStart(2, '0')}`;
};

export const convertIntoDate = (minutes) => {
    const min = NumberOf(minutes);
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minFraction);
    return now.getTime();
};

export const getMinutesFromDate = (date) => {
    if (NumberOf(date)) {
        const d = new Date(date);
        return ((d.getHours() * 60) + d.getMinutes());
    }
    return 0;
};

export const APPROVED_ATTENDANCE_LIST_FILTERS = [
    {
        name: 'employee',
        label: 'Employee',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?ignoreRetired=true&name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'manager',
        label: 'Reporting Manager',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'department',
        label: 'Department',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.DEPARTMENT.AUTOSUGGEST_WITH_ALL,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const getSecondsFromTimeString = (time) => {
    if (time) {
        const split = time.split(':');
        return (NumberOf(split[0]) * 3600) + (NumberOf(split[1]) * 60) + NumberOf(split[2]);
    }
    return 0;
};

const getSecondsFromEpochTime = (time) => {
    if (time) {
        const date = new Date(time);
        return (date.getHours() * 3600) + (date.getMinutes() * 60) + date.getSeconds();
    }
    return 0;
};

const displayTimeDiff = (seconds) => {
    const min = Math.abs(NumberOf(seconds) / 60);
    if (min <= 0) {
        return '';
    }
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    if (hours > 0) {
        return `${hours.toString()} h ${minFraction.toString()} m`;
    }
    return `${minFraction.toString()} m`;
};

const getDifference = (startTime, endTime, highlightPositive = true) => {
    const diff = getSecondsFromEpochTime(endTime) -
        getSecondsFromEpochTime(getDateObjectFromTimeString(startTime));
    if (diff > 0 && highlightPositive) {
        return <span style={{ color: 'red' }}>+{displayTimeDiff(diff)}</span>;
    } else if (diff < 0 && !highlightPositive) {
        return <span style={{ color: 'red' }}>-{displayTimeDiff(diff)}</span>;
    }
    return diff === 0 ? '' : diff;
};

export const getPunchActions = (template, punches) => {
    let actions = {};
    if (isObjectValidAndNotEmpty(template)) {
        const startTime = getStringFromObject('startTime', template);
        const endTime = getStringFromObject('endTime', template);
        const breakTime = getStringFromObject('breakTime', template);
        const resumeTime = getStringFromObject('resumeTime', template);
        if (startTime) {
            actions = {
                startTime: formatTimeForDisplay(getDateObjectFromTimeString(startTime)),
            };
        }
        if (resumeTime) {
            actions = {
                ...actions,
                resumeTime: formatTimeForDisplay(getDateObjectFromTimeString(resumeTime)),
            };
        }
        if (endTime) {
            actions = {
                ...actions,
                endTime: formatTimeForDisplay(getDateObjectFromTimeString(endTime)),
            };
        }
        if (breakTime) {
            actions = {
                ...actions,
                breakTime: formatTimeForDisplay(getDateObjectFromTimeString(breakTime)),
            };
        }
        if (isArrayValidAndNotEmpty(punches)) {
            const punch = getStringFromObject('[0]', punches);
            const pSignIn = NumberOf(getStringFromObject('signIn', punch));
            const pBreak = NumberOf(getStringFromObject('breakTime', punch));
            const pResume = NumberOf(getStringFromObject('resumeTime', punch));
            const pSignOut = NumberOf(getStringFromObject('signOut', punch));
            if (startTime && pSignIn) {
                actions = {
                    ...actions,
                    startPunchDiff: getDifference(
                        startTime, pSignIn),
                };
            }
            if (resumeTime && pResume) {
                actions = {
                    ...actions,
                    resumePunchDiff: getDifference(
                        resumeTime, pResume),
                };
            }
            if (breakTime && pBreak) {
                actions = {
                    ...actions,
                    breakPunchDiff: getDifference(
                        breakTime, pBreak, false),
                };
            }
            if (endTime && pSignOut) {
                actions = {
                    ...actions,
                    endPunchDiff: getDifference(
                        endTime, pSignOut, false),
                };
            }
        }
    }
    return actions;
};

export const getLogsTable = (log) => {
    if (isArrayValidAndNotEmpty(log)) {
        return log.map((anAttendanceLog, index) => (
            <Grid container justify="space-between" key={anAttendanceLog.uuid}>
                <Grid item lg={5} sm={5} md={5}>
                    {index + 1}. {anAttendanceLog.action}
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                    &nbsp;:&nbsp;
                </Grid>
                <Grid item lg={6} sm={6} md={6}>
                    {
                        anAttendanceLog.name ? (
                            formatDateTimeForDisplay(new Date(anAttendanceLog.name))
                        ) : (
                            'Time not recorded'
                        )
                    }
                </Grid>
            </Grid>
        ));
    }
    return (
        <Grid container justify="center">
            <Grid item>
                No logs recorded.
            </Grid>
        </Grid>
    );
};
