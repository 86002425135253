import API from '../../../constants/api';

export const FETCH_ANALYTICAL_ACCOUNTS_REQUEST = '@@accounts/FETCH_ANALYTICAL_ACCOUNTS_REQUEST';
export const FETCH_ANALYTICAL_ACCOUNTS_FAILURE = '@@accounts/FETCH_ANALYTICAL_ACCOUNTS_FAILURE';
export const FETCH_ANALYTICAL_ACCOUNTS_SUCCESS = '@@accounts/FETCH_ANALYTICAL_ACCOUNTS_SUCCESS';

export const CREATE_ANALYTICAL_ACCOUNT_REQUEST = '@@accounts/CREATE_ANALYTICAL_ACCOUNT_REQUEST';
export const CREATE_ANALYTICAL_ACCOUNTS_FAILURE = '@@accounts/CREATE_ANALYTICAL_ACCOUNTS_FAILURE';
export const CREATE_ANALYTICAL_ACCOUNTS_SUCCESS = '@@accounts/CREATE_ANALYTICAL_ACCOUNTS_SUCCESS';

export const ADJUST_BALANCE_REQUEST = '@@accounts/ADJUST_BALANCE_REQUEST';
export const ADJUST_BALANCE_FAILURE = '@@accounts/ADJUST_BALANCE_FAILURE';
export const ADJUST_BALANCE_SUCCESS = '@@accounts/ADJUST_BALANCE_SUCCESS';

export const CLEAR_ANALYTICAL_ACCOUNTS_STATE = '@@accounts/CLEAR_ANALYTICAL_ACCOUNTS_STATE';

export const fetchPagesOfAnalyticalAccounts = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_ANALYTICAL_ACCOUNTS_REQUEST,
    api: `${API.ANALYTICAL_ACCOUNTS.LIST}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
        encodeURIComponent(JSON.stringify(filters))}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const adjustBalanceRequest = (account, credit, debit, reason, successCallback, failureCallBack) => ({
    type: ADJUST_BALANCE_REQUEST,
    api: API.ACCOUNT_HEAD.UPDATE_BALANCE,
    account,
    credit,
    debit,
    reason,
    successCallback,
    failureCallBack,
});

export const createAnalyticalAccountRequest = (account, successCallback, failureCallBack) => ({
    type: CREATE_ANALYTICAL_ACCOUNT_REQUEST,
    api: API.ANALYTICAL_ACCOUNTS.CREATE_OR_UPDATE,
    account,
    successCallback,
    failureCallBack,
});

export const clearAnalyticalAccountState = () => ({
    type: CLEAR_ANALYTICAL_ACCOUNTS_STATE,
});
