import API from '../../../constants/api';
import { JSONToken, persistPrivileges, persistState } from '../../../constants/state';

export const CHECK_SESSION_REQUEST = '@@Login/CHECK_SESSION_REQUEST';
export const VALIDATE_SESSION_REQUEST = '@@Login/VALIDATE_SESSION_REQUEST';

export const LOGIN_SILENT_REQUEST = '@@Login/LOGIN_SILENT_REQUEST';
export const LOGIN_SILENT_SUCCESS = '@@Login/LOGIN_SILENT_SUCCESS';
export const LOGIN_SILENT_FAILURE = '@@Login/LOGIN_SILENT_FAILURE';

export const LOGIN_REQUEST = '@@Login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@Login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@Login/LOGIN_FAILURE';

export const SESSION_TIMEOUT = '@@Login/SESSION_TIMEOUT';

export const CLEAR_LOGIN_STATE = '@@Login/CLEAR_LOGIN_STATE';

export const CLEAR_CACHE_LOGIN_STATE = '@@Login/CLEAR_CACHE_LOGIN_STATE';

export const CLEAR_RETRY_DATA = '@@Login/CLEAR_RETRY_DATA';

export const MISMATCH_IN_VERSION = '@@Login/MISMATCH_IN_VERSION';

export const sessionTimedOut = functionToCallAfterLogin => ({
    type: SESSION_TIMEOUT,
    functionToCallAfterLogin,
});

export const persistLoginToState = (data) => {
    const state = {
        isLoggedIn: true,
        login: data,
    };
    console.log('loginn successs data', data);
    persistState(state);
    persistPrivileges(data.privileges);
    return state;
};

export const loginSuccess = (data) => {
    const state = persistLoginToState(data);
    document.cookie = `${JSONToken}=${data.jsontoken};path=/`;
    return {
        type: LOGIN_SUCCESS,
        state,
    };
};

export const loginRequest = loginDetails => ({
    type: LOGIN_REQUEST,
    api: API.LOGIN.AUTHENTICATE,
    loginDetails,
});

export const loginSilentRequest = (loginDetails, actionToDispatchAfterLogin) => ({
    type: LOGIN_SILENT_REQUEST,
    api: API.LOGIN.AUTHENTICATE,
    loginDetails,
    actionToDispatchAfterLogin,
});

export const validateSessionRequest = redirectToLink => ({
    type: VALIDATE_SESSION_REQUEST,
    api: API.LOGIN.SESSION,
    redirectToLink,
});

export const loginFailure = api => ({
    type: LOGIN_FAILURE,
    api,
});

export const logoutRequest = () => ({
    type: CLEAR_LOGIN_STATE,
});


export const clearCacheAndLogout = () => ({
    type: CLEAR_CACHE_LOGIN_STATE,
});

export const mismatchInVersion = () => ({
    type: MISMATCH_IN_VERSION,
});
