import API from '../../../constants/api';

export const FETCH_MEDICAL_PACKAGE_LIST_REQUEST = '@@medicalPackages/MEDICAL_PACKAGE_FETCH_LIST_REQUEST';
export const FETCH_MEDICAL_PACKAGE_LIST_FAILURE = '@@medicalPackages/MEDICAL_PACKAGE_FETCH_LIST_FAILURE';
export const FETCH_MEDICAL_PACKAGE_LIST_SUCCESS = '@@medicalPackages/MEDICAL_PACKAGE_FETCH_LIST_SUCCESS';

export const fetchMedicalPackagesList = (page, size, searchString = '') => ({
    type: FETCH_MEDICAL_PACKAGE_LIST_REQUEST,
    api: `${API.MEDICAL_PACKAGES.PAGES}?page=${page}&size=${size}&searchString=${searchString}`,
});

