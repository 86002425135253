import axios from 'axios';
import {
    apiCatchBlockFunction, getJsonPath,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,
    isJsonString,


} from '../../../constants/CommonUtil';
import { addOrSubtractMonth, getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import API from '../../../constants/api';
import { insuranceSchemeFieldNames } from '../AddInsuranceScheme/AddInsuranceSchemeUtil';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { APPLICATION_CONFIG_URL, IMPLEMENTATION_LOCATIONS } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const insurancePayerTypes = {
    COMPANY: {
        value: 'COMPANY',
        label: 'Company',
    },
    INSURANCE: {
        value: 'INSURANCE',
        label: 'Insurance',
    },
    ASSOCIATION: {
        value: 'ASSOCIATION',
        label: 'Association',
    },
    POLICY: {
        value: 'POLICY',
        label: 'Policy',
    },
    SUBINSURANCE: {
        value: 'SUBINSURANCE',
        label: 'Sub Insurer',
    },
    CORPORATE: {
        value: 'CORPORATE',
        label: 'Corporate',
    },
};

export const payerTypes = [
    insurancePayerTypes.COMPANY,
    insurancePayerTypes.INSURANCE,
    insurancePayerTypes.ASSOCIATION,
    insurancePayerTypes.CORPORATE,
];

export const payerFormFields = {
    NAME: 'name',
    CODE: 'code',
    NUMBER: 'number',
    VAT: 'vat',
    TIN: 'tin',
    ACTIVE: 'active',
    TYPE: 'type',
    SUBCOMPANY: 'subCompany',
    PARENT_PAYER_NAME: 'parentPayorName',
    SUB_INSURANCE: 'subInsurance',
    CREDIT_LIMIT: 'mCreditLimit',
    PHARMACY_DISCOUNT: 'pharmacyDiscount',
    EFFECTIVE_DATE: 'effectiveDate',
    EXPIRY_DATE: 'expiryDate',
    ICD_CODE: 'mIcdCode',
    VITAL_SIGNS: 'vitalSigns',
    COVER_ALL_DRUGS: 'coverAllDrugs',
    ONLY_DUMMY_SCHEMES: 'onlyDummySchemes',
    EBP_SUPPORTED: 'ebpSupported',
    ALL_DRUGS_NEED_APPROVAL: 'mandatoryPharmacyApproval',
    MERGE_CLAIMS_FOR_PUSH: 'mergeClaimsForPush',
    NEED_APPROVAL: 'needApprovalNo',
    VERIFICATION_CODE: 'verificationCode',
    DIRECT_DEDUCTIBLE: 'directDeductable',
    MANDATORY_SCAN: 'mandatoryScan',
    BLOCK_DEDUCTIBLE_BILL_PRINT: 'blockDeductableBillPrint',
    BLOCK_NEW_VISIT: 'blockNewVisit',
    UCAF: 'ucaf',
    INSURANCE_CATEGORY_PRICING_DETAIL: 'insuranceCategoryPricingDetailDto',
    // new for policy
    PRE_APPROVAL_LIMIT: 'preApprovalLimit', // number
    FOLLOWUP_DURATION_DAYS: 'followUpDurationDays', // number
    FOLLOWUP_DURATION_DENTAL: 'followUpDurationDental',
    DEDUCT_FULL_DEDUCTIBLE: 'collectFullDeductible',
    MAX_FOLLOW_UP_IN_EOC: 'maxFollowUpVisitsInEpisode',
    MAX_DURATION_OF_DRUG_ORDER_WITHOUT_APPROVAL:
        'maxDurationOfDrugorderWithoutApproval',

    REFERRAL_GENERAL_CONSULTATION_COVERED: 'referralConsultationGeneralCovered',
    REFERRAL_CONSULTATION_CONSULTANT_COVERED: 'referralConsultationConsultantCovered',
    REFERRAL_CONSULTATION_SPECIALIST_COVERED: 'referralConsultationSpecialistCovered',
    DIRECT_SPECIALIST_CONSULTATION_ALLOWED: 'directSpecialistConsultationAllowed',
    DIRECT_CONSULTANT_CONSULTATION_ALLOWED: 'directConsultantConsultationAllowed',
    PRICE_EDITABLE: 'priceEditable',
    PRICE_EDITABLE_FOR_ZERO_PRICE: 'priceEditableForZeroPrice',

    WARNING_MESSAGE: 'warningMessage',
    WARNING_MESSAGE_FOR_DOC: 'warningMessageForDoc',
    WARNING_MESSAGE_FOR_PHARMACIST: 'warningMessageForPharmacist',
    SALESMAN: 'salesMan',

    CSV_TEMPLATE: 'csvTemplateId',
    RECEIVER_CODE: 'receiverCode',
    CLIENT_PREFIX: 'clientPrefix',
    ACCOUNT_RECEIVABLE: 'accReceivables',
    ACCOUNT_PAYABLE: 'accPayables',
    ADDRESS_DTO_LIST: 'addressDtoList',
    PAYER_NAME_IN_LOCAL_LANG: 'nameInLocalLang',
    NAME_WITH_INTEGRATOR: 'nameWithIntegrator',
    INSURANCE_PAYER_DOCUMENT_DTO_LIST: 'insurancePayerDocumentDtoList',
    CR_NUMBER: 'crNumber',
    INSURANCE_PROVIDER_CODE: 'insuranceProviderCode',
    DISPLAY_ONLY_COVERED_SERVICES: 'displayOnlyCoveredServices',
    VALIDATIONS: 'validations',
    PAYER_FORMULARY_RESTRICTIONS: 'payerFormularyRestrictions',
    SUB_INSURER_VALIDATIONS: 'subInsurerValidations',
    SAVE_METADATA_AT_PATIENT_LEVEL: 'saveMetadataAtPatientLevel',
    EXEMPT_PAYER_FROM_TAX: 'exemptPayerFromTax',
    MIN_QUANTITY_FOR_PHARMACY_SALE: 'minQuantityForPharmacySale',
};

export const policyNumericFields = {
    [payerFormFields.CREDIT_LIMIT]: 'Monthly Credit Limit',
    [payerFormFields.FOLLOWUP_DURATION_DAYS]: 'Follow Up Duration',
    [payerFormFields.FOLLOWUP_DURATION_DENTAL]: 'Follow Up Duration(Dental)',
    [payerFormFields.MAX_FOLLOW_UP_IN_EOC]: 'Max Followups In Episode',
};

export const policyBooleanFields = {
    [payerFormFields.ACTIVE]: 'Active',
    [payerFormFields.DEDUCT_FULL_DEDUCTIBLE]: 'Deduct Full Deductible',
    [payerFormFields.REFERRAL_GENERAL_CONSULTATION_COVERED]: 'General Referral Consultation Covered',
    [payerFormFields.REFERRAL_CONSULTATION_SPECIALIST_COVERED]: 'Specialist Referral Consultation Covered',
    [payerFormFields.REFERRAL_CONSULTATION_CONSULTANT_COVERED]: 'Consultant Referral Consultation Covered',
    [payerFormFields.DIRECT_SPECIALIST_CONSULTATION_ALLOWED]: 'Direct Specialist Consultation Allowed',
    [payerFormFields.DIRECT_CONSULTANT_CONSULTATION_ALLOWED]: 'Direct Consultant Consultation Allowed',
    [payerFormFields.PRICE_EDITABLE]: 'Price Editable',
    [payerFormFields.PRICE_EDITABLE_FOR_ZERO_PRICE]: 'Editing allowed only for zero price',
    [payerFormFields.ONLY_DUMMY_SCHEMES]: 'Only Dummy Schemes',
};


export const getFormValuesFromPayerDetails = (props) => {
    const {
        subInsurance,
        parentPayor,
        policy,
    } = props;
    const values = { ...props.selectedPayor };
    if (subInsurance) {
        values.parentPayorName = `${getStringFromObject('name', parentPayor, '')} - ` +
            `${getStringFromObject('code', parentPayor, '')}`;
    }
    if (policy) {
        values.subInsurance = { ...parentPayor };
    }
    if (values[payerFormFields.EFFECTIVE_DATE]) {
        values[payerFormFields.EFFECTIVE_DATE] =
            getDateInYYYYMMDDFormat(new Date(values[payerFormFields.EFFECTIVE_DATE]));
    }
    if (values[payerFormFields.EXPIRY_DATE]) {
        values[payerFormFields.EXPIRY_DATE] =
            getDateInYYYYMMDDFormat(new Date(values[payerFormFields.EXPIRY_DATE]));
    }
    if (values[payerFormFields.CSV_TEMPLATE]) {
        values[payerFormFields.CSV_TEMPLATE] = {
            key: values[payerFormFields.CSV_TEMPLATE],
            value: values[payerFormFields.CSV_TEMPLATE],
        };
    }
    if (values[payerFormFields.SUBCOMPANY]) {
        values[payerFormFields.SUBCOMPANY] = {
            text: values[payerFormFields.SUBCOMPANY],
            value: values[payerFormFields.SUBCOMPANY],
        };
    }
    if (isArrayValidAndNotEmpty(values[payerFormFields.SUB_INSURER_VALIDATIONS])) {
        values[payerFormFields.SUB_INSURER_VALIDATIONS] = values[payerFormFields.SUB_INSURER_VALIDATIONS].map(option => ({ key: option, value: option }));
    }
    console.log('asd-a0id-said-a', policy, props.selectedPayor);
    return values;
};

export const isPayerTypeCompanyOrInsurance = payerType => (
    [insurancePayerTypes.ASSOCIATION.value, insurancePayerTypes.COMPANY.value, insurancePayerTypes.CORPORATE.value].includes(payerType)
);

export const getSubmissionDataFromPayerFormValues = (
    formValues,
    props,
    diagnosisApproval,
    diagnosisExcluded,
) => {
    const {
        payorUuid,
        subInsurance,
        parentPayor,
        policy,
    } = props;
    const payload = {
        ...formValues,
    };
    if (payload[payerFormFields.SUBCOMPANY]) {
        payload[payerFormFields.SUBCOMPANY] = getStringFromObject('value', payload[payerFormFields.SUBCOMPANY]);
    }
    const payerType = getStringFromObject(payerFormFields.TYPE, formValues);
    if (payload[payerFormFields.EXPIRY_DATE]) {
        payload[payerFormFields.EXPIRY_DATE] = new Date(payload[payerFormFields.EXPIRY_DATE]).getTime();
    }
    if (payload[payerFormFields.EFFECTIVE_DATE]) {
        payload[payerFormFields.EFFECTIVE_DATE] = new Date(payload[payerFormFields.EFFECTIVE_DATE]).getTime();
    }
    if (!payload[payerFormFields.EFFECTIVE_DATE] && payload[payerFormFields.EXPIRY_DATE]) {
        // 1 year before expiry is effective date
        payload[payerFormFields.EFFECTIVE_DATE] =
            addOrSubtractMonth(new Date(payload[payerFormFields.EXPIRY_DATE]), 12, '-').getTime();
    }
    if (payorUuid) {
        payload.payerUuid = payorUuid;
    }
    if (subInsurance) {
        payload.parentPayer = {
            key: getStringFromObject('payerUuid', parentPayor, ''),
            value: getStringFromObject('name', parentPayor, ''),
        };
        payload.type = insurancePayerTypes.SUBINSURANCE.value;
    }
    if (policy) {
        payload.parentPayer = getStringFromObject('subInsurance', formValues, {});
        payload.type = insurancePayerTypes.POLICY.value;
    }

    const csvTemplate = formValues[payerFormFields.CSV_TEMPLATE];
    if (isObjectValidAndNotEmpty(csvTemplate)) {
        payload[payerFormFields.CSV_TEMPLATE] = getStringFromObject('key', csvTemplate);
    } else {
        payload[payerFormFields.CSV_TEMPLATE] = null;
    }

    payload[payerFormFields.ACCOUNT_RECEIVABLE] =
        isObjectValidAndNotEmpty(payload[payerFormFields.ACCOUNT_RECEIVABLE]) ?
            {
                key: getStringFromObject(`${payerFormFields.ACCOUNT_RECEIVABLE}.id`, payload) || null,
                value: getStringFromObject(`${payerFormFields.ACCOUNT_RECEIVABLE}.value`, payload) || '',
            } :
            null;
    payload[payerFormFields.ACCOUNT_PAYABLE] =
        isObjectValidAndNotEmpty(payload[payerFormFields.ACCOUNT_PAYABLE]) ?
            {
                key: getStringFromObject(`${payerFormFields.ACCOUNT_PAYABLE}.id`, payload) || null,
                value: getStringFromObject(`${payerFormFields.ACCOUNT_PAYABLE}.value`, payload) || '',
            } :
            null;

    payload.diagnosisExcluded = isArrayValidAndNotEmpty(diagnosisExcluded) ? diagnosisExcluded.concat() : [];
    payload.diagnosisIncluded = isArrayValidAndNotEmpty(diagnosisApproval) ? diagnosisApproval.concat() : [];
    // if it is insurance this is always false
    if (!isPayerTypeCompanyOrInsurance(payerType)) {
        payload[payerFormFields.COVER_ALL_DRUGS] = false;
    }

    payload[payerFormFields.INSURANCE_CATEGORY_PRICING_DETAIL] = [];

    if (isPayerTypeCompanyOrInsurance(payerType)) {
        if (isArrayValidAndNotEmpty(payload[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY])) {
            payload[payerFormFields.INSURANCE_CATEGORY_PRICING_DETAIL] =
                payload[payerFormFields.INSURANCE_CATEGORY_PRICING_DETAIL].concat(
                    payload[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY],
                );
        }
        if (isArrayValidAndNotEmpty(payload[insuranceSchemeFieldNames.CATEGORY_COPAY])) {
            payload[payerFormFields.INSURANCE_CATEGORY_PRICING_DETAIL] =
                payload[payerFormFields.INSURANCE_CATEGORY_PRICING_DETAIL].concat(
                    payload[insuranceSchemeFieldNames.CATEGORY_COPAY],
                );
        }
    }
    return payload;
};


export const checkDiscounts = (deductibleCopay, dispatch) => {
    if (isArrayValidAndNotEmpty(deductibleCopay)) {
        for (let i = 0; i < deductibleCopay.length; i += 1) {
            const discountAmount = getStringFromObject('discountAmount', deductibleCopay[i]);
            const discount = getStringFromObject('discount', deductibleCopay[i]);
            const categName = getStringFromObject('name', deductibleCopay[i]);
            if (
                NumberOf(discount) &&
                NumberOf(discountAmount)
            ) {
                console.log('asd-0a-dai0d', discount, discountAmount);
                dispatch(
                    errorMessage(
                        `Both Discount Amt and Discount Percentage cannot be present for category: ${categName}`,
                    ),
                );
                return false;
            }
        }
    }
    return true;
};


export const validateFormData = (formData, props) => {
    const {
        subInsurance,
        policy,
        payor,
        dispatch,
    } = props;
    const payerType = getStringFromObject(payerFormFields.TYPE, formData);
    if (payor) {
        console.log('validations for payor');
        if ([insurancePayerTypes.COMPANY.value, insurancePayerTypes.ASSOCIATION.value].includes(payerType)) {
            const deductibleCopay = formData[insuranceSchemeFieldNames.DEDUCTIBLE_COPAY];
            const categCopay = formData[insuranceSchemeFieldNames.CATEGORY_COPAY];
            if (!checkDiscounts(deductibleCopay, dispatch)) {
                return false;
            }
            if (!checkDiscounts(categCopay, dispatch)) {
                return false;
            }
        }
        if (formData[payerFormFields.VALIDATIONS]) {
            if (!isJsonString(formData[payerFormFields.VALIDATIONS])) {
                dispatch(errorMessage('Please enter a valid Json for validations'));
                return false;
            }
        }
        if (formData[payerFormFields.PAYER_FORMULARY_RESTRICTIONS]) {
            if (!isJsonString(formData[payerFormFields.PAYER_FORMULARY_RESTRICTIONS])) {
                dispatch(errorMessage('Please enter a valid Json for formulary restrictions'));
                return false;
            }
        }
    }
    if (subInsurance) {
        if (formData[payerFormFields.SUB_INSURER_VALIDATIONS]) {
            if (!isJsonString(formData[payerFormFields.SUB_INSURER_VALIDATIONS])) {
                dispatch(errorMessage('Please enter a valid Json for validations'));
                return false;
            }
        }
    }
    if (policy) {
        console.log('validations for policy');
    }
    return true;
    // if (formData[payerFormFields.EXPIRY_DATE]) {
    //     formData[payerFormFields.EXPIRY_DATE] = new Date(formData[payerFormFields.EXPIRY_DATE]).getTime();
    // }
    // if (formData[payerFormFields.EFFECTIVE_DATE]) {
    //     formData[payerFormFields.EFFECTIVE_DATE] = new Date(formData[payerFormFields.EFFECTIVE_DATE]).getTime();
    // }
};

export const policyDefaultValues = {
    [payerFormFields.FOLLOWUP_DURATION_DENTAL]: 30,
    [payerFormFields.FOLLOWUP_DURATION_DAYS]: 7,
    [payerFormFields.MAX_FOLLOW_UP_IN_EOC]: 3,
    [payerFormFields.MAX_DURATION_OF_DRUG_ORDER_WITHOUT_APPROVAL]: 30,
    [payerFormFields.DEDUCT_FULL_DEDUCTIBLE]: true,
    [payerFormFields.DIRECT_CONSULTANT_CONSULTATION_ALLOWED]: true,
    [payerFormFields.DIRECT_SPECIALIST_CONSULTATION_ALLOWED]: true,
    [payerFormFields.PRICE_EDITABLE_FOR_ZERO_PRICE]: true,
    [payerFormFields.ACTIVE]: true,
    [payerFormFields.ONLY_DUMMY_SCHEMES]: false,
};

export const validatePayorAsyncFields = (values, dispatch, props) => {
    console.log('afa', props, dispatch);
    dispatch(showSpinner());
    return axios.post(getUrlWithApiParams(API.PAYORS.VALIDATE), getSubmissionDataFromPayerFormValues(values, props))
        .then(
            (response) => {
                if (isObjectValidAndNotEmpty(response.data)) {
                    throw response.data;
                }
            },
            error => apiCatchBlockFunction(error, dispatch),
        )
        .finally(() => {
            dispatch(hideSpinner());
        });
};

export const drugSchema = (editMode) => {
    const schema = {
        fieldType: 'materialTable',
        id: 'table',
        name: 'diagnosis',
        showInNonEditableMode: true,
        showInReadOnlyMode: true,
        hideCheckBoxIcon: true,
        rowstyle: {
            height: '56px',
        },
        headerColStyle: {
            color: '#FFFFFF',
            fontSize: '14px',
        },
        tableCells: [
            {
                id: 'name',
                name: 'name',
                fieldType: 'text',
                find: 'value',
                label: 'Drug',
                fullWidth: true,
            },
        ],
        grid: {
            sm: 12,
            md: 12,
            lg: 12,
        },
    };
    if (editMode) {
        schema.tableCells = schema.tableCells.concat(
            {
                id: 'deleteIcon',
                name: 'deleteIcon',
                fieldType: 'deleteIcon',
                find: 'deleteIcon',
                label: '',
            },
        );
    }
    return schema;
};


export const diagnosisSchema = (editMode) => {
    const schema = {
        fieldType: 'materialTable',
        id: 'table',
        name: 'diagnosis',
        showInNonEditableMode: true,
        showInReadOnlyMode: true,
        hideCheckBoxIcon: true,
        rowstyle: {
            height: '56px',
        },
        headerColStyle: {
            color: '#FFFFFF',
            fontSize: '14px',
        },
        tableCells: [
            {
                id: 'code',
                name: 'code',
                fieldType: 'text',
                find: 'code',
                label: 'Code',
                fullWidth: true,
            },
            {
                id: 'name',
                name: 'name',
                fieldType: 'text',
                find: 'codedDiagnosis.value',
                label: 'Name',
                fullWidth: true,
            },
        ],
        grid: {
            sm: 12,
            md: 12,
            lg: 12,
        },
    };
    if (editMode) {
        schema.tableCells = schema.tableCells.concat(
            {
                id: 'deleteIcon',
                name: 'deleteIcon',
                fieldType: 'deleteIcon',
                find: 'deleteIcon',
                label: '',
            },
        );
    }
    return schema;
};

export const getPolicyInitialValues = async (dispatcher) => {
    try {
        const booleanFieldsDefaultValue = {};
        Object.keys(policyBooleanFields).map((aField) => {
            booleanFieldsDefaultValue[aField] = false;
            return null;
        });
        console.log('asdoasdajd-0as', policyDefaultValues);
        dispatcher(showSpinner());
        const response =
            await axios.get(getJsonPath('/Payor/PolicyDefaultValues.json', APPLICATION_CONFIG_URL));
        dispatcher(hideSpinner());
        return ({
            ...booleanFieldsDefaultValue,
            ...(response.data || {}),
        });
    } catch (e) {
        apiCatchBlockFunction(e, dispatcher);
    }
    return {};
};

export const payerAddressTableSchema = (editMode) => {
    const schema = {
        fieldType: 'materialTable',
        id: 'table',
        name: 'addressDtoList',
        showInNonEditableMode: true,
        showInReadOnlyMode: true,
        hideCheckBoxIcon: true,
        rowstyle: {
            height: '56px',
        },
        headerColStyle: {
            color: '#FFFFFF',
            fontSize: '14px',
        },
        tableCells: [
            {
                id: 'buildingNo',
                name: 'buildingNo',
                fieldType: 'text',
                find: 'buildingNo',
                label: 'Building No',
                fullWidth: true,
            },
            {
                id: 'street',
                name: 'street',
                fieldType: 'text',
                find: 'street',
                label: 'Street',
                fullWidth: true,
            },
            {
                id: 'city',
                name: 'city',
                fieldType: 'text',
                find: 'city',
                label: 'City',
                fullWidth: true,
            },
            {
                id: 'district',
                name: 'district',
                fieldType: 'text',
                find: 'district',
                label: 'District',
                fullWidth: true,
            },
            {
                id: 'postalCode',
                name: 'postalCode',
                fieldType: 'text',
                find: 'postalCode',
                label: 'Postal Code',
                fullWidth: true,
            },
            {
                id: 'languageCode',
                name: 'languageCode',
                fieldType: 'text',
                find: 'languageCode',
                label: 'Language Code',
                fullWidth: true,
            },
            {
                id: 'country',
                name: 'country',
                fieldType: 'text',
                find: 'country',
                label: 'Country',
                fullWidth: true,
            },
        ],
        grid: {
            sm: 12,
            md: 12,
            lg: 12,
        },
    };
    if (editMode) {
        schema.tableCells = schema.tableCells.concat(
            {
                id: 'deleteIcon',
                name: 'deleteIcon',
                fieldType: 'deleteIcon',
                find: 'deleteIcon',
                label: '',
            },
            {
                id: 'editIcon',
                name: 'editIcon',
                fieldType: 'editIcon',
                find: 'editIcon',
                label: '',
            },
        );
    }
    return schema;
};

export const insurancePayerDocumentTableSchema = api => ({
    fieldType: 'materialTable',
    id: 'insurancePayerDocumentTableSchema',
    name: payerFormFields.INSURANCE_PAYER_DOCUMENT_DTO_LIST,
    rowstyle: {
        height: '56px',
    },
    minWidth: '500x',
    enablePagination: false,
    tableActionButtons: [
        {
            label: 'Add New',
            key: '50f9548c-e0a8-4fce-b5b9-21f0564ee404',
            value: 'addNew',
            style: {
                margin: '1em 1em 1em 1em',
            },
        },
        {
            label: 'Delete',
            key: '0f5c60c2-b4df-4346-9758-67ea2f9dbb90',
            value: 'delete',
            style: {
                margin: '1em 1em 1em 1em',
            },
        },
    ],
    tableCells: [
        {
            label: 'Name',
            fullWidth: true,
            noLabel: true,
            id: 'name',
            fieldType: 'textField',
            name: 'name',
            validations: {
                required: true,
            },
            style: {
                fontSize: '14px',
            },
            headerStyle: {
                fontSize: '14px',
            },
        },
        {
            label: 'Document',
            fullWidth: true,
            noLabel: true,
            id: 'uploadFileInfoKV',
            fieldType: 'chipUpload',
            name: 'uploadFileInfoKV',
            uploadApi: api.FILE.UPLOAD_INSURANCE_PAYER_DOCUMENT,
            downloadApi: api.FILE.UPLOAD_INSURANCE_PAYER_DOCUMENT,
            validations: {
                required: true,
            },
            style: {
                fontSize: '14px',
            },
            headerStyle: {
                fontSize: '14px',
            },
        },
        {
            label: 'Expiring On',
            noLabel: true,
            id: 'expiryDate',
            fieldType: 'date',
            name: 'expiryDate',
            style: {
                fontSize: '14px',
            },
            headerStyle: {
                fontSize: '14px',
            },
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
});

export const filterPolicyBooleanFields = (policyBoolFields, conditions) => {
    const { implementationLocation } = conditions;
    if (implementationLocation === IMPLEMENTATION_LOCATIONS.BAHRAIN) {
        return policyBooleanFields.filter(e => e !== payerFormFields.EBP_SUPPORTED);
    }
    return policyBoolFields;
};
