import { takeLatest } from 'redux-saga/effects';
import {
    ADJUST_BALANCE_REQUEST,
    CREATE_ACCOUNTS_REQUEST,
    FETCH_ACCOUNTS_LIST_REQUEST,
} from '../../redux/modules/accounts/accounts-actions';
import { createAccountWorker, fetchPagesOfAccountsWorker, adjustBalanceWorker } from './accountsWorkerSaga';

export function* fetchPagesOfAccountsWatcher() {
    yield takeLatest(FETCH_ACCOUNTS_LIST_REQUEST, fetchPagesOfAccountsWorker);
}

export function* createAccountWatcher() {
    yield takeLatest(CREATE_ACCOUNTS_REQUEST, createAccountWorker);
}

export function* adjustBalanceWatcher() {
    yield takeLatest(ADJUST_BALANCE_REQUEST, adjustBalanceWorker);
}
