/**
 * Created by vinay on 19/12/18.
 */
import API from '../../../constants/api';

export const FETCH_SLOT_TEMPLATE_REQUEST = '@@slotTemplate/FETCH_SLOT_TEMPLATE_REQUEST';
export const FETCH_SLOT_TEMPLATE_FAILURE = '@@slotTemplate/FETCH_SLOT_TEMPLATE_FAILURE';
export const FETCH_SLOT_TEMPLATE_SUCCESS = '@@slotTemplate/FETCH_SLOT_TEMPLATE_SUCCESS';

export const FETCH_SLOT_TEMPLATE_LIST_REQUEST = '@@slotTemplate/FETCH_SLOT_TEMPLATE_LIST_REQUEST';
export const FETCH_SLOT_TEMPLATE_LIST_FAILURE = '@@slotTemplate/FETCH_SLOT_TEMPLATE_LIST_FAILURE';
export const FETCH_SLOT_TEMPLATE_LIST_SUCCESS = '@@slotTemplate/FETCH_SLOT_TEMPLATE_LIST_SUCCESS';

export const CREATE_TEMPLATE_REQUEST = '@@slotTemplate/CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_FAILURE = '@@slotTemplate/CREATE_TEMPLATE_FAILURE';
export const CREATE_TEMPLATE_SUCCESS = '@@slotTemplate/CREATE_TEMPLATE_SUCCESS';

export const UPDATE_TEMPLATE_REQUEST = '@@slotTemplate/UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_FAILURE = '@@slotTemplate/UPDATE_TEMPLATE_FAILURE';
export const UPDATE_TEMPLATE_SUCCESS = '@@slotTemplate/UPDATE_TEMPLATE_SUCCESS';

export const DELETE_TEMPLATE_REQUEST = '@@slotTemplate/DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_FAILURE = '@@slotTemplate/DELETE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_SUCCESS = '@@slotTemplate/DELETE_TEMPLATE_SUCCESS';

export const UPDATE_TEMPLATE_LIST_ROW = '@@slotTemplate/UPDATE_TEMPLATE_LIST_ROW';
export const RESET_SELECTED_TEMPLATE = '@@slotTemplate/RESET_SELECTED_TEMPLATE';

export const fetchSlotTemplate = uuid => ({
    type: FETCH_SLOT_TEMPLATE_REQUEST,
    api: `${API.APPOINTMENT.SLOT_TEMPLATE}/${uuid}`,
});

export const fetchListOfSlotTemplates = () => ({
    type: FETCH_SLOT_TEMPLATE_LIST_REQUEST,
    api: API.APPOINTMENT.SLOT_TEMPLATE,
});

export const createTemplate = (payload, onSuccessCallback, onFailureCallback) => ({
    type: CREATE_TEMPLATE_REQUEST,
    api: API.APPOINTMENT.SLOT_TEMPLATE,
    payload,
    onSuccessCallback,
    onFailureCallback,
});

export const updateTemplate = (payload, onSuccessCallback, onFailureCallback) => ({
    type: UPDATE_TEMPLATE_REQUEST,
    api: `${API.APPOINTMENT.SLOT_TEMPLATE}/${payload.uuid}`,
    payload,
    onSuccessCallback,
    onFailureCallback,
});

export const renameTemplate = (payload, onSuccessCallback, onFailureCallback) => ({
    type: UPDATE_TEMPLATE_REQUEST,
    api: `${API.APPOINTMENT.SLOT_TEMPLATE}/rename/${payload.uuid}`,
    payload,
    onSuccessCallback,
    onFailureCallback,
});

export const deleteTemplate = (uuid, onSuccessCallback, onFailureCallback) => ({
    type: DELETE_TEMPLATE_REQUEST,
    api: `${API.APPOINTMENT.SLOT_TEMPLATE}/${uuid}`,
    onSuccessCallback,
    onFailureCallback,
});

export const updateTemplateListRow = (payload, index) => ({
    type: UPDATE_TEMPLATE_LIST_ROW,
    payload,
    index,
});

export const resetSelectedTemplate = () => ({
    type: RESET_SELECTED_TEMPLATE,
});
