/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_ACCOUNT_TRANSFER,
    CREATE_ACCOUNT_TRANSFER_SUCCESS,
    FETCH_ACCOUNT_TRANSFER_SUCCESS,
} from './accountTransfer-actions';

const accountTransferReducer = (state = initialStates.accountTransfer, action) => {
    let newState = {};
    switch (action.type) {
    case CREATE_ACCOUNT_TRANSFER_SUCCESS:
    case FETCH_ACCOUNT_TRANSFER_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case CLEAR_ACCOUNT_TRANSFER:
        newState = Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default accountTransferReducer;
