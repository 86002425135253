import initialStates from '../../initialStates';
import {
    FETCH_ANNOUNCEMENT_LIST_SUCCESS,
    FETCH_ANNOUNCEMENT_LIST_FAILURE,
    FETCH_ANNOUNCEMENT_LIST_REQUEST,
    ADD_ANNOUNCEMENT_SUCCESS,
    ADD_ANNOUNCEMENT_FAILURE,
    ADD_ANNOUNCEMENT_REQUEST,
    EDIT_ANNOUNCEMENT_SUCCESS,
    ADD_CURRENT_ANNOUNCEMENT_REQUEST,
} from './announcements-actions';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';

const announcementReducer = (state = initialStates.announcements, action) => {
    let newState = {};
    const { list } = state;
    switch (action.type) {
    case FETCH_ANNOUNCEMENT_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true, isFetched: false });
        break;
    case FETCH_ANNOUNCEMENT_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data,
            currentAnnouncement: isArrayValidAndNotEmpty(action.data) && action.data[0],
            isFetched: true,
        });
        break;
    case FETCH_ANNOUNCEMENT_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false, isFetched: true });
        break;
    case ADD_ANNOUNCEMENT_REQUEST:
        newState = Object.assign({}, state, { isLoading: true, isAdded: false });
        break;
    case ADD_ANNOUNCEMENT_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case ADD_ANNOUNCEMENT_SUCCESS:
        newState = Object.assign({}, state, { currentAnnouncement: action.data, isAdded: true });
        break;
    case EDIT_ANNOUNCEMENT_SUCCESS:
        list[action.index] = action.data;
        console.log('sdkjcnsdkjcds', list);
        newState = Object.assign({}, state, { list, currentAnnouncement: action.data });
        break;
    case ADD_CURRENT_ANNOUNCEMENT_REQUEST:
        newState = Object.assign({}, state, { currentAnnouncement: action.currentAnnouncement });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default announcementReducer;
