import { isObjectValidAndNotEmpty } from './nullCheckUtils';

/* eslint-disable */
export const flattenDeep = (arr) => (
    Array.isArray(arr) ? arr.reduce((a, b) => a.concat(flattenDeep(b)), []) : [arr]
);

const areArgsValid = (mainString, targetStrings) => {
    if (typeof mainString !== 'string') return false;
    if (!Array.isArray(targetStrings)) return false;
    if (!targetStrings.length) return false;
    if (targetStrings.find(s => typeof s !== 'string')) return false;
    return true;
};

const letterPairs = (str) => {
    const pairs = [];
    for (let i = 0, max = str.length - 1; i < max; i++) pairs[i] = str.substring(i, i + 2);
    return pairs;
};

const wordLetterPairs = (str) => {
    const pairs = str.toUpperCase().split(' ').map(letterPairs);
    return flattenDeep(pairs);
};

function partialRatioSimilarity(first, second) {
    //check if exact match
    if(first === second){
        return Infinity;
      }

      // replace '/' with space as we are passing routes
      first = first.replace(/\//g, ' ').trim();
      second = second.replace(/\//g, ' ').trim();

      // replace '-' with '' and convert to lowercase
      first = first.replace(/-/g, '').toLowerCase();
      second = second.replace(/-/g, '').toLowerCase();
      const shorter = first.length <= second.length ? first : second;
      const longer = first.length > second.length ? first : second;

      let windowSize = Math.min(...longer.split(' ').map(o => o.length));
      windowSize = Math.max(windowSize, 2);
      // console.log("first, second > ", first, second);
      // console.log("longer:window > ", longer, windowSize);
      let matchCount = 0;
      for (let i = 0; i < shorter.length; i++) {
        const substring = shorter.substring(i, i + windowSize);
          const matchIndex = longer.indexOf(substring);
          // console.log("longer>", longer ," -substring>", substring);
          if (matchIndex !== -1) {
            matchCount = matchCount + 1;
          }
      }
      const score = matchCount * (first.length + second.length)/(first.length*second.length);;
      // console.log("\n\n");
      return score || 0;
}

export const compareTwoStrings = (first, second) => {
    first = first.replace(/\s+/g, '');
    second = second.replace(/\s+/g, '');

    if (!first.length && !second.length) return 1;                   // if both are empty strings
    if (!first.length || !second.length) return 0;                   // if only one is empty string
    if (first === second) return 1;       							 // identical
    if (first.length === 1 && second.length === 1) return 0;         // both are 1-letter strings
    if (first.length < 2 || second.length < 2) return 0;			 // if either is a 1-letter string

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.substr(i, 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram) + 1
            : 1;

        firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.substr(i, 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram)
            : 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
};

export const findBestMatch = (mainString, targetStrings, disablePartialRatioRouteMatching=false) => {
    if (!areArgsValid(mainString, targetStrings)) throw new Error('Bad arguments: First argument should be a string, second should be an array of strings');

    // console.log('this for main String',mainString);
    const ratings = [];
    let bestMatchIndex = 0;

    for (let i = 0; i < targetStrings.length; i++) {
        const currentTargetString = targetStrings[i];
        const funcToUse = !disablePartialRatioRouteMatching ?  partialRatioSimilarity : compareTwoStrings;
        const currentRating = funcToUse(mainString, currentTargetString);
        ratings.push({target: currentTargetString, rating: currentRating})
        if (currentRating > ratings[bestMatchIndex].rating) {
            bestMatchIndex = i
        }
    }


    const bestMatch = ratings[bestMatchIndex]

    return { ratings, bestMatch, bestMatchIndex };
};

// example targetPatternObject
// { '/^([a-z0-9]{5,})$/': 'abc12' }
export const regexMatcher = (toBeCheckedString, targetPatternObject) => {
    if(!isObjectValidAndNotEmpty(targetPatternObject)){
        return null;
    }
    const patterns = Object.keys(targetPatternObject);
    for (let index = 0; index < patterns.length; index+=1) {
        const pattern = patterns[index];
        const match = targetPatternObject[pattern];
        let re = new RegExp(pattern);
        if (re.test(toBeCheckedString)) {
            return match;
        }
    }
    return null;
};
