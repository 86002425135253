import getProperty from 'lodash.get';
import setProperty from 'lodash.set';

export const getStringFromObject = (string, obj, defaultValue = '') => {
    const value = getProperty(obj, string, defaultValue);
    if (value == null) {
        return undefined;
    }
    return value;
};
// Using the getPropertyFromObject() instead of getStringFromObject() irrespective of its wide usage
// reason being it's name and implementation don't match. it doesn't always return string from the object.
export const getPropertyFromObject = (obj, path, defaultValue) => {
    const value = getProperty(obj, path, defaultValue);
    if (value === null || value === undefined) {
        return defaultValue;
    }
    return value;
};

export const setStringPropertyToObject = (string, obj, value) => setProperty(obj, string, value);
