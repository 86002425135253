import React from 'react';
import { Route } from 'react-router';
import { notificationSetting, smsSetting } from '../constants/constants';
import NotificationSettingContainer from '../containers/NotificationSettingContainer/NotificationSettingContainer';

const NotificationPage = React.lazy(() => import('../pages/NotificationSettingPage'));
const SmsSettingPage = React.lazy(() => import('../pages/SmsSettingPage'));

const NotificationSettingRoutes = props => (
    <NotificationSettingContainer {...props}>
        <Route path={notificationSetting} exact component={NotificationPage} />
        <Route path={smsSetting} exact component={SmsSettingPage} />
    </NotificationSettingContainer>
);

export default NotificationSettingRoutes;
