import API from '../../../constants/api';

export const ADD_PETTY_CASH_REQUEST = '@@pettyCash/ADD_PETTY_CASH_REQUEST';
export const ADD_PETTY_CASH_FAILURE = '@@pettyCash/ADD_SALARY_FAILURE';
export const ADD_PETTY_CASH_SUCCESS = '@@pettyCash/ADD_SALARY_SUCCESS';

export const FETCH_PETTY_CASH_SUCCESS = '@@pettyCash/FETCH_PETTY_CASH_SUCCESS';

export const CLEAR_SELECTED_PETTY_CASH = '@@pettyCash/CLEAR_SELECTED_PETTY_CASH';

export const createPettyCash = (payload, successCallBack, failureCallBack) => ({
    type: ADD_PETTY_CASH_REQUEST,
    payload,
    api: API.PETTYCASH.ADD_PETTY_CASH,
    successCallBack,
    failureCallBack,
});

export const clearSelectedPettyCash = () => ({
    type: CLEAR_SELECTED_PETTY_CASH,
});
