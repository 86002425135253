import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import deepEqual from 'react-fast-compare';
import { withFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ActionButton from '../../ActionButton/ActionButton';
import {
    apiCatchBlockFunction, getIndexFromFieldName, getJsonPath,


} from '../../../constants/CommonUtil';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import { ROWS_PER_PAGE } from '../../../constants/constants';
import { fetchStockMovesRequest, returnStockRequest } from '../../../redux/modules/stockMove/stockMove-actions';
import { getNonInvoicedPOUiObject, mapUiObjectToDto, RETURN_GOOD_OPERATION } from '../../../mapper/ReturnGoodsMapper';
import DialogComponent from '../../DialogComponent/DialogComponent';
import API from '../../../constants/api';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'goodReturnDialogNonInvoicedPo';

const style = () => ({
    header: {
        fontSize: '1.5rem',
    },
    input: {
        padding: '0 6px',
    },
    multiInput: {
        padding: '6px',
    },
    multiline: {
        padding: '0.7em 0',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    paper: {
        background: '#fafafa',
    },
});

class GoodReturnDialogNonInvoicedPO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
        };
    }

    componentWillMount() {
        const {
            dispatch, purchaseOrderUuid,
        } = this.props;
        dispatch(fetchStockMovesRequest(
            `${API.STOCK.FETCH_STOCK_MOVES_BY_PURCHASE_ORDER_BY_UUID}${purchaseOrderUuid}`));
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/GoodReturnNonInvoicedPO.json'))
            .then(response => response.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            }).catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        this.initializeForm(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.stockPickings, this.props.stockPickings)) {
            this.initializeForm(nextProps);
        }
    }

    initializeForm = (nextProps) => {
        getNonInvoicedPOUiObject(nextProps.stockPickings, nextProps.purchaseOrderUuid)
            .then(resp => nextProps.setValues(resp))
            .catch(error => apiCatchBlockFunction(error, nextProps.dispatch));
    };

    handleSave = () => {
        this.props.setFieldValue('status', RETURN_GOOD_OPERATION.SAVE);
        this.props.submitForm();
    };

    handleConfirm = () => {
        this.props.setFieldValue('status', RETURN_GOOD_OPERATION.DONE);
        this.props.submitForm();
    };

    handleValidateReturnQuantity = (value, fieldName) => {
        const { values } = this.props;
        const index = getIndexFromFieldName(fieldName);
        const trackByBatch = getStringFromObject(`stockPickingLinesDTOSet.${index}.productTrackByBatch`, values, false);
        if (trackByBatch) {
            const batchQuant = NumberOf(getStringFromObject(`stockPickingLinesDTOSet.${index}.batch.quantity`, values));
            if (NumberOf(value) > batchQuant) {
                return 'Entered quantity not available in selected batch';
            }
        }
        const maxQuant = getStringFromObject(`stockPickingLinesDTOSet.${index}.quantityReceived`, values);
        if (NumberOf(value) > NumberOf(maxQuant)) {
            return 'Can not return more than received quantity';
        }
        return '';
    };

    handleBatchSelect = (value, fieldName) => {
        const index = getIndexFromFieldName(fieldName);
        console.log('ahfjkdshfla', value, fieldName, index);
        const { setFieldValue } = this.props;
        setFieldValue(`stockPickingLinesDTOSet.${index}.stockProdLot`,
            getStringFromObject('batch', value, null));
    };

    render() {
        const {
            schema,
        } = this.state;
        const {
            open,
            classes,
            handleClose,
            values,
        } = this.props;
        const isDisabled = getStringFromObject('stockPickingLinesDTOSet.length', values) > 0;
        console.log('selectedadsdf', this.props, schema);
        return (
            <Fragment>
                <DialogComponent
                    open={open}
                    fullScreen
                    classes={classes}
                    header="Return Stock"
                    handleClose={handleClose}
                    dialogActions={
                        <React.Fragment>
                            <ActionButton onClick={handleClose} primary={false} className="mr-1">
                                Cancel
                            </ActionButton>
                            <ActionButton
                                onClick={this.handleConfirm}
                                disabled={isDisabled}
                            >
                                Confirm
                            </ActionButton>
                        </React.Fragment>
                    }
                >
                    <Grid container className="mt-2">
                        {
                            isObjectValidAndNotEmpty(schema) &&
                            <FormikTable
                                {...schema}
                                fieldName="stockPickingLinesDTOSet"
                                enablePagination
                                showRowNumber
                                tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                deleteIcon
                                rowsPerPageOptions={ROWS_PER_PAGE}
                                customValidationFunctions={{
                                    validateReturnQuantity: this.handleValidateReturnQuantity,
                                }}
                                actionHandlers={{
                                    handleBatchSelect: this.handleBatchSelect,
                                }}
                            />
                        }
                    </Grid>
                </DialogComponent>
            </Fragment>
        );
    }
}


GoodReturnDialogNonInvoicedPO.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    stockPickings: PropTypes.array,
    purchaseOrderUuid: PropTypes.string.isRequired,
};

GoodReturnDialogNonInvoicedPO.defaultProps = {
    stockPickings: [],
};

const handleSubmit = (values, { props }) => {
    const dispatch = getStringFromObject('dispatch', props);
    const payload = mapUiObjectToDto(values);
    console.log('valuesnfahflajvkla', payload);
    dispatch(returnStockRequest(payload, props.handleClose));
};

const mapStateToProps = state => ({
    stockPickings: getStringFromObject('stockMove.stockMovesByPurchaseOrder', state, []),
});

export default connect(mapStateToProps)((withFormik({
    mapPropsToValues: () => ({ stockPickingLinesDTOSet: [] }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit,
})(withStyles(style)(GoodReturnDialogNonInvoicedPO))));
