import { takeLatest } from 'redux-saga/effects';
import { addVitalsWorker, fetchVitalsQueueWorker, fetchVitalsQueueOptimizedWorker } from './vitalsQueueWorkerSaga';
import {
    ADD_VITALS_REQUEST,
    FETCH_VITALS_QUEUE_LIST_OPTIMIZED_REQUEST,
    FETCH_VITALS_QUEUE_LIST_REQUEST,
} from '../../redux/modules/vitalsQueue/vitalsQueue-actions';


export function* fetchVitalsQueueWatcher() {
    yield takeLatest(FETCH_VITALS_QUEUE_LIST_REQUEST, fetchVitalsQueueWorker);
}

export function* fetchVitalsQueueOptimizedWatcher() {
    yield takeLatest(FETCH_VITALS_QUEUE_LIST_OPTIMIZED_REQUEST, fetchVitalsQueueOptimizedWorker);
}

export function* saveVitalsWatcher() {
    yield takeLatest(ADD_VITALS_REQUEST, addVitalsWorker);
}
