import initialStates from '../../initialStates';
import { CLEAR_SELECTED_CASH_CLOSE, CREATE_CASH_CLOSE_SUCCESS, FETCH_CASH_CLOSE_SUCCESS } from './cashClose-actions';

const cashCloseReducer = (state = initialStates.cashClose, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_CASH_CLOSE_SUCCESS:
    case CREATE_CASH_CLOSE_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_SELECTED_CASH_CLOSE:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default cashCloseReducer;
