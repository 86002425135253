import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Papa from 'papaparse';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper/Stepper';
import Step from '@material-ui/core/Step/Step';
import StepLabel from '@material-ui/core/StepLabel/StepLabel';
import { withFormik } from 'formik';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import { CSVLink } from 'react-csv';


import {
    apiCatchBlockFunction, getJsonPath,
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,
    isEnterKeyPressed,
    isValidFunction,
} from '../../constants/CommonUtil';
import MapCSVData from '../PurchaseOrderComponents/MapCSVData';
import ActionButton from '../ActionButton/ActionButton';
import { ROWS_PER_PAGE } from '../../constants/constants';
import FormikTable from '../Formik/FormikTable/FormikTable';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { getDateInDDMMMYYYYFormat } from '../../constants/DateUtil';
import { commonCSVImportStyles } from './CommonCSVImportDialogStyles';
import { errorMessage, successMessage } from '../../redux/modules/message/message-actions';
import OutlinedTextField from '../OutlinedTextField';
import API from '../../constants/api';
import { applyAdditonalTransformationsFromSchema, searchCSVUploadRows, showLineNoInCSVImport } from './CSVImportUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'csvFileImportForm';

const steps = ['Upload', 'Map Data', 'Import'];

let csvFieldMap = {};

class CommonCSVImportDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schemaObject || null,
            activeStep: 0,
            csvData: {},
            csvFields: [],
            searchText: '',
        };
    }

    componentDidMount() {
        csvFieldMap = {};
        this.fetchSchemaAndRelatedConfigs(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.importName !== nextProps.importName) {
            this.fetchSchemaAndRelatedConfigs(nextProps);
        }
        if (!deepEqual(this.props.errors, nextProps.errors) && isObjectValidAndNotEmpty(nextProps.errors)) {
            console.error('submission error', nextProps.errors);
            const errors = getStringFromObject('productUploadTable', nextProps.errors);
            if (isArrayValidAndNotEmpty(errors)) {
                let indexesWithError = [];
                errors.forEach((anError, index) => {
                    if (isObjectValidAndNotEmpty(anError)) {
                        indexesWithError = indexesWithError.concat(index + 1);
                    }
                });
                console.log('ia-0sdi-a0sdi-da', indexesWithError);
                this.props.dispatch(errorMessage(`Errors in line ${indexesWithError.join(', ')}. Please fix them to upload data `));
            }
        }
    }

    componentDidUpdate(prevProps) {
        const previousValuesForUploadData =
            getStringFromObject('values.productUploadTable', prevProps);
        const newValuesForUploadData =
            getStringFromObject('values.productUploadTable', this.props);
        const {
            setFieldValue,
        } = this.props;
        if (previousValuesForUploadData.length !== newValuesForUploadData.length) {
            const rowValuesWithRecalculatedIndex =
                newValuesForUploadData.map((aRowValue, index) => ({ ...aRowValue, originalIndex: index }));
            setFieldValue('productUploadTable', rowValuesWithRecalculatedIndex);
        }
    }

    componentWillUnmount() {
        csvFieldMap = {};
    }

    onChangeSearchText = (e) => {
        const searchText = getTextFieldChangedValue(e);
        this.setState({
            searchText,
        }, () => {
            if (!searchText) {
                this.searchRows();
            }
        });
    };

    onChangeLineNoSearchText = (e) => {
        const lineNoSearch = getTextFieldChangedValue(e);
        this.setState({
            lineNoSearch,
        }, () => {
            if (!lineNoSearch) {
                this.showLineNo();
            }
        });
    };

    onKeyDown = (e) => {
        if (isEnterKeyPressed(e)) {
            this.searchRows();
        }
    };
    onKeyDownLineNoSearch = (e) => {
        if (isEnterKeyPressed(e)) {
            this.showLineNo();
        }
    };

    searchRows = () => {
        console.log('asdasdhasdo', this.props);
        const { values, setValues } = this.props;
        const { schema, searchText } = this.state;
        const newFormValues = searchCSVUploadRows(
            searchText,
            schema,
            getStringFromObject('productUploadTable', values),
        );
        if (newFormValues) {
            setValues({
                productUploadTable: newFormValues,
            });
            this.setState(prevState => ({
                resetPage: !prevState.resetPage,
            }));
        }
    };

    showLineNo = () => {
        console.log('asdasdhasdo', this.props);
        const { values, setValues } = this.props;
        const { lineNoSearch } = this.state;
        const formValues = cloneDeep(getStringFromObject('productUploadTable', values));
        const newFormValues = showLineNoInCSVImport(formValues, lineNoSearch);
        setValues({
            productUploadTable: newFormValues,
        });
        this.setState(prevState => ({
            resetPage: !prevState.resetPage,
        }));
    };

    fetchSchemaAndRelatedConfigs = async (props = this.props) => {
        const {
            dispatch,
            importName,
        } = props;
        let csvUploadConfig = cloneDeep(this.state.csvUploadConfig);
        if (importName) {
            try {
                dispatch(showSpinner());
                if (!isObjectValidAndNotEmpty(csvUploadConfig)) {
                    const response = await axios.get(getJsonPath('/CSVUploads/CSVUploadConfig.json'));
                    csvUploadConfig = response.data;
                }
                const csvImportConfig = getStringFromObject(importName, csvUploadConfig);
                if (!isObjectValidAndNotEmpty(csvImportConfig)) {
                    dispatch(errorMessage('Config Data Not Found For This Import'));
                    dispatch(hideSpinner());
                    return;
                }
                const { schema, csvSampleDataApi } = csvImportConfig;
                console.log('sdfdf api', csvSampleDataApi);
                const response = await axios.get(getJsonPath(schema));
                let csvData = null;
                if (csvSampleDataApi) {
                    dispatch(showSpinner());
                    const httpClient = axios.create();
                    httpClient.defaults.timeout = 500;
                    const resp2 = await axios.get(getJsonPath(csvSampleDataApi));
                    csvData = resp2.data;
                    console.log('sdfdf api', csvData, resp2);
                }
                this.setState({
                    schema: response.data,
                    csvData,
                });
                dispatch(hideSpinner());
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    handleFileChange = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: this.handleParsedData,
        });
    };

    mapDataToJsonField = async (data) => {
        let mappedData = [];
        const { schema } = this.state;
        const defaultRowObject = {
            visible: true,
            uiUuid: uuidv4(),
        };
        if (isArrayValidAndNotEmpty(schema.tableCells)) {
            schema.tableCells.forEach((aCell) => {
                if (aCell.defaultValue !== undefined) {
                    defaultRowObject[aCell.name] = aCell.defaultValue;
                }
            });
        }
        console.log('asd-9ajda0i', csvFieldMap);
        if (isArrayValidAndNotEmpty(data)) {
            data.map((d, index) => {
                const obj = {
                    ...defaultRowObject,
                    originalIndex: index,
                };
                Object.keys(d).map((k) => {
                    console.log('asda-0dias0', csvFieldMap[k]);
                    const foundDef = (schema.tableCells || []).find(aCell => aCell.name === csvFieldMap[k]);
                    if (d[k] === 'TRUE' || d[k] === 'true') {
                        obj[csvFieldMap[k]] = true;
                    } else if (d[k] === 'FALSE' || d[k] === 'false') {
                        obj[csvFieldMap[k]] = false;
                    } else if (isObjectValidAndNotEmpty(foundDef) && foundDef.type === 'time') {
                        console.log('asdadasd-0sds', d[k]);
                        let val = d[k];
                        if (val) {
                            const hrMin = val.split(':');
                            const hrs = NumberOf(hrMin[0]);
                            val = `${hrs > 9 ? hrs : `0${hrs}`}:${hrMin[1]}`;
                        }
                        obj[csvFieldMap[k]] = val;
                    } else {
                        obj[csvFieldMap[k]] = d[k];
                    }
                    return null;
                });
                mappedData = mappedData.concat(obj);
                return null;
            });
        }
        return mappedData;
    };

    handleNext = async () => {
        const { csvData } = this.state;
        this.setState({
            activeStep: 2,
        });
        const {
            setValues,
        } = this.props;
        const productUploadTable = await this.mapDataToJsonField(csvData.data);
        setValues({
            productUploadTable,
        });
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }), () => {
            if (this.state.activeStep === 1) {
                const {
                    setValues,
                } = this.props;
                setValues({
                    productUploadTable: [],
                });
            }
        });
    };

    handleParsedData = (result) => {
        console.log('   asdfhlasjkdf', result);
        const { schema } = this.state;
        const newSchema = { ...schema };
        const csvFields = isObjectValidAndNotEmpty(result) &&
            isObjectValidAndNotEmpty(result.meta) && result.meta.fields;
        if (isArrayValidAndNotEmpty(csvFields)) {
            csvFields.map((field) => {
                if (isArrayValidAndNotEmpty(newSchema.tableCells)) {
                    newSchema.tableCells.map((cell, index) => {
                        const obj = { ...cell };
                        if (cell.label && cell.label.trim().toLowerCase() === field.trim().toLowerCase()) {
                            csvFieldMap[field] = cell.name;
                            obj.csvName = field;
                            newSchema.tableCells[index] = { ...obj };
                        }
                        return null;
                    });
                }
                return null;
            });
        }
        this.setState({
            csvData: result,
            csvFields,
            activeStep: 1,
            schema: newSchema,
        });
    };

    handleFieldChange = (e, index) => {
        console.log('fffffff', e, index);
        const { schema } = this.state;
        const newSchema = cloneDeep(schema);
        newSchema.tableCells[index].csvName = e.target.value;
        this.setState({
            schema: newSchema,
        });
        // delete any key in csvFieldMap which has value newSchema.tableCells[index].name
        const fieldName = getStringFromObject(`tableCells[${index}].name`, newSchema);
        const foundKey = Object.keys(csvFieldMap).find(aKey => csvFieldMap[aKey] === fieldName);
        if (foundKey) {
            delete csvFieldMap[foundKey];
        }
        csvFieldMap[e.target.value] = fieldName;
    };

    handleImport = action => () => {
        const { setFieldValue, submitForm } = this.props;
        const { schema } = this.state;
        setFieldValue('action', action);
        setFieldValue('uploadTableSchema', schema);
        submitForm();
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            dispatch,
            header,
            csvHeaders,
            importName,
        } = this.props;
        const {
            schema,
            activeStep,
            csvFields,
            csvData,
            searchText,
            lineNoSearch,
            resetPage,
        } = this.state;
        const formValues = cloneDeep(getStringFromObject('productUploadTable', this.props.values));
        console.log('2222222222ddddddddddd190092309123', formValues);
        console.log('2222222222ddddddddddd', csvData, csvHeaders, csvFieldMap, isArrayValidAndNotEmpty(csvData));
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>{header}</div>
                                <Close className="cursor-pointer" onClick={handleClose} />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <Grid container>
                                <Stepper
                                    style={{ width: '100%', background: '#7e7e7e12' }}
                                    activeStep={activeStep}
                                    alternativeLabel
                                >
                                    {
                                        steps.map(label => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))
                                    }
                                </Stepper>
                            </Grid>
                            {
                                activeStep === 0 &&
                                <Grid container style={{ padding: '2em' }} justify="space-between">
                                    <input
                                        type="file"
                                        accept=".csv"
                                        aria-label="Select File"
                                        onChange={this.handleFileChange}
                                        style={{ cursor: 'pointer' }}
                                        test-id="select-file"
                                    />
                                    {
                                        isArrayValidAndNotEmpty(csvData) &&
                                        <CSVLink
                                            data={csvData}
                                            target="_blank"
                                            headers={isArrayValidAndNotEmpty(csvHeaders) ? csvHeaders : null}
                                            filename={`${importName}_${getDateInDDMMMYYYYFormat(new Date())}.csv`}
                                        >
                                            Download Sample CSV
                                        </CSVLink>
                                    }
                                </Grid>
                            }
                            {
                                activeStep === 1 &&
                                isObjectValidAndNotEmpty(schema) &&
                                    <MapCSVData
                                        tabelCells={schema.tableCells}
                                        fields={csvFields}
                                        handleFieldChange={this.handleFieldChange}
                                    />
                            }
                            {
                                activeStep === 2 &&
                                    <React.Fragment>
                                        <Grid container className="p2" spacing={16}>
                                            <Grid item sm={4} md={4} lg={4}>
                                                <OutlinedTextField
                                                    value={searchText}
                                                    label="Search"
                                                    onChange={this.onChangeSearchText}
                                                    onKeyDown={this.onKeyDown}
                                                />
                                            </Grid>
                                            <Grid item sm={2} md={2} lg={2}>
                                                <OutlinedTextField
                                                    value={lineNoSearch}
                                                    label="Go To Line Number"
                                                    type="number"
                                                    onChange={this.onChangeLineNoSearchText}
                                                    onKeyDown={this.onKeyDownLineNoSearch}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className="p1">
                                            {
                                                isObjectValidAndNotEmpty(schema) &&
                                                <FormikTable
                                                    visibilityField="visible"
                                                    fieldName="productUploadTable"
                                                    enablePagination={false}
                                                    rowsPerPage={100}
                                                    dispatch={dispatch}
                                                    styles={{ minHeight: '20em' }}
                                                    {...schema}
                                                    uniqueField="uiUuid"
                                                    tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                                    rowsPerPageOptions={ROWS_PER_PAGE}
                                                    // handleSaveIconClick={this.handleSaveProduct}
                                                    isEditable
                                                    resetPage={resetPage}
                                                />
                                            }
                                        </Grid>
                                    </React.Fragment>

                            }
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                activeStep === 1 &&
                                    <Grid container justify="flex-end">
                                        <ActionButton
                                            primary={false}
                                            className="mr-1"
                                            onClick={this.handleBack}
                                        >
                                            Back
                                        </ActionButton>
                                        <ActionButton
                                            primary={false}
                                            onClick={this.handleNext}
                                            testId="next"
                                        >
                                            Next
                                        </ActionButton>
                                    </Grid>
                            }
                            {
                                activeStep === 2 &&
                                <Grid container justify="flex-end">
                                    <ActionButton
                                        primary={false}
                                        onClick={this.handleBack}
                                    >
                                        Back
                                    </ActionButton>
                                    <ActionButton
                                        primary={false}
                                        className="ml-1"
                                        onClick={this.handleImport()}
                                        testId="import-csv"
                                    >
                                        Import
                                    </ActionButton>
                                </Grid>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

CommonCSVImportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    header: PropTypes.string,
    importName: PropTypes.string,
    payerType: PropTypes.string,
    schema: PropTypes.string.isRequired,
    setValues: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    actionButtons: PropTypes.array,
    csvHeaders: PropTypes.array,
    schemaObject: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    csvSampleDataApi: PropTypes.string,
};

CommonCSVImportDialog.defaultProps = {
    dispatch: () => {},
    header: 'Import Product',
    actionButtons: [],
    csvHeaders: [],
    schemaObject: {},
    csvSampleDataApi: '',
    payerType: '',
    importName: '',
    errors: {},
    values: {},
};

const handleSubmitForm = async (values, { props }) => {
    const rawUploadData = getStringFromObject('productUploadTable', values, []);

    console.log('asdjasd990asd0asj', rawUploadData, props.importName);
    if (!isArrayValidAndNotEmpty(rawUploadData)) {
        props.dispatch(errorMessage('No Data To Import'));
        return;
    }

    // perform additional transformation on the payload based on schema
    const schema = getStringFromObject('uploadTableSchema', values, []);
    delete rawUploadData.uploadTableSchema;
    let uploadData = applyAdditonalTransformationsFromSchema(rawUploadData, schema);

    try {
        uploadData = uploadData.map((aRow) => {
            const theRow = { ...aRow };
            delete theRow.visible;
            delete theRow.uiUuid;
            delete theRow.originalIndex;
            delete theRow.undefined;
            return theRow;
        });
        props.dispatch(showSpinner());
        const preProcessPayload = getStringFromObject('preProcessPayload', props, null);
        if (isValidFunction(preProcessPayload)) {
            uploadData = preProcessPayload(uploadData);
        }
        await axios.post(`${API.UPLOAD_CSV_DATA}${props.importName}`, uploadData);
        props.dispatch(hideSpinner());
        props.dispatch(successMessage('Import Successful'));
        props.handleClose(null, true);
    } catch (e) {
        apiCatchBlockFunction(e, props.dispatch);
    }
};

export default connect()(withFormik({
    mapPropsToValues: () => cloneDeep([]),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(commonCSVImportStyles)(CommonCSVImportDialog)));
