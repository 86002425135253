import initialStates from '../../../initialStates';
import {
    FETCH_SUB_INSURERS_LIST_SUCCESS,
    FETCH_SUB_INSURERS_LIST_REQUEST, CLEAR_SUB_INSURER_STATE,
} from './subInsurer-actions';


const subInsurerReducer = (state = initialStates.subinsurers, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_SUB_INSURERS_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_SUB_INSURERS_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case CLEAR_SUB_INSURER_STATE:
        newState = initialStates.subinsurers;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default subInsurerReducer;
