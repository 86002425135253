import API from '../../../constants/api';

export const FETCH_PAYROLL_DETAILS_REQUEST = '@@payroll/FETCH_PAYROLL_DETAILS_REQUEST';
export const FETCH_PAYROLL_DETAILS_SUCCESS = '@@payroll/FETCH_PAYROLL_DETAILS_SUCCESS';
export const FETCH_PAYROLL_DETAILS_FAILURE = '@@payroll/FETCH_PAYROLL_DETAILS_FAILURE';

export const FETCH_PAYSLIP_DETAILS_REQUEST = '@@payroll/FETCH_PAYSLIP_DETAILS_REQUEST';
export const FETCH_PAYSLIP_DETAILS_SUCCESS = '@@payroll/FETCH_PAYSLIP_DETAILS_SUCCESS';
export const FETCH_PAYSLIP_DETAILS_FAILURE = '@@payroll/FETCH_PAYSLIP_DETAILS_FAILURE';

export const fetchPayrollDetails = () => ({
    type: FETCH_PAYROLL_DETAILS_REQUEST,
    api: API.EMPLOYEE_PAYROLL.PAYROLL_DETAILS,
});

export const fetchPayslipDetails = (year, month) => ({
    type: FETCH_PAYSLIP_DETAILS_REQUEST,
    api: API.EMPLOYEE_PAYROLL.PAYSLIP_DETAILS,
    year,
    month,
});
