import API from '../../../constants/api';

export const LEAVE_BALANCE_FETCH_REQUEST = '@leave/LEAVE_BALANCE_FETCH_REQUEST';
export const LEAVE_BALANCE_FETCH_SUCCESS = '@leave/LEAVE_BALANCE_FETCH_SUCCESS';
export const LEAVE_BALANCE_FETCH_FAILED = '@leave/LEAVE_BALANCE_FETCH_FAILED';

export const AVAILED_LEAVES_FETCH_REQUEST = '@leave/AVAILED_LEAVES_FETCH_REQUEST';
export const AVAILED_LEAVES_FETCH_SUCCESS = '@leave/AVAILED_LEAVES_FETCH_SUCCESS';
export const AVAILED_LEAVES_FETCH_FAILED = '@leave/AVAILED_LEAVES_FETCH_FAILED';

export const MANAGE_LEAVE_FETCH_REQUEST = '@leave/MANAGE_LEAVE_FETCH_REQUEST';
export const MANAGE_LEAVE_FETCH_SUCCESS = '@leave/MANAGE_LEAVE_FETCH_SUCCESS';
export const MANAGE_LEAVE_FETCH_FAILED = '@leave/MANAGE_LEAVE_FETCH_FAILED';

export const LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST = '@leave/LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST';
export const LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS = '@leave/LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS';
export const LEAVE_PLANS_FOR_EMPLOYEE_FETCH_FAILED = '@leave/LEAVE_PLANS_FOR_EMPLOYEE_FETCH_FAILED';
export const CLEAR_LEAVES = '@leave/CLEAR_LEAVES';

export const fetchLeaveBalance = uuid => ({
    type: LEAVE_BALANCE_FETCH_REQUEST,
    api: API.LEAVES.BALANCE_LEAVES,
    uuid,
});

export const availedLeaveFetch = (uuid, year) => ({
    type: AVAILED_LEAVES_FETCH_REQUEST,
    api: API.LEAVES.AVAILED_LEAVES,
    uuid,
    year,
});

export const manageLeaveFetch = () => ({
    type: MANAGE_LEAVE_FETCH_REQUEST,
});

export const clearLeavesData = () => ({
    type: CLEAR_LEAVES,
});

export const fetchLeavePlansForEmployee = uuid => ({
    type: LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST,
    api: API.LEAVES.LEAVE_PLANS_FOR_EMPLOYEE,
    uuid,
});
