import { Button } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    buttonCommonStyles: {
        borderRadius: '24px',
        padding: '6px 12px',
    },
    button: {
        backgroundColor: '#E6F2F9',
        color: '#4BADE9',
        '&:hover': {
            backgroundColor: '#CEE7F7',
        },
    },
    errorButton: {
        backgroundColor: '#EB6169',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#eb515a',
        },
    },
    selectedButton: {
        backgroundColor: '#5c9cc5',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4292c5',
        },
    },
    radioStyle: {
        border: '1px solid #e2e8f0',
    },
};

const DashboardButton = (props) => {
    const {
        children, onClick, classes, selected, type,
    } = props;

    return (
        <Button
            className={classNames(classes.buttonCommonStyles, type === 'error' && classes.errorButton, selected ? classes.selectedButton : classes.button, type === 'radio' && !selected && classes.radioStyle)}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

DashboardButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    classes: PropTypes.object,
    selected: PropTypes.bool,
    type: PropTypes.string,
};

DashboardButton.defaultProps = {
    children: null,
    onClick: () => {},
    classes: {},
    selected: false,
    type: null,
};


export default withStyles(styles)(DashboardButton);
