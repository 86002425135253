import API from '../../../constants/api';

export const FETCH_BATCHES_LIST_REQUEST = '@@batches/FETCH_BATCHES_LIST_REQUEST';
export const FETCH_BATCHES_LIST_FAILURE = '@@batches/FETCH_BATCHES_LIST_FAILURE';
export const FETCH_BATCHES_LIST_SUCCESS = '@@batches/FETCH_BATCHES_LIST_SUCCESS';

export const CREATE_BATCH_REQUEST = '@@batches/CREATE_BATCH_REQUEST';
export const CREATE_BATCH_FAILURE = '@@batches/CREATE_BATCH_FAILURE';
export const CREATE_BATCH_SUCCESS = '@@batches/CREATE_BATCH_SUCCESS';

export const CLEAR_BATCH_STATE = '@@batches/CLEAR_BATCH_STATE';

export const fetchPagesOfBatches = (page, size, filters, sortOrder, sortCol = 'dateCreated') => ({
    type: FETCH_BATCHES_LIST_REQUEST,
    api: API.BATCHES.PAGES,
    page,
    size,
    filters,
    sortOrder,
    sortCol,
});

export const createOrUpdateBatchRequest = (batchDto, api, successCallback, failureCallBack) => ({
    type: CREATE_BATCH_REQUEST,
    api,
    batchDto,
    successCallback,
    failureCallBack,
});

export const clearBatchesState = () => ({
    type: CLEAR_BATCH_STATE,
});
