import React from 'react';
import PropTypes from 'prop-types';
import OutlinedTextField from '../../../components/OutlinedTextField';

const ReduxFormNativeSelect = ({
    input,
    label,
    addRef,
    validations,
    menuItems,
    ...otherProps
}) => {
    const fieldName = input.name;
    return (
        <OutlinedTextField
            label={label}
            SelectProps={{
                native: true,
            }}
            {...input}
            {...otherProps}
            select
            name={fieldName}
            inputRef={(inputRef) => {
                if (addRef && typeof addRef === 'function' && validations && validations.required) {
                    addRef(inputRef, fieldName);
                }
            }}
        >
            {menuItems}
        </OutlinedTextField>
    );
};

ReduxFormNativeSelect.propTypes = {
    input: PropTypes.object,
    menuItems: PropTypes.node,
    label: PropTypes.any,
    addRef: PropTypes.func,
    validations: PropTypes.any,
};

ReduxFormNativeSelect.defaultProps = {
    input: {},
    menuItems: <div />,
    label: null,
    addRef: () => {},
    validations: {},
};

export default ReduxFormNativeSelect;

