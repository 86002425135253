import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import {
    clinicalInbox,
    missedList,
    myPatients,
    opdAppointments,
    opdQueue,
    openEncounters,
    patientRecent,
} from '../constants/constants';
import RecentPatientPage from '../pages/RecentPatientPage/RecentPatientPage';
import PageLoader from '../components/PageLoader/PageLoader';

const OpdQueuePage = React.lazy(() => import('../pages/OpdPage/OpdQueuePage'));
const DoctorsMainContainer = React.lazy(() => import('../containers/DoctorsMainContainer/DoctorsMainContainer'));
const OpdAppointmentsPage = React.lazy(() => import('../pages/OpdPage/OpdAppointmentsPage'));
const OpenEncountersPage = React.lazy(() => import('../pages/OpenEncountersPage/OpenEncountersPage'));
const MissedOPDQueue = React.lazy(() => import('../pages/MissedOPDQueue/MissedOPDQueue'));
const ClinicalInboxPage = React.lazy(() => import('../pages/ClinicalInboxPage/ClinicalInboxPage'));
const DoctorPatientsPage = React.lazy(() => import('../pages/DoctorPatientsPage/DoctorPatientsPage'));

const DoctorRoutes = props => (
    <DoctorsMainContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={opdQueue} exact component={OpdQueuePage} />
            <Route path={opdAppointments} exact component={OpdAppointmentsPage} />
            <Route path={patientRecent} exact component={RecentPatientPage} />
            <Route path={openEncounters} exact component={OpenEncountersPage} />
            <Route path={missedList} exact component={MissedOPDQueue} />
            <Route path={clinicalInbox} exact component={ClinicalInboxPage} />
            <Route path={myPatients} exact component={DoctorPatientsPage} />
        </Suspense>
    </DoctorsMainContainer>
);

DoctorRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

DoctorRoutes.defaultProps = {
    history: {},
};

export default DoctorRoutes;
