/**
 * Created by vinay on 7/1/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = theme => ({
    paper: {
        maxWidth: 400,
        overflow: 'visible',
    },
    popper: {
        zIndex: 9999,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
});

class MaterialUiPopper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrowRef: null,
        };
    }

    handleArrowRef = (node) => {
        this.setState({
            arrowRef: node,
        });
    };

    render() {
        const {
            open,
            anchorEl,
            children,
            placement,
            classes,
            arrow,
            handleClose,
            paperStyle,
            disablePortal,
        } = this.props;
        const { arrowRef } = this.state;
        const popper = (
            <Popper
                open={open}
                anchorEl={anchorEl || <div />}
                placement={placement}
                className={classes.popper}
                disablePortal={disablePortal}
                modifiers={{
                    arrow: {
                        enabled: arrow,
                        element: arrowRef,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                }}
            >
                {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                <Paper className={classes.paper} style={{ ...paperStyle }}>
                    {children}
                </Paper>
            </Popper>
        );
        if (anchorEl) {
            return (
                <ClickAwayListener onClickAway={handleClose}>
                    {popper}
                </ClickAwayListener>
            );
        }
        return popper;
    }
}

MaterialUiPopper.propTypes = {
    open: PropTypes.bool.isRequired,
    arrow: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    anchorEl: PropTypes.node,
    children: PropTypes.node.isRequired,
    placement: PropTypes.string,
    handleClose: PropTypes.func,
    paperStyle: PropTypes.object,
    disablePortal: PropTypes.bool,
};

MaterialUiPopper.defaultProps = {
    placement: 'bottom',
    arrow: true,
    disablePortal: true,
    anchorEl: null,
    paperStyle: {},
    handleClose: () => {},
};

export default withStyles(styles)(MaterialUiPopper);
