import API from '../../../constants/api';

export const FETCH_QUEUE_DATA_REQUEST = '@@queue/FETCH_QUEUE_DATA_REQUEST';
export const FETCH_QUEUE_DATA_SUCCESS = '@@queue/FETCH_QUEUE_DATA_SUCCESS';
export const FETCH_QUEUE_DATA_FAILURE = '@@queue/FETCH_QUEUE_DATA_FAILURE';

export const RESET_QUEUE_DATA = '@@queue/RESET_QUEUE_DATA';

export const SAVE_FILTERS = '@@queue/SAVE_FILTERS';

export const CLEAR_TO_INITIAL_STATE = '@@queue/CLEAR_TO_INITIAL_STATE';

export const fetchQueueList = (
    page,
    size,
    filters,
    searchFilter,
    approvalType,
    onlyClinical,
    seekDirection,
    seekParams,
    additionalFilters,
) => ({
    type: FETCH_QUEUE_DATA_REQUEST,
    api: API.QUEUE.FETCH_QUEUE,
    page,
    size,
    filters,
    searchFilter,
    approvalType,
    onlyClinical,
    seekDirection,
    seekParams,
    additionalFilters,
});

export const resetQueueData = () => ({
    type: RESET_QUEUE_DATA,
});

export const clearApprovalQueueState = () => ({
    type: CLEAR_TO_INITIAL_STATE,
});

export const saveFilters = (filters, isSearch) => ({
    type: SAVE_FILTERS,
    filters,
    isSearch,
});
