import React from 'react';
import axios from 'axios';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import uuidv4 from 'uuid/v4';

import { IntlFormattedMessage } from '../../../../components/IntlFormattedMessage';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isValidFunction,
    replaceValuesInString,
    roundedValueFixedToTwoDigits,
    stripIdent,
} from '../../../../constants/CommonUtil';
import { formValidationFunctions } from '../../FormComponents/RegistrationFormValidations';
import { insurancePayerTypes } from '../../../../components/PayerComponents/AddNewPayer/AddNewPayerUtil';
import API from '../../../../constants/api';
import { getLoggedInName } from '../../../../constants/state';
import { getDateInYYYYMMDDFormat, isDateAfter, parseDate, removeTimeFromDate } from '../../../../constants/DateUtil';
import { appointmentStatuses, secondaryIdentifierKey } from '../../../../constants/constants';
import { fetchVisitDetailsRequest } from '../../../../redux/modules/visitDetails/visitDetails-actions';
import { errorMessage } from '../../../../redux/modules/message/message-actions';
import { clearWarning, showConfirmationDialog } from '../../../../redux/modules/warningDialog/warningDialog-actions';
import { registrationBillingTableFields } from '../RegistrationBillingTable/RegistrationBillingTableUtil';
import { billingInitialValues } from '../../PatientDetailsForm/PatientDetailsFormUtil';
import {
    billingAmountPaidByCard,
    billingAmountPaidByOtherMode,
    billingCashReceivedField,
    billingOtherPaymentMode,
    billingPaymentModeField,
} from '../QuickRegBillingSummary/QuickRegBillingSummaryUtil';
import billingTabs from '../BillingTabContainer/BillingTabContainerUtil';
import { hideSpinner, showSpinner } from '../../../../redux/modules/spinner/spinner';
import { deductibleTypes } from '../../../../components/PayerComponents/AddInsuranceScheme/AddInsuranceSchemeUtil';
import { getStringFromObject, setStringPropertyToObject } from '../../../../constants/lodashUtils';
import { NumberOf } from '../../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../../constants/nullCheckUtils';

export const episodeProviderUuidFieldName = 'episodeProviderUuid';

export const quickRegistrationForm = 'quickRegistrationForm';

export const autoGeneratedEligibilityCodeFieldName = 'autoGeneratedEligibilityCode';

export const billingSectionFieldName = 'billing';
export const deletedBillingLinesSectionFieldName = 'deletedBillingLines';
export const patientExemptedFromTaxFieldName = 'patientExemptedFromTax';
export const patientSignatureFieldName = 'patientSignature';
export const tokenDetailsFieldName = 'tokenDetails';
export const isHighPriorityFieldName = 'isHighPriority';
export const ignoreTokenCreationFN = 'ignoreTokenCreation';
export const appointmentUuidFieldName = 'appointmentUuid';
export const payerTypeFieldName = 'payerType';
export const doctorFieldName = 'provider';
export const packageConsultationProductsFN = 'packageConsultationProducts';
// only used in packages with cons prod involved
// issue was.. user selects Jinaraj.. selects baladiya.. select doctor as jinaraj..
// the price is not becoming 0 as doctor value hasn't changed.. to overcome this, added this flag
export const reFetchConsultationProductFN = 'reFetchConsultationProduct';
export const mobileFieldName = 'mobile';
export const mobile2FieldName = 'mobile2';
export const serviceFieldName = 'service';
export const departmentFieldName = 'department';
export const episodeOfCareUuidFieldName = 'episodeOfCareUuid';
export const oldOrdersEpisodeOfCareFieldName = 'oldOrdersEpisodeOfCare';
export const patientUuidFieldName = 'patientUuid';
export const registrationDocumentsFieldName = 'registrationDocuments';
export const identifierFieldName = 'patientIdentifier';
export const visitTypeFieldName = 'visitType';
export const doctorSpecialityLevelFieldName = 'doctorSpecialityLevel';
export const schemeCardDetailsFieldName = 'schemeCardDetails';
// flag to not do epsiode checks
export const fromReferralsTab = 'fromReferralsTab';
// to trigger a patient token refetch
export const reFetchPatientTokensFieldName = 'reFetchPatientTokens';
export const hasOpenVisitFieldName = 'hasOpenVisit';
export const completeEpisodeDetailsFieldName = 'completeEpisodeDetails';
export const slotUuidFieldName = 'slotUuid';

export const forceCloseEncountersFieldName = 'forceCloseEncounters';

export const secondaryIdentifierFieldName = 'secondaryIdentifier';
export const maritalStatusFieldName = 'maritalStatus';
export const occupationFieldName = 'occupation';
export const idTypeFieldName = 'idType';
export const idNumberFieldName = 'idNumber';
export const receptionistNotesFieldName = 'receptionistNotes';

// DISCOUNT RELATED FIELDS
export const discountEmployeeFieldName = 'discountEmployee';
export const privilegeCardNumber = 'privilegeCardNumber';
export const discountTypeFieldName = 'discountType';
export const isStaffDiscountFieldName = 'isStaffDiscount';
export const otherDiscountsFieldName = 'otherDiscounts';
export const billingValuesWhenDiscountAppliedFieldName = 'billingValuesWhenDiscountApplied';

// ultrasound related queues
export const directQueueDoctorFieldName = 'directQueueDoctor';
export const directQueueDoctorServiceFieldName =
    'directQueueDoctorService';
export const ultrasoundSlotUuidFieldName =
    'ultrasoundSlotUuid';
export const bookForFutureDateFieldName = 'bookForFutureDate';

// queue edit doctor change fieldname
export const editedQueueDoctorFieldName = 'editedQueueDoctor';
export const editedQueueDoctorServiceFieldName = 'editedQueueDoctorService';
export const editedQueueDoctorDepartmentFieldName = 'editedQueueDoctorDepartment';
export const editingDoctorIndicatorFieldName = 'editingDoctorIndicator';

// service visit provider
export const advisorProviderFieldName = 'advisorProvider';
export const disableAdvisorProviderFieldName = 'disableAdvisorProvider';

export const maternityCaseFieldName = 'maternityCase';
export const episodeToRecycleFieldName = 'episodeToRecycle';

// a checkbox to auto click create new episode in reg screen (after selecting doctor)
export const createNewEpisodeFieldName = 'createNewEpisode';

export const registrationPaymentModes = {
    CASH: 'CASH',
    CARD: 'CARD',
    OTHERS: 'OTHERS',
};
const cashPayment = {
    value: registrationPaymentModes.CASH,
    text: <IntlFormattedMessage
        id="visitDetails.form.billing.section.payment.mode.cash.label"
        defaultMessage="Cash"
    />,
};
const cardPayment = {
    value: registrationPaymentModes.CARD,
    text: <IntlFormattedMessage
        id="visitDetails.form.billing.section.payment.mode.card.label"
        defaultMessage="Card"
    />,
};
const fullCredit = {
    value: 'Full Credit',
    text: <IntlFormattedMessage
        id="visitDetails.form.billing.section.payment.mode.full.credit.label"
        defaultMessage="Full Credit"
    />,
};

export const payerTypes = {
    CASH: 'CASH',
    INSURANCE: insurancePayerTypes.INSURANCE.value,
    CORPORATE: insurancePayerTypes.CORPORATE.value,
    ASSOCIATION: insurancePayerTypes.ASSOCIATION.value,
    COMPANY: insurancePayerTypes.COMPANY.value,
};

export const paymentOptionsForCustomerType = {
    [payerTypes.CASH]: [cashPayment, cardPayment],
    [payerTypes.INSURANCE]: [cashPayment, cardPayment],
    [payerTypes.CORPORATE]: [cashPayment, cardPayment],
    [payerTypes.ASSOCIATION]: [cashPayment, cardPayment],
    [payerTypes.COMPANY]: [fullCredit],
};

export const invoiceTypes = {
    CASH: 'CASH',
    INSURANCE: 'INSURANCE',
    CREDIT: 'CREDIT',
    ASSOCIATION_CREDIT: 'ASSOCIATION_CREDIT',
    CORPORATE: 'CORPORATE',
};
export const insuranceFields = {
    INSURANCE_SCHEME: 'insuranceScheme',
    INSURANCE_MEMBER_NUMBER: 'insuranceMemberNumber',
    INSURANCE_SCHEME_NUMBER: 'insuranceSchemeNumber',
    INSURANCE_POLICY_NUMBER: 'policyNumber',
    INSURANCE_PAYER: 'insurancePayer',
    SUB_INSURER: 'subinsurer',
    INSURANCE_EXPIRY: 'policyExpiry',
    MATERNITY_CASE: 'maternityCase',
    DESCRIPTION: 'description',
    WARNING_MESSAGE: 'warningMessage',
    VERIFIED: 'verified',
    CO_PAY_EDITABLE: 'coPayEditable',
    ELIGIBILITY_CODE: 'eligibilityCode',
    ELIGIBILITY_REQUEST_ID: 'eligibilityRequestId',
    COVERAGE_TYPE: 'coverageType',
    RELATIONSHIP_WITH_SUBSCRIBER: 'relationshipWithSubscriber',
    EBP_ENABLED: 'ebpEnabled',
    PAYER_EXPIRY: 'payerExpiry',
    EPISODE_OF_CARE_FORMULARY_TYPES: 'episodeOfCareFormularyTypes',
};

export const companyFields = {
    COMPANY: 'companyName',
    PAYER_EXPIRY: 'companyPayerExpiry',
    SAVE_META_DATA_AT_PATIENT_LEVEL: 'companySaveMetadataAtPatientLevel',
};
export const corporateFields = {
    CORPORATE: 'corporateName',
    PAYER_EXPIRY: 'corporatePayerExpiry',
    SAVE_META_DATA_AT_PATIENT_LEVEL: 'corporateSaveMetadataAtPatientLevel',
};
export const associationFields = {
    ASSOCIATION: 'associationName',
    PAYER_EXPIRY: 'associationPayerExpiry',
    SAVE_META_DATA_AT_PATIENT_LEVEL: 'associationSaveMetadataAtPatientLevel',
};

export const insurancePayerFieldForPayerType = {
    [payerTypes.COMPANY]: companyFields.COMPANY,
    [payerTypes.CORPORATE]: corporateFields.CORPORATE,
    [payerTypes.ASSOCIATION]: associationFields.ASSOCIATION,
};
export const insurancePayerExpiryFieldForPayerType = {
    [payerTypes.COMPANY]: companyFields.PAYER_EXPIRY,
    [payerTypes.CORPORATE]: corporateFields.PAYER_EXPIRY,
    [payerTypes.ASSOCIATION]: associationFields.PAYER_EXPIRY,
};

export const insurancePayerSaveMetadataAtPatientLevelFieldForPayerType = {
    [payerTypes.COMPANY]: companyFields.SAVE_META_DATA_AT_PATIENT_LEVEL,
    [payerTypes.CORPORATE]: corporateFields.SAVE_META_DATA_AT_PATIENT_LEVEL,
    [payerTypes.ASSOCIATION]: associationFields.SAVE_META_DATA_AT_PATIENT_LEVEL,
};

export const holdAndRecallType = 'REGISTRATION';
export const holdAndRecallOperations = {
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD',
    EXACT: 'EXACT',
};

export const getHoldAndRecallDtoFromFormValues = (formValues) => {
    console.log('asd09aj0dokpada', formValues);
    return {
        fileContents: JSON.stringify(formValues),
        type: holdAndRecallType,
    };
};

export const getValidationFunctions = (validations, validationFunctions, generateNewOrReturnOldFunction) => {
    let validate = [];
    if (validations) {
        Object.keys(validations).map((validation) => {
            if (validationFunctions[validation]) {
                if (
                    validation === 'required' ||
                    validation === 'email' ||
                    validation === 'number' ||
                    validation === 'dateOfBirth' ||
                    validation === 'phoneNumber' ||
                    validation === 'dateNotLessThanToday' ||
                    validation === 'landlineNumber'
                ) {
                    validate = validations[validation] ? validate.concat(validationFunctions[validation]) : validate;
                } else {
                    console.log('12398uijkmsad', validationFunctions[validation](validations[validation]));
                    validate =
                        validations[validation] ?
                            validate.concat(generateNewOrReturnOldFunction(validation, validations[validation])) :
                            validate;
                }
            }
            return null;
        });
    }
    return validate;
};

export const getValidationsOfFormSection = (schema, formSection) => {
    const formSectionSchema = getStringFromObject(`formSections.${formSection}`, schema);
    if (isObjectValidAndNotEmpty(formSectionSchema)) {
        const { fields } = formSectionSchema;
        const fieldsToValidate = {};
        if (isArrayValidAndNotEmpty(fields)) {
            const validationFunctions = { ...formValidationFunctions };
            const generateNewOrReturnOldFunction = (baseValidationFunctionName, valueForFunction) => {
                if (!validationFunctions[`${baseValidationFunctionName}-${valueForFunction}`]) {
                    if (validationFunctions[baseValidationFunctionName]) {
                        validationFunctions[`${baseValidationFunctionName}-${valueForFunction}`] =
                            validationFunctions[baseValidationFunctionName](valueForFunction);
                    }
                }
                return validationFunctions[`${baseValidationFunctionName}-${valueForFunction}`];
            };
            for (let i = 0; i < fields.length; i += 1) {
                const potentialFieldToValidate = fields[i];
                if (isObjectValidAndNotEmpty(potentialFieldToValidate)) {
                    if (isObjectValidAndNotEmpty(potentialFieldToValidate.validations)) {
                        // eslint-disable-next-line
                        const validationFuncs =
                            getValidationFunctions(
                                potentialFieldToValidate.validations,
                                validationFunctions,
                                generateNewOrReturnOldFunction,
                            );
                        fieldsToValidate[potentialFieldToValidate.name] = validationFuncs;
                    }
                }
            }
        }
        return fieldsToValidate;
    }
    return null;
};

export const validateFormSection = (validationFunctions, formValues) => {
    console.log('asdasu9dass', validationFunctions);
    if (isObjectValidAndNotEmpty(formValues) && isObjectValidAndNotEmpty(validationFunctions)) {
        const errorObject = {};
        const fieldNamesToValidate = Object.keys(validationFunctions);
        for (let i = 0; i < fieldNamesToValidate.length; i += 1) {
            const aFieldToValidate = fieldNamesToValidate[i];
            const formValueForField = formValues[aFieldToValidate];
            const fieldValidationFunctions = validationFunctions[aFieldToValidate];
            if (isArrayValidAndNotEmpty(fieldValidationFunctions)) {
                for (let j = 0; j < fieldValidationFunctions.length; j += 1) {
                    const fieldValidationFunction = fieldValidationFunctions[j];
                    if (isValidFunction(fieldValidationFunction)) {
                        console.log('asda0uda09dusad0s-', aFieldToValidate, formValueForField, fieldValidationFunction);
                        const validationResult = fieldValidationFunction(formValueForField);
                        if (validationResult) {
                            console.log('asd-a0idsa-0idas', validationResult);
                            errorObject[aFieldToValidate] = validationResult;
                        }
                    }
                }
            }
        }
        return errorObject;
    }
    return {};
};


export const insuranceStates = {
    NEED_APPROVAL: 'NEED_APPROVAL',
    NOT_COVERED: 'NOT_COVERED',
    COVERED: 'COVERED',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    LIMIT_EXCEEDED: 'LIMIT_EXCEEDED',
    DELAYED_APPROVED: 'DELAYED_APPROVED',
};

export const insuranceCoveredStates = [
    insuranceStates.COVERED,
    insuranceStates.LIMIT_EXCEEDED,
    insuranceStates.NEED_APPROVAL,
    insuranceStates.DELAYED_APPROVED,
];

export const insuranceNotCoveredStates = [
    insuranceStates.NOT_APPLICABLE,
    insuranceStates.NOT_COVERED,
];

export const episodeProviderTypes = {
    REFERRAL: 'REFERRAL',
    TRANSFER: 'TRANSFER',
};


export const getRegistrationDocsForSubmission = (values) => {
    const registrationDocs = values[registrationDocumentsFieldName];
    if (isObjectValidAndNotEmpty(registrationDocs)) {
        let registrationDocuments = [];
        Object.keys(registrationDocs).map((aDocType) => {
            const docsOfDocType = registrationDocs[aDocType];
            if (isArrayValidAndNotEmpty(docsOfDocType)) {
                registrationDocuments = registrationDocuments.concat(docsOfDocType);
            }
            return null;
        });
        return registrationDocuments || [];
    }
    return [];
};

export const getPatientDetailsInFormWithScannedData = async (cardScanHelper, cardData) => {
    const mapperUtil = getStringFromObject('mapperUtil', cardScanHelper);
    const initialValues = {};
    if (isArrayValidAndNotEmpty(mapperUtil)) {
        for (let i = 0; i < mapperUtil.length; i += 1) {
            const aFieldToFill = mapperUtil[i];
            console.log('hi rejin', aFieldToFill.fieldToFill, new Date().getTime());
            if (aFieldToFill.isMultiple) {
                initialValues[aFieldToFill.fieldToFill] =
                    (stripIdent(replaceValuesInString(aFieldToFill.valueToFill, cardData, 'curly')) || '').trim();
            } else if (aFieldToFill.isPhoto) {
                // handle photo
                const photoBase64 =
                    `data:image/jpeg;base64,${getStringFromObject(aFieldToFill.valueToFill, cardData)}`;
                // upload the photo.. concat to that row.
                const headers = {
                    'Content-Type': 'text/plain',
                };
                // eslint-disable-next-line
                const response = await axios.post(API.FILE.BASE_64, photoBase64, { headers });
                const fileName = getStringFromObject('data[0].name', response, '');
                const fieldToFillSplit = (getStringFromObject('fieldToFill', aFieldToFill) || '').split('.');
                const regDocumentType =
                    isArrayValidAndNotEmpty(fieldToFillSplit) ? fieldToFillSplit[fieldToFillSplit.length - 1] : 'ID';
                const regDocObject = {
                    uploadedDocument: fileName,
                    registrationDocumentType: regDocumentType,
                    uploadedTime: new Date().getTime(),
                    uploader: { value: getLoggedInName() },
                    uiUuid: uuidv4(),
                };
                const currentValuesForUploadSection =
                    getStringFromObject(aFieldToFill.fieldToFill, initialValues);
                if (isArrayValidAndNotEmpty(currentValuesForUploadSection)) {
                    setStringPropertyToObject(
                        aFieldToFill.fieldToFill,
                        initialValues,
                        currentValuesForUploadSection.concat(regDocObject),
                    );
                } else {
                    setStringPropertyToObject(`${aFieldToFill.fieldToFill}[0]`, initialValues, regDocObject);
                }
                console.log('rejin some data after upload', initialValues);
            } else if (aFieldToFill.isDate) {
                const dateString = getStringFromObject(aFieldToFill.valueToFill, cardData);
                const date = parseDate(dateString, aFieldToFill.dateFormat);
                initialValues[aFieldToFill.fieldToFill] = getDateInYYYYMMDDFormat(date);
            } else if (aFieldToFill.apiFetch) {
                const valueCode = getStringFromObject(aFieldToFill.valueToFill, cardData);
                // eslint-disable-next-line
                const valueForField = await axios.get(`${aFieldToFill.apiFetch}${valueCode}`);
                let valueToFill = valueForField.data;
                if (aFieldToFill.fieldToFillFromResult) {
                    valueToFill = getStringFromObject(aFieldToFill.fieldToFillFromResult, valueToFill);
                }
                initialValues[aFieldToFill.fieldToFill] = valueToFill;
            } else {
                initialValues[aFieldToFill.fieldToFill] =
                    stripIdent(getStringFromObject(aFieldToFill.valueToFill, cardData));
            }
        }
        console.log('rejin 3', JSON.stringify(initialValues));
    }
    return initialValues;
};

export const selectPatientForRegistration = (
    patientUuid,
    appointmentUuid,
    appointmentStatus,
    props,
    scannedInitialValues,
) => {
    const {
        change,
        dispatch,
    } = props;
    let appointmentUuidVal = appointmentUuid;
    if (appointmentStatus !== appointmentStatuses.CONFIRMED) {
        appointmentUuidVal = null;
    }

    change(appointmentUuidFieldName, appointmentUuidVal);
    console.log('asdsa-d0ais-d0sia0-dad', patientUuid);
    dispatch(
        fetchVisitDetailsRequest(
            patientUuid,
            2, // todo change
            appointmentUuid,
            true,
            scannedInitialValues,
        ),
    );
};

export const doctorSpecialityLevel = {
    GENERAL: 'GENERAL',
    SPECIALIST: 'SPECIALIST',
    CONSULTANT: 'CONSULTANT',
};

export const doctorDepartments = {
    GYNAECOLOGY: 'Gynaecology',
    DENTAL: 'Dental',
};

export const validateDirectConsultation = (values, dispatcher, showWarning) => {
    const schemeDetails = getStringFromObject(schemeCardDetailsFieldName, values) || {};
    const doctorSpecialityType = getStringFromObject(doctorSpecialityLevelFieldName, values);
    const maternityCase = getStringFromObject(maternityCaseFieldName, values);
    const episodeOfCareUuid = getStringFromObject(episodeOfCareUuidFieldName, values);
    const department = getStringFromObject(departmentFieldName, values);
    console.log('asd-0', maternityCase);
    const {
        directSpecialistConsultationAllowed,
        directConsultantConsultationAllowed,
        maternityCaseCovered,
        dentalCovered,
    } = schemeDetails;
    let actionToDispatch = errorMessage;
    if (showWarning) {
        actionToDispatch = showConfirmationDialog;
    }
    const callBack = () => {
        dispatcher(clearWarning());
    };
    let error = '';
    const solutionToProblem = 'Please Change Payer To Cash Or Change The Doctor';
    if (!episodeOfCareUuid) {
        if (
            doctorSpecialityType === doctorSpecialityLevel.CONSULTANT &&
            !directConsultantConsultationAllowed
        ) {
            // direct consultant not allowed
            error = `Direct Consultant Consultation Not Allowed By Policy. ${solutionToProblem}`;
            dispatcher(actionToDispatch(error, 'WARNING', callBack));
        } else if (
            doctorSpecialityType === doctorSpecialityLevel.SPECIALIST &&
            !directSpecialistConsultationAllowed
        ) {
            // no episode -> the doc is the primary doctor..
            // direct speciality not allowed
            error = `Direct Speciality Consultation Not Allowed By Policy. ${solutionToProblem}`;
        }
    }
    if (isObjectValidAndNotEmpty(department)) {
        const departmentName = getStringFromObject('value', department);
        if (
            (doctorDepartments.DENTAL === departmentName) &&
            !dentalCovered
        ) {
            error = 'Dental Not Covered By Insurance';
        }
        if (
            (doctorDepartments.GYNAECOLOGY === departmentName) &&
            !maternityCaseCovered && (maternityCase === 'true')
        ) {
            error = 'Maternity Not Covered By Insurance';
        }
    }
    if (error) {
        dispatcher(actionToDispatch(error, 'WARNING', callBack));
        dispatcher(hideSpinner());
        return true;
    }
    return false;
};

export const getRemainingCopay = (formValues) => {
    const billProducts = getStringFromObject(billingSectionFieldName, formValues);
    if (!isArrayValidAndNotEmpty(billProducts)) {
        return 0;
    }
    const maxCopay = getStringFromObject(`${schemeCardDetailsFieldName}.maxCoPay`, formValues);
    const dailyMaxCoPay = NumberOf(getStringFromObject(`${schemeCardDetailsFieldName}.dailyMaxCoPay`, formValues));
    console.log('sand-00-dre-22', dailyMaxCoPay);
    if (NumberOf(maxCopay) <= 0 && NumberOf(dailyMaxCoPay) <= 0) {
        return Number.MAX_SAFE_INTEGER;
    }
    let totalBilledInCashInEpisode = 0;
    const rowWithTotalBilledInCash = billProducts.find(aRow => isObjectValidAndNotEmpty(aRow) && aRow.totalBilledInCashInEpisode != null);
    if (isObjectValidAndNotEmpty(rowWithTotalBilledInCash)) {
        totalBilledInCashInEpisode =
            NumberOf(getStringFromObject('totalBilledInCashInEpisode', rowWithTotalBilledInCash));
    }
    const rowWithtotalBilledInCashInEpisodeAndDate =
        billProducts.find(aRow => isObjectValidAndNotEmpty(aRow) && aRow.totalBilledInCashInEpisodeAndDate != null);
    let totalBilledInCashInEpisodeAndDate = 0;
    if (isObjectValidAndNotEmpty(rowWithtotalBilledInCashInEpisodeAndDate)) {
        totalBilledInCashInEpisodeAndDate =
            NumberOf(getStringFromObject('totalBilledInCashInEpisodeAndDate', rowWithtotalBilledInCashInEpisodeAndDate));
    }
    const remainingCoPay = NumberOf(maxCopay) - NumberOf(roundedValueFixedToTwoDigits(totalBilledInCashInEpisode));
    const remainingDailyCoPay = NumberOf(dailyMaxCoPay) - NumberOf(roundedValueFixedToTwoDigits(totalBilledInCashInEpisodeAndDate));
    let copayToCompare = null;
    let maxCopayReachedInThisBill = false;
    if (NumberOf(maxCopay) <= 0 && NumberOf(dailyMaxCoPay) > 0) {
        if (remainingDailyCoPay <= 0) {
            copayToCompare = 0;
            maxCopayReachedInThisBill = true;
        } else {
            copayToCompare = remainingDailyCoPay;
        }
    }
    if (NumberOf(maxCopay) > 0 && NumberOf(dailyMaxCoPay) <= 0) {
        if (remainingCoPay <= 0) {
            maxCopayReachedInThisBill = true;
            copayToCompare = 0;
        } else {
            copayToCompare = remainingCoPay;
        }
    }
    if (NumberOf(maxCopay) > 0 && NumberOf(dailyMaxCoPay) > 0) {
        if (remainingCoPay <= 0 || remainingDailyCoPay <= 0) {
            maxCopayReachedInThisBill = true;
            copayToCompare = 0;
        }
        // happens only if both are possitive
        if (!maxCopayReachedInThisBill && remainingCoPay < remainingDailyCoPay) {
            copayToCompare = remainingCoPay;
        } else {
            copayToCompare = remainingDailyCoPay;
        }
    }
    return copayToCompare;
};

export const checkIfMaxCopayReached = (formValues, formValueChanger, dispatcher, consultationCategories) => {
    console.log('asd-a0isd-0iasd[asdad', formValues, formValueChanger);
    const billProducts = getStringFromObject(billingSectionFieldName, formValues);
    let maxCopayReachedInThisBill = false;
    let copayPercentChanged = false;
    if (isArrayValidAndNotEmpty(billProducts)) {
        const callBack = () => {
            dispatcher(clearWarning());
        };
        const dailyMaxCopayN = getStringFromObject(`${schemeCardDetailsFieldName}.dailyMaxCoPay`, formValues);
        const maxCopayN = getStringFromObject(`${schemeCardDetailsFieldName}.maxCoPay`, formValues);
        if (NumberOf(maxCopayN) <= 0 && NumberOf(dailyMaxCopayN) <= 0) {
            return {
                copayPercentChanged,
                maxCopayReachedInThisBill,
            };
        }
        let remainingCoPay = getRemainingCopay(formValues);
        console.log('asd-s0a-da0aod', maxCopayN, remainingCoPay);
        // only consider if the remaining copay is greater than 0..
        // if it was less than 0, then we don't need to do anything
        // as the backend will handle it in the initial fetch
        // we only need to check this when the total copay paid is less than max copay
        // we need to check if the lines currently in the
        // form would together make the total copay paid to exceed max copay
        if (remainingCoPay >= 0) {
            billProducts.forEach(((aBillLine, index) => {
                if (isObjectValidAndNotEmpty(aBillLine)) {
                    const isConsultationProduct =
                        consultationCategories.includes(getStringFromObject('productCategory.key', aBillLine));
                    console.log('asd-au9-a0sdusa0das', isConsultationProduct);
                    if (
                        aBillLine[registrationBillingTableFields.PRODUCT_NAME] &&
                        // consultation product shouldn't be considered right now..
                        // as the deductible must always be collected
                        !isConsultationProduct &&
                        !(insuranceNotCoveredStates.includes(aBillLine.insuranceStatus))
                    ) {
                        const price =
                            getStringFromObject(registrationBillingTableFields.PRICE, aBillLine);
                        const quantity =
                            getStringFromObject(registrationBillingTableFields.QUANTITY, aBillLine);
                        const discountPercent =
                            getStringFromObject(registrationBillingTableFields.DISCOUNT_PERCENTAGE, aBillLine);
                        const currentLineCopayPercent =
                            NumberOf(getStringFromObject(registrationBillingTableFields.COPAY_PERCENTAGE, aBillLine));
                        const originalCoPayPercent =
                            getStringFromObject('copayAsPerScheme', aBillLine);
                        const netPrice =
                            NumberOf(roundedValueFixedToTwoDigits(price * ((100 - discountPercent) / 100) * quantity));
                        const originalCoPayAmount =
                            NumberOf(roundedValueFixedToTwoDigits(netPrice * (originalCoPayPercent / 100)));
                        const insuranceStatus = getStringFromObject('insuranceStatus', aBillLine);
                        if (insuranceCoveredStates.includes(insuranceStatus)) {
                            if (remainingCoPay > 0) {
                                if (roundedValueFixedToTwoDigits(remainingCoPay - originalCoPayAmount) < 0) {
                                    // that means max copay reached because of this copay amount
                                    // i need to set the copay of this line to the remaining copay leftover
                                    // change copay amt to remaining copay
                                    const copayPercent = roundedValueFixedToTwoDigits((remainingCoPay / netPrice) * 100);
                                    formValueChanger(
                                        `${billingSectionFieldName}[${index}].originalCoPayPercent`,
                                        copayPercent,
                                    );
                                    formValueChanger(
                                        // eslint-disable-next-line
                                        `${billingSectionFieldName}[${index}].${registrationBillingTableFields.COPAY_AMOUNT}`,
                                        (remainingCoPay / quantity),
                                    );
                                    maxCopayReachedInThisBill = true;
                                    copayPercentChanged = true;
                                } else if (currentLineCopayPercent !== originalCoPayPercent) {
                                    // reset to original copay as per scheme
                                    console.log('asdad0-9as-d0sada', currentLineCopayPercent, originalCoPayPercent);
                                    formValueChanger(
                                        `${billingSectionFieldName}[${index}].originalCoPayPercent`,
                                        originalCoPayPercent,
                                    );
                                    const finalCopayAmount = (originalCoPayAmount / quantity);
                                    formValueChanger(
                                        `${billingSectionFieldName}[${index}].${registrationBillingTableFields.COPAY_AMOUNT}`,
                                        finalCopayAmount,
                                    );
                                    copayPercentChanged = true;
                                }
                                remainingCoPay -= originalCoPayAmount;
                            } else {
                                // change the copay to 0
                                formValueChanger(
                                    `${billingSectionFieldName}[${index}].originalCoPayPercent`,
                                    0,
                                );
                                formValueChanger(
                                    `${billingSectionFieldName}[${index}].${registrationBillingTableFields.COPAY_AMOUNT}`,
                                    0,
                                );
                                maxCopayReachedInThisBill = true;
                                copayPercentChanged = true;
                            }
                        }
                    }
                }
            }));
        }
        if (maxCopayReachedInThisBill) {
            dispatcher(showConfirmationDialog(
                'Max Copay Reached. Please review the billing lines and proceed',
                'WARNING',
                callBack,
            ));
        }
        // we need to check if the patient has already paid max copay yet.. if they have paid max copay then
        // the rest of the items should have 0 copay
    }
    return {
        copayPercentChanged,
        maxCopayReachedInThisBill,
    };
};

export const insuranceRelatedValidations = (formValues, dispatcher, formValueChanger) => {
    const schemeDetails = getStringFromObject(schemeCardDetailsFieldName, formValues) || {};
    const episodeProviderUuid = getStringFromObject(episodeProviderUuidFieldName, formValues);
    console.log('---2000299', schemeDetails, episodeProviderUuid, dispatcher);
    // no episode -> the doc is the primary doctor..
    if (validateDirectConsultation(formValues, dispatcher)) {
        return true;
    }
    // todo check rounding errors
    if (false && checkIfMaxCopayReached(formValues, formValueChanger, dispatcher).maxCopayReachedInThisBill) {
        return true;
    }
    return false;
};

export const checkEpisodeValidityAndFollowUpCount = (episodeDetails, hasOpenVisit) => {
    let episodeErrorMessage = '';
    if (episodeDetails.followUpCount <= 0 && !hasOpenVisit) {
        episodeErrorMessage = 'No More Follow Ups Allowed For Selected EoC. Creating a new EoC';
    }
    if (isDateAfter(removeTimeFromDate(new Date()), new Date(episodeDetails.episodeExpiry))) {
        // episode expired
        episodeErrorMessage = 'Selected Episode Has Expired.. Creating a new EoC';
    }
    return episodeErrorMessage;
};

export const clearDoctorDetails = (changer) => {
    changer(doctorFieldName, null);
    changer(serviceFieldName, null);
    changer(departmentFieldName, null);
    changer(tokenDetailsFieldName, null);
    changer(editedQueueDoctorFieldName, null);
    changer(editedQueueDoctorServiceFieldName, null);
    changer(editedQueueDoctorDepartmentFieldName, null);
    changer(editingDoctorIndicatorFieldName, null);
    changer(slotUuidFieldName, null);
    changer(advisorProviderFieldName, null);
    changer(disableAdvisorProviderFieldName, false);
};

export const clearPaymentDetails = (change) => {
    change(billingCashReceivedField, null);
    change(billingAmountPaidByCard, null);
    change(billingAmountPaidByOtherMode, null);
    change(billingOtherPaymentMode, null);
    change(billingPaymentModeField, null);
};

export const clearRegistrationScreenFields = (change, payerType, visitType, consultationVisitType) => {
    change(billingSectionFieldName, billingInitialValues(payerType, visitType, consultationVisitType));
    change(deletedBillingLinesSectionFieldName, []);
    clearDoctorDetails(change);
    clearPaymentDetails(change);
    change(otherDiscountsFieldName, null);
    change(discountEmployeeFieldName, null);
    change(discountTypeFieldName, null);
    change(isStaffDiscountFieldName, null);
    change(receptionistNotesFieldName, null);
};

const payerFieldsToClear = {
    [payerTypes.CASH]: [
        ...Object.values(insuranceFields),
        ...Object.values(companyFields),
        ...Object.values(associationFields),
    ],
    [payerTypes.INSURANCE]: [
        ...Object.values(companyFields),
        ...Object.values(associationFields),
        ...Object.values(corporateFields),
    ],
    [payerTypes.COMPANY]: [
        ...Object.values(insuranceFields),
        ...Object.values(associationFields),
        ...Object.values(corporateFields),
    ],
    [payerTypes.ASSOCIATION]: [
        ...Object.values(insuranceFields),
        ...Object.values(companyFields),
        ...Object.values(corporateFields),
    ],
    [payerTypes.CORPORATE]: [
        ...Object.values(insuranceFields),
        ...Object.values(companyFields),
        ...Object.values(associationFields),
    ],
};

export const clearFields = (formValues, fieldsToClear) => {
    const newFormValues = cloneDeep(formValues);
    if (isArrayValidAndNotEmpty(fieldsToClear)) {
        for (let i = 0; i < fieldsToClear.length; i += 1) {
            setStringPropertyToObject(fieldsToClear[i], newFormValues, null);
        }
    }
    return newFormValues;
};

export const clearUnwantedPayerFields = formValues => (
    clearFields(formValues, payerFieldsToClear[formValues[payerTypeFieldName]])
);

export const setEpisodeOfCareFieldValue = (changer, value) => {
    if (isValidFunction(changer)) {
        changer(episodeOfCareUuidFieldName, value);
        changer(oldOrdersEpisodeOfCareFieldName, null);
        changer(episodeToRecycleFieldName, null);
    }
};


export const servicesSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'services',
    showInNonEditableMode: true,
    hideCheckBoxIcon: true,
    showInReadOnlyMode: true,
    tableCells: [
        {
            id: 'Service',
            name: 'service',
            find: 'levels.service.value',
            label: 'Service',
            fieldType: 'text',
        },
        {
            id: 'Department',
            name: 'Department',
            find: 'levels.department.value',
            label: 'Department',
            fieldType: 'text',
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};

export const selectTheService = (serviceOfDoctor, change, closeDialog) => {
    const service = getStringFromObject('levels.service', serviceOfDoctor);
    const department = getStringFromObject('levels.department', serviceOfDoctor);
    const provider = getStringFromObject('levels.provider', serviceOfDoctor);
    change(departmentFieldName, department);
    change(serviceFieldName, service);
    change(doctorFieldName, provider);
    if (isValidFunction(closeDialog)) {
        closeDialog();
    }
};

export const openableDialogs = {
    SEARCH: 'search',
    UPLOAD: 'upload',
    HISTORY: 'history',
    UCAF_PRINT: 'ucaf',
    EPISODE_RECYCLE: 'episodeRecycle',
    DOCTOR_QUEUE: 'doctorQueue',
    PATIENT_REMARK: 'patientRemark',
    BLOCK_PATIENT: 'blockPatient',
    GENERAL_QUEUE: 'generalQueue',
    PATIENT_EPISODES: 'patientEpisodes',
    PATIENT_PACKAGES: 'patientPackagesDialog',
    UNINVOICED_ORDERS: 'uninvoicedOrders',
    ORDER_ON_BEHALF: 'orderOnBehalf',
    PRIVILEGE_CARD: 'privilegeCard',
    KIOSK_PATIENT: 'kioskPatient',
    RECALL_LIST: 'recallList',
    PAID_APPOINTMENTS: 'paidAppointments',
    INVOICE_DOCUMENTS: 'invoiceDocumentPrintDialog',
    SIDE_MENU_PRINT: 'sideMenuPrintDialog',
};

export const initialState = {
    printInvoice: false,
    ordersOfOrderTypeWithDoctorNeeded: {},
    loading: false,
    initialValuesOfForm: {},
    enablePrintButton: false,
    usDoctorSelect: false,
    [openableDialogs.SEARCH]: false,
    [openableDialogs.UPLOAD]: false,
    [openableDialogs.HISTORY]: false,
    [openableDialogs.UCAF_PRINT]: false,
    [openableDialogs.EPISODE_RECYCLE]: false,
    [openableDialogs.PATIENT_EPISODES]: false,
    [openableDialogs.UNINVOICED_ORDERS]: false,
    [openableDialogs.PATIENT_PACKAGES]: false,
    printData: {},
    holdAndRecallId: null,
    patientPackages: [],
    openInvoicesForPatient: [],
    servicesOfDoctor: [],
    anchorEl: null,
    priceEditingSettings: null,
    cardScanHelper: {},
    appointmentDoctorBillableProduct: null,
    printCard: false,
    disableDoctorEntry: false,
    refetchCounts: false,
    tabValue: billingTabs.NEW.value,
    printSideMenuItems: null,
    invoiceDocumentPrintDialog: false,
    invoiceDocumentPrintList: null,
    printMultipleInvoices: null,
    sideMenuPrintDialog: false,
    sideMenuPrintDocument: null,
};

// eslint-disable-next-line
export const registrationAsyncValidator = (values, dispatcher, props, field) => {
    console.log('asdjoapdsmsadafasf', values, props);
    // const allowIdentifierEdit = getStringFromObject('appConfiguration.allowIdentifierEdit', props);

    // const primaryIdentifier = getStringFromObject(identifierFieldName, values);
    const secondaryIdentifier = getStringFromObject(secondaryIdentifierFieldName, values);

    const patientUuid = getStringFromObject(patientUuidFieldName, values) || '';
    const secondaryIdValidApi =
        `${API.PATIENT.VALIDATE_SECONDARY_IDENTIFIER}${patientUuid}&secondaryIdentifier=${secondaryIdentifier}`;
    // const primaryIdValidApi =
    //     `${API.PATIENT.VALIDATE_PRIMARY_IDENTIFIER}${patientUuid}&primaryIdentifier=${primaryIdentifier}`;
    if (field === secondaryIdentifierFieldName) {
        return axios.get(secondaryIdValidApi)
            .then((response) => {
                if (secondaryIdentifier && !response.data) {
                    // eslint-disable-next-line
                    dispatcher(errorMessage('National ID Already Exists'));
                }
            })
            .catch((e) => {
                apiCatchBlockFunction(e, dispatcher);
            });
    } /* else if (field === identifierFieldName && allowIdentifierEdit) {
        return axios.get(primaryIdValidApi)
            .then((response) => {
                if (primaryIdentifier && !response.data) {
                    // eslint-disable-next-line
                    dispatcher(errorMessage('This File No Already Exists'));
                }
            });
    } */
    dispatcher(showSpinner());
    return axios.get(secondaryIdValidApi)
        .then((response2) => {
            if (secondaryIdentifier && !response2.data) {
                // eslint-disable-next-line
                dispatcher(errorMessage('National ID Already Exists'));
            }
            dispatcher(hideSpinner());
        })
        .catch((e) => {
            apiCatchBlockFunction(e, dispatcher);
        });
    /* if (allowIdentifierEdit) {
        return axios.get(primaryIdValidApi)
            .then((response) => {
                dispatcher(hideSpinner());
                if (primaryIdentifier && !response.data) {
                    // eslint-disable-next-line
                    dispatcher(errorMessage('This File No Already Exists'));
                }
                if (secondaryIdentifier) {
                    axios.get(secondaryIdValidApi)
                        .then((response2) => {
                            if (secondaryIdentifier && !response2.data) {
                                // eslint-disable-next-line
                                dispatcher(errorMessage('CPR Already Exists'));
                            }
                        });
                }
            });
    } */ /* else if (secondaryIdentifier) {
        axios.get(secondaryIdValidApi)
            .then((response2) => {
                if (secondaryIdentifier && !response2.data) {
                    // eslint-disable-next-line
                    dispatcher(errorMessage('CPR Already Exists'));
                }
            });
    } */
};

export const getEpisodeType = (formValues) => {
    let episodeType = deductibleTypes.NORMAL;
    const maternityCase = getStringFromObject(insuranceFields.MATERNITY_CASE, formValues);
    const departmentName = getStringFromObject(`${departmentFieldName}.value`, formValues);
    console.log('asda-as0dia-da', departmentName, maternityCase);
    if ((maternityCase === 'true' || maternityCase === true) && departmentName === doctorDepartments.GYNAECOLOGY) {
        episodeType = deductibleTypes.MATERNITY;
    }
    return episodeType;
};

export const selectDoctor = (selectedOption, change, payerType) => {
    console.log('asd-9aud0asud-0akidasd[ada', selectedOption);
    const doctor = getStringFromObject('levels.provider', selectedOption);
    const service = getStringFromObject('levels.service', selectedOption);
    const department = getStringFromObject('levels.department', selectedOption);
    change(episodeProviderUuidFieldName, null);
    change(slotUuidFieldName, null);
    change(fromReferralsTab, false);
    change(departmentFieldName, department);
    change(serviceFieldName, service);
    change(doctorFieldName, doctor);
    // if doctor name was removed, then clear the billing table
    if (!doctor) {
        change(billingSectionFieldName, billingInitialValues(payerType));
        change(deletedBillingLinesSectionFieldName, []);
        change(packageConsultationProductsFN, []);
    }
};

export const getTaxExemptFieldsAndValues = (
    taxExemptionAttributes,
    taxExemptionHelper,
    taxExemptAttributeFields = [],
    taxExemptAttributeValues = [],
) => {
    let taxExemptAttributeFieldsCopy = cloneDeep(taxExemptAttributeFields) || [];
    let taxExemptAttributeValuesCopy = cloneDeep(taxExemptAttributeValues) || [];
    if (
        isObjectValidAndNotEmpty(taxExemptionAttributes) &&
        isObjectValidAndNotEmpty(taxExemptionHelper)
    ) {
        const {
            valueHelper,
            fieldHelper,
        } = taxExemptionHelper;
        if (taxExemptionAttributes.type === 'criteria') {
            const {
                key,
                operation,
                value,
            } = taxExemptionAttributes;
            const baseTable = key.split('.')[0];
            taxExemptAttributeFieldsCopy = taxExemptAttributeFieldsCopy.concat(fieldHelper[baseTable]);
            taxExemptAttributeValuesCopy = taxExemptAttributeValuesCopy.concat({
                field: valueHelper[key],
                operation,
                value,
            });
        } else if (taxExemptionAttributes.type === 'combineMode') {
            let taxFV = getTaxExemptFieldsAndValues(
                taxExemptionAttributes.left,
                taxExemptionHelper,
            );
            taxExemptAttributeValuesCopy = taxFV.taxExemptAttributeValues;
            taxExemptAttributeFieldsCopy = taxFV.taxExemptAttributeFields;
            taxFV = getTaxExemptFieldsAndValues(
                taxExemptionAttributes.right,
                taxExemptionHelper,
            );
            taxExemptAttributeValuesCopy = taxFV.taxExemptAttributeValues;
            taxExemptAttributeFieldsCopy = taxFV.taxExemptAttributeFields;
        }
    }
    return {
        taxExemptAttributeFields: taxExemptAttributeFieldsCopy,
        taxExemptAttributeValues: taxExemptAttributeValuesCopy,
    };
};

export const isPatientExemptedFromTax = (
    formValues,
    taxExemptionAttributes,
    taxExemptionHelper,
) => {
    const {
        taxExemptAttributeValues,
    } = getTaxExemptFieldsAndValues(taxExemptionAttributes, taxExemptionHelper);
    console.log(
        'asidhada09a0sda0sd',
        formValues,
        getTaxExemptFieldsAndValues(taxExemptionAttributes, taxExemptionHelper),
    );
    if (isArrayValidAndNotEmpty(taxExemptAttributeValues)) {
        for (let i = 0; i < taxExemptAttributeValues.length; i += 1) {
            const {
                field,
                operation,
                value,
            } = taxExemptAttributeValues[i];
            const theValue = getStringFromObject(field, formValues);
            let conditionPassed = false;
            if (operation === '=') {
                conditionPassed = theValue === value;
            } else if (operation === '!=') {
                conditionPassed = theValue !== value;
            }
            if (!conditionPassed) {
                return false;
            }
        }
        return true;
    }
    // this stores which all fields are
    return false;
};

export const hasTaxExemptableAttributesChanged = (
    newFormValues,
    oldFormValues,
    taxExemptionAttributes,
    taxExemptionHelper,
) => {
    const {
        taxExemptAttributeFields,
    } = getTaxExemptFieldsAndValues(taxExemptionAttributes, taxExemptionHelper);
    console.log('asda-d0ais-0asd', taxExemptionAttributes, taxExemptionHelper, taxExemptAttributeFields);
    if (isArrayValidAndNotEmpty(taxExemptAttributeFields)) {
        for (let i = 0; i < taxExemptAttributeFields.length; i += 1) {
            const taxExemptField = taxExemptAttributeFields[i];
            const oldValueOfField = getStringFromObject(taxExemptField, oldFormValues);
            const newValueOfField = getStringFromObject(taxExemptField, newFormValues);
            if (!deepEqual(oldValueOfField, newValueOfField)) {
                return true;
            }
        }
    }
    return false;
};

export const performSecondaryIdentifierValidation = (values, registrationSchema, props) => {
    const secondaryIdentifier = getStringFromObject(secondaryIdentifierFieldName, values);
    const performSecondaryIdentifierNotMandatoryValidation =
        getStringFromObject('performSecondaryIdentifierNotMandatoryValidation', registrationSchema);

    if (!secondaryIdentifier && performSecondaryIdentifierNotMandatoryValidation) {
        const idType = getStringFromObject(idTypeFieldName, values);
        const idCardNumber = getStringFromObject(idNumberFieldName, values);
        console.log('asda-0dias-ai-a0', idType, idCardNumber);
        if (!(idType && idCardNumber)) {
            const secondaryIdentifierLabel =
                getStringFromObject(`appConfiguration.${secondaryIdentifierKey}`, props);
            props.dispatch(
                errorMessage(
                    `${secondaryIdentifierLabel} or Other ID Details Mandatory To Proceed`,
                ),
            );
            props.dispatch(hideSpinner());
            return false;
        }
    }
    return true;
};

export const onPayerChange = (changedValue, formValues, changer) => {
    console.log('asd09aud0aduasdasdas22123123120asd', changedValue, formValues, changer);
    const episodeOfCareUuid = formValues[episodeOfCareUuidFieldName];
    const episodeToRecycleUuid = formValues[episodeToRecycleFieldName];
    if (episodeOfCareUuid && !episodeToRecycleUuid) {
        // we deselect the episode
        setEpisodeOfCareFieldValue(changer, null);
        changer(createNewEpisodeFieldName, false);
    }
};

export const isDoctorOptionDisabledForSelect = (ultrasoundDepartmentUuid, ordersDepartmentUuid) => (row) => {
    console.log('asdjuas-0dia-d0a', row);
    return (
        !getStringFromObject('levels.available', row) ||
        getStringFromObject('levels.department.key', row) === ultrasoundDepartmentUuid ||
        getStringFromObject('levels.department.key', row) === ordersDepartmentUuid
    );
};
export const getDataForLabelPrint = (formValues) => {
    console.log('as9dua-0da-sda', formValues);
    const printData = {
        ...formValues,
    };
    if (
        !((printData.secondaryIdentifier || '').trim()) &&
        formValues.idNumber &&
        formValues.idType
    ) {
        printData.secondaryIdentifier = `${formValues.idNumber} (${formValues.idType})`;
    }
    return printData;
};
