/**
 * Created by vinay on 28/6/19.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_CASH_CLOSE_REQUEST,
    FETCH_CASH_CLOSE_REQUEST,
    UPDATE_CASH_CLOSE_PRINT_FLAG,
} from '../../redux/modules/cashClose/cashClose-actions';
import { commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* createCashCloseWatcher() {
    yield takeLatest(CREATE_CASH_CLOSE_REQUEST, commonPostWorkerSaga);
}

export function* updateCashClosePrintStatusWatcher() {
    yield takeLatest(UPDATE_CASH_CLOSE_PRINT_FLAG, commonGetWorkerSaga);
}

export function* fetchCashCloseWatcher() {
    yield takeLatest(FETCH_CASH_CLOSE_REQUEST, commonGetWorkerSaga);
}
