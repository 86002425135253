import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Field, change, initialize, reduxForm } from 'redux-form';
import axios from 'axios';
import cloneDeep from 'clone-deep';

import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    extractTextFromDoubleQuotes,
    getIndexFromFieldName, getJsonPath,
} from '../../constants/CommonUtil';
import { ACTION_HANDLERS, extractAndSetValues } from './AddStockActionHandlers';
import ReduxFormMaterialTable from '../FormFieldComponents/ReduxFormMaterialTable/ReduxFormMaterialTable';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import OutlinedTextField from '../OutlinedTextField';
import { mapAddStockDtoToUiObject, mapAddStockFromUiObject, UI_OBJECT } from '../../mapper/AddStockMapper';
import { createAddStockRequest } from '../../redux/modules/addStock/addStock-actions';
import { required } from '../../constants/FormValidations';
import ActionButton from '../ActionButton/ActionButton';
import {
    clearStockMoveByPurchaseOrderState,
    fetchStockMovesRequest,
} from '../../redux/modules/stockMove/stockMove-actions';
import SideLabelReadOnlyText from '../FormFieldComponents/SideLabelReadOnlyText/SideLabelReadOnlyText';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';
import { PAGE, SIZE } from '../../constants/constants';
import DrugBarcodeEntry from './InternalMovesDialog/DrugBarcodeEntry';
import { getIndexToAdd, PRODUCT_CODE_FIELD } from './InternalMovesDialog/InternalMovesUtil';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import dialogComponentStyles from '../DialogComponent/DialogComponentStyles';
import GtinMapper from '../GtinComponent/GtinMapper';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'addStockForm';

class AddStockDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            page: PAGE,
            size: SIZE,
            createNewGtin: false,
            index: 0,
        };
    }

    componentDidMount() {
        const jsonApi = getJsonPath('/StockManagement/AddStock.json');
        this.getAllBatchesForPurchaseOrderInternal();
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.batchesByPurchaseOrder, this.props.batchesByPurchaseOrder)) {
            if (isArrayValidAndNotEmpty(nextProps.batchesByPurchaseOrder)) {
                this.initializeForm(nextProps.batchesByPurchaseOrder[0]);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
    }

    onAddProductViaBarcode = async (productInfo, barcodeProductQty) => {
        console.log('asdas09du9asdu', productInfo, barcodeProductQty);
        const {
            dispatch,
            formValues,
        } = this.props;
        try {
            const indexToAddTo = getIndexToAdd(formValues, 'addStock');
            const rowValue = {};
            const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_CODE}${productInfo.produceCode}&codeField=${productInfo.codeField}`);
            const product = response.data;
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.salePrice = null;
            rowValue.quantity = NumberOf(barcodeProductQty);
            rowValue.taxes = product.taxes || [];
            rowValue.expiry = productInfo.expiryDate;
            rowValue.description = productInfo.batchName;
            if (
                productInfo.codeField === PRODUCT_CODE_FIELD.EAN_13 &&
                isArrayValidAndNotEmpty(product.productGtinMapDtoList)
            ) {
                const matchingProductGtinMapDto = product.productGtinMapDtoList
                    .find(productGtinMapDto => productGtinMapDto.gtin === productInfo.produceCode);
                if (isObjectValidAndNotEmpty(matchingProductGtinMapDto)) {
                    rowValue.productGtinMapDto = matchingProductGtinMapDto;
                }
            }
            const newRowValue = extractAndSetValues(rowValue);
            this.props.change(`addStock[${indexToAddTo}]`, newRowValue);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    onPageChange = (page) => {
        this.setState({
            page,
        });
    };

    onSizeChange = (size) => {
        this.setState({
            size,
        });
    };

    getAllBatchesForPurchaseOrderInternal = () => {
        const {
            dispatch, targetId,
        } = this.props;
        let api = '';
        if (targetId) {
            api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`;
            dispatch(fetchStockMovesRequest(api));
        }
    };

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        dispatch(initialize(formName, mapAddStockDtoToUiObject(formValues)));
    };

    handleSave = (formValues) => {
        const { dispatch } = this.props;
        console.log('sdfsdfadsfasdfsdaf', formValues);
        const payload = mapAddStockFromUiObject(formValues, 'draft');
        dispatch(createAddStockRequest(payload));
    };

    handleConfirm = (formValues) => {
        const { dispatch, handleClose } = this.props;
        const payload = mapAddStockFromUiObject(formValues, 'done');
        dispatch(createAddStockRequest(payload, handleClose));
    };

    handleChooseGtin = (productGtinMapDto, _formName, field) => {
        const { formValues, dispatch } = this.props;
        const index = getIndexFromFieldName(field);
        if (formValues.addStock[index].productId) {
            if (isObjectValidAndNotEmpty(productGtinMapDto)) {
                if (productGtinMapDto.uuid && (typeof productGtinMapDto.uuid === 'string') && productGtinMapDto.uuid.includes('NCD-')) {
                    const gtin = extractTextFromDoubleQuotes(getStringFromObject('gtin', productGtinMapDto));
                    this.setState({
                        createNewGtin: true,
                        gtin,
                        index,
                    });
                }
            }
        } else {
            dispatch(errorMessage('You must select a product before adding Gtin'));
            this.clearGtin(index);
        }
    };


    handleCloseCreateDialog = (value) => {
        const {
            dispatch,
        } = this.props;
        const {
            index,
        } = this.state;
        dispatch(change(formName, `addStock[${index}].productGtinMapDto`, value));
        this.setState({
            createNewGtin: false,
            gtin: '',
        });
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            formValues,
            barcodeParser,
            dispatch,
        } = this.props;
        const {
            schema,
            page,
            size,
            createNewGtin,
            gtin,
            index,
        } = this.state;
        console.log('sjdlakjhdf', formValues);
        const isConfirmed = getStringFromObject('status', formValues) === 'done';
        const totalElements = getStringFromObject('addStock', formValues, []).length;
        const createdBy = getStringFromObject('createdBy', formValues);
        console.log('foradadamValues', createdBy);
        const allHandlers = {
            ...ACTION_HANDLERS,
            handleChooseGtin: this.handleChooseGtin,
        };
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div className={classes.header}>
                                    Add Stock
                                </div>
                                <Close
                                    className={classNames(classes.closeIcon, 'cursor-pointer')}
                                    onClick={handleClose}
                                    test-id="stock-close"
                                />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                <Grid container spacing={16} justify="space-between">
                                    <Grid item container lg={6} md={6} sm={6}>
                                        <Grid item sm={6} style={{ padding: '1em' }}>
                                            <Field
                                                name="reference"
                                                testId="reference"
                                                component={OutlinedTextField}
                                                label="Reference Name"
                                                fullWidth
                                                required
                                                disabled={isConfirmed}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classNames(classes.textField, classes.input),
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                validate={[]}
                                            />
                                        </Grid>
                                        <Grid item container spacing={16} sm={12} style={{ padding: '1em' }}>
                                            <Grid item sm={6}>
                                                <Field
                                                    name="location"
                                                    testId="location"
                                                    component={ReduxFormReactSelectMaterial}
                                                    dataSourceConfig={{
                                                        text: 'name',
                                                        value: 'uuid',
                                                    }}
                                                    label="Location"
                                                    placeholder="Input Location"
                                                    isDisabled={isConfirmed}
                                                    selectProps={{
                                                        textFieldProps: {
                                                            InputProps: {
                                                                classes: {
                                                                    root: classes.reactSelectTextField,
                                                                    input: classes.selectInput,
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    autocomplete
                                                    dataSourceApi={API.SEARCH.INTERNAL_STOCK_LOCATION}
                                                    validate={[required]}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Field
                                                    testId="date"
                                                    label="Stock Date"
                                                    name="date"
                                                    component={DateInput}
                                                    fullWidth
                                                    disabled={isConfirmed}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        createdBy &&
                                        <Grid item style={{ padding: '2em' }}>
                                            <Grid container>
                                                <SideLabelReadOnlyText
                                                    input={{ value: createdBy }}
                                                    label="Created by:"
                                                    classes={{
                                                        labelDiv: classes.labelDiv,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    isObjectValidAndNotEmpty(formValues.location) && !isConfirmed &&
                                    <DrugBarcodeEntry
                                        dispatch={this.props.dispatch}
                                        onConfirmQuantity={this.onAddProductViaBarcode}
                                        barcodeParser={barcodeParser}
                                    />
                                }
                                <Grid container className="mt-2">
                                    {
                                        isObjectValidAndNotEmpty(schema) &&
                                        <ReduxFormMaterialTable
                                            fieldName="addStock"
                                            rowsPerPage={100}
                                            actionHandlers={allHandlers}
                                            dispatch={this.props.dispatch}
                                            styles={{ minHeight: '20em' }}
                                            rerenderOnEveryChange
                                            {...schema}
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            isEditable={!isConfirmed}
                                            paramMap={formValues.addStock}
                                            formValues={formValues}
                                            internalPagination
                                            paginationDetails={{
                                                page,
                                                size,
                                                totalElements,
                                            }}
                                            handlePageChange={this.onPageChange}
                                            handleSizeChange={this.onSizeChange}
                                            enablePagination
                                            rowsPerPageOptions={[10, 20]}
                                        />
                                    }
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                testId="addstock-save"
                                disableRipple
                                disabled={isConfirmed}
                                onClick={handleSubmit(this.handleSave)}
                            >
                                Save
                            </ActionButton>
                            <ActionButton
                                testId="addstock-confirm"
                                disableRipple
                                disabled={isConfirmed}
                                onClick={handleSubmit(this.handleConfirm)}
                            >
                                Confirm
                            </ActionButton>
                        </DialogActions>
                    </Dialog>
                </form>
                {
                    createNewGtin &&
                    <GtinMapper
                        open
                        header={getStringFromObject('productName', formValues.addStock[index].product)}
                        product={formValues.addStock[index].product}
                        handleClose={this.handleCloseCreateDialog}
                        dispatch={dispatch}
                        gtin={gtin}
                    />
                }
            </React.Fragment>
        );
    }
}

AddStockDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    change: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    open: PropTypes.bool.isRequired,
    batchesByPurchaseOrder: PropTypes.array,
    targetId: PropTypes.number,
    barcodeParser: PropTypes.string,
};

AddStockDialog.defaultProps = {
    dispatch: () => {},
    change: () => {},
    formValues: { expiry: getDateInYYYYMMDDFormat(new Date()) },
    batchesByPurchaseOrder: [],
    targetId: null,
    barcodeParser: '',
};

const mapStateToProps = state => ({
    formValues: state.form[formName] ? state.form[formName].values : {},
    batchesByPurchaseOrder: state.stockMove.stockMovesByPurchaseOrder,
    barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
});

export default connect(mapStateToProps)(reduxForm({
    form: formName,
    initialValues: cloneDeep(UI_OBJECT),
})(withStyles(dialogComponentStyles)(AddStockDialog)));
