import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import {
    getTextFieldChangedValue,
    roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import OutlinedTextField from '../OutlinedTextField';
import { isEmpty } from '../../constants/FormValidations';
import {
    getFormFieldValue,
    blurFormField,
    changeFormValue,
} from '../../containers/RegistrationAppComponents/FormUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
// todo merge with numberTextField later.. it was being used in many places so..
class ReduxFormNumericField extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (
            !deepEqual(nextProps.input, this.props.input) ||
            !deepEqual(nextProps.field, this.props.field) ||
            !deepEqual(nextProps.meta, this.props.meta) ||
            !deepEqual(nextProps.disabled, this.props.disabled)
        ) {
            return true;
        }
        return false;
    }

    onBlur = (event) => {
        console.log('sadfsdfs', this.props.input);
        let changedValue = getFormFieldValue(this.props);
        console.log('changed valueee max comparison', changedValue, event.target.type);
        const {
            stripLeadingZeros,
        } = this.props;
        console.log('asda0sdi-0aid-as', stripLeadingZeros);
        if (event.target.type === 'number' && changedValue && changedValue.replace && stripLeadingZeros) {
            console.log('changed valueee replace', changedValue, event.target.type);
            changedValue = (!isEmpty(changedValue)) ? Number(changedValue) : changedValue;
            console.log('changed valueee replace s', changedValue, event.target.type);
        }
        // if changed value max, reset to max
        if (changedValue !== '') {
            const { max, min } = this.props;
            if (max) {
                changedValue = Math.min(NumberOf(changedValue), max);
            }
            console.log('asdfkjskdjf', min, (min !== undefined && (Number(changedValue) < min)));
            if (changedValue !== '') {
                changedValue = Math.max(NumberOf(changedValue), min);
            }
        }
        console.log('changed valueee', changedValue);
        changeFormValue(
            this.props,
            {
                ...event,
                target: {
                    ...event.target,
                    value: Number(changedValue),
                },
            },
            Number(changedValue),
        );
        const newEvent = cloneDeep(event);
        newEvent.target.value = changedValue;
        blurFormField(this.props, newEvent);
    };

    onChange = (value, event) => {
        console.log('asd-s0adi-asdi-a0as', value);
        changeFormValue(this.props, event, getTextFieldChangedValue(value));
    };

    handleKeyDown = (event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
        }
    };

    render() {
        console.log('number texxxxxxxxxxxxxt fieldsss', this.props, this.props.min);
        const {
            roundValue,
        } = this.props;
        const touched = getStringFromObject('meta.touched', this.props);
        const error = getStringFromObject('meta.error', this.props);
        const {
            input,
            field,
            ...otherProps
        } = this.props;
        const inputVal = getFormFieldValue(this.props);
        return (
            <OutlinedTextField
                type="number"
                {...otherProps}
                InputLabelProps={{ shrink: true }}
                input={{}}
                value={roundValue ? roundedValueFixedToTwoDigits(inputVal) : inputVal}
                onChange={this.onChange}
                onKeyDown={this.handleKeyDown}
                onBlur={this.onBlur}
                errorText={touched && error ? error : ''}
            />
        );
    }
}

ReduxFormNumericField.propTypes = {
    input: PropTypes.object,
    // for formik
    field: PropTypes.object,
    meta: PropTypes.object,
    roundValue: PropTypes.bool,
    max: PropTypes.any,
    min: PropTypes.any,
    stripLeadingZeros: PropTypes.bool,
    disabled: PropTypes.bool,
};

ReduxFormNumericField.defaultProps = {
    input: {},
    field: {},
    roundValue: false,
    meta: {
        touched: false,
        error: '',
    },
    max: 99999999999999999999,
    min: 0,
    stripLeadingZeros: true,
    disabled: false,
};

export default ReduxFormNumericField;
