import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    ADD_ANNOUNCEMENT_FAILURE,
    ADD_ANNOUNCEMENT_SUCCESS, EDIT_ANNOUNCEMENT_SUCCESS,
    FETCH_ANNOUNCEMENT_LIST_FAILURE,
    FETCH_ANNOUNCEMENT_LIST_SUCCESS,
} from '../../redux/modules/announcements/announcements-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* announcementListFetchRequest(action) {
    try {
        const apiWithQueryParam =
            `${action.api}?page=${action.page}&size=${action.size}&searchString=${action.searchText || ''}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield put({
            type: FETCH_ANNOUNCEMENT_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: FETCH_ANNOUNCEMENT_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* announcementAddRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield put({
            type: ADD_ANNOUNCEMENT_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: ADD_ANNOUNCEMENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* announcementEditRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield put({
            type: EDIT_ANNOUNCEMENT_SUCCESS,
            data: response.data,
            index: action.index,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: ADD_ANNOUNCEMENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
