import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio } from '@material-ui/core';
import {
    // isObjectValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
});

class FormikRadioButton extends React.PureComponent {
    handleChange = (event) => {
        const {
            form,
            field: {
                value,
                name,
            },
            makeAllFalse,
        } = this.props;
        console.log('dfdsg', name, value, makeAllFalse);
        // const locale = 'en';
        const setFieldValue = getStringFromObject('setFieldValue', form);
        makeAllFalse();
        if (isValidFunction(setFieldValue)) {
            setFieldValue(name, event.target.checked);
        }
    };

    render() {
        const {
            classes,
            label,
            containerStyle,
            disabled,
            field: {
                name,
                value,
            },
            formControlProps,
        } = this.props;
        console.log('asd0aud0su9da', value, disabled);
        return (
            <div style={containerStyle}>
                <div className={classes.fieldDiv}>
                    <FormControlLabel
                        control={
                            <Radio
                                key={`${name}-${value}`}
                                checked={value}
                                onChange={this.handleChange}
                                value={name}
                                disabled={disabled}
                                color="primary"
                            />
                        }
                        {...formControlProps}
                        label={label}
                    />
                </div>
            </div>
        );
    }
}

FormikRadioButton.propTypes = {
    classes: PropTypes.object.isRequired,
    containerStyle: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    formControlProps: PropTypes.object,
    /* formik props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    makeAllFalse: PropTypes.func.isRequired,
};

FormikRadioButton.defaultProps = {
    disabled: false,
    containerStyle: {},
    actionHandlers: {},
    formControlProps: {},
    onChangeHandlers: [],
    label: '',
};

export default withStyles(styles)(FormikRadioButton);
