/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio } from '@material-ui/core';
import { isArrayValidAndNotEmpty } from '../../../../constants/CommonUtil';
import { NumberOf } from '../../../../constants/numberUtils';

class RadioButtonGroup extends React.Component {
    handleChange = (event) => {
        let { value } = event.target;
        if (this.props.valueType === 'number') {
            value = NumberOf(value);
        }
        this.props.input.onChange(value);
    };

    render() {
        const {
            input,
            choices,
            label,
            disabled,
            widthForEachChoice,
        } = this.props;
        return (
            <Grid container>
                <Grid item lg={12} md={12} sm={12}>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12}>
                            {label}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <div style={{ display: 'flex', width: '100%' }}>
                            {
                                isArrayValidAndNotEmpty(choices) &&
                                    choices.map(aChoice => (
                                        <Grid
                                            key={aChoice.value}
                                            item
                                            lg={widthForEachChoice}
                                            md={widthForEachChoice}
                                            sm={widthForEachChoice}
                                        >
                                            <Radio
                                                checked={input.value === aChoice.value}
                                                onChange={this.handleChange}
                                                value={aChoice.value}
                                                color="primary"
                                                disabled={disabled}
                                            />
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={
                                                    () => {
                                                        this.handleChange(
                                                            {
                                                                target: {
                                                                    value: aChoice.value,
                                                                },
                                                            },
                                                        );
                                                    }
                                                }
                                            >
                                                {aChoice.text}
                                            </span>
                                        </Grid>
                                    ))
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

RadioButtonGroup.propTypes = {
    input: PropTypes.object,
    choices: PropTypes.array,
    label: PropTypes.node,
    widthForEachChoice: PropTypes.number,
    valueType: PropTypes.string,
    disabled: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
    input: {},
    choices: [],
    label: <div />,
    widthForEachChoice: 4,
    valueType: 'text',
    disabled: false,
};

export default RadioButtonGroup;

