/**
 * Created by vinay on 19/12/18.
 */
import API from '../../../constants/api';
import messages from '../../../constants/messages';

export const FETCH_APPOINTMENT_TYPES_REQUEST = '@@appointmentSlot/FETCH_APPOINTMENT_TYPES_REQUEST';
export const FETCH_APPOINTMENT_TYPES_FAILURE = '@@appointmentSlot/FETCH_APPOINTMENT_TYPES_FAILURE';
export const FETCH_APPOINTMENT_TYPES_SUCCESS = '@@appointmentSlot/FETCH_APPOINTMENT_TYPES_SUCCESS';

export const FETCH_SERVICE_WEEK_REQUEST = '@@appointmentSlot/FETCH_SERVICE_WEEK_REQUEST';
export const FETCH_SERVICE_WEEK_FAILURE = '@@appointmentSlot/FETCH_SERVICE_WEEK_FAILURE';
export const FETCH_SERVICE_WEEK_SUCCESS = '@@appointmentSlot/FETCH_SERVICE_WEEK_SUCCESS';

export const FETCH_PROVIDER_WEEK_REQUEST = '@@appointmentSlot/FETCH_PROVIDER_WEEK_REQUEST';
export const FETCH_PROVIDER_WEEK_FAILURE = '@@appointmentSlot/FETCH_PROVIDER_WEEK_FAILURE';
export const FETCH_PROVIDER_WEEK_SUCCESS = '@@appointmentSlot/FETCH_PROVIDER_WEEK_SUCCESS';

export const CREATE_DOCTOR_SLOT_REQUEST = '@@appointmentSlot/CREATE_DOCTOR_SLOT_REQUEST';
export const CREATE_DOCTOR_SLOT_FAILURE = '@@appointmentSlot/CREATE_DOCTOR_SLOT_FAILURE';
export const CREATE_DOCTOR_SLOT_SUCCESS = '@@appointmentSlot/CREATE_DOCTOR_SLOT_SUCCESS';

export const UPDATE_DAILY_SCHEDULE_REQUEST = '@@appointmentSlot/UPDATE_DAILY_SCHEDULE_REQUEST';
export const UPDATE_DAILY_SCHEDULE_REQUEST_FAILURE = '@@appointmentSlot/UPDATE_DAILY_SCHEDULE_REQUEST_FAILURE';
export const UPDATE_DAILY_SCHEDULE_REQUEST_SUCCESS = '@@appointmentSlot/UPDATE_DAILY_SCHEDULE_REQUEST_SUCCESS';

export const REMOVE_DAILY_SCHEDULE_REQUEST = '@@appointmentSlot/REMOVE_DAILY_SCHEDULE_REQUEST';
export const REMOVE_DAILY_SCHEDULE_REQUEST_FAILURE = '@@appointmentSlot/REMOVE_DAILY_SCHEDULE_REQUEST_FAILURE';
export const REMOVE_DAILY_SCHEDULE_REQUEST_SUCCESS = '@@appointmentSlot/REMOVE_DAILY_SCHEDULE_REQUEST_SUCCESS';

export const UPDATE_DOCTOR_SLOT_REQUEST = '@@appointmentSlot/UPDATE_DOCTOR_SLOT_REQUEST';
export const UPDATE_DOCTOR_SLOT_FAILURE = '@@appointmentSlot/UPDATE_DOCTOR_SLOT_FAILURE';
export const UPDATE_DOCTOR_SLOT_SUCCESS = '@@appointmentSlot/UPDATE_DOCTOR_SLOT_SUCCESS';

export const UPDATE_SLOTS_REQUEST = '@@appointmentSlot/UPDATE_SLOTS_REQUEST';
export const UPDATE_SLOTS_FAILURE = '@@appointmentSlot/UPDATE_SLOTS_FAILURE';
export const UPDATE_SLOTS_SUCCESS = '@@appointmentSlot/UPDATE_SLOTS_SUCCESS';

export const RUN_DOCTOR_SLOT_SCHEDULER_REQUEST = '@@appointmentSlot/RUN_DOCTOR_SLOT_SCHEDULER_REQUEST';
export const RUN_DOCTOR_SLOT_SCHEDULER_FAILURE = '@@appointmentSlot/RUN_DOCTOR_SLOT_SCHEDULER_FAILURE';
export const RUN_DOCTOR_SLOT_SCHEDULER_SUCCESS = '@@appointmentSlot/RUN_DOCTOR_SLOT_SCHEDULER_SUCCESS';

export const RESET_PROVIDER_WEEK_STATE = '@@appointmentSlot/RESET_PROVIDER_WEEK_STATE';
export const RESET_SERVICE_WEEK_STATE = '@@appointmentSlot/RESET_SERVICE_WEEK_STATE';

export const fetchAppointmentTypes = () => ({
    type: FETCH_APPOINTMENT_TYPES_REQUEST,
    api: API.APPOINTMENT.APPOINTMENT_TYPES,
});

export const updateDailyScheduleReqeust = (payload, successCallback, failureCallback) => ({
    type: UPDATE_DAILY_SCHEDULE_REQUEST,
    api: API.APPOINTMENT.DOCTOR_SLOT.UPDATE_DAILY_SCHEDULE,
    payload,
    successAction: UPDATE_DAILY_SCHEDULE_REQUEST_SUCCESS,
    successMessage: messages.DOCTOR_SLOT.UPDATE.SUCCESS,
    successCallback,
    failureAction: UPDATE_DAILY_SCHEDULE_REQUEST_FAILURE,
    failureMessage: messages.DOCTOR_SLOT.UPDATE.FAILED,
    failureCallback,
});

export const updateSlotsRequest = (payload, successCallback, failureCallback) => ({
    type: UPDATE_SLOTS_REQUEST,
    api: API.APPOINTMENT.DOCTOR_SLOT.UPDATE_SLOT,
    payload,
    successAction: UPDATE_SLOTS_SUCCESS,
    successMessage: messages.DOCTOR_SLOT.UPDATE.SUCCESS,
    successCallback,
    failureAction: UPDATE_SLOTS_FAILURE,
    failureMessage: messages.DOCTOR_SLOT.UPDATE.FAILED,
    failureCallback,
});

export const deleteDailyScheduleReqeust = (params, successCallback, failureCallback) => ({
    type: REMOVE_DAILY_SCHEDULE_REQUEST,
    api: `${API.APPOINTMENT.DOCTOR_SLOT.DELETE_SLOT}?remove=${params.remove}`,
    payload: params.slots,
    successAction: REMOVE_DAILY_SCHEDULE_REQUEST_SUCCESS,
    successMessage: messages.DOCTOR_SLOT.DELETE.SUCCESS,
    successCallback,
    failureAction: REMOVE_DAILY_SCHEDULE_REQUEST_FAILURE,
    failureMessage: messages.DOCTOR_SLOT.DELETE.FAILED,
    failureCallback,
});

export const fetchServiceWeek = serviceUuid => ({
    type: FETCH_SERVICE_WEEK_REQUEST,
    api: `${API.APPOINTMENT.PROVIDERS_IN_SERVICE}${serviceUuid}`,
});

export const resetServiceWeekState = () => ({
    type: RESET_SERVICE_WEEK_STATE,
});

export const fetchProviderWeek = (service, provider) => ({
    type: FETCH_PROVIDER_WEEK_REQUEST,
    providerUuid: provider,
    api: `${API.APPOINTMENT.DOCTOR_SLOT.CREATE}?serviceTypeUuid=${service}&providerUuid=${provider}`,
});

export const resetProviderWeekState = () => ({
    type: RESET_PROVIDER_WEEK_STATE,
});

export const createDoctorSlots = (payload, onSuccessCallback, onFailureCallback) => ({
    type: CREATE_DOCTOR_SLOT_REQUEST,
    api: API.APPOINTMENT.DOCTOR_SLOT.CREATE,
    payload,
    onSuccessCallback,
    onFailureCallback,
});

export const updateDoctorSlots = (payload, onSuccessCallback, onFailureCallback) => ({
    type: UPDATE_DOCTOR_SLOT_REQUEST,
    api: `${API.APPOINTMENT.DOCTOR_SLOT.CREATE}`,
    payload,
    onSuccessCallback,
    onFailureCallback,
});
