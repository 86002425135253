import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/lib/axios';
import HandleBars from 'handlebars/dist/handlebars';
import { connect } from 'react-redux';
import { qz } from '@packages/nuacare-qz-tray';

import {
    calculateAge, formatCurrency, isArrayValidAndNotEmpty, isStringNullOrUndefined,
    isValidDate,
    roundedValue,
    roundedValueFixedToTwoDigits, valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import {
    formatAMPM,
    getDateInDDMMMYYYYFormat,
    formatDateForDisplay,
    formatDateTimeForDisplay,
} from '../../../constants/DateUtil';
import { APPLICATION_CONFIG_URL, COMMON_APPLICATION_CONFIG_URL, printPageSizes } from '../../../constants/constants';
import numberToArabic from '../../../constants/EnglishToArabicNumeral';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import API from '../../../constants/api';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

HandleBars.registerHelper('getTaxValue', (percent, price) => {
    console.log('21unboboidaonboinboboiasbdsad', percent, price);
    return `${roundedValue(((NumberOf(percent) * NumberOf(price)) / 100))}`;
});
HandleBars.registerHelper('equals', (field, value) => field === value);
HandleBars.registerHelper('index', value => (NumberOf(value) + 1));

HandleBars.registerHelper('notEquals', (field, value) => field !== value);

HandleBars.registerHelper('roundToTwoDigits', numberValue => `${roundedValueFixedToTwoDigits(numberValue)}`);

HandleBars.registerHelper('formatAmount', numberValue => `${valueToFixedTwoDigits(NumberOf(numberValue))}`);

// eslint-disable-next-line max-len
HandleBars.registerHelper('formatCurrencyWithoutDecimal', numberValue => `${formatCurrency(Number.parseInt(NumberOf(numberValue), 10))}`);

HandleBars.registerHelper('numberToArabic', numberValue => `${numberToArabic(NumberOf(numberValue))}`);

// eslint-disable-next-line no-confusing-arrow
HandleBars.registerHelper('showCommaIfValueNotEmpty', stringValue => isStringNullOrUndefined(stringValue) ? '' : ',');

HandleBars.registerHelper('isDefined', stringValue => stringValue || stringValue === 0);

HandleBars.registerHelper('isGreaterThanZero', number => NumberOf(number) > 0);

HandleBars.registerHelper('getTime', dateObject => formatAMPM(new Date(dateObject)));

HandleBars.registerHelper('formatDate', dateObject => getDateInDDMMMYYYYFormat(new Date(dateObject)));
HandleBars.registerHelper('displayDateTime', dateObject => formatDateTimeForDisplay(new Date(dateObject)));

HandleBars.registerHelper('formatDateInDDMMYYYY', (dateObject) => {
    console.log('asd-a9sdi-asdia0ids-d', formatDateForDisplay(new Date(dateObject)));
    if (isValidDate(new Date(dateObject))) {
        return formatDateForDisplay(new Date(dateObject));
    }
    return '';
});

HandleBars.registerHelper('calculateAge', dateObject => calculateAge(new Date(dateObject)));

class Print extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (this.props.print !== nextProps.print) {
            this.print(nextProps);
        }
    }

    getHTMLAndReplaceValues = (props) => {
        axios.get(`${props.url}`).then(
            (response) => {
                let template = response.data;
                template = HandleBars.compile(template);
                const htmlToRender = template({
                    ...this.preparePrintData(props),
                    today: new Date().toDateString(),
                    appConfigURL: APPLICATION_CONFIG_URL,
                    commonAppConfigURL: COMMON_APPLICATION_CONFIG_URL,
                });
                console.log('21sadasdasdas', htmlToRender);
                const {
                    printerConfigs,
                    name,
                } = this.props;
                const printerConfigForPrint = getStringFromObject(name, printerConfigs);
                if (printerConfigForPrint && isObjectValidAndNotEmpty(printerConfigForPrint)) {
                    this.testQzTray(htmlToRender, printerConfigForPrint);
                } else {
                    this.printIFrame(htmlToRender);
                }
            },
            (e) => {
                console.log('ERROR', e);
            },
        );
    };

    preparePrintData = props => props.data;

    printIFrame = async (template) => {
        const hiddenFrame = this.iFrameInput;
        console.log('Inside update');
        hiddenFrame.contentWindow.printAndRemove = () => {
            console.log('loaded');
            hiddenFrame.contentWindow.print();
            this.props.handleClose(false);
            // hiddenFrame.remove();
        };
        const doc = this.iFrameInput.contentWindow.document.open(
            'text/html',
            'replace',
        );
        const response = await axios.get(`${API.ADDRESS.COMPANY_HEADER}${this.props.subCompany || ''}`);
        console.log('sdfd rupam ', response.data);
        let htmlContent = '';

        const crNum = getStringFromObject('data.crNum', response) ?
            `<h4 align="center" style="margin-bottom: 0">CR No : ${getStringFromObject('data.crNum', response)}` : '';

        if (this.props.showHeader) {
            htmlContent = `<!doctype html>
      <html>
      <head style="align-content: center">
      <h1 align="center" style="margin-bottom: -12px">${getStringFromObject('data.company', response)}</h1>
      <h4 align="center" style="margin-bottom: -12px">${getStringFromObject('data.address', response)}</h4>
      <h4 align="center" style="margin-bottom: -12px">Phone : ${getStringFromObject('data.phone', response)}</h4>
      <h4 align="center" style="margin-bottom: -12px">Vat No : ${getStringFromObject('data.vatNum', response)}</h4>` +
      `${crNum}
      </head>
      <body onload="printAndRemove();">
         ${template}
      </body>
      </html>`;
        } else {
            htmlContent = `<!doctype html>
      <html>
      <body onload="printAndRemove();">
         ${template}
      </body>
      </html>`;
        }
        doc.write(htmlContent);
        doc.close();
    };


    testQzTray = (htmlToRender, printerConfigForPrint) => {
        try {
            qz.websocket.connect()
                .then(() => (
                    qz.printers.find()
                ))
                .then((printers) => {
                    console.log('printerssssssss', printers);
                    // is printer connected and found..
                    const printerName = getStringFromObject('name', printerConfigForPrint);
                    if (
                        isArrayValidAndNotEmpty(printers) &&
                        printers.includes(printerName) &&
                        printerName
                    ) {
                        const pageSize = getStringFromObject('size', printerConfigForPrint) || 'A4';
                        const size = getStringFromObject(pageSize, printPageSizes) || printPageSizes.A4;
                        console.log('asd=-aod=-aoda', size);
                        const config =
                            qz.configs.create(
                                printerName,
                                {
                                    // size,
                                    // density: '600',
                                    // colorType: 'blackwhite',
                                    // units: 'in',
                                    scaleContent: false,
                                    // orientation:
                                    //     getStringFromObject('orientation', printerConfigForPrint) || 'portrait',
                                },
                            );
                        const data = [{
                            type: 'html',
                            format: 'plain', // or 'plain' if the data is raw HTML
                            data: htmlToRender,
                        }];
                        return qz.print(config, data);
                    }
                    throw new Error('no printer config');
                })
                .then(() => (
                    qz.websocket.disconnect()
                ))
                .then(() => {
                    console.log('donee');
                })
                .catch((err) => {
                    this.props.dispatch(errorMessage(`Failed To Print Via Qz ${err}`));
                    this.printIFrame(htmlToRender);
                    qz.websocket.disconnect();
                });
        } catch (e) {
            this.props.dispatch(errorMessage(e));
        }
    };

    print(props) {
        this.getHTMLAndReplaceValues(props);
    }

    render() {
        console.log('printing loggicc', this.props.subCompany, this.props.data);
        return (
            <div id="react-print">
                <iframe
                    id="content"
                    style={{ visibility: 'hidden', height: 0, width: 0 }}
                    title="Bill Printout"
                    ref={(input) => {
                        this.iFrameInput = input;
                    }} //  eslint-disable-line react/jsx-no-bind
                />
            </div>
        );
    }
}

Print.propTypes = {
    data: PropTypes.object,
    printerConfigs: PropTypes.object,
    name: PropTypes.string,
    print: PropTypes.bool,
    handleClose: PropTypes.func,
    dispatch: PropTypes.func,
    subCompany: PropTypes.object,
    showHeader: PropTypes.bool,
};

Print.defaultProps = {
    data: {},
    printerConfigs: {},
    name: '',
    print: false,
    subCompany: '',
    showHeader: true,
    handleClose: () => {},
    dispatch: () => {},
};

const mapStateToProps = state => ({
    printerConfigs: getStringFromObject('appConfiguration.printConfigs', state),
});

export default connect(mapStateToProps)(Print);
