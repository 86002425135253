import cloneDeep from 'clone-deep';
import { CLEAR_WARNING, DISPLAY_WARNING } from './warningDialog-actions';

const warningDialogReducer = (state = [], action) => {
    console.log('kljdalkdfjasklf', action);
    let newState = state;
    switch (action.type) {
    case DISPLAY_WARNING:
        newState = state.concat({
            message: action.message,
            callBack: action.callBack,
            title: action.title,
            open: true,
            isConfirmation: action.isConfirmation,
            callBackParams: action.callBackParams,
            noCallBack: action.noCallBack,
            noCallBackParams: action.noCallBackParams,
            yesLabel: action.yesLabel,
            noLabel: action.noLabel,
            cancelLabel: action.cancelLabel,
            cancelCallBack: action.cancelCallBack,
            cancelCallBackParams: action.cancelCallBackParams,
            disableKeyClose: action.disableKeyClose || false,
        });
        break;
    case CLEAR_WARNING:
        newState = cloneDeep(state);
        newState.splice(0, 1);
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only log if state has changed
        console.log(
            'new_state:',
            action,
            'state:',
            state,
            'newState:',
            newState,
        );
    }

    return newState;
};

export default warningDialogReducer;
