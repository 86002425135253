import initialStates from '../../initialStates';
import {
    ADD_VITALS_REQUEST,
    ADD_VITALS_SUCCESS,
    FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS,
    FETCH_VITALS_QUEUE_LIST_SUCCESS,
} from './vitalsQueue-actions';

const vitalsQueue = (state = initialStates.visitQueue, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_VITALS_QUEUE_LIST_SUCCESS:
        newState = action.data;
        break;
    case FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS:
        newState = {
            list: action.data.content,
            first: action.data.first,
            last: action.data.last,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            page: action.data.page,
            size: action.data.size,
            filters: action.filters,
        };
        break;
    case ADD_VITALS_REQUEST:
        newState = { ...state, success: false };
        break;
    case ADD_VITALS_SUCCESS:
        newState = { ...state, success: true };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default vitalsQueue;
