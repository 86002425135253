const numberMapping = {
    0: '٠',
    1: '١',
    2: '٢',
    3: '٣',
    4: '٤',
    5: '٥',
    6: '٦',
    7: '٧',
    8: '٨',
    9: '٩',
};

const numberToArabic = digit => digit.toString().split('').map(number => numberMapping[number]).join('');
export default numberToArabic;
