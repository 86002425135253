const appointmentTokenTimeBoxStyles = () => ({
    defaultCursor: {
        cursor: 'default',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    appointmentBox: {
        display: 'flex',
        width: '14.28%',
        margin: '1em',
    },
    availableSlot: {
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    },
    appointmentNumber: {
        padding: '6px 8px',
        color: '#fff',
        fontWeight: '600',
    },
    appointmentTime: {
        padding: '6px',
        textAlign: 'center',
        width: '100%',
    },
    vipToken: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    option: {
        margin: '1em',
        padding: '4px',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: '500',
    },
});

export default appointmentTokenTimeBoxStyles;
