import { takeLatest } from 'redux-saga/effects';
import {
    CANCEL_OR_REVERT_MATERIAL_RECEIVE,
    CREATE_MATERIAL_RECEIVE_REQUEST,
    FETCH_MATERIAL_RECEIVE_REQUEST,
    MARK_MATERIAL_RECEIVE_AS_COMPLETE,
} from '../../redux/modules/materialReceive/materialReceive-actions';
import {
    cancelOrRevertMaterialReceiveWorker,
    createMaterialReceiveWorker,
    fetchMaterialReceive,
    markMaterialReceive,
} from './materialReceiveWorkerSaga';

export function* createMaterialReceiveWatcher() {
    yield takeLatest(CREATE_MATERIAL_RECEIVE_REQUEST, createMaterialReceiveWorker);
}

export function* fetchMaterialReceiveRequestWatcher() {
    yield takeLatest(FETCH_MATERIAL_RECEIVE_REQUEST, fetchMaterialReceive);
}

export function* cancelOrRevertMaterialReceiveWatcher() {
    yield takeLatest(CANCEL_OR_REVERT_MATERIAL_RECEIVE, cancelOrRevertMaterialReceiveWorker);
}
export function* markMaterialReceiveAsComplete() {
    yield takeLatest(MARK_MATERIAL_RECEIVE_AS_COMPLETE, markMaterialReceive);
}
