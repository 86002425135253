import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import classNames from 'classnames';
import cloneDeep from 'clone-deep';
import sumBy from 'lodash.sumby';
import axios from 'axios';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import { Field, withFormik } from 'formik';
import { TextField } from 'formik-material-ui';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import API from '../../../constants/api';
import {
    apiCatchBlockFunction,
    getIndexFromFieldName, getJsonPath,
    isArrayValidAndNotEmpty,
    isValidFunction,
    isValidNumber,
    isValidObject, roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import { formatDate, getDateInDDMMMYYYYFormat } from '../../../constants/DateUtil';
import { isEmpty, required } from '../../../constants/FormValidations';
import { getUiObjectFromAccountVoucher, mapDtoFromUiObj, PAYMENT_UI_OBJECT } from
    '../../../mapper/ReceivePaymentMapper';
import { createReceivePaymentRequest } from '../../../redux/modules/receivePayment/receivePayment-action';
import ActionButton from '../../ActionButton/ActionButton';
import {
    APPLICATION_CONFIG_URL,
    documentFileTypes,
    imageFileTypes,
    PAYMENT_METHODS,
} from '../../../constants/constants';
import { clearSelectedAccountVoucher, fetchAccountVoucherById } from '../../../redux/modules/payment/payment-actions';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import receivePaymentDialogStyles from './ReceivePaymentDialogStyles';
import { showConfirmationDialog } from '../../../redux/modules/warningDialog/warningDialog-actions';
import ReduxFormChipFileUpload from '../../FormFieldComponents/ReduxFormChipFileUpload/ReduxFormChipFileUpload';
import { getSupplierSubCompany } from '../MakeBillDialog/MakeBillDialogUtil';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'receivePaymentForm';

const createTableDataFromInvoices = (invoices) => {
    let tableData = [];
    let totalAmountNeeded = 0;
    if (isArrayValidAndNotEmpty(invoices)) {
        tableData = invoices.map((d) => {
            totalAmountNeeded += d.residualAmount;
            totalAmountNeeded = Number(roundedValueFixedToTwoDigits(totalAmountNeeded));
            return {
                description: d.number,
                dueDate: getDateInDDMMMYYYYFormat(new Date(d.dueDateInMillis)),
                originalAmount: d.amountTotal,
                openBalance: d.residualAmount,
                payment: 0,
                type: d.type,
                uuid: getStringFromObject('uuid', d, ''),
            };
        });
    }
    return {
        tableData,
        totalAmountNeeded,
    };
};

class ReceivePaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        let totalAmountNeeded = 0;
        if (isObjectValidAndNotEmpty(props.invoicePayment)) {
            if (props.invoicePayment.lines) {
                props.invoicePayment.lines.forEach((aRow) => {
                    totalAmountNeeded += aRow.invoiceResidual;
                    totalAmountNeeded = Number(roundedValueFixedToTwoDigits(totalAmountNeeded));
                });
            }
        }
        this.state = {
            schema: null,
            totalAmountNeeded,
            printPayment: false,
            subCompany: '',
            // this is only used to print the data immediately after payment..
            // in other cases, it is sufficient to take selectedAccountVoucher from props
            printData: {},
            // when printed immediately after registering a payment, then we can use the close func to
            // to go back to the supplier invoice screen
            onPrintCloseFunc: () => {},
        };
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/ReceivePayment.json'))
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        const {
            dispatch,
            accountInvoiceUuid,
            selectedSupplier,
            accountVoucherId,
            accountInvoices,
            invoicePayment,
        } = this.props;
        if (accountVoucherId) {
            dispatch(fetchAccountVoucherById(accountVoucherId));
        } else if (accountInvoiceUuid) {
            axios.get(`${API.ACCOUNT_INVOICE.GET_INVOICE}/findOne/${accountInvoiceUuid}`).then(
                response => this.initializeTable([response.data], true),
            ).catch(error => apiCatchBlockFunction(error, dispatch));
        } else if (isArrayValidAndNotEmpty(accountInvoices)) {
            this.initializeTable(accountInvoices, true);
        } else if (isValidObject(selectedSupplier)) {
            if (selectedSupplier.id) {
                this.onPayorSelect(selectedSupplier);
            }
        } else if (isObjectValidAndNotEmpty(invoicePayment)) {
            this.getSubCompanyOfSupplier({
                uuid: getStringFromObject('partnerId.key', invoicePayment),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.selectedAccountVoucher, this.props.selectedAccountVoucher)
            && isObjectValidAndNotEmpty(nextProps.selectedAccountVoucher)) {
            const initialValues = getUiObjectFromAccountVoucher(nextProps.selectedAccountVoucher);
            nextProps.resetForm(initialValues);
            this.getSubCompanyOfSupplier(initialValues.payor);
            if (nextProps.selectedAccountVoucher.state === 'posted') {
                const newSchema = cloneDeep(this.state.schema);
                if (isObjectValidAndNotEmpty(newSchema) && isArrayValidAndNotEmpty(newSchema.tableCells)) {
                    const index = newSchema.tableCells.findIndex(aColumn => aColumn.name === 'openBalance');
                    newSchema.tableCells.splice(index, 1);
                    this.setState({
                        schema: newSchema,
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const oldSupplier = getStringFromObject('payor', prevProps.values);
        const newSupplier = getStringFromObject('payor', this.props.values);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
            if (!this.props.isView) {
                this.props.setFieldValue('depositTo', null);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountVoucher());
    }

    onPrintVoucher = () => {
        this.setState(prevState => ({
            printPayment: !prevState.printPayment,
        }));
    };

    onConfirmPayment = (accountVoucher) => {
        console.log('asda0u9das0-da', accountVoucher, this.props.selectedAccountVoucher);
        const { handleClose } = this.props;
        this.setState(prevState => ({
            printData: accountVoucher,
            printPayment: !prevState.printPayment,
            onPrintCloseFunc: () => { handleClose(true); },
        }));
    };

    onPayorSelect = (obj) => {
        const { dispatch } = this.props;
        axios.get(API.RECEIVE_PAYMENT.INVOICES_FOR_USER, {
            params: {
                id: obj.id,
                open: true,
            },
        }).then((response) => {
            this.initializeTable(response.data);
        }).catch(error => apiCatchBlockFunction(error, dispatch));
    };

    onRowDelete = (index, values) => {
        const { setFieldValue } = this.props;
        // this.rowPaymentChangeHandler()
        // openBalance
        if (isArrayValidAndNotEmpty(values)) {
            const cloned = cloneDeep(values);
            cloned.splice(index);
            const totalAmountNeeded = sumBy(cloned, (item => item.openBalance));
            const totalpayment = sumBy(cloned, (item => item.payment));
            this.setState({ totalAmountNeeded });
            setFieldValue('amountReceived', Number(roundedValueFixedToTwoDigits(totalpayment)));
        }
    };

    onSave = () => {
        const { setFieldValue, submitForm } = this.props;
        const { subCompany } = this.state;
        setFieldValue('action', 'draft');
        setFieldValue('subCompany', subCompany);
        submitForm();
    };

    onConfirm = () => {
        const { setFieldValue, submitForm } = this.props;
        const { subCompany } = this.state;
        setFieldValue('subCompany', subCompany);
        submitForm();
    };

    onUploadAttachment = (attachment) => {
        const { setFieldValue } = this.props;
        setFieldValue('attachment', attachment);
    };

    getSubCompanyOfSupplier = async (supplier) => {
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        console.log('asd09audas09dsa', subCompany);
        this.props.setFieldValue('subCompany', subCompany);
        this.setState({
            subCompany,
        });
    };

    initializeTable = (data, initializeSupplier) => {
        let tableData = [];
        const { setFieldValue } = this.props;
        let totalAmountNeeded = 0;
        console.log('sdfdsfdsf', totalAmountNeeded, data);
        if (isArrayValidAndNotEmpty(data)) {
            const tableDataAndAmt = createTableDataFromInvoices(data);
            // eslint-disable-next-line
            tableData = tableDataAndAmt.tableData;
            // eslint-disable-next-line
            totalAmountNeeded = tableDataAndAmt.totalAmountNeeded;
            this.setState({ totalAmountNeeded });
            if (initializeSupplier) {
                const supplier = {
                    id: getStringFromObject('[0].partnerId', data),
                    uuid: getStringFromObject('[0].partnerUuid', data),
                    name: getStringFromObject('[0].patientName', data),
                };
                if (supplier.uuid && supplier.name) {
                    setFieldValue('payor', supplier);
                    this.getSubCompanyOfSupplier(supplier);
                }
            }
        }
        console.log('asda-0dias0-d-s', tableData);
        setFieldValue('accountInvoices', tableData);
    };

    handleBlurOnPayment = (event) => {
        const { values, setFieldValue } = this.props;
        let totalAmount = event.target.value;
        if (values.accountInvoices) {
            const accountInvoices = [...values.accountInvoices];
            const newInvoices = [];
            accountInvoices.map((invoice) => {
                const t = { ...invoice };
                if (totalAmount > 0) {
                    t.payment = totalAmount > t.openBalance ? Number(t.openBalance) : Number(totalAmount);
                    t.payment = Number(roundedValueFixedToTwoDigits(t.payment));
                    totalAmount -= t.openBalance;
                } else {
                    t.payment = 0;
                }
                newInvoices.push(t);
                return null;
            });
            setFieldValue('accountInvoices', newInvoices);
        }
    };

    rowPaymentChangeHandler = (value, fieldPath) => {
        const { setFieldValue, values } = this.props;
        if (fieldPath) {
            const accountInvoices = getStringFromObject('accountInvoices', values);
            let newAmount = 0;
            const rowIndex = Number(getIndexFromFieldName(fieldPath));
            let changeValue = null;
            accountInvoices.map((invoice, index) => {
                if (index === rowIndex) {
                    const openBalance = Number(getStringFromObject('openBalance', invoice, 0));
                    if (openBalance < Number(value)) {
                        newAmount += openBalance;
                        changeValue = Number(roundedValueFixedToTwoDigits(openBalance));
                    } else {
                        newAmount += Number(value);
                    }
                } else if (invoice.payment) {
                    newAmount += Number(invoice.payment);
                }
                return null;
            });
            setFieldValue('amountReceived', Number(roundedValueFixedToTwoDigits(newAmount)));
            if (changeValue) {
                setFieldValue(`accountInvoices.${rowIndex}.payment`, changeValue);
            }
        }
        /*
        const { formValues, dispatch } = this.props;
        const totalAmount = Number(getStringFromObject('amountReceived', formValues, 0));
        let newAmount = 0;
        if (formValues.accountInvoices) {
            const accountInvoices = [...formValues.accountInvoices];
            accountInvoices.map((invoice) => {
                if (invoice.payment) {
                    newAmount += Number(invoice.payment);
                }
                return null;
            });
            newAmount = Number(newAmount.toFixed(2));
            console.log('sdsdfsdfsdfsfd', newAmount);
            if (totalAmount < newAmount) {
                dispatch(change(formName, 'amountReceived', newAmount));
            }
        }
        */
    };

    maxValueAmountNeeded = (value) => {
        const isRequired = required(value);
        if (isRequired) {
            return isRequired;
        }
        return (
            !isEmpty(value) && value > this.state.totalAmountNeeded
                ? `Value Must Be Less Than ${this.state.totalAmountNeeded}`
                : undefined
        );
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            isPayor,
            appConfiguration,
            isSupplier, // eslint-disable-line
            isView,
            selectedSupplier,
            selectedAccountVoucher,
        } = this.props;
        const {
            schema,
            totalAmountNeeded,
            printPayment,
            onPrintCloseFunc,
            printData,
            subCompany,
        } = this.state;
        const paymentMethod = getStringFromObject('paymentMethod', values, null);
        let amountReceived = getStringFromObject('amountReceived', values, 0);
        if (isValidNumber(amountReceived)) {
            amountReceived = roundedValueFixedToTwoDigits(amountReceived);
        }
        let disableSupplierEdit = false;
        // if paying against a chosen supplier from supplier tab, don't allow to change
        if (isObjectValidAndNotEmpty(selectedSupplier)) {
            disableSupplierEdit = true;
        }
        const isPaymentDone = !!(getStringFromObject('state', values, false));
        console.log('fandjfadlfa', this.props.values);
        const canSavePayment =
            checkIfPrivilegeExistsForUser(getStringFromObject('purchasePaymentSavePrivilege', appConfiguration) || []);
        const canConfirmPayment =
            checkIfPrivilegeExistsForUser(getStringFromObject('purchasePaymentConfirmPrivilege', appConfiguration) || []);

        const isNotCashPayment = (paymentMethod === 'BANKTRANSFER') || (paymentMethod === 'CHEQUE');
        const isCashPayment = (paymentMethod === 'CASH');
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    Pay Invoice {
                                        isView ? '('.concat(selectedAccountVoucher.number).concat(')') : ''
                                    }
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="pay-invoice-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" justify="space-between">
                                <Grid item lg={3} md={4} sm={5}>
                                    <Field
                                        test-id="purachase-invoice-payer"
                                        name="payor"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        label={isPayor ? 'Payor' : 'Supplier'}
                                        onSelectHandlers={['onPayorSelect']}
                                        actionHandlers={{
                                            onPayorSelect: this.onPayorSelect,
                                        }}
                                        autocomplete
                                        validate={required}
                                        isDisabled={isView || disableSupplierEdit || isPaymentDone}
                                        dataSourceApi={isPayor ? API.SEARCH.PAYOR : API.SEARCH.SUPPLIER}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={5} style={{ textAlign: 'right' }}>
                                    { isPayor && !isView && <h5>Amount Needed</h5> }
                                    { isPayor && isView && <h5>Amount Received</h5> }
                                    { isSupplier && !isView && <h5>Total Amount</h5> }
                                    { isSupplier && isView && <h5>Amount Paid</h5> }
                                    <h3 test-id="total-amount">
                                        {getStringFromObject('currency', appConfiguration).concat(' ')}
                                        {isView ? amountReceived : totalAmountNeeded}
                                    </h3>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item lg={3} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-paymentdate"
                                        name="paymentDate"
                                        component={TextField}
                                        label="Payment Date"
                                        validate={required}
                                        type="date"
                                        placeholder="Payment Date"
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        disabled={isPaymentDone || isView}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="mt-2" justify="space-between" wrap="nowrap">
                                <Grid container item>
                                    <div>
                                        <Field
                                            test-id="purachase-invoice-paymentmethod"
                                            name="paymentMethod"
                                            component={TextField}
                                            validate={required}
                                            label="Payment Method"
                                            select
                                            placeholder="Payment Method"
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            style={{
                                                minWidth: '15em',
                                            }}
                                            disabled={isPaymentDone || isView}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {PAYMENT_METHODS.map(method => (
                                                <MenuItem key={method.value} value={method.value} testId={method.value}>
                                                    {method.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </div>
                                    {
                                        (paymentMethod === 'CHEQUE') &&
                                        <div style={{ margin: '0 0 0 2em' }}>
                                            <Field
                                                test-id="purachase-invoice-bankname"
                                                name="bankName"
                                                component={TextField}
                                                label="Bank Name"
                                                multiline
                                                placeholder="Bank Name"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classNames(classes.textField, classes.multiInput),
                                                    },
                                                }}
                                                style={{
                                                    minWidth: '20em',
                                                }}
                                                disabled={isPaymentDone || isView}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (isNotCashPayment) &&
                                            <div style={{ margin: '0 0 0 2em' }}>
                                                <Field
                                                    test-id="purachase-invoice-referenceno"
                                                    name="referenceNo"
                                                    component={TextField}
                                                    label={paymentMethod === 'CHEQUE' ? 'Cheque No' : 'Reference No'}
                                                    placeholder={paymentMethod === 'CHEQUE'
                                                        ? 'Cheque No' : 'Reference No'}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classNames(classes.textField, classes.input),
                                                        },
                                                    }}
                                                    style={{
                                                        minWidth: '15em',
                                                    }}
                                                    validate={required}
                                                    disabled={isPaymentDone || isView}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                    }
                                    {
                                        (paymentMethod === 'CHEQUE') &&
                                        <div style={{ margin: '0 0 0 2em' }}>
                                            <Field
                                                test-id="purachase-invoice-dateOfTransfer"
                                                name="dateOfTransfer"
                                                component={TextField}
                                                label={paymentMethod === 'CHEQUE' ? 'Cheque Date' : 'Date'}
                                                type="date"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classNames(classes.textField, classes.input),
                                                    },
                                                }}
                                                validate={required}
                                                disabled={isPaymentDone || isView}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (isNotCashPayment) &&
                                        <div style={{ margin: '0 0 0 2em' }}>
                                            <Field
                                                test-id="purachase-invoice-remarks"
                                                name="remarks"
                                                component={TextField}
                                                label="Remarks"
                                                multiline
                                                placeholder="Remarks"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classNames(classes.textField, classes.multiInput),
                                                    },
                                                }}
                                                style={{
                                                    minWidth: '20em',
                                                }}
                                                disabled={isPaymentDone || isView}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    }
                                    <div style={{ margin: '1em 0 0 2em', minWidth: '15em' }}>
                                        <Field
                                            test-id="purachase-invoice-depositTo"
                                            name="depositTo"
                                            component={FormikReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            label={isPayor ? 'Deposit To' : 'Credit From'}
                                            isDisabled={isPaymentDone || isView}
                                            autocomplete
                                            validate={required}
                                            key={subCompany}
                                            dataSourceApi={
                                                `${API.SEARCH.ACCOUNTS
                                                }?typeIgnoreList=consolidation,view&isSupplierPayment=true&type=liquidity&subCompany=${subCompany || ''}&searchString=`
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    {
                                        !isView &&
                                        <Field
                                            name="amountReceived"
                                            component={TextField}
                                            type="number"
                                            label={isPayor ? 'Amount Received' : 'Amount To Pay'}
                                            placeholder={isPayor ? 'Amount Received' : 'Amount To Pay'}
                                            onBlur={this.handleBlurOnPayment}
                                            InputProps={{
                                                'test-id': 'purachase-invoice-amountReceived',
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            style={{
                                                minWidth: '15em',
                                            }}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            validate={this.maxValueAmountNeeded}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                {
                                    isView &&
                                        <React.Fragment>
                                            <Grid container style={{ fontSize: '1.2rem' }}>Credit Lines</Grid>
                                            <Grid container>
                                                {
                                                    isObjectValidAndNotEmpty(schema) &&
                                                    <FormikTable
                                                        test-id="purachase-invoice-credit-lines"
                                                        fieldName="creditLines"
                                                        isEditable={false}
                                                        emptyMessage="No credit lines"
                                                        {...schema}
                                                        id="creditLines"
                                                        name="creditLines"
                                                    />
                                                }
                                            </Grid>
                                            <Grid container className="mt-2" style={{ fontSize: '1.2rem' }}>Debit Lines</Grid>
                                            <Grid container>
                                                {
                                                    isObjectValidAndNotEmpty(schema) &&
                                                    <FormikTable
                                                        test-id="purachase-invoice-debit-lines"
                                                        fieldName="debitLines"
                                                        isEditable={false}
                                                        emptyMessage="No debit lines"
                                                        {...schema}
                                                        id="debitLines"
                                                        name="debitLines"
                                                    />
                                                }
                                            </Grid>
                                        </React.Fragment>
                                }
                                {
                                    !isView &&
                                        <React.Fragment>
                                            <Grid container style={{ fontSize: '1.2rem' }}>
                                                Outstanding Transactions
                                            </Grid>
                                            <Grid container>
                                                {
                                                    isObjectValidAndNotEmpty(schema) &&
                                                    <FormikTable
                                                        test-id="purachase-invoice-accountInvoices"
                                                        fieldName="accountInvoices"
                                                        actionHandlers={{
                                                            rowPaymentChangeHandler: this.rowPaymentChangeHandler,
                                                        }}
                                                        onRowDelete={this.onRowDelete}
                                                        isEditable={!isPaymentDone && !isView}
                                                        emptyMessage="No outstanding transactions"
                                                        {...schema}
                                                    />
                                                }
                                            </Grid>
                                        </React.Fragment>
                                }
                                <Grid container className="mt-2" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            test-id="purachase-invoice-comment-memo"
                                            name="comment"
                                            component={TextField}
                                            label="Memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={isPaymentDone || isView}
                                        />
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4} className="flexChildren">
                                        <h4>Attachments</h4>:
                                        <ReduxFormChipFileUpload
                                            className="ml-1"
                                            customFileLabel="Attachments"
                                            uploadApi={API.FILE.UPLOAD}
                                            supportedTypes={[...imageFileTypes, ...documentFileTypes]}
                                            input={{
                                                value: values.attachment,
                                                onChange: this.onUploadAttachment,
                                            }}
                                            disabled={isPaymentDone || isView}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid container className="mt-2">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <div style={{ color: '#0000008a' }}>Attachments</div>
                                        <div className={classes.fileUpload}>
                                            <Field
                                                name="attachments"
                                                disabled={(!payor) || isView}
                                                component={UploadFile}
                                            />
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="purchase-invoice-cancel-payment"
                                primary={false}
                                disableRipple
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            {
                                isView && isObjectValidAndNotEmpty(selectedAccountVoucher) &&
                                    <ActionButton
                                        test-id="purchase-invoice-print-voucher"
                                        disableRipple
                                        onClick={this.onPrintVoucher}
                                    >
                                        Print Voucher
                                    </ActionButton>
                            }
                            {
                                !isView &&
                                <React.Fragment>
                                    {
                                        canSavePayment &&
                                        <ActionButton
                                            disableRipple
                                            onClick={this.onSave}
                                            disabled={isPaymentDone}
                                        >
                                            Save
                                        </ActionButton>
                                    }
                                    {
                                        (canConfirmPayment || (canSavePayment && !isCashPayment)) &&
                                        <ActionButton
                                            testId="purchase-invoice-pay"
                                            className="ml-1"
                                            disableRipple
                                            onClick={this.onConfirm}
                                            disabled={isPaymentDone}
                                        >
                                            {
                                                isPayor && 'Confirm'
                                            }
                                            {
                                                isSupplier && 'Pay'
                                            }
                                        </ActionButton>
                                    }
                                </React.Fragment>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PaymentVoucher/PaymentVoucher.html`}
                    data={isObjectValidAndNotEmpty(selectedAccountVoucher) ? selectedAccountVoucher : printData}
                    print={printPayment}
                    handleClose={onPrintCloseFunc}
                    subCompany={subCompany}
                />
            </React.Fragment>
        );
    }
}

ReceivePaymentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    appConfiguration: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    isPayor: PropTypes.bool,
    isSupplier: PropTypes.bool,
    isView: PropTypes.bool,
    accountInvoiceUuid: PropTypes.string,
    selectedSupplier: PropTypes.object,
    selectedAccountVoucher: PropTypes.object,
    invoicePayment: PropTypes.object,
    accountInvoices: PropTypes.array,
    accountVoucherId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /* formik props */
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    onConfirmPayment: PropTypes.func,
};

ReceivePaymentDialog.defaultProps = {
    isView: false,
    isPayor: false,
    isSupplier: false,
    selectedSupplier: {},
    accountInvoices: [],
    selectedAccountVoucher: {},
    invoicePayment: {},
    accountInvoiceUuid: '',
    accountVoucherId: '',
    // used to pass a success callback post payment.. mainly to print the voucher
    onConfirmPayment: () => {},
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    selectedAccountVoucher: getStringFromObject('payment.selectedVoucher', state, {}),
});

const handleSubmitForm = (values, { props, ...formikProps }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    let onConfirmPayment = getStringFromObject('onConfirmPayment', props);
    if (!isValidFunction(onConfirmPayment)) {
        onConfirmPayment = props.handleClose;
    }
    const payload = mapDtoFromUiObj(values);
    payload.invoicePaymentId = getStringFromObject('invoicePaymentId', props.invoicePayment);
    if (isArrayValidAndNotEmpty(payload.invoiceDtos)) {
        dispatcher(createReceivePaymentRequest(payload, onConfirmPayment));
    } else {
        dispatcher(showConfirmationDialog('Total Amount should be more that zero.'));
    }
    formikProps.setSubmitting(false);
};

const initializeForm = (props) => {
    let values = cloneDeep(PAYMENT_UI_OBJECT);
    const {
        invoicePayment,
    } = props;
    if (isObjectValidAndNotEmpty(invoicePayment)) {
        values = {
            payor: {
                uuid: getStringFromObject('partnerId.key', invoicePayment),
                name: getStringFromObject('partnerId.value', invoicePayment),
            },
            depositTo: getStringFromObject('account', invoicePayment),
            attachment: getStringFromObject('attachment', invoicePayment),
            paymentDate: formatDate(new Date(invoicePayment.dateTime), 'yyyy-MM-dd'),
            dateOfTransfer: formatDate(new Date(getStringFromObject('paymentDetails.date', invoicePayment)), 'yyyy-MM-dd'),
            paymentMethod: invoicePayment.paymentMode,
            referenceNo: getStringFromObject('paymentDetails.refNumber', invoicePayment),
            remarks: getStringFromObject('paymentDetails.remarks', invoicePayment),
            bankName: getStringFromObject('paymentDetails.bankName', invoicePayment),
            amountReceived: invoicePayment.amount,
            comment: invoicePayment.comment,
            accountInvoices: invoicePayment.lines.map(aLine => ({
                description: aLine.description,
                narration: aLine.narration,
                dueDate: getDateInDDMMMYYYYFormat(new Date(aLine.invoiceDueDate)),
                originalAmount: aLine.amountOriginal,
                openBalance: aLine.invoiceResidual,
                payment: aLine.amount,
                uuid: getStringFromObject('invoiceUuid', aLine, ''),

            })),
        };
    }
    if (isArrayValidAndNotEmpty(props.accountInvoices)) {
        values.accountInvoices = createTableDataFromInvoices(props.accountInvoices).tableData;
        const supplier = {
            id: getStringFromObject('[0].partnerId', props.accountInvoices),
            uuid: getStringFromObject('[0].partnerUuid', props.accountInvoices),
            name: getStringFromObject('[0].patientName', props.accountInvoices),
        };
        values.payor = supplier;
    }
    if (isObjectValidAndNotEmpty(props.selectedSupplier)) {
        values.payor = cloneDeep(props.selectedSupplier);
    }
    console.log('asda-0dias0-d-s--2', props, values);
    return values;
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: initializeForm,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(receivePaymentDialogStyles)(ReceivePaymentDialog)));
