/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import { FETCH_RECALL_LIST_SUCCESS } from './recallList-actions';

const recallListReducer = (state = initialStates.recallList, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_RECALL_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default recallListReducer;
