/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import zipObjectDeep from 'lodash.zipobjectdeep';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import CreatableSelect from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Favorites from '@material-ui/icons/Favorite';
import classNames from 'classnames';
import unionby from 'lodash.unionby';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import deepEqual from 'react-fast-compare';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import debounce from 'debounce-promise';
// import CancelIcon from '@material-ui/icons/Cancel';
import {
    apiCatchBlockFunction,
    extractTextFromDoubleQuotes,
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import { retriable } from '../../constants';
import { formatUrl } from '../../constants/UrlUtil';
import './ReactSelectMaterial.css';
import { getIcon } from '../ProfileComponent/TextFieldWithIcon';
import { getColorByExpiryDate } from '../PurchaseOrderComponents/InternalMovesDialog/InternalMovesUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const styles = theme => ({
    root: {
        flexGrow: 1,
        minWidth: '150px',
    },
    input: {
        display: 'flex',
        padding: '3.5px 9px',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    singleValueContainer: {
        display: 'flex',
        flexWrap: 'unset',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        paddingLeft: '3px',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        width: '22',
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
        borderRadius: '1rem',
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        fontSize: 12,
    },
    paper: {
        position: 'absolute',
        zIndex: '9999999 !important',
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        wordWrap: 'break-word',
        overflow: 'visible',
        width: '20rem',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    multiline: {
        padding: '0px',
    },
    menuItem: {
        background: theme.palette.background.paper,
        '&:hover': {
            background: theme.palette.type === 'light' ? '#c9c7c7' : '#808080',
        },
    },
});

const styles2 = {
    multiValue: (base, state) => (
        state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
    ),
    multiValueLabel: (base, state) => (
        state.data.isFixed ?
            {
                ...base,
                fontWeight: 'bold',
                color: 'white',
                paddingRight: 6,
            } : base
    ),
    multiValueRemove: (base, state) => (
        state.data.isFixed ? { ...base, display: 'none' } : base
    ),
};

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Option(props) {
    const isFixedOption = getStringFromObject('data.isFixedOption', props);
    const isColoredBatch = getStringFromObject('selectProps.showExpiryBatchWithColors', props);
    const { data } = props;
    let optionBackgroundColor;
    if (isColoredBatch && data.expiryDate) {
        optionBackgroundColor = getColorByExpiryDate(data.expiryDate);
    }
    if (isFixedOption) {
        return (
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                    whiteSpace: 'normal',
                    height: 'auto',
                }}
                {...props.innerProps}
            >
                <ListItemText
                    // classes={{ root: classes.itemTextRoot}}
                    primary={props.label}
                    style={{
                        padding: '0 2px',
                    }}
                />
                <ListItemIcon style={{ marginRight: '3px' }} testId="removeadditional">
                    <Favorites nativeColor="#ff0000" testId="remove-additional" />
                </ListItemIcon>
            </MenuItem>
        );
    }
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            className={getStringFromObject('selectProps.classes.menuItem', props)}
            disabled={props.isDisabled}
            style={{
                cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                fontWeight: props.isSelected ? 500 : 400,
                whiteSpace: 'normal',
                height: 'auto',
                backgroundColor: `${optionBackgroundColor}`,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function ValueContainer(props) {
    return (
        <div
            className={
                props.isMulti ?
                    props.selectProps.classes.valueContainer : props.selectProps.classes.singleValueContainer
            }
        >
            {props.children}
        </div>
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

/*
function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}
*/

const fixedOptionsMapper = fixedOptions => fixedOptions.map(
    aFixedOption => ({
        ...aFixedOption,
        isFixedOption: true,
    }),
);

class ReactSelectMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options || [],
            fixedOptionSet: isArrayValidAndNotEmpty(props.fixedOptions) ? props.fixedOptions : [],
        };
        if (isArrayValidAndNotEmpty(props.fixedOptions)) {
            this.state = {
                ...this.state,
                fixedOptionSet: fixedOptionsMapper(props.fixedOptions),
            };
        }
        const wait = 300; // milliseconds
        const loadOptions = inputValue => this.loadOptions(inputValue);
        this.debouncedLoadOptions = debounce(loadOptions, wait, {
            leading: true,
        });
    }

    componentDidMount() {
        this.fetchSelectOptions(this.props);
        if (!this.props.autocomplete && this.props.dataSourceApi && !this.props.isDisabled) {
            this.fetchOptions(this.props.dataSourceApi);
        }
        if (this.props.fixedOptionsSetApi) {
            const {
                fixedOptionsSetApi,
                dispatch,
            } = this.props;
            retriable().get(fixedOptionsSetApi)
                .then((response) => {
                    this.setState({
                        fixedOptionSet: fixedOptionsMapper(response.data || []),
                    });
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, dispatch);
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.dataSourceApi && nextProps.dataSourceApi !== this.props.dataSourceApi
        ) {
            this.fetchOptions(nextProps.dataSourceApi);
        }
        if (!deepEqual(nextProps.options, this.props.options)) {
            this.setState({
                options: nextProps.options,
            });
        }
        if ((this.props.isDisabled !== nextProps.isDisabled)) {
            this.fetchSelectOptions(nextProps);
        }
        if (!deepEqual(this.props.fixedOptions, nextProps.fixedOptions)) {
            this.setState({ fixedOptionSet: fixedOptionsMapper(nextProps.fixedOptions) });
        }
    }

    getNewOptionData = (createValue) => {
        console.log('as9-u09uas0d', createValue);
        const {
            dataSourceConfig,
            upperCaseOnly,
        } = this.props;
        let valueToCreate = createValue;
        if (createValue && upperCaseOnly) {
            valueToCreate = `${valueToCreate.toUpperCase()}`;
        }
        return zipObjectDeep(
            [dataSourceConfig.value, dataSourceConfig.text],
            [`NCD-${valueToCreate}`, `Create "${valueToCreate}"`],
        );
    };

    getOptionValue = (option) => {
        if (isValidFunction(this.props.getOptionValue)) {
            return this.props.getOptionValue(option);
        } else if (isObjectValidAndNotEmpty(this.props.dataSourceConfig)) {
            return getStringFromObject(this.props.dataSourceConfig.value, option);
        }
        return option;
    };

    getOptionLabel = (option) => {
        if (isValidFunction(this.props.getOptionLabel)) {
            return this.props.getOptionLabel(option);
        } else if (isObjectValidAndNotEmpty(this.props.dataSourceConfig)) {
            return getStringFromObject(this.props.dataSourceConfig.text, option);
        }
        return option;
    };

    getRef = (obj) => {
        console.log('ReactSelect', obj.select);
    };

    fetchSelectOptions = (props) => {
        if (!props.autocomplete && props.dataSourceApi && !props.isDisabled) {
            this.fetchOptions(props.dataSourceApi);
        }
    };

    Placeholder = (props) => {
        const { selectProps } = this.props;
        const textFieldProps = selectProps && selectProps.textFieldProps;
        const InputProps = textFieldProps && textFieldProps.InputProps;
        return (
            <Typography
                color="textSecondary"
                className={
                    classNames(props.selectProps.classes.placeholder,
                        InputProps && InputProps.classes && InputProps.classes.placeholder)}
                {...props.innerProps}
            >
                {props.children}
            </Typography>
        );
    };

    extractValue = (value) => {
        if (typeof value === 'string' && value.includes('Create')) {
            return extractTextFromDoubleQuotes(value);
        }
        return value;
    };

    SingleValue = (props) => {
        const {
            selectProps,
            dataSourceConfig,
        } = this.props;
        let value = null;
        if (isObjectValidAndNotEmpty(props.children) && typeof props.children !== 'string') {
            value = { ...props.children };
            value = dataSourceConfig ? value[dataSourceConfig.text] : '';
        } else {
            value = props.children;
        }
        const singleStyle = selectProps ? selectProps.singleValueStyle : {};
        console.log('singleStyle', singleStyle);
        return (
            <Typography style={{ ...singleStyle }} {...props.innerProps}>
                {this.extractValue(value)}
            </Typography>
        );
    };

    Control = (params) => {
        const {
            selectProps,
            errorText,
            onBlur,
            onKeyPress,
            classes,
            variant,
            fullWidth,
            testId,
            icon,
        } = this.props;
        let startAdornment = null;
        if (icon) {
            startAdornment = React.isValidElement(getIcon({ icon })) ?
                getIcon({ icon }) :
                React.createElement(
                    getIcon({ icon }),
                    { className: 'basic-info-icon--gray', style: { paddingLeft: '0.75rem' } },
                );
        }
        const textFieldProps = getStringFromObject('textFieldProps', selectProps, {});
        const id = getStringFromObject('id', selectProps, '');
        const InputProps = getStringFromObject('InputProps', textFieldProps, {});
        const classesProps = getStringFromObject('classes', InputProps, null);
        const variantFromProps = getStringFromObject('variant', textFieldProps, 'outlined');
        return (
            <TextField
                fullWidth={fullWidth}
                multiline
                onBlur={onBlur}
                onKeyUp={onKeyPress}
                InputProps={{
                    inputComponent,
                    classes: {
                        root: classesProps && classesProps.placeholder,
                        multiline: (classesProps && classesProps.multiline) || classes.multiline,
                    },
                    inputProps: {
                        className: params.selectProps.classes.input,
                        inputRef: params.innerRef,
                        children: params.children,
                        ...params.innerProps,
                        id,
                        'test-id': testId,
                    },
                    startAdornment,
                    ...InputProps,
                    id,
                }}
                error={Boolean(errorText)}
                helperText={errorText}
                variant={variantFromProps || variant}
                {...params.selectProps.textFieldProps}
            />
        );
    };

    fetchOptions = (api) => {
        const { dispatch } = this.props;
        retriable().get(api)
            .then((response) => {
                this.setState({
                    options: response.data || [],
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
            });
    };

    // this method will search over the fixed option set and the set received from the api call
    handleSearchOverOptionSet = (searchedOptions, searchText) => {
        const {
            fixedOptionSet,
        } = this.state;
        const lowerCasedSearchText = (searchText || '').toLowerCase();
        console.log('asdas0-daud-0sd-adad', fixedOptionSet, searchedOptions);
        let finalOptionSet = [...searchedOptions];

        if (isArrayValidAndNotEmpty(fixedOptionSet)) {
            // iterate and search over fixed option set
            let applicableFixedOptions = fixedOptionSet.concat();
            if (searchText) {
                applicableFixedOptions =
                    fixedOptionSet.filter(anOption => (
                        (this.getOptionLabel(anOption) || '').toLowerCase().includes(lowerCasedSearchText)
                    ));
            }
            console.log('sad-as0uds-aa', applicableFixedOptions);
            if (isArrayValidAndNotEmpty(applicableFixedOptions)) {
                // remove the applicable fixed options from original option set
                const {
                    dataSourceConfig,
                } = this.props;
                finalOptionSet = unionby(applicableFixedOptions, finalOptionSet, dataSourceConfig.value);
            }
        }

        if (isArrayValidAndNotEmpty(finalOptionSet) && searchText) {
            console.log('asd0a-sdi-as0dsada', finalOptionSet);
            finalOptionSet = finalOptionSet.sort((a, b) => {
                const aTextValue = this.getOptionLabel(a);
                const bTextValue = this.getOptionLabel(b);
                if (aTextValue && aTextValue.startsWith(searchText.toLowerCase())) {
                    return -1;
                }
                if (bTextValue && bTextValue.startsWith(searchText.toLowerCase())) {
                    return -1;
                }
                return 0;
            });
        }

        return finalOptionSet;
    };

    loadOptions = (searchText) => {
        const { dataSourceApi, paramMap, dispatch } = this.props;
        console.log('asdia9sid-0asd', searchText, dataSourceApi);
        if (dataSourceApi) {
            const createdObject = formatUrl(dataSourceApi, paramMap);
            return (
                retriable().get(`${createdObject}${encodeURIComponent(searchText)}`)
                    .then(response => this.handleSearchOverOptionSet(response.data || [], searchText))
                    .catch((error) => {
                        apiCatchBlockFunction(error, dispatch);
                    }));
        }
        return new Promise(resolve => resolve([]));
    };

    render() {
        const {
            classes,
            theme,
            value,
            onChange,
            editable,
            dataSourceConfig,
            label,
            noLabel,
            isDisabled,
            multiple,
            onBlur,
            onFocus,
            autocomplete,
            autocompleteSelectStyle,
            creatable,
            placeholder,
            cacheOptions,
            inputRef,
            defaultOptions,
            required,
            autoFocus,
            isClearable,
            hasFixedOptions,
            createOptionPosition,
            testId,
            isOptionDisabled,
            fixedOptions,
            disableMinWidth,
            dataSourceApi,
            showExpiryBatchWithColors,
        } = this.props;
        const {
            options,
        } = this.state;
        console.log('asd-0-as0-doasd', options, fixedOptions);
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                ...autocompleteSelectStyle,
                '& input': {
                    font: 'inherit',
                },
            }),
            menu: provided => ({ ...provided, zIndex: '99999999999999 !important' }),
            menuList: provided => ({ ...provided, zIndex: '99999999999 !important' }),
            zIndex: '9999 !important',
        };

        const components = {
            Control: this.Control,
            Menu,
            NoOptionsMessage,
            Option,
            Placeholder: this.Placeholder,
            SingleValue: this.SingleValue,
            ValueContainer,
            // MultiValue,
        };
        let labelComponent = label;
        if (required) {
            labelComponent = getRequiredFieldLabel(label);
        }
        // console.log('asd09u-0suadasdasd', editable, MultiValue);
        if (!editable) {
            return (
                <div>
                    <div className="label">
                        {noLabel ? '' : labelComponent}
                    </div>
                    {
                        multiple && (isArrayValidAndNotEmpty(value) ?
                            <div className="font-size-1">
                                {
                                    value.map((v, idx) => (
                                        `${getStringFromObject(dataSourceConfig.text, v)},
                                        ${value.length !== idx ? ',' : ''}`
                                    ))
                                }
                            </div> : <span className="error">Parse error !!</span>
                        )
                    }
                    {
                        !multiple &&
                        <div className="font-size-1">
                            {this.extractValue(
                                dataSourceConfig ? getStringFromObject(dataSourceConfig.text, value) : value,
                            )}
                        </div>
                    }
                </div>
            );
        }
        const commonProps = {
            classes,
            'test-id': testId,
            styles: hasFixedOptions ? styles2 : selectStyles,
            options,
            components,
            showExpiryBatchWithColors,
            value,
            onBlur,
            onFocus,
            autoFocus,
            isClearable,
            isOptionDisabled,
            ref: inputRef,
            menuOpen: true,
            getOptionValue: this.getOptionValue,
            getOptionLabel: this.getOptionLabel,
            onChange,
            placeholder,
            isMulti: multiple,
            isDisabled,
            textFieldProps: {
                label: noLabel ? '' : labelComponent,
                InputLabelProps: {
                    shrink: true,
                },
            },
        };
        if (creatable && autocomplete) {
            return (
                <div className={classes.root}>
                    <AsyncCreatableSelect
                        {...commonProps}
                        defaultOptions={defaultOptions}
                        classNamePrefix="my-menu"
                        // cache options is false because it is causing issues when debouncing
                        // like it doesn't load options for any characters before the debounce call
                        cacheOptions={false}
                        createOptionPosition={createOptionPosition}
                        getNewOptionData={this.getNewOptionData}
                        loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
                        key={dataSourceApi}
                    />
                </div>
            );
        }
        if (autocomplete) {
            return (
                <div className={classes.root}>
                    <NoSsr>
                        <AsyncSelect
                            {...commonProps}
                            defaultOptions={defaultOptions}
                            classNamePrefix="my-menu"
                            // cache options is false because it is causing issues when debouncing
                            // like it doesn't load options for any characters before the debounce call
                            cacheOptions={false}
                            loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
                            key={dataSourceApi}
                        />
                    </NoSsr>
                </div>
            );
        }
        if (creatable) {
            return (
                <div className={classes.root}>
                    <NoSsr>
                        <CreatableSelect
                            {...commonProps}
                            getNewOptionData={this.getNewOptionData}
                            cacheOptions={cacheOptions}
                            classNamePrefix="my-menu"
                        />
                    </NoSsr>
                </div>
            );
        }
        return (
            <div className={classes.root} style={disableMinWidth ? { minWidth: 0 } : {}}>
                <NoSsr>
                    <Select
                        {...commonProps}
                        cacheOptions={cacheOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </NoSsr>
            </div>
        );
    }
}

ReactSelectMaterial.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    dataSourceConfig: PropTypes.object.isRequired,
    label: PropTypes.string,
    testId: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    inputRef: PropTypes.func,
    options: PropTypes.array,
    dataSourceApi: PropTypes.string,
    errorText: PropTypes.string,
    fixedOptionsSetApi: PropTypes.string,
    createOptionPosition: PropTypes.string,
    icon: PropTypes.string,
    editable: PropTypes.bool,
    noLabel: PropTypes.bool,
    multiple: PropTypes.bool,
    autocomplete: PropTypes.bool,
    autocompleteSelectStyle: PropTypes.object,
    paramMap: PropTypes.object,
    creatable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    cacheOptions: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    autoFocus: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isClearable: PropTypes.bool,
    hasFixedOptions: PropTypes.bool,
    upperCaseOnly: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    isOptionDisabled: PropTypes.func,
    getOptionValue: PropTypes.func,
    fixedOptions: PropTypes.array,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    disableMinWidth: PropTypes.bool,
    showExpiryBatchWithColors: PropTypes.bool,
};

ReactSelectMaterial.defaultProps = {
    label: 'Search and Select',
    variant: 'outlined',
    placeholder: '',
    testId: '',
    value: null,
    onChange: () => {},
    onKeyPress: () => {},
    onBlur: () => {},
    inputRef: () => {},
    onFocus: () => {},
    isOptionDisabled: null,
    options: [],
    dataSourceApi: '',
    fixedOptionsSetApi: '',
    createOptionPosition: '',
    errorText: '',
    icon: '',
    editable: true,
    fullWidth: true,
    noLabel: false,
    multiple: false,
    autocomplete: false,
    upperCaseOnly: false,
    autocompleteSelectStyle: {},
    creatable: false,
    isDisabled: false,
    paramMap: {},
    cacheOptions: true,
    defaultOptions: true,
    required: false,
    autoFocus: false,
    isClearable: false,
    hasFixedOptions: false,
    getOptionValue: null,
    getOptionLabel: null,
    fixedOptions: [],
    disableMinWidth: false,
    showExpiryBatchWithColors: false,
};

export default connect()(withStyles(styles, { withTheme: true })(ReactSelectMaterial));
