import React from 'react';
import { Route } from 'react-router';
import EmployeeOrganisationContainer
    from '../../containers/EmployeeOrganisation/EmployeeOrganisationContainer';
import { organizationList, organizationTree } from '../../constants/constants';
import OrganizationTreeContainer from '../../containers/EmployeeOrganisation/OrganizationTreeContainer';
import OrganizationListContainer from '../../containers/EmployeeOrganisation/OrganizationListContainer';

const EmployeeOrganisationPage = props => (
    <EmployeeOrganisationContainer {...props} >
        <Route path={organizationTree} exact component={OrganizationTreeContainer} />
        <Route path={organizationList} exact component={OrganizationListContainer} />
    </EmployeeOrganisationContainer>
);

export default EmployeeOrganisationPage;
