import API from '../../../constants/api';

export const SAVE_PRICE_LIST_REQUEST = '@@priceList/SAVE_PRICE_LIST_REQUEST';
export const SAVE_PRICE_LIST_SUCCESS = '@@priceList/SAVE_PRICE_LIST_SUCCESS';
export const SAVE_PRICE_LIST_FAILURE = '@@priceList/SAVE_PRICE_LIST_FAILURE';

export const FETCH_PRICE_LIST_REQUEST = '@@priceList/FETCH_PRICE_LIST_REQUEST';
export const FETCH_PRICE_LIST_SUCCESS = '@@priceList/FETCH_PRICE_LIST_SUCCESS';
export const FETCH_PRICE_LIST_FAILURE = '@@priceList/FETCH_PRICE_LIST_FAILURE';

export const savePriceList = (payload, payorUuid, state, applyFrom, successCallback) => ({
    type: SAVE_PRICE_LIST_REQUEST,
    api: API.PAYORS.SAVE_PRICE_LIST,
    payload,
    payorUuid,
    state,
    applyFrom,
    successCallback,
});

export const fetchPriceList = (payorUuid, state, successCallback, failureCallback) => ({
    type: FETCH_PRICE_LIST_REQUEST,
    api: API.PAYORS.FETCH_PRICE_LIST,
    payorUuid,
    state,
    successCallback,
    failureCallback,
});

export const fetchPriceListByUuid = (payorUuid, state, successCallback, failureCallback, priceListUuid) => ({
    type: FETCH_PRICE_LIST_REQUEST,
    api: API.PAYORS.FETCH_PRICE_LIST_BY_UUID,
    payorUuid,
    state,
    successCallback,
    failureCallback,
    priceListUuid,
});
