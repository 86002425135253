import { takeLatest } from 'redux-saga/effects';
import { createProductWorker, fetchPagesOfProductsWorker } from './productWorkerSaga';
import { CREATE_PRODUCTS_REQUEST, FETCH_PRODUCTS_LIST_REQUEST } from '../../redux/modules/products/products-actions';

export function* fetchPagesOfProductsWatcher() {
    yield takeLatest(FETCH_PRODUCTS_LIST_REQUEST, fetchPagesOfProductsWorker);
}

export function* createProductWatcher() {
    yield takeLatest(CREATE_PRODUCTS_REQUEST, createProductWorker);
}
