/**
 * Created by vinay on 19/12/18.
 */
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
    CREATE_DOCTOR_SLOT_REQUEST,
    FETCH_APPOINTMENT_TYPES_REQUEST,
    FETCH_PROVIDER_WEEK_REQUEST,
    FETCH_SERVICE_WEEK_REQUEST,
    REMOVE_DAILY_SCHEDULE_REQUEST,
    RUN_DOCTOR_SLOT_SCHEDULER_REQUEST,
    UPDATE_DAILY_SCHEDULE_REQUEST,
    UPDATE_DOCTOR_SLOT_REQUEST,
    UPDATE_SLOTS_REQUEST,
} from '../../redux/modules/appointmentSlot/appointmentSlot-actions';
import {
    createOrUpdateDoctorSlot,
    fetchAppointmentTypesWorker,
    fetchProviderWeekWorker,
    fetchServiceWeekWorker,
    runDoctorSlotSchedulerWorker,
} from './appointmentSlotWorkerSaga';
import { commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';


export function* fetchAppointmentTypesWatcher() {
    yield takeLatest(FETCH_APPOINTMENT_TYPES_REQUEST, fetchAppointmentTypesWorker);
}

export function* fetchServiceWeekWatcher() {
    yield takeLatest(FETCH_SERVICE_WEEK_REQUEST, fetchServiceWeekWorker);
}


export function* fetchProviderWeekWatcher() {
    yield takeEvery(FETCH_PROVIDER_WEEK_REQUEST, fetchProviderWeekWorker);
}

export function* createDoctorSlotWatcher() {
    yield takeLatest(CREATE_DOCTOR_SLOT_REQUEST, createOrUpdateDoctorSlot);
}

export function* updateDoctorSlotWatcher() {
    yield takeLatest(UPDATE_DOCTOR_SLOT_REQUEST, createOrUpdateDoctorSlot);
}

export function* runDoctorSlotSchedulerWatcherSaga() {
    yield takeLatest(RUN_DOCTOR_SLOT_SCHEDULER_REQUEST, runDoctorSlotSchedulerWorker);
}

export function* updateDailyScheduleWatcherSaga() {
    yield takeLatest(UPDATE_DAILY_SCHEDULE_REQUEST, commonPostWorkerSaga);
}

export function* updateSlotsWatcherSaga() {
    yield takeLatest(UPDATE_SLOTS_REQUEST, commonPostWorkerSaga);
}

export function* deleteDailyScheduleWatcherSaga() {
    yield takeLatest(REMOVE_DAILY_SCHEDULE_REQUEST, commonPostWorkerSaga);
}
