import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import MESSAGES from '../../constants/messages';
import { retriable } from '../retriable';
import {
    FETCH_INVOICE_DETAILS_SUCCESSS,
    FETCH_INVOICE_LINE_DATA_SUCCESSS,
    FETCH_INVOICE_LIST_DATA_SUCCESSS,
} from '../../redux/modules/invoice/invoice-actions';
import {
    generateConstructFromFilterColumns,
    generateSearchFilterConstruct,
} from '../../components/GenericFilterComponent/GenericFilterComponentUtil';
import { COMBINEMODE, generateObjectWithCombineMode, generateObjectWithCriteriaMode, getCombined } from '../util';
import {
    insuranceInvoiceTableFilter,
    insuranceSearchFieldFilters,
} from '../../containers/InvoiceListContainer/InsuranceInvoiceUtil';
import {
    invoiceTypes,
} from '../../containers/RegistrationAppComponents/QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';

import { getStringFromObject } from '../../constants/lodashUtils';

// const isInsuranceInvoiceSearch = state => state.insuranceInvoice.isInsuranceInvoiceSearch;

export function* getInsuranceInvoiceListWorker(action) {
    try {
        const api = `${action.api}?page=${action.page}&size=${action.size}`;
        // const search = yield select(isInsuranceInvoiceSearch);
        const {
            searchText,
            filters,
        } = action;
        const filterConstruct = generateConstructFromFilterColumns(insuranceInvoiceTableFilter(), filters);
        const invoiceState = getStringFromObject('account_invoice.state.key', filters, null);
        console.log('afnjadka', filters, invoiceState, searchText);
        const searchValueConstruct = generateSearchFilterConstruct(searchText, insuranceSearchFieldFilters);
        let insuranceInvoice = generateObjectWithCombineMode(generateObjectWithCriteriaMode(
            'account_invoice.paymentType',
            invoiceTypes.INSURANCE,
            '=',
        ), 'OR', generateObjectWithCriteriaMode(
            'account_invoice.paymentType',
            invoiceTypes.CREDIT,
            '=',
        ));
        insuranceInvoice = generateObjectWithCombineMode(generateObjectWithCriteriaMode(
            'account_invoice.paymentType',
            invoiceTypes.CORPORATE,
            '=',
        ), 'OR', insuranceInvoice);
        let searchConstruct = getCombined(filterConstruct, COMBINEMODE.AND, searchValueConstruct);
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, insuranceInvoice);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().post,
            api,
            searchConstruct,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put({
            type: FETCH_INVOICE_LIST_DATA_SUCCESSS,
            data: response.data,
            filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (e.response && e.response.data && e.response.data.message) {
            yield put({ type: ERROR_MESSAGE, message: e.response.data.message });
        } else {
            yield put({ type: ERROR_MESSAGE, message: MESSAGES.INSURANCE_INVOICE.ERROR_IN_INSURANCE_INVOICE_FETCH });
        }
    }
}

export function* getInsuranceInvoiceLinesWorker(action) {
    try {
        console.log('dddddd', action);
        const api = `${action.api}?insuranceInvoice=${action.uuid}`;
        console.log('inside worker of insurance invoice', action, api);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().get,
            api,
        );
        console.log('resposeeee', response);
        yield put({
            type: FETCH_INVOICE_LINE_DATA_SUCCESSS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (e.response && e.response.data && e.response.data.message) {
            yield put({ type: ERROR_MESSAGE, message: e.response.data.message });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: MESSAGES.INSURANCE_INVOICE.ERROR_IN_INSURANCE_INVOICE_LINE_FETCH,
            });
        }
    }
}

export function* getInsuranceInvoiceDetailsWorker(action) {
    try {
        const api = `${action.api}?uuid=${action.uuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().get,
            api,
        );
        console.log('resposeeee', response);
        yield put({
            type: FETCH_INVOICE_DETAILS_SUCCESSS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (e.response && e.response.data && e.response.data.message) {
            yield put({ type: ERROR_MESSAGE, message: e.response.data.message });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: MESSAGES.INSURANCE_INVOICE.ERROR_IN_INSURANCE_INVOICE_LINE_FETCH,
            });
        }
    }
}

export function* updateInsuranceInvoiceLineWorker(action) {
    try {
        const api = `${action.api}?invoiceUuid=${action.uuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(
            retriable().put,
            api,
            action.data,
        );
        console.log('resposeeee', response);
        yield put({
            type: FETCH_INVOICE_LINE_DATA_SUCCESSS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (e.response && e.response.data && e.response.data.message) {
            yield put({ type: ERROR_MESSAGE, message: e.response.data.message });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: MESSAGES.INSURANCE_INVOICE.ERROR_IN_INSURANCE_INVOICE_LINE_FETCH,
            });
        }
    }
}

