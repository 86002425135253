import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_CONSUMABLE_REQUEST,
    FETCH_CONSUMABLE_LIST_REQUEST,
} from '../../redux/modules/consumables/consumable-actions';
import { createConsumableWorker, fetchPagesOfConsumablesWorker } from './consumableWorkerSaga';

export function* fetchPagesOfConsumableWatcher() {
    yield takeLatest(FETCH_CONSUMABLE_LIST_REQUEST, fetchPagesOfConsumablesWorker);
}

export function* createConsumableWatcher() {
    yield takeLatest(CREATE_CONSUMABLE_REQUEST, createConsumableWorker);
}
