import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import './Spinner.css';
import { isValidTextAndNotEmpty } from '../../constants/CommonUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const styles = () => ({
    progress: {
        visibility: 'visible',
    },
    spinner: {
        visibility: 'hidden',
    },
    linear: {
        background: '#8888',
    },
    root: {
        visibility: 'hidden',
    },
});

const Spinner = ({ spinner, classes, canShow }) => {
    console.log('asda-0dias-d0asd', spinner, canShow);
    const open = spinner.canShow || canShow;
    const message = getStringFromObject('message', spinner, '');
    if (open) {
        return (
            <Dialog
                open={spinner.canShow || canShow}
                classes={
                    isValidTextAndNotEmpty(message) ? {} : {
                        paper: classes.root,
                    }}
            >
                <DialogContent
                    className={isValidTextAndNotEmpty(message) ? classes.linear : classes.spinner}
                >
                    {
                        isValidTextAndNotEmpty(message) ?
                            <React.Fragment>

                                <LinearProgress style={{ marginBottom: '8px' }} className={classes.progress} />
                                <Typography >{message}</Typography>
                            </React.Fragment>
                            :
                            <CircularProgress className={classes.progress} />

                    }

                </DialogContent>
            </Dialog>
        );
    }
    return null;
};

Spinner.propTypes = {
    spinner: PropTypes.object,
    classes: PropTypes.object,
    canShow: PropTypes.bool,
};

Spinner.defaultProps = {
    classes: {},
    spinner: {},
    canShow: false,
};

const mapStateToProps = state => ({
    spinner: state.spinner,
});

export default connect(mapStateToProps)(withStyles(styles)(Spinner));

