import API from '../../../constants/api';

export const CREATE_MATERIAL_RECEIVE_REQUEST = '@@materialReceive/CREATE_MATERIAL_RECEIVE_REQUEST';
export const CREATE_MATERIAL_RECEIVE_FAILURE = '@@materialReceive/CREATE_MATERIAL_RECEIVE_FAILURE';
export const CREATE_MATERIAL_RECEIVE_SUCCESS = '@@materialReceive/CREATE_MATERIAL_RECEIVE_SUCCESS';
export const CANCEL_OR_REVERT_MATERIAL_RECEIVE = '@@materialReceive/CANCEL_OR_REVERT_MATERIAL_RECEIVE';
export const CANCEL_OR_REVERT_MATERIAL_RECEIVE_FAILURE = '@@materialReceive/CANCEL_OR_REVERT_MATERIAL_RECEIVE_FAILURE';
export const CANCEL_OR_REVERT_MATERIAL_RECEIVE_SUCCESS = '@@materialReceive/CANCEL_OR_REVERT_MATERIAL_RECEIVE_SUCCESS';

export const FETCH_MATERIAL_RECEIVE_REQUEST = '@@materialReceive/FETCH_MATERIAL_RECEIVE_REQUEST';
export const FETCH_MATERIAL_RECEIVE_FAILURE = '@@materialReceive/FETCH_MATERIAL_RECEIVE_FAILURE';
export const FETCH_MATERIAL_RECEIVE_SUCCESS = '@@materialReceive/FETCH_MATERIAL_RECEIVE_SUCCESS';

export const CLEAR_SELECTED_MATERIAL_RECEIVE = '@@materialReceive/CLEAR_SELECTED_MATERIAL_RECEIVE';

export const MARK_MATERIAL_RECEIVE_AS_COMPLETE = '@@materialReceive/MARK_MATERIAL_RECEIVE_AS_COMPLETE';

export const createMaterialReceiveRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_MATERIAL_RECEIVE_REQUEST,
    payload,
    api: API.MATERIAL_RECEIVE.ADD_OR_UPDATE,
    successCallback,
    failureCallBack,
});

export const fetchMaterialReceiveRequest = (materialReceiveApi, successCallback, failureCallback) => ({
    type: FETCH_MATERIAL_RECEIVE_REQUEST,
    api: materialReceiveApi,
    successCallback,
    failureCallback,
});

export const clearMaterialReceiveSelectState = () => ({
    type: CLEAR_SELECTED_MATERIAL_RECEIVE,
});

export const markAsComplete = (api, successCallback, failureCallBack) => ({
    type: MARK_MATERIAL_RECEIVE_AS_COMPLETE,
    api,
    successCallback,
    failureCallBack,
});

export const cancelOrRevertMaterialReceive = (uuid, status, successCallback, failureCallBack) => ({
    type: CANCEL_OR_REVERT_MATERIAL_RECEIVE,
    status,
    uuid,
    api: status === 'REVERT' ?
        API.MATERIAL_RECEIVE.REVERT_MATERIAL_RECEIVE : API.MATERIAL_RECEIVE.CANCEL_MATERIAL_RECEIVE,
    successCallback,
    failureCallBack,
});
