/**
 * Created by sachinprakash on 9/7/19.
 */
import initialStates from '../../initialStates';
import {
    ADD_OR_UPDATE_DEPARTMENT_FAILURE,
    ADD_OR_UPDATE_DEPARTMENT_SUCCESS, ADD_OR_UPDATE_DICTIONARY_FAILURE,
    ADD_OR_UPDATE_DICTIONARY_SUCCESS,
    ADD_OR_UPDATE_PANEL_FAILURE,
    ADD_OR_UPDATE_PANEL_SUCCESS,
    ADD_OR_UPDATE_TEMPLATE_SUCCESS,
    ADD_OR_UPDATE_TEMPLATE_FAILURE,
    ADD_OR_UPDATE_TESTS_FAILURE,
    ADD_OR_UPDATE_TESTS_SUCCESS,
    ADD_OR_UPDATE_UOM_FAILURE,
    ADD_OR_UPDATE_UOM_SUCCESS,
    FETCH_DEPARTMENT_FAILURE,
    FETCH_DEPARTMENT_SUCCESS,
    FETCH_DICTIONARY_FAILURE,
    FETCH_DICTIONARY_SUCCESS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_SAMPLE_FAILURE,
    FETCH_SAMPLE_SUCCESS,
    FETCH_TESTS_FAILURE,
    FETCH_TESTS_SUCCESS,
    FETCH_UOM_FAILURE,
    FETCH_UOM_SUCCESS,
} from './labTest-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';

export const labTests = (state = initialStates.labTests, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_TESTS_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_TESTS_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export const addOrUpdateTest = (state = initialStates.tests, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_TESTS_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_TESTS_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const labPanels = (state = initialStates.labPanels, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_PANELS_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_PANELS_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export const labDepartments = (state = initialStates.labDepartment, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_DEPARTMENT_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_DEPARTMENT_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export const labSamples = (state = initialStates.labSamples, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_SAMPLE_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_SAMPLE_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};


export const labDictionary = (state = initialStates.labDictionary, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_DICTIONARY_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_DICTIONARY_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export const labUom = (state = initialStates.labUom, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_UOM_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_UOM_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export const addOrUpdateUom = (state = initialStates.uom, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_UOM_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_UOM_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};


export const addOrUpdateDepartment = (state = initialStates.department, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_DEPARTMENT_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_DEPARTMENT_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const addOrUpdatePanel = (state = initialStates.panel, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_PANEL_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_PANEL_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};


export const addOrUpdateSample = (state = initialStates.sample, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_PANEL_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_PANEL_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};


export const addOrUpdateDictionary = (state = initialStates.dictionary, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_DICTIONARY_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_DICTIONARY_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const addOrUpdateTemplate = (state = initialStates.template, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_OR_UPDATE_TEMPLATE_SUCCESS:
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case ADD_OR_UPDATE_TEMPLATE_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};
