import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import Grid from '@material-ui/core/Grid';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Clear from '@material-ui/icons/Clear';
import {
    apiCatchBlockFunction,
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,
    isValidFunction,
    findDefinedArrayElements,
} from '../../../constants/CommonUtil';
import ReactSelectMaterial from '../../../components/ReactSelectMaterial/ReactSelectMaterial';
import api from '../../../constants/api';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import ACCOUNTS from './AccountsFavoritesUtil';
import { GLOBAL_PROPERTIES } from '../../../constants/constants';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
// import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { clearFavoritesOfType } from '../../../constants/favoritesStorage';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const style = () => ({
    containerHeight: {
        height: '12rem',
        maxHeight: '12rem',
        overflow: 'auto',
        border: '1px solid #afafaf',
        boxShadow: '2px 2px #efefef',
    },
});


class AccountsFavorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: {},
            loadedFavorites: {},
        };
    }

    componentDidMount() {
        if (isValidFunction(this.props.updateDialogActions)) {
            this.props.updateDialogActions(
                <React.Fragment>
                    <ActionButton onClick={this.props.handleClose} primary={false} className="mr-1" test-id="accounts-cancel">
                        Cancel
                    </ActionButton>
                    <ActionButton onClick={this.onSaveFavorites} test-id="accounts-save">
                        Save
                    </ActionButton>
                </React.Fragment>,
            );
        }
        this.findAllFavorites();
    }

    onSaveFavorites = () => {
        const {
            favorites,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        console.log('treushooties', favorites);
        const favs = cloneDeep(favorites);
        let completeFavTypes = [];
        completeFavTypes = completeFavTypes.concat(cloneDeep(favorites[ACCOUNTS]));
        delete favs[completeFavTypes];
        let favsDto = Object.values(favs);
        if (isArrayValidAndNotEmpty(completeFavTypes)) {
            favsDto = favsDto.concat(completeFavTypes);
        }
        dispatch(showSpinner());
        axios.post(api.CLINICAL_FAVORITES.SAVE, findDefinedArrayElements(favsDto))
            .then(() => {
                this.findAllFavorites();
                dispatch(successMessage('Favorites Saved Successfully'));
                clearFavoritesOfType(ACCOUNTS);
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
            })
            .finally(() => {
                dispatch(hideSpinner());
            });
    }

    onFavoriteSelect = favoriteType => (favoriteObject) => {
        const { appConfiguration } = this.props;
        const maxFavoritesFromGP = getStringFromObject(GLOBAL_PROPERTIES.NUACARE_MAX_PROVIDER_FAVORITES, appConfiguration) || 50;
        if (isObjectValidAndNotEmpty(favoriteObject)) {
            this.setState((prevState) => {
                const newFavs = cloneDeep(prevState.favorites);
                console.log('somechangesid', prevState.favorites);
                const favType = favoriteType.value;
                const { dispatch } = this.props;
                if (!isObjectValidAndNotEmpty(newFavs[favType])) {
                    newFavs[favType] = {
                        name: favType,
                        typeOfFavorite: favType,
                    };
                    if (!isArrayValidAndNotEmpty(newFavs[favType].accounts)) {
                        newFavs[favType].accounts = [];
                    }
                }
                const favDataSourceConfig = this.getFavDataSourceConfig();
                console.log('logthemfavorites', favDataSourceConfig, newFavs[favType].accounts, favoriteObject);
                if (
                    newFavs[favType].accounts.map((
                        aFav => getStringFromObject(favDataSourceConfig.key, aFav)),
                    ).includes(getStringFromObject(favDataSourceConfig.key, favoriteObject))
                ) {
                    dispatch(errorMessage('Favorite Already Exists In List'));
                    return null;
                }
                const numberOfAddedFavorites = newFavs[favType].accounts.length || 0;
                if (numberOfAddedFavorites >= maxFavoritesFromGP) {
                    dispatch(errorMessage(`Cannot have more than ${maxFavoritesFromGP} ${favoriteType.label} favorites`));
                    return null;
                }
                newFavs[favType].accounts = newFavs[favType].accounts.concat(favoriteObject);
                console.log('saywhatyouwant', newFavs[favType]);
                return {
                    favorites: newFavs,
                };
            });
        }
    }

    onRemoveFavorite = (favType, index) => {
        this.setState((prevState) => {
            const newFavs = cloneDeep(prevState.favorites);
            console.log('relyonguys', newFavs[favType]);
            if (
                isObjectValidAndNotEmpty(newFavs[favType]) &&
                isArrayValidAndNotEmpty(newFavs[favType].accounts)
            ) {
                newFavs[favType].accounts.splice(index, 1);
                return {
                    favorites: newFavs,
                };
            }
            return {};
        });
    }

    onSearchFavorites = favoriteType => (e) => {
        const searchText = (getTextFieldChangedValue(e) || '').toLowerCase();
        this.setState(prevState => ({
            searchValues: {
                ...prevState.searchValues,
                [favoriteType]: searchText,
            },
        }));
        console.log('atop5player', searchText, getTextFieldChangedValue(e), e);
        const {
            favorites,
        } = this.state;
        const favoritesOfType = getStringFromObject(`${favoriteType}.accounts`, favorites, []) || [];
        if (isArrayValidAndNotEmpty(favoritesOfType)) {
            const newFavAccountsList = favoritesOfType.map((aFavorite) => {
                let isHidden = false;
                if (!(aFavorite.value.toLowerCase().includes(searchText))) {
                    isHidden = true;
                }
                return {
                    ...aFavorite,
                    isHidden,
                };
            });
            const newFavs = cloneDeep(favorites);
            setStringPropertyToObject(`${favoriteType}.accounts`, newFavs, newFavAccountsList);
            this.setState(prevState => ({
                favorites: newFavs,
                searchValues: {
                    ...prevState.searchValues,
                    [favoriteType]: searchText,
                },
            }));
        }
    };

    getSearchContainerForFavType = (favoriteType) => {
        console.log('favContainer', favoriteType);
        return (
            <ReactSelectMaterial
                testId="account"
                dataSourceApi={favoriteType.dataSourceApi}
                autocomplete
                dataSourceConfig={{
                    text: 'value',
                    value: 'key',
                }}
                defaultOptions
                onChange={this.onFavoriteSelect(favoriteType)}
            />
        );
    }

    getFavDataSourceConfig = () => ({ text: 'value', key: 'key' })

    isFormDirty = () => {
        const favorites = cloneDeep(this.state.favorites);
        if (isObjectValidAndNotEmpty(favorites)) {
            Object.values(favorites).forEach((aFav) => {
                if (isArrayValidAndNotEmpty(aFav.accounts)) {
                    setStringPropertyToObject(
                        'accounts',
                        aFav,
                        aFav.accounts.map((aConcept) => {
                            const concept = { ...aConcept };
                            delete concept.isHidden;
                            return concept;
                        }),
                    );
                }
            });
        }
        console.log('thenewkd', this.state.loadedFavorites);
        console.log('stepdrop44', favorites);
        return !deepEqual(this.state.loadedFavorites, favorites);
    };

    findAllFavorites = () => {
        const {
            dispatch,
        } = this.props;
        dispatch(showSpinner());
        axios.get(`${api.CLINICAL_FAVORITES.FIND_BY_TYPE}?types=ACCOUNTS`)
            .then((response) => {
                const favorites = response.data;
                const favFormData = {};
                if (isArrayValidAndNotEmpty(favorites)) {
                    favorites.forEach((aFavorite) => {
                        favFormData[aFavorite.typeOfFavorite] = aFavorite;
                    });
                }
                this.setState({
                    favorites: favFormData,
                    loadedFavorites: favFormData,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
            })
            .finally(() => {
                dispatch(hideSpinner());
            });
    };

    renderList = (favoriteType) => {
        const {
            favorites,
        } = this.state;
        const {
            classes,
        } = this.props;
        const favoritesOfType = getStringFromObject(`${favoriteType.value}.accounts`, favorites, []) || [];
        const accountsFavorites = getStringFromObject(favoriteType.value, favorites, []) || [];
        console.log('accountsfavorites', accountsFavorites);
        if (isArrayValidAndNotEmpty(favoritesOfType)) {
            const favTypeDataSourceConfig = this.getFavDataSourceConfig(favoriteType);
            console.log('consoleloglik', favTypeDataSourceConfig, favoriteType);
            return (
                <MenuList
                    className={classes.containerHeight}
                    PaperProps={{
                        elevation: 10,
                    }}
                >
                    {
                        favoritesOfType.map((aFav, index) => {
                            if (aFav.isHidden) {
                                return null;
                            }
                            const displayString = getStringFromObject(favTypeDataSourceConfig.text, aFav);
                            return (
                                <MenuItem key={getStringFromObject(favTypeDataSourceConfig.key, aFav)}>
                                    <ListItemText
                                        test-id={getStringFromObject(favTypeDataSourceConfig.text, aFav)}
                                        classes={{ primary: classes.primary }}
                                        inset
                                        primary={displayString}
                                    />
                                    <ListItemIcon
                                        className={classes.icon}
                                        onClick={() => {
                                            this.onRemoveFavorite(
                                                favoriteType.value,
                                                index,
                                            );
                                        }}
                                    >
                                        <Clear test-id={
                                            `${getStringFromObject(favTypeDataSourceConfig.text, aFav)}-clear-things`
                                        }
                                        />
                                    </ListItemIcon>
                                </MenuItem>
                            );
                        })
                    }
                </MenuList>
            );
        }
        return (
            <h4>No Favorites Added</h4>
        );
    }

    render() {
        const {
            favorites,
        } = this.state;
        const {
            classes,
        } = this.props;
        const favType = ACCOUNTS;
        console.log('examples', favorites, classes, favType);
        const privilegeCheck = true;
        // const privilegeCheck = checkIfPrivilegeExistsForUser(favType.privileges);
        return (
            privilegeCheck &&
            <Grid container>
                <Grid item sm={8} md={8} lg={8} />
                <Grid item sm={4} md={4} lg={4} classname="p1">
                    {
                        this.getSearchContainerForFavType(favType)
                    }
                </Grid>
                <Grid item sm={8} md={8} lg={8} className="p1">
                    {
                        this.renderList(favType)
                    }
                </Grid>
            </Grid>
        );
    }
}

AccountsFavorites.propTypes = {
    classes: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    appConfiguration: PropTypes.object,
    updateDialogActions: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
};

AccountsFavorites.defaultProps = {
    classes: {},
    appConfiguration: {},
    handleClose: () => {},
};

const mapStateToProps = state => ({
    appConfiguration: getStringFromObject('appConfiguration', state),
});

export default withStyles(style)(connect(mapStateToProps)(AccountsFavorites));
