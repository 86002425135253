/*
 * Created by vinay on 29/4/19.
 */
const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

export const MASKS = {
    hourMinuteMask: [/[0-9]/, /[0-9]/, 'h ', /[0-9]/, /[0-9]/, 'm'],
};

export default styles;
