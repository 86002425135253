/**
 * Created by sachinprakash on 19/8/19.
 */

import initialStates from '../../initialStates';
import {
    FETCH_INSURANCE_SCHEME_VIEW_LIST_FAILURE,
    FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST,
    FETCH_INSURANCE_SCHEME_VIEW_LIST_SUCCESS,
} from './insuranceSchemeView-actions';

const insuranceSchemeView = (state = initialStates.insuranceSchemeView, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST:
        newState = { ...state, isLoading: true };
        break;
    case FETCH_INSURANCE_SCHEME_VIEW_LIST_SUCCESS:
        newState = {
            ...state,
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            totalElements: action.data.totalElements,
            filters: action.filters,
        };
        break;
    case FETCH_INSURANCE_SCHEME_VIEW_LIST_FAILURE:
        newState = { ...state, isLoading: false };
        break;
    default:
        newState = state;
    }

    return newState;
};

export default insuranceSchemeView;
