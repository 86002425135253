import API from '../../../constants/api';

const ACCOUNTS = {
    dataSourceApi: `${API.SEARCH.ACCOUNTS}?searchString=`,
    label: 'ACCOUNTS',
    privileges: ['hr.payonaccount'],
    value: 'ACCOUNTS',
};

export default ACCOUNTS;
