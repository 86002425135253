export const CHANGE_ROUTE_REQUEST = '@@documentType/CHANGE_ROUTE_REQUEST';

export const resetRouteRequest = () => ({
    type: CHANGE_ROUTE_REQUEST,
    route: '',
});

export const changeRouteRequest = route => ({
    type: CHANGE_ROUTE_REQUEST,
    route,
});
