import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { hrLeavePlanSettings, hrLeaveTypeSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const hrLeaveTypeSettingsPage = React.lazy(() => import('../pages/HrLeaveSettingsPages/HrLeaveTypeSettingsPage'));
const hrLeavePlanSettingsPage = React.lazy(() => import('../pages/HrLeaveSettingsPages/HrLeavePlanSettingsPage'));

const HrLeaveSettingsRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={hrLeavePlanSettings} exact component={hrLeavePlanSettingsPage} />
            <Route path={hrLeaveTypeSettings} exact component={hrLeaveTypeSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default HrLeaveSettingsRoutes;
