import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    insuranceRequests,
    payorApproval,
    payorBundles,
    payorBundlesDetails,
    payorClaims,
    payorClaimsDetails,
    payorInvoice,
} from '../constants/constants';
import PayorManagementContainer from '../containers/PayerManagementContainer/PayorManagementContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const ApprovalListPage = React.lazy(() => import('../pages/ApprovalListPage/ApprovalListPage'));
const InsuranceApprovalPage = React.lazy(() => import('../pages/InsuranceApprovalPage/InsuranceApprovalPage'));
const InvoiceListPage = React.lazy(() => import('../pages/InvoiceListPage/InvoiceListPage'));
const ClaimsListPage = React.lazy(() => import('../pages/Claims/ClaimsListPage'));
const ClaimsDetailsPage = React.lazy(() => import('../pages/Claims/ClaimsDetailsPage'));
const ClaimsBundlesPage = React.lazy(() => import('../pages/Claims/ClaimsBundlesPage'));
const ClaimsBundlesDetailsPage = React.lazy(() => import('../pages/Claims/ClaimsBundlesDetailsPage'));
const InsuranceRequestsListPage = React.lazy(() => import('../pages/InsuranceRequestsListPage/InsuranceRequestsListPage'));

const PayorManagementRoutes = props => (
    <PayorManagementContainer {...props} >
        <Suspense fallback={<PageLoader />}>
            <Route path={payorApproval} exact component={ApprovalListPage} />
            <Route path={`${payorApproval}/:type/:uuid`} exact component={InsuranceApprovalPage} />
            <Route exact path={payorInvoice} component={InvoiceListPage} />
            <Route exact path={payorClaims} component={ClaimsListPage} />
            <Route exact path={`${payorClaimsDetails}/:uuid`} component={ClaimsDetailsPage} />
            <Route exact path={payorBundles} component={ClaimsBundlesPage} />
            <Route exact path={`${payorBundlesDetails}/:uuid`} component={ClaimsBundlesDetailsPage} />
            <Route exact path={insuranceRequests} component={InsuranceRequestsListPage} />
        </Suspense>
    </PayorManagementContainer>
);

export default PayorManagementRoutes;
