import uuidv4 from 'uuid/v4';
import {
    groupArrayOfObjectsByFieldValue,
    isArrayValidAndNotEmpty,
    sortArrayOfObjectsByFieldValue,
} from '../../constants/CommonUtil';
import { formatDate } from '../../constants/DateUtil';
import { PAYMENT_METHODS_MAP } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const CASH_CLOSE_FORM = 'cashCloseForm';

export const mapUiObjectToPayload = (uiObject = {}) => {
    const payload = {
        uuid: uiObject.uuid,
        cashCloseType: uiObject.cashCloseType,
        employee: uiObject.user || null,
        date: formatDate(uiObject.date, 'yyyy-MM-dd'),
        refundAmount: uiObject.refundAmount,
        notes: uiObject.remarks,
        collections: [],
        amountInSystem: uiObject.collectionInSystem,
    };
    const collections = [];
    if (isArrayValidAndNotEmpty(uiObject.cashCollections)) {
        uiObject.cashCollections.forEach((cash) => {
            if (NumberOf(cash.quantity) > 0) {
                collections.push({
                    paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
                    denomination: cash.denomination,
                    quantity: cash.quantity,
                });
            }
        });
    }
    if (isArrayValidAndNotEmpty(uiObject.otherCollections)) {
        uiObject.otherCollections.forEach((cash) => {
            if (NumberOf(cash.amount > 0)) {
                collections.push({
                    paymentMethod: cash.paymentMethod,
                    amount: cash.amount,
                    name: cash.label,
                });
            }
        });
    }
    if (!isArrayValidAndNotEmpty(collections)) {
        return { error: 'Cash collections are required' };
    }
    payload.collections = collections;
    return payload;
};

export const mapPayloadToUuObject = (payload, denominations, bankTransactions) => {
    const uiObject = {
        uuid: payload.uuid,
        user: payload.employee || null,
        date: new Date(payload.date),
        refundAmount: payload.refundAmount,
        amountInSystem: NumberOf(payload.amountInSystem),
        remarks: payload.notes,
        cashCloseType: payload.cashCloseType,
        printed: payload.printed || false,
    };
    let cashCollections = [];
    let otherCollections = [];
    if (isArrayValidAndNotEmpty(payload.collections)) {
        payload.collections.forEach((coll) => {
            if (coll.paymentMethod === PAYMENT_METHODS_MAP.CASH.value) {
                cashCollections.push({
                    uiUuid: uuidv4(),
                    denomination: coll.denomination,
                    quantity: coll.quantity,
                    amount: coll.amount,
                });
            } else {
                otherCollections.push({
                    uiUuid: uuidv4(),
                    label: coll.name,
                    paymentMethod: coll.paymentMethod,
                    amount: coll.amount,
                });
            }
        });
    }
    const groupedByDenomination = groupArrayOfObjectsByFieldValue(cashCollections, 'denomination');
    cashCollections = [];
    denominations.forEach((d) => {
        const byD = groupedByDenomination[d];
        if (isArrayValidAndNotEmpty(byD)) {
            cashCollections = cashCollections.concat(byD);
        } else {
            cashCollections = cashCollections.concat({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        }
    });
    const groupedByMode = groupArrayOfObjectsByFieldValue(otherCollections, 'paymentMethod');
    otherCollections = [];
    bankTransactions.forEach((t) => {
        const byD = groupedByMode[t.paymentMethod];
        if (isArrayValidAndNotEmpty(byD)) {
            otherCollections = otherCollections.concat(byD);
        } else {
            otherCollections = otherCollections.concat({
                uiUuid: uuidv4(),
                label: t.label,
                paymentMethod: t.paymentMethod,
                amount: 0,
            });
        }
    });

    uiObject.cashCollections = cashCollections;
    uiObject.otherCollections = otherCollections;
    let systemCollections = [{
        uiUuid: uuidv4(),
        label: PAYMENT_METHODS_MAP.CASH.label,
        paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
        amount: NumberOf(getStringFromObject(`systemCollections.${PAYMENT_METHODS_MAP.CASH.value}`, payload)),
    }];
    systemCollections = systemCollections.concat(
        otherCollections.map(other => ({
            ...other,
            amount: NumberOf(getStringFromObject(`systemCollections.${other.paymentMethod}`, payload)),
        })),
    );
    uiObject.systemCollections = systemCollections;
    return uiObject;
};

export const getInitialValues = (
    denominations = [],
    bankTransactions = [],
    defaultCashCloseType = null,
) => {
    let systemCollections = [];
    const cashCollections = [];
    const otherCollections = [];
    if (isArrayValidAndNotEmpty(denominations)) {
        denominations.forEach((d) => {
            cashCollections.push({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        });
    }
    if (isArrayValidAndNotEmpty(bankTransactions)) {
        bankTransactions.forEach((d) => {
            otherCollections.push({
                uiUuid: uuidv4(),
                label: d.label,
                paymentMethod: d.paymentMethod,
                amount: '',
            });
        });
    }
    systemCollections.push({
        uiUuid: uuidv4(),
        label: PAYMENT_METHODS_MAP.CASH.label,
        paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
        amount: '',
    });
    systemCollections = systemCollections.concat(otherCollections);
    return ({
        cashCollections,
        otherCollections,
        systemCollections,
        cashCloseType: defaultCashCloseType,
        date: new Date(),
    });
};

export const getPrintData = (cashClose, bankTransactions) => {
    const printData = { ...cashClose };
    const systemCollections = getStringFromObject('systemCollections', cashClose);
    printData.systemCollections = [];
    if (isObjectValidAndNotEmpty(systemCollections)) {
        const keys = Object.keys(systemCollections);
        printData.systemCollections = keys.map(k => ({
            name: `${k.charAt(0).toUpperCase()}${k.slice(1)}`,
            amount: NumberOf(systemCollections[k]),
        }));
    } else if (isArrayValidAndNotEmpty(bankTransactions)) {
        printData.systemCollections = [{ name: 'Cash', amount: 0 }];
        printData.systemCollections = printData.systemCollections.concat(bankTransactions.map(a => ({
            name: a.label,
            amount: 0,
        })));
    }
    const collections = getStringFromObject('collections', cashClose);
    if (isArrayValidAndNotEmpty(collections)) {
        printData.collections = sortArrayOfObjectsByFieldValue(
            collections.filter(c => c.paymentMethod === PAYMENT_METHODS_MAP.CASH.value), 'denomination').reverse();
        printData.collections = printData.collections.concat(collections.filter(c => c.paymentMethod === 'CARD'));
        printData.collections = printData.collections.concat(collections.filter(c => c.paymentMethod === 'OTHER'));
    }
    return printData;
};
