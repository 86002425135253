/**
 * Created by sachinprakash on 4/6/19.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    ASSET_MANUFACTURER_ADD_REQUEST, ASSET_MANUFACTURER_UPDATE_REQUEST,
    ASSET_MANUFACTURERS_LIST_FETCH_REQUEST,
} from '../../redux/modules/assetManufacturer/assetManufacturer-actions';
import {
    assetManufacturerListFetch,
    assetManufacturerAddWorkerRequest,
    assetManufacturerUpdateWorker,
} from './assetManufacturerWorkerSaga';

export function* assetManufacturerListFetchWatcher() {
    yield takeLatest(ASSET_MANUFACTURERS_LIST_FETCH_REQUEST, assetManufacturerListFetch);
}

export function* assetManufacturerAddWatcher() {
    yield takeLatest(ASSET_MANUFACTURER_ADD_REQUEST, assetManufacturerAddWorkerRequest);
}

export function* assetManufacturerUpdateWatcher() {
    yield takeLatest(ASSET_MANUFACTURER_UPDATE_REQUEST, assetManufacturerUpdateWorker);
}
