import { takeLatest } from 'redux-saga/effects';
import { fetchRegistrationListWorker } from './registrationListWorkerSaga';
import {
    FETCH_VISITS_LIST_REQUEST,
} from '../../redux/modules/registrationList/registrationList-actions';


export function* fetchRegistrationListWatcher() {
    yield takeLatest(FETCH_VISITS_LIST_REQUEST, fetchRegistrationListWorker);
}

export function* fetchPatientsWatcher() {
    // yield takeLatest(ADD_ORDERS_REQUEST, addOrdersWorker);
}
