import API from '../../../constants/api';
import { simplifyFilters } from '../../../components/PurchaseOrderComponents/Utils/ProductTabUtil';

export const FETCH_PRODUCTS_LIST_REQUEST = '@@product/FETCH_PRODUCTS_LIST_REQUEST';
export const FETCH_PRODUCTS_LIST_FAILURE = '@@product/FETCH_PRODUCTS_LIST_FAILURE';
export const FETCH_PRODUCTS_LIST_SUCCESS = '@@product/FETCH_PRODUCTS_LIST_SUCCESS';

export const CREATE_PRODUCTS_REQUEST = '@@product/CREATE_PRODUCTS_REQUEST';
export const CREATE_PRODUCTS_FAILURE = '@@product/CREATE_PRODUCTS_FAILURE';
export const CREATE_PRODUCTS_SUCCESS = '@@product/CREATE_PRODUCTS_SUCCESS';

export const CLEAR_PRODUCTS_STATE = '@@product/CLEAR_PRODUCTS_STATE';

export const fetchPagesOfProducts = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_PRODUCTS_LIST_REQUEST,
    api: `${API.PRODUCT.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
        encodeURIComponent(JSON.stringify(simplifyFilters(filters)))}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const createProductRequest = (product, successCallback, failureCallBack) => ({
    type: CREATE_PRODUCTS_REQUEST,
    api: API.PRODUCT.CREATE,
    product,
    successCallback,
    failureCallBack,
});

export const clearProductsState = () => ({
    type: CLEAR_PRODUCTS_STATE,
});
