/**
 * Created by vinay on 14/12/18.
 */
import axios from 'axios';
import cloneDeep from 'clone-deep';
import uuid from 'uuid/v4';
import {
    isArrayValidAndNotEmpty,
    roundedValue,
    sortArrayOfObjectsByFieldValue,
    valueToFixedTwoDigits,
} from '../constants/CommonUtil';
import API from '../constants/api';
import { formatDate, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { calculateBonusPercentage } from '../components/PurchaseOrderComponents/PurchaseOrderActionHandlers';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const PURCHASE_ORDER_OPERATION = {
    SAVE: 'save',
    CONFIRM: 'confirm',
};

export const PURCHASE_ORDER_TYPE = {
    STOCK_UPLOAD: 'stock_upload',
    INTERNAL_MOVE: 'internal_move',
    NEW_PURCHASE_ORDER: 'new_purchase_order',
};

export const PURCHASE_ORDER_LINE_UI_OBJECT = {
    orderLineUuid: '',
    product: '',
    uom: null,
    quantity: '',
    rate: '',
    amount: '',
    operation: 0,
    description: '',
    taxes: [],
    total: '',
    trackByBatch: false,
    assetCategory: null,
    visible: true,
};

export const PURCHASE_ORDER_UI_OBJECT = {
    editable: true,
    uuid: '',
    dateOrder: getDateInYYYYMMDDFormat(new Date()),
    supplier: null,
    mailingAddress: '',
    siNumber: '',
    partnerAddress: null,
    email: '',
    company: null,
    shippingAddress: '',
    warehouse: null,
    warehouseCompanyName: '',
    purchaseOrderLines: [cloneDeep(PURCHASE_ORDER_LINE_UI_OBJECT)],
    name: '',
    notes: '',
    state: '',
    productReceived: '',
    invoiced: false,
    discountHead: null,
    discountPercent: null,
    discount: null,
};

export const getAddressFromAddressDto = (address = {}) => {
    const zipCode = getStringFromObject('zipCode', address, '');
    return ([
        getStringFromObject('street', address, ''),
        getStringFromObject('village', address, ''),
        getStringFromObject('city', address, ''),
        getStringFromObject('state.value', address, ''),
        zipCode ? ['pin', zipCode].join('-') : undefined,
        getStringFromObject('country.value', address, ''),
    ].filter(Boolean).join(', '));
};

export const getPurchaseOrderLinesUiObject = (lines) => {
    const purchaseLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            const pline = {
                uiUuid: uuid(),
            };
            console.log('poline line', line);
            pline.orderLineUuid = line.orderLineUuid;
            pline.product = {
                uuid: getStringFromObject('product.key', line),
                salePrice: getStringFromObject('salePrice', line),
                mrp: getStringFromObject('mrp', line),
                cost: getStringFromObject('cost', line),
                productName: getStringFromObject('product.value', line),
                productCode: getStringFromObject('productCode', line, getStringFromObject('product.productCode', line)),
                productGtinMapDtoList: getStringFromObject('productGtinMapDtoList', line) || null,
            };
            pline.operation = 0;
            pline.uom = { uuid: line.productUOM.key, name: line.productUOM.value };
            const assetCategory = getStringFromObject('assetCategory', line, null);
            pline.assetCategory = isObjectValidAndNotEmpty(assetCategory) ? assetCategory : null;
            pline.description = line.description;
            pline.bonus = line.bonus;
            pline.quantity = line.quantity;
            const priceUnit = NumberOf(line.priceUnit);
            pline.rate = valueToFixedTwoDigits(priceUnit);
            const cost = NumberOf(line.cost);
            pline.cost = valueToFixedTwoDigits(cost);
            let amountWithoutTax = NumberOf(pline.quantity) * priceUnit;
            pline.amount = valueToFixedTwoDigits(amountWithoutTax);
            const discount = NumberOf(line.discount);
            const discount2 = NumberOf(line.discount2);
            pline.discount = discount;
            pline.discount2 = discount2;
            const discountAmount = valueToFixedTwoDigits(roundedValue(amountWithoutTax * (discount / 100)));
            const discountAmount2 = valueToFixedTwoDigits(roundedValue((amountWithoutTax - discountAmount) * (discount2 / 100)));
            pline.discountAmount = discountAmount;
            pline.discountAmount2 = discountAmount2;
            pline.amountBeforeTax = valueToFixedTwoDigits(NumberOf(pline.amount) - (NumberOf(discountAmount) + NumberOf(discountAmount2)));
            amountWithoutTax -= (NumberOf(discountAmount) + NumberOf(discountAmount2));
            pline.taxes = [];
            pline.sequence = line.sequence || index;
            pline.trackByBatch = line.trackByBatch;
            let taxAmount = 0;
            if (isArrayValidAndNotEmpty(line.accountTaxes)) {
                pline.taxes = line.accountTaxes;
                pline.taxes.forEach((tax) => {
                    taxAmount += NumberOf(amountWithoutTax) * NumberOf(tax.amount);
                });
            }
            // pline.amount = roundedValueFixedToTwoDigits(amountWithoutTax);
            taxAmount = roundedValue(taxAmount);
            pline.taxAmount = valueToFixedTwoDigits(taxAmount);
            pline.total = valueToFixedTwoDigits(amountWithoutTax + taxAmount);
            if (isObjectValidAndNotEmpty(line.requisitionDetailsDto)) {
                pline.requisitionDetailsDto = {
                    ...line.requisitionDetailsDto,
                    // eslint-disable-next-line max-len
                    minMax: `${getStringFromObject('minQty', line.requisitionDetailsDto)}-${getStringFromObject('maxQty', line.requisitionDetailsDto)}`,
                };
            }
            pline.visible = true;
            const bonus = getStringFromObject('bonus', pline) || 0;
            const quantity = getStringFromObject('quantity', pline) || 0;
            pline.bonusPercentage = calculateBonusPercentage(bonus, quantity);
            purchaseLines.push(pline);
        });
    }
    return sortArrayOfObjectsByFieldValue(purchaseLines, 'sequence');
};

export const mapPurchaseOrderFromUiObject = (uiObject) => {
    let purchaseOrder = null;
    if (isObjectValidAndNotEmpty(uiObject)) {
        console.log('sda-0so-d0aosad', uiObject.dateOrder);
        purchaseOrder = {};
        purchaseOrder.uuid = uiObject.uuid || '';
        purchaseOrder.dateOrder = formatDate(new Date(uiObject.dateOrder), 'yyyy-MM-dd hh:mm:ss');
        purchaseOrder.name = '';
        purchaseOrder.siNumber = uiObject.siNumber;
        purchaseOrder.amountUntaxed = '';
        purchaseOrder.amountTax = '';
        purchaseOrder.partnerRef = '';
        purchaseOrder.dateApprove = '';
        purchaseOrder.amountTotal = '';
        purchaseOrder.notes = uiObject.notes;
        purchaseOrder.shipped = '';
        purchaseOrder.state = '';
        purchaseOrder.pricelistId = '';
        purchaseOrder.companyId = null;
        purchaseOrder.supplierEmail = uiObject.email;
        purchaseOrder.updateSupplierEmail = uiObject.updateSupplierEmail;
        purchaseOrder.partnerId = { key: uiObject.supplier.uuid, value: uiObject.supplier.name };
        purchaseOrder.destAddressId = '';
        purchaseOrder.locationId = '';
        purchaseOrder.warehouseId = { key: uiObject.warehouse.uuid, value: uiObject.warehouse.name };
        purchaseOrder.invoiceMethod = '';
        purchaseOrder.origin = '';
        purchaseOrder.purchaseOrderLineDtos = [];
        purchaseOrder.subVendor = uiObject.subVendor || false;
        console.log('mapPurchaseOrderFromUiObject', uiObject);
        if (uiObject.intentUuid) {
            purchaseOrder.intentUuid = getStringFromObject('intentUuid', uiObject);
        }
        let totalAmount = 0;
        const lines = getStringFromObject('purchaseOrderLines', uiObject, []);
        if (isArrayValidAndNotEmpty(lines)) {
            const datePlanned = purchaseOrder.dateOrder;
            let sequence = 0;
            purchaseOrder.purchaseOrderLineDtos = lines.map((line) => {
                const pline = {};
                sequence += 1;
                pline.orderLineUuid = line.orderLineUuid;
                pline.description = line.description;
                pline.product = { key: line.product.uuid, value: line.product.productName };
                if (isObjectValidAndNotEmpty(line.uom)) {
                    pline.productUOM = { key: line.uom.uuid, value: line.uom.name };
                } else {
                    pline.productUOM = { key: line.product.erpUomDto.uuid, value: line.product.erpUomDto.name };
                }
                pline.quantity = line.quantity;
                pline.discount = line.discount;
                pline.discount2 = line.discount2;
                pline.bonus = line.bonus;
                pline.sequence = sequence;
                pline.cost = line.cost;
                pline.priceUnit = line.rate;
                pline.operation = line.operation;
                pline.datePlanned = datePlanned;
                pline.accountTaxes = line.taxes;
                totalAmount += NumberOf(line.total);
                const assetCategory = getStringFromObject('assetCategory', line, null);
                pline.assetCategory = isObjectValidAndNotEmpty(assetCategory) ? assetCategory : null;
                return pline;
            });
        }
        let discountAmount = NumberOf(uiObject.discount);
        const discountPercent = NumberOf(uiObject.discountPercent);
        if (discountPercent > 0) {
            discountAmount = totalAmount * (discountPercent / 100);
        }
        purchaseOrder.discount = valueToFixedTwoDigits(discountAmount);
        purchaseOrder.discountHead = uiObject.discountHead;
        purchaseOrder.type = getStringFromObject('type', uiObject);
        purchaseOrder.operation = getStringFromObject('operation', uiObject);
    }
    return purchaseOrder;
};

export const getUiObject = (purchaseOrder) => {
    console.log('PurchaseOrdergetUI', purchaseOrder);
    const uiObject = cloneDeep(PURCHASE_ORDER_UI_OBJECT);
    if (isObjectValidAndNotEmpty(purchaseOrder)) {
        console.log('asda-0sdias-dia0dsa', purchaseOrder);
        uiObject.uuid = purchaseOrder.uuid || '';
        uiObject.dateOrder = formatDate(purchaseOrder.dateOrder ? new Date(purchaseOrder.dateOrder) : new Date(),
            'yyyy-MM-dd');
        uiObject.supplier = { uuid: purchaseOrder.partnerId.key, name: purchaseOrder.partnerId.value };
        uiObject.mailingAddress = getAddressFromAddressDto(getStringFromObject('partnerAddress', purchaseOrder, {}));
        uiObject.partnerAddress = getStringFromObject('partnerAddress', purchaseOrder, {});
        // uiObject.email = getStringFromObject('partnerAddress.email', purchaseOrder);
        uiObject.email = getStringFromObject('supplierEmail', purchaseOrder);
        uiObject.siNumber = getStringFromObject('number', purchaseOrder);
        uiObject.updateSupplierEmail = getStringFromObject('updateSupplierEmail', purchaseOrder);
        uiObject.company = { uuid: purchaseOrder.companyId.key, name: purchaseOrder.companyId.value };
        uiObject.shippingAddress = getAddressFromAddressDto(getStringFromObject('warehouseAddress', purchaseOrder, {}));
        uiObject.warehouse = { uuid: purchaseOrder.warehouseId.key, name: purchaseOrder.warehouseId.value };
        uiObject.warehouseCompanyName = getStringFromObject('warehouseCompanyName', purchaseOrder);
        uiObject.warehouseAddress = getStringFromObject('warehouseAddress', purchaseOrder, {});
        uiObject.purchaseOrderLines = getPurchaseOrderLinesUiObject(purchaseOrder.purchaseOrderLineDtos);
        uiObject.name = purchaseOrder.name;
        uiObject.notes = purchaseOrder.notes;
        uiObject.state = purchaseOrder.state;
        uiObject.productReceived = purchaseOrder.productReceived;
        uiObject.invoiced = getStringFromObject('invoiced', purchaseOrder, false);
        uiObject.discountHead = getStringFromObject('discountHead', purchaseOrder, null);
        uiObject.discountPercent = getStringFromObject('discountPercent', purchaseOrder, null);
        uiObject.discount = getStringFromObject('discount', purchaseOrder, null);
        uiObject.editable = uiObject.state === 'draft';
        uiObject.invoiced = getStringFromObject('invoiced', purchaseOrder, false);
        uiObject.validator = getStringFromObject('validator', purchaseOrder) || '';
        uiObject.subVendor = getStringFromObject('subVendor', purchaseOrder) || false;
    }
    console.log('aklsdjfhasjkdfhlskj', uiObject);
    return uiObject;
};
export const getProductDetails = async (name, productUuid) => {
    const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_UUID}${productUuid}`);
    return response.data;
};
export const getPurchaseOrderLinesUiObjectFromIntentLine = async (lines) => {
    const purchaseLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        await Promise.all(
            lines.map(async (line, index) => {
                console.log('purchaseLines fetxhData');
                const productDetails = await getProductDetails(getStringFromObject('product.value', line), getStringFromObject('product.key', line));

                console.log(' purchaseLines getProductDetails', !productDetails);
                console.log('purchaseLines fetxhData', line.productUom.key);

                const pline = {
                    uiUuid: uuid(),
                };
                pline.quantity = line.quantityRequired;

                if (productDetails) {
                    pline.product = {
                        uuid: getStringFromObject('product.key', line),
                        salePrice: getStringFromObject('price', productDetails),
                        mrp: getStringFromObject('price', productDetails),
                        cost: getStringFromObject('cost', productDetails),
                        productName: getStringFromObject('product.value', line),
                        productCode: getStringFromObject('productCode', productDetails, getStringFromObject('product.productCode', productDetails)),
                        // productEan13Code:
                        //     getStringFromObject('productEan13Code', line),
                    };
                    pline.uom = { uuid: line.productUom.key, name: line.productUom.value };
                    pline.operation = 1;
                    const assetCategory = getStringFromObject('assetCategory', productDetails, null);
                    pline.assetCategory = isObjectValidAndNotEmpty(assetCategory) ? assetCategory : null;
                    pline.description = productDetails.description;
                    pline.bonus = productDetails.bonus;
                    const priceUnit = NumberOf(productDetails.price);
                    pline.rate = valueToFixedTwoDigits(priceUnit);
                    let amountWithoutTax = NumberOf(pline.quantity) * priceUnit;
                    pline.amount = valueToFixedTwoDigits(amountWithoutTax);
                    const discount = NumberOf(line.discount);
                    pline.discount = discount;
                    pline.discountAmount = valueToFixedTwoDigits(roundedValue(amountWithoutTax * (discount / 100)));
                    pline.amountBeforeTax = valueToFixedTwoDigits(NumberOf(pline.amount) - NumberOf(pline.discountAmount));
                    amountWithoutTax -= NumberOf(pline.discountAmount);
                    pline.taxes = [];
                    pline.sequence = line.sequence || index;
                    pline.trackByBatch = line.trackByBatch;
                    let taxAmount = 0;
                    if (isArrayValidAndNotEmpty(line.accountTaxes)) {
                        pline.taxes = line.accountTaxes;
                        pline.taxes.forEach((tax) => {
                            taxAmount += NumberOf(amountWithoutTax) * NumberOf(tax.amount);
                        });
                    }
                    // pline.amount = roundedValueFixedToTwoDigits(amountWithoutTax);
                    taxAmount = roundedValue(taxAmount);
                    pline.taxAmount = valueToFixedTwoDigits(taxAmount);
                    pline.total = valueToFixedTwoDigits(amountWithoutTax + taxAmount);
                    if (isObjectValidAndNotEmpty(line.requisitionDetailsDto)) {
                        pline.requisitionDetailsDto = {
                            ...line.requisitionDetailsDto,
                            // eslint-disable-next-line max-len
                            minMax: `${getStringFromObject('minQty', line.requisitionDetailsDto)}-${getStringFromObject('maxQty', line.requisitionDetailsDto)}`,
                        };
                    }
                } else {
                    pline.product = {
                        uuid: getStringFromObject('product.key', line),
                        productName: getStringFromObject('product.value', line),
                    };
                }

                pline.visible = true;
                purchaseLines.push(pline);
            }));
        console.log('purchaseLines datafetched');
    }
    console.log('purchaseLines getPurchaseOrderLinesUiObjectFromIntentLine', purchaseLines);
    return purchaseLines;
};
export const mapIntentToPurchaseOrder = async (intent) => {
    // const purchaseOrder = {};
    // purchaseOrder.
    console.log('PurchaseOrdergetUiObjectfromIntent', intent);
    const uiObject = cloneDeep(PURCHASE_ORDER_UI_OBJECT);
    if (isObjectValidAndNotEmpty(getStringFromObject('stockWarehouse', intent))) {
        uiObject.warehouse = { uuid: getStringFromObject('stockWarehouse.key', intent), name: getStringFromObject('stockWarehouse.value', intent) };
    }
    uiObject.purchaseOrderLines = await getPurchaseOrderLinesUiObjectFromIntentLine(intent.intentLineDtoList);
    uiObject.notes = getStringFromObject('memo', intent);
    return uiObject;
};
