const billingTabs = {
    NEW: {
        label: 'Billing',
        value: 'NEW',
    },
    ORDERS: {
        label: 'Orders',
        value: 'ORDERS',
    },
    REFERRALS: {
        label: 'Referrals',
        value: 'REFERRALS',
    },
    HISTORY: {
        label: 'History',
        value: 'HISTORY',
    },
    PENDING_APPROVALS: {
        label: 'PENDING APPROVALS',
        value: 'PENDING_APPROVALS',
    },
    PENDING_ORDERS: {
        label: 'PENDING ORDERS',
        value: 'PENDING_ORDERS',
    },
};

export default billingTabs;
