import { takeLatest } from 'redux-saga/effects';
import { createAccountJournalEntry, createPostJournalEntry, fetchJournalEntriesWorker, fetchJournalEntriesOptimizedWorker } from './journalEntryWorkerSaga';
import {
    CREATE_JOURNAL_ENTRY_REQUEST,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST,
    FETCH_JOURNAL_ENTRIES_LIST_REQUEST,
    POST_JOURNAL_ENTRY_REQUEST,
} from '../../redux/modules/journalEntries/journalEntries-actions';

export function* fetchJournalEntriesWatcher() {
    yield takeLatest(FETCH_JOURNAL_ENTRIES_LIST_REQUEST, fetchJournalEntriesWorker);
}

export function* fetchJournalEntriesOptimizedWatcher() {
    yield takeLatest(FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST, fetchJournalEntriesOptimizedWorker);
}

export function* createJournalEntryWatcher() {
    yield takeLatest(CREATE_JOURNAL_ENTRY_REQUEST, createAccountJournalEntry);
}
export function* postJournalEntryWatcher() {
    yield takeLatest(POST_JOURNAL_ENTRY_REQUEST, createPostJournalEntry);
}
