import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from 'redux-form-material-ui';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,

} from '../../../constants/CommonUtil';
import { getIcon } from '../../ProfileComponent/TextFieldWithIcon';
import { setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    disableText: {
        color: '#000000',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
    adornedStart: {
        paddingLeft: '14px',
        width: 'calc(100%-14px)',
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

class ReduxFormTextField extends React.PureComponent {
    onChangeHandler = (value) => {
        const {
            meta: { dispatch, form }, onChangeHandlers, fieldMember, fields, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    handleChange = (e) => {
        const { input, upperCase } = this.props;
        let value = e.target.value || '';
        if (upperCase && typeof value === 'string') {
            value = value.toUpperCase();
        }
        input.onChange(value);
        this.onChangeHandler(value);
    };

    handleOnBlur = (e) => {
        e.preventDefault();
        const {
            meta: { dispatch, form },
            onBlurHandlers,
            fieldMember,
            fields,
            actionHandlers,
            input: { onBlur },
        } = this.props;
        onBlur(e.target.value);
        if (isArrayValidAndNotEmpty(onBlurHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onBlurHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(e.target.value, form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    render() {
        const {
            testId,
            input,
            options,
            variant,
            classes,
            // ---- ignored props (do not pass unnecessary props down) -----
            onChangeHandlers,
            isMandatory,
            fieldMember,
            fields,
            headerStyle,
            formValues,
            actionHandlers,
            onBlurHandlers,
            icon,
            // ----------------
            ...otherProps
        } = this.props;
        const {
            // ---- ignored props (do not pass unnecessary props down) -----
            noLabel,
            ...remainingProps
            // ----------------
        } = otherProps;
        console.log('InputRef', remainingProps);
        const clonedProps = cloneDeep(remainingProps);
        if (variant === 'outlined') {
            setStringPropertyToObject('InputProps.classes.input', clonedProps, classes.input);
        }
        let startAdornment = null;
        if (icon) {
            startAdornment = React.isValidElement(getIcon({ icon })) ?
                getIcon({ icon }) :
                React.createElement(
                    getIcon({ icon }),
                    {
                        className: 'basic-info-icon--gray',
                        style: {
                            paddingRight: '0.75rem',
                        },
                    },
                );
            setStringPropertyToObject('InputProps.startAdornment', clonedProps, startAdornment);
            setStringPropertyToObject('InputProps.style', clonedProps, { width: 'calc(100% - 14px)' });
        }
        if (this.props.disabled) {
            setStringPropertyToObject('InputProps.classes.disabled', clonedProps, classes.disableText);
        }
        return (
            <TextField
                test-id={testId}
                fullWidth
                variant={variant || 'standard'}
                {...clonedProps}
                input={{
                    ...input,
                    onBlur: this.handleOnBlur,
                    onChange: this.handleChange,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                {options}
            </TextField>
        );
    }
}

ReduxFormTextField.propTypes = {
    testId: PropTypes.string,
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    onChangeHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
    fieldMember: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
    headerStyle: PropTypes.object,
    formValues: PropTypes.object,
    isMandatory: PropTypes.bool,
    options: PropTypes.array,
    upperCase: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

ReduxFormTextField.defaultProps = {
    testId: '',
    onChangeHandlers: [],
    onBlurHandlers: [],
    options: [],
    fieldMember: '',
    disabled: false,
    icon: '',
    fields: {},
    actionHandlers: {},
    headerStyle: {},
    formValues: {},
    isMandatory: false,
    upperCase: false,
    variant: 'outlined',
};

export default withStyles(styles)(ReduxFormTextField);
