/* eslint-disable react/prop-types */
import React from 'react';
import { isValidFunction } from '../constants/CommonUtil';
import { getStringFromObject } from '../constants/lodashUtils';

const changedArray = (a = [], b = []) =>
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

const initialState = {
    error: null,
    info: null,
};
class ReactErrorBoundary extends React.Component {
    state = initialState;
    componentDidUpdate(prevProps) {
        const { error } = this.state;
        const { resetKeys } = this.props;
        if (error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
            if (isValidFunction(this.props.onResetKeysChange)) {
                this.props.onResetKeysChange(prevProps.resetKeys, resetKeys);
            }
            // eslint-disable-next-line
            this.setState(initialState);
        }
    }

    componentDidCatch(error, info) {
        if (isValidFunction(this.props.onError)) {
            this.props.onError(error, getStringFromObject('componentStack', info));
        }
        this.setState({ error, info });
    }

    resetErrorBoundary = (...args) => {
        if (isValidFunction(this.props.onReset)) {
            this.props.onReset(...args);
        }
        this.setState(initialState);
    };

    render() {
        const { error, info } = this.state;
        const { fallbackRender, FallbackComponent, fallback } = this.props;

        if (error !== null) {
            const props = {
                componentStack: getStringFromObject('componentStack', info),
                error,
                resetErrorBoundary: this.resetErrorBoundary,
            };
            if (React.isValidElement(fallback)) {
                return fallback;
            } else if (typeof fallbackRender === 'function') {
                return fallbackRender(props);
            } else if (typeof FallbackComponent === 'function') {
                return <FallbackComponent {...props} />;
            }
            throw new Error(
                'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
            );
        }
        return this.props.children;
    }
}

export default ReactErrorBoundary;
