/**
 * Created by vinay on 20/12/18.
 */
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const generatePayorsSearchConstruct = (filters) => {
    let construct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        if (filters.parentPayor) {
            construct = getCombined(construct, 'AND',
                generateObjectWithCriteriaMode('insurance_payer.parentPayer', filters.parentPayor, '='));
        }
        if (filters.parentPayor === null) {
            construct = getCombined(construct, 'AND',
                generateObjectWithCriteriaMode('insurance_payer.parentPayer', null, '<>'));
        }

        // if key value pair
        const type = getStringFromObject('type.key', filters);
        if (type) {
            construct = getCombined(construct, 'AND',
                generateObjectWithCriteriaMode('insurance_payer.type', type, '='));
        } else if (filters.type) {
            construct = getCombined(construct, 'AND',
                generateObjectWithCriteriaMode('insurance_payer.type', filters.type, '='));
        }

        let searchText = getStringFromObject('searchText', filters, '');
        if (searchText) {
            let operation = ':';
            searchText = searchText.trim();
            if (searchText.endsWith('@')) {
                searchText = searchText.substr(0, searchText.length - 1);
                operation = '=';
            }
            construct = getCombined(
                construct,
                'AND',
                getCombined(
                    generateObjectWithCriteriaMode('insurance_payer.name', searchText, operation),
                    'OR',
                    generateObjectWithCriteriaMode('insurance_payer.code', searchText, operation),
                ),
            );
        }
    }
    return construct;
};

export default generatePayorsSearchConstruct;
