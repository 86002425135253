import API from '../../../constants/api';

export const CREATE_PARTNER_REQUEST = '@@resPartner/CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_FAILURE = '@@resPartner/CREATE_PARTNER_FAILURE';
export const CREATE_PARTNER_SUCCESS = '@@resPartner/CREATE_PARTNER_SUCCESS';

export const createResPartnerRequest = (partner, successCallback) => ({
    type: CREATE_PARTNER_REQUEST,
    api: API.RES_PARTNER.CREATE,
    partner,
    successCallback,
});
