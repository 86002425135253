import { PAYMENT_METHODS_MAP } from '../../../constants/constants';
import { getDateInYYYYMMDDFormat, parseDate } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const getInitialValues = () => ({
    type: 'account_supplier_advance',
    partner: null,
    account: null,
    date: new Date(),
    paymentMode: PAYMENT_METHODS_MAP.CASH,
    readOnly: false,
});

export const getUiObject = (payload) => {
    if (!isObjectValidAndNotEmpty(payload)) {
        return getInitialValues();
    }
    let partner = getStringFromObject('partner', payload);
    if (isObjectValidAndNotEmpty(partner)) {
        partner = { uuid: partner.key, name: partner.value, subCompany: getStringFromObject('subCompany', payload) };
    }
    const paymentMode = getStringFromObject('paymentMode', payload);
    let paymentDetails = getStringFromObject('paymentDetails', payload, null);
    if (isObjectValidAndNotEmpty(paymentDetails)) {
        paymentDetails = {
            ...paymentDetails,
            date: paymentDetails.date ? new Date(paymentDetails.date) : null,
        };
    }
    return {
        readOnly: true,
        ...payload,
        partner,
        paymentMode: getStringFromObject(paymentMode, PAYMENT_METHODS_MAP),
        date: parseDate(getStringFromObject('date', payload), 'yyyyy-MM-dd'),
        paymentDetails,
    };
};

export const getPayload = (uiObject) => {
    let partner = getStringFromObject('partner', uiObject);
    if (isObjectValidAndNotEmpty(partner)) {
        partner = { key: partner.uuid, value: partner.name };
    }
    let paymentDetails = getStringFromObject('paymentDetails', uiObject, null);
    if (isObjectValidAndNotEmpty(paymentDetails)) {
        paymentDetails = {
            ...paymentDetails,
            date: paymentDetails.date ? new Date(paymentDetails.date).getTime() : null,
        };
    }
    return {
        ...uiObject,
        partner,
        paymentDetails,
        paymentMode: getStringFromObject('paymentMode.value', uiObject),
        date: getDateInYYYYMMDDFormat(getStringFromObject('date', uiObject)),
    };
};

export const getRefundUiObject = uiObject => ({
    uuid: uiObject.uuid,
    partner: uiObject.partner,
    date: uiObject.date,
    amountAdvance: 0,
    amountResidue: uiObject.amountResidue,
    paymentMode: PAYMENT_METHODS_MAP.CASH,
    paymentDetails: null,
    notes: '',
    type: 'account_supplier_refund',
});
