import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import AttachFile from '@material-ui/icons/AttachFile';
import Chip from '@material-ui/core/Chip/Chip';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl/FormControl';
import UploadFile from '../UploadFiles/UploadFile';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';

class ReduxFormMultiFileUpload extends React.PureComponent {
    constructor(props) {
        super(props);
        this.uploaderRef = null;
    }

    onUploadClick = () => {
        console.log('asd9ua0s9dua', this.uploaderRef);
        if (this.uploaderRef && this.uploaderRef.clickUpload && (!this.props.disabled)) {
            this.uploaderRef.clickUpload();
        }
    };

    getUploaderRef = (uploaderRef) => {
        this.uploaderRef = uploaderRef;
    };

    getValueLabel = (file, index) => {
        console.log('asdfadfdsf', index);
        let value = file;
        const {
            disabled, labelLength,
        } = this.props;
        if (value && typeof (value) === 'object') {
            value = value && value.value;
        }
        value = (value && value.length) > labelLength ? value.substr(0, labelLength) : value;
        if (disabled) {
            return (
                <Chip
                    label={value}
                    style={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        margin: '0 8px 8px 0',
                    }}
                    onClick={() => this.handleChipClick(file)}
                />
            );
        }
        return (
            <Chip
                label={value}
                style={{
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    margin: '0 8px 8px 0',
                }}
                onDelete={() => this.handleOnDelete(index)}
            />
        );
    };

    handleChipClick = (token) => {
        const { downloadApi } = this.props;
        window.open(`${downloadApi}${token}`, '_blank');
    };

    handleOnDelete = (index) => {
        const { input } = this.props;
        const value = cloneDeep(input.value);
        value.splice(index, 1);
        input.onChange(value);
    };

    handleOnChangeFile = (keyValueOfFile) => {
        const { input } = this.props;
        let value = cloneDeep(input.value);
        const fileToken = keyValueOfFile ? keyValueOfFile.key : '';
        if (isArrayValidAndNotEmpty(value)) {
            value.push(fileToken);
        } else {
            value = [fileToken];
        }
        input.onChange(value);
    };

    render() {
        console.log('sdfsadfsasd12444df', this.props);
        const {
            uploadApi,
            input,
            meta: { touched, error },
            disabled,
            supportedTypes,
            addButtonLabel,
        } = this.props;
        const { value } = input;
        const fieldName = input.name;
        return (
            <div>
                {
                    isArrayValidAndNotEmpty(value) &&
                        value.map((v, index) => this.getValueLabel(v, index))
                }
                {
                    (!disabled) &&
                        <FormControl>
                            <Chip
                                label={addButtonLabel || 'Add New'}
                                style={{
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}
                                onDelete={this.onUploadClick}
                                onClick={this.onUploadClick}
                                deleteIcon={<AttachFile />}
                                test-id="upload-file"
                            />
                            {(touched && error) && <FormHelperText htmlFor={fieldName} error>{error}</FormHelperText>}
                        </FormControl>
                }
                <div style={{ display: 'none' }}>
                    <UploadFile
                        disabled
                        hideUploadedFileList
                        uploadApi={uploadApi}
                        onUploadSuccess={this.onUploadSuccess}
                        onStartUpload={this.onStartUpload}
                        input={{ onChange: this.handleOnChangeFile }}
                        dispatch={this.props.dispatch}
                        ref={this.getUploaderRef}
                        supportedTypes={supportedTypes}
                    />
                </div>
            </div>
        );
    }
}

ReduxFormMultiFileUpload.propTypes = {
    disabled: PropTypes.bool,
    meta: PropTypes.object,
    uploadApi: PropTypes.string.isRequired,
    downloadApi: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    labelLength: PropTypes.number,
    dispatch: PropTypes.func,
    supportedTypes: PropTypes.array,
    addButtonLabel: PropTypes.string,
};

ReduxFormMultiFileUpload.defaultProps = {
    disabled: true,
    meta: {},
    dispatch: () => {},
    labelLength: 30,
    supportedTypes: [],
    addButtonLabel: 'Add New',
};

export default connect()(ReduxFormMultiFileUpload);
