import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import { APP_VERSION } from './constants/state';
import configureStore from './redux/configureStores';
import ReactErrorBoundary from './components/ReactErrorBoundary';
import ErrorFallBackComponent from './components/ErrorFallBackComponent';
import Routes from './routes/routes';
import API from './constants/api';

window.addEventListener('beforeunload', () => {
    document.cookie = `${APP_VERSION}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
});

const store = configureStore();


const logTheError = (error, componentStack) => {
    const theError = error || {};
    console.log('asodhas09duasdsda', theError);
    axios.post(API.ERROR_LOG,
        `
        *****ERROR-IN-HR-APPLICATION****
        ${error.message}
        ${componentStack}
        `
        , {
            headers: { 'Content-Type': 'text/plain' },
        })
        .then(() => {
            console.info('Error logged successfully');
        });
};


ReactDOM.render(
    <HashRouter>
        <Provider store={store}>
            <ReactErrorBoundary
                onError={logTheError}
                FallbackComponent={ErrorFallBackComponent}
            >
                <Routes />
            </ReactErrorBoundary>
        </Provider>
    </HashRouter>,
    document.getElementById('root'),
);
