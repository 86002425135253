import API from '../../../constants/api';
import MESSAGES from '../../../constants/messages';

export const ASSET_LIST_FETCH_REQUEST = '@@asset/ASSET_LIST_FETCH_REQUEST';
export const ASSET_LIST_FETCH_FAILURE = '@@asset/ASSET_LIST_FETCH_FAILURE';
export const ASSET_LIST_FETCH_SUCCESS = '@@asset/ASSET_LIST_FETCH_SUCCESS';

export const ASSET_ADD_REQUEST = '@@asset/ASSET_ADD_REQUEST';
export const ASSET_ADD_FAILURE = '@@asset/ASSET_ADD_FAILURE';
export const ASSET_ADD_SUCCESS = '@@asset/ASSET_ADD_SUCCESS';

export const ASSET_VIEW_REQUEST = '@@asset/ASSET_VIEW_REQUEST';
export const ASSET_VIEW_FAILURE = '@@asset/ASSET_VIEW_FAILURE';
export const ASSET_VIEW_SUCCESS = '@@asset/ASSET_VIEW_SUCCESS';

export const COMPUTE_DEPRECIATION_BOARD_REQUEST = '@@asset/COMPUTE_DEPRECIATION_BOARD_REQUEST';
export const COMPUTE_DEPRECIATION_BOARD_FAILURE = '@@asset/COMPUTE_DEPRECIATION_BOARD_FAILURE';
export const COMPUTE_DEPRECIATION_BOARD_SUCCESS = '@@asset/COMPUTE_DEPRECIATION_BOARD_SUCCESS';

export const FORCE_DEPRECIATION_REQUEST = '@@asset/FORCE_DEPRECIATION_REQUEST';
export const FORCE_DEPRECIATION_FAILURE = '@@asset/FORCE_DEPRECIATION_FAILURE';
export const FORCE_DEPRECIATION_SUCCESS = '@@asset/FORCE_DEPRECIATION_SUCCESS';

export const ASSET_DELETE_REQUEST = '@@asset/ASSET_DELETE_REQUEST';
export const ASSET_DELETE_FAILURE = '@@asset/ASSET_DELETE_FAILURE';
export const ASSET_DELETE_SUCCESS = '@@asset/ASSET_DELETE_SUCCESS';

export const ASSET_RETIRE_REQUEST = '@@asset/ASSET_RETIRE_REQUEST';
export const ASSET_RETIRE_FAILURE = '@@asset/ASSET_RETIRE_FAILURE';
export const ASSET_RETIRE_SUCCESS = '@@asset/ASSET_RETIRE_SUCCESS';

export const ASSET_UNRETIRE_REQUEST = '@@asset/ASSET_UNRETIRE_REQUEST';
export const ASSET_UNRETIRE_FAILURE = '@@asset/ASSET_UNRETIRE_FAILURE';
export const ASSET_UNRETIRE_SUCCESS = '@@asset/ASSET_UNRETIRE_SUCCESS';

export const ASSET_UPDATE_STATE_REQUEST = '@@asset/ASSET_UPDATE_STATE_REQUEST';
export const ASSET_UPDATE_STATE_FAILURE = '@@asset/ASSET_UPDATE_STATE_FAILURE';
export const ASSET_UPDATE_STATE_SUCCESS = '@@asset/ASSET_UPDATE_STATE_SUCCESS';

export const ASSET_UPDATE_LOCATION_REQUEST = '@@asset/ASSET_UPDATE_LOCATION_REQUEST';
export const ASSET_UPDATE_LOCATION_FAILURE = '@@asset/ASSET_UPDATE_LOCATION_FAILURE';
export const ASSET_UPDATE_LOCATION_SUCCESS = '@@asset/ASSET_UPDATE_LOCATION_SUCCESS';

export const CLEAN_ASSET_INFO = '@@asset/CLEAN_ASSET_INFO';

export const updateAssetState = (uuid, payload, successCallback) => ({
    type: ASSET_UPDATE_STATE_REQUEST,
    api: `${API.ASSETS.UPDATE_STATE}${uuid}`,
    payload,
    successAction: ASSET_UPDATE_STATE_SUCCESS,
    successMessage: MESSAGES.ASSET.ASSET_UPDATE.SUCCESS,
    successCallback,
    failureAction: ASSET_UPDATE_STATE_FAILURE,
    failureMessage: MESSAGES.ASSET.ASSET_UPDATE.FAILURE,
});

export const updateAssetLocation = (uuid, locationUuid, successCallback) => ({
    type: ASSET_UPDATE_LOCATION_REQUEST,
    api: `${API.ASSETS.UPDATE_LOCATION}${uuid}?locationUuid=${locationUuid}`,
    successAction: ASSET_UPDATE_LOCATION_SUCCESS,
    successMessage: MESSAGES.ASSET.ASSET_UPDATE.SUCCESS,
    successCallback,
    failureAction: ASSET_UPDATE_LOCATION_FAILURE,
    failureMessage: MESSAGES.ASSET.ASSET_UPDATE.FAILURE,
});

export const fetchAssetList = (page, size, filter) => ({
    type: ASSET_LIST_FETCH_REQUEST,
    api: API.ASSETS.FETCH,
    page,
    size,
    filter,
});

export const addAsset = payload => ({
    type: ASSET_ADD_REQUEST,
    api: API.ASSETS.ADD,
    payload,
});

export const findAssetDetails = uuid => ({
    type: ASSET_VIEW_REQUEST,
    uuid,
    api: API.ASSETS.VIEW,
});

export const retireAsset = (uuid, retireReason, successCallback) => ({
    type: ASSET_RETIRE_REQUEST,
    uuid,
    retireReason,
    successCallback,
    api: API.ASSETS.RETIRE,
});

export const unRetireAsset = (uuid, successCallback) => ({
    type: ASSET_UNRETIRE_REQUEST,
    successAction: ASSET_UNRETIRE_SUCCESS,
    successMessage: MESSAGES.ASSET.UNRETIRE.SUCCESS,
    successCallback,
    failureAction: ASSET_UNRETIRE_FAILURE,
    failureMessage: MESSAGES.ASSET.UNRETIRE.FAILURE,
    api: `${API.ASSETS.UN_RETIRE}${uuid}`,
});

export const computeDepreciationBoard = uuid => ({
    type: COMPUTE_DEPRECIATION_BOARD_REQUEST,
    uuid,
    api: API.ASSETS.COMPUTE_DEPRECIATION_BOARD,
});

export const forceDepreciation = (uuid, assetUUID) => ({
    type: FORCE_DEPRECIATION_REQUEST,
    uuid,
    assetUUID,
    api: API.ASSETS.DEPRECIATE,
});

export const deleteAsset = uuid => ({
    type: ASSET_DELETE_REQUEST,
    api: API.ASSETS.DELETE,
    uuid,
});

export const cleanAssetInfo = () => ({
    type: CLEAN_ASSET_INFO,
});
