import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_PURCHASE_ORDER_REQUEST,
    FETCH_PURCHASE_ORDER_REQUEST,
} from '../../redux/modules/purchaseOrder/purchaseOrder-actions';
import { createPurchaseOrderWorker, fetchPurchaseOrderWorker } from './purchaseOrderWorkerSaga';

export function* createPurchaseOrderWatcher() {
    yield takeLatest(CREATE_PURCHASE_ORDER_REQUEST, createPurchaseOrderWorker);
}

export function* fetchPurchaseOrderWatcher() {
    yield takeLatest(FETCH_PURCHASE_ORDER_REQUEST, fetchPurchaseOrderWorker);
}
