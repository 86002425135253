/* eslint-disable react/no-did-mount-set-state */
/*
    eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,
    jsx-a11y/no-noninteractive-element-interactions
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SideBar from '../constants/SideBar';
import { dashboard, leftPaneToPageMap } from '../constants/constants';
import { isArrayValidAndNotEmpty } from '../constants/CommonUtil';
import { notifyAppSwitch } from '../redux/modules/appConfiguration/appConfiguration-actions';
import { findBestMatch } from '../constants/StringSimilarity';
import { clearPayorState } from '../redux/modules/payors/payers/payors-actions';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const drawerWidth = 300;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    closedMenuButton: {
        transform: 'rotate(0deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    openMenuButton: {
        transform: 'rotate(180deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        overflow: 'auto',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            overflow: 'auto',
            width: drawerWidth,
            position: 'relative',
            height: '100%',
        },
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginTop: '4rem',
    },
    toolBar: {
        padding: '0 14px',
    },
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonStyle: {
        height: '1.5rem',
        borderRadius: '1rem',
        width: '8em',
        fontSize: '0.7rem',
        padding: '0',
        textTransform: 'initial',
        minHeight: '1rem',
        color: '#1aa0e4',
        border: '1px solid #1aa0e4',
    },
    flexTabs: {
        minHeight: '4rem',
        alignItems: 'center',
    },
    tab: {
        fontSize: '15px',
    },
    tabIndicator: {
        backgroundColor: '#FFFFFF',
    },
});

const getBestMatchTabIfPartialRatioMatchIsDisabled = (sideBarTabs, activeTab, disablePartialRatioRouteMatching) => {
    let values = Object.values(sideBarTabs);
    console.log('Value', values);
    if (isArrayValidAndNotEmpty(values)) {
        values = values.map(v => v.value);
        const bestRoute = findBestMatch(activeTab, values, disablePartialRatioRouteMatching);
        return getStringFromObject('bestMatch.target', bestRoute, '');
    }
    return '';
};

const getBestMatchTab = (sideBarTabs, activeTab, disablePartialRatioRouteMatching) => {
    if (disablePartialRatioRouteMatching) {
        return getBestMatchTabIfPartialRatioMatchIsDisabled(sideBarTabs, activeTab, disablePartialRatioRouteMatching);
    }
    const values = Object.values(sideBarTabs);
    console.log('Value', values);
    if (isArrayValidAndNotEmpty(values)) {
        const tabs = values.map(v => v.tabs);
        const mergedTabsObjects = [].concat(...tabs);
        const allTabRoutes = mergedTabsObjects.map(e => e.route);
        const bestRoute = findBestMatch(activeTab, allTabRoutes, disablePartialRatioRouteMatching);
        const matchingRoute = getStringFromObject('bestMatch.target', bestRoute, '');
        const matchingTab = values.find((e) => {
            const matchingIndex = e.tabs.findIndex(t => t.route === matchingRoute);
            if (matchingIndex !== -1) {
                return e;
            }
            return null;
        },
        );
        return getStringFromObject('value', matchingTab) || '';
    }
    return '';
};

class SettingsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeTab: 'dashboard',
            primaryTabValue: null,
        };
        this.opentimer = null;
        this.closetimer = null;
        this.myRef = React.createRef();
    }

    componentDidMount() {
        console.log('kjhasldfhafjk', getStringFromObject('history.location.pathname', this.props));
        this.setState({
            activeTab: getStringFromObject('history.location.pathname', this.props),
        }, this.setPrimaryTabValue);
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(this.props.appConfiguration, nextProps.appConfiguration)) {
            this.setPrimaryTabValue(nextProps);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearPayorState());
    }

    onListItemClick = (value, label) => {
        console.log('sasd0s9aiod', value);
        const {
            appConfiguration,
            disablePartialRatioRouteMatching,
        } = this.props;
        this.setState({
            activeTab: value,
        });
        this.props.history.push(leftPaneToPageMap[value]);
        this.props.dispatch(notifyAppSwitch(label));
        this.handleDrawerClose();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const sideBarTabs = getStringFromObject('settingSideTabs', appConfiguration, {});
        const currentTab = getBestMatchTab(sideBarTabs, value, disablePartialRatioRouteMatching);
        let primaryDefaultValue = '';
        if (sideBarTabs && sideBarTabs[currentTab]) {
            primaryDefaultValue = getStringFromObject('defaultTabValue', sideBarTabs[currentTab], '');
        }
        this.setState({ primaryTabValue: primaryDefaultValue });
    };

    setPrimaryTabValue = (props = this.props) => {
        const {
            appConfiguration,
            disablePartialRatioRouteMatching,
        } = props;
        const {
            activeTab,
        } = this.state;
        console.log('ahlksdfhalksdhf', activeTab);
        const sideBarTabs = getStringFromObject('settingSideTabs', appConfiguration, {});
        const currentTab = getBestMatchTab(sideBarTabs, activeTab, disablePartialRatioRouteMatching);
        let primaryTabs = [];
        if (sideBarTabs && sideBarTabs[currentTab]) {
            primaryTabs = getStringFromObject('tabs', sideBarTabs[currentTab], []);
        }
        const pathname = getStringFromObject('history.location.pathname', props);
        // let primaryDefaultValue = '';
        // console.log('jkdsahflksjdfh', sideBarTabs, activeTab, currentTab, primaryTabs, pathname);
        // console.log('jkdsahflksjdfhsasa', currentTab);
        if (isArrayValidAndNotEmpty(primaryTabs)) {
            for (let i = 0; i < primaryTabs.length; i += 1) {
                const aPrimaryTab = primaryTabs[i];
                // console.log('jkdsahflksjdfhs', aPrimaryTab, pathname);
                if (aPrimaryTab.route === pathname) {
                    this.setState({
                        primaryTabValue: aPrimaryTab.value,
                    });
                    break;
                }
            }
        }
    };

    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    handleDrawerOpen = () => {
        if (this.opentimer === null && !this.state.open) {
            this.opentimer = setTimeout(() => {
                this.setState({ open: true });
                this.opentimer = null;
            }, 300);
        }
        if (this.closetimer !== null) {
            clearTimeout(this.closetimer);
            this.closetimer = null;
        }
    };

    handleDrawerClose = () => {
        if (this.opentimer !== null) {
            clearTimeout(this.opentimer);
            this.opentimer = null;
        }
        if (this.closetimer === null && this.state.open) {
            this.closetimer = setTimeout(() => {
                this.setState({ open: false });
                this.closetimer = null;
            }, 500);
        }
    };

    handleHomeIcon = () => {
        const { history } = this.props;
        history.push(dashboard);
    };

    handlePrimaryTabChange = (event, value) => {
        const { activeTab } = this.state;
        const { appConfiguration, history, disablePartialRatioRouteMatching } = this.props;
        const sideBarTabs = getStringFromObject('settingSideTabs', appConfiguration, {});
        const currentTab = getBestMatchTab(sideBarTabs, activeTab, disablePartialRatioRouteMatching);
        let primaryTabs = [];
        if (sideBarTabs && sideBarTabs[currentTab]) {
            primaryTabs = getStringFromObject('tabs', sideBarTabs[currentTab], []);
        }
        this.setState({ primaryTabValue: value });
        if (isArrayValidAndNotEmpty(primaryTabs)) {
            const currentPrimary = primaryTabs.find(t => t.value === value);
            if (isObjectValidAndNotEmpty(currentPrimary)) {
                history.push(currentPrimary.route);
            }
        }
    };

    render() {
        const {
            classes, theme, appConfiguration,
            disablePartialRatioRouteMatching,
        } = this.props;
        const {
            open,
            activeTab,
            primaryTabValue,
        } = this.state;
        const sideBarTabs = getStringFromObject('settingSideTabs', appConfiguration, {});
        const currentTab = getBestMatchTab(sideBarTabs, activeTab, disablePartialRatioRouteMatching);
        let primaryTabs = [];
        let primaryDefaultValue = '';
        if (sideBarTabs && sideBarTabs[currentTab]) {
            primaryTabs = getStringFromObject('tabs', sideBarTabs[currentTab], []);
            primaryDefaultValue = getStringFromObject('defaultTabValue', sideBarTabs[currentTab], '');
        }
        return (
            <div ref={this.myRef} className={classes.root}>
                <AppBar
                    position="absolute"
                    className={classes.appBar}
                >
                    <Toolbar disableGutters className={classes.toolBar} >
                        <div className={classes.headerStyle}>
                            <div className="display-flex">
                                <div className="display-flex">
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={this.toggleDrawer}
                                        className={
                                            classNames(
                                                classes.menuButton,
                                                open && classes.openMenuButton,
                                                !open && classes.closedMenuButton,
                                            )
                                        }
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                                <div>
                                    <Tabs
                                        value={primaryTabValue || primaryDefaultValue}
                                        onChange={this.handlePrimaryTabChange}
                                        classes={{
                                            flexContainer: classes.flexTabs,
                                            indicator: classes.tabIndicator,
                                        }}
                                        fullWidth
                                        centered
                                    >
                                        {
                                            isArrayValidAndNotEmpty(primaryTabs) &&
                                            primaryTabs.map(tab => (
                                                <Tab
                                                    test-id={tab.value}
                                                    key={tab.value}
                                                    value={tab.value}
                                                    label={tab.label}
                                                    className={classes.tab}
                                                />
                                            ))
                                        }
                                    </Tabs>
                                </div>
                            </div>
                            <IconButton aria-haspopup="true" onClick={this.handleHomeIcon} color="inherit" test-id="exit-to-app">
                                <ExitToApp />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper,
                            !this.state.open && classes.drawerPaperClose, 'position-fixed'),
                    }}
                    open={this.state.open}
                    onMouseOver={this.handleDrawerOpen}
                    onFocus={() => {}}
                    onMouseOut={this.handleDrawerClose}
                    onBlur={() => {}}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.toggleDrawer}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon test-id="right-icon" /> : <ChevronLeftIcon test-id="left-icon" />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List style={{ padding: '0px' }}>
                        <SideBar
                            onListItemClick={this.onListItemClick}
                            activeTab={activeTab}
                            sideBarTabs={sideBarTabs}
                            disablePartialRatioRouteMatching={disablePartialRatioRouteMatching}
                        />
                    </List>
                </Drawer>
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    disablePartialRatioRouteMatching: getStringFromObject('appConfiguration.disablePartialRatioRouteMatching', state) || false,
});

SettingsHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object,
    appConfiguration: PropTypes.object.isRequired,
    disablePartialRatioRouteMatching: PropTypes.bool.isRequired,
};

SettingsHeader.defaultProps = {
    history: {},
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(SettingsHeader));
