import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import Popover from '@material-ui/core/Popover/Popover';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar/Avatar';

import PurchaseOrderDialog from '../../components/PurchaseOrderComponents/PurchaseOrderDialog';
import SearchPopover from '../../components/PurchaseOrderComponents/SearchPopover';
import {
    COLORS,
    ERP_STOCK_TRANSACTION_TYPES,
    STOCK_MANAGEMENT_PRIVILEGES,
    iframeUrlMap,
    stockAdvancedSearch,
    stockFormNames,
    stockManagement,
    stockManagementBatches,
    stockManagementConsumables,
    stockManagementDrugs,
    stockManagementProductCategories,
    stockManagementReorderRules, stockManagementOpticalProducts,
} from '../../constants/constants';
import AddStockDialog from '../../components/PurchaseOrderComponents/AddStockDialog';
import InternalMovesDialog from '../../components/PurchaseOrderComponents/InternalMovesDialog/InternalMovesDialog';
import GoodReturnDialog from '../../components/PurchaseOrderComponents/GoodReturnDialog/GoodReturnDialog';
import InventoryAdjustmentDialog
    from '../../components/PurchaseOrderComponents/InventoryAdjustment/InventoryAdjustmentDialog';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import SideMenu from '../../components/SideMenu/SideMenu';
import { getSearchCategories, SEARCH_CATEGORIES } from './ErpAdvanceSearchUtil';
// import { isThisSecond } from 'date-fns';
import IntentDialog from '../../components/PurchaseOrderComponents/IntentDialog/IntentDialog';
import MaterialReceiveDialog from '../../components/PurchaseOrderComponents/MaterialReceiveDialog/MaterialReceiveDialog';
import IframeDialogComponent from '../../components/IframeDialogComponent/IframeDialogComponent';
import { getStringFromObject } from '../../constants/lodashUtils';

export const stockManagementMapping = [
    {
        title: 'Purchase Order',
        open: 'isPurchaseOrderClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder,
    },
    {
        title: 'Add Stock',
        open: 'isAddStockClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.addStock,
    },
    {
        title: 'Stock Move',
        open: 'isInternalMoveClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.stockMove,
    },
    {
        title: 'Misc Operations ',
        open: 'isMiscOpsClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.stockMove,
    },
    {
        title: 'Adjust Inventory',
        open: 'isInventoryAdjustmentClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.adjustInventory,
    },
    {
        title: 'Return Stock',
        open: 'isGoodReturnClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.returnStock,
    },
    {
        title: 'Material Receive',
        open: 'isMaterialReceiveClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.materialReceive,
    },
    {
        title: 'Stock Intent',
        open: 'isStockIntentClicked',
        privileges: STOCK_MANAGEMENT_PRIVILEGES.stockIntent,
    },
];

const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

const getSideMenuLabel = icon => (
    <div className="flexChildren justify-content-center">
        {icon}
        <span style={{ color: 'red', visibility: 'hidden' }}>
            &nbsp; &#x25cf;
        </span>
    </div>
);

class StockManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.changeTabForRoute(props),
            anchorEl: null,
            anchorElForSearch: null,
            isPurchaseOrderClicked: false,
            isAddStockClicked: false,
            isInternalMoveClicked: false,
            isInventoryAdjustmentClicked: false,
            loadIntent: false,
            isStockIntentClicked: false,
            createPO: false,
            createStockMove: false,
            intentId: null,
        };
        this.commonMenuItems = Object.keys(getSearchCategories()).map((c, index) => ({
            // eslint-disable-next-line max-len
            icon: getSideMenuLabel(<Avatar style={{ backgroundColor: COLORS[index] }}>{SEARCH_CATEGORIES[c].charAt(0)}</Avatar>),
            text: SEARCH_CATEGORIES[c],
            onClick: this.handleClickQuickSearch(SEARCH_CATEGORIES[c]),
        }));
    }

    changeRouteForSelectedTab = (selectedTab = this.state.selectedTab) => {
        this.props.history.push(selectedTab);
    };

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        if (route === stockManagement) {
            if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showInventoryTab)) {
                return stockManagementBatches;
            } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showDrugTab)) {
                return stockManagementDrugs;
            } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showConsumablesTab)) {
                return stockManagementConsumables;
            } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showOpticalProductsTab)) {
                return stockManagementOpticalProducts;
            } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showCategories)) {
                return stockManagementProductCategories;
            }
        }
        return route;
    };

    handleChange = (event, selectedTab) => {
        event.preventDefault();
        this.setState({
            selectedTab,
        }, this.changeRouteForSelectedTab);
    };

    handlePopover = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClickQuickSearch = c => () => {
        const { history } = this.props;
        history.push(`${stockAdvancedSearch}?q=${c}`);
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    handleSearchPopover = (event) => {
        this.setState({ anchorElForSearch: event.currentTarget });
    };

    handleSearchPopoverClose = () => {
        this.setState({ anchorElForSearch: null });
    };

    handleCloseDialog = (open) => {
        this.setState({
            [open]: false,
        });
    };

    handleOpenDialog = (open) => {
        console.log('sssssssssssss', open);
        this.setState({
            [open]: true,
        });
    };

    handleAdvancedSearch = () => {
        console.log('gkjgjgkjafjksdg', this.props.history);
        this.props.history.push(stockAdvancedSearch);
    };
    createPOFromIntent = (id) => {
        this.setState({
            createPO: true,
            intentId: id,
        });
    }
    createStockMoveFromIntent = (id) => {
        this.setState({
            createStockMove: true,
            intentId: id,
        });
    }
    closeDialog = () => {
        this.setState({
            createStockMove: false,
            intentId: null,
            createPO: false,
        });
    }
    render() {
        const {
            classes, children, loadIframeFormConfig, iframeFormsBase,
        } = this.props;
        const {
            isAddStockClicked,
            selectedTab,
            anchorEl,
            anchorElForSearch,
            isPurchaseOrderClicked,
            isInternalMoveClicked,
            isInventoryAdjustmentClicked,
            isGoodReturnClicked,
            isMaterialReceiveClicked,
            isMiscOpsClicked,
            isStockIntentClicked,
            createPO,
            createStockMove,
            intentId,
        } = this.state;
        const menuItems = [...this.commonMenuItems];
        console.log('dasdfafdasfdaf', this.props);
        return (
            <React.Fragment>
                <div style={{ background: '#fff', padding: '2rem' }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item lg={8} md={8} sm={8}>
                            <AppBar position="static" className={classes.appBar}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    fullWidth
                                    scrollable
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showInventoryTab) &&
                                        <Tab
                                            value={stockManagementBatches}
                                            label="Inventory"
                                            test-id="Inventory"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showDrugTab) &&
                                        <Tab
                                            value={stockManagementDrugs}
                                            label="Drugs"
                                            test-id="Drugs"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showConsumablesTab) &&
                                        <Tab
                                            value={stockManagementConsumables}
                                            label="Non-Drugs"
                                            test-id="Non-Drugs"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showOpticalProductsTab) &&
                                        <Tab
                                            value={stockManagementOpticalProducts}
                                            label="Optical"
                                            test-id="Optical"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showCategories) &&
                                        <Tab
                                            value={stockManagementProductCategories}
                                            label="Categories"
                                            test-id="Categories"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                    {
                                        checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showReorderRules) &&
                                        <Tab
                                            value={stockManagementReorderRules}
                                            label="Re-Order Rules"
                                            test-id="re-order-rules"
                                            classes={{
                                                root: classes.tabRoot,
                                                selected: classes.tabSelected,
                                                label: classes.label,
                                            }}
                                            disableRipple
                                        />
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid container item lg={4} md={4} sm={3} justify="space-evenly">
                            <AddCircle
                                onClick={this.handlePopover}
                                className="cursor-pointer"
                                test-id="add-stock-management"
                            />
                            {
                                checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.advancedSearch) &&
                                <SearchIcon
                                    onClick={this.handleSearchPopover}
                                    className="cursor-pointer"
                                    test-id="search-stock-management"
                                />
                            }
                        </Grid>
                    </Grid>
                    {children}
                </div>
                {
                    isAddStockClicked && (
                        getStringFromObject(stockFormNames.ADD_STOCK, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Add Stock"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.ADD_STOCK}`}
                                handleClose={() => this.handleCloseDialog('isAddStockClicked')}
                                fullScreen
                            /> :
                            <AddStockDialog
                                open
                                handleClose={() => this.handleCloseDialog('isAddStockClicked')}
                            />
                    )
                }
                {
                    isPurchaseOrderClicked && (
                        getStringFromObject(stockFormNames.PURCHASE_ORDER, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Purchase Order"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.PURCHASE_ORDER}`}
                                handleClose={() => this.handleCloseDialog('isPurchaseOrderClicked')}
                                fullScreen
                            /> :
                            <PurchaseOrderDialog
                                open={isPurchaseOrderClicked}
                                handleClose={() => this.handleCloseDialog('isPurchaseOrderClicked')}
                            />
                    )
                }
                {
                    isInternalMoveClicked && (
                        getStringFromObject(stockFormNames.INTERNAL_MOVES, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Internal Moves"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.INTERNAL_MOVES}`}
                                handleClose={() => this.handleCloseDialog('isInternalMoveClicked')}
                                fullScreen
                            /> :
                            <InternalMovesDialog
                                open
                                handleClose={() => this.handleCloseDialog('isInternalMoveClicked')}
                            />
                    )
                }
                {
                    isMiscOpsClicked && (
                        getStringFromObject(stockFormNames.MISCELLANEOUS_OPERATION, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Stock Move"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.MISCELLANEOUS_OPERATION}`}
                                handleClose={() => this.handleCloseDialog('isMiscOpsClicked')}
                                fullScreen
                            /> :
                            <InternalMovesDialog
                                open
                                miscOps
                                handleClose={() => this.handleCloseDialog('isMiscOpsClicked')}
                            />
                    )
                }
                {
                    isInventoryAdjustmentClicked && (
                        getStringFromObject(stockFormNames.ADJUST_INVENTORY, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Adjust Inventory"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.ADJUST_INVENTORY}`}
                                handleClose={() => this.handleCloseDialog('isInventoryAdjustmentClicked')}
                                fullScreen
                            /> :
                            <InventoryAdjustmentDialog
                                open
                                handleClose={() => this.handleCloseDialog('isInventoryAdjustmentClicked')}
                            />
                    )
                }
                {
                    isGoodReturnClicked && (
                        getStringFromObject(stockFormNames.RETURN_STOCK, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Return Stock"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.RETURN_STOCK}`}
                                handleClose={() => this.handleCloseDialog('isGoodReturnClicked')}
                                fullScreen
                            /> :
                            <GoodReturnDialog
                                open
                                handleClose={() => this.handleCloseDialog('isGoodReturnClicked')}
                            />
                    )
                }
                {
                    isMaterialReceiveClicked && (
                        getStringFromObject(stockFormNames.MATERIAL_RECEIVE, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Material Receive"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.MATERIAL_RECEIVE}`}
                                handleClose={() => this.handleCloseDialog('isMaterialReceiveClicked')}
                                fullScreen
                            /> :
                            <MaterialReceiveDialog
                                open
                                handleClose={() => this.handleCloseDialog('isMaterialReceiveClicked')}
                            />
                    )
                }
                {
                    isStockIntentClicked && (
                        getStringFromObject(stockFormNames.STOCK_INTENT, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Intent"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.INTENT}`}
                                handleClose={() => this.handleCloseDialog('isStockIntentClicked')}
                                fullScreen
                            /> :
                            <IntentDialog
                                open
                                handleClose={() => this.handleCloseDialog('isStockIntentClicked')}
                                createPO={this.createPOFromIntent}
                                createStockMove={this.createStockMoveFromIntent}
                            />
                    )
                }
                {
                    this.state.loadIntent &&
                    <InternalMovesDialog
                        open
                        intentConv
                        handleClose={() => this.handleCloseDialog('loadIntent')}
                    />
                }
                {
                    createPO && intentId && (
                        getStringFromObject(stockFormNames.PURCHASE_ORDER, loadIframeFormConfig) ?
                            <IframeDialogComponent
                                header="Internal Moves"
                                open
                                iframeUrl={`${iframeFormsBase}/#${iframeUrlMap.PURCHASE_ORDER}`}
                                handleClose={this.closeDialog}
                                fullScreen
                            /> : <PurchaseOrderDialog
                                open
                                intentUuid={intentId}
                                handleClose={this.closeDialog}
                            />
                    )
                }
                {
                    createStockMove && intentId && <InternalMovesDialog
                        open
                        intentConv
                        intentUuid={intentId}
                        handleClose={this.closeDialog}
                    />
                }
                <Popover
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                >
                    {
                        stockManagementMapping.map((w) => {
                            if (checkIfPrivilegeExistsForUser(w.privileges)) {
                                return (
                                    <MenuItem
                                        key={w.title}
                                        onClick={() => this.handleOpenDialog(w.open)}
                                        test-id={w.title}
                                    >
                                        {w.title}
                                    </MenuItem>
                                );
                            }
                            return '';
                        })
                    }
                </Popover>
                <Popover
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorElForSearch}
                    anchorEl={anchorElForSearch}
                    onClose={this.handleSearchPopoverClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                >
                    <SearchPopover
                        handleAdvancedSearch={this.handleAdvancedSearch}
                        transactionTypes={ERP_STOCK_TRANSACTION_TYPES}
                    />
                </Popover>
                <SideMenu
                    items={menuItems}
                />
            </React.Fragment>
        );
    }
}

StockManagementContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node,
    loadIframeFormConfig: PropTypes.object,
    iframeFormsBase: PropTypes.string,
};

StockManagementContainer.defaultProps = {
    children: null,
    loadIframeFormConfig: {},
    iframeFormsBase: '',
};


const mapStateToProps = state => ({
    loadIframeFormConfig: getStringFromObject('appConfiguration.loadIframeFormConfig', state) || true,
    iframeFormsBase: getStringFromObject('appConfiguration.iframeFormsBase', state) || '',
});

export default connect(mapStateToProps)(withStyles(style)(StockManagementContainer));
