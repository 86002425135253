import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { payerScheme, payorPolicy, payorSubInsurance } from '../constants/constants';
import PayerNetworkContainer from '../containers/PayerNetworkContainer/PayerNetworkContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const SubInsurancePage = React.lazy(() => import('../pages/PayerPages/SubInsurancePage'));
const PolicyPage = React.lazy(() => import('../pages/PayerPages/PolicyPage'));
const InsuranceSchemePage = React.lazy(() => import('../pages/PayerPages/InsuranceSchemePage'));

const PayorNetworkRoutes = props => (
    <PayerNetworkContainer {...props} >
        <Suspense fallback={<PageLoader />}>
            <Route path={`${payorSubInsurance}/:payerUuid`} exact component={SubInsurancePage} />
            <Route path={`${payorPolicy}/:payerUuid`} exact component={PolicyPage} />
            <Route path={`${payerScheme}/:payerUuid`} exact component={InsuranceSchemePage} />
        </Suspense>
    </PayerNetworkContainer>
);

export default PayorNetworkRoutes;
