import { takeLatest } from 'redux-saga/effects';
import { fetchPagesOfBatchesWorker, createBatchWorker } from './batchesWorkerSaga';
import { FETCH_BATCHES_LIST_REQUEST, CREATE_BATCH_REQUEST } from '../../redux/modules/batches/batches-actions';

export function* fetchPagesOfBatchesWatcher() {
    yield takeLatest(FETCH_BATCHES_LIST_REQUEST, fetchPagesOfBatchesWorker);
}

export function* createBatchWatcher() {
    yield takeLatest(CREATE_BATCH_REQUEST, createBatchWorker);
}
