import {
    ADD_VISIT_FAILURE,
    ADD_VISIT_REQUEST,
    ADD_VISIT_SUCCESS,
    CLEAR_OPEN_VISIT_FLAG,
    CLEAR_SCANNED_DATA,
    CLEAR_VISITS,
    FETCH_VISIT_REQUEST,
    FETCH_VISIT_SUCCESS,
    SET_VISIT_RELATED_INFO,
} from './visitDetails-actions';
import initialState from '../../initialStates';

import { getStringFromObject } from '../../../constants/lodashUtils';

const visitDetailsReducer = (state = initialState.visitDetails, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_VISIT_SUCCESS:
        newState = { data: action.visitDetails, printable: false };
        break;
    case FETCH_VISIT_REQUEST:
        newState = {
            ...state,
            scannedInitialValues: action.scannedInitialValues,
        };
        break;
    case CLEAR_SCANNED_DATA:
        newState = {
            ...state,
            scannedInitialValues: null,
        };
        break;
    case CLEAR_OPEN_VISIT_FLAG:
        newState = { ...state, hasOpenVisit: false };
        break;
    case SET_VISIT_RELATED_INFO:
        newState = {
            ...state,
            data: {
                ...state.data,
                hasOpenVisit: true,
                ignoreAskEpisode: true,
                previousVisitEpisodeAttributes:
                    getStringFromObject('payload.previousVisitEpisodeAttributes', action) || [],
                openVisitUuid: getStringFromObject('payload.visitUuid', action),
                openVisitType: getStringFromObject('payload.visitType', action),
            },
        };
        break;
    case ADD_VISIT_SUCCESS:
        newState = {
            data: {
                ...state.data,
                ...action.successResponse,
            },
            printable: action.printable,
            loading: false,
        };
        break;
    case CLEAR_VISITS:
        newState = initialState.visitDetails;
        break;
    case ADD_VISIT_REQUEST:
        newState = { ...state, loading: true };
        break;
    case ADD_VISIT_FAILURE:
        newState = { ...state, loading: false };
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default visitDetailsReducer;

