/**
 * Created by sachinprakash on 28/6/19.
 */
import initialStates from '../../initialStates';
import {
    ADD_OR_UPDATE_PACKAGE_FAILURE,
    ADD_OR_UPDATE_PACKAGE_SUCCESS,
    FETCH_PACKAGE_LIST_FAILURE,
    FETCH_PACKAGE_LIST_REQUEST,
    FETCH_PACKAGE_LIST_SUCCESS,
} from './servicePackage-actions';

export const fetchPackagesReducer = (state = initialStates.packageList, action) => {
    console.log('klsdfjajksfhsljkdfhalksjfdakljsdfhlaskjdf', action);
    let newState = {};
    switch (action.type) {
    case FETCH_PACKAGE_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_PACKAGE_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            isLast: action.data.isLast,
            totalPages: action.totalPages,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
        });
        break;
    case FETCH_PACKAGE_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    default:
        newState = state;
    }
    return newState;
};

export const addOrUpdatePackageReducer = (state = initialStates.packageDetails, action) => {
    let newState = {};
    switch (action.type) {
    case ADD_OR_UPDATE_PACKAGE_SUCCESS:
        newState = Object.assign({}, state, action.data);
        break;
    case ADD_OR_UPDATE_PACKAGE_FAILURE:
        newState = Object.assign({}, state);
        break;
    default: newState = state;
    }
    return newState;
};
