import sortBy from 'lodash.sortby';
import uuidv4 from 'uuid/v4';
import {
    findObjInArray,
    getNameOfEmployee,
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty, isValidObject,
} from '../../constants/CommonUtil';
import {
    formatDateForDisplay,
    getDateInYYYYMMDDFormat,
    getDifferenceDaysBetween,
    getStartOfDay,
    isDateBefore,
} from '../../constants/DateUtil';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject, setStringPropertyToObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const sortCode = (a, b) => {
    let decision = 0;
    decision = getStringFromObject('code', a) < getStringFromObject('code', b) ? -1 : 1;
    return decision;
};

export const getFileName = (file) => {
    console.log('adfsdfsdf', isValidObject(file) && file.key);
    if (isValidObject(file) && file.key) {
        return file.key;
    }
    return file;
};

const extractDataFromSchema = (schema, data) => {
    const formDataObject = {};
    if (isArrayValidAndNotEmpty(schema)) {
        schema.forEach((aField) => {
            const {
                fromProperty,
                toProperty,
                formatter,
                arrayObjectPropertyName,
                propertyToSet,
                findDocument,
                formatTo,
                staticValue,
                attributes,
            } = aField;
            let fieldValue = getStringFromObject(fromProperty, data);
            if (staticValue !== undefined) {
                fieldValue = staticValue;
            } else if (formatter === 'date') {
                fieldValue = getDateInYYYYMMDDFormat(new Date(fieldValue));
            } else if (formatter === 'epoch') {
                fieldValue = new Date(fieldValue).getTime();
            } else if (formatter === 'employeeAttributes') {
                fieldValue = {
                    ...(aField.objectStructure || {}),
                    value: fieldValue,
                };
            } else if (formatter === 'document') {
                fieldValue = {
                    documentName: fromProperty,
                    expiryDate: getStringFromObject(`${fromProperty}.expiryDate`, data) || null,
                    issueDate: getStringFromObject(`${fromProperty}.issueDate`, data) || null,
                    placeOfIssue: getStringFromObject(`${fromProperty}.issuePlace`, data) || null,
                    attributes: attributes.map(anAttribute => ({
                        name: anAttribute.to,
                        value: getStringFromObject(`${fromProperty}.${anAttribute.from}`, data),
                    })),
                    attachmentName: getFileName(getStringFromObject(`${fromProperty}.file`, data)),
                };
            } else if (formatter === 'valueInArray') {
                fieldValue = findObjInArray(fieldValue, { name: arrayObjectPropertyName });
                fieldValue = propertyToSet ? getStringFromObject(propertyToSet, fieldValue) : fieldValue;
            } else if (formatter === 'documentAttribute') {
                fieldValue = findObjInArray(fieldValue, { documentName: findDocument.split('.')[0] });
                const docAttributes = getStringFromObject('attributes', fieldValue);
                fieldValue = findObjInArray(docAttributes, { name: findDocument.split('.')[1] });
                fieldValue = propertyToSet ? getStringFromObject(propertyToSet, fieldValue) : fieldValue;
            } else if (formatter === 'documentProperty') {
                fieldValue = findObjInArray(fieldValue, { documentName: findDocument.split('.')[0] });
                fieldValue = getStringFromObject(findDocument.split('.')[1], fieldValue);
                if (formatTo === 'date') {
                    fieldValue = getDateInYYYYMMDDFormat(new Date(fieldValue));
                }
            }
            setStringPropertyToObject(toProperty, formDataObject, fieldValue);
        });
    }
    return formDataObject;
};

export const getContractsUiObject = (allContracts, allowContractEditForPastDates) => (
    sortBy(allContracts, l => new Date(l.startDate).getTime()).reverse().map((contract) => {
        let canDelete = false;
        let canEdit = false;
        let startDate = new Date(getStringFromObject('startDate', contract));
        if (startDate) {
            startDate = new Date(startDate);
            // SHIFA JEDDAH ASKED FOR CONTRACTS TO BE ALWAYS EDITED... EVEN IF IN THE PAST
            canEdit = (
                allowContractEditForPastDates ||
                !isDateBefore(getStartOfDay(startDate), getStartOfDay(new Date()))
            );
            canDelete = getDifferenceDaysBetween(new Date(), startDate) > 0;
        }
        return {
            canEdit,
            canDelete,
            uuid: getStringFromObject('uuid', contract),
            startDate: getStringFromObject('startDate', contract),
            wage: getStringFromObject('wage', contract),
            considerAttendanceInComputation:
                getStringFromObject('considerAttendanceInComputation', contract, false),
            considerOvertimeInComputation:
                getStringFromObject('considerOvertimeInComputation', contract, false),
            contract: getStringFromObject('contractType', contract),
            contractName: getStringFromObject('contractType.value', contract),
            structure: getStringFromObject('salaryStructure', contract),
            structureName: getStringFromObject('salaryStructure.value', contract),
            inputRules: getStringFromObject('ruleInputDtos', contract, []).map(l => ({
                code: getStringFromObject('inputCode.inputCode', l),
                ruleUuid: getStringFromObject('uuid', l),
                uuid: getStringFromObject('inputCode.uuid', l),
                description: getStringFromObject('inputCode.description', l),
                value: getStringFromObject('defaultValue', l),
            })).sort(sortCode),
            uiUuid: uuidv4(),
            period: formatDateForDisplay(new Date(startDate)),
        };
    })
);

export const createFormDataObject = (schema, data, allowContractEditForPastDates) => {
    console.log('asdasdasa9d', schema, data);
    let formDataObject = {};
    if (isObjectValidAndNotEmpty(data)) {
        const name = getNameOfEmployee(data);
        formDataObject = extractDataFromSchema(schema, data);
        let certificates = getStringFromObject('certificateDtos', data, []);
        if (certificates.length === 0) {
            certificates = [];
        }
        const allContracts = getStringFromObject('allContracts', data, []);

        formDataObject = {
            ...formDataObject,
            name,
            certificate: {
                certificates: certificates.map(c => ({
                    certificateName: c.documentName,
                    year: c.year,
                    expiry: c.expiry ? getDateInYYYYMMDDFormat(new Date(c.expiry)) : '',
                    certificateFile: c.attachmentName,
                })),
            },
            allContracts: getContractsUiObject(allContracts, allowContractEditForPastDates),
            uuid: data.uuid,
            salary: {
                contract: '',
                structure: '',
                wage: null,
                startDate: getDateInYYYYMMDDFormat(new Date()),
            },
        };

        console.log('asdad9as-dais0a', formDataObject);
    }
    return formDataObject;
};

export const createInitializeObjFromData = (data) => {
    const empAttr = getStringFromObject('employeeAttributes', data, []);
    const visa = findObjInArray(empAttr, { name: 'visa_status' });
    const religion = findObjInArray(empAttr, { name: 'religion' });
    const passageDestination = findObjInArray(empAttr, { name: 'passage_destination' });
    const passageClass = findObjInArray(empAttr, { name: 'passage_class' });
    const passageType = findObjInArray(empAttr, { name: 'passage_type' });
    const exitReEntry = findObjInArray(empAttr, { name: 'exit_re_entry' });
    const name = getNameOfEmployee(data);
    const documents = getStringFromObject('documentDtos', data, []);
    const passportDetails = findObjInArray(documents, { documentName: 'passport' });
    const passportAttr = getStringFromObject('attributes', passportDetails, []);
    const passportNo = findObjInArray(passportAttr, { name: 'passportNo' });
    const iqamaDetails = findObjInArray(documents, { documentName: 'iqama' });
    const iqamaAttr = getStringFromObject('attributes', iqamaDetails, []);
    const iqamaNo = findObjInArray(iqamaAttr, { name: 'iqamaNo' });
    const iqamaProfession = findObjInArray(iqamaAttr, { name: 'iqamaProfession' });
    const insuranceDetails = findObjInArray(documents, { documentName: 'insurance' });
    const insuranceAttr = getStringFromObject('attributes', insuranceDetails, []);
    const insuranceNo = findObjInArray(insuranceAttr, { name: 'insuranceCardNo' });
    const insuranceClass = findObjInArray(insuranceAttr, { name: 'insuranceClass' });
    const drivingDetails = findObjInArray(documents, { documentName: 'driving' });
    const drivingAttr = getStringFromObject('attributes', drivingDetails, []);
    const drivingLicenseNo = findObjInArray(drivingAttr, { name: 'drivingLicenseNo' });
    const drivingLicenseType = findObjInArray(drivingAttr, { name: 'drivingLicenseType' });
    const localContact = getStringFromObject('localContactDetail', data, []);
    const nativeContact = getStringFromObject('nativeContactDetail', data, []);
    let certificates = getStringFromObject('certificateDtos', data, []);
    if (certificates.length === 0) {
        certificates = [];
    }
    const allContracts = getStringFromObject('allContracts', data, []);
    /* workpermit is not coming */
    return {
        basicInfo: {
            username: getStringFromObject('userName', data),
            dateOfBirth: getDateInYYYYMMDDFormat(new Date(getStringFromObject('birthday', data))),
            gender: getStringFromObject('gender', data),
            maritalStatus: getStringFromObject('marital', data),
            religion: getStringFromObject('value', religion),
            nationality: getStringFromObject('nationality', data),
            identificationNumber: getStringFromObject('employeeId', data),
        },
        contact: {
            address: '',
            extension: getStringFromObject('extension', data),
            personalPhoneNo: getStringFromObject('mobilePhone', data),
            personalEmail: getStringFromObject('personalEmail', data),
            emergencyContactNo: '',
            localAddress: getStringFromObject('localAddress', data),
            nativeAddress: getStringFromObject('nativeAddress', data),
        },
        leaves: {
            visaStatus: getStringFromObject('value', visa),
            passageDestination: getStringFromObject('value', passageDestination),
            passageClass: getStringFromObject('value', passageClass),
            passageType: getStringFromObject('value', passageType),
            exitReEntry: getStringFromObject('value', exitReEntry),
        },
        localContact: {
            nameOne: getStringFromObject('name', localContact[0], ''),
            relationOne: getStringFromObject('relation', localContact[0], ''),
            numberOne: getStringFromObject('number', localContact[0], ''),
            nameTwo: getStringFromObject('name', localContact[1], ''),
            relationTwo: getStringFromObject('relation', localContact[1], ''),
            numberTwo: getStringFromObject('number', localContact[1], ''),
        },
        nativeContact: {
            nameOne: getStringFromObject('name', nativeContact[0], ''),
            relationOne: getStringFromObject('relation', nativeContact[0], ''),
            numberOne: getStringFromObject('number', nativeContact[0], ''),
            nameTwo: getStringFromObject('name', nativeContact[1], ''),
            relationTwo: getStringFromObject('relation', nativeContact[1], ''),
            numberTwo: getStringFromObject('number', nativeContact[1], ''),
        },
        passport: {
            passportNo: getStringFromObject('value', passportNo),
            issueDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('issueDate', passportDetails))),
            expiryDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('expiryDate', passportDetails))),
            file: getStringFromObject('attachmentName', passportDetails),
        },
        iqama: {
            iqamaNo: getStringFromObject('value', iqamaNo),
            iqamaProfession: getStringFromObject('value', iqamaProfession),
            issueDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('issueDate', iqamaDetails))),
            issuePlace: getStringFromObject('placeOfIssue', iqamaDetails),
            expiryDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('expiryDate', iqamaDetails))),
            file: getStringFromObject('attachmentName', iqamaDetails),
        },
        insurance: {
            insuranceNo: getStringFromObject('value', insuranceNo),
            insuranceClass: getStringFromObject('value', insuranceClass),
            expiryDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('expiryDate', insuranceDetails))),
            file: getStringFromObject('attachmentName', insuranceDetails),

        },
        driving: {
            licenseNo: getStringFromObject('value', drivingLicenseNo),
            licenseType: getStringFromObject('value', drivingLicenseType),
            expiryDate: getDateInYYYYMMDDFormat(new Date(getStringFromObject('expiryDate', drivingDetails))),
            file: getStringFromObject('attachmentName', drivingDetails),
        },
        bank: {
            accountNumber: getStringFromObject('bankAccountNumber', data),
            bankIFSCNumber: getStringFromObject('bank', data),
        },
        certificate: {
            certificates: certificates.map(c => ({
                certificateName: c.documentName,
                year: c.year,
                expiry: c.expiry ? getDateInYYYYMMDDFormat(new Date(c.expiry)) : '',
                certificateFile: c.attachmentName,
            })),
        },
        allContracts: sortBy(allContracts, l => new Date(l.startDate).getTime()).reverse().map(contract => ({
            uuid: getStringFromObject('uuid', contract),
            startDate: getStringFromObject('startDate', contract),
            wage: getStringFromObject('wage', contract),
            considerAttendanceInComputation:
                getStringFromObject('considerAttendanceInComputation', contract, false).toString(),
            considerOvertimeInComputation:
                getStringFromObject('considerOvertimeInComputation', contract, false).toString(),
            contract: getStringFromObject('contractType.key', contract),
            contractName: getStringFromObject('contractType.value', contract),
            structure: getStringFromObject('salaryStructure.key', contract),
            structureName: getStringFromObject('salaryStructure.value', contract),
            inputRules: getStringFromObject('ruleInputDtos', contract, []).map(l => ({
                code: getStringFromObject('inputCode.inputCode', l),
                ruleUuid: getStringFromObject('uuid', l),
                uuid: getStringFromObject('inputCode.uuid', l),
                description: getStringFromObject('inputCode.description', l),
                value: getStringFromObject('defaultValue', l),
            })).sort(sortCode),
            period: formatDateForDisplay(new Date(getStringFromObject('startDate', contract))),
        })),
        salary: {
            contract: '',
            structure: '',
            wage: null,
            startDate: getDateInYYYYMMDDFormat(new Date()),
        },
        givenName: getStringFromObject('givenName', data),
        middleName: getStringFromObject('middleName', data),
        familyName: getStringFromObject('familyName', data),
        displayName: getStringFromObject('provider.displayName', data),
        designation: getStringFromObject('provider.designation', data),
        license: getStringFromObject('provider.license', data),
        qualifications: getStringFromObject('provider.qualifications', data),
        name,
        empId: getStringFromObject('employeeId', data),
        workPhone: getStringFromObject('landlineNumber', data),
        workEmail: getStringFromObject('officialEmail', data),
        department: getStringFromObject('department', data),
        manager: getStringFromObject('reportingTo', data),
        officeLocation: '',
        position: getStringFromObject('position', data, null),
        workHoursTemplate: getStringFromObject('workHoursTemplate', data, null),
        workHoursTemplateValue: getStringFromObject('workHoursTemplate.key', data, null),
        workHoursTemplateDisplay: getStringFromObject('workHoursTemplate.value', data),
        bloodGroup: '',
        photograph: getStringFromObject('imageName', data, ''),
        joiningDate: getDateInYYYYMMDDFormat(new Date(data.joiningDate)),
    };
};

export const getContractSubData = (salary, inputRuleFieldName = 'inputCode') => ({
    ...salary,
    wage: salary.wage,
    update: true,
    considerOvertimeInComputation: salary.considerOvertimeInComputation,
    considerAttendanceInComputation: salary.considerAttendanceInComputation,
    contractType: salary.contract,
    salaryStructure: salary.structure,
    startDate: salary.startDate,
    ruleInputDtos: (getStringFromObject(inputRuleFieldName, salary, [])).map(code => ({
        defaultValue: code.value,
        uuid: code.ruleUuid,
        inputCode: {
            uuid: code.uuid,
            description: code.description,
            inputCode: code.ruleUuid,
        },
    })),
});

export const mapFormDataToPayload = (schema, values) => {
    const payload = extractDataFromSchema(schema, values);
    const {
        certificate,
    } = values;
    const certificateDtos = [];
    getStringFromObject('certificates', certificate, []).map((c) => {
        if (Object.keys(c).length !== 0) {
            certificateDtos.push({
                documentName: c.certificateName,
                year: c.year,
                attachmentName: getStringFromObject('certificateFile.key', c, c.certificateFile),
                expiry: c.expiry ? new Date(c.expiry).getTime() : null,
            });
        }
        return null;
    });
    console.log('tripledouble', payload);
    payload.certificateDtos = certificateDtos;
    // if (salary.contract) {
    //     payload.contract = getContractSubData(salary);
    // } else {
    //     payload.contract = null;
    // }
    if (isArrayValidAndNotEmpty(values.allContracts)) {
        payload.allContracts = values.allContracts.map(aContract => getContractSubData(aContract, 'inputRules'));
    }
    console.log('a0s9dua0sda9asd', payload);
    if (!payload.subCompany) {
        payload.subCompany = null;
    }
    if (!payload.bank) {
        payload.bank = null;
    }
    return {
        ...payload,
        workHoursTemplate: values.workHoursTemplate,
    };
};

export const mapDataToPayload = (values) => {
    const {
        basicInfo,
        contact,
        passport,
        leaves,
        iqama,
        insurance,
        driving,
        localContact,
        nativeContact,
        salary,
        photograph,
        certificate,
    } = values;
    console.log('sdfsdfsdfsdf', values);
    const payload = {
        employeeId: values.empId,
        givenName: values.givenName,
        middleName: values.middleName,
        familyName: values.familyName,
        department: values.department,
        reportingTo: values.manager,
        nationality: basicInfo.nationality,
        position: values.position,
        officialEmail: values.workEmail,
        landlineNumber: values.workPhone,
        workHoursTemplate: values.workHoursTemplateValue ? {
            key: values.workHoursTemplateValue,
            value: '',
        } : null,
        joiningDate: new Date(values.joiningDate).getTime(),
        userName: basicInfo.username,
        password: basicInfo.password,
        mobilePhone: contact.personalPhoneNo,
        birthday: basicInfo.dateOfBirth,
        gender: basicInfo.gender,
        marital: basicInfo.maritalStatus,
        personalEmail: contact.personalEmail,
        localAddress: contact.localAddress,
        imageName: photograph,
        nativeAddress: contact.nativeAddress,
        extension: contact.extension,
        provider: {
            displayName: values.displayName,
            designation: values.designation,
            qualifications: values.qualifications,
            license: values.license,
            licenseExpiry: values.licenseExpiry,
        },
        bank: getStringFromObject('bank.bankIFSCNumber', values) || null,
        bankAccountNumber: getStringFromObject('bank.accountNumber', values),
        localContactDetail: [
            {
                name: localContact.nameOne,
                relation: localContact.relationOne,
                number: localContact.numberOne,
                isLocal: true,
            },
            {
                name: localContact.nameTwo,
                relation: localContact.relationTwo,
                number: localContact.numberTwo,
                isLocal: true,
            },
        ],
        nativeContactDetail: [
            {
                name: nativeContact.nameOne,
                relation: nativeContact.relationOne,
                number: nativeContact.numberOne,
                isLocal: false,
            },
            {
                name: nativeContact.nameTwo,
                relation: nativeContact.relationTwo,
                number: nativeContact.numberTwo,
                isLocal: false,
            },
        ],
        employeeAttributes: [
            {
                id: 9,
                name: 'religion',
                value: basicInfo.religion,
            },
            {
                id: 1,
                name: 'visa_status',
                value: leaves.visaStatus,
            },
            {
                id: 3,
                name: 'passage_destination',
                value: leaves.passageDestination,
            },
            {
                id: 5,
                name: 'passage_type',
                value: leaves.passageType,
            },
            {
                id: 6,
                name: 'exit_re_entry',
                value: leaves.exitReEntry,
            },
            {
                id: 4,
                name: 'passage_class',
                value: leaves.passageClass,
            },

        ],
        documentDtos: [
            {
                documentName: 'passport',
                expiryDate: passport.expiryDate,
                issueDate: passport.issueDate,
                attributes: [
                    {
                        name: 'passportNo',
                        value: passport.passportNo,
                    },
                ],
                attachmentName: getFileName(passport.file),
            },
            {
                documentName: 'iqama',
                expiryDate: iqama.expiryDate,
                issueDate: iqama.issueDate,
                placeOfIssue: iqama.issuePlace,
                attributes: [
                    {
                        name: 'iqamaNo',
                        value: iqama.iqamaNo,
                    },
                    {
                        name: 'iqamaProfession',
                        value: iqama.iqamaProfession,
                    },
                ],
                attachmentName: getFileName(iqama.file),
            },
            {
                documentName: 'insurance',
                expiryDate: insurance.expiryDate,
                attributes: [
                    {
                        name: 'insuranceCardNo',
                        value: insurance.insuranceNo,
                    },
                    {
                        name: 'insuranceClass',
                        value: insurance.insuranceClass,
                    },
                ],
                attachmentName: getFileName(insurance.file),
            },
            {
                documentName: 'driving',
                expiryDate: driving.expiryDate,
                attributes: [
                    {
                        name: 'drivingLicenseNo',
                        value: driving.licenseNo,
                    },
                    {
                        name: 'drivingLicenseType',
                        value: driving.licenseType,
                    },
                ],
                attachmentName: getFileName(driving.file),
            },
        ],
    };
    const certificateDtos = [];
    getStringFromObject('certificates', certificate, []).map((c) => {
        if (Object.keys(c).length !== 0) {
            certificateDtos.push({
                documentName: c.certificateName,
                year: c.year,
                attachmentName: getStringFromObject('certificateFile.key', c, c.certificateFile),
                expiry: c.expiry ? new Date(c.expiry).getTime() : null,
            });
        }
        return null;
    });
    payload.certificateDtos = certificateDtos;
    if (salary.contract) {
        payload.contract = getContractSubData(salary);
    } else {
        payload.contract = null;
    }
    if (isArrayValidAndNotEmpty(values.allContracts)) {
        payload.allContracts = values.allContracts.map(aContract => getContractSubData(aContract, 'inputRules'));
    }
    return payload;
};

export const mapDailyAttendance = (dailyAttendance) => {
    const dailyAttendanceMap = {};
    if (isArrayValidAndNotEmpty(dailyAttendance)) {
        for (let i = 0; i < dailyAttendance.length; i += 1) {
            const aDaysAttendance = dailyAttendance[i];
            const date = getDateInYYYYMMDDFormat(new Date(aDaysAttendance.date));
            dailyAttendanceMap[date] = aDaysAttendance;
        }
    }
    return dailyAttendanceMap;
};

export const validateEmployeeProfileValues = (values, dispatch) => {
    console.log('asdj0-aa0-sjd-d', values);
    const { salary, bank } = values;
    if (isObjectValidAndNotEmpty(salary)) {
        let errMsg = '';
        if (
            salary.contract ||
            salary.structure ||
            salary.wage ||
            salary.considerAttendanceInComputation ||
            salary.considerOvertimeInComputation
        ) {
            // if these are defined, then the rest of the details must be entered
            if (!salary.contract) {
                errMsg = `${errMsg} Contract Name`;
            }
            if (!salary.structure) {
                errMsg = `${errMsg ? `${errMsg}, ` : errMsg} Salary Structure`;
            }
            if (!salary.wage) {
                errMsg = `${errMsg ? `${errMsg}, ` : errMsg} Wage`;
            }
            // if (!salary.considerAttendanceInComputation) {
            //     errMsg = `${errMsg ? `${errMsg}, ` : errMsg} Consider Attendance In Computation`;
            // }
            // if (!salary.considerOvertimeInComputation) {
            //     errMsg = `${errMsg ? `${errMsg}, ` : errMsg} Consider Overtime In Computation`;
            // }
            if (isArrayValidAndNotEmpty(salary.inputCode)) {
                salary.inputCode.forEach((anInputCode) => {
                    if (!anInputCode.value) {
                        errMsg = `${errMsg ? `${errMsg}, ` : errMsg} ${anInputCode.code}`;
                    }
                });
            }
        }
        if (errMsg) {
            dispatch(errorMessage(`The Following Fields are required: ${errMsg}`));
            return false;
        }
    }
    // if (!isObjectValidAndNotEmpty(bank.bankIFSCNumber)) {
    //     dispatch(errorMessage('Bank Name Required'));
    //     return false;
    // }
    // if (!bank.accountNumber) {
    //     dispatch(errorMessage('Bank Account Number Required'));
    //     return false;
    // }
    if (!isObjectValidAndNotEmpty(bank.bankIFSCNumber) && bank.accountNumber) {
        dispatch(errorMessage('Bank Name Required'));
        return false;
    }
    return true;
};

export const renderRequiredLabel = (label, isDisabled) => (!isDisabled ? getRequiredFieldLabel(label) : label);
