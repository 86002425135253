import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import messages from '../../constants/messages';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    FETCH_APPOINTMENT_DETAILS_FAILURE,
    SELECT_APPOINTMENT_DETAILS_SUCCESS,
} from '../../redux/modules/appointmentDetails/appointmentDetails-actions';

export default function* getAppointmentDetailsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: SELECT_APPOINTMENT_DETAILS_SUCCESS,
            appointmentDetails: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const message = getErrorMessage(e);
            yield put({ type: ERROR_MESSAGE, message: `${messages.APPOINTMENT_DETAILS_FETCH_FAILED}: ${message}` });
        }
        yield put({ type: FETCH_APPOINTMENT_DETAILS_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
