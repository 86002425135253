import React from 'react';
import Favorites from '@material-ui/icons/Favorite';
import ShortText from '@material-ui/icons/ShortText';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import Settings from '@material-ui/icons/Settings';
import AccountBoxRounded from '@material-ui/icons/AccountBoxRounded';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';

export const userSettingsTabs = {
    CLINICAL_FAVORITES: {
        value: 'CLINICAL_FAVORITES',
        label: 'Clinical Favourites',
        icon: <Favorites test-id="favorites" />,
        privileges: ['emr.consultation'],
    },
    TEMPLATES: {
        value: 'TEMPLATES',
        label: 'Templates',
        icon: <FormatAlignJustify test-id="template" />,
        privileges: ['emr.consultation'],
    },
    AUTO_TEXT: {
        value: 'AUTO_TEXT',
        label: 'Auto Text',
        icon: <ShortText test-id="short-text" />,
        privileges: ['emr.consultation'],
    },
    ON_BEHALF: {
        value: 'ON_BEHALF',
        label: 'On Behalf',
        icon: <TransferWithinAStation test-id="on-behalf" />,
        privileges: ['emr.consultation'],
    },
    OTHER_SETTINGS: {
        value: 'OTHER_SETTINGS',
        label: 'Other Settings',
        icon: <Settings data-testid="other_settings" />,
        privileges: [],
    },
    ACCOUNTS: {
        value: 'ACCOUNTS',
        label: 'ACCOUNTS',
        icon: <AccountBoxRounded testid="accpunts" />,
        privileges: [],
    },
};

export const getInitialTabBasedOnUserPrivileges = (tabs = userSettingsTabs) => {
    const initialTab = Object.keys(tabs)
        .filter(aTab => checkIfPrivilegeExistsForUser(userSettingsTabs[aTab].privileges))[0];
    return userSettingsTabs[initialTab].value;
};
