import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { isArrayValidAndNotEmpty, isValidFunction } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const ReactToggle = (props) => {
    console.log('toggleprops', props);
    const { field, disabled, form } = props;
    const rowName = getStringFromObject('name', field).split('.')[0];
    const rowValue = getStringFromObject(`values.${rowName}`, form);
    const hideToggle = getStringFromObject('hideToggle', rowValue, false);
    if (hideToggle) {
        return '';
    }

    const onChangeHandler = (value) => {
        const {
            field: { name }, onChangeHandlers, actionHandlers,
        } = props;
        console.log('as-d0ai-da0dsa[psdkasdak', onChangeHandlers);
        if (isArrayValidAndNotEmpty(onChangeHandlers) && isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, name, form);
                }
            });
        }
    };

    const handleChange = (e) => {
        const { field: { onChange } } = props;
        onChange(e);
        onChangeHandler(e.target.checked);
    };
    return (
        <Toggle
            checked={field.value}
            name={field.name}
            disabled={disabled}
            onChange={handleChange}
        />
    );
};

ReactToggle.propTypes = {
    field: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
};

export default ReactToggle;
