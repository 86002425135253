import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_ACCOUNT_VOUCHER_REQUEST,
} from '../../redux/modules/accountVoucher/accountVoucher-actions';
import fetchDebitAndCreditLinesWorker from './accountVoucherWorkerSaga';

function* fetchAccountVouchersWatcher() {
    yield takeLatest(FETCH_ACCOUNT_VOUCHER_REQUEST, fetchDebitAndCreditLinesWorker);
}

export default fetchAccountVouchersWatcher;
