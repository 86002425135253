import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import {
    bookManagement,
    bookManagementAccount,
    bookManagementAging,
    bookManagementAnalyticalAccount,
    bookManagementBankReconciliation,
    bookManagementCardReconciliation,
    bookManagementDebitAndCreditMemo,
    bookManagementJournalEntries,
    bookManagementPayerCollection,
    bookManagementPaymentVoucher,
    pathPrivileges,
    payorBundlesDetailsBooks,
} from '../constants/constants';
import BookManagementContainer from '../containers/BookManagementContainer/BookManagementContainer';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { getStringFromObject } from '../constants/lodashUtils';
import PageLoader from '../components/PageLoader/PageLoader';

const BookManagementAccountPage = React.lazy(() => import('../pages/BookManagementPages/BookManagementAccountPage'));
const BookManagementAgingPage = React.lazy(() => import('../pages/BookManagementPages/BookManagementAgingPage'));
const BookManagementJournalEntryPage = React.lazy(() => import('../pages/BookManagementPages/BookManagementJournalEntryPage'));
const AnalyticalAccountPage = React.lazy(() => import('../pages/BookManagementPages/AnalyticalAccountPage'));
const PayerCollectionPage = React.lazy(() => import('../pages/BookManagementPages/PayerCollectionPage'));
const ReconciliationPage = React.lazy(() => import('../pages/BookManagementPages/ReconciliationPage'));
const ClaimsBundlesDetailsPage = React.lazy(() => import('../pages/Claims/ClaimsBundlesDetailsPage'));
const PaymentVoucherPage = React.lazy(() => import('../pages/BookManagementPages/PaymentVoucherPage'));
const DebitAndCreditMemoPage = React.lazy(() => import('../pages/BookManagementPages/DebitAndCreditMemoPage'));

const getDefaultRoute = (props) => {
    const route = getStringFromObject('history.location.pathname', props);
    if (route === bookManagement) {
        if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementJournalEntries}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagement} exact component={BookManagementJournalEntryPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementAccount}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagement} exact component={BookManagementAccountPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementAging}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagement} exact component={BookManagementAgingPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementAnalyticalAccount}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagement} exact component={AnalyticalAccountPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementPayerCollection}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagementPayerCollection} exact component={PayerCollectionPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementBankReconciliation}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagementBankReconciliation} exact component={ReconciliationPage} />;
        } else if (checkIfPrivilegeExistsForUser(
            getStringFromObject(`${bookManagementCardReconciliation}.privileges`, pathPrivileges),
        )) {
            return <Route path={bookManagementCardReconciliation} exact component={ReconciliationPage} />;
        }
    }
    return '';
};

const BookManagementRoutes = props => (
    <BookManagementContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            {getDefaultRoute(props)}
            <Route path={bookManagementAccount} exact component={BookManagementAccountPage} />
            <Route path={bookManagementAnalyticalAccount} exact component={AnalyticalAccountPage} />
            <Route path={bookManagementAging} exact component={BookManagementAgingPage} />
            <Route path={bookManagementJournalEntries} exact component={BookManagementJournalEntryPage} />
            <Route path={bookManagementPayerCollection} exact component={PayerCollectionPage} />
            <Route path={`${payorBundlesDetailsBooks}/:uuid`} exact component={ClaimsBundlesDetailsPage} />
            <Route path={bookManagementBankReconciliation} exact component={ReconciliationPage} />
            <Route path={bookManagementCardReconciliation} exact component={ReconciliationPage} />
            <Route path={bookManagementPaymentVoucher} exact component={PaymentVoucherPage} />
            <Route path={bookManagementDebitAndCreditMemo} exact component={DebitAndCreditMemoPage} />
        </Suspense>
    </BookManagementContainer>
);

BookManagementRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

BookManagementRoutes.defaultProps = {
    history: {},
};

export default BookManagementRoutes;
