/* eslint-disable */
import axios from 'axios';
import { initialAuthState, persistState } from '../constants/state';

export const retriable = () => {
    const ret = axios.create();
    ret.interceptors.request.use(
        config => {
            console.log('going refresh', config);
            return config;
        },
        error => Promise.reject(error)
    );
    ret.interceptors.response.use(response => {
        let errorCode = 0;
        if (response.data.error) {
            errorCode = response.data.error.code;
        }
        console.log('Errorcode ', errorCode);
        console.log('Errasdq21323eqcode ', response.config);
        if (errorCode === 401) {
            return axios.request(response.config);
        }
        if (errorCode === 300) {
            persistState(initialAuthState);
            window.location('/');
            return Promise.reject(response);
        }
        return Promise.resolve(response);
    }, undefined);
    return ret;
};

export default retriable;
