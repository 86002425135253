import initialStates from '../../initialStates';
import {
    CLEAR_JOURNAL_ENTRIES,
    FETCH_JOURNAL_ENTRIES_LIST_FAILURE,
    FETCH_JOURNAL_ENTRIES_LIST_REQUEST,
    FETCH_JOURNAL_ENTRIES_LIST_SUCCESS,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST,
    PERSIST_JOURNAL_ENTRIES_FILTER,
} from './journalEntries-actions';

const journalEntriesReducer = (state = initialStates.journalEntries, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_JOURNAL_ENTRIES_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_JOURNAL_ENTRIES_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_JOURNAL_ENTRIES_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS:
        newState = {
            list: action.data.content,
            first: action.data.first,
            last: action.data.last,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            page: action.data.page,
            size: action.data.size,
            filters: action.filters,
        };
        break;
    case FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case PERSIST_JOURNAL_ENTRIES_FILTER:
        newState = Object.assign({}, state, { filters: action.filters });
        break;
    case CLEAR_JOURNAL_ENTRIES:
        newState = initialStates.journalEntries;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default journalEntriesReducer;
