/**
 * Created by vinay on 24/6/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, initialize, reduxForm } from 'redux-form';
import { required } from '../../constants/FormValidations';
import Popper from '../../components/Popper';
import ActionButton from '../../components/ActionButton/ActionButton';
import ReduxFormTextField from '../../components/FormFieldComponents/ReduxFormTextField/ReduxFormTextField';

import { getStringFromObject } from '../../constants/lodashUtils';

const formName = 'FavouriteReportTitlePopOverForm';

const style = () => ({
    paper: {
        maxWidth: '600px',
        width: '600px',
        overflow: 'visible',
    },
});

class FavouriteReportTitlePopOver extends React.PureComponent {
    componentDidMount() {
        const { dispatch, report } = this.props;
        dispatch(initialize(formName, {
            title: getStringFromObject('title', report),
            desc: getStringFromObject('desc', report),
        }));
    }

    render() {
        const {
            anchorEl,
            handleClose,
            handleSubmit,
            onClickSubmit,
            classes,
        } = this.props;
        return (
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="top"
                classes={classes}
            >
                <div className="p1">
                    <DialogTitle>Save as favourite</DialogTitle>
                    <DialogContent>
                        <form>
                            <Grid container className="mt-0-5">
                                <Field
                                    testId="title"
                                    name="title"
                                    component={ReduxFormTextField}
                                    label="Title"
                                    validate={[required]}
                                    required
                                />
                            </Grid>
                            <Grid container className="mt-0-5">
                                <Field
                                    testId="desc"
                                    name="desc"
                                    component={ReduxFormTextField}
                                    label="Description"
                                    rows={2}
                                    rowsMax={3}
                                />
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton
                            testId="cancel-favaourite"
                            primary={false}
                            disableRipple
                            onClick={handleClose}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            testId="save-favaourite"
                            className="ml-1"
                            disableRipple
                            onClick={handleSubmit(onClickSubmit)}
                        >
                            Save
                        </ActionButton>
                    </DialogActions>
                </div>
            </Popper>
        );
    }
}

FavouriteReportTitlePopOver.propTypes = {
    classes: PropTypes.object.isRequired,
    anchorEl: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    report: PropTypes.object,
};

FavouriteReportTitlePopOver.defaultProps = {
    report: {},
};

export default reduxForm({
    form: formName,
})(withStyles(style)(FavouriteReportTitlePopOver));
