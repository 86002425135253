import API from '../../../constants/api';

export const CREATE_STOCK_INTENT_REQUEST = '@@stockIntent/CREATE_STOCK_INTENT_REQUEST';
export const CREATE_STOCK_INTENT_FAILURE = '@@stockIntent/CREATE_STOCK_INTENT_FAILURE';
export const CREATE_STOCK_INTENT_SUCCESS = '@@stockIntent/CREATE_STOCK_INTENT_SUCCESS';

export const FETCH_STOCK_INTENT_REQUEST = '@@stockIntent/FETCH_STOCK_INTENT_REQUEST';
export const FETCH_STOCK_INTENT_FAILURE = '@@stockIntent/FETCH_STOCK_INTENT_FAILURE';
export const FETCH_STOCK_INTENT_SUCCESS = '@@stockIntent/FETCH_STOCK_INTENT_SUCCESS';

export const CLEAR_SELECTED_STOCK_INTENT = '@@stockIntent/CLEAR_SELECTED_STOCK_INTENT';

export const MARK_STOCK_INTENT_AS_COMPLETE = '@@stockIntent/MARK_STOCK_INTENT_AS_COMPLETE';

export const createStockIntentRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_STOCK_INTENT_REQUEST,
    payload,
    api: API.STOCK_INTENT.CREATE,
    successCallback,
    failureCallBack,
});

export const fetchStockIntentRequest = (stockIntentApi, successCallback, failureCallback) => ({
    type: FETCH_STOCK_INTENT_REQUEST,
    api: stockIntentApi,
    successCallback,
    failureCallback,
});

export const clearStockIntentSelectState = () => ({
    type: CLEAR_SELECTED_STOCK_INTENT,
});

export const markAsComplete = (api, successCallback, failureCallBack) => ({
    type: MARK_STOCK_INTENT_AS_COMPLETE,
    api,
    successCallback,
    failureCallBack,
});
