import { takeLatest } from 'redux-saga/effects';
import { fetchAppointmentsListWorker } from './appointmentListWorkerSaga';
import { FETCH_APPOINTMENTS_REQUEST } from '../../redux/modules/appointmentsList/appointmentsList-actions';
import { commonDeleteWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';
import {
    CREATE_RECALL_REQUEST,
    DELETE_RECALL_REQUEST,
    FETCH_RECALL_LIST_REQUEST,
} from '../../redux/modules/recallList/recallList-actions';

export function* fetchAppointmentsListWatcher() {
    yield takeLatest(FETCH_APPOINTMENTS_REQUEST, fetchAppointmentsListWorker);
}

export function* fetchRecallListWatcher() {
    yield takeLatest(FETCH_RECALL_LIST_REQUEST, commonPostWorkerSaga);
}

export function* createRecallWatcher() {
    yield takeLatest(CREATE_RECALL_REQUEST, commonPostWorkerSaga);
}

export function* deleteRecallWatcher() {
    yield takeLatest(DELETE_RECALL_REQUEST, commonDeleteWorkerSaga);
}
