import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_RECEIVE_PAYMENT_REQUEST,
    FETCH_RECEIVE_PAYMENT_REQUEST,
} from '../../redux/modules/receivePayment/receivePayment-action';
import { createReceivePaymentWorker, fetchReceivePaymentWorker } from './receivePaymentWorkerSaga';

export function* createReceivePaymentWatcher() {
    yield takeLatest(CREATE_RECEIVE_PAYMENT_REQUEST, createReceivePaymentWorker);
}

export function* fetchReceivePaymentWatcher() {
    yield takeLatest(FETCH_RECEIVE_PAYMENT_REQUEST, fetchReceivePaymentWorker);
}
