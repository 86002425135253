import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
});

class FormikDisabledCheckbox extends React.PureComponent {
    handleChange = (event) => {
        const {
            field: { name },
            form,
            onChangeHandlers,
            actionHandlers,
        } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        if (isValidFunction(setFieldValue)) {
            setFieldValue(name, event.target.checked);
        }
        if (isArrayValidAndNotEmpty(onChangeHandlers) && name && isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(event.target.checked, name, form);
                }
            });
        }
    };

    render() {
        const {
            classes,
            label,
            containerStyle,
            field: {
                name,
                value,
            },
            formControlProps,
        } = this.props;
        console.log('asd0aud0su9da', value);
        return (
            <div style={containerStyle}>
                <div className={classes.fieldDiv}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                key={`${name}-${value}`}
                                checked={value}
                                onChange={this.handleChange}
                                value={name}
                                disabled
                                color="primary"
                            />
                        }
                        {...formControlProps}
                        label={label}
                    />
                </div>
            </div>
        );
    }
}

FormikDisabledCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    containerStyle: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    formControlProps: PropTypes.object,
    /* formik props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

FormikDisabledCheckbox.defaultProps = {
    disabled: true,
    containerStyle: {},
    actionHandlers: {},
    formControlProps: {},
    onChangeHandlers: [],
    label: '',
};

export default withStyles(styles)(FormikDisabledCheckbox);
