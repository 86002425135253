import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDateInDDMMMYYYYFormat, isDateLessThanOrEqual, isDateString } from './DateUtil';


/* eslint-disable*/
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export const isEmpty = value =>
    typeof value === 'undefined' || value === null || value === '';

export const required = (value, _, props) => {
    const errorMessage = (
        <FormattedMessage
            id='form.requiredField'
            defaultMessage="Field is Required"
        />
    );
    return isEmpty(value) ? errorMessage : undefined;
};

export const minLength = (min, message) => (value, _, props) =>
    !isEmpty(value) && value.length < min
        ? `Must be more than ${min} characters`
        : undefined;

export const alphanNumeric =  (value, _, props) =>
        !isEmpty(value) && !/^[a-zA-Z0-9]*$/.test(value.trim())?
        'Only alphanumeric characters are accepted'
        : undefined;

export const exactLength = (exact) => (value, _, props) =>
    !isEmpty(value) && value.length !== exact
        ? `Must be exactly ${exact} characters`
        : undefined;

export const vatNumber = (value, _, props) =>
    !isEmpty(value) && !/3\d{13}3/i.test(value)
        ? `Must be exactly 15 digits and start and ends with "3"`
        : undefined;

export const bahrainVatNumber = (value, _, props) =>
    !isEmpty(value) && !/2\d{13}2/i.test(value)
        ? `Must be exactly 15 digits and start and ends with "2"`
        : undefined;

export const tinNumber = (value, _, props) =>
    !isEmpty(value) && !/3\d{9}/i.test(value)
        ? `Must be exactly 10 digits and start with "3"`
        : undefined;

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minValue = min => (value, _, props) =>
    !isEmpty(value) && value < min
        ? `Value Must Be Atleast ${min}`
        : undefined;

export const phoneNumber = value =>
    value && !/^(0|[0-9]{10}|[0-9]{11}|[0-9]{12})$/i.test(value)
        ? (
            <FormattedMessage
                id='form.invalidMobile'
                defaultMessage="Invalid phone number, must be between 10 and 12 digits"
            />
        )
        : undefined;

export const landlineNumber = value =>
    value && !/^(0|[0-9]{5}|[0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12})$/i.test(value)
        ? (
            <FormattedMessage
                id='form.invalidLandLine'
                defaultMessage="Invalid landline number, must be atleast 5 numbers"
            />
        )
        : undefined;

export const maxValue = (max, message) => (value, _, props) => {
    console.log('asdas0-das-dadadasd', value, max);
    return (
        !isEmpty(value) && value > max
            ? `Value Must Be Less Than or Equal To ${max}`
            : undefined
    );
};

export const valueLessThanField = (max, message) => (value, _, props) => {
    console.log('asdas0-das-dadadasd', value, max);
    return (
        !isEmpty(value) && Number(value) > Number(max)
            ? `Value Must Be Less Than ${max}`
            : undefined
    );
};

export const valueLessThanOrEqualToField = (max, message) => (value, _, props) => {
    console.log('asdas0-das-dadadasd', value, max);
    return (
        !isEmpty(value) && Number(value) > Number(max)
            ? `Must Not Be Greater Than ${max}`
            : undefined
    );
};

export const valueGreatThanOrEqualToField = (min, message) => (value, _, props) => {
    console.log('jskslkmd;sdlsd', value, min);
    return (
        !isEmpty(value) && Number(value) < Number(min)
            ? `Must Not Be Lesser Than ${min}`
            : undefined
    );
}

export const requiredOnlyIfNotEmpty = (field, message) => (value, _, props) => {
    const errorMessage = (
        <FormattedMessage
            id='form.requiredField'
            defaultMessage="Field is Required"
        />
    );
    return !isEmpty(field) && isEmpty(value) ? errorMessage : undefined;
};

export const dateShouldGreaterThan = (d) => (value, _, props) => {
    const valueDate = new Date(value);
    const fromDate = new Date(d);
    console.log('sdfsdfsdfdsfgraetther', fromDate, valueDate, value);
    if (fromDate.getTime() > valueDate.getTime()) {
        return `Value must be greater than ${getDateInDDMMMYYYYFormat(fromDate)}`;
    }
    return undefined;
};

export const number = (value, _, props) =>
    !isEmpty(value) && !/^\d+$/.test(value)
        ? 'Only Digits Are Allowed'
        : undefined;

export const numberValidation = (value, _, props) =>
    !isEmpty(value) && Number.isNaN(Number(value))
        ? 'Only Numbers Are Allowed'
        : undefined;

export const regex = (pattern) => (value, _, props) =>
    !isEmpty(value) && typeof value === 'string' && !pattern.test(value)
        ? (
            <FormattedMessage
                id='form.invalidEmail'
                defaultMessage="Invalid Email"
            />
        )
        : undefined;

export const email = regex(EMAIL_REGEX, 'aor.validation.email');

export const dateOfBirth = (value) => {
    let dateObject = new Date(value);
    if (dateObject.getFullYear() < 1900) {
        return (
            <FormattedMessage
                id='form.yearLessThan1900'
                defaultMessage="Birthday Cannot Before 120 Years from now"
            />
        );
    }
    if (!isDateLessThanOrEqual(dateObject, new Date())) {
        return (
            <FormattedMessage
                id='form.birthdayInFuture'
                defaultMessage="Birthday Cannot Be After Today"
            />
        );
    }
    return undefined;
};

export const choices = (list, message) => (value, _, props) =>
    !isEmpty(value) && list.indexOf(value) === -1
        ? props.translate(message, { _: message })
        : undefined;

export const dateNotLessThanToday = (value) => {
    if (value) {
        const date = new Date(value);
        date.setHours(0,0,0,0);
        const todayDate = new Date();
        todayDate.setHours(0,0,0,0);
        if (date < todayDate) {
            return "Date Cannot Be Before Today";
        }
    }
    return undefined;
};

export const dateNotMoreThanToday = (value) => {
    if (value) {
        const date = new Date(value);
        date.setHours(0,0,0,0);
        const todayDate = new Date();
        todayDate.setHours(0,0,0,0);
        if (date > todayDate) {
            return "Date can not be in future";
        }
    }
    return undefined;
};

export const dateBetweenRange = (start, end) => (d) => {
    let value = d;
    if (isDateString(d)) {
        value = new Date(d);
    }
    if ((value.getTime() > start.getTime()) && (value.getTime() < end.getTime())) {
        return undefined;
    }
    console.log('asdfsdfsdf', value, start, end, value.getTime() > start.getTime());
    return 'Date Should Be Range Between Selected Date';
};

export const maxLength20 = value =>
    value && value.length > 20
        ? `Value Must Be Less Than 20 Character`
        : undefined;

export const maxLength250 = value =>
    value && value.length > 250
        ? `Value Must Be Less Than 250 Character`
        : undefined;

const isValidDate = d => d instanceof Date && !Number.isNaN(d);



export const upperCaseNormalizer = value => value && value.toUpperCase();

export const maxLengthLimiter = limit => (value) => {
    console.log('asdaj-0aid-0das', limit, value);
    return value && value.substr(0, limit);
};

export const minNumberValue = min => (value) => {
    let validationError = numberValidation(value)
    if (!validationError) {
        validationError = minValue(min)(value);
    }
    return validationError;
}

export const percentage = (value) => {
    let validationError = numberValidation(value)
    if (!validationError) {
        validationError = minValue(0)(value);
    }
    if (!validationError) {
        validationError = maxValue(100)(value);
    }
    return validationError;
}

export const lowerCaseNormalizer = value => value && value.toLowerCase();

export const phoneNumberValidators = [
    minLength(8),
    maxLength(14),
];

export const timeIn12HourSystemWithAmPm = (value) => {
    var regex = /^([0-1][0-9])\:[0-5][0-9]\s*[ap]m$/i;
    alert("somethnig");
    var match = time.match( regex );
    if ( match ) {
        return undefined;
    }
    const  errorMessage=(<FormattedMessage
        id='form.time'
        defaultMessage="invalid time"
    />)

    return errorMessage;
}
