import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const shouldHide = (cell, rowValue) => {
    if (cell.hideIf) {
        console.log('asd-asud0asuda0sds', cell, rowValue);
        const { hideIf } = cell;
        if (hideIf && hideIf.lhsKey && hideIf.rhsKey) {
            const lhs = getStringFromObject(hideIf.lhsKey, rowValue, null);
            const rhs = getStringFromObject(hideIf.rhsKey, rowValue, null);
            return lhs === rhs;
        } else if (hideIf && hideIf.key) {
            return getStringFromObject(hideIf.key, rowValue) === hideIf.value;
        }
    }
    return false;
};
export const shouldShow = (cell, rowValue) => {
    if (cell.showIf) {
        console.log('asd-asud0asuda0sds', cell, rowValue);
        const { showIf } = cell;
        if (isArrayValidAndNotEmpty(showIf)) {
            const show = showIf.some((e) => {
                const lhs = getStringFromObject(e.key, rowValue);
                const rhs = e.value;
                return lhs === rhs;
            });
            return show;
        }
    }
    return true;
};

export const getAvatarColor = (colorSchema, fieldData, colorSchemaSpecialCase, rowData) => {
    let bgColor = getStringFromObject(fieldData, colorSchema) || undefined;
    if (isArrayValidAndNotEmpty(colorSchemaSpecialCase)) {
        for (let i = 0; i < colorSchemaSpecialCase.length; i += 1) {
            const aSpecialCase = colorSchemaSpecialCase[i];
            const fieldValue = getStringFromObject(aSpecialCase.find, rowData);
            const color = getStringFromObject(fieldValue, aSpecialCase.colorSchema);
            if (color) {
                bgColor = color;
                break;
            }
        }
    }
    return bgColor;
};

export default shouldHide;
