import React from 'react';
import PropTypes from 'prop-types';
import { profile } from '../../constants/constants';
import OrganizationBoxView from '../../components/OrganisationComponent/OrganisationBoxView';

class OrganizationTreeContainer extends React.Component {
    handleProfileClick = () => {
        const { history } = this.props;
        history.push(profile);
    };

    render() {
        return (
            <div>
                <OrganizationBoxView
                    handleProfileClick={this.handleProfileClick}
                />
            </div>
        );
    }
}

OrganizationTreeContainer.propTypes = {
    history: PropTypes.object.isRequired,
};

export default OrganizationTreeContainer;
