import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import '../SearchField/SearchField.css';
import { retriable } from '../../constants';
/*

const suggestions = [
    { key: 'Allergy 1', value: 'Allergy 1' },
    { key: 'Allergy 2', value: 'Allergy 2' },
    { key: 'Allergy 3', value: 'Allergy 3' },
    { key: 'Allergy 4', value: 'Allergy 4' },
    { key: 'Allergy 5', value: 'Allergy 5' },
    { key: 'Allergy 6', value: 'Allergy 6' },
    { key: 'Allergy 7', value: 'Allergy 7' },
    { key: 'Allergy 8', value: 'Allergy 8' },
    { key: 'Allergy 9', value: 'Allergy 9' },
    { key: 'Allergy 10', value: 'Allergy 10' },
];
*/

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
        width: '100%',
    },
    root: {
        width: '100%',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 3,
        left: 0,
        right: 0,
        zIndex: 9999,
        maxHeight: '20em',
        overflowY: 'auto',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    inputProps: {
        width: '90%',
        disableUnderline: true,
    },
    textField: {
        width: '100%',
    },
    iconButton: {},
});

class AutosuggestSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data || '',
            // TODO Remove props.suggestions  this is only for testing
            suggestions: props.suggestions || [],
            count: 0,
        };
    }

    onSuggestionSelected = (e, suggestion) => {
        if (this.props.clearSelectedValue) {
            this.setState({
                value: '',
            });
        }
        this.props.onSelectSuggestion(suggestion);
    };

    onFocus = event => event.target.select();

    onSearch = (searchText) => {
        if (this.props.callType === 'POST') {
            if (searchText && this.props.dataSourceApi) {
                const searchParams = this.props.getSearchParams(searchText);
                retriable()
                    .post(
                        this.props.dataSourceApi,
                        searchParams,
                    )
                    .then((response) => {
                        console.log('dsjhdsbvkdsf', response);
                        const result = response.data.result || response.data.content;
                        this.setState({
                            suggestions: this.props.searchResultMapper(
                                result,
                            ),
                        });
                    });
            }
        } else {
            retriable()
                .get(this.props.takeApiForGet(searchText))
                .then((response) => {
                    console.log('123654789akkakaaa', response);
                    this.setState({
                        suggestions: this.props.searchResultMapper(
                            response.data,
                        ),
                    });
                });
        }
    };

    setTimer = (value) => {
        setTimeout(() => {
            this.onSearch(value);
        }, 1000);
    };

    getSuggestionValue = suggestion => this.props.getSuggestionValue(suggestion);

    handleSuggestionsFetchRequested = ({ value }) => {
        console.log('akfjihurh', value);
        this.setTimer(value);
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = (event, { newValue }) => {
        console.log('asdfkvjdibngj', newValue);
        this.setState({
            value: newValue,
        });
    };

    keyPressHandler = (e) => {
        if (this.props.allowFreeText) {
            if ((e.key === 'Enter') && this.props.clearSelectedValue) {
                this.setState({
                    value: '',
                });
            }
            this.props.keyPressHandler(e);
        }
    };

    renderInputWithFormControl = (props, classes, error) => {
        const { isFormComponent } = this.props;
        if (isFormComponent) {
            return (
                <FormControl
                    error={!!error}
                    aria-describedby="component-error-text"
                    classes={{
                        root: classes.root,
                    }}
                >
                    {this.renderInput(props)}
                    {
                        error &&
                        <FormHelperText id="component-error-text">{error}</FormHelperText>
                    }
                </FormControl>
            );
        }
        return this.renderInput(props);
    };

    renderInput = (inputProps) => {
        const {
            classes, value, ref, disableUnderline, ...other
        } = inputProps;
        console.log('PropsFilled', inputProps, classes);
        return (
            this.props.showSearchIcon ?
                <Input
                    id="searchBar"
                    type="text"
                    inputRef={ref}
                    value={value}
                    disabled={inputProps.disabled}
                    placeholder={inputProps.placeholder}
                    fullWidth={this.props.fullWidth}
                    inputProps={{
                        ...other,
                    }}
                    onKeyPress={e => this.keyPressHandler(e)}
                    classes={{
                        input: classes.inputProps,
                        underline: classes.underline,
                    }}
                    disableUnderline={disableUnderline}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton classes={{ root: classes.iconButton }}>
                                {this.props.ArrowDropDown ?
                                    <ArrowDropDown style={{ cursor: 'pointer' }} />
                                    :
                                    <SearchIcon style={{ cursor: 'pointer' }} />
                                }
                            </IconButton>
                        </InputAdornment>
                    }
                /> :
                <Input
                    id="searchBar"
                    type="text"
                    inputRef={ref}
                    fullWidth={this.props.fullWidth}
                    disabled={inputProps.disabled}
                    value={value}
                    placeholder={inputProps.placeholder}
                    inputProps={{
                        ...other,
                    }}
                    onKeyPress={e => this.keyPressHandler(e)}
                    classes={{
                        input: classes.inputProps,
                        underline: classes.underline,
                    }}
                    disableUnderline={disableUnderline}
                />
        );
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const suggestionText = this.getSuggestionValue(suggestion);
        const matches = match(suggestionText, query);
        const parts = parse(suggestionText, matches);
        console.log('aksjfhbsd', suggestionText, suggestion);
        const style = {
            whiteSpace: 'normal',
            minHeight: '24px',
            height: 'inherit',
            wordWrap: 'break-word',
        };

        if (this.props.shouldRenderCustomSuggestion) {
            return this.props.renderCustomSuggestionComponent(isHighlighted, parts, suggestion);
        }

        return (
            <MenuItem selected={isHighlighted} component="div" style={style}>
                <div style={{ ...style, width: '100%' }}>
                    {parts.map((part, index) => (part.highlight ? (
                        <span
                            key={String(index)}
                            style={{ fontWeight: 300 }}
                        >
                            {part.text}
                        </span>
                    ) : (
                        <strong
                            key={String(index)}
                            style={{ fontWeight: 500 }}
                        >
                            {part.text}
                        </strong>
                    )))}
                </div>
            </MenuItem>
        );
    };

    renderSuggestionsContainer = (options) => {
        const { containerProps, children } = options;

        return (
            <Paper {...containerProps} square>
                {children}
            </Paper>
        );
    };

    render() {
        const { classes, error } = this.props;
        const { count } = this.state.count;
        console.log('count', count, this.props.shouldRenderCustomSuggestion);
        return (
            <Autosuggest
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                    root: classes.root,
                }}
                fullWidth={this.props.fullWidth}
                renderInputComponent={props => this.renderInputWithFormControl(props, classes, error)}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={
                    this.handleSuggestionsFetchRequested
                }
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsClearRequested={
                    this.handleSuggestionsClearRequested
                }
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                focusInputOnSuggestionClick={this.props.focusInputOnSuggestionClick}
                inputProps={{
                    autoFocus: this.props.autoFocus,
                    disableUnderline: this.props.disableUnderline,
                    classes,
                    disabled: this.props.disabled,
                    placeholder: this.props.placeholder,
                    value: this.state.value,
                    onChange: this.handleChange,
                    onFocus: this.onFocus,
                }}
            />
        );
    }
}

AutosuggestSearch.propTypes = {
    classes: PropTypes.object,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    allowFreeText: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    showSearchIcon: PropTypes.bool,
    onSuggestionSelected: PropTypes.func,
    ArrowDropDown: PropTypes.bool,
    data: PropTypes.string,
    dataSourceApi: PropTypes.string,
    label: PropTypes.string,
    dataSourceConfig: PropTypes.object,
    addCreateOptionInEnd: PropTypes.bool,
    disabled: PropTypes.bool,
    onSelectSuggestion: PropTypes.func,
    getSearchParams: PropTypes.func,
    searchResultMapper: PropTypes.func,
    focusInputOnSuggestionClick: PropTypes.bool,
    inputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    getSuggestionValue: PropTypes.func,
    takeApiForGet: PropTypes.func,
    callType: PropTypes.string,
    shouldRenderCustomSuggestion: PropTypes.bool,
    renderCustomSuggestionComponent: PropTypes.func,
    error: PropTypes.string,
    clearSelectedValue: PropTypes.bool,
    keyPressHandler: PropTypes.func,
    isFormComponent: PropTypes.bool,
    suggestions: PropTypes.array,
};

AutosuggestSearch.defaultProps = {
    classes: {},
    placeholder: '',
    fullWidth: false,
    allowFreeText: false,
    autoFocus: false,
    disableUnderline: true,
    showSearchIcon: true,
    onSuggestionSelected: () => {},
    ArrowDropDown: false,
    data: '',
    dataSourceApi: '',
    label: '',
    dataSourceConfig: {},
    inputLabelProps: {},
    onSelectSuggestion: () => {},
    getSearchParams: () => {},
    searchResultMapper: () => {},
    getSuggestionValue: () => {},
    takeApiForGet: () => {},
    disabled: false,
    addCreateOptionInEnd: false,
    focusInputOnSuggestionClick: false,
    InputProps: {},
    callType: 'POST',
    shouldRenderCustomSuggestion: false,
    renderCustomSuggestionComponent: () => {},
    error: '',
    clearSelectedValue: false,
    keyPressHandler: () => {},
    isFormComponent: false,
    suggestions: [],
};

export default withStyles(styles)(AutosuggestSearch);
