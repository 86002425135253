/**
 * Created by sachinprakash on 12/8/19.
 */
import React, { Suspense } from 'react';
import { Route } from 'react-router';
import DentalSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { dentalSettings, dentalTreatments } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const ConditionsPage = React.lazy(() => import('../components/DentalSettingComponents/DentalSettingConditionPage'));
const TreatmentsPage = React.lazy(() => import('../components/DentalSettingComponents/DentalSettingTreatmentPage'));

const DentalSettingRoutes = props => (
    <DentalSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={dentalSettings} exact component={ConditionsPage} />
            <Route path={dentalTreatments} exact component={TreatmentsPage} />
        </Suspense>
    </DentalSettingContainer>
);

export default DentalSettingRoutes;
