/**
 * Created by vinay on 19/12/18.
 */
import API from '../../../constants/api';
import MESSAGES from '../../../constants/messages';

export const FETCH_RECALL_LIST_REQUEST = '@@recall/FETCH_RECALL_LIST_REQUEST';
export const FETCH_RECALL_LIST_FAILURE = '@@recall/FETCH_RECALL_LIST_FAILURE';
export const FETCH_RECALL_LIST_SUCCESS = '@@recall/FETCH_RECALL_LIST_SUCCESS';

export const CREATE_RECALL_REQUEST = '@@recall/CREATE_RECALL_REQUEST';
export const CREATE_RECALL_REQUEST_FAILURE = '@@recall/CREATE_RECALL_REQUEST_FAILURE';
export const CREATE_RECALL_REQUEST_SUCCESS = '@@recall/CREATE_RECALL_REQUEST_SUCCESS';

export const DELETE_RECALL_REQUEST = '@@recall/DELETE_RECALL_REQUEST';
export const DELETE_RECALL_REQUEST_FAILURE = '@@recall/DELETE_RECALL_REQUEST_FAILURE';
export const DELETE_RECALL_REQUEST_SUCCESS = '@@recall/DELETE_RECALL_REQUEST_SUCCESS';

export const fetchRecallListRequest = (
    page,
    size,
    sortCol,
    sortOrder,
    payload,
    filters,
) => ({
    type: FETCH_RECALL_LIST_REQUEST,
    api: `${API.APPOINTMENT.RECALL_LIST}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
    page,
    size,
    payload,
    filters,
    successAction: FETCH_RECALL_LIST_SUCCESS,
    failureAction: FETCH_RECALL_LIST_FAILURE,
    failureMessage: MESSAGES.RECALL.RECALL_LIST_FETCH_FAILED,
});

export const createRecallRequest = (payload, successCallback, failureCallback) => ({
    type: CREATE_RECALL_REQUEST,
    api: API.APPOINTMENT.ADD_TO_RECALL_LIST,
    payload,
    successAction: CREATE_RECALL_REQUEST_SUCCESS,
    successMessage: MESSAGES.RECALL.CREATE.SUCCESS,
    successCallback,
    failureAction: CREATE_RECALL_REQUEST_FAILURE,
    failureCallback,
    failureMessage: MESSAGES.RECALL.CREATE.FAILED,
});

export const deleteRecallRequest = (uuid, removeReasonOrComment, successCallback, failureCallback) => ({
    type: DELETE_RECALL_REQUEST,
    api: `${API.APPOINTMENT.RECALL_LIST}/${uuid}?voidReason=${removeReasonOrComment}`,
    successAction: DELETE_RECALL_REQUEST_SUCCESS,
    successMessage: MESSAGES.RECALL.DELETE.SUCCESS,
    successCallback,
    failureAction: DELETE_RECALL_REQUEST_FAILURE,
    failureCallback,
    failureMessage: MESSAGES.RECALL.DELETE.FAILED,
});

