import { takeLatest } from 'redux-saga/effects';
import { addOrdersWorker, fetchOrdersQueueWorker } from './ordersQueueWorkerSaga';
import {
    ADD_ORDERS_REQUEST,
    FETCH_ORDERS_QUEUE_LIST_REQUEST,
} from '../../redux/modules/ordersQueue/ordersQueue-actions';


export function* fetchOrdersQueueWatcher() {
    yield takeLatest(FETCH_ORDERS_QUEUE_LIST_REQUEST, fetchOrdersQueueWorker);
}

export function* saveOrdersWatcher() {
    yield takeLatest(ADD_ORDERS_REQUEST, addOrdersWorker);
}
