import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { getRequiredFieldLabel } from '../../../constants/CommonUtil';

const ReduxFormSelectField = (props) => {
    const {
        input,
        label,
        menuItems,
        required,
        testId,
        meta: { touched, error },
        ...otherProps
    } = props;
    const fieldName = input.name;
    return (
        <FormControl style={{ width: '100%' }}>
            <TextField
                test-id={testId}
                {...otherProps}
                {...input}
                select
                label={required ? getRequiredFieldLabel(label) : label}
            >
                {menuItems}
            </TextField>
            {(touched && error) && <FormHelperText htmlFor={fieldName} error>{error}</FormHelperText>}
        </FormControl>
    );
};

ReduxFormSelectField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    menuItems: PropTypes.node,
    label: PropTypes.any,
    required: PropTypes.bool,
    testId: PropTypes.string,
};

ReduxFormSelectField.defaultProps = {
    input: {},
    meta: {},
    menuItems: <div />,
    label: null,
    required: false,
    testId: '',
};

export default ReduxFormSelectField;

