/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_SUPPLIER,
    CLEAR_SUPPLIER_STATE, CREATE_SUPPLIER_SUCCESS,
    FETCH_SUPPLIER_LIST_FAILURE,
    FETCH_SUPPLIER_LIST_REQUEST,
    FETCH_SUPPLIER_LIST_SUCCESS, SAVE_SELECTED_SUPPLIER,
} from './supplier-actions';

const supplierReducer = (state = initialStates.supplier, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_SUPPLIER_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_SUPPLIER_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_SUPPLIER_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case CLEAR_SUPPLIER_STATE:
        newState = initialStates.supplier;
        break;
    case CREATE_SUPPLIER_SUCCESS:
        newState = {
            ...state,
            isLoading: false,
            selectedSupplier: action.data,
        };
        break;
    case SAVE_SELECTED_SUPPLIER:
        newState = {
            ...state,
            selectedSupplier: action.payload,
        };
        break;
    case CLEAR_SELECTED_SUPPLIER:
        newState = {
            ...state,
            selectedSupplier: {},
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default supplierReducer;
