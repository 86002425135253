import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactSelectMaterial from '../../ReactSelectMaterial/ReactSelectMaterial';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    input: {
        padding: '0',
    },
});

class FormikReactSelectMaterial extends React.Component {
    onChange = (option, a, b, c) => {
        console.log('amfkflfa', a, b, c);
        const { field: { name }, form } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const validateOnChange = getStringFromObject('validateOnChange', form, false);
        if (isValidFunction(setFieldValue)) {
            if (Array.isArray(option) && option.length === 0) {
                setFieldValue(name, null, validateOnChange);
            } else {
                setFieldValue(name, option, validateOnChange);
            }
        }
        this.onSelectHandler(option);
    };

    onSelectHandler = (value) => {
        const {
            field: { name }, form, onSelectHandlers, actionHandlers, actionParams,
        } = this.props;
        if (isArrayValidAndNotEmpty(onSelectHandlers) && name &&
            isObjectValidAndNotEmpty(actionHandlers) && isObjectValidAndNotEmpty(form)) {
            onSelectHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, name, form, actionParams);
                }
            });
        }
    };

    onKeyPress = (event) => {
        const { allowFreeText, field: { name }, form } = this.props;
        if (event.key === 'Enter' && allowFreeText) {
            const setFieldValue = getStringFromObject('setFieldValue', form);
            const validateOnChange = getStringFromObject('validateOnChange', form, false);
            if (isValidFunction(setFieldValue)) {
                setFieldValue(name, event.target.value, validateOnChange);
            }
        }
    };

    onBlur = () => {
        const { field: { name }, form } = this.props;
        const setFieldTouched = getStringFromObject('setFieldTouched', form);
        const validateOnBlur = getStringFromObject('validateOnBlur', form, false);
        if (isValidFunction(setFieldTouched)) {
            setFieldTouched(name, true, validateOnBlur);
        }
    };

    render() {
        const {
            field: {
                name,
                value,
            },
            testId,
            form,
            options,
            dataSourceApi,
            dataSourceConfig,
            label,
            selectProps,
            editable,
            multiple,
            noLabel,
            autocomplete,
            classes,
            autocompleteSelectStyle,
            creatable,
            paramMap,
            isDisabled,
            placeholder,
            variant,
            required,
            defaultOptions,
            autoFocus,
            upperCaseOnly,
            disableMinWidth,
            showExpiryBatchWithColors,
        } = this.props;
        const touched = getStringFromObject(`touched.${name}`, form, false);
        const error = getStringFromObject(`errors.${name}`, form, '');
        console.log('faldfjadlfa', name, touched, error);
        console.log('asda0i-as0di-asd', value, options, dataSourceApi, dataSourceConfig);
        return (
            <ReactSelectMaterial
                showExpiryBatchWithColors={showExpiryBatchWithColors}
                testId={testId}
                placeholder={placeholder}
                value={value}
                label={label}
                multiple={multiple}
                defaultOptions={defaultOptions}
                dataSourceApi={dataSourceApi}
                dataSourceConfig={dataSourceConfig}
                options={options}
                editable={editable}
                isDisabled={isDisabled}
                noLabel={noLabel}
                onKeyPress={this.onKeyPress}
                autocomplete={autocomplete}
                creatable={creatable}
                onChange={this.onChange}
                onBlur={this.onBlur}
                errorText={error || ''}
                autocompleteSelectStyle={autocompleteSelectStyle}
                paramMap={paramMap}
                variant={variant}
                required={required}
                autoFocus={autoFocus}
                upperCaseOnly={upperCaseOnly}
                selectProps={{
                    classes,
                    id: name,
                    ...selectProps,
                    textFieldProps: {},
                }}
                disableMinWidth={disableMinWidth}
            />
        );
    }
}

FormikReactSelectMaterial.propTypes = {
    testId: PropTypes.string,
    classes: PropTypes.object,
    dataSourceConfig: PropTypes.object.isRequired,
    selectProps: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    dataSourceApi: PropTypes.string,
    editable: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    noLabel: PropTypes.bool,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    isDisabled: PropTypes.bool,
    autocomplete: PropTypes.bool,
    creatable: PropTypes.bool,
    upperCaseOnly: PropTypes.bool,
    allowFreeText: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autocompleteSelectStyle: PropTypes.object,
    onSelectHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    paramMap: PropTypes.object,
    actionParams: PropTypes.object,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    // formik props
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    disableMinWidth: PropTypes.bool,
    showExpiryBatchWithColors: PropTypes.bool,
};

FormikReactSelectMaterial.defaultProps = {
    testId: '',
    classes: {},
    label: 'Search and Select',
    variant: 'standard',
    placeholder: 'Search...',
    options: [],
    dataSourceApi: '',
    selectProps: {},
    editable: true,
    defaultOptions: true,
    noLabel: false,
    multiple: false,
    autocomplete: false,
    creatable: false,
    allowFreeText: false,
    isDisabled: false,
    required: false,
    upperCaseOnly: false,
    autoFocus: false,
    autocompleteSelectStyle: {},
    onSelectHandlers: [],
    actionHandlers: {},
    paramMap: {},
    actionParams: {},
    disableMinWidth: false,
    showExpiryBatchWithColors: false,
};

export default withStyles(styles)(FormikReactSelectMaterial);

