/**
 * Created by vinay on 25/12/18.
 */
import API from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';
import MESSAGES from '../../../constants/messages';

export const DEPARTMENT_LIST_FETCH_REQUEST = '@@DEPARTMENT/DEPARTMENT_LIST_FETCH_REQUEST';
export const DEPARTMENT_LIST_FETCH_SUCCESS = '@@DEPARTMENT/DEPARTMENT_LIST_FETCH_SUCCESS';
export const DEPARTMENT_LIST_FETCH_FAILURE = '@@DEPARTMENT/DEPARTMENT_LIST_FETCH_FAILURE';

export const SERVICE_LIST_FETCH_REQUEST = '@@DEPARTMENT/SERVICE_LIST_FETCH_REQUEST';
export const SERVICE_LIST_FETCH_SUCCESS = '@@DEPARTMENT/SERVICE_LIST_FETCH_SUCCESS';
export const SERVICE_LIST_FETCH_FAILURE = '@@DEPARTMENT/SERVICE_LIST_FETCH_FAILURE';

export const DEPARTMENT_UPDATE_REQUEST = '@@DEPARTMENT/DEPARTMENT_UPDATE_REQUEST';
export const DEPARTMENT_UPDATE_SUCCESS = '@@DEPARTMENT/DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILURE = '@@DEPARTMENT/DEPARTMENT_UPDATE_FAILURE';

export const DEPARTMENT_CREATE_REQUEST = '@@DEPARTMENT/DEPARTMENT_CREATE_REQUEST';
export const DEPARTMENT_CREATE_SUCCESS = '@@DEPARTMENT/DEPARTMENT_CREATE_SUCCESS';
export const DEPARTMENT_CREATE_FAILURE = '@@DEPARTMENT/DEPARTMENT_CREATE_FAILURE';

export const SERVICE_UPDATE_REQUEST = '@@DEPARTMENT/SERVICE_UPDATE_REQUEST';
export const SERVICE_UPDATE_SUCCESS = '@@DEPARTMENT/SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_FAILURE = '@@DEPARTMENT/SERVICE_UPDATE_FAILURE';

export const SERVICE_CREATE_REQUEST = '@@DEPARTMENT/SERVICE_CREATE_REQUEST';
export const SERVICE_CREATE_SUCCESS = '@@DEPARTMENT/SERVICE_CREATE_SUCCESS';
export const SERVICE_CREATE_FAILURE = '@@DEPARTMENT/SERVICE_CREATE_FAILURE';

export const DELETE_PROVIDER_FROM_SERVICE_REQUEST = '@@DEPARTMENT/DELETE_PROVIDER_FROM_SERVICE_REQUEST';
export const DELETE_PROVIDER_FROM_SERVICE_SUCCESS = '@@DEPARTMENT/DELETE_PROVIDER_FROM_SERVICE_SUCCESS';
export const DELETE_PROVIDER_FROM_SERVICE_FAILURE = '@@DEPARTMENT/DELETE_PROVIDER_FROM_SERVICE_FAILURE';

export const CLEAR_DEPARTMENT_LIST = '@@DEPARTMENT/CLEAR_DEPARTMENT_LIST';
export const CLEAR_SERVICE_LIST = '@@DEPARTMENT/CLEAR_SERVICE_LIST';

export const fetchDepartmentRequest = () => ({
    type: DEPARTMENT_LIST_FETCH_REQUEST,
    api: API.APPOINTMENT.DEPARTMENT.FETCH_LIST,
});

export const updateDepartmentRequest = (department, successCallback) => ({
    type: DEPARTMENT_UPDATE_REQUEST,
    api: `${API.APPOINTMENT.DEPARTMENT.BASE_URL}/${department.uuid}`,
    department,
    successCallback,
});

export const createDepartmentRequest = (department, successCallback) => ({
    type: DEPARTMENT_CREATE_REQUEST,
    api: API.APPOINTMENT.DEPARTMENT.BASE_URL,
    department,
    successCallback,
});

export const clearDepartmentList = () => ({
    type: CLEAR_DEPARTMENT_LIST,
});

export const fetchDepartmentAndServiceRequest = () => ({
    type: SERVICE_LIST_FETCH_REQUEST,
    api: API.APPOINTMENT.DEPARTMENT.BASE_URL,
});

export const updateServiceRequest = (department, successCallback) => ({
    type: SERVICE_UPDATE_REQUEST,
    api: `${API.APPOINTMENT.DEPARTMENT.BASE_URL}/${department.uuid}`,
    department,
    successCallback,
});

export const createServiceRequest = (department, successCallback) => ({
    type: SERVICE_CREATE_REQUEST,
    api: `${API.APPOINTMENT.DEPARTMENT.BASE_URL}/${department.uuid}`,
    department,
    successCallback,
});

export const deleteProviderFromServiceRequest = (
    serviceUuid, providerUuid, removeFromService = false, successCallback,
) => ({
    type: DELETE_PROVIDER_FROM_SERVICE_REQUEST,
    api: getUrlWithApiParams(API.APPOINTMENT.DOCTOR_SLOT.DELETE_PROVIDER, {
        serviceUuid, providerUuid, removeFromService,
    }),
    successAction: DELETE_PROVIDER_FROM_SERVICE_SUCCESS,
    successMessage: removeFromService ? MESSAGES.SERVICE.DELETE_PROVIDER_FROM_SERVICE_SUCCESS : '',
    successCallback,
    failureAction: DELETE_PROVIDER_FROM_SERVICE_FAILURE,
});

export const clearServiceList = () => ({
    type: CLEAR_SERVICE_LIST,
});
