import { call, put, select } from 'redux-saga/effects';
import deepEqual from 'react-fast-compare';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isObjectEmptyOrValuesEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import messages from '../../constants/messages';
import {
    FETCH_CLAIMS_OR_BUNDLES_FAILURE,
    FETCH_CLAIMS_OR_BUNDLES_SUCCESS,
    SAVE_CLAIMS_AS_BUNDLE_SUCCESS,
} from '../../redux/modules/claimsAndBundles/claimsAndBundles-actions';
import {
    getClaimListConstruct,
} from '../../components/PayerComponents/ClaimsList/ClaimsListUtil';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const getClaimPrefix = state => getStringFromObject('appConfiguration.claimPrefix', state);

export function* fetchClaimListWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        // yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const {
            api,
            page,
            size,
            filters,
            searchFilter,
            seekDirection,
            seekParams,
            isBundle,
            defaultFilter,
        } = action;
        const claimPrefix = yield select(getClaimPrefix);
        const searchConstruct = getClaimListConstruct(filters, searchFilter, isBundle, claimPrefix);

        let defaultSearchConstruct = null;
        if (!isBundle && isObjectEmptyOrValuesEmpty(defaultFilter)) {
            defaultSearchConstruct = getClaimListConstruct(defaultFilter, '', isBundle, claimPrefix);
        }
        if (
            !isBundle &&
            deepEqual(searchConstruct, defaultSearchConstruct)
        ) {
            yield put({
                type: FETCH_CLAIMS_OR_BUNDLES_SUCCESS,
                data: {
                    content: [],
                    totalElements: 0,
                    forwardSeekMap: null,
                    backwardSeekMap: null,
                    first: false,
                    last: false,
                    size,
                    page,
                    isBundle,
                },
                filters: action.filters,
            });
        } else {
            const apiWithParams =
                addSeekDirectionAndParamsToUrlParams(`${api}?page=${page}&size=${size}`, seekDirection, seekParams);


            const response = yield call(retriable().post, apiWithParams, searchConstruct, config);
            yield put({
                type: FETCH_CLAIMS_OR_BUNDLES_SUCCESS,
                data: {
                    ...response.data,
                    size,
                    page,
                    isBundle,
                },
                filters: action.filters,
            });
            if (isValidFunction(action.successCallback)) {
                action.successCallback(response.data);
            }
        }
        // yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.error('error in bundle/claims', e);
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.CLAIMS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_CLAIMS_OR_BUNDLES_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createClaimBundles(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const {
            api, page, size,
        } = action;
        const apiWithParams = `${api}?page=${page}&size=${size}`;
        const { payload } = action;
        const response = yield call(retriable().post, apiWithParams, payload, config);
        yield put({
            type: SAVE_CLAIMS_AS_BUNDLE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.CLAIMS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_CLAIMS_OR_BUNDLES_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
