import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import MaterialTable from '../../MaterialTableV2/MaterialTable';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { commonDeleteApiRequest } from '../../../redux/modules/common/common-actions';
import {
    getJsonPath,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,
} from '../../../constants/CommonUtil';
import api from '../../../constants/api';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

class InvoicePaymentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: [],
        };
    }

    componentDidMount() {
        this.loadJson();
    }

    loadJson = () => {
        fetch(getJsonPath('/BookManagement/InvoicePaymentList.json'))
            .then(resp => resp.json())
            .then((json) => {
                const schema = cloneDeep(json);
                if (isObjectValidAndNotEmpty(schema) && isArrayValidAndNotEmpty(schema.tableCells)) {
                    const rowActionDefinitionIndex =
                        schema.tableCells.findIndex(aCell => aCell.name === 'rowAction');
                    if (rowActionDefinitionIndex > -1) {
                        const rowActionDef = schema.tableCells[rowActionDefinitionIndex];
                        if (
                            isObjectValidAndNotEmpty(rowActionDef) &&
                            isArrayValidAndNotEmpty(rowActionDef.choices)
                        ) {
                            const availableActionsForUser =
                                rowActionDef.choices.filter(aChoice => checkIfPrivilegeExistsForUser(aChoice.privilege));
                            if (!isArrayValidAndNotEmpty(availableActionsForUser)) {
                                schema.tableCells.splice(rowActionDefinitionIndex, 1);
                            }
                        }
                    }
                }
                this.setState({ schema });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    };

    handleRowAction = (value, index, event, data) => {
        if (value === 'delete' && data && data.id) {
            this.props.dispatch(displayWarning(
                'Are you sure want to delete selected payment details ? This operation can not be undone !!',
                this.handleDeleteInvoicePayment,
                data,
            ));
        }
    };

    handleDeleteInvoicePayment = (data) => {
        if (data && data.id) {
            this.props.dispatch(commonDeleteApiRequest(
                getUrlWithApiParams(api.ACCOUNT_INVOICE.INVOICE_PAYMENTS, { invoicePaymentId: data.id }),
                {
                    successMessage: 'Successfully deleted invoice payment',
                    successCallback: this.props.handleDeleteInvoicePayment,
                },
            ));
        }
    };

    render() {
        const {
            schema,
        } = this.state;
        const {
            onSelectInvoicePayment,
            invoicePayments,
        } = this.props;
        console.log('SupplierPaymentDialogWrapper props', this.props);
        console.log('SupplierPaymentDialogWrapper state', this.state);
        return (
            <React.Fragment>
                <MaterialTable
                    schema={schema}
                    uniqueKey="uuid"
                    data={invoicePayments}
                    onRowClick={onSelectInvoicePayment}
                    noPagination
                    handleSelectAction={this.handleRowAction}
                />
            </React.Fragment>
        );
    }
}

InvoicePaymentList.propTypes = {
    invoicePayments: PropTypes.array,
    onSelectInvoicePayment: PropTypes.func.isRequired,
    handleDeleteInvoicePayment: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

InvoicePaymentList.defaultProps = {
    invoicePayments: [],
};

export default InvoicePaymentList;

