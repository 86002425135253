/**
 * Created by vinay on 7/5/19.
 */
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getStringFromObject } from '../../constants/lodashUtils';

const generateAttendanceConstruct = (filters) => {
    let construct = null;
    const periodUuid = getStringFromObject('period.uuid', filters);
    if (periodUuid) {
        construct = getCombined(construct, 'AND',
            generateObjectWithCriteriaMode('period.uuid', periodUuid, '='));
    }
    const status = getStringFromObject('status', filters, null);
    if (status) {
        construct = getCombined(construct, 'AND',
            generateObjectWithCriteriaMode('approvedAttendance.status', status, '='));
    }
    const employeeUuid = getStringFromObject('employee.key', filters, null);
    if (employeeUuid) {
        construct = getCombined(construct, 'AND',
            generateObjectWithCriteriaMode('hrEmployee.uuid', employeeUuid, '='));
    }
    const managerUuid = getStringFromObject('manager.key', filters, null);
    if (managerUuid) {
        construct = getCombined(construct, 'AND',
            generateObjectWithCriteriaMode('manager.uuid', managerUuid, '='));
    }
    const departmentUuid = getStringFromObject('department.key', filters, null);
    if (departmentUuid) {
        construct = getCombined(construct, 'AND',
            generateObjectWithCriteriaMode('department.id', departmentUuid, '='));
    }
    let searchText = getStringFromObject('searchText', filters);
    if (searchText) {
        searchText = searchText.trim();
        let op = ':';
        if (searchText.endsWith('@')) {
            searchText = searchText.substring(0, searchText.length - 1);
            op = '=';
        }
        const nameConstruct = getCombined(
            getCombined(
                generateObjectWithCriteriaMode('resPartner.name', searchText, op),
                'OR',
                generateObjectWithCriteriaMode('hrEmployee.nameRelated', searchText, op),
            ),
            'OR',
            generateObjectWithCriteriaMode('hrEmployee.identificationId', searchText, op),
        );
        construct = getCombined(construct, 'AND', nameConstruct);
    }
    return construct;
};

export default generateAttendanceConstruct;

