import { getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';

export const mapTransferObjFromUiObj = obj => ({
    uuid: getStringFromObject('uuid', obj),
    transferFrom: getStringFromObject('transferFrom', obj, null),
    transferTo: getStringFromObject('transferTo', obj, null),
    transferAmount: getStringFromObject('amount', obj, 0.00),
    date: new Date(getStringFromObject('date', obj, new Date().getTime())).getTime(),
    memo: getStringFromObject('memo', obj, ''),
    journal: getStringFromObject('journal', obj) || null,
    subCompany: getStringFromObject('subCompany.key', obj) || null,

});

export const mapUiObjFromTransferObj = obj => ({
    uuid: getStringFromObject('uuid', obj),
    name: getStringFromObject('name', obj),
    periodClosed: getStringFromObject('periodClosed', obj),
    accountMoveUuid: getStringFromObject('accountMoveUuid', obj),
    transferFrom: getStringFromObject('transferFrom', obj, null),
    transferTo: getStringFromObject('transferTo', obj, null),
    amount: getStringFromObject('transferAmount', obj, 0.00),
    date: getDateInYYYYMMDDFormat(new Date(getStringFromObject('date', obj, new Date().getTime()))),
    memo: getStringFromObject('memo', obj, ''),
    reconciled: getStringFromObject('reconciled', obj) || false,
    subCompany: obj.subCompany,
    // backend does not send there data :(

    // journal: getStringFromObject('journal', obj, ''),
    // subCompany: {
    //     key: getStringFromObject('key', obj, ''),
    //     value: getStringFromObject('key', obj, ''),
    // },
});
