import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ArrowRight from '@material-ui/icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';

import generateFormFields from '../../../constants/generateFormFields';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';


const styles = {
    root: {
        color: blue[600],
        '&$checked': {
            color: blue[500],
        },
    },
    checked: {},
    narrow: {
        minWidth: '26em',
    },
    disabledText: {
        color: 'black',
    },
};

const checkIfDisable = (cell, rowValue) => {
    if (cell.disableIf) {
        const { disableIf } = cell;
        if (getStringFromObject(disableIf.name, rowValue, null) === disableIf.value) {
            return true;
        }
    }

    return false;
};

const getChoicesFromRow = (cell, rowValue) => {
    if (cell.takeChoicesFromRow) {
        const { takeChoicesFromRow } = cell;
        const choices = getStringFromObject(takeChoicesFromRow, rowValue, []);
        if (isArrayValidAndNotEmpty(choices)) {
            return choices.map(c => (
                <MenuItem
                    value={c[cell.dataSourceConfig.value]}
                    key={c[cell.dataSourceConfig.value]}
                >
                    {c[cell.dataSourceConfig.text]}
                </MenuItem>
            ));
        }
    }
    return null;
};

class MaterialTableRow extends React.Component {
    constructor(props) {
        super(props);
        const rowValue = props.fields.get(props.index);
        this.state = {
            expanded: rowValue.expanded || false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.expandedRowIndex !== nextProps.expandedRowIndex) {
            this.setState({
                expanded: nextProps.expandedRowIndex === nextProps.index,
            });
        }
    }

    onRowClick = () => {
        const {
            isExpandable,
            index,
            onRowClick,
            handleRowExpand,
        } = this.props;
        if (isExpandable) {
            this.setState(prevState => ({ expanded: !prevState.expanded }));
            handleRowExpand(index);
        }
        onRowClick(index);
        // todo to remove soon when refactoring
    };

    handleSelect = (value) => {
        const { handleSelectAction, index } = this.props;
        handleSelectAction(value, index);
    };

    handleKeyUp = () => {
        console.log('HandleKeyUp');
    };

    handleKeyDown = () => {
        console.log('HandleKeyDown');
    };

    handleDeleteRowClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { handleDeleteRowClick, index } = this.props;
        handleDeleteRowClick(e, index);
    };

    handleSaveIconClick = (e) => {
        e.preventDefault();
        e.stopPropagation(); const { handleSaveIconClick, index } = this.props;
        handleSaveIconClick(e, index);
    };

    handleInfoIconClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { handleInfoIconClick, index } = this.props;
        handleInfoIconClick(e, index);
    };

    render() {
        const {
            isSelected,
            tableCells,
            isEditable,
            index,
            handleClick,
            classes,
            rowStyle,
            hideCheckBox,
            member,
            fields,
            actionHandlers,
            isExpandable,
            formValues,
            deleteIcon,
            saveIcon,
            handleRowExpand,
            getExpandableComponent,
            paramMap,
            showRowNumber,
            actionParams,
            addRef,
            expandedRowIndex,
            handleButtonAction,
            infoIcon,
            forceEnableCheckBox,
        } = this.props;
        const { expanded } = this.state;
        console.log('sandeddedd', this.props);
        console.log('retiredValue', getStringFromObject(this.props.retired, fields.get(index)));
        const tableRowStyle = { ...rowStyle };
        if (isExpandable) {
            tableRowStyle.cursor = 'pointer';
        }
        if (this.props.retired && this.props.retiredValue !== null && this.props.retiredValue !== undefined &&
            getStringFromObject(this.props.retired, fields.get(index)) === this.props.retiredValue) {
            tableRowStyle.background = '#ff7d7d';
        }
        return (
            <React.Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isSelected}
                    style={tableRowStyle}
                    onClick={this.onRowClick}
                >
                    {
                        (isEditable || forceEnableCheckBox) && (!hideCheckBox) &&
                        <TableCell>
                            <Checkbox
                                checked={isSelected}
                                classes={{
                                    checked: classes.checked,
                                }}
                                onClick={event => handleClick(event, index)}
                                data-test-id={`${member}-cb`}
                            />
                        </TableCell>
                    }
                    {
                        isExpandable &&
                        <TableCell>
                            <ArrowRight
                                style={{
                                    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'all 0.5s',
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                }}
                            />
                        </TableCell>
                    }
                    {
                        showRowNumber &&
                        <TableCell style={{ fontSize: '1rem' }}>
                            {NumberOf(index) + 1}
                        </TableCell>
                    }
                    {
                        tableCells.map((cell, colIndex) => (
                            <TableCell
                                scope="row"
                                className={classNames(cell.narrow ? classes.narrow : '')}
                                key={cell.id}
                                style={cell.cellStyle}
                            >
                                {generateFormFields({
                                    fieldData: cell,
                                    member,
                                    formValues,
                                    editable: isEditable && (!checkIfDisable(cell, fields.get(index))),
                                    fields,
                                    handleSelectAction: this.handleSelect,
                                    actionHandlers,
                                    fieldChoices: getChoicesFromRow(cell, fields.get(index)),
                                    paramMap, //  urlParameters map for autocompletes
                                    actionParams,
                                    rowValue: fields.get(index),
                                    addRef: (ref, name) => addRef(ref, name, index, colIndex),
                                    onKeyUp: this.handleKeyUp,
                                    onKeyDown: this.handleKeyDown,
                                    handleButtonAction,
                                })}
                            </TableCell>
                        ))
                    }

                    {
                        isEditable && deleteIcon &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleDeleteRowClick}>
                                <DeleteIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                    {
                        saveIcon && isEditable &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleSaveIconClick}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                    {
                        infoIcon &&
                        <TableCell style={{ width: '2%' }}>
                            <IconButton onClick={this.handleInfoIconClick} data-test-id={`${member}-info`}>
                                <InfoIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    }
                </TableRow>
                {
                    (expanded || expandedRowIndex === index) && getExpandableComponent &&
                    (
                        getExpandableComponent(
                            member,
                            index,
                            handleRowExpand,
                        )
                    )
                }
            </React.Fragment>
        );
    }
}

MaterialTableRow.propTypes = {
    isSelected: PropTypes.bool,
    tableCells: PropTypes.array.isRequired,
    paramMap: PropTypes.array,
    isEditable: PropTypes.bool,
    hideCheckBox: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    saveIcon: PropTypes.bool,
    infoIcon: PropTypes.bool,
    handleClick: PropTypes.func,
    handleDeleteRowClick: PropTypes.func,
    handleSelectAction: PropTypes.func,
    index: PropTypes.number.isRequired,
    member: PropTypes.string.isRequired,
    rowStyle: PropTypes.object,
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
    actionParams: PropTypes.object,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    handleRowExpand: PropTypes.func,
    getExpandableComponent: PropTypes.func,
    addRef: PropTypes.func,
    onRowClick: PropTypes.func,
    handleButtonAction: PropTypes.func,
    handleSaveIconClick: PropTypes.func,
    retired: PropTypes.string,
    retiredValue: PropTypes.bool,
    // to be removed soon
    expandedRowIndex: PropTypes.number,
    handleInfoIconClick: PropTypes.func,
    forceEnableCheckBox: PropTypes.bool.isRequired,
};

MaterialTableRow.defaultProps = {
    isSelected: false,
    saveIcon: false,
    deleteIcon: false,
    infoIcon: false,
    handleClick: () => {},
    handleSelectAction: () => {},
    handleDeleteRowClick: () => {},
    handleSaveIconClick: () => {},
    handleButtonAction: () => {},
    isEditable: false,
    hideCheckBox: false,
    rowStyle: {},
    fields: {},
    paramMap: [],
    actionParams: {},
    actionHandlers: {},
    isExpandable: false,
    showRowNumber: false,
    handleRowExpand: () => {},
    getExpandableComponent: () => {},
    onRowClick: () => {},
    addRef: () => {},
    retired: null,
    retiredValue: null,
    expandedRowIndex: null,
    handleInfoIconClick: () => {},
};

export default withStyles(styles)(MaterialTableRow);
