import React from 'react';
import PropTypes from 'prop-types';
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const mainContainerStyle = {
    height: '100vh',
    maxHeight: '100vh',
    width: '100%',
    maxWidth: '100vw',
    backgroundColor: '#C00',
    color: '#FFF',
};

const ErrorFallBackComponent = ({ error, componentStack }) => {
    console.log('asda-0dads-0as', error, componentStack);
    return (
        <div
            role="alert"
            style={mainContainerStyle}
            className="flexCenter justify-content-center"
        >
            <Grid container spacing={16}>
                <Grid sm={5} md={5} lg={5}>
                    <SentimentVeryDissatisfied
                        style={{
                            height: '100%',
                            width: '70%',
                        }}
                        nativeColor="#fff"
                    />
                </Grid>
                <Grid sm={7} md={7} lg={7}>
                    <h2>An Error Occurred</h2>
                    <div
                        style={{ cursor: 'help' }}
                    >
                        <Tooltip title={componentStack}>
                            <pre>
                                {error.message}
                            </pre>
                        </Tooltip>
                    </div>
                    <Button
                        style={{ color: '#fff' }}
                        onClick={() => {
                            // eslint-disable-next-line
                            location.reload();
                        }}
                    >
                        Click Here To Reload Page
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

ErrorFallBackComponent.propTypes = {
    error: PropTypes.object,
    componentStack: PropTypes.string,
};

ErrorFallBackComponent.defaultProps = {
    error: {},
    componentStack: '',
};

export default ErrorFallBackComponent;

