import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import {
    FETCH_BILLED_PACKAGES_SUCCESS,
    FETCH_BILLED_PACKAGES_FAILURE,
} from '../../redux/modules/billedMedicalPackage/billedMedicalPackage-actions';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';

function* fetchBilledPackagesWorker(action) {
    try {
        const {
            api,
            page,
            size,
            searchConstruct,
        } = action;
        const apiWithParams = `${api}?page=${page}&size=${size}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, apiWithParams, searchConstruct);
        yield put({
            type: FETCH_BILLED_PACKAGES_SUCCESS,
            data: {
                ...(response.data || {}),
                page,
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Fetching billed packages failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ FETCH_BILLED_PACKAGES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchBilledPackagesWorker;
