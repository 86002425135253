import cloneDeep from 'clone-deep';
import { autofill, change } from 'redux-form';
import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const extractAndSetValues = (rowValue) => {
    const newRowValue = cloneDeep(rowValue);
    const product = newRowValue.product ? newRowValue.product : {};
    let quantity;
    if (newRowValue.quantity) {
        quantity = Math.max(NumberOf(newRowValue.quantity), 1);
    }
    const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
    // const factor = uom && uom.factor ? Number(uom.factor) : 1;
    const salePrice = newRowValue.salePrice == null ? NumberOf(product.price) : NumberOf(newRowValue.salePrice);
    const cost = newRowValue.salePrice == null ? NumberOf(product.cost) : NumberOf(newRowValue.cost);
    const amount = (quantity * salePrice);
    const taxes = newRowValue.taxes || [];
    let totalTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            const newTax = (NumberOf(tax.amount) * amount);
            totalTax += NumberOf(newTax);
        });
    }
    // Resetting after tax calculation
    newRowValue.total = roundedValueFixedToTwoDigits(amount + totalTax);
    newRowValue.quantity = quantity;
    newRowValue.uom = uom;
    newRowValue.salePrice = roundedValueFixedToTwoDigits(salePrice);
    newRowValue.cost = roundedValueFixedToTwoDigits(cost);
    newRowValue.mrp = roundedValueFixedToTwoDigits(salePrice);
    newRowValue.amount = roundedValueFixedToTwoDigits(amount);
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', rowValue, false);
    newRowValue.uomCategoryId = getStringFromObject('erpUomDto.category.key', product) || 0;
    newRowValue.productId = getStringFromObject('productId', product) || 0;
    return newRowValue;
};

const setLine = (rowValue, index, dispatch, fields) => {
    console.log('asdfsdfsdfdsfdsf', rowValue, fields, typeof index);
    if (isObjectValidAndNotEmpty(fields)) {
        const valuesLength = fields.length;
        console.log('asdfsdfsdfdsfdsf', valuesLength);
        if (index === (valuesLength - 1).toString()) {
            // Add row automatically..
            fields.push({ uomCategoryId: 0, productId: 0 });
        }
    }
    if (isObjectValidAndNotEmpty(rowValue)) {
        const newRowValue = extractAndSetValues(rowValue);
        // dispatch(arrayRemove('addStockForm', 'addStock', index));
        dispatch(change('addStockForm', `addStock.${index}`, newRowValue));
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, formName, field, fields, dispatch) => {
    if (isObjectValidAndNotEmpty(product) && formName && field && isValidFunction(dispatch) &&
        isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.salePrice = null;
            rowValue.quantity = Math.max(1, NumberOf(rowValue.quantity));
            rowValue.taxes = product.taxes || [];
            rowValue.expiry = '';
            setLine(rowValue, index, dispatch, fields);
        }
    }
};

const handleTaxSelect = (value, formName, field, fields, dispatch) => {
    if (isArrayValidAndNotEmpty(value) && formName && field && isValidFunction(dispatch) &&
        isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const quantityChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const rateChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.salePrice = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const rowChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            dispatch(autofill(formName, `${field}.operation`, operation));
        }
    }
};

const deleteRowHandler = (selectedRows, fields, dispatch, instance) => {
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        selectedRows.forEach((idx) => {
            // const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(idx)) : null;
            // if (isObjectValidAndNotEmpty(rowValue) && rowValue.orderLineUuid) {
            //     rowValue.operation = 3;
            //     arrayRemove('purchaseOrderForm', 'purchaseOrderLines', idx);
            //     arrayInsert('purchaseOrderForm', 'purchaseOrderLines', idx, rowValue);
            // } else {
            //     fields.remove(idx);
            //     if (instance != null) {
            //         instance.setState({ selected: selectedRows.splice(idx, 1) });
            //     }
            // }
            fields.remove(idx);
            if (fields.length === 1) {
                fields.push({});
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
    }
};

export const ACTION_HANDLERS = {
    handleProductSelect,
    rateChangeHandler,
    quantityChangeHandler,
    rowChangeHandler,
    deleteRowHandler,
    handleTaxSelect,
};

export default ACTION_HANDLERS;
