/**
 * Created by sachinprakash on 1/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    ADD_OR_UPDATE_PACKAGE_REQUEST,
    FETCH_PACKAGE_LIST_REQUEST,
} from '../../redux/modules/servicePackage/servicePackage-actions';
import { addOrUpdatePackageWorker, packageListFetchWorker } from './servicePackageWorkerSaga';


export function* packageFetchWatcher() {
    yield takeLatest(FETCH_PACKAGE_LIST_REQUEST, packageListFetchWorker);
}

export function* addOrUpdatePackageWatcher() {
    yield takeLatest(ADD_OR_UPDATE_PACKAGE_REQUEST, addOrUpdatePackageWorker);
}
