import API from '../../../constants/api';

export const CREATE_ACCOUNT_INVOICE_REQUEST = '@@accountInvoice/CREATE_ACCOUNT_INVOICE_REQUEST';
export const CREATE_ACCOUNT_INVOICE_FAILURE = '@@accountInvoice/CREATE_ACCOUNT_INVOICE_FAILURE';
export const CREATE_ACCOUNT_INVOICE_SUCCESS = '@@accountInvoice/CREATE_ACCOUNT_INVOICE_SUCCESS';

export const CANCEL_ACCOUNT_INVOICE_REQUEST = '@@accountInvoice/CANCEL_ACCOUNT_INVOICE_REQUEST';
export const CANCEL_ACCOUNT_INVOICE_FAILURE = '@@accountInvoice/CANCEL_ACCOUNT_INVOICE_FAILURE';
export const CANCEL_ACCOUNT_INVOICE_SUCCESS = '@@accountInvoice/CANCEL_ACCOUNT_INVOICE_SUCCESS';

export const FETCH_ACCOUNT_INVOICE_REQUEST = '@@accountInvoice/FETCH_ACCOUNT_INVOICE_REQUEST';
export const FETCH_ACCOUNT_INVOICE_FAILURE = '@@accountInvoice/FETCH_ACCOUNT_INVOICE_FAILURE';
export const FETCH_ACCOUNT_INVOICE_SUCCESS = '@@accountInvoice/FETCH_ACCOUNT_INVOICE_SUCCESS';

export const CLEAR_SELECTED_ACCOUNT_INVOICE = '@@accountInvoice/CLEAR_SELECTED_ACCOUNT_INVOICE';

export const createAccountInvoiceRequest = (accountInvoice, successCallback, failureCallBack, invoiceType) => ({
    type: CREATE_ACCOUNT_INVOICE_REQUEST,
    api: API.ACCOUNT_INVOICE.CREATE,
    accountInvoice,
    successCallback,
    failureCallBack,
    invoiceType,
});

export const createIssueInvoiceRequest = (accountInvoice, successCallback, failureCallBack, invoiceType) => ({
    type: CREATE_ACCOUNT_INVOICE_REQUEST,
    api: API.ACCOUNT_INVOICE.CREATE_ISSUE_INVOICE,
    accountInvoice,
    successCallback,
    failureCallBack,
    invoiceType,
});

export const createAccountInvoiceRequestForMaterialReceive = (accountInvoice, successCallback, failureCallBack, invoiceType) => ({
    type: CREATE_ACCOUNT_INVOICE_REQUEST,
    api: API.MATERIAL_RECEIVE.CREATE_INVOICE,
    accountInvoice,
    successCallback,
    failureCallBack,
    invoiceType,
});

export const cancelAccountInvoiceRequest = (uuid, accountInvoice, successCallback, failureCallBack) => ({
    type: CANCEL_ACCOUNT_INVOICE_REQUEST,
    api: `${API.ACCOUNT_INVOICE.CANCEL}${uuid}`,
    payload: accountInvoice,
    successAction: CANCEL_ACCOUNT_INVOICE_SUCCESS,
    successMessage: 'Successfully cancelled invoice',
    failureAction: CANCEL_ACCOUNT_INVOICE_FAILURE,
    failureMessage: 'Failed to cancel invoice',
    successCallback,
    failureCallBack,
});

export const createCreditOrDebitMemoRequest = (accountInvoice, successCallback, failureCallBack) => ({
    type: CREATE_ACCOUNT_INVOICE_REQUEST,
    api: API.ACCOUNT_INVOICE.CREATE_CREDIT_MEMO,
    accountInvoice,
    successCallback,
    failureCallBack,
});

export const fetchAccountInvoiceForPurchaseOrderRequest = (
    orderUuid, state = '', successCallback, failureCallBack,
) => ({
    type: FETCH_ACCOUNT_INVOICE_REQUEST,
    api: `${API.ACCOUNT_INVOICE.BY_PURCHSE_ORDER}${orderUuid}&state=${state}`,
    successCallback,
    failureCallBack,
});

export const fetchAccountInvoiceById = (
    invoiceId, successCallback, failureCallBack,
) => ({
    type: FETCH_ACCOUNT_INVOICE_REQUEST,
    api: `${API.ACCOUNT_INVOICE.GET_INVOICE}/byId/${invoiceId}`,
    successCallback,
    failureCallBack,
});

export const fetchAccountInvoiceByUuid = (
    invoiceUuid, successCallback, failureCallBack,
) => ({
    type: FETCH_ACCOUNT_INVOICE_REQUEST,
    api: `${API.ACCOUNT_INVOICE.GET_INVOICE}/findOne/${invoiceUuid}`,
    successCallback,
    failureCallBack,
});

export const fetchPurchaseInvoiceForSupplier = (
    id, shopId, successCallback, failureCallBack,
) => ({
    type: FETCH_ACCOUNT_INVOICE_REQUEST,
    api: `${API.ACCOUNT_INVOICE.GET_INVOICE}?id=${id}&shop=${shopId}`,
    successCallback,
    failureCallBack,
});

export const clearSelectedAccountInvoice = () => ({
    type: CLEAR_SELECTED_ACCOUNT_INVOICE,
});
