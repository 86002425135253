import initialStates from '../../initialStates';
import {
    FETCH_MEDICAL_PACKAGE_LIST_REQUEST,
    FETCH_MEDICAL_PACKAGE_LIST_SUCCESS,
    FETCH_MEDICAL_PACKAGE_LIST_FAILURE,
} from './medicalPackage-action';

const fetchMedicalPackagesReducer = (state = initialStates.medicalPackages, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_MEDICAL_PACKAGE_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_MEDICAL_PACKAGE_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            isLast: action.data.isLast,
            totalPages: action.totalPages,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
        });
        break;
    case FETCH_MEDICAL_PACKAGE_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default fetchMedicalPackagesReducer;
