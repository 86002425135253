import React from 'react';
import { FormattedMessage } from 'react-intl';

// this component is a dummy component to fix statically evaluatable id error
// https://github.com/yahoo/babel-plugin-react-intl/issues/119
export const IntlFormattedMessage = (props) => {
    console.log('jjjjj12sadasd', props);
    return (
        <FormattedMessage
            {...props}
        />
    );
};

export default IntlFormattedMessage;
