import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import { Field, withFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';

import API from '../../../constants/api';
import {
    getJsonPath,
    getUrlWithApiParams, isArrayValidAndNotEmpty,
    isValidFunction,
    stringEqualsIgnoreCase,
} from '../../../constants/CommonUtil';
import { required } from '../../../constants/FormValidations';
import ActionButton from '../../ActionButton/ActionButton';
import {
    documentFileTypes,
    imageFileTypes,
    PAYMENT_METHODS,
    PAYMENT_METHODS_MAP,
} from '../../../constants/constants';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import receivePaymentDialogStyles from './ReceivePaymentDialogStyles';
import ReduxFormChipFileUpload from '../../FormFieldComponents/ReduxFormChipFileUpload/ReduxFormChipFileUpload';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import {
    ACTION_HANDLERS,
    autoReconcile,
    calculateTotals, FORM_VALIDATOR,
    getPayload,
    getUiObjectFromProps,
} from './SupplierPaymentUtil';
import { commonDeleteApiRequest, commonPostApiRequest } from '../../../redux/modules/common/common-actions';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { deleteInvoicePaymentPrivilege } from '../../../constants/privilegeConstants';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'supplierPaymentForm';

class SupplierPaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            advanceSchema: null,
            page: 0,
        };
    }

    componentDidMount() {
        Promise.all([
            this.loadJson(getJsonPath('/BookManagement/SupplierMakePayment.json')),
            this.loadJson(getJsonPath('/BookManagement/SupplierMakePaymentAdvance.json')),
        ]).then(res => this.setState({ schema: res[0], advanceSchema: res[1] }));
    }

    onLoadMoreInvoices = () => {
        this.setState(prevState => ({
            page: prevState.page + 1,
        }), () => {
            this.props.reloadInvoiceAndAdvance(this.state.page);
        });
    }

    onUploadAttachment = (attachment) => {
        const { setFieldValue } = this.props;
        setFieldValue('attachment', attachment);
    };

    loadJson = jsonApi => new Promise((resolve, reject) => {
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
                reject(error);
            });
    });

    saveAndPayLater = () => {
        const { setFieldValue, submitForm } = this.props;
        setFieldValue('state', 'draft');
        submitForm();
    };

    confirmAndPay = () => {
        const { setFieldValue, submitForm } = this.props;
        setFieldValue('state', 'confirm');
        submitForm();
    };

    deleteInvoicePayment = () => {
        const { values, dispatch, handleClose } = this.props;
        const invoicePaymentId = getStringFromObject('invoicePaymentId', values);
        if (invoicePaymentId) {
            dispatch(displayWarning(
                'Are you sure want to delete this draft payment ? This operation can not be undone !!',
                () => {
                    dispatch(commonDeleteApiRequest(
                        getUrlWithApiParams(API.ACCOUNT_INVOICE.INVOICE_PAYMENTS, { invoicePaymentId }),
                        {
                            successCallback: () => handleClose(),
                            successMessage: 'Successfully deleted invoice payment',
                            failureMessage: 'Failed to delete invoice payment',
                        },
                    ));
                },
            ));
        }
    };

    handleAutoRecalculate = () => {
        const { dispatch, reloadInvoiceAndAdvance } = this.props;
        dispatch(displayWarning(
            'Are you sure want to reload advances, invoices and auto reconcile advance amounts ? ' +
            'Any unsaved changes will be lost !!',
            reloadInvoiceAndAdvance,
        ));
    };

    handleKeyPressSearch = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            this.handleApplyMasterAmount();
        }
    };

    handleApplyMasterAmount = () => {
        const { values, setValues } = this.props;
        setValues(calculateTotals(autoReconcile(values)));
    };

    renderAdvanceTotalRow = () => (
        isArrayValidAndNotEmpty(getStringFromObject('unreconciledAdvance', this.props.values)) &&
        <TableRow>
            <TableCell colSpan="1" />
            <TableCell><b>Total</b></TableCell>
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalAdvance', this.props.values))}</b>
            </TableCell>
            <TableCell colSpan="1" />
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalAllocatedAdvance', this.props.values))}</b>
            </TableCell>
            <TableCell colSpan="1" />
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalAdvanceUsed', this.props.values))}</b>
            </TableCell>
        </TableRow>
    );

    renderCreditMemoTotalRow = () => (
        isArrayValidAndNotEmpty(getStringFromObject('creditMemo', this.props.values)) &&
        <TableRow>
            <TableCell colSpan="1" />
            <TableCell><b>Total</b></TableCell>
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalCreditMemo', this.props.values))}</b>
            </TableCell>
            <TableCell colSpan="1" />
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalAllocatedCreditMemo', this.props.values))}</b>
            </TableCell>
            <TableCell colSpan="1" />
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalCreditMemoUsed', this.props.values))}</b>
            </TableCell>
        </TableRow>
    );

    renderInvoiceTotalRow = () => (
        isArrayValidAndNotEmpty(getStringFromObject('accountInvoices', this.props.values)) &&
        <TableRow>
            <TableCell colSpan="1" />
            <TableCell><b>Total</b></TableCell>
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalInvoiceAmount', this.props.values))}</b>
            </TableCell>
            <TableCell>
                <b>&nbsp;{NumberOf(getStringFromObject('totalAmountPaid', this.props.values))}</b>
            </TableCell>
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalOutstanding', this.props.values))}</b>
            </TableCell>
            <TableCell colSpan="1" />
            <TableCell>
                <b>{NumberOf(getStringFromObject('totalAllocated', this.props.values))}</b>
            </TableCell>
        </TableRow>
    );

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            handleReset,
            errors,
            reloadInvoiceAndAdvance,
            appConfiguration,
            accountInvoices,
        } = this.props;
        const {
            schema,
            advanceSchema,
        } = this.state;
        const subCompany = getStringFromObject('payor.subCompany', values);
        const invoicePaymentId = getStringFromObject('invoicePaymentId', values);
        const paymentMethod = getStringFromObject('paymentMethod.value', values);
        const amountToPay = NumberOf(getStringFromObject('amountToPay', values));
        const isCheque = stringEqualsIgnoreCase(paymentMethod, PAYMENT_METHODS_MAP.CHEQUE.value);
        const isBank = stringEqualsIgnoreCase(paymentMethod, PAYMENT_METHODS_MAP.BANKTRANSFER.value);
        const isCash = stringEqualsIgnoreCase(paymentMethod, PAYMENT_METHODS_MAP.CASH.value);
        const invoiceError = getStringFromObject('accountInvoices._error', errors);
        const canSavePayment =
            checkIfPrivilegeExistsForUser(getStringFromObject('purchasePaymentSavePrivilege', appConfiguration) || []);
        const canConfirmPayment =
            checkIfPrivilegeExistsForUser(getStringFromObject('purchasePaymentConfirmPrivilege', appConfiguration) || []);
        console.log('fandjfadlfa', this.props);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    Pay Invoice
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="pay-invoice-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" justify="flex-start" spacing={24}>
                                <Grid item lg={3} md={4} sm={5}>
                                    <Field
                                        test-id="purachase-invoice-payer"
                                        name="payor"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        label="Supplier"
                                        autocomplete
                                        required
                                        validate={required}
                                        isDisabled
                                        dataSourceApi={API.SEARCH.SUPPLIER}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        test-id="purachase-invoice-paymentdate"
                                        name="paymentDate"
                                        component={DateInput}
                                        label="Payment Date"
                                        validate={required}
                                        required
                                        placeholder="Payment Date"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="mt-1" justify="space-between">
                                <Grid item>
                                    <Grid container justify="flex-start" spacing={32}>
                                        <Grid item>
                                            <Field
                                                testId="purachase-invoice-paymentmethod"
                                                name="paymentMethod"
                                                component={FormikReactSelectMaterial}
                                                validate={amountToPay > 0 ? required : false}
                                                dataSourceConfig={{
                                                    text: 'label',
                                                    value: 'value',
                                                }}
                                                disabled={amountToPay <= 0}
                                                label="Payment Method"
                                                placeholder="Payment Method"
                                                options={PAYMENT_METHODS}
                                                required={amountToPay > 0}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                test-id="purachase-invoice-depositTo"
                                                name="depositTo"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'value',
                                                    value: 'key',
                                                }}
                                                label="Credit From"
                                                autocomplete
                                                required={amountToPay > 0}
                                                validate={amountToPay > 0 ? required : false}
                                                key={subCompany}
                                                dataSourceApi={
                                                    `${API.SEARCH.ACCOUNTS
                                                    }?typeIgnoreList=consolidation,view&isSupplierPayment=true&type=liquidity&subCompany=${subCompany || ''}&searchString=`
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item />
                            </Grid>
                            <Grid container justify="flex-start" className="mt-1">
                                {
                                    (isCheque || isBank) &&
                                    <div className="mr-1">
                                        <Field
                                            test-id="purachase-invoice-bankname"
                                            name="bankName"
                                            required
                                            component={FormikTextField}
                                            label="Bank Name"
                                            placeholder="Bank Name"
                                            fullWidth
                                        />
                                    </div>
                                }
                                <div>
                                    <Field
                                        test-id="purachase-invoice-referenceno"
                                        name="referenceNumber"
                                        component={FormikTextField}
                                        fullWidth
                                        required
                                        label={paymentMethod === 'CHEQUE' ? 'Cheque No' : 'Reference No'}
                                        placeholder={paymentMethod === 'CHEQUE' ? 'Cheque No' : 'Reference No'}
                                        validate={required}
                                    />
                                </div>
                                {
                                    (isCheque || isBank) &&
                                    <div className="ml-1">
                                        <Field
                                            test-id="purachase-invoice-dateOfTransfer"
                                            name="date"
                                            component={DateInput}
                                            label={isCheque ? 'Cheque Date' : 'Date'}
                                            validate={required}
                                            required
                                            InputProps={{
                                                'data-test-id': 'select-date',
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (isCheque || isBank) &&
                                    <div className="ml-2">
                                        <Field
                                            test-id="purachase-invoice-remarks"
                                            name="remarks"
                                            fullWidth
                                            component={FormikTextField}
                                            label="Remarks"
                                            multiline
                                            placeholder="Remarks"
                                        />
                                    </div>
                                }
                            </Grid>
                            {
                                isArrayValidAndNotEmpty(getStringFromObject('unreconciledAdvance', values)) &&
                                <div className="mt-2" style={{ background: '#fff' }}>
                                    <Grid container justify="space-between" className="p2">
                                        Unreconciled Payment Details
                                    </Grid>
                                    <Grid container>
                                        {
                                            isObjectValidAndNotEmpty(advanceSchema) &&
                                            <FormikTable
                                                test-id="purachase-invoice-unreconciledAdvance"
                                                fieldName="unreconciledAdvance"
                                                emptyMessage="No Unreconciled Payments"
                                                {...advanceSchema}
                                                id="supplierPaymentAdvance"
                                                name="supplierPaymentAdvance"
                                                actionHandlers={ACTION_HANDLERS}
                                                getExtraRows={this.renderAdvanceTotalRow}
                                            />
                                        }
                                    </Grid>
                                </div>
                            }
                            {
                                isArrayValidAndNotEmpty(getStringFromObject('creditMemo', values)) &&
                                <div className="mt-2" style={{ background: '#fff' }}>
                                    <Grid container justify="space-between" className="p2">
                                        Credit Memo Details
                                    </Grid>
                                    <Grid container>
                                        {
                                            isObjectValidAndNotEmpty(advanceSchema) &&
                                            <FormikTable
                                                test-id="purachase-invoice-credit-memo"
                                                fieldName="creditMemo"
                                                emptyMessage="No Unreconciled Credit Memo"
                                                {...advanceSchema}
                                                id="supplierPaymentCreditMemo"
                                                name="supplierPaymentCreditMemo"
                                                actionHandlers={ACTION_HANDLERS}
                                                getExtraRows={this.renderCreditMemoTotalRow}
                                            />
                                        }
                                    </Grid>
                                </div>
                            }
                            <div className="mt-1" style={{ background: '#fff' }}>
                                <Grid container justify="space-between" className="p2">
                                    <Grid item>
                                        Outstanding Transactions
                                        <span className="bigLabel">
                                            (Only showing oldest {(accountInvoices || []).length} invoices)
                                        </span>
                                        {
                                            isValidFunction(reloadInvoiceAndAdvance) &&
                                            ((accountInvoices || []).length % 100 === 0) &&
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={this.onLoadMoreInvoices}
                                            >
                                                Load More
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Field
                                            name="masterAmount"
                                            component={FormikTextField}
                                            type="number"
                                            label="Enter Amount to Apply"
                                            onKeyPress={this.handleKeyPressSearch}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleApplyMasterAmount}
                                                            data-test-id="confirm-amount"
                                                        >
                                                            <CheckIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {
                                        isObjectValidAndNotEmpty(schema) &&
                                        <FormikTable
                                            test-id="purachase-invoice-accountInvoices"
                                            fieldName="accountInvoices"
                                            emptyMessage="No outstanding transactions"
                                            {...schema}
                                            actionHandlers={ACTION_HANDLERS}
                                            getExtraRows={this.renderInvoiceTotalRow}
                                        />
                                    }
                                    {
                                        invoiceError &&
                                        <div className="error-text" style={{ width: '100%', textAlign: 'right', marginTop: '1em' }}>
                                            {invoiceError}
                                        </div>
                                    }
                                </Grid>
                                <Grid container className="mt-1" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            test-id="purachase-invoice-comment-memo"
                                            name="narration"
                                            component={FormikTextField}
                                            label="Memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4} className="flexChildren">
                                        <h4>Attachments</h4>:
                                        <ReduxFormChipFileUpload
                                            className="ml-1"
                                            disabled={false}
                                            customFileLabel="Attachments"
                                            uploadApi={API.FILE.UPLOAD}
                                            supportedTypes={[...imageFileTypes, ...documentFileTypes]}
                                            input={{
                                                value: values.attachment,
                                                onChange: this.onUploadAttachment,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={4} md={3} lg={2} />
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <Grid container justify="flex-end">
                                        <div>
                                            <Field
                                                name="totalAdvanceUsed"
                                                component={FormikTextField}
                                                type="number"
                                                label="Total Advance Used"
                                                disabled
                                            />
                                        </div>
                                        <div className="ml-1">
                                            <Field
                                                name="totalCreditMemoUsed"
                                                component={FormikTextField}
                                                type="number"
                                                label="Total Credit Memo Used"
                                                disabled
                                            />
                                        </div>
                                        <div className="ml-1">
                                            <Field
                                                name="amountToPay"
                                                component={FormikTextField}
                                                type="number"
                                                label="Amount To Pay"
                                                disabled
                                            />
                                        </div>
                                    </Grid>
                                </div>
                                <div>
                                    <Grid container justify="space-between" className="mt-1">
                                        <Grid item>
                                            <Grid container justify="flex-start">
                                                {
                                                    isValidFunction(reloadInvoiceAndAdvance) &&
                                                    <ActionButton
                                                        test-id="purchase-invoice-auto-recalculate"
                                                        className={classes.floatLeft}
                                                        disableRipple
                                                        onClick={this.handleAutoRecalculate}
                                                    >
                                                        Auto Recalculate
                                                    </ActionButton>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container justify="flex-end">
                                                <ActionButton
                                                    test-id="purchase-invoice-cancel-payment"
                                                    primary={false}
                                                    disableRipple
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </ActionButton>
                                                {
                                                    invoicePaymentId
                                                    && checkIfPrivilegeExistsForUser(deleteInvoicePaymentPrivilege) &&
                                                    <ActionButton
                                                        test-id="purchase-invoice-delete"
                                                        className="ml-1"
                                                        disableRipple
                                                        onClick={this.deleteInvoicePayment}
                                                    >
                                                        Delete
                                                    </ActionButton>
                                                }
                                                <ActionButton
                                                    test-id="purchase-invoice-reset"
                                                    className="ml-1"
                                                    disableRipple
                                                    onClick={handleReset}
                                                >
                                                    Reset
                                                </ActionButton>
                                                {
                                                    canSavePayment &&
                                                    <ActionButton
                                                        testId="purchase-invoice-save"
                                                        className="ml-1"
                                                        disableRipple
                                                        onClick={this.saveAndPayLater}
                                                    >
                                                        Save
                                                    </ActionButton>
                                                }
                                                {
                                                    (canConfirmPayment || (canSavePayment && !isCash)) &&
                                                    <ActionButton
                                                        testId="purchase-invoice-confirm"
                                                        className="ml-1"
                                                        disableRipple
                                                        onClick={this.confirmAndPay}
                                                    >
                                                        Confirm & Pay
                                                    </ActionButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogActions>
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

SupplierPaymentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    appConfiguration: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    supplierUuid: PropTypes.object,
    accountInvoices: PropTypes.array,
    invoicePayment: PropTypes.object,
    reloadInvoiceAndAdvance: PropTypes.func.isRequired,
    // handlePrintVoucher: PropTypes.func,
    /* formik props */
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

SupplierPaymentDialog.defaultProps = {
    supplierUuid: null,
    accountInvoices: null,
    invoicePayment: null,
    errors: null,
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
});

const handleSubmitForm = (values, { props, ...formikProps }) => {
    console.log('saving', values, formikProps);
    formikProps.setSubmitting(true);
    const errors = FORM_VALIDATOR(values);
    if (errors) {
        formikProps.setErrors(errors);
        formikProps.setSubmitting(false);
        return;
    }
    const payload = getPayload(values, formikProps);
    props.dispatch(commonPostApiRequest(API.RECEIVE_PAYMENT.CREATE, {
        payload,
        successCallback: (voucher) => {
            if (values.state === 'confirm' && isValidFunction(props.handlePrintVoucher)) {
                props.handlePrintVoucher(voucher);
            } else {
                props.handleClose();
            }
            formikProps.setSubmitting(false);
        },
        successMessage: 'Payment successfully done',
        failureMessage: 'Failed to make Payment',
        failureCallback: () => {
            formikProps.setSubmitting(false);
        },
    }));
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => getUiObjectFromProps(props),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(receivePaymentDialogStyles)(SupplierPaymentDialog)));
