/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { loginSilentRequest } from '../../redux/modules/login/login-actions';
import { errorMessage } from '../../redux/modules/message/message-actions';
import './TimedOutSessionDialog.css';
import muiTheme from '../../themes/baseTheme';
import { COMMON_APPLICATION_CONFIG_URL } from '../../constants/constants';
import RoundedCornerButton from '../../components/RoundedCornerButton';

class TimedOutSessionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
        };
    }

    onUserNameOrPasswordChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLoginAgain = () => {
        if (this.state.username && this.state.password) {
            // login
            const values = {
                username: this.state.username,
                password: this.state.password,
            };
            this.props.dispatch(loginSilentRequest(values, this.props.actionToDispatchAfterLogin));
        } else {
            this.props.dispatch(errorMessage('Username and password are mandatory'));
        }
    };

    handleKeyDown = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            this.onLoginAgain();
        }
    };


    render() {
        return (
            <Dialog
                open
                modal={false}
                style={{ margin: 0 }}
                bodyStyle={{ padding: 0 }}
                contentClassName="dialogContent"
            >
                <DialogTitle style={{ padding: '0' }}>
                    <div className="topBar" style={{ background: muiTheme.palette.primary1Color }}>
                        <img
                            src={`${COMMON_APPLICATION_CONFIG_URL}/Session_Timed_Out.svg`}
                            alt="Session Timed Out"
                            width="200px"
                            height="100px"
                        />
                        <h3 className="sessionExpiredText">
                            Your Session Has Expired Due To Inactivity
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="formSection">
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12}>
                                <h3 className="blackText">
                                    Login
                                </h3>
                            </Grid>
                        </Grid>
                        <form onKeyPress={this.handleKeyDown}>
                            <Grid container>
                                <Grid item sm={12} md={12} lg={12}>
                                    <TextField
                                        name="username"
                                        label="Username"
                                        placeholder="Username"
                                        autoFocus
                                        onChange={this.onUserNameOrPasswordChange}
                                        value={this.state.username}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12} md={12} lg={12}>
                                    <TextField
                                        name="password"
                                        type="password"
                                        fullWidth
                                        label="Password"
                                        placeholder="Password"
                                        onChange={this.onUserNameOrPasswordChange}
                                        value={this.state.password}
                                    />
                                </Grid>
                            </Grid>
                            {
                                this.props.silentSuccess === false &&
                                <Grid container style={{ marginTop: '1em' }}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <span style={{ color: 'red' }}>
                                            Username or password is invalid
                                        </span>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container style={{ marginTop: '1em' }}>
                                <Grid item sm={6} md={6} lg={6} />
                                <Grid item lg={6} md={6} sm={6} style={{ textAlign: 'right', width: '100%' }}>
                                    <div className="pull-right">
                                        <RoundedCornerButton
                                            label="Login"
                                            backgroundColor="#1E88E5"
                                            color="#ffffff"
                                            onClick={this.onLoginAgain}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

TimedOutSessionDialog.propTypes = {
    dispatch: PropTypes.func,
    actionToDispatchAfterLogin: PropTypes.string,
    silentSuccess: PropTypes.bool,
};

TimedOutSessionDialog.defaultProps = {
    dispatch: () => {},
    actionToDispatchAfterLogin: '',
    silentSuccess: false,
};

export default TimedOutSessionDialog;
