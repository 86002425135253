/**
 * Created by vinay on 5/4/19.
 */
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { removeTimeFromDate, setMaxTimeForDate } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';


const getSearchTextConstruct = (searchValue, constructKeys) => {
    let construct = {};
    let searchText = `${searchValue || ''}`;
    if (searchText) {
        searchText = searchText.trim();
        let operation = ':';
        if (searchText.endsWith('@')) {
            operation = '=';
            searchText = searchText.substring(0, searchText.length - 1);
        }
        if (isArrayValidAndNotEmpty(constructKeys)) {
            constructKeys.forEach((key) => {
                construct = getCombined(
                    construct,
                    'OR',
                    generateObjectWithCriteriaMode(key, searchText, operation),
                );
            });
        }
    }
    return construct;
};

const getDateFilter = (dateFromKey, dateToKey, startDate, endDate) => {
    let construct = null;
    if (startDate && endDate) {
        construct = getCombined(
            construct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode(
                    dateFromKey, removeTimeFromDate(startDate).getTime(), '>=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    dateToKey, setMaxTimeForDate(endDate).getTime(), '<=',
                ),
            ),
        );
    } else if (startDate) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode(
                dateFromKey, removeTimeFromDate(startDate).getTime(), '>=',
            ),
        );
    } else if (endDate) {
        construct = getCombined(
            construct,
            'AND',
            generateObjectWithCriteriaMode(
                dateToKey, setMaxTimeForDate(endDate).getTime(), '<=',
            ),
        );
    }
    return construct;
};

export const generatePayrollBatchesConstruct = (filters) => {
    let construct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const periodUuid = getStringFromObject('period.uuid', filters);
        if (periodUuid) {
            construct = generateObjectWithCriteriaMode('period.uuid', periodUuid, '=');
        }
        const status = getStringFromObject('status.key', filters);
        if (status) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('hrPayslipBatch.state', status, '='),
            );
        }
        const department = getStringFromObject('department.key', filters);
        if (department) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('department.uuid', department, '='),
            );
        }
        const searchText = getStringFromObject('searchText', filters);
        if (searchText) {
            construct = getCombined(
                construct,
                'AND',
                getSearchTextConstruct(searchText, ['hrPayslipBatch.name']),
            );
        }
        const startDate = getStringFromObject('createDate.from', filters);
        const endDate = getStringFromObject('createDate.to', filters);
        construct = getCombined(
            construct,
            'AND',
            getDateFilter('hrPayslipBatch.createDate', 'hrPayslipBatch.createDate', startDate, endDate),
        );
    }
    return construct;
};

export const generatePayslipsConstruct = (filters) => {
    let construct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const periodUuid = getStringFromObject('period.uuid', filters);
        if (periodUuid) {
            construct = generateObjectWithCriteriaMode('period.uuid', periodUuid, '=');
        }
        const statusIn = getStringFromObject('status', filters, []);
        if (isArrayValidAndNotEmpty(statusIn)) {
            let stateCriteria = null;
            statusIn.forEach((s) => {
                const status = getStringFromObject('key', s);
                if (status) {
                    stateCriteria = getCombined(
                        stateCriteria,
                        'OR',
                        generateObjectWithCriteriaMode('hrPayslip.state', status, '='),
                    );
                }
            });
            construct = getCombined(
                construct,
                'AND',
                stateCriteria,
            );
        }
        const department = getStringFromObject('department.key', filters);
        if (department) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('hrDepartment.id', department, '='),
            );
        }
        const employee = getStringFromObject('employee.key', filters);
        if (employee) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('hrEmployee.uuid', employee, '='),
            );
        }
        const searchText = getStringFromObject('searchText', filters);
        if (searchText) {
            construct = getCombined(
                construct,
                'AND',
                getSearchTextConstruct(searchText, [
                    'hrPayslip.name', 'hrEmployee.nameRelated', 'hrEmployee.identificationId', 'batch.name',
                ]),
            );
        }
        const startDate = getStringFromObject('createDate.from', filters);
        const endDate = getStringFromObject('createDate.to', filters);
        construct = getCombined(
            construct,
            'AND',
            getDateFilter('hrPayslip.createDate', 'hrPayslip.createDate', startDate, endDate),
        );
    }
    return construct;
};

export const generatePaymentAdviceConstruct = (filters) => {
    let construct = null;
    if (isObjectValidAndNotEmpty(filters)) {
        const status = getStringFromObject('status.key', filters);
        if (status) {
            construct = generateObjectWithCriteriaMode('hrPaymentAdvice.status', status, '=');
        }
        const employee = getStringFromObject('employee.key', filters);
        if (employee) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('hrEmployee.uuid', employee, '='),
            );
        }
        const period = getStringFromObject('period.uuid', filters);
        if (period) {
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('period.uuid', period, '='),
            );
        }
        const searchText = getStringFromObject('searchText', filters);
        if (searchText) {
            construct = getCombined(
                construct,
                'AND',
                getSearchTextConstruct(searchText, [
                    'hrPaymentAdvice.name',
                ]),
            );
        }
        const startDate = getStringFromObject('createDate.from', filters);
        const endDate = getStringFromObject('createDate.to', filters);
        construct = getCombined(
            construct,
            'AND',
            getDateFilter('hrPaymentAdvice.createDate', 'hrPaymentAdvice.createDate', startDate, endDate),
        );
    }
    return construct;
};
