import cloneDeep from 'clone-deep';
import { MaterialReceiveFields } from '../components/PurchaseOrderComponents/MaterialReceiveDialog/MaterialReceiveUtil';
import {
    isArrayValidAndNotEmpty,
    valueToFixedTwoDigits,
    sortArrayOfObjectsByFieldValue,
} from '../constants/CommonUtil';
import { getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';


export const EMPTY_ROW = {
    product: '',
    batch: '',
    uom: '',
    quantity: '',
    cost: '',
    taxes: [],
};

export const UI_OBJECT = ({
    [MaterialReceiveFields.LOCATION]: '',
    [MaterialReceiveFields.STATE]: '',
    [MaterialReceiveFields.NOTES]: '',
    [MaterialReceiveFields.CASH_PURCHASE]: false,
    [MaterialReceiveFields.CASH_SUPPLIER_NAME]: '',
    [MaterialReceiveFields.CASH_SUPPLIER_VAT_NUMBER]: '',
    [MaterialReceiveFields.DATE]: new Date().getTime(),
    [MaterialReceiveFields.PRODUCTS]: [cloneDeep(EMPTY_ROW)],
});

export const RECEIVED_MATERIAL_OBJECT = {
    supplier: null,
    stockPickingLinesDTOSet: [],
    sourceLoc: null,
};

export const MATERIAL_RECEIVE_OPERATION = {
    SAVE: 'DRAFT',
    CONFIRMED: 'COMPLETE',
};

export const mapReceiveMaterialFromUiObject = (uiObject, action) => {
    const payload = {};
    if (isObjectValidAndNotEmpty(uiObject)) {
        console.log('mapStockIntentFromUiObject', uiObject);
        console.log('mapStockIntentFromUiObject', !!uiObject.uuid, uiObject.uuid !== '');
        if (!!uiObject.uuid && uiObject.uuid !== '') {
            payload.uuid = uiObject.uuid;
        }
        let totalAmount = 0;


        payload.memo = getStringFromObject(MaterialReceiveFields.NOTES, uiObject);
        payload.cashSupplierSubCompany = getStringFromObject(`${MaterialReceiveFields.CASH_SUPPLIER_SUB_COMPANY}.key`, uiObject) || null;
        payload.state = action;
        payload.dispatch = getStringFromObject('dispatch', uiObject);
        // payload.origin = uiObject.reference;
        payload.location = {
            key: getStringFromObject(`${MaterialReceiveFields.LOCATION}.uuid`, uiObject, null),
            value: getStringFromObject(`${MaterialReceiveFields.LOCATION}.name`, uiObject, ''),
        };
        const isCashPurchase = getStringFromObject(`${MaterialReceiveFields.CASH_PURCHASE}`, uiObject) || false;
        if (isCashPurchase) {
            payload.isCashPurchase = true;
            payload.cashSupplierName =
                getStringFromObject(`${MaterialReceiveFields.CASH_SUPPLIER_NAME}`, uiObject) || '';
            payload.cashSupplierVatNumber =
                getStringFromObject(`${MaterialReceiveFields.CASH_SUPPLIER_VAT_NUMBER}`, uiObject) || '';
        } else {
            payload.supplier = {
                key: getStringFromObject(`${MaterialReceiveFields.SUPPLIER}.uuid`, uiObject, null),
                value: getStringFromObject(`${MaterialReceiveFields.SUPPLIER}.name`, uiObject, ''),
            };
        }
        payload.subVendor = getStringFromObject(MaterialReceiveFields.SUB_VENDOR, uiObject) || false;
        const lines = uiObject.receiveMaterialLines;
        const materialReceiveLines = [];
        if (isArrayValidAndNotEmpty(lines)) {
            let sequence = 0;
            lines.forEach((line) => {
                console.log('this is a line', line);
                if (line.product && line.product.uuid) {
                    const mLine = {};
                    sequence += 1;
                    mLine.productId = {
                        key: getStringFromObject('product.uuid', line, null),
                        value: getStringFromObject('product.productName', line, ''),
                    };
                    mLine.quantity = getStringFromObject('quantity', line, 0);
                    mLine.bonus = getStringFromObject('bonus', line, 0);
                    mLine.price = getStringFromObject('price', line, 0);
                    mLine.discount1 = getStringFromObject('discount1', line, 0);
                    mLine.discount2 = getStringFromObject('discount2', line, 0);
                    mLine.totalPrice = getStringFromObject('totalPrice', line, 0);
                    mLine.batchName = getStringFromObject('batch', line, '');
                    mLine.uuid = getStringFromObject('uuid', line) || null;
                    mLine.sequence = sequence;
                    mLine.productGtinMapDto = getStringFromObject('productGtinMapDto', line) || null;

                    mLine.expiryDate = new Date(getStringFromObject('expiry', line, 0)).getTime();
                    // expiryDate= line.expiryDate ? formatDate(line.expiryDate, 'yyyy-MM-dd hh:mm:ss') : null,
                    mLine.taxAccountDetails =
                        getStringFromObject('taxes', line, []);
                    totalAmount += NumberOf(line.totalPrice);
                    materialReceiveLines.push(mLine);
                }
            });
        }
        let discountAmount = NumberOf(uiObject.discount);
        const discountPercent = NumberOf(uiObject.discountPercent);
        if (discountPercent > 0) {
            discountAmount = totalAmount * (discountPercent / 100);
        }
        payload.discount = valueToFixedTwoDigits(discountAmount);

        payload.lineDtoList = materialReceiveLines;
        payload.date = getStringFromObject('date', uiObject) || new Date().getTime;
    }
    console.log('mapMaterialReceiveFromUiObject payload', payload);
    return payload;
};
const mapMaterialReceiveLineToUiObject = (lines) => {
    const materialReceiveLines = lines.map((line, index) => {
        const amount = NumberOf(line.price) * NumberOf(line.quantity);
        const discount1 = getStringFromObject('discount1', line);
        const discount2 = getStringFromObject('discount2', line);
        const discountAmount = amount * NumberOf(discount1) * 0.01;
        const discountAmount2 = (amount - discountAmount) * (NumberOf(discount2) * 0.01);
        const obj = {
            uuid: getStringFromObject('uuid', line),
            materialReceiveUuid: getStringFromObject('materialReceiveUuid', line),

            product: {
                productName: getStringFromObject('productId.value', line, ''),
                uuid: getStringFromObject('productId.key', line, null),
            },
            trackByBatch: getStringFromObject('productTrackByBatch', line),
            uom: {
                name: getStringFromObject('productUom.value', line, ''),
                uuid: getStringFromObject('productUom.key', line, null),
            },
            price: getStringFromObject('price', line, 0),
            totalPrice: getStringFromObject('totalPrice', line, 0),
            taxes: getStringFromObject('taxAccountDetails', line, []),
            discount1: getStringFromObject('discount1', line, 0),
            discount2: getStringFromObject('discount2', line, 0),
            quantity: getStringFromObject('quantity', line, 0),
            bonus: getStringFromObject('bonus', line, 0),
            batch: getStringFromObject('batchName', line, ''),
            expiry: line.expiryDate ? getDateInYYYYMMDDFormat(new Date(line.expiryDate)) : '',
            sequence: line.sequence || index,
            productGtinMapDto: getStringFromObject('productGtinMapDto', line) || null,
            // trackByBatch: getStringFromObject('productTrackByBatch', line, false),
        };
        let applicableTax = 0;
        if (isArrayValidAndNotEmpty(obj.taxes)) {
            obj.taxes.forEach((tax) => {
                applicableTax += NumberOf(tax.amount);
            });
        }
        const amountBeforeTax = amount - (discountAmount + discountAmount2);
        const totalTax = NumberOf(applicableTax) * NumberOf(amountBeforeTax);
        obj.totalTax = valueToFixedTwoDigits(totalTax);
        console.log('asdfsdfdsfsdfdsf1', {
            obj,
            discountAmount,
            discountAmount2,
            applicableTax,
            amount,
        });
        obj.amountBeforeTax = valueToFixedTwoDigits(amountBeforeTax);
        return obj;
    });
    return sortArrayOfObjectsByFieldValue(materialReceiveLines, 'sequence');
};


export const mapMaterialReceiveToUiObject = (materialReceive) => {
    console.log('mapMaterialReceiveToUiObject', materialReceive);
    let uiObject = cloneDeep(UI_OBJECT);
    if (isObjectValidAndNotEmpty(materialReceive)) {
        uiObject = {
            ...uiObject,
            createDate:
                getStringFromObject('createDate', materialReceive),
            uuid: getStringFromObject('uuid', materialReceive, ''),
            id: getStringFromObject('id', materialReceive, ''),
            memo: getStringFromObject('memo', materialReceive, ''),
            supplier: {
                name: getStringFromObject('supplier.value', materialReceive, ''),
                uuid: getStringFromObject('supplier.key', materialReceive, null),
            },
            dispatch: getStringFromObject('dispatch', materialReceive, ''),
            discountPercent: getStringFromObject('discountPercent', materialReceive, null),
            discount: getStringFromObject('discount', materialReceive, null),
            status: getStringFromObject('state', materialReceive, ''),
            name: getStringFromObject('name', materialReceive, ''),
            invoice: getStringFromObject('invoice', materialReceive),
            number: getStringFromObject('number', materialReceive),
            date: getStringFromObject('date', materialReceive) || null,
            isCashPurchase: getStringFromObject('isCashPurchase', materialReceive) || '',
            cashSupplierName: getStringFromObject('cashSupplierName', materialReceive) || '',
            cashSupplierVatNumber: getStringFromObject('cashSupplierVatNumber', materialReceive) || '',
            cashSupplierSubCompany: {
                key: getStringFromObject(MaterialReceiveFields.CASH_SUPPLIER_SUB_COMPANY, materialReceive, ''),
                value: getStringFromObject(MaterialReceiveFields.CASH_SUPPLIER_SUB_COMPANY, materialReceive, ''),
            },
            subVendor: getStringFromObject(MaterialReceiveFields.SUB_VENDOR, materialReceive) || false,
        };
        if (isObjectValidAndNotEmpty(getStringFromObject('location', materialReceive))) {
            uiObject.location = {
                name: getStringFromObject('location.value', materialReceive, ''),
                uuid: getStringFromObject('location.key', materialReceive, null),
            };
        }

        const lineDtoList = getStringFromObject('lineDtoList', materialReceive, []);
        let products = [];
        if (isArrayValidAndNotEmpty(lineDtoList)) {
            products = (mapMaterialReceiveLineToUiObject(lineDtoList));
        } else {
            products = [cloneDeep(EMPTY_ROW)];
        }
        uiObject.receiveMaterialLines = products;
        console.log('mappedMaterialObject', uiObject);
    }
    return uiObject;
};
