import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { INBOX_FETCH_FAILURE, INBOX_FETCH_SUCCESS } from '../../redux/modules/inbox/inbox-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export default function* inboxFetchRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}?type=${action.inboxType}&page=${action.page}&size=${action.size}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: INBOX_FETCH_SUCCESS,
            data: response.data,
            inboxType: action.inboxType,
            page: action.page,
            size: action.size,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: INBOX_FETCH_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
