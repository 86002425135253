import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';

import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { isArrayValidAndNotEmpty, isValidFunction } from '../../../constants/CommonUtil';


const styles = () => ({
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

const FormikOutlinedSelectField = (props) => {
    const {
        field: {
            name,
            value,
            onChange,
            onBlur,
            onFocus,
        },
        form,
        label,
        menuItems,
        InputProps,
        classes,
        dataSourceConfig,
        onChangeHandlers,
        ...otherProps
    } = props;

    const callFormHandlers = (handlers, newValue, fieldName, compProps) => {
        const { actionHandlers } = this.props;
        console.log('callFormHandlerscallFormHandlers', {
            handlers,
            newValue,
            fieldName,
            actionHandlers,
        });
        if (isArrayValidAndNotEmpty(handlers)) {
            handlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(newValue, fieldName, compProps);
                }
            });
        }
    };

    const handleChange = (e) => {
        onChange(e);
        console.log('callFormHandlerscallFormHandlers', {
            onChangeHandlers,
        });
        callFormHandlers(onChangeHandlers, e.target.value, name, props);
        console.log('callFormHandlerscallFormHandlers', {
            onChangeHandlers,
        });
    };
    console.log('TestName', name);
    const error = getStringFromObject(`errors.${name}`, form);
    const inputProps = isObjectValidAndNotEmpty(InputProps) ? { ...InputProps } : {};

    return (
        <TextField
            {...otherProps}
            name={name}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            select
            variant="outlined"
            label={label}
            error={error || false}
            helperText={error || ''}
            InputProps={{
                ...inputProps,
                classes: {
                    input: classes.input,
                },
            }}
        >
            {menuItems}
        </TextField>
    );
};

FormikOutlinedSelectField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    menuItems: PropTypes.node,
    label: PropTypes.any,
    dataSourceConfig: PropTypes.object,
    classes: PropTypes.object.isRequired,
    InputProps: PropTypes.object,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
};

FormikOutlinedSelectField.defaultProps = {
    menuItems: <div />,
    label: null,
    dataSourceConfig: {},
    InputProps: {},
    onChangeHandlers: [],
    actionHandlers: {},
};

export default withStyles(styles)(FormikOutlinedSelectField);

