/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import EMailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ExtensionIcon from '@material-ui/icons/Extension';
import Avatar from '@material-ui/core/Avatar';
import Cake from '@material-ui/icons/Cake';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import './OrganisationListView.css';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import ChangePassword from '../../containers/EmployeeProfileContainer/ChangePassword';

import { getStringFromObject } from '../../constants/lodashUtils';

const styles = () => ({
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 100,
        height: 100,
        border: '2px solid #0000001f',
    },
    icon: {
        height: '1.5rem',
        width: '1.5rem',
    },
    changePassword: {
        color: '#14A8FF',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
});

class OrganisationListview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedEmployeeUuid: null,
        };
    }

    getList = (employeeDetail) => {
        console.log('djkvsfdl', employeeDetail);
        const { classes, handleProfileClick, showResetPasswordInOrganisationPrivilege } = this.props;
        return (
            <Card
                key={employeeDetail.uuid}
                style={{
                    backgroundColor: employeeDetail.retired ? '#FFCCCB' : 'white',
                    borderLeft: '14px solid #ABCDFF',
                    borderRadius: '5px',
                    marginBottom: '1rem',
                    display: 'flex',
                    padding: '1rem',
                    cursor: 'pointer',
                }}
                onClick={() => handleProfileClick(employeeDetail.uuid)}
            >
                <Grid container alignItems="center" justify="space-between">
                    <Grid item lg={4} md={5} sm={5} alignItems="center" className="flexChildren" test-id={`employee-image_${employeeDetail.userName}`}>
                        <div style={{ padding: '0 2rem' }}>
                            <Avatar
                                alt="profile"
                                src={employeeDetail.profileImage}
                                className={classNames(classes.avatar, classes.bigAvatar)}
                            />
                        </div>
                        <div>
                            <div className="org-list-view-name-container">
                                {employeeDetail.name}
                            </div>
                            <div className="org-list-view-desg-container">
                                {employeeDetail.designation}
                            </div>
                            {
                                checkIfPrivilegeExistsForUser(showResetPasswordInOrganisationPrivilege) && (
                                    <div className="org-list-view-desg-container">
                                        <div
                                            className={classes.changePassword}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                console.log('a0s9ud0uasd-0as', employeeDetail);
                                                this.toggleChangePassword(employeeDetail.uuid)();
                                            }}
                                        >
                                            <span>Reset Password</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={7} test-id={`employee-details_${employeeDetail.userName}`}>
                        <Grid container>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <span>
                                    <PersonIcon className="basic-info-icon--gray" />
                                </span>
                                <span className="p0-4">Employee ID:</span>
                                <span className="employee-details">
                                    {employeeDetail.empId}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <span>
                                    <img
                                        src={`${import.meta.env.BASE_URL}/profile/department.svg`}
                                        alt=""
                                        className="org-list-view-image-size-small"
                                    />
                                </span>
                                <span className="p0-4"> Department:</span>
                                <span className="employee-details">
                                    {employeeDetail.department}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <EMailIcon
                                    style={{
                                        color: '#808080',
                                    }}
                                />
                                <span className="p0-4"> Work Email:</span>
                                <span className="employee-details">
                                    {employeeDetail.email}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <PersonIcon className="basic-info-icon--gray" />
                                <span className="p0-4"> Username:</span>
                                <span className="employee-details">
                                    {employeeDetail.userName}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <PhoneIcon
                                    style={{
                                        color: '#808080',
                                    }}
                                />
                                <span className="p0-4">Work Phone:</span>
                                <span className="employee-details">
                                    {employeeDetail.phoneNo}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <span>
                                    <img
                                        src={`${import.meta.env.BASE_URL}/profile/manager.svg`}
                                        alt=""
                                        className="org-list-view-image-size-small"
                                    />
                                </span>
                                <span className="p0-4">Reporting manager:</span>
                                <span className="employee-details">
                                    {employeeDetail.reportingManager}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <Cake
                                    style={{
                                        color: '#808080',
                                    }}
                                />
                                <span className="p0-4">Birthday : </span>
                                <span className="employee-details">
                                    {employeeDetail.birthday}
                                </span>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={6}
                                className={classNames('min-h2-5', 'display-flex', 'align-items-center')}
                            >
                                <ExtensionIcon
                                    style={{
                                        color: '#808080',
                                    }}
                                />
                                <span className="p0-4">Extension : </span>
                                <span className="employee-details">
                                    {employeeDetail.extension}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        );
    };

    toggleChangePassword = selectedEmployeeUuid => () => {
        this.setState(prevState => ({
            open: !prevState.open,
            selectedEmployeeUuid,
        }));
    };

    render() {
        const {
            employeeList,
            showResetPasswordInOrganisationPrivilege,
        } = this.props;
        const { open, selectedEmployeeUuid } = this.state;
        return (
            <React.Fragment>
                {
                    employeeList.map(employeeDetail => (this.getList(employeeDetail)))
                }
                {
                    open &&
                    <ChangePassword
                        open={open}
                        employeeUuid={selectedEmployeeUuid}
                        handleClose={this.toggleChangePassword(null)}
                        isOrganisationAdmin={
                            checkIfPrivilegeExistsForUser(
                                showResetPasswordInOrganisationPrivilege,
                            )
                        }
                    />
                }
            </React.Fragment>
        );
    }
}
OrganisationListview.propTypes = {
    classes: PropTypes.object.isRequired,
    employeeList: PropTypes.array,
    showResetPasswordInOrganisationPrivilege: PropTypes.array,
    handleProfileClick: PropTypes.func,
};

OrganisationListview.defaultProps = {
    employeeList: [],
    showResetPasswordInOrganisationPrivilege: ['nuacare.show.organisation.reset.password'],
    handleProfileClick: () => {},
};

const mapStateToProps = state => ({
    showResetPasswordInOrganisationPrivilege:
        getStringFromObject('showResetPasswordInOrganisationPrivilege', state.appConfiguration) ||
        ['nuacare.show.organisation.reset.password'],
});

export default withStyles(styles)(connect(mapStateToProps)(OrganisationListview));
