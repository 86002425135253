import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import memoizeOne from 'memoize-one';
import HandleBars from 'handlebars/dist/cjs/handlebars';
import cloneDeep from 'clone-deep';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../../constants/api';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,


} from '../../../constants/CommonUtil';
import { formatDateForDisplay } from '../../../constants/DateUtil';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import DialogComponent from '../../../components/DialogComponent/DialogComponent';
import ActionButton from '../../../components/ActionButton/ActionButton';
import ClaimDocumentsListViewer from '../../../components/ClaimDocumentsListViewer/ClaimDocumentsListViewer';
import ImageGallery from '../../../components/GalleryPopup/ImageGallery';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


HandleBars.registerHelper('upperCase', string => string && string.toString().toUpperCase());
HandleBars.registerHelper('isDefined', string => string || string === 0);
HandleBars.registerHelper('isNotDefined', string => !(string || string === 0));
HandleBars.registerHelper('showNormalRange', (resultLimit) => {
    console.log('er1324123', resultLimit);
    if (resultLimit) {
        let {
            lowNormal,
            highNormal,
        } = resultLimit;
        let showNormalRange = false;
        if ((lowNormal || lowNormal === 0) && (highNormal || highNormal === 0)) {
            showNormalRange = true;
        }
        if (showNormalRange) {
            if (lowNormal === 0) {
                lowNormal = '< ';
            }
            if (highNormal === 0) {
                highNormal = lowNormal;
                lowNormal = '> ';
            }
        }
        if (showNormalRange) {
            return `${lowNormal} ${typeof lowNormal === 'string' ? '' : '-'} ${highNormal}`;
        }
    }
    return '';
});
HandleBars.registerHelper('ifEquals', (arg1, arg2) => (arg1 === arg2));
HandleBars.registerHelper('ifAreEquals', (arg1, arg2, arg3) => (arg1 !== null && (arg1 === arg2 || arg1 === arg3)));
HandleBars.registerHelper('formatDate', dateString => (formatDateForDisplay(new Date(dateString))));
// eslint-disable-next-line max-len
HandleBars.registerHelper('camelCase', (string) => {
    const stringLowerCase = string.toLowerCase();
    return stringLowerCase.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
});
HandleBars.registerHelper('showValidRange', (resultLimit) => {
    console.log('er1324123', resultLimit);
    if (resultLimit) {
        let {
            lowValid,
            highValid,
        } = resultLimit;
        let showValidRange = false;
        if ((lowValid || lowValid === 0) && (highValid || highValid === 0)) {
            showValidRange = true;
        }
        if (showValidRange) {
            if (lowValid === 0) {
                lowValid = '< ';
            }
            if (highValid === 0) {
                highValid = lowValid;
                lowValid = '> ';
            }
        }
        if (showValidRange) {
            return `Valid Range: ${lowValid} - ${highValid}`;
        }
    }
    return '';
});

HandleBars.registerHelper('empty', value => (value === null || (value !== null && value.length === 0)));

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        color: 'white',
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class LabResultsDetailsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accessionDetails: {},
            template: '',
            expandedAccession: null,
            loading: true,
        };
    }

    componentDidMount() {
        axios.get(`${APPLICATION_CONFIG_URL}/LabReport/LabReport.html`)
            .then((response2) => {
                this.setState({
                    template: response2.data,
                }, this.fetchAcquisitionDetails);
            });
    }
    onExpand = accessionUuid => (e, expanded) => {
        console.log('as0jaopdmkaldas09dkaop', accessionUuid);
        this.setState({
            expandedAccession: expanded ? accessionUuid : null,
        }, this.getAccessionOrders);
    };
    getAccessionOrders = () => {
        const {
            template,
            accessionDetails,
            expandedAccession,
        } = this.state;
        if (expandedAccession && !getStringFromObject(`${expandedAccession}.html`, accessionDetails)) {
            const newAccessionDetails = cloneDeep(accessionDetails);
            setStringPropertyToObject(`${expandedAccession}.loading`, newAccessionDetails, true);
            this.setState({
                accessionDetails: newAccessionDetails,
            }, () => {
                axios.post(`${api.SUMMARY.LAB_RESULTS_FOR_ACQUISITION_PRINT}${expandedAccession}`, {})
                    .then((response) => {
                        const handleBarTemplate = HandleBars.compile(template);
                        const htmlToRender = handleBarTemplate({
                            data: {
                                sampleAcquisition: getStringFromObject('data.orders', response, {}),
                            },
                        });
                        const newAccessionDetails2 = cloneDeep(accessionDetails);
                        setStringPropertyToObject(`${expandedAccession}.html`, newAccessionDetails2, htmlToRender);
                        setStringPropertyToObject(`${expandedAccession}.loading`, newAccessionDetails2, false);
                        this.setState({
                            accessionDetails: newAccessionDetails2,
                        });
                    })
                    .catch((error) => {
                        apiCatchBlockFunction(error);
                        const newAccessionDetails2 = cloneDeep(accessionDetails);
                        setStringPropertyToObject(`${expandedAccession}.loading`, newAccessionDetails, false);
                        this.setState({
                            accessionDetails: newAccessionDetails2,
                        });
                    });
            });
        }
    };

    getUploadedDocumentsInResults = (accession) => {
        const testAndUploadedDoc = {};
        const keyToIndexMap = {};
        let index = 0;
        if (isObjectValidAndNotEmpty(accession)) {
            const sampleTypesInAccession = accession.orders;
            if (isObjectValidAndNotEmpty(sampleTypesInAccession)) {
                const testsOfSampleType = Object.values(sampleTypesInAccession);
                testsOfSampleType.forEach((aTest) => {
                    if (isObjectValidAndNotEmpty(aTest)) {
                        const inHouseTests = getStringFromObject('inHouseTests', aTest) || [];
                        const referredTests = getStringFromObject('referredTests', aTest) || [];
                        const allTests = [...inHouseTests, ...referredTests];
                        if (isArrayValidAndNotEmpty(allTests)) {
                            allTests.forEach((aTestResult) => {
                                const uploadedDocument = getStringFromObject('labResult.uploadedDocument', aTestResult);
                                if (isObjectValidAndNotEmpty(uploadedDocument)) {
                                    keyToIndexMap[uploadedDocument.key] = index;
                                    testAndUploadedDoc[uploadedDocument.key] = {
                                        value: aTestResult.name,
                                        key: uploadedDocument.key,
                                    };
                                    index += 1;
                                }
                            });
                        }
                    }
                });
            }
        }
        return { testAndUploadedDoc, keyToIndexMap };
    };

    getUploadedDocumentsInResultsMemoized = memoizeOne(this.getUploadedDocumentsInResults);

    handleDocumentClick = (params) => {
        const {
            expandedAccession,
            accessionDetails,
        } = this.state;
        const expandedAccessionDetails = accessionDetails[expandedAccession];
        const uploadedDocsInAccession =
            this.getUploadedDocumentsInResultsMemoized(expandedAccessionDetails).keyToIndexMap;
        this.toggleReportView(uploadedDocsInAccession[params.description]);
    };

    toggleReportView = (clickedDocument) => {
        this.setState(prevState => ({
            viewReport: !prevState.viewReport,
            clickedDocument: !prevState.viewReport ? clickedDocument : null,
        }));
    };

    fetchAcquisitionDetails = (props = this.props) => {
        const {
            acquisitionUuid,
            patientUuid,
            dispatch,
        } = props;
        const {
            template,
        } = this.state;
        if (acquisitionUuid) {
            axios.post(`${api.ORDER.LAB_RESULTS_FOR_ACQUISITION_PRINT}${acquisitionUuid}`, {})
                .then((response) => {
                    const handleBarTemplate = HandleBars.compile(template);
                    const htmlToRender = handleBarTemplate({
                        data: { sampleAcquisition: getStringFromObject('data.orders', response, {}) },
                    });
                    this.setState({
                        accessionDetails: {
                            [acquisitionUuid]: {
                                ...response.data,
                                ...getStringFromObject('data.otherDetails', response),
                                html: htmlToRender,
                            },
                        },
                        loading: false,
                        expandedAccession: acquisitionUuid,
                    });
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, dispatch);
                });
        } else if (patientUuid) {
            axios.get(`${api.SUMMARY.LATEST_ACQUISITIONS_OF_PATIENT}${patientUuid}`)
                .then((response) => {
                    console.log('asad-0ikop21;le129e', response.data);
                    const accessionDetails = response.data;
                    const accessionUuidToIndexMap = {};
                    if (isArrayValidAndNotEmpty(accessionDetails)) {
                        accessionDetails.map((anAccession) => {
                            accessionUuidToIndexMap[anAccession.accessionUuid] = anAccession;
                            return null;
                        });
                    }
                    this.setState({
                        accessionDetails: accessionUuidToIndexMap,
                        loading: false,
                    });
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, dispatch);
                });
        }
    };

    close = () => {
        this.props.onClose(this.props.acquisitionUuid, this.props.onMarkAsReviewed, this.props.onRecallPatient);
    };

    render() {
        const {
            accessionDetails,
            expandedAccession,
            loading,
            viewReport,
            clickedDocument,
        } = this.state;
        const {
            classes,
            open,
            patientUuid,
            acquisitionUuid,
            onMarkAsReviewed,
            onRecallPatient,
            showActionButtons,
        } = this.props;
        console.log(open, accessionDetails, patientUuid, acquisitionUuid);
        console.log('a0s9djsoapmkdas09djasmklda', accessionDetails);
        const expandedAccessionDetails = accessionDetails[expandedAccession];
        const uploadedDocsInAccession =
            this.getUploadedDocumentsInResultsMemoized(expandedAccessionDetails).testAndUploadedDoc;
        const docsInAccessionArray = Object.values(uploadedDocsInAccession);
        console.log('asd-0ai-d0sd', uploadedDocsInAccession);
        const loadingElement = (
            <div className="summary-dashboard-empty fullWidth">
                <CircularProgress />
            </div>
        );
        return (
            <div style={{ height: '100%', padding: '2rem' }}>
                <DialogComponent
                    open
                    handleClose={this.close}
                    maxWidth="lg"
                    fullWidth
                    header={`Lab Result ${this.props.patientDisplayDetails}`}
                >

                    <div className="m1">
                        {
                            isObjectValidAndNotEmpty(accessionDetails) &&
                            Object.values(accessionDetails).map(anAccession => (
                                <ExpansionPanel
                                    style={{ background: '#6092c9' }}
                                    key={anAccession.accessionUuid}
                                    expanded={anAccession.accessionUuid === expandedAccession}
                                    onChange={this.onExpand(anAccession.accessionUuid)}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon nativeColor="#ffffff" />}
                                    >
                                        <Typography className={classes.heading}>
                                            Results For Tests Ordered on &nbsp;
                                            {formatDateForDisplay(new Date(anAccession.visitDate))} &nbsp;
                                            By {anAccession.doctor}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ background: '#ffffff' }}>
                                        {
                                            anAccession.loading &&
                                            loadingElement
                                        }
                                        {
                                            !anAccession.loading && anAccession.html &&
                                            <div
                                                className="fullWidth"
                                                // eslint-disable-next-line
                                                dangerouslySetInnerHTML={{ __html: anAccession.html }}
                                            />
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))
                        }
                        {
                            loading && loadingElement
                        }
                    </div>
                    {
                        isArrayValidAndNotEmpty(docsInAccessionArray) &&
                            <React.Fragment>
                                <div className="bigLabel">
                                    Uploaded Documents
                                </div>
                                <ClaimDocumentsListViewer
                                    claimDocuments={docsInAccessionArray}
                                    handleReportClick={this.handleDocumentClick}
                                    titleDescConfig={{
                                        title: 'value',
                                        description: 'key',
                                    }}
                                />
                            </React.Fragment>
                    }
                    {
                        viewReport &&
                        <ImageGallery
                            images={docsInAccessionArray}
                            onClose={this.toggleReportView}
                            downloadPDFAPI={api.FILE.DOWNLOAD_DOCUMENTS_APP}
                            downloadAPI={api.FILE.DOWNLOAD_DOCUMENTS_APP}
                            clickedDocument={clickedDocument}
                        />
                    }
                    {
                        showActionButtons &&
                        <Grid container justify="flex-end">
                            <ActionButton onClick={onMarkAsReviewed} className="mr-1">
                                Mark As Reviewed
                            </ActionButton>
                            <ActionButton onClick={onRecallPatient}>
                                Recall Patient
                            </ActionButton>
                        </Grid>
                    }
                </DialogComponent>
            </div>
        );
    }
}

LabResultsDetailsDialog.propTypes = {
    onClose: PropTypes.func,
    dispatch: PropTypes.func,
    onRecallPatient: PropTypes.func,
    onMarkAsReviewed: PropTypes.func,
    classes: PropTypes.object,
    open: PropTypes.bool,
    patientUuid: PropTypes.string,
    patientDisplayDetails: PropTypes.string,
    acquisitionUuid: PropTypes.bool,
    showActionButtons: PropTypes.bool,
};

LabResultsDetailsDialog.defaultProps = {
    onClose: () => {},
    dispatch: () => {},
    onMarkAsReviewed: () => {},
    onRecallPatient: () => {},
    classes: {},
    open: false,
    showActionButtons: true,
    acquisitionUuid: '',
    patientUuid: '',
    patientDisplayDetails: '',
};

export default withStyles(styles)(LabResultsDetailsDialog);

