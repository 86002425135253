import API from '../../../constants/api';

export const FETCH_PRODUCT_CATEGORY_LIST_REQUEST = '@@productCategory/FETCH_PRODUCT_CATEGORY_LIST_REQUEST';
export const FETCH_PRODUCT_CATEGORY_LIST_FAILURE = '@@productCategory/FETCH_PRODUCT_CATEGORY_LIST_FAILURE';
export const FETCH_PRODUCT_CATEGORY_LIST_SUCCESS = '@@productCategory/FETCH_PRODUCT_CATEGORY_LIST_SUCCESS';

export const CREATE_PRODUCT_CATEGORY_REQUEST = '@@productCategory/CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_FAILURE = '@@productCategory/CREATE_PRODUCT_CATEGORY_FAILURE';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = '@@productCategory/CREATE_PRODUCT_CATEGORY_SUCCESS';

export const CLEAR_PRODUCT_CATEGORY_STATE = '@@productCategory/CLEAR_PRODUCT_CATEGORY_STATE';

export const fetchPagesOfProductCategory = (api = API.SERVICES.PAGES, page, size, sortCol, sortOrder, filters) => {
    console.log('jakdhflksjdfhakjlsdfh', api);
    return ({
        type: FETCH_PRODUCT_CATEGORY_LIST_REQUEST,
        api:
        // eslint-disable-next-line
            `${api}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
                encodeURIComponent(JSON.stringify(filters))}`,
        page,
        size,
        sortCol,
        sortOrder,
        filters,
    });
};

export const createProductCategoryRequest = (category, isUpdate, successCallback, failureCallBack) => ({
    type: CREATE_PRODUCT_CATEGORY_REQUEST,
    api: API.PRODUCT_CATEGORY.CREATE,
    category,
    isUpdate,
    successCallback,
    failureCallBack,
});

export const clearProductCategoryState = () => ({
    type: CLEAR_PRODUCT_CATEGORY_STATE,
});
