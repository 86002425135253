import initialStates from '../../initialStates';
import { CLEAR_VISITS, FETCH_VISITS_LIST_SUCCESS } from './registrationList-actions';

const registrationList = (state = initialStates.visitQueue, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_VISITS_LIST_SUCCESS:
        newState = action.data;
        break;
    case CLEAR_VISITS:
        newState = initialStates.visitQueue;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default registrationList;
