import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';


const styles = () => ({
    card: {
        width: '100%',
        borderRadius: '8px',
        minHeight: '100%',
        marginBottom: '1rem',
    },
});


const NotificationSettingContainer = ({ children, classes, fullWidth }) => (
    <React.Fragment>
        <div
            style={{
                padding: '2rem',
                width: !fullWidth ? '35%' : 'undefined',
                marginLeft: !fullWidth ? '35%' : '1rem',
            }}
        >
            <Card className={classes.card}>
                <div style={{ background: '#fff', padding: '1rem' }}>
                    { children }
                </div>
            </Card>
        </div>
    </React.Fragment>
);

NotificationSettingContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool,
};

NotificationSettingContainer.defaultProps = {
    fullWidth: false,
};

export default withStyles(styles)(NotificationSettingContainer);
