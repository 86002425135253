const dialogComponentStyles = () => ({
    header: {
        fontSize: '1.2rem',
        color: 'white',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '1rem',
        backgroundColor: '#469DC7',
    },
    dialogContent: {
        minWidth: '768px',
        overflow: 'visible',
        marginTop: '1rem',
    },
    closeIcon: {
        color: 'white',
    },
});

export default dialogComponentStyles;
