import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import classNames from 'classnames';

import PurchaseOrderContainer from '../../containers/StockManagementContainer/PurchaseOrderContainer';
import dialogComponentStyles from '../DialogComponent/DialogComponentStyles';

const PurchaseOrderDialog = (props) => {
    const {
        open, handleClose, classes, selected, intentUuid,
    } = props;
    console.log('ahdjfkhadjkfhajk', props);
    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>
                            Purchase Order&nbsp;{selected.name ? `(${selected.name})` : ''}
                        </div>
                        <Close
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={handleClose}
                            testId="close"
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: '0' }}>
                    <PurchaseOrderContainer
                        match={{}}
                        dialogContent
                        selected={selected}
                        intentUuid={intentUuid}
                        handleClickCancel={handleClose}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

PurchaseOrderDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selected: PropTypes.object,
    intentUuid: PropTypes.string,

};

PurchaseOrderDialog.defaultProps = {
    selected: {},
    intentUuid: null,

};

const mapStateToProps = state => ({
    selected: state.purchaseOrder.selected,
});

export default connect(mapStateToProps)(withStyles(dialogComponentStyles)(PurchaseOrderDialog));
