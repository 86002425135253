import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    findValueByChoices, getValueFromObjectByStringNotationKey,
    isArrayValidAndNotEmpty,

} from '../../../constants/CommonUtil';
import { diffInDateInDays, getDateInDDMMMYYYYFormat, isDateString } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    disabled: {
        color: 'black',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        width: '11em',
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
        color: 'black',
    },
});

class SideLabelReadOnlyText extends React.PureComponent {
    calculateDateDifference = (between, formValues) => {
        if (!between.from || !between.to) {
            return 0;
        }
        const fromValue = getValueFromObjectByStringNotationKey(formValues, between.from);
        const toValue = getValueFromObjectByStringNotationKey(formValues, between.to);

        if (!fromValue || !toValue) { return 0; }
        const fromDate = new Date(fromValue);
        const toDate = new Date(toValue);
        if (fromDate.getTime() > toDate.getTime()) {
            return 0;
        }
        const dateDiff = diffInDateInDays(toDate, fromDate);
        return between.inclusive ? dateDiff + 1 : dateDiff; // inclusive
    };

    render() {
        const {
            label,
            classes,
            input,
            dataSourceConfig,
            ...otherProps
        } = this.props;
        let { value } = input;
        const { calculateDateDiff, formValues } = otherProps;
        if (otherProps.choices && value) {
            const { choices } = otherProps;
            value = findValueByChoices(choices, dataSourceConfig, value);
        }
        if (otherProps.multiple && isArrayValidAndNotEmpty(value)) {
            let valueAsString = '';
            value.forEach((v, idx) => {
                if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
                    valueAsString = valueAsString.concat(v[dataSourceConfig.text]);
                } else {
                    valueAsString = valueAsString.concat(v);
                }
                if (idx !== value.length - 1) {
                    valueAsString = valueAsString.concat(', ');
                }
            });
            value = valueAsString;
        }
        if ((typeof value === 'object')) {
            if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
                value = getStringFromObject(dataSourceConfig.text, value, '-');
            } else {
                value = JSON.stringify(value);
            }
        }
        console.log('adfsdfdfsdfsdf', dataSourceConfig, value, otherProps.multiple);

        /* check if contains date format like 2018-02-11 the convert into standard format */
        if (isDateString(value)) {
            value = getDateInDDMMMYYYYFormat(new Date(value));
        }

        if (calculateDateDiff) {
            value = this.calculateDateDifference(calculateDateDiff, formValues);
        }

        return (
            <div className={classes.parent}>
                {
                    label &&
                    <div className={classes.labelDiv}>
                        <div style={otherProps.labelstyle} >{label}</div>
                    </div>
                }
                <div className={classes.fieldDiv}>
                    {otherProps.startAdornment ?
                        <span>{otherProps.startAdornment}&nbsp;&nbsp;</span> :
                        ''
                    }{value}
                </div>
            </div>
        );
    }
}

SideLabelReadOnlyText.propTypes = {
    label: PropTypes.string,
    input: PropTypes.object.isRequired,
    dataSourceConfig: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

SideLabelReadOnlyText.defaultProps = {
    label: '',
    dataSourceConfig: {},
};

export default withStyles(styles)(SideLabelReadOnlyText);

