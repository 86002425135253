import API from '../../../constants/api';

export const FETCH_WORKFLOW_CONFIG_REQUEST = '@@appConfig/FETCH_WORKFLOW_CONFIG_REQUEST';
export const FETCH_WORKFLOW_CONFIG_SUCCESS = '@@appConfig/FETCH_WORKFLOW_CONFIG_SUCCESS';
export const FETCH_WORKFLOW_CONFIG_FAILURE = '@@appConfig/FETCH_WORKFLOW_CONFIG_FAILURE';

export const fetchWorkflowConfig = () => ({
    type: FETCH_WORKFLOW_CONFIG_REQUEST,
    api: API.WORKFLOW_CONFIGURATION_JSON,
});
