/**
 * Created by vinay on 19/12/18.
 */
import API from '../../../constants/api';

export const FETCH_APPOINTMENTS_REQUEST = '@@appointment/FETCH_APPOINTMENTS_REQUEST';
export const FETCH_APPOINTMENTS_FAILURE = '@@appointment/FETCH_APPOINTMENTS_FAILURE';
export const FETCH_APPOINTMENTS_SUCCESS = '@@appointment/FETCH_APPOINTMENTS_SUCCESS';

export const fetchAppointmentsRequest = (
    page,
    size,
    sortDir = 'desc',
    sortCol = 'appointmentDate',
    filters,
) => ({
    type: FETCH_APPOINTMENTS_REQUEST,
    api: API.APPOINTMENT.APPOINTMENT_LIST,
    page,
    size,
    sortDir,
    sortCol,
    filters,
});

