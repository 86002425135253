/**
 * Created by vinay on 19/8/19.
 */

import MESSAGES from '../../../constants/messages';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';

export const FAVOURITE_REPORTS_FETCH_REQUEST = '@@favouriteReports/FAVOURITE_REPORTS_REQUEST_FETCH';
export const FAVOURITE_REPORTS_FETCH_SUCCESS = '@@favouriteReports/FAVOURITE_REPORTS_FETCH_SUCCESS';
export const FAVOURITE_REPORTS_FETCH_FAILURE = '@@favouriteReports/FAVOURITE_REPORTS_FETCH_FAILURE';

export const ADD_FAVOURITE_REQUEST = '@@favouriteReports/ADD_FAVOURITE_REQUEST';
export const ADD_FAVOURITE_REQUEST_SUCCESS = '@@favouriteReports/ADD_FAVOURITE_REQUEST_SUCCESS';
export const ADD_FAVOURITE_REQUEST_FAILURE = '@@favouriteReports/ADD_FAVOURITE_REQUEST_FAILURE';

export const REMOVE_FAVOURITE_REQUEST = '@@favouriteReports/REMOVE_FAVOURITE_REQUEST';
export const REMOVE_FAVOURITE_REQUEST_SUCCESS = '@@favouriteReports/REMOVE_FAVOURITE_REQUEST_SUCCESS';
export const REMOVE_FAVOURITE_REQUEST_FAILURE = '@@favouriteReports/REMOVE_FAVOURITE_REQUEST_FAILURE';

export const CLEAR_FAVOURITE_REPORTS = '@@favouriteReports/CLEAR_FAVOURITE_REPORTS';

export const fetchFavouriteReports = (api, successCallback, failureCallBack) => ({
    type: FAVOURITE_REPORTS_FETCH_REQUEST,
    api,
    successAction: FAVOURITE_REPORTS_FETCH_SUCCESS,
    successCallback,
    failureAction: FAVOURITE_REPORTS_FETCH_FAILURE,
    failureMessage: MESSAGES.FAVOURITE_REPORT.FETCH_FAILURE,
    failureCallBack,
});

export const addFavouriteRequest = (api, payload, successCallback, failureCallBack) => ({
    type: ADD_FAVOURITE_REQUEST,
    api,
    payload,
    successAction: ADD_FAVOURITE_REQUEST_SUCCESS,
    successMessage: MESSAGES.FAVOURITE_REPORT.ADD.SUCCESS,
    successCallback,
    failureAction: ADD_FAVOURITE_REQUEST_FAILURE,
    failureMessage: MESSAGES.FAVOURITE_REPORT.ADD.FAILURE,
    failureCallBack,
});

export const removeFavouriteRequest = (api, uuid, successCallback, failureCallBack) => ({
    type: REMOVE_FAVOURITE_REQUEST,
    api: `${api}/${uuid}`,
    successAction: REMOVE_FAVOURITE_REQUEST_SUCCESS,
    successMessage: MESSAGES.FAVOURITE_REPORT.REMOVE.SUCCESS,
    successCallback,
    failureAction: REMOVE_FAVOURITE_REQUEST_FAILURE,
    failureMessage: MESSAGES.FAVOURITE_REPORT.REMOVE.FAILURE,
    failureCallBack,
});

export const removeFavouriteRequestWithReportUuid = (api, reportUuid, successCallback, failureCallBack) => ({
    type: REMOVE_FAVOURITE_REQUEST,
    api: getUrlWithApiParams(api, { reportUuid }),
    successAction: REMOVE_FAVOURITE_REQUEST_SUCCESS,
    successMessage: MESSAGES.FAVOURITE_REPORT.REMOVE.SUCCESS,
    successCallback,
    failureAction: REMOVE_FAVOURITE_REQUEST_FAILURE,
    failureMessage: MESSAGES.FAVOURITE_REPORT.REMOVE.FAILURE,
    failureCallBack,
});

export const clearFavouriteReports = () => ({
    type: CLEAR_FAVOURITE_REPORTS,
});

