import sortBy from 'lodash.sortby';
import cloneDeep from 'clone-deep';
import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    roundedValue, stringEqualsIgnoreCase,
} from '../../../constants/CommonUtil';
import { formatDateForDisplay } from '../../../constants/DateUtil';
import { PAYMENT_METHODS_MAP } from '../../../constants/constants';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { subtract } from '../../../constants/PrecisionUtil';

export const calculateTotals = (uiObject) => {
    const unreconciledAdvance = getStringFromObject('unreconciledAdvance', uiObject);
    const creditMemo = getStringFromObject('creditMemo', uiObject);
    const accountInvoices = getStringFromObject('accountInvoices', uiObject);
    // advance
    let totalRemainingAdvance = 0;
    let totalAdvance = 0;
    let totalAdvanceUsed = 0;
    if (isArrayValidAndNotEmpty(unreconciledAdvance)) {
        unreconciledAdvance.forEach((l) => {
            totalAdvanceUsed += NumberOf(l.unAllocatedAmount);
            totalRemainingAdvance += NumberOf(l.amountResidue);
            totalAdvance += NumberOf(l.amountAdvance);
        });
    }
    // credit memo
    let totalCreditMemo = 0;
    let totalRemainingCreditMemo = 0;
    let totalCreditMemoUsed = 0;
    if (isArrayValidAndNotEmpty(creditMemo)) {
        creditMemo.forEach((l) => {
            totalCreditMemoUsed += NumberOf(l.unAllocatedAmount);
            totalRemainingCreditMemo += NumberOf(l.amountResidue);
            totalCreditMemo += NumberOf(l.amountAdvance);
        });
    }
    // invoice
    let totalAllocated = 0;
    let totalOutstanding = 0;
    let originalAmount = 0;
    let totalAmountPaid = 0;
    if (isArrayValidAndNotEmpty(accountInvoices)) {
        accountInvoices.forEach((l) => {
            originalAmount = roundedValue(originalAmount + NumberOf(l.originalAmount));
            totalAllocated = roundedValue(totalAllocated + NumberOf(l.allocatedAmount));
            totalOutstanding = roundedValue(totalOutstanding + NumberOf(l.openBalance));
            totalAmountPaid = roundedValue(totalAmountPaid + NumberOf(l.amountPaid));
        });
    }
    return ({
        ...uiObject,
        // advance
        totalAdvance: roundedValue(totalAdvance),
        totalAllocatedAdvance: roundedValue(Math.max(0, totalAdvance - totalRemainingAdvance)),
        totalAdvanceUsed: roundedValue(totalAdvanceUsed),
        // credit memo
        totalCreditMemo: roundedValue(totalCreditMemo),
        totalAllocatedCreditMemo: roundedValue(Math.max(0, totalCreditMemo - totalRemainingCreditMemo)),
        totalCreditMemoUsed: roundedValue(totalCreditMemoUsed),
        // invoice
        totalInvoiceAmount: roundedValue(originalAmount),
        totalOutstanding: roundedValue(totalOutstanding),
        totalAllocated: roundedValue(totalAllocated),
        totalAmountPaid: roundedValue(totalAmountPaid),
        amountToPay: roundedValue(Math.max(totalAllocated - totalAdvanceUsed - totalCreditMemoUsed, 0)),
    });
};

export const reconcile = (listOfAdvances, listOfInvoices, reset) => {
    const advances = cloneDeep(listOfAdvances);
    const invoices = cloneDeep(listOfInvoices);
    if (isArrayValidAndNotEmpty(advances)) {
        for (let i = 0; i < advances.length; i += 1) {
            const amountResidue = NumberOf(advances[i].amountResidue);
            let advanceAmount = NumberOf(advances[i].unAllocatedAmount);
            if (reset || (amountResidue < advanceAmount)) {
                advanceAmount = amountResidue;
            }
            const totalAdvance = advanceAmount;
            for (let idx = 0; idx < invoices.length; idx += 1) {
                if (advanceAmount <= 0) {
                    break;
                }
                const openBalance = NumberOf(invoices[idx].openBalance);
                const alreadyAllocated = NumberOf(invoices[idx].allocatedAmount);
                const invoiceBalance = Math.max(openBalance - alreadyAllocated, 0);
                if (invoiceBalance > 0) {
                    if (invoiceBalance > advanceAmount) {
                        invoices[idx].allocatedAmount = roundedValue(alreadyAllocated + advanceAmount);
                        advanceAmount = 0;
                    } else {
                        invoices[idx].allocatedAmount = roundedValue(alreadyAllocated + invoiceBalance);
                        advanceAmount -= invoiceBalance;
                    }
                }
            }
            advances[i].unAllocatedAmount = roundedValue(totalAdvance - advanceAmount);
        }
    }
    return ({ advances, invoices });
};

export const autoReconcile = (uiObject, reset = false) => {
    let advances = getStringFromObject('unreconciledAdvance', uiObject, []);
    let creditMemo = getStringFromObject('creditMemo', uiObject, []);
    let invoices = cloneDeep(getStringFromObject('accountInvoices', uiObject));
    let masterAmount = NumberOf(getStringFromObject('masterAmount', uiObject));
    if (isArrayValidAndNotEmpty(invoices)) {
        // need to make allocated 0, as to handle case where recociled advance amount is reduced
        invoices = invoices.map(i => ({ ...i, allocatedAmount: 0 }));
        const reconciledAdvance = reconcile(advances, invoices, reset);
        advances = reconciledAdvance.advances || [];
        invoices = reconciledAdvance.invoices || [];
        const reconciledCreditMemo = reconcile(creditMemo, invoices, reset);
        creditMemo = reconciledCreditMemo.advances;
        invoices = reconciledCreditMemo.invoices || [];
        if (masterAmount > 0) {
            for (let i = 0; i < invoices.length; i += 1) {
                if (masterAmount <= 0) {
                    break;
                }
                const openBalance = NumberOf(invoices[i].openBalance);
                let allocatedAmount = NumberOf(invoices[i].allocatedAmount);
                const balance = openBalance - allocatedAmount;
                if (balance > 0) {
                    if (balance >= masterAmount) {
                        allocatedAmount = roundedValue(allocatedAmount + masterAmount);
                        masterAmount = 0;
                    } else {
                        allocatedAmount = roundedValue(allocatedAmount + balance);
                        masterAmount = roundedValue(masterAmount - balance);
                    }
                }
                invoices[i].allocatedAmount = allocatedAmount;
            }
        }
    }
    return ({
        ...uiObject,
        unreconciledAdvance: advances,
        accountInvoices: invoices,
        creditMemo,
    });
};

export const getUiObjectFromProps = (props) => {
    const uiObject = {
        invoicePaymentId: null,
        payor: null,
        paymentDate: new Date(),
        unreconciledAdvance: [],
        creditMemo: [],
        accountInvoices: [],
        totalUnallocated: 0,
        totalAllocated: 0,
        amountToPay: 0,
        paymentMethod: null,
    };
    const invoicePayment = getStringFromObject('invoicePayment', props, null);
    if (isObjectValidAndNotEmpty(invoicePayment)) {
        uiObject.invoicePaymentId = invoicePayment.invoicePaymentId;
        uiObject.depositTo = invoicePayment.account;
        uiObject.payor = {
            uuid: getStringFromObject('partnerId.key', invoicePayment),
            name: getStringFromObject('partnerId.value', invoicePayment),
            subCompany: getStringFromObject('subCompany', invoicePayment),
        };
        uiObject.narration = getStringFromObject('narration', invoicePayment);
        uiObject.subCompany = getStringFromObject('subCompany', invoicePayment, null);
        uiObject.paymentDate = new Date(invoicePayment.date);
        uiObject.paymentMethod = getStringFromObject(invoicePayment.paymentMode, PAYMENT_METHODS_MAP);
        uiObject.bankName = getStringFromObject('paymentDetails.bankName', invoicePayment, null);
        uiObject.referenceNumber = getStringFromObject('paymentDetails.refNumber', invoicePayment, null);
        uiObject.date = getStringFromObject('paymentDetails.date', invoicePayment, null);
        uiObject.remarks = getStringFromObject('paymentDetails.remarks', invoicePayment, null);
        uiObject.attachment = getStringFromObject('attachment', invoicePayment, null);
        const lines = getStringFromObject('lines', invoicePayment, []);
        if (isArrayValidAndNotEmpty(lines)) {
            uiObject.accountInvoices = sortBy(
                lines.map(l => ({
                    uuid: l.invoiceUuid,
                    description: l.name || l.description,
                    dueDateInMillis: l.invoiceDueDate,
                    dueDate: formatDateForDisplay(new Date(l.invoiceDueDate)),
                    originalAmount: NumberOf(l.amountOriginal),
                    openBalance: NumberOf(l.invoiceResidual),
                    amountPaid: roundedValue(subtract(NumberOf(l.amountOriginal), NumberOf(l.invoiceResidual))),
                    narration: l.narration,
                    allocatedAmount: roundedValue(l.amount),
                    supplierInvoiceNumber: l.supplierInvoiceNumber,
                })),
                'dueDateInMillis',
            );
        }
        const advances = getStringFromObject('advances', invoicePayment, []);
        if (isArrayValidAndNotEmpty(advances)) {
            uiObject.unreconciledAdvance = sortBy(
                advances.map(l => ({
                    uuid: l.uuid,
                    name: l.name,
                    sortOrder: new Date(l.dateString).getTime(),
                    date: formatDateForDisplay(new Date(l.dateString)),
                    amountAdvance: NumberOf(l.amountOriginal),
                    creator: l.creator,
                    narration: l.narration,
                    amountResidue: NumberOf(l.invoiceResidual),
                    allocatedAmount: roundedValue(NumberOf(l.amountOriginal) - NumberOf(l.invoiceResidual)),
                    unAllocatedAmount: roundedValue(NumberOf(l.amount)),
                })),
                'sortOrder',
            );
        }
        const creditMemo = getStringFromObject('creditMemo', invoicePayment, []);
        if (isArrayValidAndNotEmpty(creditMemo)) {
            uiObject.creditMemo = sortBy(
                creditMemo.map(l => ({
                    uuid: l.invoiceUuid,
                    name: l.name || l.description,
                    sortOrder: new Date(l.dateString).getTime(),
                    date: formatDateForDisplay(new Date(l.dateString)),
                    amountAdvance: NumberOf(l.amountOriginal),
                    amountResidue: NumberOf(l.invoiceResidual),
                    narration: l.narration,
                    creator: l.creator,
                    allocatedAmount: roundedValue(NumberOf(l.amountOriginal) - NumberOf(l.invoiceResidual)),
                    unAllocatedAmount: roundedValue(NumberOf(l.amount)),
                })),
                'sortOrder',
            );
        }
        return calculateTotals(uiObject);
    }
    const supplier = getStringFromObject('selectedSupplier', props);
    if (isObjectValidAndNotEmpty(supplier)) {
        uiObject.payor = supplier;
        uiObject.subCompany = getStringFromObject('subCompany', supplier);
    }
    const supplierAdvance = getStringFromObject('supplierAdvance', props);
    if (isArrayValidAndNotEmpty(supplierAdvance)) {
        uiObject.unreconciledAdvance = sortBy(
            supplierAdvance.map(a => ({
                ...a,
                sortOrder: new Date(a.date).getTime(),
                allocatedAmount: roundedValue(NumberOf(a.amountAdvance) - NumberOf(a.amountResidue)),
                unAllocatedAmount: 0,
            })),
            'sortOrder',
        );
    }
    const creditMemo = getStringFromObject('creditMemo', props);
    if (isArrayValidAndNotEmpty(creditMemo)) {
        uiObject.creditMemo = sortBy(
            creditMemo.map(a => ({
                uuid: a.uuid,
                name: a.name,
                sortOrder: new Date(a.date).getTime(),
                date: a.date,
                amountAdvance: NumberOf(a.amount),
                creator: a.creator,
                amountResidue: NumberOf(a.residue),
                allocatedAmount: roundedValue(NumberOf(a.amount) - NumberOf(a.residue)),
                unAllocatedAmount: 0,
            })),
            'sortOrder',
        );
    }
    const accountInvoices = getStringFromObject('accountInvoices', props);
    if (isArrayValidAndNotEmpty(accountInvoices)) {
        uiObject.accountInvoices = sortBy(
            accountInvoices.map(a => ({
                uuid: a.uuid,
                description: a.number,
                dueDateInMillis: new Date(a.dueDate).getTime(),
                dueDate: formatDateForDisplay(new Date(a.dueDate)),
                originalAmount: a.amountTotal,
                openBalance: a.residualAmount,
                narration: '',
                allocatedAmount: 0,
                supplierInvoiceNumber: a.supplierInvoiceNumber,
                amountPaid: roundedValue(subtract(NumberOf(a.amountTotal), NumberOf(a.residualAmount))),
            })),
            'dueDateInMillis',
        );
    }
    return calculateTotals(autoReconcile(uiObject, true));
};

const advanceAmountChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const values = getStringFromObject('values', form);
            form.setValues(calculateTotals(autoReconcile(values)));
        }
    }
};
const invoiceAmountChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const values = getStringFromObject('values', form);
            form.setValues(calculateTotals(values));
        }
    }
};

export const ACTION_HANDLERS = {
    advanceAmountChangeHandler,
    invoiceAmountChangeHandler,
};

export const getPayload = (uiObject) => {
    const payload = {
        invoicePaymentId: getStringFromObject('invoicePaymentId', uiObject, null),
        resPartner: {
            key: getStringFromObject('uuid', uiObject.payor),
            value: getStringFromObject('name', uiObject.payor),
        },
        state: getStringFromObject('state', uiObject, null),
        depositTo: getStringFromObject('depositTo', uiObject, null),
        paymentMethod: getStringFromObject('paymentMethod.value', uiObject, null),
        totalAmount: NumberOf(getStringFromObject('amountToPay', uiObject)),
        date: getStringFromObject('paymentDate', uiObject),
        subCompany: getStringFromObject('subCompany', uiObject, null),
        narration: getStringFromObject('narration', uiObject, null),
        attachment: getStringFromObject('attachment', uiObject, null),
        invoiceDtos: [],
        reconciledAdvance: [],
        creditMemo: [],
        referenceNumber: getStringFromObject('referenceNumber', uiObject, null),
        paymentModeDetailsDto: {
            bankName: getStringFromObject('bankName', uiObject, null),
            refNumber: getStringFromObject('referenceNumber', uiObject, null),
            date: getStringFromObject('date', uiObject, null),
            remarks: getStringFromObject('remarks', uiObject, null),
        },
    };
    const accountInvoices = getStringFromObject('accountInvoices', uiObject);
    if (isArrayValidAndNotEmpty(accountInvoices)) {
        accountInvoices.forEach((line) => {
            const payment = NumberOf(getStringFromObject('allocatedAmount', line));
            if (payment > 0) {
                payload.invoiceDtos.push({
                    payment,
                    invoiceUuid: line.uuid,
                    narration: line.narration,
                });
            }
        });
    }
    const unreconciledAdvance = getStringFromObject('unreconciledAdvance', uiObject);
    if (isArrayValidAndNotEmpty(unreconciledAdvance)) {
        unreconciledAdvance.forEach((line) => {
            const amount = NumberOf(getStringFromObject('unAllocatedAmount', line));
            if (amount > 0) {
                payload.reconciledAdvance.push({
                    uuid: line.uuid,
                    amount,
                    narration: line.narration,
                });
            }
        });
    }
    const creditMemo = getStringFromObject('creditMemo', uiObject);
    if (isArrayValidAndNotEmpty(creditMemo)) {
        creditMemo.forEach((line) => {
            const amount = NumberOf(getStringFromObject('unAllocatedAmount', line));
            if (amount > 0) {
                payload.creditMemo.push({
                    uuid: line.uuid,
                    amount,
                    narration: line.narration,
                });
            }
        });
    }
    return payload;
};

export const FORM_VALIDATOR = (values) => {
    const errors = {};
    const accountInvoices = getStringFromObject('accountInvoices', values);
    let hasInvoiceAmount = false;
    if (isArrayValidAndNotEmpty(accountInvoices)) {
        accountInvoices.forEach((l, index) => {
            const allocatedAmount = NumberOf(l.allocatedAmount);
            const openBalance = NumberOf(l.openBalance);
            hasInvoiceAmount = hasInvoiceAmount || (allocatedAmount > 0);
            if (allocatedAmount > openBalance) {
                setStringPropertyToObject(
                    `accountInvoices.${index}.allocatedAmount`,
                    errors,
                    `can not be more than ${openBalance}`,
                );
            }
        });
    }
    if (!hasInvoiceAmount) {
        setStringPropertyToObject('accountInvoices._error', errors, 'Please enter non zero amount for atleast one invoice');
    }
    const unreconciledAdvance = getStringFromObject('unreconciledAdvance', values);
    if (isArrayValidAndNotEmpty(unreconciledAdvance)) {
        unreconciledAdvance.forEach((l, index) => {
            const allocatedAmount = NumberOf(l.unAllocatedAmount);
            const openBalance = NumberOf(l.amountResidue);
            if (allocatedAmount > openBalance) {
                setStringPropertyToObject(
                    `unreconciledAdvance.${index}.unAllocatedAmount`,
                    errors,
                    `can not be more than ${openBalance}`,
                );
            }
        });
    }
    const creditMemo = getStringFromObject('creditMemo', values);
    if (isArrayValidAndNotEmpty(creditMemo)) {
        creditMemo.forEach((l, index) => {
            const allocatedAmount = NumberOf(l.unAllocatedAmount);
            const openBalance = NumberOf(l.amountResidue);
            if (allocatedAmount > openBalance) {
                setStringPropertyToObject(
                    `creditMemo.${index}.unAllocatedAmount`,
                    errors,
                    `can not be more than ${openBalance}`,
                );
            }
        });
    }
    const totalInvoiceAmount = NumberOf(values.totalAllocated);
    const amountToPay = NumberOf(values.amountToPay);
    const totalAdvanceUsed = NumberOf(values.totalAdvanceUsed);
    const totalCreditMemoUsed = NumberOf(values.totalCreditMemoUsed);
    console.log('asd8u0a9duad', totalInvoiceAmount, amountToPay + totalAdvanceUsed + totalCreditMemoUsed);
    if (totalInvoiceAmount !== roundedValue(amountToPay + totalAdvanceUsed + totalCreditMemoUsed)) {
        setStringPropertyToObject(
            'amountToPay',
            errors,
            'Unallocated Advance + Credit Memo + Amount To Pay does not match with Allocated Invoice Amount',
        );
    }
    return isObjectValidAndNotEmpty(errors) ? errors : undefined;
};

export const getPrintDataFromAccountVoucher = (selectedAccountVoucher) => {
    const isRefund = stringEqualsIgnoreCase(getStringFromObject('type', selectedAccountVoucher), 'refund');
    const paymentMethod = getStringFromObject('paymentMode', selectedAccountVoucher);
    const isCheque = paymentMethod === PAYMENT_METHODS_MAP.CHEQUE.value;
    const isCash = paymentMethod === PAYMENT_METHODS_MAP.CASH.value;
    const isBank = paymentMethod === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
    const lines = getStringFromObject('lines', selectedAccountVoucher);
    const debitLines = [];
    const creditLines = [];
    let creditSum = 0;
    let debitSum = 0;
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((l) => {
            if (l.type === 'cr') {
                creditLines.push(l);
                creditSum += NumberOf(l.amount);
            } else {
                debitLines.push(l);
                debitSum += NumberOf(l.amount);
            }
        });
    }
    const paymentModeLabel = getStringFromObject(
        `${paymentMethod}.label`,
        PAYMENT_METHODS_MAP,
    );
    return ({
        ...selectedAccountVoucher,
        isRefund,
        isCheque,
        isCash,
        isBank,
        debitLines,
        creditLines,
        paymentModeLabel,
        creditSum,
        debitSum,
    });
};
