import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import cloneDeep from 'clone-deep';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import classNames from 'classnames';
import { required } from '../../constants/FormValidations';
import { createResPartnerRequest } from '../../redux/modules/resPartner/resPartner-actions';
import AddNewSupplierDialog from './AddNewSupplierDialog';
import ActionButton from '../ActionButton/ActionButton';
import { mapSupplierFromUiObj } from '../../mapper/SupplierMapper';

const formName = 'addNewSupplierForm';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        width: '30em',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    details: {
        color: '#1a71e4eb',
        cursor: 'pointer',
    },
});

class CreateNewSupplierDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDetailsClicked: false,
        };
        if (props.tempName) {
            props.dispatch(initialize(formName, { name: props.tempName, active: true }));
        }
    }

    handleSave = (formValues) => {
        const partner = cloneDeep(formValues);
        partner.supplier = true;
        partner.customer = false;
        partner.active = true;
        const payload = mapSupplierFromUiObj(formValues);
        this.props.dispatch(createResPartnerRequest(payload, this.props.handleClose));
    };

    handleDetailsDialog = () => {
        this.setState({
            isDetailsClicked: true,
        });
    };

    handleCloseDetailsDialog = () => {
        this.setState({
            isDetailsClicked: false,
        });
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            name,
        } = this.props;
        const { isDetailsClicked } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit(this.handleSave)}>
                    <Dialog
                        open={open}
                        scroll="body"
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            New Supplier
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container>
                                <Field
                                    testId="name"
                                    name="name"
                                    component={TextField}
                                    label="Name"
                                    placeholder="Name"
                                    fullWidth
                                    validate={[required]}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                className={classNames('mt-1', classes.details)}
                                onClick={this.handleDetailsDialog}
                            >
                                <AddIcon style={{ fontSize: '1rem' }} test-id="addicon-supplier" />
                                &nbsp;Details
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="supplier-cancel"
                                primary={false}
                                disableRipple
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                test-id="supplier-save"
                                onClick={handleSubmit(this.handleSave)}
                                className="ml-1"
                                disableRipple
                            >
                                Save
                            </ActionButton>
                        </DialogActions>
                    </Dialog>
                    {
                        isDetailsClicked &&
                        <AddNewSupplierDialog
                            open={isDetailsClicked}
                            selectedSupplier={{ name, active: true }}
                            handleSave={this.handleSave}
                            handleClose={this.handleCloseDetailsDialog}
                        />
                    }
                </form>
            </React.Fragment>
        );
    }
}

CreateNewSupplierDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    tempName: PropTypes.string,
    name: PropTypes.string,
};

CreateNewSupplierDialog.defaultProps = {
    tempName: '',
    name: '',
};

const selector = formValueSelector(formName);
const mapStateToProps = state => ({
    name: selector(state, 'name'),
});

export default connect(mapStateToProps)(reduxForm({
    form: formName,
})(withStyles(style)(CreateNewSupplierDialog)));
