import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import './ConsultationStatusDisplayComponent.css';
import {
    encounterStatusMap,
} from '../../containers/RegistrationAppComponents/QuickRegistration/DoctorQueueDialog/ConsultationStatusFilter/ConsultationStatusFilterUtil';

const ConsultationStatusDisplayComponent = ({
    status,
    label,
}) => {
    const indexOfStatus = Object.values(encounterStatusMap).indexOf(status);
    console.log('asd90ua0sudas', status, indexOfStatus);
    let statusKey = 'BASIC';
    if (indexOfStatus > -1) {
        statusKey = Object.keys(encounterStatusMap)[indexOfStatus];
    }
    return (
        <Tooltip title={label}>
            <div style={{ textAlign: 'center', verticalAlign: 'center' }}>
                <span className={statusKey}>
                    {label && label.split('')[0].toUpperCase()}
                </span>
            </div>
        </Tooltip>
    );
};

ConsultationStatusDisplayComponent.propTypes = {
    status: PropTypes.number,
    label: PropTypes.string,
};

ConsultationStatusDisplayComponent.defaultProps = {
    status: null,
    label: '',
};

export default ConsultationStatusDisplayComponent;

