import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Card } from '@material-ui/core';
import PageLoader from '../components/PageLoader/PageLoader';
import { bulkUploadSettingsPath } from '../constants/constants';

const BulkUploaderSettingsPage = React.lazy(() => import('../pages/BulkUploaderSettingsPage/BulkUploaderSettingsPage'));

const BulkUploaderRoutes = () => (
    <div style={{ padding: '0.75rem', height: '100%', width: '99%' }}>
        <Card>
            <Suspense fallback={<PageLoader />}>
                <Route path={bulkUploadSettingsPath} exact component={BulkUploaderSettingsPage} />
            </Suspense>
        </Card>
    </div>
);

export default BulkUploaderRoutes;
