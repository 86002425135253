import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash.sumby';
import connect from 'react-redux/lib/connect/connect';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import API from '../../../constants/api';
import {
    getJsonPath,
    isArrayValidAndNotEmpty,
    roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import {
    clearStockMoveByPurchaseOrderState,
    fetchStockMovesRequest,
} from '../../../redux/modules/stockMove/stockMove-actions';
import MaterialTable from '../../MaterialTableV2/MaterialTable';
import ActionButton from '../../ActionButton/ActionButton';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import GenericFilterWithSearch from '../../GenericFilterComponent/GenericFilterWithSearch';
import { getMemoizedPrintDataView } from '../../../mapper/ReturnGoodsMapper';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    paper: {
        background: '#fafafa',
    },
});

const getTextField = (name, value, label) => (
    <Grid item>
        <FormikTextField
            field={{
                name,
                value,
            }}
            label={label}
            disabled
            variant="outlined"
        />
    </Grid>
);

class GoodReturnViewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productSchema: null,
            searchText: '',
            print: false,
        };
    }

    componentWillMount() {
        const { targetId, dispatch } = this.props;
        if (targetId) {
            dispatch(fetchStockMovesRequest(`${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`));
        }
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/GoodReturnProduct.json'))
            .then(product => product.json())
            .then((productJson) => {
                this.setState({
                    productSchema: productJson,
                });
            }).catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
    }

    handlePrint = () => {
        this.setState(prev => ({
            print: !prev.print,
        }));
    };

    handleSearch = (filters) => {
        this.setState({ searchText: getStringFromObject('searchText', filters) });
    };

    filterWithSearchText = (searchText, arr) => {
        let val = searchText || null;
        if (val !== null && val.length > 0 && isArrayValidAndNotEmpty(arr)) {
            val = val.trim().toLowerCase();
            return arr.filter((item) => {
                const product = getStringFromObject('name', item);
                const batchName = getStringFromObject('batchName', item);
                return (
                    (product && product.toLowerCase().includes(val)) ||
                    (batchName && batchName.toLowerCase().includes(val))
                );
            });
        }
        return arr;
    };

    render() {
        const {
            productSchema,
            searchText,
        } = this.state;
        const {
            open,
            classes,
            handleClose,
            stockMove,
        } = this.props;
        console.log('kjajsdbnflkabfaadadas', stockMove);
        const name = getStringFromObject('name', stockMove);
        let lines = this.filterWithSearchText(searchText,
            getStringFromObject('stockPickingLinesDTOSet', stockMove, []));
        if (isArrayValidAndNotEmpty(lines)) {
            lines = lines.map(l => ({
                ...l,
                totalAmount: (NumberOf(l.quantity) * roundedValueFixedToTwoDigits(NumberOf(l.cost))),
            }));
        }
        const totalAmount = sumBy(lines, line => (NumberOf(line.totalAmount)));
        const purchaseOrderNumber = getStringFromObject('purchaseOrderNumber', stockMove);
        return (
            <Fragment>
                <Dialog
                    open={open}
                    fullScreen
                    classes={{
                        paper: classes.paper,
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                        <Grid container justify="space-between">
                            <div>Return Stock { name ? `(${name})` : '' }</div>
                            <Close className="cursor-pointer" onClick={handleClose} />
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="mt-1" spacing={24}>
                            {
                                getTextField('number', name, 'ID')
                            }
                            {
                                getTextField('supplier',
                                    getStringFromObject('partner.value', stockMove), 'Supplier')
                            }
                            {
                                getTextField('location',
                                    getStringFromObject('sourceLoc.value', stockMove), 'Stock Location')
                            }
                            {
                                getTextField('returnDate',
                                    getStringFromObject('createDate', stockMove), 'Returned Date')
                            }
                            {
                                getTextField('returnedBy',
                                    getStringFromObject('receivedBy', stockMove), 'Returned By')
                            }
                            {
                                purchaseOrderNumber &&
                                getTextField('purchaseOrderNumber', purchaseOrderNumber, 'Purchase Order')
                            }
                        </Grid>
                        <Grid container className="mt-2" justify="center">
                            {
                                isObjectValidAndNotEmpty(productSchema) &&
                                <React.Fragment>
                                    <Grid item lg={3} md={4} sm={6}>
                                        <GenericFilterWithSearch
                                            filterValues={{
                                                searchText,
                                            }}
                                            hideFilters
                                            saveFilter={this.handleSearch}
                                            placeholder="Search with product name or batch"
                                        />
                                    </Grid>
                                    <MaterialTable
                                        schema={productSchema}
                                        uniqueKey="uuid"
                                        noPagination
                                        data={lines}
                                    />
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid container justify="space-between" className="mt-2">
                            {getTextField('notes', getStringFromObject('notes', stockMove), 'Notes')}
                            {getTextField('totalAmount', roundedValueFixedToTwoDigits(totalAmount), 'Total Amount')}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton
                            disableRipple
                            onClick={this.handlePrint}
                        >
                            Print
                        </ActionButton>
                    </DialogActions>
                </Dialog>
                <Print
                    data={getMemoizedPrintDataView(stockMove)}
                    print={this.state.print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/StockManagement/GoodsReturn.html`}
                />
            </Fragment>
        );
    }
}


GoodReturnViewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    targetId: PropTypes.number,
    stockMove: PropTypes.object,
    // company: PropTypes.string.isRequired,
};
GoodReturnViewDialog.defaultProps = {
    targetId: null,
    stockMove: {},
};
const mapStateToProps = (state) => {
    console.log('kdagjdsf', state);
    return {
        stockMove: getStringFromObject('stockMove.stockMovesByPurchaseOrder[0]', state, {}),
        company: getStringFromObject('appConfiguration.companyName', state),
    };
};

export default connect(mapStateToProps)(withStyles(style)(GoodReturnViewDialog));
