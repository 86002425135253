import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import { isArrayValidAndNotEmpty, isValidFunction } from '../../../constants/CommonUtil';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    progress: {
        margin: 0,
    },
});

class TextFieldWithAsyncValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            info: 'Some info here',
        };
    }
    onChangeHandler = (value) => {
        const {
            meta: { dispatch, form }, onChangeHandlers, fieldMember, fields, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form && fieldMember &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    onBlur = (e) => {
        e.preventDefault();
        const {
            meta: { dispatch, form },
            onBlurHandlers,
            fieldMember,
            fields,
            actionHandlers,
            input: { onBlur },
            api,
        } = this.props;
        this.setState({ loading: true }, () => {
            axios.get(`${api}${e.target.value}`)
                .then((response) => {
                    console.log('asjdfk;asdhflkadslkf', response);
                    if (!response.data) {
                        this.setState({
                            info: this.props.errorMessage,
                        });
                    }
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.error('asjdfk;asdhflkadslkf', error);
                    this.setState({ loading: false });
                });
        });
        if (isArrayValidAndNotEmpty(onBlurHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onBlurHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(e.target.value, form, fieldMember, fields, dispatch);
                }
            });
        }
        onBlur(e);
    };

    handleChange = (e) => {
        const { input } = this.props;
        input.onChange(e.target.value);
        this.onChangeHandler(e.target.value);
    };

    render() {
        const {
            info,
            loading,
        } = this.state;

        const {
            input: {
                value, onFocus,
            },
            meta: { touched, error },
            classes,
            ...otherProps
        } = this.props;
        console.log('123pomldaasdasdas', this.props);
        const getError = () => {
            if (touched && error) {
                return error.props ? error.props.defaultMessage : '';
            }
            return '';
        };
        const showError = (touched && error);
        let endAdornment = '';
        if (loading) {
            endAdornment = <CircularProgress className={classes.progress} style={{ width: '20px', height: '20px' }} />;
        } else if (info) {
            endAdornment = <Tooltip title={info}><InfoIcon style={{ width: '20px', height: '20px' }} /></Tooltip>;
        }
        return (
            <React.Fragment>
                <FormControl error={showError} aria-describedby="component-error-text">
                    <Input
                        {...otherProps}
                        value={value}
                        onChange={this.handleChange}
                        onFocus={onFocus}
                        onBlur={this.onBlur}
                        endAdornment={endAdornment}
                        error={showError}
                    />
                    {
                        showError &&
                        <FormHelperText
                            id="component-error-text"
                        >
                            {getError()}
                        </FormHelperText>
                    }
                </FormControl>
            </React.Fragment>
        );
    }
}

TextFieldWithAsyncValidation.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    errorMessage: PropTypes.string.isRequired,
    api: PropTypes.string.isRequired,
    onChangeHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
    fieldMember: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
};

TextFieldWithAsyncValidation.defaultProps = {
    onChangeHandlers: [],
    onBlurHandlers: [],
    fieldMember: '',
    fields: {},
    actionHandlers: {},
};

export default withStyles(styles)(TextFieldWithAsyncValidation);

