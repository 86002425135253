import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import {
    appointmentsSettingDailySchedule,
    appointmentsSettingDepartments,
    appointmentsSettingQueueEdit,
    appointmentsSettingServices,
    appointmentsSettingTemplates,
} from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const AppointmentSettingContainer = React.lazy(() => import('../containers/AppointmentSettingContainer/AppointmentSettingContainer'));
const DepartmentPage = React.lazy(() => import('../pages/AppointmentsSettingPage/DepartmentPage'));
const ServicePage = React.lazy(() => import('../pages/AppointmentsSettingPage/ServicePage'));
const TemplatePage = React.lazy(() => import('../pages/AppointmentsSettingPage/TemplatePage'));
const DailySchedulePage = React.lazy(() => import('../pages/AppointmentsSettingPage/DailySchedulePage'));
const QueueEditPage = React.lazy(() => import('../pages/AppointmentsSettingPage/QueueEditPage'));

const AppointmentsSettingRoutes = props => (
    <AppointmentSettingContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={appointmentsSettingDepartments} exact component={DepartmentPage} />
            <Route path={appointmentsSettingServices} exact component={ServicePage} />
            <Route path={appointmentsSettingTemplates} exact component={TemplatePage} />
            <Route path={appointmentsSettingDailySchedule} exact component={DailySchedulePage} />
            <Route path={appointmentsSettingQueueEdit} exact component={QueueEditPage} />
        </Suspense>
    </AppointmentSettingContainer>
);

AppointmentsSettingRoutes.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
};

AppointmentsSettingRoutes.defaultProps = {
    history: {},
};

export default AppointmentsSettingRoutes;
