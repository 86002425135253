import API from '../../../constants/api';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const PRODUCT_TAB_FILTERS = ([
    {
        name: 'category',
        label: 'Category',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.SEARCH.PRODUCT_CATEGORY_WITH_PARENT}?parent=Consumables&searchString=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'supplier',
        label: 'Supplier',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.SEARCH.SUPPLIER,
        dataSourceConfig: {
            text: 'name',
            value: 'id',
        },
    },
    {
        name: 'quantity',
        label: 'Quantity',
        type: 'fieldWithOperation',
        options: ['<=', '=', '>='],
        valueType: 'number',
        valueLabel: 'Enter Quantity',
    },
    {
        name: 'includeInactive',
        label: 'Include inactive also',
        type: 'checkbox',
        defaultValue: false,
        disableClear: true,
    },
]);

export const simplifyFilters = (values) => {
    console.log('asd98uy09asdu90as0sadsa', values);
    const obj = {};
    const name = getStringFromObject('searchText', values);
    const category = getStringFromObject('category', values);
    if (name) {
        obj.name = name;
    }
    if (isObjectValidAndNotEmpty(category)) {
        obj.category = getStringFromObject('key', category);
    }
    const supplier = getStringFromObject('supplier.id', values);
    if (supplier) {
        obj.supplier = supplier;
    }
    const operation = getStringFromObject('quantity.operation', values);
    const quantity = getStringFromObject('quantity.value', values);
    if (operation && (quantity != null || quantity !== undefined)) {
        const arr = [operation, quantity];
        obj.stockAvailability = arr.toString();
    }
    const includeInactive = getStringFromObject('includeInactive', values, false);
    if (!includeInactive) {
        obj.active = true;
    }
    const defaultCode = getStringFromObject('defaultCode', values);
    if (defaultCode) {
        obj.defaultCode = defaultCode;
    }
    const ean13 = getStringFromObject('ean13', values);
    if (ean13) {
        obj.ean13 = ean13;
    }
    return obj;
};

export default PRODUCT_TAB_FILTERS;
