import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { getJsonPath, isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import {
    clearStockMoveSelectState,
    fetchStockMovesByIdRequest,
} from '../../redux/modules/stockMove/stockMove-actions';
import ActionButton from '../ActionButton/ActionButton';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL } from '../../constants/constants';
import GenericFilterWithSearch from '../GenericFilterComponent/GenericFilterWithSearch';
import OutlinedTextField from '../OutlinedTextField';
import { getAddressFromAddressDtoMemoized } from '../PrintComponents/PurchaseOrderPrintUtil';
import {
    clearSelectedPurchaseOrder,
    fetchPurchaseOrderByUuidRequest,
} from '../../redux/modules/purchaseOrder/purchaseOrder-actions';
import RecieveStockDialog from './RecieveStockDialog';
import { getUiObject } from '../../mapper/PurchaseOrderMapper';
import { formatDateTimeForDisplay } from '../../constants/DateUtil';
import { getMemoizedPrintDataView } from '../../mapper/ReceiveStockMapper';
import BarcodePrintComponent from '../BarcodePrintComponent/BarcodePrintComponent';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
});

const getText = (id, label, value, props) => (
    <OutlinedTextField
        {...props}
        id={id}
        label={label}
        value={value}
        disabled
        InputLabelProps={{
            shrink: true,
        }}
    />
);

class RecieveStockViewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            print: false,
            searchText: '',
            receiveStock: false,
            selectedRows: [],
        };
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(clearSelectedPurchaseOrder());
    }

    componentDidMount() {
        const { targetId, dispatch } = this.props;
        dispatch(fetchStockMovesByIdRequest(targetId));
        fetch(getJsonPath('/StockManagement/ReceiveGoods.json'))
            .then(resp => resp.json())
            .then((json) => {
                console.log('dkjfkaf', json);
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearStockMoveSelectState());
        dispatch(clearSelectedPurchaseOrder());
    }

    handleContinueReceive = () => {
        const {
            stockMove,
            dispatch,
        } = this.props;
        const poUuid = getStringFromObject('purchaseOrder', stockMove);
        if (poUuid) {
            dispatch(fetchPurchaseOrderByUuidRequest(poUuid, this.openStockReceiveDialog));
        }
    };

    printHandler = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    openStockReceiveDialog = () => {
        this.setState({ receiveStock: true });
    };

    handleCloseReceiveStock = () => {
        this.setState({ receiveStock: false });
    };

    handleSearch = (filters) => {
        this.setState({ searchText: getStringFromObject('searchText', filters) });
    };


    filterWithSearchText = (searchText, arr) => {
        let val = searchText || null;
        if (val !== null && val.length > 0 && isArrayValidAndNotEmpty(arr)) {
            val = val.trim().toLowerCase();
            return arr.filter((item) => {
                const product = getStringFromObject('name', item);
                const batchName = getStringFromObject('batchName', item);
                return (
                    (product && product.toLowerCase().includes(val)) ||
                    (batchName && batchName.toLowerCase().includes(val))
                );
            });
        }
        return arr;
    };
    handleSelectAction =(index, selected) => {
        const {
            stockMove,
        } = this.props;
        const {
            searchText,
        } = this.state;
        const listData = this.filterWithSearchText(searchText,
            getStringFromObject('stockPickingLinesDTOSet', stockMove, []));

        const selectedRows = [];
        if (isArrayValidAndNotEmpty(index) && isArrayValidAndNotEmpty(listData)) {
            index.forEach((anIndex) => {
                const item = listData[anIndex];
                if (isObjectValidAndNotEmpty(item)) {
                    selectedRows.push({
                        uuid: getStringFromObject('uuid', item),
                        name: item.name,
                        batch: item.batchName,
                        quantity: item.quantity,
                        state: 'QUEUED',
                    });
                }
            });
            this.setState({ selectedRows });
        } else {
            this.setState({ selectedRows: [] });
        }
        console.log('handleSelectActionhandleSelectAction', { selectedRows, selected });
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            stockMove,
            purchaseOrder,
            enableBarcodePrintButt,
        } = this.props;
        const {
            schema,
            searchText,
            receiveStock,
            selectedRows,
        } = this.state;
        console.log('printData', stockMove);
        const listData = this.filterWithSearchText(searchText,
            getStringFromObject('stockPickingLinesDTOSet', stockMove, []));
        const supplierAddr = getAddressFromAddressDtoMemoized(getStringFromObject('partnerAddress', stockMove));
        const shippingTo = getAddressFromAddressDtoMemoized(getStringFromObject('companyAddress', stockMove));
        const allowReceive = !getStringFromObject('productReceived', stockMove, true);
        const name = getStringFromObject('name', stockMove);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>Receive Stock {name ? `(${name})` : ''}</div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="receive-stock-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                <div style={{ padding: '1em' }}>
                                    <Grid container justify="space-between" spacing={16}>
                                        <Grid item lg={9} md={9} sm={10}>
                                            <Grid container spacing={16}>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    {getText('supplier', 'Supplier',
                                                        getStringFromObject('partner.value', stockMove))}
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    {getText('email', 'Email',
                                                        getStringFromObject('email', stockMove))}
                                                </Grid>
                                            </Grid>
                                            <Grid container className="mt-1" spacing={16}>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    {
                                                        getText('mailingAddress', 'Mailing address',
                                                            supplierAddr, { rows: 5, rowsMax: 5, multiline: true })
                                                    }
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} className="pl-2">
                                                    {getText('warehouse', 'Ship to',
                                                        getStringFromObject('destLoc.value', stockMove))}
                                                    <div className="mt-1">
                                                        {getText('shippingAddress', 'Shipping address',
                                                            shippingTo, { rows: 5, rowsMax: 5, multiline: true })}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid lg={3} md={3} sm={2}>
                                            {
                                                allowReceive &&
                                                <Grid container direction="column" alignItems="flex-end" spacing={24}>
                                                    <Grid item>
                                                        <ActionButton
                                                            test-id="continue-receive-stock"
                                                            onClick={this.handleContinueReceive}
                                                        >
                                                            Continue Receive stock
                                                        </ActionButton>
                                                    </Grid>
                                                    <Grid item>
                                                        {getText('createDate', 'Date Created',
                                                            formatDateTimeForDisplay(new Date(
                                                                getStringFromObject('createDateTime', stockMove))))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                {
                                    isObjectValidAndNotEmpty(schema) &&
                                        <React.Fragment>
                                            <Grid container className="mt-2" justify="space-between">

                                                <Grid item lg={4} md={6} sm={12}>
                                                    <GenericFilterWithSearch
                                                        filterValues={{
                                                            searchText,
                                                        }}
                                                        hideFilters
                                                        saveFilter={this.handleSearch}
                                                        placeholder="Search with product name or batch"
                                                    />
                                                </Grid>
                                                {
                                                    enableBarcodePrintButt &&
                                                    <Grid item >
                                                        <BarcodePrintComponent
                                                            items={selectedRows}
                                                            actions={[
                                                                {
                                                                    key: 'STOCK_MOVE_PRODUCT',
                                                                    value: 'PRODUCT',
                                                                },
                                                                {
                                                                    key: 'STOCK_MOVE_BATCH',
                                                                    value: 'BATCH',
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container className="mt-1" >
                                                <MaterialTable
                                                    schema={schema}
                                                    uniqueKey="uuid"
                                                    noPagination
                                                    data={listData}
                                                    onSelect={this.handleSelectAction}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                disableRipple
                                test-id="receive-stock-print"
                                onClick={this.printHandler}
                            >
                                Print
                            </ActionButton>
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    data={getMemoizedPrintDataView(stockMove, purchaseOrder)}
                    print={this.state.print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/StockManagement/RecieveStock.html`}
                />
                {
                    receiveStock &&
                        <RecieveStockDialog
                            open
                            purchaseOrder={getUiObject(purchaseOrder)}
                            handleClose={this.handleCloseReceiveStock}
                        />
                }
            </React.Fragment>
        );
    }
}

RecieveStockViewDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    stockMove: PropTypes.object,
    purchaseOrder: PropTypes.object,
    targetId: PropTypes.number,
    // company: PropTypes.string.isRequired,
    enableBarcodePrintButt: PropTypes.bool,
};

RecieveStockViewDialog.defaultProps = {
    stockMove: null,
    purchaseOrder: null,
    targetId: null,
    enableBarcodePrintButt: false,
};

const mapStateToProps = state => ({
    stockMove: getStringFromObject('stockMove.selected', state, null),
    company: getStringFromObject('appConfiguration.companyName', state),
    purchaseOrder: getStringFromObject('purchaseOrder.selected', state, null),
    enableBarcodePrintButt: getStringFromObject('appConfiguration.enableBarcodePrintButton', state) || false,
});

export default connect(mapStateToProps)(withStyles(style)(RecieveStockViewDialog));
