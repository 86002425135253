import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    InputAdornment,
    IconButton,
    Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import axios from 'axios';
import cloneDeep from 'clone-deep';
import CheckIcon from '@material-ui/icons/Check';

import dialogComponentStyles from '../DialogComponent/DialogComponentStyles';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import { required } from '../../constants/FormValidations';
import {
    CASH_PAYMENT_VOUCHER_FORM_FIELDS,
    getFormValuesFrom,
    getNewPartnerIfValuesAreMissing,
    getPayloadFrom,
    getSortedLinesByDate,
    getSubCompanyPartnerAccountAndPayable,
    getTotalPayableAndReceivable,
    getUpdatedPayableAndReceivableLines,
    initialValues,
    voucherStates,
} from './CashPaymentVoucherUtil';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import {
    PAGE,
    PAYMENT_METHODS,
    PAYMENT_METHODS_MAP,
    SIZE,
} from '../../constants/constants';
import OutlinedTextField from '../OutlinedTextField';
import ActionButton from '../ActionButton/ActionButton';
import {
    apiCatchBlockFunction,
    getAllowedDefaultAccountTypes,
    getIndexFromFieldName, getJsonPath,
    isArrayValidAndNotEmpty,
    isValidFunction,

} from '../../constants/CommonUtil';
import ReduxFormMaterialTable from '../FormFieldComponents/ReduxFormMaterialTable/ReduxFormMaterialTable';
import {
    clearCashPaymentVoucher,
    clearDebitAndCreditLines,
    createOrUpdateCashPaymentVoucher,
    fetchCashPaymentVoucher,
    fetchDebitAndCreditLinesForAccount,
} from '../../redux/modules/cashPaymentVoucher/cashPaymentVoucher-actions';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { add, round, subtract } from '../../constants/PrecisionUtil';
import { errorMessage, infoMessage } from '../../redux/modules/message/message-actions';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { getPartnerApi } from '../AccountVoucherTable/AccountVoucherTableUtil';
import NumberTextField from '../NumberTextField';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const CASH_PAYMENT_VOUCHER_FORM = 'cashPaymentVoucherForm';

class CashPaymentVoucherDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            subCompany: null,
            isPayable: false, // True for payable accounts
            debitLinesPage: PAGE,
            debitLinesSize: SIZE,
            creditLinesPage: PAGE,
            creditLinesSize: SIZE,
            duplicatedVoucherNumber: this.props.duplicatedVoucherNumber,
        };
    }

    componentDidMount() {
        this.fetchSchema();
        if (isObjectValidAndNotEmpty(this.props.partner) && this.props.partner.uuid) {
            this.initializeFormWithPartner(this.props.partner);
        }
        if (this.props.voucherUuid) {
            this.fetchDraftVoucherByUuid(this.props.voucherUuid);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (isObjectValidAndNotEmpty(nextProps.partner)) {
            const nextPartnerUuid = getStringFromObject('partner.uuid', nextProps);
            const partnerUuid = getStringFromObject('partner.uuid', this.props);
            if (
                nextPartnerUuid &&
                nextPartnerUuid !== partnerUuid
            ) {
                this.initializeFormWithPartner({
                    name: getStringFromObject('partner.name', nextProps),
                    uuid: nextPartnerUuid,
                });
            }
        }
        if (nextProps.voucherUuid) {
            const nextVoucherUuid = getStringFromObject('voucherUuid', nextProps);
            const voucherUuid = getStringFromObject('voucherUuid', this.props);
            if (
                nextVoucherUuid !== voucherUuid
            ) {
                this.fetchDraftVoucherByUuid(nextVoucherUuid);
            }
        }
        if (isObjectValidAndNotEmpty(nextProps.debitAndCreditLines)) {
            const { isPayable } = this.state;
            const nextPartnerAccountUuid = getStringFromObject('debitAndCreditLines.partnerAccount.key', nextProps);
            const partnerAccountUuid = getStringFromObject('debitAndCreditLines.partnerAccount.key', this.props);
            if (nextPartnerAccountUuid) {
                if (nextPartnerAccountUuid !== partnerAccountUuid) {
                    const sortedDebitLines = getSortedLinesByDate(nextProps.debitAndCreditLines.debitLines);
                    const sortedCreditLines = getSortedLinesByDate(nextProps.debitAndCreditLines.creditLines);
                    this.updateDebitAndCreditLines(sortedDebitLines, sortedCreditLines);
                    if (
                        (isPayable && isArrayValidAndNotEmpty(sortedCreditLines)) ||
                        (!isPayable && isArrayValidAndNotEmpty(sortedDebitLines))
                    ) {
                        this.autoCalculate(
                            sortedDebitLines,
                            sortedCreditLines,
                            0,
                            Boolean(isPayable),
                            false,
                        );
                    }
                }
            } else if (partnerAccountUuid) {
                this.updateDebitAndCreditLines([], []);
            }
        }

        if (isObjectValidAndNotEmpty(nextProps.cashPaymentVoucher)) {
            const nextPartnerAccountUuid = getStringFromObject('cashPaymentVoucher.partnerAccount.key', nextProps);
            const partnerAccountUuid = getStringFromObject('cashPaymentVoucher.partnerAccount.key', this.props);
            const nextVoucherState = getStringFromObject('cashPaymentVoucher.state', nextProps);
            const voucherState = getStringFromObject('cashPaymentVoucher.state', this.props);
            if (nextPartnerAccountUuid) {
                if (
                    nextPartnerAccountUuid !== partnerAccountUuid ||
                    nextVoucherState !== voucherState
                ) {
                    const formValues = getFormValuesFrom(nextProps.cashPaymentVoucher);
                    this.initializeForm(formValues);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { formValues, voucherUuid } = this.props;
        const previousPartnerUuid = getStringFromObject(
            `formValues.${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.uuid`,
            prevProps);
        const partnerUuid = getStringFromObject(
            `formValues.${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.uuid`,
            this.props,
        );
        if (
            partnerUuid &&
            previousPartnerUuid !== partnerUuid
        ) {
            this.updatePartnerAndAccount(formValues.partner);
        }

        const previousPartnerAccountId = getStringFromObject(
            `formValues.${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.id`,
            prevProps);
        const partnerAccountId = getStringFromObject(
            `formValues.${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.id`,
            this.props,
        );
        if (
            !voucherUuid &&
            partnerAccountId &&
            previousPartnerAccountId !== partnerAccountId
        ) {
            this.fetchDebitAndCreditLinesOrCashPaymentVoucher(formValues.partnerAccount);
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearDebitAndCreditLines());
        dispatch(clearCashPaymentVoucher());
        this.props.resetDuplicateVoucherNumber();
    }

    onCloseClick = () => {
        const { handleClose } = this.props;
        if (isValidFunction(handleClose)) {
            handleClose();
        }
    }

    getRowValuesOnChange = (_value, formName, field, fields) => {
        const { isPayable } = this.state;
        if (formName && field && isObjectValidAndNotEmpty(fields)) {
            const index = getIndexFromFieldName(field);
            let isPayableLine;
            if (isPayable) {
                isPayableLine = fields.name === CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_LINES;
            } else {
                isPayableLine = fields.name === CASH_PAYMENT_VOUCHER_FORM_FIELDS.DEBIT_LINES;
            }
            if (index != null) {
                const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
                if (isObjectValidAndNotEmpty(rowValue)) {
                    console.log('test-1010', rowValue);
                    return ({ rowValue, isPayableLine });
                }
            }
        }
        return null;
    }

    handlePageOrSizeStateChange = state => (value) => {
        this.setState({
            [state]: value,
        });
    };

    updatePartnerAndAccount = async (partner) => {
        const { dispatch } = this.props;
        const newPartner = await getNewPartnerIfValuesAreMissing(partner, dispatch);
        if (isObjectValidAndNotEmpty(newPartner)) {
            const { subCompany, partnerAccount, isPayable } = getSubCompanyPartnerAccountAndPayable(newPartner);
            this.setState({
                subCompany,
                isPayable,
            }, () => {
                this.updatePartnerAccount({ ...partnerAccount });
            });
        }
    }

    updateDebitAndCreditLines = (debitLines_, creditLines_) => {
        const { change } = this.props;
        const debitLines = isArrayValidAndNotEmpty(debitLines_) ? [...debitLines_] : [];
        const creditLines = isArrayValidAndNotEmpty(creditLines_) ? [...creditLines_] : [];
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.DEBIT_LINES, debitLines);
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_LINES, creditLines);
        this.updateAmountToPay(0);
        this.updateAdvanceUsed(0);
        this.updateAmountToApply(0);
    }

    updateAmountToPay = (totalAmountToPay) => {
        const { change } = this.props;
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY, totalAmountToPay);
    }

    updateAdvanceUsed = (totalAdvanceUsed) => {
        const { change } = this.props;
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_ADVANCE_USED, totalAdvanceUsed);
    }

    updateAmountToApply = (amountToApply) => {
        const { change } = this.props;
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.AMOUNT_TO_APPLY, amountToApply);
    }

    updatePartnerAccount = (partnerAccount) => {
        const { change } = this.props;
        change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT, partnerAccount);
    }

    initializeFormWithPartner = (partner) => {
        this.initializeForm({
            ...initialValues,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER]: partner,
        });
    }

    fetchSchema = async () => {
        const { dispatch } = this.props;
        try {
            const response = await axios.get(
                getJsonPath('BookManagement/CashPaymentVoucher/DebitAndCreditLineTableSchema.json'),
            );
            this.setState({
                schema: response.data,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    fetchDraftVoucherByUuid = (voucherUuid) => {
        const { dispatch } = this.props;
        if (voucherUuid) {
            dispatch(fetchCashPaymentVoucher(voucherUuid));
        }
    }

    fetchDraftVoucherForAccount = async (accountId) => {
        const { dispatch, change } = this.props;
        const api = `${API.CASH_PAYMENT_VOUCHER.GET_DRAFT_VOUCHER_FOR_ACCOUNT}?accountId=${accountId}`;
        try {
            dispatch(showSpinner());
            const response = await axios.get(api);
            if (response.data) {
                dispatch(hideSpinner());
                const message = 'A draft voucher is found for the account Do you want to load it for the edit? \n' +
                    'Creating new will delete previous draft voucher';
                dispatch(displayWarning(
                    message,
                    () => { dispatch(fetchCashPaymentVoucher(response.data)); },
                    null,
                    () => {
                        dispatch(fetchDebitAndCreditLinesForAccount(accountId));
                        change('toBeDeletedVoucherUuid', response.data);
                    },
                    null,
                    'Edit Draft Voucher',
                    'Delete Draft And Create New',
                ));
            } else {
                dispatch(fetchDebitAndCreditLinesForAccount(accountId));
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    }

    fetchDebitAndCreditLinesOrCashPaymentVoucher = (partnerAccount) => {
        this.fetchDraftVoucherForAccount(partnerAccount.id);
    }

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        if (isObjectValidAndNotEmpty(formValues)) {
            dispatch(initialize(CASH_PAYMENT_VOUCHER_FORM, formValues));
        }
    }

    handleAutoRecalculate = () => {
        const {
            formValues: {
                debitLines,
                creditLines,
            },
            dispatch,
        } = this.props;
        const { isPayable } = this.state;
        if (
            (isPayable && !isArrayValidAndNotEmpty(creditLines)) ||
            (!isPayable && !isArrayValidAndNotEmpty(debitLines))
        ) {
            dispatch(errorMessage(`${isPayable ? 'Credit' : 'Debit'} lines are not present for auto calculations`));
            return;
        }
        dispatch(displayWarning(
            'Are you sure want to re calculate? All the changes will be lost !!',
            () => {
                this.autoCalculate(debitLines, creditLines, 0, Boolean(isPayable), false);
                this.updateAmountToApply(0);
            },
        ));
    };

    autoCalculate = (debitLines, creditLines, totalAmountToPay, isPayable, usePreviouslyAllocated) => {
        let newDebitLines;
        let newCreditLines;
        let totalReceivable;
        if (isPayable) {
            ({
                newPayableLines: newCreditLines,
                newReceivableLines: newDebitLines,
                totalReceivable,
            } = getUpdatedPayableAndReceivableLines(creditLines, debitLines, totalAmountToPay, usePreviouslyAllocated));
        } else {
            // it's a payer
            ({
                newPayableLines: newDebitLines,
                newReceivableLines: newCreditLines,
                totalReceivable,
            } = getUpdatedPayableAndReceivableLines(debitLines, creditLines, totalAmountToPay, usePreviouslyAllocated));
        }
        this.updateDebitAndCreditLines(newDebitLines, newCreditLines);
        this.updateAdvanceUsed(totalReceivable);
    }

    updateAllocation = (newAllocation, line, field, isPayableLine) => {
        const {
            change,
            formValues: {
                totalAdvanceUsed,
            },
        } = this.props;

        const { allocated: previousAllocation, residue } = line;
        const newLine = { ...line, allocated: newAllocation, allocateFully: newAllocation === residue };
        change(field, newLine);

        if (!isPayableLine) {
            const receivableWithNewAllocation = add(subtract(totalAdvanceUsed, previousAllocation), newAllocation);
            this.updateAdvanceUsed(receivableWithNewAllocation);
        }
    }

    handleAllocationChange = (value, formName, field, fields) => {
        const rowValues = this.getRowValuesOnChange(value, formName, field, fields);
        if (isObjectValidAndNotEmpty(rowValues)) {
            const { rowValue, isPayableLine } = rowValues;
            this.updateAllocation(NumberOf(value), rowValue, field, isPayableLine);
        }
    }

    handleAllocateFullySelected = (value, formName, field, fields) => {
        if (value) {
            const rowValues = this.getRowValuesOnChange(value, formName, field, fields);
            if (isObjectValidAndNotEmpty(rowValues)) {
                const { rowValue, isPayableLine } = rowValues;
                this.updateAllocation(NumberOf(rowValue.residue), rowValue, field, isPayableLine);
            }
        }
    }

    handlePartnerAccountSelect = (newAccount) => {
        const { change } = this.props;
        if (isObjectValidAndNotEmpty(newAccount)) {
            this.setState({
                subCompany: newAccount.subCompany,
                isPayable: newAccount.type === 'payable',
            }, () => {
                change(CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER, null);
            });
        }
    }

    handleFieldBlur = () => {
        const { dispatch, formValues } = this.props;
        const totalAmountToPay = NumberOf(
            getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY, formValues),
        );
        if (totalAmountToPay < 0) {
            dispatch(infoMessage('Total debit and credit are not matching. Please adjust manually'));
        }
    }

    createOrUpdateCashPaymentVoucher = (formValues, isConfirmed) => {
        const { dispatch } = this.props;
        const { isPayable, duplicatedVoucherNumber } = this.state;
        const { payableTotal, receivableTotal } = getTotalPayableAndReceivable(formValues, isPayable);
        if (payableTotal !== receivableTotal) {
            dispatch(errorMessage(
                `Total payable(${payableTotal}) and receivable(${receivableTotal}) are not matching`,
            ));
            return;
        }
        const payload = getPayloadFrom(formValues, isConfirmed);
        if (isObjectValidAndNotEmpty(payload)) {
            const message = `Payment Voucher ${isConfirmed ? 'Create' : 'Saved'} Successfully`;
            if (duplicatedVoucherNumber) {
                payload.cancelledVoucherNumber = duplicatedVoucherNumber;
            }
            dispatch(createOrUpdateCashPaymentVoucher(payload, message));
        } else {
            // code should never reach here
            dispatch(errorMessage('Something went wrong while submitting'));
        }
    }

    saveAndPayLater = (formValues) => {
        this.createOrUpdateCashPaymentVoucher(formValues, false);
    }

    confirmAndPay = (formValues) => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'This operation is not reversible. Are you sure you want to confirm ?',
            () => {
                this.createOrUpdateCashPaymentVoucher(formValues, true);
            },
        ));
    }

    handleCreateNew = () => {
        const { dispatch } = this.props;
        dispatch(clearDebitAndCreditLines());
        dispatch(clearCashPaymentVoucher());
        this.initializeForm({ ...initialValues });
        this.setState({
            duplicatedVoucherNumber: null,
        });
    }

    applyLimitAmount = (amountToApply) => {
        const {
            formValues: {
                debitLines,
                creditLines,
            },
        } = this.props;
        const { isPayable } = this.state;
        this.autoCalculate(debitLines, creditLines, round(amountToApply), isPayable, true);
        this.updateAmountToPay(round(amountToApply));
        this.updateAmountToApply(round(amountToApply));
    }

    handleAmountToApplyEnter = () => {
        const { formValues } = this.props;
        const amountToApply = NumberOf(
            getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.AMOUNT_TO_APPLY, formValues),
        );
        if (NumberOf(amountToApply) >= 0) {
            this.applyLimitAmount(amountToApply);
        }
    }

    handleApplyAmountKeyPress = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            this.handleAmountToApplyEnter();
        }
    };

    render() {
        const {
            classes,
            formValues,
            dispatch,
            onlySuppliers,
            onlyPayers,
            partner,
            handleSubmit,
            voucherUuid,
        } = this.props;
        const {
            schema,
            subCompany,
            isPayable,
            debitLinesPage,
            debitLinesSize,
            creditLinesPage,
            creditLinesSize,
            duplicatedVoucherNumber,
        } = this.state;
        console.log('cash-payment-voucher-form-values', formValues);

        const selectedPartner = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER, formValues);
        const paymentMethod = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD, formValues);
        const debitLines = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.DEBIT_LINES, formValues);
        const totalDebitLines = (debitLines || []).length;
        const creditLines = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_LINES, formValues);
        const totalCreditLines = (creditLines || []).length;
        const totalAmountToPay = NumberOf(
            getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY, formValues),
        );
        const totalAdvanceUsed = NumberOf(
            getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_ADVANCE_USED, formValues),
        );
        const voucherState = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.STATE, formValues);
        const partnerAccount = getStringFromObject(CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT, formValues);

        const isCheque = paymentMethod && paymentMethod.value === PAYMENT_METHODS_MAP.CHEQUE.value;
        const isBank = paymentMethod && paymentMethod.value === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
        const isAmountPayable = totalAmountToPay > 0;
        const isDraft = voucherState === voucherStates.DRAFT;
        const disableSaveAndConfirm = (
            totalAmountToPay < 0 ||
            (totalAmountToPay === 0 && totalAdvanceUsed === 0) ||
            (isPayable && !isArrayValidAndNotEmpty(creditLines)) ||
            (!isPayable && !isArrayValidAndNotEmpty(debitLines))
        );

        const tableProps = {
            enablePagination: true,
            dispatch,
            rerenderOnEveryChange: true,
            tableRoot: { overflow: 'visible' },
            formValues,
            // infoIcon: true,
            actionHandlers: {
                onAllocationChange: this.handleAllocationChange,
                onAllocateFullySelected: this.handleAllocateFullySelected,
                onFieldBlur: this.handleFieldBlur,
            },
            isEditable: isDraft,
            internalPagination: true,
            showRowNumber: true,
            rowsPerPageOptions: [10, 25],
            ...schema,
        };

        const debitLinesTable = bottom => (
            isArrayValidAndNotEmpty(debitLines) && (
                <div className={bottom ? '' : 'mt-1'} style={{ background: '#fff' }}>
                    <Grid container justify="center" style={{ fontSize: '1.2rem', padding: '1.2rem' }}>
                        <Grid item>
                            Debit Lines
                        </Grid>
                    </Grid>
                    <Grid container>
                        {
                            isObjectValidAndNotEmpty(schema) &&
                            <ReduxFormMaterialTable
                                fieldName={CASH_PAYMENT_VOUCHER_FORM_FIELDS.DEBIT_LINES}
                                paginationDetails={{
                                    page: debitLinesPage,
                                    size: debitLinesSize,
                                    totalElements: totalDebitLines,
                                }}
                                handlePageChange={this.handlePageOrSizeStateChange('debitLinesPage')}
                                handleSizeChange={this.handlePageOrSizeStateChange('debitLinesSize')}
                                {...tableProps}
                            />
                        }
                    </Grid>
                </div>
            )
        );

        const creditLinesTable = bottom => (
            isArrayValidAndNotEmpty(creditLines) && (
                <div className={bottom ? '' : 'mt-1'} style={{ background: '#fff' }}>
                    <Grid container justify="center" style={{ fontSize: '1.2rem', padding: '1.2rem' }}>
                        <Grid item>
                            Credit Lines
                        </Grid>
                    </Grid>
                    <Grid container>
                        {
                            isObjectValidAndNotEmpty(schema) &&
                            <ReduxFormMaterialTable
                                fieldName={CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_LINES}
                                paginationDetails={{
                                    page: creditLinesPage,
                                    size: creditLinesSize,
                                    totalElements: totalCreditLines,
                                }}
                                handlePageChange={this.handlePageOrSizeStateChange('creditLinesPage')}
                                handleSizeChange={this.handlePageOrSizeStateChange('creditLinesSize')}
                                {...tableProps}
                            />
                        }
                    </Grid>
                </div>
            )
        );

        const amountToApplyField = (
            <Grid container className="mt-2" spacing={24}>
                <Grid item lg={8} md={8} sm={8}>
                    <Typography variant="body1" gutterBottom style={{ color: '#840000de' }} >
                        Note: Loading lines may take some time depending on the number of entries.
                    </Typography>
                    {
                        duplicatedVoucherNumber &&
                        <Typography variant="body1" gutterBottom style={{ color: '#840000de', marginTop: '20px' }} >
                            { `Creating new voucher with number ${duplicatedVoucherNumber.slice(5)}` }
                        </Typography>
                    }
                </Grid>
                <Grid item lg={3} md={3} sm={3} className="mr-2">
                    <Field
                        testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.AMOUNT_TO_APPLY}-test-id`}
                        name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.AMOUNT_TO_APPLY}
                        component={NumberTextField}
                        type="number"
                        label="Extra Amount To Pay"
                        onKeyPress={this.handleApplyAmountKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.handleAmountToApplyEnter}
                                        data-test-id="confirm-amount"
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        );

        return (
            <Dialog
                open
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>
                                Payment Voucher
                        </div>
                        <Close
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={this.onCloseClick}
                            test-id="cash-payment-voucher"
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container className="mt-1" justify="flex-start" spacing={24}>
                        <Grid container item spacing={24}>
                            <Grid item lg={3} md={4} sm={5}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}
                                    component={ReduxFormReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label="Partner"
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    autocomplete
                                    defaultOptions
                                    dataSourceApi={getPartnerApi(onlySuppliers, onlyPayers)}
                                    validate={isObjectValidAndNotEmpty(partnerAccount) ? [] : [required]}
                                    required={!isObjectValidAndNotEmpty(partnerAccount)}
                                    isDisabled={isObjectValidAndNotEmpty(partner) || voucherUuid || !isDraft}
                                />
                            </Grid>
                            <Grid item lg={3} md={4} sm={5}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}
                                    component={ReduxFormReactSelectMaterial}
                                    label="Account"
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    autocomplete
                                    defaultOptions
                                    dataSourceApi={`${API.SEARCH.ACCOUNTS}?type=payable,receivable&subCompany=${subCompany || ''}&searchString=`}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'id',
                                    }}
                                    onSelectHandlers={['handlePartnerAccountSelect']}
                                    actionHandlers={{
                                        handlePartnerAccountSelect: this.handlePartnerAccountSelect,
                                    }}
                                    validate={[required]}
                                    required
                                    isDisabled={isObjectValidAndNotEmpty(partner) || voucherUuid || !isDraft}
                                />
                            </Grid>
                            <Grid item lg={2} md={4} sm={5}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_DATE}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_DATE}
                                    component={DateInput}
                                    label="Payment Date"
                                    placeholder="Payment Date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    validate={required}
                                    disabled={!isDraft}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24}>
                            <Grid item lg={2} md={3} sm={4}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD}
                                    component={ReduxFormReactSelectMaterial}
                                    label="Payment Method"
                                    validate={isAmountPayable ? [required] : []}
                                    placeholder="Select"
                                    required={isAmountPayable}
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    options={PAYMENT_METHODS}
                                    dataSourceConfig={{
                                        text: 'label',
                                        value: 'value',
                                    }}
                                    isDisabled={!isDraft}
                                />
                            </Grid>
                            <Grid item lg={3} md={4} sm={5}>
                                <Field
                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_FORM}-test-id`}
                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.CREDIT_FORM}
                                    component={ReduxFormReactSelectMaterial}
                                    label={isPayable ? 'Credit From' : 'Debit To'}
                                    validate={isAmountPayable ? [required] : []}
                                    required={isAmountPayable}
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    dataSourceApi={`${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&isSupplierPayment=true&type=${getAllowedDefaultAccountTypes('paymentVoucherAccountTypes', this.props.allowedAccountTypes)}&subCompany=${subCompany || 'CLINIC'}&searchString=`}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    autocomplete
                                    isDisabled={
                                        !isDraft ||
                                        (!isObjectValidAndNotEmpty(partnerAccount) &&
                                            !isObjectValidAndNotEmpty(selectedPartner))
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        isObjectValidAndNotEmpty(paymentMethod) && (
                            <Grid container className="mt-1" spacing={24}>
                                {
                                    (isCheque || isBank) && (
                                        <React.Fragment>
                                            <Grid item lg={3} md={3} sm={4}>
                                                <Field
                                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.BANK_NAME}-test-id`}
                                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.BANK_NAME}
                                                    label="Bank Name"
                                                    placeholder="Enter Bank Name"
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                            <Grid item lg={2} md={3} sm={4}>
                                                <Field
                                                    testId={
                                                        `${CASH_PAYMENT_VOUCHER_FORM_FIELDS.REFERENCE_NUMBER}-test-id`
                                                    }
                                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.REFERENCE_NUMBER}
                                                    label={isCheque ? 'Cheque Number' : 'Reference Number'}
                                                    placeholder={isCheque ? 'Enter Cheque No' : 'Enter Reference No'}
                                                    required
                                                    validate={[required]}
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                            <Grid item lg={2} md={4} sm={5}>
                                                <Field
                                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.DATE}-test-id`}
                                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.DATE}
                                                    component={DateInput}
                                                    label={isCheque ? 'Cheque Date' : 'Date'}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required
                                                    validate={required}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                            <Grid item lg={2} md={3} sm={4}>
                                                <Field
                                                    testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.REMARKS}-test-id`}
                                                    name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.REMARKS}
                                                    label="Remarks"
                                                    component={OutlinedTextField}
                                                    disabled={!isDraft}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                        )
                    }
                    {
                        isPayable ? (
                            <React.Fragment>
                                {debitLinesTable(false)}
                                {amountToApplyField}
                                {creditLinesTable(true)}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {creditLinesTable(true)}
                                {amountToApplyField}
                                {debitLinesTable(false)}
                            </React.Fragment>
                        )
                    }
                    <Grid container className="mt-2" spacing={24}>
                        <Grid item lg={3} md={3} sm={3}>
                            <Field
                                testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.NARRATION}-test-id`}
                                name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.NARRATION}
                                component={OutlinedTextField}
                                label="Memo"
                                multiline
                                rows={3}
                                rowsMax={3}
                                fullWidth
                                required
                                disabled={!isDraft}
                                validate={[required]}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions style={{ padding: '12px' }} className="mt-1">
                        <div style={{ width: '100%' }}>
                            <div>
                                <Grid container justify="flex-end">
                                    <div>
                                        <Field
                                            testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_ADVANCE_USED}-test-id`}
                                            name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_ADVANCE_USED}
                                            label="Total Advance Used"
                                            component={OutlinedTextField}
                                            type="number"
                                            disabled
                                        />
                                    </div>
                                    <div className="ml-1">
                                        <Field
                                            testId={`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY}-test-id`}
                                            name={CASH_PAYMENT_VOUCHER_FORM_FIELDS.TOTAL_AMOUNT_TO_PAY}
                                            label={`Total Amount To ${isPayable ? 'Pay' : 'Receive'}`}
                                            component={OutlinedTextField}
                                            type="number"
                                            disabled
                                        />
                                    </div>
                                </Grid>
                            </div>
                            <div>
                                <Grid container justify="space-between" className="mt-1">
                                    <Grid item>
                                        <Grid container justify="flex-start">
                                            {
                                                isDraft && (
                                                    <ActionButton
                                                        test-id="cash-purchase-voucher-auto-recalculate"
                                                        disableRipple
                                                        onClick={this.handleAutoRecalculate}
                                                    >
                                                        Auto Recalculate
                                                    </ActionButton>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container justify="flex-end">
                                            <ActionButton
                                                test-id="cash-purchase-voucher-cancel-payment"
                                                primary={false}
                                                disableRipple
                                                onClick={this.onCloseClick}
                                            >
                                                Close
                                            </ActionButton>
                                            {
                                                isDraft ? (
                                                    <React.Fragment>
                                                        <ActionButton
                                                            test-id="cash-purchase-voucher-save"
                                                            className="ml-1"
                                                            disableRipple
                                                            disabled={disableSaveAndConfirm}
                                                            onClick={handleSubmit(this.saveAndPayLater)}
                                                        >
                                                            Save
                                                        </ActionButton>
                                                        <ActionButton
                                                            testId="cash-purchase-voucher-confirm"
                                                            className="ml-1"
                                                            disableRipple
                                                            disabled={disableSaveAndConfirm}
                                                            onClick={handleSubmit(this.confirmAndPay)}
                                                        >
                                                            {`Confirm & ${isPayable ? 'Pay' : 'Receive'}`}
                                                        </ActionButton>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {
                                                            !voucherUuid && !isObjectValidAndNotEmpty(partner) && (
                                                                <ActionButton
                                                                    test-id="cash-purchase-voucher-new"
                                                                    className="ml-1"
                                                                    disableRipple
                                                                    onClick={this.handleCreateNew}
                                                                >
                                                                    Create New
                                                                </ActionButton>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

CashPaymentVoucherDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    dispatch: PropTypes.func,
    partner: PropTypes.object,
    debitAndCreditLines: PropTypes.object,
    cashPaymentVoucher: PropTypes.object,
    change: PropTypes.func,
    // if both payer and supplier are false then both payers and suppliers are fetched
    // if any one is true only that partner is fetched
    onlyPayers: PropTypes.bool,
    onlySuppliers: PropTypes.bool,
    voucherUuid: PropTypes.string,
    duplicatedVoucherNumber: PropTypes.string,
    resetDuplicateVoucherNumber: PropTypes.func,
    allowedAccountTypes: PropTypes.object,
};

CashPaymentVoucherDialog.defaultProps = {
    formValues: {},
    dispatch: () => {},
    partner: {},
    debitAndCreditLines: {},
    cashPaymentVoucher: {},
    change: () => {},
    onlyPayers: false,
    onlySuppliers: false,
    voucherUuid: null,
    duplicatedVoucherNumber: null,
    resetDuplicateVoucherNumber: () => {},
    allowedAccountTypes: {},
};

const mapStateToProps = state => ({
    formValues: state.form[CASH_PAYMENT_VOUCHER_FORM] ? state.form[CASH_PAYMENT_VOUCHER_FORM].values : {},
    debitAndCreditLines: state.debitAndCreditLines,
    cashPaymentVoucher: state.cashPaymentVoucher,
    allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
});

export default connect(mapStateToProps)(reduxForm({
    form: CASH_PAYMENT_VOUCHER_FORM,
    initialValues: { ...initialValues },
})(withStyles(dialogComponentStyles)(CashPaymentVoucherDialog)));
