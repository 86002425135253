import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    DOCTOR_Q_LIST_FAILURE,
    DOCTOR_Q_LIST_SUCCESS,
    OPEN_ENCOUNTER_FETCH_SUCCESS,
    OPEN_ENCOUNTER_FETCH_FAILURE,
    INCOMPLETE_ENCOUNTER_FETCH_SUCCESS,
    INCOMPLETE_ENCOUNTER_FETCH_FAILURE,
} from '../../redux/modules/doctorQueueList/doctorQueueList-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { getLoggedInProviderUuid } from '../../constants/state';

export function* doctorQListRequest(action) {
    console.log('sdafsdk');
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const {
            api,
            userUuid,
            sort,
            consultationStatus,
        } = action;
        const apiWithQueryParam = `${api}?userUuid=${userUuid}&type=${sort}&consultationStatus=${consultationStatus}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: DOCTOR_Q_LIST_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        let message = 'Network Error !!!';
        if (e.response && e.response.data && e.response.data.exception) {
            message = e.response.data.exception;
        }
        yield put({ type: ERROR_MESSAGE, message });
        yield put({ type: DOCTOR_Q_LIST_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* openEncounterFetchRequest(action) {
    try {
        const { page, size } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParam = `${action.api}?page=${page}&size=${size}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: OPEN_ENCOUNTER_FETCH_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        console.log('error in api call', e);
        yield put({ type: OPEN_ENCOUNTER_FETCH_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* incompleteEncounterFetchRequest(action) {
    try {
        const { page, size, searchString } = action;
        // const config = {
        //     headers: { 'Content-Type': 'application/json' },
        // };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParam =
            `${action.api}?page=${page}&size=${size}&providerUuid=${getLoggedInProviderUuid()}&searchStr=${searchString}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: INCOMPLETE_ENCOUNTER_FETCH_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        console.log('error in api call', e);
        yield put({ type: INCOMPLETE_ENCOUNTER_FETCH_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
