import {
    FETCH_MY_CASH_CLOSE_FAILURE,
    FETCH_MY_CASH_CLOSE_REQUEST, FETCH_MY_CASH_CLOSE_SUCCESS,
} from './myCashClose-actions';
import initialStates from '../../initialStates';

// eslint-disable-next-line default-param-last
const myCashCloseReducer = (state = initialStates.myCashClose, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_MY_CASH_CLOSE_REQUEST:
        newState = { ...state, isLoading: true };
        break;
    case FETCH_MY_CASH_CLOSE_SUCCESS:
        newState = {
            ...state,
            isLoading: false,
            list: action.data.content,
            totalElements: action.data.totalElements,
            lastUpdated: new Date(),
        };
        break;
    case FETCH_MY_CASH_CLOSE_FAILURE:
        newState = { ...state, isLoading: false };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default myCashCloseReducer;
