/**
 * Created by sachinprakash on 20/6/19.
 */
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { reduxForm, SubmissionError } from 'redux-form';
import { CASH_CLOSE_FORM, mapUiObjectToPayload } from './CashCloseDialogUtil';
import DialogComponent from '../DialogComponent/DialogComponent';
import { dispatchCreateCashCloseRequest } from '../../redux/modules/cashClose/cashClose-actions';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import CashCloseDialogForm from './CashCloseDialogForm';
import { getStringFromObject } from '../../constants/lodashUtils';

const styles = () => ({
    paper: {
        overflow: 'visible',
        background: '#fafafa',
    },
    dialog: {
        minHeight: '750px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
});

class CashCloseDialog extends React.PureComponent {
    handleSubmitForm = (formValues) => {
        const payload = mapUiObjectToPayload(formValues);
        if (payload.error) {
            throw new SubmissionError({ cashCollections: { _error: payload.error } });
        }
        const { dispatch } = this.props;
        dispatch(dispatchCreateCashCloseRequest(payload));
    };

    handleClose = () => {
        const {
            pristine,
            dispatch,
            handleClose,
        } = this.props;
        if (!pristine) {
            dispatch(displayWarning(
                'You have made some changes, are you sure want to cancel ?',
                handleClose,
                false,
            ));
        } else {
            handleClose();
        }
    };

    render() {
        const {
            formValues,
            classes,
            handleSubmit,
        } = this.props;
        const editable = !formValues.uuid;
        console.log('kjhlasdjfhjkasdfh', this.props, editable);
        return (
            <DialogComponent
                open
                maxWidth="lg"
                className={classes.dialog}
                classes={{
                    paper: classes.paper,
                }}
                header="Cash Close"
                handleClose={this.handleClose}
            >
                <CashCloseDialogForm
                    {...this.props}
                    submitForm={handleSubmit(this.handleSubmitForm)}
                    handleClose={this.handleClose}
                />
            </DialogComponent>
        );
    }
}

CashCloseDialog.propTypes = {
    handleClose: PropTypes.func,
    classes: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    selected: PropTypes.object,
    cashCloseId: PropTypes.number,
    pristine: PropTypes.bool.isRequired,
};

CashCloseDialog.defaultProps = {
    handleClose: () => {},
    classes: {},
    formValues: {},
    selected: null,
    cashCloseId: null,
};

const mapStateToProps = state => ({
    selected: getStringFromObject('cashClose.selected', state, null),
    formValues: getStringFromObject(`form.${CASH_CLOSE_FORM}.values`, state, {}),
});

export default connect(mapStateToProps)(reduxForm({
    form: CASH_CLOSE_FORM,
})(withStyles(styles)(CashCloseDialog)));

