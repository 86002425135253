import initialStates from '../../initialStates';
import { CLEAR_SELECTED_ACCOUNT_VOUCHER, FETCH_ACCOUNT_VOUCHER_SUCCESS } from './payment-actions';

const paymentReducer = (state = initialStates.payment, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ACCOUNT_VOUCHER_SUCCESS:
        newState = Object.assign({}, state, {
            selectedVoucher: action.data,
        });
        break;
    case CLEAR_SELECTED_ACCOUNT_VOUCHER:
        newState = Object.assign({}, state, {
            selectedVoucher: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default paymentReducer;
