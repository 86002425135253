import { call, put, select } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    ADD_VITALS_FAILURE,
    ADD_VITALS_SUCCESS,
    FETCH_VITALS_QUEUE_LIST_FAILURE,
    FETCH_VITALS_QUEUE_LIST_OPTIMIZED_FAILURE,
    FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS,
    FETCH_VITALS_QUEUE_LIST_SUCCESS,
} from '../../redux/modules/vitalsQueue/vitalsQueue-actions';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import MESSAGES from '../../constants/messages';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import generateSearchConstruct from '../../components/NurseQueueComponent/VitalsContainer/VitalsTabV2Util';
import { getStringFromObject } from '../../constants/lodashUtils';

export const getNurseEncounterTypeUuid = state => (
    getStringFromObject('appConfiguration.nurseEncounterTypeUuid', state)
);

export function* fetchVitalsQueueWorker(action) {
    try {
        const {
            api,
            page,
            size,
            sortDir,
            sortCol,
            searchConstruct,
            showFulfilled,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParams =
            // eslint-disable-next-line max-len
            `${api}?pageNo=${page}&pageSize=${size}&sortDir=${sortDir}&sortCol=${sortCol}&fulfilled=${showFulfilled}`;
        const response = yield call(
            retriable().post,
            apiWithQueryParams,
            searchConstruct || null,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put({
            type: FETCH_VITALS_QUEUE_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        yield put({ type: FETCH_VITALS_QUEUE_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchVitalsQueueOptimizedWorker(action) {
    try {
        const {
            api,
            page,
            size,
            seekParams,
            seekDirection,
            filters,
        } = action;
        const config = { headers: { 'Content-Type': 'application/json' } };
        const apiWithParams = addSeekDirectionAndParamsToUrlParams(`${api}?size=${size}`, seekDirection, seekParams);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const searchConstruct = generateSearchConstruct(filters);
        const response = yield call(retriable().post, apiWithParams, searchConstruct, config);
        yield put({
            type: FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS,
            data: {
                ...(response.data || {}),
                page,
            },
            filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: 'error',
            });
        }
        yield put({ type: FETCH_VITALS_QUEUE_LIST_OPTIMIZED_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* addVitalsWorker(action) {
    try {
        const {
            api,
            observations,
            visitUuid,
            patientUuid,
            providerUuid,
            vitalsQueueUuid,
            successCallBack,
        } = action;
        const nursingEncounterType = yield select(getNurseEncounterTypeUuid);

        const apiWithQueryParams =
            // eslint-disable-next-line
            `${api}?visitUuid=${visitUuid}&patientUuid=${patientUuid}&encounterTypeUuid=${nursingEncounterType}&providerUuid=${providerUuid}&vitalsQueueUuid=${vitalsQueueUuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParams, observations);
        yield put({
            type: ADD_VITALS_SUCCESS,
            data: response.data,
        });
        yield put({ type: SUCCESS_MESSAGE, message: MESSAGES.VITALS.ADD_SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(successCallBack)) {
            successCallBack();
        }
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: ADD_VITALS_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
