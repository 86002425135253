import cloneDeep from 'clone-deep';
import axios from 'axios';
import {
    apiCatchBlockFunction,
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction, isValidNumeric,
    isValidObject, roundedValueFixedToTwoDigits,
} from '../../constants/CommonUtil';
import API from '../../constants/api';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const EMPTY_ROW = {
    product: '',
    description: '',
    uom: '',
    quantity: '',
    availableQuantity: '',
    changeInQuantity: '',
    cost: '',
    salePrice: '',
    mrp: '',
    expiryDate: '',
    uomCategoryId: 0,
    productId: 0,
};

export const setValuesOfProductIntoRow = (rowValue, actionParams) => {
    const newRowValue = cloneDeep(rowValue);
    const product = newRowValue.product ? newRowValue.product : {};
    const quantity = isValidNumeric(newRowValue.quantity) ? Math.max(Number(newRowValue.quantity), 1) : null;
    const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
    const factor = uom && uom.factor ? Number(uom.factor) : 1;
    const salePrice = newRowValue.rate == null ? Number(product.price) : Number(newRowValue.salePrice);
    const cost = newRowValue.rate == null ? Number(product.cost) : Number(newRowValue.cost);
    const amount = (quantity * factor * salePrice);
    const taxes = newRowValue.taxes || [];
    let totalTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            const newTax = (Number(tax.amount) * amount);
            totalTax += Number(newTax);
        });
    }
    // Resetting after tax calculation
    newRowValue.total = roundedValueFixedToTwoDigits(amount + totalTax);
    newRowValue.quantity = quantity;
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, false);
    newRowValue.uom = uom;
    newRowValue.paramMap = { productUomCategroyId: getStringFromObject('erpUomDto.category.key', product) };
    newRowValue.salePrice = salePrice;
    newRowValue.cost = cost;
    newRowValue.mrp = salePrice;
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, null);
    newRowValue.amount = roundedValueFixedToTwoDigits(amount);
    newRowValue.uomCategoryId = getStringFromObject('erpUomDto.category.key', product) || 0;
    newRowValue.productId = getStringFromObject('productId', product) || 0;
    const locationUuid = getStringFromObject('sourceLoc.uuid', actionParams, null);
    const productUuid = getStringFromObject('product.uuid', newRowValue, null);
    const destinationLocationUuid = getStringFromObject('destinationLoc.uuid', actionParams, null);
    return {
        ...newRowValue,
        quantity,
        locationUuid,
        productUuid,
        destinationLocationUuid,
    };
};

export const setValuesOfProductIntoRowForAdjustInventory = (rowValue, actionParams) => {
    const newRowValue = cloneDeep(rowValue);
    const product = newRowValue.product ? newRowValue.product : {};
    const quantity = isValidNumeric(newRowValue.quantity) ? Math.max(Number(newRowValue.quantity), 0) : null;
    const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
    const factor = uom && uom.factor ? Number(uom.factor) : 1;
    const salePrice = newRowValue.rate == null ? Number(product.price) : Number(newRowValue.salePrice);
    const cost = newRowValue.rate == null ? Number(product.cost) : Number(newRowValue.cost);
    const amount = (quantity * factor * salePrice);
    const taxes = newRowValue.taxes || [];
    let totalTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            const newTax = (Number(tax.amount) * amount);
            totalTax += Number(newTax);
        });
    }
    // Resetting after tax calculation
    newRowValue.total = roundedValueFixedToTwoDigits(amount + totalTax);
    newRowValue.quantity = quantity;
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, false);
    newRowValue.uom = uom;
    newRowValue.paramMap = { productUomCategroyId: getStringFromObject('erpUomDto.category.key', product) };
    newRowValue.salePrice = salePrice;
    newRowValue.cost = cost;
    newRowValue.mrp = salePrice;
    newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, null);
    newRowValue.amount = roundedValueFixedToTwoDigits(amount);
    newRowValue.uomCategoryId = getStringFromObject('erpUomDto.category.key', product) || 0;
    newRowValue.productId = getStringFromObject('productId', product) || 0;
    const locationUuid = getStringFromObject('sourceLoc.uuid', actionParams, null);
    const productUuid = getStringFromObject('product.uuid', newRowValue, null);
    const destinationLocationUuid = getStringFromObject('destinationLoc.uuid', actionParams, null);
    return {
        ...newRowValue,
        quantity,
        locationUuid,
        productUuid,
        destinationLocationUuid,
    };
};

export const setBatchValuesOfProduct = (response, rowValue) => {
    console.log('asdjuasduasduas0-sad', rowValue);
    const newRowValue = cloneDeep(rowValue);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    newRowValue.isInternalLocation = getStringFromObject('data.locationType', response, '') === 'internal';
    newRowValue.batchWithQty = [];
    newRowValue.allBatches = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        batchDetails.map((batch) => {
            if (!getStringFromObject('batch.key', batch, false)) {
                newRowValue.availableQuantity = getStringFromObject('quantity', batch, 0);
            } else {
                newRowValue.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                            (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
                newRowValue.allBatches.push({ ...batch });
            }
            return null;
        });
    }
    return newRowValue;
};

export const setBatchValuesOfProductForAdjustInventory = (response, rowValue) => {
    console.log('asdjuasduasduas0-sad', rowValue);
    const newRowValue = cloneDeep(rowValue);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    newRowValue.isInternalLocation = getStringFromObject('data.locationType', response, '') === 'internal';
    newRowValue.batchWithQty = [];
    newRowValue.allBatches = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        batchDetails.map((batch) => {
            if (!getStringFromObject('batch.key', batch, false)) {
                newRowValue.quantityOnHand = getStringFromObject('quantity', batch, 0);
            } else {
                newRowValue.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                            (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
                newRowValue.allBatches.push({ ...batch });
            }
            return null;
        });
    }
    return newRowValue;
};

const getChangeInQuantity = (rowValue) => {
    const quantityInHand = NumberOf(rowValue.quantityOnHand);
    const quantity = NumberOf(rowValue.quantity);
    const diff = quantity - quantityInHand;
    if (diff > 0) {
        return `+${diff}`;
    }
    return diff;
};

const setLine = (rowValue, index, form, actionParams) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.products.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        const newRow = { ...EMPTY_ROW };
        setFieldValue(`products.${valuesLength}`, newRow);
    }
    const dispatch = getStringFromObject('dispatch', form, () => { });
    console.log('asdfsdfsdfdsfdsf', rowValue);
    if (isObjectValidAndNotEmpty(rowValue)) {
        let newRowValue = setValuesOfProductIntoRow(rowValue, actionParams);
        const {
            locationUuid,
            productUuid,
            destinationLocationUuid,
        } = newRowValue;
        const allowProductsWithoutBatch = true;
        const expiredProductLocations = getStringFromObject('expiredProductLocations', actionParams, '');
        const allowExpiredProducts = (expiredProductLocations.includes(locationUuid) && expiredProductLocations.includes(destinationLocationUuid));
        if (locationUuid) {
            axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                params: {
                    productUuid,
                    locationUuid,
                    allowExpiredProducts,
                    allowProductsWithoutBatch,
                },
            }).then((response) => {
                newRowValue = setBatchValuesOfProduct(response, newRowValue);
                setFieldValue(`products[${index}]`, newRowValue);
            }).catch(error => apiCatchBlockFunction(error, dispatch));
        }
    }
};

const setLineForAdjustInventory = (rowValue, index, form, actionParams) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.products.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        const newRow = { ...EMPTY_ROW };
        setFieldValue(`products.${valuesLength}`, newRow);
    }
    const dispatch = getStringFromObject('dispatch', form, () => { });
    console.log('asdfsdfsdfdsfdsf', rowValue);
    if (isObjectValidAndNotEmpty(rowValue)) {
        let newRowValue = setValuesOfProductIntoRowForAdjustInventory(rowValue, actionParams);
        const {
            locationUuid,
            productUuid,
            destinationLocationUuid,
        } = newRowValue;
        const allowProductsWithoutBatch = true;
        const expiredProductLocations = getStringFromObject('expiredProductLocations', actionParams, '');
        const allowExpiredProducts = (expiredProductLocations.includes(locationUuid) && expiredProductLocations.includes(destinationLocationUuid));
        if (locationUuid) {
            axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                params: {
                    productUuid,
                    locationUuid,
                    allowExpiredProducts,
                    allowProductsWithoutBatch,
                },
            }).then((response) => {
                newRowValue = setBatchValuesOfProductForAdjustInventory(response, newRowValue);
                newRowValue.changeInQuantity = getChangeInQuantity(newRowValue);
                setFieldValue(`products[${index}]`, newRowValue);
            }).catch(error => apiCatchBlockFunction(error, dispatch));
        }
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, fieldPath, form, actionParams) => {
    console.log('asdjasd0asda', product, fieldPath, form, actionParams);
    if (isObjectValidAndNotEmpty(product) && fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.taxes = product.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.quantity = isValidNumeric(rowValue.quantity) ? Number(rowValue.quantity) : 1;
            rowValue.isInternalLocation = false;
            rowValue.userCost = product.cost;
            setLine(rowValue, index, form, actionParams);
        }
    }
};

const handleProductSelectForAdjustInventory = (product, fieldPath, form, actionParams) => {
    console.log('asdjasd0asda', product, fieldPath, form, actionParams);
    if (isObjectValidAndNotEmpty(product) && fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.taxes = product.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.quantity = isValidNumeric(rowValue.quantity) ? Number(rowValue.quantity) : 0;
            rowValue.isInternalLocation = false;
            setLineForAdjustInventory(rowValue, index, form, actionParams);
        }
    }
};

// TODO remove unused code
/*
const handleTaxSelect = (value, fieldPath, form) => {
    if (isObjectValidAndNotEmpty(value) && fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = value;
                // setLine(rowValue, index, form, actionParams);
            }
        }
    }
}; */

const quantityChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            console.log('asdua09daus0d9asa', rowValue);
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = value;
                rowValue.changeInQuantity = getChangeInQuantity(rowValue);
                // setLine(rowValue, index, form, actionParams);
            }
        }
    }
};

/*
const rateChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.rate = value;
                // setLine(rowValue, index, form, actionParams);
            }
        }
    }
};
*/

const rowChangeHandler = (product, fieldPath, form) => {
    if (fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            console.log('asdua09daus0d9asa', rowValue);
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            const setFieldValue = getStringFromObject('setFieldValue', form);
            if (isValidFunction(setFieldValue)) {
                setFieldValue(`products[${index}].operation`, operation);
            }
        }
    }
};

const deleteRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.products', form, []));
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(setFieldValue) &&
        isArrayValidAndNotEmpty(values)) {
        selectedRows.forEach((idx) => {
            values.splice(idx, 1);
            if (values.length === 0) {
                values.push(EMPTY_ROW);
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
        setFieldValue('products', values);
    }
};

const handleBatchSelect = (value, name, form) => {
    console.log('Params', value, form);
    if (name && isValidObject(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const index = getIndexFromFieldName(name);
        if (index != null && isValidFunction(setFieldValue)) {
            const expiryDateTimeInMillis = getStringFromObject('expiryDate', value);
            const availableQuantity = getStringFromObject('quantity', value);
            if (expiryDateTimeInMillis) {
                setFieldValue(
                    `products.${index}.expiryDate`,
                    getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis)),
                );
            }
            if (availableQuantity) {
                setFieldValue(
                    `products.${index}.availableQuantity`,
                    availableQuantity,
                );
            }
        }
    }
};

export const setValuesOnBatchSelectForAdjustInventory = (oldRowValue, value) => {
    const rowValue = cloneDeep(oldRowValue);
    rowValue.quantityOnHand = getStringFromObject('quantity', value, 0);
    rowValue.changeInQuantity = getChangeInQuantity(rowValue);
    rowValue.description = value;
    const expiryDateTimeInMillis = getStringFromObject('expiryDate', value);
    if (expiryDateTimeInMillis) {
        rowValue.expiryDate = getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis));
    }
    return rowValue;
};

const handleBatchSelectForAdjustInventory = (value, name, form) => {
    console.log('Params', value, form);
    if (name && isValidObject(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const index = getIndexFromFieldName(name);
        if (index != null && isValidFunction(setFieldValue)) {
            const rowValue = getStringFromObject(`values.products[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                setFieldValue(
                    `products.${index}`,
                    setValuesOnBatchSelectForAdjustInventory(rowValue, value),
                );
            }
        }
    }
};

export const ACTION_HANDLERS = {
    handleProductSelect,
    handleProductSelectForAdjustInventory,
    // rateChangeHandler,
    quantityChangeHandler,
    rowChangeHandler,
    deleteRowHandler,
    // handleTaxSelect,
    handleBatchSelect,
    handleBatchSelectForAdjustInventory,
};

export default ACTION_HANDLERS;
