import {
    FETCH_BILLED_PACKAGES_SUCCESS,
} from './billedMedicalPackage-actions';

const billedMedicalPackageReducer = (state = {}, action) => {
    let newState;
    switch (action.type) {
    case FETCH_BILLED_PACKAGES_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default billedMedicalPackageReducer;
