import ReactDOM from 'react-dom';
import { change } from 'redux-form';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    roundedValue, roundedValueFixedToTwoDigits,

} from '../../../constants/CommonUtil';
import { clearPatient } from '../../../redux/modules/selectedPatient/selectedPatient-actions';
import { clearPatients } from '../../../redux/modules/patientDetails/patientDetails-actions';
import { clearScannedData, clearVisits } from '../../../redux/modules/visitDetails/visitDetails-actions';
import { clearAppointmentDetails } from '../../../redux/modules/appointmentDetails/appointmentDetails-actions';
import { calculateAmountAndTotal } from './BillingSection/BillCalculationUtil';
import api from '../../../constants/api';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { sessionTimedOut } from '../../../redux/modules/login/login-actions';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { registrationBillingTableFields } from
    '../QuickRegistration/RegistrationBillingTable/RegistrationBillingTableUtil';
import {
    billingSectionFieldName,
    insuranceFields, insurancePayerFieldForPayerType,
    payerTypeFieldName,
    payerTypes,
    registrationPaymentModes,
    tokenDetailsFieldName,
} from '../QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


export const visitDetailsFormName = 'visitDetailsForm';

export const initialBillRowValues = {
    taxes: [],
    productDetails: '',
};

export const initialBillRowValuesDisabled = {
    ...initialBillRowValues,
    disabled: true,
};

export const focusAutoCompleteField = (autoCompleteRef) => {
    // eslint-disable-next-line react/no-find-dom-node
    const autoCompleteDOMRef = ReactDOM.findDOMNode(autoCompleteRef);
    if (autoCompleteDOMRef &&
        autoCompleteDOMRef.children &&
        autoCompleteDOMRef.children[0] &&
        autoCompleteDOMRef.children[0] &&
        autoCompleteDOMRef.children[0].children &&
        autoCompleteDOMRef.children[0].children[0] &&
        autoCompleteDOMRef.children[0].children[0].children &&
        autoCompleteDOMRef.children[0].children[0].children[0] &&
        autoCompleteDOMRef.children[0].children[0].children[0].focus &&
        typeof autoCompleteDOMRef.children[0].children[0].children[0].focus === 'function'
    ) {
        autoCompleteDOMRef.children[0].children[0].children[0].focus();
    }
};

export const getBillableProductObject = (billableProduct, patientExemptedFromTax) => {
    if (
        isObjectValidAndNotEmpty(
            billableProduct[registrationBillingTableFields.PRODUCT_NAME],
        )
    ) {
        const billableProductObject = {};
        console.log('09u0990909-i-0pokl;,sa.d', billableProduct);
        billableProductObject.id =
            getStringFromObject(`${registrationBillingTableFields.PRODUCT_NAME}.id`, billableProduct);
        billableProductObject.name =
            (
                getStringFromObject(`${registrationBillingTableFields.PRODUCT_NAME}.name`, billableProduct) ||
                getStringFromObject(`${registrationBillingTableFields.PRODUCT_NAME}.displayString`, billableProduct)
            );
        billableProductObject.quantity =
            getStringFromObject(registrationBillingTableFields.QUANTITY, billableProduct) || 0;
        billableProductObject.discount =
            getStringFromObject(registrationBillingTableFields.DISCOUNT_PERCENTAGE, billableProduct) || 0;
        billableProductObject.coPayPercent =
            getStringFromObject(registrationBillingTableFields.COPAY_PERCENTAGE, billableProduct) || 0;
        billableProductObject.coPayAmount =
            getStringFromObject(registrationBillingTableFields.COPAY_AMOUNT, billableProduct) || 0;

        // mainly used in pharmacy.. just sending whatever comes in right now
        billableProductObject.originalCopaypercent =
            getStringFromObject('copayAsPerScheme', billableProduct) || 0;


        // billableProductObject.insurancePercent =
        //     getStringFromObject(registrationBillingTableFields.COPAY_PERCENTAGE, billableProduct) || 0;
        billableProductObject.unitPrice =
            getStringFromObject(registrationBillingTableFields.PRICE, billableProduct) || 0;
        billableProductObject.uom = billableProduct.uom || 1;
        billableProductObject.code =
            getStringFromObject(registrationBillingTableFields.CODE, billableProduct) || '';
        billableProductObject.discountType =
            getStringFromObject(registrationBillingTableFields.APPLIED_DISCOUNT_TYPE, billableProduct) || '';

        // TODO change
        billableProductObject.orderable = billableProduct.orderable || false;
        // this field inidicates that that product is the billable item
        billableProductObject.billableProduct = billableProduct.billableProduct || false;

        if (isArrayValidAndNotEmpty(billableProduct.taxes)) {
            billableProductObject.taxIds = billableProduct.taxes.map(aTax => aTax.id);
            billableProductObject.taxes = billableProduct.taxes;
            billableProductObject.taxExempted = (!!patientExemptedFromTax && billableProduct.productExemptedFromTax);
        }
        billableProductObject.mrsOrderUuid = billableProduct.mrsOrderUuid;
        billableProductObject.description = billableProduct.description;
        billableProductObject.encounterUuid = billableProduct.encounterUuid;
        billableProductObject.saleOrderLineUuid = billableProduct.saleOrderLineUuid;
        billableProductObject.createOrder = billableProduct.createOrder || false;
        billableProductObject.uiUuid = billableProduct.uiUuid;
        billableProductObject.parentLine = billableProduct.parentLine;
        billableProductObject.packageUuid = billableProduct.packageUuid;
        billableProductObject.packageProduct = billableProduct.packageProduct;
        billableProductObject.removeLine = !!billableProduct.removeLine;
        return billableProductObject;
    }
    return null;
};

const mapBillableProductsWithSequence = (billableProducts) => {
    let sequence = 0;
    return billableProducts.map((aBillableProduct) => {
        let subProducts = [];
        sequence += 1;
        const mappedProduct = {
            ...aBillableProduct,
            sequence,
        };
        if (isArrayValidAndNotEmpty(aBillableProduct.subProducts)) {
            subProducts = aBillableProduct.subProducts.map((aSubProduct) => {
                sequence += 1;
                return {
                    ...aSubProduct,
                    sequence,
                };
            });
        }
        return {
            ...mappedProduct,
            subProducts,
        };
    });
};


export const getRegistrationPaymentDataForSubmission = (billProducts, patientExemptedFromTax) => {
    let billableProducts = [];
    let additionalAmountPaid = 0;
    let encounterUuid = '';
    let saleOrderId = null;
    console.log('asd0asd-id0-as-di0d', billProducts);
    const packageSubOrders = {};
    const parentPackage = {};
    // this variable is used in the scenario
    // where first line is blocked for op charge and the next line we bill a packag
    // while processing we ignore the first line, so the index gets messed up when processing package sub orders
    let noOfEmptyLines = 0;
    if (isArrayValidAndNotEmpty(billProducts)) {
        billProducts.map((aBillableProduct, index) => {
            const billableProductObject = getBillableProductObject(aBillableProduct, patientExemptedFromTax);
            // need to send additional insurance amount to be paid
            additionalAmountPaid += NumberOf(aBillableProduct.additionalAmountPaid);
            if (!billableProductObject) {
                noOfEmptyLines += 1;
            }
            if (billableProductObject) {
                // eslint-disable-next-line
                encounterUuid = aBillableProduct.encounterUuid;
                const actualIndexInList = (index - noOfEmptyLines);
                // eslint-disable-next-line
                saleOrderId = aBillableProduct.saleOrderId;
                billableProducts = billableProducts.concat(billableProductObject);
                const { uiUuid, parentLine } = aBillableProduct;
                if (uiUuid) {
                    parentPackage[uiUuid] = actualIndexInList;
                }
                if (parentLine) {
                    if (!packageSubOrders[parentLine]) {
                        packageSubOrders[parentLine] = [];
                    }
                    packageSubOrders[parentLine] = packageSubOrders[parentLine].concat(actualIndexInList);
                }
            }
            return null;
        });
    }
    // reprocess to deal with package sub orders
    console.log('a-d0ajopsdadklandal', packageSubOrders, parentPackage);
    let finalProductsList = [...billableProducts];
    if (isObjectValidAndNotEmpty(packageSubOrders)) {
        Object.keys(packageSubOrders).forEach((aPackageSubOrder) => {
            const indexOfPackage = parentPackage[aPackageSubOrder];
            const indexOfSubLines = packageSubOrders[aPackageSubOrder];
            if (isArrayValidAndNotEmpty(indexOfSubLines)) {
                indexOfSubLines.forEach((indexOfSubLine) => {
                    const packageLine = getStringFromObject(`[${indexOfPackage}]`, finalProductsList);
                    const subOrderLine = getStringFromObject(`[${indexOfSubLine}]`, finalProductsList);
                    if (isObjectValidAndNotEmpty(packageLine)) {
                        if (!packageLine.subProducts) {
                            packageLine.subProducts = [];
                        }
                        packageLine.subProducts = packageLine.subProducts.concat(subOrderLine);
                    }
                });
            }
        });
        const packageSubOrdersIndices = Object.values(packageSubOrders);
        let indicesToDelete = [];
        packageSubOrdersIndices.forEach((aPackSubOrderIndices) => {
            if (isArrayValidAndNotEmpty(aPackSubOrderIndices)) {
                aPackSubOrderIndices.sort((a, b) => b - a);
                indicesToDelete = indicesToDelete.concat(aPackSubOrderIndices);
                // aPackSubOrderIndices.forEach((anIndex) => {
                //     finalProductsList.splice(anIndex, 1);
                // });
            }
        });
        if (isArrayValidAndNotEmpty(indicesToDelete)) {
            finalProductsList = finalProductsList.filter((aProd, index) => !indicesToDelete.includes(index));
        }
    }
    return {
        billableProducts: mapBillableProductsWithSequence(finalProductsList),
        additionalAmountPaid,
        encounterUuid,
        saleOrderId,
    };
};

export const addAdditionalDataToSubmissionData = (
    submissionData,
    registrationPaymentDataForSubmission,
    billTotal,
    billValue,
    calculatedBillSummaryDetails,
    otherDiscounts,
    billingMultiplePayment,
) => {
    const consolidatedSubmissionData = { ...submissionData };
    if (!consolidatedSubmissionData.visit) {
        consolidatedSubmissionData.visit = {};
    }
    consolidatedSubmissionData.visit.registrationPayment = {
        ...billValue,
        paymentMode: billValue.paymentMode || null,
        products: registrationPaymentDataForSubmission.billableProducts,
        additionalAmountPaid: registrationPaymentDataForSubmission.additionalAmountPaid,
        encounterUuid: registrationPaymentDataForSubmission.encounterUuid,
        total: NumberOf(roundedValueFixedToTwoDigits(billTotal)),
        saleOrderId: registrationPaymentDataForSubmission.saleOrderId,
    };
    // const grandTotal =
    //     (
    //         NumberOf(calculatedBillSummaryDetails.patientAmount) +
    //         (
    //             NumberOf(calculatedBillSummaryDetails.vatAmount) -
    //             NumberOf(calculatedBillSummaryDetails.exemptTaxAmount)
    //         ) +
    //         NumberOf(calculatedBillSummaryDetails.roundOffAmount)
    //     ) -
    //     NumberOf(otherDiscounts);
    const theNetAmount = (
        (calculatedBillSummaryDetails.netAmount) +
        NumberOf(otherDiscounts) +
        NumberOf(calculatedBillSummaryDetails.roundOffAmount)
    );
    const grandTotal = theNetAmount - NumberOf(otherDiscounts);
    const billValueDto = {
        amountDue: 0,
        cashReceived: billValue.cashReceived,
        amountPaidByOtherMode: billValue.amountPaidByOtherMode,
        otherPaymentMode: billValue.otherPaymentMode,
        amountPaidByCard: billValue.amountPaidByCard,
        changeToRender: 0,
        discountAmount: 0,
        discountHead: 0,
        discountInPercentage: 0,
        modeOfPayment: 0,
        grandTotal:
            NumberOf(roundedValueFixedToTwoDigits(grandTotal)),
        // to minimize changes for shifa, the net amount included deductions from other discounts
        netAmount: theNetAmount,
    };
    billValueDto.multiplePayment = billingMultiplePayment || false;
    setStringPropertyToObject('visit.registrationPayment.billValue', consolidatedSubmissionData, billValueDto);
    console.log('sadsu9adsu9apas', billValue);
    if (getStringFromObject('paymentMode', billValue) === registrationPaymentModes.CARD) {
        const netAmount = getStringFromObject('billValue.netAmount', billValue);
        setStringPropertyToObject(
            'visit.registrationPayment.billValue.cashReceived',
            consolidatedSubmissionData,
            netAmount,
        );
    }
    return consolidatedSubmissionData;
};

export const valuesToCheckAndReset = {
    doctor: ['appointmentType'],
};

export const getBillingLineFromProduct = (product, isDisabled = true, payerType = payerTypes.CASH) => {
    console.log('asd-asud-a0sd-292992', payerType, product);
    const billingLine = {};
    billingLine.quantity = product.quantity;
    billingLine.productDetails = product.itemName;
    billingLine.product = { ...product, productName: product.itemName };
    billingLine.id = product.id;
    billingLine.uom = product.uom;
    billingLine[registrationBillingTableFields.TOTAL] = product.total;
    billingLine[registrationBillingTableFields.PRICE] = product.price;
    billingLine.taxes = product.tax;
    billingLine.taxName = product.tax;
    billingLine.disabled = isDisabled;
    billingLine[registrationBillingTableFields.COPAY_PERCENTAGE] = product.coPayPercent;
    billingLine[registrationBillingTableFields.CODE] = product.code;
    billingLine[registrationBillingTableFields.PRODUCT_NAME] = {
        id: product.id,
        displayString: product.itemName,
    };
    billingLine.uiUuid = uuidv4();

    // if cash patient, then copay is 100
    if (payerType === payerTypes.CASH) {
        billingLine[registrationBillingTableFields.COPAY_PERCENTAGE] = 100;
    }
    return {
        ...product,
        ...billingLine,
    };
};

export const createProductValidityMap = (previousPayments) => {
    const productValidityMap = {};
    if (isArrayValidAndNotEmpty(previousPayments)) {
        previousPayments.map((aPreviousPayment) => {
            const productsForPayment = aPreviousPayment.products;
            if (isArrayValidAndNotEmpty(productsForPayment)) {
                productsForPayment.map((aProduct) => {
                    if (!productValidityMap[aProduct.id]) {
                        productValidityMap[aProduct.id] = aProduct.active;
                    }
                    return null;
                });
            }
            return null;
        });
    }
    console.log('123sadsadas', productValidityMap);
    return productValidityMap;
};

export const preFillDataFromLastVisit = (lastVisitData, dataToBePreFilled, dispatch) => {
    console.log('2sdaduhbjdas', lastVisitData, dataToBePreFilled, dispatch, visitDetailsFormName);
    const { visitAttributes } = lastVisitData;
    if (lastVisitData && isArrayValidAndNotEmpty(visitAttributes)) {
        visitAttributes.map((aVisitAttribute) => {
            if (dataToBePreFilled.includes(aVisitAttribute.name)) {
                if (aVisitAttribute.value) {
                    dispatch(change(visitDetailsFormName, aVisitAttribute.name, aVisitAttribute.value));
                }
            }
            return null;
        });
    }
};

export const fillAppointmentDetails = (appointmentDetails, dispatch) => {
    // fill department, service, provider and appointment type
    console.log('asas8djoaisda9-', appointmentDetails);
    if (appointmentDetails) {
        dispatch(change(visitDetailsFormName, 'department', appointmentDetails.department || ''));
        dispatch(change(visitDetailsFormName, 'service', appointmentDetails.service || ''));
        dispatch(change(visitDetailsFormName, 'doctor', appointmentDetails.doctor || ''));
        dispatch(
            change(
                visitDetailsFormName,
                'appointmentType',
                appointmentDetails.appointmentType ? appointmentDetails.appointmentType.key : '',
            ),
        );
    }
};

export const getPatientDetailsForPrint = (patient) => {
    const patientPrintData = { ...patient };
    const personAttributes = getStringFromObject('personAttributes', patient, []);
    const personAttributesMap = {};
    personAttributes.map((anAttribute) => {
        personAttributesMap[anAttribute.name] = anAttribute.value;
        return null;
    });
    patientPrintData.personAttributes = personAttributesMap;
    return patientPrintData;
};

export const filterOutMrsOrderableProducts = products => (
    products.filter(aProduct => aProduct.orderable)
);

export const areThereAnyMrsOrderableProducts = (products) => {
    if (isArrayValidAndNotEmpty(products)) {
        const mrsOrderableProducts = filterOutMrsOrderableProducts(products);
        return isArrayValidAndNotEmpty(mrsOrderableProducts);
    }
    return false;
};

export const clearStates = (dispatcher) => {
    dispatcher(clearPatient());
    dispatcher(clearPatients());
    dispatcher(clearVisits());
    dispatcher(clearAppointmentDetails());
    dispatcher(clearScannedData());
};

export const addCommonDataToFormData = (formData) => {
    // data like the department, tokentype, service, appointment details can be filled here
    const formDataWithExtraValues = { ...formData };
    formDataWithExtraValues.tokenType = 'Normal';
    // const department = getUrlQueryParameter(this.props.location, 'department');
    // if (department) {
    //     formDataWithExtraValues.department = department;
    // }
    // const service = getUrlQueryParameter(this.props.location, 'service');
    // if (service) {
    //     formDataWithExtraValues.service = service;
    // }
    // const doctor = getUrlQueryParameter(this.props.location, 'doctor');
    // if (doctor) {
    //     formDataWithExtraValues.doctor = doctor;
    // }
    // const appointmentType = getUrlQueryParameter(this.props.location, 'appointmentType');
    // if (appointmentType) {
    //     formDataWithExtraValues.appointmentType = appointmentType;
    // }
    return formDataWithExtraValues;
};

export const updateAmountAndTotal = (billingValues, oldBillingValues = [], dispatcher) => {
    billingValues.map((aBillingRow, index) => {
        if (oldBillingValues[index] &&
            (((oldBillingValues[index].quantity !== aBillingRow.quantity) ||
                (oldBillingValues[index].price !== aBillingRow.price)) ||
                (oldBillingValues[index].discount !== aBillingRow.discount))
        ) { // check whether the quantity or price changed, only then must the amount and total be recalculated
            const amountAndTotal = calculateAmountAndTotal(aBillingRow);
            dispatcher(change(
                visitDetailsFormName,
                `${billingSectionFieldName}[${index}].amount`,
                roundedValue(amountAndTotal.amount),
            ));
            dispatcher(change(
                visitDetailsFormName,
                `${billingSectionFieldName}[${index}].total`,
                roundedValue(amountAndTotal.total),
            ));
        }
        return null;
    });
};

export const nationalityPersonAttribute = 'nationality';

export const taxExemptablePersonAttributes = [nationalityPersonAttribute];

export const findPersonAttributeWithName = (personAttributes, name) => {
    if (isArrayValidAndNotEmpty(personAttributes)) {
        for (let i = 0; i < personAttributes.length; i += 1) {
            const thePersonAttribute = personAttributes[i];
            if (thePersonAttribute.name === name) {
                return thePersonAttribute.value;
            }
        }
    }
    return null;
};

export const findCommaSeparatedPersonAttributesWithNames = (personAttributes, names = []) => {
    if (isArrayValidAndNotEmpty(personAttributes)) {
        let personAttributeValues = [];
        for (let i = 0; i < personAttributes.length; i += 1) {
            const thePersonAttribute = personAttributes[i];
            if (names.includes(thePersonAttribute.name)) {
                personAttributeValues = personAttributeValues.concat(thePersonAttribute.value);
            }
        }
        return personAttributeValues.join(',');
    }
    return null;
};

// conditions to check if the product should be refetched to take into consideration
// the nationality etc, for tax exemption and stuff
export const shouldProductsBeReFetched = (newProps, oldProps) => {
    const newPatientAttributes = getStringFromObject('patientDetails.personAttributes', newProps);
    const oldPatientAttributes = getStringFromObject('patientDetails.personAttributes', oldProps);

    const oldTaxExemptableAttributeValues =
        findCommaSeparatedPersonAttributesWithNames(oldPatientAttributes, taxExemptablePersonAttributes);
    const newTaxExemptableAttributeValues =
        findCommaSeparatedPersonAttributesWithNames(newPatientAttributes, taxExemptablePersonAttributes);
    return oldTaxExemptableAttributeValues !== newTaxExemptableAttributeValues;
};

export const reFetchProducts = (products, personUuid, dispatcher, changeFunc) => {
    console.log('1-203i10-kasd;admadadjpoj0i-0i123', products);
    let refetchApi = `${api.REFETCH_PRODUCTS_WITH_IDS}${personUuid}`;
    if (isArrayValidAndNotEmpty(products)) {
        let shouldMakeApiCall = false;
        const productIdToIndexMap = {};

        products.map((aProduct, index) => {
            if (aProduct.id) {
                shouldMakeApiCall = true;
                productIdToIndexMap[aProduct.id] = index;
                refetchApi = `${refetchApi}&productIds=${aProduct.id}`;
            }
            return null;
        });
        if (shouldMakeApiCall) {
            dispatcher(showSpinner());
            axios.get(refetchApi)
                .then((response) => {
                    const refetchedProducts = response.data;
                    console.log('990010-2-103-123-102o3-[10pko3;l', refetchedProducts, changeFunc);
                    if (isArrayValidAndNotEmpty(refetchedProducts)) {
                        refetchedProducts.map((aRefetchedProduct) => {
                            const indexOfProductInOriginalArray =
                                productIdToIndexMap[aRefetchedProduct.id];
                            const newBillProductValue =
                                getBillingLineFromProduct(
                                    aRefetchedProduct.levels,
                                    indexOfProductInOriginalArray === 0,
                                );
                            changeFunc(
                                `${billingSectionFieldName}[${indexOfProductInOriginalArray}]`,
                                newBillProductValue,
                            );
                            return null;
                        });
                    }
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, dispatcher);
                });
        }
    }
};

export const getPayerOrSchemeUuid = (nextProps) => {
    let payerOrSchemeUuid = '';
    const nextPayerType = getStringFromObject(`formValues.${payerTypeFieldName}`, nextProps);
    if (nextPayerType === payerTypes.INSURANCE) {
        payerOrSchemeUuid = getStringFromObject(`formValues.${insuranceFields.INSURANCE_SCHEME}.key`, nextProps);
    } else if ([payerTypes.COMPANY, payerTypes.CORPORATE, payerTypes.ASSOCIATION].includes(nextPayerType)) {
        payerOrSchemeUuid =
            getStringFromObject(`formValues.${insurancePayerFieldForPayerType[nextPayerType]}.key`, nextProps);
    }
    return payerOrSchemeUuid;
};

export const preConditionsForBillableProductFetchMet = (nextProps) => {
    const nextPayerType = getStringFromObject('formValues.payerType', nextProps);
    if (nextPayerType === payerTypes.COMPANY) {
        console.log('asd0has0das0d9', nextPayerType, nextProps.formValues);
        return getStringFromObject('formValues.companyName.key', nextProps);
    } else if (nextPayerType === payerTypes.CORPORATE) {
        console.log('asd0has0das0d9', nextPayerType, nextProps.formValues);
        return getStringFromObject('formValues.corporateName.key', nextProps);
    } else if (nextPayerType === payerTypes.ASSOCIATION) {
        console.log('asd0has0das0d9', nextPayerType, nextProps.formValues);
        return getStringFromObject('formValues.associationName.key', nextProps);
    } else if (nextPayerType === payerTypes.INSURANCE) {
        return getStringFromObject('formValues.insuranceScheme.key', nextProps);
    }
    return true;
};


export const fetchBillableProductForDoctor = (
    doctorUUid,
    payerType,
    payerOrSchemeUuid,
    serviceUuid,
    formName = visitDetailsFormName,
    _this,
    shouldFetchNewToken = true,
) => {
    if (doctorUUid && payerType) {
        _this.props.dispatch(showSpinner());
        const queryParams =
            // eslint-disable-next-line max-len
            `${doctorUUid}&patientUuid=${_this.props.patientUuid}&payerType=${payerType}&payerOrSchemeUuid=${payerOrSchemeUuid}`;

        // token generate api
        const tokenApi =
            `${api.GET_TOKEN_NUMBER_FOR_PATIENT}?providerUuid=${doctorUUid}&serviceUuid=${serviceUuid}`;
        axios.get(`${api.FETCH_PRODUCT_FOR_PROVIDER}${queryParams}`)
            .then((response) => {
                console.log('2324sadasd', response.data, _this.state.productValidityMap);
                // const productId = response.data.id;
                if (!response.data) {
                    _this.setState({
                        errorMessage: {
                            id: 'visitDetails.form.valid.payment.active',
                            defaultMessage: 'Product payment payment is still valid',
                        },
                    });
                } else {
                    const consultationBillableProduct = getBillingLineFromProduct(response.data, true, payerType);
                    _this.props.dispatch(change(
                        formName,
                        `${billingSectionFieldName}[0]`,
                        consultationBillableProduct,
                    ));
                    _this.setState({
                        errorMessage: null,
                    });
                }
                console.log('asdapsodaksdml;adad', _this.props.tokenDetails);
                if (shouldFetchNewToken) {
                    axios.get(tokenApi)
                        .then((response2) => {
                            console.log('asd-0i91230i12-03', response2.data);
                            _this.props.change(tokenDetailsFieldName, response2.data);
                            // todo remove set state.. we are storing things in the redux form store
                            _this.setState({
                                fetchedToken: response2.data,
                            });
                        });
                }
                _this.props.dispatch(hideSpinner());
            })
            .catch((e) => {
                if (e && e.response && e.response.status === 401) {
                    _this.props.dispatch(sessionTimedOut());
                } else {
                    _this.props.dispatch(errorMessage(
                        {
                            id: 'visitDetails.form.product.for.doctor.fetch.failure',
                            defaultMessage: 'Failed To Fetch The Product For The Doctor',
                        },
                    ));
                }
                _this.props.dispatch(hideSpinner());
            });
    }
};

export const onDoctorChange = (props, _this, formName = visitDetailsFormName, shouldFetchNewToken = true) => {
    if (props.formValues.doctor && props.formValues.doctor.key) {
        if (isObjectValidAndNotEmpty(props.schema)) {
            if (preConditionsForBillableProductFetchMet(props)) {
                const payerOrSchemeUuid = getPayerOrSchemeUuid(props);
                const doctor = getStringFromObject('formValues.doctor.key', props);
                const service = getStringFromObject('formValues.service.key', props);
                const payerType = getStringFromObject('formValues.payerType', props);
                fetchBillableProductForDoctor(
                    doctor,
                    payerType,
                    payerOrSchemeUuid,
                    service,
                    formName,
                    _this,
                    shouldFetchNewToken,
                );
            }
        }
        props.dispatch(change(formName, `${billingSectionFieldName}[0]`, initialBillRowValuesDisabled));
    } else {
        props.dispatch(change(formName, `${billingSectionFieldName}[0]`, initialBillRowValuesDisabled));
    }
};

export default initialBillRowValues;
