import initialStates from '../../initialStates';
import {
    CLEAR_FETCH_ENCOUNTER_ORDERS_REQUEST,
    FETCH_ENCOUNTER_ORDERS_FAILURE,
    FETCH_ENCOUNTER_ORDERS_SUCCESS,
} from './encounterOrders-actions';

const encounterOrdersReducer = (state = initialStates.encounterOrders, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ENCOUNTER_ORDERS_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
        };
        break;
    case FETCH_ENCOUNTER_ORDERS_FAILURE:
    case CLEAR_FETCH_ENCOUNTER_ORDERS_REQUEST:
        newState = { ...state };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default encounterOrdersReducer;
