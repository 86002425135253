/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import { ADVANCE_SEARCH_FAILURE, ADVANCE_SEARCH_REQUEST, ADVANCE_SEARCH_SUCCESS } from './advanceSearch-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

// const mergeData = (content) => {
//     let data = [];
//     if (isArrayValidAndNotEmpty(content)) {
//         content.map((c) => {
//             data = data.concat(c.data);
//             return null;
//         });
//     }
//     return data;
// };

const advanceSearchReducer = (state = initialStates.advanceSearch, action) => {
    let newState = {};
    // let listData = [];
    switch (action.type) {
    case ADVANCE_SEARCH_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case ADVANCE_SEARCH_SUCCESS:
        // listData = getStringFromObject('data.content', action, null) ||
        //     getStringFromObject('data.page.content', action, []);
        // if (getStringFromObject('data[0]', listData[0], null)) {
        //     listData = mergeData(listData);
        // }
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            category: action.category,
            filters: action.filters,
            tableJson: action.tableJson,
            dateFilters: action.dateFilters,
        });
        break;
    case ADVANCE_SEARCH_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default advanceSearchReducer;
