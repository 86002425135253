/* eslint-disable max-len */
import API from '../../../constants/api';

export const FETCH_ACCOUNT_INVOICE_REQUEST = '@@cashPaymentVoucher/FETCH_ACCOUNT_INVOICE_REQUEST';
export const FETCH_ACCOUNT_INVOICE_SUCCESS = '@@cashPaymentVoucher/FETCH_ACCOUNT_INVOICE_SUCCESS';
export const FETCH_ACCOUNT_INVOICE_FAILURE = '@@cashPaymentVoucher/FETCH_ACCOUNT_INVOICE_FAILURE';
export const CLEAR_ACCOUNT_INVOICE = '@@cashPaymentVoucher/CLEAR_ACCOUNT_INVOICE';

export const fetchAccountInvoices = (page, size, searchConstruct, seekParams, seekDirection) => ({
    type: FETCH_ACCOUNT_INVOICE_REQUEST,
    api: API.ACCOUNT_INVOICE.GET_PAGES,
    page,
    size,
    seekParams,
    seekDirection,
    searchConstruct,
});

export const clearAccountInvoices = () => ({
    type: CLEAR_ACCOUNT_INVOICE,
});

