import React, { Suspense } from 'react';
import { Route } from 'react-router';
import ServiceSettingContainer from '../containers/ServiceSettingContainer/ServiceSettingContainer';
import {
    analyticalDistributionService,
    billableProductService,
    clinicSettingService,
    generalSettingService,
    limitedServicesSettings,
    medicalPackages,
    packageListService,
    serviceCategory,
} from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const CategoryListPage = React.lazy(() => import('../pages/ServicePages/CategoryPage'));
const BillableProductsPage = React.lazy(() => import('../pages/ServicePages/BillableProductList'));
const PackageListpage = React.lazy(() => import('../pages/ServicePages/PackageListPage'));
const GeneralSettingPage = React.lazy(() => import('../pages/ServicePages/GeneralSettingPage'));
const ClinicSettingsPage = React.lazy(() => import('../pages/ServicePages/ClinicSettingsPage'));
const AnalyticalDistributionPage = React.lazy(() => import('../pages/ServicePages/AnalyticalDistributionPage'));
const LimitedServicesSettingContainer = React.lazy(() => import('../pages/LimitedServicesSettingPage/LimitedServicesSettingPage'));
const MedicalPackagesSettingsPage = React.lazy(() => import('../pages/MedicalPackagesSettingsPage/MedicalPackagesSettingsPage'));

const ServiceSettingRoutes = props => (
    <ServiceSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={serviceCategory} exact component={CategoryListPage} />
            <Route path={billableProductService} exact component={BillableProductsPage} />
            <Route path={packageListService} exact component={PackageListpage} />
            <Route path={generalSettingService} exact component={GeneralSettingPage} />
            <Route path={clinicSettingService} exact component={ClinicSettingsPage} />
            <Route path={analyticalDistributionService} exact component={AnalyticalDistributionPage} />
            <Route path={limitedServicesSettings} exact component={LimitedServicesSettingContainer} />
            <Route path={medicalPackages} exact component={MedicalPackagesSettingsPage} />
        </Suspense>
    </ServiceSettingContainer>
);

export default ServiceSettingRoutes;
