import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxFormChipFileUpload from '../../FormFieldComponents/ReduxFormChipFileUpload/ReduxFormChipFileUpload';

import { getStringFromObject } from '../../../constants/lodashUtils';


class FormikFormChipFileUpload extends React.PureComponent {
    onChange = (document) => {
        console.log('asda09sduas0dusd', document);
        const { field: { name }, form } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        setFieldValue(name, document);
    };
    render() {
        console.log('asdasduas-d0', this.props);
        return (
            <React.Fragment>
                {this.props.label}
                <ReduxFormChipFileUpload
                    {...this.props}
                    input={{
                        ...this.props.field,
                        onChange: this.onChange,
                    }}
                />
            </React.Fragment>
        );
    }
}

FormikFormChipFileUpload.propTypes = {
    disabled: PropTypes.bool,
    meta: PropTypes.object,
    form: PropTypes.object,
    field: PropTypes.object,
    uploadApi: PropTypes.string.isRequired,
    label: PropTypes.string,
    customFileLabel: PropTypes.string,
    className: PropTypes.string,
    downloadApi: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    labelLength: PropTypes.number,
    dispatch: PropTypes.func,
    supportedTypes: PropTypes.array,
};

FormikFormChipFileUpload.defaultProps = {
    disabled: true,
    customFileLabel: '',
    label: 'Document',
    className: '',
    meta: {},
    field: {},
    form: {},
    supportedTypes: [],
    dispatch: () => {},
    labelLength: 30,
};

export default connect()(FormikFormChipFileUpload);
