import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import PersonIcon from '@material-ui/icons/Person';
import LoyalityIcon from '@material-ui/icons/Loyalty';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { renderRequiredLabel } from '../../containers/EmployeeProfileContainer/EmployeeProfileContainerUtil';
import OutlinedTextField from '../OutlinedTextField';
import { getValidationFunction } from '../../constants/generateFormFields';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import { formatDateForDisplay } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

export const getIcon = (field) => {
    switch (field.icon) {
    case 'person':
        return PersonIcon;
    case 'loyalty':
        return LoyalityIcon;
    case 'card':
        return CreditCardIcon;
    case 'user':
        return SupervisedUserCircleIcon;
    case 'phone':
        return PhoneIcon;
    case 'video':
        return VideoLabelIcon;
    case 'email':
        return EmailIcon;
    case 'location':
        return LocationOnIcon;
    case 'department':
        return (
            <img
                src={`${import.meta.env.BASE_URL}/profile/department_PROFILE.svg`}
                alt=""
                style={{ paddingLeft: '0.75rem' }}
                className="basic-info-icon--small"
            />
        );
    case 'manager':
        return (
            <img
                src={`${import.meta.env.BASE_URL}/profile/manager.svg`}
                alt=""
                className="basic-info-icon--small"
            />
        );
    default:
        return null;
    }
};


const TextFieldWithIcon = ({
    isDisabled,
    field,
    formValues,
}) => {
    console.log('sss', formValues, field);
    const {
        validate,
        isMandatory,
    } = getValidationFunction(
        field.validations,
        !isDisabled,
        formValues,
        {},
    );
    const getFieldToRender = () => {
        switch (field.inputFieldType) {
        case 'reactSelect':
            return ReduxFormReactSelectMaterial;
        default:
            return OutlinedTextField;
        }
    };

    const getValueToDisplay = () => {
        let valueToDisplay = getStringFromObject((field.displayField || field.name), formValues);
        if (field.displayFormatter === 'date') {
            valueToDisplay = formatDateForDisplay(new Date(valueToDisplay));
        }
        return valueToDisplay;
    };

    const commonProps = {
        name: field.name,
        component: getFieldToRender(),
        validate,
        ...(field.inputProps || {}),
    };
    console.log('asdas9d90asd09dasd', field, isMandatory);
    return (
        <div className="basic-info-fields">
            {
                React.isValidElement(getIcon(field)) ?
                    getIcon(field) :
                    React.createElement(
                        getIcon(field),
                        { className: 'basic-info-icon--gray' },
                    )
            }
            <div className="basic-info-label">
                {
                    isMandatory ?
                        renderRequiredLabel(field.label, isDisabled) :
                        field.label
                }
            </div>
            {
                isDisabled ?
                    <div className="basic-info-text">
                        {getValueToDisplay()}
                    </div>
                    :
                    <Field
                        {...commonProps}
                    />
            }
        </div>
    );
};

TextFieldWithIcon.propTypes = {
    isDisabled: PropTypes.bool,
    formValues: PropTypes.object,
    field: PropTypes.object,
};

TextFieldWithIcon.defaultProps = {
    isDisabled: false,
    formValues: {},
    field: {},
};

export default TextFieldWithIcon;

