import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import { FETCH_BATCHES_LIST_FAILURE, FETCH_BATCHES_LIST_SUCCESS, CREATE_BATCH_SUCCESS,
    CREATE_BATCH_FAILURE } from '../../redux/modules/batches/batches-actions';
import { generateBatchesSearchConstruct } from './batchesSearchConstructUtil';


export function* fetchPagesOfBatchesWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const {
            api, page, size, sortCol,
        } = action;
        let { sortOrder } = action;
        if (sortOrder) {
            sortOrder = sortOrder.toUpperCase();
        }
        const apiWithParams = `${api}?page=${page}&size=${size}&sortOrder=${sortOrder}&sortCol=${sortCol}`;
        const payload = generateBatchesSearchConstruct(action.filters);
        const response = yield call(retriable().post, apiWithParams, payload, config);
        yield put({
            type: FETCH_BATCHES_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
            sortOrder,
            sortCol,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.BATCHES.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_BATCHES_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createBatchWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.batchDto);
        yield put({
            type: CREATE_BATCH_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.BATCHES.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.BATCHES.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_BATCH_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
