import { takeLatest } from 'redux-saga/effects';
import { createProductCategoryWorker, fetchPagesOfProductCategoryWorker } from './productCategoryWorkerSaga';
import {
    CREATE_PRODUCT_CATEGORY_REQUEST,
    FETCH_PRODUCT_CATEGORY_LIST_REQUEST,
} from '../../redux/modules/productCategory/productCategory-actions';

export function* fetchPagesOfProductCategoryWatcher() {
    yield takeLatest(FETCH_PRODUCT_CATEGORY_LIST_REQUEST, fetchPagesOfProductCategoryWorker);
}

export function* createProductCategoryWatcher() {
    yield takeLatest(CREATE_PRODUCT_CATEGORY_REQUEST, createProductCategoryWorker);
}
