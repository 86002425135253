/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_PRODUCTS_STATE,
    FETCH_PRODUCTS_LIST_FAILURE,
    FETCH_PRODUCTS_LIST_REQUEST,
    FETCH_PRODUCTS_LIST_SUCCESS,
} from './products-actions';

const productsReducer = (state = initialStates.products, action) => {
    console.log('ajksdhalksdfh', action);
    let newState = {};
    switch (action.type) {
    case FETCH_PRODUCTS_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_PRODUCTS_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
            totalElements: action.data.totalElements,
            filters: action.filters,
            lastUpdated: new Date(),
        });
        break;
    case FETCH_PRODUCTS_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case CLEAR_PRODUCTS_STATE:
        newState = initialStates.products;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default productsReducer;
