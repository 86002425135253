import {
    ADD_PATIENT_FAILURE,
    ADD_PATIENT_REQUEST, CHANGE_PATIENT_BLOCK_DETAILS,
    CHANGE_PATIENT_DETAILS_EDITABILITY, CLEAR_PATIENTS, FETCH_PATIENT_SUCCESS, UPDATE_PATIENT_FAILURE,
    UPDATE_PATIENT_REQUEST,
    UPDATE_PATIENT_SUCCESS,
} from './patientDetails-actions';
import initialState from '../../initialStates';

const patientsReducer = (state = initialState.patientDetails, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_PATIENT_SUCCESS:
        newState = { data: action.patientDetails, editable: false };
        break;
    case UPDATE_PATIENT_SUCCESS:
    case CHANGE_PATIENT_DETAILS_EDITABILITY:
        newState = { data: newState.data, editable: action.editable, loading: false };
        break;
    case CHANGE_PATIENT_BLOCK_DETAILS:
        newState = { ...state, blockDetails: action.blockDetails };
        break;
    case CLEAR_PATIENTS:
        newState = initialState.patientDetails;
        break;
    case ADD_PATIENT_REQUEST:
    case UPDATE_PATIENT_REQUEST:
        newState = { ...state, loading: true };
        break;
    case ADD_PATIENT_FAILURE:
    case UPDATE_PATIENT_FAILURE:
        newState = { ...state, loading: false };
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default patientsReducer;

