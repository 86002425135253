import initialStates from '../../initialStates';
import {
    FETCH_ANALYTICAL_ACCOUNTS_REQUEST,
    FETCH_ANALYTICAL_ACCOUNTS_SUCCESS,
    FETCH_ANALYTICAL_ACCOUNTS_FAILURE,
    CLEAR_ANALYTICAL_ACCOUNTS_STATE,
    CREATE_ANALYTICAL_ACCOUNTS_SUCCESS,
} from './analyticalAccounts-actions';

const analyticalAccountsReducer = (state = initialStates.analyticalAccounts, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ANALYTICAL_ACCOUNTS_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_ANALYTICAL_ACCOUNTS_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_ANALYTICAL_ACCOUNTS_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;

    case CLEAR_ANALYTICAL_ACCOUNTS_STATE:
        newState = initialStates.analyticalAccounts;
        break;
    case CREATE_ANALYTICAL_ACCOUNTS_SUCCESS:
        newState = {
            ...state,
            selectedAccount: action.data,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default analyticalAccountsReducer;
