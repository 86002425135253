import { takeLatest } from 'redux-saga/effects';
import {
    CANCEL_APPOINTMENT_REQUEST,
    CONFIRM_APPOINTMENT_REQUEST,
    CREATE_APPOINTMENT_REQUEST,
    FETCH_APPOINTMENT_SLOT_REQUEST,
} from '../../redux/modules/appointment/appointment-actions';
import {
    cancelAppointmentWorker,
    confirmAppointmentWorker,
    createAppointmentWorker,
    getAppointmentsSlotsWorker,
} from './appointmentWorkerSaga';

export function* getAppointmentSlotsWatcher() {
    yield takeLatest(FETCH_APPOINTMENT_SLOT_REQUEST, getAppointmentsSlotsWorker);
}

export function* createAppointmentWatcher() {
    yield takeLatest(CREATE_APPOINTMENT_REQUEST, createAppointmentWorker);
}

export function* cancelAppointmentWatcher() {
    yield takeLatest(CANCEL_APPOINTMENT_REQUEST, cancelAppointmentWorker);
}

export function* confirmAppointmentWatcher() {
    yield takeLatest(CONFIRM_APPOINTMENT_REQUEST, confirmAppointmentWorker);
}
