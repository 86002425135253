import axios from 'axios';

import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    sortArrayOfObjectsByFieldValue,
} from '../../constants/CommonUtil';
import { add, subtract } from '../../constants/PrecisionUtil';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const CASH_PAYMENT_VOUCHER_FORM_FIELDS = {
    PARTNER: 'partner',
    PARTNER_ACCOUNT: 'partnerAccount',
    PAYMENT_DATE: 'paymentDate',
    PAYMENT_METHOD: 'paymentMethod',
    CREDIT_FORM: 'creditFrom',
    BANK_NAME: 'bankName',
    REFERENCE_NUMBER: 'referenceNumber',
    DATE: 'date',
    REMARKS: 'remarks',
    TOTAL_ADVANCE_USED: 'totalAdvanceUsed',
    TOTAL_AMOUNT_TO_PAY: 'totalAmountToPay',
    DEBIT_LINES: 'debitLines',
    CREDIT_LINES: 'creditLines',
    STATE: 'state',
    NARRATION: 'narration',
    AMOUNT_TO_APPLY: 'amountToApply',
};

export const voucherStates = {
    DRAFT: 'draft',
    POSTED: 'posted',
};

export const voucherTypes = {
    PAYMENT: 'payment',
    RECEIVE: 'receive',
    PAYBACK: 'payback',
    RECEIPT: 'receipt',
};

export const initialValues = {
    [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_DATE]: new Date(),
    [CASH_PAYMENT_VOUCHER_FORM_FIELDS.STATE]: voucherStates.DRAFT,
};

export const getPayloadFrom = (formValues, isConfirmed) => {
    if (isObjectValidAndNotEmpty(formValues)) {
        let payload = {
            ...formValues,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD]:
                getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD}.value`, formValues) || null,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER]: {
                key: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.uuid`, formValues) || null,
                value: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.name`, formValues),
            },
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT]: {
                key: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.id`, formValues) || null,
                value: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.value`, formValues),
            },
            paymentModeDetailsDto: {
                bankName: formValues[CASH_PAYMENT_VOUCHER_FORM_FIELDS.BANK_NAME] || null,
                refNumber: formValues[CASH_PAYMENT_VOUCHER_FORM_FIELDS.REFERENCE_NUMBER] || null,
                date: formValues[CASH_PAYMENT_VOUCHER_FORM_FIELDS.DATE] || null,
                remarks: formValues[CASH_PAYMENT_VOUCHER_FORM_FIELDS.REMARKS] || null,
                chequeCollected: (getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD}.value`, formValues) === 'CHEQUE'
                    && getStringFromObject('voucherType', formValues) === voucherTypes.RECEIPT),
            },
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.STATE]: isConfirmed ? voucherStates.POSTED : voucherStates.DRAFT,
        };
        delete payload.bankName;
        delete payload.refNumber;
        delete payload.date;
        delete payload.date;

        if (isArrayValidAndNotEmpty(formValues.creditLines)) {
            const creditLines = formValues.creditLines.filter(aCreditLine => NumberOf(aCreditLine.allocated));
            payload = {
                ...payload,
                creditLines,
            };
        }
        if (isArrayValidAndNotEmpty(formValues.debitLines)) {
            const debitLines = formValues.debitLines.filter(aDebitLine => NumberOf(aDebitLine.allocated));
            payload = {
                ...payload,
                debitLines,
            };
        }
        return payload;
    }
    return null;
};

export const getSortedLinesByDate = lines => (
    sortArrayOfObjectsByFieldValue(isArrayValidAndNotEmpty(lines) ? [...lines] : [], 'date')
);

export const getFormValuesFrom = (cashPaymentVoucherDto) => {
    let formValues = {
        ...cashPaymentVoucherDto,
        [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PAYMENT_METHOD]: cashPaymentVoucherDto.paymentMethod ? {
            value: cashPaymentVoucherDto.paymentMethod,
            label: cashPaymentVoucherDto.paymentMethod,
        } : null,
        [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER]: {
            uuid: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.key`, cashPaymentVoucherDto) || null,
            name: getStringFromObject(`${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER}.value`, cashPaymentVoucherDto),
        },
        [CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT]: {
            id: getStringFromObject(
                `${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.key`,
                cashPaymentVoucherDto,
            ) || null,
            value: getStringFromObject(
                `${CASH_PAYMENT_VOUCHER_FORM_FIELDS.PARTNER_ACCOUNT}.value`,
                cashPaymentVoucherDto,
            ),
        },
        [CASH_PAYMENT_VOUCHER_FORM_FIELDS.AMOUNT_TO_APPLY]: NumberOf(cashPaymentVoucherDto.totalAmountToPay),
    };
    if (isObjectValidAndNotEmpty(cashPaymentVoucherDto.paymentModeDetailsDto)) {
        formValues = {
            ...formValues,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.BANK_NAME]: cashPaymentVoucherDto.paymentModeDetailsDto.bankName,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.REFERENCE_NUMBER]: cashPaymentVoucherDto.paymentModeDetailsDto.refNumber,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.DATE]: cashPaymentVoucherDto.paymentModeDetailsDto.date,
            [CASH_PAYMENT_VOUCHER_FORM_FIELDS.REMARKS]: cashPaymentVoucherDto.paymentModeDetailsDto.remarks,
        };
    }

    let totalCredit = 0;
    if (isArrayValidAndNotEmpty(cashPaymentVoucherDto.creditLines)) {
        const creditLinesSorted = getSortedLinesByDate(cashPaymentVoucherDto.creditLines);
        const creditLines = creditLinesSorted.map((aCreditLine) => {
            totalCredit = add(totalCredit, NumberOf(aCreditLine.allocated));
            return { ...aCreditLine, allocateFully: NumberOf(aCreditLine.allocated) === NumberOf(aCreditLine.residue) };
        });
        formValues = {
            ...formValues,
            creditLines,
        };
    }

    let totalDebit = 0;
    if (isArrayValidAndNotEmpty(cashPaymentVoucherDto.debitLines)) {
        const debitLinesSorted = getSortedLinesByDate(cashPaymentVoucherDto.debitLines);
        const debitLines = debitLinesSorted.map((aDebitLine) => {
            totalDebit = add(totalDebit, NumberOf(aDebitLine.allocated));
            return { ...aDebitLine, allocateFully: NumberOf(aDebitLine.allocated) === NumberOf(aDebitLine.residue) };
        });
        formValues = {
            ...formValues,
            debitLines,
        };
    }
    if (cashPaymentVoucherDto.voucherType === voucherTypes.PAYMENT) {
        formValues.totalAdvanceUsed = totalDebit;
    }
    if (cashPaymentVoucherDto.voucherType === voucherTypes.RECEIPT) {
        formValues.totalAdvanceUsed = totalCredit;
    }

    delete formValues.paymentModeDetailsDto;
    return formValues;
};

export const fetchResPartnerDto = async (partner, dispatcher) => {
    if (isObjectValidAndNotEmpty(partner) && partner.uuid) {
        try {
            dispatcher(showSpinner());
            const response = await axios.get(`${API.SUPPLIER.FIND}${partner.uuid}`);
            dispatcher(hideSpinner());
            return response.data;
        } catch (e) {
            apiCatchBlockFunction(e, dispatcher);
            return null;
        }
    }
    return null;
};

export const getNewPartnerIfValuesAreMissing = async (partner, dispatcher) => {
    let newPartner = { ...partner };
    if (
        isObjectValidAndNotEmpty(partner) &&
        (partner.supplier || partner.supplier === false) &&
        !isStringNullOrUndefined(partner.subCompany) &&
        (isObjectValidAndNotEmpty(partner.accPayables) || isObjectValidAndNotEmpty(partner.accReceivables))
    ) {
        newPartner = { ...partner };
    } else {
        const resPartnerDto = await fetchResPartnerDto(partner, dispatcher);
        if (isObjectValidAndNotEmpty(resPartnerDto)) {
            newPartner = { ...resPartnerDto };
        }
    }
    return newPartner;
};

export const getSubCompanyPartnerAccountAndPayable = (partner) => {
    const newSubCompany = partner.subCompany || '';
    const newIsPayable = Boolean(partner.supplier);
    let newPartnerAccount = null;
    if (newIsPayable && isObjectValidAndNotEmpty(partner.accPayables)) {
        newPartnerAccount = {
            id: partner.accPayables.key,
            value: partner.accPayables.value,
        };
    } else if (isObjectValidAndNotEmpty(partner.accReceivables)) {
        newPartnerAccount = {
            id: partner.accReceivables.key,
            value: partner.accReceivables.value,
        };
    }
    return {
        subCompany: newSubCompany,
        partnerAccount: newPartnerAccount,
        isPayable: newIsPayable,
    };
};

const getTotalAllocatedForLines = lines => (
    lines.reduce((previousValue, currentValue) => add(previousValue, NumberOf(currentValue.allocated)), 0)
);

const getTotalResidueForLines = lines => (
    lines.reduce((previousValue, currentValue) => add(previousValue, NumberOf(currentValue.residue)), 0)
);

export const getUpdatedPayableAndReceivableLines = (
    payableLines, receivableLines, totalAmountToPay, usePreviouslyAllocated,
) => {
    let totalReceivable = 0;
    let newReceivableLines = [];
    let newPayableLines = [];
    // For payable account receivable lines are debit line
    // For receivable account receivable lines are credit line
    let remainingPayable = getTotalResidueForLines(payableLines);
    if (isArrayValidAndNotEmpty(receivableLines)) {
        newReceivableLines = receivableLines.map((aReceivableLine) => {
            const residue = NumberOf(aReceivableLine.residue);
            let allocated;
            // If total amount is sent don't change payable lines
            if (usePreviouslyAllocated) {
                allocated = NumberOf(aReceivableLine.allocated);
            } else if (remainingPayable) {
                allocated = remainingPayable < residue ? remainingPayable : residue;
                remainingPayable = subtract(remainingPayable, allocated);
            } else {
                allocated = 0;
            }
            totalReceivable = add(totalReceivable, allocated);
            return { ...aReceivableLine, allocated, allocateFully: allocated === residue };
        });
    }
    let remainingReceivable = add(totalReceivable, totalAmountToPay);
    if (isArrayValidAndNotEmpty(payableLines)) {
        newPayableLines = payableLines.map((aPayableLine) => {
            if (!remainingReceivable) {
                return { ...aPayableLine, allocated: 0, allocateFully: false };
            }
            const residue = NumberOf(aPayableLine.residue);
            const allocated = remainingReceivable < residue ? remainingReceivable : residue;
            remainingReceivable = subtract(remainingReceivable, allocated);
            return { ...aPayableLine, allocated, allocateFully: allocated === residue };
        });
    }
    console.log('remaining-amount', { totalPayable: totalReceivable, remainingAmount: remainingReceivable });
    return {
        newReceivableLines,
        newPayableLines,
        totalReceivable,
    };
};

export const getTotalPayableAndReceivable = (formValues, isPayable) => {
    const {
        creditLines,
        debitLines,
        totalAmountToPay,
    } = formValues;

    let payableTotal;
    let receivableTotal;
    if (isPayable) {
        payableTotal = getTotalAllocatedForLines(creditLines);
        receivableTotal = add(getTotalAllocatedForLines(debitLines), totalAmountToPay);
    } else {
        payableTotal = getTotalAllocatedForLines(debitLines);
        receivableTotal = add(getTotalAllocatedForLines(creditLines), totalAmountToPay);
    }
    return { payableTotal, receivableTotal };
};
