/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    FETCH_APPOINTMENT_TYPES_SUCCESS,
    FETCH_PROVIDER_WEEK_SUCCESS,
    FETCH_SERVICE_WEEK_SUCCESS,
    RESET_PROVIDER_WEEK_STATE,
    RESET_SERVICE_WEEK_STATE,
} from './appointmentSlot-actions';

const appointmentSlotReducer = (state = initialStates.appointmentSlot, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_APPOINTMENT_TYPES_SUCCESS:
        newState = Object.assign({}, state, { appointmentTypes: action.data });
        break;
    case FETCH_SERVICE_WEEK_SUCCESS:
        newState = Object.assign({}, state, { serviceWeek: action.data });
        break;
    case FETCH_PROVIDER_WEEK_SUCCESS:
        newState = Object.assign({}, state, {
            providerWeek: { ...state.providerWeek, [action.providerUuid]: action.data },
        });
        break;
    case RESET_SERVICE_WEEK_STATE:
        newState = Object.assign({}, state, { serviceWeek: {} });
        break;
    case RESET_PROVIDER_WEEK_STATE:
        newState = Object.assign({}, state, { providerWeek: {} });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default appointmentSlotReducer;
