import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip/Chip';

const getColorFromAction = (action) => {
    const actionInCapital = action && action.toUpperCase();
    let color = '';
    let background = '';
    switch (actionInCapital) {
    case 'DONE':
    case 'SUBMIT':
    case 'APPROVED':
        color = 'white';
        background = '#6CB911';
        break;
    case 'REJECTED':
        color = 'white';
        background = '#D32F2F';
        break;
    default:
        color = 'white';
        background = '#6CB911';
    }

    return { color, background };
};

const ReduxFormChipWithLabel = (props) => {
    const {
        input, labelText, actionColor, ...otherProps
    } = props;
    let actionStyle = {};
    if (actionColor) {
        actionStyle = getColorFromAction(input.value);
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ ...otherProps.labelStyle, color: actionStyle.background }} >{labelText}</div>
            <Chip
                key={`${input.name}`}
                label={input.value}
                style={{ ...otherProps.style, ...actionStyle }}
            />
        </div>
    );
};

ReduxFormChipWithLabel.propTypes = {
    input: PropTypes.object,
    labelText: PropTypes.string,
    actionColor: PropTypes.bool,
};

ReduxFormChipWithLabel.defaultProps = {
    input: {},
    labelText: '',
    actionColor: false,
};

export default ReduxFormChipWithLabel;
