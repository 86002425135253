import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { drugClassSettings, drugRouteSettings, frequencySettings, genericsSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';


const genericsSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/GenericsSettingsPage'));
const drugRoutesSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/DrugRoutesSettingsPage'));
const frequencySettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/FrequencySettingsPage'));
const drugClassSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/DrugClassSettingsPage'));

const MedicationSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={genericsSettings} exact component={genericsSettingsPage} />
            <Route path={frequencySettings} exact component={frequencySettingsPage} />
            <Route path={drugClassSettings} exact component={drugClassSettingsPage} />
            <Route path={drugRouteSettings} exact component={drugRoutesSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default MedicationSettingRoutes;
