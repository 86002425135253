import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_STOCK_INTENT_FAILURE,
    CREATE_STOCK_INTENT_SUCCESS,
    FETCH_STOCK_INTENT_SUCCESS,
    FETCH_STOCK_INTENT_FAILURE,
} from '../../redux/modules/stockIntent/stockIntent-actions';
import { STOCK_INTENT_OPERATION } from '../../mapper/StockIntentMapper';

import { FETCH_STOCK_MOVE_SUCCESS } from '../../redux/modules/stockMove/stockMove-actions';

export function* createStockIntentWorker(action) {
    console.log('createStockIntentWorker', action);
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_STOCK_INTENT_SUCCESS,
            data: response.data,
        });
        yield put({
            type: FETCH_STOCK_MOVE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        console.log('payloadoperation', action.payload.state);
        if (action.payload.state === STOCK_INTENT_OPERATION.SAVE) {
            const msg = action.payload.uuid ? messages.STOCK_INTENT.UPDATE.SUCCESS :
                messages.STOCK_INTENT.CREATE.SUCCESS;
            yield put({ type: SUCCESS_MESSAGE, message: msg });
        } else {
            yield put({ type: SUCCESS_MESSAGE, message: messages.STOCK_INTENT.CONFIRM.SUCCESS });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = '';
            console.log('payloadoperation', action.payload.operation);
            if (action.payload.status === STOCK_INTENT_OPERATION.SAVE) {
                msg = action.payload.uuid ? messages.STOCK_INTENT.UPDATE.FAILED :
                    messages.STOCK_INTENT.CREATE.FAILED;
            } else {
                msg = messages.STOCK_INTENT.CONFIRM.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_STOCK_INTENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
export function* fetchStockIntent(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_STOCK_INTENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.STOCK_INTENT.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_STOCK_INTENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* markIntent(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api);
        yield put({
            type: FETCH_STOCK_INTENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.STOCK_INTENT.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_STOCK_INTENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

