import { FETCH_APP_CONFIG_SUCCESS, APP_SWITCHED } from './appConfiguration-actions';

const appConfigurationReducer = (state = {}, action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case FETCH_APP_CONFIG_SUCCESS:
        newState = action.appConfiguration;
        break;
    case APP_SWITCHED:
        newState = Object.assign({}, state, { title: action.title });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default appConfigurationReducer;
