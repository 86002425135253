import { takeLatest } from 'redux-saga/effects';
import {
    fetchCalendarWorkHoursWorkerSaga,
    profileFetchOtherUser,
    profileFetchRequest,
    profileUpdateRequest,
} from './profileWorkerSaga';
import {
    FETCH_CURRENT_USER_PROFILE_REQUEST,
    UPDATE_PROFILE_REQUEST,
    FETCH_OTHER_USER_DATA_REQUEST, FETCH_CALENDAR_WORK_HOURS_REQUEST,
} from '../../redux/modules/profile/profile-actions';


export function* profileFetchSaga() {
    yield takeLatest(FETCH_CURRENT_USER_PROFILE_REQUEST, profileFetchRequest);
}

export function* profileUpdateSaga() {
    yield takeLatest(UPDATE_PROFILE_REQUEST, profileUpdateRequest);
}

export function* profileFetchOtherUserSaga() {
    yield takeLatest(FETCH_OTHER_USER_DATA_REQUEST, profileFetchOtherUser);
}

export function* fetchCalendarWorkHoursSaga() {
    yield takeLatest(FETCH_CALENDAR_WORK_HOURS_REQUEST, fetchCalendarWorkHoursWorkerSaga);
}
