/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    FETCH_TRANSACTIONS_LIST_FAILURE,
    FETCH_TRANSACTIONS_LIST_REQUEST,
    FETCH_TRANSACTIONS_LIST_SUCCESS,
} from './transaction-actions';

const transactionReducer = (state = initialStates.transaction, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_TRANSACTIONS_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_TRANSACTIONS_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_TRANSACTIONS_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default transactionReducer;
