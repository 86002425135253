/**
 * Created by sachinprakash on 12/8/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { FETCH_POLICY_VIEW_LIST_REQUEST } from '../../redux/modules/policyView/policyView-actions';
import { commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export default function* policyViewWatcher() {
    yield takeLatest(FETCH_POLICY_VIEW_LIST_REQUEST, commonPostWorkerSaga);
}
