import cloneDeep from 'clone-deep';
import uuidv4 from 'uuid/v4';
import sumby from 'lodash.sumby';
import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    roundedValue,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const EXPENSE_LINE_UI_OBJECT = {
    expenseLineUuid: '',
    uiUuid: uuidv4(),
    category: null,
    costCenter: null,
    description: '',
    amount: '',
    taxes: [],
    taxAmount: 0.00,
    total: 0.00,
    type: '',
};

export const getSubTotal = (values) => {
    const lines = getStringFromObject('expenseLines', values, []);
    const subTotal = NumberOf(sumby(lines, line => NumberOf(line.total)));
    let discountAmount = NumberOf(getStringFromObject('discountAmount', values));
    const discountPer = NumberOf(getStringFromObject('discountPer', values));
    if (discountPer) {
        discountAmount = roundedValue(subTotal * (discountPer / 100));
    }
    const total = Math.max(0, subTotal - discountAmount);
    return {
        subTotal: NumberOf(valueToFixedTwoDigits(subTotal)),
        discountAmount: NumberOf(valueToFixedTwoDigits(discountAmount)),
        total: NumberOf(valueToFixedTwoDigits(total)),
    };
};

const calculateTaxAndTotal = (line) => {
    const amount = NumberOf(getStringFromObject('amount', line));
    const taxes = getStringFromObject('taxes', line, []);
    let tax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((t) => {
            tax += NumberOf(t.amount);
        });
    }
    const taxAmount = roundedValue(amount * tax);
    const total = roundedValue(amount + taxAmount);
    return ({
        ...line,
        taxAmount: valueToFixedTwoDigits(taxAmount),
        total: valueToFixedTwoDigits(total),
        amount: valueToFixedTwoDigits(amount),
    });
};

export const mapUiLinesFromDtoLines = (lines) => {
    console.log('kashdflkasdh', lines);
    if (isArrayValidAndNotEmpty(lines)) {
        return lines.map((line) => {
            const aLine = ({
                expenseLineUuid: getStringFromObject('expenseLineUuid', line),
                category: {
                    key: getStringFromObject('category.key', line, ''),
                    value: getStringFromObject('category.value', line, ''),
                },
                costCenter: {
                    key: getStringFromObject('costCenter.key', line, ''),
                    value: getStringFromObject('costCenter.value', line, ''),
                },
                description: getStringFromObject('description', line, null),
                amount: line.amount,
                taxes: getStringFromObject('taxes', line, []),
                type: line.type,
            });
            return calculateTaxAndTotal(aLine);
        });
    }
    return [cloneDeep(EXPENSE_LINE_UI_OBJECT)];
};

export const mapExpenseToUiObject = (expense) => {
    console.log('kdajsdhflkashdf', expense);
    if (!isObjectValidAndNotEmpty(expense)) {
        return ({
            date: getDateInYYYYMMDDFormat(new Date()),
            editable: true,
            state: 'draft',
            type: 'PETTY',
            expenseLines: [cloneDeep(EXPENSE_LINE_UI_OBJECT)],
        });
    }
    return ({
        id: getStringFromObject('id', expense),
        name: getStringFromObject('name', expense),
        expenseUuid: getStringFromObject('expenseUuid', expense),
        employee: getStringFromObject('employee', expense) ? {
            key: getStringFromObject('employee.key', expense, ''),
            value: getStringFromObject('employee.value', expense, ''),
        } : null,
        date: getDateInYYYYMMDDFormat(new Date(getStringFromObject('date', expense, new Date()))),
        expenseLines: mapUiLinesFromDtoLines(expense.expenseLineDtos),
        printData: expense,
        state: getStringFromObject('state', expense),
        type: getStringFromObject('type', expense),
        totalAmount: expense.totalAmount,
        memo: expense.memo,
        discountAmount: NumberOf(valueToFixedTwoDigits(NumberOf(expense.discountAmount))),
        editable: false,
    });
};

export const mapExpenseLinesFromUiObject = (uiLines) => {
    const expenseLines = [];
    if (isArrayValidAndNotEmpty(uiLines)) {
        uiLines.forEach((line) => {
            if (line.category && line.category.key) {
                const oneLine = {
                    expenseLineUuid: getStringFromObject('expenseLineUuid', line),
                    category: getStringFromObject('category', line, null),
                    costCenter: getStringFromObject('costCenter', line, null),
                    description: getStringFromObject('description', line, null),
                    amount: getStringFromObject('amount', line, null),
                    taxes: Array.isArray(getStringFromObject('taxes', line, [])) ? getStringFromObject('taxes', line, []) : [],
                };
                expenseLines.push(oneLine);
            }
        });
    }
    return expenseLines;
};

export const mapUiObjectToExpense = (uiObject) => {
    console.log('kajsdhflaksjdfh', uiObject);
    const totalObj = getSubTotal(uiObject);
    return ({
        expenseUuid: getStringFromObject('expenseUuid', uiObject, null),
        employee: {
            key: getStringFromObject('employee.key', uiObject),
            value: getStringFromObject('employee.value', uiObject),
        },
        date: getDateInYYYYMMDDFormat(new Date(uiObject.date)),
        expenseLineDtos: mapExpenseLinesFromUiObject(getStringFromObject('expenseLines', uiObject, [])),
        totalAmount: totalObj.total,
        discountAmount: totalObj.discountAmount,
        type: 'PETTY',
        state: uiObject.state,
        memo: uiObject.memo,
    });
};

const updateAndSetLine = (line, index, formikBag) => {
    const setFieldValue = getStringFromObject('setFieldValue', formikBag);
    if (isValidFunction(setFieldValue)) {
        setFieldValue(`expenseLines.${index}`, calculateTaxAndTotal(line));
    }
};

const handleAmountChange = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.expenseLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.amount = NumberOf(value);
                updateAndSetLine(rowValue, index, form);
            }
        }
    }
};

const handleTaxSelect = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.expenseLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = value;
                updateAndSetLine(rowValue, index, form);
            }
        }
    }
};

const onAccountSelect = (value, fieldPath, form) => {
    if (isObjectValidAndNotEmpty(value) && isObjectValidAndNotEmpty(form)) {
        const { values, setFieldValue } = form;
        const len = NumberOf(getStringFromObject('expenseLines.length', values, 0));
        const index = NumberOf(getIndexFromFieldName(fieldPath));
        if (index === len - 1) {
            // Add row automatically..
            setFieldValue(`expenseLines.${len}`, cloneDeep(EXPENSE_LINE_UI_OBJECT));
        }
    }
};

export const HANDLERS = {
    handleAmountChange,
    onAccountSelect,
    handleTaxSelect,
};


export const style = theme => ({
    input: {
        paddingTop: '14px',
        paddingBottom: '14px',
        padding: '0 6px',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    paper: {
        padding: '15px',
        background: '#fafafa',
    },
    header: {
        fontSize: '1.5rem',
    },
    multiInput: {
        padding: '6px',
    },
    content: {
        padding: '0 25px 25px 25px',
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        minWidth: '15em',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
});
