import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import {
    clearStockMoveByPurchaseOrderState,
    fetchGoodReceiptsRequest,
} from '../../../redux/modules/stockMove/stockMove-actions';
import GoodReturnInvoiceProduct from './GoodReturnInvoiceProduct';
import MaterialTable from '../../MaterialTableV2/MaterialTable';
import { getStringFromObject } from '../../../constants/lodashUtils';

class GoodReturnListSelectPopUp extends React.Component {
    componentWillMount() {
        const {
            dispatch, partnerUuid, locationUuid, invoiced, purchaseOrderUuid,
        } = this.props;
        dispatch(fetchGoodReceiptsRequest(partnerUuid, locationUuid, invoiced, purchaseOrderUuid));
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
    }

    handleRowClick = (selected) => {
        if (isArrayValidAndNotEmpty(selected)) {
            const selectedRow = getStringFromObject(`data[${selected[0]}]`,
                this.props, []);
            this.props.onRowClick(selectedRow);
        }
    };

    renderExpandableRows = (rowIndex) => {
        console.log('rowIndexahak', rowIndex);
        return (
            <GoodReturnInvoiceProduct
                data={getStringFromObject(`data[${rowIndex}].stockPickingLinesDTOSet`, this.props)}
            />
        );
    };

    render() {
        const {
            open,
            handleClose,
            invoiceSchema,
            data,
        } = this.props;
        console.log('afjafjkalfa', data);
        return (
            <Dialog
                open={open}
                maxWidth="lg"
                PaperProps={{ style: { minWidth: '70rem', borderRadius: '0.6rem' } }}
            >
                <DialogTitle id="polist-dialog-title">
                    <Grid container justify="space-between">
                        <div>List of Stock Receipts Not Invoiced</div>
                        <Close className="cursor-pointer" onClick={handleClose} test-id="return-list-stock" />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <MaterialTable
                        schema={invoiceSchema}
                        uniqueKey="uuid"
                        noPagination
                        onSelect={this.handleRowClick}
                        data={data}
                        isExpandable
                        getExpandableComponent={this.renderExpandableRows}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

GoodReturnListSelectPopUp.propTypes = {
    data: PropTypes.object.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    partnerUuid: PropTypes.string.isRequired,
    locationUuid: PropTypes.string.isRequired,
    invoiced: PropTypes.bool,
    purchaseOrderUuid: PropTypes.string,
    invoiceSchema: PropTypes.object.isRequired,
};
GoodReturnListSelectPopUp.defaultProps = {
    open: false,
    invoiced: false,
    purchaseOrderUuid: null,
};
const mapStateToProps = state => ({
    data: getStringFromObject('stockMove.stockMovesByPurchaseOrder', state),
});

export default connect(mapStateToProps)(GoodReturnListSelectPopUp);
