export const CREATE_PAID_ON_ACCOUNT_SUCCESS = '@@paidOnAccount/CREATE_PAID_ON_ACCOUNT_SUCCESS';
export const REFUND_PAID_ON_ACCOUNT_SUCCESS = '@@paidOnAccount/REFUND_PAID_ON_ACCOUNT_SUCCESS';

export const FETCH_PAID_ON_ACCOUNT_SUCCESS = '@@paidOnAccount/FETCH_PAID_ON_ACCOUNT_SUCCESS';

export const CLEAR_PAID_ON_ACCOUNT_STATE = '@@paidOnAccount/CLEAR_PAID_ON_ACCOUNT_STATE';


export const clearSelectedAccountPayment = () => ({
    type: CLEAR_PAID_ON_ACCOUNT_STATE,
});
