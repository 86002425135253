/**
 * Created by vinay on 3/5/19.
 */
import API from '../../../constants/api';
import MESSAGES from '../../../constants/messages';
import { PREPAID_EXPENSE_STATUS } from '../../../components/BookManagementComponents/PrepaidExpenseUtil';

export const SAVE_PREPAID_EXPENSE_REQUEST = '@@prepaidExpense/SAVE_PREPAID_EXPENSE_REQUEST';
export const SAVE_PREPAID_EXPENSE_SUCCESS = '@@prepaidExpense/SAVE_PREPAID_EXPENSE_SUCCESS';
export const SAVE_PREPAID_EXPENSE_FAILURE = '@@prepaidExpense/SAVE_PREPAID_EXPENSE_FAILURE';

export const GENERATE_PERIODIC_LINE_REQUEST = '@@prepaidExpense/GENERATE_PERIODIC_LINE_REQUEST';
export const GENERATE_PERIODIC_LINE_SUCCESS = '@@prepaidExpense/GENERATE_PERIODIC_LINE_SUCCESS';
export const GENERATE_PERIODIC_LINE_FAILURE = '@@prepaidExpense/GENERATE_PERIODIC_LINE_FAILURE';

export const CREATE_JOURNAL_ENTRY_REQUEST = '@@prepaidExpense/CREATE_JOURNAL_ENTRY_REQUEST';
export const CREATE_JOURNAL_ENTRY_SUCCESS = '@@prepaidExpense/CREATE_JOURNAL_ENTRY_SUCCESS';
export const CREATE_JOURNAL_ENTRY_FAILURE = '@@prepaidExpense/CREATE_JOURNAL_ENTRY_FAILURE';

export const FETCH_PREPAID_EXPENSE_REQUEST = '@@prepaidExpense/FETCH_PREPAID_EXPENSE_REQUEST';
export const FETCH_PREPAID_EXPENSE_SUCCESS = '@@prepaidExpense/FETCH_PREPAID_EXPENSE_SUCCESS';
export const FETCH_PREPAID_EXPENSE_FAILURE = '@@prepaidExpense/FETCH_PREPAID_EXPENSE_FAILURE';

export const RESET_SELECTED_PREPAID_EXPENSE = '@@prepaidExpense/RESET_SELECTED_PREPAID_EXPENSE';

export const savePrepaidExpense = (payload, successCallback) => {
    const action = payload.status;
    let successMessage = '';
    let failureMessage = '';
    if (action === PREPAID_EXPENSE_STATUS.draft) {
        successMessage = payload.uuid
            ? MESSAGES.PREPAID_EXPENSE.UPDATE.SUCCESS : MESSAGES.PREPAID_EXPENSE.CREATE.SUCCESS;
        failureMessage = payload.uuid
            ? MESSAGES.PREPAID_EXPENSE.UPDATE.FAILURE : MESSAGES.PREPAID_EXPENSE.CREATE.FAILURE;
    } else if (action === PREPAID_EXPENSE_STATUS.confirmed) {
        successMessage = MESSAGES.PREPAID_EXPENSE.COFNIRM.SUCCESS;
        failureMessage = MESSAGES.PREPAID_EXPENSE.COFNIRM.FAILURE;
    }
    return ({
        type: SAVE_PREPAID_EXPENSE_REQUEST,
        api: API.PREPAID_EXPENSE.SAVE,
        payload,
        successAction: SAVE_PREPAID_EXPENSE_SUCCESS,
        successCallback,
        successMessage,
        failureAction: SAVE_PREPAID_EXPENSE_FAILURE,
        failureMessage,
    });
};

export const generatePeriodicLine = (uuid, successCallback) => ({
    type: GENERATE_PERIODIC_LINE_REQUEST,
    api: `${API.PREPAID_EXPENSE.GENERATE_PERIODIC_LINE}${uuid}`,
    successCallback,
    successAction: GENERATE_PERIODIC_LINE_SUCCESS,
    failureAction: GENERATE_PERIODIC_LINE_FAILURE,
    failureMessage: MESSAGES.PREPAID_EXPENSE.GENERATE_PERIODIC_LINE_FAIL,
});

export const fetchPrepaidExpenseById = (id, successCallback) => ({
    type: FETCH_PREPAID_EXPENSE_REQUEST,
    api: `${API.PREPAID_EXPENSE.BASE_URL}/one/${id}`,
    successCallback,
    successAction: FETCH_PREPAID_EXPENSE_SUCCESS,
    failureAction: FETCH_PREPAID_EXPENSE_FAILURE,
    failureMessage: MESSAGES.PREPAID_EXPENSE.FETCH_FAILED,
});

export const createJournalEntryForPeriodicLine = (uuid, successCallback) => ({
    type: CREATE_JOURNAL_ENTRY_REQUEST,
    api: `${API.PREPAID_EXPENSE.BASE_URL}/createMove/${uuid}`,
    successCallback,
    successAction: CREATE_JOURNAL_ENTRY_SUCCESS,
    successMessage: MESSAGES.PREPAID_EXPENSE.CREATE_JOURNAL_ENTRY.SUCCESS,
    failureAction: CREATE_JOURNAL_ENTRY_FAILURE,
    failureMessage: MESSAGES.PREPAID_EXPENSE.CREATE_JOURNAL_ENTRY.FAILURE,
});

export const clearSelectedExpenseState = () => ({ type: RESET_SELECTED_PREPAID_EXPENSE });
