import sumby from 'lodash.sumby';
import {
    isArrayValidAndNotEmpty,
    roundedValue, valueToFixedTwoDigits,
} from '../constants/CommonUtil';
import { formatDate } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';

export const calculateTaxAndTotal = (line) => {
    const amount = NumberOf(getStringFromObject('amount', line));
    const taxes = getStringFromObject('taxes', line, []);
    let tax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((t) => {
            tax += NumberOf(t.amount);
        });
    }
    const taxAmount = roundedValue(amount * tax);
    const total = roundedValue(amount + taxAmount);
    return {
        ...line,
        taxAmount: valueToFixedTwoDigits(taxAmount),
        total: valueToFixedTwoDigits(total),
        amount: valueToFixedTwoDigits(amount),
    };
};

export const getSubTotal = (values) => {
    const lines = getStringFromObject('expenseLines', values, []);
    const subTotal = NumberOf(sumby(lines, line => NumberOf(line.total)));
    let discountAmount = NumberOf(getStringFromObject('discountAmount', values));
    const discountPer = NumberOf(getStringFromObject('discountPer', values));
    if (discountPer) {
        discountAmount = roundedValue(subTotal * (discountPer / 100));
    }
    const total = Math.max(0, subTotal - discountAmount);
    return {
        subTotal: NumberOf(valueToFixedTwoDigits(subTotal)),
        discountAmount: NumberOf(valueToFixedTwoDigits(discountAmount)),
        total: NumberOf(valueToFixedTwoDigits(total)),
    };
};

const mapUiLinesFromDtoLines = (lines) => {
    if (isArrayValidAndNotEmpty(lines)) {
        return lines.map((line) => {
            const lineWithTax = calculateTaxAndTotal(line);
            return ({
                expenseLineUuid: getStringFromObject('expenseLineUuid', line),
                category: {
                    key: getStringFromObject('category.key', line, ''),
                    value: getStringFromObject('category.value', line, ''),
                },
                costCenter: {
                    key: getStringFromObject('costCenter.key', line, ''),
                    value: getStringFromObject('costCenter.value', line, ''),
                },
                description: getStringFromObject('description', line, null),
                vatNumber: getStringFromObject('vatNumber', line, null),
                amount: lineWithTax.amount,
                taxAmount: lineWithTax.taxAmount,
                taxes: lineWithTax.taxes,
                total: lineWithTax.total,
            });
        });
    }
    return [{}];
};

export const mapExpenseToUiObject = (expense = {}) => ({
    id: getStringFromObject('id', expense),
    name: getStringFromObject('name', expense),
    expenseUuid: getStringFromObject('expenseUuid', expense),
    isPeriodClosed: getStringFromObject('isPeriodClosed', expense, false),
    payee: {
        uuid: getStringFromObject('payee.key', expense),
        name: getStringFromObject('payee.value', expense),
    },
    assetAccount: getStringFromObject('assetAccount', expense, null),
    paymentMethod: getStringFromObject('paymentMethod', expense, null),
    paymentDate: formatDate(getStringFromObject('date', expense), 'yyyy-MM-dd'),
    referenceNumber: getStringFromObject('referenceNumber', expense, ''),
    memo: getStringFromObject('memo', expense, null),
    expenseLines: mapUiLinesFromDtoLines(getStringFromObject('expenseLineDtos', expense, [])),
    type: getStringFromObject('type', expense, 'EXPENSE'),
    paymentDetails: getStringFromObject('paymentDetails', expense, null),
    discountAmount: NumberOf(valueToFixedTwoDigits(NumberOf(getStringFromObject('discountAmount', expense)))),
    creatorName: getStringFromObject('creatorName', expense) || '',
    advanceEmployeeName: {
        key: getStringFromObject('employeeAdvanceDto.employee.key', expense),
        value: getStringFromObject('employeeAdvanceDto.employee.value', expense),
    },
    advanceEmployeeShare: getStringFromObject('employeeAdvanceDto.totalAmount', expense),
    advanceEmployeeRepayInstallment: getStringFromObject('employeeAdvanceDto.noOfRecoups', expense),
    employeeAdvanceUuid: getStringFromObject('employeeAdvanceDto.uuid', expense),
    haveEmployeeShare: false,
});

const mapExpenseLinesFromUiObject = (uiLines) => {
    if (isArrayValidAndNotEmpty(uiLines)) {
        return uiLines.map(line => ({
            expenseLineUuid: getStringFromObject('expenseLineUuid', line),
            category: getStringFromObject('category', line, null),
            costCenter: getStringFromObject('costCenter', line, null),
            vatNumber: getStringFromObject('vatNumber', line, null),
            description: getStringFromObject('description', line, null),
            amount: getStringFromObject('amount', line, null),
            taxes: getStringFromObject('taxes', line, []),
        }));
    }
    return [];
};

export const mapUiObjectToExpense = (uiObject) => {
    const paymentMethod = getStringFromObject('paymentMethod', uiObject, null);
    let paymentDetails = null;
    if (paymentMethod === 'CHEQUE') {
        paymentDetails = getStringFromObject('paymentDetails', uiObject, null);
    }
    const { discountAmount } = getSubTotal(uiObject);
    return ({
        employeeAdvanceDto: {
            employee: {
                key: getStringFromObject('advanceEmployeeName.key', uiObject, null),
                value: getStringFromObject('advanceEmployeeName.value', uiObject, null),
                subCompany: getStringFromObject('advanceEmployeeName.subCompany', uiObject, null),
            },
            totalAmount: getStringFromObject('advanceEmployeeShare', uiObject, null),
            noOfRecoups: getStringFromObject('advanceEmployeeRepayInstallment', uiObject, null),
            state: 'ISSUED',
            type: 'advance',
            date: getStringFromObject('paymentDate', uiObject, null),
            fromAccount: null,
            paymentMode: {
                key: getStringFromObject('paymentMethod', uiObject, null),
                value: getStringFromObject('paymentMethod', uiObject, null),
            },
            uuid: getStringFromObject('employeeAdvanceUuid', uiObject, null),
            isPartOfExpense: true,
        },
        haveEmployeeShare: getStringFromObject('haveEmployeeShare', uiObject, false),
        state: 'confirmed',
        type: 'EXPENSE',
        expenseUuid: getStringFromObject('expenseUuid', uiObject, null),
        payee: {
            key: getStringFromObject('payee.uuid', uiObject),
            value: getStringFromObject('payee.name', uiObject),
        },
        assetAccount: getStringFromObject('assetAccount', uiObject, null),
        date: getStringFromObject('paymentDate', uiObject, null),
        paymentMethod,
        paymentDetails,
        referenceNumber: getStringFromObject('referenceNumber', uiObject, null),
        memo: getStringFromObject('memo', uiObject, null),
        discountAmount,
        expenseLineDtos: mapExpenseLinesFromUiObject(getStringFromObject('expenseLines', uiObject, [])),
    });
};
