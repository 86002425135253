import initialStates from '../../initialStates';
import {
    CLEAR_BATCH_DETAILS,
    CLEAR_EMP_PAYSLIP_INPUT, CLEAR_SELECTED_PAYSLIP_STATE,
    FETCH_EMP_BY_DEPT_SUCCESS,
    FETCH_EMP_PAYSLIP_INPUT_SUCCESS,
    FETCH_PAYMENT_ADVICE_LIST_SUCCESS,
    FETCH_PAYMENT_ADVICE_REQUEST,
    FETCH_PAYMENT_ADVICE_SUCCESS,
    FETCH_PAYROLL_PAYSLIP_SUCCESS,
    FETCH_PAYROLL_PERIODS_SUCCESS,
    FETCH_PAYROLL_REPORT_SUCCESS,
    FETCH_PAYSLIP_BATCH_DETAIL_SUCCESS,
    FETCH_PAYSLIP_BATCHES_SUCCESS,
    FETCH_PAYSLIP_SUCCESS,
    GENERATE_PAYMENT_ADVICE_SUCCESS,
    SAVE_PAYMENT_ADVICE_SUCCESS,
    SAVE_PAYSLIP_BATCH_DETAILS,
    SAVE_PAYSLIP_BATCH_SUCCESS,
    SEARCH_EMP_BY_DEPT_SUCCESS,
    VERIFY_PAYMENT_BATCH_SUCCESS,
} from './payroll-actions';
import { PAGE, SIZE } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';

const sortCode = (a, b) => {
    let decision = 0;
    decision = getStringFromObject('ruleInputCode.inputCode', a) < getStringFromObject('ruleInputCode.inputCode', b)
        ?
        -1
        :
        1;
    return decision;
};

const payrollReducer = (state = initialStates.payroll, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_EMP_BY_DEPT_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                empByDept: action.data,
            },
        };
        break;
    case SEARCH_EMP_BY_DEPT_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                empByDept: action.data,
            },
        };
        break;
    case SAVE_PAYSLIP_BATCH_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: action.data,
            },
        };
        break;
    case CLEAR_BATCH_DETAILS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: {},
            },
        };
        break;
    case FETCH_EMP_PAYSLIP_INPUT_SUCCESS:
        newState = {
            ...state,
            empPayslipInput: {
                ...action.data,
                payslipInputDtos: action.data.payslipInputDtos.sort(sortCode),
            },
        };
        break;
    case CLEAR_EMP_PAYSLIP_INPUT:
        newState = {
            ...state,
            empPayslipInput: {},
        };
        break;
    case FETCH_PAYROLL_PERIODS_SUCCESS:
        newState = {
            ...state,
            periods: action.data,
        };
        break;
    case FETCH_PAYSLIP_BATCHES_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                filters: getStringFromObject('filters', action, {}),
                batches: getStringFromObject('data.page.content', action, []),
                isLast: getStringFromObject('data.last', action, false),
                isFirst: getStringFromObject('data.page.first', action, false),
                totalPages: getStringFromObject('data.page.totalPages', action, 0),
                totalElements: getStringFromObject('data.totalElements', action, 0),
                page: getStringFromObject('data.page.number', action, PAGE),
                size: getStringFromObject('data.page.size', action, SIZE),
                sortCol: getStringFromObject('sortCol', action),
                sortOrder: getStringFromObject('sortOrder', action, SIZE),
            },
        };
        break;
    case FETCH_PAYSLIP_BATCH_DETAIL_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: action.data,
            },
        };
        break;
    case SAVE_PAYSLIP_BATCH_DETAILS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: action.payload,
            },
        };
        break;
    case GENERATE_PAYMENT_ADVICE_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: action.data,
            },
        };
        break;
    case VERIFY_PAYMENT_BATCH_SUCCESS:
        newState = {
            ...state,
            payrollBatch: {
                ...state.payrollBatch,
                batchDetail: action.data,
            },
        };
        break;
    case FETCH_PAYMENT_ADVICE_LIST_SUCCESS:
        newState = {
            ...state,
            paymentAdvice: {
                ...state.paymentAdvice,
                list: getStringFromObject('data.page.content', action, []),
                isLast: getStringFromObject('data.last', action, false),
                isFirst: getStringFromObject('data.page.first', action, false),
                totalPages: getStringFromObject('data.page.totalPages', action, 0),
                totalElements: getStringFromObject('data.totalElements', action, 0),
                page: getStringFromObject('data.page.number', action, PAGE),
                size: getStringFromObject('data.page.size', action, SIZE),
                filters: getStringFromObject('filters', action, {}),
            },
        };
        break;
    case FETCH_PAYMENT_ADVICE_SUCCESS:
        newState = {
            ...state,
            paymentAdvice: {
                ...state.paymentAdvice,
                advice: action.data,
            },
        };
        break;
    case FETCH_PAYMENT_ADVICE_REQUEST:
        newState = {
            ...state,
            paymentAdvice: {
                ...state.paymentAdvice,
                advice: {},
            },
        };
        break;
    case SAVE_PAYMENT_ADVICE_SUCCESS:
        newState = {
            ...state,
            paymentAdvice: {
                ...state.paymentAdvice,
                advice: action.data,
            },
        };
        break;
    case FETCH_PAYROLL_PAYSLIP_SUCCESS:
        newState = {
            ...state,
            payslips: {
                data: getStringFromObject('data.page.content', action, []),
                isLast: getStringFromObject('data.last', action, false),
                isFirst: getStringFromObject('data.page.first', action, false),
                totalPages: getStringFromObject('data.page.totalPages', action, 0),
                totalElements: getStringFromObject('data.totalElements', action, 0),
                page: getStringFromObject('data.page.number', action, PAGE),
                size: getStringFromObject('data.page.size', action, SIZE),
                filters: getStringFromObject('filters', action, {}),
            },
        };
        break;
    case FETCH_PAYSLIP_SUCCESS:
        newState = {
            ...state,
            payslips: {
                ...state.payslips,
                selectedPayslip: action.data,
            },
        };
        break;
    case CLEAR_SELECTED_PAYSLIP_STATE:
        newState = {
            ...state,
            payslips: {
                ...state.payslips,
                selectedPayslip: {},
            },
        };
        break;
    case FETCH_PAYROLL_REPORT_SUCCESS:
        newState = {
            ...state,
            payrollReport: {
                ...state.payrollReport,
                data: action.data,
            },
        };
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    console.log('sachinkljdfasklfdj', newState);

    return newState;
};

export default payrollReducer;
