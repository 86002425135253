import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { payerScheme, payorPolicy, payorSubInsurance } from '../../constants/constants';
import { findBestMatch } from '../../constants/StringSimilarity';
import payerNetworkContainerStyles from './PayerNetworkContainerStyles';
import { getStringFromObject } from '../../constants/lodashUtils';

const tabs = {
    [payorSubInsurance]: {
        label: 'Sub Insurer',
        route: payorSubInsurance,
    },
    [payorPolicy]: {
        label: 'Policy',
        route: payorPolicy,
    },
    [payerScheme]: {
        label: 'Scheme',
        route: payerScheme,
    },
};

class PayerNetworkContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
        };
    }

    componentDidMount() {
        const { history, disablePartialRatioRouteMatching } = this.props;
        const pathName = getStringFromObject('location.pathname', history, '');
        if (pathName) {
            const tabValues = Object.keys(tabs);
            const bestRouteMatches = findBestMatch(pathName, Object.keys(tabs), disablePartialRatioRouteMatching);
            const bestIndex = getStringFromObject('bestMatchIndex', bestRouteMatches);
            if (bestIndex != null) {
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({
                    selectedTab: tabValues[bestIndex],
                });
            }
        }
    }

    fetchPayerDetails = () => {
        console.log('sadas-9udsap0-dojadsa');
    };

    handleTabChange = (event, selectedTab) => {
        this.setState({
            selectedTab,
        });
        const { history, payors } = this.props;
        const parentPayor = getStringFromObject('parentPayor', payors, {});
        history.push(`${tabs[selectedTab].route}/${parentPayor.payerUuid}`);
    };

    render() {
        const {
            children,
            classes,
            payors,
        } = this.props;
        const { selectedTab } = this.state;
        const parentPayor = getStringFromObject('parentPayor', payors, {});
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { fetchPayerDetails: this.fetchPayerDetails }),
        );
        return (
            <div style={{ padding: '2rem', height: '100%' }}>
                <Card className={classes.card}>
                    <div style={{ padding: '2rem', background: '#fff' }} >
                        <h4 className="align-center flexChildren justify-content-center">
                            {parentPayor.name}
                        </h4>
                        <Tabs
                            value={selectedTab}
                            onChange={this.handleTabChange}
                            fullWidth
                            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                        >
                            {
                                Object.keys(tabs).map(tab => (
                                    <Tab
                                        test-id={tabs[tab].label}
                                        key={tab}
                                        value={tab}
                                        label={tabs[tab].label}
                                        classes={{
                                            root: classes.tabRoot,
                                            selected: classes.tabSelected,
                                            label: classes.label,
                                        }}
                                        disableRipple
                                    />
                                ))
                            }
                        </Tabs>
                        {childrenWithProps}
                    </div>
                </Card>
            </div>
        );
    }
}

PayerNetworkContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    payors: PropTypes.object.isRequired,
    disablePartialRatioRouteMatching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    payors: state.payors,
    disablePartialRatioRouteMatching: getStringFromObject('appConfiguration.disablePartialRatioRouteMatching', state) || false,
});

export default connect(mapStateToProps)(withStyles(payerNetworkContainerStyles)(PayerNetworkContainer));
