import API from '../../../constants/api';

export const FETCH_TRANSACTIONS_LIST_REQUEST = '@@transaction/FETCH_TRANSACTIONS_LIST_REQUEST';
export const FETCH_TRANSACTIONS_LIST_FAILURE = '@@transaction/FETCH_TRANSACTIONS_LIST_FAILURE';
export const FETCH_TRANSACTIONS_LIST_SUCCESS = '@@transaction/FETCH_TRANSACTIONS_LIST_SUCCESS';

export const fetchTransactionsListRequest = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_TRANSACTIONS_LIST_REQUEST,
    api: API.ERP_TRANSACTION.PAGES,
    page,
    size,
    sortCol,
    filters,
});
