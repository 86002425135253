import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ActionButton from '../ActionButton/ActionButton';
import DialogComponent from '../DialogComponent/DialogComponent';
import OutlinedTextField from '../OutlinedTextField';
import { getTextFieldChangedValue } from '../../constants/CommonUtil';
import { required } from '../../constants/FormValidations';
import { getStringFromObject } from '../../constants/lodashUtils';

class ButtonWithReason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enterReason: false,
        };
    }

    onTextFieldValueChange = fieldName => (e) => {
        this.props.change(fieldName, getTextFieldChangedValue(e));
    };

    toggleDialog = () => {
        this.setState(prevState => ({
            enterReason: !prevState.enterReason,
        }));
    };

    render() {
        const {
            enterReason,
        } = this.state;
        const {
            label,
            formValues,
            onClick,
            reasonField,
            reasonRequired,
            ...otherProps
        } = this.props;
        console.log('ButtonWithReason', this.props);
        const reasonValue = getStringFromObject(reasonField, formValues);
        return (
            <React.Fragment>
                <ActionButton
                    {...otherProps}
                    onClick={this.toggleDialog}
                >
                    {label}
                </ActionButton>
                {
                    enterReason &&
                    <DialogComponent
                        open
                        header="Reason For Rejection"
                        handleClose={this.toggleDialog}
                    >
                        <Grid container spacing={16}>
                            <Grid item sm={3} md={3} lg={3}>
                                <OutlinedTextField
                                    type="text"
                                    value={reasonValue}
                                    onChange={this.onTextFieldValueChange(reasonField)}
                                    label="Reason"
                                    required={reasonRequired}
                                />
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                <ActionButton onClick={onClick} disabled={reasonRequired && required(reasonValue)} testId="reject">
                                    {label}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </DialogComponent>
                }
            </React.Fragment>
        );
    }
}
ButtonWithReason.propTypes = {
    label: PropTypes.string,
    formValues: PropTypes.object,
    change: PropTypes.func,
    onClick: PropTypes.func,
    reasonField: PropTypes.string.isRequired,
    reasonRequired: PropTypes.bool,
};

ButtonWithReason.defaultProps = {
    label: '',
    formValues: {},
    change: () => {},
    onClick: () => {},
    reasonRequired: false,
};

export default ButtonWithReason;

