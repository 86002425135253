import initialStates from '../../initialStates';
import {
    GENERIC_ADD_OR_UPDATE_CLEAR,
    GENERIC_ADD_OR_UPDATE_FAILURE,
    GENERIC_ADD_OR_UPDATE_SUCCESS,
    GENERIC_FETCH_FAILURE,
    GENERIC_FETCH_REQUEST_CLEAR,
    GENERIC_FETCH_SUCCESS,
} from './genericMaster-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';

/**
 * Created by sachinprakash on 12/8/19.
 */

const genericList = (state = initialStates.genericList, action) => {
    let newState = state;
    switch (action.type) {
    case GENERIC_FETCH_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            size: getStringFromObject('data.size', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case GENERIC_FETCH_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    case GENERIC_FETCH_REQUEST_CLEAR:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};

export default genericList;

export const genericAddOrUpdateReducer = (state = initialStates.genericAddData, action) => {
    let newState = state;
    switch (action.type) {
    case GENERIC_ADD_OR_UPDATE_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            data: getStringFromObject('data.content', action, {}),
        });
        break;
    case GENERIC_ADD_OR_UPDATE_CLEAR:
    case GENERIC_ADD_OR_UPDATE_FAILURE:
        newState = Object.assign({}, state, {
            data: {},
        });
        break;
    default:
        newState = state;
    }
    return newState;
};
