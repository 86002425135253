/**
 * Created by sachinprakash on 30/5/19.
 */
import React, { Suspense } from 'react';
import { Route, withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
    assetCategory,
    assetManufacturer,
    assets,
    assetsCreate,
    assetsEdit,
    assetsView,
} from '../constants/constants';
import AssetsPage from '../pages/AssetsPage/AssetsPage';
import PageLoader from '../components/PageLoader/PageLoader';

const AssetContainer = React.lazy(() => import('../containers/AssetsContainer/AssetContainer'));
const AssetCategory = React.lazy(() => import('../pages/AssetsPage/AssetCategoryPage'));
const AssetManufacturer = React.lazy(() => import('../pages/AssetsPage/AssetManufacturerPage'));
const AssetsAddPage = React.lazy(() => import('../pages/AssetsPage/AssetsAddPage'));
const AssetsEditPage = React.lazy(() => import('../pages/AssetsPage/AssetsEditPage'));
const AssetsViewPage = React.lazy(() => import('../pages/AssetsPage/AssetsViewPage'));

const AssetRoutes = props => (
    <AssetsPage history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={assets} exact component={AssetContainer} />
            <Route path={assetsCreate} exact component={AssetsAddPage} />
            <Route path={`${assetsEdit}/:uuid`} exact component={AssetsEditPage} />
            <Route path={`${assetsView}/:uuid`} exact component={AssetsViewPage} />
            <Route path={assetCategory} exact component={AssetCategory} />
            <Route path={assetManufacturer} exact component={AssetManufacturer} />
        </Suspense>
    </AssetsPage>
);

AssetRoutes.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
};

AssetRoutes.defaultProps = {
    history: {},
};

export default withRouter(AssetRoutes);
