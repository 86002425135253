import { getLoggedInProviderUuid } from './state';

export const setAccountFavorites = (response) => {
    const loggedInProviderUuid = getLoggedInProviderUuid();
    if (response) {
        localStorage.setItem(
            `ACCOUNT-FAVORITES-${loggedInProviderUuid}`,
            JSON.stringify(response),
        );
    } else {
        localStorage.setItem(
            `ACCOUNTS-FAVORITES-${loggedInProviderUuid}`,
            '',
        );
    }
};

export const setFavorites = (favoriteType, patientUuid, schemeUuid, response) => {
    const loggedInProviderUuid = getLoggedInProviderUuid();
    if (response) {
        localStorage.setItem(
            `${favoriteType}-FAVORITES-${loggedInProviderUuid}-${schemeUuid || ''}`,
            JSON.stringify(response),
        );
    } else {
        localStorage.setItem(
            `${favoriteType}-FAVORITES-${loggedInProviderUuid}-${schemeUuid || ''}`,
            '',
        );
    }
};

export const clearFavoritesOfType = (favoriteType) => {
    console.log('asd09ua0da90asd', Object.keys(localStorage), favoriteType);
    Object.keys(localStorage).forEach((aLocalStorageKey) => {
        if (aLocalStorageKey.startsWith(`${favoriteType || ''}-FAVORITES`)) {
            localStorage.removeItem(aLocalStorageKey);
        }
    });
};

export const getAccountFavorites = () => {
    const loggedInProviderUuid = getLoggedInProviderUuid();
    const response = localStorage.getItem(`ACCOUNTS-FAVORITES-${loggedInProviderUuid}`);
    if (response) {
        return JSON.parse(response);
    }
    return null;
};

export const getFavorites = (favoriteType, patientUuid, schemeUuid) => {
    const loggedInProviderUuid = getLoggedInProviderUuid();
    const response = localStorage.getItem(`${favoriteType}-FAVORITES-${loggedInProviderUuid}-${schemeUuid || ''}`);
    if (response) {
        return JSON.parse(response);
    }
    return null;
};

export const clearAllFavoritesOfProvider = () => {
    Object.keys(localStorage).forEach((aLocalStorageKey) => {
        if (aLocalStorageKey.includes('FAVORITES')) {
            localStorage.removeItem(aLocalStorageKey);
        }
    });
};
