import API from '../../../../constants/api';

export const FETCH_PAYORS_RES_PARTNER_LIST_REQUEST = '@@payors/FETCH_PAYORS_RES_PARTNER_LIST_REQUEST';
export const FETCH_PAYORS_RES_PARTNER_LIST_FAILURE = '@@payors/FETCH_PAYORS_RES_PARTNER_LIST_FAILURE';
export const FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS = '@@payors/FETCH_PAYORS_RES_PARTNER_LIST_SUCCESS';

export const FETCH_PAYORS_LIST_REQUEST = '@@payors/FETCH_PAYORS_LIST_REQUEST';
export const FETCH_PAYORS_LIST_FAILURE = '@@payors/FETCH_PAYORS_LIST_FAILURE';
export const FETCH_PAYORS_LIST_SUCCESS = '@@payors/FETCH_PAYORS_LIST_SUCCESS';

export const CREATE_PAYOR_REQUEST = '@@payors/CREATE_PAYOR_REQUEST';
export const CREATE_PAYOR_FAILURE = '@@payors/CREATE_PAYOR_FAILURE';
export const CREATE_PAYOR_SUCCESS = '@@payors/CREATE_PAYOR_SUCCESS';

export const FETCH_PAYOR_DETAILS_REQUEST = '@@payors/FETCH_PAYOR_DETAILS_REQUEST';
export const FETCH_PAYOR_DETAILS_SUCCESS = '@@payors/FETCH_PAYOR_DETAILS_SUCCESS';
export const FETCH_PAYOR_DETAILS_FAILURE = '@@payors/FETCH_PAYOR_DETAILS_FAILURE';

export const CREATE_PAYOR_CATEGORY_REQUEST = '@@payors/CREATE_PAYOR_CATEGORY_REQUEST';
export const CREATE_PAYOR_CATEGORY_SUCCESS = '@@payors/CREATE_PAYOR_CATEGORY_SUCCESS';
export const CREATE_PAYOR_CATEGORY_FAILURE = '@@payors/CREATE_PAYOR_CATEGORY_FAILURE';

export const CLEAR_PAYOR_STATE = '@@payors/CLEAR_PAYOR_STATE';

export const CLEAR_SELECTED_PAYER_STATE = '@@payors/CLEAR_SELECTED_PAYER_STATE';

export const SAVE_PARENT_PAYOR = '@@payors/SAVE_PARENT_PAYOR';

export const fetchPagesOfPayorsResPartner = (page, size, filters = {}) => ({
    type: FETCH_PAYORS_RES_PARTNER_LIST_REQUEST,
    api: `${API.PAYORS.RES_PARTNER_PAGES}?page=${page}&size=${size}&payor=${true}`,
    page,
    size,
    filters,
});

export const fetchPagesOfPayors = (page, size, sortCol, sortOrder, filters = {}) => ({
    type: FETCH_PAYORS_LIST_REQUEST,
    api: `${API.PAYORS.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const createPayorRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_PAYOR_REQUEST,
    api: API.PAYORS.CREATE,
    payload,
    successCallback,
    failureCallBack,
});

export const fetchPayorDetails = payorUuid => ({
    type: FETCH_PAYOR_DETAILS_REQUEST,
    api: `${API.PAYORS.DETAILS}?payer=${payorUuid}`,
    payorUuid,
});

export const clearPayorState = () => ({
    type: CLEAR_PAYOR_STATE,
});

export const clearSelectedPayer = () => ({
    type: CLEAR_SELECTED_PAYER_STATE,
});


export const saveParentPayor = payor => ({
    type: SAVE_PARENT_PAYOR,
    parentPayor: payor,
});

export const savePayorCategory = (payload, successCallback, failureCallBack) => ({
    type: CREATE_PAYOR_CATEGORY_REQUEST,
    api: API.PAYORS.SAVE_CATEGORY,
    payload,
    successCallback,
    failureCallBack,
});
