/* eslint-disable max-len */
import API from '../../../constants/api';

export const FETCH_ENCOUNTER_ORDERS_REQUEST = '@@encounterOrders/FETCH_ENCOUNTER_ORDERS_REQUEST';
export const FETCH_ENCOUNTER_ORDERS_SUCCESS = '@@encounterOrders/FETCH_ENCOUNTER_ORDERS_SUCCESS';
export const FETCH_ENCOUNTER_ORDERS_FAILURE = '@@encounterOrders/FETCH_ENCOUNTER_ORDERS_FAILURE';

export const CLEAR_FETCH_ENCOUNTER_ORDERS_REQUEST = '@@encounterOrders/CLEAR_FETCH_ENCOUNTER_ORDERS';

export const fetchEncounterOrders = (page, size, encounterUuid) => ({
    type: FETCH_ENCOUNTER_ORDERS_REQUEST,
    api: API.ORDER.ALL_ORDERS_IN_ENCOUNTER,
    page,
    size,
    encounterUuid,
});

export const clearEncounterOrders = () => ({
    type: CLEAR_FETCH_ENCOUNTER_ORDERS_REQUEST,
});
