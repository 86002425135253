import {
    CLEAR_DEPARTMENT_LIST,
    CLEAR_SERVICE_LIST,
    DEPARTMENT_LIST_FETCH_SUCCESS,
    SERVICE_LIST_FETCH_SUCCESS,
} from './department-service-actions';
import initialStates from '../../initialStates';

const departmentServiceReducer = (state = initialStates.departmentService, action) => {
    let newState = state;
    switch (action.type) {
    case DEPARTMENT_LIST_FETCH_SUCCESS:
        newState = Object.assign({}, state, { departmentList: action.data });
        break;
    case SERVICE_LIST_FETCH_SUCCESS:
        newState = Object.assign({}, state, { serviceList: action.data });
        break;
    case CLEAR_DEPARTMENT_LIST:
        newState = Object.assign({}, state, { departmentList: [] });
        break;
    case CLEAR_SERVICE_LIST:
        newState = Object.assign({}, state, { serviceList: [] });
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default departmentServiceReducer;
