import {
    CLEAR_LOGIN_STATE, CLEAR_RETRY_DATA,
    LOGIN_FAILURE,
    LOGIN_SILENT_FAILURE,
    LOGIN_SILENT_SUCCESS,
    LOGIN_SUCCESS,
    SESSION_TIMEOUT,
    MISMATCH_IN_VERSION,
} from './login-actions';
import { initialAuthState, JSONToken, persistPrivileges, persistState } from '../../../constants/state';

const loginReducer = (state = { success: null }, action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case LOGIN_SUCCESS:
        newState =
            Object.assign({}, state, {
                login: action.data,
                success: true,
                timedOut: false,
                silentSuccess: null,
            });
        break;
    case LOGIN_SILENT_SUCCESS:
        newState = Object.assign({}, state, { login: action.data, timedOut: false, silentSuccess: true });
        break;
    case LOGIN_SILENT_FAILURE:
        newState = Object.assign({}, state, { silentSuccess: false });
        console.log('INREDUCER', newState);
        break;
    case CLEAR_RETRY_DATA:
        newState = Object.assign(
            {},
            state,
            { actionToDispatchAfterLogin: [], functionToCallAfterLogin: [] },
        );
        console.log('INREDUCER', newState);
        break;
    case SESSION_TIMEOUT:
        console.log('13412321321', action);
        newState = Object.assign(
            {},
            state,
            {
                timedOut: true,
                silentSuccess: null,
                actionToDispatchAfterLogin:
                    (state.actionToDispatchAfterLogin || []).concat(action.actionToDispatchAfterLogin),
                functionToCallAfterLogin:
                    (state.functionToCallAfterLogin || []).concat(action.functionToCallAfterLogin),
            },
        );
        break;
    case MISMATCH_IN_VERSION:
        console.log('13412321321', action);
        newState = Object.assign(
            {},
            state,
            {
                appVersionMismatch: true,
            },
        );
        break;
    case LOGIN_FAILURE:
        newState = Object.assign({}, state, { success: false });
        console.log('INREDUCER', newState);
        break;
    case CLEAR_LOGIN_STATE:
        newState = { success: null, timedOut: null, silentSuccess: null };
        persistState(initialAuthState);
        persistPrivileges(null);
        document.cookie = `${JSONToken}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default loginReducer;
