import React from 'react';
import ReactDOM from 'react-dom';
import {
    findIndexOfValuePresentInArrayOfObjectsForProperty,
    isArrayValidAndNotEmpty, isValidFunction,
} from '../../constants/CommonUtil';
import { getDateInYYYYMMDDFormat, isDateValid } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';


export const focusNextRequiredField = (inputRefs, formValues) => {
    const requiredFieldNames = Object.keys(inputRefs);
    for (let i = 0; i < requiredFieldNames.length; i += 1) {
        const requiredFieldName = requiredFieldNames[i];
        const requiredFieldRef = inputRefs[requiredFieldName];
        if (requiredFieldRef &&
            !getStringFromObject(requiredFieldName, formValues)
        ) { // ref.props.formValues
            if (
                requiredFieldRef.node &&
                requiredFieldRef.node.previousSibling &&
                requiredFieldRef.node.previousSibling.focus &&
                typeof requiredFieldRef.node.previousSibling.focus === 'function'
            // in case of select field the previous sibling must be focused
            // https://stackoverflow.com/questions/47353384/
            // how-to-programmatically-trigger-focus-on-material-ui-select-field
            ) {
                requiredFieldRef.node.previousSibling.focus();
            } else if (
                requiredFieldRef.node &&
                requiredFieldRef.node.focus &&
                typeof requiredFieldRef.node.focus === 'function'
            ) {
                console.log('123sadonjaskd', requiredFieldRef.node.focus);
                requiredFieldRef.node.focus();
            } else {
                requiredFieldRef.focus();
            }
            break;
        }
    }
};

export const getDefaultValueFor = (fieldType, defaultValue) => {
    if (defaultValue !== undefined) {
        return defaultValue;
    }

    if (fieldType === 'date') {
        return '';
    }
    return '';
};
export const getValueFor = (fieldDetails, fetchedData, fieldToCheckInArray = 'name') => {
    console.log('2132132sasdsada', fetchedData, fieldDetails);
    let fieldValue = '';
    if (fieldDetails.formDataValueGetter && fetchedData) {
        const { formDataValueGetter } = fieldDetails;
        const sectionData = fetchedData[formDataValueGetter.section];
        if (formDataValueGetter.arrayOfValues) { // find value in array of values
            const indexOfValue =
                findIndexOfValuePresentInArrayOfObjectsForProperty(
                    formDataValueGetter.value,
                    fieldToCheckInArray,
                    sectionData,
                );
            if (sectionData && sectionData[indexOfValue]) {
                fieldValue = sectionData[indexOfValue].value;
            }
        } else if (sectionData) {
            let value = sectionData[formDataValueGetter.value];
            if (value === true) {
                value = 'true';
            } else if (value === false) {
                value = 'false';
            }
            fieldValue = value;
        } else {
            fieldValue = fetchedData[formDataValueGetter.value];
        }
    }
    if (fieldValue) {
        return fieldValue;
    }
    return getDefaultValueFor(fieldDetails.fieldType, fieldDetails.default);
};

export const getFormDataFrom = (fetchedData, schema = {}) => {
    console.log('12312nlajskdsad', schema);
    const formData = {};
    if (isObjectValidAndNotEmpty(schema)) {
        Object.keys(schema).map((formSection) => {
            const formSectionData = schema[formSection];
            formSectionData.fields.map((aField) => {
                if (aField.fieldType !== 'column') {
                    formData[aField.name] = getValueFor(aField, fetchedData);
                }
                return null;
            });
            return null;
        });
    }
    console.log('formmm dataaa', formData);
    return formData;
};

export const createSubmissionDataFrom = (formData, submissionDataSchema) => {
    console.log('213sdasd', formData, submissionDataSchema);
    const submissionData = {};

    Object.keys(submissionDataSchema).map((aField) => {
        const submissionSchemaForField = submissionDataSchema[aField];
        if (!submissionData[submissionSchemaForField.section]) {
            // if this is present, it means that the sections should be an array
            if (submissionSchemaForField.dataObjectStructure) {
                submissionData[submissionSchemaForField.section] = [];
            } else { // otherwise it is a plain object
                submissionData[submissionSchemaForField.section] = {};
            }
        }

        if (submissionSchemaForField.dataObjectStructure && formData[aField]) {
            let value = formData[aField];
            if (submissionSchemaForField.fieldFromFormValue) {
                value = getStringFromObject(submissionSchemaForField.fieldFromFormValue, value);
            }
            const valueOfField = { ...submissionSchemaForField.dataObjectStructure, value };
            submissionData[submissionSchemaForField.section] =
                submissionData[submissionSchemaForField.section].concat(valueOfField);
        } else {
            let { dtoField } = submissionSchemaForField;
            if (!dtoField) {
                dtoField = aField;
            }
            console.log('dtoField ', dtoField);
            // Take only first data value found.. others will be ignored
            if (!submissionData[submissionSchemaForField.section][dtoField]) {
                let dataValue = formData[aField] || submissionSchemaForField.defaultValue;
                if (submissionSchemaForField.isDate) {
                    if (isDateValid(new Date(dataValue))) {
                        dataValue = new Date(dataValue).getTime();
                    }
                }
                if (submissionSchemaForField.formatDate) {
                    if (isDateValid(new Date(dataValue))) {
                        dataValue = getDateInYYYYMMDDFormat(new Date(dataValue));
                    }
                }
                submissionData[submissionSchemaForField.section][dtoField] = dataValue;
            }
        }
        return null;
    });
    return submissionData;
};

const processErrorField = (errorObject, errors, errorField) => {
    let errField = `${errorField}`;
    if (React.isValidElement(errorObject)) {
        return errField;
    } else if (isObjectValidAndNotEmpty(errorObject) || isArrayValidAndNotEmpty(errorObject)) {
        const objectToConsider = isObjectValidAndNotEmpty(errorObject) ? errorObject : errorObject[0];
        const firstErrorKey = Object.keys(objectToConsider)[0];
        errField = `${errField}.${firstErrorKey}`;
        errField = processErrorField(getStringFromObject(errField, errors), errors, errField);
    }
    return errField;
};

const getErrorField = (errorFields, errors) => {
    let errField = '';
    if (isObjectValidAndNotEmpty(errors)) {
        const firstErrorKey = Object.keys(errors)[0]; // there will atleast be one key
        errField = `${errField}${errField ? '.' : ''}${firstErrorKey}`;
        const errorEl = getStringFromObject(errField, errors);
        errField = processErrorField(errorEl, errors, errField);
    }
    return errField;
    //
    // let errorField = !erroField ? `${errorFields[0]}` : `${erroField}`;
    // const theError = errors[errorField];
    // if (isObjectValidAndNotEmpty(theError) && !React.isValidElement(theError)) {
    //     const errorFieldName = getStringFromObject('[0]', Object.keys(theError));
    //     errorField = `${errorField}.${errorFieldName}`;
    // }
    // return errorField;
};

export const scrollToErrorField = (errors, inputRefs) => {
    console.log('sauioh21323', errors);
    if (typeof errors === 'object') {
        const errorFields = Object.keys(errors);
        if (isArrayValidAndNotEmpty(errorFields)) {
            const errorField = getErrorField(errorFields, errors);
            console.log('asdasd09uas90duasd', errorField);
            const firstErrorFieldRef = inputRefs[errorField];
            if (firstErrorFieldRef) {
                let domNode = null;
                if (firstErrorFieldRef.node &&
                    firstErrorFieldRef.node.previousSibling
                ) {
                    // eslint-disable-next-line react/no-find-dom-node
                    domNode = ReactDOM.findDOMNode(firstErrorFieldRef.node.previousSibling);
                } else {
                    // eslint-disable-next-line react/no-find-dom-node
                    domNode = ReactDOM.findDOMNode(firstErrorFieldRef);
                }
                console.log('asd98y98sad', domNode);
                if (
                    domNode &&
                    domNode.scrollIntoView &&
                    (typeof domNode.scrollIntoView === 'function')
                ) {
                    if (domNode.id) {
                        const elementById = document.getElementById(domNode.id);
                        if (elementById && elementById.focus && isValidFunction(elementById.focus)) {
                            elementById.focus();
                        }
                    }
                    domNode.scrollIntoView(
                        {
                            block: 'center',
                            inline: 'center',
                        },
                    );
                }
            }
        }
    }
};

const checkIfFalsyValue = value => !value;
const checkIfExists = (value) => {
    console.log('123ouhsnjadlkadas', value);
    return Boolean(value);
};

export const evaluateExpression = (value1, value2, operation = '===') => {
    let evaluatedValue = false;
    switch (operation) {
    case '===':
        evaluatedValue = value1 === value2;
        break;
    case '!==':
        evaluatedValue = value1 !== value2;
        break;
    case 'notExists':
        console.log('asdas0dias-0dias-0das', checkIfFalsyValue(value1), value1);
        evaluatedValue = checkIfFalsyValue(value1);
        break;
    case 'exists':
        evaluatedValue = checkIfExists(value1);
        break;
    default: evaluatedValue = false;
    }
    return evaluatedValue;
};

export const shouldSectionBeShown = (showOnlyIf, formValues) => {
    if (isArrayValidAndNotEmpty(showOnlyIf)) {
        return showOnlyIf.every((fieldToCheck) => {
            const formValueOfTheField = getStringFromObject(fieldToCheck.formField, formValues);
            return evaluateExpression(formValueOfTheField, fieldToCheck.value, fieldToCheck.operation);
        });
    }
    return true;
};

// common methods to get handlers in fields from formik and redux form
export const changeFormValue = (props, event, value) => { // formik needs event to be passed
    const {
        input,
        field,
    } = props;
    if (isValidFunction(input.onChange)) {
        input.onChange(value);
    } else if (isValidFunction(field.onChange)) {
        field.onChange({
            ...event,
            target: {
                ...event.target,
                name: field.name,
            },
        });
    }
};

export const blurFormField = (props, event) => {
    const {
        input,
        field,
    } = props;
    if (isValidFunction(input.onBlur)) {
        input.onBlur(event);
    } else if (isValidFunction(field.onBlur)) {
        field.onBlur({
            ...event,
            target: {
                ...event.target,
                name: field.name,
            },
        });
    }
};

export const getFormFieldValue = (props) => {
    const {
        input,
        field,
    } = props;
    let fieldValue = null;
    if (isObjectValidAndNotEmpty(input)) {
        fieldValue = input.value;
    } else if (isObjectValidAndNotEmpty(field)) {
        fieldValue = field.value;
    }
    return fieldValue;
};


export default focusNextRequiredField;
