import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Field, withFormik } from 'formik';
import classNames from 'classnames';
import StopIcon from '@material-ui/icons/Stop';

import {
    apiCatchBlockFunction, getJsonPath,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,
    stringEqualsIgnoreCase,
} from '../../../constants/CommonUtil';
import { ACTION_HANDLERS, setBatchValuesOfProduct, setValuesOfProductIntoRow } from '../InternalMovesHandlers';
import API from '../../../constants/api';
import {
    mapInternalMovesDtoForAdjustInventoryToUiObject,
    mapInternalMovesDtoToUiObject,
    mapInternalMovesForAdjustInventoryFromUiObject,
    mapInternalMovesFromUiObject,
    UI_OBJECT,
} from '../../../mapper/InternalMovesMapper';
// eslint-disable-next-line no-unused-vars
import {
    createInternalMoveRequest,
    fetchIntentData,
    reverseTransferRequest,
} from '../../../redux/modules/internalMoves/internalMoves-actions';
import ActionButton from '../../ActionButton/ActionButton';
import SideLabelReadOnlyText from '../../FormFieldComponents/SideLabelReadOnlyText/SideLabelReadOnlyText';
import {
    adjustInventoryRequest,
    clearStockMoveByPurchaseOrderState,
    fetchStockMovesRequest,
} from '../../../redux/modules/stockMove/stockMove-actions';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import { APPLICATION_CONFIG_URL, GLOBAL_PROPERTIES, ROWS_PER_PAGE } from '../../../constants/constants';
import { required } from '../../../constants/FormValidations';
import Spinner from '../../Spinner/Spinner';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import PrintPDF from '../../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import {
    expiryDateColors,
    expiryLegend,
    getIndexToAdd,
    intentSchema,
    internalMoveFields,
    otherOperationsWhichRequireDestInput,
    otherOperationsWhichRequireSourceInput,
    otherOperationTypes,
} from './InternalMovesUtil';
import DrugBarcodeEntry from './DrugBarcodeEntry';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import MaterialTable from '../../../components/MaterialTableV2/MaterialTable';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { NumberOf } from '../../../constants/numberUtils';

const formName = 'internalMovesForm';

class InternalMovesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            loading: false,
            print: false,
            printBarcode: false,
            itemsToPrint: [],
            intentList: [],
        };
    }

    componentDidMount() {
        const { targetId, setValues } = this.props;
        if (!targetId) {
            setValues(cloneDeep(UI_OBJECT));
        }
        if (this.props.miscOps) {
            this.fetchMiscOperationHelper();
        }
        if (this.props.intentConv) {
            this.fetchIntent();
        }
        let jsonApi = null;
        if (this.props.adjustInventory) {
            jsonApi = getJsonPath('/StockManagement/InternalMovesAdjustInventory.json');
        } else {
            jsonApi = getJsonPath('/StockManagement/InternalMoves.json');
        }
        this.getAllBatchesForPurchaseOrderInternal();
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.internalMove, this.props.internalMove)) {
            if (isArrayValidAndNotEmpty(nextProps.internalMove)) {
                this.initializeForm(nextProps.internalMove[0]);
            }
        }
        if (!deepEqual(nextProps.selectedInternalMove, this.props.selectedInternalMove)) {
            if (isObjectValidAndNotEmpty(nextProps.selectedInternalMove)) {
                this.initializeForm(nextProps.selectedInternalMove);
            }
        }
        if (!deepEqual(nextProps.selectedMove, this.props.selectedMove)) {
            if (isObjectValidAndNotEmpty(nextProps.selectedMove)) {
                this.initializeForm(nextProps.selectedMove);
            }
        }
        if (!deepEqual(nextProps.miscOps, this.props.miscOps)) {
            this.fetchMiscOperationHelper();
        }
        const oldOpType = this.getMiscOpType();
        const newOpType = this.getMiscOpType(nextProps);
        if (newOpType !== oldOpType) {
            const {
                setFieldValue,
            } = this.props;
            const {
                miscOpsHelper,
            } = this.state;
            if (newOpType) {
                const stockLocUuid = getStringFromObject(newOpType, miscOpsHelper);
                console.log('asja9sj-afj-asfa', stockLocUuid, newOpType, oldOpType);
                if (stockLocUuid) {
                    if (otherOperationsWhichRequireSourceInput.includes(newOpType)) {
                        setFieldValue(internalMoveFields.DEST_LOC, {
                            uuid: stockLocUuid,
                        });
                        setFieldValue(internalMoveFields.SOURCE_LOC, null);
                    } else if (otherOperationsWhichRequireDestInput.includes(newOpType)) {
                        setFieldValue(internalMoveFields.SOURCE_LOC, {
                            uuid: stockLocUuid,
                        });
                        setFieldValue(internalMoveFields.DEST_LOC, null);
                    }
                }
            } else {
                setFieldValue(internalMoveFields.DEST_LOC, null);
                setFieldValue(internalMoveFields.SOURCE_LOC, null);
            }
        }
        if (!deepEqual(nextProps.selectedMove, this.props.selectedMove)) {
            if (isObjectValidAndNotEmpty(nextProps.selectedMove)) {
                this.initializeForm(nextProps.selectedMove);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
    }

    onAddProductViaBarcode = async (productInfo, barcodeProductQty) => {
        console.log('asd0dasu90asdd', productInfo);
        const {
            dispatch,
            adjustInventory,
            miscOps,
            values,
            setFieldValue,
            setFieldTouched,
            intentConv,
            expiredProductLocations,
            blockProductAdditionIfBatchDataMissing,
        } = this.props;
        try {
            const allowProductAddWithoutBatchData = !adjustInventory && !miscOps && !blockProductAdditionIfBatchDataMissing;
            const indexToAddTo = getIndexToAdd(values, 'products');

            const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_CODE}${productInfo.produceCode}&codeField=${productInfo.codeField}`);
            const productDetails = response.data;
            const rowValue = {};
            rowValue.product = productDetails;
            rowValue.uom = productDetails.erpUomDto;
            rowValue.taxes = productDetails.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.quantity = NumberOf(barcodeProductQty);
            rowValue.isInternalLocation = false;
            rowValue.userCost = productDetails.cost;

            // getting batch
            // const lastIndex = getStringFromObject('products.length', values);
            // if (index === (lastIndex - 1).toString()) {
            //     // Add row automatically..
            //     setFieldValue(`products.${lastIndex}`, EMPTY_ROW);
            // }
            let newRowValue = setValuesOfProductIntoRow(rowValue, { sourceLoc: values.sourceLoc }, { destinationLoc: values.destinationLoc });
            console.log('newrowvalue', newRowValue);
            const {
                locationUuid,
                productUuid,
                destinationLocationUuid,
            } = newRowValue;
            if (locationUuid) {
                const allowExpiredProducts = (expiredProductLocations.includes(locationUuid) && expiredProductLocations.includes(destinationLocationUuid));
                const response2 = await axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                    params: {
                        productUuid,
                        locationUuid,
                        allowExpiredProducts,
                    },
                });
                newRowValue = setBatchValuesOfProduct(response2, newRowValue);
            }
            if (!isArrayValidAndNotEmpty(newRowValue.batchWithQty)) {
                dispatch(errorMessage('This product has no batches at location'));
                return;
            }
            const foundBatch = newRowValue.batchWithQty.find(aBatch => (
                getStringFromObject('batch.value', aBatch) === productInfo.batchName
            ));
            if (!isObjectValidAndNotEmpty(foundBatch) && !intentConv && !allowProductAddWithoutBatchData) {
                dispatch(errorMessage(`Batch not found with name ${productInfo.batchName} in location`));
                return;
            }
            newRowValue.description = foundBatch;
            const expiryDateTimeInMillis = getStringFromObject('expiryDate', foundBatch);
            const availableQuantity = getStringFromObject('quantity', foundBatch);
            if (expiryDateTimeInMillis) {
                newRowValue.expiryDate = getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis));
            }
            if (availableQuantity) {
                newRowValue.availableQuantity = availableQuantity;
            }
            if ((NumberOf(availableQuantity) < NumberOf(barcodeProductQty)) && !intentConv && !allowProductAddWithoutBatchData) {
                dispatch(errorMessage('This batch does not have enough quantity in this location'));
                return;
            }
            console.log('asdhasdasd9asd-09asd-s', newRowValue, indexToAddTo, rowValue);
            setFieldValue(`products[${indexToAddTo}]`, newRowValue);
            setFieldTouched(`products[${indexToAddTo}].product`);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    getDialogHeader = () => {
        const {
            adjustInventory,
            miscOps,
            values,
        } = this.props;
        if (miscOps) {
            return getStringFromObject(`${internalMoveFields.OPERATION_TYPE}.value`, values) || 'Misc Operations';
        } else if (adjustInventory) {
            return 'Adjust Inventory';
        }
        return 'Stock Move';
    };

    getMiscOpType = (props = this.props) => {
        const {
            values,
        } = props;
        return getStringFromObject(`${internalMoveFields.OPERATION_TYPE}.key`, values);
    };

    getMiscOpsFields = () => {
        const {
            values,
            setFieldValue,
        } = this.props;
        console.log('kkiiasdjoajdsao', values);
        const opType = this.getMiscOpType();
        return (
            <Grid item lg={3} md={3} sm={6}>
                <Grid container style={{ padding: '1em' }}>
                    <Field
                        name={internalMoveFields.OPERATION_TYPE}
                        component={FormikReactSelectMaterial}
                        dataSourceConfig={{
                            text: 'value',
                            value: 'key',
                        }}
                        label="Operation Type"
                        required
                        isDisabled={this.isConfirmed()}
                        placeholder="Operation Type"
                        setFieldValue={setFieldValue}
                        options={Object.values(otherOperationTypes)}
                        validate={required}
                    />
                </Grid>
                <Grid container style={{ padding: '1em' }}>
                    {
                        otherOperationsWhichRequireSourceInput.includes(opType) &&
                        this.getSourceLocationField()
                    }
                    {
                        otherOperationsWhichRequireDestInput.includes(opType) &&
                        this.getDestLocationField()
                    }
                </Grid>
            </Grid>
        );
    };

    getSourceLocationField = () => {
        const {
            adjustInventory,
            setFieldValue,
            values,
        } = this.props;
        console.log('disableitem sour', getStringFromObject('operationType', values) === 'MATERIAL_WITHDRAWAL', getStringFromObject('operationType', values));
        return (
            <Field
                name={internalMoveFields.SOURCE_LOC}
                component={FormikReactSelectMaterial}
                dataSourceConfig={{
                    text: 'name',
                    value: 'uuid',
                }}
                label={adjustInventory ? 'Location' : 'Source Location'}
                isDisabled={this.isConfirmed() || getStringFromObject('operationType', values) === 'MATERIAL_WITHDRAWAL'}
                required
                placeholder={adjustInventory ? 'Location' : 'Source Location'}
                setFieldValue={setFieldValue}
                autocomplete
                dataSourceApi={
                    `${API.SEARCH.STOCK_LOCATION_SUGGESTIONS}?size=20&restrictBasedOnPrivilege=true&searchString=`
                }
                validate={required}
            />
        );
    };

    getDestLocationField = () => {
        const {
            setFieldValue,
            values,
        } = this.props;
        console.log('disableitem dest', getStringFromObject('operationType', values) === 'MATERIAL_REQUEST', getStringFromObject('operationType', values));
        return (
            <Field
                name={internalMoveFields.DEST_LOC}
                component={FormikReactSelectMaterial}
                dataSourceConfig={{
                    text: 'name',
                    value: 'uuid',
                }}
                isDisabled={this.isConfirmed() || getStringFromObject('operationType', values) === 'MATERIAL_REQUEST'}
                label="Destination Location"
                placeholder="Destination Location"
                required
                autocomplete
                dataSourceApi={API.SEARCH.STOCK_LOCATION}
                setFieldValue={setFieldValue}
                validate={required}
            />
        );
    };

    getAllBatchesForPurchaseOrderInternal = () => {
        const {
            dispatch, targetId,
        } = this.props;
        let api = '';
        if (targetId) {
            api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`;
            dispatch(fetchStockMovesRequest(api));
        }
        console.log('kljasdflaksjdfh', this.props);
    };

    fetchIntent = () => {
        const {
            dispatch, intentUuid, setFieldValue,
        } = this.props;
        console.log(setFieldValue, intentUuid);
        const uuid = intentUuid;
        const successCallback = (data) => {
            this.setState({
                intentList: data.intentLineDtoList,
            });
            console.log('callbackdata', data);
            setFieldValue('operationType', data.type);
            console.log('operationTypee', data.type);
            setFieldValue((data.type === 'MATERIAL_REQUEST' || data.type === 'PURCHASE') ? internalMoveFields.DEST_LOC : internalMoveFields.SOURCE_LOC, { uuid: data.location.key, name: data.location.value });
        };
        dispatch(fetchIntentData(uuid, successCallback));
    };

    fetchMiscOperationHelper = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            const response =
                await axios.get(`${API.GLOBAL_PROPERTY.GET_SINGLE_PROPERTY}${GLOBAL_PROPERTIES.STOCK_MISC_OPS_HELPER}`);
            this.setState({
                miscOpsHelper: response.data,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    isConfirmed = () => {
        const {
            values,
        } = this.props;
        return ['done', 'confirmed'].indexOf(getStringFromObject('status', values)) !== -1 && values.uuid;
    };

    resetLoading = (loading = false) => this.setState({ loading: Boolean(loading) });

    initializeForm = (formValues) => {
        const { dispatch, adjustInventory, setValues } = this.props;
        this.setState({ loading: true }, () => {
            let uiObjectPromise = null;
            if (adjustInventory) {
                uiObjectPromise = mapInternalMovesDtoForAdjustInventoryToUiObject(formValues, adjustInventory);
            } else {
                uiObjectPromise = mapInternalMovesDtoToUiObject(formValues, adjustInventory);
            }
            uiObjectPromise.then((response) => {
                setValues(response);
                this.resetLoading();
            }).catch((error) => {
                apiCatchBlockFunction(error, dispatch);
                this.resetLoading();
            });
        });
    };

    handleCreateNew = () => {
        this.initializeForm({ ...UI_OBJECT });
    }

    handleSave = () => {
        const { adjustInventory, setFieldValue } = this.props;
        setFieldValue('operation', adjustInventory ? 'STOCK_ADJUSTMENT' : 'INTERNAL');
        setFieldValue('status', 'draft');
        this.props.submitForm();
        // if (adjustInventory) {
        //     dispatch(adjustInventoryRequest(mapInternalMovesFromUiObject(formValues, 'draft', true)));
        // } else {
        //     dispatch(createInternalMoveRequest(mapInternalMovesFromUiObject(formValues, 'draft')));
        // }
    };

    handleConfirm = () => {
        const { adjustInventory, setFieldValue, intentUuid } = this.props;
        setFieldValue('operation', adjustInventory ? 'STOCK_ADJUSTMENT' : 'INTERNAL');
        setFieldValue('status', 'done');
        setFieldValue('intentUuid', intentUuid);
        console.log('intentuuidonConfirm', intentUuid);
        this.props.submitForm();
        // const { dispatch, handleClose, adjustInventory } = this.props;
        // if (adjustInventory) {
        //     dispatch(adjustInventoryRequest(
        //         mapInternalMovesFromUiObject(formValues, 'done', true), handleClose));
        // } else {
        //     dispatch(createInternalMoveRequest(mapInternalMovesFromUiObject(formValues, 'done'), handleClose));
        // }
    };

    handleReverseTransfer = () => {
        const {
            dispatch,
            values,
        } = this.props;
        dispatch(reverseTransferRequest(mapInternalMovesFromUiObject(values, values.status, false)));
    };

    handlePrint = () => {
        this.setState(prev => ({
            print: !prev.print,
        }));
    };

    handleChecks = (values) => {
        const newList = cloneDeep(this.state.intentList);
        const { products } = values;
        newList.map((prod, i) => {
            const ind = products.findIndex(intent => getStringFromObject('product.uuid', intent, '') === prod.product.key);
            console.log('findProduct', ind, prod);
            if (ind !== -1) {
                newList[i].quantityReceived = products[ind].quantity;
            } else {
                newList[i].quantityReceived = 0;
            }
            return null;
        });
        console.log('Checks', newList);
        if (!deepEqual(this.state.intentList, newList)) {
            this.setState({
                intentList: newList,
            });
        }
    }

    handleSelectBatchOption = async (action, index) => {
        const itemsToPrint = [];
        const { dispatch, values } = this.props;
        const itemUuid = getStringFromObject(`products.[${index}].uuid`, values);
        let type = '';
        console.log('vaidadfnaldfa', getStringFromObject(`products.[${index}]`, values), action);
        const quantity = NumberOf(getStringFromObject(`products.[${index}].quantity`, values));
        if (action === 'printProductBarcode') {
            type = 'STOCK_MOVE_PRODUCT';
        } else if (action === 'printBatchBarcode') {
            type = 'STOCK_MOVE_BATCH';
        }
        if (itemUuid && type) {
            try {
                dispatch(showSpinner());
                const response = await axios.get(getUrlWithApiParams(API.BATCHES.PRINT, { itemUuid, quantity, type }));
                if (isObjectValidAndNotEmpty(response.data)) {
                    const failureReason = getStringFromObject('failureReason', response.data);
                    if (failureReason) {
                        dispatch(errorMessage(failureReason));
                    } else {
                        const token = getStringFromObject('downloadToken', response.data);
                        itemsToPrint.push({
                            url: `${API.FILE.DOWNLOAD_PDF_WITH_TOKEN}${token}`,
                            name: 'inventoryProductPrint',
                        });
                    }
                }
                // dispatch(hideSpinner());
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            } finally {
                dispatch(hideSpinner());
            }
        }
        this.setState(prevState => ({
            printBarcode: !prevState.printBarcode,
            itemsToPrint,
        }));
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            values,
            adjustInventory,
            company,
            miscOps,
            intentConv,
            barcodeParser,
            expiredProductLocations,
        } = this.props;
        const {
            schema,
            loading,
            print,
            printBarcode,
            itemsToPrint,
        } = this.state;
        const tableSchema = cloneDeep(schema);
        console.log('tableschema', tableSchema);
        const opType = this.getMiscOpType();
        if (otherOperationsWhichRequireDestInput.includes(opType)) {
            setStringPropertyToObject('tableCells[1].creatable', tableSchema, true);
            setStringPropertyToObject('tableCells[8].fieldType', tableSchema, 'textField');
        }
        const isConfirmed = this.isConfirmed();
        const createDate = getStringFromObject('createDate', values);
        if (isArrayValidAndNotEmpty(this.state.intentList)) {
            this.handleChecks(values);
        }
        let receivedBy = getStringFromObject('receivedBy', values, '');
        if (receivedBy === '') {
            receivedBy = 'Automatic';
        }
        const products = getStringFromObject('products', values);
        let total = 0;
        if (isArrayValidAndNotEmpty(products)) {
            total = products.reduce((previousValue, currentValue) => previousValue + (NumberOf(currentValue.cost)
                * (currentValue.quantity ? Number(currentValue.quantity) : 1)), 0);
        }
        console.log('totalvalue', total);
        const name = getStringFromObject('name', values);
        console.log('jklhlkjsadfklj', values, miscOps);
        const printData = {
            ...values,
            isStockAdjustment: stringEqualsIgnoreCase(getStringFromObject('transactionType', values), 'STOCK_ADJUSTMENT'),
            company,
            createDate,
            receivedBy,
            name: getStringFromObject('name', values, ''),
            total,
        };
        console.log('ddddddddjkhdaskjdf', this.props, values);
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div className={classes.header}>
                                    {
                                        this.getDialogHeader()
                                    }
                                </div>
                                <Close
                                    className={classNames(classes.closeIcon, 'cursor-pointer')}
                                    onClick={handleClose}
                                    testId="close-icon"
                                />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                <Grid container spacing={16} justify="space-between">
                                    {
                                        miscOps ?
                                            this.getMiscOpsFields() :
                                            <Grid item lg={6} md={6} sm={12}>
                                                <Grid container style={{ padding: '1em' }}>
                                                    <Grid item sm={12} lg={6} md={6}>
                                                        {this.getSourceLocationField()}
                                                    </Grid>
                                                </Grid>
                                                {
                                                    !adjustInventory && (
                                                        <Grid container spacing={16} style={{ padding: '1em' }}>
                                                            <Grid item sm={12} lg={6} md={6}>
                                                                {this.getDestLocationField()}
                                                            </Grid>
                                                            <Grid item sm={12} lg={6} md={6}>
                                                                <Field
                                                                    testId="date"
                                                                    label="Stock Move Date"
                                                                    name="date"
                                                                    component={DateInput}
                                                                    fullWidth
                                                                    disabled={isConfirmed}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                    }
                                    {
                                        createDate &&
                                        <Grid item style={{ padding: '2em' }}>
                                            <Grid container direction="column" spacing={16}>
                                                <Grid item>
                                                    <SideLabelReadOnlyText
                                                        input={{ value: createDate }}
                                                        label="Date Created :"
                                                        classes={classes}
                                                    />
                                                </Grid>
                                                {
                                                    receivedBy &&
                                                    <Grid item>
                                                        <SideLabelReadOnlyText
                                                            input={{ value: receivedBy }}
                                                            label="Creator :"
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    name &&
                                                    <Grid item>
                                                        <SideLabelReadOnlyText
                                                            input={{ value: name }}
                                                            label="Ref No. :"
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    values.sourceLoc && !isConfirmed &&
                                    <DrugBarcodeEntry
                                        onlyBatch={!intentConv}
                                        dispatch={this.props.dispatch}
                                        onConfirmQuantity={this.onAddProductViaBarcode}
                                        barcodeParser={barcodeParser}
                                    />
                                }
                                <Grid container fullWidth justify="flex-start" alignItems="center">
                                    {Object.keys(expiryLegend)
                                        .map(item => (
                                            <Grid
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                direction="row"
                                                item
                                                container
                                                key={item}
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <StopIcon style={{ fill: expiryDateColors[item] }} />
                                                </Grid>
                                                <Grid item>{expiryLegend[item]}  </Grid>
                                            </Grid>
                                        ))}
                                </Grid>
                                <Grid container spacing={2} direction="row" className="mt-2">
                                    {
                                        this.props.intentConv &&
                                        <Grid item sm={3}>
                                            <MaterialTable
                                                schema={intentSchema}
                                                data={this.state.intentList}
                                                noPagination
                                            />
                                        </Grid>
                                    }
                                    {
                                        values.sourceLoc && isObjectValidAndNotEmpty(tableSchema) &&
                                        <Grid item sm={this.props.intentConv ? 9 : 12}>
                                            <FormikTable
                                                fieldName={internalMoveFields.PRODUCTS}
                                                isEditable={!isConfirmed}
                                                actionHandlers={ACTION_HANDLERS}
                                                dispatch={this.props.dispatch}
                                                styles={{ minHeight: '20em' }}
                                                {...tableSchema}
                                                tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                                actionParams={{
                                                    sourceLoc: values.sourceLoc,
                                                    destinationLoc: values.destinationLoc,
                                                    expiredProductLocations,
                                                }}
                                                rowsPerPageOptions={ROWS_PER_PAGE}
                                                handleSelectAction={this.handleSelectBatchOption}
                                                formValues={values}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    values.sourceLoc &&
                                    <Grid container className="mt-2" justify="space-between">
                                        <Grid item lg={3} md={3} sm={4}>
                                            <Field
                                                name={internalMoveFields.NOTES}
                                                component={FormikTextField}
                                                label="Memo"
                                                multiline
                                                rows={5}
                                                rowsMax={5}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={isConfirmed}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                values.sourceLoc && !isConfirmed &&
                                <div>
                                    <ActionButton
                                        testId="stock-move-misc-save"
                                        disableRipple
                                        disabled={isConfirmed}
                                        onClick={this.handleSave}
                                    >
                                        Save
                                    </ActionButton>
                                    <ActionButton
                                        testId="stock-move-misc-confirm"
                                        disableRipple
                                        disabled={isConfirmed}
                                        className="ml-1"
                                        onClick={this.handleConfirm}
                                    >
                                        Confirm
                                    </ActionButton>
                                </div>
                            }
                            {
                                isConfirmed &&
                                <div>
                                    <ActionButton
                                        className="mr-1"
                                        testId="stock-move-reverse-transfer"
                                        disableRipple
                                        onClick={this.handleReverseTransfer}
                                    >
                                        Reverse Transfer
                                    </ActionButton>
                                    <ActionButton
                                        className="mr-1"
                                        testId="stock-move-misc-adjust-print"
                                        disableRipple
                                        onClick={this.handlePrint}
                                    >
                                        Print
                                    </ActionButton>
                                    <ActionButton
                                        className="mr-1"
                                        testId="stock-move-misc-adjust-new"
                                        disableRipple
                                        onClick={this.handleCreateNew}
                                    >
                                        New
                                    </ActionButton>
                                </div>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    data={printData}
                    print={print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/StockManagement/InternalMove.html`}
                />
                {
                    loading &&
                    <Spinner
                        canShow
                    />
                }
                <PrintPDF
                    print={printBarcode}
                    itemsToPrint={itemsToPrint}
                />
            </React.Fragment>
        );
    }
}

InternalMovesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    values: PropTypes.object,
    internalMove: PropTypes.array,
    selectedInternalMove: PropTypes.object,
    selectedMove: PropTypes.object,
    open: PropTypes.bool.isRequired,
    targetId: PropTypes.number,
    adjustInventory: PropTypes.bool,
    miscOps: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    company: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    intentConv: PropTypes.bool,
    intentData: PropTypes.object,
    intentUuid: PropTypes.string,
    barcodeParser: PropTypes.string,
    expiredProductLocations: PropTypes.string,
    blockProductAdditionIfBatchDataMissing: PropTypes.bool,
};

InternalMovesDialog.defaultProps = {
    dispatch: () => { },
    values: {},
    internalMove: [],
    selectedInternalMove: {},
    selectedMove: {},
    targetId: null,
    adjustInventory: false,
    miscOps: false,
    intentConv: false,
    intentData: {},
    intentUuid: '',
    barcodeParser: '',
    expiredProductLocations: '',
    blockProductAdditionIfBatchDataMissing: false,
};

const mapStateToProps = (state, props) => {
    console.log('s');
    return ({
        internalMove: getStringFromObject('stockMove.stockMovesByPurchaseOrder', state, []),
        selectedInternalMove: getStringFromObject('stockMove.selected', state, {}),
        selectedMove: getStringFromObject('internalMoves.selected', state, {}),
        miscOps: (
            props.miscOps ||
            (!!getStringFromObject('internalMoves.selected.miscOperationType', state)) ||
            (!!getStringFromObject('stockMove.stockMovesByPurchaseOrder[0].miscOperationType', state))
        ),
        company: getStringFromObject('appConfiguration.companyName', state),
        intentData: (
            props.intentConv &&
            (getStringFromObject('internalMoves.intentData', state))
        ),
        barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
        expiredProductLocations: getStringFromObject('appConfiguration.expired_product_internal_move_locations', state, ''),
        blockProductAdditionIfBatchDataMissing: getStringFromObject('appConfiguration.blockProductAdditionIfBatchDataMissing', state, '') || false,
    });
};

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('fandfjkajghajkgbfajkg', values, formikBag);
    const dispatch = getStringFromObject('dispatch', props);
    const adjustInventory = getStringFromObject('adjustInventory', props);
    const isActionConfirm = ['done', 'confirmed'].indexOf(getStringFromObject('status', values)) !== -1;
    if (values.operation === 'STOCK_ADJUSTMENT') {
        if (adjustInventory) {
            dispatch(adjustInventoryRequest(
                mapInternalMovesForAdjustInventoryFromUiObject(values, values.status, true),
                isActionConfirm ? props.handleClose : '',
            ));
        } else {
            dispatch(adjustInventoryRequest(
                mapInternalMovesFromUiObject(values, values.status, true),
                isActionConfirm ? props.handleClose : '',
            ));
        }
    } else {
        dispatch(createInternalMoveRequest(
            mapInternalMovesFromUiObject(values, values.status),
        ));
    }
};

const validate = (values, props) => {
    let errors = {};
    if (!props.adjustInventory) {
        const products = getStringFromObject('products', values, []);
        if (isArrayValidAndNotEmpty(products)) {
            products.forEach((rowValue, index) => {
                const product = getStringFromObject('product', rowValue, {});
                const isInternalLocation = getStringFromObject('isInternalLocation', rowValue, false);
                const batch = getStringFromObject('description.value', rowValue, 0);
                const quantity = getStringFromObject('quantity', rowValue, 0);
                const availableQuantity = getStringFromObject('description.quantity', rowValue, 0);
                const salePrice = NumberOf(getStringFromObject('salePrice', rowValue, 0));
                const userCost = NumberOf(getStringFromObject('userCost', rowValue, 0));
                if (
                    isInternalLocation && batch &&
                    (Number(quantity) > Number(availableQuantity))
                ) {
                    errors = setStringPropertyToObject(`products.${index}.quantity`,
                        errors, `Can not be more than available quantity(${availableQuantity})`);
                }
                if (Number(quantity <= 0) && product.productId) {
                    errors = setStringPropertyToObject(`products.${index}.quantity`,
                        errors, 'Should be more than 0');
                }
                if (salePrice < userCost) {
                    errors = setStringPropertyToObject(`products.${index}.userCost`,
                        errors, 'Cost price cannot be more than sale price ');
                }
            });
        }
    }
    return errors;
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(UI_OBJECT),
    validate,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(dialogComponentStyles)(InternalMovesDialog)));
