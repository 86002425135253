import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import OutlinedTextField from '../../OutlinedTextField';
import { apiCatchBlockFunction, getTextFieldChangedValue } from '../../../constants/CommonUtil';
import ReduxFormNumericField from '../../FormFieldComponents/ReduxFormNumericField';
import {
    discountCodeTypes,
} from '../../../containers/RegistrationAppComponents/QuickRegistration/QuickRegBillingSummary/QuickRegBillingSummaryUtil';
import ActionButton from '../../ActionButton/ActionButton';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import API from '../../../constants/api';
import ReduxFormCheckbox from '../../FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { discountCodeFormFields } from './GeneralSettingsUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

class DiscountCodeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discountCode: props.discountCode,
        };
    }

    onCodeChange = (e) => {
        const code = getTextFieldChangedValue(e);
        this.setState(prevState => ({
            discountCode: {
                ...prevState.discountCode,
                [discountCodeFormFields.CODE]: code,
            },
        }));
    };

    onDiscountChange = fieldName => (value) => {
        this.setState(prevState => ({
            discountCode: {
                ...prevState.discountCode,
                [fieldName]: value,
            },
        }));
    };

    updateDiscountCode = async () => {
        const {
            discountCode,
        } = this.state;
        const {
            dispatch,
            handleClose,
        } = this.props;
        const enteredCode = getStringFromObject(discountCodeFormFields.CODE, discountCode);
        if (!enteredCode) {
            dispatch(errorMessage('Code Is Required'));
            return null;
        }
        const discountCodeType = getStringFromObject(discountCodeFormFields.DISCOUNT_CODE_TYPE, discountCode);
        if (discountCodeType === discountCodeTypes.FIXED) {
            const discountPercentage = getStringFromObject(discountCodeFormFields.DISCOUNT_PERCENTAGE, discountCode);
            const discountAmount = getStringFromObject(discountCodeFormFields.DISCOUNT_AMOUNT, discountCode);
            console.log('sda0-od-0a-0si-da', discountPercentage, discountAmount);
            if (discountPercentage && discountAmount) {
                dispatch(errorMessage('Both Percentage And Amount Cannot Be Specified'));
                return null;
            }
            if (discountPercentage && discountPercentage > 100) {
                dispatch(errorMessage('Discount % cannot be more than 100'));
                return null;
            }
        }
        try {
            await axios.post(API.DISCOUNT_CODE.ADD_OR_UPDATE, discountCode);
            dispatch(successMessage('Updated Successfully'));
            handleClose(null, true);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
        return null;
    };

    render() {
        const {
            discountCode,
        } = this.state;
        console.log('as-1o2-3o-123o-1', discountCode);
        const discountType = getStringFromObject(discountCodeFormFields.DISCOUNT_CODE_TYPE, discountCode);
        return (
            <Grid container spacing={16}>
                <Grid item sm={2} md={2} lg={2}>
                    <OutlinedTextField
                        testId="code"
                        label="Code"
                        value={getStringFromObject(discountCodeFormFields.CODE, discountCode)}
                        onChange={this.onCodeChange}
                        required
                    />
                </Grid>
                {
                    discountType === discountCodeTypes.FIXED &&
                    <React.Fragment>
                        <Grid item sm={3} md={3} lg={3}>
                            <ReduxFormNumericField
                                test-id="discountpercent"
                                label="Discount %"
                                input={{
                                    value: getStringFromObject(
                                        discountCodeFormFields.DISCOUNT_PERCENTAGE,
                                        discountCode,
                                    ),
                                    onChange: this.onDiscountChange(discountCodeFormFields.DISCOUNT_PERCENTAGE),
                                }}
                                max={100}
                                min={0}
                            />
                        </Grid>
                        <Grid item sm={3} md={3} lg={3}>
                            <ReduxFormNumericField
                                test-id="discount-amount"
                                label="Discount Amount"
                                input={{
                                    value: getStringFromObject(discountCodeFormFields.DISCOUNT_AMOUNT, discountCode),
                                    onChange: this.onDiscountChange(discountCodeFormFields.DISCOUNT_AMOUNT),
                                }}
                                min={0}
                            />
                        </Grid>
                    </React.Fragment>
                }
                {
                    discountType === discountCodeTypes.FLEXIBLE &&
                    <React.Fragment>
                        <Grid item sm={3} md={3} lg={3}>
                            <ReduxFormNumericField
                                test-id="maxdiscountpercent"
                                label="Max Discount %"
                                input={{
                                    value:
                                        getStringFromObject(
                                            discountCodeFormFields.MAX_DISCOUNT_PERCENTAGE,
                                            discountCode,
                                        ),
                                    onChange: this.onDiscountChange(discountCodeFormFields.MAX_DISCOUNT_PERCENTAGE),
                                }}
                                max={100}
                                min={0}
                            />
                        </Grid>
                        <Grid item sm={3} md={3} lg={3}>
                            <ReduxFormNumericField
                                test-id="maxdiscountamt"
                                label="Max Discount Amt"
                                input={{
                                    value: getStringFromObject(
                                        discountCodeFormFields.MAX_DISCOUNT_AMOUNT,
                                        discountCode,
                                    ),
                                    onChange: this.onDiscountChange(discountCodeFormFields.MAX_DISCOUNT_AMOUNT),
                                }}
                                min={0}
                            />
                        </Grid>
                    </React.Fragment>
                }
                <Grid item sm={2} md={2} lg={2}>
                    <ReduxFormCheckbox
                        test-id="active"
                        label="Active"
                        input={{
                            value: getStringFromObject(discountCodeFormFields.ACTIVE, discountCode),
                            onChange: this.onDiscountChange(discountCodeFormFields.ACTIVE),
                        }}
                    />
                </Grid>
                <Grid item sm={2} md={2} lg={2} />
                <Grid item sm={12} md={12} lg={12} className="flexChildren justify-content-right">
                    <ActionButton
                        test-id="change"
                        onClick={this.updateDiscountCode}
                    >
                        CHANGE
                    </ActionButton>
                </Grid>
            </Grid>
        );
    }
}

DiscountCodeForm.propTypes = {
    discountCode: PropTypes.object,
    handleClose: PropTypes.func,
    dispatch: PropTypes.func,
};

DiscountCodeForm.defaultProps = {
    discountCode: {},
    handleClose: () => {},
    dispatch: () => {},
};

export default DiscountCodeForm;

