import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import { ordersQueueUrls } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const OrdersQueueOpdQueuePage = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueOpdQueuePage/OrdersQueueOpdQueuePage'));
const OrdersQueueMainContainer = React.lazy(() => import('../containers/OrdersQueueMainContainer/OrdersQueueMainContainer'));
const OpdAppointmentsPage = React.lazy(() => import('../pages/OpdPage/OpdAppointmentsPage'));
const OrdersQueueOrdersListPage = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueOrdersListPage/OrdersQueueOrdersListPage'));
const OrdersQueueOldOrdersListPage = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueOldOrdersListPage/OrdersQueueOldOrdersListPage'));
const OrdersQueueOrdersDetailsPage = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueOrdersDetailsPage/OrdersQueueOrdersDetailsPage'));
const OrdersQueueReportsPage = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueReportsPage/OrdersQueueReportsPage'));
const MissedOPDQueue = React.lazy(() => import('../pages/OrdersQueuePages/OrdersQueueMissedOPDQueue/OrdersQueueMissedOPDQueue'));

const OrdersQueueRoutes = props => (
    <OrdersQueueMainContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={ordersQueueUrls.home} exact component={OrdersQueueOpdQueuePage} />
            <Route path={ordersQueueUrls.reportTemplates} exact component={OpdAppointmentsPage} />
            <Route path={ordersQueueUrls.reports} exact component={OrdersQueueReportsPage} />
            <Route path={ordersQueueUrls.orders} exact component={OrdersQueueOrdersListPage} />
            <Route path={ordersQueueUrls.oldOrders} exact component={OrdersQueueOldOrdersListPage} />
            <Route path={ordersQueueUrls.missedList} exact component={MissedOPDQueue} />
            <Route path={`${ordersQueueUrls.orderDetail}/:uuid`} exact component={OrdersQueueOrdersDetailsPage} />
        </Suspense>
    </OrdersQueueMainContainer>
);

OrdersQueueRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

OrdersQueueRoutes.defaultProps = {
    history: {},
};

export default OrdersQueueRoutes;
