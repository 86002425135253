import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';

const columns = [
    {
        name: 'name',
        label: 'Name',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'orderDate',
        label: 'Date',
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: 'shippingTo',
        label: 'Shipping Location',
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: 'total',
        label: 'Total',
        options: {
            filter: true,
            sort: false,
        },
    },
];


const POListPopUp = (props) => {
    const {
        open,
        handleClose,
        data,
        onRowClick,
    } = props;

    const options = {
        onRowClick,
    };
    return (
        <Dialog
            open={open}
            maxWidth="lg"
            PaperProps={{ style: { minWidth: '70rem', borderRadius: '0.6rem' } }}
        >
            <DialogTitle id="polist-dialog-title">
                <Grid container justify="space-between">
                    <div>Purchase Orders to Receive Bill Yet</div>
                    <Close className="cursor-pointer" onClick={handleClose} test-id="close-yetbill" />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <MUIDataTable
                    title="Click on one to genereate supplier invoice"
                    data={data || []}
                    columns={columns}
                    options={options}
                />
            </DialogContent>
        </Dialog>);
};

POListPopUp.propTypes = {
    data: PropTypes.object.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
};
POListPopUp.defaultProps = {
    open: false,
};
export default POListPopUp;
