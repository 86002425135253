import React from 'react';
import PropTypes from 'prop-types';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { applicationDateAndTimeFormat, applicationDateTimeInputMask } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    input: {
        paddingTop: '9px',
        paddingBottom: '9px',
        marginRight: '12px',
    },
});

class FormikDateTimePicker extends React.PureComponent {
    onChangeHandler = (value) => {
        const {
            field: { name }, form, onChangeHandlers, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, name, form);
                }
            });
        }
    };

    onError = (_, error) => {
        const {
            form,
            field,
        } = this.props;
        if (isObjectValidAndNotEmpty(form)) {
            form.setFieldError(field.name, error);
        }
    };

    handleChange = (value) => {
        const { field: { name }, form } = this.props;
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const validateOnChange = getStringFromObject('validateOnChange', form, false);
        if (isValidFunction(setFieldValue)) {
            setFieldValue(name, value, validateOnChange);
        }
        this.onChangeHandler(value);
    };

    handleOnBlur = (e) => {
        e.preventDefault();
        const {
            field: { name, onBlur },
            form,
            onBlurHandlers,
            actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onBlurHandlers) && isObjectValidAndNotEmpty(actionHandlers)) {
            onBlurHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(e.target.value, name, form);
                }
            });
        }
        if (isValidFunction(onBlur)) {
            onBlur(e);
        }
    };

    render() {
        const {
            field: {
                name,
                value = '',
            },
            label,
            // ---- ignored props (do not pass unnecessary props down) -----
            onChangeHandlers,
            isMandatory,
            headerStyle,
            actionHandlers,
            onBlurHandlers,
            classes,
            forceError,
            // ----------------
            ...otherProps
        } = this.props;
        const {
            // ---- ignored props (do not pass unnecessary props down) -----
            form,
            noLabel,
            disableIf,
            takeChoicesFromRow,
            actionParams,
            onValueChange,
            isComponent,
            paramMap,
            formValues,
            variant,
            ...remainingProps
            // ----------------
        } = otherProps;
        const touched = getStringFromObject(`touched.${name}`, form, false);
        const error = getStringFromObject(`errors.${name}`, form, '');
        const showError = (touched && error) || (forceError && error);
        console.log('abjdfhaldfka', this.props, remainingProps);
        console.log('formikfielderrordate', {
            name,
            form,
            error,
            erros: form.errors,
            showError,
            touched,
        });
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InlineDateTimePicker
                    format={applicationDateAndTimeFormat}
                    mask={applicationDateTimeInputMask}
                    keyboard
                    showTodayButton
                    {...remainingProps}
                    id={name} // required don't remove
                    onBlur={this.handleOnBlur}
                    error={showError}
                    helperText={(showError) ? error : ''}
                    variant={variant}
                    label={label}
                    onChange={this.handleChange}
                    onError={this.onError}
                    value={value ? new Date(value) : null}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

FormikDateTimePicker.propTypes = {
    classes: PropTypes.object.isRequired,
    onChangeHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    headerStyle: PropTypes.object,
    isMandatory: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    /* formik props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    forceError: PropTypes.bool,
};

FormikDateTimePicker.defaultProps = {
    onChangeHandlers: [],
    onBlurHandlers: [],
    actionHandlers: {},
    headerStyle: {},
    isMandatory: false,
    label: '',
    forceError: false,
    variant: 'outlined',
};

export default withStyles(styles)(FormikDateTimePicker);
