import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, initialize, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import axios from 'axios';

import DialogComponent from '../../../../components/DialogComponent/DialogComponent';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { required } from '../../../../constants/FormValidations';
import OutlinedTextField from '../../../../components/OutlinedTextField';
import { hideSpinner, showSpinner } from '../../../../redux/modules/spinner/spinner';
import API from '../../../../constants/api';
import {
    isValidFunction,
    apiCatchBlockFunction,

} from '../../../../constants/CommonUtil';
import { errorMessage, successMessage } from '../../../../redux/modules/message/message-actions';
import ReduxFormCheckbox from '../../../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { getStringFromObject } from '../../../../constants/lodashUtils';

const UPDATE_ROOM_NUMBER_FORM = 'updateRoomNumberForm';
const FORM_FIELD_NAMES = {
    ROOM_NUMBER: 'roomNumber',
    FLOOR_NUMBER: 'floorNumber',
    CLEAR_ROOM_NUMBER: 'clearRoomNumber',
};

class UpdateRoomNumberForm extends Component {
    componentDidMount() {
        const { roomNumber, floorNumber } = this.props;
        this.initializeForm({
            [FORM_FIELD_NAMES.ROOM_NUMBER]: roomNumber,
            [FORM_FIELD_NAMES.FLOOR_NUMBER]: floorNumber,
            [FORM_FIELD_NAMES.CLEAR_ROOM_NUMBER]: false,
        });
    }

    onSubmit = async (formValues) => {
        const {
            dispatch,
            queueUuid,
            onClose,
            fetchDoctorQueue,
        } = this.props;
        try {
            const roomNumber = getStringFromObject(FORM_FIELD_NAMES.ROOM_NUMBER, formValues) || '';
            const floorNumber = getStringFromObject(FORM_FIELD_NAMES.FLOOR_NUMBER, formValues) || '';
            const clearRoomNumber = getStringFromObject(FORM_FIELD_NAMES.CLEAR_ROOM_NUMBER, formValues) || false;
            if (!clearRoomNumber && (!roomNumber || !floorNumber)) {
                dispatch(errorMessage('Please enter room and floor number'));
                return;
            }

            dispatch(showSpinner());
            await axios.put(
                `${API.QUEUES.UPDATE_ROOM_NUMBER
                }?queueUuid=${queueUuid}&roomNumber=${roomNumber
                }&floorNumber=${floorNumber}&clearRoomNumber=${clearRoomNumber}`,
            );
            dispatch(hideSpinner());
            dispatch(successMessage('Room number updated successfully'));
            if (isValidFunction(onClose) && isValidFunction(fetchDoctorQueue)) {
                onClose();
                fetchDoctorQueue();
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    }

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        dispatch(initialize(UPDATE_ROOM_NUMBER_FORM, formValues));
    };

    handleClearRoomNumberChecked = () => {
        const { change } = this.props;
        change(FORM_FIELD_NAMES.ROOM_NUMBER, '');
        change(FORM_FIELD_NAMES.FLOOR_NUMBER, '');
    }

    render() {
        const { onClose, handleSubmit, clearRoomNumber } = this.props;
        return (
            <DialogComponent
                open
                maxWidth="md"
                fullWidth
                header="Update Room Number"
                allowOverflow
                handleClose={onClose}
                dialogActions={
                    <React.Fragment>
                        <Grid item style={{ marginRight: '10px' }}>
                            <ActionButton primary={false} onClick={onClose}>
                                Cancel
                            </ActionButton>
                        </Grid>
                        <Grid item>
                            <ActionButton onClick={handleSubmit(this.onSubmit)}>
                                Submit
                            </ActionButton>
                        </Grid>
                    </React.Fragment>
                }
            >
                <Grid container spacing={16}>
                    <Grid item sm={3} md={3} lg={3}>
                        <Field
                            testId={FORM_FIELD_NAMES.ROOM_NUMBER}
                            name={FORM_FIELD_NAMES.ROOM_NUMBER}
                            component={OutlinedTextField}
                            label="Room Number"
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={!clearRoomNumber}
                            validate={!clearRoomNumber ? [required] : []}
                            disabled={clearRoomNumber}
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <Field
                            testId={FORM_FIELD_NAMES.FLOOR_NUMBER}
                            name={FORM_FIELD_NAMES.FLOOR_NUMBER}
                            component={OutlinedTextField}
                            label="Floor Number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={!clearRoomNumber}
                            validate={!clearRoomNumber ? [required] : []}
                            disabled={clearRoomNumber}
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <Field
                            testId={FORM_FIELD_NAMES.CLEAR_ROOM_NUMBER}
                            name={FORM_FIELD_NAMES.CLEAR_ROOM_NUMBER}
                            component={ReduxFormCheckbox}
                            label="Clear Room Number"
                            onChangeHandlers={['handleClearRoomNumberChecked']}
                            actionHandlers={{
                                handleClearRoomNumberChecked: this.handleClearRoomNumberChecked,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogComponent>
        );
    }
}

UpdateRoomNumberForm.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    roomNumber: PropTypes.string.isRequired,
    floorNumber: PropTypes.string.isRequired,
    fetchDoctorQueue: PropTypes.func.isRequired,
    queueUuid: PropTypes.string.isRequired,
    clearRoomNumber: PropTypes.bool,
    change: PropTypes.func,
};

UpdateRoomNumberForm.defaultProps = {
    dispatch: () => {},
    handleSubmit: () => {},
    clearRoomNumber: false,
    change: () => {},
};

const mapStateToProps = (state) => {
    const selector = formValueSelector(UPDATE_ROOM_NUMBER_FORM);
    const clearRoomNumber = selector(
        state,
        FORM_FIELD_NAMES.CLEAR_ROOM_NUMBER,
    );
    return {
        clearRoomNumber,
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: UPDATE_ROOM_NUMBER_FORM,
})(UpdateRoomNumberForm));
