import initialStates from '../../initialStates';
import {
    FETCH_INVOICE_LIST_DATA_SUCCESSS,
    RESET_INVOICE_LIST_DATA,
    RESET_INVOICE_LINE_DATA,
    FETCH_INVOICE_LINE_DATA_SUCCESSS,
    SAVE_INVOICE_LINE_DATA,
    FETCH_INVOICE_DETAILS_SUCCESSS,
} from './invoice-actions';

export const insuranceInvoiceListReducer = (state = initialStates.insuranceInvoice, action = {}) => {
    let newState = state;
    switch (action.type) {
    case FETCH_INVOICE_LIST_DATA_SUCCESSS:
        console.log('fetch invoice list data success', state.insuranceInvoiceList, action.data.content);
        if (action.data && action.data.content) {
            newState = Object.assign({}, state, {
                insuranceInvoiceList: action.data.content,
                isLoading: false,
                list: action.data.content,
                page: action.data.number,
                size: action.data.size,
                totalElements: action.data.totalElements,
                filters: action.filters,
            });
        }
        break;
    case FETCH_INVOICE_LINE_DATA_SUCCESSS:
        console.log('djhbjhvbxfvkjdbn', action);
        if (action.data && action.data.content) {
            newState = Object.assign({}, state, {
                insuranceInvoiceLine: action.data.content,
            });
        }
        break;
    case RESET_INVOICE_LIST_DATA:
        newState = Object.assign({}, state, { insuranceInvoiceList: [] });
        break;
    case RESET_INVOICE_LINE_DATA:
        newState = Object.assign({}, state, { insuranceInvoiceLine: [] });
        break;
    case SAVE_INVOICE_LINE_DATA:
        newState = Object.assign({}, state, {
            invoiceListData: [action.data],
        });
        break;
    case FETCH_INVOICE_DETAILS_SUCCESSS:
        console.log('djjdjdjd', action);
        newState = Object.assign({}, state, {
            insuranceInvoiceDetails: action.data,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default insuranceInvoiceListReducer;

