import initialStates from '../../../initialStates';
import { CLEAR_POLICY_STATE, FETCH_POLICY_LIST_REQUEST, FETCH_POLICY_LIST_SUCCESS } from './policy-actions';


const policyReducer = (state = initialStates.subinsurers, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_POLICY_LIST_REQUEST:
        newState = {
            ...state,
            page: action.page,
            size: action.size,
            filters: action.filters,
        };
        break;
    case FETCH_POLICY_LIST_SUCCESS:
        newState = {
            ...state,
            policyList: action.data.content,
            totalElements: action.data.totalElements,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        };
        break;
    case CLEAR_POLICY_STATE:
        newState = initialStates.subinsurers;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default policyReducer;
