import { takeLatest } from 'redux-saga/effects';
import { addVisitWorkerSaga, fetchVisitWorkerSaga, updateVisitWorkerSaga } from './visitDetailsWorkerSaga';
import {
    ADD_VISIT_REQUEST, FETCH_VISIT_REQUEST,
    UPDATE_VISIT_REQUEST,
} from '../../redux/modules/visitDetails/visitDetails-actions';

export function* addVisitWatcherSaga() {
    yield takeLatest(ADD_VISIT_REQUEST, addVisitWorkerSaga);
}

export function* updateVisitWatcherSaga() {
    yield takeLatest(UPDATE_VISIT_REQUEST, updateVisitWorkerSaga);
}

export function* fetchVisitWatcherSaga() {
    yield takeLatest(FETCH_VISIT_REQUEST, fetchVisitWorkerSaga);
}

export default addVisitWatcherSaga;
