import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import { Field } from 'formik';
import API from '../../constants/api';
import { email, required } from '../../constants/FormValidations';
import CreateNewSupplierDialog from '../../components/PurchaseOrderComponents/CreateNewSupplierDialog';
import {
    apiCatchBlockFunction,
    extractTextFromDoubleQuotes,

} from '../../constants/CommonUtil';
import { formatDate } from '../../constants/DateUtil';
import ActionButton from '../ActionButton/ActionButton';
import FormikReactSelectMaterial from '../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../Formik/FieldComponents/FormikTextField';
import { getAddressFromAddressDto } from '../../mapper/PurchaseOrderMapper';
import FormikCheckbox from '../Formik/FieldComponents/FormikCheckbox';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { BOOK_MANAGEMENT_PRIVILEGES, STOCK_MANAGEMENT_PRIVILEGES } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';


const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
});

class PurchaseOrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createNewSupplier: false,
            supplierName: '',
        };
    }

    handleCloseCreateNewSupplier = (createdPartner) => {
        this.setState({
            createNewSupplier: false,
            supplierName: '',
        }, () => {
            const { setFieldValue } = this.props;
            setFieldValue('supplier', createdPartner);
            this.handleChooseSupplier(createdPartner);
        });
    };

    handleChooseSupplier = (supplier) => {
        const { setFieldValue, intentUuid } = this.props;
        if (isObjectValidAndNotEmpty(supplier)) {
            if (supplier.uuid && (typeof supplier.uuid === 'string') && supplier.uuid.includes('NCD-')) {
                const supplierName = extractTextFromDoubleQuotes(getStringFromObject('name', supplier));
                this.setState({
                    createNewSupplier: true,
                    supplierName,
                }, () => setFieldValue('supplier', null));
            } else {
                setFieldValue('email', supplier.email || '');
                setFieldValue('mailingAddress', getAddressFromAddressDto(supplier));
                setFieldValue('dateOrder', formatDate(new Date(), 'yyyy-MM-dd'));
                setFieldValue('siNumber', supplier.sinumber || '');
                if (!intentUuid) {
                    setFieldValue('warehouse', null);
                }
            }
        }
    };

    handleChooseWarehouse = (warehouse) => {
        const { setFieldValue, dispatch } = this.props;
        const warehouseUuid = getStringFromObject('uuid', warehouse);
        if (warehouseUuid) {
            axios.get(`${API.ADDRESS.WAREHOUSE_ADDRESS}${warehouseUuid}`).then(
                response =>
                    setFieldValue('shippingAddress', getAddressFromAddressDto(getStringFromObject('data', response))),
            ).catch(error => apiCatchBlockFunction(error, dispatch));
        }
    };

    render() {
        const {
            classes,
            handleRecieveStock,
            formValues,
            isEditable,
            handleClickPaymentInvoice,
            handleMarkAsReceiveStock,
            isView,
            subCompany,
            enableSubVendorCheckBox,
        } = this.props;
        const { createNewSupplier, supplierName } = this.state;
        const isDone = getStringFromObject('state', formValues) === 'done';
        const isConfirmed = getStringFromObject('state', formValues) === 'confirmed';
        console.log('PurchaseOrder props', this.props.formValues);
        return (
            <div>
                <Grid container className="mt-1" spacing={16}>
                    <Grid item lg={9} md={9} sm={12}>
                        <Grid container className="mt-1" spacing={16}>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    test-id="purchase-order-supplier"
                                    name="supplier"
                                    validate={required}
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label="Supplier Name"
                                    autocomplete
                                    creatable
                                    dataSourceApi={API.SEARCH.SUPPLIER}
                                    onSelectHandlers={['handleChooseSupplier']}
                                    actionHandlers={{
                                        handleChooseSupplier: this.handleChooseSupplier,
                                    }}
                                    isMandatory
                                    isDisabled={!isEditable}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    test-id="purchase-order-email"
                                    name="email"
                                    component={FormikTextField}
                                    label="Email"
                                    placeholder="Email"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    validate={email}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={!isEditable}
                                />
                            </Grid>
                            {
                                !isView &&
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Field
                                            test-id="purchase-order-updatedsupplieremail"
                                            name="updateSupplierEmail"
                                            label="Update Supplier Email"
                                            component={FormikCheckbox}
                                            formControlProps={{
                                                labelPlacement: 'start',
                                            }}
                                            disabled={!isEditable}
                                        />
                                    </Grid>
                            }
                            {
                                enableSubVendorCheckBox && (
                                    <Grid item lg={2} md={2} sm={2}>
                                        <Field
                                            test-id="purchase-order-subVendor"
                                            name="subVendor"
                                            label="Sub Vendor"
                                            component={FormikCheckbox}
                                            formControlProps={{
                                                labelPlacement: 'start',
                                            }}
                                            disabled={!isEditable}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container className="mt-1" spacing={16}>
                            <Grid item lg={4} md={4} sm={4}>
                                <Field
                                    name="mailingAddress"
                                    test-id="mailingAddress"
                                    component={FormikTextField}
                                    label="Mailing address"
                                    placeholder="Mailing address"
                                    multiline
                                    rows={5}
                                    rowsMax={5}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} className="pl-2">
                                <Field
                                    name="warehouse"
                                    testId="purchase-order-warehouse"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label="Ship to"
                                    validate={required}
                                    autocomplete
                                    key={subCompany}
                                    dataSourceApi={`${API.SEARCH.WAREHOUSE}&subCompany=${subCompany || ''}&name=`}
                                    isDisabled={!isEditable}
                                    onSelectHandlers={['handleChooseWarehouse']}
                                    actionHandlers={{
                                        handleChooseWarehouse: this.handleChooseWarehouse,
                                    }}
                                />
                                <div className="mt-1">
                                    <Field
                                        test-id="purchase-order-shippingaddress"
                                        name="shippingAddress"
                                        component={FormikTextField}
                                        label="Shipping address"
                                        placeholder="Shipping address"
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />
                                </div>
                            </Grid>
                            <Grid item lg={3} md={3} sm={2} className="pl-2">
                                <Field
                                    test-id="purchase-order-orderdate"
                                    label="Purchase order date"
                                    name="dateOrder"
                                    component={FormikTextField}
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={!isEditable}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                />
                                <div className="mt-1">
                                    <Field
                                        test-id="supplier-invoice-number"
                                        name="siNumber"
                                        component={FormikTextField}
                                        label="Supplier Invoice Number"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12}>
                        {
                            !isView &&
                            <Grid container direction="column">
                                <Grid item lg={12} md={12} sm={12}>
                                    {
                                        (formValues.name && (isConfirmed || isDone)) &&
                                        (
                                            checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.receiveStock)
                                            ||
                                            checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.returnStock)
                                        )
                                        &&
                                        <div
                                            className={classNames('flo-right')}
                                            style={{ margin: '1.5em 0 0 0' }}
                                        >
                                            <ActionButton
                                                id="receive-return-stock"
                                                disableRipple
                                                fullWidth
                                                onClick={handleRecieveStock}
                                            >
                                                {
                                                    isDone ? 'View Received Or Returned Stock' : 'Receive Or Return Stock'
                                                }
                                            </ActionButton>
                                        </div>
                                    }
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    {
                                        (formValues.name && (isConfirmed || isDone)) &&
                                        checkIfPrivilegeExistsForUser(BOOK_MANAGEMENT_PRIVILEGES.purchaseBill) &&
                                        <div
                                            className={classNames('flo-right')}
                                            style={{ margin: '1.5em 0 0 0' }}
                                        >
                                            <ActionButton
                                                id="purchase-invoice"
                                                disableRipple
                                                fullWidth
                                                onClick={handleClickPaymentInvoice}
                                            >
                                                Purchase Invoice
                                            </ActionButton>
                                        </div>
                                    }
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    {
                                        (formValues.name && (isConfirmed && !isDone)) &&
                                        <div
                                            className={classNames('flo-right')}
                                            style={{ margin: '1.5em 0 0 0' }}
                                        >
                                            <ActionButton
                                                id="mark-as-completed"
                                                disableRipple
                                                fullWidth
                                                onClick={handleMarkAsReceiveStock}
                                            >
                                                Mark As Completed
                                            </ActionButton>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    createNewSupplier &&
                    <CreateNewSupplierDialog
                        open={createNewSupplier}
                        tempName={supplierName}
                        handleClose={this.handleCloseCreateNewSupplier}
                    />
                }
            </div>
        );
    }
}

PurchaseOrderDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    handleRecieveStock: PropTypes.func,
    formValues: PropTypes.object,
    isEditable: PropTypes.bool,
    isView: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    subCompany: PropTypes.string,
    handleClickPaymentInvoice: PropTypes.func,
    dispatch: PropTypes.func,
    handleMarkAsReceiveStock: PropTypes.func,
    intentUuid: PropTypes.string,
    enableSubVendorCheckBox: PropTypes.func,
};

PurchaseOrderDetails.defaultProps = {
    handleRecieveStock: () => { },
    handleClickPaymentInvoice: () => { },
    handleMarkAsReceiveStock: () => { },
    dispatch: () => { },
    formValues: {},
    subCompany: '',
    isEditable: false,
    isView: false,
    intentUuid: null,
    enableSubVendorCheckBox: false,
};

export default withStyles(style)(PurchaseOrderDetails);
