import initialStates from '../../initialStates';

import {
    CLEAR_SELECTED_ACCOUNT_EXPENSE,
    CREATE_ACCOUNT_EXPENSE_SUCCESS,
    FETCH_ACCOUNT_EXPENSE_SUCCESS,
} from './employeeExpense-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';


const accountExpenseReducer = (state = initialStates.accountExpense, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ACCOUNT_EXPENSE_SUCCESS:
        newState = { ...state, selectedExpense: action.data };
        break;
    case CREATE_ACCOUNT_EXPENSE_SUCCESS:
        newState = { ...state, selectedExpense: getStringFromObject('data', action, null) };
        break;
    case CLEAR_SELECTED_ACCOUNT_EXPENSE:
        newState = { ...state, selectedExpense: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default accountExpenseReducer;
