import React from 'react';
import { Icon, Typography, Dialog, DialogContent, Grid, DialogTitle } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import './AppVersionMismatchDialog.css';
import RoundedCornerButton from '../RoundedCornerButton';
import { clearAllDataAndReload, getJsonPath } from '../../constants/CommonUtil';

class AppVersionMismatchDialog extends React.Component {
    onReloadButtonClick = () => {
        clearAllDataAndReload();
    };

    render() {
        return (
            <Dialog
                open
                modal={false}
                style={{ margin: 0 }}
                bodyStyle={{ padding: 0 }}
                contentClassName="dialogContent"
            >
                <DialogTitle style={{ padding: '0' }}>
                    <div className="topBarVersionMismatch">
                        <Grid container justify="space-evenly" alignItems="center">
                            <Grid item>
                                <img
                                    src={getJsonPath('updated.svg')}
                                    alt="App Version Mismatch"
                                    width="100px"
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" component="h6">
                                    Latest Version Available
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="formSectionVersionMismatch">
                        <Grid container justify="center">
                            <Grid item >
                                <Typography>
                                    Great news!
                                    The app version has been updated.
                                    Kindly refresh your page to access the
                                    latest version and ensure a seamless experience
                                </Typography>
                                <Typography variant="overline" style={{ marginTop: '8px' }}>
                                    Click the reload button to clear cache data and reload
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between" style={{ marginTop: '32px' }}>
                            <Grid container item sm={8} justify="flex-start" >
                                <Grid item>
                                    <Icon>
                                        <Warning color="error" />
                                    </Icon>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        This action will logout user
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={4}
                            >
                                <RoundedCornerButton
                                    label="Reload"
                                    backgroundColor="#1E88E5"
                                    color="#ffffff"
                                    onClick={this.onReloadButtonClick}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

AppVersionMismatchDialog.propTypes = {
};

AppVersionMismatchDialog.defaultProps = {
};

export default AppVersionMismatchDialog;
