import React from 'react';
import Popover from '@material-ui/core/Popover';
import Calendar from '@material-ui/icons/InsertInvitation';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedTextField from '../OutlinedTextField';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';
import ActionButton from '../ActionButton/ActionButton';
import { getTextFieldChangedValue } from '../../constants/CommonUtil';

const styles = () => ({
    input: {
        backgroundColor: '#A8C1CF',
        marginTop: '1em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    paper: {
        padding: '2em',
        width: '23em',
    },
    dateStyle: {
        backgroundColor: '#A8C1CF',
        width: '51%',
        paddingLeft: '2px',
    },
    orderAmountStyle: {
        backgroundColor: '#A8C1CF',
        width: '100%',
    },
    labelStyle: {
        fontWeight: 'normal',
        fontSize: '14px',
        color: 'black',
    },
});


class Filter extends React.PureComponent {
    getCalendarIcon = () => (
        <Calendar
            style={{ cursor: 'pointer', marginBottom: '3px' }}
        />
    );


    render() {
        const { anchorEl, handleStateChange, states } = this.props;
        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ paper: this.props.classes.paper }}
            >
                <Grid container>
                    <Grid item lg={12} sm={12} md={12} className="mb-1">
                        <OutlinedTextField
                            type="date"
                            label="From"
                            value={getDateInYYYYMMDDFormat(states.fromDate)}
                            onChange={value => handleStateChange('fromDate', getTextFieldChangedValue(value))}
                            defaultValue={getDateInYYYYMMDDFormat(new Date())}
                        />
                    </Grid>
                    <Grid item lg={12} sm={12} md={12}>
                        <OutlinedTextField
                            type="date"
                            label="To"
                            value={getDateInYYYYMMDDFormat(states.toDate)}
                            onChange={e => handleStateChange('toDate', getTextFieldChangedValue(e))}
                            defaultValue={getDateInYYYYMMDDFormat(new Date())}
                        />
                    </Grid>
                </Grid>
                <Grid container className="mt-1">
                    <Grid item lg={12} sm={12} md={12}>
                        <OutlinedTextField
                            label="Group Company"
                            fullWidth
                            value={states.company}
                            onChange={event => handleStateChange('company', getTextFieldChangedValue(event))}
                        />
                    </Grid>
                </Grid>
                <Grid container className="mt-1">
                    <Grid item lg={12} sm={12} md={12}>
                        <OutlinedTextField
                            label="Patient Name/Patient Id"
                            fullWidth
                            value={states.patient}
                            onChange={event => handleStateChange('patient', getTextFieldChangedValue(event))}
                        />
                    </Grid>
                </Grid>
                <Grid container className="mt-1">
                    <Grid item lg={12} sm={12} md={12}>
                        <OutlinedTextField
                            label="Doctor"
                            fullWidth
                            value={states.doctor}
                            onChange={event => handleStateChange('doctor', getTextFieldChangedValue(event))}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '1em' }}>
                    <Grid item lg={5} sm={5} md={5}>
                        <InputLabel className={this.props.classes.labelStyle} htmlFor="type-helper">
                            Type
                        </InputLabel>
                        <FormControl>
                            <Select
                                value={states.type}
                                onChange={this.handleTypeChange}
                                inputProps={{
                                    name: 'type',
                                    id: 'type-helper',
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} sm={2} md={2} />
                    <Grid item lg={5} sm={5} md={5} >
                        <InputLabel className={this.props.classes.labelStyle} htmlFor="status-helper">
                            Status
                        </InputLabel>
                        <FormControl>
                            <Select
                                value={states.status}
                                onChange={this.handleStatusChange}
                                inputProps={{
                                    name: 'status',
                                    id: 'status-helper',
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '1em', marginBottom: '6px' }}>
                    Order amount
                </Grid>
                <Grid container>
                    <Grid item lg={5} sm={5} md={5}>
                        <OutlinedTextField
                            label="Min"
                            fullWidth
                            value={states.min}
                            onChange={event => handleStateChange('min', getTextFieldChangedValue(event))}
                        />
                    </Grid>
                    <Grid item lg={2} sm={2} md={2} style={{ marginTop: '24px', textAlign: 'center' }}>To</Grid>
                    <Grid item lg={5} sm={5} md={5}>
                        <OutlinedTextField
                            label="Max"
                            fullWidth
                            value={states.max}
                            onChange={event => handleStateChange('max', getTextFieldChangedValue(event))}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '36px' }}>
                    <Grid item lg={2} sm={2} md={2} />
                    <Grid item lg={8} sm={8} md={8}>
                        <ActionButton onClick={this.props.handleFilter} >
                           Apply
                        </ActionButton>
                    </Grid>

                </Grid>
            </Popover>
        );
    }
}

Filter.propTypes = {
    anchorEl: PropTypes.object,
    classes: PropTypes.object,
    handleClose: PropTypes.func,
    handleFilter: PropTypes.func,
    handleStateChange: PropTypes.func,
    states: PropTypes.object,
};

Filter.defaultProps = {
    anchorEl: null,
    handleClose: () => {},
    classes: {},
    handleFilter: () => {},
    handleStateChange: () => {},
    states: {},
};

export default withStyles(styles)(Filter);
