/* eslint-disable max-len */
import API from '../../../constants/api';

export const FETCH_DEBIT_AND_CREDIT_LINES_REQUEST = '@@cashPaymentVoucher/FETCH_DEBIT_AND_CREDIT_LINES_REQUEST';
export const FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS = '@@cashPaymentVoucher/FETCH_DEBIT_AND_CREDIT_LINES_SUCCESS';
export const FETCH_DEBIT_AND_CREDIT_LINES_FAILURE = '@@cashPaymentVoucher/FETCH_DEBIT_AND_CREDIT_LINES_FAILURE';
export const CLEAR_DEBIT_AND_CREDIT_LINES = '@@cashPaymentVoucher/CLEAR_DEBIT_AND_CREDIT_LINES';

export const CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST = '@@cashPaymentVoucher/CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST';
export const FETCH_CASH_PAYMENT_VOUCHER_REQUEST = '@@cashPaymentVoucher/FETCH_CASH_PAYMENT_VOUCHER_REQUEST';
export const FETCH_CASH_PAYMENT_VOUCHER_SUCCESS = '@@cashPaymentVoucher/FETCH_CASH_PAYMENT_VOUCHER_SUCCESS';
export const FETCH_CASH_PAYMENT_VOUCHER_FAILURE = '@@cashPaymentVoucher/FETCH_CASH_PAYMENT_VOUCHER_FAILURE';
export const CLEAR_CASH_PAYMENT_VOUCHER = '@@cashPaymentVoucher/CLEAR_CASH_PAYMENT_VOUCHER';

export const fetchDebitAndCreditLinesForAccount = accountId => ({
    type: FETCH_DEBIT_AND_CREDIT_LINES_REQUEST,
    api: API.CASH_PAYMENT_VOUCHER.GET_DEBIT_CREDIT_LINES_FOR_ACCOUNT,
    accountId,
});

export const createOrUpdateCashPaymentVoucher = (payload, successMessage) => ({
    type: CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST,
    api: API.CASH_PAYMENT_VOUCHER.CREATE_OR_UPDATE,
    payload,
    successMessage,
});

export const payOrReceiveOnAccount = (payload, successMessage) => ({
    type: CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST,
    api: API.ACCOUNT_VOUCHER.PAY_OR_RECEIVE_ON_ACCOUNT,
    payload,
    successMessage,
});

export const clearDebitAndCreditLines = () => ({
    type: CLEAR_DEBIT_AND_CREDIT_LINES,
});

export const clearCashPaymentVoucher = () => ({
    type: CLEAR_CASH_PAYMENT_VOUCHER,
});

export const fetchCashPaymentVoucher = voucherUuid => ({
    type: FETCH_CASH_PAYMENT_VOUCHER_REQUEST,
    api: API.CASH_PAYMENT_VOUCHER.GET,
    voucherUuid,
});
