import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_CONSOLIDATED_CASH_CLOSE_REQUEST,
    FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST,
    UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG,
} from '../../redux/modules/consolidatedCashClose/consolidatedCashClose-actions';
import { commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* createConsolidatedCashCloseWatcher() {
    yield takeLatest(CREATE_CONSOLIDATED_CASH_CLOSE_REQUEST, commonPostWorkerSaga);
}

export function* updateConsolidatedCashClosePrintStatusWatcher() {
    yield takeLatest(UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG, commonGetWorkerSaga);
}

export function* fetchConsolidatedCashCloseWatcher() {
    yield takeLatest(FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST, commonGetWorkerSaga);
}
