import { isArrayValidAndNotEmpty } from './CommonUtil';
import { getStringFromObject } from './lodashUtils';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

export const parseQueryParams = (locationSearchString) => {
    let match;
    const pl = /\+/g;// Regex for replacing addition symbol with a space
    const searchStr = /\??([^&=]+)=?([^&]*)/g;
    const decode = s => decodeURIComponent(s.replace(pl, ' '));
    const queryString = locationSearchString || window.location.search.substring(1);

    const urlParams = {};
    while (match = searchStr.exec(queryString)) { // eslint-disable-line no-cond-assign
        urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
};

export const formatUrl = (url, options, useQueryParams) => {
    if (url) {
        const pattern = /<<([^>]*)>>/g;
        const matches = url.match(pattern);
        let replacedString = url;
        const checkQueryParams = useQueryParams || false;
        const queryParameters = parseQueryParams();
        console.log('Sandurl', url);
        console.log('Sandurl opt', options);
        console.log(matches);
        console.log(options);
        if (matches) {
            matches.forEach((el) => {
                const key = el.replace('<<', '').replace('>>', '');
                let value = getStringFromObject(key, options);
                console.log(options[key]);
                if (!value && (checkQueryParams === true)) {
                    value = queryParameters[key] || null;
                }
                replacedString = replacedString.replace(el, value);
            });
        }
        return replacedString.trim();
    }
    return url;
};


export const getUrlQueryParameter = (location, queryParameter) => {
    if (location && location.search) {
        return new URLSearchParams(location.search).get(queryParameter);
    }
    return null;
};


export const addSeekDirectionAndParamsToUrlParams = (url, seekDirection, seekParams) => {
    let apiWithParams = `${url}`;
    if (seekDirection) {
        apiWithParams = `${apiWithParams}&direction=${seekDirection || ''}`;
    }
    if (isObjectValidAndNotEmpty(seekParams)) {
        apiWithParams = `${apiWithParams}&seekParams=${encodeURI(JSON.stringify(seekParams))}`;
    }
    return apiWithParams;
};

export const doesUrlContainsAllParams = (url, params = []) => {
    const split = url.split('?');
    if (split[1] && isArrayValidAndNotEmpty(params)) {
        const usp = new URLSearchParams(split[1]);
        for (let i = 0; i < params.length; i += 1) {
            const val = usp.get(params[i]);
            if (val === null || val === undefined || val === '') {
                return false;
            }
        }
    }
    return true;
};
