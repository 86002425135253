import {
    FETCH_ACTIVE_PATIENTS_SUCCESS, FETCH_ALL_PATIENTS_SUCCESS,
    FETCH_RECENT_PATIENTS_SUCCESS,
} from './patients-action';
import initialStates from '../../initialStates';
import { getStringFromObject } from '../../../constants/lodashUtils';


const patientsReducer = (state = initialStates.patients, action) => {
    let newState = state;
    let content = {};
    switch (action.type) {
    case FETCH_RECENT_PATIENTS_SUCCESS:
        newState = Object.assign({}, state, {
            recent: {
                data: action.data.content,
                isLast: action.data.last,
                totalPages: action.data.totalPages,
                page: action.data.number,
            },
        });
        break;
    case FETCH_ACTIVE_PATIENTS_SUCCESS:
        newState = Object.assign({}, state, {
            active: {
                data: action.data.content,
                isLast: action.data.last,
                totalPages: action.data.totalPages,
                totalElements: action.data.totalElements,
                page: action.data.number,
                filters: action.filters,
                sortCol: action.sortCol,
                sortDir: action.sortDir,
            },
        });
        break;
    case FETCH_ALL_PATIENTS_SUCCESS:
        content = getStringFromObject('data.page.content', action, []);
        console.log('asdasd9aud0asu9da0dpa9dasda', content);
        newState = Object.assign({}, state, {
            allPatients: {
                data: content,
                isLast: getStringFromObject('data.isLast', action, false),
                totalPages: getStringFromObject('data.page.totalPages', action, 0),
                page: getStringFromObject('data.page.number', action, 0),
                filters: action.filters,
                sortCol: action.sortCol,
                sortDir: action.sortDir,
            },
        });
        break;
    default:
        newState = state;
    }

    return newState;
};

export default patientsReducer;
