import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { ADD_OR_UPDATE_TEMPLATE_FAILURE, ADD_OR_UPDATE_TEMPLATE_SUCCESS } from '../../redux/modules/LabSettings/labTest-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';

export const config = {
    headers: { 'Content-Type': 'application/json' },
};

export function* addOrUpdateTemplateWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const { payload, api } = action;
        const response = yield call(retriable().post, api, payload, config);
        yield put({
            type: ADD_OR_UPDATE_TEMPLATE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallBack)) {
            action.successCallBack();
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({
            type: SUCCESS_MESSAGE,
            message: messages.GENERIC.ADD_OR_UPDATE_SUCCESS,
        });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.GENERIC.ADD_OR_UPDATE_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: ADD_OR_UPDATE_TEMPLATE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
