import API from '../../../constants/api';

export const FETCH_VITALS_QUEUE_LIST_REQUEST = '@@announcement/FETCH_VITALS_QUEUE_LIST_REQUEST';
export const FETCH_VITALS_QUEUE_LIST_SUCCESS = '@@announcement/FETCH_VITALS_QUEUE_LIST_SUCCESS';
export const FETCH_VITALS_QUEUE_LIST_FAILURE = '@@announcement/FETCH_VITALS_QUEUE_LIST_FAILURE';

export const FETCH_VITALS_QUEUE_LIST_OPTIMIZED_REQUEST = '@@vitalsQueue/FETCH_VITALS_QUEUE_LIST_OPTIMIZED_REQUEST';
export const FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS = '@@vitalsQueue/FETCH_VITALS_QUEUE_LIST_OPTIMIZED_SUCCESS';
export const FETCH_VITALS_QUEUE_LIST_OPTIMIZED_FAILURE = '@@vitalsQueue/FETCH_VITALS_QUEUE_LIST_OPTIMIZED_FAILURE';

export const ADD_VITALS_REQUEST = '@@announcement/ADD_VITALS_REQUEST';
export const ADD_VITALS_SUCCESS = '@@announcement/ADD_VITALS_SUCCESS';
export const ADD_VITALS_FAILURE = '@@announcement/ADD_VITALS_FAILURE';

export const fetchVitalsQueueRequest = (
    page,
    size,
    sortDir = 'desc',
    sortCol = 'Visit.dateStarted',
    searchConstruct,
    showFulfilled,
) => ({
    type: FETCH_VITALS_QUEUE_LIST_REQUEST,
    api: API.VITALS_QUEUE.FETCH_QUEUE,
    page,
    size,
    sortDir,
    sortCol,
    searchConstruct,
    showFulfilled,
});

export const fetchVitalsQueueOptimizedRequest = (page, size, filters, seekParams, seekDirection) => ({
    type: FETCH_VITALS_QUEUE_LIST_OPTIMIZED_REQUEST,
    api: API.VITALS_QUEUE.FETCH_QUEUE_OPTIMIZED,
    page,
    size,
    seekParams,
    seekDirection,
    filters,
});

export const addVitalsRequest = (
    observations,
    visitUuid,
    patientUuid,
    providerUuid,
    vitalsQueueUuid,
    successCallBack,
) => ({
    type: ADD_VITALS_REQUEST,
    api: API.VITALS_QUEUE.SAVE_VITALS,
    observations,
    visitUuid,
    patientUuid,
    providerUuid,
    vitalsQueueUuid,
    successCallBack,
});

