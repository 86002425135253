import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_SUPPLIER_FAILURE,
    CREATE_SUPPLIER_SUCCESS,
    FETCH_SUPPLIER_LIST_FAILURE,
    FETCH_SUPPLIER_LIST_SUCCESS,
} from '../../redux/modules/supplier/supplier-actions';
import { getStringFromObject } from '../../constants/lodashUtils';


export function* fetchPagesOfSupplierWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_SUPPLIER_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.SUPPLIER.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_SUPPLIER_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createSupplierWorker(action) {
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_SUPPLIER_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        let msg = messages.SUPPLIER.CREATE.SUCCESS;
        if (getStringFromObject('payload.uuid', action, null)) {
            msg = messages.SUPPLIER.UPDATE.SUCCESS;
        }
        yield put({ type: SUCCESS_MESSAGE, message: msg });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = messages.SUPPLIER.CREATE.FAILED;
            if (getStringFromObject('payload.uuid', action, null)) {
                msg = messages.SUPPLIER.UPDATE.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_SUPPLIER_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
