import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    FETCH_CURRENT_USER_PROFILE_SUCCESS,
    FETCH_CURRENT_USER_PROFILE_FAILURE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    FETCH_OTHER_USER_DATA_SUCCESS,
    FETCH_OTHER_USER_DATA_FAILURE,
    FETCH_CALENDAR_WORK_HOURS_SUCCESS, FETCH_CALENDAR_WORK_HOURS_FAILURE,
} from '../../redux/modules/profile/profile-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import API from '../../constants/api';
import { LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST } from '../../redux/modules/leaves/leave-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* profileFetchRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_CURRENT_USER_PROFILE_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_CURRENT_USER_PROFILE_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* profileFetchOtherUser(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}?uuid=${action.uuid}`;
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_OTHER_USER_DATA_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action

        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_OTHER_USER_DATA_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* profileUpdateRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const apiWithQueryParam = `${action.api}`;
        const response = yield call(retriable().put, apiWithQueryParam, action.payload);
        yield put({
            type: UPDATE_PROFILE_SUCCESS,
            data: response.data,
            isCurrentEmployee: action.isCurrentEmployee,
        });
        yield put({
            type: LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST,
            api: API.LEAVES.LEAVE_PLANS_FOR_EMPLOYEE,
            uuid: action.empUuid,
        });
        console.log('asd9uas9ud-as0ud-sa0d', action);
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: UPDATE_PROFILE_FAILURE, data: action.payload });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchCalendarWorkHoursWorkerSaga(action) {
    try {
        const {
            api, startDate, endDate, isCurrentUser, uuid,
        } = action;
        const apiWithQueryParam = `${api}?startDate=${startDate}` +
            `&endDate=${endDate}&currentUser=${isCurrentUser}&uuid=${uuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: FETCH_CALENDAR_WORK_HOURS_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: FETCH_CALENDAR_WORK_HOURS_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

