/**
 * Created by sachinprakash on 3/6/19.
 */
import { takeLatest } from 'redux-saga/effects';

import { assetCategoryListFetch, assetCategoryAddorUpdateWorker } from './assetCategoryWorkerSaga';
import {
    ASSET_CATEGORY_ADD_REQUEST,
    ASSET_CATEGORY_LIST_FETCH_REQUEST,
} from '../../redux/modules/assetCategory/assteCategory-actions';

export function* assetCategoryListFetchWatcher() {
    yield takeLatest(ASSET_CATEGORY_LIST_FETCH_REQUEST, assetCategoryListFetch);
}

export function* assetCategoryAddWatcher() {
    yield takeLatest(ASSET_CATEGORY_ADD_REQUEST, assetCategoryAddorUpdateWorker);
}
