import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import API from '../../../constants/api';
import {
    apiCatchBlockFunction,
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,


} from '../../../constants/CommonUtil';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { successMessage } from '../../../redux/modules/message/message-actions';
import ReduxFormCheckbox from '../../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { userProperty, booleanUserProperties } from './OtherSettingsUtil';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import DialogComponent from '../../../components/DialogComponent/DialogComponent';
import DiscountCodeForm from '../../../components/ServicesComponents/GeneralSettings/DiscountCodeForm';
import { discountCodesType } from '../../../components/ServicesComponents/GeneralSettings/GeneralSettingsUtil';
import { discountCodeTypes } from '../../RegistrationAppComponents/QuickRegistration/QuickRegBillingSummary/QuickRegBillingSummaryUtil';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


class OtherSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userProperties: {
                [userProperty.DISABLE_SOUND_FOR_NEW_PATIENT]: false,
            },
            discountCodes: [],
            discountCodeEditing: null,
        };
    }

    componentDidMount() {
        this.fetchUserSettings();
        this.fetchAllCodes();
        this.props.updateDialogActions(
            <ActionButton
                onClick={this.onConfirm}
            >
                CONFIRM
            </ActionButton>,
        );
    }

    onChangePropertyValue = propertyName => (e) => {
        const propertyValue = getTextFieldChangedValue(e);
        this.setState(prevState => ({
            userProperties: {
                ...prevState.userProperties,
                [propertyName]: propertyValue,
            },
        }));
    };

    onEnableChange = propertyName => (propertyValue) => {
        this.setState(prevState => ({
            userProperties: {
                ...prevState.userProperties,
                [propertyName]: propertyValue,
            },
        }));
    };

    onConfirm = async () => {
        const {
            userProperties,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        if (!isObjectValidAndNotEmpty(userProperties)) {
            return;
        }
        try {
            dispatch(showSpinner());
            let userProps = [];
            Object.keys(userProperties).forEach((aProp) => {
                userProps = userProps.concat({
                    property: aProp,
                    propertyValue: userProperties[aProp],
                });
            });
            await axios.post(API.PROVIDER.ADD_OR_UPDATE_USER_PROPERTY, userProps);
            dispatch(successMessage('Settings Updated Successfully'));
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    onChangePassCode = discountType => () => {
        console.log('asd-aod-0a', discountType);
        const {
            discountManagementConfig,
        } = this.props;
        const {
            discountCodes,
        } = this.state;
        const discountCode = getStringFromObject(discountType, discountCodes) || {};
        if (!isObjectValidAndNotEmpty(discountCode)) {
            const fixedDiscountTypes = [
                discountCodesType.FIXED_DISCOUNT_FRONT_DESK.value,
                discountCodesType.FIXED_DISCOUNT_PHARMACY.value,
            ];
            const frontDeskDiscountType = [
                discountCodesType.FIXED_DISCOUNT_FRONT_DESK.value,
                discountCodesType.FLEXIBLE_DISCOUNT_FRONT_DESK.value,
            ];
            setStringPropertyToObject(
                'location.key',
                discountCode,
                frontDeskDiscountType.includes(discountType) ?
                    discountManagementConfig.frontDeskLocation :
                    discountManagementConfig.pharmacyLocation,
            );
            setStringPropertyToObject(
                'discountCodeType',
                discountCode,
                fixedDiscountTypes.includes(discountType) ?
                    discountCodeTypes.FIXED :
                    discountCodeTypes.FLEXIBLE,
            );
            setStringPropertyToObject(
                'active',
                discountCode,
                true,
            );
        }
        console.log('asda0das-diaso-0dada', discountType, discountCode);
        this.setState({
            discountCodeEditing: discountCode,
        });
    };


    fetchUserSettings = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            dispatch(showSpinner());
            const response = await axios.get(API.PROVIDER.GET_ALL_USER_PROPERTY);
            this.setState((prevState) => {
                const userPropertyObj = {
                    ...prevState.userProperties,
                };
                response.data.forEach((aProperty) => {
                    userPropertyObj[aProperty.property] =
                        booleanUserProperties.includes(aProperty.property) ?
                            aProperty.propertyValue === 'true' :
                            aProperty.propertyValue;
                });
                return {
                    userProperties: userPropertyObj,
                };
            });
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    fetchAllCodes = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            dispatch(showSpinner());
            const response = await axios.get(`${API.DISCOUNT_CODE.FIND_ALL_FOR_USER}?onlyActive=true`);
            const allCodes = {};
            const {
                discountManagementConfig,
            } = this.props;
            if (isArrayValidAndNotEmpty(response.data)) {
                response.data.forEach((aCode) => {
                    const stockLocationUuid = getStringFromObject('location.key', aCode);
                    const discountCodeType = getStringFromObject('discountCodeType', aCode);
                    if (stockLocationUuid === discountManagementConfig.pharmacyLocation) {
                        if (discountCodeType === discountCodeTypes.FLEXIBLE) {
                            allCodes[discountCodesType.FLEXIBLE_DISCOUNT_PHARMACY.value] = aCode;
                        } else if (discountCodeType === discountCodeTypes.FIXED) {
                            allCodes[discountCodesType.FIXED_DISCOUNT_PHARMACY.value] = aCode;
                        }
                    } else if (stockLocationUuid === discountManagementConfig.frontDeskLocation) {
                        if (discountCodeType === discountCodeTypes.FLEXIBLE) {
                            allCodes[discountCodesType.FLEXIBLE_DISCOUNT_FRONT_DESK.value] = aCode;
                        } else if (discountCodeType === discountCodeTypes.FIXED) {
                            allCodes[discountCodesType.FIXED_DISCOUNT_FRONT_DESK.value] = aCode;
                        }
                    }
                });
            }
            dispatch(hideSpinner());
            this.setState({
                discountCodes: allCodes,
            });
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
    };

    closeEditScreen = (_, refetch) => {
        if (refetch) {
            this.fetchAllCodes();
        }
        this.setState({
            discountCodeEditing: null,
        });
    };

    render() {
        const {
            userProperties,
            discountCodes,
            discountCodeEditing,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        return (
            <Grid container spacing={16} className="mt-1">
                {
                    checkIfPrivilegeExistsForUser(['emr.consultation']) &&
                    isObjectValidAndNotEmpty(userProperties) &&
                    Object.keys(userProperties).map(aUserProperty => (
                        <Grid item sm={3} md={3} lg={3} key={aUserProperty}>
                            {
                                booleanUserProperties.includes(aUserProperty) &&
                                    <ReduxFormCheckbox
                                        test-id="enable"
                                        input={{
                                            value: userProperties[aUserProperty] || false,
                                            onChange: this.onEnableChange(aUserProperty),
                                        }}
                                        label={aUserProperty}
                                    />
                            }
                        </Grid>
                    ))
                }
                <Grid container>
                    <Grid container item sm={7} md={7} lg={7} spacing={16} className="ml-1">
                        {
                            Object.values(discountCodesType).map(aDiscountCodeType => (
                                checkIfPrivilegeExistsForUser(aDiscountCodeType.privilege) &&
                                <React.Fragment key={aDiscountCodeType.lable}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <div style={{ display: 'inline', marginRight: '40px' }}>
                                            {aDiscountCodeType.label}:
                                        </div>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4} test-id={`${aDiscountCodeType.label}`}>
                                        {getStringFromObject(`${aDiscountCodeType.value}.code`, discountCodes)}
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <ActionButton
                                            testId={aDiscountCodeType.label}
                                            className="ml-1"
                                            primary={false}
                                            onClick={this.onChangePassCode(aDiscountCodeType.value)}
                                        >
                                            Change
                                        </ActionButton>
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                </Grid>
                {
                    discountCodeEditing &&
                    <DialogComponent
                        open
                        header="Add/Edit Discount Code"
                        handleClose={this.closeEditScreen}
                    >
                        <DiscountCodeForm
                            discountCode={discountCodeEditing}
                            handleClose={this.closeEditScreen}
                            dispatch={dispatch}
                        />
                    </DialogComponent>
                }
            </Grid>
        );
    }
}

OtherSettings.propTypes = {
    dispatch: PropTypes.func,
    updateDialogActions: PropTypes.func.isRequired,
    discountManagementConfig: PropTypes.object,
};

OtherSettings.defaultProps = {
    dispatch: () => {},
    discountManagementConfig: null,
};

const mapStateToProps = state => ({
    discountManagementConfig:
        getStringFromObject('appConfiguration.discountManagement', state),
});

export default connect(mapStateToProps)(OtherSettings);

