import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_INTERNAL_MOVE_FAILURE,
    CREATE_INTERNAL_MOVE_SUCCESS,
    FETCH_INTENT_FAILURE,
    FETCH_INTENT_SUCCESS,
    REVERSE_TRANSFER_SUCCESS,
    REVERSE_TRANSFER_FAILURE,
} from '../../redux/modules/internalMoves/internalMoves-actions';
import { INTERNAL_MOVE_OPERATION } from '../../mapper/InternalMovesMapper';
import { FETCH_STOCK_MOVE_SUCCESS } from '../../redux/modules/stockMove/stockMove-actions';

export function* createInternalMovesWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_INTERNAL_MOVE_SUCCESS,
            data: response.data,
        });
        // added to set stock move state
        yield put({
            type: FETCH_STOCK_MOVE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        if (action.payload.status === INTERNAL_MOVE_OPERATION.SAVE) {
            const msg = action.payload.uuid ? messages.INTERNAL_MOVE.UPDATE.SUCCESS :
                messages.INTERNAL_MOVE.CREATE.SUCCESS;
            yield put({ type: SUCCESS_MESSAGE, message: msg });
        } else {
            yield put({ type: SUCCESS_MESSAGE, message: messages.INTERNAL_MOVE.CONFIRM.SUCCESS });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = '';
            if (action.payload.operation === INTERNAL_MOVE_OPERATION.SAVE) {
                msg = action.payload.uuid ? messages.INTERNAL_MOVE.UPDATE.FAILED :
                    messages.INTERNAL_MOVE.CREATE.FAILED;
            } else {
                msg = messages.INTERNAL_MOVE.CONFIRM.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_INTERNAL_MOVE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchIntentWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_INTENT_SUCCESS,
            data: response.data,
        });
        // added to set stock move state
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        // if (action.payload.status === INTERNAL_MOVE_OPERATION.SAVE) {
        //     const msg = action.payload.uuid ? messages.INTERNAL_MOVE.UPDATE.SUCCESS :
        //         messages.INTERNAL_MOVE.CREATE.SUCCESS;
        //     yield put({ type: SUCCESS_MESSAGE, message: msg });
        // } else {
        //     yield put({ type: SUCCESS_MESSAGE, message: messages.INTERNAL_MOVE.CONFIRM.SUCCESS });
        // }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = '';
            if (action.payload.operation === INTERNAL_MOVE_OPERATION.SAVE) {
                msg = action.payload.uuid ? messages.INTERNAL_MOVE.UPDATE.FAILED :
                    messages.INTERNAL_MOVE.CREATE.FAILED;
            } else {
                msg = messages.INTERNAL_MOVE.CONFIRM.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_INTENT_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* reverseTransferWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: REVERSE_TRANSFER_SUCCESS,
            data: response.data,
        });
        // added to set stock move state
        yield put({
            type: FETCH_STOCK_MOVE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        const msg = messages.INTERNAL_MOVE.REVERSE.SUCCESS;
        yield put({ type: SUCCESS_MESSAGE, message: msg });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else {
            const msg = messages.INTERNAL_MOVE.REVERSE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: REVERSE_TRANSFER_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
