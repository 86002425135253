import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../constants/CommonUtil';
import ReactSelectMaterial from './ReactSelectMaterial/ReactSelectMaterial';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

class OutlineSelectWithArrow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
    }

    getValueIndexFromOptions = () => {
        const { value } = this.state;
        const { options, dataSourceConfig } = this.props;
        if (isArrayValidAndNotEmpty(options) && isObjectValidAndNotEmpty(value)) {
            return options.findIndex((o) => {
                if (isObjectValidAndNotEmpty(dataSourceConfig)) {
                    const optionKey = getStringFromObject(dataSourceConfig.value, o, null);
                    const valueKey = getStringFromObject(dataSourceConfig.value, value, null);
                    if (optionKey != null && valueKey != null) {
                        return deepEqual(optionKey, valueKey);
                    }
                    return false;
                }
                return deepEqual(o, value);
            });
        }
        return -1;
    };

    handleValueChange = (value) => {
        this.setState({ value }, () => {
            const { handleChange } = this.props;
            if (isValidFunction(handleChange)) {
                handleChange(value);
            }
        });
    };

    handleArrowLeft = () => {
        const index = this.getValueIndexFromOptions();
        if (index === 0) {
            if (isValidFunction(this.props.getPrevOptions)) {
                this.props.getPrevOptions();
            }
        } else if (index > -1) {
            this.handleValueChange(this.props.options[index - 1]);
        }
    };

    handleArrowRight = () => {
        /* put condition if period is not than max size */
        const index = this.getValueIndexFromOptions();
        const { options, getNextOptions } = this.props;

        if (index === (options.length - 1)) {
            if (isValidFunction(getNextOptions)) {
                getNextOptions();
            }
        } else if (index > -1) {
            this.handleValueChange(options[index + 1]);
        }
    };

    render() {
        const {
            options,
            label,
            dataSourceConfig,
            hideLeftArrow,
            hideRightArrow,
            disabled,
        } = this.props;
        const { value } = this.state;
        console.log('ahdjkadla', value);
        return (
            <Grid container alignItems="center" spacing={8}>
                <Grid item lg={1} md={1}>
                    {
                        !hideLeftArrow &&
                        <Tooltip title="previous">
                            <ArrowLeft
                                test-id="arrow-left"
                                onClick={this.handleArrowLeft}
                                className="cursor-pointer"
                                style={{
                                    fontSize: '2.5em',
                                }}
                            />
                        </Tooltip>
                    }
                </Grid>
                <Grid item lg={10} md={10}>
                    <ReactSelectMaterial
                        testId={label}
                        dataSourceConfig={dataSourceConfig}
                        selectProps={{
                            textFieldProps: {
                                InputProps: {
                                    disableUnderline: true,
                                },
                                variant: 'outlined',
                            },
                        }}
                        onChange={this.handleValueChange}
                        label={label}
                        options={options}
                        value={value}
                        isDisabled={disabled}
                    />
                </Grid>
                <Grid item lg={1} md={1}>
                    {
                        !hideRightArrow &&
                        <Tooltip title="next">
                            <ArrowRight
                                test-id="arrow-right"
                                onClick={this.handleArrowRight}
                                className="cursor-pointer"
                                style={{
                                    fontSize: '2.5em',
                                }}
                            />
                        </Tooltip>
                    }
                </Grid>
            </Grid>
        );
    }
}

OutlineSelectWithArrow.propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    getNextOptions: PropTypes.func,
    getPrevOptions: PropTypes.func,
    value: PropTypes.number,
    dataSourceConfig: PropTypes.object,
    hideLeftArrow: PropTypes.bool,
    hideRightArrow: PropTypes.bool,
    disabled: PropTypes.bool,
};

OutlineSelectWithArrow.defaultProps = {
    value: null,
    hideLeftArrow: false,
    hideRightArrow: false,
    disabled: false,
    dataSourceConfig: {},
    getNextOptions: () => {},
    getPrevOptions: () => {},
};

export default OutlineSelectWithArrow;
