import API from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';
import { policyListSearchConstruct } from '../../../components/PayerComponents/PayerList/PayerListUtil';

/**
 * Created by sachinprakash on 12/8/19.
 */
export const FETCH_POLICY_VIEW_LIST_REQUEST = '@@payors/FETCH_POLICY_VIEW_LIST_REQUEST';
export const FETCH_POLICY_VIEW_LIST_FAILURE = '@@payors/FETCH_POLICY_VIEW_LIST_FAILURE';
export const FETCH_POLICY_VIEW_LIST_SUCCESS = '@@payors/FETCH_POLICY_VIEW_LIST_SUCCESS';

export const fetchPagesOfPolicyViews = (page, size, sortCol, sortOrder, filters = {}) => ({
    type: FETCH_POLICY_VIEW_LIST_REQUEST,
    api: getUrlWithApiParams(API.PAYORS.FETCH_POLICY_VIEW, {
        page,
        size,
        sortCol,
        sortOrder,
    }),
    payload: policyListSearchConstruct(filters),
    filters,
    page,
    size,
    sortCol,
    sortOrder,
    successAction: FETCH_POLICY_VIEW_LIST_SUCCESS,
    failureMessage: 'Failed to fetch policies',
    failureAction: FETCH_POLICY_VIEW_LIST_FAILURE,
});
