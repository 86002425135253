import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import DialogComponent from '../../components/DialogComponent/DialogComponent';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import API from '../../constants/api';
import {
    setRegistrationShop,
    toggleRegistrationShopSelect,
    setNurseShop,
} from '../../redux/modules/registrationShop/registrationShop-actions';
import { frontDeskRoutes } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class RegistrationSaleShopSelect extends React.Component {
    onChange = (shop) => {
        const {
            locationPath,
        } = this.props;
        let actionToDispatch = null;
        if (frontDeskRoutes.includes(locationPath)) {
            actionToDispatch = setRegistrationShop;
        } else {
            actionToDispatch = setNurseShop;
        }

        if (isObjectValidAndNotEmpty(shop)) {
            this.props.dispatch(actionToDispatch(shop, true));
        } else {
            this.props.dispatch(actionToDispatch(shop, false));
        }
    };
    onClose = () => {
        this.props.dispatch(toggleRegistrationShopSelect());
    };

    render() {
        const {
            registrationShop,
            receptionParentShop,
            locationPath,
            nurseParentShop,
        } = this.props;
        let shop = null;
        let dataSourceApi = null;
        if (frontDeskRoutes.includes(locationPath)) {
            shop = getStringFromObject('shop', registrationShop);
            dataSourceApi = `${API.SHOP.GET_REGISTRATION_DETAILS}&parent=${receptionParentShop}`;
        } else {
            shop = getStringFromObject('nurseShop', registrationShop);
            dataSourceApi = `${API.SHOP.GET_SHOPS}?parent=${nurseParentShop}`;
        }
        const dialogOpen = getStringFromObject('openSelect', registrationShop);
        return (
            <DialogComponent
                header="Select Your Location"
                open={dialogOpen}
                handleClose={this.onClose}
            >
                <Grid container className="mt-1" justify="space-around">
                    <ReactSelectMaterial
                        testId="registration-sale-shop-selector"
                        key={dataSourceApi}
                        dataSourceApi={dataSourceApi}
                        dataSourceConfig={{
                            text: 'shopName',
                            value: 'shopId',
                        }}
                        autoFocus
                        value={shop}
                        onChange={this.onChange}
                    />
                </Grid>
            </DialogComponent>
        );
    }
}

RegistrationSaleShopSelect.propTypes = {
    registrationShop: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    receptionParentShop: PropTypes.string,
    nurseParentShop: PropTypes.string,
    locationPath: PropTypes.string,
};

RegistrationSaleShopSelect.defaultProps = {
    registrationShop: {},
    receptionParentShop: null,
    nurseParentShop: null,
    locationPath: null,
};

const mapStateToProps = state => ({
    registrationShop: state.registrationShop,
    receptionParentShop:
        getStringFromObject('appConfiguration.receptionParentShop', state),
    nurseParentShop:
        getStringFromObject('appConfiguration.nurseParentShop', state),
});

export default connect(mapStateToProps)(RegistrationSaleShopSelect);

