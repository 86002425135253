import API from '../../../constants/api';

export const FETCH_AGING_REPORT_REQUEST = '@@aging/FETCH_AGING_REPORT_REQUEST';
export const FETCH_AGING_REPORT_FAILURE = '@@aging/FETCH_AGING_REPORT_FAILURE';
export const FETCH_AGING_REPORT_SUCCESS = '@@aging/FETCH_AGING_REPORT_SUCCESS';

export const FETCH_PAYOR_INVOICES_REQUEST = '@@aging/FETCH_PAYOR_INVOICES_REQUEST';
export const FETCH_PAYOR_INVOICES_SUCCESS = '@@aging/FETCH_PAYOR_INVOICES_SUCCESS';
export const FETCH_PAYOR_INVOICES_FAILURE = '@@aging/FETCH_PAYOR_INVOICES_FAILURE';

export const CLEAR_AGING_REPORT_REQUEST = '@@aging/CLEAR_AGING_REPORT_REQUEST';

export const fetchAgingReport = (page, size, filters, agingInvoicePayerType, subCompany) => ({
    type: FETCH_AGING_REPORT_REQUEST,
    api: API.REPORT.GET_AGING_REPORT_PAYER,
    page,
    size,
    filters,
    agingInvoicePayerType,
    subCompany,
});

export const fetchInvoicesForPayor = payorId => ({
    type: FETCH_PAYOR_INVOICES_REQUEST,
    api: API.REPORT.GET_PAYOR_INVOICES,
    payorId,
});

export const clearAgingReport = () => ({
    type: CLEAR_AGING_REPORT_REQUEST,
});
