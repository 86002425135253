/**
 * Created by vinay on 19/12/18.
 */
import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_REQUEST,
    FETCH_SLOT_TEMPLATE_LIST_REQUEST,
    FETCH_SLOT_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_REQUEST,
} from '../../redux/modules/appointmentSlotTemplate/appointmentSlotTemplate-actions';
import {
    createOrUpdateTemplateWorker,
    deleteTemplateWorker,
    fetchTemplateListWorker,
    fetchTemplateWorker,
} from './appointmentSlotTemplateWorkerSaga';

export function* fetchSlotTemplateWatcher() {
    yield takeLatest(FETCH_SLOT_TEMPLATE_REQUEST, fetchTemplateWorker);
}

export function* fetchSlotTemplateListWatcher() {
    yield takeLatest(FETCH_SLOT_TEMPLATE_LIST_REQUEST, fetchTemplateListWorker);
}

export function* createTemplateWatcher() {
    yield takeLatest(CREATE_TEMPLATE_REQUEST, createOrUpdateTemplateWorker);
}

export function* updateTemplateWatcher() {
    yield takeLatest(UPDATE_TEMPLATE_REQUEST, createOrUpdateTemplateWorker);
}

export function* deleteTemplateWatcher() {
    yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplateWorker);
}
