import { takeLatest } from 'redux-saga/effects';
import {
    createOrUpdateCashPaymentVoucherWorker,
    fetchCashPaymentVoucherWorker,
    fetchDebitAndCreditLinesWorker,
} from './cashPaymentVoucherWorker';
import {
    CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST, FETCH_CASH_PAYMENT_VOUCHER_REQUEST,
    FETCH_DEBIT_AND_CREDIT_LINES_REQUEST,
} from '../../redux/modules/cashPaymentVoucher/cashPaymentVoucher-actions';

export function* fetchDebitAndCreditLinesWatcher() {
    yield takeLatest(FETCH_DEBIT_AND_CREDIT_LINES_REQUEST, fetchDebitAndCreditLinesWorker);
}

export function* createOrUpdateCashPaymentVoucherWatcher() {
    yield takeLatest(CREATE_OR_UPDATE_CASH_PAYMENT_VOUCHER_REQUEST, createOrUpdateCashPaymentVoucherWorker);
}

export function* fetchCashPaymentVoucherWatcher() {
    yield takeLatest(FETCH_CASH_PAYMENT_VOUCHER_REQUEST, fetchCashPaymentVoucherWorker);
}
