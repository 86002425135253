import { Grid } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import api from '../../constants/api';
import { apiCatchBlockFunction, getJsonPath } from '../../constants/CommonUtil';
import { PAGE, SIZE } from '../../constants/constants';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class SanityCheckPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            data: [],
            page: PAGE,
            size: SIZE,
            totalElements: 0,
        };
    }

    componentDidMount() {
        axios.get(getJsonPath('/AccountsSanityCheck/AccountsSanityCheckSchema.json'))
            .then((response) => {
                this.setState({
                    schema: response.data,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        this.fetchSanityChecksData();
    }

    fetchSanityChecksData = async () => {
        const { page, size } = this.state;
        try {
            const response =
                await axios.get(`${api.ACCOUNT_HEAD.GET_SANITY_CHECK_DATA}?page=${page}&size=${size}`);
            this.setState({
                data: response.data.content,
                totalElements: response.data.totalElements,
            });
            console.log('thetheresponse', response);
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
    }

    handlePageChange = (page, size) => {
        console.log('page change', page);
        this.setState({ page, size }, this.fetchSanityChecksData);
    };

    render() {
        const { schema, data, totalElements } = this.state;
        return (
            <div style={{
                width: '50em', minHeight: '15em', justifyContent: 'space-around',
            }}
            >
                <Grid container>
                    <div className="p2" style={{ background: '#fafafa' }}>
                        <Grid
                            container
                            style={{
                                fontSize: '1.1rem', fontWeight: 400, width: '50em', textAlign: 'center',
                            }}
                        >
                            Sanity Check Failures for Accounts
                        </Grid>
                    </div>
                    {
                        isObjectValidAndNotEmpty(schema) ? (
                            <div style={{ padding: '15px', width: '50em' }}>
                                <MaterialTable
                                    schema={schema}
                                    data={data}
                                    size={this.state.size}
                                    totalElements={totalElements}
                                    onPageChange={this.handlePageChange}
                                    rowsPerPageOptions={[5, 10, 25, 40]}
                                />
                            </div>
                        ) : (
                            <div
                                className="flexChildren justify-content-center"
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '65%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '1.1rem',
                                    fontWeight: 300,
                                }}
                            >
                                No accounts failed sanity check !!
                            </div>
                        )
                    }
                </Grid>
            </div>
        );
    }
}

SanityCheckPopover.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(SanityCheckPopover);
