import { PAGE, SIZE, SORT_COL, SORT_ORDER, SORT_ORDER_DESC } from '../constants/constants';
import { formatDate, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { approvalQueueTabs } from '../containers/ApprovalQueueListingContainer/ApprovalQueueListingContainerUtil';
import { PAYMENT_ADVICE_STATES } from '../components/HrPayrollComponent/payrollUtils';
import { ATTENDANCE_DEFAULT_SORT_COL } from '../components/Attendance/AttendanceListUtil';
import { DATE_FILTERS } from '../components/Common/ReportDateFilterUtil';
import { ORDER_TYPE_DATE_FILTERS, getStartAndEndDateFromOrderTypeDateFilter } from '../constants/CommonUtil';
import { claimSearchTypes } from '../components/PayerComponents/ClaimsList/ClaimsListUtil';

const initialStates = {
    packageDetails: {
        isLoading: false,
    },
    visitDetails: {
        data: {},
        printable: false,
        loading: false,
        scannedInitialValues: null,
    },
    patientDetails: { data: {}, editable: true, loading: false },
    search: {},
    doctorQList: {
        qList: [],
    },
    holidays: {
        list: [],
        yearData: {},
        isLast: false,
        page: 0,
        size: 10,
        upcomingHolidays: [],
        isLoading: false,
        isCreated: false,
    },
    appConfiguration: {},
    workflow: {
        data: {},
        editable: true,
        isLoading: false,
        isUpdated: false,
    },
    inbox: {
        isLoading: false,
    },
    profile: {
        currentUserData: {},
        otherUserData: {},
        profilePictureUploaded: false,
        isLoading: false,
        isUpdated: false,
        calendarDetails: {},
    },
    asset: {
        list: [],
        filters: {},
        page: 0,
        isLast: false,
        isLoading: false,
        totalElements: 0,
        size: 10,
    },
    assetCategory: {
        list: [],
        filters: {},
        page: 0,
        isLast: false,
        isLoading: false,
        totalElements: 0,
        size: 10,
    },
    assetManufacturer: {
        list: [],
        filters: {},
        page: 0,
        isLast: false,
        isLoading: false,
        totalElements: 0,
        size: 10,
    },
    addManufacturer: {
        name: '',
        id: '',
        retired: '',
    },
    addAssetCategory: {},
    prepaidExpense: {
        selected: {},
    },
    announcements: {
        list: [],
        currentAnnouncement: {},
        isAdded: false,
    },
    patients: {
        active: {
            page: PAGE,
            size: SIZE,
            data: [],
            isLast: false,
            totalPages: 0,
        },
        recent: {
            page: PAGE,
            size: SIZE,
            data: [],
            isLast: false,
            totalPages: 0,
        },
        allPatients: {
            page: PAGE,
            size: SIZE,
            data: [],
            isLast: false,
            totalPages: 0,
            sortDir: 'desc',
            sortCol: 'patientId',
        },
    },
    leaves: {
        leaveBalance: [],
        manageLeaves: [],
        availedLeaves: [],
        employeePlans: [],
        isLoading: false,
    },
    payroll: {
        periods: [],
        payrollBatch: {
            empByDept: [],
            dept: null,
            batches: [],
            batchDetail: {},
            isLast: false,
            totalPages: 0,
            page: PAGE,
            size: SIZE,
            sortOrder: 'DESC',
            sortCol: 'id',
            filters: {},
            totalElements: 0,
        },
        paymentAdvice: {
            list: [],
            advice: {},
            filters: {
                status: PAYMENT_ADVICE_STATES[0],
            },
            isLast: false,
            totalPages: 0,
            page: PAGE,
            size: SIZE,
            totalElements: 0,
        },
        payslips: {
            data: [],
            isLast: false,
            totalPages: 0,
            page: PAGE,
            size: SIZE,
            totalElements: 0,
            filters: {},
            selectedPayslip: {},
        },
        empPayslipInput: {},
        payrollReport: {
            data: [],
        },
    },
    dashboard: {
        calendar: {
            employeeLeaves: [],
            holidays: [],
        },
    },
    employeePayroll: {
        payrollDetails: {},
        payslipDetails: {},
        isPayslipFetched: false,
        contractDate: getDateInYYYYMMDDFormat(new Date()),
    },
    organization: {
        list: [],
        isLast: false,
        searchText: '',
        positionSelectText: '',
        departmentSelectText: '',
        empType: 'allEmployees',
        showDeactivated: false,
        isFirst: true,
        totalPages: 0,
        page: PAGE,
        size: SIZE,
    },
    visitQueue: {
        list: [],
        filters: {
            searchString: '',
            showCompleted: false,
        },
        content: [],
        isLast: false,
        isFirst: true,
        totalPages: 0,
        page: PAGE,
        size: SIZE,
    },
    attendance: {
        edit: false,
        selectedAttendance: null,
        periods: [],
        data: {},
        isLoading: false,
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: ATTENDANCE_DEFAULT_SORT_COL,
        sortOrder: SORT_ORDER_DESC,
        totalElements: 0,
        filters: {
            status: 'DRAFT',
        },
    },
    advanceSearch: {
        totalElements: 0,
        isLoading: false,
        tableJson: {},
        allCategories: [],
        category: '',
        filters: {},
        dateFilters: {
            selected: 'Today',
            fromDate: new Date(),
            toDate: new Date(),
        },
        list: [],
        page: PAGE,
        size: 10,
    },
    purchaseOrder: {
        selected: {},
    },
    addStock: {
        selected: {},
    },
    accountTransfer: {
        selected: {},
    },
    advancePayment: {
        selected: null,
    },
    salaryAdvance: {
        selected: null,
    },
    pettyCash: {
        selected: {},
    },
    internalMoves: {
        selected: {},
        intentData: {},
    },
    stockMove: {
        stockMovesByPurchaseOrder: [],
        selected: [],
    },
    stockIntent: {
        stockIntent: [],
        selected: [],
    },
    materialReceive: {
        materialReceive: {},
    },
    batches: {
        isLoading: false,
        filters: {
            fromDate: '',
            toDate: '',
            searchString: '',
            location: '',
            hideZeroBatches: true,
        },
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'stock_quant.quantity',
        sortOrder: 'desc',
        totalElements: 0,
    },
    claimsAndBundles: {
        isLoading: false,
        list: [{}],
        bundlePayers: {},
        page: PAGE,
        size: SIZE,
        sortCol: '',
        sortOrder: 'desc',
        totalElements: 0,
        filter: {
            searchType: claimSearchTypes.CLAIM,
        },
        downloadTask: {},
        payerCollectionFilter: {
            payer: null,
            period: {
                key: DATE_FILTERS.THIS_FINANCIAL_YEAR,
                value: DATE_FILTERS.THIS_FINANCIAL_YEAR,
            },
        },
    },
    policyView: {
        isLoading: false,
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'name',
        sortOrder: 'asc',
        totalElements: 0,
    },
    insuranceSchemeView: {
        isLoading: false,
        list: [{}],
        page: PAGE,
        size: SIZE,
        sortCol: 'name',
        sortOrder: SORT_ORDER,
        totalElements: 0,
    },
    payors: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortOrder: 'ASC',
        sortCol: 'name',
        totalElements: 0,
        selectedPayor: {},
        categoryList: [],
        policyList: [],
        parentPayor: {},
    },
    subinsurers: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortOrder: 'ASC',
        sortCol: 'name',
        totalElements: 0,
        selectedPayor: {},
        categoryList: [],
        policyList: [],
        parentPayor: {},
    },
    productCategory: {
        isLoading: false,
        filters: {
            searchString: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        selectedCategory: {},
    },
    accounts: {
        isLoading: false,
        filters: {
            searchString: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        sortOrder: 'DESC',
        sortCol: 'id',
        selectedAccount: {},
    },
    analyticalAccounts: {
        isLoading: false,
        filters: {
            searchString: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        sortOrder: 'DESC',
        sortCol: 'id',
    },
    supplier: {
        isLoading: false,
        filters: {
            searchString: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
    },
    journalEntries: {
        isLoading: false,
        filters: {
            searchString: '',
            draftEntries: true,
        },
        list: [{}, {}],
        page: PAGE,
        size: SIZE,
        sortCol: SORT_COL,
        sortOrder: SORT_ORDER_DESC,
        totalElements: 0,
    },
    products: {
        isLoading: false,
        filters: {
            name: '',
            active: false,
        },
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'id',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedProduct: {},
    },
    labTests: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    genericList: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    labPanels: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    labDepartment: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    labSamples: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    labDictionary: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    labUom: {
        isLoading: false,
        filters: {},
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'conceptId',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedTests: {},
    },
    consumables: {
        isLoading: false,
        filters: {
            name: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'id',
        sortOrder: 'DESC',
        totalElements: 0,
        selectedProduct: {},
    },
    cashClose: {
        selected: null,
    },
    myCashClose: {
        list: [],
    },
    consolidatedCashClose: {
        selected: null,
    },
    accountInvoice: {
        selectedInvoice: [],
    },
    accountExpense: {
        selectedExpense: null,
    },
    receivePayment: {
        selectedInvoice: [],
    },
    accountMove: {
        selectedAccountMove: {},
    },
    payment: {
        selectedVoucher: {},
    },
    departmentService: {
        departmentList: [],
        serviceList: [],
    },
    appointmentSlot: {
        appointmentTypes: [],
        serviceWeek: {},
        providerWeek: {},
    },
    slotTemplate: {
        list: [],
        selected: {},
    },
    appointment: {
        appointmentSlots: {},
    },
    appointmentList: {
        page: PAGE,
        size: SIZE,
        sortCol: 'appointmentDate',
        sortOrder: 'ASC',
        list: [],
        filters: {
            fromDate: formatDate(new Date(), 'yyyy-MM-dd'),
            toDate: formatDate(new Date(), 'yyyy-MM-dd'),
            doctor: [],
            status: '',
        },
    },
    recallList: {
        page: PAGE,
        size: SIZE,
        sortCol: 'recallDate',
        sortOrder: 'ASC',
        list: [],
        filters: {
            fromDate: '',
            toDate: '',
            doctor: [],
            searchText: '',
        },
    },
    appointmentDetails: {
        selected: {},
    },
    transaction: {
        isLoading: false,
        filters: {
            type: '',
        },
        list: [],
        page: PAGE,
        size: SIZE,
        sortCol: 'id',
        sortOrder: 'DESC',
        totalElements: 0,
    },
    agingReport: {
        isLoading: false,
        list: [],
        isLast: false,
        totalPages: 0,
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        filters: null,
        invoices: {},
    },
    packageList: {
        isLoading: false,
        list: [],
        isLast: false,
        totalPages: 0,
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        filters: null,
        invoices: {},
    },
    medicalPackages: {
        isLoading: false,
        list: [],
        isLast: false,
        totalPages: 0,
        page: PAGE,
        size: SIZE,
        totalElements: 0,
        searchString: '',
    },
    approvalQueue: {
        isLoading: false,
        list: [{}],
        page: PAGE,
        size: SIZE,
        sortCol: '',
        sortOrder: 'desc',
        totalElements: 0,
        approvalType: approvalQueueTabs.request.value,
        onlyClinical: null,
        additionalFilters: [],
        last: true,
        first: true,
        filters: {
            datePeriod: { key: 'LAST_30_DAYS', value: ORDER_TYPE_DATE_FILTERS.LAST_30_DAYS },
        },
    },
    insuranceInvoice: {
        insuranceInvoiceList: [],
        insuranceInvoiceLine: [],
        isLast: true,
        insuranceInvoiceDetails: {},
        filters: {},
        page: PAGE,
        size: SIZE,
        isInsuranceInvoiceSearch: false,
    },
    order: {
        patient: {},
        orderDetails: [],
        sendToBilling: false,
    },
    productService: {
        selected: {},
    },
    orderableService: {
        selected: {},
    },
    tests: {
        data: {},
    },
    uom: {
        data: {},
    },
    department: {
        data: {},
    },
    panel: {
        data: {},
    },
    sample: {
        data: {},
    },
    dictionary: {
        data: {},
    },
    template: {
        data: {},
    },
    insuranceRequests: {
        page: PAGE,
        size: SIZE,
        filters: {
            datePeriod: {
                period: 'Past 30 Days',
                startDate: getStartAndEndDateFromOrderTypeDateFilter('Past 30 Days').startDate,
                endDate: getStartAndEndDateFromOrderTypeDateFilter('Past 30 Days').endDate,
            },
        },
    },
    insuranceRequestLines: {
        page: PAGE,
        size: SIZE,
        filters: {
            datePeriod: {
                period: 'Past 30 Days',
                startDate: getStartAndEndDateFromOrderTypeDateFilter('Past 30 Days').startDate,
                endDate: getStartAndEndDateFromOrderTypeDateFilter('Past 30 Days').endDate,
            },
        },
    },
    genericAddData: {
        data: {},
    },
    favouriteReports: {
        list: [],
    },
    bedType: {
        list: [],
        selectedBedType: {},
    },
    paidOnAccount: {
        selectedPaidOnAccount: null,
    },
    previousPurchaseInvoiceLines: {
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
    },
    cashPaymentVoucher: {
        debitLines: [],
        creditLines: [],
        partner: {},
    },
    debitAndCreditLines: {
        debitLines: [],
        creditLines: [],
        partner: {},
    },
    accountVoucher: {
        list: [],
        first: false,
        last: false,
        page: PAGE,
        size: SIZE,
        forwardSeekMap: null,
        backwardSeekMap: null,
    },
    accountVoucherTable: {
        list: [],
        first: false,
        last: false,
        page: PAGE,
        size: SIZE,
        forwardSeekMap: null,
        backwardSeekMap: null,
    },
    encounterOrders: {
        list: [],
        page: PAGE,
        size: SIZE,
        totalElements: 0,
    },
    productDispatch: {
        list: [],
        page: PAGE,
        size: SIZE,
    },
};

export default initialStates;
