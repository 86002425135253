import API from '../../../../constants/api';
import { getUrlWithApiParams } from '../../../../constants/CommonUtil';

export const FETCH_POLICY_LIST_REQUEST = '@@payors/FETCH_POLICY_LIST_REQUEST';
export const FETCH_POLICY_LIST_SUCCESS = '@@payors/FETCH_POLICY_LIST_SUCCESS';
export const FETCH_POLICY_LIST_FAILURE = '@@payors/FETCH_POLICY_LIST_FAILURE';

export const CLEAR_POLICY_STATE = '@@payors/CLEAR_POLICY_STATE';

export const fetchPolicyList = (payload, page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_POLICY_LIST_REQUEST,
    api: getUrlWithApiParams(API.PAYORS.FETCH_POLICIES, {
        page,
        size,
        sortCol,
        sortOrder,
    }),
    payload,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const clearPolicyState = () => ({
    type: CLEAR_POLICY_STATE,
});
