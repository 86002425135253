/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Close, Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getHideUserMessage } from '../constants/state';

class UserMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            animationDuration: 10,
        };
    }

    componentDidMount() {
        // TODO: getHideUserMessage() is string, change to boolean
        this.setState({ open: !getHideUserMessage() });
        this.updateAnimationDuration();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message) {
            this.updateAnimationDuration();
        }
    }


    closeMessage = () => {
        this.setState({ open: false });
        // persistHideUserMessage();
    };

    updateAnimationDuration() {
        const contentLength = this.props.message.length;

        const speedPerCharacter = 0.2;
        const animationDuration = Math.max((contentLength * speedPerCharacter), 20);

        this.setState({ animationDuration });
    }

    render() {
        const { open, animationDuration } = this.state;
        const { message } = this.props;
        return (
            <div>
                {
                    open
                    && (
                        <Grid container justify="space-between" className="user-message" alignItems="center">
                            <Grid item sm={11} container className="user-message-content" spacing={16}>
                                <Grid item>
                                    <Warning color="warning" />
                                </Grid>
                                <Grid item sm={11} style={{ textWrap: 'nowrap' }}>
                                    <div
                                        style={{
                                            animation: `marquee ${animationDuration}s linear infinite`,
                                        }}
                                    >
                                        {message.toUpperCase()}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={this.closeMessage}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                }
            </div>
        );
    }
}

UserMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

export default UserMessage;
