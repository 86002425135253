import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { commonSettings } from '../constants/constants';
import AppSettingsContainer from '../containers/AppSettingsContainer/AppSettingsContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const CommonSettingsPage = React.lazy(() => import('../pages/AppSettingsPages/CommonSettingsPage'));

const AppSettingsRoutes = props => (
    <AppSettingsContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={commonSettings} exact component={CommonSettingsPage} />
        </Suspense>
    </AppSettingsContainer>
);

export default AppSettingsRoutes;
