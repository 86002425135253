import memoizeOne from 'memoize-one';
import {
    getRoundOffValue,
    isArrayValidAndNotEmpty,


} from '../../../../constants/CommonUtil';
import { registrationBillingTableFields } from '../RegistrationBillingTable/RegistrationBillingTableUtil';
import { insuranceRequestStates } from '../../../ApprovalQueueListingContainer/ApprovalQueueListingContainerUtil';
import { insuranceStates, payerTypes } from '../QuickRegistrationForm/QuickRegistrationFormUtil';
import { NumberOf } from '../../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../../constants/nullCheckUtils';

const discountTypes = {
    PRIVILEGE_DISCOUNT: {
        label: 'PRIVILEGE CARD',
        value: 'PRIVILEGE_DISCOUNT',
        cardEntryLabel: 'Privilege Card Number',
    },
    ADDITIONAL_DISCOUNT: {
        label: 'ADDITIONAL DISCOUNT',
        value: 'ADDITIONAL_DISCOUNT',
        cardEntryLabel: 'Coupon Number',
    },
    OFFER_DISCOUNT: {
        label: 'OFFER DISCOUNT',
        value: 'OFFER_DISCOUNT',
        cardEntryLabel: 'Code',
    },
};

export const discountCodeTypes = {
    FIXED: 'FIXED',
    FLEXIBLE: 'FLEXIBLE',
};

const calculateBillSummaryDetailsFunc = (billingLines, otherDiscounts, patientExemptedFromTax, payerType, showTaxExemptForCreditBill) => {
    console.log('sad-sadus-adus0au9ds-ada', billingLines);
    let grossAmount = 0;
    let grossAmountWithoutDiscount = 0;
    let discountAmount = 0;
    let patientAmount = 0;
    let vatAmount = 0;
    let exemptTaxAmount = 0;
    const previousAmount = 0;
    let netAmount = 0;
    if (isArrayValidAndNotEmpty(billingLines)) {
        for (let i = 0; i < billingLines.length; i += 1) {
            const billingLine = billingLines[i];
            if (
                isObjectValidAndNotEmpty(billingLine) &&
                isObjectValidAndNotEmpty(billingLine[registrationBillingTableFields.PRODUCT_NAME])
            ) {
                // line total exclusive of tax
                grossAmount += NumberOf(billingLine.amount);
                // line discount
                const price = NumberOf(billingLine[registrationBillingTableFields.PRICE]);
                const qty = NumberOf(billingLine[registrationBillingTableFields.QUANTITY]);

                discountAmount += (NumberOf(billingLine[registrationBillingTableFields.DISCOUNT_AMOUNT]) * qty);
                grossAmountWithoutDiscount += (price * qty);
                // copay amt
                patientAmount += (NumberOf(billingLine[registrationBillingTableFields.COPAY_AMOUNT]) * qty);
                // tax on copay amt
                const taxForLine = NumberOf(billingLine[registrationBillingTableFields.VAT_AMOUNT]);
                vatAmount += taxForLine;
                if (
                    patientExemptedFromTax && billingLine.productExemptedFromTax &&
                    (payerType === payerTypes.COMPANY ? showTaxExemptForCreditBill : true)
                ) {
                    exemptTaxAmount += taxForLine;
                }
                // copay amt + tax on copay amt
                netAmount += NumberOf(billingLine[registrationBillingTableFields.TOTAL]);
            }
        }
    }
    const finalNetAmount = netAmount - NumberOf(otherDiscounts) - exemptTaxAmount;
    return {
        grossAmount,
        discountAmount,
        patientAmount,
        vatAmount,
        previousAmount,
        grossAmountWithoutDiscount,
        exemptTaxAmount,
        roundOffAmount: payerType !== payerTypes.COMPANY ? getRoundOffValue(finalNetAmount) : 0,
        netAmount: finalNetAmount,
    };
};

export const calculateBillSummaryDetails = memoizeOne(calculateBillSummaryDetailsFunc);

export const billingCashReceivedField = 'billSummary.cashReceived';
export const billingPaymentModeField = 'billSummary.paymentMode';
export const billingMultiplePaymentField = 'billSummary.multiplePayment';
export const billingAmountPaidByCard = 'billSummary.amountPaidByCard';
export const billingOtherPaymentMode = 'billSummary.otherPaymentMode';
export const billingAmountPaidByOtherMode = 'billSummary.amountPaidByOtherMode';

export const billingAmountReceivedFieldByPaymentMode = {
    CASH: billingCashReceivedField,
    CARD: billingAmountPaidByCard,
    OTHERS: billingAmountPaidByOtherMode,
};

export const doesBillingHaveRejectedEntriesOrNotCoveredItems = memoizeOne((billingValues) => {
    console.log('asdaopjda', billingValues);
    let rejectedItems = false;
    if (isArrayValidAndNotEmpty(billingValues)) {
        const rejectedLines =
            billingValues.filter((aRow) => {
                if (isObjectValidAndNotEmpty(aRow[registrationBillingTableFields.PRODUCT_NAME])) {
                    return (
                        aRow[registrationBillingTableFields.INS_ACTION] === insuranceRequestStates.REJECTED ||
                        [
                            insuranceStates.NOT_APPLICABLE,
                            insuranceStates.NOT_COVERED,
                        ].includes(aRow.insuranceStatus)
                    );
                }
                return true;
            });
        rejectedItems = rejectedLines.length === billingValues.length;
    }
    return rejectedItems;
});

export default discountTypes;
