/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import { CLEAR_SELECTED_ADD_STOCK_ORDER, CREATE_ADD_STOCK_SUCCESS } from './addStock-actions';

const addStockReducer = (state = initialStates.addStock, action) => {
    let newState = {};
    switch (action.type) {
    case CREATE_ADD_STOCK_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case CLEAR_SELECTED_ADD_STOCK_ORDER:
        newState = Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default addStockReducer;
