import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { Tooltip } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

import lodashOrderBy from 'lodash.orderby';
import MaterialTableRow from './FormikTableRow';
import MaterialTableHead from './FormikTableHead';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
    isValidNumber,
} from '../../../constants/CommonUtil';
import { PAGE, defaultRowsPerPageOptions, SIZE } from '../../../constants/constants';
import TablePaginationActions from './TablePaginationActions';
import Popper from '../../Popper';
import MouseTrap from '../../../constants/mousetrap';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const buttonStyle = {
    color: 'black',
    background: 'white',
    borderRadius: '15px',
    width: '10rem',
    minHeight: '1rem',
    height: '1.8rem',
    fontSize: '0.8rem',
    padding: '0.1rem',
};

const styles = () => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        overflow: 'visible',
    },
    table: {},
    tableWrapper: {
        overflowX: 'auto',
        overflow: 'visible',
    },
});

const inputRefs = [];

class PaginatedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            order: 'asc',
            orderBy: '',
            selected: [],
            expandedRowIndex: null,
            openPopper: -1,
            anchorEl: null,
        };
    }

    componentDidMount() {
        MouseTrap.bindGlobal('shift+enter', () => {
            console.log('ActiveElement', document.activeElement.getAttribute('id'));
        });
    }


    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.lastUpdated, this.props.lastUpdated)) {
            this.setState({ selected: [] });
        }
        if (this.props.resetPage !== nextProps.resetPage) {
            this.setState({
                page: PAGE,
            });
        }
        // temporary fix for row being deleted but row being kept selected in checkbox
        this.updateSelectedCheckboxesStateIfSelectedRowDeleted(this.props, nextProps);
    }
    getValuesForPage = (givenProps) => {
        const {
            form,
            name,
            paginated,
            visibilityField,
        } = givenProps;
        const {
            page,
            size,
            order,
            orderBy,
        } = this.state;
        let visibleValues = getStringFromObject(`values.${name}`, form, []) || [];
        if (visibilityField) {
            visibleValues = visibleValues.filter(aRowValue => aRowValue[visibilityField]);
        }

        const totalElements = getStringFromObject('length', visibleValues, 0);
        const start = paginated ? page * size : 0;
        const end = paginated ? start + size : totalElements;
        let valuesForPage = paginated ? visibleValues.slice(start, end) : visibleValues;
        if (orderBy) {
            valuesForPage = lodashOrderBy(valuesForPage, [orderBy], [order]);
        }
        return valuesForPage;
    };
    updateSelectedCheckboxesStateIfSelectedRowDeleted = (currentProps, nextProps) => {
        const { rowDeleteCallbackFunction } = this.props;
        const currentValuesForPage = this.getValuesForPage(currentProps);
        const nextValuesForPage = this.getValuesForPage(nextProps);
        if (currentValuesForPage.length > nextValuesForPage.length) {
            if (isValidFunction(rowDeleteCallbackFunction)) {
                rowDeleteCallbackFunction(this, currentValuesForPage, nextValuesForPage);
            }
        }
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
        this.props.handleSort(order, orderBy);
    };

    handleAction = (value) => {
        const selected = cloneDeep(this.state.selected);
        const { page, size } = this.state;
        let currentPage = 0;
        selected.sort((a, b) => b - a);
        const {
            actionHandlers,
            push,
            replace,
            remove,
            form,
            name,
            tableCells,
            visibilityField,
        } = this.props;
        let index = 0;
        const length = getStringFromObject(`values.${name}.length`, form, 0);
        let rowsLeft = length || 0;
        const rowValue = {};
        switch (value) {
        case 'addNew':
            if (isArrayValidAndNotEmpty(tableCells)) {
                tableCells.forEach((cell) => {
                    rowValue[cell.name] = '';
                });
            }
            if (visibilityField) {
                rowValue[visibilityField] = true;
            }
            push({
                ...rowValue,
                uiUuid: uuidv4(),
            });
            break;
        case 'delete':
            for (index = 0; index < selected.length; index += 1) {
                if (length <= 1) {
                    replace(selected[index], {});
                } else {
                    remove(selected[index]);
                }
            }
            rowsLeft = length - selected.length;
            currentPage = parseInt(rowsLeft / size, 10);
            if (page < currentPage) {
                currentPage = page;
            }
            this.setState({ selected: [], page: currentPage });
            break;
        default:
            if (isObjectValidAndNotEmpty(actionHandlers)) {
                const handler = actionHandlers[value];
                if (isValidFunction(handler)) {
                    handler(selected, form, this);
                }
            }
            break;
        }
    };

    handleDeleteRow = (e, index) => {
        this.setState({ openPopper: index, anchorEl: e.currentTarget });
    };

    handleDeleteRowConfirm = () => {
        const { openPopper } = this.state;
        const { remove, onRowDelete } = this.props;
        if (openPopper > -1) {
            remove(openPopper);
            if (isValidFunction(onRowDelete)) {
                const { form, name } = this.props;
                const values = cloneDeep(getStringFromObject(`values.${name}`, form, []));
                console.log('asd-9a-ad-a0ss', values, openPopper);
                onRowDelete(openPopper, values);
            }
        }
        this.resetPopperState();
    };

    handleSelectAllClick = (event) => {
        const { form, name } = this.props;
        if (event.target.checked) {
            const values = getStringFromObject(`values.${name}`, form, []);
            if (isArrayValidAndNotEmpty(values)) {
                this.setState({ selected: values.map((e, idx) => ({ ...e, index: idx })).filter(e => !e.rowCheckboxDisabled).map(e => e.index) },
                    () => this.callHandleRowSelectionFuncFromProps(this.state.selected));
            }
        } else {
            this.setState({ selected: [] }, () => this.callHandleRowSelectionFuncFromProps([]));
        }
    };

    handleClick = (event, index) => {
        event.stopPropagation();
        const selected = cloneDeep(this.state.selected);
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        this.setState({ selected: newSelected }, () => this.callHandleRowSelectionFuncFromProps(newSelected));
    };

    callHandleRowSelectionFuncFromProps = (selectedRows = []) => {
        const { handleRowSelection } = this.props;
        if (isValidFunction(handleRowSelection)) {
            handleRowSelection(selectedRows);
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
        if (isValidFunction(this.props.handlePageChange)) {
            this.props.handlePageChange(page);
        }
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ size: event.target.value });
        if (isValidFunction(this.props.handleSizeChange)) {
            this.props.handleSizeChange(event.target.value);
        }
    };

    isSelected = (index) => {
        const { selected } = this.state;
        return selected.indexOf(index) !== -1;
    };

    handleRowExpand = (index) => {
        this.setState(prevState => ({
            expandedRowIndex: prevState.expandedRowIndex === index ? null : index,
        }));
    };

    resetPopperState = () => this.setState({ openPopper: -1, anchorEl: null });

    addRef = (ref, rowIndex, colIndex) => {
        const { tableCells } = this.props;
        let temp = inputRefs[rowIndex];
        if (temp) {
            temp[colIndex] = ref;
        } else {
            temp = new Array(tableCells.length);
            temp[colIndex] = ref;
        }
        inputRefs[rowIndex] = temp;
    };

    render() {
        const {
            classes,
            tableCells,
            isEditable,
            rowstyle,
            tableActionButtons,
            hideCheckBox,
            handleSelectAction,
            minWidth,
            actionHandlers,
            isExpandable,
            headerRowStyle,
            tableRoot,
            getExpandableComponent,
            showRowNumber,
            actionParams,
            onRowClick,
            form,
            name,
            rowsPerPageOptions,
            saveIcon,
            handleSaveIconClick,
            deleteIcon,
            getExtraRows,
            infoIcon,
            handleInfoIconClick,
            paginated,
            errorIcon,
            warningIcon,
            handleIconAction,
            headerColStyle,
            customValidationFunctions,
            handleButtonAction,
            visibilityField,
            uniqueField,
            expanded,
            controlledExpand,
            rowExpandHandler,
            emptyMessage,
            rowStyleSchema,
            paramMap,
            editableIndexes,
            formValues,
            additionalButtons,
        } = this.props;
        const {
            page,
            size,
            order,
            orderBy,
            selected,
            expandedRowIndex,
            openPopper,
            anchorEl,
        } = this.state;

        const { keyboardShortcuts } = this.props;
        keyboardShortcuts.forEach(
            a => MouseTrap.bindGlobal(
                getStringFromObject('keyCombination', a, null),
                () => actionHandlers[getStringFromObject('keyHandler', a, null)](selected, form, this),
            ));

        console.log('djkfhlasjfdhaklsjh', this.props);

        let visibleValues = getStringFromObject(`values.${name}`, form, []) || [];
        if (visibilityField) {
            visibleValues = visibleValues.filter(aRowValue => aRowValue[visibilityField]);
        }
        console.log('asda-0dsia-da=sdias-d', anchorEl, openPopper);


        const totalElements = getStringFromObject('length', visibleValues, 0);
        const enablePagination = (totalElements > SIZE) && paginated;
        const start = paginated ? page * size : 0;
        const end = paginated ? start + size : totalElements;
        let valuesForPage = paginated ? visibleValues.slice(start, end) : visibleValues;
        if (orderBy) {
            valuesForPage = lodashOrderBy(valuesForPage, [orderBy], [order]);
        }
        console.log('NameInTableRow', name, valuesForPage);
        return (
            <Paper className={classes.root} style={{ ...tableRoot }}>
                <div className={classes.tableWrapper} style={{ ...tableRoot }}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        style={{ minWidth }}
                    >
                        <MaterialTableHead
                            hideCheckBox={hideCheckBox}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            tableCells={tableCells}
                            numSelected={selected.length}
                            rowCount={totalElements}
                            isExpandable={isExpandable}
                            isEditable={isEditable}
                            headerRowStyle={headerRowStyle}
                            headerColStyle={headerColStyle}
                            saveIcon={saveIcon}
                            onSelectAllClick={this.handleSelectAllClick}
                            showRowNumber={showRowNumber}
                            deleteIcon={deleteIcon}
                            infoIcon={infoIcon}
                            additionalButtons={additionalButtons}
                        />
                        <TableBody>
                            {
                                isArrayValidAndNotEmpty(valuesForPage) ?
                                    valuesForPage.map((value, index) => {
                                        console.log('a-0u-a0sdiasdasd', value, getStringFromObject(uniqueField, value));
                                        let isEditableIndex = isEditable;
                                        if (editableIndexes != null) {
                                            isEditableIndex = editableIndexes.includes(index);
                                        }
                                        // all to support search..
                                        const indexToRender = (
                                            isValidNumber(value.originalIndex) ?
                                                value.originalIndex :
                                                start + index
                                        );
                                        return (
                                            <MaterialTableRow
                                                paramMap={paramMap}
                                                controlledExpand={controlledExpand}
                                                rowExpandHandler={rowExpandHandler}
                                                expanded={expanded}
                                                key={getStringFromObject(uniqueField, value)}
                                                onRowClick={onRowClick}
                                                hideCheckBox={hideCheckBox}
                                                isSelected={this.isSelected(indexToRender)}
                                                rowStyle={rowstyle}
                                                member={
                                                    `${name}[${indexToRender}]`
                                                }
                                                test-id={
                                                    `${value}[${indexToRender}]`
                                                }
                                                tableCells={tableCells}
                                                handleClick={this.handleClick}
                                                index={indexToRender}
                                                isEditable={isEditableIndex}
                                                getExpandableComponent={getExpandableComponent}
                                                isExpandable={isExpandable}
                                                handleRowExpand={this.handleRowExpand}
                                                expandedRowIndex={expandedRowIndex}
                                                parentClasses={classes}
                                                handleSelectAction={handleSelectAction}
                                                handleButtonAction={handleButtonAction}
                                                actionHandlers={actionHandlers}
                                                showRowNumber={showRowNumber}
                                                actionParams={actionParams}
                                                rowValue={value}
                                                handleDeleteRowClick={this.handleDeleteRow}
                                                handleSaveIconClick={handleSaveIconClick}
                                                addRef={this.addRef}
                                                saveIcon={saveIcon}
                                                deleteIcon={deleteIcon}
                                                infoIcon={infoIcon}
                                                handleInfoIconClick={handleInfoIconClick}
                                                errorIcon={errorIcon}
                                                warningIcon={warningIcon}
                                                handleIconAction={handleIconAction}
                                                customValidationFunctions={customValidationFunctions}
                                                rowStyleSchema={rowStyleSchema}
                                                formValues={formValues}
                                                additionalButtons={additionalButtons}
                                            />
                                        );
                                    })
                                    :
                                    <TableRow id="formik-empty-row">
                                        <TableCell
                                            colSpan={getStringFromObject('length', tableCells)}
                                            className={classNames('text-center', 'font-size-1-1')}
                                        >
                                            {emptyMessage}
                                        </TableCell>
                                    </TableRow>
                            }
                            {
                                getExtraRows && getExtraRows()
                            }
                        </TableBody>
                    </Table>
                </div>
                {
                    enablePagination &&
                    <TablePagination
                        component="div"
                        ActionsComponent={TablePaginationActions}
                        count={totalElements}
                        rowsPerPage={size}
                        page={page}
                        rowsPerPageOptions={isArrayValidAndNotEmpty(rowsPerPageOptions)
                            ? rowsPerPageOptions : defaultRowsPerPageOptions
                        }
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                }
                <div>
                    {
                        isEditable && tableActionButtons.map(button => (
                            <Tooltip key={`${button.key}`} title={getStringFromObject('tooltipValue', button, '')} interactive>
                                <Button
                                    key={`${button.key}`}
                                    test-id={button.label}
                                    onClick={() => this.handleAction(button.value)}
                                    style={{ ...buttonStyle, ...button.style }}
                                    variant="outlined"
                                >
                                    {button.label}
                                </Button>
                            </Tooltip>
                        ))
                    }
                </div>
                {
                    (openPopper > -1) && Boolean(anchorEl) &&
                    <Popper
                        open={openPopper > -1}
                        anchorEl={anchorEl}
                        test-id="purcahse-cofirm-delete"
                        placement="left"
                        handleClose={this.resetPopperState}
                    >
                        <DialogTitle test-id="confirm">
                            Confirm
                        </DialogTitle>
                        <DialogContent test-id="delete-message">
                            Are you sure want to delete ?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                test-id="purchase-delete-no"
                                disableRipple
                                onClick={this.resetPopperState}
                            >
                                No
                            </Button>
                            <Button
                                color="primary"
                                test-id="purchase-delete-yes"
                                variant="outlined"
                                disableRipple
                                onClick={this.handleDeleteRowConfirm}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Popper>
                }
            </Paper>
        );
    }
}

PaginatedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    keyboardShortcuts: PropTypes.object,
    name: PropTypes.string.isRequired,
    tableCells: PropTypes.array.isRequired,
    tableActionButtons: PropTypes.array,
    minWidth: PropTypes.string,
    hideCheckBox: PropTypes.bool,
    rowstyle: PropTypes.object,
    isEditable: PropTypes.bool,
    editableIndexes: PropTypes.array,
    paginated: PropTypes.bool,
    handleSelectAction: PropTypes.func,
    actionHandlers: PropTypes.object,
    customValidationFunctions: PropTypes.object,
    // dispatch: PropTypes.func,
    handlePageChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    getExpandableComponent: PropTypes.func,
    handleSort: PropTypes.func,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    saveIcon: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    infoIcon: PropTypes.bool,
    handleRowSelection: PropTypes.func,
    headerRowStyle: PropTypes.object,
    tableRoot: PropTypes.object,
    actionParams: PropTypes.object,
    onRowClick: PropTypes.func,
    handleSaveIconClick: PropTypes.func,
    handleInfoIconClick: PropTypes.func,
    onRowDelete: PropTypes.func,
    lastUpdated: PropTypes.instanceOf(Date),
    rowsPerPageOptions: PropTypes.array,
    /* formik provided props */
    form: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    getExtraRows: PropTypes.func,
    errorIcon: PropTypes.element,
    warningIcon: PropTypes.element,
    handleIconAction: PropTypes.func,
    handleButtonAction: PropTypes.func,
    headerColStyle: PropTypes.object,
    emptyMessage: PropTypes.string,
    // this is to enable search in among the data.. to hide and unhide
    // check insurance price list upload(PriceListWrapper) for an example
    visibilityField: PropTypes.string,
    uniqueField: PropTypes.string,
    rowStyleSchema: PropTypes.string,
    resetPage: PropTypes.bool,
    controlledExpand: PropTypes.bool,
    expanded: PropTypes.array,
    rowExpandHandler: PropTypes.func, // handles controlled row expand
    paramMap: PropTypes.object,
    formValues: PropTypes.object,
    rowDeleteCallbackFunction: PropTypes.func,
    additionalButtons: PropTypes.array,
};

PaginatedTable.defaultProps = {
    isEditable: true,
    expanded: [],
    editableIndexes: null,
    controlledExpand: false,
    rowExpandHandler: () => {},
    paginated: true,
    hideCheckBox: false,
    visibilityField: null,
    minWidth: '1020px',
    tableActionButtons: [],
    rowstyle: PropTypes.object.isRequired,
    handleSelectAction: () => {},
    actionHandlers: {},
    customValidationFunctions: {},
    keyboardShortcuts: [],
    // dispatch: () => {},
    handlePageChange: () => {},
    handleSizeChange: () => {},
    handleSaveIconClick: () => {},
    handleInfoIconClick: () => {},
    getExpandableComponent: () => {},
    handleSort: () => {},
    isExpandable: false,
    showRowNumber: false,
    saveIcon: false,
    infoIcon: false,
    deleteIcon: true,
    handleRowSelection: () => {},
    headerRowStyle: {},
    headerColStyle: {},
    tableRoot: {},
    actionParams: {},
    lastUpdated: null,
    rowsPerPageOptions: defaultRowsPerPageOptions,
    onRowClick: () => {},
    onRowDelete: () => {},
    getExtraRows: () => {},
    errorIcon: <React.Fragment />,
    warningIcon: <React.Fragment />,
    handleIconAction: () => {},
    handleButtonAction: () => {},
    uniqueField: '',
    emptyMessage: '',
    rowStyleSchema: '',
    resetPage: false,
    paramMap: {},
    formValues: {},
    rowDeleteCallbackFunction: null,
    additionalButtons: [],
};

export default withStyles(styles)(PaginatedTable);
