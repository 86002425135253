import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { apiCatchBlockFunction, getJsonPath } from '../../../constants/CommonUtil';
import MaterialTableV2 from '../../MaterialTableV2/MaterialTable';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import ActionButton from '../../ActionButton/ActionButton';
import ReceivePaymentDialog from '../ReceivePaymentDialog/ReceivePaymentDialog';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

class PaymentDetailsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // payment print data
            paymentDetailsToPrint: {},
            paymentSchema: {},
            printPayment: false,
            loading: true,
            selectedRow: null,
        };
    }

    componentDidMount() {
        axios.get(getJsonPath('/StockManagement/PaymentDetails.json'))
            .then((response) => {
                this.setState({
                    paymentSchema: response.data,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, this.props.dispatch);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    onPaymentClick = () => {
        const { selectedInvoice } = this.props;
        const paymentDetailToPrint = cloneDeep(getStringFromObject(
            'paymentDetails',
            selectedInvoice,
            [],
        ));
        this.setState(prevState => ({
            paymentDetailsToPrint: {
                ...selectedInvoice,
                payments: paymentDetailToPrint,
            },
            printPayment: !prevState.printPayment,
        }));
    };

    onVoucherClick = (e, selectedRowIndex) => {
        const { selectedInvoice } = this.props;
        this.setState({
            selectedRow: getStringFromObject(
                `paymentDetails[${selectedRowIndex}]`,
                selectedInvoice,
                {},
            ),
        });
    };

    onCloseVoucher = () => {
        this.setState({
            selectedRow: null,
        });
    };

    render() {
        const {
            paymentSchema,
            paymentDetailsToPrint,
            printPayment,
            loading,
            selectedRow,
        } = this.state;
        const {
            selectedInvoice,
        } = this.props;
        const paymentDetails = getStringFromObject(
            'paymentDetails',
            selectedInvoice,
            [],
        );
        console.log('asd0a0pudajspdoas22', paymentDetails, selectedInvoice);
        return (
            <React.Fragment>
                <Grid item sm={12} md={12} lg={12}>
                    {
                        loading &&
                        <div className="anlaytics-widget-spinner">
                            <CircularProgress />
                        </div>
                    }
                    {
                        !loading &&
                            <React.Fragment>
                                <Grid container className="mb-1">
                                    <Grid item sm={6} md={9} lg={9} />
                                    <Grid item sm={6} md={3} lg={3}>
                                        <ActionButton onClick={this.onPaymentClick}>
                                            Print All Vouchers
                                        </ActionButton>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <MaterialTableV2
                                        schema={paymentSchema}
                                        uniqueKey="reference"
                                        totalElements={getStringFromObject('length', paymentDetails, 0)}
                                        page={0}
                                        size={50}
                                        onRowClick={this.onVoucherClick}
                                        data={paymentDetails}
                                        rowsPerPageOptions={[]}
                                    />
                                </Grid>
                            </React.Fragment>
                    }
                </Grid>
                {
                    isObjectValidAndNotEmpty(selectedRow) &&
                    <ReceivePaymentDialog
                        open
                        isView
                        handleClose={this.onCloseVoucher}
                        accountVoucherId={selectedRow.voucherId}
                    />
                }
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/InvoicePayments/InvoicePayments.html`}
                    data={paymentDetailsToPrint}
                    print={printPayment}
                />
            </React.Fragment>
        );
    }
}

PaymentDetailsTab.propTypes = {
    selectedInvoice: PropTypes.object,
    dispatch: PropTypes.func,
};

PaymentDetailsTab.defaultProps = {
    selectedInvoice: {},
    dispatch: () => {},
};

export default PaymentDetailsTab;

