/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_BATCH_STATE,
    FETCH_BATCHES_LIST_FAILURE,
    FETCH_BATCHES_LIST_REQUEST,
    FETCH_BATCHES_LIST_SUCCESS,
} from './batches-actions';

const batchReducer = (state = initialStates.batches, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_BATCHES_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_BATCHES_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
            sortOrder: action.sortOrder,
            sortCol: action.sortCol,
        });
        break;
    case FETCH_BATCHES_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case CLEAR_BATCH_STATE:
        newState = initialStates.batches;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default batchReducer;
