import {
    CLEAR_STATE, FETCH_HOLIDAY_FOR_YEAR_REQUEST,
    FETCH_HOLIDAY_FOR_YEAR_SUCCESS,
    FETCH_HOLIDAY_LIST_SUCCESS, FETCH_UPCOMING_HOLIDAY_SUCCESS, SAVE_HOLIDAY_REQUEST,
    SAVE_HOLIDAY_SUCCESS,
    SELECT_YEAR,
} from './holiday-actions';
import initalStates from '../../initialStates';

const holidayReducer = (state = initalStates.holidays, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_HOLIDAY_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            list: action.data.page.content,
            isLast: action.data.isLast,
            page: action.data.page.number + 1,
            size: action.data.page.size,
        });
        break;
    case SAVE_HOLIDAY_REQUEST:
        newState = Object.assign({}, state, { isCreated: false });
        break;
    case SAVE_HOLIDAY_SUCCESS:
        newState = Object.assign({}, state, {
            yearData: action.data,
            isCreated: true,
        });
        break;
    case SELECT_YEAR:
        newState = Object.assign({}, state, {
            yearData: action.data,
        });
        break;
    case FETCH_HOLIDAY_FOR_YEAR_REQUEST:
        newState = Object.assign({}, state, { yearData: {} });
        break;
    case FETCH_HOLIDAY_FOR_YEAR_SUCCESS:
        newState = Object.assign({}, state, {
            yearData: action.data,
        });
        break;
    case CLEAR_STATE:
        newState = Object.assign({}, initalStates.holidays);
        break;
    case FETCH_UPCOMING_HOLIDAY_SUCCESS:
        newState = Object.assign({}, state, { upcomingHolidays: action.data });
        break;
    default:
        newState = state;
    }

    return newState;
};

export default holidayReducer;
