import { PAGE, SIZE } from '../../../constants/constants';

/**
 * Created by sachinprakash on 12/8/19.
 */
export const GENERIC_FETCH_REQUEST = '@@Generic/REQUEST_FETCH_';
export const GENERIC_CONSTRUCT_FETCH_REQUEST = '@@Generic/CONSTRUCT_FETCH_REQUEST_';
export const GENERIC_FETCH_SUCCESS = '@@Generic/SUCCESS_FETCH_';
export const GENERIC_FETCH_FAILURE = '@@Generic/FAILURE_FETCH_';

export const GENERIC_FETCH_REQUEST_CLEAR = '@@Generic/REQUEST_FETCH_CLEAR';


export const GENERIC_ADD_OR_UPDATE_REQUEST = '@@Generic/REQUEST_ADD_OR_UPDATE_';
export const GENERIC_ADD_OR_UPDATE_SUCCESS = '@@Generic/SUCCESS_ADD_OR_UPDATE_';
export const GENERIC_ADD_OR_UPDATE_FAILURE = '@@Generic/FAILURE_ADD_OR_UPDATE_';
export const GENERIC_ADD_OR_UPDATE_CLEAR = '@@Generic/GENERIC_ADD_OR_UPDATE_CLEAR';

export const genericMasterConstructFetch = (
    api = '',
    page = PAGE,
    size = SIZE,
    sortCol = 'conceptId',
    sortOrder = 'DESC',
    filters = { active: true, locale: 'en' },
    searchConstruct = {
        type: 'criteria', key: 'concept.retired', value: 'f', operation: '=',
    },
) => {
    console.log('jkasdfhlaksjdfhlaksdfj', page, size);
    return (
        {
            api: `${api}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
            type: GENERIC_CONSTRUCT_FETCH_REQUEST,
            filters,
            searchConstruct,
        }
    );
};

export const genericMasterFetch = (
    api = '',
    page = PAGE,
    size = SIZE,
    sortCol = 'id',
    sortOrder = 'DESC',
    filters = { active: true, locale: 'en' },
    isFilter = true,
) => {
    console.log('jkasdfhlaksjdfhlaksdfj', page, size);
    return (
        {
            api: `${api}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}${isFilter ? `&filter=${encodeURIComponent(JSON.stringify(filters))}` : ''}`,
            type: GENERIC_FETCH_REQUEST,
        }
    );
};
export const genericMasterFetchClearState = () => ({
    type: GENERIC_FETCH_REQUEST_CLEAR,
});

export const genericAddOrUpdate = (api, payload, successCallBack, failureCallBack) => {
    console.log('jkasdfhlkjs', payload);
    return {
        type: GENERIC_ADD_OR_UPDATE_REQUEST,
        api,
        payload,
        successCallBack,
        failureCallBack,
    };
};


export const genericMasterAddClearState = () => ({
    type: GENERIC_ADD_OR_UPDATE_CLEAR,
});
