/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import appointmentTokenTimeBoxStyles from './AppointmentTokenTimeBoxStyles';
import { GLOBAL_PROPERTIES, SLOT_TYPES, tokenTypes } from '../../../constants/constants';
import { to12HourFormat } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const AppointmentTokenTimeBox = ({
    doctor,
    classes,
    slotType,
    timeSlot,
    available,
    tokenNumber,
    boxStyle,
    onClickHandler,
    convertTo12Hour,
    hoverMessage,
    token,
    tokenTypeColorConfig,
    multipleTokenTypeSupported,
    hideTime,
    readOnly,
}) => {
    console.log('sadasd', timeSlot);
    let color = getStringFromObject(`${slotType}.color`, SLOT_TYPES);
    if (!color) {
        // eslint-disable-next-line
        color = SLOT_TYPES.WALK_IN.color;
    }
    const tokenType = getStringFromObject('tokenType.value', token);
    console.log('asdjad-as', token);
    const colorBasedOnTokenType = getStringFromObject(tokenType, tokenTypeColorConfig);
    if (colorBasedOnTokenType) {
        color = colorBasedOnTokenType;
    }

    const hoverMessageDiv = (
        <div style={{ fontSize: '1rem', margin: '0.2rem', lineHeight: '1.3rem' }}>
            {hoverMessage}
        </div>
    );

    let tokenDisplay = getStringFromObject('name', token);

    if (!multipleTokenTypeSupported || !tokenDisplay) {
        tokenDisplay = tokenNumber < 10 ? `0${tokenNumber}` : tokenNumber;
    }
    const showTime = !hideTime && tokenType !== tokenTypes.VIP;

    const contentDiv = (
        <div
            test-id={`${doctor}-token-${tokenNumber}-${timeSlot}`}
            onClick={onClickHandler}
            style={{
                border: showTime ? `1px solid ${color}` : '',
                opacity: available ? '1' : '0.3',
                ...boxStyle,
            }}
            className={classNames(classes.appointmentBox, available && showTime ? classes.availableSlot : {}, readOnly ? classes.defaultCursor : classes.pointerCursor)}
        >
            <div
                style={{ background: color }}
                className={classNames(classes.appointmentNumber, { [classes.vipToken]: tokenType === tokenTypes.VIP })}
            >
                {
                    getStringFromObject('name', token) ? '' : (getStringFromObject('tokenTypePrefix', token) || '')
                }
                {tokenDisplay}
            </div>
            {
                showTime &&
                <div className={classes.appointmentTime}>
                    {convertTo12Hour ? to12HourFormat(timeSlot) : timeSlot}
                </div>
            }
        </div>
    );
    if (hoverMessage) {
        return (
            <Tooltip title={hoverMessageDiv}>
                {contentDiv}
            </Tooltip>
        );
    }
    return contentDiv;
};

AppointmentTokenTimeBox.propTypes = {
    doctor: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    slotType: PropTypes.string.isRequired,
    timeSlot: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    hoverMessage: PropTypes.string.isRequired,
    convertTo12Hour: PropTypes.bool,
    multipleTokenTypeSupported: PropTypes.bool,
    tokenNumber: PropTypes.number.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    boxStyle: PropTypes.object,
    tokenTypeColorConfig: PropTypes.object,
    token: PropTypes.string,
    hideTime: PropTypes.bool,
    readOnly: PropTypes.bool,
};

AppointmentTokenTimeBox.defaultProps = {
    boxStyle: {},
    tokenTypeColorConfig: {},
    convertTo12Hour: false,
    multipleTokenTypeSupported: false,
    token: {},
    hideTime: false,
    readOnly: false,
};

const mapStateToProps = state => ({
    tokenTypeColorConfig: getStringFromObject('appConfiguration.tokenTypeColorConfig', state),
    multipleTokenTypeSupported:
        getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.MULTIPLE_TOKEN_TYPE_SUPPORTED}`, state),
});

export default withStyles(appointmentTokenTimeBoxStyles)(connect(mapStateToProps)(AppointmentTokenTimeBox));
