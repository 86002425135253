export const SELECT_PATIENT = '@@Patient/SELECT_PATIENT';
export const CLEAR_PATIENT = '@@Patient/CLEAR_PATIENT';

export const selectPatient = patient => ({
    type: SELECT_PATIENT,
    patient,
});

export const clearPatient = () => ({
    type: CLEAR_PATIENT,
});
