import uuidV4 from 'uuid/v4';
import {
    isArrayValidAndNotEmpty, isValidFunction,
    roundedValueFixedToTwoDigits,
} from '../../../../constants/CommonUtil';
import { insuranceCoveredStates, insuranceNotCoveredStates, insuranceStates, payerTypes } from '../QuickRegistrationForm/QuickRegistrationFormUtil';
import {
    errorMessage,
} from '../../../../redux/modules/message/message-actions';
import MESSAGES from '../../../../constants/messages';
import { checkIfPrivilegeExistsForUser } from '../../../../constants/privilegeChecker';
import { generalSettings } from '../../../../components/ServicesComponents/GeneralSettings/GeneralSettingsUtil';
import { getStringFromObject } from '../../../../constants/lodashUtils';
import { NumberOf } from '../../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../../constants/nullCheckUtils';


export const highlightedBillingRowFieldName = 'highlightedBillingRow';

export const registrationBillingTableFields = {
    CODE: 'code',
    PRODUCT_NAME: 'productName',
    PRICE: 'price',
    QUANTITY: 'quantity',
    DISCOUNT_PERCENTAGE: 'discountPercentage',
    DISCOUNT_AMOUNT: 'discountAmount', // exclusive of qty
    COPAY_PERCENTAGE: 'coPayPercentage',
    COPAY_AMOUNT: 'coPayAmount',
    VAT_PERCENTAGE: 'vatPercentage',
    VAT_AMOUNT: 'vatAmount',
    TOTAL: 'total',
    ORDER_NOTES: 'orderNotes',
    APPROVAL_NOTES: 'approvalNotes',
    PRICE_WAS_ZERO: 'zeroPriceProduct',
    PRODUCT_CATEGORY: 'productCategory',
    APPLIED_DISCOUNT_TYPE: 'discountType',

    INS_ACTION: 'solcomments',
};
export const registrationBillingTableFieldLabels = {
    CODE: 'CODE',
    PRODUCT_NAME: 'NAME',
    PRICE: 'PRICE',
    QUANTITY: 'QTY',
    DISCOUNT_PERCENTAGE: 'DISC %',
    DISCOUNT_AMOUNT: 'DISC AMT',
    COPAY_PERCENTAGE: 'COPAY %',
    COPAY_AMOUNT: 'COPAY AMT',
    VAT_PERCENTAGE: 'VAT %',
    VAT_AMOUNT: 'VAT AMT',
    TOTAL: 'TOTAL',
};

export const attributesThatRequireRecalculation = [
    registrationBillingTableFields.QUANTITY,
    registrationBillingTableFields.PRICE,
    registrationBillingTableFields.COPAY_AMOUNT,
    registrationBillingTableFields.DISCOUNT_PERCENTAGE,
];

export const maxCopayAttributesThatRequireRecalculation = [
    registrationBillingTableFields.QUANTITY,
    registrationBillingTableFields.PRICE,
    registrationBillingTableFields.DISCOUNT_PERCENTAGE,
];

export const shouldRowBeRecalculated = (oldValues, newValues, attributes = attributesThatRequireRecalculation) => {
    for (let i = 0; i < attributes.length; i += 1) {
        const attribtuteToCheck = attributes[i];
        const oldAttributeValue = getStringFromObject(attribtuteToCheck, oldValues);
        const newAttributeValue = getStringFromObject(attribtuteToCheck, newValues);
        if (oldAttributeValue !== newAttributeValue) {
            return true;
        }
    }
    return false;
};

export const calculateRegBillLineTotalAndOtherRelevantVals = (
    billableTableRow,
    dispatcher,
    payerType,
) => {
    const priceNeg = NumberOf(billableTableRow[registrationBillingTableFields.PRICE]);
    const discount = NumberOf(billableTableRow[registrationBillingTableFields.DISCOUNT_PERCENTAGE]);
    const quantity = NumberOf(billableTableRow[registrationBillingTableFields.QUANTITY]);
    // if the price was reset.. then no need to do any calcs
    const { resetPricing } = billableTableRow;

    const amountWithoutDiscount = priceNeg * quantity;


    const discountAmount = priceNeg * (discount / 100);
    const amountWithDiscount = amountWithoutDiscount - (discountAmount * quantity);

    const tempPrice = NumberOf(amountWithDiscount);

    // // eslint-disable-next-line
    // debugger;
    // const coPayPercentage = NumberOf(billableTableRow[registrationBillingTableFields.COPAY_PERCENTAGE]);

    // let coPayAmount = roundedValueFixedToTwoDigits((coPayPercentage / 100) * amountWithDiscount);
    let finalAmount = NumberOf(tempPrice);
    console.log('ad95984984', finalAmount, resetPricing, payerType);
    let coPayAmount = 0;

    let additionalAmountPaid = 0;

    if (
        [payerTypes.INSURANCE, payerTypes.CORPORATE].includes(payerType) &&
        [
            insuranceStates.COVERED,
            insuranceStates.NEED_APPROVAL,
            insuranceStates.LIMIT_EXCEEDED,
            insuranceStates.DELAYED_APPROVED,
        ].includes(billableTableRow.insuranceStatus) &&
        !resetPricing
    ) {
        const enteredCoPayAmount = NumberOf(billableTableRow[registrationBillingTableFields.COPAY_AMOUNT]);
        if (billableTableRow.deductible) {
            // const deductible = NumberOf(billableTableRow.deductible);
            coPayAmount = NumberOf(enteredCoPayAmount);
            // if (coPayAmount < deductible) {
            //     additionalAmountPaid = deductible - coPayAmount;
            // } else {
            //     coPayAmount = NumberOf(deductible);
            // }
        }

        // original copaypercetage is only used to decide whether we have to take min and max copay in consideration

        const copay = NumberOf(billableTableRow.originalCoPayPercent);
        const minCopay = NumberOf(billableTableRow.minCopay);
        const maxCopay = NumberOf(billableTableRow.maxCopay);
        if (copay) {
            // coPayAmount = tempPrice * (copay / 100);
            coPayAmount = enteredCoPayAmount;
            if (minCopay) {
                if (coPayAmount < minCopay) {
                    additionalAmountPaid = minCopay - coPayAmount;
                    if (isValidFunction(dispatcher)) {
                        dispatcher(
                            errorMessage(
                                `${MESSAGES.INSURANCE.MIN_COPAY}${roundedValueFixedToTwoDigits(minCopay)}`,
                            ),
                        );
                    }
                }
            }
            if (maxCopay) {
                if (coPayAmount > maxCopay) {
                    coPayAmount = maxCopay;
                    if (isValidFunction(dispatcher)) {
                        dispatcher(
                            errorMessage(
                                `${MESSAGES.INSURANCE.MAX_COPAY}${roundedValueFixedToTwoDigits(maxCopay)}`,
                            ),
                        );
                    }
                }
            }
        }

        coPayAmount += additionalAmountPaid;
        finalAmount = (coPayAmount * quantity);
    } else {
        coPayAmount = tempPrice / quantity;
    }

    let lineTotalWithTax = NumberOf(finalAmount);

    const { taxes } = billableTableRow;

    let totalTaxAmount = 0;
    let totalTaxPercent = 0;

    console.log('asd-9uasd0d-asd', finalAmount);

    if (isArrayValidAndNotEmpty(taxes)) {
        for (let i = 0; i < taxes.length; i += 1) {
            const taxObj = taxes[i];
            totalTaxPercent += NumberOf(taxObj.percent);
            console.log('asd-9uasd0d-asd', finalAmount, (finalAmount * NumberOf(taxObj.percent)) / 100, roundedValueFixedToTwoDigits((finalAmount * NumberOf(taxObj.percent)) / 100));
            const taxAmount = NumberOf(
                roundedValueFixedToTwoDigits(
                    (finalAmount * (NumberOf(taxObj.percent) / 100)),
                ));
            totalTaxAmount += taxAmount;
            lineTotalWithTax += taxAmount;
        }
    }
    return {
        amount: amountWithDiscount,
        totalTaxPercent,
        [registrationBillingTableFields.COPAY_AMOUNT]:
            amountWithDiscount === 0 ?
                0 :
                roundedValueFixedToTwoDigits(coPayAmount),
        [registrationBillingTableFields.COPAY_PERCENTAGE]:
            amountWithDiscount === 0 ?
                billableTableRow[registrationBillingTableFields.COPAY_PERCENTAGE] :
                roundedValueFixedToTwoDigits((coPayAmount / amountWithDiscount) * 100 * quantity),
        [registrationBillingTableFields.DISCOUNT_AMOUNT]: roundedValueFixedToTwoDigits(discountAmount),
        additionalAmountPaid: NumberOf(additionalAmountPaid),
        [registrationBillingTableFields.TOTAL]: roundedValueFixedToTwoDigits(lineTotalWithTax),
        [registrationBillingTableFields.VAT_AMOUNT]: roundedValueFixedToTwoDigits(totalTaxAmount),
        [registrationBillingTableFields.VAT_PERCENTAGE]: roundedValueFixedToTwoDigits(totalTaxPercent),
    };
};

export const getRegBillingTableRowObjFromSelectedProduct = (productDetails, payerType, isSubProduct) => {
    const newRowValues = {
        [registrationBillingTableFields.QUANTITY]: productDetails.quantity,
        [registrationBillingTableFields.TOTAL]: productDetails.total,
        [registrationBillingTableFields.PRICE]: productDetails.price,
        [registrationBillingTableFields.CODE]: productDetails.code,
        [registrationBillingTableFields.INS_ACTION]:
            productDetails[registrationBillingTableFields.INS_ACTION],
        taxes: productDetails.tax,
        taxExempted: productDetails.taxExempted,
        productExemptedFromTax: productDetails.productExemptedFromTax,
        [registrationBillingTableFields.COPAY_AMOUNT]: productDetails.coPayAmount,
        [registrationBillingTableFields.COPAY_PERCENTAGE]: productDetails.calculatedCoPayPercentage,
        [registrationBillingTableFields.DISCOUNT_PERCENTAGE]: productDetails.discount,
        [registrationBillingTableFields.DISCOUNT_AMOUNT]: productDetails.discountAmount,
        [registrationBillingTableFields.PRODUCT_CATEGORY]:
            productDetails[registrationBillingTableFields.PRODUCT_CATEGORY],
        [registrationBillingTableFields.PRODUCT_NAME]: {
            id: productDetails.id,
            displayString: productDetails.itemName,
        },
        uiUuid: uuidV4(),
        enableDelete: true,
        // just there to reset the price on deselection of a package order line
        originalPrice: productDetails.price,
        orderable: productDetails.orderable,
        resetPricing: productDetails.resetPricing,
        packageProduct: productDetails.packageProduct,
        mrsOrderUuid: productDetails.mrsOrderUuid,
        productUuid: productDetails.uuid,
        minCopay: productDetails.minCopay,
        maxCopay: productDetails.maxCopay,
        deductible: productDetails.deductible,
        insuranceStatus: productDetails.insuranceStatus,
        packageUuid: productDetails.packageUuid,
        originalCoPayPercent: productDetails.coPayPercent,
        // used for max copay calcs..
        copayAsPerScheme: productDetails.originalCopayPercent,

        saleOrderLineUuid: productDetails.saleOrderLineUuid,
        totalBilledInCashInEpisode: productDetails.totalBilledInCashInEpisode,
        totalBilledInCashInEpisodeAndDate: productDetails.totalBilledInCashInEpisodeAndDate,
        totalBilledInInsuranceInEpisode: productDetails.totalBilledInInsuranceInEpisode,
        // this will be used in the bottom part of the bill to be filled in as
        // the row is highlighted
        [registrationBillingTableFields.ORDER_NOTES]: productDetails.mrsOrderNotes,
        [registrationBillingTableFields.APPROVAL_NOTES]: productDetails.insuranceApprovalNotes,
        cost: NumberOf(productDetails.cost),
    };

    if (
        // if copay is 100% in sub product i.e supplement product..
        // it means it's actually not covered.. backend sends ins state to be same as parent item
        // added isSubProduct to minimize impact
        isSubProduct &&
        payerType === payerTypes.INSURANCE &&
        [
            insuranceStates.COVERED,
            insuranceStates.LIMIT_EXCEEDED,
            insuranceStates.DELAYED_APPROVED,
            insuranceStates.NEED_APPROVAL,
        ].includes(productDetails.insuranceStatus) &&
        productDetails.calculatedCoPayPercentage === 100
    ) {
        newRowValues.insuranceStatus = insuranceStates.NOT_COVERED;
    }

    const discountAmount = NumberOf(productDetails.discountAmount);
    let discPer = NumberOf(productDetails.discount);
    if (!discPer && discountAmount) {
        discPer = (discountAmount / newRowValues[registrationBillingTableFields.PRICE]) * 100;
        newRowValues[registrationBillingTableFields.DISCOUNT_PERCENTAGE] = roundedValueFixedToTwoDigits(discPer);
    }

    const priceValue = newRowValues[registrationBillingTableFields.PRICE];
    if (payerType === payerTypes.CASH) {
        newRowValues[registrationBillingTableFields.COPAY_AMOUNT] = priceValue;
    } else if ([payerTypes.ASSOCIATION, payerTypes.COMPANY].includes(payerType)) {
        const discountPercentage =
            newRowValues[registrationBillingTableFields.DISCOUNT_PERCENTAGE];
        const copay = NumberOf(priceValue) * ((100 - NumberOf(discountPercentage)) / 100);
        newRowValues[registrationBillingTableFields.COPAY_AMOUNT] = roundedValueFixedToTwoDigits(copay);
        newRowValues[registrationBillingTableFields.COPAY_PERCENTAGE] = 100;
    }
    if (productDetails.resetPricing) {
        newRowValues[registrationBillingTableFields.PRICE] = 0;
    }

    newRowValues[registrationBillingTableFields.PRICE_WAS_ZERO] =
        NumberOf(newRowValues[registrationBillingTableFields.PRICE]) === 0;
    return {
        ...newRowValues,
        ...calculateRegBillLineTotalAndOtherRelevantVals(
            newRowValues,
            null,
            payerType,
        ),
    };
};

export const shouldPriceFieldBeDisabled = (
    priceEditablePrivilege,
    payerType,
    schemeCardDetails,
    rowValues,
    priceEditingSettings,
) => {
    let pricingDisabled = true;

    if (
        checkIfPrivilegeExistsForUser(priceEditablePrivilege) &&
        [payerTypes.INSURANCE, payerTypes.COMPANY].includes(payerType)
    ) {
        pricingDisabled = false;
    } else if (payerType === payerTypes.INSURANCE) {
        const { priceEditable, priceEditableForZeroPrice } = (schemeCardDetails || {});
        const wasZeroPriced = rowValues[registrationBillingTableFields.PRICE_WAS_ZERO];
        if (priceEditable) {
            pricingDisabled = !priceEditable;
        } else if (wasZeroPriced) {
            // check if it was a zero priced product
            pricingDisabled = !priceEditableForZeroPrice;
        }
    } else {
        const pricingEditingAllowed =
            getStringFromObject(generalSettings.PRICE_EDITING_ALLOWED, priceEditingSettings) === 'true';
        const pricingEditingAllowedForZero =
            getStringFromObject(
                generalSettings.PRICE_EDITING_ALLOWED_FOR_ZERO_AMOUNT,
                priceEditingSettings,
            ) === 'true';
        pricingDisabled = !pricingEditingAllowed;

        const wasZeroPriced = rowValues[registrationBillingTableFields.PRICE_WAS_ZERO];
        if (wasZeroPriced) {
            // check if it was a zero priced product
            pricingDisabled = !pricingEditingAllowedForZero;
        }

        console.log('a-0d-i-0i-ads2131', pricingEditingAllowedForZero);
    }
    return pricingDisabled;
};

export const getInsuranceStatesToCheck = (costCheckPayers) => {
    let insuranceStatesToCheck = [];
    if (isArrayValidAndNotEmpty(costCheckPayers)) {
        costCheckPayers.forEach((aPayer) => {
            if (aPayer === 'CASH') {
                insuranceStatesToCheck = [...insuranceStatesToCheck, ...insuranceNotCoveredStates];
            } else if (aPayer === 'INSURANCE') {
                insuranceStatesToCheck = [...insuranceStatesToCheck, ...insuranceCoveredStates];
            }
        });
    }
    return insuranceStatesToCheck;
};

export const isTotalAmountLessThanCost = (productDetails, insuranceStatesToCheck) => {
    if (isObjectValidAndNotEmpty(productDetails) && isArrayValidAndNotEmpty(insuranceStatesToCheck)) {
        const price = NumberOf(getStringFromObject('price', productDetails) || 0);
        const discountAmount = NumberOf(getStringFromObject('discountAmount', productDetails));
        const cost = NumberOf(getStringFromObject('cost', productDetails) || 0);
        const insuranceStatus = getStringFromObject('insuranceStatus', productDetails) || '';
        if (insuranceStatesToCheck.includes(insuranceStatus) && cost && price) {
            return (price - discountAmount) < cost;
        }
    }
    return false;
};
