import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card/Card';
import Grid from '@material-ui/core/Grid/Grid';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import { withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { patientPaneToPageMap } from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { searchPatientTabs } from './PatientSearchUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const styles = theme => ({
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
        maxWidth: '18em',
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});


class PatientSearch extends React.Component {
    constructor(props) {
        super(props);
        this.activeTabsForUser = searchPatientTabs.filter(aTab => checkIfPrivilegeExistsForUser(aTab.privilege));
        console.log('sdsdfasdf', props.match, props.history);
        this.state = {
            value: getStringFromObject('[0].value', this.activeTabsForUser),
        };
    }

    getTabValue = (history) => {
        const { location: { pathname } } = history;
        let value = '';
        if (pathname.includes('active')) {
            value = 'active';
        } else {
            value = 'all';
        }
        return value;
    };

    handleChange = (event, value) => {
        const { history } = this.props;
        this.setState({
            value,
        });
        history.push(patientPaneToPageMap[value]);
    };

    render() {
        const { children, classes } = this.props;
        const { value } = this.state;
        return (
            <div style={{ height: '93%', margin: '2rem' }}>
                <div className="display-flex justify-content-space-between height-100">
                    <Card
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                            minHeight: '100%',
                            marginBottom: '1rem',
                            padding: '1rem',
                        }}
                    >
                        <Grid container>
                            <AppBar position="static" className={classes.appBar}>
                                <Tabs
                                    value={value}
                                    onChange={this.handleChange}
                                    fullWidth
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    {
                                        searchPatientTabs.map((t) => {
                                            if (checkIfPrivilegeExistsForUser(t.privilege)) {
                                                return (
                                                    <Tab
                                                        key={t.key}
                                                        value={t.value}
                                                        label={t.label}
                                                        classes={{
                                                            root: classes.tabRoot,
                                                            selected: classes.tabSelected,
                                                            label: classes.label,
                                                        }}
                                                        disableRipple
                                                    />
                                                );
                                            }
                                            return '';
                                        })
                                    }
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                style={{ width: '100%', height: '100%', WebkitOverflowScrolling: 'touch' }}
                            >
                                <div>
                                    {children}
                                </div>
                            </SwipeableViews>
                        </Grid>
                    </Card>
                </div>
            </div>
        );
    }
}

PatientSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

PatientSearch.defaultProps = {
    children: <div />,
};

export default (withStyles(styles)(PatientSearch));
