import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import connect from 'react-redux/lib/connect/connect';
import cloneDeep from 'clone-deep';
import axios from 'axios';
import { Field, withFormik } from 'formik';

import {
    apiCatchBlockFunction, getJsonPath,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { isEmpty, required, upperCaseNormalizer } from '../../../constants/FormValidations';
import API from '../../../constants/api';
import {
    ACCOUNT_INVOICE_OPERATION,
    getAccountInvoiceFromUiObject,
    getUiObjectFromAccountInvoiceResponse,
    getUiObjectFromMaterialReceive,
    getUiObjectFromPurchaseOrder, INVOICE_LINE_UI_OBJECT,
    INVOICE_UI_OBJECT,
} from '../../../mapper/AccountInvoiceMapper';
import {
    cancelAccountInvoiceRequest,
    clearSelectedAccountInvoice,
    createAccountInvoiceRequest,
    createAccountInvoiceRequestForMaterialReceive,
    fetchAccountInvoiceById,
    fetchAccountInvoiceByUuid,
    fetchAccountInvoiceForPurchaseOrderRequest,
} from '../../../redux/modules/accountInvoice/accountInvoice-actions';
import { ACTION_HANDLERS } from './MakeBillHandlers';
import ActionButton from '../../ActionButton/ActionButton';
import makeBillDialogStyles from './MakeBillDialogStyles';
import getPaymentInvoiceDetailsToPrintMemoize, {
    calculateDueDateFromPaymentTerm,
    getConsolidatedJournalEntriesMemoize, getSupplierSubCompany,
    makeBillDialogTabs,
} from './MakeBillDialogUtil';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import PaymentDetailsTab from './PaymentDetailsTab';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import ReceivePaymentDialogWrapper from '../ReceivePaymentDialog/ReceivePaymentDialogWrapper';
import { getAddressFromAddressDto, getUiObject } from '../../../mapper/PurchaseOrderMapper';
import { formatDate } from '../../../constants/DateUtil';
import { generateObjectWithCriteriaMode, getCombined } from '../../../sagas/util';
import POListPopUp from '../POListPopUp';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import GoodReturnDialog from '../GoodReturnDialog/GoodReturnDialog';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import FormikCheckbox from '../../Formik/FieldComponents/FormikCheckbox';
import { subCompanies } from '../../../constants/ERPConstants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import FormikOutlinedSelectField from '../../Formik/FieldComponents/FormikOutlinedSelectField';
import { paymentMeans } from '../CreditOrDebitMemoV2/CreditOrDebitMemoUtilsV2';

const formName = 'makeBillForm';

class MakeBillDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            readOnly: false,
            showPaymentDialog: false,
            showPOsDialog: false,
            paymentEnabled: true,
            pos: [],
            // flag to trigger invoice print
            print: false,
            // flag to trigger journal entry print
            printJournal: false,
            // contains the data that should be printed in the doc
            journalEntryPrintData: null,
            // selected tab i.e item details/payment details
            selectedTab: makeBillDialogTabs.ITEM_DETAILS,
            cancelInvoice: false,
            returnStockDialog: false,
            selectedInvoice: null,
            enableServices: false,
            subCompany: '',
        };
    }

    componentWillMount() {
        this.reloadComponent();
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/PurchaseInvoiceItems.json', APPLICATION_CONFIG_URL))
            .then(resp => resp.json())
            .then((json) => {
                console.log('dkjfkaf', json);
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, this.props.dispatch);
            });
    }

    componentWillReceiveProps(nextProps) {
        console.log('next rosdd123 ', nextProps, this.props);
        if (!deepEqual(nextProps.selectedInvoices, this.props.selectedInvoices)) {
            let readOnly = false;
            let selectedInvoice = null;
            // assuming there will be only one invoice attached with a purchase order
            if (isArrayValidAndNotEmpty(nextProps.selectedInvoices)) {
                const groupedByStatus = {};
                nextProps.selectedInvoices.forEach((ele) => {
                    groupedByStatus[ele.state] = (groupedByStatus[ele.state] || []).concat(ele);
                });
                if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.PAID])) {
                    readOnly = true;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.PAID][0] || {};
                } else if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.CONFIRM])) {
                    readOnly = true;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.CONFIRM][0] || {};
                } else if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.DRAFT])) {
                    readOnly = false;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.DRAFT][0] || {};
                } else if ((nextProps.invoiceId || !isObjectValidAndNotEmpty(nextProps.purchaseOrder))
                    && isArrayValidAndNotEmpty(groupedByStatus.cancel)) {
                    readOnly = true;
                    selectedInvoice = getStringFromObject('cancel[0]', groupedByStatus, null);
                }
            }
            if ((!selectedInvoice) && isObjectValidAndNotEmpty(nextProps.selectedInvoices) &&
                !Array.isArray(nextProps.selectedInvoices)) {
                selectedInvoice = getStringFromObject('selectedInvoices', nextProps, {});
                const state = getStringFromObject('selectedInvoices.state', nextProps);
                if (state === ACCOUNT_INVOICE_OPERATION.CONFIRM) {
                    readOnly = true;
                }
                if (state === 'cancel') {
                    readOnly = true;
                }
                if (state === ACCOUNT_INVOICE_OPERATION.DRAFT) {
                    readOnly = false;
                }
            }
            this.setState({
                readOnly,
                cancelInvoice: false,
                selectedInvoice,
                paymentEnabled: (NumberOf(getStringFromObject('residualAmount', selectedInvoice)) > 0 &&
                    getStringFromObject('state', selectedInvoice) !== 'cancel'),
            },
            () => {
                const { purchaseOrder } = this.props;
                console.log('asdas0d0980---03-0ui2 - invoice', selectedInvoice);
                if (isObjectValidAndNotEmpty(selectedInvoice)) {
                    this.initializeFormWithApiResponse(selectedInvoice);
                } else if (isObjectValidAndNotEmpty(purchaseOrder)) {
                    this.initializeForm(getUiObjectFromPurchaseOrder(purchaseOrder));
                    this.setPaymentTermsFromSupplier(nextProps, purchaseOrder.supplier);
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const oldDiscountAcc = getStringFromObject('discountAccount', prevProps.values);
        const newDiscountAcc = getStringFromObject('discountAccount', this.props.values);
        // const oldSupplier = getStringFromObject('supplier', prevProps.values);
        // const newSupplier = getStringFromObject('supplier', this.props.values);
        if (!this.props.materialReceive) {
            if (!deepEqual(oldDiscountAcc, newDiscountAcc) && !isObjectValidAndNotEmpty(newDiscountAcc)) {
                this.props.setFieldValue('discountPer', 0);
                this.props.setFieldValue('discountAmount', 0);
            }
        }
        const oldSupplier = getStringFromObject('supplier', prevProps.values);
        const newSupplier = getStringFromObject('supplier', this.props.values);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
        }
        // if (!deepEqual(oldSupplier, newSupplier)) {
        //     this.getSubCompanyOfSupplier(newSupplier);
        // }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountInvoice());
    }

    onPrintJournalEntriesClick = () => {
        this.fetchJournalEntriesAndPrint(false);
    };

    onPrintConsolidatedJournalEntriesClick = () => {
        this.fetchJournalEntriesAndPrint(true);
    };

    onPrintClick = () => {
        this.setState(prevState => ({
            print: !prevState.print,
        }));
    };

    onTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    setPaymentTermsFromSupplier = (nextProps, supplier) => {
        const supplierUuid = getStringFromObject('uuid', supplier);
        if (supplierUuid) {
            nextProps.dispatch(commonGetApiRequest(`${API.SUPPLIER.FIND}${supplierUuid}`, {
                successCallback: (partner) => {
                    nextProps.setFieldValue('terms', partner.paymentTerms);
                    this.setDueDateOnPaymentTermChange(partner.paymentTerms, nextProps);
                },
            }));
        }
    };

    setDueDateOnPaymentTermChange = (paymentTerms, nextProps) => {
        const dueDate = calculateDueDateFromPaymentTerm(getStringFromObject('value.key', paymentTerms));
        // nextProps.setFieldValue('dateInvoice', dueDate);
        nextProps.setFieldValue('dueDate', dueDate);
    };

    getSubCompanyOfSupplier = async (supplier) => {
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        console.log('asd09audas09dsa', subCompany);
        this.setState({
            subCompany,
        });
    };

    fetchJournalEntriesAndPrint = (consolidated = false) => {
        const {
            selectedInvoice,
        } = this.state;
        if (isObjectValidAndNotEmpty(selectedInvoice)) {
            if (isObjectValidAndNotEmpty(selectedInvoice.accountMove)) {
                // account move already fetched.. no need to fetch again.
                this.printJournalEntries(selectedInvoice.accountMove, consolidated);
            } else {
                // not fetched.. fetch it first before proceeding
                this.props.dispatch(showSpinner());
                axios.get(`${API.ACCOUNT_MOVES.GET_ONE}${selectedInvoice.accountMoveUuid}`)
                    .then((response) => {
                        console.log('as-d0iasdps9oajdpasojds', response.data, consolidated);
                        this.props.dispatch(hideSpinner());
                        const copyOfSelectedInvoice = cloneDeep(selectedInvoice);
                        copyOfSelectedInvoice.accountMove = response.data;
                        this.printJournalEntries(copyOfSelectedInvoice.accountMove, consolidated);
                        this.setState({
                            selectedInvoice: copyOfSelectedInvoice,
                        });
                    })
                    .catch((error) => {
                        apiCatchBlockFunction(error, this.props.dispatch);
                    });
            }
        }
    };

    printJournalEntries = (accountMove, consolidated) => {
        let journalEntryPrintData = { ...accountMove, isConsolidated: false };
        if (!isStringNullOrUndefined(journalEntryPrintData.cashPartnerName)) {
            journalEntryPrintData.partner = { key: '', value: accountMove.cashPartnerName };
            journalEntryPrintData.vatNumber = accountMove.cashPartnerIdNumber || '';
        }
        if (consolidated) {
            // consolidate the move lines based on account
            journalEntryPrintData = getConsolidatedJournalEntriesMemoize(accountMove);
        }
        this.setState(prevState => ({
            selectedInvoice: { ...prevState.selectedInvoice, accountMove },
            journalEntryPrintData,
            printJournal: !prevState.printJournal,
        }));
    };

    loadBillFromPO = (poId) => {
        this.props.dispatch(showSpinner());
        console.log(poId);
        axios.get(`${API.PURCHASE_ORDER.FETCH_ONE}?id=${poId}`)
            .then((response) => {
                this.props.dispatch(hideSpinner());
                const poUiObject = getUiObject(response.data);
                this.initializeForm(getUiObjectFromPurchaseOrder(poUiObject));
                this.setPaymentTermsFromSupplier(this.props, poUiObject.supplier);
            })
            .catch((error) => {
                apiCatchBlockFunction(error, this.props.dispatch);
            });
        this.handleClosePOsDialog();
    };

    reloadComponent = () => {
        const {
            purchaseOrder,
            dispatch,
            invoiceId, materialReceive,
            values,
        } = this.props;
        console.log('reloadComponentreloadComponent', {
            purchaseOrder, dispatch, invoiceId, materialReceive,
        });
        if (getStringFromObject('uuid', values)) {
            dispatch(fetchAccountInvoiceByUuid(getStringFromObject('uuid', values)));
        }
        if (invoiceId) {
            dispatch(fetchAccountInvoiceById(invoiceId));
        } else if (purchaseOrder.uuid) {
            dispatch(fetchAccountInvoiceForPurchaseOrderRequest(purchaseOrder.uuid));
        }
        if (isObjectValidAndNotEmpty(purchaseOrder)) {
            this.initializeForm(getUiObjectFromPurchaseOrder(purchaseOrder));
            this.setPaymentTermsFromSupplier(this.props, purchaseOrder.supplier);
        }
        if (materialReceive) {
            console.log('materialReceivematerialReceive', materialReceive);
            if (getStringFromObject('invoice.key', materialReceive)) {
                dispatch(fetchAccountInvoiceByUuid(getStringFromObject('invoice.key', materialReceive)));
            } else {
                this.initializeForm(getUiObjectFromMaterialReceive(materialReceive));
            }
        }
    };

    initializeForm = (formValues) => {
        const { resetForm } = this.props;
        resetForm(formValues);
    };

    initializeFormWithApiResponse = (reponse) => {
        if (isObjectValidAndNotEmpty(reponse)) {
            this.initializeForm(getUiObjectFromAccountInvoiceResponse(reponse));
        }
    };

    handleSave = () => {
        const { setFieldValue, submitForm, values } = this.props;
        const { enableServices } = this.state;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.DRAFT);
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        setFieldValue('isService', enableServices || isServiceInvoice);
        submitForm();
    };

    handleConfirm = () => {
        const { setFieldValue, submitForm, values } = this.props;
        const { cancelInvoice, subCompany, enableServices } = this.state;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.CONFIRM);
        setFieldValue('subCompany', subCompany);
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        setFieldValue('isService', enableServices || isServiceInvoice);
        if (cancelInvoice) {
            setFieldValue('cancelInvoice', cancelInvoice);
            setFieldValue('showReturnStockDialog', this.showReturnStockDialog);
        }
        submitForm();
    };

    handleClickMakePayment = () => this.setState({ showPaymentDialog: true });

    handleClosePaymentDialog = reload => this.setState({ showPaymentDialog: false }, () => {
        if (reload === true) {
            this.reloadComponent();
        }
    });

    handleClosePOsDialog = () => this.setState({ showPOsDialog: false });

    poSelected = (rowData, rowMeta) => {
        console.log('Row Data', rowData, rowMeta);
        console.log('Row Data', this.state.pos[rowMeta.rowIndex]);
        this.loadBillFromPO(this.state.pos[rowMeta.rowIndex].id);
    };

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    handleChooseSupplier = (supplier) => {
        const { setFieldValue } = this.props;
        if (isObjectValidAndNotEmpty(supplier)) {
            setFieldValue('mailingAddress', getAddressFromAddressDto(supplier));
            setFieldValue('dateInvoice', formatDate(new Date(), 'yyyy-MM-dd'));
            const paymentTerms = getStringFromObject('paymentTerms', supplier);
            if (isObjectValidAndNotEmpty(paymentTerms)) {
                setFieldValue('terms', paymentTerms);
                this.handleChoosePayterm(paymentTerms);
            }
            let construct = getCombined(
                generateObjectWithCriteriaMode(
                    'purchase_order.invoiced',
                    false,
                    '=',
                ),
                'AND',
                generateObjectWithCriteriaMode(
                    'purchase_order.state',
                    'draft',
                    '!=',
                ),
            );
            construct = getCombined(
                construct,
                'AND',
                generateObjectWithCriteriaMode('res_partner.id', supplier.id, '='),
            );
            //  show the POs if any un-invoiced
            this.props.dispatch(showSpinner());
            axios.post(`${API.PURCHASE_ORDER.PAGES}?page=${0}&size=${500}&sortCol=name`, construct)
                .then((response) => {
                    console.log('as-d0iasdps9oajdpasojds', response.data);
                    this.props.dispatch(hideSpinner());
                    const stringFromObject = getStringFromObject('totalElements', response.data, 0);
                    console.log('as-d0iasdps9oajdpasojds', stringFromObject);
                    if (stringFromObject > 0) {
                        this.setState({
                            pos: response.data.content,
                            showPOsDialog: true,
                        });
                    }
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, this.props.dispatch);
                });
        }
    };

    handleChoosePayterm = (payterm) => {
        if (isObjectValidAndNotEmpty(payterm)) {
            this.setDueDateOnPaymentTermChange(payterm, this.props);
        }
    };

    handleClickCancel = () => {
        this.setState({ readOnly: false, cancelInvoice: true });
    };

    showReturnStockDialog = () => {
        this.setState({ returnStockDialog: true, readOnly: true, cancelInvoice: false });
    };

    handleCloseReturnStockDialog = () => {
        this.setState({ returnStockDialog: false }, this.props.handleClose);
    };

    handleConfirmCancel = () => {
        const { setFieldValue, submitForm } = this.props;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.CONFIRM);
        setFieldValue('cancelInvoice', true);
        setFieldValue('accountInvoiceLines', null);
        setFieldValue('showReturnStockDialog', this.showReturnStockDialog);
        submitForm();
    };

    handleCancelInvoiceComplete = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'Are you sure want to cancel the invoice completely ? This operation can not be undone.',
            this.handleConfirmCancel,
        ));
    };

    handleChangeDiscountPerc = () => {
        const { setFieldValue, values } = this.props;
        console.log('dicountchangesshandleChangeDiscountPerc', values);
        setFieldValue('discountAmount', 0);
    };

    handleChangeDiscountAmount = () => {
        const { setFieldValue, values } = this.props;
        console.log('dicountchangesshandleChangeDiscountAmount', values);

        setFieldValue('discountPer', 0);
    };

    handleToggleServices = (e, enableServices) => {
        const { values, dispatch, setFieldValue } = this.props;
        const lines = getStringFromObject('accountInvoiceLines', values, []);
        if (isArrayValidAndNotEmpty(lines)) {
            dispatch(displayWarning(
                'Any invoice lines added will be cleared !! Are you sure want to continue ?',
                () => {
                    // eslint-disable-next-line max-len
                    this.setState({ enableServices }, () => setFieldValue('accountInvoiceLines', [cloneDeep(INVOICE_LINE_UI_OBJECT)]));
                },
            ));
        } else {
            this.setState({ enableServices });
        }
    };

    handleCashPurchaseCheck = () => {
        const { setFieldValue } = this.props;
        setFieldValue('supplier', null);
        setFieldValue('cashPartnerName', '');
        setFieldValue('cashPartnerIdNumber', '');
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            isValid,
            billNoMandatoryForPurchaseInvoice,
            materialReceive,
            enableSubVendorCheckBox,
            additionalPurchaseInvoiceDetails,
        } = this.props;
        const {
            schema,
            readOnly,
            showPaymentDialog,
            paymentEnabled,
            selectedInvoice,
            print,
            printJournal,
            journalEntryPrintData,
            selectedTab,
            showPOsDialog,
            pos,
            cancelInvoice,
            returnStockDialog,

            enableServices,

            subCompany,

        } = this.state;
        console.log('PurchaseInvoiceSchema', schema);
        console.log('PurchaseInvoiceValues', values);
        const isCancelled = getStringFromObject('state', values) === 'cancel';
        const accountInvoiceLines = getStringFromObject('accountInvoiceLines', values, []);
        const length = NumberOf(getStringFromObject('accountInvoiceLines.length', values));
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const hasDiscountHead = true;
        let amountUntaxed = 0;
        // eslint-disable-next-line prefer-const
        let totalSalePrice = 0;
        console.log('totalSalePrice: ', totalSalePrice);
        let totalTax = 0;
        if (isArrayValidAndNotEmpty(accountInvoiceLines)) {
            accountInvoiceLines.forEach((line) => {
                console.log('line:23213123 ', line);
                amountUntaxed += (NumberOf(line.amount) - (NumberOf(line.discountAmount) + NumberOf(line.discountAmount2)));
                totalTax += NumberOf(line.totalTax);
                if (line.rate) {
                    totalSalePrice += NumberOf(line.rate ? line.rate : 0) * (NumberOf(line.bonus) + NumberOf(line.quantity));
                }
            });
        }
        const total = amountUntaxed + totalTax;
        let totalDiscount = NumberOf(getStringFromObject('discountAmount', values));
        const discountPerc = NumberOf(getStringFromObject('discountPer', values));
        if (discountPerc > 0) {
            totalDiscount = total * (discountPerc / 100);
        }
        const invoiceNumber = getStringFromObject('number', values);
        // let discountSearchApi = '';
        // if (isObjectValidAndNotEmpty(schema)) {
        //     discountSearchApi = `${getStringFromObject('discountHeadApi', schema)}?subCompany=${subCompany || ''}&searchString=`;
        // }
        let formSchema = { ...schema };
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        if (enableServices || isServiceInvoice) {
            formSchema = {
                ...schema,
                tableCells: getStringFromObject('serviceTableCells', schema, []),
            };
        }
        const hasPayment = isArrayValidAndNotEmpty(getStringFromObject('paymentDetails', selectedInvoice));

        const autoDiscountConfig = getStringFromObject('autoDiscountConfig', schema);
        const isCashPurchaseOrSale = getStringFromObject('isCashPurchaseOrSale', values) || false;
        const cashPartnerSubCompany = getStringFromObject('cashPartnerSubCompany.key', values) || '';
        const marginAmount = valueToFixedTwoDigits(NumberOf(totalSalePrice) - NumberOf(amountUntaxed));
        let marginPercentage = 0;
        if (amountUntaxed) {
            marginPercentage = valueToFixedTwoDigits(((NumberOf(totalSalePrice) - NumberOf(amountUntaxed)) / NumberOf(amountUntaxed)) * 100);
        }
        console.log('asdayu0d9au0dasdasd', autoDiscountConfig, '---', (billNoMandatoryForPurchaseInvoice && !isObjectValidAndNotEmpty(materialReceive)));
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    Purchase Invoice {
                                        invoiceNumber
                                            ?
                                            '('.concat(invoiceNumber).concat(')')
                                            :
                                            ''
                                    }
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} testId="purchase-invoice-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" spacing={16} >
                                <Grid item lg={3} md={4} sm={5}>
                                    <Field
                                        testId="supplier"
                                        name="supplier"
                                        test-id="purachase-invoice-supplier"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        label="Supplier name"
                                        isDisabled={readOnly || isCashPurchaseOrSale}
                                        autocomplete
                                        required={!isCashPurchaseOrSale}
                                        validate={isCashPurchaseOrSale ? null : required}
                                        dataSourceApi={API.SEARCH.SUPPLIER}
                                        onSelectHandlers={['handleChooseSupplier']}
                                        actionHandlers={{
                                            handleChooseSupplier: this.handleChooseSupplier,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={1} sm={3} md={2}>
                                    <Field
                                        name="isCashPurchaseOrSale"
                                        component={FormikCheckbox}
                                        label="Cash Purchase"
                                        disabled={readOnly}
                                        onChangeHandlers={['handleCashPurchaseCheck']}
                                        actionHandlers={{
                                            handleCashPurchaseCheck: this.handleCashPurchaseCheck,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchaseOrSale && (
                                        <Field
                                            name="cashPartnerName"
                                            label="Supplier Name"
                                            component={FormikTextField}
                                            disabled={readOnly}
                                            normalize={upperCaseNormalizer}
                                            validate={required}
                                            required
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchaseOrSale && (
                                        <Field
                                            name="cashPartnerIdNumber"
                                            label="Vat Number"
                                            component={FormikTextField}
                                            disabled={readOnly}
                                            fullWidth
                                        />
                                    )}
                                </Grid >
                                <Grid item lg={2} sm={4} md={3}>
                                    {isCashPurchaseOrSale && (
                                        <Field
                                            name="cashPartnerSubCompany"
                                            label="Sub Company"
                                            component={FormikReactSelectMaterial}
                                            isDisabled={readOnly}
                                            options={Object.values(subCompanies).map(aSubCompany => ({
                                                key: aSubCompany,
                                                value: aSubCompany,
                                            }))}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            fullWidth
                                            validate={required}
                                            required
                                        />
                                    )}
                                </Grid >
                                <Grid item lg={2} sm={4} md={3} container justify="flex-end" spacing={8}>
                                    {

                                        (readOnly && paymentEnabled) &&
                                        <Grid item>
                                            <ActionButton
                                                testId="purachase-invoice-make-payment"
                                                disableRipple
                                                onClick={this.handleClickMakePayment}
                                            >
                                            Make Payment
                                            </ActionButton>
                                        </Grid>
                                    }
                                    {
                                        readOnly && !isCancelled && !hasPayment &&
                                        <Grid item>
                                            <ActionButton
                                                test-id="purachase-invoice-edit-invoice"
                                                onClick={this.handleClickCancel}
                                            >
                                                Edit Invoice
                                            </ActionButton>
                                        </Grid>
                                    }
                                </Grid>

                            </Grid>
                            <Grid container className="mt-2" spacing={8}>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-terms"
                                        name="terms"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value.value',
                                            value: 'key',
                                        }}
                                        label="Terms"
                                        isDisabled={readOnly}
                                        autocomplete
                                        dataSourceApi={API.SEARCH.ACCOUNT_PAYMENT_TERM}
                                        onSelectHandlers={['handleChoosePayterm']}
                                        actionHandlers={{
                                            handleChoosePayterm: this.handleChoosePayterm,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-date-invoice"
                                        name="dateInvoice"
                                        component={FormikTextField}
                                        type="date"
                                        label="Bill date"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-duedate"
                                        name="dueDate"
                                        component={FormikTextField}
                                        label="Due date"
                                        type="date"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                {
                                    enableSubVendorCheckBox && (
                                        <Grid item lg={1} sm={3} md={2}>
                                            <Field
                                                name="subVendor"
                                                component={FormikCheckbox}
                                                label="Sub Vendor"
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid
                                    container
                                    item
                                    lg={5}
                                    justify="flex-start"
                                    direction="column"
                                    spacing={8}
                                    alignItems="flex-end"
                                >

                                    <Grid item>
                                        <Field
                                            test-id="purachase-invoice-supplierInvoiceNumber"
                                            name="supplierInvoiceNumber"
                                            component={FormikTextField}
                                            label="Bill no"
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required={billNoMandatoryForPurchaseInvoice}
                                            validate={billNoMandatoryForPurchaseInvoice ? required : undefined}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Field
                                            name="paymentMeans"
                                            label="Payment Means"
                                            component={FormikOutlinedSelectField}
                                            InputProps={{
                                                classes: {
                                                    input: classes.selectInput,
                                                },
                                            }}
                                            disabled={readOnly}
                                            style={{
                                                minWidth: '15em',
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            menuItems={Object.values(paymentMeans).map(aPaymentMean => (
                                                <MenuItem key={aPaymentMean} value={aPaymentMean} >
                                                    {aPaymentMean.replace('_', ' ')}
                                                </MenuItem>
                                            ))}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                <Grid container spacing={32}>
                                    <Grid item>
                                        <Tabs value={selectedTab} onChange={this.onTabChange}>
                                            {
                                                Object.keys(makeBillDialogTabs).map(aTab => (
                                                    <Tab
                                                        key={aTab}
                                                        test-id={aTab}
                                                        value={makeBillDialogTabs[aTab]}
                                                        label={makeBillDialogTabs[aTab]}
                                                    />
                                                ))
                                            }
                                        </Tabs>
                                    </Grid>
                                    <Grid item>
                                        {
                                            // eslint-disable-next-line max-len
                                            !readOnly && !accountInvoiceUuid && selectedTab === makeBillDialogTabs.ITEM_DETAILS && checkIfPrivilegeExistsForUser(['create.service.invoice']) &&
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={enableServices}
                                                        onChange={this.handleToggleServices}
                                                        value="services"
                                                    />
                                                }
                                                label="Switch To Services"
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container className="mt-1">
                                    {
                                        selectedTab === makeBillDialogTabs.ITEM_DETAILS &&
                                        isObjectValidAndNotEmpty(schema) &&
                                        <FormikTable
                                            fieldName="accountInvoiceLines"
                                            actionHandlers={ACTION_HANDLERS(autoDiscountConfig)}
                                            isEditable={!readOnly}
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            {...formSchema}
                                            key={enableServices ? 'serviceInvoiceTable' : 'productInvoiceTable'}
                                            formValues={values}
                                        />
                                    }
                                    {
                                        selectedTab === makeBillDialogTabs.PAYMENT_DETAILS &&
                                        <PaymentDetailsTab
                                            selectedInvoice={selectedInvoice}
                                        />
                                    }
                                </Grid>
                                <Grid container className="mt-1" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            test-id="purachase-invoice-comment"
                                            name="comment"
                                            component={FormikTextField}
                                            label="Memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={2} />
                                    <Grid item lg={2} md={2} sm={3} alignItems="flex-end">
                                        <Grid container direction="column" spacing={8} className="mt-0-5">
                                            {/* <Grid item>
                                                <Field
                                                    test-id="purchase-order-discount-head"
                                                    name="discountAccount"
                                                    component={FormikReactSelectMaterial}
                                                    dataSourceConfig={{
                                                        text: 'value',
                                                        value: 'key',
                                                    }}
                                                    label="Discount Head"
                                                    autocomplete
                                                    key={subCompany}
                                                    dataSourceApi={discountSearchApi}
                                                    isDisabled={readOnly}
                                                />
                                            </Grid> */}
                                            {
                                                hasDiscountHead &&
                                                <Grid item>
                                                    <Grid container spacing={8}>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="purchase-order-discount-perc"
                                                                label="Discount (%)"
                                                                name="discountPer"
                                                                component={FormikTextField}
                                                                type="number"
                                                                fullWidth
                                                                min={0}
                                                                max={100}
                                                                validate={this.maxDiscount(0, 100)}
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountPerc']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="purchase-order-discount-amount"
                                                                label="Discount Amount"
                                                                name="discountAmount"
                                                                component={FormikTextField}
                                                                validate={this.maxDiscount(0, total)}
                                                                min={0}
                                                                max={total}
                                                                type="number"
                                                                fullWidth
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountAmount']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item container justify="flex-end" className="mb-3">
                                        <Grid container lg={3} md={3} sm={4} justify={`${additionalPurchaseInvoiceDetails ? 'space-between' : 'flex-end'}`}>
                                            {additionalPurchaseInvoiceDetails &&
                                                <Grid item lg={6} md={6} sm={9}>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                    Total Sales Price:&nbsp;&nbsp; {valueToFixedTwoDigits(NumberOf(totalSalePrice))}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                    Margin (Amount):&nbsp;&nbsp;{marginAmount}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                    Margin (%):&nbsp;&nbsp;{marginPercentage}
                                                    </div>
                                                </Grid>}
                                            <Grid item lg={6} md={6} sm={3}>
                                                <Grid container alignContent="flex-end" direction="column">
                                                    <div className={classes.totalField}>
                                                Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(NumberOf(amountUntaxed))}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                Tax:&nbsp;&nbsp;{valueToFixedTwoDigits(totalTax)}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(totalDiscount)}
                                                    </div>
                                                    <hr />
                                                    <div className={classNames('mt-1', classes.totalField)}>
                                                Net Amount:&nbsp;&nbsp;
                                                        {valueToFixedTwoDigits(Math.max(total - totalDiscount, 0))}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid container className="mt-2">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <div style={{ color: '#0000008a' }}>Attachments</div>
                                        <div className={classes.fileUpload}>
                                            <Field
                                                name="attachments"
                                                disabled={readOnly}
                                                component={UploadFile}
                                            />
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="purachase-invoice-cancel"
                                primary={false}
                                disableRipple
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            {
                                readOnly && !isCancelled && !cancelInvoice && !hasPayment &&
                                <ActionButton
                                    test-id="purachase-invoice-cancel-invoice"
                                    className="ml-1"
                                    secondary
                                    disableRipple
                                    onClick={this.handleCancelInvoiceComplete}
                                >
                                    Cancel Invoice
                                </ActionButton>
                            }
                            {
                                !readOnly &&
                                <React.Fragment>
                                    {
                                        !cancelInvoice &&
                                        <ActionButton
                                            testId="purachase-invoice-readonly-save"
                                            className="ml-1"
                                            disableRipple
                                            onClick={this.handleSave}
                                            disabled={(readOnly || (!isValid))}
                                        >
                                            Save
                                        </ActionButton>
                                    }
                                    <ActionButton
                                        testId="edit-cancel-create-new"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.handleConfirm}
                                        disabled={readOnly}
                                    >
                                        {
                                            cancelInvoice
                                                ?
                                                `${length > 0 ? 'Cancel and create new' : 'Edit Invoice'}`
                                                :
                                                'Confirm'
                                        }
                                    </ActionButton>
                                </React.Fragment>
                            }
                            {
                                readOnly &&
                                <React.Fragment>
                                    <ActionButton
                                        test-id="purachase-invoice-printjournals-consolidated"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintConsolidatedJournalEntriesClick}
                                    >
                                        Print Journals Consolidated
                                    </ActionButton>
                                    <ActionButton
                                        test-id="purachase-invoice-print-journals"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintJournalEntriesClick}
                                    >
                                        Print Journals
                                    </ActionButton>
                                    <ActionButton
                                        testId="purachase-invoice-print"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintClick}
                                    >
                                        Print
                                    </ActionButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                        {
                            showPOsDialog &&
                            <POListPopUp
                                data={pos}
                                open={showPOsDialog}
                                handleClose={this.handleClosePOsDialog}
                                onRowClick={this.poSelected}
                            />
                        }
                    </Dialog>
                </form>
                {
                    showPaymentDialog &&
                    <ReceivePaymentDialogWrapper
                        open
                        isSupplier
                        accountInvoiceUuid={accountInvoiceUuid}
                        handleClose={this.handleClosePaymentDialog}
                        subCompany={isCashPurchaseOrSale ? cashPartnerSubCompany : subCompany}
                    />
                }
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PurchaseInvoice/PurchaseInvoice.html`}
                    data={{
                        ...getPaymentInvoiceDetailsToPrintMemoize(selectedInvoice),
                        serviceInvoice: enableServices || isServiceInvoice,
                    }}
                    print={print}
                    subCompany={isCashPurchaseOrSale ? cashPartnerSubCompany : subCompany}
                />
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                    data={journalEntryPrintData}
                    print={printJournal}
                    subCompany={isCashPurchaseOrSale ? cashPartnerSubCompany : subCompany}
                />
                {
                    returnStockDialog &&
                    <GoodReturnDialog
                        open
                        handleClose={this.handleCloseReturnStockDialog}
                        invoiced
                        partner={getStringFromObject('supplier', values)}
                        purchaseOrderUuid={getStringFromObject('purchaseOrder.uuid', values)}
                    />
                }
            </React.Fragment>
        );
    }
}

MakeBillDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    purchaseOrder: PropTypes.object,
    materialReceive: PropTypes.object,
    selectedInvoices: PropTypes.array,
    invoiceId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    /* formik props */
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    billNoMandatoryForPurchaseInvoice: PropTypes.bool,
    type: PropTypes.string,
    enableSubVendorCheckBox: PropTypes.bool,
    additionalPurchaseInvoiceDetails: PropTypes.bool,
};

MakeBillDialog.defaultProps = {
    materialReceive: null,
    purchaseOrder: {},
    selectedInvoices: [],
    invoiceId: '',
    billNoMandatoryForPurchaseInvoice: false,
    type: 'Account Invoice',
    enableSubVendorCheckBox: false,
    additionalPurchaseInvoiceDetails: false,
};

const mapStateToProps = (state) => {
    console.log('jksadfhalksjf', state);
    return {
        selectedInvoices: state.accountInvoice.selectedInvoice,
        billNoMandatoryForPurchaseInvoice:
            getStringFromObject('appConfiguration.billNoMandatoryForPurchaseInvoice', state),
        enableSubVendorCheckBox:
            getStringFromObject('appConfiguration.enableSubVendorCheckBox', state),
        additionalPurchaseInvoiceDetails: getStringFromObject('appConfiguration.additionalPurchaseInvoiceDetails', state),
    };
};

const handleSubmitForm = (values, { props }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    const materialReceiveUuid = getStringFromObject('materialReceive.uuid', props);
    let payload = getAccountInvoiceFromUiObject(values);

    console.log('asd0ad-0asid-d', values);
    if (values.cancelInvoice) {
        payload.uuid = null;
        const length = NumberOf(getStringFromObject('accountInvoiceLines.length', values));
        if (length <= 0) {
            payload = null;
        }
        const isGoodsReceived = getStringFromObject('goodsReceived', values, false);
        dispatcher(cancelAccountInvoiceRequest(values.uuid, payload, () => {
            if (isGoodsReceived) {
                dispatcher(displayWarning(
                    'Some stock have been received, please click \'yes\' to open dialog to return Stock.',
                    getStringFromObject('showReturnStockDialog', values, () => { }),
                    null,
                    null,
                ));
            }
        }));
    } else if (materialReceiveUuid) {
        payload.materialReceiveUuid = materialReceiveUuid;
        dispatcher(createAccountInvoiceRequestForMaterialReceive(payload, null, null, props.type));
    } else {
        dispatcher(createAccountInvoiceRequest(payload, null, null, props.type));
    }
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(INVOICE_UI_OBJECT),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(makeBillDialogStyles)(MakeBillDialog)));
