import initialStates from '../../initialStates';
import {
    FETCH_PAYROLL_DETAILS_SUCCESS,
    FETCH_PAYSLIP_DETAILS_REQUEST,
    FETCH_PAYSLIP_DETAILS_SUCCESS,
} from './employeeParyoll-actions';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const employeePayrollReducer = (state = initialStates.employeePayroll, action) => {
    let newState = {};

    switch (action.type) {
    case FETCH_PAYROLL_DETAILS_SUCCESS:
        newState = {
            ...state,
            payrollDetails: getStringFromObject('data.payslipDto', action, {}),
            contractDate: getStringFromObject('data.contractStartDate', action,
                getDateInYYYYMMDDFormat(new Date())),
        };
        break;
    case FETCH_PAYSLIP_DETAILS_REQUEST:
        newState = {
            ...state,
            payslipDetails: {},
            isPayslipFetched: false,
        };
        break;
    case FETCH_PAYSLIP_DETAILS_SUCCESS:
        newState = {
            ...state,
            payslipDetails: action.data,
            isPayslipFetched: true,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default employeePayrollReducer;
