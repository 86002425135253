/**
 * Created by sachinprakash on 12/8/19.
 */
import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { dutyRosterSettings, dutyRosterShiftsSettings } from '../constants/constants';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const DutyRostersSettingsPage = React.lazy(() => import('../pages/DutyRosterSettingsPages/DutyRostersSettingsPage'));
const DutyRosterShiftsSettingsPage = React.lazy(() => import('../pages/DutyRosterSettingsPages/DutyRosterShiftsSettingsPage'));

const DutyRosterSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={dutyRosterShiftsSettings} exact component={DutyRosterShiftsSettingsPage} />
            <Route path={dutyRosterSettings} exact component={DutyRostersSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default DutyRosterSettingRoutes;
