import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MakeBillDialog from '../../components/PurchaseOrderComponents/MakeBillDialog/MakeBillDialog';
import { ACTION_HANDLERS } from '../../components/PurchaseOrderComponents/AddStockActionHandlers';
import { fetchPurchaseInvoiceForSupplier } from '../../redux/modules/accountInvoice/accountInvoice-actions';

class SupplierPurchaseBillContainer extends React.Component {
    componentDidMount() {
        const { match, dispatch } = this.props;
        if (match && match.params) {
            dispatch(fetchPurchaseInvoiceForSupplier(match.params.id, match.params.shopId));
        }
    }

    render() {
        const { open, handleClose } = this.props;
        return (
            <MakeBillDialog
                open={open}
                handleClose={handleClose}
                actionHandlers={ACTION_HANDLERS}
                type="Purchas Bill"
            />
        );
    }
}

SupplierPurchaseBillContainer.propTypes = {
    open: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default connect()(SupplierPurchaseBillContainer);
