import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import api from '../../constants/api';
import DoctorQueueDialog from '../RegistrationAppComponents/QuickRegistration/DoctorQueueDialog/DoctorQueueDialog';
import { recentQueueSchema } from './RecentPatientsUtil';
import {
    apiCatchBlockFunction, getJsonPath,
    reprocessJson,
} from '../../constants/CommonUtil';
import { getPotentialEpisodeOfCareDetails } from '../QueueNodeDisplayContainer/QueueUtils';
import { APPLICATION_CONFIG_URL, GLOBAL_PROPERTIES } from '../../constants/constants';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class RecentPatientsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
        };
    }

    componentDidMount() {
        this.fetchAndProcessSchema();
    }

    fetchAndProcessSchema = async () => {
        const { appConfiguration, dispatch } = this.props;
        try {
            const response = await axios.get(
                getJsonPath('/RecentPatientsList/RecentPatientsList.json', APPLICATION_CONFIG_URL),
            );
            const responseData = response.data;
            const encounterStatusBitHelper = appConfiguration[GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP];
            const schema = reprocessJson(appConfiguration, recentQueueSchema(responseData, encounterStatusBitHelper));
            this.setState({
                schema,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    }

    openEmrApp = async (data) => {
        const { appConfiguration, dispatch } = this.props;
        try {
            const link = appConfiguration.consultationBaseLink;
            const patientUuid = getStringFromObject('patient.key', data);
            const episodeOfCareUuid = getStringFromObject('episodeOfCareUuid', data);
            const episodeProviderUuid = getStringFromObject('episodeProviderUuid', data);
            const tokenUuid = getStringFromObject('tokenUuid', data);
            const { eocUuid, epUuid } = await getPotentialEpisodeOfCareDetails(
                patientUuid,
                appConfiguration,
                dispatch,
                episodeOfCareUuid,
                tokenUuid,
                episodeProviderUuid,
            );
            const url =
                // eslint-disable-next-line
                `${link}/clinical/${patientUuid}/${eocUuid || 'na'}/${epUuid || 'na'}/consultations`;
            window.open(url, `${patientUuid}-${eocUuid || 'na'}-${epUuid || 'na'}`);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    handleRowClick = (_, __, ___, row) => {
        if (isObjectValidAndNotEmpty(row)) {
            this.openEmrApp(row);
        }
    };

    handleRowButtonClick = async (data) => {
        const { dispatch } = this.props;
        if (isObjectValidAndNotEmpty(data)) {
            try {
                const tokenUuid = getStringFromObject('tokenUuid', data);
                dispatch(showSpinner());
                await axios.post(`${api.QUEUES.RECALL_TOKEN}?tokenUuid=${tokenUuid}`);
                dispatch(hideSpinner());
                this.openEmrApp(data);
            } catch (e) {
                dispatch(hideSpinner());
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    render() {
        const { schema } = this.state;
        console.log('asdua0s9dsuad0-as', schema);
        return (
            <div className="m1">
                <DoctorQueueDialog
                    dataApi={api.PATIENTS.RECENT_PATIENTS}
                    recentQueue
                    tableSchema={schema}
                    onRowClick={this.handleRowClick}
                    onRowButtonClick={this.handleRowButtonClick}
                />
            </div>
        );
    }
}

RecentPatientsV2.propTypes = {
    appConfiguration: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
});

export default connect(mapStateToProps)(RecentPatientsV2);
