import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/Button';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core';

import { getStringFromObject } from '../../constants/lodashUtils';

const style = theme => ({
    primaryButtonRoot: getStringFromObject('primaryActionButton.root', theme, {}),
    primaryButtonLabel: getStringFromObject('primaryActionButton.label', theme, {}),
    secondaryButtonRoot: getStringFromObject('secondaryButton.root', theme, {}),
    secondaryButtonLabel: getStringFromObject('secondaryButton.label', theme, {}),
    disabledButtonLabel: getStringFromObject('disabledButton.label', theme, {}),
    disabledButtonRoot: getStringFromObject('disabledButton.root', theme, {}),
});

const ActionButton = ({
    classes,
    className,
    children,
    primary,
    disabled,
    toolTipTitle,
    disableTool,
    testId,
    ...otherProps
}) => {
    let rootClass = classNames(className, classes.primaryButtonRoot);
    let labelClass = classes.primaryButtonLabel;
    if (!primary) {
        rootClass = classNames(className, classes.secondaryButtonRoot);
        labelClass = classes.secondaryButtonLabel;
    }
    if (disabled) {
        rootClass = classNames(className, classes.disabledButtonRoot);
        labelClass = classes.disabledButtonLabel;
    }
    const buttonWithChild = (
        <Button
            {...otherProps}
            classes={{
                root: rootClass,
                label: labelClass,
            }}
            disabled={disabled}
            test-id={testId}
        >
            {children}
        </Button>
    );
    if (disableTool) {
        return buttonWithChild;
    }
    return (
        <Tooltip
            title={toolTipTitle}
            disableFocusListener={disableTool}
            disableHoverListener={disableTool}
            disableTouchListener={disableTool}
        >
            <span>
                {buttonWithChild}
            </span>
        </Tooltip>
    );
};

ActionButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    testId: PropTypes.string,
    classes: PropTypes.object,
    primary: PropTypes.bool,
    disabled: PropTypes.bool,
    disableTool: PropTypes.bool,
    toolTipTitle: PropTypes.node,
};

ActionButton.defaultProps = {
    children: null,
    className: '',
    testId: '',
    primary: true,
    disabled: false,
    classes: {},
    toolTipTitle: null,
    disableTool: true,
};

export default withStyles(style)(ActionButton);

