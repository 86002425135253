import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import OutlinedTextField from '../OutlinedTextField';
import { commonGetApiRequest } from '../../redux/modules/common/common-actions';
import { doesUrlContainsAllParams, formatUrl } from '../../constants/UrlUtil';

class RemoteTextValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    componentDidMount() {
        const { dataSourceApi, formValues } = this.props;
        this.loadData(formatUrl(dataSourceApi, formValues));
    }

    componentDidUpdate(prevProps) {
        const newApi = formatUrl(this.props.dataSourceApi, this.props.formValues);
        const oldApi = formatUrl(this.props.dataSourceApi, prevProps.formValues);
        if (newApi !== oldApi) {
            this.loadData(newApi);
        }
    }

    loadData = (api) => {
        const { requiredParams } = this.props;
        if (api && doesUrlContainsAllParams(api, requiredParams)) {
            this.props.dispatch(commonGetApiRequest(api, {
                hideSpinner: false,
                successCallback: v => this.setState({ value: v }),
            }));
        }
    };

    render() {
        const { value } = this.state;
        const {
            label,
        } = this.props;
        console.log('RemoteTextValue', this.props);
        return (
            <OutlinedTextField
                disabled
                label={label}
                value={value}
            />
        );
    }
}

RemoteTextValue.propTypes = {
    dispatch: PropTypes.func.isRequired,
    dataSourceApi: PropTypes.string.isRequired,
    requiredParams: PropTypes.array,
    formValues: PropTypes.object,
    label: PropTypes.string,
};

RemoteTextValue.defaultProps = {
    label: '',
    formValues: {},
    requiredParams: [],
};

export default connect()(RemoteTextValue);

