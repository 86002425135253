import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_ORGANIZATION_LIST_FAILURE,
    FETCH_ORGANIZATION_LIST_SUCCESS,
} from '../../redux/modules/organization/organization-actions';
import specsForFilter from './SearchConstruct';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export default function* fetchOrganizationListWorkerSaga(action) {
    try {
        const {
            api, page, size, payload,
        } = action;
        const search = specsForFilter(payload);
        const apiWithQueryParam = `${api}?page=${page}&size=${size}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParam, search);
        yield put({
            type: FETCH_ORGANIZATION_LIST_SUCCESS,
            data: response.data,
            payload,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_ORGANIZATION_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
