/**
 * Created by sachinprakash on 4/7/19.
 */
import React from 'react';
import { Route } from 'react-router';
import {
    labSettingDepartments,
    labSettingDictionary,
    labSettingPanels,
    labSettings,
    labSettingSample,
    labSettingTemplate,
    labSettingTestMethods,
    labSettingUom,
} from '../constants/constants';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';

const TestsPage = React.lazy(() => import('../components/LabSettingsComponents/TestsListPage'));
const PanelsPage = React.lazy(() => import('../components/LabSettingsComponents/LabPanelListPage'));
const DepartmentPage = React.lazy(() => import('../components/LabSettingsComponents/LabDepartmentListPage'));
const SamplePage = React.lazy(() => import('../components/LabSettingsComponents/LabSampleListPage'));
const DictionaryPage = React.lazy(() => import('../components/LabSettingsComponents/LabDictionaryListPage'));
const UomPage = React.lazy(() => import('../components/LabSettingsComponents/LabUnitOfMeasureListPage')); const TemplatesPage = React.lazy(() => import('../components/LabSettingsComponents/LabTemplatesListPage'));
const TestMethodpage = React.lazy(() => import('../components/LabSettingsComponents/LabTestMethodsPage'));

const LabSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Route path={labSettings} exact component={TestsPage} />
        <Route path={labSettingPanels} exact component={PanelsPage} />
        <Route path={labSettingDepartments} exact component={DepartmentPage} />
        <Route path={labSettingSample} exact component={SamplePage} />
        <Route path={labSettingDictionary} exact component={DictionaryPage} />
        <Route path={labSettingUom} exact component={UomPage} />
        <Route path={labSettingTemplate} exact component={TemplatesPage} />
        <Route path={labSettingTestMethods} exact component={TestMethodpage} />
    </GenericSettingContainer>
);

export default LabSettingRoutes;

