export const FETCH_VISITS_LIST_REQUEST = '@@announcement/FETCH_VISITS_LIST_REQUEST';
export const FETCH_VISITS_LIST_SUCCESS = '@@announcement/FETCH_VISITS_LIST_SUCCESS';
export const FETCH_VISITS_LIST_FAILURE = '@@announcement/FETCH_VISITS_LIST_FAILURE';

export const CLEAR_VISITS = '@@announcement/CLEAR_VISITS';

export const fetchOpenVisitsRequest = (
    page,
    size,
    sortDir = 'desc',
    sortCol = 'dateStarted',
    searchConstruct,
    api,
) => ({
    type: FETCH_VISITS_LIST_REQUEST,
    api,
    page,
    size,
    sortDir,
    sortCol,
    searchConstruct,
});

export const clearRegistrationVisits = (
) => ({
    type: CLEAR_VISITS,
});
