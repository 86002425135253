import {
    CLEAR_AGING_REPORT_REQUEST,
    FETCH_AGING_REPORT_FAILURE,
    FETCH_AGING_REPORT_REQUEST,
    FETCH_AGING_REPORT_SUCCESS,
    FETCH_PAYOR_INVOICES_SUCCESS,
} from './agingReport-actions';
import initialStates from '../../initialStates';

import { getStringFromObject } from '../../../constants/lodashUtils';

const agingReportReducer = (state = initialStates.agingReport, action) => {
    let newState = { ...state };
    console.log('new action', action);
    switch (action.type) {
    case FETCH_AGING_REPORT_REQUEST:
        newState = { ...state, isLoading: true };
        break;
    case FETCH_AGING_REPORT_FAILURE:
        newState = { ...state, isLoading: false };
        break;
    case FETCH_AGING_REPORT_SUCCESS:
        newState = {
            ...state,
            isLoading: false,
            company: getStringFromObject('data.companyDto', action),
            list: getStringFromObject('data.payerDtos.content', action),
            page: getStringFromObject('data.payerDtos.number', action),
            size: getStringFromObject('data.payerDtos.size', action),
            totalElements: getStringFromObject('data.payerDtos.totalElements', action),
            filters: getStringFromObject('filters', action, {}),
        };
        break;
    case CLEAR_AGING_REPORT_REQUEST:
        newState = { ...initialStates.agingReport };
        break;
    case FETCH_PAYOR_INVOICES_SUCCESS:
        newState = Object.assign({}, state, {
            invoices: {
                ...state.invoices,
                [action.payorId]: action.data,
            },
        });
        console.log('NewState', newState);
        break;
    default:
        newState = state;
    }
    return newState;
};

export default agingReportReducer;
