import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getEndOfDay, getStartOfDay } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const filterObj = {
    createDate: 'account_asset_asset.createDate',
    assetState: 'account_asset_asset.assetState',
    state: 'account_asset_asset.state',
    model: 'account_asset_asset.model',
    category: 'account_asset_category.name',
};

const specsForFilter = (filter) => {
    let searchConstruct = null;
    const fromDate = getStringFromObject('createDate.from', filter, null);
    const toDate = getStringFromObject('createDate.to', filter, null);
    if (fromDate && toDate) {
        searchConstruct = getCombined(
            generateObjectWithCriteriaMode(filterObj.createDate, getStartOfDay(fromDate).getTime(), '>='),
            'AND',
            generateObjectWithCriteriaMode(filterObj.createDate, getEndOfDay(toDate).getTime(), '<='),
        );
    } else if (fromDate) {
        searchConstruct = generateObjectWithCriteriaMode(filterObj.createDate, getStartOfDay(fromDate).getTime(), '>=');
    } else if (toDate) {
        searchConstruct = generateObjectWithCriteriaMode(filterObj.createDate, getEndOfDay(toDate).getTime(), '<=');
    }

    const locationUuid = getStringFromObject('location.uuid', filter);
    if (locationUuid) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('stock_location.uuid', locationUuid, '='),
        );
    }
    const categoryUuid = getStringFromObject('category.key', filter);
    if (categoryUuid) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_asset_category.uuid', categoryUuid, '='),
        );
    }
    const allocationState = getStringFromObject('allocationState.key', filter);
    if (allocationState) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode(filterObj.state, allocationState, '='),
        );
    }
    const showRetired = getStringFromObject('showRetired', filter, false);
    if (!showRetired) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_asset_asset.assetState', 'DISPOSED', '!='),
        );
    }
    const excludeDraftAssets = getStringFromObject('excludeDraftAssets', filter, false);
    if (excludeDraftAssets) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_asset_asset.assetState', 'DRAFT', '!='),
        );
    }
    const allocatedTo = getStringFromObject('allocatedTo.key', filter);
    if (allocatedTo) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode('assigned_to.uuid', allocatedTo, '='),
                'AND',
                generateObjectWithCriteriaMode('asset_allocation.deallocationDate', null, '<>'),
            ),
        );
    }
    let searchText = getStringFromObject('searchText', filter);
    if (searchText) {
        searchText = searchText.trim();
        let operation = ':';
        if (searchText.endsWith('@')) {
            operation = '=';
            searchText = searchText.substr(0, searchText.length - 1);
        }
        let textFilters = getCombined(
            generateObjectWithCriteriaMode('account_asset_asset.identificationId', searchText, operation),
            'OR',
            generateObjectWithCriteriaMode('account_asset_asset.serialNumber', searchText, operation),
        );
        textFilters = getCombined(textFilters,
            'OR',
            generateObjectWithCriteriaMode('account_asset_asset.model', searchText, operation),
        );
        textFilters = getCombined(textFilters,
            'OR',
            generateObjectWithCriteriaMode('asset_manufacturer.name', searchText, operation),
        );
        searchConstruct = getCombined(searchConstruct, 'AND', textFilters);
    }
    return searchConstruct;
};

export default specsForFilter;
