import initialStates from '../../initialStates';
import {
    CLEAR_ACCOUNTS_STATE, CREATE_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_LIST_FAILURE,
    FETCH_ACCOUNTS_LIST_REQUEST,
    FETCH_ACCOUNTS_LIST_SUCCESS,
} from './accounts-actions';

const accountsReducer = (state = initialStates.accounts, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ACCOUNTS_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_ACCOUNTS_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_ACCOUNTS_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;

    case CLEAR_ACCOUNTS_STATE:
        newState = initialStates.accounts;
        break;
    case CREATE_ACCOUNTS_SUCCESS:
        newState = {
            ...state,
            selectedAccount: action.data,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default accountsReducer;
