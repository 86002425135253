/**
 * Created by sachinprakash on 12/8/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST } from '../../redux/modules/insuranceSchemeView/insuranceSchemeView-actions';
import { commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export default function* insuranceSchemeViewWatcher() {
    yield takeLatest(FETCH_INSURANCE_SCHEME_VIEW_LIST_REQUEST, commonPostWorkerSaga);
}
