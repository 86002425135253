import API from '../../../constants/api';

export const FETCH_HOLIDAY_LIST_REQUEST = '@@holiday/FETCH_HOLIDAY_LIST_REQUEST';
export const FETCH_HOLIDAY_LIST_SUCCESS = '@@holiday/FETCH_HOLIDAY_LIST_SUCCESS';
export const FETCH_HOLIDAY_LIST_FAILURE = '@@holiday/FETCH_HOLIDAY_LIST_FAILURE';

export const FETCH_HOLIDAY_FOR_YEAR_REQUEST = '@@holiday/FETCH_HOLIDAY_FOR_YEAR_REQUEST';
export const FETCH_HOLIDAY_FOR_YEAR_SUCCESS = '@@holiday/FETCH_HOLIDAY_FOR_YEAR_SUCCESS';
export const FETCH_HOLIDAY_FOR_YEAR_FAILURE = '@@holiday/FETCH_HOLIDAY_FOR_YEAR_FAILURE';

export const SAVE_HOLIDAY_REQUEST = '@@holiday/SAVE_HOLIDAY_REQUEST';
export const SAVE_HOLIDAY_SUCCESS = '@@holiday/SAVE_HOLIDAY_SUCCESS';
export const SAVE_HOLIDAY_FAILURE = '@@holiday/SAVE_HOLIDAY_FAILURE';

export const FETCH_UPCOMING_HOLIDAY_REQUEST = '@@holiday/FETCH_UPCOMING_HOLIDAY_REQUEST';
export const FETCH_UPCOMING_HOLIDAY_SUCCESS = '@@holiday/FETCH_UPCOMING_HOLIDAY_SUCCESS';
export const FETCH_UPCOMING_HOLIDAY_FAILURE = '@@holiday/FETCH_UPCOMING_HOLIDAY_FAILURE';

export const SELECT_YEAR = '@@holiday/SELECT_YEAR';

export const CLEAR_STATE = '@@holiday/CLEAR_STATE';

export const fetchHolidayList = (page, size) => ({
    type: FETCH_HOLIDAY_LIST_REQUEST,
    api: API.HOLIDAYS.FETCH,
    page,
    size,
});

export const saveHoliday = payload => ({
    type: SAVE_HOLIDAY_REQUEST,
    api: API.HOLIDAYS.ADD,
    payload,
});

export const selectYear = payload => ({
    type: SELECT_YEAR,
    payload,
});

export const clearHolidays = () => ({
    type: CLEAR_STATE,
});

export const fetchHolidayForYear = uuid => ({
    type: FETCH_HOLIDAY_FOR_YEAR_REQUEST,
    uuid,
    api: API.HOLIDAYS.FOR_YEAR,
});

export const fetchUpcomingHolidays = () => ({
    type: FETCH_UPCOMING_HOLIDAY_REQUEST,
    api: API.HOLIDAYS.UPCOMING_HOLIDAYS,
});
