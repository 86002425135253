import API from '../../../constants/api';

const DEFAULT_COLUMNS = {
    identifier: 'identifier',
    firstName: 'personNames.givenName',
    middleName: 'personNames.middleName',
    lastName: 'personNames.familyName',
    mobile: 'personAttributes.mobile',
    email: 'personAttributes.email',
    state: 'personAddresses.stateProvince',
    pinCode: 'personAddresses.postalCode',
    city: 'personAddresses.cityVillage',
    country: 'personAddresses.country',
    birthDate: 'person.birthdate',
    merged: 'patient.merged',
    dead: 'person.dead',
    voided: 'person.voided',
    gender: 'person.gender',
};

export const ADD_VISIT_REQUEST = '@@Visit/ADD_VISIT_REQUEST';
export const ADD_VISIT_SUCCESS = '@@Visit/ADD_VISIT_SUCCESS';
export const ADD_VISIT_FAILURE = '@@Visit/ADD_VISIT_FAILURE';

export const UPDATE_VISIT_REQUEST = '@@Visit/UPDATE_VISIT_REQUEST';
export const UPDATE_VISIT_SUCCESS = '@@Visit/UPDATE_VISIT_SUCCESS';
export const UPDATE_VISIT_FAILURE = '@@Visit/UPDATE_VISIT_FAILURE';

export const FETCH_VISIT_REQUEST = '@@Visit/FETCH_VISIT_REQUEST';
export const FETCH_VISIT_SUCCESS = '@@Visit/FETCH_VISIT_SUCCESS';
export const FETCH_VISIT_FAILURE = '@@Visit/FETCH_VISIT_FAILURE';

export const CLEAR_VISITS = '@@Visit/CLEAR_VISITS';
export const CLEAR_SCANNED_DATA = '@@Visit/CLEAR_SCANNED_DATA';
export const CLEAR_OPEN_VISIT_FLAG = '@@Visit/CLEAR_OPEN_VISIT_FLAG';
export const SET_VISIT_RELATED_INFO = '@@Visit/SET_VISIT_RELATED_INFO';

export const addVisitRequest = visit => ({
    type: ADD_VISIT_REQUEST,
    api: API.VISIT.ADD,
    visit,
});

export const clearScannedData = () => ({
    type: CLEAR_SCANNED_DATA,
});

export const updateVisitRequest = (visit, visitUuid) => ({
    type: UPDATE_VISIT_REQUEST,
    api: API.VISIT.UPDATE,
    visit,
    visitUuid,
});
export const clearHasOpenVisit = () => ({
    type: CLEAR_OPEN_VISIT_FLAG,
});

export const fetchVisitDetailsRequest = (
    patientUuid = '',
    numberOfPreviousPayments = 0,
    appointmentUuid = '',
    // if true, it will dispatch
    fetchPatientDetails = false,
    // this is the scanned values..
    // this scanned values can replace the saved values
    scannedInitialValues,
    displayCols = DEFAULT_COLUMNS,
) => ({
    type: FETCH_VISIT_REQUEST,
    api: API.VISIT.FETCH,
    patientUuid,
    numberOfPreviousPayments,
    appointmentUuid,
    fetchPatientDetails,
    displayCols,
    scannedInitialValues,
});

export const clearVisits = () => ({
    type: CLEAR_VISITS,
});
