
export const IntentFields = {
    REQUEST_LOC: 'requestLoc',
    OPERATION_TYPE: 'operationType',
    PRODUCTS: 'products',
    NOTES: 'notes',
};

export const operationTypes = {

    PURCHASE: {
        key: 'PURCHASE',
        value: 'Purchase',
    },
    MATERIAL_REQUEST: {
        key: 'MATERIAL_REQUEST',
        value: 'Material Request',
    },
    MATERIAL_WITHDRAWAL: {
        key: 'MATERIAL_WITHDRAWAL',
        value: 'Material Withdrawal ',
    },
};

export const operationsWhichRequireSourceInput = [
    operationTypes.PURCHASE.key,
    operationTypes.MATERIAL_REQUEST.key,
    operationTypes.MATERIAL_WITHDRAWAL.key,
];
