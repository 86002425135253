import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_INSURANCE_REQUEST_LINES_REQUEST, FETCH_INSURANCE_REQUESTS_REQUEST,
} from '../../redux/modules/insuranceRequestsList/insuranceRequestsList-actions';
import { fetchInsuranceRequestLinesWorker, fetchInsuranceRequestsWorker } from './insuranceRequestWorkerSaga';

export function* fetchInsuranceRequestLinesWatcher() {
    yield takeLatest(FETCH_INSURANCE_REQUEST_LINES_REQUEST, fetchInsuranceRequestLinesWorker);
}

export function* fetchInsuranceRequestsWatcher() {
    yield takeLatest(FETCH_INSURANCE_REQUESTS_REQUEST, fetchInsuranceRequestsWorker);
}

export function* fetchAccountExpenseWatcher() {
    // yield takeLatest(FETCH_ACCOUNT_EXPENSE_REQUEST, fetchAccountExpenseWorker);
}
