export const DISPLAY_WARNING = '@@message/DISPLAY_WARNING';
export const CLEAR_WARNING = '@@message/CLEAR_WARNING';

export const displayWarning = (
    message,
    callBack,
    callBackParams,
    noCallBack = (() => {}),
    noCallBackParams = {},
    yesLabel,
    noLabel,
    cancelLabel,
    cancelCallBack = (() => {}),
    cancelCallBackParams = {},
) => ({
    type: DISPLAY_WARNING,
    message,
    callBack,
    callBackParams,
    noCallBack,
    noCallBackParams,
    yesLabel,
    noLabel,
    cancelLabel,
    cancelCallBack,
    cancelCallBackParams,
});

export const showConfirmationDialog = (message, title, callBack, callBackParams, disableKeyClose = false) => {
    console.log('daghdfjsjklf', message, callBack, callBackParams, title);
    return {
        type: DISPLAY_WARNING,
        isConfirmation: true,
        message,
        title,
        callBack,
        callBackParams,
        disableKeyClose,
    };
};

export const clearWarning = () => ({
    type: CLEAR_WARNING,
});
