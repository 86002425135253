import { getSelectedQueue, getServiceLocation, persistQueue, persistServiceLocation } from '../../../constants/state';
import {
    SET_QUEUE,
    SET_REGISTRATION_SERVICE_LOCATION,
    TOGGLE_QUEUE_SELECT,
} from './queue-actions';

export const queueReducer = (
    state = {
        queue: getSelectedQueue(),
        serviceLocation: getServiceLocation(),
        openSelect: false,
    },
    action,
) => {
    let newState;
    console.log('new action', action);
    if (action.type === TOGGLE_QUEUE_SELECT) {
        newState = {
            ...state,
            openSelect: !action.closeDialog,
        };
    } else if (action.type === SET_QUEUE) {
        newState = {
            ...state,
            queue: action.queue,
            openSelect: !action.closeDialog,
        };
        persistQueue(action.queue);
    } else if (action.type === SET_REGISTRATION_SERVICE_LOCATION) {
        newState = {
            ...state,
            serviceLocation: action.serviceLocation,
            openSelect: !action.closeDialog,
        };
        persistServiceLocation(action.serviceLocation);
    } else {
        newState = state;
    }
    return newState;
};

export const x = 1;
