import API from '../../constants/api';
import { ADVANCE_SEARCH_OPERATORS, DATE_FILTERS, BOOK_MANAGEMENT_PRIVILEGES } from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';

import { getStringFromObject } from '../../constants/lodashUtils';

export const SEARCH_CATEGORIES = {
    EXPENSE: 'Expense',
    PETTY_CASH_EXPENSE: 'Petty Cash Expense',
    // ADVANCE_PAYMENT: 'Advance Payment',
    // PAY_ON_ACCOUNT: 'Pay On Account',
    PURCHASE_BILL: 'Purchase Invoice',
    BILL_PAYMENT: 'Supplier Payments', // payment
    // CREDIT_MEMO: 'Credit Memo',
    PATIENT_INVOICES: 'Patient Invoices', // out_invoice
    REFUND_INVOICES: 'Refund Invoices', // out_refund
    PAYMENTS_RECEIVED: 'Payments Received', // receipt
    // CREDIT_NOTES: 'Credit Notes',
    FUND_TRANSFER: 'Fund Transfer',
    JOURNAL_ENTRIES: 'Journal Entries',
    COST_ACCOUNT_LINES: 'Cost Account Lines',
    EMPLOYEE_ADVANCE_SALARY: 'Salary Advance',
    PETTY_CASH: 'Petty Cash',
    PREPAID_EXPENSE: 'Prepaid Expense',
    CASH_CLOSE: 'Cash Close',
    CONSOLIDATED_CASH_CLOSE: 'Consolidated Cash Close',
    ISSUE_INVOICE: 'Issue Invoice',
};

const ERP_TRANSACTION_PRIVILEGES = {
    [SEARCH_CATEGORIES.EXPENSE]: BOOK_MANAGEMENT_PRIVILEGES.expense,
    [SEARCH_CATEGORIES.PETTY_CASH_EXPENSE]: BOOK_MANAGEMENT_PRIVILEGES.employeeExpense,
    [SEARCH_CATEGORIES.PURCHASE_BILL]: BOOK_MANAGEMENT_PRIVILEGES.purchaseBill,
    [SEARCH_CATEGORIES.BILL_PAYMENT]: BOOK_MANAGEMENT_PRIVILEGES.payBill,
    // [SEARCH_CATEGORIES.CREDIT_MEMO]: BOOK_MANAGEMENT_PRIVILEGES.creditMemo,
    [SEARCH_CATEGORIES.PATIENT_INVOICES]: BOOK_MANAGEMENT_PRIVILEGES.patientInvoices,
    [SEARCH_CATEGORIES.REFUND_INVOICES]: BOOK_MANAGEMENT_PRIVILEGES.patientInvoices,
    [SEARCH_CATEGORIES.PAYMENTS_RECEIVED]: BOOK_MANAGEMENT_PRIVILEGES.paymentReceived,
    [SEARCH_CATEGORIES.FUND_TRANSFER]: BOOK_MANAGEMENT_PRIVILEGES.transfer,
    [SEARCH_CATEGORIES.JOURNAL_ENTRIES]: BOOK_MANAGEMENT_PRIVILEGES.journalEntries,
    [SEARCH_CATEGORIES.COST_ACCOUNT_LINES]: BOOK_MANAGEMENT_PRIVILEGES.costAccountLines,
    [SEARCH_CATEGORIES.EMPLOYEE_ADVANCE_SALARY]: BOOK_MANAGEMENT_PRIVILEGES.salaryAdvance,
    [SEARCH_CATEGORIES.PETTY_CASH]: BOOK_MANAGEMENT_PRIVILEGES.pettyCash,
    [SEARCH_CATEGORIES.PREPAID_EXPENSE]: BOOK_MANAGEMENT_PRIVILEGES.prepaidExpense,
    [SEARCH_CATEGORIES.CASH_CLOSE]: BOOK_MANAGEMENT_PRIVILEGES.cashClose,
    [SEARCH_CATEGORIES.CONSOLIDATED_CASH_CLOSE]: BOOK_MANAGEMENT_PRIVILEGES.consolidatedCashClose,
    [SEARCH_CATEGORIES.ISSUE_INVOICE]: BOOK_MANAGEMENT_PRIVILEGES.issueInvoice,
    // [SEARCH_CATEGORIES.PAY_ON_ACCOUNT]: BOOK_MANAGEMENT_PRIVILEGES.paidOnAccount,
};

export const getSearchCategories = () => {
    const categories = {};
    Object.keys(SEARCH_CATEGORIES).forEach((cat) => {
        if (checkIfPrivilegeExistsForUser(getStringFromObject(SEARCH_CATEGORIES[cat], ERP_TRANSACTION_PRIVILEGES))) {
            categories[cat] = SEARCH_CATEGORIES[cat];
        }
    });
    return categories;
};

const patientInvoiceFilter = {
    PATIENT: {
        label: 'Customer',
        type: 'asyncSelect',
        operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        api: `${API.APPOINTMENT.SEARCH_PATIENT}`.concat(
            'attributeSearch=identifier.identifier,person.secondaryIdentifier&searchString='),
        dataSourceConfig: { text: 'value.displayName', value: 'key' },
    },
    STATUS: {
        label: 'Status',
        type: 'select',
        options: [
            { label: 'Open', value: 'open' },
            { label: 'Draft', value: 'draft' },
            { label: 'Refunded', value: 'refunded' },
            { label: 'Cancelled', value: 'cancel' },
            { label: 'Paid', value: 'paid' },
        ],
        operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    },
    AMOUNT: {
        label: 'Amount',
        type: 'number',
        operation: [
            ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
            ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
        ],
    },
    INVOICE_NUMBER: {
        label: 'Invoice Number',
        type: 'text',
        operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
    },
    // DUE_DATE: {
    //     label: 'Due date',
    //     type: 'date',
    //     operation: Object.values(DATE_FILTERS),
    // },
};

export const APPLICABLE_FILTERS = {
    [SEARCH_CATEGORIES.EXPENSE]: {
        PAYEE: {
            label: 'Payee',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        ACCOUNT: {
            label: 'Account (paid from)',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&type=liquidity&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        REFERENCE_NUMBER: {
            label: 'Reference Number',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        CATEGORY: {
            label: 'Category',
            type: 'asyncSelect',
            api: `${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&type=expense&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
    },
    [SEARCH_CATEGORIES.PETTY_CASH_EXPENSE]: {
        EMPLOYEE: {
            label: 'Employee',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.EMPLOYEE.GET_SUGGESTION}?name=`,
            dataSourceConfig: { text: 'value', value: 'partnerId' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        STATE: {
            label: 'Status',
            type: 'select',
            options: [
                { label: 'Draft', value: 'draft' },
                { label: 'Confirmed', value: 'confirmed' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        CATEGORY: {
            label: 'Category',
            type: 'asyncSelect',
            api: `${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&type=expense&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
    },
    [SEARCH_CATEGORIES.PURCHASE_BILL]: {
        PARTNER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        PURCHASE_ORDER: {
            label: 'Purchase Order',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'uuid' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        DUE_DATE: {
            label: 'Due date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            options: [
                { label: 'Open', value: 'open' },
                { label: 'Draft', value: 'draft' },
                { label: 'Refunded', value: 'refunded' },
                { label: 'Cancelled', value: 'cancel' },
                { label: 'Paid', value: 'paid' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        CASH_SUPPLIER: {
            label: 'Cash Supplier',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.ISSUE_INVOICE]: {
        PARTNER: {
            label: 'Customer',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.RES_PARTNER_BASE}&isPayor=true&searchString=`,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        DUE_DATE: {
            label: 'Due date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            options: [
                { label: 'Open', value: 'open' },
                { label: 'Draft', value: 'draft' },
                { label: 'Refunded', value: 'refunded' },
                { label: 'Cancelled', value: 'cancel' },
                { label: 'Paid', value: 'paid' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
    },
    // [SEARCH_CATEGORIES.CREDIT_MEMO]: {
    //     PARTNER: {
    //         label: 'Supplier',
    //         type: 'asyncSelect',
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    //         api: API.SEARCH.SUPPLIER,
    //         dataSourceConfig: { text: 'name', value: 'uuid' },
    //     },
    //     PRODUCT: {
    //         label: 'Product',
    //         type: 'asyncSelect',
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    //         api: API.SEARCH.PRODUCT,
    //         dataSourceConfig: { text: 'productName', value: 'uuid' },
    //     },
    //     AMOUNT: {
    //         label: 'Credit Amount',
    //         type: 'number',
    //         operation: [
    //             ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
    //             ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
    //         ],
    //     },
    // },
    [SEARCH_CATEGORIES.PATIENT_INVOICES]: patientInvoiceFilter,
    [SEARCH_CATEGORIES.REFUND_INVOICES]: patientInvoiceFilter,
    [SEARCH_CATEGORIES.BILL_PAYMENT]: {
        PARTNER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        PAYMENT_MODE: {
            label: 'Payment Mode',
            type: 'select',
            options: [
                { label: 'Cash', value: 'cash' },
                { label: 'Cheque', value: 'cheque' },
                { label: 'Bank Transfer', value: 'bankTransfer' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.PAYMENTS_RECEIVED]: {
        PARTNER: {
            label: 'Customer',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.CUSTOMER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        PAYMENT_MODE: {
            label: 'Payment Mode',
            type: 'select',
            options: [
                { label: 'Cash', value: 'cash' },
                { label: 'Cheque', value: 'cheque' },
                { label: 'Bank Transfer', value: 'bankTransfer' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        // INVOICE_NUMBER: {
        //     label: 'Invoice Number',
        //     type: 'text',
        //     operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        // },
        CHEQUE_NUMBER: {
            label: 'Received cheque number',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.FUND_TRANSFER]: {
        ACCOUNT_FROM: {
            label: 'From Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.ACCOUNT_HEADS,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        ACCOUNT_TO: {
            label: 'To Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.ACCOUNT_HEADS,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.JOURNAL_ENTRIES]: {
        ACCOUNT_JOURNAL: {
            label: 'Account Journal',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.ACCOUNT_JOURNAL,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        ACCOUNT_PERIOD: {
            label: 'Account Period',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.ACCOUNT_PERIOD,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        PARTNER: {
            label: 'Partner',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.RES_PARTNER,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATE: {
            label: 'State',
            type: 'select',
            options: [
                { label: 'Posted', value: 'posted' },
                { label: 'Draft', value: 'draft' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        NAME: {
            label: 'Name',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.COST_ACCOUNT_LINES]: {
        ANALYTICAL_ACCOUNT: {
            label: 'Cost Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.ANALYTICAL_ACCOUNTS,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        GENERAL_ACCOUNT: {
            label: 'General Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        DOCTOR: {
            label: 'Doctor',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.DOCTOR,
            dataSourceConfig: { text: 'value.value', value: 'key' },
        },
        PROVIDER: {
            label: 'Orderer',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.PROVIDER.SEARCH,
            dataSourceConfig: { text: 'value.value', value: 'key' },
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        PARTNER: {
            label: 'Partner',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            type: 'asyncSelect',
            api: API.SEARCH.RES_PARTNER,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        VISIT_TYPE: {
            label: 'Visit Type',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.VISIT_TYPE,
            type: 'asyncSelect',
            dataSourceConfig: { text: 'value', value: 'key' },
        },
    },
    [SEARCH_CATEGORIES.EMPLOYEE_ADVANCE_SALARY]: {
        EMPLOYEE: {
            label: 'Employee',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SALARYADVANCE.GET_EMPLOYEE}?name=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        FROM_ACCOUNT: {
            label: 'From Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&type=liquidity&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        STATE: {
            label: 'State',
            type: 'select',
            options: [
                { label: 'Issued', value: 'issued' },
                { label: 'Draft', value: 'draft' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        AMOUNT: {
            label: 'Amount',
            type: 'text',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS,
                ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS,
                ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        BALANCE: {
            label: 'Balance',
            type: 'text',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS,
                ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS,
                ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
    },
    [SEARCH_CATEGORIES.PETTY_CASH]: {
        EMPLOYEE: {
            label: 'Employee',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SALARYADVANCE.GET_EMPLOYEE}?name=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        FROM_ACCOUNT: {
            label: 'From Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?typeIgnoreList=consolidation,view&type=liquidity&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        STATE: {
            label: 'State',
            type: 'select',
            options: [
                { label: 'Issued', value: 'issued' },
                { label: 'Draft', value: 'draft' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        AMOUNT: {
            label: 'Amount',
            type: 'text',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS,
                ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS,
                ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        BALANCE: {
            label: 'Balance',
            type: 'text',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS,
                ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS,
                ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
    },
    [SEARCH_CATEGORIES.PREPAID_EXPENSE]: {
        NAME: {
            label: 'Name',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        PARTNER: {
            label: 'Payer',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        CREDIT_ACCOUNT: {
            label: 'Bank/Credit Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?type=liquidity&typeIgnoreList=consolidation,view&accountType=&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        EXPENSE_ACCOUNT: {
            label: 'Expense Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?type=expense&typeIgnoreList=consolidation,view&\
                                            accountType=&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        PREPAID_ACCOUNT: {
            label: 'Prepaid Account',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SEARCH.ACCOUNTS}?type=prepaid expenses&\
                                            typeIgnoreList=consolidation,view&\
                                            accountType=&searchString=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        PAYMENT_DATE: {
            label: 'Payment date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        START_DATE: {
            label: 'Start date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
    },
    [SEARCH_CATEGORIES.CASH_CLOSE]: {
        DATE: {
            label: 'Date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        USER: {
            label: 'User',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: `${API.SALARYADVANCE.GET_EMPLOYEE}?name=`,
            dataSourceConfig: { text: 'value', value: 'key' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
    },
    // [SEARCH_CATEGORIES.PAY_ON_ACCOUNT]: {
    //     NAME: {
    //         label: 'Name',
    //         type: 'text',
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
    //     },
    //     TYPE: {
    //         label: 'Type',
    //         type: 'select',
    //         options: [
    //             { label: 'Pay On Account', value: 'account_supplier_advance' },
    //             { label: 'Pay On Account Refund', value: 'account_supplier_refund' },
    //         ],
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    //     },
    //     PARTNER: {
    //         label: 'Supplier',
    //         type: 'asyncSelect',
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    //         api: API.SEARCH.SUPPLIER,
    //         dataSourceConfig: { text: 'name', value: 'uuid' },
    //     },
    //     DATE: {
    //         label: 'Date',
    //         type: 'date',
    //         operation: Object.values(DATE_FILTERS),
    //     },
    //     CREDIT_ACCOUNT: {
    //         label: 'Bank/Credit Account',
    //         type: 'asyncSelect',
    //         operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
    //         api: `${API.SEARCH.ACCOUNTS}?type=liquidity&typeIgnoreList=consolidation,view&accountType=&searchString=`,
    //         dataSourceConfig: { text: 'value', value: 'key' },
    //     },
    //     AMOUNT: {
    //         label: 'Amount',
    //         type: 'number',
    //         operation: [
    //             ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
    //             ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
    //         ],
    //     },
    // },
    [SEARCH_CATEGORIES.CONSOLIDATED_CASH_CLOSE]: {
        DATE: {
            label: 'Date',
            type: 'date',
            operation: Object.values(DATE_FILTERS),
        },
        AMOUNT: {
            label: 'Entered Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
    },
};

export const UNIQUE_KEY_FOR_TABLE = {
    // Adding a property for expense and petty cash which is not present.
    // This will return falsy and therefore generating random uuid as key for row
    [SEARCH_CATEGORIES.EXPENSE]: 'cantFindByLodash',
    [SEARCH_CATEGORIES.PETTY_CASH]: 'cantFindByLodash',
};
