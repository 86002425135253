import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import PaginatedTable from './PaginatedTable';

class FormikTable extends React.PureComponent {
    render() {
        const { fieldName, styles, ...tableProps } = this.props;
        console.log('kadfkladklf', this.props);
        return (
            <div style={{ width: '100%', ...styles }}>
                <FieldArray
                    {...tableProps}
                    name={fieldName}
                    test-id={fieldName}
                    render={arrayHelpers => <PaginatedTable {...tableProps} {...arrayHelpers} />}
                />
            </div>
        );
    }
}

FormikTable.propTypes = {
    fieldName: PropTypes.string.isRequired,
    styles: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    dataSourceApi: PropTypes.string,
    tableCells: PropTypes.array.isRequired,
    lastUpdated: PropTypes.instanceOf(Date),
};

FormikTable.defaultProps = {
    styles: {},
    dataSourceApi: null,
    lastUpdated: null,
};
export default FormikTable;
