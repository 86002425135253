/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';

// import PaginationComponent from './PaginationComponent';
import Filter from '../Filters/Filter';
import OutlinedTextField from '../OutlinedTextField';
import PopoverFilter from '../GenericFilterComponent/PopoverFilter';

const styles = theme => ({
    button: {
        backgroundColor: '#FAD403 !important',
        color: 'white',
        width: '3rem',
        height: '3rem',
        margin: theme.spacing.unit,
    },
});

class SearchWithFilterAndPaginationOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            fromDate: new Date(),
            toDate: new Date(),
            patient: '',
            company: '',
            doctor: '',
            type: '',
            status: '',
            min: '',
            max: '',
            payerName: '',
            searchText: '',
        };
    }

    handleFilter = (event) => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
        });
    };

    handleChangeFilterState = (name, value) => {
        this.setState({ [name]: value });
    };

    changeFilters = () => {
        const states = this.state;
        const filters = {
            fromDate: states.fromDate,
            toDate: states.toDate,
            company: states.company,
            doctor: states.doctor,
            type: states.type,
            status: states.status,
            min: states.min,
            max: states.max,
            payerName: states.payerName,
        };
        this.setState({ searchText: '' });
        this.props.saveFilter(filters, false);
    };

    handleSearch = (text) => {
        if (!text) {
            this.props.saveFilter(null, true);
        }
        this.setState({ searchText: text });
    };

    handleKeyPressSearch = (e) => {
        const text = this.state.searchText;
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            /* const filters = {
                doctor: text,
                company: text,
                patient: text,
            }; */
            this.props.saveFilter(text, true);
        }
    };

    render() {
        const filterStates = { ...this.state };
        const {
            filterSchema,
            saveFilter,
            filterValues,
            showFilter,
        } = this.props;
        return (
            <Grid container alignItems="center">
                <Grid
                    item
                    lg={10}
                    sm={10}
                    md={10}
                >
                    <OutlinedTextField
                        id="search-header"
                        style={{ width: '90%' }}
                        label={this.props.placeholder}
                        value={this.state.searchText}
                        onChange={this.handleSearch}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: <InputAdornment position="end"><Search /></InputAdornment>,
                        }}
                        onKeyPress={this.handleKeyPressSearch}
                    />
                </Grid>
                <Grid
                    item
                    lg={2}
                    sm={2}
                    md={2}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                    {
                        showFilter &&
                        <FilterListIcon
                            test-id="filter-icon"
                            className="cursor-pointer"
                            onClick={this.handleFilter}
                            style={{ margin: '0 0 0 16px' }}
                        />
                    }
                </Grid>
                {/* <Grid item lg={2} sm={2} md={2}>
                    {
                        this.props.showBack &&
                            <div
                                role="button"
                                tabIndex="0"
                                style={{
                                    backgroundColor: '#FFD72A',
                                    borderRadius: '4px',
                                    height: '3rem',
                                    marginLeft: '2rem',
                                    width: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '0.5em',
                                }}
                                onClick={this.props.handleBack}
                            >
                                <NextPage
                                    style={{
                                        height: '50%',
                                        width: '20%',
                                        transform: 'rotate(180deg)',
                                    }}
                                />
                                <span style={{ paddingLeft: '1em', fontSize: '1.2em' }}>Back</span>
                            </div>
                    }
                </Grid> */}
                {
                    this.state.open && this.props.filter &&
                        <Filter
                            anchorEl={this.state.anchorEl}
                            handleClose={this.handleClose}
                            handleFilter={this.changeFilters}
                            states={filterStates}
                            handleStateChange={this.handleChangeFilterState}
                        />
                }
                {
                    this.state.open &&
                    <PopoverFilter
                        filterSchema={filterSchema}
                        anchorEl={this.state.anchorEl}
                        saveFilter={saveFilter}
                        handleClose={this.handleClose}
                        filterValues={filterValues}
                    />
                }
            </Grid>
        );
    }
}

SearchWithFilterAndPaginationOption.propTypes = {
    // handleBack: PropTypes.func,
    saveFilter: PropTypes.func,
    placeholder: PropTypes.string,
    filterValues: PropTypes.object,
    filter: PropTypes.bool,
    showFilter: PropTypes.bool,
    filterSchema: PropTypes.array,
    // showBack: PropTypes.bool,
};

SearchWithFilterAndPaginationOption.defaultProps = {
    // handleBack: () => {},
    // showBack: false,
    saveFilter: () => {},
    filterValues: {},
    filter: false,
    showFilter: true,
    filterSchema: [],
    placeholder: 'Patient name / ID / National ID',
};

export default withStyles(styles)(SearchWithFilterAndPaginationOption);
