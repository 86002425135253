import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import ReceivePaymentDialog from './ReceivePaymentDialog';
import {
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,
} from '../../../constants/CommonUtil';
import api from '../../../constants/api';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import SupplierPaymentDialogWrapper from '../SupplierPayment/SupplierPaymentDialogWrapper';

/*
*  only for passing the success call back to formik form to print the voucher
*  not really needed otherwise
*
* */

class ReceivePaymentDialogWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listPayments: false,
            printPayment: false,
            // this is only used to print the data immediately after payment..
            // in other cases, it is sufficient to take selectedAccountVoucher from props
            printData: {},
            // when printed immediately after registering a payment, then we can use the close func to
            // to go back to the supplier invoice screen
            onPrintCloseFunc: () => {},
        };
    }

    componentDidMount() {
        const { accountInvoiceUuid } = this.props;
        if (accountInvoiceUuid) {
            this.loadOpenInvoicePaymentsAndRedirect(accountInvoiceUuid);
        }
    }

    onConfirmPayment = (accountVoucher) => {
        console.log('asda0u9das0-da', accountVoucher);
        const { handleClose } = this.props;
        this.setState(prevState => ({
            printData: accountVoucher,
            printPayment: !prevState.printPayment,
            onPrintCloseFunc: () => { handleClose(true); },
        }));
    };

    loadOpenInvoicePaymentsAndRedirect = (invoiceUuid) => {
        const { dispatch } = this.props;
        this.loadOpenInvoicePayments(invoiceUuid).then((invoicePayments) => {
            if (isArrayValidAndNotEmpty(invoicePayments)) {
                dispatch(displayWarning(
                    'Selected Invoice is already added to a draft payment, Do you want to load draft payment ?',
                    this.displayInvoicePayments,
                ));
            } else {
                this.hideInvoicePayments();
            }
        });
    };

    loadOpenInvoicePayments = (invoiceUuid) => {
        const { dispatch } = this.props;
        const urlWithApiParams = getUrlWithApiParams(api.ACCOUNT_INVOICE.OPEN_INVOICE_PAYMENTS, { invoiceUuid });
        return new Promise((resolve) => {
            dispatch(commonGetApiRequest(urlWithApiParams, {
                successCallback: (invoicePayments) => {
                    resolve(invoicePayments);
                },
            }));
        });
    };

    displayInvoicePayments = () => this.setState({ listPayments: true });

    hideInvoicePayments = () => this.setState({ listPayments: false });

    handleCloseSelectPaymentAndExit = () => this.setState({ listPayments: false }, this.props.handleClose);

    render() {
        const {
            printData,
            printPayment,
            onPrintCloseFunc,
            listPayments,
        } = this.state;
        const {
            handleClose,
            selectedSupplier,
            open,
            isSupplier,
            accountInvoiceUuid,
            accountInvoices,
        } = this.props;
        return (
            <React.Fragment>
                {
                    listPayments &&
                    <SupplierPaymentDialogWrapper
                        handleClose={this.handleCloseSelectPaymentAndExit}
                        invoiceUuid={accountInvoiceUuid}
                    />
                }
                {
                    !listPayments &&
                    <ReceivePaymentDialog
                        open={open}
                        isSupplier={isSupplier}
                        accountInvoiceUuid={accountInvoiceUuid}
                        selectedSupplier={selectedSupplier}
                        handleClose={handleClose}
                        onConfirmPayment={this.onConfirmPayment}
                        accountInvoices={accountInvoices}
                    />
                }
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PaymentVoucher/PaymentVoucher.html`}
                    data={printData}
                    print={printPayment}
                    handleClose={onPrintCloseFunc}
                    subCompany={printData ? printData.subCompany : ''}
                />
            </React.Fragment>
        );
    }
}

ReceivePaymentDialogWrapper.propTypes = {
    handleClose: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    selectedSupplier: PropTypes.object,
    isSupplier: PropTypes.bool,
    open: PropTypes.bool,
    accountInvoiceUuid: PropTypes.string,
    accountInvoices: PropTypes.array,
};

ReceivePaymentDialogWrapper.defaultProps = {
    handleClose: () => {},
    selectedSupplier: {},
    isSupplier: false,
    open: true,
    accountInvoiceUuid: '',
    accountInvoices: [],
};

export default connect()(ReceivePaymentDialogWrapper);

