import cloneDeep from 'clone-deep';
import { isArrayValidAndNotEmpty, isValidTextAndNotEmpty } from '../../constants/CommonUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

export const searchCSVUploadRows = (searchText, schema, values) => {
    const formValues = cloneDeep(values);
    const tableCells = getStringFromObject('tableCells', schema);
    if (isArrayValidAndNotEmpty(formValues) && isArrayValidAndNotEmpty(tableCells)) {
        // find the searchable fields
        const searchableFields =
            tableCells.filter(aTableCell => aTableCell.searchable).map(aTableCell => aTableCell.name);
        if (isArrayValidAndNotEmpty(searchableFields)) {
            const newFormValues = formValues.map((aRow) => {
                if (!searchText) {
                    return {
                        ...aRow,
                        visible: true,
                    };
                }
                for (let i = 0; i < searchableFields.length; i += 1) {
                    const aSearchableField = searchableFields[i];
                    const theValueOfTheFieldInRow = getStringFromObject(aSearchableField, aRow);
                    if ((`${theValueOfTheFieldInRow}`).toLowerCase().includes(searchText.toLowerCase())) {
                        return {
                            ...aRow,
                            visible: true,
                        };
                    }
                }
                return {
                    ...aRow,
                    visible: false,
                };
            });
            return newFormValues;
        }
    }
    return null;
};

export const showLineNoInCSVImport = (formValues, lineNoSearch) => (
    formValues.map((aRow, index) => {
        let visible = false;
        if (!lineNoSearch) {
            visible = true;
        }
        if (lineNoSearch) {
            visible = (index === (lineNoSearch - 1));
        }
        return {
            ...aRow,
            visible,
        };
    })
);

export const applyAdditonalTransformationsFromSchema = (uploadData, schema) => {
    let newUploadData = [...uploadData];

    const tableCells = getStringFromObject('tableCells', schema) || [];

    // apply trimming of extra space [trimExtraSpace]
    const toBeTrimmedKeys = tableCells.filter(e => e.trimExtraSpace).map(e => e.name);
    if (isArrayValidAndNotEmpty(toBeTrimmedKeys)) {
        newUploadData = newUploadData.map((data) => {
            const newObj = { ...data };
            toBeTrimmedKeys.forEach((key) => {
                const val = data[key];
                if (isValidTextAndNotEmpty(val)) {
                    newObj[key] = val.trim();
                }
            });
            return newObj;
        });
    }

    return newUploadData;
};
