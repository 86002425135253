import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_STOCK_MOVE_REQUEST,
    FETCH_STOCK_MOVE_BY_ID_REQUEST,
    FETCH_STOCK_MOVE_REQUEST,
} from '../../redux/modules/stockMove/stockMove-actions';
import { fetchStockMoveByPurchaseOrder, receiveStockWorker } from './stockMoveWorkerSaga';
import { commonGetWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* receiveStockWatcher() {
    yield takeLatest(CREATE_STOCK_MOVE_REQUEST, receiveStockWorker);
}

export function* fetchStockMovesByPurchaseOrderWatcher() {
    yield takeLatest(FETCH_STOCK_MOVE_REQUEST, fetchStockMoveByPurchaseOrder);
}
export function* fetchStockMovesByIdWatcher() {
    yield takeLatest(FETCH_STOCK_MOVE_BY_ID_REQUEST, commonGetWorkerSaga);
}
