import API from '../../../../constants/api';

export const FETCH_SUB_INSURERS_LIST_REQUEST = '@@payors/FETCH_SUB_INSURERS_LIST_REQUEST';
export const FETCH_SUB_INSURERS_LIST_FAILURE = '@@payors/FETCH_SUB_INSURERS_LIST_FAILURE';
export const FETCH_SUB_INSURERS_LIST_SUCCESS = '@@payors/FETCH_SUB_INSURERS_LIST_SUCCESS';

export const CLEAR_SUB_INSURER_STATE = '@@payors/CLEAR_SUB_INSURER_STATE';

export const fetchPagesOfSubInsurers = (page, size, sortCol, sortOrder, filters = {}) => ({
    type: FETCH_SUB_INSURERS_LIST_REQUEST,
    api: `${API.PAYORS.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const clearSubInsurerState = () => ({
    type: CLEAR_SUB_INSURER_STATE,
});
