import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthorized } from '../../constants/privilegeChecker';
import { pathPrivilegesWithEforms } from '../../constants/constants';

import { getStringFromObject } from '../../constants/lodashUtils';

const Authorization = (props) => {
    const {
        path,
        children,
        message,
        eformPrivilegeConfig,
        disablePartialRatioRouteMatching,
    } = props;
    const pathPrivileges = pathPrivilegesWithEforms(eformPrivilegeConfig);
    return (
        <React.Fragment>
            {
                isAuthorized(path, pathPrivileges, disablePartialRatioRouteMatching) ?
                    children :
                    <div style={{ textAlign: 'center' }}>
                        <h4>{message}</h4>
                    </div>

            }
        </React.Fragment>
    );
};

Authorization.propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    message: PropTypes.string,
    eformPrivilegeConfig: PropTypes.object,
    disablePartialRatioRouteMatching: PropTypes.bool.isRequired,
};

Authorization.defaultProps = {
    message: 'You Are Not Allowed To Access this Module',
    eformPrivilegeConfig: {},
};

const mapStateToProps = state => ({
    eformPrivilegeConfig: getStringFromObject('eformConfig.privilegeConfig', state),
    disablePartialRatioRouteMatching: getStringFromObject('appConfiguration.disablePartialRatioRouteMatching', state) || false,
});

export default connect(mapStateToProps)(Authorization);
