import API from '../../../constants/api';

import { getStringFromObject } from '../../../constants/lodashUtils';

export const amountOperation = [
    { key: '<=', value: '<=' },
    { key: '=', value: '=' },
    { key: '>=', value: '>=' },
];

const PRODUCT_TAB_FILTERS = ([
    {
        name: 'category',
        label: 'Category',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.SEARCH.PRODUCT_CATEGORY_WITH_PARENT}?parent=Drug&searchString=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'supplier',
        label: 'Supplier',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.SEARCH.SUPPLIER,
        dataSourceConfig: {
            text: 'name',
            value: 'id',
        },
    },
    {
        name: 'genericDrugAttribute',
        label: 'Generic Drug',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.GENERIC_DRUG.SEARCH}`,
        dataSourceConfig: {
            text: 'genericDrugFullName',
            value: 'id',
        },
    },
    {
        name: 'quantity',
        label: 'Quantity',
        type: 'fieldWithOperation',
        options: ['<=', '=', '>='],
        valueType: 'number',
        valueLabel: 'Enter Quantity',
    },
    {
        name: 'includeInactive',
        label: 'Include inactive also',
        type: 'checkbox',
        defaultValue: false,
        disableClear: true,
    },
]);

export const simplifyFilters = (values) => {
    const obj = {};
    const name = getStringFromObject('searchText', values);
    if (name) {
        obj.name = name;
    }
    const category = getStringFromObject('category.value', values);
    if (category) {
        obj.categoryName = category;
    }
    const supplier = getStringFromObject('supplier.id', values);
    if (supplier) {
        obj.supplier = supplier;
    }
    const operation = getStringFromObject('quantity.operation', values);
    const quantity = getStringFromObject('quantity.value', values);
    if (operation && (quantity != null)) {
        const arr = [operation, quantity];
        obj.stockAvailability = arr.toString();
    }
    const includeInactive = getStringFromObject('includeInactive', values, false);
    if (includeInactive) {
        obj.active = null;
    } else {
        obj.active = true;
    }
    const genericDrugAttribute = getStringFromObject('genericDrugAttribute.id', values);
    if (genericDrugAttribute) {
        obj.genericDrugAttribute = genericDrugAttribute;
    }
    const defaultCode = getStringFromObject('defaultCode', values);
    if (defaultCode) {
        obj.defaultCode = defaultCode;
    }
    const ean13 = getStringFromObject('ean13', values);
    if (ean13) {
        obj.ean13 = ean13;
    }
    return obj;
};

export default PRODUCT_TAB_FILTERS;
