import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = () => ({
    card: {
        width: '100%',
        borderRadius: '8px',
        minHeight: '100%',
        marginBottom: '1rem',
    },
});

const AppSettingsContainer = ({ children, classes }) => (
    <React.Fragment>
        <div style={{ padding: '2rem', height: '100%' }}>
            <Card className={classes.card}>
                <div style={{ background: '#fff', padding: '2rem' }}>
                    {children}
                </div>
            </Card>
        </div>
    </React.Fragment>
);

AppSettingsContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppSettingsContainer);
