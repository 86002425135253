import { DATE_FILTERS } from '../../../components/Common/ReportDateFilterUtil';
import api from '../../../constants/api';

export const purchasePaymentsFilters = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'account_voucher.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: false,
            maxDateField: 'account_voucher.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_voucher.date',
                filterOperation: '>=',
            },
        },
        {
            name: 'account_voucher.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            additionalFieldToClear: ['account_voucher.startDate'],
            minDateField: 'account_voucher.startDate',
            defaultValueField: 'account_voucher.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_voucher.date',
                filterOperation: '<=',
            },
        },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'account_voucher.type',
            type: 'select',
            label: 'Type',
            valueField: 'key',
            disableClear: false,
            options: [
                {
                    key: 'payment',
                    value: 'Payment',
                },
                {
                    key: 'receive',
                    value: 'Refunds',
                },
            ],
            searchConstructFields: {
                name: 'account_voucher.type',
                filterOperation: '=',
            },
        },
        {
            name: 'res_partner.id',
            type: 'autocomplete',
            label: 'Supplier',
            valueField: 'id',
            disableClear: false,
            dataSourceConfig: {
                text: 'name',
                value: 'id',
            },
            dataSourceApi: api.SEARCH.SUPPLIER,
            searchConstructFields: {
                name: 'res_partner.id',
                filterOperation: '=',
            },
        },
    ]);
};

export const purchasePaymentsSchema = {
    id: 'accountVoucherListTable',
    name: 'accountVoucherListTable',
    rowStyle: {
        height: '38px',
    },
    headerRowStyle: {
        background: 'linear-gradient(to right, rgb(58, 26, 228), rgb(47, 189, 204))',
    },
    tableStyle: {
        marginTop: '12px',
    },
    headerColStyle: {
        color: 'white',
        fontSize: '14px',
    },
    hideCheckBoxIcon: true,
    tableCells: [
        {
            id: 'date',
            isDate: true,
            find: 'dateTime',
            fieldType: 'datetime',
            label: 'Date',
            displaySortIcon: true,
            sortCol: 'date',
        },
        {
            id: 'name',
            find: 'number',
            fieldType: 'text',
            label: 'Number',
            displaySortIcon: true,
            sortCol: 'number',
        },
        {
            id: 'partner',
            find: 'partnerId.value',
            fieldType: 'text',
            displaySortIcon: true,
            label: 'Supplier',
            sortCol: 'res_partner.name',
        },
        {
            id: 'paymentMode',
            find: 'paymentMode',
            fieldType: 'text',
            displaySortIcon: true,
            label: 'Payment Method',
            sortCol: 'modeOfPayment',
        },
        {
            id: 'amount',
            find: 'amount',
            fieldType: 'price',
            displaySortIcon: true,
            label: 'Amount',
            sortCol: 'amount',
        },
        {
            id: 'type',
            find: 'type',
            fieldType: 'text',
            label: 'Type',
            sortCol: 'type',
        },
    ],
};

export const VOUCHER_LINE_SCHEMA = {
    rowStyle: {
        height: '38px',
    },
    headerRowStyle: {
        background: 'linear-gradient(to right, rgb(58, 26, 228), rgb(47, 189, 204))',
    },
    tableStyle: {
        marginTop: '12px',
    },
    headerColStyle: {
        color: 'white',
        fontSize: '14px',
    },
    hideCheckBoxIcon: true,
    tableCells: [
        {
            id: 'description',
            find: 'name',
            fieldType: 'text',
            label: 'Number',
        },
        {
            id: 'originalAmount',
            find: 'amountOriginal',
            fieldType: 'price',
            label: 'Original Amount',
        },
        {
            id: 'openBalance',
            find: 'amountUnreconciled',
            fieldType: 'price',
            label: 'Open Balance',
        },
        {
            id: 'narration',
            find: 'narration',
            fieldType: 'text',
            label: 'Narration',
        },
        {
            id: 'payment',
            find: 'amount',
            fieldType: 'price',
            label: 'Payment',
        },
    ],
};
