import React from 'react';
import cloneDeep from 'clone-deep';
import Grid from '@material-ui/core/Grid';
import { Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import axios from 'axios';
import {
    apiCatchBlockFunction,
    formatCurrency,
    getAllowedDefaultAccountTypes,
    roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import ActionButton from '../../ActionButton/ActionButton';
import { minValue, required } from '../../../constants/FormValidations';
import API from '../../../constants/api';
import {
    clearAdvanceSelectedState,
    createAdvancedSalary,
    FETCH_ADVANCE_SALARY_SUCCESS,
} from '../../../redux/modules/salaryAdvance/salaryAdvance-actions';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL, PAYMENT_METHODS } from '../../../constants/constants';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { fetchHrEmployeeDetails } from './EmployeeDialogUtils';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'salaryAdvance';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
    dialogContent: {
        marginTop: '1rem',
        overflow: 'visible',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
});

class SalaryAdvanceDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            print: false,
            printJournal: false,
            journalEntryPrintData: null,
        };
    }

    componentDidMount() {
        const {
            advanceId,
            dispatch,
        } = this.props;
        if (advanceId) {
            dispatch(commonGetApiRequest(
                `${API.SALARYADVANCE.GET_ONE}${advanceId}`,
                {
                    successAction: FETCH_ADVANCE_SALARY_SUCCESS,
                    failureMessage: 'Failed to fetch Salary Advance',
                },
            ));
        }
    }

    componentDidUpdate(prevProps) {
        const previousEmployeeUuid = getStringFromObject('values.employee.key', prevProps) || '';
        const currentEmployeeUuid = getStringFromObject('values.employee.key', this.props) || '';
        const currentEmployee = getStringFromObject('values.employee', this.props) || null;
        if (previousEmployeeUuid !== currentEmployeeUuid) {
            this.updateSubCompany(currentEmployee);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearAdvanceSelectedState());
    }

    updateSubCompany = async (employee) => {
        const { setFieldValue, dispatch } = this.props;
        let subCompany = '';
        if (isObjectValidAndNotEmpty(employee)) {
            if (employee.subCompany) {
                ({ subCompany } = employee);
            } else {
                const employeeDetails = await fetchHrEmployeeDetails(employee, dispatch);
                if (isObjectValidAndNotEmpty(employeeDetails)) {
                    ({ subCompany } = employeeDetails);
                }
            }
        }
        setFieldValue('subCompany', subCompany);
    }

    loadRemainingAdvance = async (value) => {
        const { dispatch } = this.props;
        let amount = 0;
        if (isObjectValidAndNotEmpty(value)) {
            try {
                const response = axios.get(`${API.SALARYADVANCE.GET_RESIDUE}?employeeUuid=${value.key}&type=ADVANCE`);
                amount = NumberOf(response.data);
            } catch (error) {
                apiCatchBlockFunction(error, dispatch);
            }
        }
        this.setState({
            amount,
        });
    };

    validateEmployee = (selected) => {
        const isRequired = required(selected);
        if (isRequired) {
            return isRequired;
        }
        const subCompany = getStringFromObject('subCompany', this.props.values);
        if (!subCompany) {
            return 'Sub Company is not set';
        }
        return undefined;
    };

    fetchJournalEntriesAndPrint = () => {
        const { dispatch, values } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', values);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    handleEdit = () => {
        const { setFieldValue, values } = this.props;
        setFieldValue('editable', true);
        this.loadRemainingAdvance(getStringFromObject('employee', values));
        this.setState({
            journalEntryPrintData: null,
        });
    };

    handleConfirm = () => {
        const { submitForm } = this.props;
        submitForm();
    };

    handlePrint = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    handleEmployeeSelect = (value) => {
        this.loadRemainingAdvance(value);
        this.props.setFieldValue('fromAccount', null);
    };

    handleFromAccountSelect = (value) => {
        this.props.setFieldValue('paymentMode', null);
        const filtered = PAYMENT_METHODS.filter(entry => Object.values(entry).some(val => typeof val === 'string' && val.includes(value.userType)));
        console.log('Filtered', filtered);
        if (filtered.length !== 0) {
            this.props.setFieldValue('paymentMode', { text: filtered[0].value, value: filtered[0].value });
        }
    };

    render() {
        const {
            currency, classes, handleClose,
            values, company,
        } = this.props;
        const {
            amount,
            print,
            journalEntryPrintData,
            printJournal,
        } = this.state;
        const name = getStringFromObject('number', values);
        const issued = getStringFromObject('state', values) === 'issued';
        const editable = getStringFromObject('editable', values);
        const subCompany = getStringFromObject('subCompany', values);
        // eslint-disable-next-line max-len
        const shouldAllowEdit = !getStringFromObject('reconciled', values) && !getStringFromObject('periodClosed', values);
        console.log('afhjakdfhka', values, shouldAllowEdit);
        return (
            <Dialog
                open
                classes={{
                    paper: classes.paper,
                }}
                maxWidth="md"
                scroll="body"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div>
                            Salary Advance
                            {
                                name && <React.Fragment>({name})</React.Fragment>
                            }
                        </div>
                        <Close className="cursor-pointer" onClick={handleClose} test-id="expense-close" />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={16} justify="space-between" className="mt-2">
                            <Grid item lg={2} md={5} sm={5}>
                                <Field
                                    testId="date"
                                    name="date"
                                    component={DateInput}
                                    label="Date"
                                    validate={[required]}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5}>
                                <Grid container justify="flex-end">
                                    {
                                        editable &&
                                        <React.Fragment>
                                            <div>Current balance:</div>
                                            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                <div test-id="amount-bhd">
                                                    &nbsp;&nbsp;{currency}&nbsp;
                                                    {formatCurrency(roundedValueFixedToTwoDigits(amount))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3}>
                                {
                                    !editable && shouldAllowEdit &&
                                    <ActionButton
                                        test-id="edit-salary-advance"
                                        primary
                                        disableRipple
                                        onClick={this.handleEdit}
                                    >
                                        Edit
                                    </ActionButton>
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} className="mt-1" justify="flex-start">
                            <Grid item lg={3} md={5} sm={5}>
                                <Field
                                    testId="employee"
                                    name="employee"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    label="Employee name"
                                    placeholder="Choose an Employee"
                                    autocomplete
                                    validate={this.validateEmployee}
                                    isMandatory
                                    isDisabled={!editable}
                                    dataSourceApi={`${API.SALARYADVANCE.GET_EMPLOYEE}?name=`}
                                    onSelectHandlers={['handleEmployeeSelect']}
                                    actionHandlers={{
                                        handleEmployeeSelect: this.handleEmployeeSelect,
                                    }}
                                />
                            </Grid>
                            <Grid item lg={3} md={5} sm={5}>
                                <Field
                                    testId="fromaccount"
                                    name="fromAccount"
                                    component={FormikReactSelectMaterial}
                                    label="From Account"
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    autocomplete
                                    validate={required}
                                    isMandatory
                                    key={subCompany}
                                    isDisabled={!editable || !subCompany}
                                    dataSourceApi={
                                        `${API.SEARCH.ACCOUNTS}?subCompany=${subCompany || ''}&typeIgnoreList=consolidation,view&type=${getAllowedDefaultAccountTypes('salaryAdvanceFromAccountTypes', this.props.allowedAccountTypes)}&searchString=`
                                    }
                                    onSelectHandlers={['handleFromAccountSelect']}
                                    actionHandlers={{
                                        handleFromAccountSelect: this.handleFromAccountSelect,
                                    }}
                                />
                            </Grid>
                            <Grid item lg={3} md={5} sm={5}>
                                <Field
                                    testId="paymentMethod"
                                    name="paymentMode"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'value',
                                    }}
                                    label="Payment Method"
                                    placeholder="Payment Method"
                                    validate={required}
                                    isDisabled={!editable || !subCompany}
                                    options={PAYMENT_METHODS}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} className="mt-1" justify="space-around">
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="totalamount"
                                    name="totalAmount"
                                    label="Amount"
                                    component={FormikTextField}
                                    validate={minValue(1)}
                                    variant="outlined"
                                    type="number"
                                    required
                                    disabled={!editable}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} className="mt-1" justify="space-around">
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="description"
                                    name="description"
                                    component={FormikTextField}
                                    label="Description"
                                    multiline
                                    fullWidth
                                    rows={5}
                                    rowsMax={5}
                                    required
                                    validate={required}
                                    disabled={!editable}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} className="mt-1" justify="space-around">
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="noOfRecoups"
                                    name="noOfRecoups"
                                    variant="outlined"
                                    component={FormikTextField}
                                    label="Number of Repayment Installments"
                                    fieldType="number"
                                    type="number"
                                    fullWidth
                                    required
                                    validate={required}
                                    disabled={!editable}
                                />
                            </Grid>
                        </Grid>
                        <Print
                            print={print}
                            data={{
                                ...values,
                                company,
                                title: 'Salary Advance',
                            }}
                            url={`${APPLICATION_CONFIG_URL}/HtmlPrint/EmployeePrintouts/AdvanceSalaryPrint.html`}
                            subCompany={subCompany}
                        />
                        <Print
                            url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                            data={journalEntryPrintData}
                            print={printJournal}
                            subCompany={subCompany}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        test-id="cancel-salary-advance"
                        disableRipple
                        primary={false}
                        onClick={handleClose}
                    >
                        Cancel
                    </ActionButton>
                    {
                        editable &&
                        <ActionButton
                            testId="confirm-salary-advance"
                            primary
                            disableRipple
                            onClick={this.handleConfirm}
                        >
                            Confirm
                        </ActionButton>
                    }
                    {
                        !editable && issued &&
                        <ActionButton
                            onClick={this.handlePrint}
                        >
                            Print
                        </ActionButton>
                    }
                    {
                        !editable && issued &&
                        <ActionButton
                            onClick={this.fetchJournalEntriesAndPrint}
                        >
                            Print Journal Entries
                        </ActionButton>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

const getInitialValues = (props) => {
    if (isObjectValidAndNotEmpty(props.selectedAdvance)) {
        const editable = !getStringFromObject('state', props.selectedAdvance) === 'issued';
        const amount = NumberOf(getStringFromObject('totalAmount', props.selectedAdvance));
        const amountResidual = NumberOf(getStringFromObject('amountResidual', props.selectedAdvance));
        return ({
            ...props.selectedAdvance,
            reconciled: amountResidual < amount,
            editable,
        });
    }
    return ({ date: new Date(), editable: true, noOfRecoups: 1 });
};

const handleSubmit = (formValues, { props, ...formikBag }) => {
    const { dispatch } = props;
    if (formValues.noOfRecoups < 1) {
        dispatch(errorMessage('Number of Repayment Installments should be atleast 1'));
        return;
    }
    formikBag.setSubmitting(true);
    dispatch(createAdvancedSalary(
        { ...formValues, type: 'advance', state: 'ISSUED' },
        () => formikBag.setSubmitting(false),
        () => formikBag.setSubmitting(false),
    ));
};

SalaryAdvanceDialog.propTypes = {
    advanceId: PropTypes.number,
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    company: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    values: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    allowedAccountTypes: PropTypes.object,
    // selectedAdvance: PropTypes.object,
};

SalaryAdvanceDialog.defaultProps = {
    advanceId: null,
    company: '',
    // selectedAdvance: null,
    values: {},
    allowedAccountTypes: {},
};

const mapStateToProps = state => ({
    currency: getStringFromObject('appConfiguration.currency', state),
    company: getStringFromObject('appConfiguration.companyName', state),
    selectedAdvance: getStringFromObject('selected', state.createAdvancedSalaryReducer),
    allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
});

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => cloneDeep(getInitialValues(props)),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit,
})(withStyles(style)(SalaryAdvanceDialog)));
