import { takeLatest } from 'redux-saga/effects';
import { commonDeleteWorkerSaga, commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';
import {
    ADD_FAVOURITE_REQUEST,
    FAVOURITE_REPORTS_FETCH_REQUEST,
    REMOVE_FAVOURITE_REQUEST,
} from '../../redux/modules/favouriteReports/favouriteReports-actions';

export function* fetchFavouriteReportsWatcher() {
    yield takeLatest(FAVOURITE_REPORTS_FETCH_REQUEST, commonGetWorkerSaga);
}

export function* addFavouriteRequestWatcher() {
    yield takeLatest(ADD_FAVOURITE_REQUEST, commonPostWorkerSaga);
}

export function* removeFavouriteRequestWatcher() {
    yield takeLatest(REMOVE_FAVOURITE_REQUEST, commonDeleteWorkerSaga);
}
