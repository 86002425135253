/**
 * Created by sachinprakash on 21/6/19.
 */
import {
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    ADD_PRODUCT_SERVICES_SUCCESS,
    ADD_PRODUCT_SERVICES_FAILURE, ADD_ORDERABLE_SERVICES_SUCCESS, ADD_ORDERABLE_SERVICES_FAILURE,
} from './products-actions';
import initialStates from '../../initialStates';

import { getStringFromObject } from '../../../constants/lodashUtils';

export const productsFetcReducer = (state = initialStates.products, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
        console.log('jkdshaflkjasdfhklasfhd', action);
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            size: getStringFromObject('data.size', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            lastUpdated: new Date(),
        });
        break;
    case FETCH_PRODUCTS_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    default:
        newState = state;
    }
    console.log('smafaksdjfaklsfjsl', newState);
    return newState;
};


export const addProductService = (state = initialStates.productService, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_PRODUCT_SERVICES_SUCCESS:
        newState = Object.assign({}, state, {
            selected: action.data,
        });
        break;
    case ADD_PRODUCT_SERVICES_FAILURE:
        Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }

    return newState;
};

export const addOrderableService = (state = initialStates.productService, action) => {
    let newState = state;
    switch (action.type) {
    case ADD_ORDERABLE_SERVICES_SUCCESS:
        newState = Object.assign({}, state, {
            selected: action.data,
        });
        break;
    case ADD_ORDERABLE_SERVICES_FAILURE:
        Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }

    return newState;
};

