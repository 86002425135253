import { call, put } from 'redux-saga/effects';
import axios from 'axios/lib/axios';
import { retriable } from '../retriable';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_MY_CASH_CLOSE_FAILURE,
    FETCH_MY_CASH_CLOSE_SUCCESS,
} from '../../redux/modules/myCashClose/myCashClose-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getUrlWithApiParams,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';

export default function* fetchMyCashCloseWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, getUrlWithApiParams(action.api, action.params));
        yield put({
            type: FETCH_MY_CASH_CLOSE_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        let message = 'Network Error !!!';
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        if (e.response && e.response.data && e.response.data.exception) {
            message = e.response.data.exception;
        }
        yield put({ type: ERROR_MESSAGE, message });
        yield put({ type: FETCH_MY_CASH_CLOSE_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
