export const insuranceInvoiceTableFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'account_invoice.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: true,
            maxDateField: 'account_invoice.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_invoice.dateInvoice',
                filterOperation: '>=',
            },
        },
        {
            name: 'account_invoice.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            additionalFieldToClear: ['account_invoice.startDate'],
            minDateField: 'account_invoice.startDate',
            defaultValueField: 'account_invoice.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_invoice.dateInvoice',
                filterOperation: '<=',
            },
        },
        {
            name: 'account_invoice.state',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: true,
            options: [
                {
                    key: 'draft',
                    value: 'DRAFT',
                },
                {
                    key: 'open',
                    value: 'OPEN',
                },
                {
                    key: 'paid',
                    value: 'PAID',
                },
                {
                    key: 'cancel',
                    value: 'Cancel',
                },
            ],
            searchConstructFields: {
                name: 'account_invoice.state',
                filterOperation: '=',
            },
        },
    ]);
};

export const insuranceSearchFieldFilters = [
    {
        name: 'account_invoice.number',
        filterOperation: ':',
    },
    {
        name: 'res_partner.name',
        filterOperation: ':',
    },
    {
        name: 'res_partner.ref',
        filterOperation: ':',
    },
];

/*
*   policyHolderId: text,
    policyHolderName: text,
    policyNumber: text,
*/
