import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import axios from 'axios';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {
    apiCatchBlockFunction,
    formatCurrency,
    isArrayValidAndNotEmpty,
    roundedValueFixedToTwoDigits,
    stringEqualsIgnoreCase,
} from '../../../constants/CommonUtil';
import { DB_DATE_FORMAT, formatDateForDisplay, getDateInYYYYMMDDFormat, parseDate } from '../../../constants/DateUtil';
import { APPLICATION_CONFIG_URL, PAYMENT_METHODS_MAP } from '../../../constants/constants';
import { clearSelectedAccountVoucher, fetchAccountVoucherById } from '../../../redux/modules/payment/payment-actions';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import OutlinedTextField from '../../../components/OutlinedTextField';
import MaterialTable from '../../../components/MaterialTableV2/MaterialTable';
import ActionButton from '../../../components/ActionButton/ActionButton';
import receivePaymentDialogStyles
    from '../../../components/PurchaseOrderComponents/ReceivePaymentDialog/ReceivePaymentDialogStyles';
import { VOUCHER_LINE_SCHEMA } from './PurchasePaymentsContainerUtil';
import API from '../../../constants/api';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import DateInput from '../../../components/FormFieldComponents/DateInput/DateInput';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { getSupplierSubCompany } from '../../../components/PurchaseOrderComponents//MakeBillDialog/MakeBillDialogUtil';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const renderText = (label, value, testId) => (
    <OutlinedTextField
        test-id={testId || label}
        value={value}
        label={label}
        disabled
    />
);

const renderTotalRows = total => () => (
    <TableRow>
        <TableCell colSpan={3} />
        <TableCell align="right"><b>Total</b></TableCell>
        <TableCell><b>{roundedValueFixedToTwoDigits(total)}</b></TableCell>
    </TableRow>
);

class AccountVoucherDialog extends React.Component {
    constructor(props) {
        super(props);
        const dateTime = getStringFromObject('dateTime', props.selectedAccountVoucher);
        this.state = {
            printPayment: false,
            printJournal: false,
            paymentDate: dateTime ? new Date(NumberOf(dateTime)).getTime() : null,
            journalEntryPrintData: {},
            subCompany: '',
        };
    }

    componentDidMount() {
        const {
            dispatch,
            accountVoucherId,
        } = this.props;
        if (accountVoucherId) {
            dispatch(fetchAccountVoucherById(accountVoucherId));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(this.props.selectedAccountVoucher, nextProps.selectedAccountVoucher)) {
            const dateTime = getStringFromObject('dateTime', nextProps.selectedAccountVoucher);
            this.setState({
                paymentDate: dateTime ? new Date(NumberOf(dateTime)).getTime() : null,
            });
            this.getSubCompanyOfSupplier({ uuid: getStringFromObject('partnerId.key', nextProps.selectedAccountVoucher) });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountVoucher());
    }

    onPrintVoucher = () => {
        this.setState(prevState => ({
            printPayment: !prevState.printPayment,
        }));
    };

    onChangePaymentDate = (paymentDate) => {
        this.setState({
            paymentDate,
        });
    };

    onSaveVoucherPaymentDate = async () => {
        const {
            paymentDate,
        } = this.state;
        const {
            dispatch,
            selectedAccountVoucher,
        } = this.props;
        if (!paymentDate) {
            dispatch(errorMessage('Payment Date Not Specified'));
            return;
        }
        try {
            const dateString = getDateInYYYYMMDDFormat(new Date(paymentDate));
            dispatch(showSpinner());
            await axios.patch(`${API.ACCOUNT_VOUCHER.EDIT_PAYMENT_DATE}${selectedAccountVoucher.id}&paymentDate=${dateString}`);
            dispatch(hideSpinner());
            dispatch(successMessage('Payment Date Changed'));
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    getSubCompanyOfSupplier = async (supplier) => {
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        this.setState({
            subCompany,
        });
    };

    fetchJournalEntriesAndPrint = () => {
        const {
            journalEntryPrintData,
        } = this.state;
        const {
            selectedAccountVoucher,
            dispatch,
        } = this.props;
        const moveId = getStringFromObject('moveId.key', selectedAccountVoucher);
        if (isObjectValidAndNotEmpty(journalEntryPrintData)) {
            this.setState(p => ({
                printJournal: !p.printJournal,
            }));
        } else if (moveId) {
            dispatch(commonGetApiRequest(`${API.ACCOUNT_MOVES.GET_ONE_BY_ID}/${moveId}`, {
                failureMessage: 'Failed to load journal entries',
                successCallback: (response) => {
                    this.setState(p => ({
                        journalEntryPrintData: { ...response },
                        printJournal: !p.printJournal,
                    }));
                },
            }));
        }
    };

    undoReconciliationWaring = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            ' This is an irreversible operation.\n Do you want to cancel this voucher ?',
            this.undoReconciliation,
        ));
    };

    duplicateVoucherWarning = () => {
        const { dispatch, handleVoucherDuplication, selectedAccountVoucher } = this.props;
        dispatch(displayWarning(
            ' This operation lets you create a fresh voucher with same number as the cancelled voucher. \n' +
            ' Do you wish to continue ?',
            () => {
                const voucherNumber = getStringFromObject('number', selectedAccountVoucher);
                handleVoucherDuplication(voucherNumber);
            },
        ));
    };

    undoReconciliation = async () => {
        const {
            dispatch,
            selectedAccountVoucher,
            handleClose,
        } = this.props;
        try {
            dispatch(showSpinner());
            await axios.post(`${API.ACCOUNT_VOUCHER.CANCEL_VOUCHER}${selectedAccountVoucher.id}`);
            dispatch(hideSpinner());
            dispatch(successMessage('Voucher cancellation successful'));
            handleClose();
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    togglePaymentDateEdit = () => {
        this.setState(prevState => ({
            editPaymentDate: !prevState.editPaymentDate,
        }));
    };
    render() {
        const {
            handleClose,
            classes,
            appConfiguration,
            selectedAccountVoucher,
        } = this.props;
        const {
            printPayment,
            printJournal,
            journalEntryPrintData,
            paymentDate,
            editPaymentDate,
            subCompany,
        } = this.state;
        const moveId = NumberOf(getStringFromObject('moveId.key', selectedAccountVoucher));
        const isRefund = stringEqualsIgnoreCase(getStringFromObject('type', selectedAccountVoucher), 'refund');
        const name = getStringFromObject('number', selectedAccountVoucher);
        const voucherState = getStringFromObject('state', selectedAccountVoucher);
        const paymentMethod = getStringFromObject('paymentMode', selectedAccountVoucher);
        const isCheque = paymentMethod === PAYMENT_METHODS_MAP.CHEQUE.value;
        const isCash = paymentMethod === PAYMENT_METHODS_MAP.CASH.value;
        const isBank = paymentMethod === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
        const lines = getStringFromObject('lines', selectedAccountVoucher);
        const debitLines = [];
        const creditLines = [];
        let debitSum = 0;
        let creditSum = 0;
        if (isArrayValidAndNotEmpty(lines)) {
            lines.forEach((l) => {
                if (l.type === 'cr') {
                    creditLines.push(l);
                    creditSum += NumberOf(l.amount);
                } else {
                    debitLines.push(l);
                    debitSum += NumberOf(l.amount);
                }
            });
        }
        const paymentModeLabel = getStringFromObject(
            `${paymentMethod}.label`,
            PAYMENT_METHODS_MAP,
        );
        const printData = {
            company: getStringFromObject('companyName', appConfiguration),
            ...selectedAccountVoucher,
            isRefund,
            isCheque,
            isCash,
            isBank,
            debitLines,
            creditLines,
            paymentModeLabel,
            debitSum,
            creditSum,
        };
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    {
                                        isRefund ? 'Supplier Payment Refund' : 'Supplier Payment'
                                    }
                                    {
                                        name && <React.Fragment>({name})</React.Fragment>
                                    }
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="pay-invoice-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" justify="space-between">
                                <Grid item lg={9} md={8} sm={7} />
                                <Grid item lg={3} md={4} sm={5} style={{ textAlign: 'right' }}>
                                    <ActionButton
                                        onClick={this.togglePaymentDateEdit}
                                        data-test-id="edit-payment-date"
                                    >
                                        Edit Payment Date
                                    </ActionButton>
                                </Grid>
                                <Grid item lg={3} md={4} sm={5}>
                                    {
                                        renderText(
                                            'Supplier',
                                            getStringFromObject('partnerId.value', selectedAccountVoucher),
                                        )
                                    }
                                </Grid>
                                <Grid item lg={3} md={4} sm={5} style={{ textAlign: 'right' }}>
                                    <h5>Voucher Amount</h5>
                                    <h3>
                                        {getStringFromObject('currency', appConfiguration).concat(' ')}
                                        {
                                            formatCurrency(
                                                NumberOf(getStringFromObject('amount', selectedAccountVoucher)),
                                            )
                                        }
                                    </h3>
                                </Grid>
                            </Grid>
                            <Grid container spacing={32}>
                                <Grid item sm={4} md={3} lg={2}>
                                    {
                                        !editPaymentDate &&
                                        renderText(
                                            'Payment Date',
                                            // eslint-disable-next-line max-len
                                            formatDateForDisplay(new Date(paymentDate)),
                                        )
                                    }
                                    {
                                        editPaymentDate &&
                                        <DateInput
                                            label="Payment Date"
                                            value={paymentDate}
                                            disabled={!editPaymentDate}
                                            onChange={this.onChangePaymentDate}
                                            InputProps={{
                                                'data-test-id': 'payment-date',
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item sm={4} md={3} lg={2}>
                                    {
                                        renderText(
                                            'Payment Method',
                                            paymentModeLabel,
                                        )
                                    }
                                </Grid>
                                <Grid item sm={4} md={3} lg={3}>
                                    {
                                        // eslint-disable-next-line max-len
                                        renderText('Credit Account', getStringFromObject('account.value', selectedAccountVoucher))
                                    }
                                </Grid>
                            </Grid>
                            <Grid container className="mt-1" spacing={24}>
                                {
                                    (isCheque || isBank) &&
                                        <Grid item sm={4} md={3} lg={2}>
                                            {
                                                // eslint-disable-next-line max-len
                                                renderText('Bank Name', getStringFromObject('paymentDetails.bankName', selectedAccountVoucher))
                                            }
                                        </Grid>
                                }
                                {
                                    (isBank || isCheque) &&
                                    <Grid item sm={4} md={3} lg={2}>
                                        {
                                            // eslint-disable-next-line max-len
                                            renderText(isCheque ? 'Cheque No' : 'Reference No', getStringFromObject('paymentDetails.refNumber', selectedAccountVoucher))
                                        }
                                    </Grid>
                                }
                                {
                                    (isBank || isCheque) &&
                                    <Grid item sm={4} md={3} lg={2}>
                                        {
                                            // eslint-disable-next-line max-len
                                            renderText(isCheque ? 'Cheque Date' : 'Date', formatDateForDisplay(new Date(getStringFromObject('paymentDetails.date', selectedAccountVoucher))))
                                        }
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={32}>
                                <Grid item sm={4} md={3} lg={2}>
                                    {
                                        renderText(
                                            'Creator',
                                            // eslint-disable-next-line max-len
                                            getStringFromObject('creator', selectedAccountVoucher),
                                        )
                                    }
                                </Grid>
                                <Grid item sm={4} md={3} lg={2}>
                                    {
                                        renderText(
                                            'Date Created',
                                            // eslint-disable-next-line max-len
                                            formatDateForDisplay(parseDate(getStringFromObject('dateCreated', selectedAccountVoucher), DB_DATE_FORMAT)),
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                {
                                    isArrayValidAndNotEmpty(creditLines) &&
                                        <React.Fragment>
                                            <Grid container><h4>Credit Lines</h4></Grid>
                                            <Grid container>
                                                <MaterialTable
                                                    schema={VOUCHER_LINE_SCHEMA}
                                                    data={creditLines}
                                                    emptyMessage="No credit lines"
                                                    id="creditLines"
                                                    name="creditLines"
                                                    noPagination
                                                    renderTotalRows={renderTotalRows(creditSum)}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                }
                                {
                                    isArrayValidAndNotEmpty(debitLines) &&
                                        <React.Fragment>
                                            <Grid container className="mt-2"><h4>Debit Lines</h4></Grid>
                                            <Grid container>
                                                <MaterialTable
                                                    schema={VOUCHER_LINE_SCHEMA}
                                                    data={debitLines}
                                                    noPagination
                                                    emptyMessage="No debit lines"
                                                    id="debitLines"
                                                    name="debitLines"
                                                    renderTotalRows={renderTotalRows(debitSum)}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                }
                            </div>
                            <Grid container className="mt-2" justify="space-between">
                                <Grid item lg={3} md={3} sm={4}>
                                    <OutlinedTextField
                                        value={getStringFromObject('narration', selectedAccountVoucher)}
                                        label="Memo"
                                        multiline
                                        rows={3}
                                        rowsMax={3}
                                        isDisabled
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                editPaymentDate &&
                                <ActionButton
                                    disableRipple
                                    onClick={this.onSaveVoucherPaymentDate}
                                    data-test-id="save-supplier-payment"
                                >
                                    SAVE
                                </ActionButton>
                            }
                            {
                                !editPaymentDate &&
                                <React.Fragment>
                                    {
                                        moveId > 0 &&
                                        <React.Fragment>
                                            <ActionButton
                                                disableRipple
                                                className="ml-1"
                                                onClick={this.undoReconciliationWaring}
                                            >
                                                Cancel Voucher
                                            </ActionButton>
                                            <ActionButton
                                                disableRipple
                                                className="ml-1"
                                                onClick={this.fetchJournalEntriesAndPrint}
                                            >
                                                Print Journal Entries
                                            </ActionButton>
                                        </React.Fragment>
                                    }
                                    {
                                        voucherState === 'cancel' &&
                                        <ActionButton
                                            disableRipple
                                            className="ml-1"
                                            onClick={this.duplicateVoucherWarning}
                                        >
                                                Duplicate Voucher Number
                                        </ActionButton>
                                    }
                                    <ActionButton
                                        test-id="purchase-invoice-print-voucher"
                                        disableRipple
                                        className="ml-1"
                                        onClick={this.onPrintVoucher}
                                    >
                                        Print Voucher
                                    </ActionButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PaymentVoucher/SupplierPaymentVoucher.html`}
                    data={printData}
                    print={printPayment}
                    subCompany={subCompany}
                />
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                    data={journalEntryPrintData}
                    print={printJournal}
                    subCompany={subCompany}
                />
            </React.Fragment>
        );
    }
}

AccountVoucherDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    appConfiguration: PropTypes.object.isRequired,
    selectedAccountVoucher: PropTypes.object,
    accountVoucherId: PropTypes.number,
    handleVoucherDuplication: PropTypes.func,
};

AccountVoucherDialog.defaultProps = {
    selectedAccountVoucher: {},
    accountVoucherId: null,
    handleVoucherDuplication: () => {},
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    selectedAccountVoucher: getStringFromObject('payment.selectedVoucher', state, {}),
});

export default connect(mapStateToProps)(withStyles(receivePaymentDialogStyles)(AccountVoucherDialog));
