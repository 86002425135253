import React from 'react';
import PropTypes from 'prop-types';

/**
 * @deprecated - use BlinkingText instead
 *
 * lack of support for theme
 */
class BlinkText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState(prevState => ({
                visible: !prevState.visible,
            }));
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        const {
            visible,
        } = this.state;
        const {
            style,
            text,
        } = this.props;
        return (
            <span style={{ ...style, visibility: visible ? 'unset' : 'hidden' }}>
                {text}
            </span>
        );
    }
}

BlinkText.propTypes = {
    text: PropTypes.string,
    style: PropTypes.object,
};

BlinkText.defaultProps = {
    text: '',
    style: {},
};

export default BlinkText;

