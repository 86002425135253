export const FETCH_INVOICE_LIST_DATA_REQUEST = '@@invoice/FETCH_INVOICE_LIST_DATA_REQUEST';
export const FETCH_INVOICE_LIST_DATA_SUCCESSS = '@@invoice/FETCH_INVOICE_LIST_DATA_SUCCESS';
export const FETCH_INVOICE_LIST_DATA_FAILURE = '@@invoice/FETCH_INVOICE_LIST_DATA_FAILURE';

export const UPDATE_INVOICE_LINE_REQUEST = '@@invoice/UPDATE_INVOICE_LINE_REQUEST';

export const FETCH_INVOICE_DETAILS_REQUEST = '@@invoice/FETCH_INVOICE_DETAILS_REQUEST';
export const FETCH_INVOICE_DETAILS_SUCCESSS = '@@invoice/FETCH_INVOICE_DETAILS_SUCCESSS';

export const RESET_INVOICE_LIST_DATA = '@@invoice/RESET_INVOICE_LIST_DATA';
export const RESET_INVOICE_LINE_DATA = '@@invoice/RESET_INVOICE_LINE_DATA';

export const SAVE_INVOICE_LIST_FILTERS = '@@invoice/SAVE_INVOICE_LIST_FILTERS';

export const FETCH_INVOICE_LINE_DATA_REQUEST = '@@invoice/FETCH_INVOICE_LINE_DATA_REQUEST';
export const FETCH_INVOICE_LINE_DATA_SUCCESSS = '@@invoice/FETCH_INVOICE_LINE_DATA_SUCCESSS';

export const SAVE_INVOICE_LINE_DATA = '@@invoice/SAVE_INVOICE_LINE_DATA ';

export const fetchInvoiceListRequest = (api, page, size, filters, searchText) => ({
    type: FETCH_INVOICE_LIST_DATA_REQUEST,
    api,
    page,
    size,
    filters,
    searchText,
});

export const fetchInvoiceDetailsRequest = (api, uuid) => ({
    type: FETCH_INVOICE_DETAILS_REQUEST,
    api,
    uuid,
});

export const fetchInvoiceLineRequest = (api, uuid) => ({
    type: FETCH_INVOICE_LINE_DATA_REQUEST,
    api,
    uuid,
});
export const resetInvoiceListData = () => ({
    type: RESET_INVOICE_LIST_DATA,
});

export const resetInvoiceLineData = () => ({
    type: RESET_INVOICE_LINE_DATA,
});

export const saveInvoiceFilters = (filters, isSearch) => ({
    type: SAVE_INVOICE_LIST_FILTERS,
    filters,
    isSearch,
});

export const saveInvoiceLine = data => ({
    type: SAVE_INVOICE_LINE_DATA,
    data,
});

export const updateInvoiceLine = (api, uuid, data) => ({
    type: UPDATE_INVOICE_LINE_REQUEST,
    api,
    uuid,
    data,
});
