import { takeLatest } from 'redux-saga/effects';
import { createBedTypeWorker, fetchPagesOfBedTypeWorker } from './bedTypeWorkerSaga';
import {
    CREATE_BED_TYPE_REQUEST,
    FETCH_BED_TYPE_LIST_REQUEST,
} from '../../redux/modules/bedManagement/bedType/bedType-actions';

export function* fetchPagesOfBedTypeWatcher() {
    yield takeLatest(FETCH_BED_TYPE_LIST_REQUEST, fetchPagesOfBedTypeWorker);
}

export function* createBedTypeWatcher() {
    yield takeLatest(CREATE_BED_TYPE_REQUEST, createBedTypeWorker);
}
