/**
 * Created by vinay on 19/8/19.
 */

import initialStates from '../../initialStates';
import { CLEAR_FAVOURITE_REPORTS, FAVOURITE_REPORTS_FETCH_SUCCESS } from './favouriteReports-actions';

const favouriteReportsReducer = (state = initialStates.favouriteReports, action) => {
    let newState = {};

    switch (action.type) {
    case FAVOURITE_REPORTS_FETCH_SUCCESS:
        newState = { ...state, list: action.data };
        break;
    case CLEAR_FAVOURITE_REPORTS:
        newState = { ...state, list: [] };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default favouriteReportsReducer;
