import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { addLocaleData } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import localeAr from 'react-intl/locale-data/ar';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import deepEqual from 'react-fast-compare';
import cloneDeep from 'clone-deep';
import { connect } from 'react-redux';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import AppContainer from '../AppContainer';
import EmployeeOrganisationPage from '../pages/EmployeeOrganisationPage/EmployeeOrganisationPage';
import {
    addNewHoliday,
    analytics,
    announcements,
    announcementsCreate,
    announcementsEdit,
    APPLICATION_CONFIG_URL,
    appointments,
    appointmentsList,
    appointmentsSetting,
    assets,
    attendanceList,
    attendanceSettings,
    autoTextSettings,
    bedManagementSettings,
    billedMedicalPackages,
    bookManagement,
    bookManagementSearch,
    bookManagementSupplierPurchaseBill,
    bookSettings,
    bulkUploadSettingsPath,
    configurationSettings,
    costAccountSettings,
    createNewHoliday,
    dashboard,
    dashboardN,
    dentalSettings,
    documents,
    documentsHr,
    dutyRoster,
    dutyRosterSettings,
    eform,
    holidays,
    hrLeaveSettings,
    inbox,
    ipd,
    labSettings,
    leaveBasePage,
    medicationSettings,
    medicationSettings2,
    miscSettings,
    mrsSettings,
    myCashClose,
    myPayslip,
    notificationSetting,
    nurseQueue,
    opd,
    ordersQueue,
    organisationSettings,
    organization,
    patientDocumentsPage,
    patientSearch,
    payorNetwork,
    payors,
    payorSettings,
    payroll,
    proceduresQueue,
    profile,
    purchaseOrder,
    purchasePage,
    registration,
    registrationV2,
    registrationV3,
    reports,
    rightToLeftLanguages,
    ris,
    rootPage,
    services,
    stockAdvancedSearch,
    stockManagement,
    technicianQueue,
    userManagementSettings,
    utilPage,
    viewHolidays,
    workBoxMailPage,
} from '../constants/constants';

import PatientSearchRoutes from './PatientSearchRoutes';
import PayrollRouteContainer from '../pages/PayrollHrPage/PayrollRouteContainer';
import doctorRoutes from './DoctorRoutes';
import risRoutes from './RisRoutes';
import ordersQueueRoutes from './OrdersQueueRoutes';
import AppointmentsSettingRoutes from './AppointmentsSettingRoutes';
import AppointmentsListingRoutes from './AppointmentsListingRoutes';
import { getLanguage, persistLanguage } from '../constants/state';
import { fetchAppConfig } from '../redux/modules/appConfiguration/appConfiguration-actions';
import { hideSpinner, showSpinner } from '../redux/modules/spinner/spinner';
import { errorMessage } from '../redux/modules/message/message-actions';
import ConnectedIntlProvider from '../containers/ConnectedIntlProvider/ConnectedIntlProvider';
import { apiCatchBlockFunction, getJsonPath } from '../constants/CommonUtil';
import { fetchWorkflowConfig } from '../redux/modules/workflowConfig/workflowConfig-actions';
import StockManagementRoutes from './StockManagementRoutes';
import BookManagementRoutes from './BookManagementRoutes';
import PayerSettingRoutes from './PayerSettingRoutes';
import PayorNetworkRoutes from './PayorNetworkRoutes';
import PayorManagementRoutes from './PayorManagementRoutes';
import ServiceSettingRoutes from './ServicesSettingRoutes';
import BookSettingRoutes from './BookSettingsRoutes';
import AttendanceRoutes from './AttendanceRoutes';
import NotificationSettingRoutes from './NotificationSettingRoutes';
import AutoTextSettingSettingRoutes from './AutoTextSettingRoutes';
import AssetRoutes from './AssetRoutes';
import LabSettingRoutes from './LabSettingRoutes';
import DentalSettingRoutes from './DentalSettingRoutes';
import CostAccountSettingRoutes from './CostAccountSettingRoutes';
import DutyRosterSettingRoutes from './DutyRosterSettingRoutes';
import MyPayslipPage from '../pages/MyPayslipPage/MyPayslipPage';
import MiscSettingsRoutes from './MiscSettingsRoutes';
import BedManagementSettingsRoutes from './BedManagementSettingRoutes';
import PurchaseManagementRoutes from './PurchaseManagementRoutes';
import UserManagementRoutes from './UserManagementRoutes';
import MedicationSettingsRoutes from './MedicationSettingsRoutes';
import HrLeaveSettingsRoutes from './HrLeaveSettingsRoutes';
import AttendanceSettingsRoutes from './AttendanceSettingsRoutes';
import LeaveRoutes from './LeaveRoutes';
import MedicationSettings2Routes from './MedicationSettings2Routes';
import OrganisationSettingRoutes from './OrganisationSettingsRoutes';
import MRSRoutes from './MRSRoutes';
import AppSettingsRoutes from './AppSettingsRoute';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
import PageLoader from '../components/PageLoader/PageLoader';
import BulkUploaderRoutes from './BulkUploaderRoutes';


const AdvanceSearchPage = React.lazy(() => import('../pages/BookManagementPages/AdvanceSearchPage'));

const SupplierPurchaseBillPage = React.lazy(() => import('../pages/BookManagementPages/SupplierPurchaseBillPage'));

const EmployeeDashboardPage = React.lazy(() => import('../pages/EmployeeDashboardPage/EmployeeDashboardPage'));
const EmployeeDashboardPageN = React.lazy(() => import('../pages/EmployeeDashboardPage/EmployeeDashboardPageN'));
const EmployeeProfilePage = React.lazy(() => import('../pages/EmployeeProfilePage/EmployeeProfilePage'));
const EmployeeDocumentPage = React.lazy(() => import('../pages/EmployeeDocumentPage/EmployeeDocumentPage'));
const BilledMedicalPackagePage = React.lazy(() => import('../pages/BilledMedicalPackagePage/BilledMedicalPackagePage'));
const HolidaysPage = React.lazy(() => import('../pages/HolidaysPage/HolidaysPage'));
const EditHolidaysPage = React.lazy(() => import('../pages/HolidaysPage/EditHolidaysPage'));
const DutyRosterPage = React.lazy(() => import('../pages/DutyRosterPage/DutyRosterPage'));
const AnnouncementsPage = React.lazy(() => import('../pages/AnnouncementsPage/AnnouncementsPage'));
const AnnouncementsEditPage = React.lazy(() => import('../pages/AnnouncementsPage/AnnouncementsEditPage'));
const AnnouncementsCreatePage = React.lazy(() => import('../pages/AnnouncementsPage/AnnouncementsCreatePage'));
const DocumentsHrPage = React.lazy(() => import('../pages/DocumentsHrPage/DocumentsHrPage'));
const EFormPage = React.lazy(() => import('../pages/EFormPage/EFormPage'));
const LoginPage = React.lazy(() => import('../pages/LoginPage/LoginPage'));
const WorkflowPage = React.lazy(() => import('../pages/WorkflowPage/WorkflowPage'));
const InboxPage = React.lazy(() => import('../pages/InboxPage/InboxPage'));
const CreateHolidaysPage = React.lazy(() => import('../pages/HolidaysPage/CreateHolidaysPage'));
const ViewHolidaysPage = React.lazy(() => import('../pages/HolidaysPage/ViewHolidaysPage'));
const ErpAdvanceSearchPage = React.lazy(() => import('../pages/StockManagementPages/ErpAdvanceSearchPage'));
const NurseQueuePage = React.lazy(() => import('../pages/NurseQueuePage/NurseQueuePage'));
const ReportsPage = React.lazy(() => import('../pages/ReportsPage/ReportsPage'));
const TechnicianQueuePage = React.lazy(() => import('../pages/TechnicianQueuePage/TechnicianQueuePage'));
const ProceduresQueuePage = React.lazy(() => import('../pages/ProceduresQueuePage/ProceduresQueuePage'));
const AppointmentsPage = React.lazy(() => import('../pages/AppointmentsPage/AppointmentsPage'));
const PurchaseOrderPage = React.lazy(() => import('../pages/StockManagementPages/PurchaseOrderPage'));
const RegistrationPage = React.lazy(() => import('../pages/RegistrationPage/RegistrationPage'));

const RegistrationPageV2 = React.lazy(() => import('../pages/RegistrationPage/RegistrationPageV2'));

const RegistrationPageV3 = React.lazy(() => import('../pages/RegistrationPage/RegistrationPageV3'));

const AnalyticsPage = React.lazy(() => import('../pages/AnalyticsPage/AnalyticsPage'));


const UtilsPage = React.lazy(() => import('../pages/UtilsPage/UtilsPage'));

const PatientDocumentsPage = React.lazy(() => import('../pages/PatientDocumentsPage/PatientDocumentsPage'));
const WorkBoxMailPage = React.lazy(() => import('../pages/WorkBoxMailPage/WorkBoxMailPage'));
const MyCashClosePage = React.lazy(() => import('../pages/MyCashClosePage/MyCashClosePage'));

addLocaleData([...localeEn, ...localeAr]);
class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: {},
            intlResourcesLoaded: false,
        };
        this.jss = create({ plugins: [...jssPreset().plugins] });
        // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
        this.jss.options.insertionPoint = document.getElementById('jss-insertion-point');
        if (rightToLeftLanguages.includes(getLanguage())) {
            console.log('2432414124321', getLanguage());
            this.jss = create({ plugins: [...jssPreset().plugins, rtl()] });
        }
        // Custom Material-UI class name generator.
        this.generateClassName = createGenerateClassName();
    }
    componentDidMount() {
        this.fetchAppVersion();
        if (!getLanguage()) {
            persistLanguage('en');
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.appConfiguration, this.props.appConfiguration)) {
            this.fetchLanguageJSONS(getStringFromObject('appConfiguration.languages', nextProps, {}));
        }
        if (nextProps.language !== this.props.language) {
            console.log('2233444', nextProps.language);
            this.jss = create({ plugins: [...jssPreset().plugins] });
            if (rightToLeftLanguages.includes(getLanguage())) {
                this.jss = create({ plugins: [...jssPreset().plugins, rtl()] });
            }
            this.jss.options.insertionPoint = document.getElementById('jss-insertion-point');
        }
    }

    fetchAppVersion = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            this.props.dispatch(fetchAppConfig());
            this.props.dispatch(fetchWorkflowConfig());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
            this.props.dispatch(fetchAppConfig());
            this.props.dispatch(fetchWorkflowConfig());
        }
    };

    fetchLanguageJSONS = (languages) => {
        if (isObjectValidAndNotEmpty(languages)) {
            const languageKeys = Object.keys(languages);
            languageKeys.map((languageKey) => {
                this.props.dispatch(showSpinner());
                fetch(getJsonPath(languages[languageKey], APPLICATION_CONFIG_URL), {
                    credentials: 'same-origin',
                })
                    .then(resp => resp.json())
                    .then((json) => {
                        const languagesJSONs = cloneDeep(this.state.languages);
                        console.log('JSONCAlL', json);
                        languagesJSONs[languageKey] = json;
                        console.log('tis2312312asdas222', languagesJSONs, languageKeys);
                        if (Object.keys(languagesJSONs).length === languageKeys.length) {
                            this.props.dispatch(hideSpinner());
                        }
                        this.setState({
                            languages: languagesJSONs,
                            intlResourcesLoaded: Object.keys(languagesJSONs).length === languageKeys.length,
                        });
                    })
                    .catch((error) => {
                        this.props.dispatch(errorMessage(
                            'Error While Fetching Language Jsons',
                        ));
                        console.error(
                            `There has been a problem with your fetch operation:${error.message}`,
                        );
                        this.props.dispatch(hideSpinner());
                    });
                return null;
            });
        }
    };

    render() {
        const messages = this.state.languages;
        const language = getLanguage() || 'en';
        console.log('asd[aksopdasd,sa', this.state.languages);
        if (this.state.intlResourcesLoaded) {
            return (
                <ConnectedIntlProvider locale={language} messages={messages[language]}>
                    <JssProvider jss={this.jss} generateClassName={this.generateClassName}>
                        <Suspense fallback={<PageLoader />}>
                            <Route exact path={rootPage} component={LoginPage} />
                            {
                                this.props.location.pathname !== '/' &&
                                <AppContainer>
                                    <Switch>
                                        <Route path={ipd} exact component={EmployeeDashboardPage} />
                                        <Route path={dashboardN} exact component={EmployeeDashboardPage} />
                                        <Route path={dashboard} exact component={EmployeeDashboardPageN} />
                                        <Route path={`${profile}/:uuid`} exact component={EmployeeProfilePage} />
                                        <Route path={leaveBasePage} component={LeaveRoutes} />
                                        <Route path={organization} component={EmployeeOrganisationPage} />
                                        <Route path={documents} exact component={EmployeeDocumentPage} />
                                        <Route path={billedMedicalPackages} exact component={BilledMedicalPackagePage} />
                                        <Route path={documentsHr} exact component={DocumentsHrPage} />
                                        <Route path={`${eform}`} exact component={EFormPage} />
                                        <Route path={`${eform}/:type/:stage`} exact component={WorkflowPage} />
                                        <Route
                                            path={`${eform}/:type/:stage/:entityUuid/:inboxType`}
                                            exact
                                            component={WorkflowPage}
                                        />
                                        <Route path={holidays} exact component={HolidaysPage} />
                                        <Route path={`${addNewHoliday}/:uuid`} exact component={EditHolidaysPage} />
                                        <Route path={`${viewHolidays}/:uuid`} exact component={ViewHolidaysPage} />
                                        <Route path={createNewHoliday} exact component={CreateHolidaysPage} />
                                        <Route path={dutyRoster} exact component={DutyRosterPage} />
                                        <Route path={myCashClose} exact component={MyCashClosePage} />
                                        <Route path={assets} component={AssetRoutes} />
                                        <Route path={announcements} exact component={AnnouncementsPage} />
                                        <Route path={announcementsCreate} exact component={AnnouncementsCreatePage} />
                                        <Route path={announcementsEdit} exact component={AnnouncementsEditPage} />
                                        <Route path={payroll} component={PayrollRouteContainer} />
                                        <Route path={opd} component={doctorRoutes} />
                                        <Route path={ris} component={risRoutes} />
                                        <Route path={ordersQueue} component={ordersQueueRoutes} />
                                        <Route path={`${inbox}/:type`} component={InboxPage} />
                                        <Route path={`${patientSearch}`} component={PatientSearchRoutes} />
                                        <Route path={stockManagement} component={StockManagementRoutes} />
                                        <Route path={bookManagementSearch} exact component={AdvanceSearchPage} />
                                        <Route
                                            path={`${bookManagementSupplierPurchaseBill}/:id/:shopId`}
                                            exact
                                            component={SupplierPurchaseBillPage}
                                        />
                                        <Route path={bookManagement} component={BookManagementRoutes} />
                                        <Route path={`${purchaseOrder}/:id`} exact component={PurchaseOrderPage} />
                                        <Route path={stockAdvancedSearch} exact component={ErpAdvanceSearchPage} />
                                        <Route path={nurseQueue} exact component={NurseQueuePage} />
                                        <Route path={reports} exact component={ReportsPage} />
                                        <Route path={registration} exact component={RegistrationPage} />
                                        <Route path={registrationV2} exact component={RegistrationPageV2} />
                                        <Route path={registrationV3} exact component={RegistrationPageV3} />
                                        <Route path={technicianQueue} exact component={TechnicianQueuePage} />
                                        <Route path={proceduresQueue} exact component={ProceduresQueuePage} />
                                        <Route path={appointments} exact component={AppointmentsPage} />
                                        <Route path={appointmentsSetting} component={AppointmentsSettingRoutes} />
                                        <Route path={appointmentsList} component={AppointmentsListingRoutes} />
                                        <Route path={analytics} component={AnalyticsPage} />
                                        <Route path={utilPage} component={UtilsPage} />
                                        <Route path={payorNetwork} component={PayorNetworkRoutes} />
                                        <Route path={payorSettings} component={PayerSettingRoutes} />
                                        <Route path={payors} component={PayorManagementRoutes} />
                                        <Route path={purchasePage} component={PurchaseManagementRoutes} />
                                        <Route path={services} component={ServiceSettingRoutes} />
                                        <Route path={bookSettings} component={BookSettingRoutes} />
                                        <Route path={attendanceList} component={AttendanceRoutes} />
                                        <Route path={configurationSettings} component={AppSettingsRoutes} />
                                        <Route path={notificationSetting} component={NotificationSettingRoutes} />
                                        <Route path={autoTextSettings} component={AutoTextSettingSettingRoutes} />
                                        <Route path={labSettings} component={LabSettingRoutes} />
                                        <Route path={dentalSettings} component={DentalSettingRoutes} />
                                        <Route path={dutyRosterSettings} component={DutyRosterSettingRoutes} />
                                        <Route path={miscSettings} component={MiscSettingsRoutes} />
                                        <Route path={bedManagementSettings} component={BedManagementSettingsRoutes} />
                                        <Route path={userManagementSettings} component={UserManagementRoutes} />
                                        <Route path={medicationSettings} component={MedicationSettingsRoutes} />
                                        <Route path={medicationSettings2} component={MedicationSettings2Routes} />
                                        <Route path={organisationSettings} component={OrganisationSettingRoutes} />
                                        <Route path={hrLeaveSettings} component={HrLeaveSettingsRoutes} />
                                        <Route path={attendanceSettings} component={AttendanceSettingsRoutes} />
                                        <Route path={costAccountSettings} component={CostAccountSettingRoutes} />
                                        <Route path={myPayslip} component={MyPayslipPage} />
                                        <Route path={patientDocumentsPage} component={PatientDocumentsPage} />
                                        <Route path={workBoxMailPage} component={WorkBoxMailPage} />
                                        <Route path={mrsSettings} component={MRSRoutes} />
                                        <Route path={bulkUploadSettingsPath} component={BulkUploaderRoutes} />
                                    </Switch>
                                </AppContainer>
                            }
                        </Suspense>
                    </JssProvider>
                </ConnectedIntlProvider>
            );
        }
        return null;
    }
}
Routes.propTypes = {
    appConfiguration: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    language: PropTypes.string,
};

Routes.defaultProps = {
    appConfiguration: {},
    location: {},
    language: '',
    dispatch: () => { },
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    language: state.language,
});
export default withRouter(connect(mapStateToProps)(Routes));

