import { roundedValueFixedToTwoDigits } from '../constants/CommonUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const getAccountDtoFromUiObject = (uiObject) => {
    let account = {};
    if (isObjectValidAndNotEmpty(uiObject)) {
        account = {
            name: getStringFromObject('name', uiObject, null),
            code: getStringFromObject('code', uiObject, null),
            type: getStringFromObject('type', uiObject, null),
            currencyMode: getStringFromObject('currencyMode', uiObject) || null,
            parentId: getStringFromObject('parent', uiObject, {}),
            userType: getStringFromObject('userType', uiObject) || null,
            companyId: getStringFromObject('company', uiObject) || null,
            active: getStringFromObject('active', uiObject, false),
            id: getStringFromObject('id', uiObject, null),
        };
        const subCompany = getStringFromObject('subCompany', uiObject, null);
        if (isObjectValidAndNotEmpty(subCompany)) {
            account.subCompany = subCompany.key;
        }
    }
    return account;
};

export const getUiObjectFromAccountDto = (obj) => {
    let account = {};
    if (isObjectValidAndNotEmpty(obj)) {
        const credit = getStringFromObject('credit', obj, 0);
        const debit = getStringFromObject('debit', obj, 0);
        const balance = roundedValueFixedToTwoDigits(debit - credit);
        account = {
            name: getStringFromObject('name', obj, null),
            code: getStringFromObject('code', obj, null),
            type: getStringFromObject('type', obj, null),
            currencyMode: getStringFromObject('currencyMode', obj, null),
            parent: getStringFromObject('parentId', obj, {}),
            userType: getStringFromObject('userType', obj, {}),
            company: getStringFromObject('companyId', obj, false),
            active: getStringFromObject('active', obj, false),
            id: getStringFromObject('id', obj, null),
            credit,
            debit,
            balance,
            subCompany: obj.subCompany ? { key: obj.subCompany, value: obj.subCompany } : null,
        };
    }
    return account;
};

