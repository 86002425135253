import initialStates from '../../initialStates';
import {
    INBOX_FETCH_FAILURE,
    INBOX_FETCH_REQUEST,
    INBOX_FETCH_SUCCESS,
} from './inbox-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

const inboxReducer = (state = initialStates.inbox, action) => {
    let newState = {};

    switch (action.type) {
    case INBOX_FETCH_REQUEST:
        newState = Object.assign({}, state, { isLoading: true, isFetched: false });
        break;
    case INBOX_FETCH_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            [action.inboxType]: {
                data: getStringFromObject('data.page.content', action, []),
                page: getStringFromObject('data.page.number', action, 0),
                isLast: getStringFromObject('data.isLast', action, false),
                totalPages: getStringFromObject('data.page.totalPages', action, 0),
            },
            isFetched: true,
        });
        break;
    case INBOX_FETCH_FAILURE:
        newState = Object.assign({}, state, { isLoading: false, isFetched: true });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default inboxReducer;
