import API from '../../../constants/api';

export const FETCH_ACCOUNTS_LIST_REQUEST = '@@accounts/FETCH_ACCOUNTS_LIST_REQUEST';
export const FETCH_ACCOUNTS_LIST_FAILURE = '@@accounts/FETCH_ACCOUNTS_LIST_FAILURE';
export const FETCH_ACCOUNTS_LIST_SUCCESS = '@@accounts/FETCH_ACCOUNTS_LIST_SUCCESS';

export const CREATE_ACCOUNTS_REQUEST = '@@accounts/CREATE_ACCOUNTS_REQUEST';
export const CREATE_ACCOUNTS_FAILURE = '@@accounts/CREATE_ACCOUNTS_FAILURE';
export const CREATE_ACCOUNTS_SUCCESS = '@@accounts/CREATE_ACCOUNTS_SUCCESS';

export const ADJUST_BALANCE_REQUEST = '@@accounts/ADJUST_BALANCE_REQUEST';
export const ADJUST_BALANCE_FAILURE = '@@accounts/ADJUST_BALANCE_FAILURE';
export const ADJUST_BALANCE_SUCCESS = '@@accounts/ADJUST_BALANCE_SUCCESS';

export const CLEAR_ACCOUNTS_STATE = '@@accounts/CLEAR_ACCOUNTS_STATE';

export const fetchPagesOfAccounts = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_ACCOUNTS_LIST_REQUEST,
    // eslint-disable-next-line max-len
    api: `${API.ACCOUNT_HEAD.PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}&filter=${
        encodeURIComponent(JSON.stringify(filters))}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const adjustBalanceRequest = (account, credit, debit, reason, date, successCallback, failureCallBack) => ({
    type: ADJUST_BALANCE_REQUEST,
    api: API.ACCOUNT_HEAD.UPDATE_BALANCE,
    account,
    credit,
    debit,
    reason,
    date,
    successCallback,
    failureCallBack,
});

export const createAccountRequest = (account, successCallback, failureCallBack) => ({
    type: CREATE_ACCOUNTS_REQUEST,
    api: API.ACCOUNT_HEAD.CREATE,
    account,
    successCallback,
    failureCallBack,
});

export const clearAccountState = () => ({
    type: CLEAR_ACCOUNTS_STATE,
});
