import { generateObjectWithCriteriaMode, getCombined } from '../util';

import { getStringFromObject } from '../../constants/lodashUtils';

const filterObj = {
    giveName: 'person_name.givenName',
    familyName: 'person_name.familyName',
    middleName: 'person_name.middleName',
    identificationId: 'hr_employee.identificationId',
    reportingTo: 'hr_employee.hrEmployeeByParentId',
    position: 'hr_employee.hrJob.id',
    department: 'hr_employee.hrDepartment.id',
};

const specsForFilter = (filter) => {
    let searchConstruct = {};
    if (filter && filter.searchText) {
        let { searchText } = filter;
        searchText = searchText.trim();
        const giveName = generateObjectWithCriteriaMode(filterObj.giveName, searchText, ':');
        const middleName = generateObjectWithCriteriaMode(filterObj.middleName, searchText, ':');
        const familyName = generateObjectWithCriteriaMode(filterObj.familyName, searchText, ':');
        let joinName = getCombined(getCombined(giveName, 'OR', middleName), 'OR', familyName);
        const identificationJoin = generateObjectWithCriteriaMode(filterObj.identificationId, searchText, ':');
        joinName = getCombined(joinName, 'OR', identificationJoin);
        searchConstruct = getCombined(searchConstruct, 'OR', joinName);
    }
    if (filter && filter.reportingTo) {
        const { reportingTo } = filter;
        const reportingJoin = generateObjectWithCriteriaMode(filterObj.reportingTo, reportingTo, '=');
        searchConstruct = getCombined(searchConstruct, 'AND', reportingJoin);
    }
    if (filter && filter.positionSelectText) {
        const { positionSelectText } = filter;
        if (getStringFromObject('key', positionSelectText) !== '') {
            const positionJoin = generateObjectWithCriteriaMode(filterObj.position, getStringFromObject('key', positionSelectText), ':');
            searchConstruct = getCombined(searchConstruct, 'AND', positionJoin);
        }
    }
    if (filter && filter.departmentSelectText) {
        const { departmentSelectText } = filter;
        if (getStringFromObject('key', departmentSelectText) !== '') {
            const departmentJoin = generateObjectWithCriteriaMode(filterObj.department, getStringFromObject('key', departmentSelectText), ':');
            searchConstruct = getCombined(searchConstruct, 'AND', departmentJoin);
        }
    }
    if (Object.keys(searchConstruct).length === 0) {
        searchConstruct = null;
    }
    if (filter && !filter.showDeactivated) {
        searchConstruct = getCombined(
            generateObjectWithCriteriaMode('users.retired', false, '==='),
            'AND',
            searchConstruct,
        );
    }
    return { searchConstruct };
};

export default specsForFilter;
