import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_ANALYTICAL_ACCOUNTS_FAILURE,
    CREATE_ANALYTICAL_ACCOUNTS_SUCCESS,
    FETCH_ANALYTICAL_ACCOUNTS_FAILURE,
    FETCH_ANALYTICAL_ACCOUNTS_SUCCESS,
} from '../../redux/modules/analyticalAccounts/analyticalAccounts-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* fetchPagesOfAnalyticalAccountsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const searchString = getStringFromObject('filters.name', action);
        const response = yield call(retriable().get, `${action.api}&searchString=${searchString}`);
        yield put({
            type: FETCH_ANALYTICAL_ACCOUNTS_SUCCESS,
            data: response.data,
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.ACCOUNTS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_ANALYTICAL_ACCOUNTS_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createAnalyticalAccountWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.account);
        yield put({
            type: CREATE_ANALYTICAL_ACCOUNTS_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.ACCOUNTS.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.ACCOUNTS.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_ANALYTICAL_ACCOUNTS_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
