/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OrgChart from '../Orgchart/orgchart';
import '../Orgchart/orgchart.css';

const datascource = {
    name: 'Lao Lao',
    title: 'general manager',
    className: 'top-level',
    children: [
        { name: 'Bo Miao', title: 'department manager', className: 'middle-level' },
        {
            name: 'Su Miao',
            title: 'department manager',
            className: 'middle-level',
            children: [
                { name: 'Tie Hua', title: 'senior engineer', className: 'bottom-level' },
                {
                    name: 'Hei Hei',
                    title: 'senior engineer',
                    className: 'bottom-level',
                    children: [
                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                    ],
                },
            ],
        },
        {
            name: 'Su Miao',
            title: 'department manager',
            className: 'middle-level',
            children: [
                {
                    name: 'Tie Hua',
                    title: 'senior engineer',
                    className: 'bottom-level',
                    children: [
                        { name: 'Bo Miao', title: 'department manager', className: 'middle-level' },
                        {
                            name: 'Su Miao',
                            title: 'department manager',
                            className: 'middle-level',
                            children: [
                                { name: 'Tie Hua', title: 'senior engineer', className: 'bottom-level' },
                                {
                                    name: 'Hei Hei',
                                    title: 'senior engineer',
                                    className: 'bottom-level',
                                    children: [
                                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Hei Hei',
                    title: 'senior engineer',
                    className: 'bottom-level',
                    children: [
                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                        {
                            name: 'Su Miao',
                            title: 'department manager',
                            className: 'middle-level',
                            children: [
                                { name: 'Tie Hua', title: 'senior engineer', className: 'bottom-level' },
                                {
                                    name: 'Hei Hei',
                                    title: 'senior engineer',
                                    className: 'bottom-level',
                                    children: [
                                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                                        {
                                            name: 'Su Miao',
                                            title: 'department manager',
                                            className: 'middle-level',
                                            children: [
                                                {
                                                    name: 'Hei Hei',
                                                    title: 'senior engineer',
                                                    className: 'bottom-level',
                                                    children: [
                                                        {
                                                            name: 'Pang Pang',
                                                            title: 'engineer',
                                                            className: 'bottom-level',
                                                        },
                                                        {
                                                            name: 'Xiang Xiang',
                                                            title: 'UE engineer',
                                                            className: 'bottom-level',
                                                        },
                                                        {
                                                            name: 'Su Miao',
                                                            title: 'department manager',
                                                            className: 'middle-level',
                                                            children: [
                                                                {
                                                                    name: 'Tie Hua',
                                                                    title: 'senior engineer',
                                                                    className: 'bottom-level',
                                                                },
                                                                {
                                                                    name: 'Hei Hei',
                                                                    title: 'senior engineer',
                                                                    className: 'bottom-level',
                                                                    children: [
                                                                        {
                                                                            name: 'Pang Pang',
                                                                            title: 'engineer',
                                                                            className: 'bottom-level',
                                                                        },
                                                                        {
                                                                            name: 'Xiang Xiang',
                                                                            title: 'UE engineer',
                                                                            className: 'bottom-level',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    name: 'Tie Hua',
                                                    title: 'senior engineer',
                                                    className: 'bottom-level',
                                                },
                                                {
                                                    name: 'Hei Hei',
                                                    title: 'senior engineer',
                                                    className: 'bottom-level',
                                                    children: [
                                                        {
                                                            name: 'Pang Pang',
                                                            title: 'engineer',
                                                            className: 'bottom-level',
                                                        },
                                                        {
                                                            name: 'Xiang Xiang',
                                                            title: 'UE engineer',
                                                            className: 'bottom-level',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            name: 'Su Miao',
                            title: 'department manager',
                            className: 'middle-level',
                            children: [
                                { name: 'Tie Hua', title: 'senior engineer', className: 'bottom-level' },
                                {
                                    name: 'Hei Hei',
                                    title: 'senior engineer',
                                    className: 'bottom-level',
                                    children: [
                                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                                    ],
                                },
                            ],
                        },
                        {
                            name: 'Su Miao',
                            title: 'department manager',
                            className: 'middle-level',
                            children: [
                                { name: 'Tie Hua', title: 'senior engineer', className: 'bottom-level' },
                                {
                                    name: 'Hei Hei',
                                    title: 'senior engineer',
                                    className: 'bottom-level',
                                    children: [
                                        { name: 'Pang Pang', title: 'engineer', className: 'bottom-level' },
                                        { name: 'Xiang Xiang', title: 'UE engineer', className: 'bottom-level' },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        { name: 'Yu Jie', title: 'department manager', className: 'middle-level' },
        { name: 'Yu Li', title: 'department manager', className: 'middle-level' },
        { name: 'Hong Miao', title: 'department manager', className: 'middle-level' },
        { name: 'Yu Wei', title: 'department manager', className: 'middle-level' },
        { name: 'Chun Miao', title: 'department manager', className: 'middle-level' },
        { name: 'Yu Tie', title: 'department manager', className: 'middle-level' },
    ],
};


const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        width: '550px',
    },
});

class OrganisationBoxView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isListView: false,
        };
    }

    componentDidMount() {
        const org = new OrgChart({
            chartContainer: '#chart-container',
            data: datascource,
            depth: 2,
            nodeContent: 'title',
            direction: 't2b',
            nodeId: 'id',
        });
    }

    render() {
        const { classes } = this.props;
        const { isListView } = this.state;
        console.log('11111fkhvbkdsfjb', classes, isListView);
        return (
            <div
                style={{
                    overflow: 'auto',
                }}
            >
                <div style={{ height: '100%', width: '100%', overflow: 'auto' }} id="chart-container" />
            </div>
        );
    }
}
OrganisationBoxView.propTypes = {
    classes: PropTypes.object.isRequired,
};

OrganisationBoxView.defaultProps = {

};
export default withStyles(styles)(OrganisationBoxView);
