import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
// import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import Card from '@material-ui/core/Card/Card';
import Skeleton from 'react-loading-skeleton';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import Typography from '@material-ui/core/Typography/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import {
    apiCatchBlockFunction,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty, isValidFunction,
} from '../constants/CommonUtil';
import { formatDateTimeForDisplay } from '../constants/DateUtil';
import api from '../constants/api';
import { hideSpinner } from '../redux/modules/spinner/spinner';
import { SIZE, notificationTypes, leftPaneToPageMap } from '../constants/constants';
import LabResultsDetailsDialog from '../containers/ClinicalInboxContainer/LabResultDialog/LabResultsDetailsDialog';
import Linkify from './Linkify/Linkify';
import { getStringFromObject } from '../constants/lodashUtils';

const getTitleDisplayFromNotification = (n, mapWorkflowNameToTitle) => {
    if (n.notificationType === notificationTypes.WORKFLOW) {
        return getStringFromObject(n.title, mapWorkflowNameToTitle, n.title);
    }
    return n.title;
};

class NotificationDialogContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: SIZE,
            notifications: [],
            totalElements: 0,
            loading: false,
            viewLabResults: false,
            targetObjectUuid: '',
        };
    }

    componentDidMount() {
        this.fetchList();
        this.updateDialogActions();
        this.updateReadStatus();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.totalElements !== this.state.totalElements) {
            this.updateDialogActions(this.state);
        }
        if (!deepEqual(prevState.notifications, this.state.notifications)) {
            // update read status
            this.updateReadStatus(this.state);
        }
    }

    onNotificationClick = (aNotification) => {
        console.log('asd-0saida-dada', aNotification);
        if (aNotification.notificationType === notificationTypes.ABNORMAL_LAB_RESULT) {
            this.setState({
                targetObjectUuid: aNotification.objectUuid,
            });
            this.toggleLabView();
        } else if (aNotification.notificationType === notificationTypes.WORKFLOW) {
            const pushFn = getStringFromObject('push', this.props.history);
            if (isValidFunction(pushFn)) {
                pushFn(leftPaneToPageMap.inbox);
                this.closeDialog();
            }
        }
    };

    closeDialog = () => {
        const { handleClose } = this.props;
        if (isValidFunction(handleClose)) {
            handleClose();
        }
    };

    updateReadStatus = (state = this.state) => {
        const {
            notifications,
        } = state;
        console.log('sdas9dus0du9d', notifications);
        if (isArrayValidAndNotEmpty(notifications)) {
            const unreadAnnouncements =
                notifications.filter(aNotification => aNotification.readStatus === 'UN_READ').map(
                    anAnnouncement => anAnnouncement.notificationUuid,
                );
            if (isArrayValidAndNotEmpty(unreadAnnouncements)) {
                axios.post(api.NOTIFICATIONS.READ_ANNOUNCEMENTS, unreadAnnouncements)
                    .then(() => {
                        this.props.fetchNotificationCount();
                    });
            }
        }
    };

    updateDialogActions = (state = this.state) => {
        const {
            updateDialogActions,
        } = this.props;
        const {
            page,
            size,
            totalElements,
        } = state;
        console.log('asda9sujd-as0dja', totalElements, size, page);
        const dialogActions = (
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalElements}
                rowsPerPage={size}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
            />
        );
        updateDialogActions(dialogActions);
    };

    handleChangePage = (event, page) => {
        this.setState({
            page,
        }, this.fetchList);
    };

    fetchList = () => {
        const {
            page,
            size,
        } = this.state;
        console.log(size);
        const {
            dispatch,
            ignoreNotificationTypes,
            notificationTypesIn,
        } = this.props;
        this.setState({
            loading: true,
        });
        const url = getUrlWithApiParams(api.NOTIFICATIONS.ALL_NOTIFICATION_FOR_USER, {
            page, size, ignoreTypes: ignoreNotificationTypes, typesIn: notificationTypesIn,
        });
        axios.get(url)
            .then((response) => {
                this.setState({
                    notifications: getStringFromObject('content', response.data, []),
                    totalElements: getStringFromObject('totalElements', response.data, []),
                    loading: false,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, dispatch);
                this.setState({
                    loading: false,
                });
            })
            .finally(() => {
                dispatch(hideSpinner());
            });
        this.updateDialogActions();
    };

    toggleLabView = () => {
        this.setState(prevState => ({
            viewLabResults: !prevState.viewLabResults,
        }));
    };

    render() {
        const {
            notifications,
            loading,
            size,
            viewLabResults,
            targetObjectUuid,
        } = this.state;
        const {
            mapWorkflowNameToTitle,
        } = this.props;
        console.log('announcementList', notifications);
        let notificationList = notifications;
        if (loading) {
            notificationList = Array(size).fill({ uuid: uuidv4() });
        }
        console.log('asdsa-0id-a', notificationList);
        return (
            <div>
                <Grid container spacing={16} justify="flex-start">
                    <DialogContentText className="fullWidth">
                        {
                            isArrayValidAndNotEmpty(notificationList) &&
                            notificationList.map((aNotification, index) => (
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    style={{
                                        cursor:
                                            [
                                                notificationTypes.ABNORMAL_LAB_RESULT,
                                                notificationTypes.WORKFLOW,
                                            ].includes(
                                                aNotification.notificationType,
                                            ) ?
                                                'pointer' :
                                                'auto',
                                    }}
                                    onClick={() => {
                                        this.onNotificationClick(aNotification);
                                    }}
                                    // eslint-disable-next-line
                                    key={`card-${aNotification.uuid}-${index}`}
                                >
                                    {console.log('asda', aNotification)}
                                    <Card
                                        square
                                        elevation="0"
                                        style={{ borderBottom: 'solid rgb(70, 157, 199) 0.7px' }}
                                    >
                                        <CardHeader
                                            title={
                                                loading ?
                                                    <Skeleton /> : getTitleDisplayFromNotification(aNotification, mapWorkflowNameToTitle)
                                            }
                                            subheader={loading ? <Skeleton /> :
                                                `By ${getStringFromObject('createdBy.value', aNotification)} on 
                                                ${formatDateTimeForDisplay(new Date(aNotification.createDate))}
                                                `
                                            }
                                        >
                                            <div style={{ fontWeight: 'bold' }}>
                                                {
                                                    loading ? <Skeleton />
                                                        : getTitleDisplayFromNotification(aNotification, mapWorkflowNameToTitle)
                                                }
                                            </div>
                                        </CardHeader>
                                        <CardContent>
                                            <Linkify>
                                                <Typography component="p">
                                                    {aNotification.message}
                                                </Typography>
                                            </Linkify>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                        {
                            !isArrayValidAndNotEmpty(notificationList) &&
                            <div className="mt-2 flexWithCenterAligned justify-content-center font-size-1">
                                No Notifications To Display
                            </div>
                        }
                    </DialogContentText>
                </Grid>
                {
                    viewLabResults &&
                    <LabResultsDetailsDialog
                        open={viewLabResults}
                        acquisitionUuid={targetObjectUuid}
                        onClose={this.toggleLabView}
                        showActionButtons={false}
                        dispatch={this.props.dispatch}
                    />
                }
            </div>
        );
    }
}

NotificationDialogContents.propTypes = {
    totalElements: PropTypes.number.isRequired,
    updateDialogActions: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetchNotificationCount: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    ignoreNotificationTypes: PropTypes.array,
    notificationTypesIn: PropTypes.array,
    mapWorkflowNameToTitle: PropTypes.object,
    history: PropTypes.object,
    handleClose: PropTypes.func,
};

NotificationDialogContents.defaultProps = {
    ignoreNotificationTypes: [],
    notificationTypesIn: [],
    mapWorkflowNameToTitle: {},
    history: null,
    handleClose: null,
};

const mapStateToProps = state => ({
    notifications: getStringFromObject('notifications.list.content', state),
    totalElements: getStringFromObject('notifications.list.totalElements', state),
    mapWorkflowNameToTitle: getStringFromObject('mapWorkflowNameToTitle', state.eformConfig),
});

export default connect(mapStateToProps)(NotificationDialogContents);
