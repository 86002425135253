import { getStringFromObject, setStringPropertyToObject } from './lodashUtils';
import { NumberOf } from './numberUtils';

export const LOCAL_STORAGE_KEY = 'redux:auth';
export const LANGUAGE = 'language';
export const APP_VERSION = 'AppVersion';
export const PRIVILEGES = 'privileges';
export const JSONToken = 'JSONToken';
export const REGISTRATION_SALE_SHOP = 'reg.sale.shop';
export const NURSE_SHOP = 'nurse.shop';
export const ROUNDING_PLACES = 'rounding.places';
export const BILL_ROUND_PLACE = 'bill.rounding.places';
export const ANALYTICS_CURRENCY_NUMERIC_SYSTEM = 'analytics.currency.numeric.system';
export const QUEUE = 'insurance.queue';
export const REGISTRATION_SERVICE_LOCATION = 'registration.service.location';

export const ACCOUNTS_FAVORITES = 'favourite.accounts';
export const ORDERS_OF_ORDER_TYPE_FILTER = 'ORDERS_OF_ORDER_TYPE_FILTER';

export const REPORTS_V2_ENABLED = 'reports_v2_enabled';

export const HIDE_USER_MESSAGE = 'hide_user_message';


export const initialAuthState = {
    isLoggedIn: false,
    login: null,
};

const getCookieValue = (name) => {
    const appVersionCookie = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    if (!appVersionCookie) {
        return '';
    }
    return appVersionCookie.pop() || '';
};

export const persistState = (state) => {
    console.log('setting state', state);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

export const persistRoundingPlaces = (roundingPlaces) => {
    localStorage.setItem(ROUNDING_PLACES, roundingPlaces);
};

export const getRoundingPlaces = () => NumberOf(localStorage.getItem(ROUNDING_PLACES)) || 2;

export const dynamicProperties = {
    ROUNDING_METHODOLOGY: 'rounding.methodology',
};
export const roundingMethodologies = {
    BIGDECIMAL: 'BIGDECIMAL',
    MATHROUND: 'MATHROUND',
};
export const persistRoundingMethodology = (roundingMethodology) => {
    localStorage.setItem(dynamicProperties.ROUNDING_METHODOLOGY, roundingMethodology);
};

export const getRoundingMethodology = () => (
    localStorage.getItem(dynamicProperties.ROUNDING_METHODOLOGY) || roundingMethodologies.BIGDECIMAL
);

export const persistBillRoundPlaces = (roundingPlaces) => {
    localStorage.setItem(BILL_ROUND_PLACE, roundingPlaces);
};

export const getBillRoundingPlaces = () => NumberOf(localStorage.getItem(BILL_ROUND_PLACE)) || 0;

export const persistAnalyticsCurrencyNumericSystem = (roundingPlaces) => {
    localStorage.setItem(ANALYTICS_CURRENCY_NUMERIC_SYSTEM, roundingPlaces);
};

export const getAnalyticsCurrencyNumericSystem = () => localStorage.getItem(ANALYTICS_CURRENCY_NUMERIC_SYSTEM) || 'en-US';

export const persistLanguage = (languageKey) => {
    localStorage.setItem(LANGUAGE, languageKey);
};

export const getAppVersion = () => getCookieValue(APP_VERSION);

export const getLanguage = () => localStorage.getItem(LANGUAGE);

export const getState = () => {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    let state;
    if (storedState) {
        state = JSON.parse(storedState);
    } else {
        state = initialAuthState;
    }
    return state;
};


export const getLoggedInName = () => {
    const state = getState();
    if (state && state.login && state.login && state.login.name) {
        return state.login.name;
    }
    return '';
};

export const getLoggedInUserName = () => {
    const state = getState();
    return JSON.parse(getStringFromObject('login.user', state, ''));
};


export const getLoggedInUserUuid = () => {
    const state = getState();
    console.log('asd-ajoda;lsdas', state);
    return getStringFromObject('login.uuid', state, '');
};
export const getLoggedInUserTitle = () => {
    const state = getState();
    return getStringFromObject('login.title', state, '');
};

export const getLoggedInUserDepartment = () => {
    const state = getState();
    return getStringFromObject('login.providerDepartment.value', state, '');
};


export const getLoggedInProviderUuid = () => {
    const state = getState();
    return getStringFromObject('login.providerUuid', state, '');
};

export const getLoggedInLocation = () => {
    const state = getState();
    console.log('21sdsadsada', state);
    if (state && state.login && state.login && state.login.location && state.login.location.value) {
        return state.login.location.value;
    }
    return '';
};


export const persistPrivileges = (privileges) => {
    localStorage.setItem(PRIVILEGES, privileges);
};

export const getPrivileges = () => localStorage.getItem(PRIVILEGES);

export const getSessionId = () => {
    const state = getState();
    if (state && state.login && state.login.jsontoken) {
        return state.login.jsontoken;
    }
    return null;
};

export const persistRegistrationShop = (shopId) => {
    localStorage.setItem(REGISTRATION_SALE_SHOP, JSON.stringify(shopId));
};

export const getRegistrationShop = () => {
    const shop = localStorage.getItem(REGISTRATION_SALE_SHOP);
    if (shop) {
        try {
            return JSON.parse(shop);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const persistNurseShop = (shopId) => {
    localStorage.setItem(NURSE_SHOP, JSON.stringify(shopId));
};

export const getNurseShop = () => {
    const shop = localStorage.getItem(NURSE_SHOP);
    if (shop) {
        try {
            return JSON.parse(shop);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const persistQueue = (queue) => {
    localStorage.setItem(QUEUE, JSON.stringify(queue));
};

export const getSelectedQueue = () => {
    const reqQueue = localStorage.getItem(QUEUE);
    if (reqQueue) {
        try {
            return JSON.parse(reqQueue);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const persistServiceLocation = (serviceLocation) => {
    localStorage.setItem(REGISTRATION_SERVICE_LOCATION, JSON.stringify(serviceLocation));
};

export const getServiceLocation = () => {
    const regServiceLoc = localStorage.getItem(REGISTRATION_SERVICE_LOCATION);
    if (regServiceLoc) {
        try {
            return JSON.parse(regServiceLoc);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const getOrdersOfOrderTypeFilter = () => {
    const ordersOfOrderTypeFilter = localStorage.getItem(ORDERS_OF_ORDER_TYPE_FILTER);
    if (ordersOfOrderTypeFilter) {
        try {
            const parsedJson = JSON.parse(ordersOfOrderTypeFilter);
            return getStringFromObject(getLoggedInUserUuid(), parsedJson) || null;
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const persistFavs = (favs) => {
    localStorage.setItem(ACCOUNTS_FAVORITES, favs);
};

export const getFavs = () => localStorage.getItem(ACCOUNTS_FAVORITES);

export const persistOrdersOfOrderTypeFilter = (filter) => {
    const ordersOfOrderTypeFilter = localStorage.getItem(ORDERS_OF_ORDER_TYPE_FILTER);
    const defaultObj = {
        [getLoggedInUserUuid()]: filter,
    };
    if (ordersOfOrderTypeFilter) {
        try {
            const parsedJson = JSON.parse(ordersOfOrderTypeFilter);
            setStringPropertyToObject(getLoggedInUserUuid(), parsedJson, filter);
            localStorage.setItem(ORDERS_OF_ORDER_TYPE_FILTER, JSON.stringify(parsedJson));
        } catch (e) {
            localStorage.setItem(ORDERS_OF_ORDER_TYPE_FILTER, JSON.stringify(defaultObj));
        }
    } else {
        localStorage.setItem(ORDERS_OF_ORDER_TYPE_FILTER, JSON.stringify(defaultObj));
    }
};


export const persistReportsV2Enabled = (state) => {
    localStorage.setItem(REPORTS_V2_ENABLED, state);
};

export const getReportsV2Enabled = () => localStorage.getItem(REPORTS_V2_ENABLED);


export const persistHideUserMessage = () => {
    localStorage.setItem(HIDE_USER_MESSAGE, true);
};

export const getHideUserMessage = () => localStorage.getItem(HIDE_USER_MESSAGE);
