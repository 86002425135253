export const WORKFLOW_SAVE_REQUEST = '@@workflow/WORKFLOW_SAVE_REQUEST';
export const WORKFLOW_SAVE_FAILURE = '@@workflow/WORKFLOW_SAVE_FAILURE';
export const WORKFLOW_SAVE_SUCCESS = '@@workflow/WORKFLOW_SAVE_SUCCESS';

export const WORKFLOW_FETCH_REQUEST = '@@workflow/WORKFLOW_FETCH_REQUEST';
export const WORKFLOW_FETCH_FAILURE = '@@workflow/WORKFLOW_FETCH_FAILURE';
export const WORKFLOW_FETCH_SUCCESS = '@@workflow/WORKFLOW_FETCH_SUCCESS';

export const RESET_WORKFLOW_STATE = '@@workflow/RESET_WORKFLOW_STATE';

export const workflowSaveRequest = (api, payload, currentState) => ({
    type: WORKFLOW_SAVE_REQUEST,
    api,
    payload,
    currentState,
});

export const workflowFetchRequest = (api, uuid, inboxType) => ({
    type: WORKFLOW_FETCH_REQUEST,
    api,
    uuid,
    inboxType,
});

export const resetWorkflowState = () => ({
    type: RESET_WORKFLOW_STATE,
});
