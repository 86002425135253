import React from 'react';
import cloneDeep from 'clone-deep';
import uuidv4 from 'uuid/v4';
import Grid from '@material-ui/core/Grid';
import { fetchAttendanceRequest } from '../../../redux/modules/attendance/attendance-actions';
import {
    getIndexFromFieldName,
    getInnerIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidNumber,
    isValidTextAndNotEmpty,
} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
import {
    diffInDateInMMs,
    formatDateForDisplay,
    formatDateTimeForDisplay,
    formatTimeForDisplay,
    getDateObjectFromTimeString,
    getTimezoneOffsetAdjustedValue,
    parseDate,
    weekDaysFullNameFromSun
} from '../../../constants/DateUtil';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { applicationDateFormat } from '../../../constants/constants';

export const ATTENDANCE_DEFAULT_SORT_COL = 'approvedAttendance.id';

export const fetchAttendanceListFromServer = (dispatch, page, size, sortCol, sortOrder, filters, successCallback) => {
    dispatch(
        fetchAttendanceRequest(page, size, sortCol, sortOrder, filters, successCallback));
};

export const getStatus = (status) => {
    switch (status) {
        case 'P': return 'Present';
        case 'A': return 'Absent';
        case 'OT': return 'Overtime';
        case 'L': return 'Leave';
        case 'H': return 'Holiday';
        case 'SH': return 'Shortage';
        case 'U': return 'Unparsable';
        default: return status;
    }
};

export const convertIntoTimeFormat = (minutes) => {
    const min = NumberOf(minutes);
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    return `${hours.toString().padStart(2, '0')}:${minFraction.toString().padStart(2, '0')}`;
};

export const convertIntoDate = (minutes) => {
    const min = NumberOf(minutes);
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minFraction);
    return now.getTime();
};

export const getMinutesFromDate = (date) => {
    if (NumberOf(date)) {
        const d = new Date(date);
        return ((d.getHours() * 60) + d.getMinutes());
    }
    return 0;
};

export const APPROVED_ATTENDANCE_LIST_FILTERS = [
    {
        name: 'employee',
        label: 'Employee',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?ignoreRetired=true&name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'manager',
        label: 'Reporting Manager',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'department',
        label: 'Department',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.DEPARTMENT.AUTOSUGGEST_WITH_ALL,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const getSecondsFromTimeString = (time) => {
    if (time) {
        const split = time.split(':');
        return (NumberOf(split[0]) * 3600) + (NumberOf(split[1]) * 60) + NumberOf(split[2]);
    }
    return 0;
};

const getSecondsFromEpochTime = (time) => {
    if (time) {
        const date = new Date(time);
        return (date.getHours() * 3600) + (date.getMinutes() * 60) + date.getSeconds();
    }
    return 0;
};

const displayTimeDiff = (seconds) => {
    const min = Math.abs(NumberOf(seconds) / 60);
    if (min <= 0) {
        return '';
    }
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    if (hours > 0) {
        return `${hours.toString()} h ${minFraction.toString()} m`;
    }
    return `${minFraction.toString()} m`;
};

const getDifference = (startTime, endTime, highlightPositive = true) => {
    const diff = getSecondsFromEpochTime(endTime) -
        getSecondsFromEpochTime(getDateObjectFromTimeString(startTime));
    if (diff > 0 && highlightPositive) {
        return <span style={{ color: 'red' }}>+{displayTimeDiff(diff)}</span>;
    } else if (diff < 0 && !highlightPositive) {
        return <span style={{ color: 'red' }}>-{displayTimeDiff(diff)}</span>;
    }
    return diff === 0 ? '' : diff;
};

export const getPunchActions = (template, punches) => {
    let actions = {};
    if (isObjectValidAndNotEmpty(template)) {
        const startTime = getStringFromObject('startTime', template);
        const endTime = getStringFromObject('endTime', template);
        const breakTime = getStringFromObject('breakTime', template);
        const resumeTime = getStringFromObject('resumeTime', template);
        if (startTime) {
            actions = {
                startTime: formatTimeForDisplay(getDateObjectFromTimeString(startTime)),
            };
        }
        if (resumeTime) {
            actions = {
                ...actions,
                resumeTime: formatTimeForDisplay(getDateObjectFromTimeString(resumeTime)),
            };
        }
        if (endTime) {
            actions = {
                ...actions,
                endTime: formatTimeForDisplay(getDateObjectFromTimeString(endTime)),
            };
        }
        if (breakTime) {
            actions = {
                ...actions,
                breakTime: formatTimeForDisplay(getDateObjectFromTimeString(breakTime)),
            };
        }
        if (isArrayValidAndNotEmpty(punches)) {
            const punch = getStringFromObject('[0]', punches);
            const pSignIn = NumberOf(getStringFromObject('signIn', punch));
            const pBreak = NumberOf(getStringFromObject('breakTime', punch));
            const pResume = NumberOf(getStringFromObject('resumeTime', punch));
            const pSignOut = NumberOf(getStringFromObject('signOut', punch));
            if (startTime && pSignIn) {
                actions = {
                    ...actions,
                    startPunchDiff: getDifference(
                        startTime, pSignIn),
                };
            }
            if (resumeTime && pResume) {
                actions = {
                    ...actions,
                    resumePunchDiff: getDifference(
                        resumeTime, pResume),
                };
            }
            if (breakTime && pBreak) {
                actions = {
                    ...actions,
                    breakPunchDiff: getDifference(
                        breakTime, pBreak, false),
                };
            }
            if (endTime && pSignOut) {
                actions = {
                    ...actions,
                    endPunchDiff: getDifference(
                        endTime, pSignOut, false),
                };
            }
        }
    }
    return actions;
};

export const getLogsTable = (log) => {
    if (isArrayValidAndNotEmpty(log)) {
        return log.map((anAttendanceLog, index) => (
            <Grid container justify="space-between" key={anAttendanceLog.uuid}>
                <Grid item lg={5} sm={5} md={5}>
                    {index + 1}. {anAttendanceLog.action}
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                    &nbsp;:&nbsp;
                </Grid>
                <Grid item lg={6} sm={6} md={6}>
                    {
                        anAttendanceLog.name ? (
                            formatDateTimeForDisplay(new Date(anAttendanceLog.name))
                        ) : (
                            'Time not recorded'
                        )
                    }
                </Grid>
            </Grid>
        ));
    }
    return (
        <Grid container justify="center">
            <Grid item>
                No logs recorded.
            </Grid>
        </Grid>
    );
};

export const getPunchesLines = (attendanceLines) => {
    const lines = [];
    attendanceLines.forEach((aLine, index) => {
        console.log('linessss', { aLine });
        const { attendancePunchs } = aLine;
        if (isArrayValidAndNotEmpty(attendancePunchs)) {
            attendancePunchs.forEach((aPunch) => {
                const newPunch = {
                    ...aLine,
                    ...aPunch,
                    action: {
                        key: aPunch.action,
                        value: `${aPunch.action}`.replace('_', ' '),
                    },
                    day: {
                        key: `DAY_${index + 1}`,
                        value: `DAY ${index + 1}`,
                    },
                };
                if (aPunch.name) {
                    lines.push(newPunch);
                }
            });
        }
    });
    return lines;
};


// export const actions = [
//     { label: 'SIGN_IN', value: 'SIGN_IN' },
//     { label: 'SIGN_OUT', value: 'SIGN_OUT' },
// ];
export const actions = [
    'SIGN_IN',
    'SIGN_OUT',
];

export const days = (index) => {
    const daysList = [];
    if (index > 1) {
        daysList.push(
            { key: `${index - 1}`, value: `DAY ${index - 1}` },
        );
    }
    daysList.push(
        { key: `${index}`, value: `DAY ${index}` },
    );
    if (index < 31) {
        daysList.push(

            { key: `${index + 1}`, value: `DAY ${index + 1}` },

        );
    }
    return daysList;
};

export const sortPunches = (attendanceLine) => {
    console.log('sortedPunches', { attendanceLine });
    const { attendancePunchs } = attendanceLine;
    let newPunches = [];
    // let signIns = attendancePunchs.filter(obj => obj.action === 'SIGN_IN');
    // let signOuts = attendancePunchs.filter(obj => obj.action === 'SIGN_OUT');
    // signIns = signIns.sort((a, b) => a.name - b.name);
    // signOuts = signOuts.sort((a, b) => a.name - b.name);

    // const length = signIns.length > signOuts.length ? signIns.length : signOuts.length;
    // for (let i = 0; i < length; i += 1) {
    //     if (isObjectValidAndNotEmpty(signIns[i])) {
    //         newPunches.push(signIns[i]);
    //     } else {
    //         newPunches.push({
    //             dayNumber: `${(NumberOf(new Date(attendanceLine.date).getDate()))}`,
    //             day: `${weekDaysFullNameFromSun[(NumberOf(new Date(attendanceLine.date).getDay()))]}`,
    //             action: 'SIGN_IN',
    //             hrEmployee: attendanceLine.hrEmployee,
    //         });
    //     }
    //     if (isObjectValidAndNotEmpty(signOuts[i])) {
    //         newPunches.push(signOuts[i]);
    //     } else {
    //         newPunches.push({
    //             dayNumber: `${(NumberOf(new Date(attendanceLine.date).getDate()))}`,
    //             day: `${weekDaysFullNameFromSun[(NumberOf(new Date(attendanceLine.date).getDay()))]}`,
    //             action: 'SIGN_OUT',
    //             hrEmployee: attendanceLine.hrEmployee,
    //         });
    //     }
    // }
    newPunches = attendancePunchs.sort((a, b) => a.name - b.name);
    newPunches = newPunches.map(aPunch => ({
        ...aPunch,
        dayNumber: `${(NumberOf(new Date(attendanceLine.date).getDate()))}`,
        // day: {
        // key: `DAY_${(NumberOf(new Date(aPunch.name).getDate()))}`,
        // value: `DAY_${(NumberOf(new Date(aPunch.name).getDate()))}`,
        // },
    }));
    console.log('dataForSorting', {
        newPunches,
    });
    return newPunches;
};

export const filterNullPunches = (attendanceLines) => {
    console.log('filterNullPunchesfilterNullPunches', attendanceLines);
    const payload = [];
    attendanceLines.forEach((aLine) => {
        const punches = aLine.attendancePunchs.filter(obj => isValidNumber(obj.name));
        payload.push({
            ...aLine,
            attendancePunchs: punches,
        });
    });
    return payload;
};

export const getWorkHours = (attendanceLine) => {
    const { attendancePunchs } = attendanceLine;
    let totalHours = 0;
    if (isArrayValidAndNotEmpty(attendancePunchs)) {
        for (let index = 0; index < attendancePunchs.length / 2; index += 1) {
            const signIn = getStringFromObject('name', attendancePunchs[index * 2]);
            const signOut = getStringFromObject('name', attendancePunchs[(index * 2) + 1]);
            if (isValidNumber(signIn) && isValidNumber(signOut)) {
                const diff = signOut - signIn;
                totalHours += diff;
                console.log('getWorkHours',
                    {
                        attendancePunchs,
                        totalHours,
                        signIn,
                        signOut,
                        diff,
                        diffInHrs: new Date(diff),
                        difffnd: (diffInDateInMMs(new Date(signIn), new Date(signOut))),
                    });
            }
        }
    }
    return (NumberOf(totalHours));
};

export const getWorkHourFields = (attendanceLine) => {
    const workedHours = getWorkHours(attendanceLine);
    let overtime = 0;
    let shortage = 0;
    let status = 'PRESENT';


    const weeklyWorkHours = {};
    const date = new Date(getStringFromObject('date', attendanceLine) || 0);
    const dutyHoursInM = NumberOf(getStringFromObject('dutyHours', attendanceLine));
    const day = date.getUTCDay();
    //
    //
    //
    // const requiredWorkHours = NumberOf(workHoursTemplate[workedHoursDayList[day]]) * 60 * 60 * 1000;
    const requiredWorkHours = NumberOf(dutyHoursInM) * 60 * 1000;
    const difference = requiredWorkHours - workedHours;
    const difmms = diffInDateInMMs(new Date(workedHours), new Date(requiredWorkHours));
    if (dutyHoursInM === 0) {
        status = 'OFF';
    }
    console.log('attendanceLineattendanceLine', { attendanceLine, assigned: attendanceLine });
    const slack = 20 * 60 * 1000;
    const absDiff = Math.abs(difference);
    if (absDiff > slack) {
        if (difference > 0) {
            shortage = (((difference)));
            status = 'SHORTAGE_HOURS';
        } else if (difference < 0) {
            overtime = ((difference));
            status = 'OVERTIME';
        }
    }
    console.log('dtata', {
        difmms,
        difmmsTD: new Date(difmms),
        day,
        requiredWorkHours,
        difference,
        difDa: (new Date(difference)),
        attendanceLine,
        weeklyWorkHours,
        workedHoursString: workedHours,
        overtimeHours: overtime,
        shortageHours: shortage,
        dutyHoursInM,
    });
    return {
        workedHoursString: getTimezoneOffsetAdjustedValue(workedHours),
        overtimeHours: getTimezoneOffsetAdjustedValue(Math.abs(overtime)),
        shortageHours: getTimezoneOffsetAdjustedValue(Math.abs(shortage)),
        status,
    };
};


export const checkLineErrors = (attendanceLine) => {
    console.log('checkForError', { attendanceLine });
    const errors = {};
    const { attendancePunchs } = attendanceLine;
    let prevPunch = {};
    const signIns = attendancePunchs.filter(obj => obj.action === 'SIGN_IN') || [];
    const signOuts = attendancePunchs.filter(obj => obj.action === 'SIGN_OUT') || [];
    if (signIns.length !== signOuts.length) {
        setStringPropertyToObject('parity', errors, 'no of Sign Ins should be equal to Sign Outs');
    }
    attendancePunchs.forEach((aPunch, index) => {
        if (isObjectValidAndNotEmpty(prevPunch) && isObjectValidAndNotEmpty(aPunch)) {
            const { action, name } = aPunch;
            const { action: prevAction, name: prevName } = prevPunch;

            // Same action cannot be consecutive
            if (action === prevAction) {
                setStringPropertyToObject(`attendancePunchs[${index}].action`, errors, `The same action cannot be repeated consecutively (${action})`);
            }
            // If actions are different and a valid punch is present
            if (isValidTextAndNotEmpty(action) && action !== prevAction && isValidNumber(prevName) && isValidNumber(name)) {
                // Sign out must follow sign in, not the other way
                if (prevAction === 'SIGN_IN' && name < prevName) {
                    setStringPropertyToObject(`attendancePunchs[${index}].name`, errors, `Invalid ${action}. Sign Out is before Sign In`);
                } else
                    // Sign in shouldn't overlap the previous sign out
                    if (prevAction === 'SIGN_OUT' && name < prevName) {
                        setStringPropertyToObject(`attendancePunchs[${index}].name`, errors, `Invalid ${action}. Sign In is overlapping Sign out`);
                    }
            }
        }
        prevPunch = aPunch;
    });

    // Validation v2
    const punchesCount = attendancePunchs.length;
    for (let index = 0; index < punchesCount; index += 2) {
        const signIn = attendancePunchs[index];
        const signInaction = getStringFromObject('action', signIn);
        // validate sign in punch
        if (signIn.name) {
            // first punch must be sign in
            if (signInaction !== 'SIGN_IN') {
                setStringPropertyToObject(`attendancePunchs[${index}].name`, errors, `Invalid ${signInaction}. Expected a SIGN IN`);
            }
        }
        // validate sign out punch
        if (punchesCount > index + 1) {
            const signOut = attendancePunchs[index + 1];
            const signOutAction = getStringFromObject('action', signOut);
            if (signOut.name) {

                // second punch must be sign out

                if (signOutAction !== 'SIGN_OUT') {
                    setStringPropertyToObject(`attendancePunchs[${index + 1}].name`, errors, `Invalid ${signInaction}. Expected a SIGN OUT`);
                }
            } else if (signIn.name) {
                setStringPropertyToObject(`attendancePunchs[${index}].name`, errors, 'Punch Missing. Sign out for this punch is missing');
            }
            if (!signIn.name && signOut.name) {
                setStringPropertyToObject(`attendancePunchs[${index + 1}].name`, errors, 'Punch Missing. Sign In for this punch is missing');
            }
        }
    }
    console.log('checkForErrorFinal', errors, attendancePunchs);

    return errors;
};


export const setLine = (rowValue, fieldPath, form) => {
    const { setFieldValue, setFieldError } = form;
    const errors = checkLineErrors(rowValue);
    if (isObjectValidAndNotEmpty(errors)) {
        setFieldError(fieldPath, errors);
    }
    console.log('errorrrs', { errors, form });
    setFieldValue(fieldPath, rowValue);
};

export const onActionChange = (value, fieldPath, form) => {
    console.log('ActionChangeHandler', { value, fieldPath, form });
    const { values } = form;
    const index = getIndexFromFieldName(fieldPath);
    setStringPropertyToObject(fieldPath, values, value);
    const rowValue = cloneDeep(getStringFromObject(`attendanceLines.${index}`, values, {}));

    setLine(rowValue, `attendanceLines.${index}`, form);
};

export const onDayChange = (value, fieldPath, form) => {
    const { values, setFieldValue } = form;
    const index = getIndexFromFieldName(fieldPath);
    const attendanceLines = getStringFromObject('attendanceLines', values) || [];
    const workHoursTemplate = cloneDeep(getStringFromObject('values.workHoursTemplate', form, {}));
    const initialValue = getStringFromObject(fieldPath, values);
    let rowValue = cloneDeep(getStringFromObject(`values.attendanceLines.${index}`, form, {}));
    const newDayNumber = NumberOf(value);
    if (NumberOf(initialValue) === newDayNumber) {
        return;
    }
    // find the index of new line
    const indexToMove = attendanceLines.findIndex((aLine => aLine.dayNumber === newDayNumber));
    const punches = cloneDeep(getStringFromObject('attendancePunchs', rowValue));
    const indexOfPunch = getInnerIndexFromFieldName(fieldPath, 2);
    const punchToMove = { ...punches[indexOfPunch], dayNumber: value };
    if (indexOfPunch !== null) {
        punches.splice(indexOfPunch, 1);
        rowValue.attendancePunchs = punches;
        rowValue = { ...rowValue, ...getWorkHourFields(rowValue, workHoursTemplate) };
        setLine(rowValue, `attendanceLines.${index}`, form);
        setStringPropertyToObject(`[${index}]`, attendanceLines, rowValue);
        // line  exists for the the new date
        if (indexToMove > -1) {
            // remove the punch from old line
            let newLine = cloneDeep(getStringFromObject(`values.attendanceLines.${indexToMove}`, form, {}));
            const newPunches = cloneDeep(getStringFromObject('attendancePunchs', newLine));
            if (index > indexToMove) {
                newPunches.push(punchToMove);
            } else {
                newPunches.unshift(punchToMove);
            }
            newLine.attendancePunchs = newPunches;
            // newLine = { ...newLine, attendancePunchs: sortPunches(newLine) };
            newLine = { ...newLine, ...getWorkHourFields(newLine, workHoursTemplate) };
            setLine(newLine, `attendanceLines.${indexToMove}`, form);

            // update the old line
        } else {
            // line doesn't exist for the the new date
            // if line is not present for the punch, find the next day and the new line to the left
            // if indexToRight is zero, all the lines are after the punch entered (unshift => insert at front)
            // if indexToRight is -1, all the lines are before the punch entered (push => insert at end)
            const indexToRight = attendanceLines.findIndex((aLine => newDayNumber < NumberOf(aLine.dayNumber)));
            const currentLine = attendanceLines[index];
            const currentDate = new Date(currentLine.date);
            const date = parseDate(`${value}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`, applicationDateFormat);
            const newDay = `${formatDateForDisplay(date)} (${weekDaysFullNameFromSun[date.getDay()]})`;
            let newLine = {
                ...currentLine,
                dayNumber: NumberOf(value),
                dateString: newDay,
                uiUuid: uuidv4(),
                date: date.getTime(),
            };
            newLine.attendancePunchs = [
                {
                    ...punchToMove,
                    dateString: newDay,
                    dayNumber: NumberOf(value),
                    uiUuid: uuidv4(),
                },
            ];
            newLine = { ...newLine, ...getWorkHourFields(newLine, workHoursTemplate) };
            console.log('newDateToAdd', { newLine });
            // delete uuid to create new line
            delete newLine.uuid;
            delete newLine.dutyHours;
            delete newLine.shortageHours;
            delete newLine.overtimeHours;
            delete newLine.workedHours;
            delete newLine.workedHoursString;
            delete newLine.statusDisplay;
            console.log('dataFound', indexToRight);
            if (indexToRight > 0) {
                attendanceLines.splice(indexToRight - 1, 0, newLine);
            } else {
                console.log('foundItem', { newLine });
                attendanceLines.unshift(newLine);
            }
            console.log('foundItem', { newLine });
            rowValue = newLine;
            setFieldValue('attendanceLines', attendanceLines);
        }
    }
    console.log('DayChangeHandler',
        {
            value,
            fieldPath,
            values,
            setFieldValue,
            initialValue,
        });
};


export const onPunchChange = (value, fieldPath, form) => {
    const index = getIndexFromFieldName(fieldPath);
    const { values } = form;
    console.log('punchDataChanged', {
        value,
        index,
        fieldPath,
        values: form.values,
        type: (typeof value),
        date: (new Date(value)).getTime(),
    });
    if (index != null) {
        const indexOfPunch = getInnerIndexFromFieldName(fieldPath, 2);

        let rowValue = cloneDeep(getStringFromObject(`values.attendanceLines.${index}`, form, {}));
        const workHoursTemplate = cloneDeep(getStringFromObject('values.workHoursTemplate', form, {}));

        const punches = getStringFromObject('attendancePunchs', rowValue);


        // check if the punch was already present
        const punchesPath = fieldPath.replace('.name', '');
        console.log('punchData', { punchesPath, puch: getStringFromObject(punchesPath, values) });
        let punchToUpdate = getStringFromObject(punchesPath, values);
        // if it's a new punch initialize with necessary fields
        if (!isObjectValidAndNotEmpty(punchToUpdate)) {
            punchToUpdate = {
                dayNumber: `${(NumberOf(new Date(rowValue.date).getDate()))}`,
                day: `${weekDaysFullNameFromSun[(NumberOf(new Date(rowValue.date).getDay()))]}`,
                action: indexOfPunch % 2 === 0 ? 'SIGN_IN' : 'SIGN_OUT',
            };
        }
        punchToUpdate.name = (new Date(value)).getTime();
        // if (isArrayValidAndNotEmpty(punches)) {
        console.log('punchDataChangednew', {
            indexOfPunch, punchToUpdate, punches, rowValue,
        });
        if (indexOfPunch !== null) {
            setStringPropertyToObject(`[${indexOfPunch}]`, punches, punchToUpdate);
        }
        rowValue = { ...rowValue, ...getWorkHourFields(rowValue, workHoursTemplate) };
        rowValue.attendancePunchs = punches;
        // }
        setLine(rowValue, `attendanceLines.${index}`, form);
    }
};


export const attendanceActionHandler = {
    onPunchChange,
};

export const punchActionHandler = {
    onPunchChange,
    onActionChange,
    onDayChange,
};
