/**
 * Created by sachinprakash on 21/6/19.
 */
import { PAGE, SIZE } from '../../../constants/constants';
import API from '../../../constants/api';

export const FETCH_PRODUCTS_REQUEST = '@@Products/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = '@@Products/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = '@@Products/FETCH_PRODUCTS_FAILURE';

export const ADD_PRODUCT_SERVICES_REQUEST = '@@Products/ADD_PRODUCT_SERVICES_REQUEST';
export const ADD_PRODUCT_SERVICES_FAILURE = '@@Products/ADD_PRODUCT_SERVICES_FAILURE';
export const ADD_PRODUCT_SERVICES_SUCCESS = '@@Products/ADD_PRODUCT_SERVICES_SUCCESS';

export const ADD_ORDERABLE_SERVICES_REQUEST = '@@Products/ADD_ORDERABLE_SERVICES_REQUEST';
export const ADD_ORDERABLE_SERVICES_SUCCESS = '@@Products/ADD_ORDERABLE_SERVICES_SUCCESS';
export const ADD_ORDERABLE_SERVICES_FAILURE = '@@Products/ADD_ORDERABLE_SERVICES_FAILURE';

export const fetchProductsList = (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true }) => ({
    type: FETCH_PRODUCTS_REQUEST,
    api: API.SERVICE_SETTING.GET_PRODUCTS_LIST,
    page,
    size,
    sortOrder,
    sortCol,
    filters,
});

export const addBillableProduct = (payload, successCallback) => ({
    type: ADD_PRODUCT_SERVICES_REQUEST,
    api: API.SERVICE_SETTING.GET_PRODUCTS_LIST,
    payload,
    successCallback,
});

export const addOrderableProduct = (payload, successCallback) => ({
    type: ADD_ORDERABLE_SERVICES_REQUEST,
    api: API.PRODUCT.ORDERABLE_SERVICES,
    payload,
    successCallback,
});
