import { takeLatest } from 'redux-saga/effects';
import { fetchAccountVoucherWorker, registerPaymentWorker } from './paymentWorkerSaga';
import { FETCH_ACCOUNT_VOUCHER_REQUEST, REGISTER_PAYMENT_REQUEST } from '../../redux/modules/payment/payment-actions';

export function* registerPaymentRequestWatcher() {
    yield takeLatest(REGISTER_PAYMENT_REQUEST, registerPaymentWorker);
}

export function* fetchAccountWatcherRequestWatcher() {
    yield takeLatest(FETCH_ACCOUNT_VOUCHER_REQUEST, fetchAccountVoucherWorker);
}
