import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import ReduxFormTextField from '../FormFieldComponents/ReduxFormTextField/ReduxFormTextField';

class OtherCollection extends React.Component {
    render() {
        const {
            fields,
            disabled,
        } = this.props;
        return fields.map((member, index) => (
            <Grid container className="mt-1" key={member} justify="center">
                <Field
                    component={ReduxFormTextField}
                    name={`${member}.amount`}
                    fullWidth={false}
                    label={fields.get(index).label}
                    type="number"
                    disabled={disabled}
                />
            </Grid>
        ));
    }
}

OtherCollection.propTypes = {
    fields: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

OtherCollection.defaultProps = {
    disabled: true,
};

export default OtherCollection;

