import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { isEmpty } from '../../../constants/FormValidations';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


const styles = theme => ({
    iconDisable: {
        visibility: 'hidden',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        width: '11em',
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
    },
    actionButton: {
        backgroundColor: getStringFromObject('palette.nuacarePrimaryColor', theme, ''),
        color: 'white',
        '&:hover': {
            backgroundColor: getStringFromObject('palette.nuacareHoverColor', theme, ''),
        },
    },
    badge: {
        height: '1em',
        width: '1em',
    },
});

class MenuActionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            buttonAnchor: null,
        };
    }

    onChange = (event, index) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
        const {
            input,
            handleSelectAction,
            choices,
            dataSourceConfig,
        } = this.props;
        console.log('afnjksadfkasdlf', this.props);
        const selectedChoice = choices[index];
        if (input && isValidFunction(input.onChange)) {
            input.onChange(selectedChoice[dataSourceConfig.value]);
        }
        if (isValidFunction(handleSelectAction)) {
            handleSelectAction(selectedChoice[dataSourceConfig.value], this.state.buttonAnchor);
        }
        const {
            meta: {
                dispatch,
                form,
            } = {}, onChangeHandlers, fieldMember, fields, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(selectedChoice[dataSourceConfig.value], form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    handleButtonClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget, buttonAnchor: event.currentTarget });
    };

    handleCloseMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: null });
    };

    shouldDisplayBadge = (category) => {
        const conditions = getStringFromObject('showBadgeIf', category);
        if (isObjectValidAndNotEmpty(conditions)) {
            const { rowValue } = this.props;
            const existCondition = getStringFromObject('exists', conditions);
            console.log('njkafhakjdfa value', existCondition, rowValue, getStringFromObject(existCondition, rowValue), !isEmpty(getStringFromObject(existCondition, rowValue)));
            if (existCondition && !isEmpty(getStringFromObject(existCondition, rowValue))) {
                return true;
            }
        }
        return false;
    };

    isToBeHide = (category) => {
        if (category.hideIf) {
            const { rowValue } = this.props;
            const { hideIf } = category;
            // console.log('rowValueHideIf', hideIf, rowValue);
            if (hideIf.isNull && (!rowValue[hideIf.key])) {
                return true;
            }
            if (hideIf.isNotNull && (rowValue[hideIf.key])) {
                return true;
            }
            if (hideIf.isEmptyArray && (rowValue[hideIf.key])) {
                return !isArrayValidAndNotEmpty(rowValue[hideIf.key]);
            }
            if (isArrayValidAndNotEmpty(hideIf)) {
                console.log('asd-0aod-as0da[', hideIf);
                for (let i = 0; i < hideIf.length; i += 1) {
                    const hideIfVal = getStringFromObject(hideIf[i].key, rowValue) === hideIf[i].value;
                    console.log('asd-0aod-as0da[', getStringFromObject(hideIf[i].key, rowValue), hideIf[i].value, hideIfVal);
                    if (hideIfVal) {
                        return true;
                    }
                }
                return false;
            }
            // value could be one of many
            if (isArrayValidAndNotEmpty(hideIf.value) && hideIf.value.includes(rowValue[hideIf.key])) {
                return true;
            }
            if (rowValue[hideIf.key] === hideIf.value) {
                console.log('rowValueHideIf', hideIf, rowValue);
                return true;
            }
        }
        return false;
    };

    isToBeShown = (category) => {
        console.log('asda-dais-d0a', category.showOnlyIf);
        let showIt = true;
        if (isObjectValidAndNotEmpty(category.showOnlyIf)) {
            showIt = this.isToBeHide({ ...category, hideIf: category.showOnlyIf });
        }
        if (showIt && isArrayValidAndNotEmpty(category.privilege)) {
            showIt = checkIfPrivilegeExistsForUser(category.privilege);
        }
        return showIt;
    };


    render() {
        const { anchorEl } = this.state;
        const {
            label,
            choices,
            dataSourceConfig,
            testId,
            classes,
            disabled,
        } = this.props;
        console.log('Classes', choices);
        return (
            <React.Fragment>
                <Button
                    test-id={testId}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    disabled={disabled}
                    onClick={this.handleButtonClick}
                    className={classes.actionButton}
                >
                    {label}
                    <ArrowDropDown />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                >
                    {
                        choices.map((c, index) => {
                            if (
                                !this.isToBeHide(c) &&
                                this.isToBeShown(c)
                            ) {
                                return (
                                    <MenuItem
                                        onClick={e => this.onChange(e, index)}
                                        test-id={c.label}
                                        value={c[dataSourceConfig.value]}
                                        key={`${c[dataSourceConfig.value]}-${c[dataSourceConfig.text]}`}
                                    >
                                        <Badge
                                            invisible={!this.shouldDisplayBadge(c)}
                                            variant="dot"
                                            color="secondary"
                                            classes={{
                                                badge: classes.badge,
                                            }}
                                            badgeContent=""
                                        >
                                            {c[dataSourceConfig.text]}
                                        </Badge>
                                    </MenuItem>
                                );
                            }
                            return null;
                        })
                    }
                </Menu>
            </React.Fragment>
        );
    }
}

MenuActionButton.propTypes = {
    meta: PropTypes.object,
    testId: PropTypes.string,
    input: PropTypes.object,
    choices: PropTypes.array.isRequired,
    dataSourceConfig: PropTypes.object,
    label: PropTypes.any,
    classes: PropTypes.object.isRequired,
    handleSelectAction: PropTypes.func, // normal table
    onChangeHandlers: PropTypes.array, // handlers passes through json in redux form material table
    fieldMember: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
    rowValue: PropTypes.object,
    disabled: PropTypes.bool,
};

MenuActionButton.defaultProps = {
    testId: '',
    meta: {},
    input: {},
    label: null,
    dataSourceConfig: {},
    handleSelectAction: () => {},
    onChangeHandlers: [],
    fieldMember: '',
    fields: {},
    actionHandlers: {},
    rowValue: {},
    disabled: false,
};

export default connect()(withStyles(styles)(MenuActionButton));
