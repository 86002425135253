import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { labelRequired } from '../../../constants/CommonUtil';
import FormikReactSelectMaterial from './FormikReactSelectMaterial';

const styles = () => ({
    disabled: {
        color: 'black',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        width: '11em',
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
        color: 'black',
    },
    reactSelectTextField: {
        fontSize: '14px',
    },
    multiline: {
        padding: 0,
    },
    input: {
        '&::placeholder': {
            fontSize: '14px',
        },
    },
});

class SideLabelReactSelect extends React.PureComponent {
    render() {
        const {
            label,
            classes,
            isMandatory,
            ...otherProps
        } = this.props;
        console.log('sdfsdfsdfasdfasdfsdf', otherProps);
        return (
            <div className={classes.parent}>
                {
                    label &&
                        <aside className={classes.labelDiv}>
                            <div style={otherProps.labelstyle} >
                                {label}{ isMandatory && labelRequired()}
                            </div>
                        </aside>
                }
                <div className={classes.fieldDiv}>
                    <FormikReactSelectMaterial
                        {...otherProps}
                        noLabel
                        selectProps={{
                            textFieldProps: {
                                InputProps: {
                                    classes: {
                                        root: classes.reactSelectTextField,
                                        multiline: classes.multiline,
                                        input: classes.input,
                                    },
                                },
                            },
                            singleValueStyle: {
                                fontSize: '14px',
                            },
                        }}
                    />
                </div>
            </div>
        );
    }
}

SideLabelReactSelect.propTypes = {
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    isMandatory: PropTypes.bool,
};

SideLabelReactSelect.defaultProps = {
    label: '',
    isMandatory: false,
};

export default withStyles(styles)(SideLabelReactSelect);

