import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import { isValidFunction } from '../../../constants/CommonUtil';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    iconDisable: {
        visibility: 'hidden',
    },
});

class ReduxFormMenuButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => this.setState({ anchorEl: event.currentTarget });

    handleMenuItemClick = (event) => {
        const { handleSelectAction } = this.props;
        if (isValidFunction(handleSelectAction)) {
            handleSelectAction(event.taget.value);
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {
            classes,
            label,
            choices,
            dataSourceConfig,
        } = this.props;
        const { anchorEl } = this.state;
        console.log('adfsadasdadf', this.props, classes, choices);
        return (
            <React.Fragment>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    {label}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {
                        choices.map(c => (
                            <MenuItem
                                key={isObjectValidAndNotEmpty(dataSourceConfig) ? c[dataSourceConfig.value] : c}
                                onClick={this.handleMenuItemClick}
                            >
                                {isObjectValidAndNotEmpty(dataSourceConfig) ? c[dataSourceConfig.text] : c}
                            </MenuItem>
                        ))
                    }
                </Menu>
            </React.Fragment>
        );
    }
}

ReduxFormMenuButton.propTypes = {
    choices: PropTypes.array,
    dataSourceConfig: PropTypes.object,
    label: PropTypes.any,
    classes: PropTypes.object.isRequired,
    handleSelectAction: PropTypes.func,
};

ReduxFormMenuButton.defaultProps = {
    choices: [],
    dataSourceConfig: {},
    label: '',
    handleSelectAction: () => {},
};

export default withStyles(styles)(ReduxFormMenuButton);

