import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getEndOfDay, getStartOfDay } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

/**
 * Created by vinay on 30/3/19.
 */

const generateSearchConstruct = (filters) => {
    let searchConstruct = null;
    let searchText = getStringFromObject('searchText', filters) || '';
    if (searchText && searchText.trim()) {
        searchText = searchText.trim();
        let operation = ':';
        if (searchText.endsWith('@')) {
            searchText = searchText.substr(0, searchText.length - 1);
            operation = '=';
        }
        searchConstruct = generateObjectWithCriteriaMode('account_move.name', searchText, operation);
    }
    const journalUuid = getStringFromObject('journal.id', filters);
    if (journalUuid) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_journal.id', journalUuid, '='),
        );
    }
    const startDate = getStringFromObject('startDate', filters);
    const endDate = getStringFromObject('endDate', filters);
    if (startDate && endDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode('account_move.date', getStartOfDay(startDate).getTime(), '>='),
                'AND',
                generateObjectWithCriteriaMode('account_move.date', getEndOfDay(endDate).getTime(), '<='),
            ),
        );
    } else if (startDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_move.date', getStartOfDay(startDate).getTime(), '>='),
        );
    } else if (endDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_move.date', getEndOfDay(endDate).getTime(), '<='),
        );
    }
    const includeDraftEntries = getStringFromObject('draftEntries', filters, false);
    if (!includeDraftEntries) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_move.state', 'posted', '='),
        );
    }
    const memo = getStringFromObject('memo.value', filters);
    if (memo) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('account_move.narration', memo, ':'),
        );
    }
    if (!isObjectValidAndNotEmpty(searchConstruct)) {
        return null;
    }
    return searchConstruct;
};

export default generateSearchConstruct;
