/**
 * Created by sachinprakash on 15/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { ADD_OR_UPDATE_SAMPLE_REQUEST, FETCH_SAMPLE_REQUEST } from '../../redux/modules/LabSettings/labTest-actions';
import { addOrUpdateSampleWorker, fetchPagesOfSampleWorker } from './labSampleWorkerSaga';

export function* fetchPagesOfSampleWatcher() {
    yield takeLatest(FETCH_SAMPLE_REQUEST, fetchPagesOfSampleWorker);
}

export function* addOrUpdateSampleWatcher() {
    yield takeLatest(ADD_OR_UPDATE_SAMPLE_REQUEST, addOrUpdateSampleWorker);
}
