/*
 * Created by vinay on 29/4/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import MaskInput from 'react-text-mask';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

import styles, { MASKS } from './MaskedInputUtil';
import { isValidFunction } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

class MaskedInput extends React.PureComponent {
    onChangeHandler = (event) => {
        const { onChangeHandler, field, input } = this.props;
        if (isValidFunction(onChangeHandler)) {
            onChangeHandler(event);
        }
        const formikChangeHandler = getStringFromObject('onChange', field);
        if (isValidFunction(formikChangeHandler)) {
            formikChangeHandler(event);
        }
        const reduxChangeHandler = getStringFromObject('onChange', input);
        if (isValidFunction(reduxChangeHandler)) {
            reduxChangeHandler(event.target.value);
        }
    };

    onBlurHandler = (event) => {
        const { onBlurHandler, field, input } = this.props;
        if (isValidFunction(onBlurHandler)) {
            onBlurHandler(event);
        }
        const formikBlurHandler = getStringFromObject('onBlur', field);
        if (isValidFunction(formikBlurHandler)) {
            formikBlurHandler(event);
        }
        const reduxBlurHandler = getStringFromObject('onBlur', input);
        if (isValidFunction(reduxBlurHandler)) {
            reduxBlurHandler(event);
        }
    };

    getMaskedInput = ({ inputRef, ...other }) => {
        const { mask } = this.props;
        console.log('afhjkadfasaxxxlafd', mask);
        return (
            <MaskInput
                {...other}
                ref={ref => inputRef(ref ? ref.inputElement : null)}
                mask={MASKS[mask]}
                keepCharPositions
                showMask
            />
        );
    };

    render() {
        const {
            label,
            classes,
            field,
            input,
            value,
            meta,
            form,
            variant,
            ...otherProps
        } = this.props;
        let val = value || '';
        let touched = false;
        let error = '';
        if (isObjectValidAndNotEmpty(field)) {
            val = getStringFromObject('value', field);
            const formikFormName = getStringFromObject('name', form);
            if (formikFormName) {
                touched = getStringFromObject(`touched.${formikFormName}`, form, false);
                error = getStringFromObject(`errors.${formikFormName}`, form, '');
            }
        } else if (isObjectValidAndNotEmpty(input)) {
            val = getStringFromObject('value', input);
            touched = getStringFromObject('touched', meta, false);
            error = getStringFromObject('error', meta, '');
        }
        console.log('valueanjdnaldaaa', val, this.props);
        return (
            <FormControl className={classes.formControl} variant={variant}>
                {
                    label &&
                    <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
                }
                <Input
                    {...otherProps}
                    value=""
                    onChange={this.onChangeHandler}
                    onBlur={this.onBlurHandler}
                    id="formatted-text-mask-input"
                    inputComponent={this.getMaskedInput}
                />
                {
                    touched && error &&
                    <FormHelperText id="masked-input-helper-text">{error}</FormHelperText>
                }
            </FormControl>
        );
    }
}

MaskedInput.propTypes = {
    classes: PropTypes.object.isRequired,
    mask: PropTypes.array.isRequired,
    field: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    form: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    onChangeHandler: PropTypes.func,
    onBlurHandler: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

MaskedInput.defaultProps = {
    field: null,
    input: null,
    meta: null,
    form: null,
    value: '',
    label: '',
    onChangeHandler: null,
    onBlurHandler: null,
    variant: 'outlined',
};

export default withStyles(styles)(MaskedInput);
