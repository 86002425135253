import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import FormikReactSelectMaterial from '../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../Formik/FieldComponents/FormikTextField';
import CreateOrChooseSupplier from '../CreateOrChooseSupplier/CreateOrChooseSupplier';
import { getAddressFromAddressDto } from '../../mapper/PurchaseOrderMapper';
import { getRequiredFieldLabel } from '../../constants/CommonUtil';
import { required } from '../../constants/FormValidations';
import ActionButton from '../ActionButton/ActionButton';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'makeAdvancePaymentForm';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    paper: {
        background: '#fafafa',
    },
});

class MakeAdvancePaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
        };
    }

    handleSupplierChange = (supplier) => {
        const { setFieldValue } = this.props;
        setFieldValue('email', getStringFromObject('email', supplier));
        setFieldValue('mailingAddress', getAddressFromAddressDto(supplier));
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            setFieldValue,
            selectedSupplier,
        } = this.props;
        const {
            total,
        } = this.state;
        const disableSupplier = isObjectValidAndNotEmpty(selectedSupplier);
        console.log('2222222222ddddddddddd', this.props);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>Advance Payment</div>
                                <Close className="cursor-pointer" onClick={handleClose} />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1">
                                <Grid item lg={3} md={4} sm={4} style={{ paddingRight: '1em' }}>
                                    <CreateOrChooseSupplier
                                        required
                                        supplierChangeHandler={this.handleSupplierChange}
                                        setFieldValue={setFieldValue}
                                        validate={required}
                                        isDisabled={disableSupplier}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3}>
                                    <Field
                                        name="bankOrCreditAccount"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'value',
                                        }}
                                        required
                                        label="Bank/Credit account"
                                        autocomplete
                                        dataSourceApi=""
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="mt-2">
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        name="mailingAddress"
                                        component={FormikTextField}
                                        label="Mailing address"
                                        placeholder="Mailing address"
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        name="paymentDate"
                                        component={FormikTextField}
                                        type="date"
                                        label={getRequiredFieldLabel('Payment date')}
                                        validate={required}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid container item lg={8} justify="flex-end">
                                    <div style={{ width: '10em' }}>
                                        <Field
                                            name="refNo"
                                            component={FormikTextField}
                                            label="Ref no"
                                            type="number"
                                            fullWidth
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                <Grid container className="mt-2" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            name="memo"
                                            component={FormikTextField}
                                            label="Memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid container item lg={9} justify="flex-end">
                                        <div>
                                            <Field
                                                name="amount"
                                                component={FormikTextField}
                                                label={getRequiredFieldLabel('Amount')}
                                                type="number"
                                                validate={required}
                                                fullWidth
                                            />
                                            <div className={classNames('mt-1', classes.totalField)}>
                                                Total:&nbsp;&nbsp;{total}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                {/* <Grid container className="mt-2">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <div style={{ color: '#0000008a' }}>Attachments</div>
                                        <div className={classes.fileUpload}>
                                            <Field
                                                name="attachments"
                                                component={UploadFile}
                                            />
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                primary={false}
                                disableRipple
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                className="ml-1"
                                disableRipple
                                onClick={handleClose}
                            >
                                Save and New
                            </ActionButton>
                        </DialogActions>
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

MakeAdvancePaymentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    selectedSupplier: PropTypes.object,
    // dispatch: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    /* formik props */
    setFieldValue: PropTypes.func.isRequired,
};

MakeAdvancePaymentDialog.defaultProps = {
    // dispatch: () => {},
    selectedSupplier: {},
};

const handleSubmitForm = () => {};

const initializeForm = props => ({
    supplier: props.selectedSupplier,
    email: getStringFromObject('selectedSupplier.email', props),
    mailingAddress: getAddressFromAddressDto(props.selectedSupplier),
    paymentDate: getDateInYYYYMMDDFormat(new Date()),
});

export default connect()(withFormik({
    mapPropsToValues: initializeForm,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(MakeAdvancePaymentDialog)));
