import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import API from '../../constants/api';
import CreateNewSupplierDialog from '../../components/PurchaseOrderComponents/CreateNewSupplierDialog';
import {
    extractTextFromDoubleQuotes,
    isValidFunction,
} from '../../constants/CommonUtil';
import FormikReactSelectMaterial from '../Formik/FieldComponents/FormikReactSelectMaterial';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class CreateOrChooseSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createNewSupplier: false,
            supplierName: '',
        };
    }

    handleCloseCreateNewSupplier = (createdPartner) => {
        this.setState({
            createNewSupplier: false,
            supplierName: '',
        }, () => {
            const { setFieldValue } = this.props;
            setFieldValue('supplier', createdPartner);
            this.handleChooseSupplier(createdPartner);
        });
    };

    handleChooseSupplier = (supplier) => {
        const { setFieldValue, supplierChangeHandler } = this.props;
        if (isObjectValidAndNotEmpty(supplier)) {
            if (supplier.uuid && (typeof supplier.uuid === 'string') && supplier.uuid.includes('NCD-')) {
                const supplierName = extractTextFromDoubleQuotes(getStringFromObject('name', supplier));
                this.setState({
                    createNewSupplier: true,
                    supplierName,
                }, () => setFieldValue('supplier', null));
            } else if (isValidFunction(supplierChangeHandler)) {
                supplierChangeHandler(supplier);
            }
        }
    };

    render() {
        const {
            ...otherProps
        } = this.props;
        const { createNewSupplier, supplierName } = this.state;
        return (
            <React.Fragment>
                <Field
                    name="supplier"
                    label="Supplier Name"
                    {...otherProps}
                    component={FormikReactSelectMaterial}
                    dataSourceConfig={{
                        text: 'name',
                        value: 'uuid',
                    }}
                    autocomplete
                    creatable
                    dataSourceApi={API.SEARCH.SUPPLIER}
                    onSelectHandlers={['handleChooseSupplier']}
                    actionHandlers={{
                        handleChooseSupplier: this.handleChooseSupplier,
                    }}
                />
                {
                    createNewSupplier &&
                    <CreateNewSupplierDialog
                        open={createNewSupplier}
                        tempName={supplierName}
                        handleClose={this.handleCloseCreateNewSupplier}
                    />
                }
            </React.Fragment>
        );
    }
}

CreateOrChooseSupplier.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    supplierChangeHandler: PropTypes.func,
};

CreateOrChooseSupplier.defaultProps = {
    supplierChangeHandler: null,
};

export default CreateOrChooseSupplier;
