import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import axios from 'axios';

import PropTypes from 'prop-types';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import { PAGE, SIZE } from '../../constants/constants';
import {
    getJsonPath,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,

} from '../../constants/CommonUtil';
import {
    clearPreviousPurchaseInvoiceLines,
    fetchPreviousPurchaseInvoiceLines,
} from '../../redux/modules/previousPurchaseInvoiceLines/previousPurchaseInvoiceLines-actions';
import { addOrSubtractDays } from '../../constants/DateUtil';
import DialogComponent from '../DialogComponent/DialogComponent';
import { COMBINEMODE, getCombined } from '../../sagas/util';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const DEFAULT_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH = 180;

class PreviousPurchaseInvoiceLinesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
        };
    }

    componentDidMount() {
        axios.get(getJsonPath('/PreviousPurchaseInvoiceLines/PreviousPurchaseInvoiceLinesSchema.json'))
            .then((response) => {
                this.setState({
                    schema: response.data,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        this.fetchPreviousPurchaseInvoiceLines(PAGE, SIZE);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearPreviousPurchaseInvoiceLines());
    }

    onPageChange = (page, size) => {
        this.fetchPreviousPurchaseInvoiceLines(page, size);
    };

    getSearchConstruct = () => {
        const {
            dispatch,
            productUuid,
            productId,
            numberOfDaysForPreviousPurchaseInvoiceLineFetch,
        } = this.props;
        const maximumDaysToFetch = NumberOf(numberOfDaysForPreviousPurchaseInvoiceLineFetch) ||
            DEFAULT_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH;
        const createDate = addOrSubtractDays(new Date(), -NumberOf(maximumDaysToFetch));
        const defaultConstruct = {
            type: 'combineMode',
            left: {
                type: 'combineMode',
                left: {
                    type: 'criteria',
                    key: 'account_invoice_line.createDate',
                    value: createDate.getTime(),
                    operation: '>=',
                },
                right: {
                    type: 'criteria',
                    key: 'account_invoice.type',
                    value: 'in_invoice',
                    operation: '=',
                },
                mode: 'AND',
            },
            right: {
                type: 'combineMode',
                left: {
                    type: 'combineMode',
                    left: {
                        type: 'criteria',
                        key: 'account_invoice.state',
                        value: 'draft',
                        operation: '=',
                    },
                    right: {
                        type: 'criteria',
                        key: 'account_invoice.state',
                        value: 'open',
                        operation: '=',
                    },
                    mode: 'OR',
                },
                right: {
                    type: 'criteria',
                    key: 'account_invoice.state',
                    value: 'paid',
                    operation: '=',
                },
                mode: 'OR',
            },
            mode: 'AND',
        };
        let productCriteria = null;
        if (!isStringNullOrUndefined(productUuid)) {
            productCriteria = {
                type: 'criteria',
                key: 'product_product.uuid',
                value: productUuid,
                operation: '=',
            };
        } else if (!isStringNullOrUndefined(productId)) {
            productCriteria = {
                type: 'criteria',
                key: 'product_product.id',
                value: productId,
                operation: '=',
            };
        }
        if (!isObjectValidAndNotEmpty(productCriteria)) {
            dispatch(errorMessage('Product uuid/id is not found'));
            return null;
        }
        return getCombined(defaultConstruct, COMBINEMODE.AND, productCriteria);
    }

    fetchPreviousPurchaseInvoiceLines = (page, size) => {
        const { dispatch } = this.props;
        const searchConstruct = this.getSearchConstruct();
        if (isObjectValidAndNotEmpty(searchConstruct)) {
            dispatch(fetchPreviousPurchaseInvoiceLines(page, size, searchConstruct));
        }
    }

    render() {
        const {
            data,
            page,
            size,
            totalElements,
            onClose,
            productName,
        } = this.props;
        const { schema } = this.state;
        const header = productName ? `Previous Purchases For ${productName}` : 'Previous Purchases';
        return (
            <DialogComponent
                open
                handleClose={onClose}
                maxWidth="md"
                header={header}
            >
                <Grid conatiner >
                    {
                        isObjectValidAndNotEmpty(schema) && (
                            <Grid item sm={12} lg={12} md={12}>
                                {
                                    isArrayValidAndNotEmpty(data) ? (
                                        <MaterialTable
                                            schema={schema}
                                            uniqueKey="invoiceLineUuid"
                                            totalElements={totalElements}
                                            page={page}
                                            size={size}
                                            data={data}
                                            onPageChange={this.onPageChange}
                                        />
                                    ) : (
                                        <div
                                            className="flexChildren justify-content-center"
                                            style={{ fontSize: '1.5rem', fontWeight: 300 }}
                                        >
                                            Previous Purchases not found
                                        </div>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </DialogComponent>
        );
    }
}

PreviousPurchaseInvoiceLinesDialog.propTypes = {
    data: PropTypes.array,
    page: PropTypes.number,
    size: PropTypes.number,
    totalElements: PropTypes.number,
    dispatch: PropTypes.func,
    numberOfDaysForPreviousPurchaseInvoiceLineFetch: PropTypes.number,
    productUuid: PropTypes.string,
    productId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    productName: PropTypes.string,
};

PreviousPurchaseInvoiceLinesDialog.defaultProps = {
    data: [],
    page: PAGE,
    size: SIZE,
    totalElements: 0,
    dispatch: () => {},
    numberOfDaysForPreviousPurchaseInvoiceLineFetch: DEFAULT_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH,
    productUuid: '',
    productId: '',
    productName: '',
};

const mapStateToProps = state => ({
    data: state.previousPurchaseInvoiceLines.list,
    page: state.previousPurchaseInvoiceLines.page,
    size: state.previousPurchaseInvoiceLines.size,
    totalElements: state.previousPurchaseInvoiceLines.totalElements,
    numberOfDaysForPreviousPurchaseInvoiceLineFetch:
            getStringFromObject('appConfiguration.number_of_days_for_previous_purchase_invoice_lines_fetch', state) ||
            DEFAULT_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH,
});

export default connect(mapStateToProps)(PreviousPurchaseInvoiceLinesDialog);
