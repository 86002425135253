import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountsFavorites from './AccountsSettings/AccountsFavorites';
import DialogComponent from '../../components/DialogComponent/DialogComponent';
import { getInitialTabBasedOnUserPrivileges, userSettingsTabs } from './UserSettingsDialogUtil';
import ClinicalFavorites from './ClinicalFavorites/ClinicalFavorites';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import AutoTextListContainer from '../AutoTextListContainer/AutoTextListContainer';
import OnBehalfSettings from './OnBehalfSettings/OnBehalfSettings';
import { isValidFunction } from '../../constants/CommonUtil';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import OtherSettings from './OtherSettings/OtherSettings';

class UserSettingsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: getInitialTabBasedOnUserPrivileges(userSettingsTabs),
            tabChangePreCheckFunc: () => false,
        };
    }

    handleChange = (event, value) => {
        const {
            tabChangePreCheckFunc,
        } = this.state;
        console.log('asd-0ad-0aid0a', tabChangePreCheckFunc);
        const callBack = () => {
            this.setState({
                tabValue: value,
                tabChangePreCheckFunc: () => false,
            });
        };
        this.checkFormDirtiness(callBack);
    };

    checkFormDirtiness = (callBack) => {
        const {
            dispatch,
        } = this.props;
        const {
            tabChangePreCheckFunc,
        } = this.state;
        if (isValidFunction(tabChangePreCheckFunc)) {
            if (tabChangePreCheckFunc()) {
                dispatch(displayWarning(
                    'Any unsaved data will be lost. Do you wish to proceed?',
                    callBack,
                ));
            } else {
                callBack();
            }
        } else {
            callBack();
        }
    };

    handleClose = () => {
        this.checkFormDirtiness(this.props.handleClose);
    };

    updateTabChangePreCheck = (tabChangePreCheckFunc) => {
        console.log('asda-dia-s0di', tabChangePreCheckFunc);
        this.setState({
            tabChangePreCheckFunc,
        });
    };

    render() {
        const {
            tabValue,
        } = this.state;
        const {
            handleClose,
            open,
            dispatch,
        } = this.props;
        return (
            <DialogComponent
                header="Settings"
                open={open}
                maxWidth="lg"
                fullWidth
                handleClose={this.handleClose}
            >
                <Tabs
                    value={tabValue}
                    onChange={this.handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    {
                        Object.keys(userSettingsTabs).map(aUserSettingTab => (
                            checkIfPrivilegeExistsForUser(userSettingsTabs[aUserSettingTab].privileges) &&
                            <Tab
                                test-id={userSettingsTabs[aUserSettingTab].label}
                                key={aUserSettingTab}
                                icon={userSettingsTabs[aUserSettingTab].icon}
                                label={userSettingsTabs[aUserSettingTab].label}
                                value={userSettingsTabs[aUserSettingTab].value}
                            />
                        ))
                    }
                </Tabs>
                {
                    tabValue === userSettingsTabs.CLINICAL_FAVORITES.value &&
                        <ClinicalFavorites
                            handleClose={handleClose}
                            updateTabChangePreCheck={this.updateTabChangePreCheck}
                        />
                }
                {
                    tabValue === userSettingsTabs.TEMPLATES.value &&
                        <ClinicalFavorites
                            handleClose={handleClose}
                            updateTabChangePreCheck={this.updateTabChangePreCheck}
                            isTemplate
                        />
                }
                {
                    tabValue === userSettingsTabs.AUTO_TEXT.value &&
                        <AutoTextListContainer
                            isCategory={false}
                            isProvider
                        />
                }
                {
                    tabValue === userSettingsTabs.ON_BEHALF.value &&
                        <OnBehalfSettings
                            dispatch={dispatch}
                        />
                }
                {
                    tabValue === userSettingsTabs.OTHER_SETTINGS.value &&
                        <OtherSettings />
                }
                {
                    tabValue === userSettingsTabs.ACCOUNTS.value &&
                        <AccountsFavorites dispatch={dispatch} />
                }
            </DialogComponent>
        );
    }
}

UserSettingsDialog.propTypes = {
    handleClose: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

UserSettingsDialog.defaultProps = {
    handleClose: () => {},
    open: false,
};

export default UserSettingsDialog;

