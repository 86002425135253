import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { connect } from 'react-redux';
import ActionButton from '../ActionButton/ActionButton';
import DialogComponent from '../DialogComponent/DialogComponent';
import OutlinedTextField from '../OutlinedTextField';
import {
    apiCatchBlockFunction,
    getTextFieldChangedValue,
    roundedValue,
} from '../../constants/CommonUtil';
import { errorMessage } from '../../redux/modules/message/message-actions';
import API from '../../constants/api';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';

const paidLeavesFieldName = 'stageFour.paidLeaves';
const unpaidLeavesFieldName = 'stageFour.unpaidLeaves';

class ApprovalButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openLeaveEntryDialog: false,
        };
    }

    componentDidMount() {
        this.fetchLeaveBalance(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const employeeUuid = getStringFromObject('stageOne.empUuid', this.props.formValues);
        const leaveTypeUuid = getStringFromObject('stageOne.employeeLeaveType.key', this.props.formValues);
        const toDate = getStringFromObject('stageOne.toDate', this.props.formValues);

        const newEmployeeUuid = getStringFromObject('stageOne.empUuid', nextProps.formValues);
        const newLeaveTypeUuid = getStringFromObject('stageOne.employeeLeaveType.key', nextProps.formValues);
        const newToDate = getStringFromObject('stageOne.toDate', nextProps.formValues);
        console.log('asd0a9udasd09asd', newEmployeeUuid, newLeaveTypeUuid, newToDate);
        if (
            newEmployeeUuid !== employeeUuid ||
            newLeaveTypeUuid !== leaveTypeUuid ||
            toDate !== newToDate
        ) {
            this.fetchLeaveBalance(nextProps);
        }
    }

    onTextFieldValueChange = fieldName => (e) => {
        this.props.change(fieldName, getTextFieldChangedValue(e));
    };

    onSubmit = (e) => {
        const {
            onClick,
            dispatch,
            formValues,
        } = this.props;
        const {
            leaveBalance,
        } = this.state;
        const totalLeaves =
            // from leave request
            NumberOf(getStringFromObject('stageOne.totalLeaves', formValues)) ||
            // from vacation
            NumberOf(getStringFromObject('stageOne.totalDays', formValues));
        const paidLeaves = getStringFromObject(paidLeavesFieldName, formValues);
        const unpaidLeaves = getStringFromObject(unpaidLeavesFieldName, formValues);
        if ((NumberOf(paidLeaves) + NumberOf(unpaidLeaves)) !== totalLeaves) {
            dispatch(errorMessage(`Paid + Unpaid Leaves is not equal to total leaves ${totalLeaves}`));
            return;
        }
        if (NumberOf(paidLeaves) && NumberOf(paidLeaves) > leaveBalance) {
            dispatch(displayWarning(
                'Number of paid leaves are greater than the available balance. Are you sure you want to continue?',
                () => {
                    onClick(e);
                },
            ));
            return;
        }
        onClick(e);
    };
    toggleDialog = () => {
        this.setState(prevState => ({
            openLeaveEntryDialog: !prevState.openLeaveEntryDialog,
        }));
    };

    fetchLeaveBalance = async (props = this.props) => {
        const {
            dispatch,
            formValues,
        } = props;
        const employeeUuid = getStringFromObject('stageOne.empUuid', formValues);
        const leaveTypeUuid = getStringFromObject('stageOne.employeeLeaveType.key', formValues);
        const toDate = getStringFromObject('stageOne.toDate', formValues);
        const isVacation = getStringFromObject('stageOne.applicationFor', formValues) === 'Annual Vacation';
        const leaveBalance = getStringFromObject('stageOne.leaveBalance', formValues);
        console.log('asd09usa09dua0das', formValues, leaveBalance, leaveTypeUuid, toDate, employeeUuid);
        // leaveTypeUuid is not there then it is from vacation
        if (employeeUuid && toDate && (leaveTypeUuid || isVacation)) {
            try {
                const response =
                    await axios.get(`${API.LEAVES.BALANCE_FOR_EMPLOYEE}?employeeUuid=${employeeUuid}&leaveTypeUuid=${leaveTypeUuid}&tillDate=${toDate}&isVacation=${isVacation}`);
                this.setState({
                    leaveBalance: response.data,
                }, this.updateNoOfPaidAndUnpaidLeaves);
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        } else {
            this.setState({
                leaveBalance: 0,
            }, this.updateNoOfPaidAndUnpaidLeaves);
        }
    };

    updateNoOfPaidAndUnpaidLeaves = () => {
        const {
            leaveBalance,
        } = this.state;
        const {
            change,
            formValues,
        } = this.props;
        const totalLeaves =
            // from leave request
            NumberOf(getStringFromObject('stageOne.totalLeaves', formValues)) ||
            // from vacation
            NumberOf(getStringFromObject('stageOne.totalDays', formValues));
        if (leaveBalance) {
            if (totalLeaves < leaveBalance) {
                change(paidLeavesFieldName, totalLeaves);
                change(unpaidLeavesFieldName, 0);
            } else {
                change(paidLeavesFieldName, leaveBalance);
                change(unpaidLeavesFieldName, roundedValue(totalLeaves - leaveBalance));
            }
        } else {
            change(unpaidLeavesFieldName, totalLeaves);
        }
    };

    render() {
        const {
            openLeaveEntryDialog,
            leaveBalance,
        } = this.state;
        const {
            label,
            formValues,
            onClick,
            ...otherProps
        } = this.props;
        const paidLeaves = getStringFromObject(paidLeavesFieldName, formValues);
        const unpaidLeaves = getStringFromObject(unpaidLeavesFieldName, formValues);
        const totalLeaves =
            // from leave request
            NumberOf(getStringFromObject('stageOne.totalLeaves', formValues)) ||
            // from vacation
            NumberOf(getStringFromObject('stageOne.totalDays', formValues));
        return (
            <React.Fragment>
                <ActionButton
                    {...otherProps}
                    onClick={this.toggleDialog}
                >
                    {label}
                </ActionButton>
                {
                    openLeaveEntryDialog &&
                    <DialogComponent
                        open
                        disableBackdropClick
                        header="Leave Details"
                        handleClose={this.toggleDialog}
                    >
                        <Grid container spacing={16}>
                            <Grid item sm={3} md={3} lg={3}>
                                <OutlinedTextField
                                    type="number"
                                    value={paidLeaves}
                                    onChange={this.onTextFieldValueChange(paidLeavesFieldName)}
                                    label="Paid Leaves"
                                />
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                                <OutlinedTextField
                                    type="number"
                                    value={unpaidLeaves}
                                    onChange={this.onTextFieldValueChange(unpaidLeavesFieldName)}
                                    label="Unpaid Leaves"
                                />
                            </Grid>
                            <Grid item sm={3} md={3} lg={3} className="flexChildren ml-1">
                                Total Days: {totalLeaves} <br />
                                Leave Balance: {leaveBalance}
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                                <ActionButton onClick={this.onSubmit}>
                                    Approve
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </DialogComponent>
                }
            </React.Fragment>
        );
    }
}
ApprovalButton.propTypes = {
    label: PropTypes.string,
    formValues: PropTypes.object,
    change: PropTypes.func,
    onClick: PropTypes.func,
    dispatch: PropTypes.func,
};

ApprovalButton.defaultProps = {
    label: '',
    formValues: {},
    change: () => {},
    onClick: () => {},
    dispatch: () => {},
};

export default connect()(ApprovalButton);

