import API from '../../../../constants/api';
import { getUrlWithApiParams } from '../../../../constants/CommonUtil';

export const FETCH_CATEGORY_LIST_REQUEST = '@@payors/FETCH_CATEGORY_LIST_REQUEST';
export const FETCH_CATEGORY_LIST_SUCCESS = '@@payors/FETCH_CATEGORY_LIST_SUCCESS';
export const FETCH_CATEGORY_LIST_FAILURE = '@@payors/FETCH_CATEGORY_LIST_FAILURE';

export const CLEAR_SCHEME_STATE = '@@payors/CLEAR_SCHEME_STATE';

export const fetchCategoryList = (payload, page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_CATEGORY_LIST_REQUEST,
    api: getUrlWithApiParams(API.PAYORS.FETCH_CATEGORIES, {
        page,
        size,
        sortCol,
        sortOrder,
    }),
    payload,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const clearSchemeState = () => ({
    type: CLEAR_SCHEME_STATE,
});
