import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    ADD_VISIT_FAILURE,
    ADD_VISIT_SUCCESS,
    FETCH_VISIT_SUCCESS,
    UPDATE_VISIT_SUCCESS,
} from '../../redux/modules/visitDetails/visitDetails-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { SELECT_PATIENT } from '../../redux/modules/selectedPatient/selectedPatient-actions';
import {
    CHANGE_PATIENT_BLOCK_DETAILS,
    FETCH_PATIENT_SUCCESS,
} from '../../redux/modules/patientDetails/patientDetails-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,

} from '../../constants/CommonUtil';
import { FETCH_APPOINTMENT_DETAILS_SUCCESS } from '../../redux/modules/appointmentDetails/appointmentDetails-actions';
import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* addVisitWorkerSaga(action) {
    try {
        console.log('0999iaisjkdlassww', action);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.visit);
        console.log('searching', response.data);
        yield put({
            type: ADD_VISIT_SUCCESS,
            printable: true,
            successResponse: response.data,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: { id: 'visitAddForm.add.success', defaultMessage: 'Visit Registered Successfully' },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.error('error in api call', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: getErrorMessage(e),
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({ type: ADD_VISIT_FAILURE });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* updateVisitWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().put, `${action.api}${action.visitUuid}`, action.visit);
        console.log('searching', response.data);
        yield put({
            type: UPDATE_VISIT_SUCCESS,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: { id: 'visitAddForm.update.success', defaultMessage: 'Visit Updated Successfully' },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'visitAddForm.update.fail', defaultMessage: 'Failed To Update Visit' },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}

export function* fetchVisitWorkerSaga(action) {
    try {
        const {
            fetchPatientDetails,
            patientUuid,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        if (fetchPatientDetails) {
            const response = yield call(retriable().get, `${API.PATIENT.FETCH}${patientUuid}`);
            yield put({
                type: FETCH_PATIENT_SUCCESS,
                patientDetails: response.data,
            });

            const response2 = yield call(retriable().get, `${API.PATIENT.GET_BLOCK_DETAILS}${patientUuid}`);
            yield put({
                type: CHANGE_PATIENT_BLOCK_DETAILS,
                blockDetails: response2.data,
            });
        }
        const apiWithUrlParams =
        // eslint-disable-next-line max-len
            `${action.api}${action.patientUuid}&numberOfPreviousPayments=${action.numberOfPreviousPayments}&appointmentUuid=${action.appointmentUuid}`;
        const response = yield call(retriable().post, apiWithUrlParams, action.displayCols);
        console.log('wwasdadwqeqwe123213213', response.data);
        yield put({
            type: SELECT_PATIENT,
            patient: response.data.patientDetails,
        });
        yield put({
            type: FETCH_PATIENT_SUCCESS,
            patientDetails: getStringFromObject('data.completePatientDetails', response),
        });

        const response2 = yield call(retriable().get, `${API.PATIENT.GET_BLOCK_DETAILS}${patientUuid}`);
        yield put({
            type: CHANGE_PATIENT_BLOCK_DETAILS,
            blockDetails: response2.data,
        });
        yield put({
            type: FETCH_VISIT_SUCCESS,
            visitDetails: {
                ...getStringFromObject('data.lastVisitDetails', response, {}),
                hasOpenVisit: response.data.hasOpenVisit,
                openVisitUuid: response.data.openVisitUuid,
                openVisitType: response.data.openVisitType,
                previousVisitEpisodeAttributes:
                    getStringFromObject('data.previousVisitEpisodeAttributes', response, []),
            },
        });
        // appointment must be checked in from appotinemnt scrren.. and at that time
        // , all the necessary details will be there
        if (false) {
            yield put({
                type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
                appointmentDetails: getStringFromObject('data.appointmentDetails', response),
            });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: { id: 'visitAddForm.fetch.fail', defaultMessage: 'Failed To Fetch Visit Details' },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        console.error('error in api call', e);
        // yield put({ type: SEARCH_FAILURE });
    }
}
export default addVisitWorkerSaga;
