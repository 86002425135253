import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_ACCOUNT_TRANSFER_REQUEST,
    FETCH_ACCOUNT_TRANSFER_REQUEST,
} from '../../redux/modules/accountTransfer/accountTransfer-actions';
import { createAccountTransferWorker, fetchAccountTransferWorker } from './accountTransferWorker';

export function* createAccountTransferWatcher() {
    yield takeLatest(CREATE_ACCOUNT_TRANSFER_REQUEST, createAccountTransferWorker);
}

export function* fetchAccountTransferWatcher() {
    yield takeLatest(FETCH_ACCOUNT_TRANSFER_REQUEST, fetchAccountTransferWorker);
}
