import { FETCH_FAVORITES_FOR_DOCTOR_SUCCESS } from './favoritesForDoctor-actions';

export const favoriteForDoctorReducer = (state = {}, action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case FETCH_FAVORITES_FOR_DOCTOR_SUCCESS:
        newState = action.favorites;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default favoriteForDoctorReducer;
