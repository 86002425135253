import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field, withFormik } from 'formik';
import { Chip, MenuItem, withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import classNames from 'classnames';

import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import { required, upperCaseNormalizer } from '../../../constants/FormValidations';
import API from '../../../constants/api';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import FormikOutlinedSelectField from '../../Formik/FieldComponents/FormikOutlinedSelectField';
import {
    apiCatchBlockFunction,
    formatCurrency,
    getAllowedDefaultAccountTypes, getJsonPath,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    isValidTextAndNotEmpty,
    roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import FormikCheckbox from '../../Formik/FieldComponents/FormikCheckbox';
import { ACTION_HANDLERS } from './CreditOrDebitMemoHandlersV2';
import { APPLICATION_CONFIG_URL, ROWS_PER_PAGE, applicationDateAndTimeFormat } from '../../../constants/constants';
import { getAccountInvoiceFromUiObject, getAccountInvoiceLineDescription, getUiObject } from '../../../mapper/CreditOrDebitMemoMapperV2';
import {
    clearSelectedAccountInvoice,
    createCreditOrDebitMemoRequest,
    fetchAccountInvoiceById,
} from '../../../redux/modules/accountInvoice/accountInvoice-actions';
import ActionButton from '../../ActionButton/ActionButton';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import { getDateInYYYYMMDDFormat, isDateAfter, parseDate } from '../../../constants/DateUtil';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { fetchResPartnerDto } from '../../CashPaymentVoucher/CashPaymentVoucherUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import {
    getIsCreditMemo,
    getIsIssue,
    getPartnerApi,
    getMemoTitle,
    memoFieldNames,
    paymentMeans,
    getAccountDescription,
    getInvoiceTypeByMemoType,
} from './CreditOrDebitMemoUtilsV2';
import OutlinedTextField from '../../OutlinedTextField';
import { subCompanies } from '../../../constants/ERPConstants';
import PrintPDF from '../../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import {
    displayConfirmDialogWithUserInput,
} from '../../../redux/modules/confirmDialogWithUserInput/confirmDialogWithUserInput-actions';
import FormikDateTimePicker from '../../Formik/FieldComponents/FormikDateTimePicker';

const formName = 'creditOrDebitMemo';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    paper: {
        background: '#fafafa',
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    ...dialogComponentStyles(),
});


class CreditOrDebitMemoDialogV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productSchema: null,
            print: false,
            editDate: false,
            resPartnerDto: null,
            invoiceReferenceNumber: null,
            invoicePrintItems: null,
            printInvoice: false,
            isLineDescriptionEdited: false,
        };
    }

    componentDidMount() {
        const { invoiceId } = this.props;
        this.getCreditMemo(invoiceId);

        fetch(getJsonPath('/StockManagement/CreditOrDebitMemoProduct.json'))
            .then(response => response.json())
            .then((schema) => {
                this.setState({
                    productSchema: schema,
                });
            })
            .catch((error) => {
                console.log('Error While Fetching Json Schema', error);
            });
    }

    componentDidUpdate(prevProps) {
        const { isLineDescriptionEdited } = this.state;

        const previousPartnerUuid = getStringFromObject('values.supplier.uuid', prevProps);
        const partnerUuid = getStringFromObject('values.supplier.uuid', this.props);
        const isPartnerUpdated = partnerUuid && partnerUuid !== previousPartnerUuid;
        if (isPartnerUpdated) {
            this.updateResPartnerDto();
        }
        const uuid = getStringFromObject('values.uuid', this.props);
        if (!isLineDescriptionEdited && !uuid) {
            const previousCreditAmount = getStringFromObject('values.creditAmount', prevProps);
            const creditAmount = getStringFromObject('values.creditAmount', this.props);
            const isCreditAmountUpdated = NumberOf(creditAmount) && creditAmount !== previousCreditAmount;
            const previousCashPartnerName = getStringFromObject('values.cashPartnerName', prevProps);
            const cashPartnerName = getStringFromObject('values.cashPartnerName', this.props);
            const isCashPartnerNameUpdated = cashPartnerName && cashPartnerName !== previousCashPartnerName;
            if (isPartnerUpdated || isCreditAmountUpdated || isCashPartnerNameUpdated) {
                this.generateInitialLineDescription();
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountInvoice());
    }

    onChangeDate = async () => {
        const {
            dispatch,
            values,
        } = this.props;
        const dateInvoice = getStringFromObject('dateInvoice', values);
        console.log('as0d9ua0d9au-dasdi0asda', dateInvoice, values);
        const theDate = parseDate(dateInvoice, 'yyyy-MM-dd');
        if (isDateAfter(theDate, new Date())) {
            dispatch(errorMessage('Date cannot be in the future'));
        } else {
            try {
                dispatch(showSpinner());
                await axios.put(`${API.ACCOUNT_INVOICE.CHANGE_CREDIT_MEMO_DATE}${values.uuid}&dateStr=${dateInvoice}`);
                dispatch(hideSpinner());
                dispatch(successMessage('Memo date changed successfully'));
                this.toggleEditDate();
                const { invoiceId } = this.props;
                this.getCreditMemo(invoiceId);
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    getCreditMemo = (invoiceId) => {
        const { dispatch } = this.props;
        if (invoiceId) {
            dispatch(fetchAccountInvoiceById(invoiceId));
        }
    };

    getExtraRows = () => {
        const { values } = this.props;
        const uuid = getStringFromObject('uuid', values);
        const products = getStringFromObject('accountInvoiceLines', values, []);
        let price = 0;
        let untaxed = 0;
        let tax = 0;
        if (isArrayValidAndNotEmpty(products)) {
            products.forEach((j) => {
                price += NumberOf(j.total);
                untaxed += NumberOf(j.subTotal);
                tax += NumberOf(j.totalTax);
            });
        }
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Sub Total</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(untaxed)}</h4>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Tax</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(tax)}</h4>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Total</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(price)}</h4>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    updateResPartnerDto = async () => {
        const { dispatch } = this.props;
        const partner = getStringFromObject('values.supplier', this.props);
        if (
            isObjectValidAndNotEmpty(partner) &&
            !isStringNullOrUndefined(partner.subCompany)
        ) {
            this.setState({
                resPartnerDto: partner,
            });
        } else {
            const resPartnerDto = await fetchResPartnerDto(partner, dispatch);
            if (isObjectValidAndNotEmpty(resPartnerDto)) {
                this.setState({
                    resPartnerDto,
                });
            }
        }
    }

    handleConfirm = state => () => {
        const {
            submitForm,
            setFieldValue,
            values,
            setValues,
            dispatch,
            memoType,
        } = this.props;

        if (values.dateInvoice) {
            const theDate = parseDate(values.dateInvoice, 'yyyy-MM-dd');
            if (isDateAfter(theDate, new Date())) {
                dispatch(errorMessage('Date cannot be in the future'));
                return;
            }
        }
        if (getIsIssue(memoType) && !isArrayValidAndNotEmpty(values.invoiceReferences)) {
            dispatch(errorMessage('Reference invoices are mandatory when issuing a credit or debit memo.'));
            return;
        }

        const confirmCallBack = async () => {
            setFieldValue('callBack', this.getCreditMemo);
            setFieldValue('failureCallBack', () => {
                setValues(values);
            });
            setFieldValue('state', state);
            setFieldValue('memoType', memoType);
            submitForm();
        };

        if (state === 'draft') {
            confirmCallBack();
        } else if (state === 'open') {
            dispatch(displayConfirmDialogWithUserInput({
                message:
                    'This operation is irreversible.\n' +
                    'Once confirmed, the memo will be created and cannot be edited.\n' +
                    'Are you sure you want to proceed?',
                validationText: 'CONFIRM',
                confirmCallBack,
                title: 'Warning',
                cancelLabel: 'Cancel',
                confirmLabel: 'Confirm',
            }));
        }
    };

    handlePrint = () => {
        this.setState(prev => ({
            print: !prev.print,
        }));
    };

    handleDelete = () => {
        const {
            dispatch,
            values,
        } = this.props;
        dispatch(displayWarning(
            'Are you sure you want to delete this memo?',
            async () => {
                try {
                    const uuid = getStringFromObject('uuid', values);
                    dispatch(showSpinner());
                    await axios.delete(`${API.ACCOUNT_INVOICE.DELETE_CREDIT_MEMO}${uuid}`);
                    dispatch(hideSpinner());
                    dispatch(successMessage('Memo deleted successfully'));
                    this.props.handleClose();
                } catch (e) {
                    apiCatchBlockFunction(e, dispatch);
                }
            },
        ));
    };

    stockToReturnCheckboxHandler = (value) => {
        if (!value) {
            this.props.setFieldValue('accountInvoiceLines', []);
        }
    };

    handleCashPurchaseOrSaleCheck = () => {
        const { setFieldValue } = this.props;
        setFieldValue('supplier', null);
        setFieldValue(memoFieldNames.CASH_PARTNER_NAME, '');
        setFieldValue(memoFieldNames.CASH_PARTNER_ID_NUMBER, '');
        setFieldValue(memoFieldNames.CASH_PARTNER_SUB_COMPANY, null);
    }

    toggleEditDate = (_, resetValue) => {
        const {
            creditOrDebitMemo,
        } = this.props;
        if (resetValue) {
            this.props.setFieldValue('dateInvoice', getDateInYYYYMMDDFormat(creditOrDebitMemo.invoiceDateTime));
        }

        this.setState(prevState => ({
            editDate: !prevState.editDate,
        }));
    };

    fetchAndAddInvoiceReferenceByNumber = async (invoiceNumber) => {
        const { dispatch, values, setFieldValue } = this.props;
        const invoiceReferences = getStringFromObject('invoiceReferences', values) || [];
        const isInvoiceNumberAlreadyAdded = invoiceReferences.some(anInvoiceReference => anInvoiceReference.value === invoiceNumber);
        if (isInvoiceNumberAlreadyAdded) {
            dispatch(errorMessage('Invoice reference number is already added.'));
            return;
        }

        try {
            dispatch(showSpinner());
            const response = await axios.get(`${API.ACCOUNT_INVOICE.GET_BY_NUMBER}?invoiceNumber=${invoiceNumber}`);
            const accountInvoice = response.data;
            if (isObjectValidAndNotEmpty(accountInvoice)) {
                if (accountInvoice.isCashPurchaseOrSale) {
                    const isCashPurchaseOrSale = getStringFromObject(memoFieldNames.IS_CASH_PURCHASE_OR_SALE, values);
                    if (!isCashPurchaseOrSale) {
                        dispatch(errorMessage('Reference invoice was issued to a cash partner, not a registered partner.'));
                        dispatch(hideSpinner());
                        return;
                    }

                    const cashPartnerName = getStringFromObject(memoFieldNames.CASH_PARTNER_NAME, values);
                    const cashPartnerNameByInvoice = getStringFromObject('cashPartnerName', accountInvoice);
                    if (
                        isValidTextAndNotEmpty(cashPartnerName) &&
                        isValidTextAndNotEmpty(cashPartnerNameByInvoice) &&
                        cashPartnerName !== cashPartnerNameByInvoice
                    ) {
                        dispatch(errorMessage(`Reference invoice Partner name '${cashPartnerNameByInvoice}' is not the same as the entered Partner name for memo`));
                        dispatch(hideSpinner());
                        return;
                    }


                    const cashPartnerIdNumber = getStringFromObject(memoFieldNames.CASH_PARTNER_ID_NUMBER, values);
                    const cashPartnerIdNumberByInvoice = getStringFromObject('cashPartnerIdNumber', accountInvoice);
                    if (
                        isValidTextAndNotEmpty(cashPartnerIdNumber) &&
                        isValidTextAndNotEmpty(cashPartnerIdNumberByInvoice) &&
                        cashPartnerIdNumber !== cashPartnerIdNumberByInvoice
                    ) {
                        dispatch(errorMessage(`Reference invoice Partner VAT No. '${cashPartnerIdNumberByInvoice}' is not the same as the entered Partner VAT No. for memo`));
                        dispatch(hideSpinner());
                        return;
                    }

                    const cashPartnerSubCompany = getStringFromObject(memoFieldNames.CASH_PARTNER_SUB_COMPANY, values);
                    const cashPartnerSubCompanyByInvoice = getStringFromObject('cashPartnerSubCompany', accountInvoice);
                    if (
                        isValidTextAndNotEmpty(cashPartnerSubCompany) &&
                        isValidTextAndNotEmpty(cashPartnerSubCompanyByInvoice) &&
                        cashPartnerSubCompany !== cashPartnerSubCompanyByInvoice
                    ) {
                        dispatch(errorMessage(`Reference invoice Partner Sub Company '${cashPartnerSubCompanyByInvoice}' is not the same as the selected Partner Sub Company for memo`));
                        dispatch(hideSpinner());
                        return;
                    }
                } else {
                    const isCashPurchaseOrSale = getStringFromObject(memoFieldNames.IS_CASH_PURCHASE_OR_SALE, values);
                    if (isCashPurchaseOrSale) {
                        dispatch(errorMessage('Reference invoice was issued to a registered partner, not a cash partner.'));
                        dispatch(hideSpinner());
                        return;
                    }
                    const partnerUuid = getStringFromObject('supplier.uuid', values);
                    const partnerUuidByInvoice = getStringFromObject('partnerUuid', accountInvoice);
                    if (isValidTextAndNotEmpty(partnerUuid)) {
                        if (partnerUuidByInvoice !== partnerUuid) {
                            dispatch(errorMessage('Reference invoice partner is not the same as the selected partner for memo'));
                            dispatch(hideSpinner());
                            return;
                        }
                    }
                }
                setFieldValue('invoiceReferences',
                    [
                        ...invoiceReferences,
                        { key: accountInvoice.uuid, value: accountInvoice.number },
                    ]);
                this.setState({ invoiceReferenceNumber: '' });
            }
            dispatch(hideSpinner());
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        }
    }


    handleInvoiceReferenceNumberChange = (newInvoiceReferenceNumber) => {
        this.setState({ invoiceReferenceNumber: newInvoiceReferenceNumber });
    };

    handleInvoiceReferenceNumberKeyPress = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            const { invoiceReferenceNumber } = this.state;
            if (invoiceReferenceNumber && isValidTextAndNotEmpty(invoiceReferenceNumber.trim())) {
                this.fetchAndAddInvoiceReferenceByNumber(invoiceReferenceNumber.trim());
            }
        }
    }

    handleRemoveInvoiceReferenceNumber = invoiceReference => () => {
        const { values, setFieldValue } = this.props;
        const invoiceReferences = getStringFromObject('invoiceReferences', values) || [];
        if (isArrayValidAndNotEmpty(invoiceReferences)) {
            const index = invoiceReferences.findIndex(anInvoiceReference => anInvoiceReference.key === invoiceReference.key);
            if (index > -1) {
                invoiceReferences.splice(index, 1);
            }
            setFieldValue('invoiceReferences', invoiceReferences);
        }
    }

    generateInitialLineDescription = () => {
        const { memoType, values, setFieldValue } = this.props;
        const amountTotal = getStringFromObject('creditAmount', values);
        if (NumberOf(amountTotal)) {
            const invoiceType = getInvoiceTypeByMemoType(memoType);
            const isCashPurchaseOrSale = getStringFromObject(memoFieldNames.IS_CASH_PURCHASE_OR_SALE, values);
            let partnerName;
            if (isCashPurchaseOrSale) {
                partnerName = getStringFromObject(memoFieldNames.CASH_PARTNER_NAME, values);
            } else {
                partnerName = getStringFromObject('supplier.name', values);
            }
            if (isValidTextAndNotEmpty(partnerName)) {
                const lineDescription = getAccountInvoiceLineDescription(
                    invoiceType,
                    partnerName,
                    amountTotal,
                );
                setFieldValue('lineDescription', lineDescription);
            }
        }
    }

    handleLineDescriptionChange = () => {
        this.setState({
            isLineDescriptionEdited: true,
        });
    }

    initiateInvoicePrint = async () => {
        const {
            dispatch,
            values,
        } = this.props;
        const invoiceUuid = getStringFromObject('uuid', values) || null;
        if (invoiceUuid) {
            try {
                const response = await axios.get(`${API.ACCOUNT_INVOICE.GET_TAX_INVOICE_PRINT}?invoiceUuid=${invoiceUuid}`);
                const failureReason = getStringFromObject('failureReason', response.data);
                const downloadToken = getStringFromObject('downloadToken', response.data);
                if (failureReason) {
                    dispatch(errorMessage(failureReason));
                    return;
                }
                this.setState(prevState => ({
                    invoicePrintItems: [
                        {
                            url: `${API.DOWNLOAD_FILE.WITH_TOKEN}/${downloadToken}`,
                            name: 'payslipPrint',
                        },
                    ],
                    printInvoice: !prevState.printInvoice,
                }));
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            appConfiguration,
            memoType,
            deleteMemoPrivilege,
        } = this.props;
        const {
            editDate,
            resPartnerDto,
            invoiceReferenceNumber,
            invoicePrintItems,
            printInvoice,
        } = this.state;
        console.log('afjahfkjajfjla', values);
        const subCompany = getStringFromObject('subCompany', resPartnerDto) || 'CLINIC';
        const isCancelled = getStringFromObject('state', values) === 'cancel';
        const view = (getStringFromObject('state', values) === 'open') || isCancelled;
        const stockToReturn = getStringFromObject('stockToReturn', values, false);
        const stockLocation = getStringFromObject('stockLocation.uuid', values);
        const creditAmount = getStringFromObject('creditAmount', values, false);
        const taxAccount = getStringFromObject('taxAccount', values, false);
        const invoiceReferences = getStringFromObject('invoiceReferences', values) || [];
        console.log('discountscottie', taxAccount);
        const { productSchema } = this.state;
        const products = getStringFromObject('accountInvoiceLines', values, []);
        let totalCreditAmount = 0;
        let amountUntaxed = 0;
        let amountTax = 0;
        if (!stockToReturn) {
            amountTax = NumberOf(creditAmount) * NumberOf(getStringFromObject('amount', taxAccount));
            totalCreditAmount = creditAmount !== null ? NumberOf(creditAmount) + NumberOf(amountTax) : 0;
        } else if (isArrayValidAndNotEmpty(products)) {
            products.forEach((j) => {
                totalCreditAmount += NumberOf(j.total);
                amountUntaxed += NumberOf(j.subTotal);
                amountTax += NumberOf(j.totalTax);
            });
        }
        const printData = {
            ...values,
            credit: !stockToReturn,
            total: totalCreditAmount,
            amountUntaxed,
            amountTax,
            companyName: getStringFromObject('companyName', appConfiguration),
        };
        const isCreditMemo = getIsCreditMemo(memoType);
        const isIssue = getIsIssue(memoType);

        const isCashPurchaseOrSale = getStringFromObject(memoFieldNames.IS_CASH_PURCHASE_OR_SALE, values);
        const isInvoiceReferenceAdded = isArrayValidAndNotEmpty(invoiceReferences);
        const userHasDeleteMemoPrivilege = isArrayValidAndNotEmpty(deleteMemoPrivilege) && checkIfPrivilegeExistsForUser(deleteMemoPrivilege);
        const taxApi = isIssue ? API.SEARCH.SALES_TAXES_BASE : API.SEARCH.PURCHASE_TAXES;

        return (
            <Dialog
                open={open}
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>
                            {getMemoTitle(memoType)} {isCancelled ? '[CANCELLED]' : ''}
                        </div>
                        <Close
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={handleClose}
                            test-id="close-credit-debit-stock"
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid container item className="mt-1" spacing={8}>
                            <Grid item lg={3} sm={4} md={3}>
                                <Field
                                    testId="supplier"
                                    name="supplier"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label={isIssue ? 'Customer' : 'Supplier'}
                                    autocomplete
                                    validate={isCashPurchaseOrSale ? undefined : required}
                                    required={!isCashPurchaseOrSale}
                                    isDisabled={view || isInvoiceReferenceAdded || isCashPurchaseOrSale}
                                    dataSourceApi={getPartnerApi(memoType)}
                                />
                            </Grid>
                            <Grid item lg={2} sm={3} md={2}>
                                <Field
                                    name={memoFieldNames.IS_CASH_PURCHASE_OR_SALE}
                                    label="Is Cash Partner"
                                    component={FormikCheckbox}
                                    formControlProps={{
                                        labelPlacement: 'start',
                                    }}
                                    onChangeHandlers={['onChangeHandler']}
                                    actionHandlers={{
                                        onChangeHandler: this.handleCashPurchaseOrSaleCheck,
                                    }}
                                    disabled={view || isInvoiceReferenceAdded}
                                />
                            </Grid>
                            {
                                isCashPurchaseOrSale &&
                                <React.Fragment>
                                    <Grid item lg={2} sm={4} md={3}>
                                        <Field
                                            name={memoFieldNames.CASH_PARTNER_NAME}
                                            label={isIssue ? 'Customer Name' : 'Supplier Name'}
                                            component={FormikTextField}
                                            disabled={view || isInvoiceReferenceAdded}
                                            normalize={upperCaseNormalizer}
                                            validate={required}
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={4} md={3}>
                                        <Field
                                            name={memoFieldNames.CASH_PARTNER_ID_NUMBER}
                                            label="Vat Number"
                                            component={FormikTextField}
                                            disabled={view || isInvoiceReferenceAdded}
                                            validate={isIssue ? required : undefined}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={4} md={3}>
                                        <Field
                                            name={memoFieldNames.CASH_PARTNER_SUB_COMPANY}
                                            label="Sub Company"
                                            component={FormikOutlinedSelectField}
                                            validate={required}
                                            style={{
                                                minWidth: '15em',
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                },
                                            }}
                                            disabled={view || isInvoiceReferenceAdded}
                                            menuItems={Object.values(subCompanies).map(aSubCompany => (
                                                <MenuItem key={aSubCompany} value={aSubCompany} >
                                                    {aSubCompany}
                                                </MenuItem>
                                            ))}
                                        />
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid container item sm={12} spacing={8}>
                                <Grid item lg={3} sm={4} md={3} >
                                    <Field
                                        name="dateInvoice"
                                        component={FormikDateTimePicker}
                                        validate={required}
                                        type="date"
                                        variant="outlined"
                                        label={isCreditMemo ? 'Credit Date' : 'Debit Date'}
                                        required
                                        fullWidth
                                        disabled={view ? !editDate : view}
                                        format={applicationDateAndTimeFormat}
                                    />

                                </Grid>
                                <Grid item className="mr-1">
                                    {
                                        view && !isIssue &&
                                        <React.Fragment>
                                            {
                                                editDate ?
                                                    <div>
                                                        <IconButton onClick={() => { this.toggleEditDate(null, true); }}>
                                                            <Cancel />
                                                        </IconButton>
                                                        <IconButton onClick={this.onChangeDate}>
                                                            <Save />
                                                        </IconButton>
                                                    </div> :
                                                    <IconButton onClick={this.toggleEditDate}>
                                                        <Edit />
                                                    </IconButton>
                                            }
                                        </React.Fragment>
                                    }
                                </Grid>
                                <Grid item lg={2} sm={4} md={3}>
                                    <Field
                                        name={memoFieldNames.PAYMENT_MEANS}
                                        label="Payment Means"
                                        component={FormikOutlinedSelectField}
                                        InputProps={{
                                            classes: {
                                                input: classes.input,
                                            },
                                        }}
                                        disabled={view}
                                        validate={isIssue ? required : undefined}
                                        style={{
                                            minWidth: '15em',
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        menuItems={Object.values(paymentMeans).map(aPaymentMean => (
                                            <MenuItem key={aPaymentMean} value={aPaymentMean} >
                                                {aPaymentMean.replace('_', ' ')}
                                            </MenuItem>
                                        ))}
                                    />
                                </Grid>
                                <Grid item lg={5} sm={3} md={5}>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            Total Amount
                                            <h3>
                                                {getStringFromObject('currency', appConfiguration)}&nbsp;
                                                {formatCurrency(roundedValueFixedToTwoDigits(totalCreditAmount))}
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8} item>
                            <Grid
                                item
                                sm={9}
                                container
                                spacing={8}
                                alignItems="center"
                                className="mb-1"
                            >
                                {
                                    !view && (
                                        <Grid item lg={2} sm={3} md={2}>
                                            <OutlinedTextField
                                                value={invoiceReferenceNumber}
                                                label="Invoice Ref Number"
                                                placeholder="Type invoice number and hit enter"
                                                onKeyPress={this.handleInvoiceReferenceNumberKeyPress}
                                                onChange={this.handleInvoiceReferenceNumberChange}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    isArrayValidAndNotEmpty(invoiceReferences) &&
                                    <React.Fragment>
                                        <Grid item>Reference Numbers:</Grid>
                                        {invoiceReferences.map(aNumber => (
                                            <Grid item key={aNumber.key}>
                                                <Chip
                                                    label={aNumber.value}
                                                    onDelete={view ? undefined : this.handleRemoveInvoiceReferenceNumber(aNumber)}
                                                />
                                            </Grid>
                                        ))}
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={32} item>
                            {
                                !view && !isCreditMemo && !isIssue &&
                                <Grid item lg={2} sm={3} md={2}>
                                    <Field
                                        name="stockToReturn"
                                        label="Stock To Return"
                                        component={FormikCheckbox}
                                        formControlProps={{
                                            labelPlacement: 'start',
                                        }}
                                        onChangeHandlers={['onChangeHandler']}
                                        actionHandlers={{
                                            onChangeHandler: this.stockToReturnCheckboxHandler,
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                !stockToReturn &&
                                <React.Fragment>
                                    <Grid container spacing={32} item lg={12} sm={12} md={12}>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                name="account"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'value',
                                                    value: 'key',
                                                }}
                                                label={isCreditMemo ? 'Debit Account' : 'Credit Account'}
                                                autocomplete
                                                isDisabled={view}
                                                required
                                                validate={required}
                                                dataSourceApi={`${API.SEARCH.ACCOUNTS}?type=${getAllowedDefaultAccountTypes('creditOrDebitMemoAccountTypes', this.props.accountTypes)}&subCompany=${isObjectValidAndNotEmpty(resPartnerDto) ? subCompany : ''}&searchString=`}
                                            />
                                            <div>
                                                { getAccountDescription(memoType) }
                                            </div>
                                        </Grid>
                                        <Grid item lg={2} sm={2} md={2}>
                                            <Field
                                                name="creditAmount"
                                                component={FormikTextField}
                                                validate={required}
                                                variant="outlined"
                                                label="Amount"
                                                required
                                                disabled={view}
                                            />
                                        </Grid>
                                        <Grid item lg={2} sm={2} md={2}>
                                            <Field
                                                name="taxAccount"
                                                component={FormikReactSelectMaterial}
                                                variant="outlined"
                                                label="Tax"
                                                disabled={view}
                                                dataSourceApi={`${taxApi}?size=20&name=`}
                                                dataSourceConfig={{
                                                    text: 'name',
                                                    value: 'id',
                                                }}
                                                testId="taxAccount"
                                                autocomplete
                                                isDisabled={view}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                testId="lineDescription"
                                                name="lineDescription"
                                                component={FormikTextField}
                                                label="Line Description"
                                                multiline
                                                rows={3}
                                                rowsMax={3}
                                                fullWidth
                                                disabled={view}
                                                validate={required}
                                                required
                                                onChangeHandlers={['onLineDescriptionChange']}
                                                actionHandlers={{
                                                    onLineDescriptionChange: this.handleLineDescriptionChange,
                                                }}
                                            />
                                        </Grid>
                                    }
                                    {
                                        view &&
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                name="residualAmount"
                                                component={FormikTextField}
                                                variant="outlined"
                                                label="Residual Amount"
                                                disabled
                                            />
                                        </Grid>
                                    }
                                </React.Fragment>
                            }
                            {
                                stockToReturn && !view &&
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="stock-location"
                                        name="stockLocation"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'id',
                                        }}
                                        label="Stock Location"
                                        autocomplete
                                        required
                                        isDisabled={view}
                                        validate={required}
                                        dataSourceApi={API.SEARCH.INTERNAL_STOCK_LOCATION}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container className="mt-2">
                            {
                                stockToReturn && (stockLocation || view) && isObjectValidAndNotEmpty(productSchema) &&
                                <FormikTable
                                    fieldName="accountInvoiceLines"
                                    enablePagination
                                    showRowNumber
                                    tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                    deleteIcon
                                    getExtraRows={this.getExtraRows}
                                    actionHandlers={ACTION_HANDLERS}
                                    actionParams={{
                                        sourceLoc: values.stockLocation,
                                    }}
                                    rowsPerPageOptions={ROWS_PER_PAGE}
                                    {...productSchema}
                                    isEditable={!view}
                                    formValues={values}
                                />
                            }
                        </Grid>
                        <Grid container className="mt-2">
                            <Grid item lg={3} md={3} sm={3}>
                                <Field
                                    testId="memo"
                                    name="comment"
                                    component={FormikTextField}
                                    label="Memo"
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    fullWidth
                                    disabled={view}
                                    validate={required}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <PrintPDF
                        itemsToPrint={invoicePrintItems}
                        print={printInvoice}
                    />
                    <Print
                        data={printData}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/CreditMemo.html`}
                        print={this.state.print}
                        subCompany={subCompany}
                    />
                </DialogContent>
                {
                    !isCancelled &&
                    <DialogActions>
                        <ActionButton
                            onClick={handleClose}
                            primary={false}
                        >
                            Cancel
                        </ActionButton>
                        {
                            !view &&
                            <ActionButton
                                testId="Save"
                                onClick={this.handleConfirm('draft')}
                                disabled={!(totalCreditAmount > 0)}
                            >
                                SAVE
                            </ActionButton>
                        }
                        {
                            !view && values.uuid &&
                            <ActionButton
                                onClick={this.handleConfirm('open')}
                                disabled={!(totalCreditAmount > 0)}
                            >
                                Confirm
                            </ActionButton>
                        }
                        {
                            view &&
                            <ActionButton
                                disableRipple
                                toolTipTitle={<div>Print</div>}
                                primary
                                onClick={this.handlePrint}
                                disableTool={false}
                            >
                                Print
                            </ActionButton>
                        }
                        {
                            view && values.uuid && isIssue &&
                            <ActionButton
                                disableRipple
                                toolTipTitle={<div>Print Invoice</div>}
                                primary
                                onClick={this.initiateInvoicePrint}
                                disableTool={false}
                            >
                                Print Invoice
                            </ActionButton>
                        }
                        {
                            userHasDeleteMemoPrivilege && (values.uuid) &&
                            <ActionButton
                                disableRipple
                                primary
                                onClick={this.handleDelete}
                                disableTool={false}
                            >
                                Delete
                            </ActionButton>
                        }
                    </DialogActions>
                }
            </Dialog>
        );
    }
}


CreditOrDebitMemoDialogV2.propTypes = {
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    appConfiguration: PropTypes.object,
    values: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    creditOrDebitMemo: PropTypes.object,
    invoiceId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    accountTypes: PropTypes.object,
    memoType: PropTypes.string.isRequired,
    deleteMemoPrivilege: PropTypes.array,
};

CreditOrDebitMemoDialogV2.defaultProps = {
    appConfiguration: {},
    invoiceId: null,
    creditOrDebitMemo: null,
    accountTypes: {},
    deleteMemoPrivilege: [],
};

const handleSubmitForm = (values, { props }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    const callback = getStringFromObject('callBack', values);
    const failureCallBack = getStringFromObject('failureCallBack', values);
    dispatcher(createCreditOrDebitMemoRequest(getAccountInvoiceFromUiObject(values), (r) => {
        callback(getStringFromObject('id', r));
    }, () => {
        failureCallBack();
    }));
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    creditOrDebitMemo: getStringFromObject('accountInvoice.selectedInvoice[0]', state, {}),
    accountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
    deleteMemoPrivilege: getStringFromObject('appConfiguration.deleteMemoPrivilege', state),
});

export default connect(mapStateToProps)((withFormik({
    mapPropsToValues: props => getUiObject(props.creditOrDebitMemo, props.memoType),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(CreditOrDebitMemoDialogV2))));

