import API from '../../../constants/api';

export const FETCH_JOURNAL_ENTRIES_LIST_REQUEST = '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_REQUEST';
export const FETCH_JOURNAL_ENTRIES_LIST_FAILURE = '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_FAILURE';
export const FETCH_JOURNAL_ENTRIES_LIST_SUCCESS = '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_SUCCESS';

export const FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST =
    '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST';
export const FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE =
    '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE';
export const FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS =
    '@@journalEntry/FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS';

export const CREATE_JOURNAL_ENTRY_REQUEST = '@@journalEntry/CREATE_JOURNAL_ENTRY_REQUEST';
export const CREATE_JOURNAL_ENTRY_FAILURE = '@@journalEntry/CREATE_JOURNAL_ENTRY_FAILURE';
export const CREATE_JOURNAL_ENTRY_SUCCESS = '@@journalEntry/CREATE_JOURNAL_ENTRY_SUCCESS';

export const POST_JOURNAL_ENTRY_REQUEST = '@@journalEntry/POST_JOURNAL_ENTRY_REQUEST';
export const POST_JOURNAL_ENTRY_FAILURE = '@@journalEntry/POST_JOURNAL_ENTRY_FAILURE';
export const POST_JOURNAL_ENTRY_SUCCESS = '@@journalEntry/POST_JOURNAL_ENTRY_SUCCESS';

export const CLEAR_JOURNAL_ENTRIES = '@@journalEntry/CLEAR_JOURNAL_ENTRIES';
export const PERSIST_JOURNAL_ENTRIES_FILTER = '@@journalEntry/PERSIST_JOURNAL_ENTRIES_FILTER';

export const fetchJournalEntryListRequest = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_JOURNAL_ENTRIES_LIST_REQUEST,
    api: `${API.ACCOUNT_MOVES.GET_PAGES}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});

export const fetchJournalEntryListOptimizedRequest = (page, size, filters, seekParams, seekDirection) => ({
    type: FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_REQUEST,
    api: API.ACCOUNT_MOVES.GET_SEEKABLE_PAGES,
    page,
    size,
    seekParams,
    seekDirection,
    filters,
});

export const createJournalEntryRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_JOURNAL_ENTRY_REQUEST,
    api: API.JOURNAL_ENTRIES.CREATE,
    payload,
    successCallback,
    failureCallBack,
});

export const postJournalEntryRequest = (payload, successCallback, failureCallBack) => ({
    type: POST_JOURNAL_ENTRY_REQUEST,
    api: `${API.JOURNAL_ENTRIES.POST}${payload}`,
    payload,
    successCallback,
    failureCallBack,
});


export const clearJournalEntries = () => ({
    type: CLEAR_JOURNAL_ENTRIES,
});

export const persistJournalEntriesFilter = filters => ({
    type: PERSIST_JOURNAL_ENTRIES_FILTER,
    filters,
});
