/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';

const Z_INDEX = 999999;
const TOP = 20;

const style = () => ({
    fixedMenu: {
        position: 'fixed',
        top: `${TOP}%`,
        right: 0,
        width: '4.5em',
        transition: 'all .2s ease-in-out',
        transitionProperty: 'all',
        '&:hover': {
            width: '17em',
        },
        zIndex: Z_INDEX
    },
});

class SideMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        };
        this.timer = null;
    }
    onMouseOver = () => {
        this.setState({
            hovered: true,
        });
    };
    onMouseOut = () => {
        this.setState({
            hovered: false,
        });
    };

    render() {
        const { classes, items, indexOfSideMenu } = this.props;
        const { hovered } = this.state;
        let itemsToRender = [];
        if (isArrayValidAndNotEmpty(items)) {
            if (items.length > 1 && !hovered) {
                itemsToRender = (
                    <MenuItem
                        className={classes.menuItem}
                        key={items[0].text}
                        id={items[0].text}
                        onClick={items[0].onClick}
                    >
                        <ListItemIcon className={classes.icon}>
                            {items[0].icon}
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.primary }}
                            inset
                            primary={items[0].text}
                        />
                    </MenuItem>
                );
            } else {
                itemsToRender = items.map(item => (
                    <MenuItem
                        className={classes.menuItem}
                        key={item.text}
                        id={item.text}
                        onClick={item.onClick}
                    >
                        <ListItemIcon className={classes.icon}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.primary }}
                            inset
                            primary={item.text}
                        />
                    </MenuItem>
                ));
            }
        }
        return (
            <div
                className={classes.fixedMenu}
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
                style={
                    (indexOfSideMenu > 1) ?
                    { top: `${TOP + ((indexOfSideMenu - 1) * 15)}%`, zIndex: Z_INDEX - (indexOfSideMenu - 1) } : {}
                }
            >
                <Paper>
                    <MenuList style={{ maxHeight: '30rem', overflow: 'auto' }}>
                        {itemsToRender}
                    </MenuList>
                </Paper>
            </div>
        );
    }
}

SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    indexOfSideMenu: PropTypes.number

};

SideMenu.defaultProps = {
    indexOfSideMenu: 1,
};

export default withStyles(style)(SideMenu);
