/**
 * Created by sachinprakash on 3/6/19.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    ASSET_CATEGORY_ADD_FAILURE,
    ASSET_CATEGORY_ADD_SUCCESS,
    ASSET_CATEGORY_LIST_FETCH_FAILURE,
    ASSET_CATEGORY_LIST_FETCH_SUCCESS,
} from '../../redux/modules/assetCategory/assteCategory-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';


export function* assetCategoryListFetch(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const { page, size, filters } = action;
        const apiWithQueryParam
            =
            `${action.api}?page=${page}&size=${size}&sortCol=name&filter=${encodeURIComponent(JSON.stringify(filters))}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam, config);
        console.log('sdaghksdfgkasdhfgaj', response);
        yield put({
            type: ASSET_CATEGORY_LIST_FETCH_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_CATEGORY_LIST_FETCH_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetCategoryAddorUpdateWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const { payload, api } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const id = getStringFromObject('id', payload, null);
        let response = null;
        console.log('jkdhajdgfakghajkgfas', id, action);
        if (id === null || id === undefined) {
            response = yield call(retriable().post, api, payload, config);
        } else response = yield call(retriable().put, `${api}/${id}`, payload, config);
        yield put({ type: SUCCESS_MESSAGE, message: 'Updated Category Successfully' });
        yield put({
            type: ASSET_CATEGORY_ADD_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_CATEGORY_ADD_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

