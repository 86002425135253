/* eslint-disable max-len */
import API from '../../../constants/api';

export const FETCH_ACCOUNT_VOUCHER_REQUEST = '@@cashPaymentVoucher/FETCH_ACCOUNT_VOUCHER_REQUEST';
export const FETCH_ACCOUNT_VOUCHER_SUCCESS = '@@cashPaymentVoucher/FETCH_ACCOUNT_VOUCHER_SUCCESS';
export const FETCH_ACCOUNT_VOUCHER_FAILURE = '@@cashPaymentVoucher/FETCH_ACCOUNT_VOUCHER_FAILURE';
export const CLEAR_ACCOUNT_VOUCHER = '@@cashPaymentVoucher/CLEAR_ACCOUNT_VOUCHER';

export const fetchAccountVouchers = (page, size, searchConstruct, seekParams, seekDirection) => ({
    type: FETCH_ACCOUNT_VOUCHER_REQUEST,
    api: API.ACCOUNT_VOUCHER.FETCH_SEEKABLE_PAGES,
    page,
    size,
    seekParams,
    seekDirection,
    searchConstruct,
});

export const clearAccountVouchers = () => ({
    type: CLEAR_ACCOUNT_VOUCHER,
});

