/**
 * Created by vinay on 28/6/19.
 */

import API from '../../../constants/api';
import MESSAGES from '../../../constants/messages';

export const FETCH_CASH_CLOSE_REQUEST = '@@cashClose/FETCH_CASH_CLOSE_REQUEST';
export const FETCH_CASH_CLOSE_FAILURE = '@@cashClose/FETCH_CASH_CLOSE_FAILURE';
export const FETCH_CASH_CLOSE_SUCCESS = '@@cashClose/FETCH_CASH_CLOSE_SUCCESS';

export const CREATE_CASH_CLOSE_REQUEST = '@@cashClose/CREATE_CASH_CLOSE_REQUEST';
export const CREATE_CASH_CLOSE_FAILURE = '@@cashClose/CREATE_CASH_CLOSE_FAILURE';
export const CREATE_CASH_CLOSE_SUCCESS = '@@cashClose/CREATE_CASH_CLOSE_SUCCESS';

export const UPDATE_CASH_CLOSE_PRINT_FLAG = '@@cashClose/UPDATE_CASH_CLOSE_PRINT_FLAG';
export const UPDATE_CASH_CLOSE_PRINT_FLAG_SUCCESS = '@@cashClose/UPDATE_CASH_CLOSE_PRINT_FLAG_SUCCESS';
export const UPDATE_CASH_CLOSE_PRINT_FLAG_FAILED = '@@cashClose/UPDATE_CASH_CLOSE_PRINT_FLAG_FAILED';

export const CLEAR_SELECTED_CASH_CLOSE = '@@cashClose/CLEAR_SELECTED_CASH_CLOSE';

export const dispatchUpdateCashClosePrintFlagRequest = (uuid, successCallback, failureCallBack) => ({
    type: UPDATE_CASH_CLOSE_PRINT_FLAG,
    api: `${API.CASH_CLOSE.UPDATE_PRINT_STATUS}/${uuid}`,
    successAction: UPDATE_CASH_CLOSE_PRINT_FLAG_SUCCESS,
    successCallback,
    failureAction: UPDATE_CASH_CLOSE_PRINT_FLAG_FAILED,
    failureMessage: MESSAGES.CASH_CLOSE.UPDATE_PRINT_FLAG_FAILED,
    failureCallBack,
});

export const dispatchCreateCashCloseRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_CASH_CLOSE_REQUEST,
    payload,
    api: API.CASH_CLOSE.CREATE,
    successAction: CREATE_CASH_CLOSE_SUCCESS,
    successMessage: MESSAGES.CASH_CLOSE.CREATE.SUCCESS,
    successCallback,
    failureAction: CREATE_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CASH_CLOSE.CREATE.FAILURE,
    failureCallBack,
});

export const dispatchGetCashCloseWithUuidRequest = uuid => ({
    type: FETCH_CASH_CLOSE_REQUEST,
    api: `${API.CASH_CLOSE.FETCH_WITH_UUID}/${uuid}`,
    successAction: FETCH_CASH_CLOSE_SUCCESS,
    failureAction: FETCH_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CASH_CLOSE.FETCH_FAILED,
});

export const dispatchGetCashCloseWithIdRequest = id => ({
    type: FETCH_CASH_CLOSE_REQUEST,
    api: `${API.CASH_CLOSE.FETCH_WITH_ID}/${id}`,
    successAction: FETCH_CASH_CLOSE_SUCCESS,
    failureAction: FETCH_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CASH_CLOSE.FETCH_FAILED,
});

export const clearSelectCashState = () => ({
    type: CLEAR_SELECTED_CASH_CLOSE,
});
