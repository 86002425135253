import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    CLEAR_LOGIN_STATE,
    LOGIN_FAILURE,
    LOGIN_SILENT_FAILURE,
    LOGIN_SILENT_SUCCESS,
    LOGIN_SUCCESS,
    loginSuccess,
} from '../../redux/modules/login/login-actions';
import {
    initialAuthState, persistState,
} from '../../constants/state';
import { CHANGE_ROUTE_REQUEST } from '../../redux/modules/navigator/navigator-actions';
import { FETCH_CURRENT_USER_PROFILE_REQUEST } from '../../redux/modules/profile/profile-actions';
import API from '../../constants/api';
import {
    clearAllDataAndReload,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import { CLEAR_RETRY_REQUESTS } from '../../redux/modules/retryRequests/retryRequests-actions';
import { clearAllFavoritesOfProvider } from '../../constants/favoritesStorage';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const getRetryRequests = state => state.retryRequestsReducer;
const getFuncToCallAfterLogin = state => getStringFromObject('login.functionToCallAfterLogin', state);

export function* loginSilently(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, action.api, action.loginDetails);
        console.log('loginn success', response, action);
        if (response.data) {
            yield call(loginSuccess, response.data);
            yield call(clearAllFavoritesOfProvider);
            yield put({ type: LOGIN_SILENT_SUCCESS, data: response.result });
            const retryRequests = yield select(getRetryRequests);
            const funcToCall = yield select(getFuncToCallAfterLogin);
            if (isArrayValidAndNotEmpty(action.actionToDispatchAfterLogin)) {
                for (let i = 0; i < action.actionToDispatchAfterLogin.length; i += 1) {
                    if (isObjectValidAndNotEmpty(action.actionToDispatchAfterLogin[i])) {
                        yield put(action.actionToDispatchAfterLogin[i]);
                    }
                }
            }
            if (isArrayValidAndNotEmpty(funcToCall)) {
                for (let i = 0; i < funcToCall.length; i += 1) {
                    if (isValidFunction(funcToCall[i])) {
                        yield call(funcToCall[i]);
                    }
                }
            }
            if (isArrayValidAndNotEmpty(retryRequests)) {
                retryRequests.forEach((aRetryRequest) => {
                    axios(aRetryRequest);
                });
            }
            yield put({ type: CLEAR_RETRY_REQUESTS });
        } else {
            yield put({ type: LOGIN_SILENT_FAILURE });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.log('error in api call', e);
        yield put({ type: LOGIN_SILENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* login(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, action.api, action.loginDetails);
        console.log('loginn success', response, action);
        if (response.data) {
            yield call(loginSuccess, response.data);
            yield call(clearAllFavoritesOfProvider);
            yield put({ type: LOGIN_SUCCESS, data: response.result });
            yield put({ type: FETCH_CURRENT_USER_PROFILE_REQUEST, api: API.PROFILE.FETCH_CURRENT_PROFILE });
        } else {
            yield put({ type: LOGIN_FAILURE });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.log('error in api call');
        yield put({ type: LOGIN_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* validateSessionWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, action.api);
        if (response.data) {
            yield call(loginSuccess, response.data);
            yield put({ type: LOGIN_SUCCESS, data: response.result });
            yield put({ type: CHANGE_ROUTE_REQUEST, route: action.redirectToLink });
            yield put({ type: FETCH_CURRENT_USER_PROFILE_REQUEST, api: API.PROFILE.FETCH_CURRENT_PROFILE });
        } else {
            yield put({ type: LOGIN_FAILURE });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.log('error in api call');
        yield put({ type: CHANGE_ROUTE_REQUEST, route: `/?destination=${action.redirectToLink}` });
        yield put({ type: CLEAR_LOGIN_STATE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* logout(action) {
    console.log('searching', action);
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    persistState(initialAuthState);
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* clearCacheAndLogout() {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    yield call(clearAllDataAndReload);
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
