import { takeLatest } from 'redux-saga/effects';
import { openEncounterFetchRequest, doctorQListRequest, incompleteEncounterFetchRequest } from './doctorQWorkerSaga';
import {
    DOCTOR_Q_LIST_REQUEST, INCOMPLETE_ENCOUNTER_FETCH_REQUEST,
    OPEN_ENCOUNTER_FETCH_REQUEST,
} from '../../redux/modules/doctorQueueList/doctorQueueList-actions';


export function* doctorQListSaga() {
    yield takeLatest(DOCTOR_Q_LIST_REQUEST, doctorQListRequest);
}

export function* openEncounterSaga() {
    yield takeLatest(OPEN_ENCOUNTER_FETCH_REQUEST, openEncounterFetchRequest);
}

export function* incompleteEncounterSaga() {
    yield takeLatest(INCOMPLETE_ENCOUNTER_FETCH_REQUEST, incompleteEncounterFetchRequest);
}
