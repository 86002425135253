/**
 * Created by vinay on 18/12/18.
 */
import memoizeOne from 'memoize-one';
import {
    isArrayValidAndNotEmpty,
    valueToFixedTwoDigits,
} from '../constants/CommonUtil';
import { getBatches } from './InternalMovesMapper';
import { formatDateForDisplay, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getAddressFromAddressDtoMemoized } from '../components/PrintComponents/PurchaseOrderPrintUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const RETURN_GOOD_OPERATION = {
    SAVE: 'draft',
    DONE: 'done',
};
export const RECEIVED_GOODS_OBJECT = {
    partner: null,
    stockPickingLinesDTOSet: [],
    sourceLoc: null,
    createDate: getDateInYYYYMMDDFormat(new Date()),
};

const mapStockPickingLinesFromUiObject = (lines) => {
    const stockPickingLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line) => {
            const quan = getStringFromObject('quantity', line, 0);
            if (Number(quan) > 0) {
                stockPickingLines.push({
                    product: getStringFromObject('product', line, null),
                    batchName: getStringFromObject('batchName', line, null),
                    quantity: quan,
                    productUom: getStringFromObject('productUom', line, null),
                    cost: getStringFromObject('cost', line, 0),
                    salePrice: getStringFromObject('salePrice', line, 0),
                    mrp: getStringFromObject('mrp', line, 0),
                    uuid: getStringFromObject('uuid', line, null),
                    expiryDate: getStringFromObject('expiryDate', line, null),
                    stockProdLot: getStringFromObject('stockProdLot', line, null),
                });
            }
        });
    }
    return stockPickingLines;
};

export const mapUiObjectToDto = (uiObject) => {
    const payload = {};
    console.log('asdja-0sd9s-a', uiObject);
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = uiObject.status;
        payload.origin = uiObject.reference;
        payload.purchaseOrder = uiObject.purchaseOrder;
        payload.operation = 'RETURN_STOCK';
        payload.partner = {
            key: getStringFromObject('partner.uuid', uiObject, null),
            value: getStringFromObject('partner.name', uiObject, null),
        };
        payload.sourceLoc = {
            key: getStringFromObject('sourceLoc.uuid', uiObject, null),
            value: getStringFromObject('sourceLoc.name', uiObject, ''),
        };
        payload.stockPickingLinesDTOSet = mapStockPickingLinesFromUiObject(uiObject.stockPickingLinesDTOSet);
    }
    return payload;
};

export const getNonInvoicedPOUiObject = async (pickings, purchaseOrderUuid) => {
    const productMap = {};
    let stockLocation = null;
    let partner = null;
    if (isArrayValidAndNotEmpty(pickings)) {
        // get partner
        partner = getStringFromObject('[0].partner', pickings);
        pickings.forEach((p) => {
            // only confirmed stock pickings
            if (p.status === 'done') {
                const pLines = getStringFromObject('stockPickingLinesDTOSet', p, []);
                if (isArrayValidAndNotEmpty(pLines)) {
                    pLines.forEach((pline) => {
                        let pickingsForProduct = productMap[pline.product.key];
                        if (!isObjectValidAndNotEmpty(pickingsForProduct)) {
                            pickingsForProduct = {
                                name: pline.name,
                                cost: pline.cost,
                                salePrice: pline.salePrice,
                                mrp: pline.mrp,
                                productTrackByBatch: pline.productTrackByBatch,
                                productUom: pline.productUom,
                                product: pline.product,
                                quantity: 0,
                                quantityReceived: 0,
                                quantityReturned: 0,
                                locationUuid: getStringFromObject('destLocation.key', pline),
                                allBatches: new Set(),
                            };
                        }
                        if (p.transactionType === 'RETURN_STOCK') {
                            pickingsForProduct.quantityReturned += NumberOf(pline.quantity);
                        } else {
                            pickingsForProduct.quantityReceived += NumberOf(pline.quantity);
                        }
                        if (isObjectValidAndNotEmpty(pline.stockProdLot)) {
                            pickingsForProduct.allBatches.add(pline.stockProdLot.key);
                        }
                        stockLocation = {
                            uuid: getStringFromObject('destLocation.key', pline),
                            name: getStringFromObject('destLocation.value', pline),
                        };
                        // todo add batches list later to choose batch from
                        productMap[pline.product.key] = pickingsForProduct;
                    });
                }
            }
        });
    }
    console.log('hajfhfgahfla', productMap);
    const stockReturnLines = Object.values(productMap);
    let batches = [];
    if (isArrayValidAndNotEmpty(stockReturnLines)) {
        batches = await Promise.all(
            stockReturnLines.map(async (line) => {
                const allBatchesSet = line.allBatches || new Set();
                const productUuid = getStringFromObject('product.key', line);
                const locationUuid = getStringFromObject('locationUuid', line);
                if (productUuid && locationUuid && line.productTrackByBatch) {
                    const batchResponse = await getBatches(productUuid, locationUuid);
                    const batchList = getStringFromObject('data.batchDetailsDtos', batchResponse, []);
                    const batchOptions = [];
                    if (isArrayValidAndNotEmpty(batchList)) {
                        batchList.forEach((batch) => {
                            if (allBatchesSet.has(getStringFromObject('batch.key', batch))) {
                                batchOptions.push({
                                    ...batch,
                                    // eslint-disable-next-line
                                    display: `${getStringFromObject('batch.value', batch)} (${getStringFromObject('quantity', batch)})`,
                                });
                            }
                        });
                    }
                    return ({
                        ...line,
                        batchList: batchOptions,
                    });
                }
                return line;
            }),
        );
    }
    console.log('hajfhfgahfla', batches);
    return {
        partner,
        purchaseOrder: purchaseOrderUuid,
        sourceLoc: stockLocation,
        stockPickingLinesDTOSet: batches,
    };
};

const getPrintDataView = (values) => {
    const printData = {
        lines: [],
        name: getStringFromObject('name', values),
        date: formatDateForDisplay(getStringFromObject('createDateTime', values)),
        company: getStringFromObject('companyName', values),
        partner: getStringFromObject('partner.value', values),
        purchaseOrder: getStringFromObject('purchaseOrderNumber', values),
    };
    const partnerAddress = getStringFromObject('partnerAddress', values);
    if (isObjectValidAndNotEmpty(partnerAddress)) {
        printData.partnerAddress = getAddressFromAddressDtoMemoized(partnerAddress);
    }
    const lines = getStringFromObject('stockPickingLinesDTOSet', values);
    let total = 0;
    if (isArrayValidAndNotEmpty(lines)) {
        printData.lines = lines.map((l) => {
            const rate = NumberOf(l.cost);
            const quantity = NumberOf(l.quantity);
            const lineTotal = rate * quantity;
            total += lineTotal;
            return ({
                name: getStringFromObject('product.value', l),
                uom: getStringFromObject('productUom.value', l),
                quantity: valueToFixedTwoDigits(quantity),
                rate: valueToFixedTwoDigits(rate),
                total: valueToFixedTwoDigits(lineTotal),
            });
        });
    }
    printData.total = total;
    console.log('pahfuhdsfa', printData, values);
    return printData;
};

export const getMemoizedPrintDataView = memoizeOne(getPrintDataView);
