/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import { CLEAR_APPOINTMENT_SLOT_STATE, FETCH_APPOINTMENT_SLOT_SUCCESS } from './appointment-actions';

const appointmentReducer = (state = initialStates.appointment, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_APPOINTMENT_SLOT_SUCCESS:
        newState = { ...state, appointmentSlots: action.data };
        break;
    case CLEAR_APPOINTMENT_SLOT_STATE:
        newState = { ...state, appointmentSlots: {} };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default appointmentReducer;
