import { takeLatest } from 'redux-saga/effects';
import {
    createAnalyticalAccountWorker,
    fetchPagesOfAnalyticalAccountsWorker,
} from './analyticalAccountsWorkerSaga';
import {
    CREATE_ANALYTICAL_ACCOUNT_REQUEST,
    FETCH_ANALYTICAL_ACCOUNTS_REQUEST,
} from '../../redux/modules/analyticalAccounts/analyticalAccounts-actions';

export function* fetchPagesOfAnalyticalAccountsWatcher() {
    yield takeLatest(FETCH_ANALYTICAL_ACCOUNTS_REQUEST, fetchPagesOfAnalyticalAccountsWorker);
}

export function* createAnalyticalAccountWatcher() {
    yield takeLatest(CREATE_ANALYTICAL_ACCOUNT_REQUEST, createAnalyticalAccountWorker);
}
