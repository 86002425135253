import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid/index';

import { isValidFunction } from '../../constants/CommonUtil';
import OutlinedTextField from '../OutlinedTextField';
import {
    clearConfirmDialogWithUserInput,
} from '../../redux/modules/confirmDialogWithUserInput/confirmDialogWithUserInput-actions';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class ConfirmDialogWithUserInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
        };
    }
    handleChangeInput = (value) => {
        this.setState({ inputText: value });
    };

    handleCancelClick = () => {
        const { dispatch } = this.props;
        this.setState({ inputText: '' });
        dispatch(clearConfirmDialogWithUserInput());
    };

    handleConfirmClick = () => {
        const { inputText } = this.state;
        const {
            confirmDialogWithUserInput: {
                confirmCallBack,
                validationText = 'YES',
            },
            dispatch,
        } = this.props;
        const isInputTextValid = inputText && inputText.toLowerCase() === validationText.toLowerCase();
        if (!isInputTextValid) {
            return;
        }
        this.setState({ inputText: '' });
        dispatch(clearConfirmDialogWithUserInput());
        if (isValidFunction(confirmCallBack)) {
            confirmCallBack();
        }
    };

    render() {
        const { inputText } = this.state;
        const {
            confirmDialogWithUserInput,
        } = this.props;
        if (!isObjectValidAndNotEmpty(confirmDialogWithUserInput)) {
            return null;
        }
        const {
            message,
            open,
            title = 'Warning',
            confirmLabel = 'Confirm',
            cancelLabel = 'Cancel',
            validationText = 'YES',
        } = confirmDialogWithUserInput;
        const isInputTextValid = inputText && inputText.toLowerCase() === validationText.toLowerCase();
        return (
            <Dialog
                fullScreen={false}
                maxWidth="md"
                open={!!(open)}
                onClose={this.handleCancelClick}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ whiteSpace: 'pre' }} test-id="message">
                        <Grid container spacing={8}>
                            <Grid item lg={12}>
                                {message}
                            </Grid>
                            <Grid item lg={12} className="mt-1">
                            Type <strong>{validationText.toUpperCase()}</strong> to {confirmLabel}
                            </Grid>
                            <Grid item lg={12}>
                                <OutlinedTextField
                                    type="text"
                                    value={inputText}
                                    onChange={this.handleChangeInput}
                                    fullWidth={false}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelClick} color="secondary" test-id="label-cancel">
                        {cancelLabel}
                    </Button>
                    <Button
                        onClick={this.handleConfirmClick}
                        color="primary"
                        test-id="label-confirm"
                        disabled={!isInputTextValid}
                    >
                        {confirmLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDialogWithUserInput.propTypes = {
    confirmDialogWithUserInput: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    confirmDialogWithUserInput: state.confirmDialogWithUserInput,
});


export default connect(mapStateToProps)(ConfirmDialogWithUserInput);
