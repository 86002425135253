import initialStates from '../../initialStates';
import { CLEAR_SELECTED_INTERNAL_MOVE_ORDER, CREATE_INTERNAL_MOVE_SUCCESS, FETCH_INTENT_SUCCESS } from './internalMoves-actions';

const internalMovesReducer = (state = initialStates.internalMoves, action) => {
    let newState = {};
    switch (action.type) {
    case CREATE_INTERNAL_MOVE_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case CLEAR_SELECTED_INTERNAL_MOVE_ORDER:
        newState = Object.assign({}, state, { selected: {} });
        break;
    case FETCH_INTENT_SUCCESS:
        newState = Object.assign({}, state, { intentData: action.data });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default internalMovesReducer;
