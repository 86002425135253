import { takeLatest } from 'redux-saga/effects';
import { createAccountInvoiceWorker, fetchAccountInvoiceWorker } from './accountInvoiceWorkerSaga';
import {
    CANCEL_ACCOUNT_INVOICE_REQUEST,
    CREATE_ACCOUNT_INVOICE_REQUEST,
    FETCH_ACCOUNT_INVOICE_REQUEST,
} from '../../redux/modules/accountInvoice/accountInvoice-actions';
import { commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* createAccountInvoiceWatcher() {
    yield takeLatest(CREATE_ACCOUNT_INVOICE_REQUEST, createAccountInvoiceWorker);
}

export function* fetchAccountInvoiceWatcher() {
    yield takeLatest(FETCH_ACCOUNT_INVOICE_REQUEST, fetchAccountInvoiceWorker);
}

export function* cancelAccountInvoiceWatcher() {
    yield takeLatest(CANCEL_ACCOUNT_INVOICE_REQUEST, commonPostWorkerSaga);
}
