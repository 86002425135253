import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import Popover from '@material-ui/core/Popover/Popover';
import Avatar from '@material-ui/core/Avatar/Avatar';
import AddCircle from '@material-ui/icons/AddCircle';
import { Typography } from '@material-ui/core';
import { Report } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import {
    bookManagement,
    bookManagementAccount,
    bookManagementAging,
    bookManagementAnalyticalAccount,
    bookManagementSearch,
    bookManagementJournalEntries,
    bookManagementPayerCollection,
    COLORS,
    ERP_BOOK_TRANSACTION_TYPES,
    pathPrivileges,
    bookManagementBankReconciliation,
    bookManagementCardReconciliation,
    bookManagementPaymentVoucher,
    bookManagementDebitAndCreditMemo,
} from '../../constants/constants';
import BookAddition from '../../components/PurchaseOrderComponents/BookAddition';
import TransferDialog from '../../components/PurchaseOrderComponents/TransferDialog';
import SupplierPurchaseBillContainer from './SupplierPurchaseBillContainer';
import MakeAdvancePaymentDialog from '../../components/PurchaseOrderComponents/MakeAdvancePaymentDialog';
import ExpenseDialog from '../../components/BookManagementComponents/ExpenseDialog';
import SearchPopover from '../../components/PurchaseOrderComponents/SearchPopover';
import JournalEntriesDialog from '../../components/PurchaseOrderComponents/JournalEntriesDialog';
import PettyCashDialog from '../../components/PurchaseOrderComponents/EmployeeDialogs/PettyCashDialog';
import SalaryAdvanceContainer from '../../components/PurchaseOrderComponents/EmployeeDialogs/SalaryAdvanceDialog';
import ExpenseContainer from '../../components/PurchaseOrderComponents/EmployeeDialogs/ExpenseDialog';
import PrepaidExpenseDialog from '../../components/BookManagementComponents/PrepaidExpenseDialog';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import CashCloseDialog from '../../components/CashClose/CashCloseDialog';
import ConsolidatedCashCloseDialog from '../../components/ConsolidatedCashClose/ConsolidatedCashCloseDialog';
import ReceivePaymentDialogWrapper
    from '../../components/PurchaseOrderComponents/ReceivePaymentDialog/ReceivePaymentDialogWrapper';
import SideMenu from '../../components/SideMenu/SideMenu';
import { getSearchCategories, SEARCH_CATEGORIES } from './AdvanceSearchUtil';
import SupplierPaymentDialogWrapper
    from '../../components/PurchaseOrderComponents/SupplierPayment/SupplierPaymentDialogWrapper';
import PayOrReceiveOnAccount from '../../components/PayOrReceiveOnAccount/PayOrReceiveOnAccount';
import CashPaymentVoucherDialog from '../../components/CashPaymentVoucher/CashPaymentVoucherDialog';
import SanityCheckPopover from '../../components/PurchaseOrderComponents/SanityCheckPopover';
import IssueInvoiceDialog from '../../components/PurchaseOrderComponents/IssueInvoice/IssueInvoiceDialog';
import { getStringFromObject } from '../../constants/lodashUtils';
import CreditOrDebitMemoWrapper from '../../components/PurchaseOrderComponents/CreditOrDebitMemoWrapper/CreditOrDebitMemoWrapper';
import { memoTypes } from '../../components/PurchaseOrderComponents/CreditOrDebitMemoV2/CreditOrDebitMemoUtilsV2';

const menuContainerMap = {
    expense: ExpenseDialog,
    transfer: TransferDialog,
    purchaseBill: SupplierPurchaseBillContainer,
    advancePayment: MakeAdvancePaymentDialog,
    payBill: SupplierPaymentDialogWrapper,
    receivePayment: ReceivePaymentDialogWrapper,
    journalEntries: JournalEntriesDialog,
    issueCreditMemo: CreditOrDebitMemoWrapper,
    issueDebitMemo: CreditOrDebitMemoWrapper,
    receiveCreditMemo: CreditOrDebitMemoWrapper,
    receiveDebitMemo: CreditOrDebitMemoWrapper,
    pettyCash: PettyCashDialog,
    salaryAdvance: SalaryAdvanceContainer,
    employeeExpense: ExpenseContainer,
    prepaidExpense: PrepaidExpenseDialog,
    cashClose: CashCloseDialog,
    consolidatedCashClose: ConsolidatedCashCloseDialog,
    // paidOnAccount: PaidOnAccountDialog,
    payOrReceiveOnAccount: PayOrReceiveOnAccount,
    cashPaymentVoucher: CashPaymentVoucherDialog,
    issueInvoice: IssueInvoiceDialog,
};

const style = theme => ({
    fixedMenu: {
        '&:hover': {
            width: '20em',
        },
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '0.8rem',
        },
    },
});


const bookManageMentTabs = {
    JOURNAL_ENTRIES: {
        label: 'ENTRIES',
        value: bookManagementJournalEntries,
    },
    CHART_OF_ACCOUNTS: {
        label: 'ACCOUNTS',
        value: bookManagementAccount,
    },
    AGING: {
        label: 'AGING',
        value: bookManagementAging,
    },
    // ANALYTICAL_ACCOUNTS: {
    //     label: 'Cost Accounts',
    //     value: bookManagementAnalyticalAccount,
    // },
    PAYER_COLLECTION: {
        label: 'PAYER',
        value: bookManagementPayerCollection,
    },
    BANK_RECONCILIATION: {
        label: 'RECONCILIATION',
        value: bookManagementBankReconciliation,
    },
    PAYMENT_VOUCHER: {
        label: 'VOUCHER',
        value: bookManagementPaymentVoucher,
    },
    DEBIT_AND_CREDIT_MEMO: {
        label: 'MEMO',
        value: bookManagementDebitAndCreditMemo,
    },
};

const getSideMenuLabel = icon => (
    <div className="flexChildren justify-content-center">
        {icon}
        <span style={{ color: 'red', visibility: 'hidden' }}>
            &nbsp; &#x25cf;
        </span>
    </div>
);

class BookManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.changeTabForRoute(props),
            anchorEl: null,
            anchorElForSearch: null,
            anchorElForSanityCheck: null,
            selectedMenu: null,
        };
        this.commonMenuItems = Object.keys(getSearchCategories()).map((c, index) => ({
            // eslint-disable-next-line max-len
            icon: getSideMenuLabel(<Avatar style={{ backgroundColor: COLORS[index] }}>{SEARCH_CATEGORIES[c].charAt(0)}</Avatar>),
            text: SEARCH_CATEGORIES[c],
            onClick: this.handleClickQuickSearch(SEARCH_CATEGORIES[c]),
        }));
    }

    getMenuProps = (selectedMenu) => {
        if (selectedMenu) {
            let menuProps = {};
            switch (selectedMenu) {
            case 'payBill':
                menuProps = {
                    ...menuProps,
                    chooseSupplier: true,
                };
                break;
            case 'receivePayment':
                menuProps = {
                    ...menuProps,
                    isPayor: true,
                };
                break;
            case 'prepaidExpense':
                menuProps = {
                    ...menuProps,
                };
                break;
            case 'issueCreditMemo':
                menuProps = {
                    ...menuProps,
                    memoType: memoTypes.ISSUE_CREDIT_MEMO,
                };
                break;
            case 'issueDebitMemo':
                menuProps = {
                    ...menuProps,
                    memoType: memoTypes.ISSUE_DEBIT_MEMO,
                };
                break;
            case 'receiveCreditMemo':
                menuProps = {
                    ...menuProps,
                    memoType: memoTypes.RECEIVE_CREDIT_MEMO,
                };
                break;
            case 'receiveDebitMemo':
                menuProps = {
                    ...menuProps,
                    memoType: memoTypes.RECEIVE_DEBIT_MEMO,
                };
                break;
            default:
            }
            console.log('SelectedMenu', menuProps);
            return menuProps;
        }
        return {};
    };

    changeRouteForSelectedTab = (selected = this.state.selectedTab) => {
        if (selected) {
            this.props.history.push(selected);
        }
    };

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        if (route === bookManagement) {
            if (checkIfPrivilegeExistsForUser(
                getStringFromObject(`${bookManagementJournalEntries}.privileges`, pathPrivileges),
            )) {
                return bookManagementJournalEntries;
            } else if (checkIfPrivilegeExistsForUser(
                getStringFromObject(`${bookManagementAccount}.privileges`, pathPrivileges),
            )) {
                return bookManagementAccount;
            } else if (checkIfPrivilegeExistsForUser(
                getStringFromObject(`${bookManagementAging}.privileges`, pathPrivileges),
            )) {
                return bookManagementAging;
            } else if (checkIfPrivilegeExistsForUser(
                getStringFromObject(`${bookManagementAnalyticalAccount}.privileges`, pathPrivileges),
            )) {
                return bookManagementAnalyticalAccount;
            } else if (
                checkIfPrivilegeExistsForUser(
                    getStringFromObject(`${bookManagementBankReconciliation}.privileges`, pathPrivileges),
                ) || checkIfPrivilegeExistsForUser(
                    getStringFromObject(`${bookManagementCardReconciliation}.privileges`, pathPrivileges),
                )
            ) {
                return bookManagementBankReconciliation;
            }
        }
        return route;
    };

    handleClickQuickSearch = c => () => {
        const { history } = this.props;
        history.push(`${bookManagementSearch}?q=${c}`);
    };

    handleChange = (event, selectedTab) => {
        event.preventDefault();
        this.setState({
            selectedTab,
        }, this.changeRouteForSelectedTab);
    };

    handleMenuClick = (value) => {
        this.setState({
            selectedMenu: value,
            anchorEl: null,
            anchorElForSearch: null,
            anchorElForSanityCheck: null,
        });
    };

    handlePopover = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    handleSearchPopover = (event) => {
        this.setState({ anchorElForSearch: event.currentTarget });
    };

    handleSearchPopoverClose = () => {
        this.setState({ anchorElForSearch: null });
    };

    handleSanityCheckPopover = (event) => {
        this.setState({ anchorElForSanityCheck: event.currentTarget });
    };

    handleSanityCheckPopoverClose = () => {
        this.setState({ anchorElForSanityCheck: null });
    };

    handleMenuCloseDialog = () => {
        this.setState({
            selectedMenu: null,
        });
    };

    handleAdvancedSearch = () => {
        this.props.history.push(bookManagementSearch);
    };

    render() {
        const { classes, children } = this.props;
        const {
            selectedTab,
            anchorEl,
            anchorElForSearch,
            anchorElForSanityCheck,
            selectedMenu,
        } = this.state;
        let MenuDialog = null;
        const menuProps = this.getMenuProps(selectedMenu);
        /* selected menu is there then we render a particular dialog from menuContainer map */
        if (selectedMenu) {
            MenuDialog = menuContainerMap[selectedMenu];
        }
        const menuItems = [...this.commonMenuItems];
        return (
            <React.Fragment>
                <div style={{ background: '#fff', padding: '2rem', minHeight: '100%' }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item lg={9} md={9} sm={9}>
                            <AppBar position="static" className={classes.appBar}>
                                <Tabs
                                    scrollable
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    fullWidth
                                    scrollButtons="auto"
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    {
                                        Object.values(bookManageMentTabs).map((aBookManagementTab) => {
                                            if (checkIfPrivilegeExistsForUser(
                                                getStringFromObject(
                                                    `${aBookManagementTab.value}.privileges`, pathPrivileges, [],
                                                ),
                                            )) {
                                                return (
                                                    <Tab
                                                        test-id={aBookManagementTab.label}
                                                        key={aBookManagementTab.value}
                                                        value={aBookManagementTab.value}
                                                        label={aBookManagementTab.label}
                                                        classes={{
                                                            root: classes.tabRoot,
                                                            selected: classes.tabSelected,
                                                            label: classes.label,
                                                        }}
                                                        disableRipple
                                                    />
                                                );
                                            }
                                            return '';
                                        })
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid
                            item
                            lg={0.8}
                            md={0.8}
                            sm={0.8}
                            onClick={this.handlePopover}
                            style={{ textAlign: 'center' }}
                        >
                            <AddCircle onClick={this.handlePopover} className="cursor-pointer" test-id="create-add" />
                            <Typography
                                test-id="create"
                                onClick={this.handlePopover}
                                className="cursor-pointer"
                                style={{ display: 'block', marginBottom: '-10px' }}
                            >
                                Create
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={0.8}
                            md={0.8}
                            sm={0.8}
                            textAlign="center"
                            onClick={this.handleSanityCheckPopover}
                            style={{ textAlign: 'center' }}
                        >
                            <Report
                                // onClick={this.handlePopover}
                                className="cursor-pointer"
                                test-id="sanity-check"
                            />
                            <Typography
                                test-id="s-check"
                                // onClick={this.handlePopover}
                                className="cursor-pointer"
                                style={{ display: 'block', marginBottom: '-10px' }}
                            >
                                Sanity Check
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            onClick={this.handleSearchPopover}
                            className="cursor-pointer"
                            lg={0.8}
                            md={0.8}
                            sm={0.8}
                            style={{ textAlign: 'center' }}
                        >
                            {
                                checkIfPrivilegeExistsForUser(
                                    getStringFromObject(`${bookManagementSearch}.privileges`, pathPrivileges),
                                ) &&
                                    <React.Fragment>
                                        <SearchIcon
                                            onClick={this.handleSearchPopover}
                                            className="cursor-pointer"
                                            test-id="search-icon"
                                        />
                                        <Typography
                                            test-id="search"
                                            onClick={this.handleSearchPopover}
                                            className="cursor-pointer"
                                            style={{ display: 'block', marginBottom: '-10px' }}
                                        >
                                            Search
                                        </Typography>
                                    </React.Fragment>
                            }
                        </Grid>
                        <Grid item lg={0.7} md={0.7} sm={0.7} />
                    </Grid>
                    {children}
                </div>
                {
                    selectedMenu &&
                        <MenuDialog
                            {...menuProps}
                            open
                            handleClose={this.handleMenuCloseDialog}
                        />
                }
                <Popover
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                >
                    <BookAddition
                        handleMenuClick={this.handleMenuClick}
                    />
                </Popover>
                <Popover
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorElForSearch}
                    anchorEl={anchorElForSearch}
                    onClose={this.handleSearchPopoverClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                >
                    <SearchPopover
                        handleAdvancedSearch={this.handleAdvancedSearch}
                        transactionTypes={ERP_BOOK_TRANSACTION_TYPES}
                        // eslint-disable-next-line
                        label="Purchase Invoice ,Account Move ,Expense ,Customer Refund Invoice ,Credit Memo ,Supplier Invoice ,Pay Invoice ,Fund Transfer ,Salary Advance ,Petty Cash ,Prepaid Expense"
                    />
                </Popover>
                <Popover
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorElForSanityCheck}
                    anchorEl={anchorElForSanityCheck}
                    onClose={this.handleSanityCheckPopoverClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                >
                    <SanityCheckPopover handleClose={this.handleSanityCheckPopoverClose} />
                </Popover>
                <SideMenu
                    items={menuItems}
                    classes={{
                        primary: classes.sideMenuItem,
                        fixedMenu: classes.fixedMenu,
                    }}
                />
            </React.Fragment>
        );
    }
}

BookManagementContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node,
};

BookManagementContainer.defaultProps = {
    children: null,
};

export default withStyles(style)(BookManagementContainer);
