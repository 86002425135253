import memoizeOne from 'memoize-one';


import {
    isArrayValidAndNotEmpty, isValidNumber,
    roundedValue, valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { formatDate } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';

export const MaterialReceiveFields = {
    LOCATION: 'location',
    SUPPLIER: 'supplier',
    PRODUCTS: 'receiveMaterialLines',
    NOTES: 'memo',
    STATE: 'state',
    DATE: 'date',
    DISCOUNT_PERCENT: 'discountPercent',
    DISCOUNT: 'discount',
    CASH_PURCHASE: 'isCashPurchase',
    CASH_SUPPLIER_NAME: 'cashSupplierName',
    CASH_SUPPLIER_VAT_NUMBER: 'cashSupplierVatNumber',
    CASH_SUPPLIER_SUB_COMPANY: 'cashSupplierSubCompany',
    SUB_VENDOR: 'subVendor',
};

export const convertToPlainData = (materialReceive) => {
    console.log('convertToPlainData', materialReceive);
    const printData = {
        from: getStringFromObject('supplier.name', materialReceive),
        to: getStringFromObject('location.name', materialReceive),
        state: getStringFromObject('status', materialReceive),

        name: getStringFromObject('number', materialReceive),
        date: formatDate(getStringFromObject('createDate', materialReceive), 'dd-MM-yyyy'),
        discount: NumberOf(getStringFromObject('discount', materialReceive)),
    };
    const isCashPurchase = getStringFromObject('isCashPurchase', materialReceive) || false;
    if (isCashPurchase) {
        printData.from = getStringFromObject('cashSupplierName', materialReceive);
        printData.vatNumber = getStringFromObject('cashSupplierVatNumber', materialReceive);
    } else {
        printData.from = getStringFromObject('supplier.name', materialReceive);
    }
    let subTotal = 0;
    let materialReceiveTax = 0;
    const lines = getStringFromObject('receiveMaterialLines', materialReceive, []);
    if (isArrayValidAndNotEmpty(lines)) {
        printData.lines = lines.map((lineData) => {
            const discountPerc = NumberOf(getStringFromObject('discount1', lineData, 0));
            const discountPerc2 = NumberOf(getStringFromObject('discount2', lineData, 0));
            const quantity = NumberOf(getStringFromObject('quantity', lineData));
            const rate = NumberOf(getStringFromObject('price', lineData));
            const subTotalLine = quantity * rate;
            const discountAmount = roundedValue(subTotalLine * (discountPerc / 100));
            const discountAmount2 = roundedValue((subTotalLine - discountAmount) * (discountPerc2 / 100));
            const amount = NumberOf(valueToFixedTwoDigits(subTotalLine - (discountAmount + discountAmount2)));
            let lineTotal = 0;
            const taxes = getStringFromObject('taxes', lineData, []);
            let taxDisplay = '-';

            let totalTaxAmount = 0;

            if (isArrayValidAndNotEmpty(taxes)) {
                taxDisplay = taxes.map((tax) => {
                    const taxPercentageFactor = NumberOf(tax.amount);
                    const taxAmountOfLine = (amount * taxPercentageFactor);
                    totalTaxAmount += taxAmountOfLine;
                    return [
                        tax.name,
                        isValidNumber(taxPercentageFactor) ? `${taxPercentageFactor * 100}%` : false,
                    ].filter(Boolean).join('-');
                }).filter(Boolean).join('<br/>');
            }
            totalTaxAmount = roundedValue(totalTaxAmount);
            lineTotal = totalTaxAmount + amount;
            materialReceiveTax += totalTaxAmount;
            subTotal += amount;
            return {
                productCode: getStringFromObject('product.productCode', lineData),
                product: getStringFromObject('product.productName', lineData),
                uom: getStringFromObject('uom.name', lineData),
                quantity: getStringFromObject('quantity', lineData),
                bonus: getStringFromObject('bonus', lineData),
                discount: discountPerc,
                discount2: discountPerc2,
                discountAmount: valueToFixedTwoDigits(discountAmount),
                discountAmount2: valueToFixedTwoDigits(discountAmount2),
                rate,
                amount: valueToFixedTwoDigits(amount),
                taxes: taxDisplay,
                totalTaxAmount: valueToFixedTwoDigits(totalTaxAmount),
                total: valueToFixedTwoDigits(lineTotal),
            };
        });
    }
    printData.subTotal = valueToFixedTwoDigits(subTotal);
    printData.invoiceTax = valueToFixedTwoDigits(materialReceiveTax);
    printData.total = valueToFixedTwoDigits((subTotal + materialReceiveTax) - printData.discount);
    console.log('asd09u09uqe2qsdasd', printData);
    return printData;
};

export const convertToPlainDataMemoized = memoizeOne(convertToPlainData);
