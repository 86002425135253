import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage, getUrlWithApiParams,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_ACCOUNTS_FAILURE,
    CREATE_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_LIST_FAILURE,
    FETCH_ACCOUNTS_LIST_SUCCESS,
    ADJUST_BALANCE_FAILURE,
    ADJUST_BALANCE_SUCCESS,
} from '../../redux/modules/accounts/accounts-actions';

export function* fetchPagesOfAccountsWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_ACCOUNTS_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.ACCOUNTS.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_ACCOUNTS_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createAccountWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.account);
        yield put({
            type: CREATE_ACCOUNTS_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.ACCOUNTS.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.ACCOUNTS.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_ACCOUNTS_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* adjustBalanceWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const params = {
            accountId: action.account,
            credit: action.credit,
            debit: action.debit,
            reason: action.reason,
            date: action.date,
        };
        const apiWithQueryParam = getUrlWithApiParams(action.api, params);
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: ADJUST_BALANCE_SUCCESS,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.ACCOUNTS.ADJUST_BALANCE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.ACCOUNTS.ADJUST_BALANCE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: ADJUST_BALANCE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
