import {
    CLEAR_MESSAGE,
    ERROR_MESSAGE,
    INFO_MESSAGE,
    SUCCESS_MESSAGE,
    WARNING_MESSAGE,
} from './message-actions';

const privilegeReducer = (state = {}, action) => {
    let newState;
    switch (action.type) {
    case INFO_MESSAGE:
        newState = Object.assign(
            {},
            { message: action.message, canShow: true, type: 'info' },
        );
        break;
    case WARNING_MESSAGE:
        newState = Object.assign(
            {},
            { message: action.message, canShow: true, type: 'warning' },
        );
        break;
    case SUCCESS_MESSAGE:
        newState = Object.assign(
            {},
            { message: action.message, canShow: true, type: 'success' },
        );
        break;
    case ERROR_MESSAGE:
        newState = Object.assign(
            {},
            { message: action.message, canShow: true, type: 'error' },
        );
        break;
    case CLEAR_MESSAGE:
        newState = Object.assign({}, { canShow: false, type: state.type });
        break;
    default:
        newState = state;
    }

    return newState;
};

export default privilegeReducer;
