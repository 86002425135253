import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { contractTypeSettings, workHourTemplateSettings, workShiftSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const workShiftSettingsPage = React.lazy(() => import('../pages/AttendanceSettingsPages/WorkShiftSettingsPage'));
const workHourTemplateSettingsPage = React.lazy(() => import('../pages/AttendanceSettingsPages/WorkHourTemplateSettingsPage'));
const contractTypeSettingsPage = React.lazy(() => import('../pages/AttendanceSettingsPages/ContractTypeSettingsPage'));

const AttendanceSettingsRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={workHourTemplateSettings} exact component={workHourTemplateSettingsPage} />
            <Route path={workShiftSettings} exact component={workShiftSettingsPage} />
            <Route path={contractTypeSettings} exact component={contractTypeSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default AttendanceSettingsRoutes;
