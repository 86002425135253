import { takeEvery } from 'redux-saga/effects';
import {
    COMMON_DELETE_API_REQUEST,
    COMMON_GET_API_REQUEST,
    COMMON_POST_API_REQUEST,
} from '../../redux/modules/common/common-actions';
import { commonDeleteWorkerSaga, commonGetWorkerSaga, commonPostWorkerSaga } from './commonWorkerSaga';

export function* commonGetWatcherSaga() {
    yield takeEvery(COMMON_GET_API_REQUEST, commonGetWorkerSaga);
}

export function* commonPostWatcherSaga() {
    yield takeEvery(COMMON_POST_API_REQUEST, commonPostWorkerSaga);
}

export function* commonDeleteWatcherSaga() {
    yield takeEvery(COMMON_DELETE_API_REQUEST, commonDeleteWorkerSaga);
}
