/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { errorMessage } from '../../redux/modules/message/message-actions';
import './ValidateUserDialog.css';
import muiTheme from '../../themes/baseTheme';
import RoundedCornerButton from '../../components/RoundedCornerButton';
import api from '../../constants/api';
import { isValidFunction } from '../../constants/CommonUtil';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class ValidateUserDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
        };
    }

    onUserNameOrPasswordChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLoginAgain = () => {
        if (this.state.username && this.state.password) {
            // login
            const values = {
                username: this.state.username,
                password: this.state.password,
            };
            const {
                validateFunction,
            } = this.props;
            if (isValidFunction(validateFunction)) {
                validateFunction(values);
            } else {
                this.props.dispatch(showSpinner());
                axios.post(api.LOGIN.VALIDATE_USERNAME_PASSWORD, values)
                    .then((response) => {
                        console.log('asda0piodkad', response.data);
                        this.props.dispatch(hideSpinner());
                        if (response && isObjectValidAndNotEmpty(response.data)) {
                            this.props.postValidateFunction(response.data);
                        } else {
                            this.props.dispatch(errorMessage('Username and password do not match'));
                        }
                    })
                    .catch(() => {
                        this.props.dispatch(hideSpinner());
                        this.props.dispatch(errorMessage('Username and password do not match'));
                    });
            }
        } else {
            this.props.dispatch(errorMessage('Username and password are mandatory'));
        }
    };

    handleKeyDown = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            this.onLoginAgain();
        }
    };


    render() {
        const {
            dialogTitle,
        } = this.props;
        return (
            <Dialog
                open
                modal={false}
                style={{ margin: 0 }}
                onClose={this.props.onClose}
                bodyStyle={{ padding: 0 }}
                contentClassName="dialogContent"
            >
                <DialogTitle style={{ padding: '0' }}>
                    <div className="topBar" style={{ background: muiTheme.palette.primary1Color }}>
                        <h3 className="sessionExpiredText">
                            {dialogTitle}
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="formSection">
                        <form onKeyPress={this.handleKeyDown}>
                            <Grid container>
                                <Grid item sm={12} md={12} lg={12}>
                                    <TextField
                                        test-id="username"
                                        name="username"
                                        label="Username"
                                        placeholder="Username"
                                        autoFocus
                                        onChange={this.onUserNameOrPasswordChange}
                                        value={this.state.username}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12} md={12} lg={12}>
                                    <TextField
                                        test-id="password"
                                        name="password"
                                        type="password"
                                        fullWidth
                                        label="Password"
                                        placeholder="Password"
                                        onChange={this.onUserNameOrPasswordChange}
                                        value={this.state.password}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '1em' }}>
                                <Grid item sm={6} md={6} lg={6} />
                                <Grid item lg={6} md={6} sm={6} style={{ textAlign: 'right', width: '100%' }}>
                                    <div className="pull-right margin-left8">
                                        <RoundedCornerButton
                                            testId="save"
                                            label="Save"
                                            backgroundColor="#1E88E5"
                                            color="#ffffff"
                                            onClick={this.onLoginAgain}
                                        />
                                    </div>
                                    <div className="pull-right">
                                        <RoundedCornerButton
                                            testId="cancel"
                                            label="Cancel"
                                            backgroundColor="#ffffff"
                                            color="#1E88E5"
                                            style={{ border: '1px solid #1E88E5' }}
                                            onClick={this.props.onClose}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

ValidateUserDialog.propTypes = {
    dispatch: PropTypes.func,
    postValidateFunction: PropTypes.func,
    validateFunction: PropTypes.func,
    onClose: PropTypes.func,
    dialogTitle: PropTypes.string,
};

ValidateUserDialog.defaultProps = {
    dispatch: () => {},
    postValidateFunction: () => {},
    validateFunction: null,
    dialogTitle: 'Enter Your Username & Password',
    onClose: () => {},
};

export default ValidateUserDialog;
