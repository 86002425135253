import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_INVOICE_LINE_DATA_REQUEST,
    FETCH_INVOICE_LIST_DATA_REQUEST,
    FETCH_INVOICE_DETAILS_REQUEST,
    UPDATE_INVOICE_LINE_REQUEST,
} from '../../redux/modules/invoice/invoice-actions';
import {
    getInsuranceInvoiceListWorker,
    getInsuranceInvoiceLinesWorker,
    getInsuranceInvoiceDetailsWorker,
    updateInsuranceInvoiceLineWorker,
} from './insuranceInvoiceListWorkerSaga';

export function* getInsuranceInvoiceListSaga() {
    yield takeLatest(FETCH_INVOICE_LIST_DATA_REQUEST, getInsuranceInvoiceListWorker);
}

export function* getInsuranceInvoiceLineSaga() {
    yield takeLatest(FETCH_INVOICE_LINE_DATA_REQUEST, getInsuranceInvoiceLinesWorker);
}

export function* getInsuranceInvoiceDetailsSaga() {
    yield takeLatest(FETCH_INVOICE_DETAILS_REQUEST, getInsuranceInvoiceDetailsWorker);
}

export function* updateInsuranceInvoiceLineSaga() {
    yield takeLatest(UPDATE_INVOICE_LINE_REQUEST, updateInsuranceInvoiceLineWorker);
}
