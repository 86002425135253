import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearAll from '@material-ui/icons/ClearAll';
import OutlinedTextField from '../../OutlinedTextField';
import {
    getTextFieldChangedValue, getTextFieldValueByNativeEvent,
    isEnterKeyPressed, isGroupSeparatorPressed,

} from '../../../constants/CommonUtil';
import { getProductDetailsFromScannedBarcode } from '../InternalMovesDialog/InternalMovesUtil';
import { GROUP_SEPARATOR_CHAR } from '../../../constants/constants';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

class DrugBarcodeEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enterBarcodeProductQty: false,
            scannedBarcode: '',
            barcodeProductQty: '',
        };
    }

    onChangeScannedBarcode = (_, event) => {
        const changedVal = getTextFieldValueByNativeEvent(event);
        this.setState({
            scannedBarcode: changedVal,
        });
    };

    onChangeBarcodeProductQty = (e) => {
        const changedVal = getTextFieldChangedValue(e);
        this.setState({
            barcodeProductQty: changedVal,
        });
    };

    onKeyDown = async (e) => {
        const { scannedBarcode } = this.state;
        if (isEnterKeyPressed(e)) {
            // check if the barcode scanned has the product in this list
            const {
                dispatch,
                onlyBatch,
                barcodeParser,
            } = this.props;
            const prodInfo = await getProductDetailsFromScannedBarcode(scannedBarcode, dispatch, onlyBatch, barcodeParser);
            if (isObjectValidAndNotEmpty(prodInfo) && prodInfo.produceCode) {
                this.setState({
                    enterBarcodeProductQty: true,
                });
            }
        }
        if (isGroupSeparatorPressed(e)) {
            this.setState({
                scannedBarcode: scannedBarcode + GROUP_SEPARATOR_CHAR,
            });
        }
    };

    onBarcodeProductQtyConfirm = async (e) => {
        if (isEnterKeyPressed(e)) {
            const {
                onConfirmQuantity,
                dispatch,
                barcodeParser,
            } = this.props;
            const {
                barcodeProductQty,
                scannedBarcode,
            } = this.state;
            const prodInfo = await getProductDetailsFromScannedBarcode(scannedBarcode, dispatch, false, barcodeParser);
            if (prodInfo) {
                const successfull = onConfirmQuantity(prodInfo, barcodeProductQty);
                if (successfull) {
                    this.clearBarcodeEntry();
                }
            }
        }
    };

    clearBarcodeEntry = () => {
        this.setState({
            enterBarcodeProductQty: false,
            barcodeProductQty: '',
            scannedBarcode: '',
        });
    };

    render() {
        const {
            barcodeProductQty,
            enterBarcodeProductQty,
            scannedBarcode,
        } = this.state;
        const {
            onConfirmQuantity,
        } = this.props;
        console.log(onConfirmQuantity, barcodeProductQty);
        return (
            <Grid container className="mt-1" spacing={16}>
                <Grid item sm={4} md={4} lg={4}>
                    <OutlinedTextField
                        key={enterBarcodeProductQty}
                        label="Scan barcode"
                        value={scannedBarcode}
                        disabled={enterBarcodeProductQty}
                        onChange={this.onChangeScannedBarcode}
                        onKeyDown={this.onKeyDown}
                        autoFocus
                    />
                </Grid>
                {
                    enterBarcodeProductQty &&
                    <React.Fragment>
                        <Grid item sm={3} md={3} lg={3}>
                            <OutlinedTextField
                                label="Enter Quantity"
                                value={barcodeProductQty}
                                onChange={this.onChangeBarcodeProductQty}
                                onKeyDown={this.onBarcodeProductQtyConfirm}
                                type="number"
                                autoFocus
                            />
                        </Grid>
                        <Grid item sm={2} md={2} lg={2}>
                            <IconButton onClick={this.clearBarcodeEntry}>
                                <ClearAll />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        );
    }
}

DrugBarcodeEntry.propTypes = {
    dispatch: PropTypes.func,
    onConfirmQuantity: PropTypes.func,
    onlyBatch: PropTypes.bool,
    barcodeParser: PropTypes.string,
};

DrugBarcodeEntry.defaultProps = {
    dispatch: () => { },
    onConfirmQuantity: () => { },
    onlyBatch: false,
    barcodeParser: '',
};

export default DrugBarcodeEntry;

