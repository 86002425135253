/**
 * Created by vinay on 18/12/18.
 */
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty,
    sortArrayOfObjectsByFieldValue,
} from '../constants/CommonUtil';
import { formatDate, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
// import { formatDate } from '../constants/DateUtil';

export const ADD_STOCK_OPERATION = {
    SAVE: 'save',
    CONFIRM: 'confirm',
};

const mapStockPickingLinesFromUiObject = (lines) => {
    console.log('these are the lines', lines);
    const stockPickingLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            if (line.product && line.product.uuid) {
                const stockPickingLine = {
                    product: {
                        key: getStringFromObject('product.uuid', line, null),
                        value: getStringFromObject('product.productName', line, ''),
                    },
                    batchName: getStringFromObject('description', line, null),
                    quantity: getStringFromObject('quantity', line, 0),
                    productUom: {
                        key: getStringFromObject('uom.uuid', line, null),
                        value: getStringFromObject('uom.name', line, ''),
                    },
                    cost: getStringFromObject('cost', line, 0),
                    salePrice: getStringFromObject('salePrice', line, 0),
                    mrp: getStringFromObject('mrp', line, 0),
                    uuid: getStringFromObject('uuid', line, null),
                    expiryDate: formatDate(getStringFromObject('expiry', line, new Date()), 'yyyy-MM-dd hh:mm:ss'),
                    // To start sequence from 1
                    sequence: index + 1,
                    productGtinMapDto: getStringFromObject('productGtinMapDto', line) || null,
                };
                stockPickingLines.push(stockPickingLine);
            }
        });
    }
    console.log('these are the stock lines', stockPickingLines);
    return stockPickingLines;
};

export const mapAddStockFromUiObject = (uiObject, action) => {
    const payload = {};
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = action;
        payload.origin = uiObject.reference;
        payload.operation = 'ADD_STOCK';
        payload.destLoc = {
            key: getStringFromObject('location.uuid', uiObject, null),
            value: getStringFromObject('location.name', uiObject, ''),
        };
        payload.stockPickingLinesDTOSet = mapStockPickingLinesFromUiObject(uiObject.addStock);
        payload.date = getStringFromObject('date', uiObject) || new Date().getTime;
    }
    return payload;
};

const mapStockPickingLineUiObject = (lines) => {
    console.log('dhalkdjfhaklsdfhakls', lines);
    let stockPickingLines = [];
    if (isArrayValidAndNotEmpty(lines)) {
        stockPickingLines = lines.map((stock, index) => {
            const temp = {
                product: {
                    uuid: getStringFromObject('product.key', stock, ''),
                    productName: getStringFromObject('product.value', stock, ''),
                },
                uom: {
                    uuid: getStringFromObject('productUom.key', stock, ''),
                    name: getStringFromObject('productUom.value', stock, ''),
                },
                cost: getStringFromObject('cost', stock, 0),
                salePrice: getStringFromObject('salePrice', stock, 0),
                mrp: getStringFromObject('mrp', stock, 0),
                quantity: getStringFromObject('quantity', stock, 0),
                uuid: getStringFromObject('uuid', stock, null),
                trackByBatch: getStringFromObject('productTrackByBatch', stock, false),
                sequence: stock.sequence || index,
                productGtinMapDto: getStringFromObject('productGtinMapDto', stock) || null,
            };
            if (temp.trackByBatch) {
                temp.description = getStringFromObject('batchName', stock, '');
                temp.expiry = getDateInYYYYMMDDFormat(new Date(getStringFromObject('expiryDate', stock, new Date())));
            }
            return temp;
        });
    } else {
        stockPickingLines.push({});
    }
    console.log('productTrackByBatch', stockPickingLines);
    return sortArrayOfObjectsByFieldValue(stockPickingLines, 'sequence');
};

export const UI_OBJECT = {
    date: new Date().getTime(),
    addStock: [{ expiry: getDateInYYYYMMDDFormat(new Date()), uomCategoryId: 0, productId: 0 }],
};

export const mapAddStockDtoToUiObject = (stock) => {
    let uiObject = cloneDeep(UI_OBJECT);
    console.log('hgaksdgfkajsgf', stock);
    if (isObjectValidAndNotEmpty(stock)) {
        uiObject = {
            ...uiObject,
            createDate: getStringFromObject('createDate', stock),
            createdBy: getStringFromObject('receivedBy', stock),
            reference: getStringFromObject('origin', stock, ''),
            uuid: getStringFromObject('uuid', stock, ''),
            location: {
                uuid: getStringFromObject('destLoc.key', stock, ''),
                name: getStringFromObject('destLoc.value', stock, ''),
            },
            status: getStringFromObject('status', stock, ''),
            addStock: mapStockPickingLineUiObject(getStringFromObject('stockPickingLinesDTOSet', stock, [])),
            date: getStringFromObject('date', stock) || null,
        };
    }
    return uiObject;
};
