import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import printJS from 'print-js';
import axios from 'axios/lib/axios';
import { hextorstr, KEYUTIL, KJUR, qz, RSVP, stob64 } from '@packages/nuacare-qz-tray';
import HandleBars from 'handlebars/dist/handlebars';

import {
    apiCatchBlockFunction,
    calculateAge,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    isValidDate,
    isValidFunction,
    roundedValueFixedToTwoDigits,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
// import { printPageSizes } from '../../constants/constants';
import { errorMessage, infoMessage } from '../../../redux/modules/message/message-actions';
import { APPLICATION_CONFIG_URL, COMMON_APPLICATION_CONFIG_URL, printPageSizes } from '../../../constants/constants';
import { formatAMPM, formatDateForDisplay, getDateInDDMMMYYYYFormat } from '../../../constants/DateUtil';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import numberToArabic from '../../../constants/EnglishToArabicNumeral';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { requestBase64 } from '../../../constants/imageUtils';

HandleBars.registerHelper('getTaxValue', (percent, price) => {
    console.log('21unboboidaonboinboboiasbdsad', percent, price);
    return `${valueToFixedTwoDigits(((NumberOf(percent) * NumberOf(price)) / 100))}`;
});
HandleBars.registerHelper('equals', (field, value) => field === value);

HandleBars.registerHelper('roundToTwoDigits', numberValue => `${roundedValueFixedToTwoDigits(numberValue)}`);

HandleBars.registerHelper('formatAmount', numberValue => `${valueToFixedTwoDigits(numberValue)}`);

HandleBars.registerHelper('numberToArabic', numberValue => `${numberToArabic(NumberOf(numberValue))}`);

// eslint-disable-next-line no-confusing-arrow
HandleBars.registerHelper('showCommaIfValueNotEmpty', stringValue => isStringNullOrUndefined(stringValue) ? '' : ',');

HandleBars.registerHelper('isDefined', stringValue => stringValue || stringValue === 0);

HandleBars.registerHelper('isGreaterThanZero', number => NumberOf(number) > 0);

HandleBars.registerHelper('getTime', dateObject => formatAMPM(new Date(dateObject)));

HandleBars.registerHelper('formatDate', dateObject => getDateInDDMMMYYYYFormat(new Date(dateObject)));

HandleBars.registerHelper('formatDateInDDMMYYYY', (dateObject) => {
    console.log('asd-a9sdi-asdia0ids-d', formatDateForDisplay(new Date(dateObject)));
    if (isValidDate(new Date(dateObject))) {
        return formatDateForDisplay(new Date(dateObject));
    }
    return '';
});

HandleBars.registerHelper('calculateAge', dateObject => calculateAge(new Date(dateObject)));

const promiseWithTimeout = (time, promise) => {
    let timeoutId;
    const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
            reject(new Error('Request timed out'));
        }, time);
    });
    return {
        promiseOrTimeout: Promise.race([promise, timeoutPromise]),
        timeoutId,
    };
};

class PrintPDF extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (this.props.print !== nextProps.print) {
            this.setState({
                browserPrint: false,
            }, () => {
                this.print(nextProps);
            });
        }
    }

    getHTMLAndReplaceValues = async (props) => {
        try {
            const response = await axios.get(`${props.url}`);
            let template = response.data;
            template = HandleBars.compile(template);
            const providerSignature = getStringFromObject('data.providerSignature', this.props);
            const printData = {
                ...props.data,
            };
            if (providerSignature) {
                const url =
                    // eslint-disable-next-line max-len
                    `/nuacare-core/rest/nuacare/v1/file/download?path=masters&name=${providerSignature}`;
                printData.providerSignature = await requestBase64(url);
            }
            return template({
                ...printData,
                today: new Date().getTime(),
                appConfigURL: APPLICATION_CONFIG_URL,
                commonAppConfigURL: COMMON_APPLICATION_CONFIG_URL,
            });
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
        return null;
    };

    getPdfPrintCallback = (pdfPrints, index, htmlPrints) => (
        (postPdfPrintCallback) => {
            if (isArrayValidAndNotEmpty(pdfPrints)) {
                console.log('printJsParams', pdfPrints, index, htmlPrints);
                printJS({
                    printable: pdfPrints[index],
                    type: 'pdf',
                    onPrintDialogClose: () => {
                        const nextIndex = index + 1;
                        if (nextIndex !== pdfPrints.length) {
                            console.log('printing once again', nextIndex);
                            this.getPdfPrintCallback(pdfPrints, nextIndex, htmlPrints)(postPdfPrintCallback);
                        } else if (isValidFunction(postPdfPrintCallback)) {
                            // Iframe variable from printJS is attacked to global scope
                            // If next postPdfPrintCallback is calling images print then iframe will be used of pdf print
                            // which will be removed after event loop completes and onload will never be triggered
                            // Hence delaying postPdfPrintCallback to next event loop cycle
                            Promise.resolve(true).then(() => {
                                postPdfPrintCallback(true);
                            });
                        }
                    },
                });
            } else if (isValidFunction(postPdfPrintCallback)) {
                postPdfPrintCallback();
            }
        }
    );

    getImagePrintCallback = (imagePrints, index) => (postPdfPrintCallback) => {
        if (isArrayValidAndNotEmpty(imagePrints)) {
            printJS({
                printable: imagePrints[index],
                type: 'image',
                onPrintDialogClose: () => {
                    const nextIndex = index + 1;
                    if (nextIndex !== imagePrints.length) {
                        console.log('printing once again', nextIndex);
                        this.getImagePrintCallback(imagePrints, nextIndex)(postPdfPrintCallback);
                    } else if (isValidFunction(postPdfPrintCallback)) {
                        postPdfPrintCallback();
                    }
                },
            });
        } else if (isValidFunction(postPdfPrintCallback)) {
            postPdfPrintCallback();
        }
    };

    openBrowserPrintAfterCheck = async (itemsToPrint) => {
        if (!this.state.browserPrint) {
            this.setState({
                browserPrint: true,
            }, () => {
                this.openBrowserPrint(itemsToPrint);
            });
        } else {
            console.error('Already a browser print initiated');
        }
    };

    openBrowserPrint = async (itemsToPrint) => {
        const {
            printerConfigs,
            dispatch,
        } = this.props;
        if (isArrayValidAndNotEmpty(itemsToPrint)) {
            let pdfPrints = [];
            let htmlPrints = [];
            let imagePrints = [];
            dispatch(showSpinner());
            for (let i = 0; i < itemsToPrint.length; i += 1) {
                const anItemToPrint = itemsToPrint[i];
                if (isObjectValidAndNotEmpty(anItemToPrint)) {
                    const url = getStringFromObject('url', anItemToPrint);
                    const fileExtension = getStringFromObject('type', anItemToPrint, 'pdf');
                    const printerConfig = getStringFromObject(anItemToPrint.name, printerConfigs);
                    if (printerConfig.type === 'html' || printerConfig.format === 'html' || anItemToPrint.type === 'html') {
                        // todo html print
                        console.log('as0i-0ias0d[kapdasd', url);
                        // eslint-disable-next-line
                        const html = await this.getHTMLAndReplaceValues(itemsToPrint[i]);
                        htmlPrints = htmlPrints.concat(html);
                    } else if (!fileExtension || fileExtension === 'pdf' || fileExtension === 'PDF') {
                        pdfPrints = pdfPrints.concat(url);
                    } else {
                        imagePrints = imagePrints.concat(url);
                    }
                }
            }
            dispatch(hideSpinner());
            if (isArrayValidAndNotEmpty(pdfPrints)) {
                let postPdfPrintCallback = null;
                if (isArrayValidAndNotEmpty(imagePrints)) {
                    let postImagePrintCallback = null;
                    if (isArrayValidAndNotEmpty(htmlPrints)) {
                        postImagePrintCallback = this.printIFrame(htmlPrints, 0);
                    } else {
                        postImagePrintCallback = this.props.onPrintComplete;
                    }
                    postPdfPrintCallback = () => {
                        this.getImagePrintCallback(imagePrints, 0)(postImagePrintCallback);
                    };
                } else if (isArrayValidAndNotEmpty(htmlPrints)) {
                    postPdfPrintCallback = this.printIFrame(htmlPrints, 0);
                } else {
                    postPdfPrintCallback = this.props.onPrintComplete;
                }
                this.getPdfPrintCallback(pdfPrints, 0, htmlPrints)(postPdfPrintCallback);
            } else if (isArrayValidAndNotEmpty(imagePrints)) {
                let postImagePrintCallback = null;
                if (isArrayValidAndNotEmpty(htmlPrints)) {
                    postImagePrintCallback = this.printIFrame(htmlPrints, 0);
                } else {
                    postImagePrintCallback = this.props.onPrintComplete;
                }
                this.getImagePrintCallback(imagePrints, 0)(postImagePrintCallback);
            } else if (isArrayValidAndNotEmpty(htmlPrints)) {
                this.printIFrame(htmlPrints, 0)();
            }
        }
    };

    testQzTray = async (itemsToPrint, printerConfigForPrint, printConnectConfig) => {
        // create 2 arrays.. ones with print configs and other without
        let itemsWithPrintConfig = [];
        let itemsWithoutPrintConfig = [];
        itemsToPrint.forEach((anItemToPrint) => {
            if (isObjectValidAndNotEmpty(getStringFromObject(anItemToPrint.name, printerConfigForPrint))) {
                itemsWithPrintConfig = itemsWithPrintConfig.concat(anItemToPrint);
            } else {
                itemsWithoutPrintConfig = itemsWithoutPrintConfig.concat(anItemToPrint);
            }
        });
        if (isArrayValidAndNotEmpty(itemsWithPrintConfig)) {
            const catchBlock = (e) => {
                console.error(e);
                this.props.dispatch(errorMessage('Failed To Auto Print'));
                this.openBrowserPrintAfterCheck(
                    [...itemsWithoutPrintConfig, ...itemsWithPrintConfig],
                );
                try {
                    // qz.websocket.disconnect();
                } catch (err) {
                    console.error(err);
                }
            };
            const {
                qzCert,
                qzKey,
            } = this.props;
            try {
                qz.security.setCertificatePromise((resolve) => {
                    resolve(qzCert);
                });
                qz.security.setSignaturePromise(toSign => (
                    (resolve, reject) => {
                        /* eslint-disable */
                        try {
                            var pk = KEYUTIL.getKey(qzKey);
                            var sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
                            sig.init(pk);
                            sig.updateString(toSign);
                            var hex = sig.sign();
                            console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                            resolve(stob64(hextorstr(hex)));
                        } catch (err) {
                            console.error(err);
                            reject(err);
                        }
                    }));
                console.log('qz initiated');
                if (!qz.websocket.isActive()) {
                    await qz.websocket.connect(printConnectConfig);
                }
                console.log('qz connected');
//                const printers = qz.printers.find();
//                console.info('qz printers found', printers);
                let chain = [];
                for (let i = 0; i < itemsWithPrintConfig.length; i += 1) {
                    // eslint-disable-next-line
                    // setup this chain link
                    const name = getStringFromObject(`[${i}].name`, itemsWithPrintConfig);
                    const url = getStringFromObject(`[${i}].url`, itemsWithPrintConfig);
                    const printerConfig = getStringFromObject(name, printerConfigForPrint);
                    let data = null;
                    const isHtml = printerConfig.format === 'html';
                    if (isHtml) {
                        // eslint-disable-next-line
                        const html = await this.getHTMLAndReplaceValues(itemsWithPrintConfig[i]);
                        data = [{
                            type: 'pixel',
                            format: 'html',
                            flavor: 'plain',
                            data: html,
                        }];
                        console.log('html data', data);
                    } else {
                        let port = ':8090';
                        if (process.env.NODE_ENV === 'production') {
                            port = '';
                        }
                        let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
                        if (this.props.printUrl) {
                            baseUrl = this.props.printUrl;
                            port = '';
                        }
                        const pdfUrlWithPort =
                            `${baseUrl}${port}${url}`;
                        data = [
                            {
                                type: 'pixel',
                                format: 'pdf',
                                flavor: 'file',
                                data: pdfUrlWithPort,
                            },
                        ];
                    }
                    const link = () => {
                        const pageSize = getStringFromObject('size', printerConfig) || 'A4';
                        const size = getStringFromObject(pageSize, printPageSizes) || printPageSizes.A4;
                        console.log('print stuff', printerConfig.name, printerConfig, size, pageSize);
                        let copies = getStringFromObject('copies', printerConfig) || 1;
                        const {
                            payerType,
                        } = this.props;
                        const copyStrategy = getStringFromObject('copyStrategy', printerConfig);
                        if (copyStrategy && copyStrategy === 'payerType') {
                            if (payerType === 'INSURANCE') {
                                copies = 2;
                            }
                        }
                        const config = qz.configs.create(
                            printerConfig.name,
                            {
                                // size,
                                colorType: 'blackwhite',
                                scaleContent: true,
                                density: 600,
                                copies,
                                orientation:
                                    (
                                        getStringFromObject('orientation', printerConfig) ||
                                        'portrait'
                                    ),
                                ...printerConfig,
                            },
                        );
                        console.log('qz configuring print: ', printerConfig.name);
                        return qz.print(config, data);
                    };
                    console.log('concat link', link, data);
                    chain = chain.concat(link);
                    // closure ensures this promise's concept of `i` doesn't change
                }
//                console.log('printerssssssss', printers);
                // is printer connected and found..
                const firstLink = new RSVP.Promise((r) => { r(); });

                let lastLink = null;
                console.log('linkssss', chain, firstLink, lastLink);
                chain.reduce((sequence, link) => {
                    lastLink = sequence.then(link).catch((e) => {
                        console.error('jha089hj0asda', e);
                        catchBlock(e);
                    });
                    return lastLink;
                }, firstLink);

                // this will be the very last link in the chain
                lastLink.then(() => {
                    // qz.websocket.disconnect();
                    if (isValidFunction(this.props.onPrintComplete)) {
                        this.props.onPrintComplete(true);
                    }
                });

            } catch (e) {
                catchBlock(e);
            }
        } else {
            this.openBrowserPrintAfterCheck(
                itemsWithoutPrintConfig,
            );
        }
    };

    printIFrame = (htmlPrint, index) => (
        () => {
            const hiddenFrame = document.getElementById('content');
            console.log('Inside update', hiddenFrame);
            hiddenFrame.contentWindow.printAndRemove = () => {
                console.log('loaded');
                hiddenFrame.contentWindow.print();
                setTimeout(() => {
                    console.log('clossssinngggg');
                    const nextIndex = index + 1;
                    if (nextIndex !== htmlPrint.length) {
                        console.log('printing once again', nextIndex);
                        this.printIFrame(htmlPrint, nextIndex)();
                    } else {
                        if (isValidFunction(this.props.onPrintComplete)) {
                            this.props.onPrintComplete(true);
                        }
                    }
                }, 100);
                // hiddenFrame.remove();
            };
            const doc = hiddenFrame.contentWindow.document.open(
                'text/html',
                'replace',
            );
            const htmlContent = `<!doctype html>
              <html>
              <body onload="printAndRemove();">
                 ${htmlPrint[index]}
              </body>
              </html>`;
            doc.write(htmlContent);
            doc.close();
        }
    );

    print = async (props) => {
        const {
            itemsToPrint,
            printerConfigs,
            printConnectConfig,
        } = props;
        let {
            autoPrintTimeout,
            dispatch,
        } = props;
        // const printerConfigForPrint = getStringFromObject(name, printerConfigs);
        if (isArrayValidAndNotEmpty(itemsToPrint)) {
            if(!autoPrintTimeout) {
                autoPrintTimeout = 1000;
            }
            this.props.dispatch(infoMessage('Trying to AutoPrint...'));
            const { promiseOrTimeout, timeoutId } =
              promiseWithTimeout(autoPrintTimeout, this.testQzTray(itemsToPrint, printerConfigs, printConnectConfig));
            try {
                console.log("SANDEEP ",autoPrintTimeout, new Date());
                const result = await promiseOrTimeout;
              } catch (error) {
                console.log("SANDEEP timedout", );
                console.log("SANDEEP ", new Date());
                this.openBrowserPrintAfterCheck([...itemsToPrint]);
              } finally {
                clearTimeout(timeoutId);
              }
        } else {
            dispatch(errorMessage('No Items To Print'));
        }
    };

    render() {
        const {
            printerConfigs,
            itemsToPrint,
            onPrintComplete,
        } = this.props;
        console.log('printing loggicc', onPrintComplete, itemsToPrint, printerConfigs);
        return (
            <div>
                <div id="pdf-print" />
                <div id="react-multi-html-print">
                    <iframe
                        test-id="bill-printout"
                        id="content"
                        style={{ visibility: 'hidden', height: 0, width: 0 }}
                        title="Bill Printout"
                        ref={(input) => {
                            this.iFrameInput = input;
                        }} //  eslint-disable-line react/jsx-no-bind
                    />
                </div>
            </div>
        );
    }
}

PrintPDF.propTypes = {
    itemsToPrint: PropTypes.array,
    printerConfigs: PropTypes.object,
    printConnectConfig: PropTypes.object,
    print: PropTypes.bool,
    printUrl: PropTypes.string,
    dispatch: PropTypes.func,
    onPrintComplete: PropTypes.func,
    qzCert: PropTypes.string,
    qzKey: PropTypes.string,
    payerType: PropTypes.string,
    autoPrintTimeout: PropTypes.integer,
};

PrintPDF.defaultProps = {
    itemsToPrint: [], // [ { url: 'pdf url', name: 'printer name' ]
    printerConfigs: {},
    printConnectConfig: {},
    print: false,
    printUrl: '',
    dispatch: () => {},
    onPrintComplete: () => {},
    qzCert: '',
    qzKey: '',
    payerType: '',
    autoPrintTimeout: 10000,
};

const mapStateToProps = state => ({
    printerConfigs: getStringFromObject('appConfiguration.printConfigs', state),
    autoPrintTimeout: getStringFromObject('appConfiguration.autoPrintTimeout', state),
    printUrl: getStringFromObject('appConfiguration.printUrl', state),
    qzCert: getStringFromObject('appConfiguration.qzCert', state),
    qzKey: getStringFromObject('appConfiguration.qzKey', state),
    printConnectConfig: getStringFromObject('appConfiguration.printConnectConfig', state),
});

export default connect(mapStateToProps)(PrintPDF);
