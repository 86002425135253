import sortBy from 'lodash.sortby';
import {
    CHANGE_DOCTOR_STATUS,
    DOCTOR_Q_LIST_FAILURE,
    DOCTOR_Q_LIST_SUCCESS,
    DOCTOR_TV_FETCH, INCOMPLETE_ENCOUNTER_FETCH_SUCCESS, OPEN_ENCOUNTER_FETCH_SUCCESS,
} from './doctorQueueList-actions';

const initialState = {
    qList: [],
    pastQList: [],
    tvDisplay: [],
    currentPatient: {},
    qListFetched: false,
    encounters: {},
};

// const sortTheQueue = queueMapperDtoList => sortBy(queueMapperDtoList, ['tokenTypePriority', 'tokenNumber']);
const sortTheQueue = queueMapperDtoList => sortBy(queueMapperDtoList, ['sortOrder']);

const doctorQListReducer = (state = initialState, action) => {
    let newState;
    console.log('new action for refund for pagw', action);
    switch (action.type) {
    case DOCTOR_Q_LIST_SUCCESS:
        newState = Object.assign(
            {},
            state,
            {
                queueStatus: action.data.queueStatus,
                doctorStatus: action.data.doctorStatus,
                queueUuid: action.data.queueUuid,
                qList: sortTheQueue(action.data.queueMapperDtoList),
                pastQList: sortTheQueue(action.data.pastQueueMapperDtoList || []),
                qListFetched: true,
            },
        );
        break;
    case DOCTOR_Q_LIST_FAILURE:
        newState = { ...state, qListFetched: true };
        break;
    case CHANGE_DOCTOR_STATUS:
        newState = { ...state, doctorStatus: action.newStatus };
        break;
    case DOCTOR_TV_FETCH:
        newState = Object.assign({}, state, { tvDisplay: action.data });
        break;
    case OPEN_ENCOUNTER_FETCH_SUCCESS:
    case INCOMPLETE_ENCOUNTER_FETCH_SUCCESS:
        newState = Object.assign({}, state, { encounters: action.data });
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default doctorQListReducer;
