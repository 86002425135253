import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PayerSettingContainer from '../containers/PayerSettingContainer/PayerSettingContainer';
import {
    insuranceSchemeViewList,
    payorList,
    payorProductCategory,
    policyViewList,
    sfdaGtin,
} from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const PayerListPage = React.lazy(() => import('../pages/PayerPages/PayerListPage'));
const PayerProductCategoryPage = React.lazy(() => import('../pages/PayerPages/PayerProductCategoryPage'));
const PolicyViewList = React.lazy(() => import('../pages/PayerPages/PolicyViewListPage'));
const InsuranceSchemeViewList = React.lazy(() => import('../pages/PayerPages/InsuranceSchemeViewListPage'));
const SfdaGtinMappingPage = React.lazy(() => import('../pages/PayerPages/SfdaGtinMappingPage'));

const PayerSettingRoutes = props => (
    <PayerSettingContainer {...props} >
        <Suspense fallback={<PageLoader />}>
            <Route path={payorList} exact component={PayerListPage} />
            <Route path={payorProductCategory} exact component={PayerProductCategoryPage} />
            <Route path={policyViewList} exact component={PolicyViewList} />
            <Route path={insuranceSchemeViewList} exact component={InsuranceSchemeViewList} />
            <Route path={sfdaGtin} exact component={SfdaGtinMappingPage} />
        </Suspense>
    </PayerSettingContainer>
);

export default PayerSettingRoutes;
