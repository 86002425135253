/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_PURCHASE_ORDER,
    CREATE_PURCHASE_ORDER_SUCCESS,
    FETCH_PURCHASE_ORDER_SUCCESS,
} from './purchaseOrder-actions';

const purchaseOrderReducer = (state = initialStates.purchaseOrder, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_PURCHASE_ORDER_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case CREATE_PURCHASE_ORDER_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case CLEAR_SELECTED_PURCHASE_ORDER:
        newState = Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default purchaseOrderReducer;
