import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import MESSAGES from '../../constants/messages';
import {
    ADD_ORDERS_FAILURE,
    ADD_ORDERS_SUCCESS,
    FETCH_ORDERS_QUEUE_LIST_FAILURE,
    FETCH_ORDERS_QUEUE_LIST_SUCCESS,
} from '../../redux/modules/ordersQueue/ordersQueue-actions';

export function* fetchOrdersQueueWorker(action) {
    try {
        const {
            api,
            page,
            size,
            sortDir,
            sortCol,
            orderTypeUuid,
            searchConstruct,
            showFulfilled,
            onlyCompleted,
            searchValue,
            specialCaseConstruct,
            unpaidSpecialCaseConstruct,
            includeList,
            sameProvider,
        } = action;
        const searchConstructToPost = {
            searchConstruct: searchConstruct || null,
            specialCaseConstruct: specialCaseConstruct || null,
            unpaidSpecialCaseConstruct: unpaidSpecialCaseConstruct || null,
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParams =
            `${api}?page=${page}&size=${size}&direction=${sortDir}&sortCol=${sortCol}&orderTypeUuid=${orderTypeUuid
            }&fulfilled=${showFulfilled}&onlyFulfilled=${onlyCompleted || false}&anyIdentifier=${searchValue
            }&includeList=${includeList.join(',')}&sameProvider=${Boolean(sameProvider)}`;
        const response = yield call(
            retriable().post,
            apiWithQueryParams,
            searchConstructToPost,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put({
            type: FETCH_ORDERS_QUEUE_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        yield put({ type: FETCH_ORDERS_QUEUE_LIST_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* addOrdersWorker(action) {
    try {
        const {
            api,
            observations,
            visitUuid,
            patientUuid,
            encounterTypeUuid,
            providerUuid,
            successCallBack,
            patientRefuse,
            orderUuid,
            patientSensitive,
        } = action;
        const apiWithQueryParams =
        // eslint-disable-next-line max-len
            `${api}?visitUuid=${visitUuid}&patientUuid=${patientUuid}&encounterTypeUuid=${encounterTypeUuid}&providerUuid=${providerUuid}&patientRefuse=${patientRefuse || false}&patientSensitive=${patientSensitive || false}&orderUuid=${orderUuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParams, observations);
        yield put({
            type: ADD_ORDERS_SUCCESS,
            data: response.data,
        });
        yield put({ type: SUCCESS_MESSAGE, message: MESSAGES.ORDER.FULFILLED_SUCCESSFULLY });
        // yield put({
        //     type: FETCH_ORDERS_QUEUE_LIST_REQUEST,
        //     api: API.ORDERS_QUEUE.FETCH_QUEUE,
        //     ...paginationDetails,
        // });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(successCallBack)) {
            yield call(successCallBack);
        }
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: ADD_ORDERS_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
