import { rightToLeftLanguages } from '../../../constants/constants';

export const SELECT_LANGUAGE = '@@message/SELECT_LANGUAGE';

export const selectLanguage = (language) => {
    if (rightToLeftLanguages.includes(language)) {
        document.body.dir = 'rtl';
    } else {
        document.body.dir = 'ltr';
    }
    return {
        type: SELECT_LANGUAGE,
        language,
    };
};
