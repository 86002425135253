import API from '../../../constants/api';

export const FETCH_APP_CONFIG_REQUEST = '@@appConfig/FETCH_APP_CONFIG_REQUEST';
export const FETCH_APP_CONFIG_SUCCESS = '@@appConfig/FETCH_APP_CONFIG_SUCCESS';
export const FETCH_APP_CONFIG_FAIL = '@@appConfig/FETCH_APP_CONFIG_FAIL';

export const APP_SWITCHED = '@@appConfig/APP_SWITCHED';

export const notifyAppSwitch = title => ({
    type: APP_SWITCHED,
    title,
});

export const fetchAppConfig = () => ({
    type: FETCH_APP_CONFIG_REQUEST,
    api: API.APP_CONFIGURATION_JSON,
});
