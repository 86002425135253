import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Route } from 'react-router';

import {
    editPaymentAdvice,
    editPaySlipBatches,
    newPaySlipBatches,
    paymentAdvices,
    payroll,
    payrollReports,
    paySlipBatches,
    payslips,
} from '../../constants/constants';

const PayrollReportsPage = React.lazy(() => import('./PayrollReportsPage'));
const PayslipBatchesPage = React.lazy(() => import('./PayslipBatchesPage'));
const NewPayslipBatchesPage = React.lazy(() => import('./NewPayslipBatchesPage'));
const PaymentAdvicesPage = React.lazy(() => import('./PaymentAdvicesPage'));
const PaySlipsPage = React.lazy(() => import('./PaySlipsPage'));
const HrPayRollContainer = React.lazy(() => import('../../containers/HrPayRollContainer/HrPayRollContainer'));
const PayrollPage = React.lazy(() => import('../PayrollPage/PayrollPage'));
const EditPayslipBatchesPage = React.lazy(() => import('./EditPayslipBatchPage'));
const EditPaymentAdvicePage = React.lazy(() => import('./EditPaymentAdvicePage'));

const PayrollRouteContainer = props => (
    <React.Fragment>
        <Route path={payroll} exact component={PayrollPage} />
        <HrPayRollContainer history={props.history}>
            <Route path={paySlipBatches} exact component={PayslipBatchesPage} />
            <Route path={newPaySlipBatches} exact component={NewPayslipBatchesPage} />
            <Route path={`${editPaySlipBatches}/:uuid`} exact component={EditPayslipBatchesPage} />
            <Route path={payrollReports} exact component={PayrollReportsPage} />
            <Route path={paymentAdvices} exact component={PaymentAdvicesPage} />
            <Route path={payslips} exact component={PaySlipsPage} />
            <Route path={`${editPaymentAdvice}/:uuid`} exact component={EditPaymentAdvicePage} />
        </HrPayRollContainer>
    </React.Fragment>
);

PayrollRouteContainer.propTypes = {
    history: PropTypes.object,
};

PayrollRouteContainer.defaultProps = {
    history: {},
};

export default withRouter(PayrollRouteContainer);
