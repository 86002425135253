/**
 * Created by sachinprakash on 20/6/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { change, Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import ReduxFormText from '../FormFieldComponents/ReduxFormText/ReduxFormText';
import ReduxFormTextField from '../FormFieldComponents/ReduxFormTextField/ReduxFormTextField';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';

class CashCloseCashCollection extends React.PureComponent {
    handleQuantityChange = (member, index) => (value) => {
        const { fields, dispatch, formName } = this.props;
        const denomination = NumberOf(fields.get(index).denomination);
        dispatch(change(formName, `${member}.amount`, denomination * value));
    };

    validateQuantity = value => ((`${value}`).match(/^[\d]*$/g) ? undefined : 'only +ve integers allowed');

    render() {
        const {
            fields,
            disabled,
            meta,
        } = this.props;
        console.log('vinaydabhfajkda', meta);
        const error = getStringFromObject('error', meta);
        return (
            <React.Fragment>
                {
                    error &&
                        <Grid container justify="center" className="mt-0-5">
                            <span className="error-text">{error}</span>
                        </Grid>
                }
                {
                    fields.map((member, index) => (
                        <Grid container justify="space-around" key={member} className="mt-1">
                            <Grid
                                item
                                sm={1}
                                md={1}
                                lg={1}
                                style={{ paddingTop: '5px', paddingLeft: '5px' }}
                            >
                                <Field
                                    name={`${member}.denomination`}
                                    component={ReduxFormText}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={3}>
                                <Field
                                    component={ReduxFormTextField}
                                    name={`${member}.quantity`}
                                    label="Qty"
                                    onChangeHandlers={['handleQuantityChange']}
                                    actionHandlers={{
                                        handleQuantityChange: this.handleQuantityChange(member, index),
                                    }}
                                    validate={[this.validateQuantity]}
                                    type="number"
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={3}>
                                <Field
                                    component={ReduxFormTextField}
                                    name={`${member}.amount`}
                                    disabled
                                    label="Amount"
                                />
                            </Grid>
                        </Grid>
                    ))
                }
            </React.Fragment>
        );
    }
}

CashCloseCashCollection.propTypes = {
    fields: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

CashCloseCashCollection.defaultProps = {
    disabled: false,
};

export default CashCloseCashCollection;

