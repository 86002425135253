/**
 * Created by sachinprakash on 15/7/19.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
    isValidNumber,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    ADD_OR_UPDATE_UOM_FAILURE,
    ADD_OR_UPDATE_UOM_SUCCESS,
    FETCH_UOM_FAILURE,
    FETCH_UOM_SUCCESS,
} from '../../redux/modules/LabSettings/labTest-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

export const config = {
    headers: { 'Content-Type': 'application/json' },
};

export function* fetchPagesOfUomWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_UOM_SUCCESS,
            data: response.data,
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.PANEL.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_UOM_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* addOrUpdateWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const { payload, api } = action;
        let apiWithParam = api;
        if (isValidNumber(getStringFromObject('id', payload, undefined))) {
            apiWithParam = `${api}/${getStringFromObject('id', payload, null)}`;
        }
        let response = {};
        if (isValidNumber(getStringFromObject('id', payload, undefined))) {
            response = yield call(retriable().put, apiWithParam, payload, config);
        } else response = yield call(retriable().post, apiWithParam, payload, config);
        yield put({
            type: ADD_OR_UPDATE_UOM_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallBack)) {
            action.successCallBack();
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        yield put({
            type: SUCCESS_MESSAGE,
            message: messages.TESTS.ADD_OR_UPDATE_SUCCESS,
        });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.TESTS.ADD_OR_UPDATE_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: ADD_OR_UPDATE_UOM_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
