import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const ReactToggle = ({ input, disabled }) => (
    <Toggle
        checked={input.value}
        onChange={input.onChange}
        name={input.name}
        disabled={disabled}
    />
);

ReactToggle.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
};

React.defaultProps = {
    disabled: false,
};

export default ReactToggle;
