/**
 * Created by vinay on 16/1/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import API from '../../../constants/api';
import {
    getUrlWithApiParams,
    isValidFunction,
} from '../../../constants/CommonUtil';
// import UploadFile from '../FormFieldComponents/UploadFiles/UploadFile';
import { required } from '../../../constants/FormValidations';
import ActionButton from '../../ActionButton/ActionButton';
import {
    APPLICATION_CONFIG_URL,
    PAID_ON_ACCOUNT_EDIT_PRIV,
    PAID_ON_ACCOUNT_REFUND_PRIV,
    PAYMENT_METHODS, PAYMENT_METHODS_MAP,
} from '../../../constants/constants';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import { commonGetApiRequest, commonPostApiRequest } from '../../../redux/modules/common/common-actions';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import { getPayload, getUiObject } from './PaidOnAccountUtil';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import {
    clearSelectedAccountPayment,
    CREATE_PAID_ON_ACCOUNT_SUCCESS,
    FETCH_PAID_ON_ACCOUNT_SUCCESS,
} from '../../../redux/modules/paidOnAccount/paidOnAccount-actions';
import PaidOnAccountRefundDialog from './PaidOnAccountRefundDialog';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'PaidOnAccountForm';

const style = theme => ({
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    paper: {
        background: '#fafafa',
    },
});

/**
 @deprecated as paid on account entity is deprecated
 */
class PaidOnAccountDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            print: false,
            journalEntryPrintData: null,
            printJournal: false,
            refund: false,
        };
    }

    componentDidMount() {
        const {
            paidOnAccountUUid,
            paidOnAccountId,
        } = this.props;
        if (paidOnAccountUUid || NumberOf(paidOnAccountId) > 0) {
            this.getPaidOnAccountByUuid(paidOnAccountUUid, paidOnAccountId);
        }
    }

    componentDidUpdate(prevProps) {
        if (!deepEqual(prevProps.selectedPayment, this.props.selectedPayment)) {
            this.props.setFieldValue('readOnly', true);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountPayment());
    }

    getPaidOnAccountByUuid = (uuid, id) => {
        const {
            dispatch,
        } = this.props;
        const params = {};
        if (uuid) params.uuid = uuid; else params.id = id;
        dispatch(commonGetApiRequest(getUrlWithApiParams(API.PAID_ON_ACCOUNT.FIND, params), {
            successAction: FETCH_PAID_ON_ACCOUNT_SUCCESS,
        }));
    };

    initializeComponent = () => {
        const {
            values,
        } = this.props;
        const paidOnAccountUUid = getStringFromObject('uuid', values);
        if (paidOnAccountUUid) {
            this.getPaidOnAccountByUuid(paidOnAccountUUid);
        }
    };

    fetchJournalEntriesAndPrint = () => {
        const { values, dispatch } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', values);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    handleCancel = () => {
        const { dispatch, dirty, values } = this.props;
        const readOnly = getStringFromObject('readOnly', values);
        if (!readOnly && dirty) {
            dispatch(displayWarning(
                'You have unsaved changes, select action to confirm !!',
                this.handleSaveConfirm,
                undefined,
                this.props.handleClose,
                undefined,
                'Save Changes',
                'Cancel & Exit',
                'Cancel',
            ));
        } else {
            this.props.handleClose();
        }
    };

    handleReset = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'Any unsaved changes will be lost, are you sure want to continue ?',
            this.props.handleReset,
        ));
    };

    handleSave = () => {
        const { values, dispatch } = this.props;
        const uuid = getStringFromObject('uuid', values);
        if (uuid) {
            dispatch(displayWarning(
                'You are about to edit Pay On Account, Are you sure want to continue ?',
                this.handleSaveConfirm,
            ));
        } else {
            this.handleSaveConfirm();
        }
    };

    handleSaveConfirm = () => {
        const { submitForm } = this.props;
        submitForm();
    };

    printHandler = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    handleOnPayeeSelect = (selected) => {
        console.log(selected);
        const subCompany = getStringFromObject('subCompany', selected, '');
        this.props.setFieldValue('subCompany', subCompany);
        this.props.setFieldValue('account', null);
    };

    handlEdit = () => {
        this.props.setFieldValue('readOnly', false);
    };

    handleRefund = () => {
        this.setState({ refund: true });
    };

    handleCloseRefund = () => {
        this.setState({ refund: false }, this.initializeComponent);
    };

    render() {
        const {
            classes,
            values,
            dirty,
            isValid,
            company,
            currency,
        } = this.props;
        const {
            journalEntryPrintData,
            printJournal,
            refund,
        } = this.state;
        const readOnly = getStringFromObject('readOnly', values);
        const isRefund = getStringFromObject('type', values) === 'account_supplier_refund';
        const name = getStringFromObject('name', values);
        const paymentMethod = getStringFromObject('paymentMode.value', values);
        const subCompany = getStringFromObject('subCompany', values, '');
        const reconcileStatus = getStringFromObject('reconsileStatus', values);
        const canBeEdited = readOnly && !isRefund && reconcileStatus === 'UNRECONCILLED';
        const canBeRefunded = readOnly && !isRefund && (reconcileStatus === 'PARTIAL' || reconcileStatus === 'UNRECONCILLED');
        const isPaymentByCheque = paymentMethod === 'CHEQUE';
        const isPaymentByBank = paymentMethod === PAYMENT_METHODS_MAP.BANKTRANSFER.value;
        const printData = {
            ...values,
            company,
            currency,
            paymentMethod,
            isRefund,
            isPaymentByCheque,
            isPaymentByBank,
        };
        console.log('agfjahfjka', this.props, subCompany);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    {isRefund ? 'Pay On Account Refund' : 'Pay On Account'}
                                    {name ? '('.concat(name).concat(')') : ''}
                                </div>
                                <Close className="cursor-pointer" onClick={this.handleCancel} test-id="expense-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" spacing={8}>
                                <Grid item lg={11} md={10} sm={9}>
                                    <Grid container justify="flex-start" spacing={8}>
                                        <Grid item lg={3} md={6} sm={6}>
                                            <Field
                                                testId="partner_name"
                                                name="partner"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'name',
                                                    value: 'uuid',
                                                }}
                                                label="Supplier"
                                                required
                                                placeholder="Choose a supplier"
                                                isDisabled={readOnly}
                                                autocomplete
                                                onSelectHandlers={['onSelectPayee']}
                                                actionHandlers={{
                                                    onSelectPayee: this.handleOnPayeeSelect,
                                                }}
                                                validate={required}
                                                dataSourceApi={API.SEARCH.SUPPLIER}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6}>
                                            <Field
                                                testId="poa_assetAccount"
                                                name="account"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'value',
                                                    value: 'key',
                                                }}
                                                label="Credit From Account"
                                                placeholder="Choose credit account"
                                                required
                                                autocomplete
                                                validate={required}
                                                isDisabled={readOnly}
                                                key={subCompany}
                                                dataSourceApi={
                                                    `${API.SEARCH.ACCOUNTS
                                                    }?type=liquidity&typeIgnoreList=consolidation,view&
                                            accountType=&subCompany=${subCompany || ''}&searchString=`
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="mt-2" justify="flex-start" spacing={24}>
                                        <Grid item lg={3} md={3} sm={3}>
                                            <Field
                                                testId="paymentDate"
                                                name="date"
                                                component={DateInput}
                                                label="Payment Date"
                                                required
                                                placeholder="Payment Date"
                                                disabled={readOnly}
                                                validate={required}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} sm={3}>
                                            <Field
                                                testId="paymentmethod"
                                                name="paymentMode"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'label',
                                                    value: 'value',
                                                }}
                                                label="Payment Method"
                                                placeholder="Payment Method"
                                                required
                                                validate={required}
                                                isDisabled={readOnly}
                                                options={PAYMENT_METHODS}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={1} md={2} sm={3}>
                                    <Grid container justify="flex-end" direction="column">
                                        {
                                            canBeEdited && checkIfPrivilegeExistsForUser(PAID_ON_ACCOUNT_EDIT_PRIV) &&
                                            <ActionButton
                                                test-id="poa-edit"
                                                disableRipple
                                                onClick={this.handlEdit}
                                            >
                                                Edit
                                            </ActionButton>
                                        }
                                        {
                                            canBeRefunded && checkIfPrivilegeExistsForUser(PAID_ON_ACCOUNT_REFUND_PRIV) &&
                                            <ActionButton
                                                test-id="poa-edit"
                                                disableRipple
                                                onClick={this.handleRefund}
                                                className="mt-2"
                                            >
                                                Refund
                                            </ActionButton>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                (isPaymentByCheque || isPaymentByBank) &&
                                <Grid container className="mt-2">
                                    <Grid item lg={2} sm={2} md={2}>
                                        <Field
                                            testId="paymentDetails.refNUmber"
                                            name="paymentDetails.refNumber"
                                            component={FormikTextField}
                                            label={isPaymentByCheque ? 'Cheque Number' : 'Reference Number'}
                                            required
                                            type="number"
                                            placeholder={isPaymentByCheque ? 'Cheque Number' : 'Reference Number'}
                                            disabled={readOnly}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} md={2}>
                                        <Field
                                            testId="paymentDetails.bankName"
                                            name="paymentDetails.bankName"
                                            component={FormikTextField}
                                            label="Bank Name"
                                            required
                                            type="text"
                                            placeholder="Bank Name"
                                            disabled={readOnly}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} md={2}>
                                        <Field
                                            testId="paymentDetails.date"
                                            name="paymentDetails.date"
                                            component={DateInput}
                                            label={isPaymentByCheque ? 'Cheque Date' : 'Transfer Date'}
                                            required
                                            placeholder={isPaymentByCheque ? 'Cheque Date' : 'Transfer Date'}
                                            disabled={readOnly}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            validate={required}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container className="mt-2" justify="space-between">
                                <Grid item lg={3} md={3} sm={4}>
                                    <Field
                                        testId="amount"
                                        name="amountAdvance"
                                        component={FormikTextField}
                                        label="Amount"
                                        type="number"
                                        fullWidth
                                        min={1}
                                        required
                                        validate={required}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                {
                                    readOnly && name && !isRefund &&
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            testId="amountResidue"
                                            name="amountResidue"
                                            component={FormikTextField}
                                            label="Residual Amount"
                                            type="number"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid container className="mt-2" justify="space-between">
                                <Grid item lg={3} md={3} sm={4}>
                                    <Field
                                        testId="memo"
                                        name="notes"
                                        component={FormikTextField}
                                        label="Notes"
                                        multiline
                                        rows={3}
                                        rowsMax={3}
                                        fullWidth
                                        disabled={readOnly}
                                    />
                                </Grid>
                                {
                                    name &&
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Grid container justify="space-between" direction="column" spacing={24}>
                                            <Grid item>
                                                <Field
                                                    testId="dateCreated"
                                                    name="dateCreated"
                                                    component={DateInput}
                                                    label="Date Created"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    testId="creator"
                                                    name="creator"
                                                    component={FormikTextField}
                                                    label="Created By"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="cancel-expense"
                                disableRipple
                                primary={false}
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </ActionButton>
                            {
                                !readOnly &&
                                    <React.Fragment>
                                        <ActionButton
                                            test-id="reset-expense"
                                            disableRipple
                                            disabled={!dirty}
                                            onClick={this.handleReset}
                                        >
                                            Reset
                                        </ActionButton>
                                        <ActionButton
                                            test-id="expense-confirm"
                                            disableRipple
                                            onClick={this.handleSave}
                                            disabled={!dirty && !isValid}
                                            className="ml0-5"
                                        >
                                            Confirm
                                        </ActionButton>
                                    </React.Fragment>
                            }
                            {
                                readOnly &&
                                <React.Fragment>
                                    <ActionButton
                                        test-id="expense-print"
                                        primary
                                        onClick={this.printHandler}
                                        disableRipple
                                    >
                                        Print
                                    </ActionButton>
                                    <ActionButton
                                        test-id="expense-journal-print"
                                        primary
                                        onClick={this.fetchJournalEntriesAndPrint}
                                        disableRipple
                                        className="ml0-5"
                                    >
                                        Print Journal Entries
                                    </ActionButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                    </Dialog>
                    <Print
                        data={printData}
                        print={this.state.print}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/PaidOnAccount.html`}
                    />
                    <Print
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                        data={journalEntryPrintData}
                        print={printJournal}
                    />
                </form>
                {
                    refund &&
                        <PaidOnAccountRefundDialog
                            selectedPayment={values}
                            handleClose={this.handleCloseRefund}
                        />
                }
            </React.Fragment>
        );
    }
}

PaidOnAccountDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    currency: PropTypes.string,
    company: PropTypes.string,
    paidOnAccountUUid: PropTypes.string,
    paidOnAccountId: PropTypes.number,
    setFieldValue: PropTypes.func.isRequired,
    // resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object,
    selectedPayment: PropTypes.object,
};

PaidOnAccountDialog.defaultProps = {
    paidOnAccountUUid: null,
    paidOnAccountId: null,
    currency: '',
    company: '',
    values: {},
    selectedPayment: null,
};

const mapStateToProps = state => ({
    currency: getStringFromObject('appConfiguration.currency', state, ''),
    company: getStringFromObject('appConfiguration.companyName', state, ''),
    selectedPayment: getStringFromObject('paidOnAccount.selectedPaidOnAccount', state, null),
});

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('ahfajflafa submit', values);
    formikBag.setSubmitting(true);
    const callBackFunction = getStringFromObject('callBackFunction', values);
    const payload = getPayload(values);
    console.log('ahfajflafa payload', payload);
    props.dispatch(commonPostApiRequest(API.PAID_ON_ACCOUNT.BASE, {
        payload,
        successCallback: (r) => {
            if (isValidFunction(callBackFunction)) {
                callBackFunction(r);
            }
            formikBag.setSubmitting(false);
        },
        successAction: CREATE_PAID_ON_ACCOUNT_SUCCESS,
        successMessage: 'Pay On Account successfully created',
        failureMessage: 'Failed to create Pay On Account',
        failureCallBack: () => formikBag.setSubmitting(false),
    }));
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => getUiObject(getStringFromObject('selectedPayment', props)),
    displayName: formName,
    handleSubmit: handleSubmitForm,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
})(withStyles(style)(PaidOnAccountDialog)));
