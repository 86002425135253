import React from 'react';
import { Route } from 'react-router';
import { autoTextCategorySettings, autoTextSettings } from '../constants/constants';
import NotificationSettingContainer from '../containers/NotificationSettingContainer/NotificationSettingContainer';

const AutoTextPage = React.lazy(() => import('../pages/AutoTextListPage/AutoTextListPage'));
const AutoTextCategoryPage = React.lazy(() => import('../pages/AutoTextCategoryPage/AutoTextCategoryPage'));

const AutoTextSettingSettingRoutes = props => (
    <NotificationSettingContainer {...props} fullWidth>
        <Route path={autoTextSettings} exact component={AutoTextPage} />
        <Route path={autoTextCategorySettings} exact component={AutoTextCategoryPage} />
    </NotificationSettingContainer>
);

export default AutoTextSettingSettingRoutes;
