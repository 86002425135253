import { takeLatest } from 'redux-saga/effects';
import { WORKFLOW_SAVE_REQUEST, WORKFLOW_FETCH_REQUEST } from '../../redux/modules/workflow/workflow-actions';
import {
    workflowSaveRequestWorkerSaga,
    workflowFetchRequestWorkerSaga,
} from './workflowWorkerSaga';

export function* workflowSaveRequestSaga() {
    yield takeLatest(WORKFLOW_SAVE_REQUEST, workflowSaveRequestWorkerSaga);
}

export function* workflowFetchRequestSaga() {
    yield takeLatest(WORKFLOW_FETCH_REQUEST, workflowFetchRequestWorkerSaga);
}
