import {
    discountCodeTypes,
    // eslint-disable-next-line
} from '../../../containers/RegistrationAppComponents/QuickRegistration/QuickRegBillingSummary/QuickRegBillingSummaryUtil';

export const generalSettings = {
    NUMBER_OF_REFERRALS: 'NUMBER_OF_REFERRALS',
    PRICE_EDITING_ALLOWED: 'PRICE_EDITING_ALLOWED',
    PRICE_EDITING_ALLOWED_FOR_ZERO_AMOUNT: 'PRICE_EDITING_ALLOWED_FOR_ZERO_AMOUNT',
};
export const generalSettingsDefaultValues = {
    NUMBER_OF_REFERRALS: 0,
    PRICE_EDITING_ALLOWED: false,
    PRICE_EDITING_ALLOWED_FOR_ZERO_AMOUNT: false,
};
export const generalSettingsLabels = {
    NUMBER_OF_REFERRALS: 'Number Of Referrals',
    PRICE_EDITING_ALLOWED: 'Price editing allowed?',
    PRICE_EDITING_ALLOWED_FOR_ZERO_AMOUNT: 'Price editing allowed for zero amount',
};

export const discountTypesForSettings = {
    passCodeForPartialPayment: 'PASS_CODE_FOR_PARTIAL_PAYMENT',
    passCodeForDiscount: 'PASS_CODE_FOR_DISCOUNT',
};

export const discountCodesType = {
    FIXED_DISCOUNT_FRONT_DESK: {
        label: 'Fixed Discount Front Desk',
        discountCodeType: discountCodeTypes.FIXED,
        value: 'FIXED_DISCOUNT_FRONT_DESK',
        privilege: ['front.desk.discount.setting'],
    },
    FLEXIBLE_DISCOUNT_FRONT_DESK: {
        label: 'Flexible Discount Front Desk',
        discountCodeType: discountCodeTypes.FLEXIBLE,
        value: 'FLEXIBLE_DISCOUNT_FRONT_DESK',
        privilege: ['front.desk.discount.setting'],
    },
    FIXED_DISCOUNT_PHARMACY: {
        label: 'Fixed Discount Pharmacy',
        discountCodeType: discountCodeTypes.FIXED,
        value: 'FIXED_DISCOUNT_PHARMACY',
        privilege: ['pharmacy.discount.setting'],
    },
    FLEXIBLE_DISCOUNT_PHARMACY: {
        label: 'Flexible Discount Pharmacy',
        discountCodeType: discountCodeTypes.FLEXIBLE,
        value: 'FLEXIBLE_DISCOUNT_PHARMACY',
        privilege: ['pharmacy.discount.setting'],
    },
};

export const discountCodeFormFields = {
    CODE: 'code',
    DISCOUNT_PERCENTAGE: 'discountPercentage',
    MAX_DISCOUNT_PERCENTAGE: 'maxDiscountPercentage',
    DISCOUNT_AMOUNT: 'discountAmount',
    MAX_DISCOUNT_AMOUNT: 'maxDiscountAmount',
    DISCOUNT_CODE_TYPE: 'discountCodeType',
    ACTIVE: 'active',
};
