import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton/index';
import Clear from '@material-ui/icons/Clear';
import { GLOBAL_PROPERTIES } from '../../../../../constants/constants';
import { encounterStatusMainFiltersLabels, initialEncounterStatusValue } from './ConsultationStatusFilterUtil';
import ReduxFormCheckbox from '../../../../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { getStringFromObject } from '../../../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../../../constants/nullCheckUtils';

class ConsultationStatusFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleState: {},
        };
    }

    render() {
        const {
            sampleState,
        } = this.state;
        const {
            encounterStatusBitHelper,
            consultationStatusFilter,
            onSelectAConsultationFilter,
        } = this.props;
        console.log('asda90uda-0sdasa', consultationStatusFilter);
        console.log(encounterStatusBitHelper, sampleState);
        if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
            return Object.keys(encounterStatusBitHelper).map((anOrderType) => {
                console.log('asd0aa0s9d', anOrderType);
                return (
                    <div key={anOrderType} className="flexChildren">
                        <h4 style={{ width: '10%' }}>{anOrderType}</h4>
                        {
                            Object.keys(encounterStatusMainFiltersLabels).map(aFilter => (
                                <ReduxFormCheckbox
                                    key={aFilter}
                                    label={encounterStatusMainFiltersLabels[aFilter]}
                                    input={{
                                        value: consultationStatusFilter[anOrderType][aFilter],
                                        onChange: () => {
                                            onSelectAConsultationFilter(anOrderType)({
                                                ...consultationStatusFilter[anOrderType],
                                                [aFilter]: !consultationStatusFilter[anOrderType][aFilter],
                                            });
                                        },
                                    }}
                                />
                            ))
                        }
                        <IconButton
                            onClick={() => {
                                onSelectAConsultationFilter(anOrderType)({
                                    ...initialEncounterStatusValue,
                                });
                            }}
                        >
                            <Clear />
                        </IconButton>
                    </div>
                );
            });
        }
        return null;
    }
}

ConsultationStatusFilter.propTypes = {
    encounterStatusBitHelper: PropTypes.object,
    consultationStatusFilter: PropTypes.object,
    onSelectAConsultationFilter: PropTypes.func,
};

ConsultationStatusFilter.defaultProps = {
    encounterStatusBitHelper: {},
    consultationStatusFilter: {},
    onSelectAConsultationFilter: () => {},
};

const mapStateToProps = state => ({
    encounterStatusBitHelper:
        getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP}`, state),
});

export default connect(mapStateToProps)(ConsultationStatusFilter);

