import cloneDeep from 'clone-deep';
import api from '../../../constants/api';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { DATE_FILTERS } from '../../Common/ReportDateFilterUtil';
import { bundleSearchFieldFilters, bundleTableFilter } from '../ClaimBundles/ClaimBundlesUtils';
import {
    generateConstructFromFilterColumns,
    generateSearchFilterConstruct,
} from '../../GenericFilterComponent/GenericFilterComponentUtil';
import { COMBINEMODE, getCombined } from '../../../sagas/util';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { insurancePayerTypes } from '../AddNewPayer/AddNewPayerUtil';
import { subCompanies } from '../../../constants/ERPConstants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const claimStates = {
    DRAFT: 'DRAFT',
    REVERTED: 'REVERTED',
    SUBMITTED: 'SUBMITTED',
    CONFIRMED: 'CONFIRMED',
    PAID: 'PAID',
    BUNDLED: 'BUNDLED',
    ARCHIVED: 'ARCHIVED',
    CANCELLED: 'CANCELLED',
    MERGED: 'MERGED',
};

export const claimPushStates = {
    NA: 'NA',
    RE_PROCESS: 'RE_PROCESS',
    CONFIRMED: 'CONFIRMED',
    PROCESSING: 'PROCESSING',
    REJECTED: 'REJECTED',
    COMPLETED: 'COMPLETED',
    ERRORED: 'ERRORED',
};

export const claimTypes = {
    PROFESSIONAL: {
        label: 'Professional',
        value: 'professional',
    },
    PHARMACY: {
        label: 'Pharmacy',
        value: 'pharmacy',
    },
    ORAL: {
        label: 'Oral',
        value: 'oral',
    },
    VISION: {
        label: 'Vision',
        value: 'vision',
    },
};

export const claimTableFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'claim.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: false,
            maxDateField: 'claim.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'claim.claimDate',
                filterOperation: '>=',
            },
        },
        {
            name: 'claim.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            additionalFieldToClear: ['claim.startDate'],
            minDateField: 'claim.startDate',
            defaultValueField: 'claim.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'claim.claimDate',
                filterOperation: '<=',
            },
        },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'claim.claimState',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(claimStates).map(aClaimState => ({
                key: aClaimState,
                value: aClaimState,
            })),
            searchConstructFields: {
                name: 'claim.claimState',
                filterOperation: '=',
            },
        },
        {
            name: 'insurance_payer.type',
            type: 'select',
            label: 'Payer Type',
            valueField: 'key',
            disableClear: false,
            options: [
                insurancePayerTypes.COMPANY,
                insurancePayerTypes.INSURANCE,
                insurancePayerTypes.CORPORATE,
            ].map(aPayerType => ({
                key: aPayerType.value,
                value: aPayerType.label,
            })),
            searchConstructFields: {
                name: 'insurance_payer.type',
                filterOperation: '=',
            },
        },
        {
            name: 'insurance_payer.uuid',
            type: 'autocomplete',
            label: 'TPA',
            valueField: 'key',
            disableClear: false,
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=COMPANY&payerType=CORPORATE&payerType=INSURANCE&searchString=`,
            searchConstructFields: {
                name: 'insurance_payer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'sub_payer.uuid',
            type: 'autocomplete',
            label: 'Sub Insurer',
            valueField: 'key',
            disableClear: false,
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=SUBINSURANCE&searchString=`,
            searchConstructFields: {
                name: 'sub_payer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'policy_payer.uuid',
            type: 'autocomplete',
            label: 'Policy',
            valueField: 'key',
            disableClear: false,
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=POLICY&searchString=`,
            searchConstructFields: {
                name: 'policy_payer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'provider.uuid',
            type: 'autocomplete',
            label: 'Doctor',
            disableClear: false,
            valueField: 'key',
            dataSourceApi: api.SEARCH.DOCTOR,
            dataSourceConfig: {
                text: 'value.value',
                value: 'key',
            },
            searchConstructFields: {
                name: 'provider.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'claim.subCompany',
            type: 'select',
            label: 'Sub Company',
            valueField: 'key',
            disableClear: false,
            options: Object.values(subCompanies).map(aSubCompany => ({
                key: aSubCompany,
                value: aSubCompany,
            })),
            searchConstructFields: {
                name: 'claim.subCompany',
                filterOperation: '=',
            },
        },
        {
            name: 'claim.amount',
            type: 'fieldWithOperation',
            label: 'Amount',
            valueType: 'number',
            valueLabel: 'Enter Amount',
            disableClear: false,
            options: ['<=', '=', '>='],
            searchConstructFields: {
                name: 'claim.amount',
            },
        },
        {
            name: 'claim.voided',
            type: 'checkbox',
            label: 'Show only archived claims',
            disableClear: true,
            defaultValue: false,
            searchConstructFields: {
                name: 'claim.voided',
                filterOperation: '===',
            },
        },
        {
            name: 'episode_of_care.dentalEpisode',
            type: 'checkbox',
            label: 'Only Dental Claims',
            disableClear: true,
            defaultValue: false,
            searchConstructFields: {
                name: 'episode_of_care.dentalEpisode',
                filterOperation: '===',
            },
        },
    ]);
};

export const claimSearchFieldFilter = [
    {
        name: 'claim.claimNo',
        filterOperation: '=',
    },
];

export const claimPatientSearchFieldFilters = [
    {
        name: 'person.secondaryIdentifier',
        filterOperation: '=',
    },
    {
        name: 'patient_identifier.identifier',
        filterOperation: '=',
    },
];

export const claimSearchFieldFilters = [
    ...claimSearchFieldFilter,
    ...claimPatientSearchFieldFilters,
];

export const getDefaultFilter = (claimListDefaultFilter) => {
    let defaultFilter = {};
    if (isArrayValidAndNotEmpty(claimListDefaultFilter)) {
        const matchingFilter =
            claimListDefaultFilter.find((aDefaultFilter => checkIfPrivilegeExistsForUser(aDefaultFilter.privilege)));
        if (isObjectValidAndNotEmpty(matchingFilter) && isObjectValidAndNotEmpty(matchingFilter.filters)) {
            defaultFilter = {
                ...defaultFilter,
                ...matchingFilter.filters,
            };
        }
    }
    return defaultFilter;
};

export const claimBatchActions = {
    // CREATE_BUNDLE: 'Create Bundle',
    CONFIRM_CLAIMS: 'Confirm Claims',
    CONS_INVOICES: 'Cons Invoices',
    ARCHIVE: 'Archive',
    MERGE: 'Merge',
};

export const claimSearchTypes = {
    CLAIM: 'CLAIM',
    PATIENT: 'PATIENT',
};

export const getClaimListSearchConstruct = (isBundle, filters, searchFilter, claimPrefix = 'SAJ') => {
    let filterSchema = claimTableFilter();
    const cloneFilters = cloneDeep(filters);
    let searchFilterSchema = claimSearchFieldFilters;
    let textToSearch = (searchFilter || '').trim();
    const {
        searchType,
    } = cloneFilters;
    delete cloneFilters.searchType;

    if (isBundle) {
        filterSchema = bundleTableFilter();
        searchFilterSchema = bundleSearchFieldFilters;
    } else if (searchType === claimSearchTypes.CLAIM) {
        searchFilterSchema = claimSearchFieldFilter;
        textToSearch = textToSearch && !textToSearch.startsWith(claimPrefix) ? `${claimPrefix}${textToSearch}` : textToSearch;
    } else if (searchType === claimSearchTypes.PATIENT) {
        searchFilterSchema = claimPatientSearchFieldFilters;
    }

    const filterConstruct = generateConstructFromFilterColumns(filterSchema, cloneFilters);
    console.log('asd0aud0as9das', searchType, textToSearch, searchFilterSchema);
    const searchValueConstruct = generateSearchFilterConstruct(textToSearch, searchFilterSchema);
    const searchConstruct = getCombined(filterConstruct, COMBINEMODE.AND, searchValueConstruct);

    console.log('asdas-0das[dkasd', filterConstruct, searchValueConstruct, searchConstruct);
    return searchConstruct;
};

export const checkPreRequisitesForBundlingAreMet = (props) => {
    const filters = getStringFromObject('claims.filters', props, {});
    const {
        dispatch,
    } = props;
    const tpa = filters['insurance_payer.uuid'];
    const startDateFilter = filters['claim.startDate'];
    const endDateFilter = filters['claim.endDate'];
    if (!isObjectValidAndNotEmpty(tpa)) {
        dispatch(errorMessage('Payer Filter Must Be Applied'));
        return null;
    }
    if (!startDateFilter || !endDateFilter) {
        dispatch(errorMessage('Date Filters Must Be Applied To Create A Bundle'));
        return null;
    }
    return {
        tpa,
        startDateFilter,
        endDateFilter,
    };
};

export const getClaimListConstruct = (filters, searchFilter, isBundle = false, claimPrefix = 'SAJ') => {
    const cloneFilters = cloneDeep(filters);
    delete cloneFilters.datePeriod;
    return getClaimListSearchConstruct(isBundle, cloneFilters, searchFilter, claimPrefix);
};
