/*
accountInvoices: Array(2)
0: {description: "EXJ/2019/00001", dueDate: "1 Jan 1970", originalAmount: 24.32, openBalance: 24.32, payment: "12.00"}
1: {description: "EXJ/2019/00005", dueDate: "20 Jan 2019", originalAmount: 21.66, openBalance: 21.66, payment: "0"}
length: 2
__proto__: Array(0)
amountReceived: "12"
paymentMethod: "creditCard"
payor: {id: 81697, uuid: "a7b09e25-e364-4f5b-bdd5-0b94055131bb", name: "update", resPartnerNameDto: null, ref: null, …}
referenceNo: "reference"
*/
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty, roundedValueFixedToTwoDigits,
} from '../constants/CommonUtil';
import { formatDate, getDateInDDMMMYYYYFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const mapDtoFromUiObj = (obj) => {
    const payload = {
        resPartner: {
            key: getStringFromObject('payor.uuid', obj, null),
            value: getStringFromObject('payor.name', obj, null),
        },
        comment: obj.comment,
        depositTo: {
            key: getStringFromObject('depositTo.key', obj, null),
            value: getStringFromObject('depositTo.value', obj, null),
        },
        state: obj.action || '',
        attachment: obj.attachment || null,
        paymentMethod: getStringFromObject('paymentMethod', obj, null),
        referenceNumber: getStringFromObject('referenceNo', obj, null),
        totalAmount: getStringFromObject('amountReceived', obj, null),
        subCompany: getStringFromObject('subCompany', obj, null) || null,
        date: getStringFromObject('paymentDate', obj, new Date()),
        paymentModeDetailsDto: {
            date: getStringFromObject('dateOfTransfer', obj, new Date()),
            refNumber: getStringFromObject('referenceNo', obj, null),
            remarks: getStringFromObject('remarks', obj, null),
            bankName: getStringFromObject('bankName', obj, null),
        },
    };
    let accountInvoices = getStringFromObject('accountInvoices', obj, []);
    if (isArrayValidAndNotEmpty(accountInvoices)) {
        accountInvoices = accountInvoices.filter(l => NumberOf(l.payment) > 0);
        payload.invoiceDtos = accountInvoices.map(invoice => ({
            payment: NumberOf(getStringFromObject('payment', invoice, 0)),
            invoiceUuid: getStringFromObject('uuid', invoice, ''),
            narration: getStringFromObject('narration', invoice, ''),
        }));
    }
    return payload;
};

export const mapUiObjFromDto = (obj) => {
    const payload = {
        resPartner: {
            uuid: getStringFromObject('payor.key', obj, null),
            name: getStringFromObject('payor.value', obj, null),
        },
        depositTo: {
            key: getStringFromObject('depositTo.key', obj, null),
            value: getStringFromObject('depositTo.value', obj, null),
        },
        paymentMethod: getStringFromObject('paymentMethod', obj, null),
        referenceNumber: getStringFromObject('referenceNo', obj, null),
        totalAmount: getStringFromObject('totalAmount', obj, null),
        date: getStringFromObject('paymentDate', obj, new Date()),
    };
    return payload;
};

export const PAYMENT_UI_LINE_OBJECT = {
    description: '',
    narration: '',
    dueDate: '',
    originalAmount: '',
    openBalance: '',
    payment: '',
};

export const PAYMENT_UI_OBJECT = {
    payor: null,
    paymentDate: formatDate(new Date(), 'yyyy-MM-dd'),
    dateOfTransfer: formatDate(new Date(), 'yyyy-MM-dd'),
    paymentMethod: '',
    referenceNo: '',
    amountReceived: 0,
    comment: '',
    accountInvoices: [cloneDeep(PAYMENT_UI_LINE_OBJECT)],
    creditLines: [],
    debitLines: [],
};

export const getUiObjectFromAccountVoucher = (voucher) => {
    const uiObject = {
        uuid: voucher.uuid,
        payor: {
            uuid: getStringFromObject('partnerId.key', voucher),
            name: getStringFromObject('partnerId.value', voucher),
        },
        paymentDate: formatDate(getStringFromObject('dateTime', voucher), 'yyyy-MM-dd'),
        paymentMethod: getStringFromObject('paymentMode', voucher),
        referenceNo: getStringFromObject('reference', voucher),
        attachment: getStringFromObject('attachment', voucher),
        amountReceived: getStringFromObject('amount', voucher, 0),
        comment: getStringFromObject('comment', voucher),
        depositTo: getStringFromObject('account', voucher),
        accountInvoices: [],
    };
    const paymentDetails = getStringFromObject('paymentDetails', voucher, null);
    if (isObjectValidAndNotEmpty(paymentDetails)) {
        uiObject.dateOfTransfer = formatDate(new Date(paymentDetails.date), 'yyyy-MM-dd');
        uiObject.referenceNo = paymentDetails.refNumber;
        uiObject.remarks = paymentDetails.remarks;
        uiObject.bankName = paymentDetails.bankName;
    }
    const lines = getStringFromObject('lines', voucher, []);
    if (isArrayValidAndNotEmpty(lines)) {
        uiObject.debitLines = [];
        uiObject.creditLines = [];
        uiObject.accountInvoices = lines.map((line) => {
            console.log('asdad09su0asd', line);
            const originalAmount = getStringFromObject('amountOriginal', line, 0);
            const balanceAmount = getStringFromObject('amountUnreconciled', line, 0);
            const amount = getStringFromObject('amount', line, 0);
            const aline = {
                description: getStringFromObject('name', line),
                narration: getStringFromObject('narration', line),
                dueDate: getDateInDDMMMYYYYFormat(new Date(line.invoiceDueDate)),
                originalAmount: originalAmount != null ? Number(roundedValueFixedToTwoDigits(originalAmount)) : 0,
                openBalance: balanceAmount != null ? Number(roundedValueFixedToTwoDigits(balanceAmount)) : 0,
                payment: amount != null ? Number(roundedValueFixedToTwoDigits(amount)) : 0,
            };
            if (line.type === 'cr') {
                uiObject.creditLines.push(aline);
            } else {
                uiObject.debitLines.push(aline);
            }
            return (aline);
        });
    }
    return uiObject;
};
