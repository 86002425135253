import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_ORDER_DETAILS_REQUEST,
    ORDER_OPERATION_REQUEST,
    FETCH_MRS_ORDER_DETAILS_REQUEST,
    ORDER_SEND_TO_BILLING_REQUEST,
} from '../../redux/modules/order/order-actions';
import { getOrderDetails, operateOrders, sendToBilling, getMrsOrderDetails } from './orderWorkerSaga';

export function* getOrderDetailsSaga() {
    yield takeLatest(FETCH_ORDER_DETAILS_REQUEST, getOrderDetails);
}

export function* getMrsOrderDetailsSaga() {
    yield takeLatest(FETCH_MRS_ORDER_DETAILS_REQUEST, getMrsOrderDetails);
}

export function* operateOrderSaga() {
    yield takeLatest(ORDER_OPERATION_REQUEST, operateOrders);
}

export function* orderSendToBillingSaga() {
    yield takeLatest(ORDER_SEND_TO_BILLING_REQUEST, sendToBilling);
}
