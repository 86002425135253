import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import {
    STOCK_MANAGEMENT_PRIVILEGES,
    stockManagement,
    stockManagementBatches,
    stockManagementConsumables,
    stockManagementDrugs,
    stockManagementOpticalProducts,
    stockManagementProductCategories,
    stockManagementReorderRules,
} from '../constants/constants';
import StockManagementContainer from '../containers/StockManagementContainer/StockManagementContainer';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { getStringFromObject } from '../constants/lodashUtils';
import PageLoader from '../components/PageLoader/PageLoader';

const StockManagementBatchesPage = React.lazy(() => import('../pages/StockManagementPages/StockManagementBatchesPage'));
const StockManagementReorderRulesPage = React.lazy(() => import('../pages/StockManagementPages/StockManagementReorderRulesPage'));
const StockManagementConsumablePage = React.lazy(() => import('../pages/StockManagementPages/StockManagementConsumablePage'));
const StockManagementOpticalProductsPage = React.lazy(() => import('../pages/StockManagementPages/StockManagementOpticalProductsPage'));
const StockManagementProductPage = React.lazy(() => import('../pages/StockManagementPages/StockManagementProductPage'));
const StockManagementProductCategoryPage = React.lazy(() => import('../pages/StockManagementPages/StockManagementProductCategoryPage'));

const getDefaultRoute = (props) => {
    const route = getStringFromObject('history.location.pathname', props);
    if (route === stockManagement) {
        if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showInventoryTab)) {
            return <Route path={stockManagement} exact component={StockManagementBatchesPage} />;
        } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showDrugTab)) {
            return <Route path={stockManagement} exact component={StockManagementProductPage} />;
        } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showConsumablesTab)) {
            return <Route path={stockManagement} exact component={StockManagementConsumablePage} />;
        } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showOpticalProductsTab)) {
            return <Route path={stockManagement} exact component={StockManagementOpticalProductsPage} />;
        } else if (checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.showCategories)) {
            return <Route path={stockManagement} exact component={StockManagementProductCategoryPage} />;
        }
    }
    return '';
};

const StockManagementRoutes = props => (
    <StockManagementContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            {
                getDefaultRoute(props)
            }
            <Route path={stockManagementBatches} exact component={StockManagementBatchesPage} />
            <Route path={stockManagementDrugs} exact component={StockManagementProductPage} />
            <Route path={stockManagementProductCategories} exact component={StockManagementProductCategoryPage} />
            <Route path={stockManagementConsumables} exact component={StockManagementConsumablePage} />
            <Route path={stockManagementOpticalProducts} exact component={StockManagementOpticalProductsPage} />
            <Route path={stockManagementReorderRules} exact component={StockManagementReorderRulesPage} />
        </Suspense>
    </StockManagementContainer>
);

StockManagementRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

StockManagementRoutes.defaultProps = {
    history: {},
};

export default StockManagementRoutes;
