/**
 * Created by sachinprakash on 9/5/19.
 */
import axios from 'axios';
import { getIndexFromFieldName, isStringNullOrUndefined } from '../../constants/CommonUtil';
import api from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

export const setAccountType = (rowValue, fieldPath, form) => {
    const setFieldVal = getStringFromObject('setFieldValue', form);
    const accountName = getStringFromObject('value', rowValue);
    const index = getIndexFromFieldName(fieldPath);
    console.log('hdgfjadsgj', rowValue, `moveLineDtos.${index}`, accountName);
    const config = {
        headers: { 'Content-Type': 'application/json' },
    };
    if (!isStringNullOrUndefined(accountName)) {
        console.log('hdgfjadsgj', accountName);
        axios.get(`${api.JOURNAL_ENTRIES.GET_ACCOUNT_TYPE}${accountName}`,
            config)
            .then((response) => {
                console.log('sdgkajdgkjasdg', response);
                console.log('sdgkajdgkjasdg', `movlineDtos.${index}`);
                setFieldVal(`moveLineDtos.${index}.type`, response.data);
            })
            .catch((error) => {
                console.log('hjdgkjadf', error);
            });
    }
};

export const ACTION_HANDLERS = {
    setAccountType,
};

