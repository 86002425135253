import API from '../../../constants/api';

export const CREATE_STOCK_MOVE_REQUEST = '@@stockMove/CREATE_STOCK_MOVE_REQUEST';
export const CREATE_STOCK_MOVE_FAILURE = '@@stockMove/CREATE_STOCK_MOVE_FAILURE';
export const CREATE_STOCK_MOVE_SUCCESS = '@@stockMove/CREATE_STOCK_MOVE_SUCCESS';

export const FETCH_STOCK_MOVE_REQUEST = '@@stockMove/FETCH_STOCK_MOVE_REQUEST';
export const FETCH_STOCK_MOVE_FAILURE = '@@stockMove/FETCH_STOCK_MOVE_FAILURE';
export const FETCH_STOCK_MOVE_SUCCESS = '@@stockMove/FETCH_STOCK_MOVE_SUCCESS';

export const FETCH_STOCK_MOVE_BY_ID_REQUEST = '@@stockMove/FETCH_STOCK_MOVE_BY_ID_REQUEST';
export const FETCH_STOCK_MOVE_BY_ID_FAILURE = '@@stockMove/FETCH_STOCK_MOVE_BY_ID_FAILURE';
export const FETCH_STOCK_MOVE_BY_ID_SUCCESS = '@@stockMove/FETCH_STOCK_MOVE_BY_ID_SUCCESS';

export const CLEAR_STOCK_MOVE_BY_PURCHASE_ORDER_SUCCESS = '@@stockMove/CLEAR_STOCK_MOVE_BY_PURCHASE_ORDER_SUCCESS';
export const CLEAR_SELECTED_STOCK_MOVE_SUCCESS = '@@stockMove/CLEAR_SELECTED_STOCK_MOVE_SUCCESS';

export const receiveStockRequest = (payload, successCallback, failureCallback) => ({
    type: CREATE_STOCK_MOVE_REQUEST,
    api: API.STOCK.RECEIVE_STOCK,
    payload,
    successCallback,
    failureCallback,
});

export const returnStockRequest = (payload, successCallback, failureCallback) => ({
    type: CREATE_STOCK_MOVE_REQUEST,
    api: API.STOCK.RETURN_STOCK,
    payload,
    successCallback,
    failureCallback,
});

export const adjustInventoryRequest = (payload, successCallback, failureCallback) => ({
    type: CREATE_STOCK_MOVE_REQUEST,
    api: API.STOCK.ADJUST_INVENTORY,
    payload,
    successCallback,
    failureCallback,
});

export const fetchStockMovesRequest = (purchaseOrderApi, successCallback, failureCallback) => ({
    type: FETCH_STOCK_MOVE_REQUEST,
    api: purchaseOrderApi,
    successCallback,
    failureCallback,
});

export const fetchStockMovesByIdRequest = (id, successCallback, failureCallback) => ({
    type: FETCH_STOCK_MOVE_BY_ID_REQUEST,
    api: `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${id}`,
    successAction: FETCH_STOCK_MOVE_BY_ID_SUCCESS,
    failureAction: FETCH_STOCK_MOVE_BY_ID_FAILURE,
    successCallback,
    failureCallback,
});

export const fetchGoodReceiptsRequest = (
    partnerUuid, locationUuid, invoiced, purchaseOrderUuid, successCallback, failureCallback,
) => ({
    type: FETCH_STOCK_MOVE_REQUEST,
    api: `${API.STOCK.GOOD_RECEIPTS}?partnerUuid=${partnerUuid}&locationUuid=${locationUuid}
    &invoiced=${invoiced}&purchaseOrderUuid=${purchaseOrderUuid}`,
    successCallback,
    failureCallback,
});

export const clearStockMoveByPurchaseOrderState = () => ({
    type: CLEAR_STOCK_MOVE_BY_PURCHASE_ORDER_SUCCESS,
});

export const clearStockMoveSelectState = () => ({
    type: CLEAR_SELECTED_STOCK_MOVE_SUCCESS,
});
