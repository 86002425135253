/**
 * Created by vinay on 19/12/18.
 */
import { takeLatest } from 'redux-saga/effects';
import { ADVANCE_SEARCH_REQUEST } from '../../redux/modules/advanceSearch/advanceSearch-actions';
import advanceSearchWorker from './advanceSearchWorkerSaga';


export default function* advanceSearchWatcher() {
    yield takeLatest(ADVANCE_SEARCH_REQUEST, advanceSearchWorker);
}
