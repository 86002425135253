import { CLEAR_WS_CONNECTION, SAVE_WS_CONNECTION } from './websocket-actions';

const webSocketStore = (state = null, action) => {
    let newState;
    switch (action.type) {
    case SAVE_WS_CONNECTION:
        newState = action.stompClient;
        break;
    case CLEAR_WS_CONNECTION:
        newState = null;
        break;
    default:
        newState = state;
        break;
    }
    return newState;
};
export default webSocketStore;
