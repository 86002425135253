import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import API from '../../constants/api';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';
import { getAllowancesAndDeductionsFromPayslipLines } from '../../components/HrPayrollComponent/payrollUtils';
import PayslipDetails from './PayslipDetails';
import { formatDateForDisplay } from '../../constants/DateUtil';
import ActionButton from '../../components/ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import PrintPDF from '../RegistrationAppComponents/PrintHTML/PrintPDF';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const profileDetailsLeft = [
    {
        name: 'employeeDetails.name',
        label: 'Name',
    },
    {
        name: 'employeeDetails.identificationId',
        label: 'Employee No',
    },
    {
        name: 'employeeDetails.designation',
        label: 'Designation',
    },
    {
        name: 'employeeDetails.department',
        label: 'Department',
    },
    {
        name: 'employeeDetails.joiningDate',
        type: 'date',
        label: 'Date Of Joining',
    },
    {
        name: 'employeeDetails.nationality',
        label: 'Nationality',
    },
];

const profileDetailsRight = [
    {
        name: 'employeeDetails.bankName',
        label: 'Bank Name',
    },
    {
        name: 'employeeDetails.accountNo',
        label: 'Account No',
    },
    {
        name: 'employeeDetails.bankBranchCode',
        label: 'IFSC Code',
    },
];

class MyPayslipContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payrollPeriod: null,
            payslipDetails: null,
        };
    }

    onSelectPeriod = (payrollPeriod) => {
        this.setState({
            payrollPeriod,
        }, this.fetchPayslip);
    };

    onDownloadOrPrintPayslip = print => async () => {
        const {
            payslipDetails,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        const payslipUuid = getStringFromObject('payslipDetails.payslipUuid', payslipDetails);
        if (payslipUuid) {
            try {
                dispatch(showSpinner());
                const response = await axios.get(`${API.PAYROLL.PAYSLIP_PRINT}${payslipUuid}`);
                dispatch(hideSpinner());
                const failureReason = getStringFromObject('failureReason', response.data);
                const downloadToken = getStringFromObject('downloadToken', response.data);
                if (failureReason) {
                    dispatch(errorMessage(failureReason));
                    return;
                }
                if (!print) {
                    window.open(`${API.DOWNLOAD_FILE.WITH_TOKEN}/${downloadToken}`);
                } else {
                    this.setState(prevState => ({
                        downloadToken,
                        printPayslip: !prevState.printPayslip,
                    }));
                }
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    getColumnDisplay = rowsToMap => (
        rowsToMap.map((aProfileDet) => {
            let valueToDisplay = getStringFromObject(aProfileDet.name, this.state.payslipDetails);
            if (aProfileDet.type === 'date') {
                valueToDisplay = formatDateForDisplay(new Date(valueToDisplay));
            }
            return (
                <Grid container key={aProfileDet.name} className="p0-5">
                    <Grid item sm={12} md={12} lg={12}>
                        <Typography>
                            {aProfileDet.label}:&nbsp;&nbsp;
                            {valueToDisplay}
                        </Typography>
                    </Grid>
                </Grid>
            );
        })
    );

    fetchPayslip = async () => {
        try {
            const {
                payrollPeriod,
            } = this.state;
            if (isObjectValidAndNotEmpty(payrollPeriod)) {
                const response =
                    await axios.get(`${API.PAYROLL.CURRENT_EMPLOYEE_PAYSLIP}${payrollPeriod.uuid}`);
                this.setState({
                    payslipDetails: response.data,
                });
            }
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
    };


    render() {
        const {
            payrollPeriod,
            payslipDetails,
            printPayslip,
            downloadToken,
        } = this.state;
        const {
            dispatch,
            appConfiguration,
        } = this.props;
        console.log(dispatch, payrollPeriod, payslipDetails);
        const borderStyle = '1px solid #efefef';
        const payslipLines = getStringFromObject('payslipDetails.payslipLines', payslipDetails, []);
        const {
            allowances,
            deductions,
        } = getAllowancesAndDeductionsFromPayslipLines(payslipLines);
        const showPayslip = appConfiguration.nuacare_show_my_payslip === 'true';
        console.log('asd-9as9daj0d-asd', payslipDetails, allowances, deductions);
        return (
            <Paper className="m1 p1">
                {
                    showPayslip &&
                    <React.Fragment>
                        <Grid container spacing={16}>
                            <Grid item sm={3} md={3} lg={3}>
                                <ReactSelectMaterial
                                    dataSourceApi={`${API.PAYROLL.ALL_PERIODS}?size=36&searchString=`}
                                    dataSourceConfig={{
                                        text: 'display',
                                        value: 'uuid',
                                    }}
                                    autocomplete
                                    label="Select the period"
                                    value={payrollPeriod}
                                    onChange={this.onSelectPeriod}
                                />
                            </Grid>
                            <Grid item sm={5} md={5} lg={5} />
                            <Grid item sm={4} md={4} lg={4}>
                                {
                                    isObjectValidAndNotEmpty(payslipDetails) &&
                                    <React.Fragment>
                                        <ActionButton
                                            className="mr-1"
                                            onClick={this.onDownloadOrPrintPayslip(false)}
                                        >
                                            Download
                                        </ActionButton>
                                        <ActionButton
                                            onClick={this.onDownloadOrPrintPayslip(true)}
                                        >
                                            Print
                                        </ActionButton>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                        {
                            isObjectValidAndNotEmpty(payrollPeriod) &&
                            <Grid container spacing={16} className="mt-1" style={{ border: '1px solid #efefef' }}>
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    style={{
                                        borderBottom: borderStyle,
                                        borderRight: borderStyle,
                                    }}
                                    className="p1"
                                >
                                    {this.getColumnDisplay(profileDetailsLeft)}
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    style={{
                                        borderBottom: borderStyle,
                                    }}
                                    className="p1"
                                >
                                    {this.getColumnDisplay(profileDetailsRight)}
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    style={{
                                        borderBottom: borderStyle,
                                        borderRight: borderStyle,
                                    }}
                                    className="p1"
                                >
                                    <Grid container justify="center" className="bigLabel mb-1">
                                        ALLOWANCES
                                    </Grid>
                                    <PayslipDetails payslipLines={allowances} />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    style={{
                                        borderBottom: borderStyle,
                                    }}
                                    className="p1"
                                >
                                    <Grid container justify="center" className="bigLabel mb-1">
                                        DEDUCTIONS
                                    </Grid>
                                    <PayslipDetails payslipLines={deductions} />
                                </Grid>
                                <PrintPDF
                                    itemsToPrint={
                                        [
                                            {
                                                url: `${API.DOWNLOAD_FILE.WITH_TOKEN}/${downloadToken}`,
                                                name: 'payslipPrint',
                                            },
                                        ]
                                    }
                                    print={printPayslip}
                                />
                            </Grid>
                        }
                    </React.Fragment>
                }
                {
                    !showPayslip &&
                    <h3 className="bigLabel2Rem flexChildren justify-content-center">
                        You do not have privilege to access this page
                    </h3>
                }
            </Paper>
        );
    }
}

MyPayslipContainer.propTypes = {
    dispatch: PropTypes.func,
    appConfiguration: PropTypes.object,
};

MyPayslipContainer.defaultProps = {
    dispatch: () => {},
    appConfiguration: {},
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
});

export default connect(mapStateToProps)(MyPayslipContainer);

