import initialStates from '../../initialStates';
import {
    AVAILED_LEAVES_FETCH_SUCCESS, CLEAR_LEAVES, LEAVE_BALANCE_FETCH_FAILED,
    LEAVE_BALANCE_FETCH_SUCCESS,
    LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS,
    MANAGE_LEAVE_FETCH_SUCCESS,
} from './leave-actions';

const leaveReducer = (state = initialStates.leaves, action) => {
    let newState = {};

    switch (action.type) {
    case AVAILED_LEAVES_FETCH_SUCCESS:
        newState = Object.assign({}, state, { isLoading: false, availedLeaves: action.data });
        break;
    case LEAVE_BALANCE_FETCH_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            leaveBalance: action.data,
        });
        break;
    case MANAGE_LEAVE_FETCH_SUCCESS:
        newState = Object.assign({}, state, { isLoading: false, isFetched: true });
        break;
    case LEAVE_PLANS_FOR_EMPLOYEE_FETCH_SUCCESS:
        newState = Object.assign({}, state, { employeePlans: action.data });
        break;
    case CLEAR_LEAVES:
    case LEAVE_BALANCE_FETCH_FAILED:
        newState = initialStates.leaves;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default leaveReducer;
