import API from '../../../constants/api';

export const REGISTER_PAYMENT_REQUEST = '@@payment/REGISTER_PAYMENT_REQUEST';
export const REGISTER_PAYMENT_REQUEST_FAILURE = '@@payment/REGISTER_PAYMENT_REQUEST_FAILURE';
export const REGISTER_PAYMENT_REQUEST_SUCCESS = '@@payment/REGISTER_PAYMENT_REQUEST_SUCCESS';

export const FETCH_ACCOUNT_VOUCHER_REQUEST = '@@payment/FETCH_ACCOUNT_VOUCHER_REQUEST';
export const FETCH_ACCOUNT_VOUCHER_FAILURE = '@@payment/FETCH_ACCOUNT_VOUCHER_FAILURE';
export const FETCH_ACCOUNT_VOUCHER_SUCCESS = '@@payment/FETCH_ACCOUNT_VOUCHER_SUCCESS';

export const CLEAR_SELECTED_ACCOUNT_VOUCHER = '@@payment/CLEAR_SELECTED_ACCOUNT_VOUCHER';

export const registerPaymentRequest = (paymentLines, successCallback, failureCallBack) => ({
    type: REGISTER_PAYMENT_REQUEST,
    api: API.PAYMENT.REGISTER_PAYMENT,
    paymentLines,
    successCallback,
    failureCallBack,
});

export const fetchAccountVoucherById = id => ({
    type: FETCH_ACCOUNT_VOUCHER_REQUEST,
    api: `${API.ACCOUNT_VOUCHER.FETHC_BY_ID}/${id}`,
});

export const clearSelectedAccountVoucher = () => ({
    type: CLEAR_SELECTED_ACCOUNT_VOUCHER,
});
