import API from '../../../constants/api';

export const FETCH_MY_CASH_CLOSE_REQUEST = '@@myCashClose/FETCH_MY_CASH_CLOSE_REQUEST';
export const FETCH_MY_CASH_CLOSE_FAILURE = '@@myCashClose/FETCH_MY_CASH_CLOSE_FAILURE';
export const FETCH_MY_CASH_CLOSE_SUCCESS = '@@myCashClose/FETCH_MY_CASH_CLOSE_SUCCESS';

export const fetchPagesOfCashClose = (params) => ({
    type: FETCH_MY_CASH_CLOSE_REQUEST,
    api: API.USER_CASH_CLOSE.PAGES_USER,
    params,
});
