/**
 * Created by vinay on 19/12/18.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    CREATE_DOCTOR_SLOT_FAILURE,
    CREATE_DOCTOR_SLOT_REQUEST,
    CREATE_DOCTOR_SLOT_SUCCESS,
    FETCH_APPOINTMENT_TYPES_FAILURE,
    FETCH_APPOINTMENT_TYPES_SUCCESS,
    FETCH_PROVIDER_WEEK_FAILURE,
    FETCH_PROVIDER_WEEK_SUCCESS,
    FETCH_SERVICE_WEEK_FAILURE,
    FETCH_SERVICE_WEEK_SUCCESS,
    RUN_DOCTOR_SLOT_SCHEDULER_FAILURE,
    RUN_DOCTOR_SLOT_SCHEDULER_REQUEST,
    RUN_DOCTOR_SLOT_SCHEDULER_SUCCESS,
    UPDATE_DOCTOR_SLOT_FAILURE,
    UPDATE_DOCTOR_SLOT_SUCCESS,
} from '../../redux/modules/appointmentSlot/appointmentSlot-actions';
import message from '../../constants/messages';
import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* fetchAppointmentTypesWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_APPOINTMENT_TYPES_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.APPOINTMENT_TYPES_FETCH_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_APPOINTMENT_TYPES_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchServiceWeekWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_SERVICE_WEEK_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.SERVICE_WEEK_FETCH_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_SERVICE_WEEK_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchProviderWeekWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_PROVIDER_WEEK_SUCCESS,
            providerUuid: action.providerUuid,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.PROVIDER_WEEK_FETCH_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_PROVIDER_WEEK_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createOrUpdateDoctorSlot(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        let response = null;
        let successMessage = '';
        let successActionType = '';
        if (action.type === CREATE_DOCTOR_SLOT_REQUEST) {
            response = yield call(retriable().post, action.api, action.payload);
            successMessage = message.DOCTOR_SLOT.CREATE.SUCCESS;
            successActionType = CREATE_DOCTOR_SLOT_SUCCESS;
        } else {
            response = yield call(retriable().post, action.api, action.payload);
            successMessage = message.DOCTOR_SLOT.UPDATE.SUCCESS;
            successActionType = UPDATE_DOCTOR_SLOT_SUCCESS;
        }
        yield put({
            type: successActionType,
            data: response.data,
        });
        yield put({
            type: RUN_DOCTOR_SLOT_SCHEDULER_REQUEST,
            api: API.APPOINTMENT.DOCTOR_SLOT.RUN_SCHEDULER,
            params: {
                serviceUuid: getStringFromObject('payload.service.key', action),
                providerUuid: getStringFromObject('payload.provider.key', action),
            },
        });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: successMessage });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        let failedAction = '';
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let errorMessage = '';
            if (action.type === CREATE_DOCTOR_SLOT_REQUEST) {
                failedAction = CREATE_DOCTOR_SLOT_FAILURE;
                errorMessage = message.DOCTOR_SLOT.CREATE.FAILED;
            } else {
                failedAction = UPDATE_DOCTOR_SLOT_FAILURE;
                errorMessage = message.DOCTOR_SLOT.UPDATE.FAILED;
            }
            yield put({
                type: ERROR_MESSAGE,
                message: `${errorMessage} : ${getErrorMessage(e)}`,
            });
        }
        if (isValidFunction(action.onFailureCallback)) {
            action.onFailureCallback();
        }
        yield put({ type: failedAction });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* runDoctorSlotSchedulerWorker(action) {
    try {
        yield call(retriable().get, action.api, { params: action.params });
        yield put({
            type: RUN_DOCTOR_SLOT_SCHEDULER_SUCCESS,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.DOCTOR_SLOT.SCHEDULER_JOB_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        if (isValidFunction(action.onFailureCallback)) {
            action.onFailureCallback();
        }
        yield put({ type: RUN_DOCTOR_SLOT_SCHEDULER_FAILURE });
    }
}
