import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_RECEIVE_PAYMENT_FAILURE,
    CREATE_RECEIVE_PAYMENT_SUCCESS, FETCH_RECEIVE_PAYMENT_FAILURE, FETCH_RECEIVE_PAYMENT_SUCCESS,
} from '../../redux/modules/receivePayment/receivePayment-action';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* createReceivePaymentWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.receivePayment, config);
        yield put({
            type: CREATE_RECEIVE_PAYMENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        if (getStringFromObject('receivePayment.state', action) === 'draft') {
            yield put({ type: SUCCESS_MESSAGE, message: 'Draft Payment Created Successfully' });
        } else {
            yield put({ type: SUCCESS_MESSAGE, message: messages.RECEIVE_PAYMENT.CREATE.SUCCESS });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = messages.RECEIVE_PAYMENT.CREATE.FAILED;
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_RECEIVE_PAYMENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchReceivePaymentWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_RECEIVE_PAYMENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.RECEIVE_PAYMENT.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_RECEIVE_PAYMENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
