import api from '../../constants/api';

const autoTextListSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'autoTextList',
    showInNonEditableMode: true,
    showInReadOnlyMode: true,
    hideCheckBoxIcon: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#FFFFFF',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'shortForm',
            name: 'shortForm',
            fieldType: 'text',
            find: 'shortForm',
            label: 'Short Form',
            fullWidth: true,
        },
        {
            id: 'category',
            name: 'category',
            fieldType: 'text',
            find: 'autoTextCategory.value',
            label: 'Category',
            fullWidth: true,
        },
        {
            id: 'fulltext',
            name: 'fullText',
            fieldType: 'text',
            find: 'fullText',
            label: 'Full Text',
        },
        {
            id: 'action',
            name: 'rowAction',
            label: 'Action',
            fieldType: 'menuActionButton',
            choices: [
                {
                    key: 'edit',
                    value: 'Edit',
                },
                {
                    key: 'delete',
                    value: 'Delete',
                },
                {
                    key: 'enableDisable',
                    value: 'Enable/Disable',
                },
            ],
            dataSourceConfig: {
                text: 'value',
                value: 'key',
            },
            headerStyle: {
                fontSize: '14px',
                width: '10%',
            },
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};
export const autoTextCategoryListSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'autoTextCategoryList',
    showInNonEditableMode: true,
    showInReadOnlyMode: true,
    hideCheckBoxIcon: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#FFFFFF',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'name',
            name: 'name',
            fieldType: 'text',
            find: 'name',
            label: 'Name',
            fullWidth: true,
        },
        {
            id: 'action',
            name: 'rowAction',
            label: 'Action',
            fieldType: 'menuActionButton',
            choices: [
                {
                    key: 'edit',
                    value: 'Edit',
                },
                {
                    key: 'enableDisable',
                    value: 'Enable/Disable',
                },
            ],
            dataSourceConfig: {
                text: 'value',
                value: 'key',
            },
            headerStyle: {
                fontSize: '14px',
                width: '10%',
            },
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};

export const autoTextFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'category.uuid',
            type: 'autocomplete',
            label: 'Category',
            valueField: 'key',
            disableClear: true,
            dataSourceApi: api.AUTO_TEXTS.CATEGORY.SEARCH_CATEGORY,
            searchConstructFields: {
                name: 'category.uuid',
                filterOperation: '=',
            },
        },
    ]);
};

export default autoTextListSchema;
