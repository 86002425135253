export const SHOW_SPINNER = '@@spinner/SHOW_SPINNER';
export const HIDE_SPINNER = '@@spinner/HIDE_SPINNER';

export const DEFAULT_SPINNER_STATE = {
    canShow: false,
    messageId: null,
    message: '',
};

export const showSpinner = (messageId, message) => ({
    type: SHOW_SPINNER,
    state: {
        canShow: true,
        messageId,
        message,
    },
});

export const hideSpinner = () => ({
    type: HIDE_SPINNER,
    state: DEFAULT_SPINNER_STATE,
});

const spinnerReducer = (state = DEFAULT_SPINNER_STATE, action) => {
    let newState = state;
    console.log('spinner starte', action);
    switch (action.type) {
    case SHOW_SPINNER:
    case HIDE_SPINNER:
        newState = Object.assign({}, state, action.state);
        break;
    default:
        newState = state;
    }
    console.log('new state in spinner', newState);

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default spinnerReducer;
