import {
    isArrayValidAndNotEmpty,
    roundedValueFixedToTwoDigits,
    sortArrayOfObjectsByFieldValue,
} from '../../constants/CommonUtil';

export const prepaidExpenseFieldNames = {
    PAYEE: 'partner',
    PREPAIDACCOUNT: 'prepaidAccount',
    EXPENSEACCOUNT: 'expenseAccount',
};

export const PREPAID_EXPENSE_STATUS = {
    draft: 'draft',
    confirmed: 'confirmed',
    stopped: 'stopped',
};
export const PERIOD_TYPE = [
    {
        label: 'Days',
        value: 'days',
    },
    {
        label: 'Months',
        value: 'month',
    },
    {
        label: 'Years',
        value: 'year',
    },
];
export const PERIOD_TYPE_MAP = {
    DAYS: PERIOD_TYPE[0],
    MONTHS: PERIOD_TYPE[1],
    YEARS: PERIOD_TYPE[2],
};

export const prepaidExpenseSchema = {
    hideCheckBoxIcon: 'true',
    rowstyle: {
        height: '56px',
        border: '0.1px solid white',
    },
    headerRowStyle: {
        background: 'linear-gradient(to right, rgb(58, 26, 228), rgb(47, 189, 204))',
        padding: '0.5em',
    },
    tableStyle: {
        boxShadow: 'none',
    },
    headerColStyle: {
        color: 'black',
        fontSize: '14px',
    },
    headerStyle: {
        padding: '0.5em',
    },
    tableCells: [
        {
            id: 'sequence',
            name: 'sequence',
            find: 'sequence',
            label: 'Sequence',
            fieldType: 'number',
        },
        {
            id: 'date',
            name: 'date',
            find: 'date',
            label: 'Date',
            fieldType: 'date',
        },
        {
            id: 'amount',
            name: 'amount',
            find: 'amount',
            label: 'Amount',
            fieldType: 'text',
        },
        {
            id: 'tax',
            name: 'tax',
            find: 'tax',
            label: 'Tax Amount',
            fieldType: 'text',
        },
        {
            id: 'taxAmount',
            name: 'taxAmount',
            find: 'taxAmount',
            label: 'Amount after Tax',
            fieldType: 'text',
        },
        {
            id: 'moveCheck',
            name: 'moveCheck',
            find: 'moveCheck',
            label: 'Move Status',
            fieldType: 'text',
        },
        {
            label: 'Action',
            id: 'action',
            name: 'rowAction',
            fieldType: 'menuActionButton',
            choices: [
                {
                    key: 'createMove',
                    value: 'Create Journal Entry',
                    hideIf: {
                        key: 'moveCheck',
                        value: 'CREATED',
                    },
                },
            ],
            dataSourceConfig: {
                text: 'value',
                value: 'key',
            },
            hideIf: {
                key: 'moveCheck',
                value: 'CREATED',
            },
        },
    ],
};

export const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fafafa',
        border: `1px solid ${theme.palette.borderColor}`,
    },
    root: {
        maxWidth: 'false',
    },
    input: {
        padding: '0 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
    paper: {
        background: '#fafafa',
    },
    dialog: {
        overflow: 'visible',
    },
    card: {
        margin: '1em',
    },
    cardHeader: {
        backgroundColor: 'rgb(70, 157, 199)',
        color: '#ffffff',
    },
    cardContent: {
        backgroundColor: 'white',
    },
    historyCard: {
        margin: '1em',
        fontSize: '12px',
    },
    historyCardHeader: {
        backgroundColor: '#ffffff',
        fontcolor: '#09090a',
    },
    headers: {
        backgroundColor: 'linear-gradient(to right, rgb(58, 26, 228), rgb(47, 189, 204))',
        borderRadius: '4px',
        height: '30px',
        padding: '5px',
        fontSize: '20px',
    },
    details: {
        margin: '0.5em',
        borderBottom: ' 0.7px solid #dfdfe1',
        display: 'flex',
    },
    detailsDiv: {
        fontWeight: '0.7px',
        color: '#56565b',
        display: 'inline',
    },
    subPart: {
        marginBottom: '1em',
    },
    headings: {
        fontSize: '18px',
        color: '#56565b',
        fontWeight: 'bold',
        marginTop: '1em',
    },
    keys: {
        display: 'inline',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fafafa',
        minWidth: '15em',
        border: `2px solid ${theme.palette.borderColor}`,
    },
});

export const refactorPeriodicLines = (lines) => {
    let ln = [];
    if (isArrayValidAndNotEmpty(lines)) {
        ln = sortArrayOfObjectsByFieldValue(lines, 'sequence');
        ln = ln.map(l => ({
            ...l,
            amount: roundedValueFixedToTwoDigits(l.amount),
            moveCheck: l.moveCheck ? 'CREATED' : 'SCHEDULED',
        }));
    }
    return ln;
};
