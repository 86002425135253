import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import { formatDateForDisplay } from '../../../constants/DateUtil';
import {
    numberBetweenFilterGenerator,
    numberEqualsFilterGenerator,
    numberGreaterThanFilterGenerator,
    numberGreaterThanOrEqualsFilterGenerator,
    numberLessThanFilterGenerator,
    numberLessThanOrEqualsFilterGenerator,
    stringContainingFilterGenerator,
    stringEndsWithFilterGenerator,
    stringEqualsFilterGenerator,
    stringStartsWithFilterGenerator,
} from '../LazyTableUtil';

export const SELECT = 'select';
export const AUTOCOMPLETE = 'autocomplete';
export const CHECKBOX = 'checkbox';
export const FIELD_WITH_OPERATION = 'fieldWithOperation';
export const DATE_RANGE = 'dateRange';
export const DATE_TIME = 'datetime';
export const DATE = 'date';
export const BETWEEN = 'b/w';


export const SEARCH_OPERATORS_EXTRACTOR = {
    string: {
        Equals: stringEqualsFilterGenerator,
        Containing: stringContainingFilterGenerator,
        'Starts With': stringStartsWithFilterGenerator,
        'Ends With': stringEndsWithFilterGenerator,
    },
    number: {
        '=': numberEqualsFilterGenerator,
        '<': numberLessThanFilterGenerator,
        '>': numberGreaterThanFilterGenerator,
        '<=': numberLessThanOrEqualsFilterGenerator,
        '>=': numberGreaterThanOrEqualsFilterGenerator,
        [BETWEEN]: numberBetweenFilterGenerator,
    },
};

export const DEFAULT_SEARCH_OPERATORS_EXTRACTOR = {
    string: 'Containing',
    number: '=',
};

export const getFiltersForFieldWithOperation = (column) => {
    if (column.valueType && !isArrayValidAndNotEmpty(column.options)) {
        return {
            ...column,
            options: Object.keys(SEARCH_OPERATORS_EXTRACTOR[column.valueType]),
            defaultOperation: DEFAULT_SEARCH_OPERATORS_EXTRACTOR[column.valueType],
        };
    }
    return column;
};

export const mapFilters = (filterColumns) => {
    if (!isArrayValidAndNotEmpty(filterColumns)) return null;
    return filterColumns.map((aColumn) => {
        if (aColumn.type === FIELD_WITH_OPERATION) {
            return getFiltersForFieldWithOperation(aColumn);
        }
        return aColumn;
    });
};

export const dateRangeQueryGenerator = (filterColumn, dateRangeValue) => {
    const { dateKey } = filterColumn;
    const { startDate, endDate } = dateRangeValue;
    const dateKeyForQuery = dateKey || 'date';
    let query = '';
    if (startDate) {
        const startDateValue = formatDateForDisplay(startDate);
        query = `${dateKeyForQuery} > '${startDateValue}'`;
    }
    if (endDate) {
        const endDateValue = formatDateForDisplay(endDate);
        query = query.concat(`and ${dateKeyForQuery} < '${endDateValue}'`);
    }
    return query;
};

export const dateQueryGenerator = (fieldName, fieldValue) => {
    const dateValue = formatDateForDisplay(fieldValue);
    const query = `${fieldName} = '${dateValue}'`;
    return query;
};

export const booleanEqualsFilterGenerator = (field, value) => `${field} = ${Boolean(value)}`;
