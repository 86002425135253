import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Clear from '@material-ui/icons/Clear';
import DateFnsUtils from '@date-io/date-fns';
import ActionButton from '../ActionButton/ActionButton';
import { initializeFilterColumnValues } from './GenericFilterComponentUtil';
import {
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,

} from '../../constants/CommonUtil';
import ReactSelectMaterial from '../ReactSelectMaterial/ReactSelectMaterial';
import { applicationDateAndTimeFormat } from '../../constants/constants';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import OutlinedTextField from '../OutlinedTextField';
import FilterFieldWithOperation from './FilterFieldWithOperation';
import { formatUrl } from '../../constants/UrlUtil';
import DateRangePicker from '../FormFieldComponents/DateInput/DateRangePicker';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const dataSourceConfig = {
    text: 'value',
    value: 'key',
};

class GenericFilterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterColumnValues: props.filterValues || initializeFilterColumnValues(props.filterColumns),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(this.props.filterColumns, nextProps.filterColumns)) {
            this.setState({
                filterColumnValues: initializeFilterColumnValues(nextProps.filterColumns),
            });
        }
        if ((this.props.resetFilters !== nextProps.resetFilters)) {
            this.setState({
                filterColumnValues: initializeFilterColumnValues(nextProps.filterColumns),
            });
        }
    }


    getFilterInput = (filterColumn) => {
        const {
            filterColumnValues,
        } = this.state;
        const columnValue = filterColumnValues[filterColumn.name];

        let inputNode = null;
        let disabled = false;
        if (isObjectValidAndNotEmpty(filterColumn.showIf)) {
            if (getStringFromObject(filterColumn.showIf.key, filterColumnValues) !== filterColumn.showIf.value) {
                disabled = true;
            }
        }
        if (filterColumn.type === 'dateRange') {
            if (filterColumn.showOnTop) {
                return <React.Fragment />;
            }
            const filterValue = getStringFromObject(filterColumn.name, filterColumnValues);
            inputNode = (
                <DateRangePicker
                    onRangeSelect={this.handleDateRangeSelect(filterColumn.name)}
                    selectedValues={filterValue}
                    options={filterColumn.options}
                    defaultOption={filterColumn.defaultOption}
                />
            );
        } else if (filterColumn.type === 'date') {
            let minDate = null;

            if (filterColumn.minDateField && filterColumnValues[filterColumn.minDateField]) {
                minDate = new Date(filterColumnValues[filterColumn.minDateField]);
            }

            let maxDate = null;

            if (filterColumn.maxDateField && filterColumnValues[filterColumn.maxDateField]) {
                maxDate = new Date(filterColumnValues[filterColumn.maxDateField]);
            }

            let defaultFilterColumnValue = null;
            if (!columnValue) {
                if (filterColumn.defaultValueField && filterColumnValues[filterColumn.defaultValueField]) {
                    defaultFilterColumnValue =
                        new Date(filterColumnValues[filterColumn.defaultValueField]);
                }
                console.log('sa0-i909asdad', columnValue, defaultFilterColumnValue, filterColumn);
            }

            inputNode = (
                <DateInput
                    onChange={this.handleDateSelect(filterColumn.name)}
                    value={columnValue ? new Date(columnValue) : defaultFilterColumnValue}
                    label={filterColumn.label}
                    testId={filterColumn.label}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                />
            );
        } else if (filterColumn.type === 'select') {
            const formatUrlProps = filterColumn.formatUrl ? filterColumnValues : {};
            inputNode = (
                <ReactSelectMaterial
                    fullWidth
                    testId={filterColumn.label}
                    key={formatUrl(filterColumn.dataSourceApi, formatUrlProps)}
                    isDisabled={filterColumn.disabled}
                    multiple={!!filterColumn.multiple}
                    label={filterColumn.label}
                    value={columnValue}
                    onChange={this.handleSelect(filterColumn.name)}
                    options={filterColumn.options}
                    dataSourceApi={filterColumn.dataSourceApi}
                    dataSourceConfig={filterColumn.dataSourceConfig || dataSourceConfig}
                    variant="outlined"
                    paramMap={formatUrlProps}
                    cacheOptions={false}
                />
            );
        } else if (filterColumn.type === 'autocomplete') {
            const formatUrlProps = filterColumn.formatUrl ? filterColumnValues : {};
            console.log('asda9jopfasfml;fa', formatUrlProps);
            inputNode = (
                <ReactSelectMaterial
                    getOptionLabel={filterColumn.getOptionLabel}
                    fullWidth
                    testId={filterColumn.label}
                    key={formatUrl(filterColumn.dataSourceApi, formatUrlProps)}
                    label={filterColumn.label}
                    multiple={!!filterColumn.multiple}
                    isDisabled={filterColumn.disabled}
                    value={columnValue}
                    autocomplete
                    onChange={this.handleSelect(filterColumn.name)}
                    options={filterColumn.options}
                    dataSourceApi={filterColumn.dataSourceApi}
                    dataSourceConfig={filterColumn.dataSourceConfig || dataSourceConfig}
                    variant="outlined"
                    paramMap={formatUrlProps}
                />
            );
        } else if (filterColumn.type === 'fieldWithOperation') {
            inputNode = (
                <FilterFieldWithOperation
                    fullWidth
                    testId={filterColumn.label}
                    label={filterColumn.label}
                    valueLabel={filterColumn.valueLabel}
                    type={filterColumn.valueType}
                    value={columnValue}
                    onChange={this.handleSelect(filterColumn.name)}
                    options={filterColumn.options}
                    dataSourceApi={filterColumn.dataSourceApi}
                    dataSourceConfig={filterColumn.dataSourceConfig}
                />
            );
        } else if (filterColumn.type === 'datetime') {
            let minDate = null;

            if (filterColumn.minDateField && filterColumnValues[filterColumn.minDateField]) {
                minDate = new Date(filterColumnValues[filterColumn.minDateField]);
            }
            inputNode = (
                <InlineDateTimePicker
                    keyboard
                    ampm
                    testId={filterColumn.label}
                    label={filterColumn.label}
                    onChange={this.handleDateTimeSelect(filterColumn.name)}
                    onError={console.log}
                    maxDate={new Date()}
                    minDate={minDate}
                    disablePast
                    value={columnValue ? new Date(columnValue) : ''}
                    format={applicationDateAndTimeFormat}
                    mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '',
                        /\d/,
                        /\d/,
                        ':',
                        /\d/,
                        /\d/,
                    ]}
                />
            );
        } else if (filterColumn.type === 'checkbox') {
            inputNode = (
                <FormControlLabel
                    control={
                        <Checkbox
                            testId={filterColumn.label}
                            checked={Boolean(columnValue)}
                            onChange={this.handleCheckboxChange(filterColumn.name)}
                        />
                    }
                    label={filterColumn.label}
                />
            );
        } else {
            inputNode = (
                <OutlinedTextField
                    fullWidth
                    testId={filterColumn.label}
                    type={filterColumn.type}
                    placeholder={filterColumn.label}
                    value={columnValue}
                    label={filterColumn.label}
                    onChange={this.handleTextChange(filterColumn.name)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
        console.log('asd-asdpk[asdl;ada');
        return (
            <Grid item sm={12} md={6} lg={6} key={filterColumn.name} {...(filterColumn.grid || {})} test-id={filterColumn.name}>
                <Grid container>
                    <Grid item sm={9} md={9} lg={9}>
                        {inputNode}
                    </Grid>
                    {
                        !filterColumn.disableClear &&
                        <Grid item sm={3} md={3} lg={3}>
                            <Clear
                                test-id={`${filterColumn.name}.svg-clear`}
                                style={{ cursor: 'pointer' }}
                                onClick={this.clearValue(filterColumn)}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        );
    };

    clearValue = ({ name, additionalFieldToClear }) => () => {
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: null,
        };
        if (isArrayValidAndNotEmpty(additionalFieldToClear)) {
            additionalFieldToClear.map((anAdditionalFieldToClear) => {
                newFilterColumnValues[anAdditionalFieldToClear] = null;
                return null;
            });
        }
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    };

    handleSelect = name => (value) => {
        console.log('asd09jioasmkldasdas', name);
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: value,
        };
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    };

    handleTextChange = name => (value) => {
        console.log('asd09jioasmkldasdas', name);
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: getTextFieldChangedValue(value),
        };
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    };

    handleCheckboxChange = name => (event, value) => {
        console.log('asd09jioasmkldasdas', name);
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: value,
        };
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    };

    handleDateTimeSelect = name => (value) => {
        console.log('asd09jioasmkldasdas', value);
        if (value && value.getTime && typeof value.getTime === 'function') {
            const newFilterColumnValues = {
                ...this.state.filterColumnValues,
                [name]: value ? value.getTime() : null,
            };
            this.setState({
                filterColumnValues: newFilterColumnValues,
            });
        }
    };

    handleDateSelect = name => (value) => {
        console.log('asd09jioasmkldasdas', name, value);
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: value,
        };
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    };

    handleDateRangeSelect = name => (value) => {
        console.log('handleDateRangeSelect', name, value);
        const newFilterColumnValues = {
            ...this.state.filterColumnValues,
            [name]: value,
        };
        this.setState({
            filterColumnValues: newFilterColumnValues,
        });
    }

    applyFilter = () => {
        this.props.applyFilter(this.state.filterColumnValues);
        this.props.handleClose();
    };

    clearFilter = () => {
        const { filterColumnValues: currentFilterValues } = this.state;
        const filterColumnValues = initializeFilterColumnValues(this.props.filterColumns, currentFilterValues);
        this.setState({ filterColumnValues }, this.applyFilter);
    };

    render() {
        const {
            filterColumnValues,
        } = this.state;
        const {
            filterColumns,
        } = this.props;
        console.log('091u2-0jkoas,lda;dasdasdsa', filterColumns, filterColumnValues);

        if (isArrayValidAndNotEmpty(filterColumns)) {
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <React.Fragment>
                        <Grid container spacing={16}>
                            {
                                filterColumns.map(aColumn => this.getFilterInput(aColumn))
                            }
                        </Grid>
                        <Grid container spacing={16} justify="flex-end" className="mt-1">
                            <ActionButton
                                testId="clear-all"
                                primary={false}
                                onClick={this.clearFilter}
                            >
                                CLEAR ALL
                            </ActionButton>
                            <ActionButton
                                testId="apply-filter"
                                primary
                                onClick={this.applyFilter}
                                className="ml-1"
                            >
                                APPLY FILTERS
                            </ActionButton>
                        </Grid>
                    </React.Fragment>
                </MuiPickersUtilsProvider>
            );
        }
        return null;
    }
}

GenericFilterComponent.propTypes = {
    filterColumns: PropTypes.array,
    applyFilter: PropTypes.func,
    handleClose: PropTypes.func,
    resetFilters: PropTypes.bool,
    filterValues: PropTypes.object,
};

GenericFilterComponent.defaultProps = {
    filterColumns: [],
    applyFilter: () => {},
    handleClose: () => {},
    resetFilters: false,
    filterValues: null,
};

export default GenericFilterComponent;

