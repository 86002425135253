import API from '../../../constants/api';

export const ADD_ORDER = '@@order/ADD_ORDER';
export const FETCH_ORDER_DETAILS_REQUEST = '@@order/FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_MRS_ORDER_DETAILS_REQUEST = '@@order/FETCH_MRS_ORDER_DETAILS_REQUEST';
export const SAVE_ORDER_DETAILS = '@@order/SAVE_ORDER_DETAILS';
export const SAVE_MRS_ORDER_DETAILS = '@@order/SAVE_MRS_ORDER_DETAILS';
export const RESET_ORDER_DETAILS = '@@order/RESET_ORDER_DETAILS';
export const RESET_MRS_ORDER_DETAILS = '@@order/RESET_MRS_ORDER_DETAILS';

export const ORDER_OPERATION_REQUEST = '@@order/ORDER_OPERATION_REQUEST';
export const ORDER_OPERATION_SUCCESS = '@@order/ORDER_OPERATION_SUCCESS';

export const ORDER_SEND_TO_BILLING_REQUEST = '@@order/ORDER_SEND_TO_BILLING_REQUEST';
export const ORDER_SEND_TO_BILLING_SUCCESS = '@@order/ORDER_SEND_TO_BILLING_SUCCESS';

export const addOrder = patient => ({
    type: ADD_ORDER,
    api: API.ORDER.ORDER_DETAILS,
    patient,
});

export const fetchMRSOrderDetails = (uuid, fetchAdditionalPatDetails, successCallback) => ({
    type: FETCH_MRS_ORDER_DETAILS_REQUEST,
    api: API.ORDER.MRS_ORDER_DETAIL,
    uuid,
    fetchAdditionalPatDetails,
    successCallback,
});
export const fetchOrderDetails = (requestType, uuid, status, orderTypes, onlyClinical) => ({
    type: FETCH_ORDER_DETAILS_REQUEST,
    api: API.ORDER.ORDER_DETAILS,
    requestType,
    uuid,
    status,
    orderTypes,
    onlyClinical,
});

export const resetOrderDetails = () => ({
    type: RESET_ORDER_DETAILS,
});

export const operateOrders = (orders, successCallback) => ({
    type: ORDER_OPERATION_REQUEST,
    api: API.ORDER.ORDER_OPERATION,
    orders,
    successCallback,
});

export const sendToBilling = approvalDto => ({
    type: ORDER_SEND_TO_BILLING_REQUEST,
    api: API.ORDER.SEND_TO_BILLING,
    approvalDto,
});
