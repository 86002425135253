import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Close from '@material-ui/icons/Close';
import deepEqual from 'react-fast-compare';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';

import { required } from '../../constants/FormValidations';
import RoundedCornerButton from '../../components/RoundedCornerButton';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';
import API from '../../constants/api';
import { getLoggedInUserName } from '../../constants/state';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { successMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

const changePasswordForm = 'changePasswordForm';

const style = () => ({
    paper: {
        width: '30em',
    },
    fieldStyle: {
        margin: '8px 0 0 0',
    },
    dialogTitle: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
        color: '#DFF2BF',
        padding: '12px 24px',
    },
    success: {
        color: '#4F8A10',
        background: '#DFF2BF',
        padding: '0.5em',
        margin: '1em 0',
    },
    error: {
        color: '#D8000C',
        background: '#FFBABA',
        padding: '0.5em',
        margin: '1em 0',
    },
});

class ChangePassword extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            success: '',
        };
    }

    handleChangePassword = async (values) => {
        const {
            employeeUuid,
            handleClose,
            dispatch,
        } = this.props;
        console.log('asd0ia-d0iass', values);
        try {
            dispatch(showSpinner());
            await axios.post(API.LOGIN.CHANGE_PASSWORD, {
                userName: getLoggedInUserName(),
                employeeUuid,
                currentPassword: getStringFromObject('currentPassword', values, ''),
                newPassword: getStringFromObject('newPassword', values, ''),
            });
            dispatch(successMessage('Password Changed Successfully'));
            this.setState({ success: 'Password Changed Successfully', error: '' });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
            this.setState({
                error: getStringFromObject('data.exception', e.response, 'Error Occurred'),
                success: '',
            });
        } finally {
            dispatch(hideSpinner());
            handleClose();
        }
    };


    handleSamePassword = (value) => {
        const newPassword = getStringFromObject('newPassword', this.props.formValues, '');
        if (!deepEqual(value, newPassword)) {
            return 'Passwords are not same';
        }
        return undefined;
    };

    handleFormSubmit = () => {
        const { handleSubmit } = this.props;
        const submitter = handleSubmit(this.handleChangePassword);
        submitter();
    };

    render() {
        const { error, success } = this.state;
        const {
            open,
            handleClose,
            classes,
            isOrganisationAdmin,
        } = this.props;
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes.paper,
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    id="form-dialog-title"
                >
                    <span style={{ color: 'white' }}>Password</span>
                    <Close style={{ color: 'white' }} className="cursor-pointer flo-right" onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    {
                        !isOrganisationAdmin &&
                        <React.Fragment>
                            <div className={classes.fieldStyle}>
                                <Field
                                    name="currentPassword"
                                    type="password"
                                    fullWidth
                                    label="Current Password"
                                    component={TextField}
                                    validate={[required]}
                                />
                            </div>
                        </React.Fragment>
                    }
                    <div className={classes.fieldStyle}>
                        <Field
                            name="newPassword"
                            type="password"
                            fullWidth
                            label="New Password"
                            component={TextField}
                            validate={[required]}
                        />
                    </div>
                    <div className={classes.fieldStyle}>
                        <Field
                            name="confirmNewPassword"
                            type="password"
                            fullWidth
                            label="Confirm New Password"
                            component={TextField}
                            validate={[required, this.handleSamePassword]}
                        />
                    </div>
                    {
                        error && <div className={classes.error}>{error}</div>
                    }
                    {
                        success && <div className={classes.success}>{success}</div>
                    }
                    <div style={{ margin: '2em 0 0 0', textAlign: 'center' }}>
                        <RoundedCornerButton
                            width="8rem"
                            label="Change Password"
                            onClick={this.handleFormSubmit}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    formValues: getStringFromObject(`form.${changePasswordForm}.values`, state, {}),
});

ChangePassword.propTypes = {
    open: PropTypes.bool,
    isOrganisationAdmin: PropTypes.bool,
    handleClose: PropTypes.func,
    dispatch: PropTypes.func,
    employeeUuid: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
};

ChangePassword.defaultProps = {
    open: false,
    isOrganisationAdmin: false,
    employeeUuid: '',
    handleClose: () => {},
    dispatch: () => {},
};

export default connect(mapStateToProps)(reduxForm({
    form: changePasswordForm,
    enableReinitialize: false,
})(withStyles(style)(ChangePassword)));
