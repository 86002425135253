import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton/IconButton';
import withTheme from '@material-ui/core/styles/withTheme';

/*
 * @ignore - internal component.
 */
class SeekableTablePaginationActions extends React.Component {
    handleBackButtonClick = (event) => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = (event) => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    render() {
        const {
            backIconButtonProps,
            count,
            nextIconButtonProps,
            onChangePage,
            page,
            rowsPerPage,
            theme,
            first,
            last,
            ...other
        } = this.props;

        return (
            <div {...other}>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={first}
                    {...backIconButtonProps}
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={last}
                    {...nextIconButtonProps}
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </div>
        );
    }
}

SeekableTablePaginationActions.propTypes = {
    /**
     * Properties applied to the back arrow [`IconButton`](/api/icon-button/) element.
     */
    backIconButtonProps: PropTypes.object.isRequired,
    /**
     * The total number of rows.
     */
    count: PropTypes.number.isRequired,
    /**
     * Properties applied to the next arrow [`IconButton`](/api/icon-button/) element.
     */
    nextIconButtonProps: PropTypes.object.isRequired,
    /**
     * Callback fired when the page is changed.
     *
     * @param {object} event The event source of the callback
     * @param {number} page The page selected
     */
    onChangePage: PropTypes.func.isRequired,
    /**
     * The zero-based index of the current page.
     */
    page: PropTypes.number.isRequired,
    /**
     * The number of rows per page.
     */
    rowsPerPage: PropTypes.number.isRequired,
    /**
     * @ignore
     */
    theme: PropTypes.object.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
};

export default withTheme()(SeekableTablePaginationActions);
