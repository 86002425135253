import API from '../../../constants/api';

export const SAVE_ROOM_DEPARTMENT_MAPPING_REQUEST = '@@roomDepartmentMapping/SAVE_ROOM_DEPARTMENT_MAPPING_REQUEST';
export const SAVE_ROOM_DEPARTMENT_MAPPING_SUCCESS = '@@roomDepartmentMapping/SAVE_ROOM_DEPARTMENT_MAPPING_SUCCESS';
export const SAVE_ROOM_DEPARTMENT_MAPPING_FAILURE = '@@roomDepartmentMapping/SAVE_ROOM_DEPARTMENT_MAPPING_FAILURE';

export const FETCH_ROOM_DEPARTMENT_MAPPING_REQUEST = '@@roomDepartmentMapping/FETCH_ROOM_DEPARTMENT_MAPPING_REQUEST';
export const FETCH_ROOM_DEPARTMENT_MAPPING_SUCCESS = '@@roomDepartmentMapping/FETCH_ROOM_DEPARTMENT_MAPPING_SUCCESS';
export const FETCH_ROOM_DEPARTMENT_MAPPING_FAILURE = '@@roomDepartmentMapping/FETCH_ROOM_DEPARTMENT_MAPPING_FAILURE';

export const saveRoomDepartmentMapping = (payload, successCallback) => ({
    type: SAVE_ROOM_DEPARTMENT_MAPPING_REQUEST,
    api: API.ROOM_DEPARTMENT_MAPPING.CREATE,
    payload,
    successCallback,
});

export const fetchRoomDepartmentMapping = (successCallback, failureCallback) => ({
    type: FETCH_ROOM_DEPARTMENT_MAPPING_REQUEST,
    api: API.ROOM_DEPARTMENT_MAPPING.LIST,
    successCallback,
    failureCallback,
});
