import { DATE_FILTERS } from '../Common/ReportDateFilterUtil';
import api from '../../constants/api';
import { generateConstructFromFilterColumns } from '../GenericFilterComponent/GenericFilterComponentUtil';
import { COMBINEMODE, generateObjectWithCriteriaMode, getCombined } from '../../sagas/util';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { voucherTypes } from '../CashPaymentVoucher/CashPaymentVoucherUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const getPartnerApi = (onlySuppliers, onlyPayers) => {
    const basePartnerApi = api.SEARCH.RES_PARTNER_BASE;
    if (onlySuppliers && !onlyPayers) {
        return `${basePartnerApi}&isSupplier=true&searchString=`;
    } else if (onlyPayers && !onlySuppliers) {
        return `${basePartnerApi}&isPayor=true&searchString=`;
    }
    return `${basePartnerApi}&isSupplier=true&isPayor=true&searchString=`;
};

const getTypeOptionsForPartner = (onlyPayers, onlySuppliers) => {
    const supplierOptions = [
        { key: voucherTypes.PAYMENT, value: 'Payment' },
        { key: voucherTypes.RECEIVE, value: 'Refunds' },
    ];
    const payerOptions = [
        { key: voucherTypes.PAYBACK, value: 'Payback' },
        { key: voucherTypes.RECEIPT, value: 'Receipt' },
    ];
    const allOptions = [
        ...supplierOptions,
        ...payerOptions,
    ];
    if (onlySuppliers && !onlyPayers) {
        return supplierOptions;
    } else if (onlyPayers && !onlySuppliers) {
        return payerOptions;
    } else if (!onlySuppliers && !onlyPayers) {
        return allOptions;
    }
    // Code must never reach here
    return [];
};

export const accountVoucherTableFilterSchema = (allowDraft, onlyPayers, onlySuppliers) => {
    let filters = [
        {
            name: 'account_voucher.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: false,
            maxDateField: 'account_voucher.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_voucher.date',
                filterOperation: '>=',
            },
        },
        {
            name: 'account_voucher.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            additionalFieldToClear: ['account_voucher.startDate'],
            minDateField: 'account_voucher.startDate',
            defaultValueField: 'account_voucher.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'account_voucher.date',
                filterOperation: '<=',
            },
        },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'res_partner.id',
            type: 'autocomplete',
            label: 'Supplier',
            valueField: 'id',
            disableClear: false,
            dataSourceConfig: {
                text: 'name',
                value: 'id',
            },
            dataSourceApi: getPartnerApi(onlySuppliers, onlyPayers),
            searchConstructFields: {
                name: 'res_partner.id',
                filterOperation: '=',
            },
        },
        {
            name: 'account_voucher.type',
            type: 'select',
            label: 'Type',
            valueField: 'key',
            disableClear: false,
            options: [...getTypeOptionsForPartner(onlyPayers, onlySuppliers)],
            searchConstructFields: {
                name: 'account_voucher.type',
                filterOperation: '=',
            },
        },
    ];
    if (allowDraft) {
        filters = [
            ...filters,
            {
                name: 'account_voucher.state',
                type: 'select',
                label: 'State',
                valueField: 'key',
                disableClear: false,
                options: [
                    {
                        key: 'draft',
                        value: 'Draft',
                    },
                    {
                        key: 'posted',
                        value: 'Posted',
                    },
                    {
                        key: 'cancel',
                        value: 'Cancel',
                    },
                ],
                searchConstructFields: {
                    name: 'account_voucher.state',
                    filterOperation: '=',
                },
            },
        ];
    }
    return filters;
};

const supplierConstruct = {
    type: 'criteria',
    key: 'res_partner.supplier',
    value: true,
    operation: '=',
};

const payerConstruct = {
    type: 'criteria',
    key: 'res_partner.payer',
    value: true,
    operation: '=',
};

const voucherStateConstruct = {
    type: 'criteria',
    key: 'account_voucher.state',
    value: 'draft',
    operation: '!=',
};

export const getAccountVoucherSearchConstructByFilters = (
    filters, onlyPayers, onlySuppliers, allowDraft, allowedPartnerAccounts,
) => {
    const appliedFilters = { ...filters };
    let searchConstruct = null;
    if (onlySuppliers && !onlyPayers) {
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, supplierConstruct);
    } else if (onlyPayers && !onlySuppliers) {
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, payerConstruct);
    }

    if (isArrayValidAndNotEmpty(allowedPartnerAccounts)) {
        let accountTypeConstruct = null;
        allowedPartnerAccounts.forEach((anAccountType) => {
            const anAccountTypeConstruct = generateObjectWithCriteriaMode('partner_account.type', anAccountType, '=');
            accountTypeConstruct = getCombined(accountTypeConstruct, COMBINEMODE.OR, anAccountTypeConstruct);
        });
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, accountTypeConstruct);
    }

    if (!allowDraft) {
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, voucherStateConstruct);
    }

    if (isObjectValidAndNotEmpty(filters)) {
        const searchText = getStringFromObject('searchText', filters);
        const textToSearch = (searchText || '').trim().toUpperCase();
        if (textToSearch) {
            const searchTextConstruct = generateObjectWithCriteriaMode('account_voucher.number', searchText, ':');
            searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, searchTextConstruct);
        }
        const filterConstruct = generateConstructFromFilterColumns(
            accountVoucherTableFilterSchema(allowDraft, onlyPayers, onlySuppliers),
            appliedFilters,
        );
        searchConstruct = getCombined(searchConstruct, COMBINEMODE.AND, filterConstruct);
    }
    return searchConstruct;
};
