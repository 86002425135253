import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import {
    INSURANCE_APPROVAL_PRIV,
    INSURANCE_BUNDLE_PRIVILEGE,
    INSURANCE_CLAIM_PRIV,
    INSURANCE_INVOICES_PRIV,
    INSURANCE_REQUESTS_PRIV, insuranceRequests,
    payorApproval,
    payorBundles,
    payorClaims,
    payorInvoice,
} from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { regexMatcher } from '../../constants/StringSimilarity';
import { getStringFromObject } from '../../constants/lodashUtils';

const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

const payerMangementTabs = {
    APPROVALS: {
        value: 'approvals',
        label: 'Approvals',
        privilege: [INSURANCE_APPROVAL_PRIV],
    },
    INSURANCE_REQUESTS: {
        value: 'insuranceRequests',
        label: 'Insurance Requests',
        privilege: [INSURANCE_REQUESTS_PRIV],
    },
    INVOICES: {
        value: 'invoices',
        label: 'Invoices',
        privilege: [INSURANCE_INVOICES_PRIV],
    },
    CLAIMS: {
        value: 'claims',
        label: 'Claims',
        privilege: [INSURANCE_CLAIM_PRIV],
    },
    BUNDLES: {
        value: 'bundles',
        label: 'Bundles',
        privilege: [INSURANCE_BUNDLE_PRIVILEGE],
    },
};

class PayorManagementContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.changeTabForRoute(props),
        };
    }


    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        const targetPatternObject = {
            '^/payors/requests/.*$': 'insuranceRequests',
            '^/payors/bundles/.*$': 'bundles',
            '^/payors/claims/details/.*$': 'claims',
            '^/payors/claims/.*$': 'claims',
            '^/payors/invoice/.*$': 'invoices',
            '^/payors/approval/all/.*$': 'insuranceRequests',
            '^/payors/approval/.*$': 'approvals',
        };
        const defaultBestMatch = regexMatcher(route, targetPatternObject);
        const defaultBestMatchTab = defaultBestMatch || payerMangementTabs.APPROVALS.value;
        switch (route) {
        case payorApproval: return payerMangementTabs.APPROVALS.value;
        case payorInvoice: return payerMangementTabs.INVOICES.value;
        case payorClaims: return payerMangementTabs.CLAIMS.value;
        case payorBundles: return payerMangementTabs.BUNDLES.value;
        case insuranceRequests: return payerMangementTabs.INSURANCE_REQUESTS.value;
        default: return defaultBestMatchTab;
        }
    };

    changeRouteForSelectedTab = (selected) => {
        let route = '';
        switch (selected) {
        case payerMangementTabs.APPROVALS.value:
            route = payorApproval;
            break;
        case payerMangementTabs.INVOICES.value:
            route = payorInvoice;
            break;
        case payerMangementTabs.CLAIMS.value:
            route = payorClaims;
            break;
        case payerMangementTabs.BUNDLES.value:
            route = payorBundles;
            break;
        case payerMangementTabs.INSURANCE_REQUESTS.value:
            route = insuranceRequests;
            break;
        default:
        }
        if (route) {
            this.props.history.push(route);
        }
    };

    handleChange = (event, selectedTab) => {
        this.setState({
            selectedTab,
        }, () => this.changeRouteForSelectedTab(selectedTab));
    };

    render() {
        const { selectedTab } = this.state;
        const { classes, children } = this.props;
        console.log('TestIsHere', selectedTab);
        return (
            <React.Fragment>
                <div style={{ background: '#fff', padding: '2rem' }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item lg={8} md={8} sm={8}>
                            <AppBar position="static" className={classes.appBar}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    fullWidth
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    {
                                        Object.values(payerMangementTabs).map(aPayerManagementTab => (
                                            checkIfPrivilegeExistsForUser(aPayerManagementTab.privilege) &&
                                            <Tab
                                                test-id={aPayerManagementTab.value}
                                                key={aPayerManagementTab.value}
                                                value={aPayerManagementTab.value}
                                                label={aPayerManagementTab.label}
                                                classes={{
                                                    root: classes.tabRoot,
                                                    selected: classes.tabSelected,
                                                    label: classes.label,
                                                }}
                                                disableRipple
                                            />
                                        ))
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

PayorManagementContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
};

PayorManagementContainer.defaultProps = {};

export default withStyles(style)(PayorManagementContainer);
