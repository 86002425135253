import { takeLatest } from 'redux-saga/effects';
import { createSupplierWorker, fetchPagesOfSupplierWorker } from './supplierWorkerSaga';
import { CREATE_SUPPLIER_REQUEST, FETCH_SUPPLIER_LIST_REQUEST } from '../../redux/modules/supplier/supplier-actions';

export function* fetchPagesOfSupplierWatcher() {
    yield takeLatest(FETCH_SUPPLIER_LIST_REQUEST, fetchPagesOfSupplierWorker);
}

export function* createSupplierWatcher() {
    yield takeLatest(CREATE_SUPPLIER_REQUEST, createSupplierWorker);
}
