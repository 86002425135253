import {
    CLEAR_CONFIRM_DIALOG_WITH_USER_INPUT,
    DISPLAY_CONFIRM_DIALOG_WITH_USER_INPUT,
} from './confirmDialogWithUserInput-actions';

const confirmDialogWithUserInputReducer = (state = {}, action) => {
    let newState;
    switch (action.type) {
    case DISPLAY_CONFIRM_DIALOG_WITH_USER_INPUT:
        newState = {
            message: action.message,
            confirmCallBack: action.confirmCallBack,
            title: action.title,
            cancelLabel: action.cancelLabel,
            confirmLabel: action.confirmLabel,
            validationText: action.validationText,
            open: true,
        };
        break;
    case CLEAR_CONFIRM_DIALOG_WITH_USER_INPUT:
        newState = null;
        break;
    default:
        newState = state;
    }

    return newState;
};

export default confirmDialogWithUserInputReducer;
