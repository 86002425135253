/**
 * Created by sachinprakash on 28/6/19.
 */
import API from '../../../constants/api';

export const FETCH_PACKAGE_LIST_REQUEST = '@@packages/FETCH_PACKAGE_LIST_REQUEST';
export const FETCH_PACKAGE_LIST_FAILURE = '@@packages/FETCH_PACKAGE_LIST_FAILURE';
export const FETCH_PACKAGE_LIST_SUCCESS = '@@packages/FETCH_PACKAGE_LIST_SUCCESS';

export const ADD_OR_UPDATE_PACKAGE_REQUEST = '@@packages/ADD_OR_UPDATE_PACKAGE_REQUEST';
export const ADD_OR_UPDATE_PACKAGE_SUCCESS = '@@packages/ADD_OR_UPDATE_PACKAGE_SUCCESS';
export const ADD_OR_UPDATE_PACKAGE_FAILURE = '@@packages/ADD_OR_UPDATE_PACKAGE_FAILURE';

export const fetchPackagesList = (page, size, sortOrder = 'DESC', sortCol = 'createDate', filter = {}) => ({
    type: FETCH_PACKAGE_LIST_REQUEST,
    api:
        `${API.PACKAGE_SERVICES.PACKAGE}?page=${
            page
        }&size=${size}&sortOrder=${sortOrder}&sortCol=${sortCol}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
});


export const addOrUpdatePackage = (payload, successCallback, failureCallback) => ({
    type: ADD_OR_UPDATE_PACKAGE_REQUEST,
    api: API.PACKAGE_SERVICES.PACKAGE,
    payload,
    successCallback,
    failureCallback,
});
