import initialStates from '../../../initialStates';
import {
    CLEAR_BED_TYPE_STATE, CREATE_BED_TYPE_SUCCESS,
    FETCH_BED_TYPE_LIST_FAILURE,
    FETCH_BED_TYPE_LIST_REQUEST,
    FETCH_BED_TYPE_LIST_SUCCESS,
} from './bedType-actions';

const bedTypeReducer = (state = initialStates.bedType, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_BED_TYPE_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_BED_TYPE_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_BED_TYPE_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;

    case CLEAR_BED_TYPE_STATE:
        newState = initialStates.bedType;
        break;
    case CREATE_BED_TYPE_SUCCESS:
        newState = {
            ...state,
            selectedBedType: action.data,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default bedTypeReducer;
