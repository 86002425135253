/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_PAID_ON_ACCOUNT_STATE,
    CREATE_PAID_ON_ACCOUNT_SUCCESS,
    FETCH_PAID_ON_ACCOUNT_SUCCESS,
} from './paidOnAccount-actions';

const paidOnAccountReducer = (state = initialStates.paidOnAccount, action) => {
    let newState = {};
    switch (action.type) {
    case CREATE_PAID_ON_ACCOUNT_SUCCESS:
    case FETCH_PAID_ON_ACCOUNT_SUCCESS:
        newState = { ...state, selectedPaidOnAccount: action.data };
        break;
    case CLEAR_PAID_ON_ACCOUNT_STATE:
        newState = { ...state, selectedPaidOnAccount: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default paidOnAccountReducer;
