/**
 * Created by sachinprakash on 3/6/19.
 */
import initialStates from '../../initialStates';
import {
    ASSET_CATEGORY_ADD_SUCCESS,
    ASSET_CATEGORY_LIST_CLEAR,
    ASSET_CATEGORY_LIST_FETCH_FAILURE,
    ASSET_CATEGORY_LIST_FETCH_SUCCESS,
} from './assteCategory-actions';
import { ASSET_MANUFACTURER_ADD_FAILURE } from '../assetManufacturer/assetManufacturer-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';

export const assetCategoryList = (state = initialStates.assetCategory, action) => {
    let newState = {};
    console.log('sdaghksdfgkasdhfgaj', action);
    switch (action.type) {
    case ASSET_CATEGORY_LIST_FETCH_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: getStringFromObject('data.content', action, []),
            page: getStringFromObject('data.number', action, 0),
            isLast: getStringFromObject('data.last', action, false),
            totalElements: getStringFromObject('data.totalElements', action, 0),
            filters: getStringFromObject('filters', action, {}),
        });
        break;
    case ASSET_CATEGORY_LIST_FETCH_FAILURE:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: [],
        });
        break;
    case ASSET_CATEGORY_LIST_CLEAR:
        newState = state;
        break;
    default:
        newState = state;
    }
    return newState;
};

export const assetCategoryAdd = (state = initialStates.addAssetCategory, action) => {
    let newState = {};
    switch (action.type) {
    case ASSET_MANUFACTURER_ADD_FAILURE:
    case ASSET_CATEGORY_ADD_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};
