import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrganisationHeader from '../../components/OrganisationComponent/OrganisationHeader';
import { PAGE, profile as profileRoute } from '../../constants/constants';
import { fetchOrganizationList } from '../../redux/modules/organization/organization-actions';

class EmployeeOrganisationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: this.props.organization.searchText,
            positionSelectText: this.props.organization.positionSelectText,
            departmentSelectText: this.props.organization.departmentSelectText,
            empType: this.props.organization.empType,
            showDeactivated: this.props.organization.showDeactivated,
        };
    }

    getDataBySearch = (search) => {
        this.setState({ searchText: search }, () => this.fetchList());
    };

    handleEmpType = (event) => {
        this.setState({
            empType: event.target.value,
            searchText: '',
            positionSelectText: '',
            departmentSelectText: '',
        }, () => this.fetchList());
    };

    handleSearchText = (event) => {
        this.setState({
            searchText: event.target.value,
            empType: '',
        });
    };

    handleSelectPositionText = fieldName => (value) => {
        this.setState({
            [fieldName]: value,
            empType: '',
        }, this.fetchList);
    };

    handleSelectDepartmentText = fieldName => (value) => {
        this.setState({
            [fieldName]: value,
            empType: '',
        }, this.fetchList);
    };

    handleShowDeactivatedChange = (e, checked) => {
        this.setState({
            showDeactivated: checked,
        }, this.fetchList);
    };

    increasePage = () => {
        const { organization } = this.props;
        let { page } = organization;
        page += 1;
        if (page < organization.totalPages) {
            this.fetchList(page);
        }
    };

    handleProfileClick = () => {
        this.props.history.push(profileRoute);
    };

    handleTabClick = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    decreasePage = () => {
        const { organization } = this.props;
        let { page } = organization;
        page -= 1;
        if (page >= 0) {
            this.fetchList(page);
        }
    };

    fetchList = (page = PAGE) => {
        const { dispatch, organization, profile: { currentUserData } } = this.props;
        const {
            searchText,
            empType,
            positionSelectText,
            departmentSelectText,
            showDeactivated,
        } = this.state;
        let reportingTo = null;
        if (empType === 'myTeam' && currentUserData) {
            reportingTo = currentUserData.uuid;
        } else if (empType === 'myPeers' && currentUserData) {
            reportingTo = currentUserData.reportingTo ? currentUserData.reportingTo.key : null;
        }
        dispatch(fetchOrganizationList({
            searchText,
            reportingTo,
            positionSelectText,
            departmentSelectText,
            showDeactivated,
        }, page, organization.size));
    };

    render() {
        const { children, history, organization } = this.props;
        const {
            searchText,
            positionSelectText,
            departmentSelectText,
            empType,
            showDeactivated,
        } = this.state;

        return (
            <React.Fragment>
                <OrganisationHeader
                    organization={organization}
                    onLeftArrowClick={this.decreasePage}
                    onRightArrowClick={this.increasePage}
                    location={history.location}
                    selectedFilter={empType}
                    searchText={searchText}
                    positionSelectText={positionSelectText}
                    departmentSelectText={departmentSelectText}
                    getDataBySearch={this.getDataBySearch}
                    onSearchTextChange={this.handleSearchText}
                    onPositionSelectTextChange={this.handleSelectPositionText}
                    onDepartmentSelectTextChange={this.handleSelectDepartmentText}
                    onShowDeactivatedChange={this.handleShowDeactivatedChange}
                    handleFilterChange={this.handleEmpType}
                    onTabClick={this.handleTabClick}
                    showDeactivated={showDeactivated}
                />
                <div style={{ padding: '2em' }}>
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    organization: state.organization,
    profile: state.profile,
});

EmployeeOrganisationContainer.propTypes = {
    history: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(EmployeeOrganisationContainer);
