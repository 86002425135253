/**
 * Created by user on 18/7/17.
 */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Resumablejs from 'resumablejs';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { isJsonString } from '../../../constants/CommonUtil';
import { errorMessage } from '../../../redux/modules/message/message-actions';

export default class ReactResumableJs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressBar: 0,
            messageStatus: '',
            fileList: { files: [] },
            isPaused: false,
            isUploading: false,
        };
        this.resumable = null;
    }

    componentDidMount = () => {
        console.log('resumable', this.props.filetypes);
        const ResumableField = new Resumablejs({
            target: this.props.service,
            query: this.props.query || {},
            fileType: this.props.filetypes,
            maxFiles: this.props.maxFiles,
            maxFileSize: this.props.maxFileSize,
            fileTypeErrorCallback: (file, errorCount) => {
                if (typeof this.props.onFileAddedError === 'function') {
                    this.props.onFileAddedError(file, errorCount);
                }
            },
            maxFileSizeErrorCallback: (file, errorCount) => {
                if (
                    typeof this.props.onMaxFileSizeErrorCallback === 'function'
                ) {
                    this.props.onMaxFileSizeErrorCallback(file, errorCount);
                }
            },
            testMethod: this.props.testMethod || 'post',
            testChunks: this.props.testChunks || false,
            headers: this.props.headerObject || {},
            chunkSize: this.props.chunkSize,
            simultaneousUploads: this.props.simultaneousUploads,
            fileParameterName: this.props.fileParameterName,
            generateUniqueIdentifier: this.props.generateUniqueIdentifier,
            forceChunkSize: this.props.forceChunkSize,
        });

        if (typeof this.props.maxFilesErrorCallback === 'function') {
            ResumableField.opts.maxFilesErrorCallback = this.props.maxFilesErrorCallback;
        }

        ResumableField.assignBrowse(this.uploader);

        // Enable or Disable DragAnd Drop
        if (this.props.disableDragAndDrop === false) {
            console.log('DISABLED DRAG AND D'); // eslint-disable-line
            ResumableField.assignDrop(this.dropZone);
        }

        ResumableField.on('error', (e) => {
            let errorMsg = 'Failed To Upload File: ';
            if (e && isJsonString(e)) {
                const errObj = JSON.parse(e);
                console.log('as09du0aduas0suad0aas-da-dais0asidsasad', errObj);
                errorMsg = `${errorMsg}${errObj.exception}`;
            }
            this.props.dispatch(errorMessage(errorMsg));
        });

        ResumableField.on('fileAdded', (file) => {
            // eslint-disable-line
            console.log('File added muh maan', file); // eslint-disable-line
            this.setState({
                messageStatus: this.props.fileAddedMessage || ' Starting upload! ',
            });

            if (typeof this.props.onFileAdded === 'function') {
                this.props.onFileAdded(file, this.resumable);
            } else {
                ResumableField.upload();
            }
        });

        ResumableField.on('fileSuccess', (file, fileServer) => {
            if (this.props.fileNameServer) {
                const objectServer = JSON.parse(fileServer);
                file.fileName = objectServer[this.props.fileNameServer]; // eslint-disable-line
            } else {
                file.fileName = fileServer; // eslint-disable-line
            }

            const currentFiles = this.state.fileList.files;
            currentFiles.push(file);

            this.setState(
                {
                    fileList: { files: currentFiles },
                    messageStatus: this.props.completedMessage || fileServer,
                },
                () => {
                    if (typeof this.props.onFileSuccess === 'function') {
                        this.props.onFileSuccess(file, fileServer);
                    }
                },
            );
        });

        ResumableField.on('progress', () => {
            this.setState({
                isUploading: ResumableField.isUploading(),
            });
            if (
                this.props.progressBar &&
                ResumableField.progress() * 100 < 100
            ) {
                this.setState({
                    messageStatus: `${parseInt(
                        ResumableField.progress() * 100,
                        10,
                    )}%`,
                    progressBar: ResumableField.progress() * 100,
                });
            } else {
                this.setState({
                    progressBar: 0,
                });
            }
        });

        ResumableField.on('fileError', (file, errorCount) => {
            this.props.onUploadErrorCallback(file, errorCount);
        });

        this.resumable = ResumableField;
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.disableDragAndDrop !== this.props.disableDragAndDrop) {
            if (nextProps.disableDragAndDrop) {
                this.resumable.unAssignDrop(this.dropZone);
                this.dropZone.addEventListener(
                    'dragover',
                    (e) => {
                        e = e || event; // eslint-disable-line
                        e.preventDefault();
                    },
                    false,
                );
                this.dropZone.addEventListener(
                    'drop',
                    (e) => {
                        e = e || event; // eslint-disable-line
                        e.preventDefault();
                    },
                    false,
                );
            } else {
                this.resumable.assignDrop(this.dropZone);
            }
        }
    }

    createFileList = () => {
        const markup = this.state.fileList.files.map((file, index) => {
            const uniqID = `${this.props.uploaderID}-${index}`;
            const originFile = file.file;
            let media = '';
            if (file.file.type.indexOf('video') > -1) {
                media = <label htmlFor={originFile.name} className="video">{originFile.name}</label>;
                return (
                    <li className="thumbnail" key={uniqID}>
                        <label id={`media_${uniqID}`}>{media}</label>
                        <span
                            role="presentation"
                            onKeyDown={event =>
                                this.removeFile(event, file, index)
                            }
                            onClick={event =>
                                this.removeFile(event, file, index)
                            } // eslint-disable-line
                        >
                            [X]
                        </span>
                    </li>
                );
            } else if (file.file.type.indexOf('image') > -1) {
                if (this.props.tmpDir !== '') {
                    const src = this.props.tmpDir + file.fileName;
                    media = (
                        <img className="image" width="80" src={src} alt="" />
                    );
                    return (
                        <li className="thumbnail" key={uniqID}>
                            <label id={`media_${uniqID}`}>{media}</label>
                            <span
                                role="presentation"
                                onKeyDown={event =>
                                    this.removeFile(event, file, index)
                                }
                                onClick={event =>
                                    this.removeFile(event, file, index)
                                } // eslint-disable-line
                            >
                                [X]
                            </span>
                        </li>
                    );
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(originFile);
                fileReader.onload = (event) => {
                    media = (
                        <img
                            className="image"
                            width="80"
                            src={event.target.result}
                            alt=""
                        />
                    );
                    document.querySelector(
                        `#media_${uniqID}`,
                    ).innerHTML = media;
                };
                return (
                    <li className="thumbnail" key={uniqID}>
                        <label id={`media_${uniqID}`} />
                        <span
                            role="presentation"
                            onKeyDown={event =>
                                this.removeFile(event, file, index)
                            }
                            onClick={event =>
                                this.removeFile(event, file, index)
                            } // eslint-disable-line
                        >
                            [X]
                        </span>
                    </li>
                );
            }
            // eslint-disable-line
            media = <label className="document">{originFile.name}</label>;
            return (
                <li className="thumbnail" key={uniqID}>
                    <label id={`media_${uniqID}`}>{media}</label>
                    <span
                        role="presentation"
                        onKeyDown={event =>
                            this.removeFile(event, file, index)
                        }
                        onClick={event =>
                            this.removeFile(event, file, index)
                        } // eslint-disable-line
                    >
                        [X]
                    </span>
                </li>
            );
        });
        return <ul id={`items-${this.props.uploaderID}`}>{markup}</ul>;
    };

    removeImage = (fileName) => {
        if (this.state.fileList && this.state.fileList.files) {
            const { files } = this.state.fileList;
            files.map((file, index) => {
                if (file.fileName === fileName) {
                    this.removeFile(null, file, index);
                }
                return null;
            });
        }
    };

    removeFile = (event, file, index) => {
        if (event) {
            event.preventDefault();
        }
        const currentFileList = this.state.fileList.files;
        delete currentFileList[index];

        this.setState({
            fileList: { files: currentFileList },
        });

        this.props.onFileRemoved(file);
        this.resumable.removeFile(file);
    };

    cancelUpload = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.resumable.cancel();
        this.setState({
            fileList: { files: [] },
            messageStatus: 'Upload Cancelled',
        });
        this.props.onCancelUpload();
    };

    pauseUpload = () => {
        if (!this.state.isPaused) {
            this.resumable.pause();
            this.setState({
                isPaused: true,
            });
            this.props.onPauseUpload();
        } else {
            this.resumable.upload();
            this.setState({
                isPaused: false,
            });
            this.props.onResumeUpload();
        }
    };

    startUpload = () => {
        this.resumable.upload();
        this.setState({
            isPaused: false,
        });
        this.props.onStartUpload();
    };

    render() {
        let fileList = null;
        if (this.props.showFileList) {
            fileList = (
                <div className="resumable-list">{this.createFileList()}</div>
            );
        }
        let previousText = null;
        if (this.props.previousText) {
            if (typeof this.props.previousText === 'string') {
                previousText = (
                    <Grid item sm={7} md={7} lg={7} onClick={this.props.onFileClick} className="cursor-pointer">
                        <div style={{ float: 'left', width: '100%' }}>
                            <div
                                style={{
                                    padding: '15px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                }}
                            >
                                <span style={{ color: 'rgb(33, 150, 243)' }}>
                                    {this.props.previousText}
                                </span>
                            </div>
                        </div>
                    </Grid>
                );
            } else {
                previousText = this.props.previousText;
            }
        }
        let textLabel = null;
        if (this.props.textLabel) {
            textLabel = this.props.textLabel;
        }
        let startButton = null;
        if (this.props.startButton) {
            if (
                typeof this.props.startButton === 'string' ||
                typeof this.props.startButton === 'boolean'
            ) {
                startButton = (
                    <label>
                        <button
                            disabled={this.state.isUploading}
                            className="btn start"
                            onClick={this.startUpload}
                        >
                            {this.props.startButton && 'upload'}
                        </button>
                    </label>
                );
            } else {
                startButton = this.props.startButton;
            }
        }

        let cancelButton = null;
        if (this.props.cancelButton) {
            if (
                typeof this.props.cancelButton === 'string' ||
                typeof this.props.cancelButton === 'boolean'
            ) {
                cancelButton = (
                    <label>
                        <button
                            disabled={!this.state.isUploading}
                            className="btn cancel"
                            onClick={this.cancelUpload}
                        >
                            {this.props.cancelButton && 'cancel'}
                        </button>
                    </label>
                );
            } else {
                cancelButton = this.props.cancelButton;
            }
        }

        let pauseButton = null;
        if (this.props.pauseButton) {
            if (
                typeof this.props.pauseButton === 'string' ||
                typeof this.props.pauseButton === 'boolean'
            ) {
                pauseButton = (
                    <label>
                        <button
                            disabled={!this.state.isUploading}
                            className="btn pause"
                            onClick={this.pauseUpload}
                        >
                            {this.props.pauseButton && 'pause'}
                        </button>
                    </label>
                );
            } else {
                pauseButton = this.props.pauseButton;
            }
        }
        let inputHidden = this.props.hideInputTag || false;
        if (this.props.browseFileButton) {
            inputHidden = true;
        }
        console.log('MESAGE STATUS', this.props, this.state);
        return (
                <div
                    id={this.props.dropTargetID}
                    ref={(node) => {
                        this.dropZone = node;
                    }} // eslint-disable-line
                >
                    <Grid container>
                        {
                            !this.state.isUploading &&
                            previousText
                        }
                        {
                            this.state.isUploading &&
                                <Grid item sm={7} md={7} lg={7} className="flexChildren justify-content-center">
                                    <CircularProgress />
                                </Grid>
                        }
                        <Grid item sm={5} lg={5} md={5}>
                            <label
                                className={
                                    this.props.disableInput
                                        ? 'btn file-upload disabled'
                                        : 'btn file-upload'
                                }
                            >
                                {textLabel}
                                {this.props.progressBar
                                    ? this.state.messageStatus
                                    : ''}
                                <input
                                    ref={(node) => {
                                        this.uploader = node;
                                    }} // eslint-disable-line
                                    type="file"
                                    id={this.props.uploaderID}
                                    className="btn"
                                    name={`${this.props.uploaderID}-upload`}
                                    accept={this.props.fileAccept || '*'}
                                    style={{
                                        display: inputHidden ? 'none' : 'block',
                                        float: 'right',
                                    }}
                                    disabled={this.props.disableInput || false}
                                />
                                {this.props.browseFileButton}
                            </label>
                            {this.props.progressBar && (
                                <div
                                    className="progress"
                                    style={{
                                        display:
                                            this.state.progressBar === 0
                                                ? 'none'
                                                : 'block',
                                    }}
                                >
                                    <div
                                        className="progress-bar"
                                        style={{
                                            width: `${this.state.progressBar}%`,
                                        }}
                                    />
                                </div>
                            )}
                            {fileList}
                            {startButton}
                            {pauseButton}
                            {this.state.isUploading && cancelButton}
                        </Grid>
                    </Grid>
                </div>
        );
    }
}

ReactResumableJs.propTypes = {
    service: PropTypes.string,
    query: PropTypes.string,
    filetypes: PropTypes.array,
    maxFiles: PropTypes.number,
    maxFileSize: PropTypes.number,
    onFileAddedError: PropTypes.func,
    disableInput: PropTypes.bool,
    fileAccept: PropTypes.string,
    uploaderID: PropTypes.string,
    dropTargetID: PropTypes.string,
    pauseButton: PropTypes.bool,
    cancelButton: PropTypes.bool,
    startButton: PropTypes.bool,
    textLabel: PropTypes.string,
    previousText: PropTypes.string,
    showFileList: PropTypes.bool,
    onStartUpload: PropTypes.func,
    onResumeUpload: PropTypes.func,
    onPauseUpload: PropTypes.func,
    onCancelUpload: PropTypes.func,
    onFileRemoved: PropTypes.func,
    tmpDir: PropTypes.string,
    onUploadErrorCallback: PropTypes.func,
    onFileSuccess: PropTypes.func,
    completedMessage: PropTypes.string,
    fileNameServer: PropTypes.string,
    onFileAdded: PropTypes.func,
    fileAddedMessage: PropTypes.string,
    disableDragAndDrop: PropTypes.bool,
    maxFilesErrorCallback: PropTypes.func,
    dispatch: PropTypes.func,
    onFileClick: PropTypes.func,
    forceChunkSize: PropTypes.bool,
    generateUniqueIdentifier: PropTypes.any,
    fileParameterName: PropTypes.string,
    simultaneousUploads: PropTypes.number,
    chunkSize: PropTypes.number,
    headerObject: PropTypes.object,
    testChunks: PropTypes.any, // eslint-disable-line
    testMethod: PropTypes.func, // eslint-disable-line
    onMaxFileSizeErrorCallback: PropTypes.func,
    browseFileButton: PropTypes.node,
    hideInputTag: PropTypes.bool,
    progressBar: PropTypes.bool,
};

ReactResumableJs.defaultProps = {
    maxFiles: undefined,
    uploaderID: 'default-resumable-uploader',
    dropTargetID: 'dropTarget',
    filetypes: [],
    fileAccept: '*',
    maxFileSize: 10240000,
    showFileList: true,
    onUploadErrorCallback: (file, errorCount) => {
        console.log('error', file, errorCount);
    },
    onFileRemoved: file => file,
    onCancelUpload: () => true,
    onPauseUpload: () => true,
    onResumeUpload: () => true,
    onStartUpload: () => true,
    onFileAddedError: () => {},
    onFileSuccess: () => {},
    onMaxFileSizeErrorCallback: () => {},
    onFileAdded: () => {},
    onFileClick: () => {},
    dispatch: () => {},
    disableDragAndDrop: false,
    browseFileButton: <div />,
    fileNameServer: '',
    tmpDir: '',
    chunkSize: 1024 * 1024,
    simultaneousUploads: 1,
    fileParameterName: 'file',
    generateUniqueIdentifier: null,
    maxFilesErrorCallback: null,
    cancelButton: false,
    hideInputTag: false,
    progressBar: false,
    startButton: null,
    pauseButton: null,
    previousText: '',
    query: '',
    service: '',
    textLabel: '',
    completedMessage: 'File Uploaded Successfully',
    fileAddedMessage: 'File Added Successfully',
    headerObject: {},
    forceChunkSize: false,
    disableInput: false,
};
