import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles/index';
import { connect } from 'react-redux';
import SnackBarContent from './SnackbarContent';
import { clearMessage } from '../../redux/modules/message/message-actions';

import { getStringFromObject } from '../../constants/lodashUtils';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
});

class SnackBar extends React.PureComponent {
    handleRequestClose = () => {
        this.props.dispatch(clearMessage());
    };
    render() {
        const {
            message,
            classes,
        } = this.props;
        const canShow = getStringFromObject('canShow', message);
        const messageToDisplay = getStringFromObject('message', message);
        const type = getStringFromObject('type', message);
        return (
            <Snackbar
                open={Boolean(canShow)}
                autoHideDuration={5000}
                onClose={this.handleRequestClose}
                name="snackbardisplay"
            >
                <SnackBarContent
                    onClose={this.handleRequestClose}
                    className={classes.margin}
                    variant={type}
                    message={messageToDisplay}
                />
            </Snackbar>
        );
    }
}

SnackBar.propTypes = {
    message: PropTypes.object,
    dispatch: PropTypes.func,
    classes: PropTypes.object,
};

SnackBar.defaultProps = {
    message: {},
    classes: {},
    dispatch: () => {},
};

const mapStateToProps = state => ({
    message: state.message,
});

export default connect(mapStateToProps)(withStyles(styles)(SnackBar));

