import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field, withFormik } from 'formik';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import classNames from 'classnames';

import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import { required } from '../../../constants/FormValidations';
import API from '../../../constants/api';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import {
    apiCatchBlockFunction,
    formatCurrency,
    getAllowedDefaultAccountTypes, getJsonPath,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import FormikCheckbox from '../../Formik/FieldComponents/FormikCheckbox';
import { ACTION_HANDLERS } from './CreditOrDebitMemoHandlers';
import { APPLICATION_CONFIG_URL, ROWS_PER_PAGE } from '../../../constants/constants';
import { getAccountInvoiceFromUiObject, getUiObject } from '../../../mapper/CreditOrDebitMemoMapper';
import {
    clearSelectedAccountInvoice,
    createCreditOrDebitMemoRequest,
    fetchAccountInvoiceById,
} from '../../../redux/modules/accountInvoice/accountInvoice-actions';
import ActionButton from '../../ActionButton/ActionButton';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import { getDateInYYYYMMDDFormat, isDateAfter, parseDate } from '../../../constants/DateUtil';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { getPartnerApi } from '../../AccountVoucherTable/AccountVoucherTableUtil';
import { fetchResPartnerDto } from '../../CashPaymentVoucher/CashPaymentVoucherUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'creditOrDebitMemo';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    paper: {
        background: '#fafafa',
    },
    ...dialogComponentStyles(),
});


class CreditOrDebitMemoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productSchema: null,
            print: false,
            editDate: false,
            resPartnerDto: null,
        };
    }

    componentDidMount() {
        const { invoiceId } = this.props;
        this.getCreditMemo(invoiceId);

        fetch(getJsonPath('/StockManagement/CreditOrDebitMemoProduct.json'))
            .then(response => response.json())
            .then((schema) => {
                this.setState({
                    productSchema: schema,
                });
            })
            .catch((error) => {
                console.log('Error While Fetching Json Schema', error);
            });
    }

    componentDidUpdate(prevProps) {
        const previousPartnerUuid = getStringFromObject('values.supplier.uuid', prevProps);
        const partnerUuid = getStringFromObject('values.supplier.uuid', this.props);
        if (
            partnerUuid &&
            previousPartnerUuid !== partnerUuid
        ) {
            this.updateResPartnerDto();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountInvoice());
    }

    onChangeDate = async () => {
        const {
            dispatch,
            values,
        } = this.props;
        const dateInvoice = getStringFromObject('dateInvoice', values);
        console.log('as0d9ua0d9au-dasdi0asda', dateInvoice, values);
        const theDate = parseDate(dateInvoice, 'yyyy-MM-dd');
        if (isDateAfter(theDate, new Date())) {
            dispatch(errorMessage('Date cannot be in the future'));
        } else {
            try {
                dispatch(showSpinner());
                await axios.put(`${API.ACCOUNT_INVOICE.CHANGE_CREDIT_MEMO_DATE}${values.uuid}&dateStr=${dateInvoice}`);
                dispatch(hideSpinner());
                dispatch(successMessage('Credit memo date changed successfully'));
                this.toggleEditDate();
                const { invoiceId } = this.props;
                this.getCreditMemo(invoiceId);
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
    };

    getCreditMemo = (invoiceId) => {
        const { dispatch } = this.props;
        if (invoiceId) {
            dispatch(fetchAccountInvoiceById(invoiceId));
        }
    };

    getExtraRows = () => {
        const { values } = this.props;
        const uuid = getStringFromObject('uuid', values);
        const products = getStringFromObject('accountInvoiceLines', values, []);
        let price = 0;
        let untaxed = 0;
        let tax = 0;
        if (isArrayValidAndNotEmpty(products)) {
            products.forEach((j) => {
                price += NumberOf(j.total);
                untaxed += NumberOf(j.subTotal);
                tax += NumberOf(j.totalTax);
            });
        }
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Sub Total</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(untaxed)}</h4>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Tax</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(tax)}</h4>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={uuid ? 9 : 10} />
                    <TableCell colSpan={1}>
                        <h4>Total</h4>
                    </TableCell>
                    <TableCell>
                        <h4>{roundedValueFixedToTwoDigits(price)}</h4>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    updateResPartnerDto = () => {
        const { dispatch } = this.props;
        const partner = getStringFromObject('values.supplier', this.props);
        if (
            isObjectValidAndNotEmpty(partner) &&
            !isStringNullOrUndefined(partner.subCompany)
        ) {
            this.setState({
                resPartnerDto: partner,
            });
        } else {
            const resPartnerDto = fetchResPartnerDto(partner, dispatch);
            if (isObjectValidAndNotEmpty(resPartnerDto)) {
                this.setState({
                    resPartnerDto,
                });
            }
        }
    }

    handleConfirm = state => () => {
        const {
            submitForm,
            setFieldValue,
            values,
            setValues,
            dispatch,
            isCreditMemo,
        } = this.props;
        if (values.dateInvoice) {
            const theDate = parseDate(values.dateInvoice, 'yyyy-MM-dd');
            if (isDateAfter(theDate, new Date())) {
                dispatch(errorMessage('Date cannot be in the future'));
                return;
            }
        }
        setFieldValue('callBack', this.getCreditMemo);
        console.info('asd98as0d9asyd0sa9', values);
        setFieldValue('failureCallBack', () => {
            console.info('asd0aduasdas0-as', values);
            setValues(values);
        });
        setFieldValue('state', state);
        setFieldValue('isCreditMemo', isCreditMemo);
        submitForm();
    };

    handlePrint = () => {
        this.setState(prev => ({
            print: !prev.print,
        }));
    };

    handleDelete = () => {
        const {
            dispatch,
            values,
        } = this.props;
        dispatch(displayWarning(
            'Are you sure you want to delete this memo?',
            async () => {
                try {
                    const uuid = getStringFromObject('uuid', values);
                    dispatch(showSpinner());
                    await axios.delete(`${API.ACCOUNT_INVOICE.DELETE_CREDIT_MEMO}${uuid}`);
                    dispatch(hideSpinner());
                    dispatch(successMessage('Memo deleted successfully'));
                    this.props.handleClose();
                } catch (e) {
                    apiCatchBlockFunction(e, dispatch);
                }
            },
        ));
    };

    stockToReturnCheckboxHandler = (value) => {
        if (!value) {
            this.props.setFieldValue('accountInvoiceLines', []);
        }
    };

    toggleEditDate = (_, resetValue) => {
        const {
            creditOrDebitMemo,
        } = this.props;
        if (resetValue) {
            this.props.setFieldValue('dateInvoice', getDateInYYYYMMDDFormat(creditOrDebitMemo.invoiceDateTime));
        }

        this.setState(prevState => ({
            editDate: !prevState.editDate,
        }));
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            appConfiguration,
            isCreditMemo,
            onlyPayers,
            onlySuppliers,
        } = this.props;
        const {
            editDate,
            resPartnerDto,
        } = this.state;
        console.log('afjahfkjajfjla', values);
        const subCompany = getStringFromObject('subCompany', resPartnerDto) || '';
        const isCancelled = getStringFromObject('state', values) === 'cancel';
        const view = (getStringFromObject('state', values) === 'open') || isCancelled;
        const stockToReturn = getStringFromObject('stockToReturn', values, false);
        const stockLocation = getStringFromObject('stockLocation.uuid', values);
        const creditAmount = getStringFromObject('creditAmount', values, false);
        const taxAccount = getStringFromObject('taxAccount', values, false);
        console.log('discountscottie', taxAccount);
        const { productSchema } = this.state;
        const products = getStringFromObject('accountInvoiceLines', values, []);
        let totalCreditAmount = 0;
        let amountUntaxed = 0;
        let amountTax = 0;
        if (!stockToReturn) {
            amountTax = NumberOf(creditAmount) * NumberOf(getStringFromObject('amount', taxAccount));
            totalCreditAmount = creditAmount !== null ? NumberOf(creditAmount) + NumberOf(amountTax) : 0;
        } else if (isArrayValidAndNotEmpty(products)) {
            products.forEach((j) => {
                totalCreditAmount += NumberOf(j.total);
                amountUntaxed += NumberOf(j.subTotal);
                amountTax += NumberOf(j.totalTax);
            });
        }
        const printData = {
            ...values,
            credit: !stockToReturn,
            total: totalCreditAmount,
            amountUntaxed,
            amountTax,
            companyName: getStringFromObject('companyName', appConfiguration),
        };


        return (
            <Dialog
                open={open}
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div className={classes.header}>
                            {isCreditMemo ? 'Credit Memo' : 'Debit Memo'} {isCancelled ? '[CANCELLED]' : ''}
                        </div>
                        <Close
                            className={classNames(classes.closeIcon, 'cursor-pointer')}
                            onClick={handleClose}
                            test-id="close-credit-debit-stock"
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container className="mt-1 flexChildren" spacing={32}>
                        <Grid item lg={3} sm={4} md={3}>
                            <Field
                                testId="supplier"
                                name="supplier"
                                component={FormikReactSelectMaterial}
                                dataSourceConfig={{
                                    text: 'name',
                                    value: 'uuid',
                                }}
                                label={isCreditMemo ? 'Payer' : 'Supplier'}
                                autocomplete
                                validate={required}
                                required
                                isDisabled={view}
                                dataSourceApi={getPartnerApi(onlySuppliers, onlyPayers)}
                            />
                        </Grid>
                        <Grid item lg={3} sm={4} md={3} className="flexChildren">
                            <Field
                                name="dateInvoice"
                                component={FormikTextField}
                                validate={required}
                                type="date"
                                variant="outlined"
                                label={isCreditMemo ? 'Credit Date' : 'Debit Date'}
                                required
                                disabled={view ? !editDate : view}
                            />
                            {
                                view &&
                                <React.Fragment>
                                    {
                                        editDate ?
                                            <div className="flexChildren">
                                                <IconButton onClick={() => { this.toggleEditDate(null, true); }}>
                                                    <Cancel />
                                                </IconButton>
                                                <IconButton onClick={this.onChangeDate}>
                                                    <Save />
                                                </IconButton>
                                            </div> :
                                            <IconButton onClick={this.toggleEditDate}>
                                                <Edit />
                                            </IconButton>
                                    }
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item lg={5} sm={3} md={5}>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    Total Amount
                                    <h3>
                                        {getStringFromObject('currency', appConfiguration)}&nbsp;
                                        {formatCurrency(roundedValueFixedToTwoDigits(totalCreditAmount))}
                                    </h3>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="mt-2" spacing={32}>
                        {
                            !view && !isCreditMemo &&
                                <Grid item lg={2} sm={3} md={2}>
                                    <Field
                                        name="stockToReturn"
                                        label="Stock To Return"
                                        component={FormikCheckbox}
                                        formControlProps={{
                                            labelPlacement: 'start',
                                        }}
                                        onChangeHandlers={['onChangeHandler']}
                                        actionHandlers={{
                                            onChangeHandler: this.stockToReturnCheckboxHandler,
                                        }}
                                    />
                                </Grid>
                        }
                        {
                            !stockToReturn &&
                            <React.Fragment>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        name="account"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label={isCreditMemo ? 'Debit Account' : 'Credit Account'}
                                        autocomplete
                                        isDisabled={view}
                                        required
                                        validate={required}
                                        dataSourceApi={`${API.SEARCH.ACCOUNTS}?type=${getAllowedDefaultAccountTypes('creditOrDebitMemoAccountTypes', this.props.accountTypes)}&subCompany=${subCompany}&searchString=`}
                                    />
                                    {
                                        isCreditMemo ? (
                                            <div>
                                                The amount is increased to accounts payable associated with the payer,
                                                select a account for debit
                                            </div>
                                        ) : (
                                            <div>
                                                The amount is reduced from accounts payable associated with the supplier,
                                                select a purchase return account for credit
                                            </div>
                                        )
                                    }
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        name="creditAmount"
                                        component={FormikTextField}
                                        validate={required}
                                        variant="outlined"
                                        label="Amount"
                                        required
                                        disabled={view}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        name="taxAccount"
                                        component={FormikReactSelectMaterial}
                                        variant="outlined"
                                        label="Tax"
                                        disabled={view}
                                        dataSourceApi={`${API.SEARCH.PURCHASE_TAXES}?size=20&name=`}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'id',
                                        }}
                                        testId="taxAccount"
                                        autocomplete
                                        isDisabled={view}
                                    />
                                </Grid>
                                {
                                    view &&
                                    <Grid item lg={3} sm={3} md={3}>
                                        <Field
                                            name="residualAmount"
                                            component={FormikTextField}
                                            variant="outlined"
                                            label="Residual Amount"
                                            disabled
                                        />
                                    </Grid>
                                }
                            </React.Fragment>
                        }
                        {
                            stockToReturn && !view &&
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="stock-location"
                                        name="stockLocation"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'id',
                                        }}
                                        label="Stock Location"
                                        autocomplete
                                        required
                                        isDisabled={view}
                                        validate={required}
                                        dataSourceApi={API.SEARCH.INTERNAL_STOCK_LOCATION}
                                    />
                                </Grid>
                        }
                    </Grid>
                    <Grid container className="mt-2">
                        {
                            stockToReturn && (stockLocation || view) && isObjectValidAndNotEmpty(productSchema) &&
                            <FormikTable
                                fieldName="accountInvoiceLines"
                                enablePagination
                                showRowNumber
                                tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                deleteIcon
                                getExtraRows={this.getExtraRows}
                                actionHandlers={ACTION_HANDLERS}
                                actionParams={{
                                    sourceLoc: values.stockLocation,
                                }}
                                rowsPerPageOptions={ROWS_PER_PAGE}
                                {...productSchema}
                                isEditable={!view}
                                formValues={values}
                            />
                        }
                    </Grid>
                    <Grid container className="mt-2">
                        <Grid item lg={3} md={3} sm={3}>
                            <Field
                                testId="memo"
                                name="comment"
                                component={FormikTextField}
                                label="Memo"
                                multiline
                                rows={3}
                                rowsMax={3}
                                fullWidth
                                disabled={view}
                                validate={required}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Print
                        data={printData}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/CreditMemo.html`}
                        print={this.state.print}
                        subCompany={subCompany}
                    />
                </DialogContent>
                {
                    !isCancelled &&
                    <DialogActions>
                        <ActionButton
                            onClick={handleClose}
                            primary={false}
                        >
                            Cancel
                        </ActionButton>
                        {
                            !view &&
                            <ActionButton
                                testId="Save"
                                onClick={this.handleConfirm('draft')}
                                disabled={!(totalCreditAmount > 0)}
                            >
                                SAVE
                            </ActionButton>
                        }
                        {
                            !view && values.uuid &&
                            <ActionButton
                                onClick={this.handleConfirm('open')}
                                disabled={!(totalCreditAmount > 0)}
                            >
                                Confirm
                            </ActionButton>
                        }
                        {
                            view &&
                            <ActionButton
                                disableRipple
                                toolTipTitle={<div>Print</div>}
                                primary
                                onClick={this.handlePrint}
                                disableTool={false}
                            >
                                Print
                            </ActionButton>
                        }
                        {
                            (values.uuid) &&
                            <ActionButton
                                disableRipple
                                primary
                                onClick={this.handleDelete}
                                disableTool={false}
                            >
                                Delete
                            </ActionButton>
                        }
                    </DialogActions>
                }
            </Dialog>
        );
    }
}


CreditOrDebitMemoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    appConfiguration: PropTypes.object,
    values: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    creditOrDebitMemo: PropTypes.object,
    invoiceId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    isCreditMemo: PropTypes.bool,
    // if both payer and supplier are false then both payers and suppliers are fetched
    // if any one is true only that partner is fetched
    onlyPayers: false,
    onlySuppliers: false,
    accountTypes: PropTypes.object,
};

CreditOrDebitMemoDialog.defaultProps = {
    appConfiguration: {},
    invoiceId: null,
    creditOrDebitMemo: null,
    isCreditMemo: false,
    onlyPayers: false,
    onlySuppliers: false,
    accountTypes: {},
};

const handleSubmitForm = (values, { props }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    const callback = getStringFromObject('callBack', values);
    const failureCallBack = getStringFromObject('failureCallBack', values);
    dispatcher(createCreditOrDebitMemoRequest(getAccountInvoiceFromUiObject(values), (r) => {
        callback(getStringFromObject('id', r));
    }, () => {
        failureCallBack();
    }));
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    creditOrDebitMemo: getStringFromObject('accountInvoice.selectedInvoice[0]', state, {}),
    accountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
});

export default connect(mapStateToProps)((withFormik({
    mapPropsToValues: props => getUiObject(props.creditOrDebitMemo, props.isCreditMemo),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(CreditOrDebitMemoDialog))));

