import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
});

class ReduxFormCheckbox extends React.PureComponent {
    handleChange = (event) => {
        const {
            input,
            meta,
            onChangeHandlers,
            actionHandlers,
            fieldMember,
            fields,
            rowValue,
        } = this.props;
        input.onChange(event.target.checked);
        const dispatch = getStringFromObject('dispatch', meta);
        const form = getStringFromObject('form', meta);
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(event.target.checked, form, fieldMember, fields, dispatch, rowValue);
                }
            });
        }
    };

    render() {
        const {
            classes,
            label,
            containerStyle,
            disabled,
            input,
            formControlProps,
            testId,
        } = this.props;
        return (
            <div style={containerStyle}>
                <div className={classes.fieldDiv}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'test-id': testId,
                                }}
                                checked={input.value}
                                onChange={this.handleChange}
                                value={input.name}
                                disabled={disabled}
                                color="primary"
                            />
                        }
                        {...formControlProps}
                        label={label}
                    />
                </div>
            </div>
        );
    }
}

ReduxFormCheckbox.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    containerStyle: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    rowValue: PropTypes.object,
    fieldMember: PropTypes.string,
    fields: PropTypes.object,
    formControlProps: PropTypes.object,
    testId: PropTypes.string,
};

ReduxFormCheckbox.defaultProps = {
    disabled: false,
    containerStyle: {},
    rowValue: {},
    actionHandlers: {},
    fields: {},
    formControlProps: {},
    onChangeHandlers: [],
    fieldMember: '',
    label: '',
    testId: 'test-checkbox',
};

export default withStyles(styles)(ReduxFormCheckbox);
