import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    FETCH_MEDICAL_PACKAGE_LIST_SUCCESS,
    FETCH_MEDICAL_PACKAGE_LIST_FAILURE,
} from '../../redux/modules/medicalPackage/medicalPackage-action';


// eslint-disable-next-line import/prefer-default-export
export function* medicalPackageListFetchWorkerSaga(action) {
    try {
        yield put({
            type: SHOW_SPINNER,
            state: { canShow: true },
        });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_MEDICAL_PACKAGE_LIST_SUCCESS,
            data: response.data,
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    } catch (error) {
        if (didApiCallFailDueToUnauthorizedUser(error)) {
            yield put({
                type: SESSION_TIMEOUT,
                actionToDispatchAfterLogin: action,
            });
        } else if (didApiCallFailDueToVersionMismatch(error)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({
            type: ERROR_MESSAGE,
            message: getErrorMessage(error),
        });
        yield put({
            type: FETCH_MEDICAL_PACKAGE_LIST_FAILURE,
            data: {},
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    }
}

