import API from '../../../constants/api';
import MESSAGES from '../../../constants/messages';

export const FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST = '@@consolidatedCashClose/FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST';
export const FETCH_CONSOLIDATED_CASH_CLOSE_FAILURE = '@@consolidatedCashClose/FETCH_CONSOLIDATED_CASH_CLOSE_FAILURE';
export const FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS = '@@consolidatedCashClose/FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS';

export const CREATE_CONSOLIDATED_CASH_CLOSE_REQUEST = '@@consolidatedCashClose/CREATE_CONSOLIDATED_CASH_CLOSE_REQUEST';
export const CREATE_CONSOLIDATED_CASH_CLOSE_FAILURE = '@@consolidatedCashClose/CREATE_CONSOLIDATED_CASH_CLOSE_FAILURE';
export const CREATE_CONSOLIDATED_CASH_CLOSE_SUCCESS = '@@consolidatedCashClose/CREATE_CONSOLIDATED_CASH_CLOSE_SUCCESS';

export const UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG = '@@consolidatedCashClose/UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG';
export const UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_SUCCESS = '@@consolidatedCashClose/UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_SUCCESS';
export const UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_FAILED = '@@consolidatedCashClose/UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_FAILED';

export const CLEAR_SELECTED_CONSOLIDATED_CASH_CLOSE = '@@consolidatedCashClose/CLEAR_SELECTED_CONSOLIDATED_CASH_CLOSE';

export const dispatchUpdateConsolidatedCashClosePrintFlagRequest = (uuid, successCallback, failureCallBack) => ({
    type: UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG,
    api: `${API.CONSOLIDATED_CASH_CLOSE.UPDATE_PRINT_STATUS}/${uuid}`,
    successAction: UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_SUCCESS,
    successCallback,
    failureAction: UPDATE_CONSOLIDATED_CASH_CLOSE_PRINT_FLAG_FAILED,
    failureMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.UPDATE_PRINT_FLAG_FAILED,
    failureCallBack,
});

export const dispatchCreateConsolidatedCashCloseRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_CONSOLIDATED_CASH_CLOSE_REQUEST,
    payload,
    api: API.CONSOLIDATED_CASH_CLOSE.CREATE,
    successAction: CREATE_CONSOLIDATED_CASH_CLOSE_SUCCESS,
    successMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.CREATE.SUCCESS,
    successCallback,
    failureAction: CREATE_CONSOLIDATED_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.CREATE.FAILURE,
    failureCallBack,
});

export const dispatchGetConsolidatedCashCloseWithUuidRequest = uuid => ({
    type: FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST,
    api: `${API.CONSOLIDATED_CASH_CLOSE.FETCH_WITH_UUID}/${uuid}`,
    successAction: FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS,
    failureAction: FETCH_CONSOLIDATED_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.FETCH_FAILED,
});

export const dispatchGetConsolidatedCashCloseWithIdRequest = id => ({
    type: FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST,
    api: `${API.CONSOLIDATED_CASH_CLOSE.FETCH_WITH_ID}/${id}`,
    successAction: FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS,
    failureAction: FETCH_CONSOLIDATED_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.FETCH_FAILED,
});

export const clearSelectCashState = () => ({
    type: CLEAR_SELECTED_CONSOLIDATED_CASH_CLOSE,
});

export const dispatchGetConsolidatedCashCloseForType = (closeType, date) => ({
    type: FETCH_CONSOLIDATED_CASH_CLOSE_REQUEST,
    api: `${API.CONSOLIDATED_CASH_CLOSE.GET_COLLECTIONS}?closeType=${closeType}&date=${date}`,
    successAction: FETCH_CONSOLIDATED_CASH_CLOSE_SUCCESS,
    failureAction: FETCH_CONSOLIDATED_CASH_CLOSE_FAILURE,
    failureMessage: MESSAGES.CONSOLIDATED_CASH_CLOSE.FETCH_FAILED,
});
