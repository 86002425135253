/**
 * Created by sachinprakash on 15/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { ADD_OR_UPDATE_UOM_REQUEST, FETCH_UOM_REQUEST } from '../../redux/modules/LabSettings/labTest-actions';
import { fetchPagesOfUomWorker, addOrUpdateWorker } from './labUomWorkerSaga';

export function* fetchPagesOfUomWatcher() {
    yield takeLatest(FETCH_UOM_REQUEST, fetchPagesOfUomWorker);
}

export function* addOrUpdateUomWatcher() {
    yield takeLatest(ADD_OR_UPDATE_UOM_REQUEST, addOrUpdateWorker);
}
