import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStringFromObject } from '../../../constants/lodashUtils';
import CreditOrDebitMemoDialog from '../CreditOrDebitMemo/CreditOrDebitMemoDialog';
import CreditOrDebitMemoDialogV2 from '../CreditOrDebitMemoV2/CreditOrDebitMemoDialogV2';
import { getIsCreditMemo } from '../CreditOrDebitMemoV2/CreditOrDebitMemoUtilsV2';

const CreditOrDebitMemoWrapper = (props) => {
    const { disableCreditOrDebitMemoV2, memoType, ...otherProps } = props;
    if (disableCreditOrDebitMemoV2) {
        return (
            <CreditOrDebitMemoDialog
                isCreditMemo={getIsCreditMemo(memoType)}
                {...otherProps}
            />
        );
    }
    return (
        <CreditOrDebitMemoDialogV2
            memoType={memoType}
            {...otherProps}
        />
    );
};

CreditOrDebitMemoWrapper.propTypes = {
    disableCreditOrDebitMemoV2: PropTypes.bool,
    memoType: PropTypes.string.isRequired,
};


CreditOrDebitMemoWrapper.defaultProps = {
    disableCreditOrDebitMemoV2: false,
};

const mapStateToProps = state => ({
    disableCreditOrDebitMemoV2: getStringFromObject('disableCreditOrDebitMemoV2', state.appConfiguration),
});

export default connect(mapStateToProps)(CreditOrDebitMemoWrapper);
