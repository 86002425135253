import API from '../../../constants/api';

export const FETCH_ORDERS_QUEUE_LIST_REQUEST = '@@announcement/FETCH_ORDERS_QUEUE_LIST_REQUEST';
export const FETCH_ORDERS_QUEUE_LIST_SUCCESS = '@@announcement/FETCH_ORDERS_QUEUE_LIST_SUCCESS';
export const FETCH_ORDERS_QUEUE_LIST_FAILURE = '@@announcement/FETCH_ORDERS_QUEUE_LIST_FAILURE';

export const ADD_ORDERS_REQUEST = '@@announcement/ADD_ORDERS_REQUEST';
export const ADD_ORDERS_SUCCESS = '@@announcement/ADD_ORDERS_SUCCESS';
export const ADD_ORDERS_FAILURE = '@@announcement/ADD_ORDERS_FAILURE';

export const fetchOrdersQueueRequest = (
    page,
    size,
    orderTypeUuid,
    sortDir = 'desc',
    sortCol = 'dateActivated',
    searchConstruct,
    showFulfilled,
    onlyCompleted,
    searchValue,
    specialCaseConstruct,
    unpaidSpecialCaseConstruct,
    includeList = [],
    sameProvider = false,
) => ({
    type: FETCH_ORDERS_QUEUE_LIST_REQUEST,
    api: API.ORDERS_QUEUE.FETCH_QUEUE,
    page,
    size,
    sortDir,
    sortCol,
    orderTypeUuid,
    searchConstruct,
    showFulfilled,
    onlyCompleted,
    searchValue,
    specialCaseConstruct,
    unpaidSpecialCaseConstruct,
    includeList,
    sameProvider,
});

export const addOrdersRequest = (
    observations,
    visitUuid,
    patientUuid,
    paginationDetails,
    encounterTypeUuid,
    providerUuid,
    successCallBack,
    patientRefuse,
    orderUuid,
    patientSensitive,
) => ({
    type: ADD_ORDERS_REQUEST,
    api: API.ORDERS_QUEUE.SAVE_ORDERS,
    observations,
    visitUuid,
    patientUuid,
    paginationDetails,
    encounterTypeUuid,
    providerUuid,
    successCallBack,
    patientRefuse,
    orderUuid,
    patientSensitive,
});

