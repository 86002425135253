import memoizeOne from 'memoize-one';
import {
    isArrayValidAndNotEmpty,
    isValidNumber, roundedValue,
    valueToFixedTwoDigits,
} from '../../constants/CommonUtil';
import { formatDate } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';

const getAddressFromAddressDto = (address) => {
    const zipCode = getStringFromObject('zipCode', address, '');
    const phone = getStringFromObject('mobile', address, '');
    const email = getStringFromObject('email', address, '');
    return ([
        [
            getStringFromObject('street', address, ''),
            getStringFromObject('village', address, ''),
        ].filter(Boolean).join(', '),
        [
            getStringFromObject('city', address, ''),
            getStringFromObject('state.value', address, ''),
            getStringFromObject('country.value', address, ''),
        ].filter(Boolean).join(', '),
        zipCode ? ['pin', zipCode].join('-') : undefined,
        phone ? ['Phone', phone].join(': ') : undefined,
        email ? ['Email', email].join(': ') : undefined,
    ].filter(Boolean).join('<br/>'));
};

export const getAddressFromAddressDtoMemoized = memoizeOne(getAddressFromAddressDto);

const convertToPlainData = (purchaseOrder) => {
    console.log('sad0-asiadasda', purchaseOrder);
    const plain = {
        from: getStringFromObject('supplier.name', purchaseOrder),
        fromAddress: getAddressFromAddressDtoMemoized(getStringFromObject('partnerAddress', purchaseOrder)),
        to: getStringFromObject('warehouse.name', purchaseOrder),
        toAddress: getAddressFromAddressDtoMemoized(getStringFromObject('warehouseAddress', purchaseOrder)),
        name: getStringFromObject('name', purchaseOrder),
        number: getStringFromObject('siNumber', purchaseOrder),
        state: getStringFromObject('state', purchaseOrder),
        date: formatDate(getStringFromObject('dateOrder', purchaseOrder), 'dd-MM-yyyy'),
        notes: getStringFromObject('notes', purchaseOrder),
        warehouseCompanyName: getStringFromObject('warehouseCompanyName', purchaseOrder),
        lines: [],
        discount: NumberOf(getStringFromObject('discount', purchaseOrder)),
        validator: getStringFromObject('validator', purchaseOrder) || '',
    };
    let subTotal = 0;
    let invoiceTax = 0;
    let totalCostPrice = 0;
    const lines = getStringFromObject('purchaseOrderLines', purchaseOrder, []);
    if (isArrayValidAndNotEmpty(lines)) {
        plain.lines = lines.map((line) => {
            console.log('asd09u09uqe2qsdasd', line);
            const discountPerc = NumberOf(getStringFromObject('discount', line, 0));
            const discountPerc2 = NumberOf(getStringFromObject('discount2', line, 0));
            const subTotalLine = NumberOf(getStringFromObject('amount', line, 0));
            const discountAmount = roundedValue(subTotalLine * (discountPerc / 100));
            const discountAmount2 = roundedValue((subTotalLine - discountAmount) * (discountPerc2 / 100));
            const amount = NumberOf(valueToFixedTwoDigits(subTotalLine - (discountAmount + discountAmount2)));
            let lineTotal = 0;
            const taxes = getStringFromObject('taxes', line, []);
            let taxDisplay = '-';
            const lineTotalCost = getStringFromObject('cost', line, 0) * getStringFromObject('quantity', line);
            totalCostPrice += lineTotalCost;
            let totalTaxAmount = 0;
            if (isArrayValidAndNotEmpty(taxes)) {
                taxDisplay = taxes.map((tax) => {
                    const taxPercentageFactor = NumberOf(tax.amount);
                    const taxAmountOfLine = (amount * taxPercentageFactor);
                    totalTaxAmount += taxAmountOfLine;
                    return [
                        tax.name,
                        isValidNumber(taxPercentageFactor) ? `${taxPercentageFactor * 100}%` : false,
                    ].filter(Boolean).join('-');
                }).filter(Boolean).join('<br/>');
            }
            totalTaxAmount = roundedValue(totalTaxAmount);
            lineTotal = totalTaxAmount + amount;
            invoiceTax += totalTaxAmount;
            subTotal += amount;
            return {
                productCode: getStringFromObject('product.productCode', line),
                product: getStringFromObject('product.productName', line),
                uom: getStringFromObject('uom.name', line),
                quantity: getStringFromObject('quantity', line),
                bonus: getStringFromObject('bonus', line),
                cost: getStringFromObject('cost', line),
                totalCost: valueToFixedTwoDigits(lineTotalCost),
                discount: discountPerc,
                discount2: discountPerc2,
                discountAmount: valueToFixedTwoDigits(discountAmount),
                discountAmount2: valueToFixedTwoDigits(discountAmount2),
                rate: valueToFixedTwoDigits(NumberOf(getStringFromObject('rate', line))),
                amount: valueToFixedTwoDigits(amount),
                taxes: taxDisplay,
                totalTaxAmount: valueToFixedTwoDigits(totalTaxAmount),
                total: valueToFixedTwoDigits(lineTotal),
            };
        });
    }
    plain.invoiceCost = valueToFixedTwoDigits(totalCostPrice);
    plain.subTotal = valueToFixedTwoDigits(subTotal);
    plain.invoiceTax = valueToFixedTwoDigits(invoiceTax);
    plain.total = valueToFixedTwoDigits((subTotal + invoiceTax) - plain.discount);
    console.log('asd09u09uqe2qsdasd', plain);

    return plain;
};

export const convertToPlainDataMemoized = memoizeOne(convertToPlainData);
