import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';

class ReduxFormSwitch extends React.Component {
    onChange = (val) => {
        const { disabled, input } = this.props;
        if (!disabled) {
            input.onChange(val);
        }
    };

    render() {
        const { input, ...otherProps } = this.props;
        console.log('asfsdfsdf', input.value);
        return (
            <Switch
                value={input.value}
                onChange={this.onChange}
                {...otherProps}
                disabled={false}
            />
        );
    }
}

ReduxFormSwitch.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
};

ReduxFormSwitch.defaultProps = {
    disabled: true,
};

export default ReduxFormSwitch;
