import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import {
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../constants/CommonUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const style = () => ({
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    disableText: {
        color: '#000000',
    },
});

class OutlinedTextField extends React.Component {
    onChange = (event) => {
        const { onChange, actionHandlers, onChangeHandlers } = this.props;
        let onChanger = null;
        if (isValidFunction(onChange)) {
            onChanger = (value, e) => {
                onChange(value, e);
            };
        }
        const { upperCase } = this.props;
        if (isObjectValidAndNotEmpty(this.props.input) && isValidFunction(this.props.input.onChange)) {
            // eslint-disable-next-line prefer-destructuring
            onChanger = (value, e) => {
                this.props.input.onChange(value, e);
            };
        }
        let value = event.target.value || '';
        if (upperCase && typeof value === 'string') {
            value = value.toUpperCase();
        }
        console.log('asd=-asod-asd=-', value, this.props.input);
        if (isValidFunction(onChanger)) {
            onChanger(value, event);
        }
        if (isArrayValidAndNotEmpty(onChangeHandlers)) {
            onChangeHandlers.forEach((h) => {
                const action = getStringFromObject(h, actionHandlers);
                if (isValidFunction(action)) {
                    action(
                        value,
                        getStringFromObject('meta.form', this.props),
                        getStringFromObject('fieldMember', this.props),
                        getStringFromObject('fields', this.props),
                        getStringFromObject('meta.dispatch', this.props),
                    );
                }
            });
        }
    };

    onBlur = (event) => {
        const { onBlur, onBlurHandlers, actionHandlers } = this.props;
        let onBlurer = null;
        if (isValidFunction(onBlur)) {
            onBlurer = (e) => {
                onBlur(e);
            };
        }
        if (isObjectValidAndNotEmpty(this.props.input) && isValidFunction(this.props.input.onBlur)) {
            // eslint-disable-next-line prefer-destructuring
            onBlurer = (e) => {
                this.props.input.onBlur(e);
            };
        }
        if (isValidFunction(onBlurer)) {
            onBlurer(event);
        }
        if (isArrayValidAndNotEmpty(onBlurHandlers)) {
            onBlurHandlers.forEach((h) => {
                const action = getStringFromObject(h, actionHandlers);
                if (isValidFunction(action)) {
                    action(
                        getStringFromObject('value', this.props.input),
                        getStringFromObject('meta.form', this.props),
                        getStringFromObject('fieldMember', this.props),
                        getStringFromObject('fields', this.props),
                        getStringFromObject('meta.dispatch', this.props),
                    );
                }
            });
        }
    };

    render() {
        const {
            label,
            placeholder,
            classes,
            type,
            InputLabelProps,
            InputProps,
            fullWidth,
            input,
            required,
            multiline,
            onBlur,
            autoPickBlurFunc,
            onFocus,
            endAdornment,
            testId,
        } = this.props;
        const touched = getStringFromObject('meta.touched', this.props);
        const error = getStringFromObject('meta.error', this.props);
        console.log('asd0ps9oadkas;lda', this.props);
        let labelComponent = label;
        if (required) {
            labelComponent = getRequiredFieldLabel(label);
        }
        const inputProps = isObjectValidAndNotEmpty(InputProps) ? { ...InputProps } : {};
        if (endAdornment) {
            inputProps.endAdornment = endAdornment;
        }
        let otherProps = {};
        if (testId) {
            otherProps = {
                inputProps: {
                    'test-id': testId,
                },
            };
        }
        return (
            <TextField
                testId={testId}
                variant="outlined"
                {...this.props}
                {...input}
                type={type}
                placeholder={placeholder}
                fullWidth={fullWidth}
                onChange={this.onChange}
                error={touched && error}
                onBlur={autoPickBlurFunc ? this.onBlur : onBlur}
                onFocus={isValidFunction(onFocus) ? onFocus : input.onFocus}
                helperText={touched && error}
                label={labelComponent}
                required={false}
                InputProps={{
                    ...inputProps,
                    classes: {
                        input: multiline ? {} : classes.input,
                        disabled: this.props.disabled ? classes.disableText : null,
                    },
                }}
                {...otherProps}
                InputLabelProps={{
                    shrink: true,
                    ...InputLabelProps,
                }}
            />
        );
    }
}

OutlinedTextField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    testId: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    upperCase: PropTypes.bool,
    // todo remove it when possible.. being used from quick reg form..
    // risky to change at the moment
    autoPickBlurFunc: PropTypes.bool,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    endAdornment: PropTypes.string,
    actionHandlers: PropTypes.object,
    onChangeHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
};

OutlinedTextField.defaultProps = {
    input: {},
    meta: {},
    label: '',
    value: '',
    testId: '',
    placeholder: '',
    required: false,
    fullWidth: true,
    multiline: false,
    upperCase: false,
    disabled: false,
    autoPickBlurFunc: false,
    type: 'input',
    InputLabelProps: {},
    InputProps: {},
    onChange: () => {},
    onBlur: () => {},
    onFocus: null,
    endAdornment: '',
    actionHandlers: null,
    onChangeHandlers: [],
    onBlurHandlers: [],
};

export default withStyles(style)(OutlinedTextField);
