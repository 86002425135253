import initialStates from '../../initialStates';
import {
    ORDER_OPERATION_SUCCESS,
    ORDER_SEND_TO_BILLING_SUCCESS,
    RESET_ORDER_DETAILS,
    SAVE_ORDER_DETAILS,
    SAVE_MRS_ORDER_DETAILS,
} from './order-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';

const orderReducer = (state = initialStates.order, action) => {
    let newState = state;
    switch (action.type) {
    case SAVE_ORDER_DETAILS:
        if (action.data && action.data.saleOrderLineDetails) {
            newState = Object.assign({}, state, {
                orderDetails: action.data.saleOrderLineDetails,
                patient: action.data,
                sendToBilling: false,
                copayEditable: getStringFromObject('copayEditable', action.data),
                insuranceRequestState:
                    getStringFromObject('insuranceRequestState', action.data),
                warningMessageForDoc:
                    getStringFromObject('warningMessageForDoc', action.data),
                insuranceAuthRequestStatus:
                    getStringFromObject('insuranceAuthRequestStatus', action.data),
                insuranceAuthResponseStatus:
                    getStringFromObject('insuranceAuthResponseStatus', action.data),
                insuranceRequestUuid:
                    getStringFromObject('insuranceRequestUuid', action.data),
                totalBilledInInsurance: NumberOf(getStringFromObject('totalBilledInInsurance', action.data)),
                preApprovalLimit: NumberOf(getStringFromObject('preApprovalLimit', action.data)),
            });
        }
        break;
    case SAVE_MRS_ORDER_DETAILS:
        if (action.data) {
            newState = Object.assign({}, state, {
                mrsorder: action.data,
            });
        }
        break;
    case RESET_ORDER_DETAILS:
        newState = Object.assign({}, state, { orderDetails: [], patient: {}, warningMessageForDoc: '' });
        break;
    case ORDER_OPERATION_SUCCESS:
        if (action.data && action.data.saleOrderLineDetails) {
            newState = Object.assign({}, state, {
                orderDetails: action.data.saleOrderLineDetails,
                patient: action.data,
            });
        }
        break;
    case ORDER_SEND_TO_BILLING_SUCCESS:
        newState = Object.assign({}, state, { sendToBilling: true });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default orderReducer;
