import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { TextField } from 'redux-form-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import { getRequiredFieldLabel } from '../../../constants/CommonUtil';
import RoundedCornerButton from '../../RoundedCornerButton';

const styles = () => ({
    disabled: {
        color: 'black',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        flexShrink: 0,
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
    },
    root: {
        fontSize: '14px',
        color: 'black',
    },
});

// eslint-disable-next-line react/prefer-stateless-function
class SideLabelTextField extends React.Component {
    handleGenerateAssetId = () => {
        const { input, generateIdApi } = this.props;
        axios.get(generateIdApi)
            .then((response) => {
                input.onChange(response.data);
            });
    };

    handleChange = (event) => {
        const { input, onValueChange } = this.props;
        input.onChange(event.target.value);
        onValueChange(event.target.value);
    };

    render() {
        const {
            label,
            classes,
            InputProps,
            isMandatory,
            labelDivWidth,
            variant,
            formValues, // eslint-disable-line
            ...otherProps
        } = this.props;
        if (otherProps.startAdornment) {
            InputProps.startAdornment = (
                <InputAdornment position="start">{otherProps.startAdornment}</InputAdornment>
            );
        }
        const isOutlined = (variant === 'outlined');
        const labelRequired = isMandatory ? getRequiredFieldLabel(label) : label;
        return (
            <div className={classes.parent}>
                {
                    !isOutlined &&
                    <aside className={classes.labelDiv} style={{ flexBasis: labelDivWidth }}>
                        <div style={otherProps.labelstyle} >
                            {labelRequired}
                        </div>
                    </aside>
                }
                <div className={classes.fieldDiv}>
                    <TextField
                        variant={variant || 'standard'}
                        label={isOutlined ? labelRequired : ''}
                        {...otherProps}
                        InputProps={{
                            ...InputProps,
                            classes: {
                                disabled: otherProps.editable ? classes.disabled : '',
                                root: classes.root,
                            },
                        }}
                        onChange={this.handleChange}
                    />
                    {
                        otherProps.generateId &&
                        <RoundedCornerButton
                            marginLeft="8px"
                            label="GENERATE ID"
                            height="2.4rem"
                            backgroundColor="white"
                            borderRadius="10px"
                            color="#0062D1"
                            onClick={this.handleGenerateAssetId}
                            padding="6px"
                            border="2px solid #0062D1"
                        />
                    }
                </div>
            </div>
        );
    }
}

SideLabelTextField.propTypes = {
    label: PropTypes.string,
    labelDivWidth: PropTypes.string,
    input: PropTypes.object.isRequired,
    generateIdApi: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    InputProps: PropTypes.object,
    isMandatory: PropTypes.bool,
    onValueChange: PropTypes.func,
    variant: PropTypes.string,
};

SideLabelTextField.defaultProps = {
    label: '',
    InputProps: {},
    labelDivWidth: '11em',
    variant: '',
    isMandatory: false,
    onValueChange: () => {},
};

export default withStyles(styles)(SideLabelTextField);

