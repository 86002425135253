/* eslint-disable indent */
import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_STOCK_INTENT,
    CREATE_STOCK_INTENT_SUCCESS,
    FETCH_STOCK_INTENT_SUCCESS,
} from './stockIntent-actions';

const stockIntentReducer = (state = initialStates.stockIntent, action) => {
    let newState = {};
    switch (action.type) {
        case CREATE_STOCK_INTENT_SUCCESS:
            newState = Object.assign({}, state, { selected: action.data });
            break;
        case CLEAR_SELECTED_STOCK_INTENT:
            newState = Object.assign({}, state, { selected: {} });
            break;
        case FETCH_STOCK_INTENT_SUCCESS:
            newState = Object.assign({}, state, { selected: action.data });
            break;
        default:
            newState = state;
    }
    return newState;
};

export default stockIntentReducer;
