import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getDateInYYYYMMDDFormat } from '../../constants/DateUtil';

export const generateAppointmentSlotSearchConstruct = (payload) => {
    const criterias = [];
    if (payload.service) {
        criterias.push(generateObjectWithCriteriaMode('serviceType.uuid', payload.service, '='));
    }
    if (payload.provider) {
        criterias.push(generateObjectWithCriteriaMode('provider.uuid', payload.provider, '='));
    }
    if (payload.department) {
        criterias.push(generateObjectWithCriteriaMode('department.uuid', payload.department, '='));
    }
    if (payload.slotType) {
        criterias.push(generateObjectWithCriteriaMode('appointmentSlot.slotType', payload.slotType, '='));
    }
    if (payload.date) {
        criterias.push(generateObjectWithCriteriaMode('appointmentSlot.slotDate',
            getDateInYYYYMMDDFormat(new Date(payload.date)), '='));
    }
    let lastCriteria = null;
    if (criterias.length > 0) {
        criterias.map((criteria) => {
            if (lastCriteria) {
                lastCriteria = getCombined(lastCriteria, 'AND', criteria);
            } else {
                lastCriteria = criteria;
            }
            return null;
        });
        return lastCriteria;
    }
    return null;
};

export const generateAppointmentListConstruct = () => ({});
