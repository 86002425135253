import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';

import MouseTrap from '../../constants/mousetrap';
import {
    isStringNullOrUndefined,
    isValidFunction,
    isValidText,
} from '../../constants/CommonUtil';
import { applicationShortcuts } from '../../constants/constants';
import { clearWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const YES = 'YES';
const NO = 'NO';
const Cancel = 'Cancel';

class WarningDialog extends React.Component {
    componentDidMount() {
        MouseTrap.bindGlobal(applicationShortcuts.WARNING_DIALOG_YES, this.handleYes);
        MouseTrap.bindGlobal(applicationShortcuts.WARNING_DIALOG_NO, this.handleNo);
    }

    componentWillUnmount() {
        MouseTrap.unbind(applicationShortcuts.WARNING_DIALOG_NO);
        MouseTrap.unbind(applicationShortcuts.WARNING_DIALOG_YES);
    }

    onWarningDialogClick = (clickedButton) => {
        const { dispatch, warningDialog } = this.props;
        dispatch(clearWarning());
        if (isObjectValidAndNotEmpty(warningDialog)) {
            if (clickedButton === YES && isValidFunction(warningDialog.callBack)) {
                warningDialog.callBack(warningDialog.callBackParams);
            } else if (clickedButton === NO && isValidFunction(warningDialog.noCallBack)) {
                warningDialog.noCallBack(warningDialog.noCallBackParams);
            } else if (clickedButton === Cancel && isValidFunction(warningDialog.cancelCallBack)) {
                warningDialog.cancelCallBack(warningDialog.cancelCallBackParams);
            }
        }
    };

    handleYes = () => {
        this.onWarningDialogClick(YES);
    };

    handleNo = () => {
        this.onWarningDialogClick(NO);
    };

    handleCancel = () => {
        this.onWarningDialogClick(Cancel);
    };

    render() {
        const {
            warningDialog,
        } = this.props;
        if (!isObjectValidAndNotEmpty(warningDialog)) {
            return null;
        }
        console.log('sdagjkjdfgakjsdf', warningDialog);
        const yesLabel = getStringFromObject('yesLabel', warningDialog) || 'Yes';
        const noLabel = getStringFromObject('noLabel', warningDialog) || 'No';
        const cancelLabel = getStringFromObject('cancelLabel', warningDialog) || 'Cancel';
        return (
            <Dialog
                fullScreen={false}
                maxWidth="md"
                open={!!(warningDialog.open)}
                onClose={warningDialog.disableKeyClose ? () => {} : this.handleCancel}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {isValidText(warningDialog.title) ? warningDialog.title : 'Warning'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText test-id="message">
                        {warningDialog.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        !warningDialog.isConfirmation && !isStringNullOrUndefined(warningDialog.cancelLabel) &&
                        <Button onClick={this.handleCancel} color="primary" test-id="label-cancel">
                            {cancelLabel}
                        </Button>
                    }
                    {
                        !warningDialog.isConfirmation &&
                        <Button onClick={this.handleNo} color="primary" test-id="label-no">
                            {noLabel}
                        </Button>
                    }
                    <Button onClick={this.handleYes} color="primary" test-id="label-yes-ok">
                        {warningDialog.isConfirmation ? 'Ok' : yesLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

WarningDialog.propTypes = {
    warningDialog: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    warningDialog: state.warningDialog[0],
});


export default withMobileDialog()(connect(mapStateToProps)(WarningDialog));
