import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ContentCopy from '@material-ui/icons/FileCopy';
import { connect } from 'react-redux';
import { copyToClipboard } from '../../constants/CommonUtil';
import { infoMessage } from '../../redux/modules/message/message-actions';

const CopiableText = ({ textToCopy, setHtmlDangerously, dispatch }) => {
    let textRef = null;
    const onCopy = () => {
        if (setHtmlDangerously && textRef && textRef.textContent) {
            console.log('asd9asud0a9duas', textRef.textContent);
            copyToClipboard(textRef.textContent);
        } else {
            copyToClipboard(textToCopy);
        }
        dispatch(infoMessage('Copied To Clipboard'));
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ whiteSpace: 'pre-wrap' }}>
                {
                    setHtmlDangerously ?
                        <div
                            ref={(ref) => {
                                if (!textRef) {
                                    textRef = ref;
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: textToCopy }}
                        /> :
                        textToCopy
                }
            </div>
            {
                textToCopy &&
                <Tooltip title="Click Here To Copy">
                    <IconButton onClick={onCopy} className="ml-1">
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
};

CopiableText.propTypes = {
    textToCopy: PropTypes.string,
    setHtmlDangerously: PropTypes.bool,
    dispatch: PropTypes.func,
};

CopiableText.defaultProps = {
    textToCopy: '',
    setHtmlDangerously: false,
    dispatch: () => {},
};

export default connect()(CopiableText);

