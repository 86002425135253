import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid/Grid';
// import MenuItem from '@material-ui/core/MenuItem/MenuItem';
// import TextField from '@material-ui/core/TextField/TextField';
// import classNames from 'classnames';
import ReactSelectMaterial from '../ReactSelectMaterial/ReactSelectMaterial';

import { getStringFromObject } from '../../constants/lodashUtils';

const styles = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
});

const MapCSVData = (props) => {
    const {
        // classes,
        tabelCells,
        fields,
        handleFieldChange,
    } = props;
    return (
        <div style={{ width: '40em', padding: '2rem' }}>
            <Grid
                container
                style={{
                    background: '#fff',
                    borderBottom: '1px solid black',
                    lineHeight: '3em',
                }}
                alignContent="center"
            >
                <Grid item lg={6} md={6} sm={6} style={{ borderRight: '1px solid black', padding: '6px' }}>
                    Online Fields
                </Grid>
                <Grid item lg={6} md={6} sm={6} style={{ padding: '6px' }}>Your Fields</Grid>
            </Grid>
            {
                tabelCells.map((tableCell, index) => {
                    console.log('12222fkvjnkrjtb', tableCell);
                    return (
                        <Grid
                            container
                            key={tableCell.label}
                            style={{
                                background: '#fff',
                                borderBottom: '1px solid black',
                                lineHeight: '3em',
                            }}
                        >
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                style={{
                                    borderRight: '1px solid black',
                                    padding: '6px',
                                }}
                            >
                                {tableCell.label}
                            </Grid>
                            <Grid item testId={tableCell.label} lg={6} md={6} sm={6} style={{ padding: '6px' }}>
                                <ReactSelectMaterial
                                    options={fields.map(aField => ({
                                        key: aField,
                                        value: aField,
                                    }))}
                                    noLabel
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    fullWidth
                                    value={{
                                        key: tableCell.csvName,
                                        value: tableCell.csvName,
                                    }}
                                    onChange={(option) => {
                                        handleFieldChange(
                                            {
                                                target: {
                                                    value: getStringFromObject('value', option),
                                                },
                                            },
                                            index,
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    );
                })
            }
        </div>
    );
};

MapCSVData.propTypes = {
    // classes: PropTypes.object.isRequired,
    tabelCells: PropTypes.array,
    fields: PropTypes.array,
    handleFieldChange: () => {},
};

MapCSVData.defaultProps = {
    tabelCells: [],
    fields: [],
    handleFieldChange: () => {},
};

export default withStyles(styles)(MapCSVData);
