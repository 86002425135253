import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import api from '../../constants/api';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';
import ActionButton from '../../components/ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import NumberTextField from '../../components/NumberTextField';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class AddAttendanceForEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: null,
            period: null,
        };
    }

    onChangeValue = fieldName => (changedValue) => {
        this.setState({
            [fieldName]: changedValue,
        });
    };

    onChangePeriod = (period) => {
        this.setState({
            period,
        });
    };

    onAddAttendance = () => {
        const {
            employee,
            daysWorked,
            period,
        } = this.state;
        const {
            dispatch,
        } = this.props;

        if (!isObjectValidAndNotEmpty(employee)) {
            dispatch(errorMessage('Please enter an employee'));
            return;
        }

        if (!isObjectValidAndNotEmpty(period)) {
            dispatch(errorMessage('Period is mandatory'));
            return;
        }

        dispatch(showSpinner());
        const p = Promise.race([
            axios.get(
                `${api.ATTENDANCE.ADD_ATTENDANCE_FOR_EMPLOYEE
                }${employee.key}&periodUuid=${period.uuid}&noOfDays=${daysWorked}`,
            ),
            new Promise((resolve) => {
                setTimeout(() => resolve('resolved in 5 sec'), 3000);
            }),
        ]);
        p.then(() => {
            console.log('asd-9asudas0aas');
            dispatch(hideSpinner());
            this.props.handleClose();
        });
        p.catch(e => apiCatchBlockFunction(e, dispatch));
    };
    render() {
        const {
            employee,
            period,
            daysWorked,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        console.log(dispatch);
        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item sm={4} md={4} lg={4}>
                        <ReactSelectMaterial
                            fullWidth
                            dataSourceConfig={{
                                text: 'value',
                                value: 'key',
                            }}
                            autoFocus
                            label="Employee"
                            autocomplete
                            dataSourceApi={`${api.EMPLOYEE.GET_SUGGESTION}?ignoreRetired=true&name=`}
                            onChange={this.onChangeValue('employee')}
                            value={employee}
                            required
                            selectProps={{
                                textFieldProps: {
                                    InputProps: {
                                        'data-test-id': 'employee-name',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} md={4} lg={4}>
                        <ReactSelectMaterial
                            dataSourceApi={`${api.PAYROLL.ALL_PERIODS}?size=4&searchString=`}
                            dataSourceConfig={{
                                value: 'uuid',
                                text: 'displayWithDates',
                            }}
                            onChange={this.onChangePeriod}
                            label="Period"
                            value={period}
                            selectProps={{
                                textFieldProps: {
                                    InputProps: {
                                        'data-test-id': 'attendance-period',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <NumberTextField
                            label="Days worked"
                            min={0}
                            input={{
                                onChange: this.onChangeValue('daysWorked'),
                                value: daysWorked,
                            }}
                            data-test-id="days-worked"
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12} className="flexChildren justify-content-right">
                        <ActionButton
                            onClick={this.onAddAttendance}
                            data-test-id="add-attendance"
                        >
                            Add Attendance
                        </ActionButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

AddAttendanceForEmployee.propTypes = {
    dispatch: PropTypes.func,
    handleClose: PropTypes.func,
};

AddAttendanceForEmployee.defaultProps = {
    dispatch: () => {},
    handleClose: () => {},
};

export default AddAttendanceForEmployee;

