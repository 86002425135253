import initialStates from '../../initialStates';
import {
    BUNDLE_PAYER_LIST_REQUEST_SUCCESS,
    CLEAR_CLAIM_STATE,
    CLEAR_DOWNLOAD_TASK_STATUS,
    DOWNLOAD_TASK_STATUS_SUCCESS,
    FETCH_CLAIMS_OR_BUNDLES_FAILURE,
    FETCH_CLAIMS_OR_BUNDLES_REQUEST,
    FETCH_CLAIMS_OR_BUNDLES_SUCCESS, SAVE_PAYER_COLLECTION_FILTER,
} from './claimsAndBundles-actions';

const claimsAndBundlesReducer = (state = initialStates.claimsAndBundles, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_CLAIMS_OR_BUNDLES_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case DOWNLOAD_TASK_STATUS_SUCCESS:
        newState = Object.assign({}, state, { downloadTask: action.data });
        break;
    case CLEAR_DOWNLOAD_TASK_STATUS:
        newState = Object.assign({}, state, { downloadTask: {} });
        break;
    case FETCH_CLAIMS_OR_BUNDLES_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
            sortOrder: action.sortOrder,
            sortCol: action.sortCol,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            first: action.data.first,
            last: action.data.last,
            isBundle: action.data.isBundle,
        });
        break;
    case FETCH_CLAIMS_OR_BUNDLES_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case BUNDLE_PAYER_LIST_REQUEST_SUCCESS:
        newState = Object.assign({}, state, { bundlePayers: action.data });
        break;
    case SAVE_PAYER_COLLECTION_FILTER:
        newState = { ...state, payerCollectionFilter: action.payerCollectionFilter };
        break;
    case CLEAR_CLAIM_STATE:
        newState = initialStates.claimsAndBundles;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default claimsAndBundlesReducer;
