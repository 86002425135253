import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const style = () => ({
    disabled: {
        color: 'black',
    },
});

const ReadOnlyTextField = ({
    input,
    label,
    classes,
    ...otherProps
}) => {
    const { value } = otherProps.field;
    const { InputProps } = otherProps;
    console.log('adsfasdf', otherProps, input);
    return (
        <div>
            {
                !InputProps && InputProps.noLabel &&
                    <div>{label}</div>
            }
            <div>
                <TextField
                    {...otherProps}
                    disabled
                    name={input.name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            disabled: classes.disabled,
                        },
                    }}
                    value={value}
                />
            </div>
        </div>
    );
};

ReadOnlyTextField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

ReadOnlyTextField.defaultProps = {
    input: {},
    label: '',
};

export default withStyles(style)(ReadOnlyTextField);

