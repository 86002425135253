/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CANCEL_ACCOUNT_INVOICE_SUCCESS,
    CLEAR_SELECTED_ACCOUNT_INVOICE,
    CREATE_ACCOUNT_INVOICE_SUCCESS,
    FETCH_ACCOUNT_INVOICE_SUCCESS,
} from './accountInvoice-actions';

const accountInvoiceReducer = (state = initialStates.accountInvoice, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_ACCOUNT_INVOICE_SUCCESS:
        newState = Object.assign({}, state, {
            selectedInvoice: [].concat(action.data),
        });
        break;
    case CREATE_ACCOUNT_INVOICE_SUCCESS:
    case CANCEL_ACCOUNT_INVOICE_SUCCESS:
        newState = Object.assign({}, state, {
            selectedInvoice: [action.data],
        });
        break;
    case CLEAR_SELECTED_ACCOUNT_INVOICE:
        newState = Object.assign({}, state, { selectedInvoice: [] });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default accountInvoiceReducer;
