import { ADD_SALARY_SUCCESS, CLEAR_SELECTED_STATE, FETCH_ADVANCE_SALARY_SUCCESS } from './salaryAdvance-actions';
import initialStates from '../../initialStates';

const createAdvancedSalaryReducer = (state = initialStates.salaryAdvance, action) => {
    let newState = {};
    switch (action.type) {
    case ADD_SALARY_SUCCESS:
    case FETCH_ADVANCE_SALARY_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_SELECTED_STATE:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default createAdvancedSalaryReducer;
