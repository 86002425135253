import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import messages from '../../constants/messages';
import { generateAppointmentSlotSearchConstruct } from './appointmentSearchConstructUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    CANCEL_APPOINTMENT_FAILURE,
    CANCEL_APPOINTMENT_SUCCESS,
    CONFIRM_APPOINTMENT_FAILURE,
    CONFIRM_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_FAILURE,
    CREATE_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENT_SLOT_FAILURE,
    FETCH_APPOINTMENT_SLOT_SUCCESS,
} from '../../redux/modules/appointment/appointment-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* getAppointmentsSlotsWorker(action) {
    console.log('adsfsdfdasf', action);
    const construct = generateAppointmentSlotSearchConstruct(action.payload);
    const config = {
        headers: { 'Content-Type': 'application/json' },
    };
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, construct, config);
        yield put({
            type: FETCH_APPOINTMENT_SLOT_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const message = getErrorMessage(e);
            yield put({ type: ERROR_MESSAGE, message: `${messages.APPOINTMENT_SLOT_FETCH_FAILED}: ${message}` });
        }
        yield put({ type: FETCH_APPOINTMENT_SLOT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createAppointmentWorker(action) {
    console.log('adsfsdfdasf', action);
    const config = {
        headers: { 'Content-Type': 'application/json' },
    };
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        let response = null;
        const appointmentUuid = getStringFromObject('payload.appointmentUuid', action);
        if (appointmentUuid) {
            response = yield call(retriable().put, `${action.api}/${appointmentUuid}`, action.payload, config);
        } else {
            response = yield call(retriable().post, action.api, action.payload, config);
        }
        yield put({
            type: CREATE_APPOINTMENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.APPOINTMENT.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const message = getErrorMessage(e);
            yield put({ type: ERROR_MESSAGE, message: `${messages.APPOINTMENT.CREATE.FAILED}: ${message}` });
        }
        yield put({ type: CREATE_APPOINTMENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* cancelAppointmentWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().delete, action.api, { params: action.params });

        yield put({
            type: CANCEL_APPOINTMENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.APPOINTMENT.CANCEL.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const message = getErrorMessage(e);
            yield put({ type: ERROR_MESSAGE, message: `${messages.APPOINTMENT.CANCEL.FAILED}: ${message}` });
        }
        yield put({ type: CANCEL_APPOINTMENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* confirmAppointmentWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: CONFIRM_APPOINTMENT_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.APPOINTMENT.CONFIRM.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const message = getErrorMessage(e);
            yield put({ type: ERROR_MESSAGE, message: `${messages.APPOINTMENT.CONFIRM.FAILED}: ${message}` });
        }
        yield put({ type: CONFIRM_APPOINTMENT_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
