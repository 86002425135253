import { ORDER_TYPE_DATE_FILTERS, getStartAndEndDateFromOrderTypeDateFilter } from '../../../constants/CommonUtil';
import { getEndOfDay, getStartOfDay } from '../../../constants/DateUtil';
import { generateObjectWithCriteriaMode, getCombined } from '../../../sagas/util';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const generateSearchConstruct = (filters) => {
    let searchConstruct = null;
    let searchText = getStringFromObject('searchText', filters);
    if (searchText && searchText.trim()) {
        let operation = ':';
        if (searchText.endsWith('@')) {
            searchText = searchText.substr(0, searchText.length - 1);
            operation = '=';
        }
        searchConstruct = generateObjectWithCriteriaMode('patient_identifier.identifier', searchText, operation);
    }
    const providerUuid = getStringFromObject('doctor.key', filters);
    if (providerUuid) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('provider.uuid', providerUuid, '='),
        );
    }
    const showCompleted = getStringFromObject('showCompleted', filters);
    if (showCompleted) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('vitals_queue.vitalsStatus', 'COLLECTED', '='),
        );
    } else {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('vitals_queue.vitalsStatus', 'PENDING', '='),
        );
    }
    const datePeriod = getStringFromObject('datePeriod', filters) ||
        getStartAndEndDateFromOrderTypeDateFilter(ORDER_TYPE_DATE_FILTERS.LAST_10_DAYS);
    const {
        startDate,
        endDate,
    } = datePeriod;
    if (startDate && endDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            getCombined(
                generateObjectWithCriteriaMode('vitals_queue.dateCreated', getStartOfDay(startDate).getTime(), '>='),
                'AND',
                generateObjectWithCriteriaMode('vitals_queue.dateCreated', getEndOfDay(endDate).getTime(), '<='),
            ),
        );
    } else if (startDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('vitals_queue.dateCreated', getStartOfDay(startDate).getTime(), '>='),
        );
    } else if (endDate) {
        searchConstruct = getCombined(
            searchConstruct,
            'AND',
            generateObjectWithCriteriaMode('vitals_queue.dateCreated', getEndOfDay(endDate).getTime(), '<='),
        );
    }

    if (!isObjectValidAndNotEmpty(searchConstruct)) {
        return null;
    }
    return searchConstruct;
};

export default generateSearchConstruct;
