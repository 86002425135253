import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import { appointmentsListing, appointmentsRecallListing, appointmentsWaitingListing } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const AppointmentsListingContainer = React.lazy(() => import('../containers/AppointmentsListingContainer/AppointmentsListingContainer'));
const AppointmentsListPage = React.lazy(() => import('../pages/AppointmentsListingPage/AppointmentsListPage'));
const RecallListPage = React.lazy(() => import('../pages/AppointmentsListingPage/RecallListPage'));
const WaitingListPage = React.lazy(() => import('../pages/AppointmentsListingPage/WaitingListPage'));

const AppointmentsListingRoutes = props => (
    <AppointmentsListingContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={appointmentsListing} exact component={AppointmentsListPage} />
            <Route path={appointmentsWaitingListing} exact component={WaitingListPage} />
            <Route path={appointmentsRecallListing} exact component={RecallListPage} />
        </Suspense>
    </AppointmentsListingContainer>
);

AppointmentsListingRoutes.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
};

AppointmentsListingRoutes.defaultProps = {
    history: {},
};

export default AppointmentsListingRoutes;
