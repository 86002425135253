import uuidv4 from 'uuid/v4';
import {
    groupArrayOfObjectsByFieldValue,
    isArrayValidAndNotEmpty,
    sortArrayOfObjectsByFieldValue,
} from '../../constants/CommonUtil';
import { formatDate } from '../../constants/DateUtil';
import { PAYMENT_METHODS_MAP } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const CONSOLIDATED_CASH_CLOSE_FORM = 'consolidatedCashCloseForm';

export const mapUiObjectToPayload = (uiObject = {}) => {
    const payload = {
        uuid: uiObject.uuid,
        cashCloseType: uiObject.cashCloseType,
        employee: uiObject.user || null,
        date: formatDate(uiObject.date, 'yyyy-MM-dd'),
        refundAmount: uiObject.refundAmount,
        notes: uiObject.remarks,
        collections: [],
        amountInSystem: uiObject.collectionInSystem,
    };
    const collections = [];
    if (isArrayValidAndNotEmpty(uiObject.userEnteredConsolidatedCashCollections)) {
        uiObject.userEnteredConsolidatedCashCollections.forEach((cash) => {
            if (NumberOf(cash.quantity) > 0) {
                collections.push({
                    paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
                    denomination: cash.denomination,
                    quantity: cash.quantity,
                });
            }
        });
    }
    if (isArrayValidAndNotEmpty(uiObject.userEnteredConsolidatedOtherCollections)) {
        uiObject.userEnteredConsolidatedOtherCollections.forEach((cash) => {
            if (NumberOf(cash.amount > 0)) {
                collections.push({
                    paymentMethod: cash.paymentMethod,
                    amount: cash.amount,
                    name: cash.label,
                });
            }
        });
    }
    if (!isArrayValidAndNotEmpty(collections)) {
        return { error: 'Cash collections are required' };
    }
    payload.collections = collections;
    return payload;
};

export const mapPayloadToUuObject = (payload, denominations, bankTransactions) => {
    const uiObject = {
        uuid: payload.uuid,
        user: payload.employee || null,
        date: new Date(payload.date),
        refundAmount: payload.refundAmount,
        amountInSystem: NumberOf(payload.amountInSystem),
        remarks: payload.notes,
        cashCloseType: payload.cashCloseType,
        printed: payload.printed || false,
    };
    let cashCollections = [];
    let otherCollections = [];
    if (isArrayValidAndNotEmpty(payload.calculatedCollections)) {
        payload.calculatedCollections.forEach((coll) => {
            if (coll.paymentMethod === PAYMENT_METHODS_MAP.CASH.value) {
                cashCollections.push({
                    uiUuid: uuidv4(),
                    denomination: coll.denomination,
                    quantity: coll.quantity,
                    amount: coll.amount,
                });
            } else {
                otherCollections.push({
                    uiUuid: uuidv4(),
                    label: coll.name,
                    paymentMethod: coll.paymentMethod,
                    amount: coll.amount,
                });
            }
        });
    }
    const groupedByDenomination = groupArrayOfObjectsByFieldValue(cashCollections, 'denomination');
    cashCollections = [];
    denominations.forEach((d) => {
        const byD = groupedByDenomination[d];
        if (isArrayValidAndNotEmpty(byD)) {
            cashCollections = cashCollections.concat(byD);
        } else {
            cashCollections = cashCollections.concat({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        }
    });
    const groupedByMode = groupArrayOfObjectsByFieldValue(otherCollections, 'paymentMethod');
    otherCollections = [];
    bankTransactions.forEach((t) => {
        const byD = groupedByMode[t.paymentMethod];
        if (isArrayValidAndNotEmpty(byD)) {
            otherCollections = otherCollections.concat(byD);
        } else {
            otherCollections = otherCollections.concat({
                uiUuid: uuidv4(),
                label: t.label,
                paymentMethod: t.paymentMethod,
                amount: 0,
            });
        }
    });

    uiObject.cashCollections = cashCollections;
    uiObject.otherCollections = otherCollections;

    let userEnteredConsolidatedCashCollections = [];
    let userEnteredConsolidatedOtherCollections = [];
    if (isArrayValidAndNotEmpty(payload.userEnteredCollections)) {
        payload.userEnteredCollections.forEach((coll) => {
            if (coll.paymentMethod === PAYMENT_METHODS_MAP.CASH.value) {
                userEnteredConsolidatedCashCollections.push({
                    uiUuid: uuidv4(),
                    denomination: coll.denomination,
                    quantity: coll.quantity,
                    amount: coll.amount,
                });
            } else {
                userEnteredConsolidatedOtherCollections.push({
                    uiUuid: uuidv4(),
                    label: coll.name,
                    paymentMethod: coll.paymentMethod,
                    amount: coll.amount,
                });
            }
        });
    }
    const groupedByDenominationUserConsolidatedCollections = groupArrayOfObjectsByFieldValue(userEnteredConsolidatedCashCollections, 'denomination');
    userEnteredConsolidatedCashCollections = [];
    denominations.forEach((d) => {
        const byD = groupedByDenominationUserConsolidatedCollections[d];
        if (isArrayValidAndNotEmpty(byD)) {
            userEnteredConsolidatedCashCollections = userEnteredConsolidatedCashCollections.concat(byD);
        } else {
            userEnteredConsolidatedCashCollections = userEnteredConsolidatedCashCollections.concat({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        }
    });
    const groupedByModeUserConsolidatedCollections = groupArrayOfObjectsByFieldValue(userEnteredConsolidatedOtherCollections, 'paymentMethod');
    userEnteredConsolidatedOtherCollections = [];
    bankTransactions.forEach((t) => {
        const byD = groupedByModeUserConsolidatedCollections[t.paymentMethod];
        if (isArrayValidAndNotEmpty(byD)) {
            userEnteredConsolidatedOtherCollections = userEnteredConsolidatedOtherCollections.concat(byD);
        } else {
            userEnteredConsolidatedOtherCollections = userEnteredConsolidatedOtherCollections.concat({
                uiUuid: uuidv4(),
                label: t.label,
                paymentMethod: t.paymentMethod,
                amount: 0,
            });
        }
    });

    uiObject.userEnteredConsolidatedCashCollections = userEnteredConsolidatedCashCollections;
    uiObject.userEnteredConsolidatedOtherCollections = userEnteredConsolidatedOtherCollections;
    let totalCollections = [{
        uiUuid: uuidv4(),
        label: PAYMENT_METHODS_MAP.CASH.label,
        paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
        amount: NumberOf(getStringFromObject(`totalCollections.${PAYMENT_METHODS_MAP.CASH.value}`, payload)),
    }];
    totalCollections = totalCollections.concat(
        otherCollections.map(other => ({
            ...other,
            amount: NumberOf(getStringFromObject(`totalCollections.${other.paymentMethod}`, payload)),
        })),
    );
    uiObject.totalCollections = totalCollections;
    return uiObject;
};

export const getInitialValues = (
    denominations = [],
    bankTransactions = [],
    defaultCashCloseType = null,
) => {
    let totalCollections = [];
    const cashCollections = [];
    const otherCollections = [];
    const userEnteredConsolidatedCashCollections = [];
    const userEnteredConsolidatedOtherCollections = [];
    if (isArrayValidAndNotEmpty(denominations)) {
        denominations.forEach((d) => {
            cashCollections.push({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        });
    }
    if (isArrayValidAndNotEmpty(denominations)) {
        denominations.forEach((d) => {
            userEnteredConsolidatedCashCollections.push({
                uiUuid: uuidv4(),
                denomination: d,
                quantity: '',
                amount: '',
            });
        });
    }
    if (isArrayValidAndNotEmpty(bankTransactions)) {
        bankTransactions.forEach((d) => {
            otherCollections.push({
                uiUuid: uuidv4(),
                label: d.label,
                paymentMethod: d.paymentMethod,
                amount: '',
            });
        });
    }
    if (isArrayValidAndNotEmpty(bankTransactions)) {
        bankTransactions.forEach((d) => {
            userEnteredConsolidatedOtherCollections.push({
                uiUuid: uuidv4(),
                label: d.label,
                paymentMethod: d.paymentMethod,
                amount: '',
            });
        });
    }
    totalCollections.push({
        uiUuid: uuidv4(),
        label: PAYMENT_METHODS_MAP.CASH.label,
        paymentMethod: PAYMENT_METHODS_MAP.CASH.value,
        amount: '',
    });
    totalCollections = totalCollections.concat(otherCollections);
    return ({
        cashCollections,
        userEnteredConsolidatedCashCollections,
        otherCollections,
        userEnteredConsolidatedOtherCollections,
        totalCollections,
        cashCloseType: defaultCashCloseType,
        date: new Date(),
    });
};

export const getPrintData = (consolidatedCashClose, bankTransactions) => {
    const printData = { ...consolidatedCashClose };
    const totalCollections = getStringFromObject('totalCollections', consolidatedCashClose);
    printData.totalCollections = [];
    if (isObjectValidAndNotEmpty(totalCollections)) {
        const keys = Object.keys(totalCollections);
        printData.totalCollections = keys.map(k => ({
            name: `${k.charAt(0).toUpperCase()}${k.slice(1)}`,
            amount: NumberOf(totalCollections[k]),
        }));
    } else if (isArrayValidAndNotEmpty(bankTransactions)) {
        printData.totalCollections = [{ name: 'Cash', amount: 0 }];
        printData.totalCollections = printData.totalCollections.concat(bankTransactions.map(a => ({
            name: a.label,
            amount: 0,
        })));
    }
    const caculatedCollections = getStringFromObject('calculatedCollections', consolidatedCashClose);
    if (isArrayValidAndNotEmpty(caculatedCollections)) {
        printData.consolidatedCollections = sortArrayOfObjectsByFieldValue(
            caculatedCollections.filter(c => c.paymentMethod === PAYMENT_METHODS_MAP.CASH.value), 'denomination').reverse();
        printData.consolidatedCollections = printData.consolidatedCollections.concat(caculatedCollections.filter(c => c.paymentMethod === 'CARD'));
        printData.consolidatedCollections = printData.consolidatedCollections.concat(caculatedCollections.filter(c => c.paymentMethod === 'OTHER'));
    }
    const userEnteredCollections = getStringFromObject('userEnteredCollections', consolidatedCashClose);
    if (isArrayValidAndNotEmpty(userEnteredCollections)) {
        printData.userCollections = sortArrayOfObjectsByFieldValue(
            userEnteredCollections.filter(c => c.paymentMethod === PAYMENT_METHODS_MAP.CASH.value), 'denomination').reverse();
        printData.userCollections = printData.userCollections.concat(userEnteredCollections.filter(c => c.paymentMethod === 'CARD'));
        printData.userCollections = printData.userCollections.concat(userEnteredCollections.filter(c => c.paymentMethod === 'OTHER'));
    }
    return printData;
};
