import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    ASSET_ADD_FAILURE,
    ASSET_ADD_SUCCESS,
    ASSET_DELETE_FAILURE,
    ASSET_DELETE_SUCCESS,
    ASSET_LIST_FETCH_FAILURE,
    ASSET_LIST_FETCH_SUCCESS,
    ASSET_RETIRE_FAILURE,
    ASSET_RETIRE_SUCCESS,
    ASSET_VIEW_FAILURE,
    ASSET_VIEW_SUCCESS,
    COMPUTE_DEPRECIATION_BOARD_FAILURE,
    COMPUTE_DEPRECIATION_BOARD_SUCCESS,
    findAssetDetails,
    FORCE_DEPRECIATION_FAILURE,
    FORCE_DEPRECIATION_SUCCESS,
} from '../../redux/modules/assets/asset-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import specsForFilter from './SearchConstruct';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* assetListFetchRequest(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const { filter, page, size } = action;
        const apiWithQueryParam = `${action.api}?page=${page}&size=${size}&sort=createDate`;
        const searchConstruct = specsForFilter(filter);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParam, searchConstruct, config);
        yield put({
            type: ASSET_LIST_FETCH_SUCCESS,
            data: response.data,
            filters: filter,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_LIST_FETCH_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetAddRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({
            type: ASSET_ADD_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ASSET_ADD_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetDeleteRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}?uuid=${action.uuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithQueryParam, action.payload);
        yield put({
            type: ASSET_DELETE_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: ASSET_DELETE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetRetireRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}/${action.uuid}?retireReason=${action.retireReason}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield call(retriable().post, apiWithQueryParam, null);
        yield put({ type: ASSET_RETIRE_SUCCESS });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: ASSET_RETIRE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* assetViewRequest(action) {
    try {
        const apiWithQueryParam = `${action.api}?uuid=${action.uuid}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam);
        yield put({
            type: ASSET_VIEW_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: ASSET_VIEW_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* computeDepreciationBoardRequest(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield call(retriable().get, `${action.api}/${action.uuid}`);
        yield put({ type: COMPUTE_DEPRECIATION_BOARD_SUCCESS });
        yield put(findAssetDetails(action.uuid));
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: COMPUTE_DEPRECIATION_BOARD_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}


export function* forceDepreciationRequest(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield call(retriable().get, `${action.api}?uuid=${action.uuid}`);
        yield put({ type: FORCE_DEPRECIATION_SUCCESS });
        yield put(findAssetDetails(action.assetUUID));
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FORCE_DEPRECIATION_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
