import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';

import API from '../../../constants/api';
import { getLoggedInProviderUuid } from '../../../constants/state';
import ReduxFormCheckbox from '../../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import { apiCatchBlockFunction, getTextFieldChangedValue } from '../../../constants/CommonUtil';
import OutlinedTextField from '../../../components/OutlinedTextField';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { successMessage } from '../../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';

class OnBehalfSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onBehalfDto: {
                enable: false,
                password: null,
            },
        };
    }

    componentDidMount() {
        this.fetchBehalfSettings();
        this.props.updateDialogActions(
            <ActionButton
                onClick={this.onConfirm}
            >
                CONFIRM
            </ActionButton>,
        );
    }

    onPasswordChange = (e) => {
        const password = getTextFieldChangedValue(e);
        this.setState(prevState => ({
            onBehalfDto: {
                ...prevState.onBehalfDto,
                password,
            },
        }));
    };

    onConfirm = async () => {
        const {
            onBehalfDto,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        try {
            dispatch(showSpinner());
            await axios.post(API.ON_BEHALF.UPDATE_ON_BEHALF, onBehalfDto);
            dispatch(successMessage('Behalf Settings Updated Successfully'));
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    onEnableChange = (enable) => {
        this.setState(prevState => ({
            onBehalfDto: {
                ...prevState.onBehalfDto,
                enable,
            },
        }));
    };

    fetchBehalfSettings = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            dispatch(showSpinner());
            const response = await axios(`${API.ON_BEHALF.FETCH_BEHALF_DETAILS}${getLoggedInProviderUuid()}`);
            this.setState({
                onBehalfDto: response.data,
            });
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    render() {
        const {
            onBehalfDto,
        } = this.state;
        return (
            <Grid container spacing={16} className="mt-1">
                <Grid item sm={2} md={2} lg={2}>
                    <ReduxFormCheckbox
                        test-id="enable"
                        input={{
                            value: getStringFromObject('enable', onBehalfDto) || false,
                            onChange: this.onEnableChange,
                        }}
                        label="Enable"
                    />
                </Grid>
                <Grid item sm={3} md={3} lg={3}>
                    <OutlinedTextField
                        testId="password"
                        value={getStringFromObject('password', onBehalfDto)}
                        onChange={this.onPasswordChange}
                        label="Password"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

OnBehalfSettings.propTypes = {
    dispatch: PropTypes.func.isRequired,
    updateDialogActions: PropTypes.func.isRequired,
};

OnBehalfSettings.defaultProps = {
};

export default OnBehalfSettings;

