import initialStates from '../../initialStates';
import { FETCH_ORGANIZATION_LIST_SUCCESS } from './organization-actions';

const organizationReducer = (state = initialStates.organization, action) => {
    let newState = {};
    const { data, payload } = action;
    switch (action.type) {
    case FETCH_ORGANIZATION_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            list: data.page.content,
            isLast: data.isLast,
            isFirst: data.page.first,
            totalPages: data.page.totalPages,
            page: data.page.number,
            size: data.page.size,
            searchText: payload.searchText,
            positionSelectText: payload.positionSelectText,
            departmentSelectText: payload.departmentSelectText,
            empType: payload.empType,
            showDeactivated: payload.showDeactivated,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default organizationReducer;
