import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_FORMULARY_LIST_FAILURE,
    FETCH_FORMULARY_LIST_SUCCESS,
    SAVE_FORMULARY_LIST_FAILURE,
    SAVE_FORMULARY_LIST_SUCCESS,
} from '../../redux/modules/formularyList/formularyList-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import messages from '../../constants/messages';

export function* saveFormularyListWorker(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const {
            api,
            payorUuid,
            state,

            applyFrom,
        } = action;
        console.log('applyfromasdasd', applyFrom);
        const apiWithParams = `${api}?payerUUID=${payorUuid}&state=${state}&applyFrom=${applyFrom}`;
        const response = yield call(retriable().post, apiWithParams, action.payload);
        yield put({
            type: SAVE_FORMULARY_LIST_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({
            type: SUCCESS_MESSAGE,
            message: `${messages.FORMULARY_LIST.CREATE.SUCCESS}`,
        });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.FORMULARY_LIST.CREATE.FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({
            type: SAVE_FORMULARY_LIST_FAILURE,
        });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchFormularyListWorker(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const {
            api, payorUuid, state, searchString, formularyListUuid = null,
        } = action;
        const apiWithParams = formularyListUuid ?
            `${api}?formularyUuid=${formularyListUuid}&searchString=${searchString}`
            : `${api}?payerUuid=${payorUuid}&state=${state}&searchString=${searchString}`;
        const response = yield call(retriable().get, apiWithParams);
        yield put({
            type: FETCH_FORMULARY_LIST_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
    } catch (e) {
        if (isValidFunction(action.failureCallback)) {
            action.failureCallback();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.FORMULARY_LIST.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({
            type: FETCH_FORMULARY_LIST_FAILURE,
        });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
