import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    iconDisable: {
        visibility: 'hidden',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        width: '11em',
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
    },
});

class SelectFieldWithAction extends React.PureComponent {
    onChange = (event) => {
        event.stopPropagation();
        const { input, handleSelectAction } = this.props;
        const onChangeHandler = getStringFromObject('onChange', input);
        if (isValidFunction(onChangeHandler)) {
            onChangeHandler(event.target.value);
        }
        if (isValidFunction(handleSelectAction)) {
            handleSelectAction(event.target.value, event);
        }
        const {
            meta: { dispatch, form }, onChangeHandlers, fieldMember, fields, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(event.target.value, form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    handleClick = (event) => {
        event.stopPropagation();
    };

    render() {
        const {
            menuitems,
            classes,
            label,
            noLabel,
            // ---- ignored props (do not pass unnecessary props down) -----
            onChangeHandlers,
            fieldMember,
            fields,
            actionHandlers,
            // ----------------
            ...otherProps
        } = this.props;
        const InputProps = getStringFromObject('InputProps', otherProps, {});
        console.log('adfsdf', otherProps);
        return (
            <FormControl style={{ width: '100%' }}>
                <TextField
                    {...otherProps}
                    select
                    onClick={this.handleClick}
                    label={noLabel ? '' : label}
                    SelectProps={{
                        classes: {
                            icon: InputProps.disabled ? classes.iconDisable : '',
                        },
                    }}
                    onChange={this.onChange}
                    InputProps={{
                        ...InputProps,
                    }}
                >
                    {menuitems}
                </TextField>
            </FormControl>
        );
    }
}

SelectFieldWithAction.propTypes = {
    meta: PropTypes.object,
    labelstyle: PropTypes.object,
    input: PropTypes.object.isRequired,
    menuitems: PropTypes.node,
    label: PropTypes.any,
    classes: PropTypes.object.isRequired,
    isMandatory: PropTypes.bool,
    noLabel: PropTypes.bool,
    handleSelectAction: PropTypes.func,
    onChangeHandlers: PropTypes.array,
    fieldMember: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
};

SelectFieldWithAction.defaultProps = {
    meta: {},
    labelstyle: {},
    menuitems: <div />,
    label: null,
    isMandatory: false,
    noLabel: false,
    handleSelectAction: () => {},
    onChangeHandlers: [],
    fieldMember: '',
    fields: {},
    actionHandlers: {},
};

export default withStyles(styles)(SelectFieldWithAction);

