export const SAVE_WS_CONNECTION = '@@websocket/SAVE_WS_CONNECTION';
export const CLEAR_WS_CONNECTION = '@@websocket/CLEAR_WS_CONNECTION';

export const saveWSConnection = stompClient => ({
    type: SAVE_WS_CONNECTION,
    stompClient,
});
export const clearWSConnection = () => ({
    type: CLEAR_WS_CONNECTION,
});

export default saveWSConnection;
