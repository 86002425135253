export const COMMON_GET_API_REQUEST = '@@common/COMMON_GET_API_REQUEST';
export const COMMON_GET_API_SUCCESS = '@@common/COMMON_GET_API_SUCCESS';
export const COMMON_GET_API_FAILURE = '@@common/COMMON_GET_API_FAILURE';

export const COMMON_POST_API_REQUEST = '@@common/COMMON_POST_API_REQUEST';
export const COMMON_POST_API_SUCCESS = '@@common/COMMON_POST_API_SUCCESS';
export const COMMON_POST_API_FAILURE = '@@common/COMMON_POST_API_FAILURE';

export const COMMON_DELETE_API_REQUEST = '@@common/COMMON_DELETE_API_REQUEST';
export const COMMON_DELETE_API_SUCCESS = '@@common/COMMON_DELETE_API_SUCCESS';
export const COMMON_DELETE_API_FAILURE = '@@common/COMMON_DELETE_API_FAILURE';

export const commonGetApiRequest = (api, actionParams) => ({
    type: COMMON_GET_API_REQUEST,
    api,
    successAction: COMMON_GET_API_SUCCESS,
    failureAction: COMMON_GET_API_FAILURE,
    ...actionParams,
});

export const commonPostApiRequest = (api, actionParams) => ({
    type: COMMON_POST_API_REQUEST,
    api,
    successAction: COMMON_POST_API_SUCCESS,
    failureAction: COMMON_POST_API_FAILURE,
    ...actionParams,
});

export const commonDeleteApiRequest = (api, actionParams) => ({
    type: COMMON_DELETE_API_REQUEST,
    api,
    successAction: COMMON_DELETE_API_SUCCESS,
    failureAction: COMMON_DELETE_API_FAILURE,
    ...actionParams,
});
