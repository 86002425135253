import API from '../../../constants/api';

export const FETCH_BILLED_PACKAGES = '@@billedMedicalPackage/FETCH_BILLED_PACKAGES';
export const FETCH_BILLED_PACKAGES_SUCCESS = '@@billedMedicalPackage/FETCH_BILLED_PACKAGES_REQUEST';
export const FETCH_BILLED_PACKAGES_FAILURE = '@@billedMedicalPackage/FETCH_BILLED_PACKAGES_FAILURE';

export const fetchBilledPackages = (page, size, searchConstruct) => ({
    type: FETCH_BILLED_PACKAGES,
    api: API.MEDICAL_PACKAGES.BILL_MEDICAL_PACKAGES,
    page,
    size,
    searchConstruct,
});
