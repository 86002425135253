import React from 'react';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { TextField } from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    labelRequired,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const styles = () => ({
    iconDisable: {
        visibility: 'hidden',
    },
    disableText: {
        color: 'black',
    },
    parent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    labelDiv: {
        width: '11em',
        marginRight: '1em',
    },
    fieldDiv: {
        flexGrow: '1',
    },
    root: {
        fontSize: '14px',
        color: 'black',
    },
});

class SideLabelSelectField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: props.menuitems,
            apiData: [],
        };
    }

    componentDidMount() {
        const { dataSourceApi } = this.props;
        if (dataSourceApi) {
            this.fetchChoices(dataSourceApi);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.dataSourceApi, this.props.dataSourceApi)) {
            this.fetchChoices(nextProps.dataSourceApi);
        }
    }

    getMenuItems = (data) => {
        if (isArrayValidAndNotEmpty(data)) {
            const { dataSourceConfig } = this.props;
            return data.map(aChoice => (
                <MenuItem
                    value={aChoice[dataSourceConfig.value]}
                    key={aChoice[dataSourceConfig.value]}
                >
                    {aChoice[dataSourceConfig.text]}
                </MenuItem>
            ));
        }
        return [];
    };

    handleOnChange = (event) => {
        const { input, dataSourceConfig, onValueChange } = this.props;
        const { apiData } = this.state;
        const value = getStringFromObject('target.value', event, '');
        input.onChange(value);
        /* if api data is there then search the object
        and send object also */
        let obj = null;
        if (isArrayValidAndNotEmpty(apiData)) {
            obj = apiData.find(t => t[dataSourceConfig.value] === value);
        }
        onValueChange(value, obj);
    };

    fetchChoices = (dataSourceApi) => {
        const { dispatch } =
        axios.get(dataSourceApi)
            .then((response) => {
                this.setState({ menuItems: this.getMenuItems(response.data), apiData: response.data });
            })
            .catch(error => apiCatchBlockFunction(error, dispatch));
    };

    render() {
        const {
            label,
            isMandatory,
            formValues, // eslint-disable-line
            meta: { touched, error },
            classes,
            editable,
            ...otherProps
        } = this.props;
        const { menuItems } = this.state;
        const fieldName = otherProps.input.name;
        const { InputProps = {} } = otherProps;
        console.log('sfsdfsdfdf', otherProps.input, editable, this.props);
        return (
            <div className={classes.parent}>
                <div className={classes.labelDiv}>
                    <div style={otherProps.labelstyle} >
                        {label}{ isMandatory && labelRequired()}
                    </div>
                </div>
                <div className={classes.fieldDiv}>
                    <FormControl style={{ width: '100%' }}>
                        <TextField
                            {...otherProps}
                            select
                            SelectProps={{
                                classes: {
                                    icon: InputProps.disabled ? classes.iconDisable : '',
                                },
                            }}
                            InputProps={{
                                ...InputProps,
                                classes: {
                                    disabled: classes.disableText,
                                    root: classes.root,
                                },
                            }}
                            onChange={this.handleOnChange}
                        >
                            {menuItems}
                        </TextField>
                        {(touched && error) && <FormHelperText htmlFor={fieldName} error>{error}</FormHelperText>}
                    </FormControl>
                </div>
            </div>
        );
    }
}

SideLabelSelectField.propTypes = {
    meta: PropTypes.object,
    dataSourceApi: PropTypes.string,
    labelstyle: PropTypes.object,
    dataSourceConfig: PropTypes.object,
    editable: PropTypes.bool,
    menuitems: PropTypes.node,
    label: PropTypes.any,
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    isMandatory: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
};

SideLabelSelectField.defaultProps = {
    meta: {},
    labelstyle: {},
    menuitems: <div />,
    dataSourceConfig: <div />,
    editable: true,
    label: null,
    dataSourceApi: null,
    isMandatory: false,
};

export default connect()(withStyles(styles)(SideLabelSelectField));

