import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    FETCH_PAYROLL_DETAILS_FAILURE,
    FETCH_PAYROLL_DETAILS_SUCCESS, FETCH_PAYSLIP_DETAILS_FAILURE, FETCH_PAYSLIP_DETAILS_SUCCESS,
} from '../../redux/modules/employeePayroll/employeeParyoll-actions';
import { didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch } from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';

export function* fetchPayrollDetailsWorker(action) {
    try {
        const { api } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, api);
        yield put({
            type: FETCH_PAYROLL_DETAILS_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_PAYROLL_DETAILS_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* fetchPayslipDetailsWorker(action) {
    try {
        const { api, year, month } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithParam = `${api}?year=${year}&month=${month}`;
        const response = yield call(retriable().get, apiWithParam);
        yield put({
            type: FETCH_PAYSLIP_DETAILS_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        }
        yield put({ type: FETCH_PAYSLIP_DETAILS_FAILURE, data: {} });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
