import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { IntlFormattedMessage } from '../../components/IntlFormattedMessage';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: '#0ab129',
    },
    error: {
        backgroundColor: '#b13100',
    },
    info: {
        backgroundColor: '#2979ff',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const MySnackbarContent = (props) => {
    const {
        classes,
        className,
        message,
        onClose,
        variant,
        showCloseIcon,
        messageStyle,
        ...other
    } = props;
    const Icon = variantIcon[variant];
    console.log('asda0shidnoksd', variant);
    let messageElement = message;
    if (isObjectValidAndNotEmpty(message) && message.id) {
        messageElement = <IntlFormattedMessage {...message} />;
    }
    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message} style={messageStyle}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {messageElement}
                </span>
            }
            action={showCloseIcon ? [
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ] : []}
            {...other}
        />
    );
};

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    showCloseIcon: PropTypes.bool,
    messageStyle: PropTypes.object,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

MySnackbarContent.defaultProps = {
    className: '',
    showCloseIcon: true,
    messageStyle: {},
    message: <div />,
    onClose: () => {},
};

export default withStyles(styles)(MySnackbarContent);
