import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { roundedValueFixedToTwoDigits } from '../../constants/CommonUtil';
import baseTheme from '../../themes/baseTheme';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';

class PayslipDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleState: {},
        };
    }

    render() {
        const {
            sampleState,
        } = this.state;
        const {
            payslipLines,
        } = this.props;
        console.log(payslipLines, sampleState);
        const borderString = '1px solid #adadad';
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={16}
                    style={{
                        borderBottom: borderString,
                        color: baseTheme.palette.nuacarePrimaryColor,
                    }}
                >
                    <Grid
                        item
                        sm={5}
                        md={5}
                        lg={5}
                    >
                        Pay And Allowances
                    </Grid>
                    <Grid item sm={3} md={3} lg={3} className="flexChildren justify-content-center">
                        Monthly
                    </Grid>
                    <Grid item sm={4} md={4} lg={4} className="flexChildren justify-content-center">
                        Annually
                    </Grid>
                </Grid>
                {
                    payslipLines.map(aPayslipLine => (
                        <Grid
                            container
                            spacing={16}
                            className="p0-5"
                            style={{
                                borderBottom: borderString,
                            }}
                            key={getStringFromObject('payslip.key', aPayslipLine)}
                        >
                            <Grid
                                item
                                sm={5}
                                md={5}
                                lg={5}
                            >
                                {getStringFromObject('name', aPayslipLine)}
                            </Grid>
                            <Grid
                                item
                                sm={3}
                                md={3}
                                lg={3}
                                className="flexChildren justify-content-center"
                            >
                                {roundedValueFixedToTwoDigits(NumberOf(aPayslipLine.total))}
                            </Grid>
                            <Grid
                                item
                                sm={4}
                                md={4}
                                lg={4}
                                className="flexChildren justify-content-center"
                            >
                                {roundedValueFixedToTwoDigits(NumberOf(aPayslipLine.annualTotal))}
                            </Grid>
                        </Grid>
                    ))
                }
            </React.Fragment>
        );
    }
}

PayslipDetails.propTypes = {
    payslipLines: PropTypes.array,
};

PayslipDetails.defaultProps = {
    payslipLines: [],
};

export default PayslipDetails;

