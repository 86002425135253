import { takeLatest } from 'redux-saga/effects';
import {
    GENERIC_ADD_OR_UPDATE_REQUEST,
    GENERIC_CONSTRUCT_FETCH_REQUEST,
    GENERIC_FETCH_REQUEST,
} from '../../redux/modules/genericMastersRedux/genericMaster-actions';
import { constructFetchGenericMastersPagesWorker, fetchGenericMastersPagesWorker, genericAddOrUpdateWorker } from './genericMastersWorkerSaga';

/**
 * Created by sachinprakash on 12/8/19.
 */

function* fetchGenericMastersPagesWatcher() {
    yield takeLatest(GENERIC_FETCH_REQUEST, fetchGenericMastersPagesWorker);
}
export function* constructFetchGenericMastersPagesWatcher() {
    yield takeLatest(GENERIC_CONSTRUCT_FETCH_REQUEST, constructFetchGenericMastersPagesWorker);
}
export function* genericAddOrUpdateyWatcher() {
    yield takeLatest(GENERIC_ADD_OR_UPDATE_REQUEST, genericAddOrUpdateWorker);
}

export default fetchGenericMastersPagesWatcher;

