import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { Field, withFormik } from 'formik';
import cloneDeep from 'clone-deep';
import { withStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import classNames from 'classnames';

import { required } from '../../../constants/FormValidations';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import API from '../../../constants/api';
import ActionButton from '../../ActionButton/ActionButton';
import { getJsonPath, isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import GoodReturnListSelectPopUp from './GoodReturnListSelectPopUp';
import { ROWS_PER_PAGE } from '../../../constants/constants';
import { returnStockRequest } from '../../../redux/modules/stockMove/stockMove-actions';
import { mapUiObjectToDto, RECEIVED_GOODS_OBJECT, RETURN_GOOD_OPERATION } from '../../../mapper/ReturnGoodsMapper';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'goodReturnDialog';


class GoodReturnDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiced: Boolean(props.invoiced),
            invoiceSchema: null,
            productSchema: null,
            isSelectReceiptDialogOpen: false,
        };
    }

    componentDidMount() {
        Promise.all([
            fetch(getJsonPath('/StockManagement/GoodReturnInvoice.json')),
            fetch(getJsonPath('/StockManagement/GoodReturnEditProduct.json')),
        ]).then(([invoice, product]) => {
            Promise.all([
                invoice.json(),
                product.json(),
            ]).then(([invoiceJson, productJson]) => {
                this.setState({
                    invoiceSchema: invoiceJson,
                    productSchema: productJson,
                });
            }).catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        }).catch((error) => {
            console.error(`There has been a problem with your fetch operation:${error.message}`);
        });
    }

    handleSupplierChange = (value) => {
        const supplierUuid = getStringFromObject('uuid', value);
        const stockLocationUuid = getStringFromObject('sourceLoc.uuid', this.props.values);
        this.setState({ isSelectReceiptDialogOpen: Boolean(supplierUuid && stockLocationUuid) });
    };

    handleLocationChange = (value) => {
        const supplierUuid = getStringFromObject('partner.uuid', this.props.values);
        const stockLocationUuid = getStringFromObject('uuid', value);
        this.setState({ isSelectReceiptDialogOpen: Boolean(supplierUuid && stockLocationUuid) });
    };

    handleCloseReceiptDialog = () => this.setState({ isSelectReceiptDialogOpen: false });

    handleSelectReceipt = (selected = {}) => {
        console.log(selected);
        const lines = getStringFromObject('stockPickingLinesDTOSet', selected, []);
        const purchaseOrder = getStringFromObject('purchaseOrder', selected);
        this.setState({ isSelectReceiptDialogOpen: false }, () => {
            const products = [];
            if (isArrayValidAndNotEmpty(lines)) {
                lines.forEach((p) => {
                    products.push({
                        cost: p.cost,
                        salePrice: p.salePrice,
                        mrp: p.mrp,
                        expiryDate: p.expiryDate,
                        name: p.name,
                        batchName: p.batchName,
                        productUom: p.productUom,
                        product: p.product,
                        stockProdLot: p.stockProdLot,
                        quantityReceived: p.quantity,
                        quantity: 0,
                    });
                });
            }
            this.props.setFieldValue('stockPickingLinesDTOSet', products);
            this.props.setFieldValue('purchaseOrder', purchaseOrder);
        });
    };

    handleSave = () => {
        this.props.setFieldValue('status', RETURN_GOOD_OPERATION.SAVE);
        this.props.submitForm();
    };

    handleConfirm = () => {
        this.props.setFieldValue('status', RETURN_GOOD_OPERATION.DONE);
        this.props.submitForm();
    };

    render() {
        const {
            invoiceSchema,
            productSchema,
            isSelectReceiptDialogOpen,
            invoiced,
        } = this.state;
        const {
            open,
            classes,
            handleClose,
            values,
            purchaseOrderUuid,
        } = this.props;
        const partnerUuid = getStringFromObject('partner.uuid', values);
        const locationUuid = getStringFromObject('sourceLoc.uuid', values);
        const isDisabled = !(partnerUuid && locationUuid &&
            getStringFromObject('stockPickingLinesDTOSet.length', values) > 0);
        console.log('selectedadsdf', this.props);
        return (
            <Fragment>
                <Dialog
                    open={open}
                    fullScreen
                    classes={{
                        paper: classes.paper,
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                        <Grid container justify="space-between">
                            <div className={classes.header}>
                                Return Stock
                            </div>
                            <Close
                                className={classNames(classes.closeIcon, 'cursor-pointer')}
                                onClick={handleClose}
                                test-id="close-return-stock"
                            />
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="mt-1" spacing={32}>
                            <Grid item lg={3} sm={4} md={3}>
                                <Field
                                    name="partner"
                                    test-id="return-stock-partner"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    label="Supplier"
                                    autocomplete
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    required
                                    validate={required}
                                    dataSourceApi={API.SEARCH.SUPPLIER}
                                    onSelectHandlers={['handleSupplierChange']}
                                    actionHandlers={{
                                        handleSupplierChange: this.handleSupplierChange,
                                    }}
                                />
                            </Grid>
                            <Grid item lg={3} sm={4} md={3}>
                                <Field
                                    name="sourceLoc"
                                    test-id="return-stock-location"
                                    component={FormikReactSelectMaterial}
                                    validate={required}
                                    dataSourceConfig={{
                                        text: 'name',
                                        value: 'uuid',
                                    }}
                                    required
                                    label="Stock Location"
                                    autocomplete
                                    selectProps={{
                                        textFieldProps: {
                                            InputProps: {
                                                classes: {
                                                    multiline: classes.multiline,
                                                },
                                                disableUnderline: true,
                                            },
                                            variant: 'outlined',
                                        },
                                    }}
                                    dataSourceApi={API.SEARCH.INTERNAL_STOCK_LOCATION}
                                    onSelectHandlers={['handleLocationChange']}
                                    actionHandlers={{
                                        handleLocationChange: this.handleLocationChange,
                                    }}
                                />
                            </Grid>
                            {
                                partnerUuid && locationUuid &&
                                    <Grid item >
                                        <Field
                                            test-id="return-stock-createDate"
                                            name="createDate"
                                            component={FormikTextField}
                                            validate={required}
                                            type="date"
                                            variant="outlined"
                                            label="Return Date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                            }
                        </Grid>
                        <Grid container className="mt-2">
                            {
                                isObjectValidAndNotEmpty(productSchema) && partnerUuid && locationUuid &&
                                <FormikTable
                                    {...productSchema}
                                    fieldName="stockPickingLinesDTOSet"
                                    enablePagination
                                    showRowNumber
                                    tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                    deleteIcon
                                    rowsPerPageOptions={ROWS_PER_PAGE}
                                />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton onClick={handleClose} primary={false} className="mr-1" testId="return-stock-cancel">
                            Cancel
                        </ActionButton>
                        {/* <ActionButton
                            onClick={this.handleSave}
                            disabled={isConfirmed}
                        >
                            Save
                        </ActionButton> */}
                        <ActionButton
                            testId="return-stock-confirm"
                            onClick={this.handleConfirm}
                            disabled={isDisabled}
                        >
                            Confirm
                        </ActionButton>
                    </DialogActions>
                </Dialog>
                {
                    (
                        isSelectReceiptDialogOpen && partnerUuid && locationUuid
                        && isObjectValidAndNotEmpty(invoiceSchema)
                    ) &&
                        <GoodReturnListSelectPopUp
                            open
                            invoiceSchema={invoiceSchema}
                            purchaseOrderUuid={purchaseOrderUuid}
                            partnerUuid={partnerUuid}
                            locationUuid={locationUuid}
                            handleClose={this.handleCloseReceiptDialog}
                            onRowClick={this.handleSelectReceipt}
                            invoiced={invoiced}
                        />
                }
            </Fragment>
        );
    }
}


GoodReturnDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    invoiced: PropTypes.bool,
    purchaseOrderUuid: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};

GoodReturnDialog.defaultProps = {
    invoiced: false,
    purchaseOrderUuid: null,
};

const handleSubmit = (values, { props }) => {
    const dispatch = getStringFromObject('dispatch', props);
    const payload = mapUiObjectToDto(values);
    console.log('valuesnfahflajvkla', payload);
    dispatch(returnStockRequest(payload, props.handleClose));
};

const mapPropsToValues = (props) => {
    const initVal = cloneDeep(RECEIVED_GOODS_OBJECT);
    initVal.partner = getStringFromObject('partner', props, null);
    return initVal;
};

export default connect()((withFormik({
    mapPropsToValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit,
})(withStyles(dialogComponentStyles)(GoodReturnDialog))));
