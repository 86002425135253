import initialState from '../../initialStates';
import {
    CLEAR_APPOINTMENT_DETAILS,
    CLEAR_SELECTED_APPOINTMENT_DETAILS,
    FETCH_APPOINTMENT_DETAILS_SUCCESS,
    SELECT_APPOINTMENT_DETAILS_SUCCESS,
    SET_PATIENT_UUID,
} from './appointmentDetails-actions';
import {
    CANCEL_APPOINTMENT_SUCCESS,
    CONFIRM_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_SUCCESS,
} from '../appointment/appointment-actions';

const appointmentDetails = (state = initialState.appointmentDetails, action) => {
    let newState = state;
    switch (action.type) {
    case FETCH_APPOINTMENT_DETAILS_SUCCESS:
        newState = Object.assign({}, state, action.appointmentDetails);
        break;
    case SELECT_APPOINTMENT_DETAILS_SUCCESS:
        newState = Object.assign({}, state, { selected: action.appointmentDetails });
        break;
    case CANCEL_APPOINTMENT_SUCCESS:
    case CONFIRM_APPOINTMENT_SUCCESS:
    case CREATE_APPOINTMENT_SUCCESS:
        newState = Object.assign({}, state, { selected: action.data });
        break;
    case SET_PATIENT_UUID:
        newState = Object.assign({}, state, { selected: { ...state.selected, patientUuid: action.patientUuid } });
        break;
    case CLEAR_APPOINTMENT_DETAILS:
        newState = Object.assign({}, initialState.appointmentDetails, { selected: state.selected });
        break;
    case CLEAR_SELECTED_APPOINTMENT_DETAILS:
        newState = Object.assign({}, state, { selected: {} });
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export default appointmentDetails;

