import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '../../MaterialTableV2/MaterialTable';

import { getJsonPath } from '../../../constants/CommonUtil';

class GoodReturnInvoiceProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productSchema: null,
        };
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/GoodReturnProduct.json'))
            .then(response => response.json())
            .then((schema) => {
                this.setState({
                    productSchema: schema,
                });
            })
            .catch((error) => {
                console.log('Error In Fetch', error);
            });
    }

    render() {
        const { productSchema } = this.state;
        const { data } = this.props;
        return (
            <div style={{ padding: '10px' }}>
                {
                    productSchema &&
                        <MaterialTable
                            schema={productSchema}
                            data={data}
                            uniqueKey="uuid"
                            noPagination
                        />
                }
            </div>
        );
    }
}

GoodReturnInvoiceProduct.propTypes = {
    data: PropTypes.array,
};

GoodReturnInvoiceProduct.defaultProps = {
    data: [],
};

export default GoodReturnInvoiceProduct;
