import { call, put, select } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    FETCH_QUEUE_DATA_FAILURE,
    FETCH_QUEUE_DATA_SUCCESS,
} from '../../redux/modules/approvalQueue/approvalQueue-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import MESSAGES from '../../constants/messages';
import {
    didApiCallFailDueToUnauthorizedUser,
    isStringNullOrUndefined,
    didApiCallFailDueToVersionMismatch,
    isArrayValidAndNotEmpty,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import {
    getApprovalQueueSearchConstruct,
} from '../../containers/ApprovalQueueListingContainer/ApprovalQueueListingContainerUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const getApprovalDefaultListingEnabledDatePeriod = state => getStringFromObject('appConfiguration.approvalDefaultListingEnabledDatePeriods', state) || [];

export default function* getQueueListWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const {
            page,
            size,
            filters,
            searchFilter,
            approvalType,
            onlyClinical,
            seekDirection,
            seekParams,
            additionalFilters,
        } = action;
        let queryParams = `?page=${page}&size=${size}&approvalType=${approvalType}`;
        queryParams = addSeekDirectionAndParamsToUrlParams(queryParams, seekDirection, seekParams);
        console.log('asd9ja0sd90jad', seekParams, seekDirection);
        const api = `${action.api}${queryParams}`;
        const searchConstruct =
            getApprovalQueueSearchConstruct(approvalType, filters, searchFilter, onlyClinical, additionalFilters);

        const approvalDefaultListingEnabledDatePeriods = yield select(getApprovalDefaultListingEnabledDatePeriod);
        const datePeriod = getStringFromObject('datePeriod.key', filters);
        if (
            approvalType !== 'request' ||
            !isStringNullOrUndefined(searchFilter) ||
            (
                isArrayValidAndNotEmpty(approvalDefaultListingEnabledDatePeriods) &&
                !isStringNullOrUndefined(datePeriod) &&
                approvalDefaultListingEnabledDatePeriods.includes(datePeriod)
            )
        ) {
            const response = yield call(retriable().post, api, searchConstruct, config);
            console.log('asd-as0ud-saudap', response.data);
            yield put({
                ...action,
                type: FETCH_QUEUE_DATA_SUCCESS,
                data: response.data,
            });
        } else {
            yield put({ type: FETCH_QUEUE_DATA_FAILURE });
        }
    } catch (e) {
        yield put({ type: FETCH_QUEUE_DATA_FAILURE });
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({
                type: SESSION_TIMEOUT,
                actionToDispatchAfterLogin: action,
            });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else if (e.response && e.response.data && e.response.data.message) {
            yield put({
                type: ERROR_MESSAGE,
                message: e.response.data.message,
            });
        } else {
            console.error('error in fetch ', e);
            yield put({
                type: ERROR_MESSAGE,
                message: MESSAGES.QUEUE_LIST.ERROR_IN_FETCH,
            });
        }
    }
}
