import { takeLatest } from 'redux-saga/effects';
import { FETCH_FORMULARY_LIST_REQUEST,
    SAVE_FORMULARY_LIST_REQUEST } from '../../redux/modules/formularyList/formularyList-actions';
import { fetchFormularyListWorker, saveFormularyListWorker } from './formularyListWorkerSaga';

export function* saveFormularyListWatcher() {
    yield takeLatest(SAVE_FORMULARY_LIST_REQUEST, saveFormularyListWorker);
}

export function* fetchFormularyListWatcher() {
    yield takeLatest(FETCH_FORMULARY_LIST_REQUEST, fetchFormularyListWorker);
}
