import API from '../../constants/api';
import { getStringFromObject } from '../../constants/lodashUtils';

const OPTICAL_TAB_FILTERS = ([
    {
        name: 'opticalType',
        label: 'Type',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.GENERIC_DRUG.SEARCH_OPTICAL_GENERICS}`,
        dataSourceConfig: {
            text: 'genericDrugFullName',
            value: 'uuid',
        },
    },
    {
        name: 'supplier',
        label: 'Supplier',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.SEARCH.SUPPLIER,
        dataSourceConfig: {
            text: 'name',
            value: 'id',
        },
    },
    {
        name: 'quantity',
        label: 'Quantity',
        type: 'fieldWithOperation',
        options: ['<=', '=', '>='],
        valueType: 'number',
        valueLabel: 'Enter Quantity',
    },
    {
        name: 'includeInactive',
        label: 'Include inactive also',
        type: 'checkbox',
        defaultValue: false,
        disableClear: true,
    },
]);

export const simplifyOpticalPageFilters = (values) => {
    const obj = { opticalProducts: true };
    const name = getStringFromObject('searchText', values);
    if (name) {
        obj.name = name;
    }
    const supplier = getStringFromObject('supplier.id', values);
    if (supplier) {
        obj.supplier = supplier;
    }
    const operation = getStringFromObject('quantity.operation', values);
    const quantity = getStringFromObject('quantity.value', values);
    if (operation && (quantity != null || quantity !== undefined)) {
        const arr = [operation, quantity];
        obj.stockAvailability = arr.toString();
    }
    const includeInactive = getStringFromObject('includeInactive', values, false);
    if (!includeInactive) {
        obj.active = true;
    }
    const defaultCode = getStringFromObject('defaultCode', values);
    if (defaultCode) {
        obj.defaultCode = defaultCode;
    }
    const ean13 = getStringFromObject('ean13', values);
    if (ean13) {
        obj.ean13 = ean13;
    }
    const opticalType = getStringFromObject('opticalType.id', values);
    if (opticalType) {
        obj.opticalType = opticalType;
    }
    return obj;
};

export default OPTICAL_TAB_FILTERS;
