import { takeLatest } from 'redux-saga/effects';
import {
    recentListRequest,
    activeListRequest, allPatientListRequest,
} from './patientsWorkerSaga';
import {
    FETCH_ACTIVE_PATIENTS_REQUEST, FETCH_ALL_PATIENTS_REQUEST,
    FETCH_RECENT_PATIENTS_REQUEST,
} from '../../redux/modules/patients/patients-action';


export function* recentListFetchSaga() {
    yield takeLatest(FETCH_RECENT_PATIENTS_REQUEST, recentListRequest);
}

export function* activeListFetchSaga() {
    yield takeLatest(FETCH_ACTIVE_PATIENTS_REQUEST, activeListRequest);
}

export function* allPatientsListFetchSaga() {
    yield takeLatest(FETCH_ALL_PATIENTS_REQUEST, allPatientListRequest);
}
