const makeBillDialogStyles = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
    selectInput: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    multiInput: {
        padding: '6px',
    },
    totalField: {
        fontWeight: '500',
        padding: '0 8px',
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    paper: {
        background: '#fafafa',
    },
});

export default makeBillDialogStyles;
