/**
 * Created by sachinprakash on 15/7/19.
 */
import { takeLatest } from 'redux-saga/effects';
import { ADD_OR_UPDATE_PANEL_REQUEST, FETCH_PANELS_REQUEST } from '../../redux/modules/LabSettings/labTest-actions';
import { addOrUpdatePanelWorker, fetchPagesOfPanelsWorker } from './labPanelWorkerSaga';

export function* fetchPagesOfPanelsWatcher() {
    yield takeLatest(FETCH_PANELS_REQUEST, fetchPagesOfPanelsWorker);
}

export function* addOrUpdatePanelWatcher() {
    yield takeLatest(ADD_OR_UPDATE_PANEL_REQUEST, addOrUpdatePanelWorker);
}

