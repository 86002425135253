import { ADD_RETRY_REQUEST, CLEAR_RETRY_REQUESTS } from './retryRequests-actions';

const retryRequestsReducer = (state = [], action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case ADD_RETRY_REQUEST:
        newState = state.concat(action.payload.requestConfig);
        break;
    case CLEAR_RETRY_REQUESTS:
        newState = [];
        break;
    default:
        newState = state;
    }
    return newState;
};

export default retryRequestsReducer;
