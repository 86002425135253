import API from '../../../constants/api';

export const CREATE_RECEIVE_PAYMENT_REQUEST = '@@receivePayment/CREATE_RECEIVE_PAYMENT_REQUEST';
export const CREATE_RECEIVE_PAYMENT_FAILURE = '@@receivePayment/CREATE_RECEIVE_PAYMENT_FAILURE';
export const CREATE_RECEIVE_PAYMENT_SUCCESS = '@@receivePayment/CREATE_RECEIVE_PAYMENT_SUCCESS';

export const FETCH_RECEIVE_PAYMENT_REQUEST = '@@receivePayment/FETCH_RECEIVE_PAYMENT_REQUEST';
export const FETCH_RECEIVE_PAYMENT_FAILURE = '@@receivePayment/FETCH_RECEIVE_PAYMENT_FAILURE';
export const FETCH_RECEIVE_PAYMENT_SUCCESS = '@@receivePayment/FETCH_RECEIVE_PAYMENT_SUCCESS';

export const CLEAR_SELECTED_RECEIVE_PAYMENT = '@@receivePayment/CLEAR_SELECTED_RECEIVE_PAYMENT';

export const createReceivePaymentRequest = (receivePayment, successCallback, failureCallBack) => ({
    type: CREATE_RECEIVE_PAYMENT_REQUEST,
    api: API.RECEIVE_PAYMENT.CREATE,
    receivePayment,
    successCallback,
    failureCallBack,
});

export const fetchReceivePayment = (
    orderUuid, state = '', successCallback, failureCallBack,
) => ({
    type: FETCH_RECEIVE_PAYMENT_REQUEST,
    api: `${API.RECEIVE_PAYMENT.BY_PURCHSE_ORDER}${orderUuid}&state=${state}`,
    successCallback,
    failureCallBack,
});

export const clearSelectedReceivePayment = () => ({
    type: CLEAR_SELECTED_RECEIVE_PAYMENT,
});
