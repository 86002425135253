/**
 * Created by vinay on 18/12/18.
 */
import memoizeOne from 'memoize-one';
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty,
    roundedValueFixedToTwoDigits, sortArrayOfObjectsByFieldValue, sortData, valueToFixedTwoDigits,
} from '../constants/CommonUtil';
import { formatDate, formatDateForDisplay } from '../constants/DateUtil';
import {
    getAddressFromAddressDto,
    PURCHASE_ORDER_UI_OBJECT,
    getPurchaseOrderLinesUiObject,
} from './PurchaseOrderMapper';
import { getAddressFromAddressDtoMemoized } from '../components/PrintComponents/PurchaseOrderPrintUtil';
import { ERP_TRANSACTION_TYPES_DISPLAY } from '../constants/constants';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const OPERATION = {
    DRAFT: 'draft',
    DONE: 'done',
};

export const BATCH_UI_OBJECT = {
    uuid: '',
    origin: '',
    batchCost: '',
    batchSalePrice: '',
    batchMrp: '',
    batchNumber: '',
    batchQuantity: '',
    batchExpiryDate: '',
    stockProdLot: null,
};

const mapStockPickingLinesFromUiObject = (lines) => {
    const stockMoves = [];
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line) => {
            if (isArrayValidAndNotEmpty(line.batches)) {
                line.batches.forEach((batch) => {
                    const move = {};
                    move.uuid = batch.uuid;
                    move.origin = batch.origin;
                    move.cost = batch.batchCost;
                    move.salePrice = batch.batchSalePrice;
                    move.mrp = batch.batchMrp;
                    move.name = batch.batchNumber;
                    move.stockProdLot = batch.nonCodedBatch ? null : batch.stockProdLot;
                    move.batchName = batch.batchNumber;
                    move.productUom = { key: line.uom.uuid, value: line.uom.name };
                    move.product = { key: line.product.uuid, value: line.product.productName };
                    move.quantity = batch.batchQuantity;
                    move.purchaseOrderLine = line.orderLineUuid;
                    move.expiryDate = formatDate(batch.batchExpiryDate, 'yyyy-MM-dd hh:mm:ss');
                    move.productGtinMapDto = getStringFromObject('productGtinMapDto', batch) || null;
                    stockMoves.push(move);
                });
            }
        });
    }
    return stockMoves;
};

export const mapReceiveStockFromUiObject = (uiObject, purchaseOrder) => {
    const payload = {};
    console.log('recievemapper', uiObject, purchaseOrder);
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = getStringFromObject('action', uiObject);
        payload.operation = 'RECEIVE_STOCK';
        payload.origin = uiObject.origin;
        payload.notes = uiObject.notes;
        if (isObjectValidAndNotEmpty(purchaseOrder)) {
            payload.partner = { key: purchaseOrder.supplier.uuid, value: purchaseOrder.supplier.name };
            payload.company = { key: purchaseOrder.company.uuid, value: purchaseOrder.company.name };
            payload.purchaseOrder = purchaseOrder.uuid;
        } else {
            payload.partner = {
                key: getStringFromObject('supplier.uuid', uiObject),
                value: getStringFromObject('supplier.name', uiObject),
            };
            payload.company = {
                key: getStringFromObject('company.uuid', uiObject),
                value: getStringFromObject('company.name', uiObject),
            };
            payload.purchaseOrder = getStringFromObject('purchaseOrder', uiObject);
        }
        payload.stockPickingLinesDTOSet = mapStockPickingLinesFromUiObject(uiObject.receiveStock);
    }
    return payload;
};

export const getUObject = (purchaseOrder) => {
    console.log('getuistock', purchaseOrder);
    const uiObject = cloneDeep(PURCHASE_ORDER_UI_OBJECT);
    if (isObjectValidAndNotEmpty(purchaseOrder)) {
        console.log('asda-0sdias-dia0dsa', purchaseOrder);
        uiObject.uuid = purchaseOrder.uuid || '';
        uiObject.dateOrder = formatDate(purchaseOrder.dateOrder ? new Date(purchaseOrder.dateOrder) : new Date(),
            'yyyy-MM-dd');
        uiObject.supplier = {
            uuid: getStringFromObject('supplier.uuid', purchaseOrder),
            name: getStringFromObject('supplier.name', purchaseOrder),
        };
        uiObject.mailingAddress = getAddressFromAddressDto(getStringFromObject('partnerAddress', purchaseOrder, {}));
        uiObject.partnerAddress = getStringFromObject('partnerAddress.email', purchaseOrder, {});
        uiObject.email = getStringFromObject('partnerAddress.email', purchaseOrder);
        uiObject.email = getStringFromObject('email', purchaseOrder);
        uiObject.company = {
            uuid: getStringFromObject('companyId.uuid', purchaseOrder),
            name: getStringFromObject('companyId.name', purchaseOrder),
        };
        uiObject.shippingAddress = getAddressFromAddressDto(getStringFromObject('warehouseAddress', purchaseOrder, {}));
        uiObject.warehouse = {
            uuid: getStringFromObject('warehouse.uuid', purchaseOrder),
            name: getStringFromObject('warehouse.name', purchaseOrder),
        };
        uiObject.warehouseCompanyName = getStringFromObject('warehouseCompanyName', purchaseOrder);
        uiObject.warehouseAddress = getStringFromObject('warehouseAddress', purchaseOrder, {});
        uiObject.purchaseOrderLines = getPurchaseOrderLinesUiObject(purchaseOrder.purchaseOrderLineDtos);
        uiObject.name = purchaseOrder.name;
        uiObject.notes = purchaseOrder.notes;
        uiObject.state = purchaseOrder.state;
        const state = getStringFromObject('state', purchaseOrder);
        uiObject.productReceived = state === 'done' || state === 'cancel';
        uiObject.invoiced = getStringFromObject('invoiced', purchaseOrder, getStringFromObject('purchaseOrderInvoiced', purchaseOrder, false));
    }
    return uiObject;
};

const mapStockPickingLineUiObject = (
    moves,
    plines,
    receivedQuantMap,
    inDraftQuantMap,
    productReceived,
    returnedQuant,
) => {
    const lines = [];
    if (isArrayValidAndNotEmpty(plines)) {
        plines.forEach((pline, index) => {
            const sline = cloneDeep(pline);
            console.log('poline ', sline);
            let tempBatches = [];
            if (isArrayValidAndNotEmpty(moves)) {
                tempBatches = moves.filter(m => m.purchaseOrderLine === pline.orderLineUuid);
            }
            const batches = [];
            if (isArrayValidAndNotEmpty(tempBatches)) {
                console.log('asd-a0sd-a0doas-d0asodada', tempBatches);
                tempBatches.forEach((b) => {
                    const batch = cloneDeep(BATCH_UI_OBJECT);
                    batch.uuid = b.uuid;
                    batch.origin = b.origin;
                    batch.batchCost = b.cost;
                    batch.batchSalePrice = b.salePrice;
                    batch.batchMrp = b.mrp;
                    batch.batchNumber = b.batchName;
                    batch.stockProdLot = b.stockProdLot;
                    batch.batchQuantity = b.quantity;
                    batch.batchExpiryDate = b.expiryDate;
                    batch.trackByBatch = b.productTrackByBatch;
                    batch.productGtinMapDto = b.productGtinMapDto;
                    batches.push(batch);
                });
            }
            sline.batches = batches;
            sline.bonusPo = NumberOf(sline.bonus);
            const productUuid = pline.product.uuid;
            const receivedQuant = NumberOf(receivedQuantMap[productUuid]);
            // const receivedQuantInDraft = NumberOf(getStringFromObject(productUuid, inDraftQuantMap, 0));
            sline.received = receivedQuant;
            sline.returned = NumberOf(getStringFromObject(productUuid, returnedQuant));
            sline.originallyReceived = sline.received;
            sline.balanced = (NumberOf(sline.quantity) + sline.bonusPo) - (sline.received + sline.returned);
            const bonus = Math.max(0, (sline.received - sline.returned) - (NumberOf(sline.quantity) + sline.bonusPo));
            sline.bonus = bonus > 0 ? `+${bonus}` : bonus;
            sline.productReceived = productReceived;
            const cost = (NumberOf(pline.amount) - NumberOf(pline.discountAmount)) / (NumberOf(pline.quantity) + NumberOf(pline.bonus));
            sline.rate = roundedValueFixedToTwoDigits(cost);
            sline.sequence = pline.sequence || index;
            console.log('sdfadsfdsfadsfasdf', sline, pline);
            lines.push(sline);
        });
    }
    return sortArrayOfObjectsByFieldValue(lines, 'sequence');
};

export const getEmptyReceiveStockUiObject = (purchaseOrder, receivedQuant = {}, returnedMap) => {
    console.log('recieve goods props1', purchaseOrder, receivedQuant);
    const state = getStringFromObject('state', purchaseOrder);
    const productReceived = state === 'done' || state === 'cancel';
    const uiObject = {};
    uiObject.uuid = '';
    uiObject.status = '';
    uiObject.notes = '';
    uiObject.origin = '';
    uiObject.invoiced = getStringFromObject('invoiced', purchaseOrder, false);
    uiObject.isStockReceived = isObjectValidAndNotEmpty(receivedQuant);
    uiObject.receiveStock = mapStockPickingLineUiObject([],
        getStringFromObject('purchaseOrderLines', purchaseOrder, []),
        receivedQuant, null, productReceived,
        returnedMap);
    uiObject.supplier = getStringFromObject('supplier', purchaseOrder, null);
    uiObject.email = getStringFromObject('email', purchaseOrder);
    uiObject.email = getStringFromObject('email', purchaseOrder);
    uiObject.mailingAddress = getStringFromObject('mailingAddress', purchaseOrder);
    uiObject.shippingAddress = getStringFromObject('shippingAddress', purchaseOrder);
    uiObject.dateOrder = getStringFromObject('dateOrder', purchaseOrder);
    uiObject.warehouse = getStringFromObject('warehouse', purchaseOrder, null);
    uiObject.subVendor = getStringFromObject('subVendor', purchaseOrder) || false;
    return uiObject;
};

export const getFormValuesFromStockPickingDto = (stockPicking) => {
    const uiObject = {};
    uiObject.uuid = stockPicking.uuid;
    uiObject.status = stockPicking.status;
    uiObject.notes = stockPicking.notes;
    uiObject.origin = stockPicking.origin;
    uiObject.invoiced = getStringFromObject('purchaseOrderInvoiced', stockPicking, false);
    uiObject.isStockReceived = false;
    // uiObject.receiveStock = mapStockPickingLineUiObject([],
    //     purchaseOrder.purchaseOrderLines, receivedQuant, null, purchaseOrder.productReceived);
};


export const mapReceiveStockDtoToUiObject = (pickings = [], purchaseOrder) => {
    const uiObject = cloneDeep(getEmptyReceiveStockUiObject(purchaseOrder));
    if (isObjectValidAndNotEmpty(purchaseOrder)) {
        const state = getStringFromObject('state', purchaseOrder);
        const productReceived = state === 'done' || state === 'cancel';
        const groupedByStatus = {};
        if (isArrayValidAndNotEmpty(pickings)) {
            pickings.forEach((p) => {
                uiObject.invoiced = !!uiObject.invoiced || !!getStringFromObject('purchaseOrderInvoiced', p, false);
                if (p.status === 'done' || p.status === 'confirmed') {
                    groupedByStatus.done = (groupedByStatus[p.status] || []).concat(p);
                } else {
                    groupedByStatus[p.status] = (groupedByStatus[p.status] || []).concat(p);
                }
            });
        }
        if (isObjectValidAndNotEmpty(groupedByStatus)) {
            const receivedQuant = {};
            const returnedQuant = {};
            const inDraftQuant = {};
            if (isArrayValidAndNotEmpty(groupedByStatus.done)) {
                uiObject.isStockReceived = true;
                groupedByStatus.done.forEach((stock) => {
                    if (isArrayValidAndNotEmpty(stock.stockPickingLinesDTOSet)) {
                        stock.stockPickingLinesDTOSet.forEach((line) => {
                            if (stock.transactionType === 'RETURN_STOCK') {
                                // receivedQuant[line.product.key] = (receivedQuant[line.product.key] || 0) -
                                //     NumberOf(line.quantity);
                                returnedQuant[line.product.key] = (returnedQuant[line.product.key] || 0) +
                                    NumberOf(line.quantity);
                            } else {
                                receivedQuant[line.product.key] = (receivedQuant[line.product.key] || 0) +
                                    NumberOf(line.quantity);
                            }
                        });
                    }
                });
            }
            const inDraft = groupedByStatus.draft;
            if (isArrayValidAndNotEmpty(inDraft)) {
                inDraft.forEach((stock) => {
                    if (isArrayValidAndNotEmpty(stock.stockPickingLinesDTOSet)) {
                        stock.stockPickingLinesDTOSet.forEach((line) => {
                            inDraftQuant[line.product.key] = (inDraftQuant[line.product.key] || 0) +
                                NumberOf(line.quantity);
                        });
                    }
                });
                const stock = inDraft[0];
                uiObject.uuid = stock.uuid;
                uiObject.status = stock.status;
                uiObject.notes = stock.notes;
                uiObject.origin = stock.origin;
                uiObject.receiveStock = mapStockPickingLineUiObject(stock.stockPickingLinesDTOSet,
                    purchaseOrder.purchaseOrderLines, receivedQuant, inDraftQuant, productReceived,
                    returnedQuant);
            } else {
                return cloneDeep(getEmptyReceiveStockUiObject(purchaseOrder, receivedQuant, returnedQuant));
            }
        } else {
            return cloneDeep(getEmptyReceiveStockUiObject(purchaseOrder));
        }
    }
    return uiObject;
};

const getPrintData = (stockPickings, purchaseOrder) => {
    const printData = {
        pickings: [],
        company: getStringFromObject('warehouseCompanyName', purchaseOrder),
        partner: getStringFromObject('supplier.name', purchaseOrder),
        purchaseOrder: getStringFromObject('name', purchaseOrder),
    };
    const partnerAddress = getStringFromObject('partnerAddress', purchaseOrder);
    if (isObjectValidAndNotEmpty(partnerAddress)) {
        printData.partnerAddress = getAddressFromAddressDtoMemoized(partnerAddress);
    }
    if (isArrayValidAndNotEmpty(stockPickings)) {
        stockPickings.forEach((sp) => {
            const status = getStringFromObject('status', sp);
            if (status === 'done') {
                const moves = getStringFromObject('stockPickingLinesDTOSet', sp, []);
                if (isArrayValidAndNotEmpty(moves)) {
                    const lines = moves.map((l) => {
                        const rate = NumberOf(l.cost);
                        const quantity = NumberOf(l.quantity);
                        const lineTotal = rate * quantity;
                        return ({
                            name: getStringFromObject('product.value', l),
                            uom: getStringFromObject('productUom.value', l),
                            quantity: valueToFixedTwoDigits(quantity),
                            rate: valueToFixedTwoDigits(rate),
                            total: valueToFixedTwoDigits(lineTotal),
                        });
                    });
                    const type = getStringFromObject('transactionType', sp);
                    printData.pickings.push({
                        transactionType: getStringFromObject(type, ERP_TRANSACTION_TYPES_DISPLAY, type),
                        order: type === 'RECEIVE_STOCK' ? 0 : 1,
                        name: getStringFromObject('name', sp),
                        createDate: getStringFromObject('createDateTime', sp),
                        receivedBy: getStringFromObject('receivedBy', sp),
                        lines,
                    });
                }
            }
        });
    }
    printData.pickings = sortData(printData.pickings, 'order', true);
    return printData;
};
const getPrintDataView = (values) => {
    const printData = {
        lines: [],
        name: getStringFromObject('name', values),
        date: formatDateForDisplay(getStringFromObject('createDateTime', values)),
        company: getStringFromObject('companyName', values),
        partner: getStringFromObject('partner.value', values),
        purchaseOrder: getStringFromObject('purchaseOrderNumber', values),
    };
    const partnerAddress = getStringFromObject('partnerAddress', values);
    if (isObjectValidAndNotEmpty(partnerAddress)) {
        printData.partnerAddress = getAddressFromAddressDtoMemoized(partnerAddress);
    }
    const lines = getStringFromObject('stockPickingLinesDTOSet', values);
    let total = 0;
    if (isArrayValidAndNotEmpty(lines)) {
        printData.lines = lines.map((l) => {
            const rate = NumberOf(l.cost);
            const quantity = NumberOf(l.quantity);
            const lineTotal = rate * quantity;
            total += lineTotal;
            return ({
                name: getStringFromObject('product.value', l),
                uom: getStringFromObject('productUom.value', l),
                quantity: valueToFixedTwoDigits(quantity),
                rate: valueToFixedTwoDigits(rate),
                total: valueToFixedTwoDigits(lineTotal),
            });
        });
    }
    printData.total = total;
    console.log('pahfuhdsfa', printData, values);
    return printData;
};
export const getMemoizedPrintData = memoizeOne(getPrintData);
export const getMemoizedPrintDataView = memoizeOne(getPrintDataView);
