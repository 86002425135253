import initialStates from '../../initialStates';
import {
    WORKFLOW_SAVE_SUCCESS,
    WORKFLOW_FETCH_SUCCESS,
    RESET_WORKFLOW_STATE, WORKFLOW_FETCH_REQUEST, WORKFLOW_SAVE_REQUEST, WORKFLOW_SAVE_FAILURE,
} from './workflow-actions';

const workflowReducer = (state = initialStates.workflow, action) => {
    let newState = {};
    let editable = true;
    switch (action.type) {
    case WORKFLOW_FETCH_REQUEST:
        newState = Object.assign({}, state, { isLoading: true, data: {} });
        break;
    case WORKFLOW_SAVE_REQUEST:
        newState = Object.assign({}, state, { isUpdated: false });
        break;
    case WORKFLOW_SAVE_SUCCESS:
        editable = false;
        newState = Object.assign({}, state, {
            data: action.data, editable, isUpdated: true,
        });
        break;
    case WORKFLOW_SAVE_FAILURE:
        editable = false;
        newState = Object.assign({}, state, { isUpdated: false });
        break;
    case WORKFLOW_FETCH_SUCCESS:
        editable = !!(action.inboxType && ((action.inboxType === 'draft') || (action.inboxType === 'requests')));
        newState = Object.assign({}, state, { data: action.data, editable, isLoading: false });
        break;
    case RESET_WORKFLOW_STATE:
        newState = Object.assign(state, {}, initialStates.workflow);
        break;
    default:
        newState = state;
    }

    return newState;
};

export default workflowReducer;
