import initialStates from '../../initialStates';
import {
    CLEAR_ACCOUNT_VOUCHER,
    FETCH_ACCOUNT_VOUCHER_FAILURE,
    FETCH_ACCOUNT_VOUCHER_SUCCESS,
} from './accountVoucher-actions';


const accountVoucherReducer = (state = initialStates.accountVoucher, action) => {
    let newState;
    switch (action.type) {
    case FETCH_ACCOUNT_VOUCHER_SUCCESS:
        newState = {
            list: action.data.content,
            first: action.data.first,
            last: action.data.last,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            page: action.data.page,
            size: action.data.size,
        };
        break;
    case FETCH_ACCOUNT_VOUCHER_FAILURE:
    case CLEAR_ACCOUNT_VOUCHER:
        newState = { ...initialStates.debitAndCreditLines };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default accountVoucherReducer;
