export const INFO_MESSAGE = '@@message/INFO_MESSAGE';
export const WARNING_MESSAGE = '@@message/WARNING_MESSAGE';
export const SUCCESS_MESSAGE = '@@message/SUCCESS_MESSAGE';
export const ERROR_MESSAGE = '@@message/ERROR_MESSAGE';
export const CLEAR_MESSAGE = '@@message/CLEAR_MESSAGE';

export const errorMessage = message => ({
    type: ERROR_MESSAGE,
    message,
});

export const warningMessage = message => ({
    type: WARNING_MESSAGE,
    message,
});

export const successMessage = message => ({
    type: SUCCESS_MESSAGE,
    message,
});
export const infoMessage = message => ({
    type: INFO_MESSAGE,
    message,
});
export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});
