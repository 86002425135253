import api from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';

export const FETCH_CLAIMS_OR_BUNDLES_REQUEST = '@@claims/FETCH_CLAIMS_OR_BUNDLES_REQUEST';
export const FETCH_CLAIMS_OR_BUNDLES_SUCCESS = '@@claims/FETCH_CLAIMS_OR_BUNDLES_SUCCESS';
export const FETCH_CLAIMS_OR_BUNDLES_FAILURE = '@@claims/FETCH_CLAIMS_OR_BUNDLES_FAILURE';

export const SAVE_CLAIMS_AS_BUNDLE_REQUEST = '@@claims/SAVE_CLAIMS_AS_BUNDLE_REQUEST';
export const SAVE_CLAIMS_AS_BUNDLE_SUCCESS = '@@claims/SAVE_CLAIMS_AS_BUNDLE_SUCCESS';
export const SAVE_CLAIMS_AS_BUNDLE_FAILURE = '@@claims/SAVE_CLAIMS_AS_BUNDLE_FAILURE';

export const DOWNLOAD_BUNDLE_REQUEST = '@@claims/DOWNLOAD_BUNDLE_REQUEST';
export const DOWNLOAD_BUNDLE_SUCCESS = '@@claims/DOWNLOAD_BUNDLE_SUCCESS';
export const DOWNLOAD_BUNDLE_FAILURE = '@@claims/DOWNLOAD_BUNDLE_FAILURE';

export const DOWNLOAD_CLAIM_REQUEST = '@@claims/DOWNLOAD_CLAIM_REQUEST';
export const DOWNLOAD_CLAIM_SUCCESS = '@@claims/DOWNLOAD_CLAIM_SUCCESS';
export const DOWNLOAD_CLAIM_FAILURE = '@@claims/DOWNLOAD_CLAIM_FAILURE';

export const DOWNLOAD_TASK_STATUS_REQUEST = '@@claims/DOWNLOAD_TASK_STATUS_REQUEST';
export const DOWNLOAD_TASK_STATUS_SUCCESS = '@@claims/DOWNLOAD_TASK_STATUS_SUCCESS';
export const DOWNLOAD_TASK_STATUS_FAILURE = '@@claims/DOWNLOAD_TASK_STATUS_FAILURE';
export const CLEAR_DOWNLOAD_TASK_STATUS = '@@claims/CLEAR_DOWNLOAD_TASK_STATUS';

export const SAVE_PAYER_COLLECTION_FILTER = '@@claims/SAVE_PAYER_COLLECTION_FILTER';

export const BUNDLE_PAYER_LIST_REQUEST = '@@claims/BUNDLE_PAYER_LIST_REQUEST';
export const BUNDLE_PAYER_LIST_REQUEST_SUCCESS = '@@claims/BUNDLE_PAYER_LIST_REQUEST_SUCCESS';
export const BUNDLE_PAYER_LIST_REQUEST_FAILURE = '@@claims/BUNDLE_PAYER_LIST_REQUEST_FAILURE';

export const CLEAR_CLAIM_STATE = '@@claims/CLEAR_CLAIM_STATE';

export const fetchClaimsRequest = (
    page,
    size,
    filters,
    searchFilter,
    seekDirection,
    seekParams,
    defaultFilter,
) => ({
    type: FETCH_CLAIMS_OR_BUNDLES_REQUEST,
    api: api.CLAIMS.LIST,
    page,
    size,
    filters,
    searchFilter,
    seekDirection,
    seekParams,
    defaultFilter,
});

export const fetchBundlesRequest = (page, size, filters, searchFilter, seekDirection, seekParams, defaultFilter) => ({
    type: FETCH_CLAIMS_OR_BUNDLES_REQUEST,
    api: api.BUNDLES.SEEKABLE_LIST,
    page,
    size,
    filters,
    searchFilter,
    seekDirection,
    seekParams,
    defaultFilter,
    isBundle: true,
});

export const saveClaimBundler = () => ({
    type: SAVE_CLAIMS_AS_BUNDLE_REQUEST,
});

export const downloadBundleRequest = (uuid, successCallback, failureCallBack) => ({
    type: DOWNLOAD_BUNDLE_REQUEST,
    api: `${api.BUNDLES.DOWNLOAD_BUNDLE}${uuid}`,
    successAction: DOWNLOAD_BUNDLE_SUCCESS,
    successCallback,
    failureAction: DOWNLOAD_BUNDLE_FAILURE,
    failureCallBack,
    failureMessage: 'Failed to request download',
});

export const downloadClaimRequest = (claimUuid, zipFiles, successCallback, failureCallBack) => ({
    type: DOWNLOAD_CLAIM_REQUEST,
    api: getUrlWithApiParams(api.CLAIMS.DOWNLOAD_CLAIM, { claimUuid, zipFiles }),
    successAction: DOWNLOAD_CLAIM_SUCCESS,
    successCallback,
    failureAction: DOWNLOAD_CLAIM_FAILURE,
    failureCallBack,
    failureMessage: 'Failed to request download',
});

export const printClaimReportsRequest = (claimUuid, orderType, successCallback, failureCallBack) => ({
    type: DOWNLOAD_CLAIM_REQUEST,
    api: getUrlWithApiParams(api.CLAIMS.PRINT_ORDER_REPORTS, { claimUuid, orderType }),
    successAction: DOWNLOAD_CLAIM_SUCCESS,
    successCallback,
    failureAction: DOWNLOAD_CLAIM_FAILURE,
    failureCallBack,
    failureMessage: `Failed to generate '${orderType}' report`,
});

export const getDownloadTaskStatusRequest = (ref, type, successCallback, failureCallBack) => ({
    type: DOWNLOAD_TASK_STATUS_REQUEST,
    api: getUrlWithApiParams(api.CLAIMS.DOWNLOAD_STATUS, { ref, type }),
    successAction: DOWNLOAD_TASK_STATUS_SUCCESS,
    successCallback,
    failureAction: DOWNLOAD_TASK_STATUS_FAILURE,
    failureCallBack,
    failureMessage: 'Failed to get download status',
});

export const getBundlePayerListRequest = (bundleUuid, successCallback, failureCallBack) => ({
    type: BUNDLE_PAYER_LIST_REQUEST,
    api: `${api.BUNDLES.BUNDLE_PAYERS}${bundleUuid}`,
    successAction: BUNDLE_PAYER_LIST_REQUEST_SUCCESS,
    successCallback,
    failureAction: BUNDLE_PAYER_LIST_REQUEST_FAILURE,
    failureCallBack,
    failureMessage: 'Failed to get TPA, SubInsurer, Policy List for bundle',
});


export const clearClaimState = () => ({
    type: CLEAR_CLAIM_STATE,
});

export const clearDownloadTaskStatus = () => ({
    type: CLEAR_DOWNLOAD_TASK_STATUS,
});


export const savePayerCollectionFilter = payerCollectionFilter => ({
    type: SAVE_PAYER_COLLECTION_FILTER,
    payerCollectionFilter,
});
