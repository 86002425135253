import API from '../../../constants/api';

export const ADD_PATIENT_REQUEST = '@@Patient/ADD_PATIENT_REQUEST';
export const ADD_PATIENT_SUCCESS = '@@Patient/ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAILURE = '@@Patient/ADD_PATIENT_FAILURE';

export const UPDATE_PATIENT_REQUEST = '@@Patient/UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = '@@Patient/UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = '@@Patient/UPDATE_PATIENT_FAILURE';


// the registration actions are used when both patient add/update as well as visit related details are saved
export const ADD_REGISTRATION_REQUEST = '@@Patient/ADD_REGISTRATION_REQUEST';
export const ADD_REGISTRATION_SUCCESS = '@@Patient/ADD_REGISTRATION_SUCCESS';
export const ADD_REGISTRATION_FAILURE = '@@Patient/ADD_REGISTRATION_FAILURE';

export const UPDATE_REGISTRATION_REQUEST = '@@Patient/UPDATE_REGISTRATION_REQUEST';
export const UPDATE_REGISTRATION_SUCCESS = '@@Patient/UPDATE_REGISTRATION_SUCCESS';
export const UPDATE_REGISTRATION_FAILURE = '@@Patient/UPDATE_REGISTRATION_FAILURE';

export const FETCH_PATIENT_REQUEST = '@@Patient/FETCH_PATIENT_REQUEST';
export const FETCH_PATIENT_SUCCESS = '@@Patient/FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = '@@Patient/FETCH_PATIENT_FAILURE';

export const CLEAR_PATIENTS = '@@Visit/CLEAR_PATIENTS';

export const CHANGE_PATIENT_DETAILS_EDITABILITY = '@@Visit/CHANGE_PATIENT_DETAILS_EDITABILITY';

export const CHANGE_PATIENT_BLOCK_DETAILS = '@@Visit/CHANGE_PATIENT_BLOCK_DETAILS';

export const addPatientRequest = (patient, redirectUrlParams, successCallback) => ({
    type: ADD_PATIENT_REQUEST,
    api: API.PATIENT.ADD,
    patient,
    redirectUrlParams,
    successCallback,
});

export const updatePatientRequest = (patient, patientUuid) => ({
    type: UPDATE_PATIENT_REQUEST,
    api: API.PATIENT.UPDATE,
    patient,
    patientUuid,
});

export const addRegistrationRequest = (
    patient,
    redirectUrlParams,
    successCallback,
    failCallback,
    saveOnlyPatientDetails,
) => ({
    type: ADD_REGISTRATION_REQUEST,
    api: API.PATIENT.ADD,
    patient,
    redirectUrlParams,
    successCallback,
    failCallback,
    saveOnlyPatientDetails,
});

export const updateRegistrationRequest = (
    patient,
    patientUuid,
    successCallback,
    failCallback,
) => ({
    type: UPDATE_REGISTRATION_REQUEST,
    api: API.PATIENT.UPDATE,
    patient,
    patientUuid,
    successCallback,
    failCallback,
});

export const fetchPatientDetailsRequest = patientUuid => ({
    type: FETCH_PATIENT_REQUEST,
    api: API.PATIENT.FETCH,
    patientUuid,
});

export const changePatientDetailsEditability = editable => ({
    type: CHANGE_PATIENT_DETAILS_EDITABILITY,
    editable,
});

export const changePatientBlockDetails = blockDetails => ({
    type: CHANGE_PATIENT_BLOCK_DETAILS,
    blockDetails,
});

export const clearPatients = () => ({
    type: CLEAR_PATIENTS,
});
