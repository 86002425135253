import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_RECEIVE_PAYMENT,
    CREATE_RECEIVE_PAYMENT_SUCCESS,
    FETCH_RECEIVE_PAYMENT_SUCCESS,
} from './receivePayment-action';

const receivePaymentReducer = (state = initialStates.receivePayment, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_RECEIVE_PAYMENT_SUCCESS:
        newState = Object.assign({}, state, {
            selectedInvoice: action.data,
        });
        break;
    case CREATE_RECEIVE_PAYMENT_SUCCESS:
        newState = Object.assign({}, state, {
            selectedInvoice: [action.data],
        });
        break;
    case CLEAR_SELECTED_RECEIVE_PAYMENT:
        newState = Object.assign({}, state, { selectedInvoice: [] });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default receivePaymentReducer;
