/**
 * Created by sachinprakash on 21/6/19.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import {
    ADD_ORDERABLE_SERVICES_FAILURE,
    ADD_ORDERABLE_SERVICES_SUCCESS, ADD_PRODUCT_SERVICES_FAILURE,
    ADD_PRODUCT_SERVICES_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_SUCCESS,
} from '../../redux/modules/servicesPage/products-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import messages from '../../constants/messages';

export function* productsListFetch(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const {
            page,
            size,
            filters,
            sortCol,
            sortOrder,
        } = action;
        console.log('lkjasdhdlfkjahsdlkjfhaskdff', action);
        const apiWithQueryParam
            =
        // eslint-disable-next-line
        `${action.api}?page=${page}&size=${size}&sortCol=${sortCol}&filter=${encodeURIComponent(JSON.stringify(filters))}&sortOrder=${sortOrder}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, apiWithQueryParam, config);
        console.log('sdaghksdfgkasdhfgaj', response);
        yield put({
            type: FETCH_PRODUCTS_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: FETCH_PRODUCTS_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* addProductServiceWorker(action) {
    try {
        // const config = {
        //     headers: { 'Content-Type': 'application/json' },
        // };
        const data = action.payload;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, data);
        yield put({
            type: ADD_PRODUCT_SERVICES_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.PRODUCT.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ADD_PRODUCT_SERVICES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* addOrderableServiceWorker(action) {
    try {
        // const config = {
        //     headers: { 'Content-Type': 'application/json' },
        // };
        const data = action.payload;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, data);
        yield put({
            type: ADD_ORDERABLE_SERVICES_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback();
        }
        yield put({ type: SUCCESS_MESSAGE, message: messages.PRODUCT.CREATE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        yield put({ type: ERROR_MESSAGE, message: e.response && e.response.data && e.response.data.exception });
        yield put({ type: ADD_ORDERABLE_SERVICES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
