import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, initialize, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import axios from 'axios';

import DialogComponent from '../../../../components/DialogComponent/DialogComponent';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { required } from '../../../../constants/FormValidations';
import OutlinedTextField from '../../../../components/OutlinedTextField';
import RadioButtonGroup from '../../FormComponents/RadioButtonGroup/RadioButtonGroup';
import { hideSpinner, showSpinner } from '../../../../redux/modules/spinner/spinner';
import API from '../../../../constants/api';
import { isValidFunction, apiCatchBlockFunction } from '../../../../constants/CommonUtil';
import { getStringFromObject } from '../../../../constants/lodashUtils';

const ADJUST_QUEUE_FORM = 'adjustQueueTimeForm';
const FORM_FIELD_NAMES = {
    OPERATION: 'operation',
    MINUTES: 'mins',
};
const OPERATION = {
    EXTEND: 'extend',
    FORWARD: 'forward',
};

class AdjustQueueTimeForm extends Component {
    componentDidMount() {
        this.initializeForm({ [FORM_FIELD_NAMES.OPERATION]: OPERATION.EXTEND });
    }

    onSubmit = async (formValues) => {
        const {
            dispatch,
            queueUuid,
            onClose,
            fetchDoctorQueue,
        } = this.props;
        try {
            dispatch(showSpinner());
            const operation = getStringFromObject(FORM_FIELD_NAMES.OPERATION, formValues) || '';
            const mins = getStringFromObject(FORM_FIELD_NAMES.MINUTES, formValues) || '';
            await axios.get(`${API.QUEUES.ADJUST_QUEUE_TIMING}?queueUuid=${queueUuid}&operation=${operation}&mins=${mins}`);
            if (isValidFunction(onClose) && isValidFunction(fetchDoctorQueue)) {
                fetchDoctorQueue();
                onClose(null, true);
            }
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    }

    initializeForm = (formValues) => {
        const { dispatch } = this.props;
        dispatch(initialize(ADJUST_QUEUE_FORM, formValues));
    };

    render() {
        const { onClose, handleSubmit } = this.props;
        return (
            <DialogComponent
                open
                maxWidth="md"
                fullWidth
                header="Adjust Timing"
                allowOverflow
                handleClose={onClose}
                dialogActions={
                    <React.Fragment>
                        <Grid item style={{ marginRight: '10px' }}>
                            <ActionButton primary={false} onClick={onClose}>
                                Cancel
                            </ActionButton>
                        </Grid>
                        <Grid item>
                            <ActionButton onClick={handleSubmit(this.onSubmit)}>
                                Submit
                            </ActionButton>
                        </Grid>
                    </React.Fragment>
                }
            >
                <Grid container spacing={16}>
                    <Grid item sm={3} md={3} lg={3}>
                        <Field
                            testId={FORM_FIELD_NAMES.MINUTES}
                            name={FORM_FIELD_NAMES.MINUTES}
                            component={OutlinedTextField}
                            label="Minutes"
                            type="number"
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            validate={[required]}
                        />
                    </Grid>
                    <Grid item sm={5} md={5} lg={5}>
                        <Field
                            testId={FORM_FIELD_NAMES.OPERATION}
                            name={FORM_FIELD_NAMES.OPERATION}
                            component={RadioButtonGroup}
                            choices={[
                                { value: OPERATION.EXTEND, text: 'Extend' },
                                { value: OPERATION.FORWARD, text: 'Forward' },
                            ]}
                            validate={[required]}
                        />
                    </Grid>
                </Grid>
            </DialogComponent>
        );
    }
}

AdjustQueueTimeForm.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    fetchDoctorQueue: PropTypes.func.isRequired,
    queueUuid: PropTypes.string.isRequired,
};

AdjustQueueTimeForm.defaultProps = {
    dispatch: () => {},
    handleSubmit: () => {},
};

const mapStateToProps = (state) => {
    console.log('aasdsadsa-dsads', state);
    return {
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: ADJUST_QUEUE_FORM,
})(AdjustQueueTimeForm));
