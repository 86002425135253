import { takeLatest } from 'redux-saga/effects';
import {
    addPatientWorkerSaga, addRegistrationWorkerSaga,
    fetchPatientWorkerSaga,
    updatePatientWorkerSaga,
    updateRegistrationWorkerSaga,
} from './patientDetailsWorkerSaga';
import {
    ADD_PATIENT_REQUEST, ADD_REGISTRATION_REQUEST, FETCH_PATIENT_REQUEST,
    UPDATE_PATIENT_REQUEST, UPDATE_REGISTRATION_REQUEST,
} from '../../redux/modules/patientDetails/patientDetails-actions';

export function* addPatientWatcherSaga() {
    yield takeLatest(ADD_PATIENT_REQUEST, addPatientWorkerSaga);
}

export function* updatePatientWatcherSaga() {
    yield takeLatest(UPDATE_PATIENT_REQUEST, updatePatientWorkerSaga);
}

export function* updateRegistrationWatcherSaga() {
    yield takeLatest(UPDATE_REGISTRATION_REQUEST, updateRegistrationWorkerSaga);
}

export function* addRegistrationWatcherSaga() {
    yield takeLatest(ADD_REGISTRATION_REQUEST, addRegistrationWorkerSaga);
}

export function* fetchPatientWatcherSaga() {
    yield takeLatest(FETCH_PATIENT_REQUEST, fetchPatientWorkerSaga);
}

export default addPatientWatcherSaga;
