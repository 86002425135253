import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    accountTaxSettings,
    cashCloseTypesSettings,
    defaultJournalSettings,
    fiscalYearSettings,
    irSequenceSettings,
    periodicProcessingSettings,
    periodSettings,
} from '../constants/constants';

import BookSettingsContainer from '../containers/BookSettingsContainer/BookSettingsContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const PeriodPage = React.lazy(() => import('../pages/BookSettingPages/PeriodPage'));
const FiscalYearPage = React.lazy(() => import('../pages/BookSettingPages/FiscalYearPage')); const PeriodicProcessingPage = React.lazy(() => import('../pages/BookSettingPages/PeriodicProcessingPage'));
const CashCloseTypeSettingPage = React.lazy(() => import('../pages/BookSettingPages/CashCloseTypeSettingPage'));
const AccountTaxSettingsPage = React.lazy(() => import('../pages/BookSettingPages/AccountTaxSettingsPage'));
const DefaultJournalSettingPage = React.lazy(() => import('../pages/BookSettingPages/DefaultJournalSettingPage'));
const IRSequenceSettingPage = React.lazy(() => import('../pages/BookSettingPages/IRSequenceSettingPage'));

const BookSettingRoutes = props => (
    <BookSettingsContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={periodSettings} exact component={PeriodPage} />
            <Route path={fiscalYearSettings} exact component={FiscalYearPage} />
            <Route path={periodicProcessingSettings} exact component={PeriodicProcessingPage} />
            <Route path={cashCloseTypesSettings} exact component={CashCloseTypeSettingPage} />
            <Route path={accountTaxSettings} exact component={AccountTaxSettingsPage} />
            <Route path={defaultJournalSettings} exact component={DefaultJournalSettingPage} />
            <Route path={irSequenceSettings} exact component={IRSequenceSettingPage} />
        </Suspense>
    </BookSettingsContainer>
);

export default BookSettingRoutes;
