import API from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';

export const FETCH_EMP_BY_DEPT_REQUEST = '@@payroll/FETCH_EMP_BY_DEPT_REQUEST';
export const FETCH_EMP_BY_DEPT_SUCCESS = '@@payroll/FETCH_EMP_BY_DEPT_SUCCESS';
export const FETCH_EMP_BY_DEPT_FAILURE = '@@payrollFETCH_EMP_BY_DEPT_FAILURE';

export const SAVE_PAYSLIP_BATCH_REQUEST = '@@payroll/SAVE_PAYSLIP_BATCH_REQUEST';
export const SAVE_PAYSLIP_BATCH_FAILURE = '@@payroll/SAVE_PAYSLIP_BATCH_FAILURE';
export const SAVE_PAYSLIP_BATCH_SUCCESS = '@@payroll/SAVE_PAYSLIP_BATCH_SUCCESS';

export const FETCH_PAYSLIP_BATCH_DETAIL_REQUEST = '@@payroll/FETCH_PAYSLIP_BATCH_DETAIL_REQUEST';
export const FETCH_PAYSLIP_BATCH_DETAIL_FAILURE = '@@payroll/FETCH_PAYSLIP_BATCH_DETAIL_FAILURE';
export const FETCH_PAYSLIP_BATCH_DETAIL_SUCCESS = '@@payroll/FETCH_PAYSLIP_BATCH_DETAIL_SUCCESS';

export const FETCH_PAYSLIP_BATCHES_REQUEST = '@@payroll/FETCH_PAYSLIP_BATCHES_REQUEST';
export const FETCH_PAYSLIP_BATCHES_FAILURE = '@@payroll/FETCH_PAYSLIP_BATCHES_FAILURE';
export const FETCH_PAYSLIP_BATCHES_SUCCESS = '@@payroll/FETCH_PAYSLIP_BATCHES_SUCCESS';

export const FETCH_EMP_PAYSLIP_INPUT_REQUEST = '@@payroll/FETCH_EMP_PAYSLIP_INPUT_REQUEST';
export const FETCH_EMP_PAYSLIP_INPUT_SUCCESS = '@@payroll/FETCH_EMP_PAYSLIP_INPUT_SUCCESS';
export const FETCH_EMP_PAYSLIP_INPUT_FAILURE = '@@payroll/FETCH_EMP_PAYSLIP_INPUT_FAILURE';

export const CLEAR_EMP_PAYSLIP_INPUT = '@@payroll/CLEAR_EMP_PAYSLIP_INPUT';
export const CLEAR_BATCH_DETAILS = '@@payroll/CLEAR_BATCH_DETAILS';

export const SAVE_EMP_PAYSLIP_INPUT_REQUEST = '@@payroll/SAVE_EMP_PAYSLIP_INPUT_REQUEST';
export const SAVE_EMP_PAYSLIP_INPUT_SUCCESS = '@@payroll/SAVE_EMP_PAYSLIP_INPUT_SUCCESS';
export const SAVE_EMP_PAYSLIP_INPUT_FAILURE = '@@payroll/SAVE_EMP_PAYSLIP_INPUT_FAILURE';

export const GENERATE_PAYMENT_ADVICE_REQUEST = '@@payroll/GENERATE_PAYMENT_ADVICE_REQUEST';
export const GENERATE_PAYMENT_ADVICE_SUCCESS = '@@payroll/GENERATE_PAYMENT_ADVICE_SUCCESS';
export const GENERATE_PAYMENT_ADVICE_FAILURE = '@@payroll/GENERATE_PAYMENT_ADVICE_FAILURE';

export const VERIFY_PAYMENT_BATCH_REQUEST = '@@payroll/VERIFY_PAYMENT_BATCH_REQUEST';
export const VERIFY_PAYMENT_BATCH_SUCCESS = '@@payroll/VERIFY_PAYMENT_BATCH_SUCCESS';
export const VERIFY_PAYMENT_BATCH_FAILURE = '@@payroll/VERIFY_PAYMENT_BATCH_FAILURE';

export const FETCH_PAYMENT_ADVICE_LIST_REQUEST = '@@payroll/FETCH_PAYMENT_ADVICE_LIST_REQUEST';
export const FETCH_PAYMENT_ADVICE_LIST_SUCCESS = '@@payroll/FETCH_PAYMENT_ADVICE_LIST_SUCCESS';
export const FETCH_PAYMENT_ADVICE_LIST_FAILURE = '@@payroll/FETCH_PAYMENT_ADVICE_LIST_FAILURE';

export const FETCH_PAYMENT_ADVICE_REQUEST = '@@payroll/FETCH_PAYMENT_ADVICE_REQUEST';
export const FETCH_PAYMENT_ADVICE_FAILURE = '@@payroll/FETCH_PAYMENT_ADVICE_FAILURE';
export const FETCH_PAYMENT_ADVICE_SUCCESS = '@@payroll/FETCH_PAYMENT_ADVICE_SUCCESS';

export const SAVE_PAYMENT_ADVICE_REQUEST = '@@payroll/SAVE_PAYMENT_ADVICE_REQUEST';
export const SAVE_PAYMENT_ADVICE_SUCCESS = '@@payroll/SAVE_PAYMENT_ADVICE_SUCCESS';
export const SAVE_PAYMENT_ADVICE_FAILURE = '@@payroll/SAVE_PAYMENT_ADVICE_FAILURE';

export const FETCH_PAYROLL_PAYSLIP_REQUEST = '@@payroll/FETCH_PAYROLL_PAYSLIP_REQUEST';
export const FETCH_PAYROLL_PAYSLIP_SUCCESS = '@@payroll/FETCH_PAYROLL_PAYSLIP_SUCCESS';
export const FETCH_PAYROLL_PAYSLIP_FAILURE = '@@payroll/FETCH_PAYROLL_PAYSLIP_FAILURE';

export const CLEAR_SELECTED_PAYSLIP_STATE = '@@payroll/CLEAR_SELECTED_PAYSLIP_STATE';

export const FETCH_PAYSLIP_REQUEST = '@@payroll/FETCH_PAYSLIP_REQUEST';
export const FETCH_PAYSLIP_SUCCESS = '@@payroll/FETCH_PAYSLIP_SUCCESS';
export const FETCH_PAYSLIP_FAILURE = '@@payroll/FETCH_PAYSLIP_FAILURE';

export const FETCH_PAYROLL_REPORT_REQUEST = '@@payroll/FETCH_PAYROLL_REPORT_REQUEST';
export const FETCH_PAYROLL_REPORT_SUCCESS = '@@payroll/FETCH_PAYROLL_REPORT_SUCCESS';
export const FETCH_PAYROLL_REPORT_FAILURE = '@@payroll/FETCH_PAYROLL_REPORT_FAILURE';

export const SAVE_PAYSLIP_BATCH_DETAILS = '@@payroll/SAVE_PAYSLIP_BATCH_DETAILS';

export const SEARCH_EMP_BY_DEPT_REQUEST = '@@payroll/SEARCH_EMP_BY_DEPT_REQUEST';
export const SEARCH_EMP_BY_DEPT_FAILURE = '@@payroll/SEARCH_EMP_BY_DEPT_FAILURE';
export const SEARCH_EMP_BY_DEPT_SUCCESS = '@@payroll/SEARCH_EMP_BY_DEPT_SUCCESS';

export const FETCH_PAYROLL_PERIODS_REQUEST = '@@payroll/FETCH_PAYROLL_PERIODS_REQUEST';
export const FETCH_PAYROLL_PERIODS_FAILURE = '@@payroll/FETCH_PAYROLL_PERIODS_FAILURE';
export const FETCH_PAYROLL_PERIODS_SUCCESS = '@@payroll/FETCH_PAYROLL_PERIODS_SUCCESS';

export const FETCH_SALARY_COMPUTATION_REQUEST = '@@payroll/FETCH_SALARY_COMPUTATION_REQUEST';
export const FETCH_SALARY_COMPUTATION_FAILURE = '@@payroll/FETCH_SALARY_COMPUTATION_FAILURE';
export const FETCH_SALARY_COMPUTATION_SUCCESS = '@@payroll/FETCH_SALARY_COMPUTATION_SUCCESS';

export const CREATE_PAYMENT_ADVICE_REQUEST = '@@payroll/CREATE_PAYMENT_ADVICE_REQUEST';
export const CREATE_PAYMENT_ADVICE_FAILURE = '@@payroll/CREATE_PAYMENT_ADVICE_FAILURE';
export const CREATE_PAYMENT_ADVICE_SUCCESS = '@@payroll/CREATE_PAYMENT_ADVICE_SUCCESS';

export const CREATE_PAYMENT_ADVICE_ALL_REQUEST = '@@payroll/CREATE_PAYMENT_ADVICE_ALL_REQUEST';
export const CREATE_PAYMENT_ADVICE_ALL_FAILURE = '@@payroll/CREATE_PAYMENT_ADVICE_ALL_FAILURE';
export const CREATE_PAYMENT_ADVICE_ALL_SUCCESS = '@@payroll/CREATE_PAYMENT_ADVICE_ALL_SUCCESS';

export const REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_REQUEST = '@@payroll/REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_REQUEST';
export const REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_FAILURE = '@@payroll/REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_FAILURE';
export const REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_SUCCESS = '@@payroll/REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_SUCCESS';

export const DELETE_CONTRACT_REQUEST = '@@payroll/DELETE_CONTRACT_REQUEST';
export const DELETE_CONTRACT_FAILURE = '@@payroll/DELETE_CONTRACT_FAILURE';
export const DELETE_CONTRACT_SUCCESS = '@@payroll/DELETE_CONTRACT_SUCCESS';

export const createPaymentAdviceRequest = (uuids, successCallback) => ({
    type: CREATE_PAYMENT_ADVICE_REQUEST,
    api: `${API.PAYROLL.GENERATE_PAYMENT_ADVICE}${uuids}`,
    successAction: CREATE_PAYMENT_ADVICE_SUCCESS,
    successMessage: 'Successfully created payment advice.',
    successCallback,
    failureAction: CREATE_PAYMENT_ADVICE_FAILURE,
    failureMessage: 'Failed to create payment advice.',
});

export const deleteContractRequest = (uuid, successCallback, failureCallback) => ({
    type: DELETE_CONTRACT_REQUEST,
    api: `${API.PAYROLL.DELETE_CONTRACT}${uuid}`,
    successAction: DELETE_CONTRACT_SUCCESS,
    successMessage: 'Successfully deleted contract.',
    successCallback,
    failureAction: DELETE_CONTRACT_FAILURE,
    failureCallback,
    failureMessage: 'Failed to delete contract.',
});

export const createPaymentAdviceForAllRequest = (construct, successCallback) => ({
    type: CREATE_PAYMENT_ADVICE_ALL_REQUEST,
    api: API.PAYROLL.GENERATE_PAYMENT_ADVICE_ALL,
    payload: construct,
    successAction: CREATE_PAYMENT_ADVICE_ALL_SUCCESS,
    successMessage: 'Successfully created payment advice.',
    successCallback,
    failureAction: CREATE_PAYMENT_ADVICE_ALL_FAILURE,
    failureMessage: 'Failed to create payment advice.',
});

export const fetchPayslipByUuidRequest = (uuid, successCallback) => ({
    type: FETCH_PAYSLIP_REQUEST,
    api: `${API.PAYROLL.FETCH_PAYSLIP_DETAILS_BY_UUID}${uuid}`,
    successAction: FETCH_PAYSLIP_SUCCESS,
    successCallback,
    failureAction: FETCH_PAYSLIP_FAILURE,
    failureMessage: 'Failed to fetch payslip details.',
});

export const clearSelectedPayslipRequest = () => ({
    type: CLEAR_SELECTED_PAYSLIP_STATE,
});

export const fetchPayrollPeriodsRequest = () => ({
    type: FETCH_PAYROLL_PERIODS_REQUEST,
    api: API.PAYROLL.OPEN_PERIODS,
    successAction: FETCH_PAYROLL_PERIODS_SUCCESS,
    failureAction: FETCH_PAYROLL_PERIODS_FAILURE,
    hideSpinner: true,
});

export const fetchSalaryComputationRequest = (ignoreComputation = false, payload, successCallback) => ({
    type: FETCH_SALARY_COMPUTATION_REQUEST,
    api: getUrlWithApiParams(API.PAYROLL.FETCH_SALARY_COMPUTATION, { ignoreComputation }),
    payload,
    successAction: FETCH_SALARY_COMPUTATION_SUCCESS,
    failureAction: FETCH_SALARY_COMPUTATION_FAILURE,
    successCallback,
});

export const fetchEmpByDept = (deptId, periodUuid) => ({
    type: FETCH_EMP_BY_DEPT_REQUEST,
    api: `${API.PAYROLL.EMPLOYEES_BY_DEPT}?departmentId=${deptId}&periodUuid=${periodUuid}`,
    successAction: FETCH_EMP_BY_DEPT_SUCCESS,
    failureAction: FETCH_EMP_BY_DEPT_FAILURE,
});

export const clearEmpPayslipInputState = () => ({ type: CLEAR_EMP_PAYSLIP_INPUT });
export const clearBatchDetailsState = () => ({ type: CLEAR_BATCH_DETAILS });

export const savePayslipBatch = (payload, successCallback) => ({
    type: SAVE_PAYSLIP_BATCH_REQUEST,
    api: API.PAYROLL.SAVE_PAYSLIP_BATCH,
    payload,
    successAction: SAVE_PAYSLIP_BATCH_SUCCESS,
    successMessage: 'Batch is Successfully Saved',
    successCallback,
    failureAction: SAVE_PAYSLIP_BATCH_FAILURE,
});

export const fetchPayslipBatches = (page, size, sortCol, sortOrder, filters) => ({
    type: FETCH_PAYSLIP_BATCHES_REQUEST,
    api: API.PAYROLL.FETCH_PAYSLIP_BATCHES,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
});
//
export const fetchEmpPayslipInput = (uuid, periodUuid, successCallback) => ({
    type: FETCH_EMP_PAYSLIP_INPUT_REQUEST,
    api: `${API.PAYROLL.FETCH_EMPLOYEE_INPUT}?uuid=${uuid}&periodUuid=${periodUuid}`,
    successAction: FETCH_EMP_PAYSLIP_INPUT_SUCCESS,
    failureAction: FETCH_EMP_PAYSLIP_INPUT_FAILURE,
    successCallback,
});

export const fetchPayslipBatchDetail = uuid => ({
    type: FETCH_PAYSLIP_BATCH_DETAIL_REQUEST,
    api: `${API.PAYROLL.FETCH_PAYSLIP_BATCH_DETAILS}?uuid=${uuid}`,
    successAction: FETCH_PAYSLIP_BATCH_DETAIL_SUCCESS,
    failureAction: FETCH_PAYSLIP_BATCH_DETAIL_FAILURE,
});

export const savePayslipInput = (payload, successCallback, failureCallback) => ({
    type: SAVE_EMP_PAYSLIP_INPUT_REQUEST,
    payload,
    api: API.PAYROLL.SAVE_EMPLOYEE_INPUT,
    successCallback,
    failureCallback,
});

export const savePayslipBatchDetails = payload => ({
    type: SAVE_PAYSLIP_BATCH_DETAILS,
    payload,
});

export const generatePaymentAdvice = (payload, successCallback) => ({
    type: GENERATE_PAYMENT_ADVICE_REQUEST,
    api: API.PAYROLL.GENERATE_PAYMENT_SLIPS,
    payload,
    successAction: GENERATE_PAYMENT_ADVICE_SUCCESS,
    successMessage: 'Payment slips are successfully generated',
    successCallback,
    failureAction: GENERATE_PAYMENT_ADVICE_FAILURE,
});

export const verifyPaymentBatch = (payload, successCallback) => ({
    type: VERIFY_PAYMENT_BATCH_REQUEST,
    api: API.PAYROLL.VERIFY_PAYMENT_BATCH,
    payload,
    successAction: VERIFY_PAYMENT_BATCH_SUCCESS,
    failureAction: VERIFY_PAYMENT_BATCH_FAILURE,
    successCallback,
});

export const fetchPaymentAdviceList = (page, size, filters) => ({
    type: FETCH_PAYMENT_ADVICE_LIST_REQUEST,
    api: API.PAYROLL.FETCH_PAYMENT_ADVICE_LIST,
    page,
    size,
    filters,
});

export const fetchPaymentAdvice = uuid => ({
    type: FETCH_PAYMENT_ADVICE_REQUEST,
    api: API.PAYROLL.FETCH_PAYMENT_ADVICE,
    uuid,
});

export const removePayslipsFromPaymentAdviceRequest = (adviceUuid, payslipUuids, successCallback) => ({
    type: REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_REQUEST,
    api: `${API.PAYROLL.REMOVE_PAYSLIPS_FROM_PAYMENT_ADVICE}${adviceUuid}?payslipUuidList=${payslipUuids}`,
    successAction: REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_SUCCESS,
    successMessage: 'Successfully removed payslips',
    failureAction: REMOVE_PAYSLIP_FROM_PAYMENT_ADVICE_FAILURE,
    successCallback,
    failureMessage: 'Failed to remove payslips',
});

export const savePaymentAdvice = payload => ({
    type: SAVE_PAYMENT_ADVICE_REQUEST,
    api: API.PAYROLL.SAVE_PAYMENT_ADVICE,
    payload,
});

export const fetchPaymentPayslip = (page, size, filters) => ({
    type: FETCH_PAYROLL_PAYSLIP_REQUEST,
    api: API.PAYROLL.FETCH_PAYSLIPS,
    page,
    size,
    filters,
});

export const fetchPayrollReport = (startDate, page, size) => ({
    type: FETCH_PAYROLL_REPORT_REQUEST,
    api: API.PAYROLL.FETCH_PAYROLL_REPORTS,
    startDate,
    page,
    size,
});
