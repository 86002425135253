import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isArrayValidAndNotEmpty, isValidFunction } from '../../../constants/CommonUtil';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    progress: {
        margin: 0,
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

class TextFieldWithAsyncValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            validating: false,
        };
    }
    onChangeHandler = (value) => {
        const {
            meta: { dispatch, form }, onChangeHandlers, fieldMember, fields, actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) && dispatch && form && fieldMember &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, form, fieldMember, fields, dispatch);
                }
            });
        }
    };

    onBlur = (e) => {
        e.preventDefault();
        const textValue = e.target.value;
        this.setState({ validating: true }, () => {
            axios.get(`${this.props.validationApi}${textValue}`)
                .then((response) => {
                    console.log('asjdfk;asdhflkadslkf', response);
                    if (!response.data) {
                        this.setState({
                            errorText: this.props.errorMessage,
                        });
                    } else if (this.state.errorText) {
                        this.setState({
                            errorText: '',
                        });
                    }
                    this.setState({ validating: false });
                })
                .catch((error) => {
                    console.error('asjdfk;asdhflkadslkf', error);
                    this.setState({ validating: false });
                });
        });
        this.props.input.onBlur(e);
    };

    handleChange = (e) => {
        const { input } = this.props;
        input.onChange(e.target.value);
        this.onChangeHandler(e.target.value);
    };

    render() {
        const {
            errorText,
            validating,
        } = this.state;

        const {
            input: {
                value, onFocus,
            },
            meta: { touched, error, dirty },
            classes,
            ...otherProps
        } = this.props;
        console.log('123pomlda', validating);
        console.log('123pomldaasdasdas', this.props);
        const getError = () => {
            if (touched && error && dirty) {
                return error.props ? error.props.defaultMessage : '';
            }
            return errorText;
        };
        const showError = errorText || (touched && error);
        return (
            <React.Fragment>
                <FormControl error={showError} aria-describedby="component-error-text">
                    <TextField
                        {...otherProps}
                        value={value}
                        onChange={this.handleChange}
                        onFocus={onFocus}
                        onBlur={this.onBlur}
                        InputProps={{
                            classes: {
                                input: classes.input,
                            },
                        }}
                        endAdornment={
                            validating &&
                            <CircularProgress className={classes.progress} style={{ width: '20px', height: '20px' }} />
                        }
                    />
                    {
                        showError &&
                        <FormHelperText
                            id="component-error-text"
                        >
                            {getError()}
                        </FormHelperText>
                    }
                </FormControl>
            </React.Fragment>
        );
    }
}

TextFieldWithAsyncValidation.propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    errorMessage: PropTypes.string.isRequired,
    validationApi: PropTypes.string.isRequired,
    onChangeHandlers: PropTypes.array,
    fieldMember: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
};

TextFieldWithAsyncValidation.defaultProps = {
    onChangeHandlers: [],
    fieldMember: '',
    fields: {},
    actionHandlers: {},
};

export default withStyles(styles)(TextFieldWithAsyncValidation);

