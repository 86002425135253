import initialStates from '../../initialStates';
import {
    CREATE_JOURNAL_ENTRY_SUCCESS,
    FETCH_PREPAID_EXPENSE_SUCCESS,
    GENERATE_PERIODIC_LINE_SUCCESS,
    RESET_SELECTED_PREPAID_EXPENSE,
    SAVE_PREPAID_EXPENSE_SUCCESS,
} from './prepaidExpense-actions';

const prepaidExpenseReducer = (state = initialStates.prepaidExpense, action) => {
    let newState = {};

    switch (action.type) {
    case SAVE_PREPAID_EXPENSE_SUCCESS:
    case FETCH_PREPAID_EXPENSE_SUCCESS:
    case GENERATE_PERIODIC_LINE_SUCCESS:
    case CREATE_JOURNAL_ENTRY_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case RESET_SELECTED_PREPAID_EXPENSE:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default prepaidExpenseReducer;
