import { getFormDataFrom } from '../FormUtil';
import {
    isArrayValidAndNotEmpty, isStringNullOrUndefined,

} from '../../../constants/CommonUtil';
import {
    initialBillRowValuesDisabled,
    initialBillRowValues,
} from '../VisitDetailsForm/VisitDetailsFormUtil';
import {
    billingSectionFieldName, patientSignatureFieldName,
    patientUuidFieldName,
    payerTypeFieldName,
    registrationDocumentsFieldName,
} from '../QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const getUploadedRegistrationDocs = (patientDetails, uploadSchema) => {
    const uploadedData = {};
    if (isArrayValidAndNotEmpty(uploadSchema)) {
        const documentTypes = uploadSchema.map(anUploadedFile => anUploadedFile.name);
        const registrationDocuments = getStringFromObject('registrationDocuments', patientDetails) || [];
        if (isArrayValidAndNotEmpty(registrationDocuments)) {
            registrationDocuments.map((aRegistrationDocument) => {
                if (documentTypes.includes(aRegistrationDocument.registrationDocumentType)) {
                    if (!(isArrayValidAndNotEmpty(uploadedData[aRegistrationDocument.registrationDocumentType]))) {
                        uploadedData[aRegistrationDocument.registrationDocumentType] = [];
                    }
                    uploadedData[aRegistrationDocument.registrationDocumentType] =
                        uploadedData[aRegistrationDocument.registrationDocumentType].concat(aRegistrationDocument);
                }
                return null;
            });
        }
    }
    console.log('213123sadada ', uploadedData);
    return uploadedData;
};

export const billingInitialValues = (payerType, visitType, opConsultationVisitType) => {
    console.log('asd0ad-asuid0-asid-asi-dadasdasda', payerType, visitType, opConsultationVisitType);
    // if ([payerTypes.CASH, payerTypes.ASSOCIATION, payerTypes.INSURANCE].includes(payerType)) {
    if (NumberOf(visitType) !== NumberOf(opConsultationVisitType)) {
        return [initialBillRowValues];
    }
    return [initialBillRowValuesDisabled, initialBillRowValues];
    // }
    // if (NumberOf(visitType) !== NumberOf(opConsultationVisitType)) {
    //     return [initialBillRowValues];
    // }
    // return [initialBillRowValuesDisabled];
};

export const getFormDataAndUploadData = (props) => {
    const formData = getFormDataFrom(props.patientDetails, props.schema.formSections);
    formData.givenName = isStringNullOrUndefined(props.givenName) ? formData.givenName : props.givenName;
    formData.familyName = isStringNullOrUndefined(props.familyName) ? formData.familyName : props.familyName;
    formData.mobile = isStringNullOrUndefined(props.mobile) ? formData.mobile : props.mobile;
    formData.appointmentUuid = props.appointmentUuid;
    formData[patientUuidFieldName] = getStringFromObject('patientDetails.personUuid', props) || null;
    if (props.patientUuid) {
        formData[patientUuidFieldName] = props.patientUuid;
    }
    const patientSignature = getStringFromObject('patientDetails.visit.patientSignature', props);
    if (isObjectValidAndNotEmpty(patientSignature)) {
        formData[patientSignatureFieldName] = patientSignature;
    }
    const uploadData = getUploadedRegistrationDocs(props.patientDetails, props.schema.uploadsSchema);
    const billingInitialVals = billingInitialValues(formData[payerTypeFieldName]);
    return {
        ...formData,
        [registrationDocumentsFieldName]: uploadData,
        [billingSectionFieldName]: billingInitialVals,
    };
};

export const patientAddForm = 'patientAddForm';

export default getUploadedRegistrationDocs;
