import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../ActionButton/ActionButton';
import ApprovalButton from './ApprovalButton';
import ButtonWithReason from './ButtonWithReason';
// import shouldHide from '../MaterialTableV2/TabelRowUtil';

class ButtonGroup extends React.PureComponent {
    handleButtonAction = (e, action, member) => {
        e.stopPropagation();
        const { handleButtonAction } = this.props;
        console.log('jklasdhflkajsfhaklsjf', handleButtonAction, e, action, member);
        handleButtonAction(action, member);
    };

    render() {
        const {
            buttons,
            align,
            member,
            disabled,
            change,
            formValues,
        } = this.props;
        let flex = '';
        if (align === 'right') {
            flex = 'flex-end';
        } else if (align === 'left') {
            flex = 'flex-start';
        }
        console.log('asdfdsdassd', this.props);
        const buttonStyle = {
            fontSize: '0.8rem',
        };
        return (
            <div style={{ justifyContent: flex, display: 'flex' }}>
                {
                    buttons.map((button) => {
                        switch (button.fieldType) {
                        case 'approvalButton':
                            return (
                                <ApprovalButton
                                    key={`${button.key}`}
                                    testId={`${member}-${button.key || button.name}`}
                                    disabled={disabled}
                                    label={button.label}
                                    className="mr-1"
                                    change={change}
                                    formValues={formValues}
                                    style={{ ...buttonStyle, ...button.style }}
                                    onClick={e => this.handleButtonAction(e, button.action, member)}
                                />
                            );
                        case 'buttonWithReason':
                            return (
                                <ButtonWithReason
                                    key={`${button.key}`}
                                    testId={`${member}-${button.key || button.name}`}
                                    disabled={disabled}
                                    label={button.label}
                                    className="mr-1"
                                    change={change}
                                    formValues={formValues}
                                    style={{ ...buttonStyle, ...button.style }}
                                    onClick={e => this.handleButtonAction(e, button.action, member)}
                                    reasonField={button.reasonField}
                                    reasonRequired={button.reasonRequired}
                                />
                            );
                        default:
                            return (
                                <ActionButton
                                    key={`${button.key}`}
                                    testId={`${member}-${button.testId || button.key || button.name}`}
                                    disabled={disabled}
                                    className="mr-1"
                                    style={{ ...buttonStyle, ...button.style }}
                                    onClick={e => this.handleButtonAction(e, button.action, member)}
                                >
                                    {button.label}
                                </ActionButton>
                            );
                        }
                    })
                }
            </div>
        );
    }
}

ButtonGroup.propTypes = {
    buttons: PropTypes.array,
    formValues: PropTypes.object,
    member: PropTypes.string,
    align: PropTypes.string,
    handleButtonAction: PropTypes.func,
    change: PropTypes.func,
    disabled: PropTypes.bool,
};

ButtonGroup.defaultProps = {
    buttons: [],
    formValues: {},
    align: 'left',
    member: '',
    disabled: false,
    handleButtonAction: () => {},
    change: () => {},
};

export default ButtonGroup;
