import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    FETCH_ACTIVE_PATIENTS_FAILURE,
    FETCH_ACTIVE_PATIENTS_SUCCESS, FETCH_ALL_PATIENTS_FAILURE, FETCH_ALL_PATIENTS_SUCCESS,
    FETCH_RECENT_PATIENTS_FAILURE,
    FETCH_RECENT_PATIENTS_SUCCESS,
} from '../../redux/modules/patients/patients-action';
import { generateActivePatientConstruct, generateAllPatientSearchConstruct } from './PatientSearchConstruct';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,

} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { generateObjectWithCriteriaMode, getCombined } from '../util';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* recentListRequest(action) {
    yield put({ type: SHOW_SPINNER, state: { canShow: true } });
    try {
        const {
            page, size, searchText, sortDir, sortBy,
        } = action;
        const searchConstruct = generateActivePatientConstruct({ searchText });
        const apiWithQueryParam = `${action.api}?page=${page}&size=${size}&sortDir=${sortDir}&sortBy=${sortBy}`;
        const response = yield call(retriable().get, apiWithQueryParam, searchConstruct);
        yield put({
            type: FETCH_RECENT_PATIENTS_SUCCESS,
            data: response.data,
        });
    } catch (e) {
        // do error action
        let message = 'Network Error !!!';
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        if (e.response && e.response.data && e.response.data.exception) {
            message = e.response.data.exception;
        }
        yield put({ type: ERROR_MESSAGE, message });
        yield put({ type: FETCH_RECENT_PATIENTS_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* activeListRequest(action) {
    try {
        const {
            page, size, filters, sortDir, sortBy,
        } = action;
        const searchConstruct = generateActivePatientConstruct(filters);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParam = `${action.api}?page=${page}&size=${size}&sortDir=${sortDir}&sortBy=${sortBy}`;
        const response = yield call(retriable().post, apiWithQueryParam, searchConstruct);
        yield put({
            type: FETCH_ACTIVE_PATIENTS_SUCCESS,
            data: response.data,
            filters,
            sortCol: sortBy,
            sortDir,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        console.log('error in api call', e);
        yield put({ type: FETCH_ACTIVE_PATIENTS_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}

export function* allPatientListRequest(action) {
    try {
        const {
            page, size, filters, sortDir, sortBy, displayCol,
        } = action;
        const searchText = getStringFromObject('searchText', filters);
        const searchConstruct = generateAllPatientSearchConstruct(searchText);
        if (searchText) {
            const mobileCriteria = generateObjectWithCriteriaMode('personAttributes.mobile', searchText, ':');
            searchConstruct.searchConstruct = getCombined(searchConstruct.searchConstruct, 'OR', mobileCriteria);
        }
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const apiWithQueryParam = `${action.api}?page=${page}&size=${size}&sortDirection=${sortDir}&sortCol=${sortBy}`;
        const response = yield call(retriable().post, apiWithQueryParam, {
            displayColumns: displayCol,
            ...searchConstruct,
        });
        yield put({
            type: FETCH_ALL_PATIENTS_SUCCESS,
            data: response.data,
            filters,
            sortCol: sortBy,
            sortDir,
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        }
        console.log('error in api call', e);
        yield put({ type: FETCH_ALL_PATIENTS_FAILURE });
    }
    yield put({ type: HIDE_SPINNER, state: { canShow: false } });
}
