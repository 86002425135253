import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid/Grid';

import { isArrayValidAndNotEmpty } from '../constants/CommonUtil';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';

const styles = () => ({
    icon: {
        height: '2rem',
        width: '2rem',
    },
    appDiv: {
        cursor: 'pointer',
    },
});

class ApplicaitonShortcutPopover extends React.PureComponent {
    render() {
        const { classes, handleShortcutApp, appShortcuts } = this.props;
        console.log('dddddddddd', classes);
        return (
            <React.Fragment>
                <Grid container style={{ width: '400px', padding: '1em 0' }}>
                    {
                        isArrayValidAndNotEmpty(appShortcuts) &&
                        appShortcuts.map((app) => {
                            if (checkIfPrivilegeExistsForUser(app.privileges)) {
                                return (
                                    <Grid
                                        item
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        key={app.title}
                                        className="p1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleShortcutApp(app.url, app.title)}
                                    >
                                        <Grid container justify="center">
                                            <img
                                                src={`${import.meta.env.BASE_URL}/applicaitonShorcutIcons/${app.svgUrl}`}
                                                alt=""
                                                className={classes.icon}
                                            />
                                        </Grid>
                                        <Grid container justify="center" className="mt-1">
                                            {app.title}
                                        </Grid>
                                    </Grid>
                                );
                            }
                            return '';
                        })
                    }
                </Grid>
            </React.Fragment>
        );
    }
}

ApplicaitonShortcutPopover.propTypes = {
    classes: PropTypes.object.isRequired,
    appShortcuts: PropTypes.array.isRequired,
    handleShortcutApp: PropTypes.func.isRequired,
};

ApplicaitonShortcutPopover.defaultProps = {
};

export default withStyles(styles)(ApplicaitonShortcutPopover);
