/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_SELECTED_STOCK_MOVE_SUCCESS,
    CLEAR_STOCK_MOVE_BY_PURCHASE_ORDER_SUCCESS,
    CREATE_STOCK_MOVE_SUCCESS,
    FETCH_STOCK_MOVE_BY_ID_SUCCESS,
    FETCH_STOCK_MOVE_SUCCESS,
} from './stockMove-actions';

import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const stockMoveReducer = (state = initialStates.stockMove, action) => {
    let newState = {};
    let actionData = {};
    if (isObjectValidAndNotEmpty(action)) {
        actionData = action.data;
    }
    switch (action.type) {
    case FETCH_STOCK_MOVE_SUCCESS:
        if (!Array.isArray(action.data)) {
            actionData = [];
            actionData.push(action.data);
        }
        console.log('asd-as0ida-sudias0sd', action.data, actionData);
        newState = Object.assign({}, state, { stockMovesByPurchaseOrder: actionData });
        break;
    case FETCH_STOCK_MOVE_BY_ID_SUCCESS:
    case CREATE_STOCK_MOVE_SUCCESS:
        newState = { ...state, selected: action.data };
        break;
    case CLEAR_STOCK_MOVE_BY_PURCHASE_ORDER_SUCCESS:
        newState = Object.assign({}, state, { stockMovesByPurchaseOrder: [] });
        break;
    case CLEAR_SELECTED_STOCK_MOVE_SUCCESS:
        newState = { ...state, selected: null };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default stockMoveReducer;
