import { takeLatest } from 'redux-saga/effects';
import { FETCH_PRICE_LIST_REQUEST, SAVE_PRICE_LIST_REQUEST } from '../../redux/modules/priceList/priceList-actions';
import { fetchPriceListWorker, savePriceListWorker } from './priceListWorkerSaga';

export function* savePriceListWatcher() {
    yield takeLatest(SAVE_PRICE_LIST_REQUEST, savePriceListWorker);
}

export function* fetchPriceListWatcher() {
    yield takeLatest(FETCH_PRICE_LIST_REQUEST, fetchPriceListWorker);
}
