import { all } from 'redux-saga/effects';

import {
    loginSaga, loginSilentSaga, validateSessionSaga,
    clearCacheAndLogoutSaga,
} from './loginSaga/loginWatcherSaga';
import { doctorQListSaga, incompleteEncounterSaga, openEncounterSaga } from './doctorQueueListSaga/doctorQWatcherSaga';
import { workflowFetchRequestSaga, workflowSaveRequestSaga } from './workflow/workflowWatcherSaga';
import inboxFetchSaga from './inbox/inboxWatcherSaga';
import {
    fetchCalendarWorkHoursSaga,
    profileFetchOtherUserSaga,
    profileFetchSaga,
    profileUpdateSaga,
} from './profileSaga/profileWatcherSaga';
import {
    assetAddSaga,
    assetDeleteSaga,
    assetFetchSaga,
    assetRetireRequestSaga,
    assetUnRetireRequestSaga,
    assetUpdateLocationSaga,
    assetUpdateStateSaga,
    assetViewSaga,
    computeDepreciationBoardSaga,
    forceDepreciationSaga,
} from './assets/assetWatcherSaga';

import { assetCategoryAddWatcher, assetCategoryListFetchWatcher } from './assetCategorySaga/assetCategoryWatcherSaga';
import {
    assetManufacturerAddWatcher,
    assetManufacturerListFetchWatcher,
    assetManufacturerUpdateWatcher,
} from './assetManufacturerSaga/assetManufacturerWatcherSaga';
import {
    announcementAddSaga,
    announcementEditSaga,
    announcementFetchSaga,
} from './announcements/announcementWatcherSaga';
import {
    fetchHolidayForYearSaga,
    fetchUpcomingHolidaySaga,
    holidaysFetchSaga,
    holidaysSaveSaga,
} from './holidays/holidayWatcherSaga';
import { activeListFetchSaga, allPatientsListFetchSaga, recentListFetchSaga } from './patients/patientsWatcherSaga';
import {
    availedLeaveFetchSaga,
    fetchLeavePlansForEmployeeSaga,
    leaveBalanceFetchSaga,
} from './leaves/leavesWatcherSaga';
import {
    fetchAttendanceListSaga,
    fetchByEmployeeAndPeriodWatcherSaga,
    fetchPeriodSaga,
    fetchPeriodsSaga,
    saveDutySaga,
    updateAttendanceSaga,
    updateEmployeeAttendanceSaga,
    revertApprovedAttendanceSaga,
} from './attendance/attendanceWatcherSaga';
import fetchOrganizationListSaga from './organization/organizationWatcherSaga';
import createResPartnerWatcher from './resPartnerSaga/resPartnerWatcherSaga';
import {
    createPaymentAdviceAllSaga,
    createPaymentAdviceSaga,
    deleteContractSaga,
    fetchEmpByDeptSaga,
    fetchEmpPayslipInputSaga,
    fetchOpenPayrollPeriodsSaga,
    fetchPaymentAdviceListSaga,
    fetchPaymentAdviceSaga,
    fetchPaymentPayslipSaga,
    fetchPayrollReportSaga,
    fetchPayslipBatchDetailSaga,
    fetchPayslipBatchesSaga,
    fetchPayslipSaga,
    fetchSalaryComputationSaga,
    generatePaymentAdviceSaga,
    removePayslipsFromAdviceSaga,
    savePaymentAdviceSaga,
    savePayslipBatchSaga,
    savePayslipInputSaga,
    searchEmpByDeptSaga,
    verifyPaymentBatchSaga,
} from './payroll/payrollWatcherSaga';
import { createPurchaseOrderWatcher, fetchPurchaseOrderWatcher } from './purchaseOrderSaga/purchaseOrderWatcherSaga';
import {
    fetchStockMovesByIdWatcher,
    fetchStockMovesByPurchaseOrderWatcher,
    receiveStockWatcher,
} from './stockMoveSaga/stockMoveWatcherSaga';
import {
    createStockIntentWatcher,
    fetchStockIntentRequestWatcher,
    markIntentAsComplete,
} from './stockIntentSaga/stockIntentWatcherSaga';
import {
    createMaterialReceiveWatcher,
    fetchMaterialReceiveRequestWatcher,
    markMaterialReceiveAsComplete,
    cancelOrRevertMaterialReceiveWatcher,
} from './materialReceiveSaga/materialReceiveWatcherSaga';
import {
    fetchVitalsQueueWatcher,
    fetchVitalsQueueOptimizedWatcher,
    saveVitalsWatcher,
} from './vitalsQueue/vitalsQueueWatcherSaga';
import advanceSearchWatcher from './advanceSearch/advanceSearchWatcherSaga';
import fetchCalendarDetailSaga from './dashboard/dashboardWatcherSaga';
import { fetchOrdersQueueWatcher, saveOrdersWatcher } from './ordersQueue/ordersQueueWatcherSaga';
import { createBatchWatcher, fetchPagesOfBatchesWatcher } from './batchesSaga/batchesWatcherSaga';
import {
    cancelAccountInvoiceWatcher,
    createAccountInvoiceWatcher,
    fetchAccountInvoiceWatcher,
} from './accountInvoiceSaga/accountInvoiceWatcherSaga';
import { fetchAccountWatcherRequestWatcher, registerPaymentRequestWatcher } from './paymentSaga/paymentWatcherSaga';
import { fetchAppConfigWatcherSaga } from './appConfigurationSaga/appConfigurationWatcherSaga';
import {
    addPatientWatcherSaga,
    addRegistrationWatcherSaga,
    fetchPatientWatcherSaga,
    updatePatientWatcherSaga,
    updateRegistrationWatcherSaga,
} from './patientDetailsSaga/patientDetailsWatcherSaga';
import {
    createDepartmentWatcher,
    createServicesWatcher,
    deleteProviderFromServiceWatcher,
    fetchDepartmentsWatcher,
    fetchServicesWatcher,
    updateDepartmentWatcher,
    updateServicesWatcher,
} from './departmentSaga/departmentWatcherSaga';
import { fetchRegistrationListWatcher } from './registrationList/registrationListWatcherSaga';
import { fetchPayrollDetailsSaga, fetchPayslipDetailsSaga } from './employeePayroll/employeePayrollWatcherSaga';
import {
    createDoctorSlotWatcher,
    deleteDailyScheduleWatcherSaga,
    fetchAppointmentTypesWatcher,
    fetchProviderWeekWatcher,
    fetchServiceWeekWatcher,
    runDoctorSlotSchedulerWatcherSaga,
    updateDailyScheduleWatcherSaga,
    updateDoctorSlotWatcher,
    updateSlotsWatcherSaga,
} from './appointmentSlot/appointmentSlotWatcherSaga';
import {
    addVisitWatcherSaga,
    fetchVisitWatcherSaga,
    updateVisitWatcherSaga,
} from './visitDetailsSaga/visitDetailsWatcherSaga';
import {
    cancelAppointmentWatcher,
    confirmAppointmentWatcher,
    createAppointmentWatcher,
    getAppointmentSlotsWatcher,
} from './appointmentSaga/appointmentWatcherSaga';
import fetchWorkflowConfigSaga from './workflowConfig/workflowWatcherSaga';
import {
    createRecallWatcher,
    deleteRecallWatcher,
    fetchAppointmentsListWatcher,
    fetchRecallListWatcher,
} from './appointmentListSaga/appointmentListWatcherSaga';
import getAppointmentDetailsWatcher from './appointmentDetailsSaga/appointmentDetailsWatcherSaga';
import { createSupplierWatcher, fetchPagesOfSupplierWatcher } from './supplierSaga/supplierWatcherSaga';
import { createProductWatcher, fetchPagesOfProductsWatcher } from './productsSaga/productWatcherSaga';
import {
    createProductCategoryWatcher,
    fetchPagesOfProductCategoryWatcher,
} from './productCategorySaga/productCategoryWatcherSaga';
import {
    createTemplateWatcher,
    deleteTemplateWatcher,
    fetchSlotTemplateListWatcher,
    fetchSlotTemplateWatcher,
    updateTemplateWatcher,
} from './appointmentSlotTemplate/appointmentSlotTemplateWatcherSaga';
import createAddStockWatcher from './addStockSaga/addStockWatcherSaga';
import { createInternalMovesWatcher, fetchIntentWatcher, reverseTransferWatcher } from './internalMovesSaga/internalMovesWatcherSaga';
import { createConsumableWatcher, fetchPagesOfConsumableWatcher } from './consumablesSaga/consumableWatcherSaga';
import {
    createPayorCategoryWatcher,
    createPayorWatcher,
    fetchCategoryListRequest,
    fetchPagesOfPayorsWatcher,
    fetchPagesOfResPartnerPayorsWatcher,
    fetchPayorDetails,
    fetchPolicyListRequest,
} from './payorsSaga/payorsWatcherSaga';
import {
    createAccountTransferWatcher,
    fetchAccountTransferWatcher,
} from './accountTransferSaga/accountTransferWatcher';
import {
    createAccountExpenseWatcher,
    fetchAccountExpenseWatcher,
} from './accountExpenseSaga/accountExpenseWatcherSaga';
import {
    createReceivePaymentWatcher,
    fetchReceivePaymentWatcher,
} from './receivePaymentSaga/receivePaymentWatcherSaga';
import fetchListOfTransactionWatcher from './transactionSaga/transactionWatcherSaga';
import {
    adjustBalanceWatcher,
    createAccountWatcher,
    fetchPagesOfAccountsWatcher,
} from './accounts/accountsWatcherSaga';
import { fetchAgingReport, fetchInvoicesForPayor } from './agingReportSaga/agingReportWatcherSaga';
import { fetchPriceListWatcher, savePriceListWatcher } from './priceListSaga/priceListWatcherSaga';
import getQueueListSaga from './approvalQueueSaga/approvalQueueWatcherSaga';
import {
    getInsuranceInvoiceDetailsSaga,
    getInsuranceInvoiceLineSaga,
    getInsuranceInvoiceListSaga,
    updateInsuranceInvoiceLineSaga,
} from './invoiceSaga/insuranceInvoiceListWatcherSaga';
import {
    getMrsOrderDetailsSaga,
    getOrderDetailsSaga,
    operateOrderSaga,
    orderSendToBillingSaga,
} from './orderSaga/orderWatcherSaga';
import {
    downloadClaimsBundleWatcher,
    downloadClaimsWatcher,
    fetchBundlePayersWatcher,
    fetchClaimsListWatcher,
    getDownloadTaskStatusRequest,
    saveClaimsBundleWatcher,
} from './claimsSaga/claimsWatcherSaga';
import {
    createJournalEntryWatcher,
    fetchJournalEntriesWatcher,
    postJournalEntryWatcher,
    fetchJournalEntriesOptimizedWatcher,
} from './journalEntrySaga/journalEntryWatcherSaga';
import {
    createAnalyticalAccountWatcher,
    fetchPagesOfAnalyticalAccountsWatcher,
} from './analyticalAccountsSaga/analyticalAccountsWatcherSaga';
import createAdvancedSalary from './advanceSalarySaga/advanceSalaryWatcher';
import createPettyCash from './pettyCashSaga/pettyCashWatcher';
import {
    createAccountExpenseWatcher as employeeCreateExpenseWatcher,
    fetchAccountExpenseWatcher as employeeFetchExpenseWatcher,
} from './employeeExpenseSaga/employeeExpenseWatcherSaga';
import {
    createPeriodicExpenseJournalEntrySaga,
    createPrepaidExpenseSaga,
    fetchPeriodicExpenseSaga,
    generatePeriodicLineSaga,
} from './prepaidExpense/prepaidExpenseWatcherSaga';
import {
    createAdvancePaymentWatcher,
    fetchAdvancePaymentWatcher,
} from './advancePaymentSaga/advancePaymentWatcherSaga';
import {
    addOrderableServiceWatcher,
    addProductServiceWatcher,
    productFetchWatcher,
} from './servicePageSaga/productsWatcherSaga';
import {
    createCashCloseWatcher,
    fetchCashCloseWatcher,
    updateCashClosePrintStatusWatcher,
} from './cashCloseSaga/cashCloseWatcherSaga';
import {
    createConsolidatedCashCloseWatcher,
    fetchConsolidatedCashCloseWatcher,
    updateConsolidatedCashClosePrintStatusWatcher,
} from './consolidatedCashCloseSaga/consolidatedCashCloseWatcherSaga';
import { addOrUpdatePackageWatcher, packageFetchWatcher } from './servicePackagesSaga/servicePackageWatcherSaga';
import { addOrUpdateTestWatcher, fetchPagesOfTestsWatcher } from './labSettingsTestsSaga/labTestsWatcherSaga';
import { addOrUpdatePanelWatcher, fetchPagesOfPanelsWatcher } from './labSettingsPanelsSaga/labPanelWatcherSaga';
import {
    addOrUpdateDepartmentWatcher,
    fetchPagesOfDepartmentWatcher,
} from './labSettingsDepartmentSaga/labDepartmentWatcherSaga';
import { addOrUpdateSampleWatcher, fetchPagesOfSampleWatcher } from './labSettingsSampleSaga/labSampleWatcherSaga';
import {
    addOrUpdateDictionaryWatcher,
    fetchPagesOfDictionaryWatcher,
} from './labSettingsDictionarySaga/labDictionaryWatcherSaga';
import { addOrUpdateUomWatcher, fetchPagesOfUomWatcher } from './labSettingsUomSaga/labUomWatcherSaga';
import addOrUpdateTemplateWatcher from './labSettingsTemplateSaga/labTemplateWatcherSaga';
import {
    fetchInsuranceRequestLinesWatcher,
    fetchInsuranceRequestsWatcher,
} from './insuranceRequestSaga/insuranceRequestWatcherSaga';
import policyViewWatcher from './policyViewSaga/policyViewWatcherSaga';
import fetchGenericMastersPagesWatcher, { constructFetchGenericMastersPagesWatcher, genericAddOrUpdateyWatcher } from './genericMastersSaga/genericMastersWatcherSaga';
import insuranceSchemeViewWatcher from './insuranceSchemeViewSaga/insuranceSchemeViewWatcherSaga';
import {
    addFavouriteRequestWatcher,
    fetchFavouriteReportsWatcher,
    removeFavouriteRequestWatcher,
} from './favouriteReportsSaga/favouriteReportsWatcherSaga';
import { createBedTypeWatcher, fetchPagesOfBedTypeWatcher } from './bedTypeSaga/bedTypeWatcherSaga';
import { commonGetWatcherSaga, commonPostWatcherSaga, commonDeleteWatcherSaga } from './commonSaga/commonWatcherSaga';
import { fetchFormularyListWatcher, saveFormularyListWatcher } from './formularyListSaga/formularyListWatcherSaga';
import previousPurchaseInvoiceLinesWatcher from './previousPurchaseInvoiceLinesSaga/previousPurchaseInvoiceLinesWatcher';
import { fetchRoomDepartmentMappingWatcher, saveRoomDepartmentMappingWatcher } from './roomDepartmentMappingSaga/roomDepartmentMappingWatcherSaga';
import {
    fetchDebitAndCreditLinesWatcher,
    createOrUpdateCashPaymentVoucherWatcher,
    fetchCashPaymentVoucherWatcher,
} from './cashPaymentVoucherSaga/cashPaymentVoucherWatcher';
import fetchAccountVouchersWatcher from './accountVoucherSaga/accountVoucherWatcherSaga';
import fetchAccountInvoiceTableWatcher from './accountInvoiceTableSaga/accountInvoiceTableWatcherSaga';
import encounterOrdersWatcherSaga from './encounterOrdersSaga/encounterOrdersWatcherSaga';
import { fetchProductDispatchWatcher } from './productDispatchSaga/productDispatchWatcherSaga';
import { favoriteForDoctorWatcherSaga } from './favoritesForDoctorSaga/favoritesForDoctorWatcherSaga';
import medicalPackageFetchWatcherSaga from './medicalPackageSaga/medicalPackageWatcherSaga';
import fetchBilledPackagesWatcher from './billedMedicalPackageSaga/billedMedicalPackageWatcherSaga';
import fetchMyCashCloseWatcher from './myCashCloseSaga/myCashCloseWatcherSaga';

export default function* rootSaga() {
    yield all([
        loginSaga(),
        loginSilentSaga(),
        validateSessionSaga(),
        clearCacheAndLogoutSaga(),
        doctorQListSaga(),
        openEncounterSaga(),
        incompleteEncounterSaga(),
        workflowSaveRequestSaga(),
        workflowFetchRequestSaga(),
        inboxFetchSaga(),
        profileFetchSaga(),
        profileUpdateSaga(),
        assetFetchSaga(),
        assetAddSaga(),
        announcementFetchSaga(),
        announcementAddSaga(),
        assetViewSaga(),
        announcementEditSaga(),
        holidaysFetchSaga(),
        holidaysSaveSaga(),
        fetchHolidayForYearSaga(),
        recentListFetchSaga(),
        activeListFetchSaga(),
        assetDeleteSaga(),
        availedLeaveFetchSaga(),
        leaveBalanceFetchSaga(),
        fetchUpcomingHolidaySaga(),
        saveDutySaga(),
        fetchAttendanceListSaga(),
        assetManufacturerAddWatcher(),
        assetManufacturerUpdateWatcher(),
        fetchPeriodsSaga(),
        updateAttendanceSaga(),
        updateEmployeeAttendanceSaga(),
        revertApprovedAttendanceSaga(),
        fetchPeriodSaga(),
        fetchLeavePlansForEmployeeSaga(),
        fetchOrganizationListSaga(),
        fetchEmpByDeptSaga(),
        fetchOpenPayrollPeriodsSaga(),
        assetManufacturerListFetchWatcher(),
        fetchSalaryComputationSaga(),
        createPaymentAdviceSaga(),
        deleteContractSaga(),
        fetchEmpPayslipInputSaga(),
        profileFetchOtherUserSaga(),
        createPaymentAdviceAllSaga(),
        fetchPayslipSaga(),
        // res partner watcher
        createResPartnerWatcher(),
        savePayslipBatchSaga(),
        fetchPayslipBatchesSaga(),
        fetchPayslipBatchDetailSaga(),
        savePayslipInputSaga(),
        generatePaymentAdviceSaga(),
        verifyPaymentBatchSaga(),
        createPurchaseOrderWatcher(),
        fetchPurchaseOrderWatcher(),
        fetchPaymentAdviceListSaga(),
        fetchPaymentAdviceSaga(),
        removePayslipsFromAdviceSaga(),
        savePaymentAdviceSaga(),
        fetchPaymentPayslipSaga(),
        receiveStockWatcher(),
        fetchStockMovesByPurchaseOrderWatcher(),
        fetchStockMovesByIdWatcher(),
        createStockIntentWatcher(),
        fetchStockIntentRequestWatcher(),
        markIntentAsComplete(),
        createMaterialReceiveWatcher(),
        fetchMaterialReceiveRequestWatcher(),
        markMaterialReceiveAsComplete(),
        cancelOrRevertMaterialReceiveWatcher(),
        fetchPayrollReportSaga(),
        saveVitalsWatcher(),
        fetchVitalsQueueWatcher(),
        fetchVitalsQueueOptimizedWatcher(),
        advanceSearchWatcher(),
        fetchCalendarDetailSaga(),
        fetchOrdersQueueWatcher(),
        saveOrdersWatcher(),
        fetchPagesOfBatchesWatcher(),
        createBatchWatcher(),
        allPatientsListFetchSaga(),
        createAccountInvoiceWatcher(),
        fetchAccountInvoiceWatcher(),
        cancelAccountInvoiceWatcher(),
        registerPaymentRequestWatcher(),
        fetchAppConfigWatcherSaga(),
        addPatientWatcherSaga(),
        updatePatientWatcherSaga(),
        fetchPatientWatcherSaga(),
        addVisitWatcherSaga(),
        updateVisitWatcherSaga(),
        fetchVisitWatcherSaga(),
        // Appointment Related Saga
        fetchDepartmentsWatcher(),
        updateDepartmentWatcher(),
        createDepartmentWatcher(),
        fetchServicesWatcher(),
        createServicesWatcher(),
        updateServicesWatcher(),
        deleteProviderFromServiceWatcher(),
        fetchServiceWeekWatcher(),
        fetchAppointmentTypesWatcher(),
        createDoctorSlotWatcher(),
        fetchProviderWeekWatcher(),
        updateDoctorSlotWatcher(),
        getAppointmentSlotsWatcher(),
        createAppointmentWatcher(),
        getAppointmentDetailsWatcher(),
        fetchAppointmentsListWatcher(),
        fetchRecallListWatcher(),
        createRecallWatcher(),
        deleteRecallWatcher(),
        cancelAppointmentWatcher(),
        confirmAppointmentWatcher(),
        createTemplateWatcher(),
        fetchSlotTemplateListWatcher(),
        updateTemplateWatcher(),
        deleteTemplateWatcher(),
        fetchSlotTemplateWatcher(),
        runDoctorSlotSchedulerWatcherSaga(),
        updateDailyScheduleWatcherSaga(),
        deleteDailyScheduleWatcherSaga(),
        updateSlotsWatcherSaga(),
        // ------------------------
        fetchRegistrationListWatcher(),
        fetchPayrollDetailsSaga(),
        fetchPayslipDetailsSaga(),
        fetchCalendarWorkHoursSaga(),
        searchEmpByDeptSaga(),
        fetchWorkflowConfigSaga(),
        fetchPagesOfSupplierWatcher(),
        fetchPagesOfProductsWatcher(),
        fetchPagesOfProductCategoryWatcher(),
        createBedTypeWatcher(),
        fetchPagesOfBedTypeWatcher(),
        createAddStockWatcher(),
        createInternalMovesWatcher(),
        fetchIntentWatcher(),
        reverseTransferWatcher(),
        createProductWatcher(),
        fetchPagesOfConsumableWatcher(),
        createConsumableWatcher(),
        fetchPagesOfResPartnerPayorsWatcher(),
        createSupplierWatcher(),
        createPayorWatcher(),
        createAccountTransferWatcher(),
        fetchAccountTransferWatcher(),
        createAccountExpenseWatcher(),
        fetchAccountExpenseWatcher(),
        fetchReceivePaymentWatcher(),
        createReceivePaymentWatcher(),
        createProductCategoryWatcher(),
        fetchListOfTransactionWatcher(),
        createAccountWatcher(),
        adjustBalanceWatcher(),
        fetchPagesOfAccountsWatcher(),
        fetchAgingReport(),
        fetchInvoicesForPayor(),
        fetchAccountWatcherRequestWatcher(),
        fetchPagesOfPayorsWatcher(),
        fetchPayorDetails(),
        createPayorCategoryWatcher(),
        savePriceListWatcher(),
        fetchPriceListWatcher(),
        getQueueListSaga(),
        getInsuranceInvoiceListSaga(),
        getInsuranceInvoiceLineSaga(),
        getInsuranceInvoiceDetailsSaga(),
        updateInsuranceInvoiceLineSaga(),
        getOrderDetailsSaga(),
        getMrsOrderDetailsSaga(),
        operateOrderSaga(),
        orderSendToBillingSaga(),
        fetchCategoryListRequest(),
        fetchPolicyListRequest(),
        fetchClaimsListWatcher(),
        saveClaimsBundleWatcher(),
        downloadClaimsBundleWatcher(),
        downloadClaimsWatcher(),
        fetchBundlePayersWatcher(),
        getDownloadTaskStatusRequest(),
        // registration v2
        updateRegistrationWatcherSaga(),
        addRegistrationWatcherSaga(),
        computeDepreciationBoardSaga(),
        forceDepreciationSaga(),
        assetRetireRequestSaga(),
        assetUnRetireRequestSaga(),
        assetUpdateLocationSaga(),
        assetUpdateStateSaga(),
        fetchJournalEntriesWatcher(),
        createJournalEntryWatcher(),
        postJournalEntryWatcher(),
        fetchJournalEntriesOptimizedWatcher(),
        fetchPagesOfAnalyticalAccountsWatcher(),
        createAnalyticalAccountWatcher(),
        // advanced salary
        createAdvancedSalary(),
        // petty Cash
        createPettyCash(),
        // employee expense recouping
        employeeCreateExpenseWatcher(),
        employeeFetchExpenseWatcher(),
        /* ----- prepaid expense ------ */
        createPrepaidExpenseSaga(),
        generatePeriodicLineSaga(),
        fetchPeriodicExpenseSaga(),
        createPeriodicExpenseJournalEntrySaga(),
        /* ----------------------------- */
        createAdvancePaymentWatcher(),
        assetCategoryAddWatcher(),
        fetchAdvancePaymentWatcher(),
        assetCategoryListFetchWatcher(),
        productFetchWatcher(),
        addOrderableServiceWatcher(),
        addProductServiceWatcher(),
        packageFetchWatcher(),
        /* ----- cash close ------ */
        createCashCloseWatcher(),
        fetchCashCloseWatcher(),
        fetchMyCashCloseWatcher(),
        updateCashClosePrintStatusWatcher(),
        /* ----- consolidated cash close ------ */
        createConsolidatedCashCloseWatcher(),
        fetchConsolidatedCashCloseWatcher(),
        updateConsolidatedCashClosePrintStatusWatcher(),
        /* ----------------------------- */
        addOrUpdatePackageWatcher(),
        /* ------LabTests------------------ */
        fetchPagesOfTestsWatcher(),
        addOrUpdateTestWatcher(),
        /* -------LabPanels---------------- */
        fetchPagesOfPanelsWatcher(),
        addOrUpdatePanelWatcher(),
        /* -------LabDepartments---------------- */
        fetchPagesOfDepartmentWatcher(),
        addOrUpdateDepartmentWatcher(),
        /* -------LabSample---------------- */
        fetchPagesOfSampleWatcher(),
        addOrUpdateSampleWatcher(),
        /* -------LabDictionary---------------- */
        fetchPagesOfDictionaryWatcher(),
        addOrUpdateDictionaryWatcher(),
        /* -------LabUom---------------- */
        fetchPagesOfUomWatcher(),
        addOrUpdateUomWatcher(),
        /* -------LabTemplate----------------------*/
        addOrUpdateTemplateWatcher(),

        /* policyView */
        policyViewWatcher(),
        /* insuranceSchemeView */
        insuranceSchemeViewWatcher(),
        /* insurance requests */
        fetchInsuranceRequestLinesWatcher(),
        fetchInsuranceRequestsWatcher(),
        /* ---------generic saga */
        fetchGenericMastersPagesWatcher(),
        constructFetchGenericMastersPagesWatcher(),
        genericAddOrUpdateyWatcher(),
        /* ---------- favourite reports saga ---------------*/
        fetchFavouriteReportsWatcher(),
        addFavouriteRequestWatcher(),
        removeFavouriteRequestWatcher(),
        /*----------------------------------*/
        commonGetWatcherSaga(),
        commonPostWatcherSaga(),
        commonDeleteWatcherSaga(),
        saveFormularyListWatcher(),
        fetchFormularyListWatcher(),
        previousPurchaseInvoiceLinesWatcher(),
        fetchRoomDepartmentMappingWatcher(),
        saveRoomDepartmentMappingWatcher(),
        fetchDebitAndCreditLinesWatcher(),
        createOrUpdateCashPaymentVoucherWatcher(),
        fetchCashPaymentVoucherWatcher(),
        fetchAccountVouchersWatcher(),
        fetchAccountInvoiceTableWatcher(),
        fetchByEmployeeAndPeriodWatcherSaga(),
        encounterOrdersWatcherSaga(),
        /* --------- RSD --------- */
        fetchProductDispatchWatcher(),
        /* ----------------------- */
        favoriteForDoctorWatcherSaga(),
        medicalPackageFetchWatcherSaga(),
        /* ---- Iqama / Baladiya ---- */
        fetchBilledPackagesWatcher(),
    ]);
}
