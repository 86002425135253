import API from '../../../constants/api';

export const FETCH_ANNOUNCEMENT_LIST_REQUEST = '@@announcement/FETCH_ANNOUNCEMENT_LIST_REQUEST';
export const FETCH_ANNOUNCEMENT_LIST_SUCCESS = '@@announcement/FETCH_ANNOUNCEMENT_LIST_SUCCESS';
export const FETCH_ANNOUNCEMENT_LIST_FAILURE = '@@announcement/FETCH_ANNOUNCEMENT_LIST_FAILURE';

export const ADD_ANNOUNCEMENT_REQUEST = '@@announcement/ADD_ANNOUNCEMENT_REQUEST';
export const ADD_ANNOUNCEMENT_SUCCESS = '@@announcement/ADD_ANNOUNCEMENT_SUCCESS';
export const ADD_ANNOUNCEMENT_FAILURE = '@@announcement/ADD_ANNOUNCEMENT_FAILURE';

export const EDIT_ANNOUNCEMENT_REQUEST = '@@announcement/EDIT_ANNOUNCEMENT_REQUEST';
export const EDIT_ANNOUNCEMENT_SUCCESS = '@@announcement/EDIT_ANNOUNCEMENT_SUCCESS';
export const EDIT_ANNOUNCEMENT_FAILURE = '@@announcement/EDIT_ANNOUNCEMENT_FAILURE';

export const ADD_CURRENT_ANNOUNCEMENT_REQUEST = '@@announcement/ADD_CURRENT_ANNOUNCEMENT_REQUEST';


export const fetchAnnouncementList = (
    page = 0,
    size = 10,
    searchText = '',
) => ({
    type: FETCH_ANNOUNCEMENT_LIST_REQUEST,
    api: API.ANNOUNCEMENTS.FETCH,
    page,
    size,
    searchText,
});

export const addAnnouncement = payload => ({
    type: ADD_ANNOUNCEMENT_REQUEST,
    payload,
    api: API.ANNOUNCEMENTS.ADD,
});

export const editAnnouncement = (payload, index) => ({
    type: EDIT_ANNOUNCEMENT_REQUEST,
    payload,
    index,
    api: API.ANNOUNCEMENTS.EDIT,
});

export const addCurrentAnnouncement = currentAnnouncement => ({
    type: ADD_CURRENT_ANNOUNCEMENT_REQUEST,
    currentAnnouncement,
});
