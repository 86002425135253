import initialStates from '../../initialStates';
import {
    CLEAR_SELECT_ATTENDANCE,
    FETCH_ATTENDANCE_LIST_ACTION_FAILURE,
    FETCH_ATTENDANCE_LIST_ACTION_REQUEST,
    FETCH_ATTENDANCE_LIST_ACTION_SUCCESS,
    FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE,
    FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS,
    FETCH_PERIODS_SUCCESS,
    RENDER_ATTENDANCE_EDIT_FORM,
    SAVE_DUTY_ACTION_SUCCESS,
    SELECT_ATTENDANCE_REQUEST,
    UPDATE_APPROVED_ATTENDANCE_SUCCESS,
    UPDATE_ATTENDANCE_LIST_ACTION,
    REVERT_APPROVED_ATTENDANCE_SUCCESS,
} from './attendance-actions';

const attendanceReducer = (state = initialStates.attendance, action) => {
    let newState = {};

    switch (action.type) {
    case SAVE_DUTY_ACTION_SUCCESS:
        newState = Object.assign({}, state, { data: action.data });
        break;
    case FETCH_PERIODS_SUCCESS:
        newState = Object.assign({}, state, { periods: action.data });
        break;
    case RENDER_ATTENDANCE_EDIT_FORM:
        newState = Object.assign({}, state, { edit: action.edit });
        break;
    case SELECT_ATTENDANCE_REQUEST:
        newState = Object.assign({}, state, { selectedAttendance: action.selectedAttendance });
        break;
    case UPDATE_APPROVED_ATTENDANCE_SUCCESS:
        newState = Object.assign({}, state, { selectedAttendance: action.data, edit: false });
        break;
    case FETCH_ATTENDANCE_LIST_ACTION_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_ATTENDANCE_LIST_ACTION_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS:
        newState = Object.assign({}, state, { selectedAttendance: action.data });
        break;
    case FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE:
    case CLEAR_SELECT_ATTENDANCE:
        newState = Object.assign({}, state, { selectedAttendance: {} });
        break;
    case UPDATE_ATTENDANCE_LIST_ACTION:
        newState = { ...state, list: action.list };
        break;
    case REVERT_APPROVED_ATTENDANCE_SUCCESS:
        newState = Object.assign({}, state, { selectedAttendance: action.data, edit: false });
        break;
    case FETCH_ATTENDANCE_LIST_ACTION_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            first: action.data.first,
            last: action.data.last,
            totalElements: action.data.totalElements,
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default attendanceReducer;
