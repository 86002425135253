import { takeLatest } from 'redux-saga/effects';
import { createAccountExpenseWorker, fetchAccountExpenseWorker } from './accountExpenseWorkerSaga';
import {
    CREATE_ACCOUNT_EXPENSE_REQUEST,
    FETCH_ACCOUNT_EXPENSE_REQUEST,
} from '../../redux/modules/accountExpense/accountExpense-actions';

export function* createAccountExpenseWatcher() {
    yield takeLatest(CREATE_ACCOUNT_EXPENSE_REQUEST, createAccountExpenseWorker);
}

export function* fetchAccountExpenseWatcher() {
    yield takeLatest(FETCH_ACCOUNT_EXPENSE_REQUEST, fetchAccountExpenseWorker);
}
