import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    WORKFLOW_SAVE_SUCCESS,
    WORKFLOW_SAVE_FAILURE,
    WORKFLOW_FETCH_SUCCESS, WORKFLOW_FETCH_FAILURE,
} from '../../redux/modules/workflow/workflow-actions';
import { CHANGE_ROUTE_REQUEST } from '../../redux/modules/navigator/navigator-actions';
import { inbox } from '../../constants/constants';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,

} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import { retriable } from '../retriable';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* workflowSaveRequestWorkerSaga(action) {
    const api = `${action.api}?wfProcessName=${action.currentState}`;
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().put, api, action.payload);
        if (response.data) {
            yield put({ type: WORKFLOW_SAVE_SUCCESS, data: response.data });
            let msg = messages.WORKFLOW.SUBMITTED;
            if (getStringFromObject('completed', response.data, false) === true) {
                msg = messages.WORKFLOW.COMPLETED;
            }
            yield put({ type: SUCCESS_MESSAGE, message: msg });
        }
        yield put({ type: CHANGE_ROUTE_REQUEST, route: `${inbox}/requests` });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: WORKFLOW_SAVE_FAILURE, data: action.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* workflowFetchRequestWorkerSaga(action) {
    const api = `${action.api}?entityUuid=${action.uuid}`;
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, api);
        if (response.data) {
            yield put({ type: WORKFLOW_FETCH_SUCCESS, data: response.data, inboxType: action.inboxType });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: WORKFLOW_FETCH_FAILURE, data: action.data });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
