import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import api from '../../constants/api';
import DateInput from '../../components/FormFieldComponents/DateInput/DateInput';
import {
    addOrSubtractDays,
    formatDateForDisplay,
    getDateInYYYYMMDDFormat,
    getStartOfDay,
    parseDate,
} from '../../constants/DateUtil';
import ActionButton from '../../components/ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
import { getStringFromObject } from '../../constants/lodashUtils';
import { apiCatchBlockFunction } from '../../constants/CommonUtil.jsx';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner.js';


class RegenerateAttendanceForAllEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            period: null,
            processWithoutShiftTiming: false,
            regenerateStatus: '',

        };
    }

    onProcessChange = (event) => {
        this.setState({
            processWithoutShiftTiming: event.target.checked,
        });
    };

    onChangeValue = fieldName => (changedValue) => {
        this.setState({
            [fieldName]: changedValue,
        });
    };

    onChangePeriod = (period) => {
        let startDate = '';
        let endDate = '';
        if (isObjectValidAndNotEmpty(period)) {
            startDate = parseDate(period.startDate, 'yyyy-MM-dd').getTime();
            endDate = parseDate(period.endDate, 'yyyy-MM-dd').getTime();
        }
        this.setState({
            period,
            startDate,
            endDate,
        });
    };
    onRegenerateAttendance = async () => {
        const { startDate, endDate, processWithoutShiftTiming } = this.state;
        const { dispatch, handleClose } = this.props;
        if (!startDate || !endDate) {
            dispatch(errorMessage('Date range is mandatory'));
            return;
        }

        this.setState({ regenerateStatus: 'Starting Attendance Regeneration' });
        dispatch(showSpinner());
        for (let currentDate = getStartOfDay(startDate).getTime(); currentDate <= getStartOfDay(endDate).getTime(); currentDate = addOrSubtractDays(currentDate, 1).getTime()) {
            this.setState({
                regenerateStatus: `Regenerating Attendance For (${formatDateForDisplay(currentDate)}) from ${formatDateForDisplay(startDate)} - ${formatDateForDisplay(endDate)}`,
            });

            try {
                // eslint-disable-next-line no-await-in-loop
                await axios.get(api.ATTENDANCE.REGENERATE_ATTENDANCE_FOR_ALL_EMPLOYEES, {
                    params: {
                        fromDateString: getDateInYYYYMMDDFormat(currentDate),
                        toDateString: getDateInYYYYMMDDFormat(currentDate),
                        processWithoutShiftTiming,
                    },
                });
                // eslint-disable-next-line no-await-in-loop
                await new Promise(resolve => setTimeout(resolve, 1000));
            } catch (error) {
                apiCatchBlockFunction(error, dispatch);
                this.setState({ regenerateStatus: 'Attendance Generation Failed !' });
                return;
            }
        }
        dispatch(hideSpinner());
        handleClose();
    };

    render() {
        const {
            startDate,
            endDate,
            period,
            processWithoutShiftTiming,
            regenerateStatus,
        } = this.state;
        const {
            dispatch,
            allowAttendanceProcessWithoutShiftTiming,
        } = this.props;
        console.log(dispatch, endDate);
        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item sm={6} md={6} lg={6}>
                        <ReactSelectMaterial
                            dataSourceApi={`${api.PAYROLL.ALL_PERIODS}?size=4&searchString=`}
                            dataSourceConfig={{
                                value: 'uuid',
                                text: 'displayWithDates',
                            }}
                            onChange={this.onChangePeriod}
                            label="Period"
                            value={period}
                            testId="period"
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <DateInput
                            label="Start Date"
                            value={startDate}
                            onChange={this.onChangeValue('startDate')}
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <DateInput
                            label="End Date"
                            value={endDate}
                            onChange={this.onChangeValue('endDate')}
                        />
                    </Grid>
                    {
                        allowAttendanceProcessWithoutShiftTiming && (
                            <Grid item container sm={12} md={12} lg={12} alignItems="center" justify="flex-end">
                                <Grid item>
                                    <Checkbox
                                        checked={processWithoutShiftTiming}
                                        onChange={this.onProcessChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        Process without shift timings.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid container item sm={12} md={12} lg={12} alignContent="center">
                        <Typography sm={12} align="center" >
                            {regenerateStatus}
                        </Typography>
                    </Grid>
                    <strong>Warning: Regeneration will result in the loss of manually edited attendance changes.</strong>
                    <Grid item sm={12} md={12} lg={12} className="flexChildren justify-content-right">
                        <ActionButton
                            onClick={this.onRegenerateAttendance}
                            testId="regenerate-attendance"
                        >
                            Regenerate Attendance
                        </ActionButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

RegenerateAttendanceForAllEmployee.propTypes = {
    dispatch: PropTypes.func,
    handleClose: PropTypes.func,
    allowAttendanceProcessWithoutShiftTiming: PropTypes.bool,
};

RegenerateAttendanceForAllEmployee.defaultProps = {
    dispatch: () => {},
    handleClose: () => { },
    allowAttendanceProcessWithoutShiftTiming: false,
};

const mapStateToProps = state => ({
    allowAttendanceProcessWithoutShiftTiming: getStringFromObject('appConfiguration.allowAttendanceProcessWithoutShiftTiming', state) || false,
});


export default connect(mapStateToProps)(RegenerateAttendanceForAllEmployee);

