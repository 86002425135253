import cloneDeep from 'clone-deep';
import axios from 'axios';
import {
    apiCatchBlockFunction,
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    isValidNumeric,
    isValidObject,
    roundedValue,
    roundedValueFixedToTwoDigits, valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const EMPTY_ROW = {
    product: '',
    batch: '',
    uom: '',
    quantity: '',
    salePrice: '',
    taxes: [],
    amount: '',
    discount: '',
    discountAmount: '',
    subTotal: '',
    total: '',
    description: '',
};

export const setSubTotalAndTotal = (rowValue) => {
    const quantity = NumberOf(rowValue.quantity);
    const salePrice = NumberOf(rowValue.salePrice);
    const amount = (quantity * salePrice);
    const discount = NumberOf(rowValue.discount);
    const discountAmount = roundedValue(amount * (discount / 100));
    const subTotal = amount - discountAmount;
    const taxes = rowValue.taxes || [];
    let totalTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            const newTax = (NumberOf(tax.amount) * subTotal);
            totalTax += NumberOf(newTax);
        });
    }
    return {
        ...rowValue,
        amount: roundedValueFixedToTwoDigits(amount),
        discount: roundedValueFixedToTwoDigits(discount),
        discountAmount: roundedValueFixedToTwoDigits(discountAmount),
        subTotal: roundedValueFixedToTwoDigits(subTotal),
        totalTax: roundedValueFixedToTwoDigits(totalTax),
        total: roundedValueFixedToTwoDigits(subTotal + totalTax),
    };
};

const setLine = (rowValue, index, form, actionParams) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.accountInvoiceLines.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        setFieldValue(`accountInvoiceLines.${valuesLength}`, cloneDeep(EMPTY_ROW));
    }
    const dispatch = getStringFromObject('dispatch', form, () => {});
    const newRowValue = cloneDeep(rowValue);
    console.log('asdfsdfsdfdsfdsf', rowValue);
    if (isObjectValidAndNotEmpty(newRowValue)) {
        const product = newRowValue.product ? newRowValue.product : {};
        let quantity = Math.max(NumberOf(newRowValue.quantity), 1);
        const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
        // const factor = uom && uom.factor ? Number(uom.factor) : 1;
        const salePrice = newRowValue.salePrice == null ? NumberOf(product.price) : NumberOf(newRowValue.salePrice);
        newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, false);
        newRowValue.uom = uom;
        newRowValue.paramMap = { productUomCategroyId: getStringFromObject('erpUomDto.category.key', product) };
        newRowValue.salePrice = salePrice;
        newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', newRowValue, null);
        const locationUuid = getStringFromObject('sourceLoc.uuid', actionParams, null);
        const productUuid = getStringFromObject('product.uuid', newRowValue, null);
        const includeZeroBatches = 'true';
        if (locationUuid) {
            axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                params: {
                    productUuid,
                    locationUuid,
                    includeZeroBatches,
                },
            }).then((response) => {
                const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
                newRowValue.isInternalLocation = getStringFromObject('data.locationType', response, '') === 'internal';
                newRowValue.batchWithQty = [];
                newRowValue.allBatches = [];
                if (isArrayValidAndNotEmpty(batchDetails)) {
                    batchDetails.map((batch) => {
                        if (!getStringFromObject('batch.key', batch, false)) {
                            quantity = Math.max(NumberOf(getStringFromObject('quantity', batch)), 1);
                        } else {
                            newRowValue.batchWithQty.push({
                                ...batch,
                                text: `${getStringFromObject('batch.value', batch, '')}
                            (${getStringFromObject('quantity', batch, 0)})`,
                                value: getStringFromObject('batch.value', batch, ''),
                            });
                            newRowValue.allBatches.push({ ...batch });
                        }
                        return null;
                    });
                }
                newRowValue.quantity = quantity;
                setFieldValue(`accountInvoiceLines[${index}]`, setSubTotalAndTotal(newRowValue));
            }).catch(error => apiCatchBlockFunction(error, dispatch));
        }
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, fieldPath, form, actionParams) => {
    if (fieldPath && isValidObject(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            if (isObjectValidAndNotEmpty(product)) {
                const rowValue = getStringFromObject(`values.accountInvoiceLines[${index}]`, form, {});
                rowValue.product = product;
                rowValue.batch = null;
                rowValue.uom = getStringFromObject('erpUomDto', product, null);
                rowValue.taxes = product.taxes || [];
                rowValue.batchWithQty = [];
                rowValue.allBatches = [];
                rowValue.quantity = isValidNumeric(rowValue.quantity) ? NumberOf(rowValue.quantity) : 1;
                rowValue.isInternalLocation = false;
                rowValue.salePrice = null;
                rowValue.amount = null;
                rowValue.discount = null;
                rowValue.total = null;
                setLine(rowValue, index, form, actionParams);
            } else {
                const setFieldValue = getStringFromObject('setFieldValue', form);
                if (isValidFunction(setFieldValue)) {
                    setFieldValue(`accountInvoiceLines[${index}]`, cloneDeep(EMPTY_ROW));
                }
            }
        }
    }
};

const quantityChangeHandler = (value, fieldPath, form) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (fieldPath && isValidObject(form) && isValidFunction(setFieldValue)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.accountInvoiceLines[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = NumberOf(value);
                setFieldValue(`accountInvoiceLines[${index}]`, setSubTotalAndTotal(rowValue));
            }
        }
    }
};

const rateChangeHandler = (value, fieldPath, form) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (fieldPath && isValidObject(form) && isValidFunction(setFieldValue)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = getStringFromObject(`values.accountInvoiceLines[${index}]`, form, {});
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.salePrice = NumberOf(value);
                setFieldValue(`accountInvoiceLines[${index}]`, setSubTotalAndTotal(rowValue));
            }
        }
    }
};

const handleTaxSelect = (value, fieldPath, form) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (fieldPath && isObjectValidAndNotEmpty(form) && isValidFunction(setFieldValue)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = isArrayValidAndNotEmpty(value) ? value : [];
                setFieldValue(`accountInvoiceLines[${index}]`, setSubTotalAndTotal(rowValue));
            }
        }
    }
};

const deleteRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.accountInvoiceLines', form, []));
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(setFieldValue) &&
        isArrayValidAndNotEmpty(values)) {
        selectedRows.forEach((idx) => {
            values.splice(idx, 1);
            if (values.length === 0) {
                values.push(cloneDeep(EMPTY_ROW));
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
        setFieldValue('accountInvoiceLines', values);
    }
};

const handleBatchSelect = (value, name, form) => {
    console.log('Params', value, form);
    if (name && isValidObject(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const index = getIndexFromFieldName(name);
        if (index != null && isValidFunction(setFieldValue)) {
            let rowValue = getStringFromObject(`values.accountInvoiceLines.${index}`, form, {});
            rowValue = { ...rowValue, batch: value };
            const expiryDateTimeInMillis = getStringFromObject('expiryDate', value);
            if (expiryDateTimeInMillis) {
                rowValue = { ...rowValue, expiryDate: getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis)) };
            }
            // eslint-disable-next-line max-len
            rowValue = { ...rowValue, salePrice: roundedValueFixedToTwoDigits(NumberOf(getStringFromObject('cost', value))) };
            setFieldValue(`accountInvoiceLines.${index}`, setSubTotalAndTotal(rowValue));
        }
    }
};

const discountChangeHandler = (value, fieldPath, form) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (fieldPath && isObjectValidAndNotEmpty(form) && isValidFunction(setFieldValue)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.discount = NumberOf(value);
                setFieldValue(`accountInvoiceLines[${index}]`, setSubTotalAndTotal(rowValue));
            }
        }
    }
};

const totalChangeHandler = (value, fieldPath, form) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (fieldPath && isObjectValidAndNotEmpty(form) && isValidFunction(setFieldValue)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (!isObjectValidAndNotEmpty(rowValue.product)) {
                    form.setFieldError(`accountInvoiceLines[${index}].product`, 'Required');
                    return;
                }
                if (rowValue.trackByBatch && !isObjectValidAndNotEmpty(rowValue.batch)) {
                    form.setFieldError(`accountInvoiceLines[${index}].batch`, 'Required');
                    return;
                }
                if (NumberOf(rowValue.salePrice) <= 0) {
                    form.setFieldError(`accountInvoiceLines[${index}].salePrice`, 'Required');
                    return;
                }
                const quantity = NumberOf(rowValue.quantity);
                const rate = NumberOf(rowValue.salePrice);
                const amount = quantity * rate;
                const taxes = getStringFromObject('taxes', rowValue, []);
                let applicableTax = 0;
                if (isArrayValidAndNotEmpty(taxes)) {
                    taxes.forEach((tax) => {
                        applicableTax += NumberOf(tax.amount);
                    });
                }
                const validTotal = roundedValue(amount + (amount * applicableTax));
                const total = Math.min(NumberOf(value), validTotal);
                const discountAmount = roundedValue(amount - (total / (1 + applicableTax)));
                const discount = roundedValue((discountAmount * 100) / amount);
                const subTotal = amount - discountAmount;
                rowValue.amount = NumberOf(valueToFixedTwoDigits(amount));
                rowValue.discount = roundedValue(discount);
                rowValue.discountAmount = roundedValue(discountAmount);
                rowValue.subTotal = roundedValue(subTotal);
                rowValue.totalTax = valueToFixedTwoDigits(subTotal * applicableTax);
                rowValue.total = NumberOf(valueToFixedTwoDigits(total));
                setFieldValue(`accountInvoiceLines[${index}]`, rowValue, false);
            }
        }
    }
};

export const ACTION_HANDLERS = {
    handleProductSelect,
    rateChangeHandler,
    quantityChangeHandler,
    deleteRowHandler,
    handleTaxSelect,
    handleBatchSelect,
    discountChangeHandler,
    totalChangeHandler,
};

export default ACTION_HANDLERS;
