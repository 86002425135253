import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import ActionButton from '../ActionButton/ActionButton';
import BarcodePrintDialog from './BarcodePrintDialog';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';

class BarcodePrintComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            printDialog: false,
            batchActionAnchor: null,
            type: '',
        };
    }


    handlePrintMenuClick = (event) => {
        this.setState({
            batchActionAnchor: event.target,
        });
    };
    handlePrintMenuClose = () => {
        this.setState({
            batchActionAnchor: null,
        });
    };

    openPrintBarcodeDialog = type => () => {
        this.setState({
            printDialog: true,
            type,
        });
        this.handlePrintMenuClose();
    }
    closePrintBarcodeDialog = () => {
        this.setState({
            printDialog: false,
            batchActionAnchor: null,
        });
    }


    render() {
        const {
            batchActionAnchor,
            type,
            printDialog,
        } = this.state;
        const {
            items,
            actions,
        } = this.props;
        console.log('BarcodePrintComponent Items', items);
        return (
            <React.Fragment>
                <Grid item>
                    <ActionButton
                        onClick={this.handlePrintMenuClick}
                        disabled={!isArrayValidAndNotEmpty(items)}
                    >
                        Print Barcode
                    </ActionButton>
                    <Menu
                        id="batch-action-menu"
                        anchorEl={batchActionAnchor}
                        open={Boolean(batchActionAnchor)}
                        onClose={this.handlePrintMenuClose}
                    >
                        {
                            actions.map(anAction => (
                                <MenuItem
                                    test-id={anAction.key}
                                    key={anAction.key}
                                    onClick={this.openPrintBarcodeDialog(anAction.key)}
                                    value={anAction.key}
                                >
                                    {anAction.value}
                                </MenuItem>
                            ))
                        }
                    </Menu>
                    {
                        printDialog &&
                        <BarcodePrintDialog
                            type={type}
                            items={items}
                            onClose={this.closePrintBarcodeDialog}
                        />
                    }
                </Grid>
            </React.Fragment>
        );
    }
}

BarcodePrintComponent.propTypes = {
    items: PropTypes.array.isRequired,
    actions: PropTypes.array.isRequired,
};

BarcodePrintComponent.defaultProps = {

};

export default BarcodePrintComponent;
