/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialTableRow from './MaterialTableRow';
import MaterialTableHead from './MaterialTableHead';
import {
    getIndexFromFieldName,
    getLoadingSkeletonsForTable,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { defaultRowsPerPageOptions } from '../../../constants/constants';
import MouseTrap from '../../../constants/mousetrap';
import Popper from '../../Popper';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const buttonStyle = {
    color: 'black',
    background: 'white',
    borderRadius: '15px',
    width: '8rem',
    minHeight: '1rem',
    height: '1.8rem',
    fontSize: '0.8rem',
    padding: '0.1rem',
};

/* function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
} */

/* function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
} */

/* function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
} */
const styles = () => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        overflow: 'visible',
    },
    table: {},
    tableWrapper: {
        overflowX: 'auto',
        overflow: 'visible',
    },
    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    miniCell: {
        minWidth: '75px',
    },
    narrowCell: {
        maxWidth: '150px',
    },
});

const inputRefs = [];

class EnhancedTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        selected: [],
        expandedRowIndex: null,
        openPopper: -1,
        anchorEl: null,
    };

    componentDidMount() {
        MouseTrap.bind('alt+a', () => {
            this.addNewRow();
        });

        MouseTrap.bindGlobal('shift+enter', () => {
            const elementName = document.activeElement.getAttribute('name');
            const elementIndex = getIndexFromFieldName(elementName);
            if (!(Number.isNaN(Number(elementIndex)))) {
                /* Get Next Row First Ref and Focus it */
                const nextRow = inputRefs[Number(elementIndex) + 1];
                console.log('NextRowRefs', nextRow, inputRefs[Number(elementIndex) + 1], elementIndex, elementName);
                if (nextRow) {
                    nextRow[1].focus();
                } else {
                    this.addNewRow();
                }
            }
        });

        MouseTrap.prototype.stopCallback = () => {
            console.log('SpecialFunc');
            return false;
        };
    }

    componentWillReceiveProps(nextProps) {
        // if pagination enabled reset selection if page/size changes
        if (!deepEqual(nextProps.paginationDetails, this.props.paginationDetails) && !nextProps.internalPagination) {
            this.setState({ selected: [] });
        }
        console.log('DateFromProps', nextProps.lastUpdated, this.props.lastUpdated);
        if (!deepEqual(nextProps.lastUpdated, this.props.lastUpdated)) {
            this.setState({ selected: [] });
        }
    }

    getVisibleItems = () => {
        const { paginationDetails: { page, size }, formValues, fields: { name } } = this.props;
        const items = getStringFromObject(name, formValues);
        if (isArrayValidAndNotEmpty(items)) {
            const count = items.length;
            const start = (page) * (size);
            const end = start + size;
            // this.setState({ start });
            const visibleItems = items.slice(start, end < count ? end : count);
            return visibleItems;
        }
        return [];
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
        this.props.handleSort(order, orderBy);
    };

    handleAction = (value) => {
        const selected = cloneDeep(this.state.selected);
        selected.sort((a, b) => b - a);
        const { fields, actionHandlers, dispatch } = this.props;
        let index = 0;
        switch (value) {
            case 'addNew':
                fields.push({});
                break;
            case 'delete':
                for (index = 0; index < selected.length; index += 1) {
                    fields.remove(selected[index]);
                }
                this.setState({ selected: [] });
                break;
            default:
                if (isObjectValidAndNotEmpty(actionHandlers)) {
                    const handler = actionHandlers[value];
                    if (isValidFunction(handler)) {
                        handler(selected, fields, dispatch, this);
                    }
                }
                break;
        }
    };

    handleSelectAllClick = (event) => {
        const { fields } = this.props;
        if (event.target.checked) {
            this.setState({ selected: fields.map((n, idx) => idx) },
                () => this.callHandleRowSelectionFuncFromProps(this.state.selected));
        } else {
            this.setState({ selected: [] }, () => this.callHandleRowSelectionFuncFromProps([]));
        }
    };

    handleClick = (event, index) => {
        event.stopPropagation();
        const selected = cloneDeep(this.state.selected);
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        this.setState({ selected: newSelected }, () => this.callHandleRowSelectionFuncFromProps(newSelected));
    };

    callHandleRowSelectionFuncFromProps = (selectedRows = []) => {
        const { handleRowSelection } = this.props;
        if (isValidFunction(handleRowSelection)) {
            handleRowSelection(selectedRows);
        }
    };

    handleChangePage = (event, page) => {
        if (isValidFunction(this.props.handlePageChange)) {
            this.props.handlePageChange(page);
        }
    };

    handleChangeRowsPerPage = (event) => {
        if (isValidFunction(this.props.handleSizeChange)) {
            this.props.handleSizeChange(event.target.value);
        }
    };

    isSelected = (index) => {
        const { selected } = this.state;
        return selected.indexOf(index) !== -1;
    };

    handleRowExpand = (index) => {
        console.log('sad-asud-asd-s0asad', index, this.state.expandedRowIndex);
        this.setState(prevState => ({
            expandedRowIndex: prevState.expandedRowIndex === index ? null : index,
        }));
    };

    addRef = (ref, field, rowIndex, colIndex) => {
        console.log('AddRefTest', ref, field);
        const { tableCells } = this.props;
        let temp = inputRefs[rowIndex];
        if (temp) {
            temp[colIndex] = ref;
        } else {
            temp = new Array(tableCells.length);
            temp[colIndex] = ref;
        }
        inputRefs[rowIndex] = temp;
    };

    addNewRow = () => {
        const { fields } = this.props;
        fields.push({});
        const currentRow = inputRefs[inputRefs.length - 1];
        console.log('AddNewRef', currentRow, inputRefs, inputRefs.length);
        if (currentRow) {
            currentRow[1].focus();
        }
    };

    resetPopperState = () => this.setState({ openPopper: -1, anchorEl: null });

    handleDeleteRow = (e, index) => {
        this.setState({ openPopper: index, anchorEl: e.currentTarget });
    };

    handleDeleteRowConfirm = () => {
        const { openPopper } = this.state;
        const { onRowDelete, fields } = this.props;
        if (openPopper > -1) {
            if (isValidFunction(onRowDelete)) {
                const { formValues } = this.props;
                onRowDelete(openPopper, formValues, fields);
            }
        }
        this.resetPopperState();
    };


    render() {
        const {
            classes,
            tableCells,
            fields,
            meta,
            isEditable,
            rowstyle,
            tableActionButtons,
            enablePagination,
            hideCheckBox,
            handleSelectAction,
            minWidth,
            handleButtonAction,
            actionHandlers,
            isExpandable,
            tableRoot,
            getExpandableComponent,
            paginationDetails: {
                page,
                size,
                totalElements,
            },
            formValues,
            showRowNumber,
            actionParams,
            onRowClick,
            loading,
            saveIcon,
            handleSaveIconClick,
            deleteIcon,
            uniqueKey,
            infoIcon,
            internalPagination,
            handleInfoIconClick,
            rowsPerPageOptions,
            forceEnableCheckBox,
        } = this.props;
        const {
            order,
            orderBy,
            selected,
            expandedRowIndex,
            openPopper,
            anchorEl,
        } = this.state;
        const showEmpty = (isEditable) || (fields.length > 0);
        let rowData = fields;
        if (internalPagination) {
            rowData = this.getVisibleItems();
        }
        const start = (page) * (size);
        const { name } = fields;
        console.log('dsdfsdasdfsdfsdf', this.props);
        const headerRowStyle = { background: '#fafafa' };
        const headerColStyle = { color: '#000', fontSize: '14px' };
        const error = getStringFromObject('error', meta);
        if (showEmpty) {
            return (
                <Paper className={classes.root} style={{ ...tableRoot }}>
                    <div className={classes.tableWrapper} style={{ ...tableRoot }}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            style={{ minWidth }}
                        >
                            <MaterialTableHead
                                hideCheckBox={hideCheckBox}
                                forceEnableCheckBox={forceEnableCheckBox}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                tableCells={tableCells}
                                classes={classes}
                                numSelected={selected.length}
                                rowCount={fields.length}
                                isExpandable={isExpandable}
                                isEditable={isEditable}
                                headerRowStyle={headerRowStyle}
                                headerColStyle={headerColStyle}
                                onSelectAllClick={this.handleSelectAllClick}
                                showRowNumber={showRowNumber}
                                saveIcon={saveIcon}
                                deleteIcon={deleteIcon}
                            />
                            <TableBody>
                                {
                                    loading &&
                                    getLoadingSkeletonsForTable(
                                        enablePagination ? size : 1,
                                        isArrayValidAndNotEmpty(tableCells) ? tableCells.length : 2,
                                    )
                                }
                                { /* eslint-disable react/no-array-index-key */}
                                {
                                    rowData.map((member, index) => {
                                        console.log('eaachMember', { member, index: `${name}[${index + start}]` });
                                        return (
                                            <MaterialTableRow
                                                forceEnableCheckBox={forceEnableCheckBox}
                                                onRowClick={onRowClick}
                                                formValues={formValues}
                                                hideCheckBox={hideCheckBox}
                                                key={
                                                    uniqueKey ?
                                                        getStringFromObject(`${name}.${uniqueKey}`, formValues) :
                                                        `${name}-${index}`
                                                }
                                                isSelected={this.isSelected(internalPagination ? index + start : index)}
                                                rowStyle={rowstyle}
                                                member={internalPagination ? `${name}[${index + start}]` : member}
                                                tableCells={tableCells}
                                                handleClick={this.handleClick}
                                                // index={index}
                                                index={internalPagination ? index + start : index}
                                                isEditable={isEditable}
                                                getExpandableComponent={getExpandableComponent}
                                                isExpandable={isExpandable}
                                                handleRowExpand={this.handleRowExpand}
                                                expandedRowIndex={expandedRowIndex}
                                                parentClasses={classes}
                                                fields={fields}
                                                addRef={this.addRef}
                                                handleSelectAction={handleSelectAction}
                                                handleButtonAction={handleButtonAction}
                                                actionHandlers={actionHandlers}
                                                paramMap={getStringFromObject('paramMap', this.props.paramMap[index], {})}
                                                showRowNumber={showRowNumber}
                                                actionParams={actionParams}
                                                retired={this.props.retired}
                                                retiredValue={this.props.retiredValue}
                                                saveIcon={saveIcon}
                                                deleteIcon={deleteIcon}
                                                handleDeleteRowClick={this.handleDeleteRow}
                                                handleSaveIconClick={handleSaveIconClick}
                                                infoIcon={infoIcon}
                                                handleInfoIconClick={handleInfoIconClick}
                                            />
                                        );
                                    })
                                }
                            </TableBody>
                            {
                                error &&
                                <TableFooter>
                                    <TableRow>
                                        <TableCell className="error-text">
                                            {error}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            }
                        </Table>
                    </div>
                    {
                        enablePagination &&
                        <TablePagination
                            component="div"
                            count={totalElements}
                            rowsPerPage={size}
                            page={page}
                            rowsPerPageOptions={
                                isArrayValidAndNotEmpty(rowsPerPageOptions) ?
                                    rowsPerPageOptions : defaultRowsPerPageOptions
                            }
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    <div>
                        {
                            isEditable && tableActionButtons.map(button => (
                                <Button
                                    key={`${button.key}`}
                                    test-id={`${button.key}`}
                                    onClick={() => this.handleAction(button.value)}
                                    style={{ ...buttonStyle, ...button.style }}
                                    variant="outlined"
                                >
                                    {button.label}
                                </Button>
                            ))
                        }
                    </div>
                    {
                        (openPopper > -1) && Boolean(anchorEl) &&
                        <Popper
                            open={openPopper > -1}
                            anchorEl={anchorEl}
                            placement="left"
                            handleClose={this.resetPopperState}
                        >
                            <DialogTitle test-id="confrim-text">
                                Confirm
                            </DialogTitle>
                            <DialogContent test-id="message-delete">
                                Are you sure want to delete ?
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    test-id="noPopperState"
                                    variant="outlined"
                                    disableRipple
                                    onClick={this.resetPopperState}
                                >
                                    No
                                </Button>
                                <Button
                                    test-id="yesDeleteRowConfirm"
                                    color="primary"
                                    variant="outlined"
                                    disableRipple
                                    onClick={this.handleDeleteRowConfirm}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Popper>
                    }
                </Paper>
            );
        }
        return <div />;
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    tableCells: PropTypes.array.isRequired,
    tableActionButtons: PropTypes.array,
    paramMap: PropTypes.array,
    minWidth: PropTypes.string,
    hideCheckBox: PropTypes.bool,
    deleteIcon: PropTypes.bool,
    saveIcon: PropTypes.bool,
    rowstyle: PropTypes.object,
    paginationDetails: PropTypes.object,
    isEditable: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    meta: PropTypes.object,
    enablePagination: PropTypes.bool,
    handleSelectAction: PropTypes.func,
    actionHandlers: PropTypes.object,
    dispatch: PropTypes.func,
    handlePageChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    onRowDelete: PropTypes.func,
    getExpandableComponent: PropTypes.func,
    handleSaveIconClick: PropTypes.func,
    handleSort: PropTypes.func,
    isExpandable: PropTypes.bool,
    showRowNumber: PropTypes.bool,
    handleRowSelection: PropTypes.func,
    handleButtonAction: PropTypes.func,
    tableRoot: PropTypes.object,
    actionParams: PropTypes.object,
    onRowClick: PropTypes.func,
    lastUpdated: PropTypes.instanceOf(Date),
    retired: PropTypes.string,
    retiredValue: PropTypes.bool,
    loading: PropTypes.bool,
    uniqueKey: PropTypes.string,
    handleInfoIconClick: PropTypes.func,
    infoIcon: PropTypes.bool,
    internalPagination: PropTypes.bool,
    rowsPerPageOptions: PropTypes.array,
    forceEnableCheckBox: PropTypes.bool,
};

EnhancedTable.defaultProps = {
    isEditable: true,
    meta: {},
    hideCheckBox: false,
    deleteIcon: false,
    saveIcon: false,
    minWidth: '1020px',
    uniqueKey: null,
    tableActionButtons: [],
    paramMap: [],
    rowstyle: PropTypes.object.isRequired,
    enablePagination: true,
    internalPagination: false,
    handleSelectAction: () => { },
    handleSaveIconClick: () => { },
    onRowDelete: () => { },
    actionHandlers: {},
    paginationDetails: {},
    dispatch: () => { },
    handlePageChange: () => { },
    handleSizeChange: () => { },
    handleButtonAction: () => { },
    getExpandableComponent: () => { },
    handleSort: () => { },
    isExpandable: false,
    showRowNumber: false,
    handleRowSelection: () => { },
    tableRoot: {},
    actionParams: {},
    lastUpdated: null,
    onRowClick: () => { },
    retired: null,
    loading: false,
    retiredValue: null,
    infoIcon: false,
    handleInfoIconClick: () => { },
    rowsPerPageOptions: defaultRowsPerPageOptions,
    forceEnableCheckBox: false,
};

export default withStyles(styles)(EnhancedTable);
