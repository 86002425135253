export const DISPLAY_CONFIRM_DIALOG_WITH_USER_INPUT = '@@message/DISPLAY_CONFIRM_DIALOG_WITH_USER_INPUT';
export const CLEAR_CONFIRM_DIALOG_WITH_USER_INPUT = '@@message/CLEAR_CONFIRM_DIALOG_WITH_USER_INPUT';

export const displayConfirmDialogWithUserInput = ({
    message,
    confirmCallBack,
    title,
    cancelLabel,
    confirmLabel,
    validationText,
}) => ({
    type: DISPLAY_CONFIRM_DIALOG_WITH_USER_INPUT,
    message,
    confirmCallBack,
    title,
    cancelLabel,
    confirmLabel,
    validationText,
});

export const clearConfirmDialogWithUserInput = () => ({
    type: CLEAR_CONFIRM_DIALOG_WITH_USER_INPUT,
});
