import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_HOLIDAY_FOR_YEAR_REQUEST,
    FETCH_HOLIDAY_LIST_REQUEST, FETCH_UPCOMING_HOLIDAY_REQUEST,
    SAVE_HOLIDAY_REQUEST,
} from '../../redux/modules/holidays/holiday-actions';
import {
    fetchHolidayForYearRequest,
    fetchUpcomingHolidaysRequest,
    holidayFetchRequest,
    holidaySaveRequest,
} from './holidayWorkerSaga';


export function* holidaysFetchSaga() {
    yield takeLatest(FETCH_HOLIDAY_LIST_REQUEST, holidayFetchRequest);
}

export function* holidaysSaveSaga() {
    yield takeLatest(SAVE_HOLIDAY_REQUEST, holidaySaveRequest);
}

export function* fetchHolidayForYearSaga() {
    yield takeLatest(FETCH_HOLIDAY_FOR_YEAR_REQUEST, fetchHolidayForYearRequest);
}
export function* fetchUpcomingHolidaySaga() {
    yield takeLatest(FETCH_UPCOMING_HOLIDAY_REQUEST, fetchUpcomingHolidaysRequest);
}
