import React from 'react';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel';
import PropTypes from 'prop-types';
import { hexToRgb, isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { headerColStyle, headerRowStyle } from '../../constants/constants';

const styles = theme => ({
    root: {
        color: theme.palette.primary1Color,
        '&$checked': {
            color: theme.palette.nuacareSecondaryColor,
        },
    },
    checked: {},
});

class MaterialTableHead extends React.Component {
    getSortCol = (row) => {
        if (row.sortCol) {
            return row.sortCol;
        }
        return row.id;
    };

    createSortHandler = property => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            tableCells,
            hideCheckBoxIcon,
            classes,
            isExpandable,
            tableName,
            stylizedHeaderColor,
        } = this.props;
        let additionalRowStyle = {};
        let additionalColStyle = {};
        if (stylizedHeaderColor) {
            const rgb = hexToRgb(stylizedHeaderColor);
            additionalRowStyle = {
                background: `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 0.2)`,
            };
            additionalColStyle = {
                color: stylizedHeaderColor,
            };
        }
        console.log('skjdfhlaksdjfh', headerRowStyle);
        return (
            <TableHead>
                <TableRow style={{ ...headerRowStyle, ...additionalRowStyle, height: '37px' }}>
                    {
                        !hideCheckBoxIcon &&
                        <TableCell padding="checkbox" style={{ ...headerColStyle, width: '1em' }}>
                            <Checkbox
                                test-id={`${tableName}.header.checkbox`}
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                            />
                        </TableCell>
                    }
                    {
                        isExpandable &&
                        <TableCell padding="checkbox" style={{ width: '1em' }} />
                    }
                    {
                        isArrayValidAndNotEmpty(tableCells) &&
                        tableCells.map(row => (
                            <TableCell
                                key={row.id}
                                test-id={`${tableName}.${row.name}`}
                                numeric={row.numeric}
                                align={row.align || 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                                style={{ ...row.style, ...row.headerStyle }}
                            >
                                {
                                    !row.noHeader &&
                                    (
                                        row.displaySortIcon ?
                                            <Tooltip
                                                title="Sort"
                                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === this.getSortCol(row)}
                                                    direction={order}
                                                    onClick={this.createSortHandler(this.getSortCol(row))}
                                                    style={{ ...headerColStyle, ...additionalColStyle }}
                                                >
                                                    {row.label}
                                                </TableSortLabel>
                                            </Tooltip> :
                                            <span style={{ ...headerColStyle, ...additionalColStyle }}>
                                                {row.label}
                                            </span>
                                    )
                                }
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        );
    }
}

MaterialTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    tableCells: PropTypes.array.isRequired,
    hideCheckBoxIcon: PropTypes.bool.isRequired,
    isExpandable: PropTypes.bool.isRequired,
    stylizedHeaderColor: PropTypes.string,
};

MaterialTableHead.defaultProps = {
    stylizedHeaderColor: '',
};

export default withStyles(styles)(MaterialTableHead);
