import { isArrayValidAndNotEmpty } from './CommonUtil';
import { getPrivileges } from './state';
import { findBestMatch } from './StringSimilarity';
import { getStringFromObject } from './lodashUtils';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

export const checkIfPrivilegeExistsForUser = (privilege) => {
    // if (process.env.NODE_ENV === 'development') {
    //     return true;
    // }
    if (getPrivileges() && isArrayValidAndNotEmpty(privilege)) {
        const userPrivileges = getPrivileges().split(',');
        for (let j = 0; j < privilege.length; j += 1) {
            if (!userPrivileges.includes(privilege[j])) {
                return false;
            }
        }
    }
    return true;
};


export const isAuthorized = (path, pathPrivileges, disablePartialRatioRouteMatching = false) => {
    // if (process.env.NODE_ENV === 'development') {
    //     return true;
    // }
    const allRoutes = Object.keys(pathPrivileges);
    const bestRouteMatches = findBestMatch(path, allRoutes, disablePartialRatioRouteMatching);
    // console.log('asd-0-0-a0sd-0akdapodasdknasld', bestRouteMatches);
    const bestMatchIndex = getStringFromObject('bestMatchIndex', bestRouteMatches, -1);
    if (getPrivileges() && (bestMatchIndex >= 0) && (bestMatchIndex <= allRoutes.length)) {
        const bestRoute = getStringFromObject('bestMatch.target', bestRouteMatches, null);
        console.log('as-dia-sd0ia', bestMatchIndex, bestRoute);
        if (path.startsWith(bestRoute)) {
            const privileges = getStringFromObject('privileges', pathPrivileges[bestRoute], []);
            if (isArrayValidAndNotEmpty(privileges)) {
                console.log('asd9asd0aidadppd', checkIfPrivilegeExistsForUser(privileges), privileges, bestRoute);
                return checkIfPrivilegeExistsForUser(privileges);
            }
        } else {
            console.log('best match else', pathPrivileges, bestRoute);
            return true;
        }
    }
    return false;
};

const checkIfUserHasRequiredPrivilegeForTabs = (sideBarTabs, location, disablePartialRatioRouteMatching) => {
    if (isObjectValidAndNotEmpty(sideBarTabs)) {
        const tabs = Object.keys(sideBarTabs);
        let tabObject = null;
        if (isArrayValidAndNotEmpty(tabs)) {
            const bestRoute = findBestMatch(location, tabs, disablePartialRatioRouteMatching);
            tabObject = sideBarTabs[getStringFromObject('bestMatch.target', bestRoute, {})];
        }
        if (isObjectValidAndNotEmpty(tabObject)) {
            // console.log('asdfasdfadsf', tabObject);
            const privilege = getStringFromObject('privilege', tabObject);
            return checkIfPrivilegeExistsForUser(privilege);
        }
    }
    return true;
};

export default checkIfUserHasRequiredPrivilegeForTabs;
