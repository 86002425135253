import API from '../../../constants/api';
import consultationStatuses from '../../../containers/QueueNodeDisplayContainer/QueueUtils';

export const DOCTOR_Q_LIST_REQUEST = '@@Visit/DOCTOR_Q__LIST_REQUEST';
export const DOCTOR_Q_LIST_SUCCESS = '@@Visit/DOCTOR_Q__LIST_SUCCESS';
export const DOCTOR_Q_LIST_FAILURE = '@@Visit/DOCTOR_Q__LIST_FAILURE';
export const REMOVE_PATIENT_FROM_QUEUE = '@@Visit/REMOVE_PATIENT_FROM_QUEUE';
export const DOCTOR_TV_FETCH = '@@tvDisplay/DOCTOR_TV_FETCH';
export const CHANGE_DOCTOR_STATUS = '@@tvDisplay/CHANGE_DOCTOR_STATUS';
export const OPEN_ENCOUNTER_FETCH_REQUEST = '@@Visit/OPEN_ENCOUNTER_FETCH_REQUEST';
export const OPEN_ENCOUNTER_FETCH_SUCCESS = '@@Visit/OPEN_ENCOUNTER_FETCH_SUCCESS';
export const OPEN_ENCOUNTER_FETCH_FAILURE = '@@Visit/OPEN_ENCOUNTER_FETCH_FAILURE';
export const INCOMPLETE_ENCOUNTER_FETCH_REQUEST = '@@Visit/INCOMPLETE_ENCOUNTER_FETCH_REQUEST';
export const INCOMPLETE_ENCOUNTER_FETCH_SUCCESS = '@@Visit/INCOMPLETE_ENCOUNTER_FETCH_SUCCESS';
export const INCOMPLETE_ENCOUNTER_FETCH_FAILURE = '@@Visit/INCOMPLETE_ENCOUNTER_FETCH_FAILURE';


export const fetchDoctorQList = (
    userUuid,
    sort,
    consultationStatus = consultationStatuses.WAITING,
) => ({
    type: DOCTOR_Q_LIST_REQUEST,
    api: API.QUEUES.PATIENT_LIST,
    userUuid,
    sort,
    consultationStatus,
});

export const fetchDoctorQSucess = api => ({
    type: DOCTOR_Q_LIST_SUCCESS,
    api,
});
export const fetchDoctorQFailure = api => ({
    type: DOCTOR_Q_LIST_FAILURE,
    api,
});
export const removePatientFromQueue = index => ({
    type: REMOVE_PATIENT_FROM_QUEUE,
    index,
});

export const fetchDataForTvDisplay = data => ({
    type: DOCTOR_TV_FETCH,
    data,
});

export const changeDoctorStatus = newStatus => ({
    type: CHANGE_DOCTOR_STATUS,
    newStatus,
});

export const openEncounters = (page, size) => ({
    type: OPEN_ENCOUNTER_FETCH_REQUEST,
    api: API.ENCOUNTERS.OPEN,
    page,
    size,
});

export const incompleteEncounters = (page, size, searchString) => ({
    type: INCOMPLETE_ENCOUNTER_FETCH_REQUEST,
    api: API.ENCOUNTERS.INCOMPLETE_QUEUE,
    page,
    size,
    searchString,
});

export const saveQList = data => ({
    type: DOCTOR_Q_LIST_SUCCESS,
    data,
});
