import React from 'react';
import PropTypes from 'prop-types';
import NavigationHeader from '../../components/NavigationHeader';

const mainPageContainer = props => (
    <div>
        <NavigationHeader
            profile={props.profile}
            NavbarBottomBar
            appShortcuts={props.appShortcuts}
            history={props.history}
            updatePreRouteChangeCheck={props.updatePreRouteChangeCheck}
            title=""
        >
            {props.children}
        </NavigationHeader>
    </div>
);

mainPageContainer.propTypes = {
    history: PropTypes.object,
    children: PropTypes.node,
    profile: PropTypes.object.isRequired,
    updatePreRouteChangeCheck: PropTypes.func.isRequired,
    appShortcuts: PropTypes.array.isRequired,
};

mainPageContainer.defaultProps = {
    history: {},
    children: <div />,
};

export default mainPageContainer;
