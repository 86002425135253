import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import TextField from '@material-ui/core/TextField/TextField';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import SearchField from '../SearchField/SearchField';
import './OrganisationListView.css';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { organisationAdmin, organisationAccountStatus } from '../../constants/privilegeConstants';
import { organizationList } from '../../constants/constants';
import API from '../../constants/api';

const filterOptions = [
    { label: 'All employees', value: 'allEmployees' },
    { label: 'My team', value: 'myTeam' },
    { label: 'My peers', value: 'myPeers' },
];

const style = () => ({
    arrow: {
        cursor: 'pointer',
    },
    disableArrow: {
        opacity: '0.6',
        cursor: 'not-allowed',
    },
});

const OrganisationHeader = ({
    location, organization, classes, onLeftArrowClick, onRightArrowClick, selectedFilter, handleFilterChange, onSearchTextChange, getDataBySearch, searchText, onPositionSelectTextChange, positionSelectText, onDepartmentSelectTextChange, departmentSelectText, showDeactivated, onShowDeactivatedChange, onTabClick,
}) => (
    <Grid
        container
        style={{
            minHeight: '5rem',
            alignItems: 'center',
            backgroundColor: 'white',
        }}
    >
        <Grid item lg={1} md={1} sm={1}>
            <Button
                test-id="list-view"
                disableRipple
                className="org-box-list-button"
                onClick={() => onTabClick(organizationList)}
            >
                <span style={{ color: (location.pathname === organizationList) && '#1AA0E4' }}>
                    List View
                </span>
            </Button>
        </Grid>
        <Grid item lg={3} md={3} sm={3} className="justify-content-center">
            {
                (location.pathname === organizationList) &&
                    <Grid container>
                        <Grid
                            container
                            item
                            lg={4}
                            md={4}
                            sm={5}
                            justify="flex-end"
                            alignItems="center"
                        >
                            <ArrowLeft
                                test-id="arrow-left"
                                className={organization.isFirst ? classes.disableArrow : classes.arrow}
                                onClick={onLeftArrowClick}
                            />
                            <span>
                                {organization.page + 1} of {organization.totalPages}
                            </span>
                            <ArrowRight
                                test-id="arrow-right"
                                className={organization.isLast ? classes.disableArrow : classes.arrow}
                                onClick={onRightArrowClick}
                            />
                        </Grid>
                        <Grid item lg={6} md={7} sm={6} className={classNames('display-flex', 'align-center')}>
                            <TextField
                                test-id="selectallemployee"
                                id="standard-fiscal-year"
                                select
                                value={selectedFilter}
                                onChange={handleFilterChange}
                                style={{
                                    width: '12em',
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            >
                                {
                                    filterOptions.map(m => (
                                        <MenuItem
                                            test-id={`fiscal-${m.value}`}
                                            style={{ fontSize: '13px' }}
                                            key={`fiscal-${m.value}`}
                                            value={m.value}
                                        >
                                            {m.label}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
            }
        </Grid>
        {
            checkIfPrivilegeExistsForUser(organisationAdmin) &&
            checkIfPrivilegeExistsForUser(organisationAccountStatus) ?
                <Grid item lg={2} md={2} sm={2} style={{ paddingRight: '2rem' }}>
                    <ReactSelectMaterial
                        testId="searchByPosition"
                        dataSourceApi={API.EMPLOYEE.GET_POSITION_SUGGESTIONS}
                        dataSourceConfig={{
                            value: 'key',
                            text: 'value',
                        }}
                        autocomplete
                        label="Search By Position"
                        value={positionSelectText}
                        onChange={onPositionSelectTextChange('positionSelectText')}
                    />
                </Grid>
                :
                <Grid item lg={3} md={3} sm={3} style={{ paddingRight: '2rem' }}>
                    <ReactSelectMaterial
                        testId="searchByPosition"
                        dataSourceApi={API.EMPLOYEE.GET_POSITION_SUGGESTIONS}
                        dataSourceConfig={{
                            value: 'key',
                            text: 'value',
                        }}
                        autocomplete
                        label="Search By Position"
                        value={positionSelectText}
                        onChange={onPositionSelectTextChange('positionSelectText')}
                    />
                </Grid>
        }
        {
            checkIfPrivilegeExistsForUser(organisationAdmin) &&
            checkIfPrivilegeExistsForUser(organisationAccountStatus) ?
                <Grid item lg={2} md={2} sm={2} style={{ paddingRight: '2rem' }}>
                    <ReactSelectMaterial
                        testId="searchByDepartment"
                        dataSourceApi={API.EMPLOYEE.GET_DEPARTMENT_SUGGESTION}
                        dataSourceConfig={{
                            value: 'key',
                            text: 'value',
                        }}
                        autocomplete
                        label="Search By Department"
                        value={departmentSelectText}
                        onChange={onDepartmentSelectTextChange('departmentSelectText')}
                    />
                </Grid>
                :
                <Grid item lg={3} md={3} sm={3} style={{ paddingRight: '2rem' }}>
                    <ReactSelectMaterial
                        testId="searchByDepartment"
                        dataSourceApi={API.EMPLOYEE.GET_DEPARTMENT_SUGGESTION}
                        dataSourceConfig={{
                            value: 'key',
                            text: 'value',
                        }}
                        autocomplete
                        label="Search By Department"
                        value={departmentSelectText}
                        onChange={onDepartmentSelectTextChange('departmentSelectText')}
                    />
                </Grid>
        }
        <Grid item lg={2} md={2} sm={2} style={{ paddingRight: '2rem' }}>
            <SearchField
                testId="employeeName"
                label="Employee Name or ID"
                allowFreeText
                textField
                disableUnderline={false}
                showSearchIcon
                backgroundColor="white"
                searchValue={searchText}
                onChange={onSearchTextChange}
                getDataBySearch={getDataBySearch}
            />
        </Grid>
        {
            checkIfPrivilegeExistsForUser(organisationAdmin) &&
            checkIfPrivilegeExistsForUser(organisationAccountStatus) &&
            <Grid item lg={2} md={2} sm={2} style={{ paddingRight: '2rem' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={onShowDeactivatedChange}
                            checked={showDeactivated}
                            color="primary"
                        />
                    }
                    label="Include Inactive"
                />
            </Grid>
        }
    </Grid>
);

OrganisationHeader.propTypes = {
    location: PropTypes.object.isRequired,
    onLeftArrowClick: PropTypes.func,
    onRightArrowClick: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    onPositionSelectTextChange: PropTypes.func,
    onDepartmentSelectTextChange: PropTypes.func,
    onShowDeactivatedChange: PropTypes.func,
    organization: PropTypes.object.isRequired,
    selectedFilter: PropTypes.string,
    searchText: PropTypes.string,
    positionSelectText: PropTypes.string,
    departmentSelectText: PropTypes.string,
    showDeactivated: PropTypes.bool,
    handleFilterChange: PropTypes.func,
    getDataBySearch: PropTypes.func,
    onTabClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
};

OrganisationHeader.defaultProps = {
    selectedFilter: 'allEmployees',
    searchText: '',
    positionSelectText: '',
    departmentSelectText: '',
    showDeactivated: false,
    onLeftArrowClick: () => {},
    onRightArrowClick: () => {},
    getDataBySearch: () => {},
    onSearchTextChange: () => {},
    onPositionSelectTextChange: () => {},
    onDepartmentSelectTextChange: () => {},
    onShowDeactivatedChange: () => {},
    handleFilterChange: () => {},
    onTabClick: () => {},
};

export default withStyles(style)(OrganisationHeader);
