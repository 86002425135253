/* eslint-disable*/
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import AutosuggestSearch from '../AutosuggestSearch/AutosuggestSearch';
import './SearchField.css';

class SearchField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.searchValue !== nextProps.searchValue ||
            this.state.searchText!==nextProps.searchValue
        ) {
            if (nextProps.searchValue === undefined) {
                this.setState({ searchText: '' });
            } else {
                this.setState({ searchText: nextProps.searchValue });
            }
        }
    }

    onChange = (event) => {
        const searchText = event.target.value;
        console.log('IN', event, searchText);
        this.props.onChange(event, searchText);
        this.setState({ searchText }, () => {
            if (searchText.length === 0) {
                this.props.getDataBySearch(searchText);
            }
        });
    };

    handleSelect = (onChange, selected) => {
        onChange(selected);
        this.props.onNewRequest(
            `${this.props.onSelectApiCall}/${selected.key}`,
            this.props.field,
        );
    };

    handleKeyDownTextField = (event) => {
        const { searchText } = this.state;
        if (event.key === 'Enter' && searchText.length !== 0) {
            this.props.getDataBySearch(this.state.searchText);
        }
    };

    handleOnClickSearchIcon = () => {
        if (this.state.searchText.length !== 0) {
            this.props.getDataBySearch(this.state.searchText);
        }
    };

    render() {
        /*
        const { onSelectApiCall, onNewRequest } = this.props;
        const input = {
            value: [],
            onChange: this.onChange,
        };
        */
        const { props } = this.props;
        const { searchText } = this.props;
        const backgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : '#f9f9f9';
        const borderRadius = this.props.borderRadius
            ? this.props.borderRadius
            : '0px';
        const padding = this.props.padding ? this.props.padding : '0.6em';
        const margin = this.props.margin ? this.props.margin : '0';
        const styleSearchBox = {
            backgroundColor,
            borderRadius,
        };
        const styleSearchTextField = {
            padding,
            margin,
        };
        if (this.props.textField) {
            return (
                <div className="searchBox" style={styleSearchBox}>
                    <div
                        className="searchTextField"
                        style={styleSearchTextField}
                    >
                        <TextField
                            placeholder={this.props.label}
                            fullWidth
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDownTextField}
                            value={this.state.searchText}
                            inputProps={{
                                'test-id': this.props.testId
                            }}
                            InputProps={{
                                disableUnderline: this.props.disableUnderline,
                                width: '100%',
                            }}
                        />
                        {this.props.showSearchIcon &&
                        <div id="searchIcon" onClick={this.handleOnClickSearchIcon}>
                            <SearchIcon
                                test-id="search-icon-stock"
                                onClick={this.handleOnClickSearchIcon}
                                style={{cursor: 'pointer'}}
                            />
                        </div>
                        }
                    </div>
                </div>
            );
        }

        return (
            <div className="searchBox">
                <div className="searchTextField">
                    <AutosuggestSearch placeholder={this.props.placeholder} />
                </div>
            </div>
        );
    }
}

SearchField.propTypes = {
    onSelectApiCall: PropTypes.string,
    allowFreeText: PropTypes.bool,
    onNewRequest: PropTypes.func,
    field: PropTypes.string,
    searchValue: PropTypes.string,
    getDataBySearch: PropTypes.func,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    textField: PropTypes.bool,
    label: PropTypes.string,
    disableUnderline: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    showSearchIcon: PropTypes.bool,
};

SearchField.defaultProps = {
    searchValue: '',
    allowFreeText: false,
    getDataBySearch: () => {},
    onSelectApiCall: '',
    onNewRequest: () => {},
    field: '',
    backgroundColor: '',
    borderRadius: '',
    padding: '',
    margin: '',
    textField: false,
    label: '',
    disableUnderline: false,
    placeholder: '',
    onChange: () => {},
    showSearchIcon: false,
};

export default SearchField;
