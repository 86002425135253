/**
 * Created by vinay on 27/6/19.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedSelectField from '../OutlinedSelectField';
import { getTextFieldChangedValue } from '../../constants/CommonUtil';
import OutlinedTextField from '../OutlinedTextField';
import { getStringFromObject } from '../../constants/lodashUtils';

const style = () => ({
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

class FilterFieldWithOperation extends React.PureComponent {
    handleChangeOperation = (e) => {
        const { value, onChange } = this.props;
        onChange({ ...value, operation: e.target.value });
    };

    handleChangeValue = (changedValue) => {
        const { value, onChange } = this.props;
        onChange({ ...value, value: getTextFieldChangedValue(changedValue) });
    };

    render() {
        const {
            classes,
            value,
            label,
            valueLabel,
            options,
            type,
            dataSourceConfig,
        } = this.props;
        const optionLabel = getStringFromObject('text', dataSourceConfig);
        const optionValue = getStringFromObject('value', dataSourceConfig);
        const operation = getStringFromObject('operation', value);
        return (
            <Grid container spacing={16}>
                <Grid item lg={6} md={6} sm={6}>
                    <OutlinedSelectField
                        value={operation}
                        label={label}
                        onChange={this.handleChangeOperation}
                        options={options.map(o => (
                            <MenuItem
                                test-id={getStringFromObject(optionLabel, o, o)}
                                key={getStringFromObject(optionValue, o, o)}
                                value={getStringFromObject(optionValue, o, o)}
                            >
                                {getStringFromObject(optionLabel, o, o)}
                            </MenuItem>
                        ))}
                        InputProps={{
                            classes: {
                                input: classes.input,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                    {
                        operation &&
                        <OutlinedTextField
                            testId={valueLabel}
                            value={getStringFromObject('value', value)}
                            label={valueLabel}
                            type={type}
                            onChange={this.handleChangeValue}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    }
                </Grid>
            </Grid>
        );
    }
}

FilterFieldWithOperation.propTypes = {
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    valueLabel: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.object,
    dataSourceConfig: PropTypes.object,
};

FilterFieldWithOperation.defaultProps = {
    label: 'Operation',
    type: '',
    valueLabel: 'Value',
    options: [],
    value: {},
    dataSourceConfig: {},
};

export default withStyles(style)(FilterFieldWithOperation);
