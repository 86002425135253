import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_MEDICAL_PACKAGE_LIST_REQUEST,
} from '../../redux/modules/medicalPackage/medicalPackage-action';
import { medicalPackageListFetchWorkerSaga } from './medicalPackageWorkerSaga';


export default function* medicalPackageFetchWatcherSaga() {
    yield takeLatest(FETCH_MEDICAL_PACKAGE_LIST_REQUEST, medicalPackageListFetchWorkerSaga);
}

