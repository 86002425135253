import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import DialogComponent from '../../components/DialogComponent/DialogComponent';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import API from '../../constants/api';
import {
    setQueue,
    setServiceLocation,
    toggleQueueSelect,
} from '../../redux/modules/queue/queue-actions';
import { apiCatchBlockFunction, getJsonPath } from '../../constants/CommonUtil';
import { getSelectedQueue } from '../../constants/state';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { APPLICATION_CONFIG_URL, GLOBAL_PROPERTIES } from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class QueueSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceLocations: [],
        };
    }
    componentDidMount() {
        const queue = getSelectedQueue();
        if (queue) {
            this.loadServiceLocation(queue);
        }
        console.log(queue);
    }

    onClose = () => {
        this.props.dispatch(toggleQueueSelect(true));
    };

    onSelectQueue = (queue) => {
        const { dispatch } = this.props;
        if (isObjectValidAndNotEmpty(queue)) {
            dispatch(setQueue(queue, false));
            this.loadServiceLocation(queue);
        } else {
            dispatch(setQueue(null, false));
        }
        dispatch(setServiceLocation(null, false));
    };

    onSelectServiceLoc = (serviceLocation) => {
        if (isObjectValidAndNotEmpty(serviceLocation)) {
            this.props.dispatch(setServiceLocation(serviceLocation, false));
        }
    };
    getQConfigForRequest = async () => {
        const r1 = await axios.get(getJsonPath('/QueueUserConfig.json', APPLICATION_CONFIG_URL));
        const qConfig = r1.data;
        const token = `${getStringFromObject('username', qConfig)}:${getStringFromObject('password', qConfig)}`;
        const hash = btoa(token);
        const config = { headers: { Authorization: `Basic ${hash}` } };
        return config;
    };
    loadServiceLocation = async (queue) => {
        const { dispatch } = this.props;
        try {
            dispatch(showSpinner());
            const config = await this.getQConfigForRequest();
            const resp = await axios.get(`${API.QUEUE.FIND_ONE}${queue.key}`, config);
            const serviceLocations = getStringFromObject('data.queueLocationDtos', resp, [])
                .map(e => ({ key: e.id, value: getStringFromObject('serviceLocation.value', e) }));
            this.setState({
                serviceLocations,
            });
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        } finally {
            dispatch(hideSpinner());
        }
    }
    render() {
        const {
            queueData,
            enableApprovalQueue,
        } = this.props;
        const { serviceLocations } = this.state;
        const dialogOpen = getStringFromObject('openSelect', queueData);
        const {
            queue,
        } = queueData;
        const serviceLocation = getStringFromObject('serviceLocation', queueData);
        console.log('dfjhdjhdjfd', queueData, this.props);
        return (
            <DialogComponent
                header="Select Your Queue"
                open={dialogOpen}
                handleClose={this.onClose}
            >
                {
                    enableApprovalQueue &&
                    <Grid container className="mt-1" justify="space-around">
                        <ReactSelectMaterial
                            autocomplete
                            label="Queue"
                            dataSourceApi={API.GENERAL_QUEUE.SEARCH_QUEUE}
                            dataSourceConfig={{
                                text: 'value',
                                value: 'key',
                            }}
                            value={queue}
                            onChange={this.onSelectQueue}
                        />
                    </Grid>
                }
                {
                    enableApprovalQueue &&
                    <Grid container className="mt-1" justify="space-around">
                        <ReactSelectMaterial
                            options={serviceLocations}
                            dataSourceConfig={{
                                value: 'key',
                                text: 'value',
                            }}
                            onChange={this.onSelectServiceLoc}
                            label="Service location"
                            value={serviceLocation}
                        />
                    </Grid>
                }
            </DialogComponent>
        );
    }
}

QueueSelect.propTypes = {
    dispatch: PropTypes.func.isRequired,
    queueData: PropTypes.object,
    enableApprovalQueue: PropTypes.bool,
};

QueueSelect.defaultProps = {
    queueData: null,
    enableApprovalQueue: false,
};

const mapStateToProps = state => ({
    queueData: state.queue,
    enableApprovalQueue: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.QUEUE_APPROVAL_ENABLED.replace(/\./g, '_')}`, state, false),
});

export default connect(mapStateToProps)(QueueSelect);

