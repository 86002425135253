import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_JOURNAL_ENTRY_FAILURE,
    CREATE_JOURNAL_ENTRY_SUCCESS,
    FETCH_JOURNAL_ENTRIES_LIST_FAILURE,
    FETCH_JOURNAL_ENTRIES_LIST_SUCCESS,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE,
    FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS,
    POST_JOURNAL_ENTRY_FAILURE,
    POST_JOURNAL_ENTRY_SUCCESS,
} from '../../redux/modules/journalEntries/journalEntries-actions';
import generateSearchConstruct from './journalEntrySearchConstructUtil';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';

export function* fetchJournalEntriesWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const construct = generateSearchConstruct(action.filters);
        const response = yield call(retriable().post, action.api, construct, config);
        yield put({
            type: FETCH_JOURNAL_ENTRIES_LIST_SUCCESS,
            data: response.data,
            filters: action.filters,
            sortCol: action.sortCol,
            sortOrder: action.sortOrder,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.JOURNAL_ENTRIES.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_JOURNAL_ENTRIES_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchJournalEntriesOptimizedWorker(action) {
    try {
        const {
            api,
            page,
            size,
            seekParams,
            seekDirection,
            filters,
        } = action;
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const apiWithParams = addSeekDirectionAndParamsToUrlParams(`${api}?size=${size}`, seekDirection, seekParams);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const searchConstruct = generateSearchConstruct(filters);
        const response = yield call(retriable().post, apiWithParams, searchConstruct, config);
        yield put({
            type: FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_SUCCESS,
            data: {
                ...(response.data || {}),
                page,
                size,
            },
            filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.JOURNAL_ENTRIES.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_JOURNAL_ENTRIES_LIST_OPTIMIZED_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createAccountJournalEntry(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const response = yield call(retriable().post, action.api, action.payload, config);
        yield put({
            type: CREATE_JOURNAL_ENTRY_SUCCESS,
            data: response.data,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: messages.JOURNAL_ENTRIES.CREATE.SUCCESS,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.JOURNAL_ENTRIES.CREATE.FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: CREATE_JOURNAL_ENTRY_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createPostJournalEntry(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        const response = yield call(retriable().post, action.api, null, config);
        yield put({
            type: POST_JOURNAL_ENTRY_SUCCESS,
            data: response.data,
        });
        yield put({
            type: SUCCESS_MESSAGE,
            message: messages.JOURNAL_ENTRIES.POST.SUCCESS,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.JOURNAL_ENTRIES.POST.FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: POST_JOURNAL_ENTRY_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
