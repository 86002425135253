import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_INTERNAL_MOVE_REQUEST,
    FETCH_INTENT_REQUEST,
    REVERSE_TRANSFER_REQUEST,
} from '../../redux/modules/internalMoves/internalMoves-actions';
import { createInternalMovesWorker, fetchIntentWorkerSaga, reverseTransferWorkerSaga } from './internalMovesWorkerSaga';

export function* createInternalMovesWatcher() {
    yield takeLatest(CREATE_INTERNAL_MOVE_REQUEST, createInternalMovesWorker);
}

export function* fetchIntentWatcher() {
    yield takeLatest(FETCH_INTENT_REQUEST, fetchIntentWorkerSaga);
}

export function* reverseTransferWatcher() {
    yield takeLatest(REVERSE_TRANSFER_REQUEST, reverseTransferWorkerSaga);
}
