import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty, isValidTextAndNotEmpty, roundedValueFixedToTwoDigits,
} from '../constants/CommonUtil';
import { getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { setSubTotalAndTotal } from '../components/PurchaseOrderComponents/CreditOrDebitMemo/CreditOrDebitMemoHandlers';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
import {
    getInvoiceTypeByMemoType,
    getIsCreditMemo,
    getIsIssue,
    getMemoDescription,
} from '../components/PurchaseOrderComponents/CreditOrDebitMemoV2/CreditOrDebitMemoUtilsV2';

export const INVOICE_LINE_UI_OBJECT = {
    uuid: '',
    name: '',
    product: null,
    batch: null,
    uom: null,
    quantity: '',
    salePrice: '',
    amount: '',
    total: '',
    totalTax: '',
    narration: '',
    taxes: [],
};
export const INVOICE_UI_OBJECT = {
    uuid: '',
    state: 'draft',
    comment: '',
    name: null,
    supplier: null,
    dateInvoice: (new Date()),
    amountTax: 0,
    amountUntaxed: 0,
    subTotal: 0,
    amountTotal: 0,
    stockToReturn: true,
    lineDescription: '',
    accountInvoiceLines: [cloneDeep(INVOICE_LINE_UI_OBJECT)],
};

export const getAccountInvoiceLineDescription = (invoiceType, partnerName, amountTotal) => (
    [
        invoiceType,
        partnerName,
        amountTotal,
    ].filter(Boolean).join('/')
);

export const getAccountInvoiceFromUiObject = (uiObject) => {
    const accountInvoice = {};
    const { memoType } = uiObject;
    const invoiceType = getInvoiceTypeByMemoType(memoType);
    accountInvoice.state = uiObject.state;
    accountInvoice.uuid = uiObject.uuid;
    accountInvoice.paymentMeans = uiObject.paymentMeans;
    if (uiObject.isCashPurchaseOrSale) {
        accountInvoice.isCashPurchaseOrSale = true;
        accountInvoice.cashPartnerName = uiObject.cashPartnerName;
        accountInvoice.cashPartnerIdNumber = uiObject.cashPartnerIdNumber;
        accountInvoice.cashPartnerSubCompany = uiObject.cashPartnerSubCompany;
    } else {
        accountInvoice.partnerUuid = uiObject.supplier.uuid;
        accountInvoice.patientName = uiObject.supplier.name;
    }
    accountInvoice.invoiceReferences = getStringFromObject('invoiceReferences', uiObject) || [];
    accountInvoice.comment = uiObject.comment;
    accountInvoice.type = invoiceType;
    accountInvoice.referenceType = 'none';
    accountInvoice.stockLocation = {
        key: getStringFromObject('stockLocation.uuid', uiObject, null),
        value: getStringFromObject('stockLocation.name', uiObject, null),
    };
    accountInvoice.groupDescription = getStringFromObject('stockLocation.uuid', uiObject, null);
    if (uiObject.dateInvoice) {
        accountInvoice.dateInvoice = getDateInYYYYMMDDFormat(`${uiObject.dateInvoice}`);
    }
    const accountInvoiceLines = [];
    let amountUnTaxed = 0;
    let amountTotal = 0;
    let amountTax = 0;
    const stockToReturn = getStringFromObject('stockToReturn', uiObject, false);
    if (!stockToReturn) {
        const creditAmount = getStringFromObject('creditAmount', uiObject, 0);
        const taxAccount = getStringFromObject('taxAccount', uiObject, null);
        const taxes = taxAccount ? [taxAccount] : null;
        amountUnTaxed = NumberOf(creditAmount);
        amountTax = NumberOf(getStringFromObject('amount', taxAccount)) * amountUnTaxed;
        amountTotal = amountUnTaxed + amountTax;
        accountInvoiceLines.push({
            name: isValidTextAndNotEmpty(uiObject.lineDescription) ?
                uiObject.lineDescription.trim() :
                getAccountInvoiceLineDescription(
                    invoiceType,
                    getStringFromObject('supplier.name', uiObject),
                    getStringFromObject(roundedValueFixedToTwoDigits(amountTotal)),
                ),
            creditOrDebitMemo: true,
            accountHead: getStringFromObject('account', uiObject, null),
            sequence: 0,
            priceUnit: amountUnTaxed,
            priceSubtotal: amountUnTaxed,
            quantity: 1,
            discount: 0,
            description: getMemoDescription(memoType),
            taxAccountDetails: taxes,
        });
        accountInvoice.amountUntaxed = roundedValueFixedToTwoDigits(amountUnTaxed);
        accountInvoice.amountTotal = roundedValueFixedToTwoDigits(amountTotal);
        accountInvoice.amountTax = roundedValueFixedToTwoDigits(amountTax);
        accountInvoice.accountInvoiceLineDtoList = accountInvoiceLines;
        return accountInvoice;
    }
    const uiLines = uiObject.accountInvoiceLines || [];
    if (isArrayValidAndNotEmpty(uiLines)) {
        uiLines.forEach((uiLine, index) => {
            if (uiLine.product && uiLine.product.uuid) {
                const invoiceLine = {};
                invoiceLine.uuid = uiLine.uuid;
                invoiceLine.stockProdLot = getStringFromObject('batch.batch', uiLine, null);
                invoiceLine.name = uiLine.name;
                invoiceLine.description = 'Credit memo from supplier';
                invoiceLine.uom = uiLine.uom;
                invoiceLine.sequence = index;
                invoiceLine.productUuid = uiLine.product.uuid;
                invoiceLine.batchName = getStringFromObject('batchName', uiLine, null);
                invoiceLine.expiryDate = getStringFromObject('expiryDate', uiLine, null);
                invoiceLine.quantity = uiLine.quantity;
                invoiceLine.bonus = uiLine.bonus;
                invoiceLine.priceUnit = uiLine.salePrice;
                invoiceLine.discount = uiLine.discount;
                invoiceLine.priceSubtotal = NumberOf(uiLine.subTotal);
                invoiceLine.taxAccountDetails = uiLine.taxes;
                amountUnTaxed += NumberOf(uiLine.subTotal);
                amountTax += NumberOf(uiLine.totalTax);
                amountTotal += NumberOf(uiLine.total);
                accountInvoiceLines.push(invoiceLine);
            }
        });
    }

    accountInvoice.amountUntaxed = roundedValueFixedToTwoDigits(amountUnTaxed);
    accountInvoice.amountTotal = roundedValueFixedToTwoDigits(amountTotal);
    accountInvoice.amountTax = roundedValueFixedToTwoDigits(amountTax);
    accountInvoice.accountInvoiceLineDtoList = accountInvoiceLines;
    return accountInvoice;
};

export const getUiObject = (invoice, memoType) => {
    const canReturnStock = !getIsCreditMemo(memoType) && !getIsIssue(memoType);
    if (isObjectValidAndNotEmpty(invoice)) {
        const uiObject = {
            ...invoice,
        };
        const lines = getStringFromObject('accountInvoiceLineDtoList', invoice, []);
        if (isArrayValidAndNotEmpty(lines)) {
            uiObject.stockToReturn = true;
            const name = getStringFromObject('name', lines[0]);
            if ((lines.length === 1 && name.startsWith('in_refund/')) || !canReturnStock) {
                uiObject.stockToReturn = false;
                uiObject.account = getStringFromObject('accountHead', lines[0], null);
                uiObject.creditAmount = NumberOf(invoice.amountUntaxed);
                uiObject.taxAccount = getStringFromObject('taxAccountDetails[0]', lines[0], null);
                uiObject.lineDescription = getStringFromObject('name', lines[0], null);
            } else {
                uiObject.accountInvoiceLines = lines.map((l, index) => {
                    const line = { ...l };
                    line.uuid = l.uuid;
                    const batch = getStringFromObject('stockProdLot', l, null);
                    line.trackByBatch = false;
                    if (isObjectValidAndNotEmpty(batch)) {
                        line.batch = {
                            batch,
                            text: batch.value,
                            value: batch,
                        };
                        line.trackByBatch = true;
                    }
                    line.name = l.name;
                    line.description = l.description;
                    line.uom = l.uom;
                    line.sequence = index;
                    line.product = {
                        uuid: line.productUuid,
                        productName: getStringFromObject('product.value', l),
                    };
                    line.batchName = getStringFromObject('batchName', l, null);
                    line.expiryDate = getStringFromObject('expiryDate', l, null);
                    line.quantity = l.quantity;
                    line.bonus = getStringFromObject('bonus', l, null);
                    line.salePrice = roundedValueFixedToTwoDigits(l.priceUnit);
                    line.discount = roundedValueFixedToTwoDigits(l.discount);
                    line.taxes = l.taxAccountDetails;
                    return setSubTotalAndTotal(line);
                });
            }
        }
        uiObject.state = invoice.state;
        uiObject.uuid = invoice.uuid;
        uiObject.paymentMeans = invoice.paymentMeans;
        uiObject.supplier = {
            uuid: invoice.partnerUuid,
            name: invoice.patientName,
        };
        uiObject.comment = invoice.comment;
        uiObject.type = invoice.type;
        uiObject.stockLocation = {
            uuid: getStringFromObject('stockLocation.key', invoice),
            name: getStringFromObject('stockLocation.value', invoice),
        };
        uiObject.dateInvoice = (invoice.invoiceDateTime);
        return uiObject;
    }
    return {
        ...INVOICE_UI_OBJECT,
        stockToReturn: canReturnStock,
    };
};

