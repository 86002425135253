import initialStates from '../../initialStates';
import {
    CLEAR_FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST,
    FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_FAILURE,
    FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_SUCCESS,
} from './previousPurchaseInvoiceLines-actions';

const previousPurchaseInvoiceLinesReducer = (state = initialStates.previousPurchaseInvoiceLines, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
        };
        break;
    case FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_FAILURE:
        newState = { ...state };
        break;
    case CLEAR_FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST:
        newState = { ...state };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default previousPurchaseInvoiceLinesReducer;
