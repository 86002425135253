import API from '../../../constants/api';

export const CREATE_ADD_STOCK_REQUEST = '@@addStock/CREATE_ADD_STOCK_REQUEST';
export const CREATE_ADD_STOCK_FAILURE = '@@addStock/CREATE_ADD_STOCK_FAILURE';
export const CREATE_ADD_STOCK_SUCCESS = '@@addStock/CREATE_ADD_STOCK_SUCCESS';

export const FETCH_ADD_STOCK_REQUEST = '@@addStock/FETCH_ADD_STOCK_REQUEST';
export const FETCH_ADD_STOCK_FAILURE = '@@addStock/FETCH_ADD_STOCK_FAILURE';
export const FETCH_ADD_STOCK_SUCCESS = '@@addStock/FETCH_ADD_STOCK_SUCCESS';

export const CLEAR_SELECTED_ADD_STOCK_ORDER = '@@addStock/CLEAR_SELECTED_ADD_STOCK_ORDER';

export const createAddStockRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_ADD_STOCK_REQUEST,
    payload,
    api: API.ADD_STOCK.CREATE,
    successCallback,
    failureCallBack,
});
