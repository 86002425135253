import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_JOURNAL_ENTRY_REQUEST,
    FETCH_PREPAID_EXPENSE_REQUEST,
    GENERATE_PERIODIC_LINE_REQUEST,
    SAVE_PREPAID_EXPENSE_REQUEST,
} from '../../redux/modules/prepaidExpense/prepaidExpense-actions';
import { commonGetWorkerSaga, commonPostWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* createPrepaidExpenseSaga() {
    yield takeLatest(SAVE_PREPAID_EXPENSE_REQUEST, commonPostWorkerSaga);
}

export function* generatePeriodicLineSaga() {
    yield takeLatest(GENERATE_PERIODIC_LINE_REQUEST, commonPostWorkerSaga);
}

export function* fetchPeriodicExpenseSaga() {
    yield takeLatest(FETCH_PREPAID_EXPENSE_REQUEST, commonGetWorkerSaga);
}

export function* createPeriodicExpenseJournalEntrySaga() {
    yield takeLatest(CREATE_JOURNAL_ENTRY_REQUEST, commonPostWorkerSaga);
}
