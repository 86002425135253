import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_PAYROLL_DETAILS_REQUEST,
    FETCH_PAYSLIP_DETAILS_REQUEST,
} from '../../redux/modules/employeePayroll/employeeParyoll-actions';
import { fetchPayrollDetailsWorker, fetchPayslipDetailsWorker } from './employeePayrollWorkerSaga';

export function* fetchPayrollDetailsSaga() {
    yield takeLatest(FETCH_PAYROLL_DETAILS_REQUEST, fetchPayrollDetailsWorker);
}

export function* fetchPayslipDetailsSaga() {
    yield takeLatest(FETCH_PAYSLIP_DETAILS_REQUEST, fetchPayslipDetailsWorker);
}
