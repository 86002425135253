import initialStates from '../../initialStates';
import {
    FETCH_PRODUCT_DISPATCH_FAILURE, FETCH_PRODUCT_DISPATCH_SUCCESS,
} from './productDispatch-actions';

export const productDispatchReducer = (state = initialStates.productDispatch, action) => {
    let newState;
    switch (action.type) {
    case FETCH_PRODUCT_DISPATCH_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
        };
        break;
    case FETCH_PRODUCT_DISPATCH_FAILURE:
    default:
        newState = state;
    }
    return newState;
};

export default productDispatchReducer;
