import React, { Suspense } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import { leaveBasePage, leaves, leavesBalances } from '../constants/constants';
import LeavesContainer from '../containers/EmployeeLeavesListContainer/LeavesContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const EmployeeLeavesPage = React.lazy(() => import('../pages/EmployeeLeavesPage/EmployeeLeavesPage'));
const EmployeeLeaveBalancePage = React.lazy(() => import('../pages/EmployeeLeaveBalancePage/EmployeeLeaveBalancePage'));

const LeaveRoutes = props => (
    <LeavesContainer history={props.history} match={props.match}>
        <Suspense fallback={<PageLoader />}>
            <Route path={leaves} exact component={EmployeeLeavesPage} />
            <Route path={leaveBasePage} exact component={EmployeeLeavesPage} />
            <Route path={leavesBalances} exact component={EmployeeLeaveBalancePage} />
        </Suspense>
    </LeavesContainer>
);

LeaveRoutes.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
};

LeaveRoutes.defaultProps = {
    history: {},
};

export default LeaveRoutes;
