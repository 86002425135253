import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import api from '../../constants/api';
import { PAGE, SIZE } from '../../constants/constants';
import MaterialTable from '../../components/MaterialTableV2/MaterialTable';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';
import autoTextListSchema, { autoTextCategoryListSchema, autoTextFilter } from './AutoTextUtil';
import SearchWithFilterAndPaginationOption
    from '../../components/PatientDetailComponent/SearchWithFilterAndPaginationOption';
import { COMBINEMODE, generateObjectWithCriteriaMode, getCombined } from '../../sagas/util';
import ActionButton from '../../components/ActionButton/ActionButton';
import DialogComponent from '../../components/DialogComponent/DialogComponent';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { generateConstructFromFilterColumns } from '../../components/GenericFilterComponent/GenericFilterComponentUtil';
import { getLoggedInProviderUuid } from '../../constants/state';
import { getStringFromObject } from '../../constants/lodashUtils';

const AutoTextForm = React.lazy(() => import('../AutoTextForm/AutoTextForm'));

class AutoTextListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            autoTextData: {},
            searchFilterValue: null,
            filters: null,
            showAddForm: false,
            selectedAutoText: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchAutoTexts();
    }

    onAddNewClick = () => {
        this.setState({
            showAddForm: true,
            selectedAutoText: null,
        });
    };

    onCloseForm = (_, reloadList) => {
        if (reloadList) {
            this.fetchAutoTexts();
        }
        this.setState({
            showAddForm: false,
            selectedAutoText: null,
        });
    };

    onPageChange = (page, size) => {
        this.setState({
            page,
            size,
        }, this.fetchAutoTexts);
    };

    onPerformAction = (value, index) => {
        console.log('asd-asi-d0[asdpkds', value, index);
        const {
            autoTextData,
        } = this.state;
        const {
            isCategory,
        } = this.props;
        const autoText = getStringFromObject(`content[${index}]`, autoTextData, {});
        console.log('asda0[diasid-ada', autoText);
        let enableDisableApi = api.AUTO_TEXTS.ACTIVATE_DEACTIVATE;
        if (isCategory) {
            enableDisableApi = api.AUTO_TEXTS.CATEGORY.ACTIVATE_DEACTIVATE;
        }
        switch (value) {
        case 'edit':
            this.editAutoText(autoText);
            break;
        case 'delete':
            this.deleteOrActivateOrDeactivateAutoText(autoText, api.AUTO_TEXTS.DELETE);
            break;
        case 'enableDisable':
            this.deleteOrActivateOrDeactivateAutoText(autoText, enableDisableApi);
            break;
        default:
            console.log('sad-0');
        }
    };

    editAutoText = (autoText) => {
        this.setState({
            showAddForm: true,
            selectedAutoText: autoText,
        });
    };

    deleteOrActivateOrDeactivateAutoText = async (autoText, apiToCall) => {
        try {
            this.props.dispatch(showSpinner());
            await axios.get(`${apiToCall}?autoTexts=${autoText.uuid}`);
            this.fetchAutoTexts();
            this.props.dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
    };

    applyFilter = (savedFilters, isSearch) => {
        if (isSearch) {
            this.setState({
                searchFilterValue: savedFilters,
            }, this.fetchAutoTexts);
        } else {
            console.log('asd0au9dsjapdoada', savedFilters);
            this.setState({
                filters: savedFilters,
            }, this.fetchAutoTexts);
        }
    };

    fetchAutoTexts = async () => {
        const {
            page,
            size,
            filters,
            searchFilterValue,
        } = this.state;
        const {
            isCategory,
            isProvider,
        } = this.props;
        this.setState({
            loading: true,
        });
        console.log('asdas-0disa-idas', searchFilterValue, filters);
        let searchConstruct = null;
        if (!isCategory) {
            if (isProvider) {
                searchConstruct =
                    generateObjectWithCriteriaMode('provider.uuid', getLoggedInProviderUuid(), '=');
            } else {
                searchConstruct = generateObjectWithCriteriaMode('autoText.provider', null, '<>');
            }
        }
        if (!isCategory) {
            if (searchFilterValue) {
                const searchFilterConstruct = getCombined(
                    generateObjectWithCriteriaMode('autoText.shortForm', searchFilterValue, ':'),
                    COMBINEMODE.OR,
                    generateObjectWithCriteriaMode('autoText.fullText', searchFilterValue, ':'),
                );
                searchConstruct = getCombined(
                    searchConstruct,
                    COMBINEMODE.AND,
                    searchFilterConstruct,
                );
            }
            if (filters && !isCategory) {
                const generatedConstruct = generateConstructFromFilterColumns(autoTextFilter(), filters);
                searchConstruct = getCombined(
                    searchConstruct,
                    COMBINEMODE.AND,
                    generatedConstruct,
                );
            }
        }
        try {
            let response = null;
            if (isCategory) {
                response =
                    await axios.post(
                        // eslint-disable-next-line
                        `${api.AUTO_TEXTS.CATEGORY.GET_ALL}?page=${page}&size=${size}&searchString=${searchFilterValue || ''}`,
                    );
            } else {
                response =
                    await axios.post(`${api.AUTO_TEXTS.GET_ALL}?page=${page}&size=${size}`, searchConstruct);
            }
            console.log('asd-0asid-0id-as', response.data, searchFilterValue, filters);
            this.setState({
                autoTextData: response.data,
                loading: false,
            });
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const {
            autoTextData,
            filters,
            showAddForm,
            selectedAutoText,
            loading,
        } = this.state;
        const {
            dispatch,
            isCategory,
            isProvider,
        } = this.props;
        const autoTextList = getStringFromObject('content', autoTextData) || [];
        console.log('sss', isCategory);
        return (
            <React.Fragment>
                <Grid container spacing={16} className="pt-1">
                    <Grid item sm={6} md={6} lg={6}>
                        <SearchWithFilterAndPaginationOption
                            saveFilter={this.applyFilter}
                            placeholder="Short Form/ Full Text"
                            filterSchema={autoTextFilter()}
                            filterValues={filters}
                            showFilter={!isCategory}
                        />
                    </Grid>
                    <Grid item sm={4} md={4} lg={4} />
                    <Grid item sm={2} md={2} lg={2} className="flexChildren justify-content-right">
                        <ActionButton
                            test-id="autotext-add"
                            onClick={this.onAddNewClick}
                        >
                            Add New
                        </ActionButton>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <MaterialTable
                            schema={isCategory ? autoTextCategoryListSchema : autoTextListSchema}
                            uniqueKey="uuid"
                            totalElements={getStringFromObject('totalElements', autoTextData, 0)}
                            rowsPerPageOptions={[10, 20, 50]}
                            page={getStringFromObject('page', autoTextData, 0)}
                            size={getStringFromObject('size', autoTextData, 10)}
                            data={autoTextList}
                            handleSelectAction={this.onPerformAction}
                            onPageChange={this.onPageChange}
                            retired="active"
                            retiredValue={false}
                            isLoading={loading}
                        />
                    </Grid>
                </Grid>
                {
                    showAddForm &&
                        <DialogComponent
                            open
                            header={isCategory ? 'Add/Edit AutoText Category' : 'Add/Edit AutoText'}
                            handleClose={this.onCloseForm}
                        >
                            <AutoTextForm
                                autoText={selectedAutoText}
                                handleClose={this.onCloseForm}
                                dispatch={dispatch}
                                isCategory={isCategory}
                                isProvider={isProvider}
                            />
                        </DialogComponent>
                }
            </React.Fragment>
        );
    }
}

AutoTextListContainer.propTypes = {
    dispatch: PropTypes.func,
    isCategory: PropTypes.bool,
    // if it is coming from provider settings
    isProvider: PropTypes.bool,
};

AutoTextListContainer.defaultProps = {
    dispatch: () => {},
    isCategory: false,
    isProvider: false,
};

export default connect()(AutoTextListContainer);
