import React from 'react';
import PropTypes from 'prop-types';
import DialogComponent from './DialogComponent/DialogComponent';
import NotificationDialogContents from './NotificationDialogContents';

class NotificationDialog extends React.PureComponent {
    render() {
        const {
            open,
            handleClose,
            fetchNotificationCount,
            ignoreNotificationTypes,
            notificationTypesIn,
            header,
            history,
        } = this.props;
        return (
            <DialogComponent
                open={open}
                fullScreen={false}
                handleClose={handleClose}
                autoDetectWindowHeight
                autoScrollBodyContent
                header={header}
                overflowY="auto"
                scroll="paper"
                titleColor="primary"
                modal
                contentStyle={{
                    width: '90%',
                    height: '90%',
                }}
                maxWidth="lg"
                fullWidth
            >
                <NotificationDialogContents
                    fetchNotificationCount={fetchNotificationCount}
                    ignoreNotificationTypes={ignoreNotificationTypes}
                    notificationTypesIn={notificationTypesIn}
                    history={history}
                    handleClose={handleClose}
                />
            </DialogComponent>
        );
    }
}

NotificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchNotificationCount: PropTypes.func.isRequired,
    ignoreNotificationTypes: PropTypes.array,
    notificationTypesIn: PropTypes.array,
    header: PropTypes.string,
    history: PropTypes.object,
};

NotificationDialog.defaultProps = {
    ignoreNotificationTypes: [],
    notificationTypesIn: [],
    header: 'Notifications',
    history: null,
};

export default NotificationDialog;
