import { TRIGGER_COUNT_FETCH } from './doctorTabCount-actions';

const doctorTabCountReducer = (state = false, action) => {
    let newState;
    console.log('new action', action);
    switch (action.type) {
    case TRIGGER_COUNT_FETCH:
        newState = !state;
        break;
    default:
        newState = state;
    }
    return newState;
};

export default doctorTabCountReducer;
