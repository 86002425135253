import API from '../../../constants/api';

export const SAVE_FORMULARY_LIST_REQUEST = '@@priceList/SAVE_FORMULARY_LIST_REQUEST';
export const SAVE_FORMULARY_LIST_SUCCESS = '@@priceList/SAVE_FORMULARY_LIST_SUCCESS';
export const SAVE_FORMULARY_LIST_FAILURE = '@@priceList/SAVE_FORMULARY_LIST_FAILURE';

export const FETCH_FORMULARY_LIST_REQUEST = '@@priceList/FETCH_FORMULARY_LIST_REQUEST';
export const FETCH_FORMULARY_LIST_SUCCESS = '@@priceList/FETCH_FORMULARY_LIST_SUCCESS';
export const FETCH_FORMULARY_LIST_FAILURE = '@@priceList/FETCH_FORMULARY_LIST_FAILURE';

export const saveFormularyList = (payload, payorUuid, state, applyFrom, successCallback) => ({
    type: SAVE_FORMULARY_LIST_REQUEST,
    api: API.PAYORS.SAVE_FORMULARY_LIST,
    payload,
    payorUuid,
    state,
    applyFrom,
    successCallback,
});

export const fetchFormularyList = (payorUuid, state, successCallback, failureCallback, searchString = '') => ({
    type: FETCH_FORMULARY_LIST_REQUEST,
    api: API.PAYORS.FETCH_FORMULARY_LIST,
    payorUuid,
    state,
    successCallback,
    failureCallback,
    searchString,
});

export const fetchFormularyListByUuid = (payorUuid, state, successCallback, failureCallback, searchString = '', formularyListUuid) => ({
    type: FETCH_FORMULARY_LIST_REQUEST,
    api: API.PAYORS.FETCH_FORMULARY_LIST_BY_UUID,
    payorUuid,
    state,
    successCallback,
    failureCallback,
    searchString,
    formularyListUuid,
});
