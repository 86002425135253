/**
 * Created by vinay on 19/12/18.
 */
import initialStates from '../../initialStates';
import {
    CLEAR_PRODUCT_CATEGORY_STATE, CREATE_PRODUCT_CATEGORY_SUCCESS,
    FETCH_PRODUCT_CATEGORY_LIST_FAILURE,
    FETCH_PRODUCT_CATEGORY_LIST_REQUEST,
    FETCH_PRODUCT_CATEGORY_LIST_SUCCESS,
} from './productCategory-actions';

const productCategoryReducer = (state = initialStates.productCategory, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_PRODUCT_CATEGORY_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_PRODUCT_CATEGORY_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.number,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        });
        break;
    case FETCH_PRODUCT_CATEGORY_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;

    case CLEAR_PRODUCT_CATEGORY_STATE:
        newState = initialStates.productCategory;
        break;
    case CREATE_PRODUCT_CATEGORY_SUCCESS:
        newState = {
            ...state,
            selectedCategory: action.data,
        };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default productCategoryReducer;
