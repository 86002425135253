import React from 'react';
import axios from 'axios';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { change, FieldArray } from 'redux-form';
import CodeTable from './CodeTable';
import API from '../../../constants/api';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { apiCatchBlockFunction } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

class InputCode extends React.PureComponent {
    componentWillReceiveProps(nextProps) {
        const preValues = this.props.formValues;
        const nextValues = nextProps.formValues;
        const { dispatch, formName, name } = this.props;
        if (nextValues.stageOne && preValues.stageOne && nextProps.isEditable) {
            if ((!deepEqual(preValues.stageOne.salaryStructure, nextValues.stageOne.salaryStructure))) {
                const uuid = getStringFromObject('stageOne.salaryStructure.key', nextValues);
                dispatch(showSpinner());
                axios(API.PAYROLL.FETCH_INPUT_CODES, { params: { uuid } })
                    .then((response) => {
                        dispatch(hideSpinner());
                        dispatch(change(formName, name, response.data.map(d => ({
                            code: d.inputCode,
                            description: d.description,
                            uuid: d.uuid,
                        }))));
                    })
                    .catch((error) => {
                        dispatch(hideSpinner());
                        apiCatchBlockFunction(error, dispatch);
                    });
            }
        }
    }

    render() {
        const { name, isEditable } = this.props;
        console.log('asfdsdfdsfdsf', this.props);
        return (
            <React.Fragment>
                <div style={{ width: '800px', margin: '0 auto' }}>
                    <FieldArray
                        name={name}
                        isEditable={isEditable}
                        component={CodeTable}
                    />
                </div>
            </React.Fragment>
        );
    }
}

InputCode.propTypes = {
    name: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

export default InputCode;

