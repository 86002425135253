import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { privilegeSettings, roleSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const PrivilegeSettingsPage = React.lazy(() => import('../pages/UserManagementSettingsPages/PrivilegeSettingsPage'));
const RoleSettingsPage = React.lazy(() => import('../pages/UserManagementSettingsPages/RoleSettingsPage'));

const UserManagementSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={privilegeSettings} exact component={PrivilegeSettingsPage} />
            <Route path={roleSettings} exact component={RoleSettingsPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default UserManagementSettingRoutes;
