/**
 * Created by vinay on 19/12/18.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import message from '../../constants/messages';
import {
    CREATE_TEMPLATE_FAILURE,
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    DELETE_TEMPLATE_SUCCESS,
    FETCH_SLOT_TEMPLATE_FAILURE,
    FETCH_SLOT_TEMPLATE_LIST_FAILURE,
    FETCH_SLOT_TEMPLATE_LIST_SUCCESS,
    FETCH_SLOT_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from '../../redux/modules/appointmentSlotTemplate/appointmentSlotTemplate-actions';

export function* fetchTemplateWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_SLOT_TEMPLATE_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.SLOT_TEMPLATE.FETCH_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_SLOT_TEMPLATE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchTemplateListWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_SLOT_TEMPLATE_LIST_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.SLOT_TEMPLATE.LIST_FETCH_FAILED} : ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_SLOT_TEMPLATE_LIST_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* deleteTemplateWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        yield call(retriable().delete, action.api);
        yield put({ type: DELETE_TEMPLATE_SUCCESS });
        yield put({ type: SUCCESS_MESSAGE, message: message.SLOT_TEMPLATE.DELETE.SUCCESS });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback();
        }
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${message.SLOT_TEMPLATE.DELETE.FAILED} : ${getErrorMessage(e)}`,
            });
        }
        if (isValidFunction(action.onFailureCallback)) {
            action.onFailureCallback();
        }
        yield put({ type: DELETE_TEMPLATE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* createOrUpdateTemplateWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        let response = null;
        let successMessage = '';
        let successActionType = '';
        if (action.type === CREATE_TEMPLATE_REQUEST) {
            response = yield call(retriable().post, action.api, action.payload);
            successMessage = message.SLOT_TEMPLATE.CREATE.SUCCESS;
            successActionType = CREATE_TEMPLATE_SUCCESS;
        } else {
            response = yield call(retriable().put, action.api, action.payload);
            successMessage = message.SLOT_TEMPLATE.UPDATE.SUCCESS;
            successActionType = UPDATE_TEMPLATE_SUCCESS;
        }
        yield put({
            type: successActionType,
            data: response.data,
        });
        if (isValidFunction(action.onSuccessCallback)) {
            action.onSuccessCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: successMessage });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        let failedAction = '';
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let errorMessage = '';
            if (action.type === CREATE_TEMPLATE_REQUEST) {
                failedAction = CREATE_TEMPLATE_FAILURE;
                errorMessage = message.SLOT_TEMPLATE.CREATE.FAILED;
            } else {
                failedAction = UPDATE_TEMPLATE_FAILURE;
                errorMessage = message.SLOT_TEMPLATE.UPDATE.FAILED;
            }
            yield put({
                type: ERROR_MESSAGE,
                message: `${errorMessage} : ${getErrorMessage(e)}`,
            });
        }
        if (isValidFunction(action.onFailureCallback)) {
            action.onFailureCallback();
        }
        yield put({ type: failedAction });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
