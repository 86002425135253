import React from 'react';
import axios from 'axios';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { FieldArray, change } from 'redux-form';
import MaterialTable from './MaterialTable';
import { isArrayValidAndNotEmpty } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

class ReduxFormMaterialTable extends React.PureComponent {
    componentDidMount() {
        const {
            dataSourceApi,
        } = this.props;
        if (dataSourceApi) {
            axios.get(dataSourceApi)
                .then((response) => {
                    this.mapAndChangeData(response.data);
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.dataSourceApi, this.props.dataSourceApi)) {
            axios.get(nextProps.dataSourceApi)
                .then((response) => {
                    this.mapAndChangeData(response.data);
                });
        }
    }

    mapAndChangeData = (data) => {
        const {
            dispatch, formName, tableCells, fieldName,
        } = this.props;
        if (isArrayValidAndNotEmpty(data)) {
            const refactoredData = [];
            data.map((d) => {
                const obj = {};
                tableCells.map((t) => {
                    obj[t.name] = getStringFromObject(t.find, d, '-');
                    return null;
                });
                refactoredData.push(obj);
                return null;
            });
            dispatch(change(formName, fieldName, refactoredData));
        }
    };

    render() {
        const { fieldName, styles, ...tableProps } = this.props;
        return (
            <div style={{ width: '100%', ...styles }}>
                <FieldArray
                    {...tableProps}
                    name={fieldName}
                    test-id={fieldName}
                    component={MaterialTable}
                />
            </div>
        );
    }
}

ReduxFormMaterialTable.propTypes = {
    fieldName: PropTypes.string.isRequired,
    styles: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    dataSourceApi: PropTypes.string,
    formName: PropTypes.string.isRequired,
    tableCells: PropTypes.array.isRequired,
    lastUpdated: PropTypes.instanceOf(Date),
    retired: PropTypes.string,
    retiredValue: PropTypes.bool,
    forceEnableCheckBox: PropTypes.bool,
};

ReduxFormMaterialTable.defaultProps = {
    styles: {},
    dataSourceApi: null,
    lastUpdated: null,
    retired: null,
    retiredValue: null,
    forceEnableCheckBox: false,
};
export default ReduxFormMaterialTable;
