export const SET_REGISTRATION_SHOP = '@@appConfig/SET_REGISTRATION_SHOP';
export const SET_NURSE_SHOP = '@@appConfig/SET_NURSE_SHOP';
export const TOGGLE_REGISTRATION_SHOP_SELECT = '@@appConfig/TOGGLE_REGISTRATION_SHOP_SELECT';

export const setRegistrationShop = (shop, closeDialog) => ({
    type: SET_REGISTRATION_SHOP,
    shop,
    closeDialog,
});
export const setNurseShop = (shop, closeDialog) => ({
    type: SET_NURSE_SHOP,
    shop,
    closeDialog,
});
export const toggleRegistrationShopSelect = () => ({
    type: TOGGLE_REGISTRATION_SHOP_SELECT,
});
