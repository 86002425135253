import { isArrayValidAndNotEmpty, roundedValue } from '../../../../constants/CommonUtil';
import { NumberOf } from '../../../../constants/numberUtils';

export const getTaxAmount = (taxObject, price) => {
    console.log('123001099001298jkjkjaoosauuiww', taxObject, price);
    return roundedValue((NumberOf(taxObject.percent) * NumberOf(price)) / 100);
};

export const calculateAmountAndTotal = (billingRow = {}) => {
    /*
    *  copay percentage is applied on the total price of the product..
    *  the tax is on the total amount
    *  total bill amount is copay amt + tax on copay amt
    *  if it is a cash patient, we will set copay to be 100%
    * */
    console.log('78yhuinjksad', billingRow);
    // this is in percentage
    const discount = NumberOf(billingRow.discountPercentage);
    const amountPerItem = NumberOf(billingRow.price) * (1 - (discount / 100));
    // total line amount
    const amount = amountPerItem * NumberOf(billingRow.quantity);
    const discountAmount = (discount * (NumberOf(billingRow.price) * NumberOf(billingRow.quantity))) / 100;
    console.log('asdas-0disa-0dias-da', discount, amount, discountAmount);
    // total tax on copay amt
    let totalTax = 0;

    // total tax on invoice amt
    let totalInvoiceLineTax = 0;

    // total tax percent
    let totalTaxPercent = 0;

    // this will be set based on insurance card / will be 100% if cash
    const coPayAmount = NumberOf(billingRow.coPayAmount);
    const coPayPercentage = roundedValue((coPayAmount / amount) * 100);

    if (isArrayValidAndNotEmpty(billingRow.taxes)) {
        // tax is calculated on copay amount
        billingRow.taxes.map((aTax) => {
            console.log('sadas-0dua9s0udsa9d', getTaxAmount(aTax, coPayAmount));
            totalTax += getTaxAmount(aTax, coPayAmount);
            totalInvoiceLineTax += getTaxAmount(aTax, amount);
            totalTaxPercent += aTax.percent;
            return null;
        });
    }
    console.log('asdp0a9uda0duad', billingRow, totalTax);
    // total on copay
    const total = coPayAmount + totalTax;
    // total on line without copay
    const invoiceLineTotal = amount + totalInvoiceLineTax;
    return {
        amount,
        totalTax,
        totalTaxPercent,
        discountAmount,
        totalInvoiceTax: totalInvoiceLineTax,
        coPayAmount,
        coPayPercentage,
        total: roundedValue(total),
        invoiceLineTotal: roundedValue(invoiceLineTotal),
    };
};
export const calculateGrandTotal = (formValues) => {
    let totalValue = 0;
    formValues.map((item) => {
        if (item.productDetails && item.productDetails.length !== 0) {
            totalValue += item.total;
        }
        return null;
    });
    return roundedValue(totalValue);
};

export const calculateBillTotal = (formValues, currentBillValue) => {
    const billValue = { ...currentBillValue };
    billValue.grandTotal = calculateGrandTotal(formValues);
    billValue.netAmount = calculateGrandTotal(formValues);
    billValue.amountDue = billValue.grandTotal;
    billValue.cashReceived = 0;
    billValue.changeToRender = 0;
    billValue.discountInPercentage = false;
    return billValue;
};

// called when cash received has changed
export const calculateAmountDueAndChangeToTender = (cashReceived, currentBillValues) => {
    const newBillValues = { ...currentBillValues };
    newBillValues.cashReceived = roundedValue(NumberOf(cashReceived));
    if (newBillValues.cashReceived < newBillValues.grandTotal) {
        newBillValues.amountDue = roundedValue(newBillValues.grandTotal - newBillValues.cashReceived);
        newBillValues.changeToRender = 0;
    } else {
        newBillValues.changeToRender = roundedValue(newBillValues.cashReceived - newBillValues.grandTotal);
        newBillValues.amountDue = 0;
    }
    return newBillValues;
};

// called when discount amount has changed
export const calculateGrandTotalOnDiscountChange = (discountAmount, currentBillValues) => {
    const newBillValues = { ...currentBillValues };
    newBillValues.discountAmount = roundedValue(NumberOf(discountAmount));
    if (currentBillValues.discountInPercentage) {
        if (newBillValues.discountAmount <= 100) {
            const discountedAmountOnApplyingDiscountPercentage =
                (NumberOf(newBillValues.netAmount) * NumberOf(newBillValues.discountAmount)) / 100;
            newBillValues.grandTotal =
                roundedValue(newBillValues.netAmount - discountedAmountOnApplyingDiscountPercentage);
        } else {
            newBillValues.discountAmount = 100;
            newBillValues.grandTotal = 0;
        }
    } else if (newBillValues.discountAmount <= newBillValues.netAmount) {
        newBillValues.grandTotal =
            roundedValue(NumberOf(newBillValues.netAmount) - NumberOf(newBillValues.discountAmount));
    } else {
        newBillValues.discountAmount = NumberOf(newBillValues.netAmount);
        newBillValues.grandTotal = 0;
    }
    return newBillValues;
};

export const calculateBillValues = (cashReceived, discountAmount, currentBillValues, recalculateAllValues) => {
    let billValue = { ...currentBillValues };
    if (recalculateAllValues) {
        billValue = calculateGrandTotalOnDiscountChange(discountAmount, billValue);
        billValue = calculateAmountDueAndChangeToTender(cashReceived, billValue);
    }
    if (billValue.discountAmount !== roundedValue(NumberOf(discountAmount))) {
        console.log('12312sa1223dfasd', discountAmount, billValue.discountAmount);
        billValue = calculateGrandTotalOnDiscountChange(discountAmount, billValue);
        billValue = calculateAmountDueAndChangeToTender(cashReceived, billValue);
    }
    if (billValue.cashReceived !== roundedValue(NumberOf(cashReceived))) { // cash received has changed
        billValue = calculateAmountDueAndChangeToTender(cashReceived, billValue);
    }
    return billValue;
};

export const getBillDetailsToPrint = (billings) => {
    if (isArrayValidAndNotEmpty(billings)) {
        let billableProducts = [];
        let totalAmountWithoutTax = 0;
        let totalAmountWithTax = 0;
        let totalTax = 0;
        const totalTaxByName = {};
        console.log('9oa8sd09u09psodakdl;sa', billings);
        billings.map((aBillingItem) => {
            console.log('as-0isdadaasd0id-a0idadas', aBillingItem, billableProducts);
            if (aBillingItem.id) {
                let totalLineTax = 0;
                const lineTotalWithoutTax = NumberOf(aBillingItem.quantity) * NumberOf(aBillingItem.price);
                totalAmountWithoutTax += lineTotalWithoutTax;

                if (isArrayValidAndNotEmpty(aBillingItem.taxes)) {
                    aBillingItem.taxes.map((aTax) => {
                        const taxAmount = (NumberOf(aTax.percent) * lineTotalWithoutTax) / 100;
                        totalLineTax += taxAmount;

                        if (!totalTaxByName[aTax.name]) {
                            totalTaxByName[aTax.name] = 0;
                        }
                        totalTaxByName[aTax.name] += taxAmount;
                        return null;
                    });
                    totalTax += totalLineTax;
                }
                const lineTotalWithTax = lineTotalWithoutTax + totalLineTax;
                totalAmountWithTax += lineTotalWithTax;
                billableProducts = billableProducts.concat({
                    ...aBillingItem,
                    lineTotalWithTax,
                    lineTotalWithoutTax,
                    totalLineTax,
                });
            }
            return null;
        });

        return {
            totalAmountWithoutTax,
            totalAmountWithTax,
            totalTax,
            totalTaxByName,
            billableProducts,
        };
    }
    return {};
};


export default getTaxAmount;
