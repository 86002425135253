import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { findValueByChoices } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
});

class ReduxFormRadio extends React.PureComponent {
    handleChange = (event) => {
        const { input } = this.props;
        input.onChange(event.target.value);
    };

    render() {
        const {
            buttonInNewLine,
            classes,
            label,
            dataSourceConfig,
            containerStyle,
            disabled,
            input,
            labelPlacement,
            direction,
            ...otherProps
        } = this.props;
        console.log('sadfsdf', this.props);
        let { value } = input;
        const { choices, labelstyle } = otherProps;
        if (disabled && choices && value) {
            value = findValueByChoices(choices, dataSourceConfig, value);
        }
        if (typeof value === 'object') {
            value = value.value || '-';
        }
        const minWidth = `${choices.length * 5.5}em`;
        return (
            <div className={!buttonInNewLine ? classes.container : ''} style={containerStyle}>
                <div className={classes.labelDiv} style={labelstyle}>
                    {label}
                </div>
                <div className={classes.fieldDiv} style={{ minWidth }}>
                    {
                        (!disabled) &&
                            <RadioGroup
                                style={{ flexDirection: direction || 'row' }}
                                value={input.value}
                                onChange={this.handleChange}
                            >
                                {
                                    choices.map(choice => (
                                        <FormControlLabel
                                            value={getStringFromObject(dataSourceConfig.value, choice, choice)}
                                            key={getStringFromObject(dataSourceConfig.value, choice, choice)}
                                            control={<Radio color="primary" />}
                                            label={getStringFromObject(dataSourceConfig.text, choice, choice)}
                                            labelPlacement={labelPlacement || 'start'}
                                        />
                                    ))
                                }
                            </RadioGroup>
                    }
                    {
                        disabled &&
                        <div style={{ margin: '-8px 0 0 0' }}>
                            {value}
                        </div>
                    }

                </div>
            </div>
        );
    }
}

ReduxFormRadio.propTypes = {
    buttonInNewLine: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    containerStyle: PropTypes.object,
    dataSourceConfig: PropTypes.object,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    direction: PropTypes.string,
};

ReduxFormRadio.defaultProps = {
    buttonInNewLine: false,
    disabled: false,
    labelPlacement: 'start',
    direction: 'row',
    containerStyle: {},
    dataSourceConfig: {},
};

export default withStyles(styles)(ReduxFormRadio);
