import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import OutlinedTextField from '../../../../components/OutlinedTextField';

const ReduxFormSelectField = (props) => {
    const {
        input,
        label,
        addRef,
        validations,
        menuItems,
    } = props;
    const fieldName = input.name;
    return (
        <FormControl style={{ width: '100%' }}>
            <OutlinedTextField
                {...props}
                select
                inputRef={(inputRef) => {
                    if (addRef && typeof addRef === 'function' && validations && validations.required) {
                        addRef(inputRef, fieldName);
                    }
                }}
                name={fieldName}
                label={label}
            >
                {menuItems}
            </OutlinedTextField>
        </FormControl>
    );
};

ReduxFormSelectField.propTypes = {
    input: PropTypes.object,
    menuItems: PropTypes.node,
    label: PropTypes.any,
    addRef: PropTypes.func,
    validations: PropTypes.any,
};

ReduxFormSelectField.defaultProps = {
    input: {},
    menuItems: <div />,
    label: null,
    addRef: () => {},
    validations: {},
};

export default ReduxFormSelectField;

