import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import {
    allergyReactionSettings,
    allergySeveritySettings,
    dosageFormSettings,
    dosageInstructionSettings,
    dosageUnitSettings,
    extendedGenericAttribute,
    warningsSettings,
} from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';

const dosageFormSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/DosageFormSettingsPage'));
const dosageInstructionSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/DosageInstructionsSettingsPage'));
const dosageUnitsSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/DosageUnitsSettingsPage'));
const allergyReactionSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/AllergyReactionSettingsPage'));
const allergySeveritySettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/AllergySeveritySettingsPage'));
const warningsSettingsPage = React.lazy(() => import('../pages/MedicationSettingsPages/WarningsSettingsPage'));

const ExtendedGenericAttributePage = React.lazy(() => import('../pages/ExtendedGenericAttributePage/ExtendedGenericAttributePage'));

const MedicationSetting2Routes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={dosageFormSettings} exact component={dosageFormSettingsPage} />
            <Route path={dosageUnitSettings} exact component={dosageUnitsSettingsPage} />
            <Route path={dosageInstructionSettings} exact component={dosageInstructionSettingsPage} />
            <Route path={allergyReactionSettings} exact component={allergyReactionSettingsPage} />
            <Route path={allergySeveritySettings} exact component={allergySeveritySettingsPage} />
            <Route path={warningsSettings} exact component={warningsSettingsPage} />
            <Route path={extendedGenericAttribute} exact component={ExtendedGenericAttributePage} />
        </Suspense>
    </GenericSettingContainer>
);

export default MedicationSetting2Routes;
