import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { isArrayValidAndNotEmpty } from './CommonUtil';
import checkIfUserHasRequiredPrivilege from './privilegeChecker';
import { findBestMatch } from './StringSimilarity';
import './Sidebar.css';
import { getStringFromObject } from './lodashUtils';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

const listStyle = {
    height: '4em',
    borderBottom: '1px solid #0000001f',
};

const activeListStyle = {
    height: '4em',
    borderBottom: '1px solid #0000001f',
    borderRight: '0.4rem solid #0EA8FF',
    backgroundColor: '#F2F5FF',
};

const getBestMatchRoute = (sideBarTabs, activeTab, disablePartialRatioRouteMatching) => {
    let values = Object.values(sideBarTabs);
    // console.log('here it came');
    if (isArrayValidAndNotEmpty(values)) {
        values = values.map(v => v.value);
        const bestRoute = findBestMatch(activeTab, values, disablePartialRatioRouteMatching);
        // console.log('TabSelected', activeTab, bestRoute);
        return getStringFromObject('bestMatch.target', bestRoute, '');
    }
    return '';
};

const SideBar = (props) => {
    const { onListItemClick, sideBarTabs, disablePartialRatioRouteMatching } = props;
    const activeRoute = getBestMatchRoute(sideBarTabs, props.activeTab, disablePartialRatioRouteMatching);
    // console.log('rupam', activeRoute, props.activeTab);
    // console.log('rupam console onlistitemClick', onListItemClick);
    return (
        <div>
            {
                isObjectValidAndNotEmpty(sideBarTabs) &&
                isArrayValidAndNotEmpty(Object.values(sideBarTabs)) &&
                Object.values(sideBarTabs).map(item => (
                    checkIfUserHasRequiredPrivilege(sideBarTabs, item.value, disablePartialRatioRouteMatching) &&
                    <ListItem
                        key={item.label}
                        test-id={`nuacare-sidebar-${item.value}`}
                        button
                        onClick={() => onListItemClick(item.value)}
                        style={activeRoute === item.value ? { ...activeListStyle } : { ...listStyle }}
                    >
                        <ListItemIcon>
                            <img
                                src={`${import.meta.env.BASE_URL}/sidebar-icons/${item.url}`}
                                alt=""
                                className="image-size-small"
                            />
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))
            }
        </div>
    );
};

SideBar.propTypes = {
    onListItemClick: PropTypes.func,
    activeTab: PropTypes.string,
    sideBarTabs: PropTypes.object,
    disablePartialRatioRouteMatching: PropTypes.bool,
};

SideBar.defaultProps = {
    onListItemClick: () => {},
    activeTab: '',
    sideBarTabs: {},
    disablePartialRatioRouteMatching: false,
};

export default SideBar;

