import API from '../../../constants/api';
import { getUrlWithApiParams } from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';

export const FETCH_SUPPLIER_LIST_REQUEST = '@@supplier/FETCH_SUPPLIER_LIST_REQUEST';
export const FETCH_SUPPLIER_LIST_FAILURE = '@@supplier/FETCH_SUPPLIER_LIST_FAILURE';
export const FETCH_SUPPLIER_LIST_SUCCESS = '@@supplier/FETCH_SUPPLIER_LIST_SUCCESS';

export const CREATE_SUPPLIER_REQUEST = '@@supplier/CREATE_SUPPLIER_REQUEST';
export const CREATE_SUPPLIER_FAILURE = '@@supplier/CREATE_SUPPLIER_FAILURE';
export const CREATE_SUPPLIER_SUCCESS = '@@supplier/CREATE_SUPPLIER_SUCCESS';

export const CLEAR_SUPPLIER_STATE = '@@supplier/CLEAR_SUPPLIER_STATE';

export const SAVE_SELECTED_SUPPLIER = '@@supplier/SAVE_SELECTED_SUPPLIER';

export const CLEAR_SELECTED_SUPPLIER = '@@supplier/CLEAR_SELECTED_SUPPLIER';

export const fetchPagesOfSupplier = (page, size, filters) => ({
    type: FETCH_SUPPLIER_LIST_REQUEST,
    api: getUrlWithApiParams(API.SUPPLIER.PAGES, {
        page,
        size,
        supplier: true,
        searchString: getStringFromObject('searchText', filters),
    }),
    page,
    size,
    filters,
});

export const fetchPagesOfSupplierAndSearch = (page, size, filters, searchString) => ({
    type: FETCH_SUPPLIER_LIST_REQUEST,
    api: `${API.SUPPLIER.PAGES}?page=${page}&size=${size}&supplier=${true}&searchString=${searchString}`,
    page,
    size,
    filters,
});


export const createSupplierRequest = (payload, successCallback, failureCallBack) => ({
    type: CREATE_SUPPLIER_REQUEST,
    api: API.SUPPLIER.CREATE,
    payload,
    successCallback,
    failureCallBack,
});

export const clearSupplierState = () => ({
    type: CLEAR_SUPPLIER_STATE,
});

export const saveSelectedSupplier = payload => ({
    type: SAVE_SELECTED_SUPPLIER,
    payload,
});

export const clearSelectedSupplier = () => ({
    type: CLEAR_SELECTED_SUPPLIER,
});
