/**
 * Created by sachinprakash on 9/7/19.
 */
import { PAGE, SIZE } from '../../../constants/constants';
import API from '../../../constants/api';

export const FETCH_TESTS_REQUEST = '@@Tests/FETCH_TESTS_REQUEST';
export const FETCH_TESTS_SUCCESS = '@@Tests/FETCH_TESTS_SUCCESS';
export const FETCH_TESTS_FAILURE = '@@Tests/FETCH_TESTS_FAILURE';

export const FETCH_UOM_REQUEST = '@@Uom/FETCH_UOM_REQUEST';
export const FETCH_UOM_SUCCESS = '@@Uom/FETCH_UOM_SUCCESS';
export const FETCH_UOM_FAILURE = '@@Uom/FETCH_UOM_FAILURE';

export const FETCH_PANELS_REQUEST = '@@Panels/FETCH_PANELS_REQUEST';
export const FETCH_PANELS_SUCCESS = '@@Panels/FETCH_PANELS_SUCCESS';
export const FETCH_PANELS_FAILURE = '@@Panels/FETCH_PANELS_FAILURE';

export const FETCH_DEPARTMENT_REQUEST = '@@Department/FETCH_DEPARTMENT_REQUEST';
export const FETCH_DEPARTMENT_SUCCESS = '@@Department/FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENT_FAILURE = '@@Department/FETCH_DEPARTMENT_FAILURE';

export const FETCH_DICTIONARY_REQUEST = '@@Dictionary/FETCH_DICTIONARY_REQUEST';
export const FETCH_DICTIONARY_SUCCESS = '@@Dictionary/FETCH_DICTIONARY_SUCCESS';
export const FETCH_DICTIONARY_FAILURE = '@@Dictionary/FETCH_DICTIONARY_FAILURE';

export const FETCH_SAMPLE_REQUEST = '@@Sample/FETCH_SAMPLE_REQUEST';
export const FETCH_SAMPLE_SUCCESS = '@@Sample/FETCH_SAMPLE_SUCCESS';
export const FETCH_SAMPLE_FAILURE = '@@Sample/FETCH_SAMPLE_FAILURE';

export const ADD_OR_UPDATE_TESTS_REQUEST = '@@Tests/ADD_OR_UPDATE_TESTS_REQUEST';
export const ADD_OR_UPDATE_TESTS_SUCCESS = '@@Tests/ADD_OR_UPDATE_TESTS_SUCCESS';
export const ADD_OR_UPDATE_TESTS_FAILURE = '@@Tests/ADD_OR_UPDATE_TESTS_FAILURE';

export const ADD_OR_UPDATE_DICTIONARY_REQUEST = '@@Dictionary/ADD_OR_UPDATE_DICTIONARY_REQUEST';
export const ADD_OR_UPDATE_DICTIONARY_SUCCESS = '@@Dictionary/ADD_OR_UPDATE_DICTIONARY_SUCCESS';
export const ADD_OR_UPDATE_DICTIONARY_FAILURE = '@@Dictionary/ADD_OR_UPDATE_DICTIONARY_FAILURE';

export const ADD_OR_UPDATE_PANEL_REQUEST = '@@Panel/ADD_OR_UPDATE_PANEL_REQUEST';
export const ADD_OR_UPDATE_PANEL_SUCCESS = '@@Panel/ADD_OR_UPDATE_PANEL_SUCCESS';
export const ADD_OR_UPDATE_PANEL_FAILURE = '@@Panel/ADD_OR_UPDATE_PANEL_FAILURE';

export const ADD_OR_UPDATE_SAMPLE_REQUEST = '@@Sample/ADD_OR_UPDATE_SAMPLE_REQUEST';
export const ADD_OR_UPDATE_SAMPLE_SUCCESS = '@@Sample/ADD_OR_UPDATE_SAMPLE_SUCCESS';
export const ADD_OR_UPDATE_SAMPLE_FAILURE = '@@Sample/ADD_OR_UPDATE_SAMPLE_FAILURE';

export const ADD_OR_UPDATE_UOM_REQUEST = '@@Uom/ADD_OR_UPDATE_UOM_REQUEST';
export const ADD_OR_UPDATE_UOM_SUCCESS = '@@Uom/ADD_OR_UPDATE_UOM_SUCCESS';
export const ADD_OR_UPDATE_UOM_FAILURE = '@@Uom/ADD_OR_UPDATE_UOM_FAILURE';

export const ADD_OR_UPDATE_DEPARTMENT_REQUEST = '@@Department/ADD_OR_UPDATE_DEPARTMENT_REQUEST';
export const ADD_OR_UPDATE_DEPARTMENT_SUCCESS = '@@Department/ADD_OR_UPDATE_DEPARTMENT_SUCCESS';
export const ADD_OR_UPDATE_DEPARTMENT_FAILURE = '@@Department/ADD_OR_UPDATE_DEPARTMENT_FAILURE';

export const ADD_OR_UPDATE_TEMPLATE_REQUEST = '@@Template/ADD_OR_UPDATE_TEMPLATE_REQUEST';
export const ADD_OR_UPDATE_TEMPLATE_SUCCESS = '@@Template/ADD_OR_UPDATE_TEMPLATE_SUCCESS';
export const ADD_OR_UPDATE_TEMPLATE_FAILURE = '@@Template/ADD_OR_UPDATE_TEMPLATE_FAILURE';

export const fetchTests
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.TESTS}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_TESTS_REQUEST,
    });


export const addOrUpdateTests = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_TESTS_REQUEST,
    api: API.LAB.TESTS,
    payload,
    successCallBack,
});


export const fetchPanels
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.PANELS}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_PANELS_REQUEST,
    });


export const fetchDepartment
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.DEPARTMENT}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_DEPARTMENT_REQUEST,
    });

export const fetchSample
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.SAMPLE}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_SAMPLE_REQUEST,
    });

export const fetchDictionary
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.DICTIONARY}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_DICTIONARY_REQUEST,
    });


export const fetchUom
    =
    (page = PAGE, size = SIZE, sortCol = 'id', sortOrder = 'DESC', filters = { active: true, locale: 'en' }) => ({
        api: `${API.LAB.UOM}?filter=${
            encodeURIComponent(JSON.stringify(filters))
        }&page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
        type: FETCH_UOM_REQUEST,
    });

export const addOrUpdateUom = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_UOM_REQUEST,
    api: API.LAB.UOM,
    payload,
    successCallBack,
});

export const addOrUpdatedepartment = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_DEPARTMENT_REQUEST,
    api: API.LAB.DEPARTMENT,
    payload,
    successCallBack,
});


export const addOrUpdatePanel = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_PANEL_REQUEST,
    api: API.LAB.PANELS,
    payload,
    successCallBack,
});


export const addOrUpdateSample = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_SAMPLE_REQUEST,
    api: API.LAB.SAMPLE,
    payload,
    successCallBack,
});


export const addOrUpdateDictionary = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_DICTIONARY_REQUEST,
    api: API.LAB.DICTIONARY,
    payload,
    successCallBack,
});

export const addOrUpdateTemplate = (payload, successCallBack) => ({
    type: ADD_OR_UPDATE_TEMPLATE_REQUEST,
    api: API.LAB.ADD_OR_UPDATE_TEMPLATE,
    payload,
    successCallBack,
});
