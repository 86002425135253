/* eslint-disable max-len */
import API from '../../../constants/api';

export const FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST = '@@previousPurchaseInvoiceLines/FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST';
export const FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_SUCCESS = '@@previousPurchaseInvoiceLines/FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_SUCCESS';
export const FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_FAILURE = '@@previousPurchaseInvoiceLines/FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_FAILURE';

export const CLEAR_FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST = '@@previousPurchaseInvoiceLines/CLEAR_FETCH_PREVIOUS_PURCHASE_INVOICE_LINES';

export const fetchPreviousPurchaseInvoiceLines = (page, size, searchConstruct) => ({
    type: FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST,
    api: API.ACCOUNT_INVOICE.PREVIOUS_PURCHASE_INVOICE_LINES,
    page,
    size,
    searchConstruct,
});

export const clearPreviousPurchaseInvoiceLines = () => ({
    type: CLEAR_FETCH_PREVIOUS_PURCHASE_INVOICE_LINES_REQUEST,
});
