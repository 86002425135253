import { takeLatest } from 'redux-saga/effects';

import {
    AVAILED_LEAVES_FETCH_REQUEST,
    LEAVE_BALANCE_FETCH_REQUEST,
    LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST,
} from '../../redux/modules/leaves/leave-actions';
import {
    availedLeavesWorkerSaga,
    fetchLeavePlansForEmployeeWorkerSaga,
    leaveBalanceWorkerSaga,
} from './leavesWorkerSaga';


export function* availedLeaveFetchSaga() {
    yield takeLatest(AVAILED_LEAVES_FETCH_REQUEST, availedLeavesWorkerSaga);
}

export function* leaveBalanceFetchSaga() {
    yield takeLatest(LEAVE_BALANCE_FETCH_REQUEST, leaveBalanceWorkerSaga);
}

export function* fetchLeavePlansForEmployeeSaga() {
    yield takeLatest(LEAVE_PLANS_FOR_EMPLOYEE_FETCH_REQUEST, fetchLeavePlansForEmployeeWorkerSaga);
}
