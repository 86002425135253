
import { getProfileImageUrl } from '../../constants/CommonUtil';
import API from '../../constants/api';
import { formatAMPM, getDateInDDMMMYYYYFormat, getFullAgeString } from '../../constants/DateUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const mapEncounterDto = (t) => {
    let profileImage = getProfileImageUrl({
        birthDate: getStringFromObject('dob', t, new Date().getTime()),
        gender: getStringFromObject('gender', t, 'M'),
    });
    if (t.imageName) {
        profileImage = `${API.FILE.DOWNLOAD}${t.imageName}`;
    }
    return {
        ...t,
        timeSlot: formatAMPM(new Date(getStringFromObject('tokenDetails.slotTime', t))),
        time: formatAMPM(new Date(getStringFromObject('tokenDetails.slotTime', t))),
        tokenNumber: getStringFromObject('tokenDetails.tokenNumber', t),
        patientName: getStringFromObject('patient.value', t, '-'),
        patientIdentifier: getStringFromObject('patientIdentifier', t, '-'),
        consultedAt:
            `${getDateInDDMMMYYYYFormat(new Date(t.consultationDate))} ${formatAMPM(new Date(t.consultationDate))}`,
        age: getFullAgeString(new Date(t.dob)),
        gender: t.gender,
        dob: getDateInDDMMMYYYYFormat(new Date(t.dob)),
        payer: t.payerType,
        patientUuid: getStringFromObject('patient.key', t, '-'),
        profileImage,
    };
};

export const recentQueueSchema = (schema, encounterStatusBitHelper) => {
    const processedSchema = { ...schema };
    if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
        processedSchema.tableCells = processedSchema.tableCells.concat({
            id: 'consultationStatus',
            name: 'consultationStatus',
            find: 'consultationStatus',
            label: 'Status',
            fieldType: 'encounterStatus',
            cellStyle: {
                width: '15%',
            },
            divStyle: {
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%',
            },
        });
    }
    return processedSchema;
};

export default mapEncounterDto;
