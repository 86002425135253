import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_AGING_REPORT_REQUEST,
    FETCH_PAYOR_INVOICES_REQUEST,
} from '../../redux/modules/agingReport/agingReport-actions';
import { fetchAgingReportWorkerSaga, fetchInvoicesForPayorWorker } from './agingReportWorkerSaga';

export function* fetchAgingReport() {
    yield takeLatest(FETCH_AGING_REPORT_REQUEST, fetchAgingReportWorkerSaga);
}

export function* fetchInvoicesForPayor() {
    yield takeLatest(FETCH_PAYOR_INVOICES_REQUEST, fetchInvoicesForPayorWorker);
}

