import { call, put } from 'redux-saga/effects';
import cloneDeep from 'clone-deep';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidNumber,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    FETCH_INSURANCE_REQUEST_LINES_SUCCESS, FETCH_INSURANCE_REQUESTS_SUCCESS,
} from '../../redux/modules/insuranceRequestsList/insuranceRequestsList-actions';
import {
    generateConstructFromFilterColumns,
    generateSearchFilterConstruct,
} from '../../components/GenericFilterComponent/GenericFilterComponentUtil';
import {
    insuranceRequestLineFilter, insuranceRequestLineFilters, insuranceRequestsFilter, insuranceRequestsFilters,
} from '../../containers/InsuranceRequestListContainer/InsuranceRequestListContainerUtil';
import { COMBINEMODE, generateObjectWithCriteriaMode, getCombined } from '../util';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* fetchInsuranceRequestLinesWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const { payload } = action;
        const {
            page,
            size,
            api,
            filters,
            searchFilter,
        } = payload;
        const clonedFilters = cloneDeep(filters);
        const datePeriod = getStringFromObject('datePeriod', clonedFilters);

        delete clonedFilters.datePeriod;

        const construct =
            generateConstructFromFilterColumns(insuranceRequestLineFilter(), clonedFilters);
        const searchValueConstruct = generateSearchFilterConstruct(searchFilter, insuranceRequestLineFilters);
        let searchConstruct = getCombined(construct, COMBINEMODE.AND, searchValueConstruct);
        if (datePeriod) {
            const orderDateStarted =
                generateObjectWithCriteriaMode('InsuranceRequestLine.dateCreated', isValidNumber(datePeriod.startDate) ?
                    datePeriod.startDate : new Date(datePeriod.startDate).getTime(), '>=');

            const orderDateEnd =
                    generateObjectWithCriteriaMode('InsuranceRequestLine.dateCreated', isValidNumber(datePeriod.endDate) ?
                        datePeriod.endDate : new Date(datePeriod.endDate).getTime(), '<=');

            searchConstruct =
                getCombined(
                    searchConstruct,
                    COMBINEMODE.AND,
                    getCombined(
                        orderDateStarted,
                        COMBINEMODE.AND,
                        orderDateEnd,
                    ),
                );
        }

        const response =
            yield call(retriable().post, `${api}?page=${page}&size=${size}`, searchConstruct, config);
        yield put({
            type: FETCH_INSURANCE_REQUEST_LINES_SUCCESS,
            payload: response.data,
            page,
            size,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchInsuranceRequestsWorker(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({
            type: SHOW_SPINNER,
            state: { canShow: true },
        });
        const { payload } = action;
        const {
            page,
            size,
            api,
            filters,
            searchFilter,
        } = payload;
        const clonedFilters = cloneDeep(filters);

        const datePeriod = getStringFromObject('datePeriod', clonedFilters);
        delete clonedFilters.datePeriod;

        const construct =
        generateConstructFromFilterColumns(insuranceRequestsFilter(), clonedFilters);
        const searchValueConstruct = generateSearchFilterConstruct(searchFilter, insuranceRequestsFilters);
        let searchConstruct = getCombined(construct, COMBINEMODE.AND, searchValueConstruct);
        if (datePeriod) {
            const orderDateStarted =
            generateObjectWithCriteriaMode('InsuranceRequest.dateCreated', isValidNumber(datePeriod.startDate) ?
                datePeriod.startDate : new Date(datePeriod.startDate).getTime(), '>=');
            const orderDateEnd =
            generateObjectWithCriteriaMode('InsuranceRequest.dateCreated', isValidNumber(datePeriod.endDate) ?
                datePeriod.endDate : new Date(datePeriod.endDate).getTime(), '<=');
            searchConstruct =
            getCombined(
                searchConstruct,
                COMBINEMODE.AND,
                getCombined(
                    orderDateStarted,
                    COMBINEMODE.AND,
                    orderDateEnd,
                ),
            );
        }


        const response =
            yield call(retriable().post, `${api}?page=${page}&size=${size}`, searchConstruct, config);
        yield put({
            type: FETCH_INSURANCE_REQUESTS_SUCCESS,
            payload: response.data,
        });
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    } catch (e) {
        // do error action
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({
                type: SESSION_TIMEOUT,
                actionToDispatchAfterLogin: action,
            });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: getErrorMessage(e),
            });
        }
        yield put({
            type: HIDE_SPINNER,
            state: { canShow: false },
        });
    }
}
