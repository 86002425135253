import { takeLatest } from 'redux-saga/effects';
import { ADD_ANNOUNCEMENT_REQUEST, EDIT_ANNOUNCEMENT_REQUEST, FETCH_ANNOUNCEMENT_LIST_REQUEST }
    from '../../redux/modules/announcements/announcements-actions';
import {
    announcementAddRequest,
    announcementEditRequest,
    announcementListFetchRequest,
} from './announcementWorkerSaga';


export function* announcementFetchSaga() {
    yield takeLatest(FETCH_ANNOUNCEMENT_LIST_REQUEST, announcementListFetchRequest);
}

export function* announcementAddSaga() {
    yield takeLatest(ADD_ANNOUNCEMENT_REQUEST, announcementAddRequest);
}

export function* announcementEditSaga() {
    yield takeLatest(EDIT_ANNOUNCEMENT_REQUEST, announcementEditRequest);
}
